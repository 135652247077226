import React from 'react';
import { DetailedMetricCounter, CombinedMetricCounter } from '../../components';
import { MetricType } from '@bringg/react-components/dist/components/metric-counter/metric-counter';

interface Props {
	title: string;
	assignedCount: number;
	assignedPercentage: number;
	deliveredOnTimePercentage: number;
	avgTimeAtDoor: number;
	className?: string;
}

const MetricUnit = ({
	title,
	assignedCount,
	assignedPercentage,
	deliveredOnTimePercentage,
	avgTimeAtDoor,
	className
}: Props) => {
	return (
		<div className={className}>
			<CombinedMetricCounter
				title={title}
				count={assignedCount}
				percentage={assignedPercentage}
				className="metric-unit-combined-metric"
			/>

			<div className="metric-unit-container">
				<DetailedMetricCounter
					description={'VRP_AD.ANALYTICS.PROMISED_TIME'}
					metricValue={deliveredOnTimePercentage}
					metricType={MetricType.PERCENTAGE}
				/>
				<DetailedMetricCounter
					description={'VRP_AD.ANALYTICS.OUT_THE_DOOR_TIME'}
					metricValue={avgTimeAtDoor}
					metricType={MetricType.TIME}
				/>
			</div>
		</div>
	);
};

export default MetricUnit;
