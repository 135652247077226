const ModalAction = Object.freeze({
	OPEN_DYNAMIC_FORM_MODAL: 'open-dynamic-form',
	OPEN_TASKS_CSV_IMPORTER_V2: 'open_tasks_csv_importer_v2',
	NEW_TASK_CSV: 'new_task_csv',
	RUN_SELECT_DRIVER_TEAM: 'select_driver_team',
	RUN_USER_ASSIGNMENT: 'run_user_assignment',
	RUN_VEHICLE_ASSIGNMENT: 'run_vehicle_assignment',
	FLEET_ASSIGNMENT: 'fleet_assignment',
	OPEN_TASK_ACTIVITY_LOGS: 'open_tasks_activity_logs',
	SHOW_RUNS_PARTIAL_ACTIONS_MODAL: 'show_runs_partial_actions_modal',
	SHOW_RUNS_PARTIAL_ACTIONS_MODAL_RESPONSE: 'show_runs_partial_actions_modal_response',
	ADD_EMPLOYEE_MODAL: 'add_employee_modal',
	RUN_EDITOR_MODAL: 'run_editor_modal',
	OPEN_OPTIMIZATION_MODAL: 'open_optimization_modal',
	OPEN_ROUTES_PLANNER_MODAL: 'open_routes_planner_modal',
	OPEN_OPTIMIZATION_ERROR_MODAL: 'open_optimization_error_modal',
	OPEN_ROUTES_PLANNER_MODAL_MANUALLY: 'open_routes_planner_modal_manually',
	SUBMIT_TICKET_MODAL: 'submit_ticket_modal',
	OPEN_TASK_CREATION_MODAL: 'open_task_creation_modal',
	OPEN_REASON_TO_CANCEL_TASK_MODAL: 'open_reason_to_cancel_task_modal'
});

const DriverAction = Object.freeze({
	DRIVER_CREATED: 'driver_created'
});

const UserSettingsAction = Object.freeze({
	USER_SETTINGS_UPDATE_LANG: 'update_user_language'
});

const MapboxActions = Object.freeze({
	DISPATCH_MAPBOX_SELECTED_TASK: 'DISPATCH_MAPBOX_SELECTED_TASK',
	DISPATCH_MAPBOX_DATA_RESPONSE: 'DISPATCH_MAPBOX_DATA_RESPONSE'
});

const PackagesActions = Object.freeze({
	PRINT_LABEL_REQUEST: 'print_label_request'
});

const CustomAttributesActions = Object.freeze({
	CUSTOM_ATTRIBUTES_SET: 'custom_attributes_set'
});

// TODO: rename to SUBMIT_TICKET_MODAL after FF is removed https://bringg.atlassian.net/browse/BRNGG-22152
const HelpMenuAction = Object.freeze({
	SUBMIT_TICKET: 'submit_ticket'
});

const EmployeeListInMapTab = Object.freeze({
	EMPLOYEE_LIST_EMPLOYEES_UPDATED: 'employee_list_employees_updated',
	EMPLOYEE_LIST_SELECTED_EMPLOYEE_UPDATED: 'employee_list_selected_employee_updated',
	EMPLOYEE_LIST_REGISTER: 'employee_list_register',
	CHANGE_EMPLOYEE_STATE_FILTER: 'change_employee_state_filter',
	CHANGE_EMPLOYEE_AVAILABILITY_STATE_FILTER: 'change_employee_availability_state_filter',
	EMPLOYEE_FILTER_CHANGE: 'employee_filter_change',
	EMPLOYEE_LIST_DRIVER_GOT_HOME: 'employee_list_driver_got_home',
	EMPLOYEE_LIST_DRIVER_LEFT_HOME: 'employee_list_driver_left_home',
	EMPLOYEE_LIST_PICK_EMPLOYEE: 'employee_list_pick_employee'
});

const PresetViews = Object.freeze({
	PRESET_VIEWS_UPDATED: 'preset_views_updated',
	PRESET_VIEWS_INITIATED: 'preset_views_initiated'
});

const CustomerAppConfiguration = Object.freeze({
	CUSTOMER_APP_CONFIGURATION_UPDATED: 'customer_app_configuration_updated'
});

export {
	ModalAction,
	UserSettingsAction,
	MapboxActions,
	DriverAction,
	PackagesActions,
	CustomAttributesActions,
	HelpMenuAction,
	EmployeeListInMapTab,
	PresetViews,
	CustomerAppConfiguration
};
