import { useMemo } from 'react';

import { Table, Tooltip } from '@bringg/react-components';
import { Failure } from '@bringg-frontend/batch-action-status';
import { BringgIcon, BringgFontIcons } from '@bringg/bringg-icons';

import OrdersColumnsPopover from './orders-columns-popover';

import './orders-mapping-table.scss';

export type Props = {
	parsedFile: string[][];
	headers: string[];
	onChange?(headers: string[]): void;
	failures?: Failure[];
	expandMandatory?: boolean;
	readonly?: boolean;
};

function OrdersMappingTable({
	parsedFile,
	headers,
	onChange,
	failures = [],
	expandMandatory = false,
	readonly = false
}: Props) {
	const columns = useMemo(() => {
		if (!parsedFile) {
			return null;
		}

		const selectColumns = index => (_, e) => {
			const newColumns = [...headers];
			newColumns[index] = e.selected ? e.node.key : null;
			onChange?.(newColumns);
		};

		const fileColumns = parsedFile[0].map((column, index) => ({
			title: (
				<OrdersColumnsPopover
					readonly={readonly}
					onSelect={selectColumns(index)}
					originalTitle={column}
					headers={headers}
					selected={headers[index]}
					expandMandatory={expandMandatory}
				>
					{headers[index]}
				</OrdersColumnsPopover>
			),
			dataIndex: index,
			key: index,
			className: headers[index] ? 'orders-mapping-table-matched' : null
		}));

		if (failures.length) {
			fileColumns.unshift({
				title: <></>,
				dataIndex: -1,
				key: -1,
				className: null
			});
		}

		return fileColumns;
	}, [parsedFile, headers, onChange, expandMandatory, readonly, failures]);

	const dataSource = useMemo(() => {
		if (!parsedFile) {
			return null;
		}

		const fileSource = parsedFile.slice(1).map((cell, key) => ({
			key: key,
			...cell.reduce(
				(acc, value, index) => ({
					...acc,
					[index]: value?.toString() || null
				}),
				{}
			)
		}));

		if (failures.length) {
			fileSource.forEach((row, index) => {
				row[-1] = (
					<Tooltip title={failures[index].title}>
						<BringgIcon iconName={BringgFontIcons.Info} />
					</Tooltip>
				);
			});
		}

		return fileSource;
	}, [parsedFile, failures]);

	if (!parsedFile) {
		return null;
	}

	return <Table scroll={{ x: 878 }} className="orders-mapping-table" dataSource={dataSource} columns={columns} />;
}

export default OrdersMappingTable;
