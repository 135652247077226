import React, { useCallback, useContext, useMemo } from 'react';

import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Slider } from '@bringg/react-components';

import { OptimizationSettingsFormContext } from '../optimization-configuration-form/optimization-configuration-form';
import InputLabelWithTooltip from './../input-label-with-tooltip/input-label-with-tooltip';
import { OptimizationSettingsFormContextType, OptimizationSliderAttribute } from '../../types';

import './slider.scss';

const edgeMark = (args?: { edgeMark: string; edgeNumber: number }) =>
	Number.isFinite(args?.edgeNumber)
		? {
				label: (
					<div className="range-picker-serif-borders">
						<div className="range-picker-serif"></div>
						<div className="range-picker-text">{args.edgeMark || args.edgeNumber}</div>
					</div>
				)
		  }
		: { label: <div className="range-picker-serif"></div> };

const RangePicker = ({
	tooltipTitle,
	title,
	value,
	id,
	start: { value: start, label: startLabel },
	end: { value: end, label: endLabel },
	isSubField,
	disabled,
	containerId
}: OptimizationSliderAttribute) => {
	const { handleChange } = useContext<OptimizationSettingsFormContextType>(OptimizationSettingsFormContext);
	const { t } = useTranslation();

	const marks = useMemo(
		() =>
			Array(end - start + 1)
				.fill(0)
				.reduce((acc, _, i) => {
					const mark = start + i === start ? startLabel : start + i === end ? endLabel : undefined;

					acc[start + i] =
						start + i === start || start + i === end
							? edgeMark({ edgeNumber: start + i, edgeMark: t(mark) })
							: edgeMark();

					return acc;
				}, {}),
		[start, end, startLabel, endLabel, t]
	);

	const wrapperId = `route-optimization-range-slider-${id}`;
	const getPopupContainer = useCallback(
		() => document.querySelector(`#${wrapperId} .range-picker-slider`),
		[wrapperId]
	);

	return (
		<div
			id={wrapperId}
			className={classnames('route-optimization-range-picker', 'route-optimization-field-container', {
				'sub-field': isSubField
			})}
		>
			<InputLabelWithTooltip
				id={id}
				title={t(title)}
				tooltip={tooltipTitle ? t(tooltipTitle) : null}
				containerId={containerId}
			/>
			<Slider
				className="range-picker-slider"
				step={1}
				marks={marks}
				tooltip={{ getPopupContainer }}
				max={end}
				min={start}
				value={Number(value)}
				onChange={nextValue => handleChange(id, { value: nextValue, isValid: true })}
				disabled={disabled}
			/>
		</div>
	);
};

export default RangePicker;
