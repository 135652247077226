import React from 'react';
import { Radio, Space } from 'antd';
import { Modal } from '@bringg/react-components';
import { Translate } from '../../../../translation';

interface Props {
	onApply: (recurringOption: RecurringOptions) => void;
	onCancel: () => void;
	visible?: boolean;
}

interface State {
	recurringOption: RecurringOptions;
}

export type RecurringOptions = 'future' | 'all' | '';

const RECURRING_FUTURE = 'future';
const RECURRING_ALL = 'all';
const RECURRING_SINGLE = '';

class RecurringOptionsModal extends React.PureComponent<Props, State> {
	state = {
		recurringOption: RECURRING_SINGLE as RecurringOptions
	};

	handleApply = () => {
		this.props.onApply(this.state.recurringOption);
		this.resetRecurringOption();
	};

	handleCancel = () => {
		this.props.onCancel();
		this.resetRecurringOption();
	};

	resetRecurringOption = () => this.setState({ recurringOption: RECURRING_SINGLE });

	onRecurringOptionChange = ({ target }) => this.setState({ recurringOption: target.value });

	render() {
		const { handleApply, handleCancel, onRecurringOptionChange } = this;
		const { visible } = this.props;
		const { recurringOption } = this.state;
		return (
			<Modal
				className="recurring-options-modal"
				title={<Translate text="DELIVERY_BLOCKS.SELECT_RECURRING_OPTION" />}
				visible={visible}
				okText={<Translate text="DELIVERY_BLOCKS.APPLY" />}
				cancelText={<Translate text="DELIVERY_BLOCKS.CANCEL" />}
				onOk={handleApply}
				onCancel={handleCancel}
			>
				<Radio.Group value={recurringOption} onChange={onRecurringOptionChange}>
					<Space direction="vertical">
						<Radio value={RECURRING_SINGLE}>
							<Translate text="DELIVERY_BLOCKS.RECURRING.CURRENT_INSTANCE" />
						</Radio>
						<Radio value={RECURRING_FUTURE}>
							<Translate text="DELIVERY_BLOCKS.RECURRING.FUTURE" />
						</Radio>
						<Radio value={RECURRING_ALL}>
							<Translate text="DELIVERY_BLOCKS.RECURRING.ALL" />
						</Radio>
					</Space>
				</Radio.Group>
			</Modal>
		);
	}
}

export default RecurringOptionsModal;
