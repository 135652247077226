import { getRootEnv } from '@bringg-frontend/bringg-web-infra';
import { action, observable, computed, makeObservable } from 'mobx';
import { getRoot, getEnv } from 'mobx-easy';
import noop from 'lodash/noop';
import { User, PlannedRoute } from '@bringg/types';
import _first from 'lodash/first';
import { RootEnv } from '@bringg-frontend/bringg-web-infra';

import Crew from './domain-objects/crew';
import RootStore from '../root-store';

class CrewsStore {
	crews: Map<number, Crew> = new Map();
	availableDrivers: Map<number, User[]> = new Map();
	plannedRoutes: Map<number, PlannedRoute[]> = new Map();
	selectedTeamId: number = null;
	newCrew: Crew = null;

	add = (crew: Crew): Map<number, Crew> => this.crews.set(crew.id, crew);

	clearStore = (): void => this.crews.clear();

	delete = (id: number): boolean => this.crews.delete(id);

	update = (newCrew: Crew): void => {
		const oldCrew = this.crews.get(newCrew.id) || {};

		this.crews.set(newCrew.id, new Crew({ ...oldCrew, ...newCrew }, this));
	};

	constructor() {
		makeObservable(this, {
			crews: observable,
			availableDrivers: observable,
			plannedRoutes: observable,
			selectedTeamId: observable,
			newCrew: observable,
			add: action,
			clearStore: action,
			delete: action,
			update: action,
			setTeam: action,
			setPlannedRoutes: action,
			initNewCrew: action,
			setAvailableDrivers: action,
			getAll: computed,
			cancel: action
		});
	}

	setTeam(teamId: number) {
		this.selectedTeamId = teamId;
	}

	setPlannedRoutes(teamId: number, plannedRoutes: PlannedRoute[]) {
		this.plannedRoutes.set(teamId, plannedRoutes);
	}

	initNewCrew = () => {
		this.newCrew = new Crew(
			{
				team_id: this.selectedTeamId,
				primary_driver_id: null,
				planned_route_id: null,
				driver_ids: [],
				drivers: []
			},
			this
		);
	};

	setAvailableDrivers(teamId: number, drivers: User[]) {
		this.availableDrivers.set(teamId, drivers);
	}

	get getAll(): Crew[] {
		return Array.from(this.crews.values());
	}

	cancel() {
		this.newCrew = null;
	}

	selectTeam = async (teamId: number): Promise<void> => {
		this.clearStore();

		this.setTeam(teamId);

		await this.fetchData();
	};

	fetchData = async (): Promise<void> => {
		if (!this.selectedTeamId) {
			const firstTeamId = _first(getRoot<RootStore>().data.teamsStore.all)?.id;

			if (firstTeamId) {
				this.setTeam(firstTeamId);
			}
		}

		// selectedTeam still can be undefined if there is no teams at all
		if (this.selectedTeamId) {
			await this.getAllByTeam(this.selectedTeamId);
		}
	};

	getAvailableDriversByTeam = (teamId: number) => {
		return this.availableDrivers.get(teamId);
	};

	getPlannedRoutes(teamId: number) {
		return this.plannedRoutes.get(teamId);
	}

	getAllByTeam = async (teamId: number): Promise<void> => {
		await getRoot<RootStore>().data.driversStore.fetchByTeam(teamId);
		await this.fetchCrewData(teamId);

		const crews: Bringg.Crew[] = await getRootEnv().dashboardSdk.sdk.crews.getAllByTeam(teamId);

		crews.forEach(crew => this.add(new Crew(crew, this)));
	};

	fetchAvailableDrivers = async (teamId: number): Promise<User[]> =>
		getRootEnv().dashboardSdk.sdk.crews.getTeamAvalibleDrivers(teamId);

	fetchPlannedRoutes = async (teamId: number): Promise<PlannedRoute[]> =>
		// @ts-ignore until fix sdk using it's own type :>
		getRootEnv().dashboardSdk.sdk.plannedRoutes.getAllByTeam(teamId);

	fetchCrewData = async (teamId: number) => {
		const promises = [];

		if (!this.availableDrivers.get(teamId)) {
			promises.push(this.fetchAvailableDrivers(teamId));
		} else {
			promises.push(Promise.resolve(this.availableDrivers.get(teamId)));
		}

		if (!this.plannedRoutes.get(teamId)) {
			promises.push(this.fetchPlannedRoutes(teamId));
		} else {
			promises.push(Promise.resolve(this.plannedRoutes.get(teamId)));
		}

		const results = Promise.all(promises);

		const [availableDrivers, plannedRoutes] = await results;

		this.setPlannedRoutes(teamId, plannedRoutes);
		this.setAvailableDrivers(teamId, availableDrivers);
	};

	subscribe = (): void => {
		getRootEnv().dashboardSdk.sdk.crews.onItemUpdate(this.update);
		getRootEnv().dashboardSdk.sdk.crews.onItemRemoved(this.delete);
	};

	unsubscribe = (): void => {
		getRootEnv().dashboardSdk.sdk.crews.onItemUpdate(noop);
		getRootEnv().dashboardSdk.sdk.crews.onItemRemoved(noop);
	};
}

export default CrewsStore;
