import React, { useCallback, useMemo } from 'react';
import CSVUploader from '@bringg/react-components/dist/components/csv-uploader/csv-uploader';
import { useTranslation } from 'react-i18next';
import { CsvUploadResult } from '@bringg/react-components/dist/components/csv-uploader/csv-uploader.consts';
import { MerchantConfigurationCSVField } from '@bringg/types';
import { Translate } from '../../../../translation';
import notification from '../../../../services/notification';
import useStores from '../../../../recipes/use-stores';

interface Props {
	afterModalClose: () => void;
	vehiclesCsvFields: MerchantConfigurationCSVField[];
}

const VehiclesCSVUploader: React.FC<Props> = ({ afterModalClose, vehiclesCsvFields }: Props) => {
	const { vehiclesStore } = useStores();
	const { t } = useTranslation();

	const handleOnUpload = useCallback(
		async (_, csvFile: Blob): Promise<CsvUploadResult> => {
			try {
				const importResponse = await vehiclesStore.importVehiclesCsv(csvFile);

				if (!importResponse.success) {
					notification.error(t('VEHICLES_CSV.ERROR_MESSAGE'));
					return undefined;
				}

				return {
					errors: importResponse.errors.map(error => {
						return `${t('GLOBAL.LINE')} ${error.line}: ${error.error.message}`;
					}),
					failed_number: importResponse.failed_importing || 0,
					imported_number: importResponse.imported_vehicles,
					uploaded_number: (importResponse.failed_importing || 0) + importResponse.imported_vehicles
				};
			} catch (e) {
				notification.error(t('VEHICLES_CSV.ERROR_MESSAGE'));
			}

			return undefined;
		},
		[vehiclesStore, t]
	);

	const headers = useMemo(() => {
		return vehiclesCsvFields?.map(header => ({
			...header,
			// Replacing underscores with spaces
			field_name: header.field_name.replace(/_/g, ' ')
		}));
	}, [vehiclesCsvFields]);

	return (
		<CSVUploader
			modalProps={{
				title: <Translate text="VEHICLES_CSV.TITLE" />,
				okText: <Translate text="GLOBAL.UPLOAD" />,
				cancelText: <Translate text="GLOBAL.CANCEL" />,
				afterClose: afterModalClose
			}}
			onUpload={handleOnUpload}
			headers={headers}
			description={t('VEHICLES_CSV.DESCRIPTION')}
			totalRowsText={t('VEHICLES_CSV.TOTAL_ROWS')}
			columnsSelectedText={t('VEHICLES_CSV.COLUMNS_SELECTED')}
			showFirstRow
			uploadText={t('GLOBAL.UPLOAD')}
			finishText={t('GLOBAL.COMPLETE')}
			linesWithErrorsText={t('VEHICLES_CSV.LINES_WITH_ERRORS')}
			linesUploadedText={t('VEHICLES_CSV.LINES_UPLOADED')}
			linesImportedText={t('VEHICLES_CSV.LINES_IMPORTED')}
			tableProps={{
				className: 'vehicle-csv-uploader-table'
			}}
			showDownloadTemplate={false}
		/>
	);
};

export default VehiclesCSVUploader;
