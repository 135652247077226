import React, { useCallback, useEffect, useState } from 'react';

import { useObserver } from 'mobx-react';
import _isEmpty from 'lodash/isEmpty';
import { BringgException } from '@bringg/dashboard-sdk/dist/Core/BringgException';
import SpinnerWrapper from '@bringg/react-components/dist/components/spinner/spinner';
import { useTranslation } from 'react-i18next';
import { Button } from '@bringg/react-components';
import { withErrorBoundary } from '@bringg-frontend/bringg-web-infra';

import notification from 'bringg-web/services/notification';
import { useStores } from 'bringg-web/recipes';
import NotificationsConfigurationSection from './configurations-sections/notifications/notifications-configuration';
import TasksFilterSettings from './configurations-sections/tasks-filter-settings/tasks-filter-settings';
import {
	defaultStoreAppFilterSettings,
	formatFilterSettingsFromServer,
	OpenTasksFilter,
	FilterSettings
} from './configurations-sections/tasks-filter-settings/consts';

const formatInitialFilterSettings = (filterSettings: OpenTasksFilter): FilterSettings => {
	if (_isEmpty(filterSettings?.options)) {
		return defaultStoreAppFilterSettings;
	}
	return formatFilterSettingsFromServer(filterSettings);
};

const StoreAppSettingsContainer: React.FC = () => {
	const { storeAppStore, merchantConfigurationsStore } = useStores();
	const [isLoaded, setLoaded] = useState(false);
	const [initialValues, setInitialValues] = useState(null);

	const { t } = useTranslation();

	const initData = useCallback(async () => {
		try {
			await Promise.all([storeAppStore.fetchConfiguration(), merchantConfigurationsStore.fetch()]);
			setInitialValues({
				filterSettings: formatInitialFilterSettings(
					merchantConfigurationsStore.configuration?.open_tasks_filter
				)
			});
		} catch (error) {
			const { message } = error as BringgException;
			console.error(`failed to load store app settings: ${message}`, error);
			notification.error(
				t('STORE_APP_CONFIG.PUSH_NOTIFICATIONS.FAILED_TO_LOAD', 'Failed to load store app settings')
			);
		} finally {
			setLoaded(true);
		}
	}, [storeAppStore, t]);

	const updateConfiguration = async () => {
		try {
			await storeAppStore.updateConfig();
		} catch (error) {
			console.error('failed to update store app configuration', error);
			notification.error(t('STORE_APP_CONFIG.PUSH_NOTIFICATIONS.FAILED_TO_UPDATE', 'failed to update'));
		}
	};

	useEffect(() => {
		initData();
	}, []);

	return useObserver(() => {
		if (!isLoaded) {
			return <SpinnerWrapper className="spinner-center" />;
		}

		return (
			<div className="store-app-settings-container">
				<div className="store-app-settings-section">
					<div className="store-app-settings-section-title">
						{t('STORE_APP_CONFIG.PUSH_NOTIFICATIONS.TITLE', 'Push Notifications')}
					</div>
					<div className="store-app-settings-section-content">
						<NotificationsConfigurationSection />
					</div>
					<Button type="primary" className="button" onClick={updateConfiguration}>
						{t('STORE_APP_CONFIG.PUSH_NOTIFICATIONS.UPDATE', 'Update')}
					</Button>
				</div>
				<TasksFilterSettings initialSettings={initialValues.filterSettings} />
			</div>
		);
	});
};

export default withErrorBoundary(StoreAppSettingsContainer);
