import React, { Component } from 'react';

import { getRootEnv } from '@bringg-frontend/bringg-web-infra';
import moment from 'moment';
import { Portal } from '@bringg-frontend/bringg-web-infra';

import { POLLING_TIMEOUT } from '../dashboard-consts';
import { Table } from '../../../components';

type preparationStatusWidgetValues = {
	values: {
		team_id: number;
		name: string;
		count: number;
		last_sent: string;
	}[];
};

export type preparationStatusWidget = Bringg.Widget & preparationStatusWidgetValues;

interface Props {
	widget: preparationStatusWidget;
}

interface State {
	data: preparationStatusWidget;
	fetched: boolean;
	sortBy: 'longest_delay' | 'count' | 'name';
	order: 'descend' | 'ascend';
}

class PreparationStatusWidget extends Component<Props, State> {
	state = {
		data: this.props.widget,
		fetched: false,
		sortBy: 'longest_delay' as const,
		order: 'descend' as const
	};

	formatDelay = (epoch: number) => {
		return moment.duration(epoch).humanize();
	};

	onSortClicked = columnKey => {
		this.setState(state => {
			let order = 'ascend' as const;

			if (state.sortBy === columnKey) {
				order = state.order === 'descend' ? 'ascend' : ('descend' as any);
			}

			return { order, sortBy: columnKey };
		});
	};

	onHeaderClickHandler = column => ({
		onClick: () => this.onSortClicked(column.dataIndex)
	});

	getColumns = () => [
		{
			title: 'Team',
			dataIndex: 'name',
			sorter: (a, b) => (a.name > b.name ? 1 : -1), //asdf
			sortOrder: this.state.sortBy === ('name' as any) && (this.state.order as any),
			onHeaderCell: this.onHeaderClickHandler
		},
		{
			title: 'Orders In Queue',
			dataIndex: 'count',
			sorter: (a, b) => a.count - b.count,
			sortOrder: this.state.sortBy === ('count' as any) && (this.state.order as any),
			onHeaderCell: this.onHeaderClickHandler
		},
		{
			title: 'Orders Pending For',
			dataIndex: 'longest_delay',
			render: this.formatDelay,
			sorter: (a, b) => a.longest_delay - b.longest_delay,
			sortOrder: this.state.sortBy === ('longest_delay' as any) && (this.state.order as any),
			onHeaderCell: this.onHeaderClickHandler
		}
	];

	pollingInterval: NodeJS.Timeout;

	componentDidMount() {
		this.fetchWidgetData();
		this.pollingInterval = setInterval(() => {
			this.fetchWidgetData();
		}, POLLING_TIMEOUT);
	}

	fetchWidgetData = async () => {
		const data: any = await getRootEnv().dashboardSdk.sdk.dashboard.getWidgetData(this.props.widget['id']);
		this.setState({
			data,
			fetched: true
		});
	};

	componentWillUnmount() {
		clearInterval(this.pollingInterval);
	}

	public render() {
		return (
			<Table
				dataSource={this.state.data.values}
				columns={this.getColumns()}
				rowKey="team_id"
				locale={{ emptyText: 'No Delays' }}
				size="small"
				loading={!this.state.fetched}
				className="preparation-status-widget react-component"
				pagination={{
					position: ['bottomLeft'],
					pageSize: 5
				}}
			/>
		);
	}
}

const PreparationWidgetPortal = ({ widget }: { widget: preparationStatusWidget }) => {
	return <Portal element={<PreparationStatusWidget widget={widget} />} nodeId={'preparation-status-widget'} />;
};

export default PreparationWidgetPortal;
