import React from 'react';
import { OpeniFrameData as OpeniFrameDataEntity, ActionData } from '@bringg/types/types/action_data';
import { Input, Row } from 'antd';
import { observer } from 'mobx-react';
import Translate from '../../../translation/translator';

interface OpeniFrameDataProps {
	actionsData: ActionData;
	onFieldUpdate: (updatedFields: Partial<ActionData>) => void;
}

const OpeniFrameData: React.FC<OpeniFrameDataProps> = observer(({ onFieldUpdate, actionsData }): JSX.Element => {
	const setURL = event => {
		onFieldUpdate({ data: { url: event.target.value } });
	};

	const getOpeniFrameData = (): OpeniFrameDataEntity => {
		const defaultOpeniFrameData = { url: '' };
		return Object.assign(defaultOpeniFrameData, actionsData.data || {});
	};

	return (
		<div className="open-iframe-form-data">
			<Row>
				<div>
					<Translate text="ACTION_CONFIGURATION.OPEN_IFRAME.URL" />
				</div>
				<Input
					type="text"
					onChange={setURL}
					value={getOpeniFrameData().url}
					data-test-id="action-data-open-iframe-url"
					className="open-iframe-data-input"
				/>
			</Row>
		</div>
	);
});

export default OpeniFrameData;
