import { Run } from '@bringg/types';
import TimezoneService from '../timezone/timezone-service';

const getPlannedRunsOfSameDayById = (
	runs: Run[],
	dateRange: { endTime?: Date; startTime?: Date },
	sourceRunId: number,
	timezone?: string
) => {
	const runsById = {};

	for (const run of runs) {
		if (run.id === sourceRunId || isBetweenTimeRange(run.scheduled_start_time, dateRange, timezone)) {
			runsById[run.id] = run;
		}
	}

	return runsById;
};

const isBetweenTimeRange = (dateString: string, dateRange: { endTime?: Date; startTime?: Date }, timezone?: string) => {
	if (!dateString || !dateRange.startTime || !dateRange.endTime) {
		return true;
	}

	const date = new Date(dateString);
	const momentTz = TimezoneService.getMomentTimezone(timezone);

	return momentTz(date).isBetween(
		momentTz(dateRange.startTime).startOf('day'),
		momentTz(dateRange.endTime).endOf('day')
	);
};

export default { getPlannedRunsOfSameDayById };
