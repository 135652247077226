import React from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { FleetStatus } from '@bringg/types';

interface DeliveryCatalogConnectStatusProps {
	status: FleetStatus;
}

const DeliveryCatalogConnectStatus = ({ status }: DeliveryCatalogConnectStatusProps) => {
	const { t } = useTranslation();

	return (
		<div className="delivery-catalog-connect-status">
			<h3>{t('DELIVERY_PROVIDER_CATALOG_CONNECT.STATUS.TITLE')}</h3>

			<ul>
				<li className="delivery-catalog-connect-status-active">
					<div className="delivery-catalog-connect-status-circle">1</div>
					<span>{t('DELIVERY_PROVIDER_CATALOG_CONNECT.STATUS.FIRST')}</span>
				</li>
				<li
					className={classNames({
						'delivery-catalog-connect-status-active': [
							FleetStatus.Active,
							FleetStatus.Pending,
							FleetStatus.Failed
						].includes(status)
					})}
				>
					<div className="delivery-catalog-connect-status-circle">2</div>
					<span>{t('DELIVERY_PROVIDER_CATALOG_CONNECT.STATUS.SECOND')}</span>
				</li>
				<li className={classNames({ 'delivery-catalog-connect-status-active': status === FleetStatus.Active })}>
					<div className="delivery-catalog-connect-status-circle">3</div>
					<span>{t('DELIVERY_PROVIDER_CATALOG_CONNECT.STATUS.THIRD')}</span>
				</li>
			</ul>
		</div>
	);
};

export default DeliveryCatalogConnectStatus;
