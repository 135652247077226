import i18next from 'i18next';

import WorkflowStore from '../../../stores/workflow-store';
import { and, changed } from '../../../utils/use-automation-translations';
import { TriggerContainer } from '../../../utils/types';

export const getTriggerGroupHeader = ({ trigger }: WorkflowStore) => {
	if (trigger.familyTriggers.length === 1) {
		return trigger.family;
	}

	if (trigger.family === TriggerContainer.Field_Changed) {
		return `${trigger.findTriggerMetadata.display_path} ${i18next.t(changed)}`;
	}

	return trigger.findTriggerMetadata.display_path;
};

export const getTriggerFamilyGroupHeader = (workflow: WorkflowStore) =>
	workflow.trigger.findTriggerMetadata.displayFamily;

export const getActionGroupHeader = (allActionsOptions: Record<number, string>) => (workflow: WorkflowStore) =>
	workflow.actions.flatActions
		.map(theAction => allActionsOptions[theAction.action_type])
		.filter((value, index, self) => self.indexOf(value) === index)
		.sort()
		.join(` ${i18next.t(and)} `);
