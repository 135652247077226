import React from 'react';

import moment, { Moment } from 'moment';
// eslint-disable-next-line no-restricted-imports
import { Row, Col } from 'antd';

interface Props {
	text: string;
	date: Date | Moment | string;
	eventType: string;
	counter: number;
	id?: number | string;
}

const RunHistoryRow = (props: Props) => {
	return (
		<div className="history-row">
			<Row gutter={15}>
				<Col span={14}>
					<span className="history-title">
						{props.id ? `#${props.id} - ` : null}
						{props.eventType}
					</span>
				</Col>
				<Col span={10} className={'text-align-end'}>
					<span className="history-date">{moment(props.date).format('LT')}</span>
				</Col>
				<Col span={24}>
					<span className="history-text">{props.text}</span>
				</Col>
			</Row>
		</div>
	);
};

export default RunHistoryRow;
