import moment from 'moment';
import { Task } from '@bringg/types';
import { getRoot } from 'mobx-easy';

import { getWaypointRange } from 'bringg-web/services/tasks/task-time-run-provider';
import { MAX_OPTIMIZATION_DAYS } from './optimization-time-frame/optimization-time-frame';
import RootStore from 'bringg-web/stores/root-store';

const adjustOptimizationTimeFrameToOneDay = (
	tasksDateRange: { startTime: Date; endTime: Date },
	selectedTasks: Task[]
) => {
	const { startTime, endTime } = tasksDateRange;
	if (moment(tasksDateRange.startTime).isSame(tasksDateRange.endTime, 'day')) {
		return tasksDateRange;
	}

	let newEndTime = endTime;
	selectedTasks.forEach(task => {
		for (const wayPoint of task.way_points) {
			const wpNoLaterThen = wayPoint.no_later_than || wayPoint.first_attempt_promise_no_later_than;
			if (startTime && wpNoLaterThen && !moment(wpNoLaterThen).isSame(startTime, 'day')) {
				const startTimeClone = moment(startTime).clone();
				const timeZone = getRoot<RootStore>().data.teamsStore.get(task.team_ids[0]).time_zone;
				const endOfDay = startTimeClone.clone().tz(timeZone).endOf('day').utc();
				newEndTime = endOfDay.toDate();
			}
		}
		return false;
	});
	return { startTime, endTime: newEndTime };
};

const limitOptimizationToMaxOptimizationDays = (tasksDateRange: { startTime: Date; endTime: Date }) => {
	const optimizationDuration = moment
		.duration(moment(tasksDateRange.endTime).diff(tasksDateRange.startTime))
		.asDays();
	if (optimizationDuration > MAX_OPTIMIZATION_DAYS) {
		const newOptimizationEndDate = moment(tasksDateRange.startTime)
			.add(MAX_OPTIMIZATION_DAYS - 1, 'days')
			.utc()
			.endOf('day')
			.toString();
		return new Date(newOptimizationEndDate);
	}

	return tasksDateRange.endTime;
};

export const getOptimizationTimeFrameFromSelectedTasks = (selectedTasks: Task[]) => {
	const isMultiDayOptimization =
		getRoot<RootStore>().data.merchantConfigurationsStore.configuration.allow_multi_day_optimization;
	let tasksDateRange = getWaypointRange(selectedTasks);
	if (!isMultiDayOptimization) {
		tasksDateRange = adjustOptimizationTimeFrameToOneDay(tasksDateRange, selectedTasks);
	}
	tasksDateRange.endTime = limitOptimizationToMaxOptimizationDays(tasksDateRange);
	return tasksDateRange;
};
