import React from 'react';
import _range from 'lodash/range';
import { Controller, UseFormMethods } from 'react-hook-form';
import { Checkbox, Option, Select } from '@bringg/react-components';
import './shift-part.scss';

interface Props {
	name: string;
	start: number;
	end: number;
	isChecked: boolean;
	form: UseFormMethods;
	disabled?: boolean;
}

const options = _range(25);

const TimeSelect = ({ ...props }) => {
	return (
		<Select size="small" {...props}>
			{options.map(option => (
				<Option key={option} value={option}>
					{`${option}:00`}
				</Option>
			))}
		</Select>
	);
};

const ShiftPart: React.FC<Props> = ({ name, start, end, isChecked, form, disabled = false }) => {
	const { control, watch, setValue } = form;
	const isSelectDisabled = !watch(`${name}.enabled`) || disabled;

	const onChange = e => {
		const isChecked = e.target.checked;
		if (!isChecked) {
			setValue(`${name}.startHour`, null, { shouldDirty: true });
			setValue(`${name}.endHour`, null, { shouldDirty: true });
		}

		setValue(`${name}.enabled`, isChecked, { shouldDirty: true });
	};

	return (
		<div className="shift-part">
			<Controller
				render={({ value }) => <Checkbox className="checkbox" checked={value} onChange={onChange} />}
				name={`${name}.enabled`}
				control={control}
				defaultValue={isChecked}
				disabled={disabled}
			/>
			<Controller
				render={({ ref, ...rest }) => <TimeSelect {...rest} />}
				name={`${name}.startHour`}
				control={control}
				defaultValue={start}
				disabled={isSelectDisabled}
			/>
			<span className="dash"> - </span>
			<Controller
				render={({ ref, ...rest }) => <TimeSelect {...rest} />}
				name={`${name}.endHour`}
				control={control}
				defaultValue={end}
				disabled={isSelectDisabled}
			/>
		</div>
	);
};

export default ShiftPart;
