import { action, computed, makeObservable, observable } from 'mobx';
import { AlertRuleDefinition } from '@bringg/types/types/workflow/alerts_configuration';
import { DisplayFact, FactType } from '@bringg/types';
import PlaceholderCondition from './placeholder-condition-store';
import RuleStore from './rule-store';

class RulesRepo {
	conditions: RuleStore[] = [];
	factType: FactType;
	displayFact: DisplayFact;

	constructor(rule: AlertRuleDefinition, factType: FactType, displayFact: DisplayFact) {
		makeObservable(this, {
			conditions: observable,
			addRule: action,
			rules: computed,
			conditionMetadata: computed,
			isEmpty: computed,
			conditionsLength: computed,
			reset: action
		});
		this.factType = factType;
		this.displayFact = displayFact;
		this.conditions = rule.any.map(anyRule => new RuleStore(factType, displayFact, anyRule));
	}

	reset = (factType: FactType, displayFact: DisplayFact) => {
		this.conditions = [];
		this.factType = factType;
		this.displayFact = displayFact;
	};

	addRule = (): void => {
		this.conditions.push(new RuleStore(this.factType, this.displayFact));
	};

	get rules(): RuleStore[] {
		const conditions = this.conditions.filter(cond => {
			return cond.conditions.length > 0;
		});
		if (conditions.length === 0) {
			const rule = new RuleStore(this.factType, this.displayFact);
			conditions.push(rule);
			this.conditions.push(rule);
		}
		return conditions;
	}

	get conditionsLength() {
		if (this.rules.length === 1) {
			return this.rules[0].conditions.length;
		}
		return this.rules.length;
	}

	get conditionMetadata() {
		return this.rules[0].conditionMetadata;
	}

	get isEmpty(): boolean {
		return this.rules[0].conditions[0] instanceof PlaceholderCondition;
	}

	get canComplete() {
		return this.conditions.every(condition => condition.canComplete);
	}
}

export default RulesRepo;
