import { CellProps, Column } from 'react-table';
import { CustomAttributeRes } from '@bringg/types';
import { BringgFontIcons, BringgIcon } from '@bringg/bringg-icons';
import { TFunction } from 'i18next';
import classNames from 'classnames';

import { columnIds } from './consts';
import { ActionsCell, AttributeDataTypeCell, AttributeEntityTypeCell, AttributeMarkerCell } from '../components';

export const tableColumns = (t: TFunction, openEditModal: (id: number) => void) => {
	return [
		{
			id: columnIds.ATTRIBUTE_MARKER,
			sticky: 'left',
			width: 6,
			Cell: ({ row }: CellProps<CustomAttributeRes>) => {
				return <AttributeMarkerCell suggestion={row.original.suggestion} />;
			},
			disableSortBy: true,
			disableResizing: true
		},
		{
			id: columnIds.ATTRIBUTE_NUMBER,
			sticky: 'left',
			width: 48,
			disableSortBy: true,
			disableResizing: true,
			Header: () => {
				return <div className="text-center"># </div>;
			},
			Cell: ({ row }: CellProps<CustomAttributeRes>) => {
				return <div className="text-center"> {row.index + 1} </div>;
			}
		},
		{
			id: columnIds.NAME,
			accessor: columnIds.NAME,
			filter: 'byNameAndPath',
			minWidth: 150,
			Header: t('CUSTOM_ATTRIBUTES.DISPLAY_NAME'),
			Cell: ({ value }: CellProps<CustomAttributeRes>) => {
				return <span title={value}> {value} </span>;
			}
		},
		{
			id: columnIds.PATH,
			accessor: columnIds.PATH,
			filter: 'byNameAndPath',
			minWidth: 150,
			Header: t('CUSTOM_ATTRIBUTES.PATH'),
			Cell: ({ value }: CellProps<CustomAttributeRes>) => {
				return <span title={value}> {value} </span>;
			}
		},
		{
			id: columnIds.ENTITY_TYPE,
			accessor: columnIds.ENTITY_TYPE,
			minWidth: 150,
			Header: t('CUSTOM_ATTRIBUTES.ENTITY'),
			Cell: ({ row, value: entityType }: CellProps<CustomAttributeRes>) => {
				return <AttributeEntityTypeCell entityType={entityType} suggestion={row.original.suggestion} />;
			}
		},
		{
			id: columnIds.DATA_TYPE,
			accessor: columnIds.DATA_TYPE,
			filter: 'arrayIncludes',
			Header: t('CUSTOM_ATTRIBUTES.DATA_TYPE'),
			Cell: ({ row, value: dataType }: CellProps<CustomAttributeRes>) => {
				return <AttributeDataTypeCell dataType={dataType} suggestion={row.original.suggestion} />;
			}
		},
		{
			id: columnIds.SUGGESTION,
			accessor: columnIds.SUGGESTION,
			Header: t('CUSTOM_ATTRIBUTES.STATUS'),
			Cell: ({ value: suggestion }: CellProps<CustomAttributeRes>) => {
				return (
					<span className={classNames({ suggestion })}>
						{t(suggestion ? 'CUSTOM_ATTRIBUTES.SUGGESTED' : 'CUSTOM_ATTRIBUTES.ACTIVE')}
					</span>
				);
			}
		},
		{
			id: columnIds.REQUIRED,
			accessor: columnIds.REQUIRED,
			disableResizing: true,
			Header: t('CUSTOM_ATTRIBUTES.MANDATORY'),
			Cell: ({ row, value: required }: CellProps<CustomAttributeRes>) =>
				required ? (
					<BringgIcon
						key={`${row.original.id}-${required}`}
						iconName={BringgFontIcons.Suffix}
						className="required-attribute-icon"
					/>
				) : (
					<>&#8212; </>
				)
		},
		{
			id: columnIds.DRIVER_APP,
			accessor: columnIds.DRIVER_APP,
			disableResizing: true,
			Header: t('CUSTOM_ATTRIBUTES.DRIVER_APP'),
			Cell: ({ row, value: show_in_mobile_app }: CellProps<CustomAttributeRes>) =>
				show_in_mobile_app ? (
					<BringgIcon
						key={`${row.original.id}-${show_in_mobile_app}`}
						iconName={BringgFontIcons.Suffix}
						className="required-attribute-icon"
					/>
				) : (
					<>&#8212; </>
				)
		},
		{
			id: columnIds.ACTION,
			sticky: 'right',
			accessor: 'suggestion',
			disableResizing: true,
			disableSortBy: true,
			width: 150,
			Header: t('CUSTOM_ATTRIBUTES.ACTIONS'),
			Cell: ({ row, value }: CellProps<CustomAttributeRes>) => (
				<ActionsCell suggestion={value} id={row.original.id} onEdit={openEditModal} />
			)
		}
	] as Column<CustomAttributeRes>[];
};
