import React from 'react';

import { Spinner } from '@bringg/react-components';
import { useTranslation } from 'react-i18next';

import styles from './attachments-loader.module.scss';

export const AttachmentsLoader = () => {
	const { t } = useTranslation();

	return (
		<div className={styles.attachmentsLoader}>
			<p className={styles.attachmentsLoaderTitle}>{t('NEW_SUBMIT_TICKET.ATTACHMENTS_UPLOADING')}</p>
			<Spinner size="large" />
		</div>
	);
};
