import React from 'react';
import { Tooltip } from '@bringg/react-components';

import { VpnKey } from '../icons';

interface Props {
	status: boolean;
	tooltip: string;
}

export const TurnkeyStatus = ({ status, tooltip }: Props) => {
	return status ? (
		<Tooltip placement="top" title={tooltip}>
			<span>
				<VpnKey />
			</span>
		</Tooltip>
	) : null;
};
