interface AppConfig {
	APPLY_MERCHANT_TABLE_CONFIG: boolean;
	STORE_MERCHANT_TABLE_CONFIG: boolean;
	ALERTS_REAL_TIME_UPDATE: boolean;
	TASKS_REAL_TIME_UPDATE: boolean;
	ALERTS_TABLE_FAST_INITIALIZATION: boolean;
	ALERTS_REAL_TIME_BATCHING: boolean;
}

export const APP_CONFIG: AppConfig = {
	APPLY_MERCHANT_TABLE_CONFIG: true,
	STORE_MERCHANT_TABLE_CONFIG: true,
	ALERTS_REAL_TIME_UPDATE: true,
	TASKS_REAL_TIME_UPDATE: true,
	ALERTS_TABLE_FAST_INITIALIZATION: true,
	ALERTS_REAL_TIME_BATCHING: true
};

// This is needed mostly to test different configs, don't overuse it
export const setAppConfig = (config: Partial<AppConfig>) => Object.assign(APP_CONFIG, config);
