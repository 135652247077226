import React from 'react';

import { MobXProviderContext } from 'mobx-react';

import DataStore from '../stores/data-store';
import ViewsStore from '../stores/views-store';

export type AllStores = DataStore & ViewsStore;

const useStores = (): AllStores => {
	const stores = React.useContext(MobXProviderContext) as AllStores;

	return stores;
};

export default useStores;
