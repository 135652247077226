import { useEffect } from 'react';

import CrossAppTransport from '@bringg/dashboard-sdk/dist/CrossAppTranport/CrossAppTransport';
import { registerAction, executeAction } from '@bringg-frontend/global-stores';

export { registerAction, executeAction };

export const useCrossApp = (event: any, callback: any, crossAppTransport: CrossAppTransport) => {
	useEffect(() => registerAction(event, callback, crossAppTransport), []);
};
