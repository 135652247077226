import { getRootEnv } from '@bringg-frontend/bringg-web-infra';
import { action, makeObservable } from 'mobx';
import { getEnv } from 'mobx-easy';
import { RootEnv } from '@bringg-frontend/bringg-web-infra';

import BaseDomainStore from './core/base-domain-store';

abstract class BaseDomainObject<T> {
	protected entityName;
	abstract id: number;
	abstract get asJson(): Partial<T>;

	protected store;

	// TODO: make all stores u BaseDomainStore
	protected constructor(store: BaseDomainStore<T> | any, entityName: string) {
		makeObservable(this, {
			set: action
		});

		this.store = store;
		this.entityName = entityName;
	}

	set(domainObject: Partial<T> | Partial<BaseDomainObject<T>>) {
		Object.assign(this, domainObject);
	}

	create = async (json: Partial<T>) => {
		const { id } = await getRootEnv().dashboardSdk.sdk[this.entityName].create(json);
		this.id = id;
		this.store.set(this);
	};

	async update(json: Partial<T>, options = {}) {
		const response = await getRootEnv().dashboardSdk.sdk[this.entityName].update(Object.assign({}, json, options));

		this.set({ ...this.asJson, ...response });
		this.store.set(this);
	}

	delete = async (...args: any[]) => {
		await getRootEnv().dashboardSdk.sdk[this.entityName].delete(this.id, ...args);
		this.store.remove(this.id);
	};
}

export default BaseDomainObject;
