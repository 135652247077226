import React from 'react';
import { Driver } from '@bringg/types';
import { BringgFontIcons, BringgIcon } from '@bringg/bringg-icons';
import { ALL_DRIVERS, UNASSIGNED } from '../../vehicle-assignment-history.consts';
import { MultiSelect } from '../../../../components';
import { translate } from '../../../../translation';
import Translate from '../../../../translation/translator';

interface Props {
	optionalDrivers: Driver[];
	onSelectDrivers: (values: number[]) => void;
	translate: Function;
}

interface State {
	displayValue: string;
	selectedValues: number[];
}

class DriversFilterComponent extends React.Component<Props> {
	ALL_OPTIONS = [ALL_DRIVERS, UNASSIGNED, ...this.props.optionalDrivers.map(driver => driver.id)];

	UNASSIGNED_OPTION = {
		value: UNASSIGNED,
		children: <Translate text="VEHICLE.UNASSIGNED" />
	};

	ALL_DRIVERS_OPTION = {
		value: ALL_DRIVERS,
		children: <Translate text="MAP.ALL_DRIVERS" />
	};

	state = {
		displayValue: this.ALL_DRIVERS_OPTION.children,
		selectedValues: this.ALL_OPTIONS
	};

	getDriverOptions = () => {
		const driversOptions = [this.ALL_DRIVERS_OPTION, this.UNASSIGNED_OPTION];

		if (this.props.optionalDrivers) {
			this.props.optionalDrivers.forEach(driver => {
				driversOptions.push({
					value: driver.id,
					children: driver.name as any
				});
			});
		}

		return driversOptions;
	};

	onSelectDrivers = (selected: number[]): void => {
		const selectedValues = this.getValueFromSelected(selected);
		const displayValue = this.getDisplayValue(selectedValues);
		this.setState({ displayValue, selectedValues });

		this.props.onSelectDrivers(selectedValues);
	};

	getDisplayValue = (selected: number[]) => {
		if (selected.includes(ALL_DRIVERS)) {
			return this.ALL_DRIVERS_OPTION.children;
		}
		if (selected.length === 1) {
			const driver = this.props.optionalDrivers.find(driver => driver.id === selected[0]);
			return driver ? driver.name : <Translate text="VEHICLE.UNASSIGNED" />;
		}
		if (selected.length > 1) {
			return `${selected.length} options`;
		}

		return this.props.translate('VEHICLE.SELECT_DRIVERS');
	};

	getValueFromSelected = (selectedValues: number[]): number[] => {
		let value = selectedValues;
		const prevValue = this.state.selectedValues;

		if (selectedValues.includes(ALL_DRIVERS)) {
			if (prevValue.includes(ALL_DRIVERS)) {
				// Deselect the "ALL_DRIVER" option since one of the element was deselected
				value = selectedValues.slice(1);
			} else {
				value = this.ALL_OPTIONS;
			}
		} else if (prevValue.includes(ALL_DRIVERS)) {
			value = [];
		}

		return value;
	};

	render() {
		return (
			<div className="drivers-filter">
				<label className="drivers-placeholder">
					<span className="drivers-text-icon">
						<BringgIcon iconName={BringgFontIcons.User} className="drivers-icon" />
						<span className="placeholder-text">{this.state.displayValue}</span>
					</span>
					<BringgIcon iconName={BringgFontIcons.Chevron} />
				</label>
				<MultiSelect
					showArrow={false}
					options={this.getDriverOptions()}
					onChange={this.onSelectDrivers}
					value={this.state.selectedValues}
				/>
			</div>
		);
	}
}

export default DriversFilterComponent;
export const DriversFilter = translate()(DriversFilterComponent);
