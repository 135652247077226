import moment, { Moment } from 'moment';
import { DeliveryBlockTemplateSchedule, Team } from '@bringg/types';
import { RRule } from 'rrule';

import { makeRRuleString } from 'bringg-web/services/rrule/rrule-service';

const getUpdatedSchedulesDates = (
	templateSchedules: Partial<DeliveryBlockTemplateSchedule>[],
	startTime: string,
	endTime: string,
	teamsMap: Map<number, Team>
) => {
	return templateSchedules.map(schedule => {
		let until: Moment;
		const team = teamsMap.get(schedule.team_id);
		const timezoneOffset =
			moment()
				.tz(team?.time_zone || 'UTC')
				.utcOffset() / 60;
		const shouldAddDay = timezoneOffset < 0;

		const templateStartTime = moment.utc(startTime);
		const templateEndTime = moment.utc(endTime);

		const options = RRule.fromString(schedule.ical).origOptions;

		let updateIcalOptions = { ...options };

		if (schedule.termination_time.length) {
			until = moment.utc(schedule.termination_time).set({
				h: templateStartTime.hour(),
				m: templateStartTime.minute(),
				s: templateStartTime.second(),
				milliseconds: 0
			});

			updateIcalOptions = {
				...options,
				until: until
					.clone()
					.add(shouldAddDay ? 1 : 0, 'd')
					.toDate()
			};
		}

		schedule.ical = makeRRuleString(updateIcalOptions);

		schedule.start_time = moment
			.utc(schedule.start_time)
			.set({
				h: templateStartTime.hour(),
				m: templateStartTime.minute(),
				s: templateStartTime.second(),
				milliseconds: 0
			})
			.toISOString();

		schedule.end_time = moment
			.utc(schedule.start_time)
			.set({
				h: templateEndTime.hour(),
				m: templateEndTime.minute(),
				s: templateEndTime.second(),
				milliseconds: 0
			})
			.toISOString();

		schedule.termination_time = schedule.termination_time.length ? until.toISOString() : '';

		return schedule;
	});
};

export const templateSchedulesService = { getUpdatedSchedulesDates };
