import React from 'react';

import { getRootEnv } from '@bringg-frontend/bringg-web-infra';
import moment, { Moment } from 'moment';
import notification from '@bringg/react-components/dist/components/notification/notification';
import { Routes } from '@bringg/dashboard-sdk/dist/Reports/Service/reports.service';
import { BringgFontIcons, BringgIcon } from '@bringg/bringg-icons';

import './export-log.scss';

export interface ExportLogProps {
	exportValues: {
		changeTime: [Moment, Moment];
		sort?: string;
		configurationModels?: string[];
		changedBy?: string[];
	};
	disabled: boolean;
}

const ValuesArr = ({ values, name }: { values: string[]; name: string }) => (
	<>
		{values.map(value => (
			<input readOnly hidden value={value} key={value} name={name} />
		))}
	</>
);

const ExportLog = (exportLogProps: ExportLogProps): JSX.Element => {
	const token = getRootEnv().dashboardSdk.sdk.session.user.authentication_token.toString();
	const { protocol, host, port } = getRootEnv().dashboardSdk.sdk.reports.endpoint;
	const endpoint = `${protocol}//${host}:${port}${Routes.GET_CONFIGURATIONS_CSV}?auth_token=${token}`;

	const yesterday = moment().subtract(1, 'day');
	const today = moment();
	const {
		exportValues: {
			sort = 'desc',
			changeTime: [from, to] = [yesterday, today],
			configurationModels = [],
			changedBy = []
		}
	} = exportLogProps ?? {};
	const users = changedBy.concat();
	users[users.indexOf(null)] = 'BringgAdmins';

	return (
		<div id="export-file">
			<BringgIcon
				iconName={BringgFontIcons.Export}
				style={{ color: exportLogProps?.disabled ? '#a0aec0' : '#718096' }}
			/>
			<form method="post" action={endpoint} id="configuration-audit-export-button">
				<input readOnly hidden value={from.format('YYYYMMDD')} name="from" data-test-id="from" />
				<input readOnly hidden value={to.format('YYYYMMDD')} name="to" data-test-id="to" />
				<input readOnly hidden value={sort} name="sort" data-test-id="sort" />

				{configurationModels?.length > 0 && (
					<ValuesArr values={configurationModels} name="configurationModels" />
				)}
				{changedBy?.length > 0 && <ValuesArr values={users} name="changedBy" />}

				<input
					type="submit"
					disabled={exportLogProps?.disabled ?? true}
					value="Export Log"
					className="ant-btn ant-btn-link filter-bar-2-button-blank"
					onClick={() => notification.success('Download has started')}
				/>
			</form>
		</div>
	);
};

export default ExportLog;
