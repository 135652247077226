import React from 'react';
import { Radio, DatePicker } from 'antd';
import { LabelNumberInput, Switch } from '@bringg/react-components';
import { Options } from 'rrule';
import moment from 'moment';
import { RecurringRepeatLabels } from '../recurring.labels';

interface Props {
	options: Options;
	onOptionsChange: (options: Partial<Options>) => void;
	disabled: boolean;
	repeatLabels: RecurringRepeatLabels;
}

interface State {
	repeat: boolean;
	selectedRepeat: number;
}

export enum RepeatOptions {
	AFTER,
	ON
}

const UNTIL_DATE_FORMAT = 'ddd, MMM DD, YYYY';

class RecurringRepeat extends React.PureComponent<Props, State> {
	state = {
		repeat: false,
		selectedRepeat: RepeatOptions.AFTER
	};

	static getDerivedStateFromProps(nextProps) {
		if (nextProps.options.until) {
			return {
				selectedRepeat: RepeatOptions.ON
			};
		}

		if (nextProps.options.count) {
			return {
				selectedRepeat: RepeatOptions.AFTER
			};
		}

		return null;
	}

	onCountChange = count => this.props.onOptionsChange({ count });
	onUntilChange = until => this.props.onOptionsChange({ until: until.toDate() });

	removeRepeatOptions = () => this.props.onOptionsChange({ count: null, until: null });

	toggleNoRepeat = () =>
		this.setState(prevState => {
			if (prevState.repeat) {
				this.removeRepeatOptions();
			}

			return {
				repeat: !prevState.repeat
			};
		});

	onRepeatOptionChange = ({ target }) => {
		this.removeRepeatOptions();
		this.updateRepeatOption(target.value);
	};

	updateRepeatOption = selectedRepeat => this.setState({ selectedRepeat });

	render() {
		const { toggleNoRepeat, onRepeatOptionChange, onCountChange, onUntilChange } = this;
		const { options, disabled, repeatLabels } = this.props;
		const { repeat, selectedRepeat } = this.state;

		const repeatSwitchDisabled = disabled;
		const radioGroupDisabled = disabled || !repeat;
		const afterDisabled = disabled || !repeat || selectedRepeat !== RepeatOptions.AFTER;
		const onDisabled = disabled || !repeat || selectedRepeat !== RepeatOptions.ON;

		const { count, until } = options;

		return (
			<div className="recurring-repeat">
				<div className="repeat-item">
					<label>{repeatLabels.endRepeatLabel}:</label>
					<Switch checked={repeat} onChange={toggleNoRepeat} disabled={repeatSwitchDisabled} />
				</div>
				<Radio.Group
					value={selectedRepeat}
					disabled={radioGroupDisabled}
					onChange={onRepeatOptionChange}
					className="recurring-repeat-group"
				>
					<div className="repeat-item">
						<Radio value={RepeatOptions.AFTER}>{repeatLabels.afterLabel}:</Radio>
						<LabelNumberInput
							label={repeatLabels.timesLabel}
							disabled={afterDisabled}
							value={count}
							minValue={0}
							onChange={onCountChange}
						/>
					</div>
					<div className="repeat-item">
						<Radio value={RepeatOptions.ON}>{repeatLabels.onLabel}:</Radio>
						<DatePicker
							className={`${onDisabled ? 'disabled' : ''}`}
							value={until ? moment(until) : null}
							format={UNTIL_DATE_FORMAT}
							disabled={onDisabled}
							onChange={onUntilChange}
						/>
					</div>
				</Radio.Group>
			</div>
		);
	}
}

export default RecurringRepeat;
