export function compare<T>(value1: T, value2: T) {
	if (isNullOrUndefined(value1) && !isNullOrUndefined(value2)) {
		return 1;
	}

	if (!isNullOrUndefined(value1) && isNullOrUndefined(value2)) {
		return -1;
	}

	if (isNullOrUndefined(value1) && isNullOrUndefined(value2)) {
		return 0;
	}

	if (typeof value1 !== typeof value2) {
		throw new Error(`compare type mismatch: comparing ${typeof value1} against ${typeof value2}`);
	}

	if (typeof value1 === 'string') {
		return stringCompare(value1, value2 as string);
	} else if (typeof value1 === 'number') {
		return numberCompare(value1, value2 as number);
	} else if (typeof value1 === 'boolean') {
		return booleanCompare(value1, value2 as boolean);
	}
}

function numberCompare(value1: number, value2: number) {
	return value1 - value2;
}

function stringCompare(value1: string, value2: string) {
	return value1.localeCompare(value2);
}

function booleanCompare(value1: boolean, value2: boolean) {
	return value1 === value2 ? 0 : value1 ? 1 : -1;
}

function isNullOrUndefined(value: any) {
	return value === null || value === undefined;
}

export const comparer = { compare };
