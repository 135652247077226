export enum PartialRunsAction {
	NONE,
	SELECT_ALL_IN_RUN,
	REMOVE_SELECTED_FROM_RUN
}

export enum PartialRunsSource {
	MOVE_FROM_PLANNING_TO_DISPATCH,
	MOVE_FROM_DISPATCH_TO_PLANNING,
	OPEN_PREVIEW
}
