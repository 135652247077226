import { useTranslationKeys } from '@bringg-frontend/utils';

export const translationKeys = {
	uploadTitle: 'ORDERS_CSV.UPLOAD_TITLE',
	uploadDescription: 'ORDERS_CSV.UPLOAD_DESCRIPTION',
	mappingDescription: 'ORDERS_CSV.MAPPING_DESCRIPTION',
	uploadFailed: 'TASKS_CSV_UPLOADER.ERROR_MESSAGE',
	uploadSuccess: 'TASKS_CSV_UPLOADER.SUCCESS_MESSAGE',
	templateName: 'ORDERS_CSV.TEMPLATE_NAME',
	status: 'ORDERS_CSV.UPLOAD_TITLE',
	originalField: 'ORDERS_CSV.ORIGINAL_FIELD',
	search: 'GLOBAL.SEARCH',
	approveAndGenerate: 'ORDERS_CSV.APPROVE_AND_GENERATE',
	matched: 'ORDERS_CSV.MATCHED',
	mandatory: 'ORDERS_CSV.MANDATORY_FIELDS',
	mandatoryDescription: 'ORDERS_CSV.MANDATORY_FIELDS_DESCRIPTION',
	progressTitle: 'ORDERS_CSV.UPLOAD_TITLE',
	downloadErrorsFile: 'ORDERS_CSV.DOWNLOAD_ERRORS_FILE',
	parsingFile: 'ORDERS_CSV.PARSING_FILE',
	failedToFetchStatus: 'ORDERS_CSV.FAILED_TO_FETCH_STATUS',
	keepMapping: 'ORDERS_CSV.KEEP_MAPPING',
	learnMore: 'ORDERS_CSV.LEARN_MORE',
	finish: 'GLOBAL.FINISH',
	close: 'GLOBAL.CLOSE',
	downloadAndClose: 'ORDERS_CSV.DOWNLOAD_AND_CLOSE',
	confirmNonAssignedTasks: 'ORDERS_CSV.CONFIRM_NON_ASSIGNED_TASKS',
	confirmNonAssignedTasksDescription: 'ORDERS_CSV.CONFIRM_NON_ASSIGNED_TASKS_DESCRIPTION',
	failedToParseFile: 'ORDERS_CSV.FAILED_TO_PARSE_FILE'
};

export function useCSVImportTranslation() {
	return useTranslationKeys<typeof translationKeys>(translationKeys);
}
