import { action, computed, makeObservable, observable, override } from 'mobx';
import i18next from 'i18next';
import { ArrayType, ConditionProperties, RuleCompareOperator } from '@bringg/types';
import { ConditionType } from '@bringg/react-components';
import { is, isNot } from '../utils/use-automation-translations';
import { ConditionBaseStore, RuleStore } from './internal';
import { ArrayConditionValue, ArrayConditionValueInnerValue, TriggerValues } from '../utils/types';
import { operatorsWithNoValue } from '../utils/use-options';

class ArrayConditionStore extends ConditionBaseStore {
	_value: ArrayConditionValue;
	operator: RuleCompareOperator;
	parent: RuleStore;

	constructor(parent: RuleStore, condition?: Partial<ConditionProperties>, guid?: number) {
		super(condition);

		makeObservable(this, {
			_value: observable,
			operator: observable,
			setArrayValueType: action,
			arrayValueType: computed,
			value: computed,
			setFromValue: action,
			setToValue: action,
			addEmptyArrayValue: action,
			setPath: action,
			setValue: action,
			setOperator: action,
			isValid: computed,
			displayPath: override,
			displayValue: override
		});

		if (guid) {
			this.guid = guid;
		}

		this.parent = parent;
		this.operator = condition?.operator as RuleCompareOperator;

		this._value = condition?.value || {
			type: ArrayType.ANY,
			value: this.conditionMetadata?.type === ConditionType.STRING_ARRAY ? [''] : undefined
		};
	}

	setValue = (newValue: string | ArrayConditionValueInnerValue, index?: number) => {
		if (index != null) {
			(this._value as any).value[index] = newValue;
		} else {
			(this._value as ArrayConditionValue).value = newValue as number;
		}
	};

	setFromValue = fromValue => {
		this._value.value = [fromValue, this._value.value[1]];
	};

	setToValue = toValue => {
		this._value.value = [this._value.value[0], toValue];
	};

	setPath = newPath => {
		this._path = newPath;
		this.parent.setAndRuleInstance(this);
	};

	setArrayValueType = type => {
		(this._value as ArrayConditionValue).type = type;
	};

	addEmptyArrayValue = () => {
		// eslint-disable-next-line no-unused-expressions
		((this._value as ArrayConditionValue)?.value as string[])?.push('');
	};

	get arrayValueType(): ArrayType {
		return (this._value as ArrayConditionValue)?.type;
	}

	setOperator = (newOperator, shouldResetValue = true) => {
		this.operator = newOperator;

		if (operatorsWithNoValue.includes(this.operator)) {
			this.setValue(this.conditionMetadata?.type === ConditionType.STRING_ARRAY ? [''] : null);
			return;
		}

		if (!shouldResetValue) return;

		if (newOperator === RuleCompareOperator.ARRAY_ELEMENT_IN_RANGE) {
			this._value.value = Array(2);
		} else {
			this._value = {
				type: this.arrayValueType,
				value: undefined
			};
		}
	};

	get isValid() {
		if (!this.path) {
			return false;
		}
		switch (this.conditionMetadata?.type) {
			case ConditionType.STRING_ARRAY:
				return (
					!!this.operator &&
					!!this.arrayValueType &&
					(operatorsWithNoValue.includes(this.operator) ||
						((this.value as string[])?.length > 0 && (this.value as string[]).every(value => !!value)))
				);
			case ConditionType.NUMBER_ARRAY:
				if (this.conditionMetadata.options) {
					return (this.value as number[])?.length > 0;
				}
				if (!this.operator || !this.arrayValueType) {
					return false;
				}
				if (this.operator === RuleCompareOperator.ARRAY_ELEMENT_IN_RANGE) {
					return this.value[0] != null && this.value[1] != null;
				}
				if (operatorsWithNoValue.includes(this.operator)) {
					return true;
				}
				return this.value != null;
			case ConditionType.BOOLEAN_ARRAY:
				return !!this.arrayValueType && (this.value as boolean[])?.length > 0 && this.value[0] != null;
			default:
				return true;
		}
	}

	get value() {
		return this._value?.value;
	}

	get displayPath(): string {
		return this.conditionMetadata?.title || this.path;
	}

	get displayValue(): string | string[] {
		if (this.conditionMetadata.options) {
			return ((this.value as TriggerValues) || []).map(
				value => this.conditionMetadata.options.find(v => v.key === value)?.value
			);
		}

		if (this.conditionMetadata.type === ConditionType.BOOLEAN_ARRAY) {
			return i18next.t(this.value && this.value[0] === true ? is : isNot) as string;
		}

		return '';
	}
}

export default ArrayConditionStore;
