import React from 'react';

import { BringgInput } from '@bringg/react-components';
import { BringgFontIcons, BringgIcon } from '@bringg/bringg-icons';

import styles from './search-bar.module.scss';

interface Props {
	setSearchString: (value: React.SetStateAction<string>) => void;
	value: string;
	placeholder?: string;
}

const SearchBar = ({ value, setSearchString, placeholder }: Props) => {
	return (
		<div className={styles.searchBar}>
			<BringgInput
				role="search"
				allowClear
				autoFocus
				placeholder={placeholder}
				onChange={e => setSearchString(e.target.value)}
				value={value}
				prefix={<BringgIcon iconName={BringgFontIcons.Search} />}
				data-test-id="draggable-list-selector-search-input"
			/>
		</div>
	);
};

export default SearchBar;
