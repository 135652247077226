import _includes from 'lodash/includes';
import _find from 'lodash/find';

import type { GanttTimeLineItem } from '../../gantt-types';
import { BREAK_ITEM, CLUSTER_ITEM, TIMELINE_GROUP_ITEM, TIMELINE_ITEM_PREFIX } from '../timeline-items/consts';

export const getTimelineItemElement = (event: MouseEvent): HTMLDivElement => {
	let target = event.target as HTMLDivElement;

	if (!target.hasAttribute(TIMELINE_GROUP_ITEM)) {
		while (target.parentNode) {
			target = target.parentNode as HTMLDivElement;
			if (target.hasAttribute(TIMELINE_GROUP_ITEM)) {
				return target;
			}
		}
	}

	return event.target as HTMLDivElement;
};

export const isElementCluster = (element: HTMLDivElement): boolean => {
	return _includes(element.classList, CLUSTER_ITEM);
};

export const isElementBreak = (element: HTMLDivElement): boolean => {
	return _includes(element.classList, BREAK_ITEM);
};

export const getTimelineItemsForClusterElement = (
	element: HTMLDivElement,
	items: GanttTimeLineItem[]
): GanttTimeLineItem[] => {
	const { classList } = element;
	const result: GanttTimeLineItem[] = [];

	classList.forEach(className => {
		if (className.startsWith(TIMELINE_ITEM_PREFIX)) {
			const timelineItemId = className.split(TIMELINE_ITEM_PREFIX)[1];
			const timelineItem = _find(items, item => item.id.toString() === timelineItemId);

			if (timelineItem) {
				result.push(timelineItem);
			}
		}
	});

	return result;
};
