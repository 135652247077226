import _reduce from 'lodash/reduce';
import TeamsStore from '../../../../../stores/teams/teams-store';

export type TeamOptions = {
	[teamId: string]: { teamName: string };
};

export const initTeamsList = async (teamsStore: TeamsStore, setParentTeamOptions: (options: TeamOptions) => void) => {
	const teams = await teamsStore.fetchAll();
	const options = _reduce(
		teams,
		(acc, team) => {
			acc[team.id] = team.name;
			return acc;
		},
		{}
	);
	setParentTeamOptions(options);
};
