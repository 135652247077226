import React from 'react';
import { Row, Checkbox } from 'antd';
import { UserType } from '@bringg/types';
import { Table, Link } from '../../../components';
import { Translate } from '../../../translation';

interface Props {
	userTypes: Partial<UserType>[];
	count?: number;
}

const TABLE_SIZE = 'small';

const UserTypesTable = (props: Props) => {
	const { userTypes } = props;
	const getColumns = () => {
		return [
			{
				sortDirections: ['descend', 'ascend'],
				title: <Translate text="USER_TYPE.ID" />,
				dataIndex: 'id',
				sorter: (a, b) => a.id - b.id,
				render: (id: number) => {
					return (
						<span data-test-id="roleId">
							{id === 0 ? <>{id}</> : <Link to={`/drivers/user-types/${id}`}>{id}</Link>}
						</span>
					);
				}
			},
			{
				sortDirections: ['descend', 'ascend'],
				title: <Translate text="USER_TYPE.TITLE" />,
				sorter: (a, b) => a.title.localeCompare(b.title),
				dataIndex: 'title',
				render: (title, userType) => {
					return (
						<span data-test-id="roleTitle">
							{userType.id === 0 ? (
								<>{title}</>
							) : (
								<Link to={`/drivers/user-types/${userType.id}`}>{title}</Link>
							)}
						</span>
					);
				}
			},
			{
				sortDirections: ['descend', 'ascend'],
				title: <Translate text="USER_TYPE.DEFAULT_FOR_MERCHANT" />,
				dataIndex: 'default_for_merchant',
				render: (text, userType) => {
					const checked = userType.default_for_merchant;
					return <Checkbox checked={checked} disabled data-test-id="defaultForMerchant" />;
				}
			},
			{
				sortDirections: ['descend', 'ascend'],
				title: <Translate text="USER_TYPE.MINIMUM_TASKS_PER_SHIFT" />,
				sorter: (a, b) => a.minimum_tasks_per_shift - b.minimum_tasks_per_shift,
				dataIndex: 'minimum_tasks_per_shift',
				render: text => {
					return <span data-test-id="minimumTasksPerShift">{text}</span>;
				}
			},
			{
				sortDirections: ['descend', 'ascend'],
				title: <Translate text="USER_TYPE.MAXIMUM_TASKS_PER_SHIFT" />,
				sorter: (a, b) => a.maximum_tasks_per_shift - b.maximum_tasks_per_shift,
				dataIndex: 'maximum_tasks_per_shift',
				render: text => {
					return <span data-test-id="maximumTasksPerShift">{text}</span>;
				}
			},
			{
				sortDirections: ['descend', 'ascend'],
				title: <Translate text="USER_TYPE.COST_PER_TASK" />,
				sorter: (a, b) => a.cost_per_task - b.cost_per_task,
				dataIndex: 'cost_per_task',
				render: text => {
					return <span data-test-id="costPerTask">{text}</span>;
				}
			},
			{
				sortDirections: ['descend', 'ascend'],
				title: <Translate text="USER_TYPE.COST_PER_SHIFT" />,
				sorter: (a, b) => a.cost_per_shift - b.cost_per_shift,
				dataIndex: 'cost_per_shift',
				render: text => {
					return <span data-test-id="costPerShift">{text}</span>;
				}
			},
			{
				sortDirections: ['descend', 'ascend'],
				title: <Translate text="USER_TYPE.SPEED_FACTOR" />,
				sorter: (a, b) => a.speed_factor - b.speed_factor,
				dataIndex: 'speed_factor',
				render: text => {
					return <span data-test-id="speedFactor">{text}</span>;
				}
			},
			{
				sortDirections: ['descend', 'ascend'],
				title: <Translate text="USER_TYPE.CROWD" />,
				dataIndex: 'is_crowd',
				render: (text, userType) => {
					return <Checkbox checked={userType.is_crowd} disabled data-test-id="isCrowd" />;
				}
			}
		];
	};

	return (
		<div className="user-types-table-container">
			{userTypes.length ? (
				<Row>
					<Table
						className="user-types-table"
						columns={getColumns() as any}
						dataSource={userTypes}
						pagination={false}
						rowKey="id"
						size={TABLE_SIZE}
					/>
				</Row>
			) : null}
			<div className="total-count">
				<Translate text="USER_TYPE.TOTAL_COUNT" />: {props.count}
			</div>
		</div>
	);
};

export default UserTypesTable;
