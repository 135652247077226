import { Modal } from '@bringg/react-components';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Radio } from 'antd';
import { Translate } from '../../../translation';
import { PartialRunsAction, PartialRunsSource } from './partial-runs-consts';
import './partial-runs-actions-modal.scss';

export interface Props {
	onResponse: (partialRunsAction: PartialRunsAction) => void;
	partialRunsSource: PartialRunsSource;
}

const PartialRunsActionsModal: React.FC<Props> = ({ onResponse, partialRunsSource }: Props) => {
	const { t } = useTranslation();
	const [selectedAction, setSelectedAction] = useState<PartialRunsAction>(PartialRunsAction.SELECT_ALL_IN_RUN);

	const textsBySource = {
		[PartialRunsSource.MOVE_FROM_DISPATCH_TO_PLANNING]: {
			description: 'RUN_PARTIAL_ACTIONS.TO_PLANNING_DESCRIPTION',
			selectAll: 'RUN_PARTIAL_ACTIONS.TO_PLANNING_SELECT_ALL_IN_RUN',
			removeSelectedFromRun: 'RUN_PARTIAL_ACTIONS.TO_PLANNING_REMOVE_SELECTED_FROM_RUN',
			okText: '',
			cancelText: ''
		},
		[PartialRunsSource.MOVE_FROM_PLANNING_TO_DISPATCH]: {
			description: 'RUN_PARTIAL_ACTIONS.TO_DISPATCH_DESCRIPTION',
			selectAll: 'RUN_PARTIAL_ACTIONS.TO_DISPATCH_SELECT_ALL_IN_RUN',
			removeSelectedFromRun: 'RUN_PARTIAL_ACTIONS.TO_DISPATCH_REMOVE_SELECTED_FROM_RUN',
			okText: '',
			cancelText: ''
		},
		[PartialRunsSource.OPEN_PREVIEW]: {
			description: 'RUN_PARTIAL_ACTIONS.OPEN_PREVIEW_DESCRIPTION',
			okText: 'RUN_PARTIAL_ACTIONS.OPEN_PREVIEW_OK',
			cancelText: 'RUN_PARTIAL_ACTIONS.OPEN_PREVIEW_CANCEL'
		}
	};

	return (
		<Modal
			visible
			className="partial-runs-actions-modal"
			okText={t(textsBySource[partialRunsSource].okText || 'RUN_PARTIAL_ACTIONS.OK')}
			cancelText={t(textsBySource[partialRunsSource].cancelText || 'RUN_PARTIAL_ACTIONS.CANCEL')}
			onOk={() => onResponse(selectedAction)}
			onCancel={() => onResponse(PartialRunsAction.NONE)}
		>
			<div className="partial-runs-actions-description">
				<span>{t(textsBySource[partialRunsSource].description)}</span>
			</div>

			{partialRunsSource !== PartialRunsSource.OPEN_PREVIEW && (
				<Radio.Group
					className="run-actions"
					value={selectedAction}
					onChange={e => setSelectedAction(e.target.value)}
				>
					<Radio value={PartialRunsAction.SELECT_ALL_IN_RUN}>
						<Translate text={textsBySource[partialRunsSource].selectAll} />
					</Radio>
					<Radio value={PartialRunsAction.REMOVE_SELECTED_FROM_RUN}>
						<Translate text={textsBySource[partialRunsSource].removeSelectedFromRun} />
					</Radio>
				</Radio.Group>
			)}
		</Modal>
	);
};

export default PartialRunsActionsModal;
