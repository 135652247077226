import React, { FunctionComponent } from 'react';
import { Select, Option } from '../../../../components';
import { RUN_HISTORY_TYPES } from '../../run.consts';
import { Translate } from '../../../../translation';
import { IFilterOption } from '../../../../types/common.consts';

interface Props {
	onSelect: (value: number) => void;
	selectedType: RUN_HISTORY_TYPES;
}

const RunHistoryFilter: FunctionComponent<Props> = (props: Props) => {
	const options: IFilterOption<RUN_HISTORY_TYPES>[] = [
		{
			value: RUN_HISTORY_TYPES.ALL_EVENTS,
			title: 'RUN.HISTORY_FILTER_ALL_EVENTS'
		},
		{
			value: RUN_HISTORY_TYPES.ALERT,
			title: 'RUN.HISTORY_FILTER_ALERTS'
		},
		{
			value: RUN_HISTORY_TYPES.HOME_EVENT,
			title: 'RUN.HISTORY_FILTER_HOME_EVENTS'
		},
		{
			value: RUN_HISTORY_TYPES.OFFLINE_ONLINE_EVENT,
			title: 'RUN.HISTORY_FILTER_OFFLINE_ONLINE_EVENTS'
		},
		{
			value: RUN_HISTORY_TYPES.SHIFT,
			title: 'RUN.HISTORY_FILTER_SHIFTS'
		},
		{
			value: RUN_HISTORY_TYPES.TASK_HISTORY,
			title: 'RUN.HISTORY_FILTER_TASK_HISTORIES'
		}
	];

	return (
		<div className="history-filter">
			<Select
				onSelect={props.onSelect}
				value={props.selectedType}
				placeholder={<Translate text={'RUN.HISTORY_FILTER_PLACEHOLDER'} />}
			>
				{options.map(option => (
					<Option key={option.value.toString()} value={option.value}>
						<Translate text={option.title} />
					</Option>
				))}
			</Select>
		</div>
	);
};

export default RunHistoryFilter;
