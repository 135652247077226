import React from 'react';
import { Row } from 'antd';
import { VehicleChange, Driver } from '@bringg/types';
import AssignmentsTable from '../assignments-table/assignments-table';
import Translate from '../../../../translation/translator';
import AssignmentsFilter from '../assignments-filter/assignments-filter';

interface Props {
	assignments: VehicleChange[];
	optionalDrivers?: Driver[];
	onRowClicked?: (record: any, index: number) => React.HTMLAttributes<HTMLElement>;
}

interface State {
	filteredAssignments: VehicleChange[];
}

export default class AssignmentsContainer extends React.Component<Props, State> {
	state = {
		filteredAssignments: this.props.assignments
	};

	onAssignmentsFiltered = (filteredAssignments: VehicleChange[]): void => {
		this.setState({ filteredAssignments });
	};

	public render() {
		return (
			<div className="assignments">
				<Row>
					<span className="assignments-title">
						<Translate text="VEHICLE.TITLE" />
					</span>
				</Row>
				<Row>
					<AssignmentsFilter
						assignments={this.props.assignments}
						optionalDrivers={this.props.optionalDrivers}
						onFiltered={this.onAssignmentsFiltered}
					/>
				</Row>
				<hr />
				<Row>
					<AssignmentsTable
						assignments={this.state.filteredAssignments}
						onRowClicked={this.props.onRowClicked}
						optionalDrivers={this.props.optionalDrivers}
					/>
				</Row>
			</div>
		);
	}
}
