import { getRootEnv } from '@bringg-frontend/bringg-web-infra';
import { action, makeObservable } from 'mobx';
import { getEnv } from 'mobx-easy';
import { RootEnv } from '@bringg-frontend/bringg-web-infra';

import ActionsConfiguration, { normalizeActionsConfiguration } from './actions-configuration';
import {
	ActionsConfigurationLevel,
	ActionsConfiguration as ActionsConfigurationEntity
} from '../../../types/common.consts';

export default class TaskConfiguration extends ActionsConfiguration {
	level = ActionsConfigurationLevel.ORDER;

	constructor(actionsConfiguration: ActionsConfigurationEntity) {
		super(actionsConfiguration);

		makeObservable(this, {
			delete: action
		});
	}

	update = () => {
		const updateParams = {
			title: this.title,
			action_data: normalizeActionsConfiguration(this.action_data, this.level)
		};
		return getRootEnv().dashboardSdk.sdk.taskConfigurations.update(this.id, updateParams);
	};

	delete = () => {
		return getRootEnv().dashboardSdk.sdk.taskConfigurations.update(this.id, { action_data: [] });
	};
}
