import React, { useState, useCallback, useMemo } from 'react';
import { Modal, Divider, Button } from 'antd';
import { useTranslation } from 'react-i18next';

import { ConfigurationAudit } from '@bringg/types/types/configuration_audit';
import ChangesDiff from './changes-diff-popup/changes-diff';

import './changes-diff-popup.scss';

const ChangesDiffPopup = ({ row }: { row: ConfigurationAudit }) => {
	const [isVisible, setIsVisible] = useState(false);
	const { t } = useTranslation();

	const onClick = useCallback(() => setIsVisible(!isVisible), []);
	const hideModal = useCallback(() => setIsVisible(false), []);
	const changes = useMemo(() => Object.entries(row.changes).map(ChangesDiff), [row]);

	return (
		<>
			<Button className="see-details" type="link" onClick={onClick}>
				{t('CONFIGURATION_AUDIT.DETAILS')}
			</Button>
			<Modal
				className="changes-diff-modal"
				width="fit-content"
				visible={isVisible}
				onOk={hideModal}
				cancelButtonProps={{ className: 'changes-diff-cancel-button' }}
				onCancel={hideModal}
			>
				<h2> {t('CONFIGURATION_AUDIT.DATA_CHANGES')}</h2>
				<Divider />
				{changes}
			</Modal>
		</>
	);
};

export default ChangesDiffPopup;
