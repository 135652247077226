import React from 'react';
import { useTranslation } from 'react-i18next';

const EmptyTableData = (isLoading: boolean, t: typeof useTranslation) => {
	if (isLoading) {
		return null;
	}

	return (
		<div className="vehicle-profiles--empty-table-text">
			<div>{t('VEHICLE_PROFILES.TABLE.NO_DATA')}</div>
			<div>{t('VEHICLE_PROFILES.TABLE.NO_DATA_HINT')}</div>
		</div>
	);
};

export default EmptyTableData;
