import { useTranslationKeys } from '@bringg-frontend/utils';

export const translationKeys = {
	uploadTitle: 'SERVICE_AREA.UPLOAD_TITLE',
	uploadDescription: 'SERVICE_AREA.UPLOAD_DESCRIPTION',
	upload: 'GLOBAL.UPLOAD',
	uploadFailed: 'SERVICE_AREA.UPLOAD_FAILED',
	templateName: 'SERVICE_AREA.TEMPLATE_NAME',
	progressTitle: 'SERVICE_AREA.PROGRESS_TITLE',
	downloadErrorsFile: 'SERVICE_AREA.DOWNLOAD_ERRORS_FILE',
	parsingFile: 'SERVICE_AREA.PARSING_FILE',
	failedToFetchStatus: 'SERVICE_AREA.FAILED_TO_FETCH_STATUS',
	failedToCreate: 'SERVICE_AREA.FAILED_TO_CREATE',
	successfullyUpdated: 'SERVICE_AREA.SUCCESSFULLY_UPDATED',
	successfullyCreated: 'SERVICE_AREA.SUCCESSFULLY_CREATED',
	title: 'SERVICE_AREA.TITLE',
	mapToolsDescription: 'SERVICE_AREA.MAP_TOOLS_DESCRIPTION'
};

export function useServiceAreaTranslation() {
	return useTranslationKeys<typeof translationKeys>(translationKeys);
}
