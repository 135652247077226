import { useCallback, useEffect, useMemo, useState } from 'react';

import { getRootEnv } from '@bringg-frontend/bringg-web-infra';
import { debounce } from 'lodash';
import { useQuery } from 'react-query';
import { Space, Table } from '@bringg/react-components';
import { OrderHistoryFiltersType, PrivilegeTypes, ResizableDataField } from '@bringg/types';
import { BringgDashboardSDK } from '@bringg/dashboard-sdk';
import { usePrevious } from '@bringg-frontend/hooks';

import {
	columnsToDataFields,
	dataFieldsToColumns
} from 'bringg-web/features/order-history-page/utils/data-field-to-columns';
import notification from 'bringg-web/services/notification';
import { useStores } from 'bringg-web/recipes';
import { useHistoryTranslation } from 'bringg-web/features/order-history-page/translations';
import { useHasAccess } from 'bringg-web/utils/privileges';
import { getColumns } from './columns';
import ColumnsPicker from './columns-picker';
import Pagination from './pagination';
import { DEFAULT_DATE_FORMAT } from 'bringg-web/consts';

const FIRST_PAGE = 1;
const LIMIT = 25;
const SHORT_STALE_TIME = 1_000 * 60 * 2;
const LONG_STALE_TIME = 1_000 * 60 * 10;
const DEBOUNCE_INTERVAL = 3_000;

const fetchTasks = async (params: OrderHistoryFiltersType, page: number, limit: number) => {
	return getRootEnv().dashboardSdk.sdk.tasks.getHistoryTasks(params, page, limit);
};

const debounceSaveDashboardUI = debounce(
	async (
		sdk: BringgDashboardSDK,
		orders_history_fields: Partial<ResizableDataField>[],
		callback: () => void,
		isAdmin: boolean
	) => {
		if (isAdmin) {
			await sdk.merchantConfiguration.setDashboardUiConfigurationOnMerchantLevel({ orders_history_fields });
		} else {
			await sdk.merchantConfiguration.dashboardTableConfigurationUpdate({ orders_history_fields });
		}
		callback();
	},
	DEBOUNCE_INTERVAL
);

const dateTimeFormat = (hourStringFormat: string): string => {
	return `${DEFAULT_DATE_FORMAT}, ${hourStringFormat}`;
};

const HistoryTable = ({ filters }: { filters: OrderHistoryFiltersType }) => {
	const translations = useHistoryTranslation();

	const { dashboardSdk } = getRootEnv();
	const {
		merchantConfigurationsStore,
		usersStore: { currentUser }
	} = useStores();

	const defaultColumns = useMemo(() => {
		const hourStringFormat = merchantConfigurationsStore.hourStringFormat;
		return getColumns(translations, dateTimeFormat(hourStringFormat), hourStringFormat);
	}, [translations]);
	const [columns, setColumns] = useState(defaultColumns);
	const [page, setPage] = useState(FIRST_PAGE);
	const [limit, setLimit] = useState(LIMIT);

	const prevFilters = usePrevious(filters);
	const prevLimit = usePrevious(limit);

	const canCustomizeLayout = useHasAccess(PrivilegeTypes.CUSTOMIZE_LIST_LAYOUT);

	const enableFetching = () => {
		if (page === FIRST_PAGE) return true;
		if (prevLimit && limit === prevLimit && prevFilters && filters === prevFilters) return true;

		return false;
	};

	const { isLoading, data, error } = useQuery(
		['historyTasks', filters, page, limit],
		async () => {
			if (filters.late) {
				const converted = filters.late.map((val: number | boolean) => !!val);

				filters.late = converted;
			}

			return fetchTasks(filters, page, limit).then((response: any) => response);
		},
		{
			refetchOnWindowFocus: true,
			staleTime: page === FIRST_PAGE ? SHORT_STALE_TIME : LONG_STALE_TIME,
			enabled: enableFetching()
		}
	);

	useEffect(() => {
		if (page > FIRST_PAGE) setPage(FIRST_PAGE);
	}, [filters, limit]);

	useEffect(() => {
		if (!error) {
			return;
		}

		notification.error(translations.errorFetchHistory);
	}, [error, translations]);

	useEffect(() => {
		if (!merchantConfigurationsStore.ordersHistoryFields) {
			return;
		}

		setColumns(dataFieldsToColumns(merchantConfigurationsStore.ordersHistoryFields, defaultColumns));
	}, [merchantConfigurationsStore.ordersHistoryFields, defaultColumns]);

	const handleColumnsChange = useCallback(
		async newColumns => {
			setColumns(newColumns);

			try {
				await debounceSaveDashboardUI(
					dashboardSdk.sdk,
					columnsToDataFields(newColumns),
					merchantConfigurationsStore.forceFetch,
					currentUser.admin
				);
			} catch (ex) {
				console.error(ex);
				notification.error(translations.errorSaveColumns);
			}
		},
		[merchantConfigurationsStore.forceFetch, translations, dashboardSdk]
	);

	const handleDefaultClick = useCallback(() => {
		handleColumnsChange([...defaultColumns]);

		return {
			selectedValues: defaultColumns.filter(c => c.active),
			unselectedValues: defaultColumns.filter(c => !c.active)
		};
	}, [defaultColumns, handleColumnsChange]);

	const activeColumns = useMemo(() => {
		return columns.filter(column => column.active);
	}, [columns]);

	return (
		<Space direction="vertical" style={{ width: '100%' }}>
			{canCustomizeLayout && (
				<Space style={{ display: 'flex', justifyContent: 'end' }}>
					<ColumnsPicker onDefault={handleDefaultClick} columns={columns} onChange={handleColumnsChange} />
				</Space>
			)}
			<Table
				rowKey="id"
				loading={isLoading}
				columns={activeColumns}
				dataSource={data?.items || []}
				scroll={{ x: true }}
			/>
			<Pagination
				page={page}
				limit={limit}
				filters={filters}
				loading={isLoading}
				nextPage={!data?.next_page}
				onChange={setPage}
				onSelect={setLimit}
			/>
		</Space>
	);
};

export default HistoryTable;
