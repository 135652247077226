import React from 'react';

interface CheckProps {
	active: boolean;
}

export const Check = ({ active }: CheckProps) =>
	active ? (
		<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M18 9.567 10.272 17 6 12.892l1.63-1.567 2.642 2.54L16.37 8 18 9.567z"
				fill="#00C364"
			/>
		</svg>
	) : (
		<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M18 7.4 16.6 6 12 10.6 7.4 6 6 7.4l4.6 4.6L6 16.6 7.4 18l4.6-4.6 4.6 4.6 1.4-1.4-4.6-4.6L18 7.4z"
				fill="#E64B38"
			/>
		</svg>
	);
