import React, { useCallback, useMemo, useState } from 'react';
// eslint-disable-next-line no-restricted-imports
import { Form } from 'antd';
import { isEmpty } from 'lodash';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'react-i18next';
import { Modal, Button, BringgInput as Input, Select, Divider } from '@bringg/react-components';
import { Tag, TaskConfiguration, ServicePlan } from '@bringg/types';
import { ServicePlanEntity } from '@bringg/dashboard-sdk/dist/ServicePlans/ServicePlansApi';

import './service-plan-form.scss';
import { Rule } from 'antd/lib/form';

interface FooterParams {
	onCancel: () => void;
	onSave: () => Promise<void>;
	onCreateAndSave: () => Promise<void>;
	isCreateForm: boolean;
	disabled: boolean;
	t: TFunction;
}

export type ModalProps = {
	servicePlan: ServicePlan | null;
	setServicePlan: (arg: null) => void;
	names: string[];
	externalIds: string[];
	taskConfigurations: TaskConfiguration[];
	tags: Tag[];
	isOpen?: boolean;
	onCancel: () => void;
	onSubmit: (data: Partial<ServicePlanEntity & { id: number }>) => Promise<void>;
	setIsOpen: (value: React.SetStateAction<boolean>) => void;
};

const nullIfEmpty = data =>
	Object.fromEntries(Object.entries(data).map(([k, v]) => [k, v === '' || v === undefined ? null : v]));

const FooterButtons = ({ onCancel, onSave, onCreateAndSave, isCreateForm, disabled, t }: FooterParams) => (
	<>
		<Button
			type="link"
			className="service-plan-cancel-btn"
			data-test-id="cancelBtn"
			htmlType="button"
			onClick={onCancel}
		>
			{t('SERVICE_PLAN.MODAL.CANCEL')}
		</Button>
		{isCreateForm && (
			<Button
				disabled={disabled}
				className="service-plan-save-create-btn"
				htmlType="button"
				onClick={onCreateAndSave}
			>
				{t('SERVICE_PLAN.SAVE_AND_CREATE')}
			</Button>
		)}
		<Button
			type="primary"
			disabled={disabled}
			data-test-id="saveBtn"
			className="service-plan-save-btn"
			htmlType="button"
			onClick={onSave}
		>
			{t('SERVICE_PLAN.SAVE')}
		</Button>
	</>
);

export const ServiceModal = ({
	servicePlan,
	setServicePlan,
	names,
	externalIds,
	taskConfigurations,
	tags,
	isOpen = false,
	onCancel,
	onSubmit,
	setIsOpen
}: ModalProps) => {
	const isCreateForm = !servicePlan?.id;

	const { t } = useTranslation();
	const [form] = Form.useForm();
	const [disabled, setDisabled] = useState(true);
	const [submitting, setSubmitting] = useState(false);

	const submitModal = useCallback(async () => {
		const data = await form.validateFields();

		await onSubmit({
			...nullIfEmpty({ ...data, name: data?.name?.trimEnd(), external_id: data?.external_id?.trimEnd() }),
			id: servicePlan?.id
		});
	}, [onSubmit, form, servicePlan?.id]);

	const validateDropdown = async () => {
		try {
			await form.validateFields(['name', 'external_id']);
		} catch (err) {
			if ((err as { errorFields: [] })?.errorFields?.length) {
				return setDisabled(true);
			}
		}
		return setDisabled(false);
	};

	const serviceNameRule = useMemo<Rule[]>(() => {
		return [
			{ pattern: /^\w+|[\w-\s]+/, message: t('SERVICE_PLAN.VALIDATION.ALPHANUMERIC') },
			{
				async validator(_, value: string) {
					if (isEmpty(value?.trim())) {
						setDisabled(true);
						// eslint-disable-next-line prefer-promise-reject-errors
						return Promise.reject(t('SERVICE_PLAN.MANDATORY_NAME'));
					}
					if (names.includes(value) && value !== servicePlan?.name) {
						setDisabled(true);
						// eslint-disable-next-line prefer-promise-reject-errors
						return Promise.reject(t('SERVICE_PLAN.NAME_IN_USE'));
					}
					if (value === servicePlan?.name) {
						return Promise.resolve();
					}

					const externalIdErr = form.getFieldsError(['external_id']);
					if (externalIdErr[0].errors.length !== 0) {
						return Promise.resolve();
					}
					if (!isEmpty(value) && !submitting) {
						return setDisabled(false);
					}
					return undefined;
				}
			},
			{ required: true, message: '' }
		];
	}, [t, servicePlan?.name, form, submitting, names]);

	const externalIdRule = useMemo<Rule[]>(() => {
		return [
			{ message: t('SERVICE_PLAN.MIN_MAX_LENGTHS'), max: 255, min: 1 },
			{
				async validator(_, value: string) {
					if (value === servicePlan?.external_id) {
						return;
					}
					if (externalIds.includes(value)) {
						setDisabled(true);
						// eslint-disable-next-line
						return Promise.reject(t('SERVICE_PLAN.EXTERNAL_ID_IN_USE'));
					}

					const nameErr = form.getFieldsError(['name']);
					const nameTouched = form.isFieldTouched('name');

					if (nameErr[0].errors.length !== 0) {
						setDisabled(true);
						return;
					}
					if (isCreateForm && nameTouched && !submitting) {
						setDisabled(false);
					} else if (!isCreateForm && !submitting) {
						setDisabled(false);
					}
				}
			}
		];
	}, [t, externalIds, form, isCreateForm, servicePlan?.external_id, submitting]);

	const modalOnCancel = useCallback(() => {
		setDisabled(true);
		setSubmitting(false);
		onCancel();
	}, [setSubmitting, setDisabled, onCancel]);

	const modalOnSubmit = useCallback(
		async (keepOpenAfterSubmit: boolean) => {
			setSubmitting(true);
			setDisabled(true);
			await submitModal();
			setSubmitting(false);
			setIsOpen(keepOpenAfterSubmit);
		},
		[setSubmitting, setDisabled, submitModal, setIsOpen]
	);

	const onCreateAndSave = useCallback(async () => {
		await modalOnSubmit(true);
		form.resetFields();
	}, [modalOnSubmit, form]);

	const onSave = useCallback(async () => {
		await modalOnSubmit(false);
		setServicePlan(null);
	}, [modalOnSubmit, setServicePlan]);

	const handleValuesChange = useCallback(
		changedValues => {
			if (changedValues.name || changedValues.external_id) {
				const changedKey = Object.keys(changedValues)[0];
				form.setFieldValue(changedKey, changedValues[changedKey].trimStart());
			}
		},
		[form]
	);

	return (
		<div>
			<Modal
				className="service-plan-form"
				width="fit-content"
				destroyOnClose
				visible={isOpen}
				maskClosable={false}
				onCancel={modalOnCancel}
				footer={
					<FooterButtons
						onCancel={modalOnCancel}
						onSave={onSave}
						onCreateAndSave={onCreateAndSave}
						isCreateForm={isCreateForm}
						disabled={disabled}
						t={t}
					/>
				}
			>
				<h2 className="service-plan-header">
					{t(isCreateForm ? 'SERVICE_PLAN.CREATE' : 'SERVICE_PLAN.UPDATE')}
				</h2>
				{!isCreateForm && (
					<>
						{!isEmpty(servicePlan?.updated_at) && servicePlan?.updated_at === servicePlan?.created_at ? (
							<span className="sub-header">
								{t('SERVICE_PLAN.CREATED.AT')}:&nbsp;
								{`${moment(servicePlan?.created_at).format('MMM Do, YYYY')}`}
							</span>
						) : (
							<span className="sub-header">
								{t('SERVICE_PLAN.UPDATED.AT')}:&nbsp;
								{`${moment(servicePlan?.updated_at).format('MMM Do, YYYY')}`}
							</span>
						)}
					</>
				)}
				<Divider className="service-plan-modal-divider" />
				<Form
					form={form}
					layout="vertical"
					colon={false}
					className="service-plan-fields"
					autoComplete="off"
					onValuesChange={handleValuesChange}
					preserve={false}
				>
					<Form.Item
						label={t('SERVICE_PLAN.SERVICE.NAME')}
						name="name"
						initialValue={servicePlan?.name}
						rules={serviceNameRule}
					>
						<Input />
					</Form.Item>
					<Form.Item
						label={t('SERVICE_PLAN.EXTERNAL.ID')}
						name="external_id"
						rules={externalIdRule}
						initialValue={servicePlan?.external_id}
					>
						<Input value={servicePlan?.external_id} />
					</Form.Item>

					<Form.Item
						label={t('SERVICE_PLAN.TASK.CONFIGURATION.ID')}
						name="task_configuration_id"
						initialValue={servicePlan?.task_configuration_id}
					>
						<Select
							allowClear
							className="service-plan-select-input"
							placeholder={t('SERVICE_PLAN.SELECT_FROM_LIST')}
							onChange={validateDropdown}
							options={taskConfigurations.map(({ title, id }) => ({ id, name: title }))}
						/>
					</Form.Item>

					<Form.Item label={t('SERVICE_PLAN.TAG')} name="tag_id" initialValue={servicePlan?.tag_id}>
						<Select
							allowClear
							className="service-plan-select-input"
							placeholder={t('SERVICE_PLAN.SELECT_FROM_LIST')}
							onChange={validateDropdown}
							options={tags.map(({ tag, id = '' }) => ({ id, name: tag }))}
						/>
					</Form.Item>
				</Form>
			</Modal>
		</div>
	);
};
