import moment from 'moment';
import { dateUtils } from '@bringg-frontend/utils';

import {
	DailyCutoff,
	RelativeCutoff,
	RelativeOptions
} from 'bringg-web/features/planned-delivery-windows/modals/slots-modal/wizard-steps/configuration-step/time-slots/cutoff-radio-group/cutoff.types';

const getDate = (startWindowTime: Date, cutoff: number, timezone: string) => {
	if (cutoff < 0) {
		return moment(startWindowTime)
			.tz(timezone)
			.add(cutoff * -1, 'm');
	}
	return moment(startWindowTime).tz(timezone).subtract(cutoff, 'm');
};

const calculateRelative = (relativeCutoff: RelativeCutoff) => {
	let cutoff = 0;
	cutoff += dateUtils.daysToMinutes(relativeCutoff.days);
	cutoff += dateUtils.hoursToMinutes(relativeCutoff.hours);
	cutoff += relativeCutoff.minutes;

	return relativeCutoff.relativeOption === RelativeOptions.AFTER ? cutoff * -1 : cutoff;
};

const calculateDaily = (dailyCutoff: DailyCutoff, startOfTimeSlot: number, timezone: string): number => {
	const startDate = dateUtils.addMinutesToLastMonday(startOfTimeSlot, timezone);
	const timeDate = moment(dailyCutoff.time).tz(timezone);
	const dateAfterSubtract = startDate.clone().subtract(dailyCutoff.days, 'd').set({
		hours: timeDate.hours(),
		minutes: timeDate.minutes()
	});
	const cutoff = startDate.diff(dateAfterSubtract, 'm');
	return cutoff;
};

const cutoffToRelative = (cutoff: number): RelativeCutoff => {
	const cutoffDuration = moment.duration(cutoff, 'minutes');
	return {
		days: Math.abs(cutoffDuration.days()),
		hours: Math.abs(cutoffDuration.hours()),
		minutes: Math.abs(cutoffDuration.minutes()),
		relativeOption: cutoff < 0 ? RelativeOptions.AFTER : RelativeOptions.BEFORE
	};
};

const cutoffToDaily = (cutoff: number, startWindowTime: Date, timezone: string): DailyCutoff => {
	const startWindowDate = moment(startWindowTime).tz(timezone);
	const cutoffDate = startWindowDate.clone().subtract(cutoff, 'm');
	const diffInDays = startWindowDate.clone().startOf('day').diff(cutoffDate.clone().startOf('day'), 'days');

	return {
		days: diffInDays,
		time: cutoffDate.tz(timezone).toISOString()
	};
};

export const cutoffCalculation = { getDate, calculateRelative, calculateDaily, cutoffToRelative, cutoffToDaily };
