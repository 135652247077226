import { Currencies, Currency } from '@bringg/types';
import { getSharedRootStore } from '@bringg-frontend/global-stores';

type CurrencyInfo = {
	title: string;
	code: Currency;
	symbol: string;
};

const DEFAULT_CURRENCY = Currencies[Currency.USD];

export const useMerchantCurrency = () => {
	const {
		data: { merchantConfigurationsStore }
	} = getSharedRootStore();

	const currencyKey = merchantConfigurationsStore.configuration?.price_currency || DEFAULT_CURRENCY.code;
	const currencyInfo: CurrencyInfo = Currencies[currencyKey] ?? DEFAULT_CURRENCY;

	return currencyInfo;
};
