import { getRootEnv } from '@bringg-frontend/bringg-web-infra';
import { getEnv } from 'mobx-easy';
import ResourceUploadType from '@bringg/dashboard-sdk/dist/ResourceUpload/ResourceUploadType';
import { ResourceProcessDoneResult } from '@bringg/dashboard-sdk/dist/ResourceUpload/ResourceResults';
import { RootEnv } from '@bringg-frontend/bringg-web-infra';

const MIN_HTTP_ERROR_CODE = 400;

async function putFileInUrl(url: string, file: Blob) {
	return new Promise<void>((resolve, reject) => {
		const reader = new FileReader();

		reader.onload = async e => {
			try {
				const fileContents: any = e.target.result;
				const binary = atob(fileContents.split(',')[1]);
				const array = [];

				for (let i = 0; i < binary.length; i++) {
					array.push(binary.charCodeAt(i));
				}
				const blobData = new Blob([new Uint8Array(array)], { type: file.type });

				const result = await fetch(url, {
					method: 'PUT',
					body: blobData
				});

				if (result.status >= MIN_HTTP_ERROR_CODE) {
					reject(result);
				} else {
					resolve();
				}
			} catch (exc) {
				reject(exc);
			}
		};

		reader.readAsDataURL(file);
	});
}

async function upload(
	file: Blob,
	type: ResourceUploadType,
	doneEvent: string,
	prepareParams: any
): Promise<ResourceProcessDoneResult> {
	const { resource_id } = await prepareAndProcess(file, type, doneEvent, prepareParams);

	return new Promise(resolve => {
		const unsubscribe = getRootEnv().dashboardSdk.sdk.resourceUploads.registerOnProcessed(
			resource_id,
			(data: ResourceProcessDoneResult) => {
				resolve(data);
				unsubscribe();
			}
		);
	});
}

async function prepareAndProcess(file: Blob, type: ResourceUploadType, doneEvent: string, prepareParams: any) {
	const importResponse = await getRootEnv().dashboardSdk.sdk.resourceUploads.prepare(type, prepareParams);

	if (!importResponse.success) {
		throw new Error(`file uploader: error on prepare func ${JSON.stringify(importResponse)}`);
	}

	await putFileInUrl(importResponse.url, file);

	const result = await getRootEnv().dashboardSdk.sdk.resourceUploads.process(importResponse.resource_id, doneEvent);

	if (!result.success) {
		throw new Error(`file uploader: error on import func ${JSON.stringify(result)}`);
	}

	return importResponse;
}

async function getProgress(resourceId: string) {
	return getRootEnv().dashboardSdk.sdk.resourceUploads.progress(resourceId);
}

export default { upload, prepareAndProcess, getProgress };
