import { DeliveryBlockRechargeBase } from 'bringg-web/features/delivery-blocks-v2/new-modal/recharge/recharge-types';
import DeliveryBlock from 'bringg-web/features/delivery-blocks-v2/stores/domain-objects/delivery-block';
import { DeliveryBlockTemplate } from '@bringg/types';

const init = (
	deliveryBlock: Partial<DeliveryBlock> | DeliveryBlockTemplate,
	updateDeliveryBlock: <T>(key: string, value: T) => void
) => {
	const isTemplate = (deliveryBlock as DeliveryBlockTemplate).template_recharges !== undefined;
	const deliveryBlockTemplate = deliveryBlock as DeliveryBlockTemplate;
	const deliveryBlockEditable = deliveryBlock as DeliveryBlock;

	const onAddRecharge = (rechargeItem: DeliveryBlockRechargeBase) => {
		updateDeliveryBlock(isTemplate ? 'template_recharges' : 'delivery_block_recharges', [
			...(isTemplate ? deliveryBlockTemplate.template_recharges : deliveryBlockEditable.delivery_block_recharges),
			rechargeItem
		]);
	};

	const onRechargesChange = (recharges: DeliveryBlockRechargeBase[]) => {
		updateDeliveryBlock(isTemplate ? 'template_recharges' : 'delivery_block_recharges', recharges);
	};

	return { onAddRecharge, onRechargesChange };
};
export const rechargeEditor = { init };
