import React, { Component } from 'react';
import { InputNumber, Radio } from 'antd';
import { observer } from 'mobx-react';
import isNumber from 'lodash/isNumber';
import has from 'lodash/has';
import get from 'lodash/get';
import CustomerConfiguration from '../../../stores/customer-configurations/domain-objects/customer-configuration';
import CheckboxEnableOption from './checkbox-enable-option';
import { Translate } from '../../../translation';
import TagCustomerConfiguration from '../../../stores/tag-customer-configurations/tag-customer-configuration';

interface Props {
	customerConfiguration: CustomerConfiguration | TagCustomerConfiguration;
	enableModeKey: string;
	showPrivateMode: boolean;
	privateModeKey?: string;
	enableFreeSmsMode: boolean;
}

class AlertOnStartEnableOption extends Component<Props, {}> {
	onChange = ({ target }) => {
		if (target.value) {
			return;
		}
		this.props.customerConfiguration.setValue('alert_customer_minutes_before_eta', null);
	};

	updateMinutes = ({ target }) => {
		this.props.customerConfiguration.setValue('alert_customer_minutes_before_eta', target.value);
	};

	render() {
		const { customerConfiguration } = this.props;
		const minutes = get(customerConfiguration, 'alert_customer_minutes_before_eta');
		const defaultButtonValue = isNumber(minutes);
		const showCustomerMinutesBefore = has(customerConfiguration, 'alert_customer_minutes_before_eta');
		return (
			<div className="enable-option">
				{showCustomerMinutesBefore && (
					<div>
						<Radio.Group onChange={this.onChange} defaultValue={defaultButtonValue}>
							<Radio value={false} className="radio-button" data-test-id="alert-as-task-starts-radio">
								<Translate text="CUSTOMER_CONFIGURATION_VIEW.ALERT_TASK_START_TRIGGER_ON_ORDER_START" />
							</Radio>
							<br />
							<Radio value className="radio-button" data-test-id="alert-before-driver-arrive-radio">
								<Translate text="CUSTOMER_CONFIGURATION_VIEW.ALERT_TASK_START_TRIGGER_ON_ETA_PRE" />
								<InputNumber
									defaultValue={minutes}
									className="input-number"
									onBlur={this.updateMinutes}
									data-test-id="number-input-before-driver-arrive"
								/>
								<Translate text="CUSTOMER_CONFIGURATION_VIEW.ALERT_TASK_START_TRIGGER_ON_ETA_POST" />
							</Radio>
						</Radio.Group>
					</div>
				)}
				<CheckboxEnableOption {...this.props} />
			</div>
		);
	}
}

export default observer(AlertOnStartEnableOption);
