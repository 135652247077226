import { getRootEnv } from '@bringg-frontend/bringg-web-infra';
import { Run } from '@bringg/types';
import { getRoot } from 'mobx-easy';
import { reaction } from 'mobx';
import { RunModel, RunQueryOptions, UpdateRunOptions } from '@bringg/dashboard-sdk/dist/Run/run-types';
import { TaskVehiclePair } from '@bringg/dashboard-sdk/dist/Run/Service/runs.service';
import { DataOptions, MappedStorableItem } from '@bringg/dashboard-sdk/dist/data-entity';
import { CommonOptions } from '@bringg/dashboard-sdk/dist/Core/RouteGenerator';

import RunStore from 'bringg-web/stores/runs/run-store';
import RootStore from 'bringg-web/stores/root-store';
import { hasFeatureFlag } from '../../utils/feature-flags';

export default class RunsStoreAdapter {
	public localStore: RunStore;
	private DEFAULT_PAGE_SIZE = 200;

	constructor() {
		this.localStore = new RunStore();
	}

	public afterRootInit() {
		reaction(
			() => getRoot<RootStore>().views.authStore.isLoggedIn,
			isLoggedIn => {
				if (RunsStoreAdapter.shouldUseDashboardSdkApi()) {
					RunsStoreAdapter.getRunsApi().updateStoreOnEvents();
				} else {
					if (isLoggedIn) {
						this.localStore.afterUserLoggedIn();
					} else {
						this.localStore.afterUserLoggedOut();
					}
				}
			}
		);
	}

	public onCreate(callback: (mappedStorableItem: MappedStorableItem<Run>) => void) {
		if (RunsStoreAdapter.shouldUseDashboardSdkApi()) {
			return RunsStoreAdapter.getRunsApi().onCreate(callback);
		}

		return getRootEnv().dashboardSdk.sdk.runs.onCreate(runModel => {
			callback({ current: this.getRunWithTaskIds(runModel) });
		});
	}

	public onUpdate(callback: (mappedStorableItem: MappedStorableItem<Run>) => void) {
		if (RunsStoreAdapter.shouldUseDashboardSdkApi()) {
			return RunsStoreAdapter.getRunsApi().onUpdate(callback);
		}

		return getRootEnv().dashboardSdk.sdk.runs.onUpdate(runModel => {
			callback({ current: this.getRunWithTaskIds(runModel) });
		});
	}

	public onDelete(callback: (mappedStorableItem: MappedStorableItem<Run>) => void) {
		if (RunsStoreAdapter.shouldUseDashboardSdkApi()) {
			return RunsStoreAdapter.getRunsApi().onDelete(callback);
		}

		return getRootEnv().dashboardSdk.sdk.runs.onDelete(runModel => {
			return callback({ diff: this.getRunWithTaskIds(runModel) });
		});
	}

	public async loadMany(
		query: RunQueryOptions,
		options?: DataOptions,
		commonOptions?: CommonOptions
	): Promise<Run[]> {
		if (query?.ids && query?.ids.length === 0) {
			return [];
		}

		if (RunsStoreAdapter.shouldUseDashboardSdkApi()) {
			return RunsStoreAdapter.getRunsApi().loadMany(
				{
					items: this.DEFAULT_PAGE_SIZE,
					...query
				},
				options,
				commonOptions
			);
		}

		const runModels = await this.localStore.loadAll(query);
		return runModels.map(runModel => {
			return this.getRunWithTaskIds(runModel);
		});
	}

	public async loadAllByIds(ids: number[]): Promise<Run[]> {
		if (!ids.length) {
			return [];
		}

		if (RunsStoreAdapter.shouldUseDashboardSdkApi()) {
			return RunsStoreAdapter.getRunsApi().loadMany({ ids });
		}

		const runModels = await this.localStore.batchGetByIds(ids);
		this.localStore.setBatch(runModels);

		return runModels.map(runModel => {
			return this.getRunWithTaskIds(runModel);
		});
	}

	public async load(id: number, force?: boolean): Promise<Run> {
		if (RunsStoreAdapter.shouldUseDashboardSdkApi()) {
			return RunsStoreAdapter.getRunsApi().load(id, { useCache: !force });
		}

		const runModel = await this.localStore.load(id, force);
		return runModel?.run;
	}

	public get(id: number): Run {
		if (RunsStoreAdapter.shouldUseDashboardSdkApi()) {
			return RunsStoreAdapter.getRunsApi().get(id);
		}

		const runModel = this.localStore.getFromStore(id);
		return this.getRunWithTaskIds(runModel);
	}

	public getByIds(ids: number[]): Run[] {
		if (RunsStoreAdapter.shouldUseDashboardSdkApi()) {
			return RunsStoreAdapter.getRunsApi().getByIds(ids);
		}

		return ids.map(id => {
			const runModel = this.localStore.getFromStore(id);
			return this.getRunWithTaskIds(runModel);
		});
	}

	public add(run: Run) {
		if (RunsStoreAdapter.shouldUseDashboardSdkApi()) {
			RunsStoreAdapter.getRunsApi().add(run);
		}

		this.localStore.addToStore(run);
	}

	public remove(id: number) {
		if (RunsStoreAdapter.shouldUseDashboardSdkApi()) {
			RunsStoreAdapter.getRunsApi().remove(id);
		}

		this.localStore.removeFromStore(id);
	}

	public getAll(): Record<number, Run> {
		if (RunsStoreAdapter.shouldUseDashboardSdkApi()) {
			return RunsStoreAdapter.getRunsApi().getAllMap();
		}

		return [...this.localStore.getAllFromStore().values()].map(runModel => {
			return this.getRunWithTaskIds(runModel);
		});
	}

	public async update(id: number, options: UpdateRunOptions): Promise<Run> {
		if (RunsStoreAdapter.shouldUseDashboardSdkApi()) {
			return RunsStoreAdapter.getRunsApi().update(id, options);
		}

		return this.localStore.update(id, options);
	}

	assignVehicles = async (runId: number, vehicleId: number, tasksIds: TaskVehiclePair[]) => {
		if (RunsStoreAdapter.shouldUseDashboardSdkApi()) {
			return RunsStoreAdapter.getRunsApi().assignVehicles(runId, vehicleId, tasksIds);
		}

		return this.localStore.assignVehicles(runId, vehicleId, tasksIds);
	};

	unassignVehicles = async (runId: number) => {
		if (RunsStoreAdapter.shouldUseDashboardSdkApi()) {
			return RunsStoreAdapter.getRunsApi().unassignVehicles(runId);
		}

		return this.localStore.unassignVehicles(runId);
	};

	private static getRunsApi() {
		return getRootEnv().dashboardSdk.sdk.v2.runs;
	}

	private static shouldUseDashboardSdkApi() {
		return hasFeatureFlag(getRoot<RootStore>().data.usersStore.currentUser, 'runs_v2_api');
	}

	private getRunWithTaskIds(runModel: RunModel) {
		if (runModel?.run) {
			runModel.run.task_ids = runModel.taskIds ? Array.from(runModel.taskIds) : [];
			return runModel.run;
		}
	}
}
