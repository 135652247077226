import React, { useEffect, useMemo, useState } from 'react';

import { BringgInput, DateRangePicker, Modal, Notification, OptGroup, Option, Select } from '@bringg/react-components';
import moment, { Moment } from 'moment-timezone';
import { useTranslation } from 'react-i18next';
import { ApplicationUuid, ExclusionType, ExclusionWindow } from '@bringg/types';
import { ServiceAreaFilterGroups } from '@bringg/dashboard-sdk/dist/ServiceArea/v2/service-area.consts';
import { isEqual as _isEqual } from 'lodash';
import { CreateExclusionWindowsRequest } from '@bringg/dashboard-sdk/dist/ExclusionWindow/v2/exclusion-window.consts';
import { DndProvider } from 'react-dnd';
import { BringgFontIcons, BringgIcon } from '@bringg/bringg-icons';
import { HTML5Backend } from 'react-dnd-html5-backend';

import { useStores } from 'bringg-web/recipes';
import { NO_TEAM } from 'bringg-web/features/planned-delivery-windows/planned-delivery-windows-view';
import { filterOption, filterOptionByTitle } from 'bringg-web/services/utils';
import ModalFooter from 'bringg-web/features/planned-delivery-windows/modals/exclusion-window-modal/footer/modal-footer';
import { timezoneProvider } from 'bringg-web/services/timezone/timezone-provider';
import TransferModal from 'bringg-web/features/transfer-modal/transfer-modal';
import { TransferItemList } from 'bringg-web/features/transfer-modal/types';

import styles from './exclusion-window-modal.module.scss';

const FAKE_NO_SERVICE_PLAN_ID = 0;

export interface Props {
	isVisible: boolean;
	title: string;
	closeModal: () => void;
	exclusionWindow?: ExclusionWindow;
	timeFormat: string;
	teamId: number;
	onCreate?: (exclusionWindow: ExclusionWindow[]) => void;
	onEdit?: (id: number) => void;
}

const MINIMUM_MINUTES_DIFF_EFFECTIVE_DATES = 30;
const ExclusionWindowModal = ({
	isVisible,
	title,
	closeModal,
	exclusionWindow,
	timeFormat,
	teamId,
	onCreate,
	onEdit
}: Props) => {
	const {
		teamsStore,
		servicePlansStore,
		serviceArea,
		exclusionWindows: exclusionWindowStore,
		applicationStore
	} = useStores();

	const timezone =
		teamId !== NO_TEAM ? timezoneProvider.getTimezoneByTeamId(teamId) : timezoneProvider.getTimezoneByMerchant();
	const [effectiveDates, setEffectiveDates] = useState<{ startDate: Moment; endDate: Moment }>({
		startDate: exclusionWindow
			? moment(exclusionWindow.start_time).tz(timezone)
			: moment().tz(timezone).startOf('day'),
		endDate: exclusionWindow
			? moment(exclusionWindow.end_time).tz(timezone)
			: moment().tz(timezone).startOf('day').add(12, 'h')
	});
	const [isTeamsSelectModalOpen, setIsTeamsSelectModalOpen] = useState(false);
	const [loader, setLoader] = useState(false);
	const [name, setName] = useState(exclusionWindow?.name || '');
	const [serviceAreasIds, setServiceAreasIds] = useState<number[]>(exclusionWindow?.service_area_ids || []);
	const [teamIds, setTeamIds] = useState<number[]>([teamId]);
	const [servicePlanId, setServicePlanId] = useState<number>(exclusionWindow?.service_plan_id || null);
	const [exclusionType, setExclusionType] = useState<ExclusionType>(
		exclusionWindow?.exclusion_type || ExclusionType.ALL
	);
	const [repeat, setRepeat] = useState(exclusionWindow?.repeat);
	const [tasksLimit, setTasksLimit] = useState(exclusionWindow?.tasks_limit || 0);

	const { t } = useTranslation();
	const getIdAndName = ({ id, name }: { id?: number; name?: string }) => ({ id, name });
	const getIdAndTitle = ({ id, name }: { id?: number; name?: string }) => ({ id, title: name });

	const serviceAreaOptions = useMemo(
		() => serviceArea.getGroup(ServiceAreaFilterGroups.Teams, teamIds).map(getIdAndName),
		[teamIds]
	);

	const sourcesTeams = [{ id: NO_TEAM, name: t('PLANNED_DELIVERY_WINDOWS.BY_MERCHANT') }, ...teamsStore.all].map(
		getIdAndTitle
	);

	const teamsOptions = [{ id: NO_TEAM, name: t('PLANNED_DELIVERY_WINDOWS.BY_MERCHANT') }, ...teamsStore.all].map(
		getIdAndName
	);

	const servicePlanOptions = [
		{ id: FAKE_NO_SERVICE_PLAN_ID, name: t('PLANNED_DELIVERY_WINDOWS_MODAL_TABLE_STEP.NO_SERVICE_PLAN') },
		...servicePlansStore.getAll.sort((a, b) => (a.name > b.name ? 1 : -1))
	].map(getIdAndName);

	const exclusionTypeOptions = Object.keys(ExclusionType)
		.filter(v => isNaN(Number(v)))
		.map(exclusionTypeKey => ({
			id: ExclusionType[exclusionTypeKey],
			name: t(`EXCLUSION_WINDOW_MODAL_TYPE.${exclusionTypeKey.toLocaleUpperCase()}`)
		}));

	const changeEffectiveDates = (range: Moment[]) => {
		range.forEach(momentDate => momentDate?.isValid() && momentDate.set({ second: 0, ms: 0 }));
		setEffectiveDates({ startDate: range[0], endDate: range[1] });
	};

	const getTitle = () => <span className={styles.modalTitle}>{title}</span>;

	const onRepeatChange = (updatedRepeat: boolean) => {
		setRepeat(updatedRepeat);
	};

	const createExclusionWindow = async () => {
		try {
			setLoader(true);
			const exclusionWindowsToCreate: CreateExclusionWindowsRequest[] = teamIds.map(teamId => {
				const teamTimezone = timezoneProvider.getTimezoneByTeamId(teamId);
				return {
					name,
					repeat,
					service_area_ids: serviceAreasIds,
					service_plan_id: servicePlanId,
					start_time: moment(effectiveDates.startDate).tz(teamTimezone, true).toISOString(),
					end_time: moment(effectiveDates.endDate).tz(teamTimezone, true).toISOString(),
					team_id: teamId || null,
					tasks_limit: tasksLimit,
					exclusion_type: exclusionType
				};
			});
			const createdExclusionWindow: ExclusionWindow[] = await exclusionWindowStore.createMany(
				exclusionWindowsToCreate
			);

			Notification.success(t('PLANNED_DELIVERY_WINDOWS_EXCLUSION_MODAL.SUCCESS_TO_CREATE'));
			onCreate && onCreate(createdExclusionWindow);
			closeModal();
		} catch (e) {
			Notification.error(t('PLANNED_DELIVERY_WINDOWS_EXCLUSION_MODAL.FAILED_TO_CREATE'));
			console.error('failed to create exclusion window', e);
			setLoader(false);
		}
	};

	const showTasksLimit = !!applicationStore.getApplication(ApplicationUuid.ExclusionWindowTasksLimitApp);

	const updateExclusionWindow = async () => {
		try {
			setLoader(true);
			await exclusionWindowStore.update(exclusionWindow.id, {
				name,
				repeat,
				start_time: moment(effectiveDates.startDate).tz(timezone).toISOString(),
				end_time: moment(effectiveDates.endDate).tz(timezone).toISOString(),
				service_area_ids: serviceAreasIds,
				service_plan_id: servicePlanId,
				tasks_limit: tasksLimit,
				exclusion_type: exclusionType
			});
			onEdit && onEdit(exclusionWindow.id);
			Notification.success(t('EXCLUSION_WINDOW_VIEW_EDIT.SUCCESS_TO_EDIT'));
			closeModal();
		} catch (e) {
			Notification.error(t('EXCLUSION_WINDOW_VIEW_EDIT.FAILED_TO_EDIT'));
			console.error('failed to update exclusion window', e);
			setLoader(false);
		}
	};

	const handleTeamIds = (teamIds: number[]) => {
		if (teamIds.includes(NO_TEAM)) {
			setServiceAreasIds([]);
		}

		setTeamIds(teamIds);
	};

	useEffect(() => {
		const validServiceAreaIds = new Set(serviceAreaOptions.map(option => option.id));
		const filteredServiceAreas = serviceAreasIds.filter(id => validServiceAreaIds.has(id));
		if (serviceAreasIds.some(item => !filteredServiceAreas.includes(item))) {
			setServiceAreasIds(filteredServiceAreas);
		}
	}, [teamIds, serviceAreaOptions, serviceAreasIds]);

	const isEffectiveDatesValid =
		effectiveDates.endDate?.diff(effectiveDates.startDate, 'm') >= MINIMUM_MINUTES_DIFF_EFFECTIVE_DATES;

	const isDisabled = exclusionWindow
		? !name ||
		  !isEffectiveDatesValid ||
		  (exclusionWindow.name === name &&
				exclusionWindow.service_plan_id === servicePlanId &&
				moment(exclusionWindow.start_time).isSame(effectiveDates.startDate) &&
				moment(exclusionWindow.end_time).isSame(effectiveDates.endDate) &&
				_isEqual(exclusionWindow.service_area_ids, serviceAreasIds) &&
				exclusionWindow.repeat === repeat &&
				(!showTasksLimit || Number(exclusionWindow.tasks_limit) === tasksLimit) &&
				exclusionWindow.exclusion_type === exclusionType)
		: !name || !teamIds.length || !isEffectiveDatesValid;

	const onApplySelectedTeams = (sourceList: TransferItemList[], destinationList: TransferItemList[]) => {
		setTeamIds(destinationList.map(destination => destination.id));
		setIsTeamsSelectModalOpen(false);
	};

	return (
		<>
			<Modal
				title={getTitle()}
				visible={isVisible}
				onCancel={closeModal}
				footer={
					<ModalFooter
						repeat={repeat}
						onRepeatChange={onRepeatChange}
						onCancel={closeModal}
						onOk={exclusionWindow ? updateExclusionWindow : createExclusionWindow}
						okText={
							exclusionWindow
								? t('PLANNED_DELIVERY_WINDOWS_EXCLUSION_MODAL_FOOTER.SAVE')
								: t('PLANNED_DELIVERY_WINDOWS_EXCLUSION_MODAL_FOOTER.CREATE')
						}
						isDisabled={isDisabled}
						loader={loader}
					/>
				}
				width={600}
			>
				<div className={styles.nameInput}>
					<span className={styles.labelTextRequired}>
						{t('PLANNED_DELIVERY_WINDOWS_EXCLUSION_MODAL.NAME_TEXT')}
					</span>
					<BringgInput
						placeholder={t('PLANNED_DELIVERY_WINDOWS_EXCLUSION_MODAL.NAME')}
						type="text"
						value={name}
						onChange={e => setName(e.target.value)}
						data-test-id="exclusion-modal-name"
					/>
				</div>
				{!exclusionWindow && (
					<div className={styles.teamIds}>
						<span className={styles.labelTextRequired}>
							{t('PLANNED_DELIVERY_WINDOWS_EXCLUSION_MODAL.TEAM_IDS')}
						</span>
						<Select
							showSearch
							mode="multiple"
							maxTagCount={2}
							value={teamIds}
							placeholder={t('PLANNED_DELIVERY_WINDOWS_EXCLUSION_MODAL.TEAM_IDS_PLACEHOLDER')}
							onChange={handleTeamIds}
							filterOption={filterOption}
							className={styles.teamIdsDropdown}
							data-test-id="exclusion-modal-teams"
						>
							<OptGroup
								label={
									<span className={styles.expandList} onClick={() => setIsTeamsSelectModalOpen(true)}>
										<span className={styles.expandListText}>
											{t('PLANNED_DELIVERY_WINDOWS_EXCLUSION_MODAL.EXPAND_TEAMS_SELECTOR')}
											<BringgIcon
												className={styles.showMoreIcon}
												iconName={BringgFontIcons.ShowMore}
											/>
										</span>
									</span>
								}
							/>
							{teamsOptions.map(teamOption => (
								<Option key={teamOption.id} value={teamOption.id}>
									{teamOption.name}
								</Option>
							))}
						</Select>
					</div>
				)}
				<div className={styles.effectiveDates}>
					<span className={styles.labelTextRequired}>
						{t('PLANNED_DELIVERY_WINDOWS_EXCLUSION_MODAL.EFFECTIVE_DATES')}
					</span>
					<DateRangePicker
						startDate={effectiveDates.startDate}
						endDate={effectiveDates.endDate}
						translations={{
							fromDate: t('PLANNED_DELIVERY_WINDOWS_EXCLUSION_MODAL.START_DATE'),
							toDate: t('PLANNED_DELIVERY_WINDOWS_EXCLUSION_MODAL.END_DATE')
						}}
						status={!isEffectiveDatesValid ? 'error' : null}
						onChange={changeEffectiveDates}
						allowClear={false}
						className={styles.effectiveDatesPicker}
						showTime={{ format: timeFormat }}
						format={`MMM DD, YYYY ${timeFormat}`}
						order
						data-test-id="exclusion-modal-effective-dates"
					/>
					{!isEffectiveDatesValid && (
						<span
							className={styles.dangerText}
							data-test-id="exclusion-modal-effective-dates-error-message"
						>
							{t('PLANNED_DELIVERY_WINDOWS_EXCLUSION_MODAL.MINIMUM_EFFECTIVE_DATE_TEXT')}
						</span>
					)}
				</div>
				<div className={styles.serviceAreas}>
					<span className={styles.labelText}>
						{t('PLANNED_DELIVERY_WINDOWS_EXCLUSION_MODAL.SERVICE_AREAS_TEXT')}
					</span>
					<Select
						showSearch
						mode="multiple"
						disabled={!teamIds.length || teamIds.includes(NO_TEAM)}
						options={serviceAreaOptions}
						maxTagCount={2}
						value={serviceAreasIds}
						placeholder={t('PLANNED_DELIVERY_WINDOWS_EXCLUSION_MODAL.SERVICE_AREAS_PLACEHOLDER')}
						onChange={(selectedServiceAreasIds: number[]) => setServiceAreasIds(selectedServiceAreasIds)}
						filterOption={filterOptionByTitle}
						className={styles.serviceAreasDropdown}
						data-test-id="exclusion-modal-service-areas"
					/>
				</div>
				<div className={styles.servicePlan}>
					<span className={styles.labelText}>
						{t('PLANNED_DELIVERY_WINDOWS_EXCLUSION_MODAL.SERVICE_PLAN_TEXT')}
					</span>
					<Select
						showSearch
						options={servicePlanOptions}
						value={servicePlanId || FAKE_NO_SERVICE_PLAN_ID}
						defaultValue={FAKE_NO_SERVICE_PLAN_ID}
						placeholder={t('PLANNED_DELIVERY_WINDOWS_EXCLUSION_MODAL.SERVICE_PLAN_PLACEHOLDER')}
						onChange={(selectedServicePlanId: number) =>
							setServicePlanId(
								selectedServicePlanId === FAKE_NO_SERVICE_PLAN_ID ? null : selectedServicePlanId
							)
						}
						filterOption={filterOptionByTitle}
						className={styles.servicePlanDropdown}
						data-test-id="exclusion-modal-service-plan"
					/>
				</div>
				{showTasksLimit && (
					<div className={styles.capacity}>
						<span className={styles.labelText}>
							{t('PLANNED_DELIVERY_WINDOWS_EXCLUSION_MODAL.CAPACITY_TEXT')}
						</span>
						<BringgInput
							placeholder={t('PLANNED_DELIVERY_WINDOWS_EXCLUSION_MODAL.CAPACITY_PLACEHOLDER')}
							type="number"
							value={tasksLimit}
							onChange={e => setTasksLimit(+e.target.value)}
							data-test-id="exclusion-modal-capacity"
							min={0}
							className={styles.capacityInput}
						/>
					</div>
				)}
				<div className={styles.exclusionType}>
					<span className={styles.labelText}>
						{t('PLANNED_DELIVERY_WINDOWS_EXCLUSION_MODAL.EXCLUSION_TYPE_TEXT')}
					</span>
					<Select
						showSearch
						options={exclusionTypeOptions}
						value={exclusionType}
						placeholder={t('PLANNED_DELIVERY_WINDOWS_EXCLUSION_MODAL.EXCLUSION_TYPE_PLACEHOLDER')}
						onChange={(exclusionTypeChanged: ExclusionType) => setExclusionType(exclusionTypeChanged)}
						filterOption={filterOptionByTitle}
						className={styles.exclusionTypeDropdown}
						data-test-id="exclusion-modal-exclusion-type"
					/>
				</div>
			</Modal>
			{isTeamsSelectModalOpen && (
				<DndProvider backend={HTML5Backend}>
					<TransferModal
						zIndex={1051}
						className={styles.transferModal}
						onClose={() => setIsTeamsSelectModalOpen(false)}
						title={t('DELIVERY_BLOCK_TEMPLATE.TEAMS_CUSTOMIZATION')}
						sourceList={sourcesTeams.filter(sourcesTeam => !teamIds.includes(sourcesTeam.id))}
						targetList={teamIds.map(teamID => {
							const team = teamsStore.get(teamID);
							return {
								id: team?.id || NO_TEAM,
								title: team?.name || t('PLANNED_DELIVERY_WINDOWS.BY_MERCHANT')
							};
						})}
						sourceTitle={t('DELIVERY_BLOCK_TEMPLATE.TEAMS_SELECT_TEAMS')}
						targetTitle={t('DELIVERY_BLOCK_TEMPLATE.TEAMS_SELECT_SELECTED_TEAMS')}
						onApply={onApplySelectedTeams}
					/>
				</DndProvider>
			)}
		</>
	);
};

export default ExclusionWindowModal;
