import React, { Component } from 'react';
import { observer } from 'mobx-react';
import CustomerConfiguration from '../../../stores/customer-configurations/domain-objects/customer-configuration';
import AlertOnStartEnableOption from './alert-on-start-enable-option';
import CheckboxEnableOption from './checkbox-enable-option';
import SelectEnableOption from './select-enable-option';
import TagCustomerConfiguration from '../../../stores/tag-customer-configurations/tag-customer-configuration';
import AlertOnEtaDelayEnableOption from './alert-on-eta-delay-enable-option';

interface Props {
	customerConfiguration: CustomerConfiguration | TagCustomerConfiguration;
	enableModeKey: string;
	privateModeKey: string;
	showPrivateMode: boolean;
	enableOptionType: string;
	enableOptions: { label: any; value: any }[];
	enableFreeSmsMode: boolean;
}

class EnableOptions extends Component<Props> {
	components = {
		'alert-on-eta-delay': AlertOnEtaDelayEnableOption,
		'alert-on-start': AlertOnStartEnableOption,
		checkbox: CheckboxEnableOption,
		select: SelectEnableOption
	};

	render() {
		const { enableOptionType } = this.props;
		const EnableOptionComponent = this.components[enableOptionType];
		return (
			<div className="enable-options">
				<EnableOptionComponent {...this.props} />
			</div>
		);
	}
}

export default observer(EnableOptions);
