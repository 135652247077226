import React from 'react';
import { observer } from 'mobx-react';
import { NumberType } from '@bringg/types';
import CustomerConfiguration from '../../../stores/customer-configurations/domain-objects/customer-configuration';
import TagCustomerConfiguration from '../../../stores/tag-customer-configurations/tag-customer-configuration';
import { DISPLAYED_NOTIFICATION_COLLECTION } from '../notification-template/notification-template.consts';
import CheckboxOption from './checkbox-option';

interface Props {
	customerConfiguration: CustomerConfiguration | TagCustomerConfiguration;
	showPrivateMode: boolean;
	enableModeKey: string;
	privateModeKey?: string;
	enableFreeSmsMode: boolean;
}

const CheckboxEnableOption = ({
	privateModeKey,
	enableModeKey,
	customerConfiguration,
	showPrivateMode,
	enableFreeSmsMode
}: Props) => {
	const { messageType } = DISPLAYED_NOTIFICATION_COLLECTION[enableModeKey];
	const enabledMode = customerConfiguration[enableModeKey] ?? false;
	const privateMode = customerConfiguration[privateModeKey] ?? false;
	const freeSmsMode = customerConfiguration.smsSettings[messageType] === NumberType.FreeSms;

	return (
		<div className="enable-option">
			<CheckboxOption
				checked={enabledMode}
				onChange={({ target }) => customerConfiguration.setValue(enableModeKey, target.checked)}
				translateKey="CUSTOMER_CONFIGURATION_VIEW.ENABLE"
				testAtrr="enable-checkbox"
			/>

			{enabledMode && showPrivateMode && (
				<CheckboxOption
					checked={privateMode}
					onChange={({ target }) => customerConfiguration.setValue(privateModeKey, target.checked)}
					translateKey="CUSTOMER_CONFIGURATION_VIEW.ENABLE_PRIVATE_MODE"
					testAtrr="enable-private-mode-checkbox"
				/>
			)}

			{enableFreeSmsMode && (
				<CheckboxOption
					checked={freeSmsMode}
					onChange={({ target }) => customerConfiguration.setFreeSms(messageType, target.checked)}
					translateKey="CUSTOMER_CONFIGURATION_VIEW.FREE_SMS_MODE"
					translateDefault="Send Free SMS"
					testAtrr="enable-free-sms-checkbox"
				/>
			)}
		</div>
	);
};

export default observer(CheckboxEnableOption);
