import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { PlannedRoute, Run, Task } from '@bringg/types';
import { DatePicker, Dropdown, Input, Menu } from 'antd';
import moment from 'moment';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Tooltip } from '@bringg/react-components';
import { BringgFontIcons, BringgIcon } from '@bringg/bringg-icons';
import useStores from '../../recipes/use-stores';
import Team from '../../stores/teams/domain-object/team';
import getTeamId from './run-assigner-team-id-provider';
import useDateTimeFormat from '../../hooks/use-date-time-format';
import './run-creator.scss';

const { Item } = Menu;

export interface RunCreatorProps {
	onRunParamsChanged: (run: Partial<Run>) => void;
	plannedRoutes: PlannedRoute[];
	source: {
		run?: Run;
		tasks?: Task[];
	};
	title: string;
	subtitle?: string;
	dateRange: { startTime: Date; endTime: Date; disabled?: boolean };
	errorElement?: JSX.Element;
	isOverlappedByScheduledTimeRange?: (runId: number, plannedRouteId: number) => boolean;
}

const UNDEFINED_TITLE = 'Undefined';

const RunEditor: React.FC<RunCreatorProps> = ({
	onRunParamsChanged,
	plannedRoutes,
	source,
	title,
	subtitle,
	dateRange,
	errorElement,
	isOverlappedByScheduledTimeRange
}: RunCreatorProps) => {
	const { t } = useTranslation();
	const { teamsStore, merchantConfigurationsStore } = useStores();
	const [plannedRoute, setPlannedRoute] = useState(
		source.run?.planned_route_id ? plannedRoutes.find(item => item.id === source.run.planned_route_id) : null
	);
	const [externalId, setExternalId] = useState('');
	const [scheduledStartTime, setScheduledStartTime] = useState(null);
	const [scheduledEndTime, setScheduledEndTime] = useState(null);
	const dateTimeFormat = useDateTimeFormat();

	useEffect(() => {
		setScheduledStartTime(getTimeByTimezone(dateRange.startTime));
		setScheduledEndTime(getTimeByTimezone(dateRange.endTime));
	}, []);

	useEffect(() => {
		onRunParamsChanged({
			planned_route_id: plannedRoute?.id,
			external_id: externalId === '' ? undefined : externalId,
			scheduled_start_time: scheduledStartTime?.toISOString(),
			scheduled_end_time: scheduledEndTime?.toISOString()
		});
	}, [plannedRoute, externalId, scheduledStartTime, scheduledEndTime]);

	const getTimeByTimezone = date => {
		let momentDate = moment(date || new Date().toISOString());
		const localizationTimezone = getTeam()?.localizationTimezone;

		if (localizationTimezone) {
			momentDate = momentDate.tz(localizationTimezone);
		}

		return momentDate;
	};

	const getTeam = (): Team => {
		return teamsStore.get(getTeamId(source));
	};

	const overlay = useMemo(
		() => (
			<Menu>
				{plannedRoutes
					.slice()
					.sort((plannedRouteA, plannedRouteB) => {
						if (plannedRouteA.title < plannedRouteB.title) {
							return -1;
						}
						if (plannedRouteA.title > plannedRouteB.title) {
							return 1;
						}
						return 0;
					})
					.map(currentPlannedRoute => (
						<Item
							className={classNames('run-editor-planned-route-title', {
								'is-disabled':
									currentPlannedRoute.id === plannedRoute?.id ||
									isOverlappedByScheduledTimeRange?.(source.run.id, currentPlannedRoute.id)
							})}
							key={currentPlannedRoute.id}
							onClick={() => setPlannedRoute(currentPlannedRoute)}
						>
							{!currentPlannedRoute.title ? UNDEFINED_TITLE : currentPlannedRoute.title}
						</Item>
					))}
			</Menu>
		),
		[]
	);

	const getRouteIdentifierInfo = useCallback(() => {
		if (plannedRoute) {
			return !plannedRoute.title ? UNDEFINED_TITLE : plannedRoute.title;
		}
		return t('RUN_USER_ASSIGNMENT.SELECT');
	}, [plannedRoute]);

	return (
		<div className="run-creator">
			<div className="run-creator-title">{title}</div>
			{subtitle && <div className="run-creator-subtitle">{subtitle}</div>}
			{errorElement}
			{plannedRoutes.length === 0 && (
				<div className="run-creator-section run-creator-external-id-section">
					<span>{t('RUN_USER_ASSIGNMENT.RUN_EXTERNAL_ID')}</span>
					<Input onChange={e => setExternalId(e.target.value)} />
				</div>
			)}
			{plannedRoutes.length > 0 && (
				<div className="run-creator-section run-creator-planned-routes-section">
					<span>{t('RUN_USER_ASSIGNMENT.ROUTE_IDENTIFIER')}</span>
					<Dropdown overlayClassName="planned-routes-overlay" overlay={overlay} trigger={['click']}>
						<span>
							<span className={classNames('route-identifier-input')}>{getRouteIdentifierInfo()}</span>
							<BringgIcon iconName={BringgFontIcons.Chevron} className="route-identifier-caret" />
						</span>
					</Dropdown>
				</div>
			)}
			<div className="run-creator-section run-creator-start-time-section">
				<div>
					<span>{t('RUN_USER_ASSIGNMENT.SCHEDULED_START_TIME')}</span>
					<Tooltip
						key="min-eta-tooltip"
						title={t('RUN_USER_ASSIGNMENT.SCHEDULED_START_TIME_MIN_ETA')}
						placement="top"
					>
						<BringgIcon iconName={BringgFontIcons.Info} className="run-creator-info" />
					</Tooltip>
				</div>
				<DatePicker
					showTime
					onChange={setScheduledStartTime}
					value={scheduledStartTime}
					format={dateTimeFormat}
					disabled={dateRange.disabled ?? false}
					use12Hours={merchantConfigurationsStore.shouldUseTime12HoursFormat}
					dropdownClassName="run-editor-start-date-picker-dropdown"
				/>
			</div>
			<div className="run-creator-section run-creator-end-time-section">
				<div>
					<span>{t('RUN_USER_ASSIGNMENT.SCHEDULED_END_TIME')}</span>
					<Tooltip
						key="max-eta-tooltip"
						title={t('RUN_USER_ASSIGNMENT.SCHEDULED_END_TIME_MAX_ETA')}
						placement="top"
					>
						<BringgIcon iconName={BringgFontIcons.Info} className="run-creator-info" />
					</Tooltip>
				</div>
				<DatePicker
					showTime
					onChange={setScheduledEndTime}
					value={scheduledEndTime}
					format={dateTimeFormat}
					disabled={dateRange.disabled ?? false}
					use12Hours={merchantConfigurationsStore.shouldUseTime12HoursFormat}
					dropdownClassName="run-editor-end-date-picker-dropdown"
				/>
			</div>
		</div>
	);
};

export default RunEditor;
