import { useMemo } from 'react';

import { BringgIcon, BringgFontIcons } from '@bringg/bringg-icons';
import { Button, DropdownMenu } from '@bringg/react-components';
import { useTranslation } from 'react-i18next';

export type Props = {
	showBulkUpload?: boolean;
	onBulkUpload: () => void;
	onAdd: () => void;
};

function DropdownButtons({ onBulkUpload, onAdd }: Omit<Props, 'showBulkUpload'>) {
	const { t } = useTranslation();

	const menuItems = useMemo(
		() => [
			{
				label: t('SERVICE_AREA.BULK_UPLOAD'),
				key: 0,
				onClick: onBulkUpload
			},
			{
				label: t('SERVICE_AREA.ADD_MANUALLY'),
				key: 1,
				onClick: onAdd
			}
		],
		[t, onBulkUpload, onAdd]
	);

	return (
		<DropdownMenu items={menuItems}>
			<Button data-test-id="service-area-add-button" type="primary">
				{t('SERVICE_AREA.ADD_NEW')}
				<BringgIcon iconName={BringgFontIcons.Chevron} />
			</Button>
		</DropdownMenu>
	);
}

function SingleButton({ onAdd }: Omit<Props, 'onBulkUpload' | 'showBulkUpload'>) {
	const { t } = useTranslation();

	return (
		<Button data-test-id="service-area-add-button" type="primary" onClick={onAdd}>
			{t('SERVICE_AREA.ADD_NEW')}
		</Button>
	);
}

export const AddServiceAreaButton = ({ showBulkUpload = false, ...rest }: Props) => {
	return showBulkUpload ? <DropdownButtons {...rest} /> : <SingleButton {...rest} />;
};
