import { getRootEnv } from '@bringg-frontend/bringg-web-infra';
import i18next from 'i18next';
import { Package } from '@bringg/types';

import notification from '../notification';
import { hasFeatureFlag } from '../../utils/feature-flags';
import type { PrintPackageType } from '../../stores/packages/packages-store';

function printRawZPL(zpl: string) {
	const iframe = document.createElement('iframe');
	iframe.id = 'temp-print-raw-zpl';
	iframe.style.display = 'none';

	document.body.appendChild(iframe);

	// browser upon print optimize whitespaces, but they are crucial for ZPL format, so we need to replace them with &nbsp;
	const zplWithWhiteSpaces = zpl.replaceAll(' ', '&nbsp;');

	iframe.contentWindow.document.open('text/zpl');
	iframe.contentWindow.document.write(`<!DOCTYPE html><html><body>${zplWithWhiteSpaces}</body></html>`);
	iframe.contentWindow.document.body.style.fontFamily = 'monospace';
	iframe.contentWindow.document.body.style.whiteSpace = 'break-spaces';
	iframe.contentWindow.document.body.style.wordBreak = 'break-all';
	iframe.contentWindow.document.close();
	iframe.contentWindow.focus();
	iframe.contentWindow.print();

	// remove iframe in 10 minutes after print initiated
	setTimeout(() => iframe.remove(), 10 * 60 * 1000);
}

async function printPDFZPL(zpl: string) {
	const pdf: Blob = await getRootEnv().dashboardSdk.sdk.templatesApi.printZPLPDF({ zplCommands: zpl });

	const iframe = document.createElement('iframe');
	iframe.id = 'temp-print-pdf-zpl';
	iframe.style.display = 'none';

	const objectURL = URL.createObjectURL(pdf);
	iframe.src = '';
	iframe.src = objectURL;
	document.body.appendChild(iframe);
	iframe.contentWindow.focus();
	iframe.contentWindow.print();
	// remove iframe in 10 minutes after print initiated
	setTimeout(() => iframe.remove(), 10 * 60 * 1000);
}

function downloadZPLFile(zpl: string, filename = 'label') {
	const blob = new Blob([zpl], { type: 'text/plain;charset=utf-8' });
	const url = URL.createObjectURL(blob);

	const link = document.createElement('a');
	link.href = url;
	link.download = `${filename}.zpl`;

	link.click();

	setTimeout(() => {
		window.URL.revokeObjectURL(url);
		link.remove();
	}, 100);
}

const decodeHTMLSymbols = (string: string): string => {
	const doc = new DOMParser().parseFromString(string, 'text/html');

	return doc.documentElement.textContent;
};
const decodeBase64Buffer = base64BufferString =>
	decodeHTMLSymbols(decodeURIComponent(escape(atob(base64BufferString || ''))));

function printLabelsFromPackages(packages: Package[], printFormat: PrintPackageType) {
	const zpl = packages.reduce((acc, item) => (acc += decodeBase64Buffer(item.label_base64)), ''); // eslint-disable-line

	if (zpl) {
		if (printFormat === 'zpl') {
			// TODO consider move FF check somewhere far above from here
			const downloadAsFile = hasFeatureFlag(
				getRootEnv().dashboardSdk.sdk.session.user,
				'download_zpl_instead_print'
			);

			if (downloadAsFile) {
				const filename = packages
					.map(item => item.external_id || item.id)
					.filter(Boolean)
					.join(',');

				downloadZPLFile(zpl, filename);
			} else {
				printRawZPL(zpl);
			}
		}

		if (printFormat === 'pdf') {
			printPDFZPL(zpl);
		}
	} else {
		notification.error(i18next.t('LABELS.TASK_DOESNT_HAVE_LABELS'));
	}
}

export default {
	printLabelsFromPackages
};
