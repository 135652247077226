import React from 'react';

import { Modal } from '@bringg/react-components';
import classNames from 'classnames';

import css from './dialog.module.scss';

type DialogSize = 'small' | 'medium' | 'large' | 'xlarge' | 'xxlarge';

type DialogProps = {
	title: string;
	subtitle?: string;
	open: boolean;
	handleOk: any;
	handleCancel?: any;
	okText?: string;
	okButtonProps?: any;
	children: React.ReactNode;
	footer?: React.ReactNode;
	destroyOnClose?: boolean;
	size?: DialogSize;
};

const Dialog = ({
	title,
	subtitle,
	open,
	handleOk,
	handleCancel,
	okText,
	okButtonProps,
	children,
	footer,
	destroyOnClose,
	size = 'medium'
}: DialogProps) => {
	// Draft
	const isSmall = size === 'small';
	const isMedium = size === 'medium';
	const isLarge = size === 'large';
	const isXlarge = size === 'xlarge';
	const isXxlarge = size === 'xxlarge';

	return (
		<Modal
			destroyOnClose={destroyOnClose}
			okButtonProps={okButtonProps}
			className={classNames(css.dialog, {
				[css.small]: isSmall,
				[css.medium]: isMedium,
				[css.large]: isLarge,
				[css.xlarge]: isXlarge,
				[css.xxlarge]: isXxlarge
			})}
			title={
				<div>
					<div className={css.title}>{title}</div>
					<div className={css.subtitle}>{subtitle}</div>
				</div>
			}
			open={open}
			onOk={handleOk}
			onCancel={handleCancel}
			okText={okText}
			transitionName=""
			maskTransitionName=""
			footer={footer ? footer : undefined}
		>
			{children}
		</Modal>
	);
};

export default Dialog;
