import React, { memo } from 'react';
import { Button } from '@bringg/react-components';
import { Language as LanguageType } from '@bringg/types';
import { BringgFontIcons, BringgIcon } from '@bringg/bringg-icons';

import styles from './tab-title.module.scss';

interface TabTitleProps {
	language: LanguageType;
	isRemovable: boolean;
	['data-test-id']?: string;
	onRemove(): void;
}

export const TabTitle = memo(({ language, onRemove, isRemovable, 'data-test-id': dataTestId }: TabTitleProps) => (
	<div className={styles.tabTitle}>
		<div>{language.name}</div>
		{isRemovable && (
			<Button
				shape="circle"
				className={styles.removeLanguageBtn}
				icon={<BringgIcon iconName={BringgFontIcons.Close} />}
				onClick={e => {
					e.stopPropagation();
					onRemove();
				}}
				data-test-id={dataTestId}
			/>
		)}
	</div>
));
