import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import extend from 'lodash/extend';

interface Props {
	text: string;
	args?: any;
	defaultValue?: string;
}

const Translate = ({ text, args = {}, defaultValue }: Props) => {
	const { t } = useTranslation();
	args = extend({ defaultValue }, args);

	return <Fragment>{t(text, args)}</Fragment>;
};

export default Translate;
