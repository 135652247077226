import i18next from 'i18next';
import {
	ActionCoreData,
	ActionKey,
	ActionsMetadata,
	ActionType,
	AddReadOnly,
	AlertSeverity,
	AllConditions,
	AnyConditions,
	Attribute,
	AttributeDatatype,
	CalculateAvailabilityAction,
	CalculateAvailabilityStateActionMetadata,
	CancelTaskAction,
	CancelTaskActionMetadata,
	ClientTriggerMetadata,
	ClientTriggerParams,
	ConditionProperties,
	ConsoleAlertData,
	CreateWorkflowRequest,
	CustomWebhookActionMetadata,
	CustomWebhookData,
	EmailSharedLocationAction,
	EmailSharedLocationActionMetadata,
	EventTriggerDatetimeParamsWithValue,
	EventTriggerNumberParamsWithValue,
	GenericAction,
	NarrowActionableWpTimeWindowAction,
	NarrowActionableWpTimeWindowDataActionMetadata,
	NestedCondition,
	OptimizationAction,
	RuleCompareOperator,
	SharedLocationAction,
	SharedLocationActionMetadata,
	TopLevelCondition,
	TriggerMetadataContainer,
	TriggerParamsValueType,
	UpdateAction,
	UpdateEntityConfiguration,
	UpdateWorkflowRequest
} from '@bringg/types';
import { ConditionType } from '@bringg/react-components';
import { sortByString } from '@bringg-frontend/utils';

import { getType, OPERATORS } from 'bringg-web/features/rule-engine/utils/mapper';
import PlaceholderCondition from '../stores/placeholder-condition-store';
import {
	ActionFamilyType,
	ClientAction,
	ClientAlertAction,
	ClientAlertData,
	ClientCalculateAvailability,
	ClientCancelTaskAction,
	ClientCustomWebhook,
	ClientEmailSharedLocationAction,
	ClientGenericAction,
	ClientNarrowActionableWpTimeWindowAction,
	ClientOptimizationAction,
	ClientShareLocationAction,
	ClientUpdateEntityAction,
	ConditionValues,
	MappedAttribute,
	MappedTrigger,
	MappedTriggerData,
	Relative,
	triggerChangeNoValuesOptions,
	TriggerContainer
} from './types';
import {
	ActionsRepo,
	ArrayConditionStore,
	ConditionStore,
	RulesRepo,
	RuleStore,
	TriggerStore,
	workflowsRootStore,
	WorkflowStore
} from '../stores/internal';
import { ActionFamily } from '../stores/actions-repo';

const generateGuid = () => {
	let counter = 0;
	// eslint-disable-next-line no-plusplus
	return () => counter++;
};

export const DISPLAY_PATH_SEPARATOR = '.';

export const getRandomGuid = generateGuid();

export const mapTriggerMetadataFromServer = (
	trigger: ClientTriggerMetadata | TriggerMetadataContainer,
	family?: TriggerContainer,
	display_path = '',
	displayFamily = ''
): MappedTriggerData => {
	const id = (trigger as TriggerMetadataContainer).container || (trigger as ClientTriggerMetadata).id;
	const translatedTitle = i18next.t(trigger.translation_string);
	const newDisplayPath = display_path
		? `${display_path}${DISPLAY_PATH_SEPARATOR}${translatedTitle}`
		: translatedTitle;

	const { datatype, linking_key, values, properties, fact_type, display_fact } = trigger as ClientTriggerMetadata;
	const displayTranslatedFamily =
		displayFamily || ((trigger as TriggerMetadataContainer).container ? i18next.t(trigger.translation_string) : '');

	if ((trigger as ClientTriggerMetadata).actions?.length === 0) {
		return;
	}

	return {
		id,
		display_path: newDisplayPath,
		family,
		title: translatedTitle,
		datatype,
		linking_key,
		values,
		properties,
		factType: fact_type,
		display_fact,
		displayFamily: displayTranslatedFamily,
		actions: (trigger as ClientTriggerMetadata).actions,
		items: (trigger as TriggerMetadataContainer).items
			?.map(item =>
				mapTriggerMetadataFromServer(
					item,
					family || ((trigger as TriggerMetadataContainer).container as TriggerContainer),
					family && newDisplayPath,
					displayTranslatedFamily
				)
			)
			.filter(Boolean)
			.sort((firstItem, secondItem) => sortByString(firstItem, secondItem, 'display_path'))
	};
};

const arrayAttributeTypes = new Set([
	AttributeDatatype.Array,
	AttributeDatatype.StringArray,
	AttributeDatatype.NumberArray,
	AttributeDatatype.BooleanArray
]);

export const mapAttributeFromServer = (
	{ data_type, title, values, attributes, path, disable_sorting }: Attribute,
	factType,
	display_path = ''
): MappedAttribute => {
	const translatedTitle = i18next.t(title);
	const newDisplayPath = display_path ? `${display_path}.${translatedTitle}` : translatedTitle;
	const options = (values || []).map(({ value, key }) => ({
		value: i18next.t(value, value),
		key
	}));

	let type;
	if (!arrayAttributeTypes.has(data_type) && values) {
		type = ConditionType.LIST;
	} else {
		type = getType(data_type);
	}

	return {
		type,
		title: translatedTitle,
		path,
		options: values ? options : undefined,
		items: attributes ? attributes.map(attr => mapAttributeFromServer(attr, factType, newDisplayPath)) : undefined,
		display_path: newDisplayPath,
		identifier: title.replace(/[.]/g, '-').toLowerCase(),
		disable_sorting,
		factType,
		container: display_path
	};
};

export const conditionStoreFactory = (parent: RuleStore, condition: ConditionProperties) => {
	const isArrayStoreInstance = condition.value?.type;
	if (isArrayStoreInstance) {
		return new ArrayConditionStore(parent, condition);
	}
	return new ConditionStore(parent, condition);
};

export const mapRuleFromServer = (parent: RuleStore, rule: NestedCondition): (RuleStore | ConditionStore)[] => {
	let conditions;
	if ((rule as AllConditions).all) {
		conditions = (rule as AllConditions)?.all.map(allConditions => {
			if ((allConditions as AnyConditions).any) {
				return new RuleStore(parent.factType, parent.displayFact, allConditions as AnyConditions, null, parent);
			}
			return conditionStoreFactory(parent, allConditions as ConditionProperties);
		}) || [new ConditionStore(parent)];
	} else {
		conditions = (rule as AnyConditions)?.any.map(anyConditions => {
			if ((anyConditions as AnyConditions).any) {
				return new RuleStore(parent.factType, parent.displayFact, anyConditions as AnyConditions);
			}
			return conditionStoreFactory(parent, anyConditions as ConditionProperties);
		}) || [new ConditionStore(parent)];
	}
	return conditions;
};

export const mapTriggerToServer = (trigger: TriggerStore): ClientTriggerParams => {
	let valueType: TriggerParamsValueType | null | undefined;

	if (trigger.id) {
		valueType = trigger.changedTo;
	}

	if (valueType === TriggerParamsValueType.Changed && trigger.family !== TriggerContainer.Field_Changed) {
		valueType = undefined;
	}

	const mappedTrigger = {
		id: trigger.id,
		value: trigger.value,
		value_type: valueType,
		operator: trigger.operator,
		unit_of_time: trigger.units
	};

	Object.keys(mappedTrigger).forEach(key => {
		if (mappedTrigger[key] == null) {
			delete mappedTrigger[key];
		}
	});

	if (trigger.datatype === AttributeDatatype.Boolean && !triggerChangeNoValuesOptions.has(trigger.changedTo)) {
		mappedTrigger.value = !!trigger.value;
	}

	if (trigger.family === TriggerContainer.Date_Arrived) {
		mappedTrigger.value = Math.abs(mappedTrigger.value as number);

		if (
			!trigger.findTriggerMetadata?.properties?.disable_before_date_operator &&
			trigger.relative === Relative.before
		) {
			mappedTrigger.value *= -1;
		}
	}

	return mappedTrigger as ClientTriggerParams;
};

export const mapConditionToServer = (
	condition: RuleStore | ConditionStore | PlaceholderCondition | ArrayConditionStore
): TopLevelCondition | NestedCondition => {
	const base = {
		path: condition.path,
		fact_type: condition.factType
	};

	switch (condition.conditionMetadata?.type) {
		case ConditionType.ARRAY:
			return {
				...base,
				operator: (condition as ConditionStore).operator,
				value: (condition as ConditionStore).value
			};
		case ConditionType.BOOLEAN:
			return {
				...base,
				operator: OPERATORS.IN,
				value: (condition as ConditionStore).value
			};
		case ConditionType.LIST:
			return {
				...base,
				operator: (condition as ConditionStore).operator,
				value: (condition as ConditionStore).value
			};
		case ConditionType.DATETIME:
			return {
				...base,
				operator: (condition as ConditionStore).operator,

				// If not comparable operator has been selected, we don't want to send the comparable value
				value: condition.comparable
					? {
							path: condition.comparable,
							fact_type: condition.factType
					  }
					: null
			};
		case ConditionType.STRING_ARRAY:
		case ConditionType.NUMBER_ARRAY:
		case ConditionType.BOOLEAN_ARRAY:
			return {
				...base,
				operator: (condition as ArrayConditionStore).operator || RuleCompareOperator.ARRAY_ELEMENT_IN,
				value: {
					value: (condition as ArrayConditionStore).value,
					type: (condition as ArrayConditionStore).arrayValueType
				}
			};
		case ConditionType.NUMBER:
		case ConditionType.STRING:
			if (condition instanceof RuleStore) {
				return {
					any: (condition as RuleStore).conditions.map(cond => mapConditionToServer(cond))
				};
			}
			if (condition instanceof ConditionStore) {
				return {
					...base,
					operator: condition.operator,
					value: getAtomicConditionValue(condition)
				};
			}
			return {
				any: []
			};

		default:
			return {
				any: []
			};
	}
};

const getAtomicConditionValue = (condition: ConditionStore): ConditionValues => {
	if (condition.comparable) {
		return {
			path: condition.comparable,
			fact_type: condition.factType
		};
	}
	return condition.operator === OPERATORS.IN_RANGE ? [condition.value[0], condition.value[1]] : condition.value;
};

const mapRulesToServer = (rules: RulesRepo) => {
	return {
		any: rules.rules.map(rule => ({
			all: rule.conditions.filter(condition => !!condition.path).map(condition => mapConditionToServer(condition))
		}))
	};
};

const mapNewWorkflow = (workflow: WorkflowStore, base: Partial<CreateWorkflowRequest>): CreateWorkflowRequest => {
	return {
		...base,
		template_id: workflow.template_id
	} as CreateWorkflowRequest;
};

const mapExistingWorkflow = (workflow: WorkflowStore, base: CreateWorkflowRequest): UpdateWorkflowRequest => {
	return {
		...base,
		id: workflow.id
	};
};

export const mapBaseWorkflowToServer = (workflow: WorkflowStore): CreateWorkflowRequest | UpdateWorkflowRequest => {
	const { id, title, enabled, team_ids, trigger, rules, actions } = workflow;
	const base = {
		title,
		enabled,
		team_ids,
		trigger: mapTriggerToServer(trigger),
		rule: mapRulesToServer(rules),
		actions: mapActionsToServer(actions)
	};
	if (id) {
		return mapExistingWorkflow(workflow, base);
	}
	return mapNewWorkflow(workflow, base);
};

export const mapActionsToServer = (actionsRepo: ActionsRepo): ActionCoreData[] => {
	return actionsRepo.actions.reduce((acc, action) => {
		const mapped = action.mappedToServer;
		if (Array.isArray(mapped)) {
			acc.push(...mapped);
		} else {
			acc.push(mapped);
		}
		return acc;
	}, []);
};

export const mapTriggerFromServer = (trigger: ClientTriggerParams, metadata?: MappedTriggerData): MappedTrigger => {
	const value =
		metadata?.family === TriggerContainer.Date_Arrived && trigger.value != null
			? Math.abs(trigger.value as number)
			: trigger.value;
	let changedTo = (trigger as EventTriggerNumberParamsWithValue).value_type ?? TriggerParamsValueType.Changed;

	if (
		metadata?.family === TriggerContainer.Field_Changed &&
		metadata?.datatype === AttributeDatatype.Boolean &&
		value != null
	) {
		changedTo = TriggerParamsValueType.ChangedTo;
	}

	if (metadata?.family !== TriggerContainer.Field_Changed && changedTo === TriggerParamsValueType.Changed) {
		changedTo = undefined;
	}
	return {
		id: trigger.id,
		changedTo,
		units: (trigger as EventTriggerDatetimeParamsWithValue).unit_of_time,
		relative: (trigger as EventTriggerNumberParamsWithValue).value < 0 ? Relative.before : Relative.after,
		operator: (trigger as EventTriggerNumberParamsWithValue).operator,
		value
	};
};

export const notifyActionTypes = [ActionType.WEBHOOK, ActionType.ALERT, ActionType.INTERNAL_SMS, ActionType.EMAIL];

const mapUpdateEntityAction = (_action: ActionCoreData): ClientUpdateEntityAction => ({
	type: ActionFamilyType.UPDATE_ENTITY,
	actions: (_action.data as UpdateEntityConfiguration).configurations.map(({ identifier, data }) => ({
		identifier,
		data
	}))
});

const mapAlertAction = (_action: ActionCoreData, alert: ClientAlertAction): ClientAlertAction => {
	const mappedAlert: ClientAlertAction = alert || {
		type: ActionFamilyType.NOTIFY,
		actions: []
	};

	if (_action.action_type === ActionType.ALERT) {
		if (_action.data.severity) {
			mappedAlert.severity = _action.data.severity;
		}
		if (_action.data.description_rich_text) {
			mappedAlert.description_rich_text = _action.data.description_rich_text;
		}

		mappedAlert.title = _action.data.title;
		mappedAlert.actions.push({
			guid: getRandomGuid(),
			action_type: _action.action_type,
			data: _action.data
		});

		return mappedAlert;
	}

	if (_action.action_type === ActionType.INTERNAL_SMS || _action.action_type === ActionType.EMAIL) {
		mappedAlert.title = _action.data.title;
		mappedAlert.actions.push({
			guid: getRandomGuid(),
			action_type: _action.action_type as ActionType.INTERNAL_SMS,
			data: _action.data
		});

		return mappedAlert;
	}

	if (_action.action_type === ActionType.WEBHOOK) {
		mappedAlert.actions.push({
			guid: getRandomGuid(),
			action_type: _action.action_type,
			data: _action.data
		});

		return mappedAlert;
	}
	return mappedAlert;
};

const mapSharedLocationAction = (_action: SharedLocationAction): ClientShareLocationAction => ({
	type: ActionFamilyType.COSTUMER_NOTIFICATION,
	actionType: ActionType.SHARED_LOCATION,
	translation: _action.data.sms.translation,
	sharingMethod: _action.data.sharing_method,
	privateMode: _action.data.private_mode
});

const mapEmailSharedLocationAction = (_action: EmailSharedLocationAction): ClientEmailSharedLocationAction => ({
	type: ActionFamilyType.EMAIL_COSTUMER_NOTIFICATION,
	actionType: ActionType.EMAIL_SHARED_LOCATION,
	template_id: _action.data.template_id
});

const mapNarrowActionableWpTimeWindowAction = (
	_action: NarrowActionableWpTimeWindowAction
): ClientNarrowActionableWpTimeWindowAction => ({
	type: ActionFamilyType.NARROW_ACTIONABLE_WP_TIME_WINDOW,
	action_type: ActionType.NARROW_ACTIONABLE_WP_TIME_WINDOW,
	bufferInMinutes: _action.data.buffer_in_minutes
});

const mapGenericAction = (_action: ActionCoreData, genericAction?: ClientGenericAction): ClientGenericAction => {
	const mappedAction: ClientGenericAction = {
		type: ActionFamilyType.GENERIC_ACTION,
		actions: genericAction?.actions || []
	};

	mappedAction.actions.push({
		guid: getRandomGuid(),
		action_type: _action.action_type,
		data: null
	} as ActionCoreData);

	return mappedAction;
};

const mapOptimizationAction = (action: OptimizationAction): ClientOptimizationAction => {
	return {
		type: ActionFamilyType.OPTIMIZATION,
		action_type: action.action_type,
		data: { ...action.data }
	};
};

const mapCancelTaskAction = (action: CancelTaskAction): ClientCancelTaskAction => {
	return {
		type: ActionFamilyType.CANCEL_TASK,
		action_type: action.action_type,
		data: { ...action.data }
	};
};

const mapCustomWebhookAction = (action: ActionCoreData): ClientCustomWebhook => {
	return {
		type: ActionFamilyType.CUSTOM_WEBHOOK,
		action_type: action.action_type,
		data: { ...action.data } as CustomWebhookData
	};
};

const mapCalculateAvailabilityAction = (action: CalculateAvailabilityAction): ClientCalculateAvailability => {
	return {
		type: ActionFamilyType.CALCULATE_AVAILABILITY_STATE,
		action_type: action.action_type,
		data: { ...action.data }
	};
};

const isWebhookAction = (action: ActionCoreData): boolean => {
	const { metadataRepo } = workflowsRootStore.getStore();
	return metadataRepo.metadata.actions.find(_action => _action.actionType === action.action_type)?.isWebhook;
};

export const mapActionsFromServer = (actions: ActionCoreData[]): { [key in ActionFamilyType]: ClientAction } =>
	actions.reduce((acc, _action) => {
		if (_action.action_type === ActionType.ENTITY_UPDATE) {
			acc.updateEntity = mapUpdateEntityAction(_action);
		} else if (notifyActionTypes.includes(_action.action_type)) {
			acc.alert = mapAlertAction(_action, acc.alert as ClientAlertAction);
		} else if (_action.action_type === ActionType.SHARED_LOCATION) {
			acc.sharedLocation = mapSharedLocationAction(_action);
		} else if (_action.action_type === ActionType.EMAIL_SHARED_LOCATION) {
			acc.emailSharedLocation = mapEmailSharedLocationAction(_action);
		} else if (_action.action_type === ActionType.OPTIMIZATION) {
			acc.optimization = mapOptimizationAction(_action);
		} else if (_action.action_type === ActionType.CANCEL_TASK) {
			acc.cancelTask = mapCancelTaskAction(_action);
		} else if (_action.action_type === ActionType.CALCULATE_AVAILABILITY_STATE) {
			acc.calculateAvailabilityState = mapCalculateAvailabilityAction(_action);
		} else if (_action.action_type === ActionType.NARROW_ACTIONABLE_WP_TIME_WINDOW) {
			acc.narrowActionableWpTimeWindow = mapNarrowActionableWpTimeWindowAction(_action);
		} else if (isWebhookAction(_action)) {
			acc.customWebhook = mapCustomWebhookAction(_action);
		} else {
			acc.genericAction = mapGenericAction(_action, acc.genericAction as ClientGenericAction);
		}
		return acc;
	}, {} as { [key in ActionFamilyType]: ClientAction });

export const mapActions = (actions: ActionsMetadata): ActionFamily[] => {
	return Object.entries(actions).reduce((acc, [actionKey, actionMetadataData]) => {
		if (actionKey === ActionKey.UPDATE) {
			Object.entries((actionMetadataData as UpdateAction).itemsByFact).forEach(([factType, items]) => {
				if (items.length > 0) {
					const entityTranslation = i18next.t(`RULE_ENGINE.FACTS.${factType?.toUpperCase()}.TITLE`);
					acc.push({
						disabled: (actionMetadataData as UpdateAction).is_read_only,
						title: `${i18next.t(
							(actionMetadataData as UpdateAction).translation_string
						)} ${entityTranslation}`,
						actionType: ActionType.ENTITY_UPDATE,
						type: actionKey,
						factType
					});
				}
			});
		} else if (actionKey === ActionKey.GENERIC_ACTION) {
			Object.entries((actionMetadataData as GenericAction).itemsByFact).forEach(([factType, items]) => {
				(items || []).forEach(genericAction => {
					acc.push({
						disabled: genericAction.is_read_only,
						title: i18next.t(genericAction.translation_string),
						type: actionKey,
						actionType: genericAction.action_type,
						factType
					});
				});
			});
		} else if (actionKey === ActionKey.SHARED_LOCATION) {
			Object.entries(
				(actionMetadataData as AddReadOnly<SharedLocationActionMetadata>).placeholdersByFact
			).forEach(([factType, items]) => {
				if (items.length) {
					acc.push({
						disabled: (actionMetadataData as AddReadOnly<SharedLocationActionMetadata>).is_read_only,
						title: i18next.t(
							(actionMetadataData as AddReadOnly<SharedLocationActionMetadata>).translation_string
						),
						type: actionKey,
						actionType: ActionType.SHARED_LOCATION,
						factType
					});
				}
			});
		} else if (actionKey === ActionKey.CANCEL_TASK) {
			acc.push({
				disabled: (actionMetadataData as AddReadOnly<CancelTaskActionMetadata>).is_read_only,
				title: i18next.t((actionMetadataData as AddReadOnly<CancelTaskActionMetadata>).translation_string),
				type: actionKey,
				actionType: ActionType.CANCEL_TASK
			});
		} else if (actionKey === ActionKey.CALCULATE_AVAILABILITY_STATE) {
			acc.push({
				disabled: (actionMetadataData as AddReadOnly<CalculateAvailabilityStateActionMetadata>).is_read_only,
				title: i18next.t(
					(actionMetadataData as AddReadOnly<CalculateAvailabilityStateActionMetadata>).translation_string
				),
				type: actionKey,
				actionType: ActionType.CALCULATE_AVAILABILITY_STATE
			});
		} else if (actionKey === ActionKey.NARROW_ACTIONABLE_WP_TIME_WINDOW) {
			acc.push({
				disabled: (actionMetadataData as AddReadOnly<NarrowActionableWpTimeWindowDataActionMetadata>)
					.is_read_only,
				title: i18next.t(
					(actionMetadataData as AddReadOnly<NarrowActionableWpTimeWindowDataActionMetadata>)
						.translation_string
				),
				type: actionKey,
				actionType: ActionType.NARROW_ACTIONABLE_WP_TIME_WINDOW
			});
		} else if (actionKey === ActionKey.EMAIL_SHARED_LOCATION) {
			acc.push({
				disabled: (actionMetadataData as AddReadOnly<EmailSharedLocationActionMetadata>).is_read_only,
				title: i18next.t(
					(actionMetadataData as AddReadOnly<EmailSharedLocationActionMetadata>).translation_string
				),
				type: actionKey,
				actionType: ActionType.EMAIL_SHARED_LOCATION
			});
		} else if ((actionMetadataData as AddReadOnly<CustomWebhookActionMetadata>).is_webhook) {
			acc.push({
				disabled: (actionMetadataData as AddReadOnly<CustomWebhookActionMetadata>).is_read_only,
				title: i18next.t((actionMetadataData as AddReadOnly<CustomWebhookActionMetadata>).translation_string),
				type: ActionFamilyType.CUSTOM_WEBHOOK,
				isWebhook: true,
				actionType: (actionMetadataData as AddReadOnly<CustomWebhookActionMetadata>).action_type
			});
		}

		return acc;
	}, []);
};

export const alertActionsWithTitle = [ActionType.ALERT, ActionType.EMAIL, ActionType.INTERNAL_SMS];

export type MapAlertActionProps = {
	actions: ClientAlertData[];
	severity: AlertSeverity;
	title: string;
	description_rich_text?: string;
};
export const mapAlertActions = ({
	actions,
	severity,
	title,
	description_rich_text
}: MapAlertActionProps): ActionCoreData[] => {
	return actions.reduce((acc, alertAction) => {
		const base = {
			data: { ...alertAction.data },
			action_type: alertAction.action_type
		};

		if (alertAction.action_type === ActionType.ALERT) {
			(base.data as ConsoleAlertData).severity = severity;
			if (description_rich_text) {
				(base.data as ConsoleAlertData).description_rich_text = description_rich_text;
			} else {
				delete (base.data as ConsoleAlertData).description_rich_text;
			}
		}

		if (alertActionsWithTitle.includes(alertAction.action_type)) {
			(base.data as ConsoleAlertData).title = title;
		}

		acc.push(base);
		return acc;
	}, []);
};
