import { LatLng, datetime, Location as BringgLocation } from '@bringg/types';

export type Anchor = 'center' | 'left' | 'right' | 'top' | 'bottom';
export const defaultAnchor: Anchor = 'bottom';
export type IdType = string | number;
export type MapboxIconOptions = { pixelRatio?: number; sdf?: boolean };

export type Size = {
	width: number;
	height: number;
};

export interface MarkerData {
	id: IdType;
	groupId?: IdType;
	location: BringgLocation;
	color?: string;
	textColor?: string;
	text?: string;
	anchor?: Anchor;
	icon?: JSX.Element | string;
	iconOptions?: MapboxIconOptions;
	size?: Size;
}

export type RouteLocation = {
	lat: number;
	lng: number;
	happened_at: datetime;
};

type BringgMapEntityExtention = {
	shouldIncludeInBounds?: boolean;
	shouldAutoFocus?: boolean;
};

export enum TriggerType {
	CLICK = 'click',
	MOUSEOVER = 'mouseover'
}

type TooltipOptions = {
	trigger?: TriggerType;
};

export type ExtendedMarkerData = BringgMapEntityExtention &
	MarkerData & {
		plainSvg?: string;
		zIndex?: number;
		tooltip?: Element | string;
		tooltipOptions?: TooltipOptions;
		opacity?: number;
		title?: string;
	};
export type ExtendedPolylineData = BringgMapEntityExtention & {
	id: IdType;
	path: RouteLocation[];
	color?: string;
	strokeOpacity?: number;
	strokeWeight?: number;
	onMouseover?: (polyline: google.maps.Polyline) => void;
	onMouseout?: (polyline: google.maps.Polyline) => void;
};
export type BringgMapTranslations = {
	noRouteLocations?: string;
	liveTracking?: string;
	traffic?: string;
};
export type MapOptionsProps = {
	markers?: ExtendedMarkerData[];
	polylines?: ExtendedPolylineData[];
	mapCenter?: LatLng;
	mapZoom?: number;
	maxZoom?: number;
	minZoom?: number;
	isPlaying?: boolean;
	forceRecenterOnAddingMarkers?: boolean; // coordinate change wont trigger recenter
	forceRecenterOnUpdatingMarkers?: boolean;
	mapElementClassName?: string;
	mapContainerClassName?: string;
	fullscreenControl?: boolean;
	allowTrafficLayer?: boolean;
	translations?: BringgMapTranslations;
	buttons?: ButtonPropType[];
	['data-test-id']?: string;
	selectedMarkers?: IdType[];
	onSelectMarker?: (isCtrlClick: boolean, markerId: IdType) => void;
	clearSelectedMarkers?: () => void;
	onMapClick?: (e: google.maps.MapMouseEvent) => void;
	boundsPadding?: number;
	options?: google.maps.MapOptions;
};

export type BringgMapPropsV2 = Omit<MapOptionsProps, 'isPlaying'> & {
	disablePlayer?: boolean;
	showPlayer?: boolean;
	timeZone: string;
	timeFormat: string;
	onMarkerClick?: (markerId: IdType, isCtrlClick: boolean) => void;
	onMapClick?: (e: google.maps.MapMouseEvent) => void;
	boundsPadding?: number;
	options?: google.maps.MapOptions;
};

export type ButtonPropType = {
	text: string;
	['data-test-id']: string;
	className?: string;
	clickHandler: (e: MouseEvent) => void;
};
