import React from 'react';

import './cluster-item.scss';

interface Props {
	content: string;
}

export const ClusterItem = React.memo(({ content }: Props) => {
	return (
		<span className="cluster-item-component cluster-item">
			<span className="cluster-item-text">{content}</span>
		</span>
	);
});
