import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

export function renameTranslationProp() {
	return function (WrappedComponent: React.ComponentType<any>) {
		class RenamedTranslate extends Component<any> {
			render() {
				const { t } = this.props;
				return <WrappedComponent {...this.props} translate={t} />;
			}
		}
		return withTranslation()(RenamedTranslate);
	};
}

export default function translate() {
	return function (component: React.ComponentType<any>): React.ComponentType<any> {
		return renameTranslationProp()(component);
	};
}
