import { ActionType } from '@bringg/types';
import { FilterBar2Values } from '@bringg/react-components';

import { workflowsRootStore } from '../stores/workflows-root-store';

export const webhookSpecialKey = 123456; // a key to mark all sendWebhook action

const getSendWebhookActions = (): ActionType[] => {
	const { metadataRepo } = workflowsRootStore.getStore();
	return metadataRepo.metadata.actions.filter(action => action.isWebhook).map(({ actionType }) => actionType);
};

export const mapGroupKeyToSendWebhookActions = (filterObject: FilterBar2Values): FilterBar2Values => {
	if (filterObject.actions && (filterObject.actions as number[]).includes(webhookSpecialKey)) {
		const mapped = { ...filterObject };

		mapped.actions = (mapped.actions as number[]).filter(id => id !== webhookSpecialKey);
		mapped.actions.push(...getSendWebhookActions());

		return mapped;
	}

	return filterObject;
};

export const mapSendWebhookToGroupKey = (filterObject: FilterBar2Values): FilterBar2Values => {
	const actions = getSendWebhookActions();

	if (filterObject.actions && (filterObject.actions as number[]).find(item => actions.includes(item))) {
		const mapped = { ...filterObject };

		mapped.actions = (mapped.actions as number[]).filter(item => !actions.includes(item));
		mapped.actions.push(webhookSpecialKey);

		return mapped;
	}

	return filterObject;
};
