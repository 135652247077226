import React, { useEffect, useRef, useState } from 'react';

import classNames from 'classnames';

import { BringgMapTranslations, RouteLocation } from '../types';
import { isLatLngValid } from '../utils';
import BringgMapRoutePlayerSlider from './bringg-map-route-player-slider/bringg-map-route-player-slider';
import BringgMapRoutePlayerControls from './bringg-map-route-player-controls/bringg-map-route-player-controls';
import BringgMapRoutePlayerTimes from './bringg-map-route-player-times/bringg-map-route-player-times';
import useBringgMapRoutePlayerInit from './custom-hooks/bringg-map-route-player-hooks/use-bringg-map-route-player-init/use-bringg-map-route-player-init';
import { UPDATE_DRIVER_LOCATION_THROTTLE_MS } from './consts';

import styles from './bringg-map-route-player.module.scss';

type BringgMapRoutePlayerProps = {
	disablePlayer?: boolean;
	hasRealtimeDriverLocation: boolean;
	initialRouteLocations: RouteLocation[];
	timeZone: string;
	timeFormat: string;
	translations: BringgMapTranslations;
	setRoutePlayerDriverLocation: any;
	onTogglePlaying: (isPLaying: boolean) => void;
};

const BringgMapRoutePlayerContainer = ({
	disablePlayer,
	hasRealtimeDriverLocation,
	initialRouteLocations,
	timeZone,
	timeFormat,
	setRoutePlayerDriverLocation,
	translations,
	onTogglePlaying
}: BringgMapRoutePlayerProps) => {
	const timeoutInstanceRef = useRef<NodeJS.Timeout | null>(null);
	const shouldSetDriverMarkerLocation = useRef(true);
	const [routeIndex, setRouteIndex] = useState<number>(0);

	const { route } = useBringgMapRoutePlayerInit(initialRouteLocations);

	const onUpdateDriverMarkerLocation = (customRouteIndex?: number) => {
		if (!route?.length || (!shouldSetDriverMarkerLocation.current && customRouteIndex === undefined)) return;

		shouldSetDriverMarkerLocation.current = false;
		timeoutInstanceRef.current = setTimeout(
			() => (shouldSetDriverMarkerLocation.current = true),
			UPDATE_DRIVER_LOCATION_THROTTLE_MS
		);
		const newRouteIndex = customRouteIndex !== undefined ? customRouteIndex : routeIndex;
		const newDriverLocation = {
			lat: route[newRouteIndex]?.lat,
			lng: route[newRouteIndex]?.lng
		};
		if (!isLatLngValid(newDriverLocation)) return;
		setRoutePlayerDriverLocation(newDriverLocation);
	};

	useEffect(() => {
		shouldSetDriverMarkerLocation.current = true;
		onUpdateDriverMarkerLocation();
		return () => {
			timeoutInstanceRef.current && clearTimeout(timeoutInstanceRef.current);
		};
	}, [route]);

	const isRouteTrackerDisabled = route.length < 2 || !!hasRealtimeDriverLocation || disablePlayer;

	return (
		<>
			<BringgMapRoutePlayerSlider
				routeLocations={route}
				routeIndex={routeIndex}
				onChangeRouteIndex={setRouteIndex}
				timeFormat={timeFormat}
				timeZone={timeZone}
				disabled={!!isRouteTrackerDisabled}
			/>
			{disablePlayer ? (
				<span className={classNames(styles.noRouteLocations)}>
					{hasRealtimeDriverLocation ? translations.liveTracking : translations.noRouteLocations}
				</span>
			) : (
				<BringgMapRoutePlayerTimes routeLocations={route} timeFormat={timeFormat} timeZone={timeZone} />
			)}
			<BringgMapRoutePlayerControls
				routeLocations={route}
				disabled={!!disablePlayer}
				routeIndex={routeIndex}
				onRouteIndexChange={setRouteIndex}
				onUpdateDriverMarkerLocation={onUpdateDriverMarkerLocation}
				onTogglePlaying={onTogglePlaying}
			/>
		</>
	);
};

export default BringgMapRoutePlayerContainer;
