import { useTranslation } from 'react-i18next';
import { omitBy as _omitBy, isNil as _isNil } from 'lodash';
import { FilterBar2, FilterBar2Values, getDefaultFilterBarTranslations } from '@bringg/react-components';
import { FloatingInventoriesRequest } from '@bringg/types';

import { FloatingInventoriesFilterItems } from './floating-inventories-filters.types';

import { buildFilterObject } from '.';

interface Props {
	getFilterResults: (currentFilter: FloatingInventoriesRequest) => void;
	initialValues: FilterBar2Values;
	onResetFilter: (field?: string) => void;
	filterItems: FloatingInventoriesFilterItems;
}

const FloatingInventoriesFilters = ({ getFilterResults, initialValues, onResetFilter, filterItems }: Props) => {
	const { t } = useTranslation();

	const getCurrentValues = () => ({
		...initialValues
	});

	const onChange = (query: { [key: string]: any }) => {
		getFilterResults(query as FloatingInventoriesRequest);
	};

	return (
		<FilterBar2
			list={buildFilterObject(t, filterItems)}
			initialValues={_omitBy(getCurrentValues(), _isNil)}
			translations={getDefaultFilterBarTranslations(t)}
			onFilter={onChange}
			onReset={onResetFilter}
		>
			{() => <></>}
		</FilterBar2>
	);
};

export default FloatingInventoriesFilters;
