import CrossAppTransport from '@bringg/dashboard-sdk/dist/CrossAppTranport/CrossAppTransport';

export const registerAction = (
	action: string,
	callback: (...args: any[]) => any,
	crossAppTransport: CrossAppTransport
): (() => void) => {
	return crossAppTransport.on(action, callback);
};

export const executeAction = (crossAppTransport: CrossAppTransport, action: string, ...args: any[]) => {
	crossAppTransport.emit(action as any, ...args);
};
