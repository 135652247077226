import React from 'react';
import { TimeService } from '@bringg/frontend-utils';
import _find from 'lodash/find';
import { useTranslation } from 'react-i18next';
import DeliveryBlock from '../stores/domain-objects/delivery-block';
import TimezoneService from '../../../services/timezone/timezone-service';
import { useMerchantConfiguration } from '../../../recipes';
import { DeliveryBlockBreakId } from '../stores/domain-objects/delivery-block-break';
import { useHasCombinedOptimization } from 'bringg-web/features/delivery-blocks-v2/utils/use-has-combined-optimization';

interface Props {
	deliveryBlock: DeliveryBlock;
	breakId?: DeliveryBlockBreakId;
	recharge?: { startTime: string; endTime: string };
}

const DELIVERY_BLOCK_TIME_FORMAT = 'hh:mm A';

const DeliveryBlockTimelineTooltip: React.FC<Props> = ({ deliveryBlock, breakId, recharge }: Props) => {
	const { hour_format: hoursFormat } = useMerchantConfiguration();
	const { t } = useTranslation();
	const isCombinedOptimization = useHasCombinedOptimization(deliveryBlock.team);

	const { name, capacity, original_capacity, start_time, end_time, delivery_block_breaks, delivery_block_resources } =
		deliveryBlock;

	const deliveryBlockBreak = _find(delivery_block_breaks, { id: breakId });

	const title = recharge
		? t(`GLOBAL.RECHARGE`)
		: deliveryBlockBreak
		? t('GLOBAL.BREAK')
		: `${name} (${isCombinedOptimization ? delivery_block_resources.length : capacity}/${original_capacity})`;

	const timesToDisplay = recharge
		? { startTime: recharge.startTime, endTime: recharge.endTime }
		: deliveryBlockBreak
		? { startTime: deliveryBlockBreak.start_time, endTime: deliveryBlockBreak.end_time }
		: { startTime: start_time, endTime: end_time };

	const momentTz = TimezoneService.getMomentTimezone(deliveryBlock.teamLocalizationTimezone);

	const formatTimes = (startTime: string, endTime: string) => {
		return `${TimeService.format(
			momentTz(startTime),
			DELIVERY_BLOCK_TIME_FORMAT,
			hoursFormat
		)} - ${TimeService.format(momentTz(endTime), DELIVERY_BLOCK_TIME_FORMAT, hoursFormat)}`;
	};

	return (
		<div className="delivery-block-timeline-tooltip">
			<span className="text">{title}</span>
			<span className="text">{formatTimes(timesToDisplay.startTime, timesToDisplay.endTime)}</span>
		</div>
	);
};

export default DeliveryBlockTimelineTooltip;
