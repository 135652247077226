import { getEnv } from 'mobx-easy';
import { registerAction, RootEnv } from '@bringg-frontend/global-stores';

import CollapseLinkedTasksService from './collapse-linked-tasks-service';

export const GENERATE_COLLAPSE_LINKED_TASKS_SERVICE = 'generate_collapse_linked_tasks_service';
export default class CollapseLinkedTasksListenerStore {
	constructor() {
		registerAction(
			GENERATE_COLLAPSE_LINKED_TASKS_SERVICE,
			() => {
				return new CollapseLinkedTasksService();
			},
			getEnv<RootEnv>().dashboardSdk.sdk.crossAppTransport
		);
	}
}
