import { cloneDeep, isEmpty } from 'lodash';

import {
	EnrichedTeamsTreeData,
	GenericParameter,
	OptimizationConfigurationManifestTypes,
	OptimizationConfigurationView,
	TeamsTreeData
} from './types';

export const enrichTeamsTreeData = (
	teamsTree: TeamsTreeData,
	teamConfigurationMap: Map<number, number>
): EnrichedTeamsTreeData[] => {
	const newTree = cloneDeep(teamsTree);

	(function addOptimizations(treeData: TeamsTreeData) {
		if (!treeData || treeData.length === 0) {
			return;
		}

		for (const node of treeData) {
			const { value, children } = node;

			node['optimizationId'] = teamConfigurationMap.get(value);

			if (children && children.length > 0) {
				addOptimizations(children);
			}
		}
	})(newTree);

	return newTree;
};

function getKeyValueFromAttribute(attribute: GenericParameter) {
	let defaultValue = attribute.default;

	if (
		attribute.type === OptimizationConfigurationManifestTypes.DROPDOWN ||
		attribute.type === OptimizationConfigurationManifestTypes.CONDITIONAL_TIME_INPUT
	) {
		defaultValue = attribute.options.find(option => option.default)?.id;
	}

	return { [attribute.id]: defaultValue };
}

export const getValuesWithDefaults = (manifest: GenericParameter[]) => {
	return manifest.reduce((acc, attribute) => {
		const nextValue = getKeyValueFromAttribute(attribute);

		// Include default value for fields from `sub_fields_if_enabled` array if default value is TRUE
		if (
			attribute.default &&
			(attribute.type === OptimizationConfigurationManifestTypes.CHECKBOX ||
				attribute.type === OptimizationConfigurationManifestTypes.TOGGLE) &&
			attribute.sub_fields_if_enabled
		) {
			Object.assign(
				nextValue,
				attribute.sub_fields_if_enabled.reduce((obj, attr) => {
					return Object.assign(obj, getKeyValueFromAttribute(attr));
				}, {})
			);
		}

		return Object.assign(acc, nextValue);
	}, {});
};

export const isAllAttributesValid = (values: OptimizationConfigurationView) => {
	const manifestElementsValid = values.configuration.every(attribute => attribute.isValid);

	if (values?.defaultConfiguration) {
		return manifestElementsValid;
	}

	return !isEmpty(values.configurationName) && !isEmpty(values.teams) && manifestElementsValid;
};
