import { computed, observable, makeObservable, action } from 'mobx';
import { getEnv, getRoot } from 'mobx-easy';
import { MerchantData } from '@bringg/types';
import { LoginResponse } from '@bringg/react-components/dist/components/login/login';

import { RootEnv } from '../root-env';
import { RootStore } from '../root-store-types';
import { getSharedRootStore } from '../root-store';

interface AuthStore {
	setLogin: (flag: boolean) => void;
}

class AuthStore {
	isLoggedIn = false;

	constructor() {
		makeObservable(this, {
			isLoggedIn: observable,
			recaptchaSiteKey: computed,
			merchant: computed,
			mapboxAccessToken: computed,
			crossAppTransport: computed,
			setLogin: action
		});
	}

	setLogin = (isLoggedIn: boolean) => {
		this.isLoggedIn = isLoggedIn;
	};

	get recaptchaSiteKey() {
		return getEnv<RootEnv>().envConfig.recaptcha_site_key;
	}

	get merchant() {
		return getEnv<RootEnv>().dashboardSdk.sdk.session.merchant;
	}

	get mapboxAccessToken() {
		return getEnv<RootEnv>().envConfig.mapbox_access_token;
	}

	get crossAppTransport() {
		return getEnv<RootEnv>().dashboardSdk.sdk.crossAppTransport;
	}

	async initSdkFromLocalEnvironment() {
		try {
			const { dashboardSdk } = getEnv<RootEnv>();
			await dashboardSdk.init();

			if (dashboardSdk.sdk) {
				await this.afterLoginEvents();
				this.setLogin(true);
			}
		} catch (e) {
			console.error('Failed to init SDK in auth store', e);
		}
	}

	attemptToLogin = async (
		email: string,
		password: string,
		selectedMerchant?: MerchantData,
		captcha?: string
	): Promise<LoginResponse> => {
		const result = await getEnv<RootEnv>().authService.attemptToLogin(email, password, selectedMerchant, captcha);

		if (result.success) {
			await this.afterLoginEvents();
		}

		return result;
	};

	async afterLoginEvents() {
		const rootStore = getSharedRootStore();
		const { sdk } = getEnv<RootEnv>().dashboardSdk;

		rootStore.data.usersStore.setCurrentUser(sdk.session.user);
		await rootStore.data.merchantConfigurationsStore.fetch();
		await sdk.merchant.get();
	}
}

export { AuthStore };
