import { action, computed, observable, makeObservable } from 'mobx';
import { Connection } from '@bringg/types';
import _filter from 'lodash/filter';
import _isNil from 'lodash/isNil';
import _reject from 'lodash/reject';
import { GetAllDriversOptions } from '@bringg/dashboard-sdk/dist/User/Users';
import { KeysetPaginationRequest } from '@bringg/dashboard-sdk/dist/utils/consts/keyset-pagination.consts';
import moment from 'moment';
import { getRootEnv } from '@bringg-frontend/bringg-web-infra';
import { CommonOptions } from '@bringg/dashboard-sdk/dist/Core/RouteGenerator';

import ShiftActionsConfiguration from 'bringg-web/stores/actions-configuration/domain-objects/shift-actions-configuration';
import Driver from './domain-object/driver';
import BaseDomainStore from '../core/base-domain-store';

class DriversStore extends BaseDomainStore<Driver> {
	driversByTeam: Map<number, Driver[]> = new Map();

	constructor() {
		super();

		makeObservable(this, {
			driversByTeam: observable,
			setByTeam: action,
			onlineDrivers: computed,
			onShiftDrivers: computed
		});
	}

	afterUserLoggedIn = () => {
		this.subscribeToEvents();
	};

	afterUserLoggedOut = () => {
		const { dashboardSdk } = getRootEnv();

		dashboardSdk.sdk.users.unsubscribe();
	};

	fetchAll = async (params: KeysetPaginationRequest<GetAllDriversOptions> = {}) => {
		const driversResponse = await getRootEnv().dashboardSdk.sdk.users.getAllDrivers(params);
		this.setBatch(driversResponse.users.map(driver => new Driver(this, driver)));
		return this.all;
	};

	loadMany = async (ids: number[], options?: CommonOptions) => {
		const users = await getRootEnv().dashboardSdk.sdk.users.getMany(ids, options);

		this.setBatch(users.filter(user => !this.get(user.id)).map(driver => new Driver(this, driver)));

		return users.map(user => this.get(user.id));
	};

	fetch = async (id: number) => {
		const driverResponse = await getRootEnv().dashboardSdk.sdk.users.get(id);
		const driver = new Driver(this, driverResponse);

		this.set(driver);
		return driver;
	};

	startDriverShift = async (driverId: number, selectedTeam?: number) => {
		try {
			// why do we need to create Configuration instance here? just to call start()?
			const shift = new ShiftActionsConfiguration({});
			await shift.start(driverId, selectedTeam);
			return true;
		} catch (e) {
			console.error(e);
			return false;
		}
	};

	setByTeam = (teamId: number, drivers: Driver[]) => {
		this.driversByTeam.set(teamId, drivers);
	};

	allByTeam = (teamId: number): Driver[] => {
		return computed(() => this.driversByTeam.get(teamId)).get();
	};

	allByTeamAndCompany = (teamId: number, companyId: number): Driver[] => {
		const driversByTeam = computed(() => this.driversByTeam.get(teamId)).get();
		return driversByTeam?.filter(driver => driver.company_id === companyId);
	};

	fetchByTeam = async (teamId: number) => {
		if (this.driversByTeam.has(teamId)) {
			return this.allByTeam(teamId);
		}

		const drivers = await getRootEnv().dashboardSdk.sdk.users.getDriversByTeam(teamId);
		const storeDrivers: Driver[] = [];

		drivers.forEach(driver => {
			const storeDriver = new Driver(this, driver);
			this.set(storeDriver);
			storeDrivers.push(storeDriver);
		});

		this.setByTeam(teamId, storeDrivers);

		return storeDrivers;
	};

	fetchDriverTodayDeliveryBlock = async (driverId: number) => {
		const deliveryBlock = await getRootEnv().dashboardSdk.sdk.deliveryBlocks.getByDriverId(
			driverId,
			moment().startOf('day')?.unix(),
			moment().endOf('day')?.unix()
		);
		return deliveryBlock;
	};

	delete = async (id: number, teamId: number) => {
		await getRootEnv().dashboardSdk.sdk.users.delete(id);

		this.remove(id);
		this.setByTeam(teamId, _reject(this.allByTeam(teamId), { id }));
	};

	batchDelete = async (ids: number[], teamId: number) => {
		await Promise.all(ids.map(async id => getRootEnv().dashboardSdk.sdk.users.delete(id)));

		ids.forEach(id => {
			this.remove(id);
		});

		this.setByTeam(
			teamId,
			_filter(this.allByTeam(teamId) || [], driver => !ids.includes(driver.id))
		);
	};

	messageDriver = async (userId: number, message: string): Promise<boolean> => {
		const announcementId = await getRootEnv().dashboardSdk.sdk.users.messageDriver(userId, message);

		if (announcementId) {
			return true;
		}

		return false;
	};

	get onlineDrivers() {
		return _filter(this.all, { status: Connection.Online });
	}

	get onShiftDrivers() {
		return _filter(this.all, driver => !_isNil(driver.active_shift_id));
	}

	private onDriverCreate = (driver: Bringg.Driver) => {
		if (!this.get(driver.id)) {
			this.set(new Driver(this, driver));
		}
	};

	private onDriverUpdate = (driver: Bringg.Driver) => {
		const localDriver = this.get(driver.id);

		if (localDriver) {
			localDriver.set(driver);
		}
	};

	private onDriverDelete = (id: number) => {
		if (this.has(id)) {
			this.remove(id);
		}
	};

	subscribeToEvents() {
		const { dashboardSdk } = getRootEnv();

		dashboardSdk.sdk.users.onCreate(this.onDriverCreate);
		dashboardSdk.sdk.users.onUpdate(this.onDriverUpdate);
		dashboardSdk.sdk.users.onDelete(this.onDriverDelete);
	}
}

export default DriversStore;
