import { DataNode } from '@bringg/react-components';
import { mapValues } from 'lodash';

export type TaskColumn = {
	title: string;
	dataIndex: string;
	mandatory?: boolean;
};

function mapTranslate(node) {
	const title = `ORDERS_CSV.${node.title.replace(/\s+/g, '_').toUpperCase()}`;
	const tooltip = `${title}_TOOLTIP`;

	return {
		...node,
		title,
		tooltip: tooltip
	};
}

export const taskColumnsByCategory: Record<string, TaskColumn[]> = mapValues(
	{
		'ORDERS_CSV.MANDATORY_FIELDS': [
			{
				title: 'customer full name',
				dataIndex: 'customer.name'
			},
			{
				title: 'customer first name',
				dataIndex: 'customer.first_name'
			},
			{
				title: 'customer last name',
				dataIndex: 'customer.last_name'
			},
			{
				title: 'full address',
				dataIndex: 'way_points[1].address'
			},
			{
				title: 'street',
				dataIndex: 'way_points[1].street'
			},
			{
				title: 'city',
				dataIndex: 'way_points[1].city'
			},
			{
				title: 'zipcode',
				dataIndex: 'way_points[1].zipcode'
			},
			{
				title: 'pickup full address',
				dataIndex: 'way_points[0].address'
			},
			{
				title: 'pickup street',
				dataIndex: 'way_points[0].street'
			},
			{
				title: 'pickup city',
				dataIndex: 'way_points[0].city'
			},
			{
				title: 'pickup zipcode',
				dataIndex: 'way_points[0].zipcode'
			}
		],
		'ORDERS_CSV.DELIVERY_CUSTOMER_INFO': [
			{
				title: 'customer external id',
				dataIndex: 'customer.external_id'
			},
			{
				title: 'customer phone number',
				dataIndex: 'customer.phone'
			},
			{
				title: 'customer email',
				dataIndex: 'customer.email'
			},
			{
				title: 'borough',
				dataIndex: 'way_points[1].borough'
			},
			{
				title: 'state',
				dataIndex: 'way_points[1].state'
			},
			{
				title: 'latitude',
				dataIndex: 'way_points[1].lat'
			},
			{
				title: 'longitude',
				dataIndex: 'way_points[1].lng'
			},
			{
				title: 'notes',
				dataIndex: 'way_points[1].note'
			},
			{
				title: 'address second line',
				dataIndex: 'way_points[1].address_second_line'
			}
		],
		'ORDERS_CSV.PICKUP_INFO': [
			{
				title: 'pickup name',
				dataIndex: 'way_points[0].customer.name'
			},
			{
				title: 'pickup external id',
				dataIndex: 'way_points[0].external_id'
			},
			{
				title: 'pickup email',
				dataIndex: 'way_points[0].customer.email'
			},
			{
				title: 'pickup phone number',
				dataIndex: 'way_points[0].customer.phone'
			},
			{
				title: 'pickup opted into SMS',
				dataIndex: 'way_points[0].allow_sending_sms'
			},
			{
				title: 'pickup opted into email',
				dataIndex: 'way_points[0].allow_sending_email'
			},
			{
				title: 'pickup borough',
				dataIndex: 'way_points[0].borough'
			},
			{
				title: 'pickup state',
				dataIndex: 'way_points[0].state'
			},
			{
				title: 'pickup latitude',
				dataIndex: 'way_points[0].lat'
			},
			{
				title: 'pickup longitude',
				dataIndex: 'way_points[0].lng'
			},
			{
				title: 'pickup notes',
				dataIndex: 'way_points[0].note'
			},
			{
				title: 'pickup address second line',
				dataIndex: 'way_points[0].address_second_line'
			}
		],
		'ORDERS_CSV.ORDER_INFO': [
			{
				title: 'order external id',
				dataIndex: 'external_id'
			},
			{
				title: 'order title',
				dataIndex: 'title'
			},
			{
				title: 'order type',
				dataIndex: 'task_type_id'
			},
			{
				title: 'total price',
				dataIndex: 'total_price'
			},
			{
				title: 'delivery fee',
				dataIndex: 'delivery_price'
			},
			{
				title: 'tax amount',
				dataIndex: 'tax_price'
			},
			{
				title: 'price before tax',
				dataIndex: 'price_before_tax'
			},
			{
				title: 'pre-delivery tip',
				dataIndex: 'pre_delivery_tip'
			},
			{
				title: 'extras',
				dataIndex: 'extras'
			},
			{
				title: 'team external id',
				dataIndex: 'team_external_id'
			},
			{
				title: 'team id',
				dataIndex: 'teams'
			},
			{
				title: 'tag id',
				dataIndex: 'tag_id'
			},
			{
				title: 'service plan external id',
				dataIndex: 'service_plan_external_id'
			},
			{
				title: 'delivery method',
				dataIndex: 'way_points[1].delivery_method'
			},
			{
				title: 'payment method',
				dataIndex: 'payment_method'
			},
			{
				title: 'skills',
				dataIndex: 'required_skills'
			}
		],
		'ORDERS_CSV.INVENTORY': [
			{
				title: 'inventory name',
				dataIndex: 'inventory.name'
			},
			{
				title: 'inventory external id',
				dataIndex: 'inventory.external_id'
			},
			{
				title: 'inventory note',
				dataIndex: 'inventory.note'
			},
			{
				title: 'inventory original quantity',
				dataIndex: 'inventory.quantity'
			},
			{
				title: 'inventory weight',
				dataIndex: 'inventory.weight'
			},
			{
				title: 'inventory height',
				dataIndex: 'inventory.height'
			},
			{
				title: 'inventory length',
				dataIndex: 'inventory.length'
			},
			{
				title: 'inventory width',
				dataIndex: 'inventory.width'
			},
			{
				title: 'inventory scan string',
				dataIndex: 'inventory.scan_string'
			},
			{
				title: 'inventory price',
				dataIndex: 'inventory.price'
			},
			{
				title: 'inventory SKU',
				dataIndex: 'inventory.sku'
			},
			{
				title: 'inventory age restricted',
				dataIndex: 'inventory.age_restricted'
			},
			{
				title: 'inventory extras',
				dataIndex: 'inventory.extras'
			}
		],
		'ORDERS_CSV.TIME_PICKUP': [
			{
				title: 'pickup scheduled time',
				dataIndex: 'way_points[0].scheduled_at'
			},
			{
				title: 'pickup no earlier than',
				dataIndex: 'way_points[0].no_earlier_than'
			},
			{
				title: 'pickup no later than',
				dataIndex: 'way_points[0].no_later_than'
			},
			{
				title: 'pickup timezone',
				dataIndex: 'way_points[0].timezone'
			},
			{
				title: 'pickup ETOS',
				dataIndex: 'way_points[0].etos'
			}
		],
		'ORDERS_CSV.TIME_DROPOFF': [
			{
				title: 'dropoff scheduled time',
				dataIndex: 'way_points[1].scheduled_at'
			},
			{
				title: 'dropoff no earlier than',
				dataIndex: 'way_points[1].no_earlier_than'
			},
			{
				title: 'dropoff no later than',
				dataIndex: 'way_points[1].no_later_than'
			},
			{
				title: 'dropoff timezone',
				dataIndex: 'way_points[1].timezone'
			},
			{
				title: 'dropoff ETOS',
				dataIndex: 'way_points[1].etos'
			}
		],
		'ORDERS_CSV.ROUTING': [
			{
				title: 'priority',
				dataIndex: 'priority'
			},
			{
				title: 'driver external id',
				dataIndex: 'external_user_id'
			}
		]
	},
	category => category.map(mapTranslate)
);

export const taskColumns = Object.values(taskColumnsByCategory).flat();
export const taskColumnsByDataIndex: Record<string, TaskColumn> = taskColumns.reduce(
	(acc, column) => ({
		...acc,
		[column.dataIndex]: column
	}),
	{}
);

export const tasksColumnsTreeData: DataNode[] = Object.entries(taskColumnsByCategory).map(([category, columns]) => ({
	title: category,
	key: category,
	children: columns.map(({ title, dataIndex }) => ({
		title,
		key: dataIndex
	}))
}));

export const findExpandedByDataIndex = (dataIndex: string) => {
	if (!dataIndex) {
		return [];
	}

	return [tasksColumnsTreeData.find(({ children }) => children.find(({ key }) => key === dataIndex))?.key];
};

const parentChildFields = {
	'customer.name': ['customer.first_name', 'customer.last_name'],
	'way_points[0].address': ['way_points[0].street', 'way_points[0].city', 'way_points[0].zipcode'],
	'way_points[1].address': ['way_points[1].street', 'way_points[1].city', 'way_points[1].zipcode']
};

export function getMissingMandatoryFields(selected: string[]) {
	return taskColumnsByCategory['ORDERS_CSV.MANDATORY_FIELDS']
		.map(field => {
			const key = field.dataIndex;
			const childFields = parentChildFields[key];
			const fieldPresent = selected.includes(key);

			if (childFields) {
				return fieldPresent || (childFields.length && childFields.every(el => selected.includes(el)))
					? null
					: field;
			} else {
				const parentField = Object.keys(parentChildFields).find(el => parentChildFields[el].includes(key));
				return fieldPresent || selected.includes(parentField) ? null : field;
			}
		})
		.filter(el => el);
}
