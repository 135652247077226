import { FloatingInventoryOwnerType } from '@bringg/types';
import LinkWrapper from 'bringg-web/components/link/link';
import { useStores } from 'bringg-web/recipes';
import { observer } from 'mobx-react';
import { NO_INFO } from '../consts';

type OwnerNameProps = {
	id: number;
	ownerType: FloatingInventoryOwnerType;
};
const OwnerName = ({ id, ownerType }: OwnerNameProps) => {
	const { driversStore, teamsStore } = useStores();

	if (!id) return getNoInfoComponent();

	switch (ownerType) {
		case FloatingInventoryOwnerType.DRIVER: {
			const user = driversStore.get(id);
			const name = user?.name || id.toString();
			const path = `/drivers/${id}`;
			return generateComponent({ path, name });
		}
		case FloatingInventoryOwnerType.TEAM: {
			const team = teamsStore.get(id);
			const name = team?.name || id.toString();
			const path = `/drivers/teams/${id}`;
			return generateComponent({ path, name });
		}
		case FloatingInventoryOwnerType.NONE:
		default:
			return getNoInfoComponent();
	}
};
export default observer(OwnerName);

const getNoInfoComponent = () => <>{NO_INFO}</>;

const generateComponent = ({ path, name }: { path: string; name: string }) => (
	<span>
		<LinkWrapper to={path} title={name}>
			{name}
		</LinkWrapper>
	</span>
);
