import { ALERT_TYPES, AlertSeverity } from '@bringg/types';
import type { Alert as BringgAlert, Task } from '@bringg/types';
import type { KeysetPaginationRequest } from '@bringg/dashboard-sdk/dist/utils/consts/keyset-pagination.consts';
import type { ActionData } from '@bringg/types/types/action_data';

export enum ENVIRONMENT {
	DEVELOPMENT = 'development',
	PRODUCTION = 'production',
	TEST = 'test'
}

export interface WayPointLocation {
	location: google.maps.LatLng;
	wayPointPosition: number;
	id: number;
}

export interface ActionsConfiguration {
	id?: number;
	title?: string;
	action_data?: ActionData[];
}

export enum ActionsConfigurationLevel {
	USER = 'ACTION_CONFIGURATION.USER_LEVEL',
	ORDER = 'ACTION_CONFIGURATION.TASK_LEVEL',
	INVENTORY = 'ACTION_CONFIGURATION.INVENTORY_LEVEL',
	SHIFT = 'ACTION_CONFIGURATION.SHIFT_LEVEL'
}

export enum ActionDataType {
	QUESTION = 'Question',
	ACTION_FORM = 'Action form',
	REJECT_INVENTORY = 'Reject inventory',
	TAKE_NOTE = 'Take note',
	TAKE_SIGNATURE = 'Take signature',
	TAKE_PICTURE = 'Take picture',
	TAKE_SCAN = 'Take scan',
	CANCEL_TASK = 'Cancel task',
	OPEN_IFRAME = 'Open webpage',
	VERIFICATION_PIN_CODE = 'Verification PIN Code'
}

export interface IFilterOption<T> {
	value: T;
	title: string;
}

export type OptionalBy<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>;
export type ElasticAlert = OptionalBy<BringgAlert, 'delete_at' | 'updated_at'>;
export type RequiredBy<T, K extends keyof T> = Omit<T, K> & Required<Pick<T, K>>;

export interface GetAlertsParams extends KeysetPaginationRequest<void> {
	requiredField?: keyof ElasticAlert;
	alertTypes?: number[];
}

export type Exception = Pick<
	Task,
	| 'external_id'
	| 'required_skills'
	| 'user_id'
	| 'fleet_id'
	| 'status'
	| 'priority'
	| 'title'
	| 'scheduled_at'
	| 'created_at'
	| 'way_points'
	| 'active_way_point_id'
	| 'total_weight'
	| 'team_ids'
	| 'uuid'
> & {
	id: string;
	routeId: number;
	alert_type: ALERT_TYPES;
	alert_time: string;
	team_id: number;
	task_id: number;
	attempt_creation_reason?: string;
	extra_data: Record<string, unknown>;
	// TODO: owner_id should be taken from Bringg.Alert => needs to make Exception extends all properties from ElacticAlert
	owner_id: number;
	severity: AlertSeverity;
	routeTitle: string;
};

export type AlertGroup = 'taskAlerts' | 'driverAlerts' | 'optimizationAlerts';
export type AlertGroupWithAll = AlertGroup | 'all';

export type ExceptionRoute = Pick<Exception, 'routeId' | 'routeTitle'>;

export type UnwrapArray<T> = T extends Array<infer U> ? U : never;
