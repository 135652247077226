import React, { useMemo } from 'react';

import { BringgFontIcons, BringgIcon } from '@bringg/bringg-icons';
import { Team } from '@bringg/types';
import { UnassignedResourcesArrayByTeam } from '@bringg/dashboard-sdk/dist/DeliveryBlocks/DeliveryBlocks.consts';
import { useTranslation } from 'react-i18next';
import { Collapse } from '@bringg/react-components';
import moment from 'moment';

import useDateFormat from 'bringg-web/hooks/use-date-format';

import styles from './unassigned-resources.module.scss';

interface Props {
	unassignedResources: UnassignedResourcesArrayByTeam;
	teamsMap: Map<number, Team>;
}

const UnassignedResources = ({ unassignedResources, teamsMap }: Props) => {
	const { t } = useTranslation();
	const dateFormat = useDateFormat();

	const teamsUnassignedResources: {
		name: string;
		id: number;
		schedules_missing_resources: {
			missing_resources_count: number;
			type: string;
			from: string;
		}[];
	}[] = useMemo(() => {
		const teamsMissingResourcesArray = [];

		if (teamsMap) {
			Object.entries(unassignedResources).forEach(([teamId, scheduleMissingResources]) => {
				const team = teamsMap.get(+teamId);
				if (team) {
					teamsMissingResourcesArray.push({
						schedules_missing_resources: scheduleMissingResources,
						name: team.name,
						id: teamId
					});
				}
			});
		}
		return teamsMissingResourcesArray;
	}, [teamsMap, unassignedResources]);

	return (
		<div className={styles.logContainer} data-test-id="team-unassigned-resources-container">
			<div className={styles.logHeader}>
				<div className={styles.iconContainer}>
					<BringgIcon iconName={BringgFontIcons.PrompedMessage} />
				</div>
				<div className={styles.headerTitle} data-test-id="team-unassigned-resources-title">
					{t('DELIVERY_BLOCKS_TEMPLATE.MISSING_RESOURCES')}
				</div>
			</div>
			<div className={styles.subHead} data-test-id="team-unassigned-resources-headline">
				{t('DELIVERY_BLOCKS_TEMPLATE.HEADLINE')}
			</div>
			<div className={styles.teamRowsContainer}>
				<Collapse removePadding={true}>
					{teamsUnassignedResources.map((teamLog, index) => (
						<Collapse.Panel
							key={index.toString()}
							header={
								<div className={styles.teamRow} data-test-id="team-unassigned-resources-row">
									<span>{teamLog.name}</span>
									<a
										href={`#/delivery-blocks-v2?team_id=${teamLog.id}`}
										target="_blank"
										className={styles.textInSentence}
										rel="noreferrer"
									>
										{t('DELIVERY_BLOCKS_TEMPLATE.ASSIGN_MANUALLY')}
									</a>
								</div>
							}
						>
							{teamLog.schedules_missing_resources.map(
								(scheduleMissingResource, missingScheduleIndex) => (
									<div
										className={styles.teamScheduleMissingResource}
										data-test-id="team-schedule-missing-resource"
										key={missingScheduleIndex.toString()}
									>
										<span>
											{`${moment(scheduleMissingResource.from).format(dateFormat)} - ${t(
												'DELIVERY_BLOCKS_TEMPLATE.MISSING'
											)}`}
										</span>
										<span> </span>
										<span className={styles.missingCount}>{`${
											scheduleMissingResource.missing_resources_count
										} ${scheduleMissingResource.type}${
											scheduleMissingResource.missing_resources_count > 1 ? 's' : ''
										}`}</span>
									</div>
								)
							)}
						</Collapse.Panel>
					))}
				</Collapse>
			</div>
		</div>
	);
};

export default UnassignedResources;
