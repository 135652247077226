import React from 'react';
import { inject, observer } from 'mobx-react';
import ChatWindowHeader from '../chat-window-header/chat-window-header';
import ChatWindowBody from '../chat-window-body/chat-window-body';
import ChatWindowInput from '../chat-window-input/chat-window-input';
import ChatStore from '../stores/chat-store';

interface Props {
	chatStore?: ChatStore;
}

const ChatWindow = ({ chatStore }: Props) => {
	const currentChat = chatStore?.getCurrentChat;

	return currentChat ? (
		<div className="chat-window">
			<ChatWindowHeader />
			<ChatWindowBody />
			<ChatWindowInput />
		</div>
	) : null;
};

export default inject('chatStore')(observer(ChatWindow));
