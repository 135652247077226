import { getRootEnv } from '@bringg-frontend/bringg-web-infra';
import { action, computed, observable, makeObservable } from 'mobx';
import { NotificationTypes } from '@bringg/types';

import NotificationTemplate from './domain-objects/notification-template';
import { EDITABLE_NOTIFICATION_TYPES } from '../../features/customer-notifications/notification-template/notification-template.consts';

const RatingScreenNotificationTypes = [
	NotificationTypes.RatingScreenTitle,
	NotificationTypes.PostRatingNegativeTitle,
	NotificationTypes.PostRatingPositiveTitle
];

const CxSchedulingNotificationTypes = [
	NotificationTypes.CxSchedulingCustomerMessageTitle,
	NotificationTypes.CxSchedulingFreeCommentInputLabel
];

class NotificationTemplatesStore {
	notificationTemplates: Map<string, NotificationTemplate> = new Map();
	availableLanguages: Bringg.Language[] = [];
	isAllFetched = false;

	set = (template: Partial<Bringg.NotificationTemplate>) => {
		if (template) this.notificationTemplates.set(this.buildMapKey(template), new NotificationTemplate(template));
	};

	remove = (template: NotificationTemplate) => {
		this.notificationTemplates.delete(this.buildMapKey(template));
	};

	storeAvailableLanguages = languages => {
		this.availableLanguages = languages;
	};

	constructor() {
		makeObservable(this, {
			notificationTemplates: observable.shallow,
			availableLanguages: observable,
			isAllFetched: observable,
			set: action,
			remove: action,
			storeAvailableLanguages: action,
			getAll: computed,
			ratingReasonLanguages: computed,
			cxSchedulingLanguages: computed
		});
	}

	get getAll(): NotificationTemplate[] {
		return Array.from(this.notificationTemplates.values());
	}

	get ratingReasonLanguages(): string[] {
		const allTemplates = this.getAll;

		const languages: string[] = allTemplates
			.filter(template => RatingScreenNotificationTypes.includes(template.notification_type as NotificationTypes))
			.map(template => template.language);

		return [...new Set(languages)];
	}

	get cxSchedulingLanguages(): string[] {
		const allTemplates = this.getAll;

		const languages: string[] = allTemplates
			.filter(template => CxSchedulingNotificationTypes.includes(template.notification_type as NotificationTypes))
			.map(template => template.language);

		return [...new Set(languages)];
	}

	getByLanguage = (language: string, tagId: number = null): NotificationTemplate[] => {
		const templates = [];
		EDITABLE_NOTIFICATION_TYPES.forEach(notificationType => {
			this.getAll.forEach(template => {
				if (
					template.notification_type === notificationType &&
					template.language === language &&
					template.tag_id === tagId
				) {
					templates.push(template);
				}
			});
		});
		return templates;
	};

	async fetchLanguages() {
		this.storeAvailableLanguages(await getRootEnv().dashboardSdk.sdk.languages.getAll());
	}

	async fetchAll() {
		this.isAllFetched = false;
		await this.fetchLanguages();
		const result: Bringg.NotificationTemplate[] =
			await getRootEnv().dashboardSdk.sdk.notificationTemplates.getAll();

		result.map(this.set);
		this.isAllFetched = true;
	}

	async fetchByNames(names: string[]) {
		const result: Bringg.NotificationTemplate[] =
			await getRootEnv().dashboardSdk.sdk.notificationTemplates.getByNames(names);

		result.map(this.set);
	}

	async fetchCustomerRatingTemplates(ratingOptions: string[] = []) {
		await this.fetchByNames([...RatingScreenNotificationTypes, ...ratingOptions]);
	}

	get(template: Partial<NotificationTemplate>): NotificationTemplate {
		return this.notificationTemplates.get(this.buildMapKey(template));
	}

	update = async (template: NotificationTemplate) => {
		this.set(await template.upsert());
	};

	delete = async (template: NotificationTemplate) => {
		await template.delete();
		this.remove(template);
	};

	buildMapKey(template: Partial<Bringg.NotificationTemplate>): string {
		const { language, notification_type, tag_id } = template;
		return `${language}-${notification_type}-${tag_id || null}`;
	}
}

export default NotificationTemplatesStore;
