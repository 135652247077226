import React, { useState } from 'react';
import { Button } from '@bringg/react-components';
import { useTranslation } from 'react-i18next';
import { Checkbox } from 'antd';
import { BringgException } from '@bringg/dashboard-sdk/dist/Core/BringgException';
import _toUpper from 'lodash/toUpper';
import _startCase from 'lodash/startCase';
import { useStores } from '../../../../../../recipes';
import { FilterSettings, formatFilterSettingsToServer } from './consts';
import notification from '../../../../../../services/notification';

interface Props {
	initialSettings: FilterSettings;
}

const TasksFilterSettings = ({ initialSettings }: Props) => {
	const { t } = useTranslation();
	const { merchantConfigurationsStore } = useStores();
	const [settings, setSettings] = useState<FilterSettings>(initialSettings);

	const onChange = (name, selected) => {
		setSettings({ ...settings, [name]: selected });
	};

	const apply = async () => {
		merchantConfigurationsStore.configuration.open_tasks_filter = formatFilterSettingsToServer(settings);
		try {
			await merchantConfigurationsStore.update();
			notification.success(t('STORE_APP_CONFIG.UPDATE_SUCCESS'));
		} catch (err) {
			console.error(`failed to load store app settings: ${(err as BringgException).message}`, err);
			notification.error(t('MERCHANT_SPECIFIC_CONFIGURATION.FAILED_UPDATING_MERCHANT'));
		}
	};

	const notificationsNotEmpty = Object.values(settings).some(value => value);

	return (
		<div className="tasks-filter-settings store-app-settings-section">
			<div className="store-app-settings-section-title">{t('STORE_APP_CONFIG.TASK_FILTER_TITLE')}</div>
			<div className="store-app-settings-section-content">
				{Object.entries(settings).map(([name, selected]) => {
					return (
						<Checkbox
							key={name}
							checked={selected}
							onChange={e => onChange(name, e.target.checked)}
							className="store-app-settings-checkbox"
						>
							{t(`STORE_APP_CONFIG.${_toUpper(name)}`, { defaultValue: _startCase(name) })}
						</Checkbox>
					);
				})}
			</div>
			<Button type="primary" size="middle" className="button" onClick={apply} disabled={!notificationsNotEmpty}>
				{t('STORE_APP_CONFIG.PUSH_NOTIFICATIONS.UPDATE')}
			</Button>
		</div>
	);
};

export default TasksFilterSettings;
