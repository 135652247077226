import React from 'react';
import Row from '@bringg/react-components/dist/components/row/row';
import Button from '@bringg/react-components/dist/components/button/button';
import { Divider } from 'antd';
import { RatingReason } from '@bringg/types';
import { BringgFontIcons, BringgIcon } from '@bringg/bringg-icons';
import { Input, Col, Checkbox } from '@bringg/react-components';
import Translate from '../../../translation/translator';

interface Props {
	onChange: <T>(paramName: string, value: T) => void;
	allowFreeTextComment: boolean;
	ratingOptions: Partial<RatingReason>[];
	disabled: boolean;
}

const MAX_REASONS = 5;

const RatingOptions = ({ onChange, ratingOptions, disabled, allowFreeTextComment }: Props) => {
	const addOption = () => {
		onChange('ratingOptions', [...ratingOptions, { text: '' }]);
	};

	const removeOption = (option: Partial<RatingReason>) => {
		const indexToRemove = ratingOptions.indexOf(option);
		ratingOptions.splice(indexToRemove, 1);
		onChange('ratingOptions', [...ratingOptions]);
	};

	const onInputChange = option => e => {
		const newOption = option;

		newOption.text = e.target.value;

		onChange('ratingOptions', [...ratingOptions]);
	};

	return (
		<div className="rating-options">
			<Divider>
				<h3>
					<Translate text="RATING_CONFIGURATION.OPTIONS" />
				</h3>
			</Divider>
			{ratingOptions.length < MAX_REASONS && (
				<Row className="add-reason customer-rating-field">
					<Button disabled={disabled} type="default" onClick={addOption} className="bg-success">
						<Translate text="RATING_CONFIGURATION.ADD_REASON" />
					</Button>
				</Row>
			)}
			{ratingOptions.map((option, index) => {
				return (
					<Row key={option.id || `no_id_${index}`} className="rating-option customer-rating-field">
						<Col span={20}>
							<Input
								isDisabled={disabled}
								placeholder={<Translate text="RATING_CONFIGURATION.OPTION_TEXT" />}
								value={option.text}
								onChange={onInputChange(option)}
							/>
						</Col>
						<Col className="rating-option-delete">
							<Button
								className="trash-btn"
								disabled={disabled}
								type="primary"
								onClick={() => removeOption(option)}
							>
								<BringgIcon iconName={BringgFontIcons.Trash} />
							</Button>
						</Col>
					</Row>
				);
			})}
			<div className="customer-rating-field">
				<Checkbox
					className="allow-text-comment-checkbox"
					checked={allowFreeTextComment}
					onChange={e => onChange('allowFreeTextComment', e.target.checked)}
				/>
				<Translate text="RATING_CONFIGURATION.ALLOW_FREE_TEXT_COMMENT" />
			</div>
		</div>
	);
};

export default RatingOptions;
