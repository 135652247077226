import React, { useCallback, useEffect, useState } from 'react';

import { useObserver } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { Button } from '@bringg/react-components';
import { withErrorBoundary } from '@bringg-frontend/bringg-web-infra';

import bringgNotification from '../../../services/notification';
import useStores from '../../../recipes/use-stores';
import DriversTable from '../../drivers/drivers-table/drivers-table';

interface Props {
	teamId: number;
}

const getDeleteText = (t, selectedDriversNumber) => {
	if (selectedDriversNumber === 0) {
		return t('GLOBAL.DELETE');
	}

	return `${t('GLOBAL.DELETE')} (${selectedDriversNumber})`;
};

const TeamDrivers: React.FC<Props> = ({ teamId }) => {
	const { driversStore, usersStore } = useStores();
	const { t } = useTranslation();
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [isDeleting, setIsDeleting] = useState<boolean>(false);
	const [selectedDriversIds, setSelectedDriversIds] = useState<number[]>([]);

	useEffect(() => {
		let mount = true;
		const fetchDrivers = async () => {
			await driversStore.fetchByTeam(teamId);

			if (mount) {
				setIsLoading(false);
			}
		};
		fetchDrivers();

		return () => {
			mount = false;
		};
	}, [teamId, driversStore]);

	const onSelectedChange = useCallback(selectedRowsKeys => {
		setSelectedDriversIds(selectedRowsKeys);
	}, []);

	const handleDeleteClick = useCallback(async () => {
		setIsDeleting(true);

		try {
			await driversStore.batchDelete(selectedDriversIds, teamId);
			bringgNotification.success(t('TEAM_DRIVERS.DELETE_SUCCEED'));
			setSelectedDriversIds([]);
		} catch (e) {
			bringgNotification.error(t('TEAM_DRIVERS.DELETE_FAILED'));
		} finally {
			setIsDeleting(false);
		}
	}, [selectedDriversIds, driversStore, teamId, t]);

	return useObserver(() => (
		<div className="table-container" data-test-id="teamDriverTableContainer">
			{usersStore.currentUser.admin && (
				<div className="table-actions">
					<Button
						danger
						type="primary"
						loading={isDeleting}
						onClick={handleDeleteClick}
						disabled={selectedDriversIds.length === 0}
					>
						{getDeleteText(t, selectedDriversIds.length)}
					</Button>
				</div>
			)}
			<DriversTable
				isLoading={isLoading}
				onSelectedChange={onSelectedChange}
				selectedKeys={selectedDriversIds}
				teamId={teamId}
			/>
		</div>
	));
};

export default withErrorBoundary(TeamDrivers);
