import { useState, useCallback } from 'react';

interface FilterTypes {
	data_type: number[];
	searchText: string;
	suggestion: number;
	required: number;
	show_in_mobile_app: number;
}

export interface UseTableFilters {
	filters: FilterTypes;
	updateFilters: (newFilters) => void;
	resetFilters: (filterKey?: string) => void;
}

const DEFAULT_FILTERS: FilterTypes = {
	data_type: [],
	searchText: '',
	suggestion: 0,
	required: 0,
	show_in_mobile_app: 0
};

export const useTableFilters = () => {
	const [filters, setFilters] = useState(DEFAULT_FILTERS);

	const updateFilters = useCallback(newFilters => {
		setFilters({ ...filters, ...newFilters });
	}, []);

	const resetFilters = useCallback(
		(filterKey?: string) => {
			if (filterKey) {
				updateFilters({ ...filters, [filterKey]: DEFAULT_FILTERS[filterKey] });
			} else {
				updateFilters(DEFAULT_FILTERS);
			}
		},
		[filters]
	);

	return { filters, updateFilters, resetFilters } as UseTableFilters;
};
