import { EndOfWeekDay } from '@bringg/types';
const getMomentISODayMap = () => {
	const days = [
		{ id: EndOfWeekDay.Monday, momentDay: 1 },
		{ id: EndOfWeekDay.Tuesday, momentDay: 2 },
		{ id: EndOfWeekDay.Wednesday, momentDay: 3 },
		{ id: EndOfWeekDay.Thursday, momentDay: 4 },
		{ id: EndOfWeekDay.Friday, momentDay: 5 },
		{ id: EndOfWeekDay.Saturday, momentDay: 6 },
		{ id: EndOfWeekDay.Sunday, momentDay: 7 }
	];
	return new Map(days.map(day => [day.id, day]));
};

export const endOfWeekDayService = { getMomentISODayMap };
