import { useEffect, useState } from 'react';

import { BringgFontIcons, BringgIcon } from '@bringg/bringg-icons';
import { Button, EditableTitle, Spinner } from '@bringg/react-components';
import notification from '@bringg/react-components/dist/components/notification/notification';
import { withErrorBoundary } from '@bringg-frontend/bringg-web-infra';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

import { taskRejectReasonsRoot } from '../../stores/data-providers/task-reject-reasons-root';
import TaskRejectReasonStore from '../../stores/task-reject-reasons-store';
import TaskRejectReasonsItem from '../task-reject-reason-item/task-reject-reasons-item';

import './task-reject-reasons-card.scss';

const TaskRejectReasonsCard = () => {
	const { t } = useTranslation();
	const { taskRejectReasonRepo } = taskRejectReasonsRoot.getStore();
	const taskRejectReasons = taskRejectReasonRepo.getAll();
	const [newTaskRejectReason, setNewTaskRejectReason] = useState<TaskRejectReasonStore>(null);
	const [isFetchingData, setIsFetchingData] = useState(true);

	useEffect(() => {
		fetchRejectionReasons();

		return () => {
			taskRejectReasonRepo.removeAll();
		};
	}, []);

	const fetchRejectionReasons = async () => {
		await taskRejectReasonRepo.loadAll();
		setIsFetchingData(false);
	};

	const createNewReason = async () => {
		if (!newTaskRejectReason.reason) {
			setNewTaskRejectReason(null);
			return;
		}
		const { success } = await newTaskRejectReason.create();
		if (success) {
			setNewTaskRejectReason(null);
		} else {
			notification.error(t('TASK_REJECT_REASON_CONFIG.ITEM.ERROR_CREATE'));
		}
	};

	const NewRejectReasonItem = () => (
		<div className="task-reject-reasons-item" id="new-reason">
			<div className="task-reject-reasons-content">
				<div className="task-reject-reasons-id">—</div>
				<EditableTitle
					maxLength={56}
					className="task-reject-reasons-reason"
					value={newTaskRejectReason.reason}
					onValueChange={newTaskRejectReason.setReason}
					placeholder={t('TASK_REJECT_REASON_CONFIG.NEW_ITEM.PLACEHOLDER')}
					onApplyChanges={createNewReason}
					loading={newTaskRejectReason.isSaving}
				/>
			</div>
			<div className="task-reject-reasons-delete-container">
				<Button
					className="task-reject-reasons-delete"
					onClick={() => setNewTaskRejectReason(null)}
					type="link"
					size="small"
					icon={<BringgIcon iconName={BringgFontIcons.Trash} />}
					shape="circle"
				/>
			</div>
		</div>
	);

	return (
		<div className="task-reject-reasons-card">
			<h4 className="task-reject-reasons-card-title">{t('TASK_REJECT_REASON_CONFIG.CARD.TITLE')}</h4>
			<p className="task-reject-reasons-card-explanation">{t('TASK_REJECT_REASON_CONFIG.CARD.EXPLANATION')}</p>
			{isFetchingData ? (
				<Spinner />
			) : (
				<>
					<div className="task-reject-reasons-items">
						<div className="task-reject-reasons-items-header">
							<div className="task-reject-reasons-content">
								<div className="task-reject-reasons-id">ID</div>
								<div className="task-reject-reasons-reason">
									{t('TASK_REJECT_REASON_CONFIG.TABLE_HEADER.REASON')}
								</div>
							</div>
						</div>
						{taskRejectReasons.map(taskRejectReason => (
							<TaskRejectReasonsItem key={taskRejectReason.id} taskRejectReason={taskRejectReason} />
						))}
						{newTaskRejectReason && <NewRejectReasonItem />}
					</div>
					<Button
						type="link"
						className="task-reject-reasons-add"
						disabled={!!newTaskRejectReason}
						onClick={() => {
							!newTaskRejectReason && setNewTaskRejectReason(new TaskRejectReasonStore());
						}}
					>
						{t('TASK_REJECT_REASON_CONFIG.ADD_REASON')}
					</Button>
				</>
			)}
		</div>
	);
};
export default withErrorBoundary(observer(TaskRejectReasonsCard));
