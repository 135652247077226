import React from 'react';

import { BringgFontIcons, BringgIcon } from '@bringg/bringg-icons';

import './timeline-tools.scss';

interface TimelineToolsProps {
	onZoomIn: () => void;
	onZoomOut: () => void;
}

const TimelineTools = (props: TimelineToolsProps) => {
	const { onZoomIn, onZoomOut } = props;

	return (
		<div className="timeline-tools">
			<div className="timeline-icons">
				<div onClick={onZoomIn} className="zoom-icon" data-test-id="zoomInIcon">
					<BringgIcon iconName={BringgFontIcons.ZoomIn} className="timeline-icon" />
				</div>
				<div onClick={onZoomOut} className="zoom-icon" data-test-id="zoomOutIcon">
					<BringgIcon iconName={BringgFontIcons.ZoomOut} className="timeline-icon" />
				</div>
			</div>
		</div>
	);
};

export default TimelineTools;
