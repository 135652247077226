import { Task, TaskStatus } from '@bringg/types';

export const getDropOffWayPoint = (task: Partial<Task>) => {
	return task.way_points?.find(wp => wp.position === 2);
};

export const isTaskActive = (task: Partial<Task>) => {
	if (!task) {
		return false;
	}
	return [TaskStatus.OnTheWay, TaskStatus.CheckedIn].indexOf(task?.status) !== -1;
};
