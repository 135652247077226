import React from 'react';
import classNames from 'classnames';
import { InputNumber } from '@bringg/react-components';
import { BringgFontIcons, BringgIcon } from '@bringg/bringg-icons';
import { datetime } from '@bringg/types';
import { useTranslation } from 'react-i18next';
import moment from 'moment-timezone';
import { DeliveryBlockBreakBase } from '../break-types';
import { convertMinutesToHours } from './break-item';
import './relative-break.scss';

interface Props {
	breakItem: DeliveryBlockBreakBase;
	onFromChanged: (from: datetime) => void;
	onToChanged: (to: datetime) => void;
	onLengthChanged: (length: number) => void;
	isError: boolean;
}

export const getHoursFromStartOfDay = dateTime => {
	const momentDateTime = moment(dateTime);
	const minutes = momentDateTime.diff(moment(0).utc().startOf('day'), 'minutes');
	return convertMinutesToHours(minutes);
};

export const RelativeBreak = ({ breakItem, onFromChanged, onToChanged, onLengthChanged, isError }: Props) => {
	const { t } = useTranslation();

	const onChangeTime = val => {
		return moment(0).add(val, 'hours').toISOString();
	};

	return (
		<div className="relative-break-data">
			<BringgIcon iconName={BringgFontIcons.BreakTo} className="relative-break-icon" />
			<span>{t('DELIVERY_BLOCK_MODAL.BREAK_DURATION')}</span>
			<InputNumber
				className="break-length"
				placeholder={t('DELIVERY_BLOCK_MODAL.BREAK_LENGTH_PLACEHOLDER')}
				min={1}
				formatter={value => `${value} ${t('DELIVERY_BLOCK_MODAL.MIN')}`}
				parser={value => value.replace(t('DELIVERY_BLOCK_MODAL.MIN'), '')}
				value={breakItem.length}
				onChange={length => {
					onLengthChanged(Number(length));
				}}
			/>
			<span>{t('DELIVERY_BLOCK_MODAL.ANY_TIME_BETWEEN')}</span>
			<InputNumber
				placeholder="DELIVERY_BLOCK_MODAL.BREAK_FROM_PLACEHOLDER"
				className={classNames('break-from', { 'range-error': isError })}
				value={getHoursFromStartOfDay(breakItem.start_time)}
				onChange={from => onFromChanged(onChangeTime(from))}
				formatter={value => `${value} ${t('DELIVERY_BLOCK_MODAL.HOURS')}`}
				step={0.5}
				min={1}
				max={24}
				parser={value => value.replace(t('DELIVERY_BLOCK_MODAL.HOURS'), '')}
			/>
			<span>-</span>
			<InputNumber
				placeholder="DELIVERY_BLOCK_MODAL.BREAK_TO_PLACEHOLDER"
				className={classNames('break-to', { 'range-error': isError })}
				value={getHoursFromStartOfDay(breakItem.end_time)}
				onChange={to => onToChanged(onChangeTime(to))}
				formatter={value => `${value} ${t('DELIVERY_BLOCK_MODAL.HOURS')}`}
				step={0.5}
				min={1}
				max={24}
				parser={value => value.replace(t('DELIVERY_BLOCK_MODAL.HOURS'), '')}
			/>
		</div>
	);
};
