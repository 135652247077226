import React from 'react';

import { Button, Space, TypographyTitle } from '@bringg/react-components';
import { BringgFontIcons, BringgIcon } from '@bringg/bringg-icons';

interface PageProps {
	title?: string;
	subtitle?: string;
	children?: React.ReactNode;
	rightGutter?: boolean;
	onBack?: () => void;
}

const baseSpacing = '24px';
const baseColor = '#2D3748';

const Page = ({ title, subtitle, children, rightGutter = false, onBack }: PageProps) => {
	const styles = {
		root: {
			marginLeft: baseSpacing,
			marginTop: baseSpacing,
			marginRight: rightGutter ? baseSpacing : 0
		},
		content: {
			marginTop: baseSpacing,
			paddingBottom: baseSpacing
		},
		description: {
			color: baseColor
		}
	};

	return (
		<div style={styles.root}>
			<PageTitle title={title} subtitle={subtitle} onBack={onBack} />
			<div style={styles.content}>{children}</div>
		</div>
	);
};

const PageTitle = ({ title, subtitle, onBack }) => {
	return (
		<>
			<Space size="small">
				{onBack && <BackButton onBack={onBack} />}
				{title && (
					<TypographyTitle level={3} style={{ color: baseColor, margin: 0 }} data-test-id="page-title">
						{title}
					</TypographyTitle>
				)}
			</Space>
			{subtitle && (
				<div style={{ color: baseColor, marginTop: '8px' }} data-test-id="page-subtitle">
					{subtitle}
				</div>
			)}
		</>
	);
};

const BackButton = ({ onBack }) => {
	return (
		<Button size="small" icon={<BringgIcon iconName={BringgFontIcons.ArrowLeft} />} onClick={onBack}>
			Back
		</Button>
	);
};

export default Page;
