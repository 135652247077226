import React from 'react';
import { MomentInput } from 'moment';
import { first, last } from 'lodash';
import { Task, WayPoint } from '@bringg/types';
import LinkWrapper from 'bringg-web/components/link/link';
import { Table, Date } from 'bringg-web/components';
import { RunTask } from '../run.consts';
import { translate } from '../../../translation';
import sortWaypointsByPosition from '../utils/sortWaypointsByPosition';
import './run-table.scss';

type RunTableComponentProps = {
	tasks: Task[];
	t?: () => void;
};

type ExternalId = RunTask['externalId'];

const RunTableComponent = ({ tasks, t }: RunTableComponentProps) => {
	const translate: (translationKey: string) => void = t;

	const dateRenderer = (date: MomentInput) => {
		return <Date format="MMM DD, LT" invalidDateText="----" date={date} />;
	};

	const columns = [
		{
			title: translate('RUN.INDEX'),
			dataIndex: 'index'
		},
		{
			title: translate('MAP.ID'),
			dataIndex: 'externalId',
			render: (externalId: ExternalId, record: RunTask) => {
				return (
					<LinkWrapper to={`/history/${record.id}`} title={externalId} className="run-table-task-id">
						{externalId}
					</LinkWrapper>
				);
			}
		},
		{
			title: translate('RUN.TASK.PRIORITY'),
			dataIndex: 'priority'
		},
		{
			title: translate('RUN.WAYPOINT.ETA'),
			dataIndex: 'eta',
			render: dateRenderer
		},
		{
			title: translate('USER_TYPE.TITLE'),
			dataIndex: 'title'
		},
		{
			title: translate('RUN.FIRST_STOP_CHECK_IN'),
			dataIndex: 'firstStopCheckIn',
			render: dateRenderer
		},
		{
			title: translate('RUN.FIRST_STOP_CHECK_OUT'),
			dataIndex: 'firstStopCheckOut',
			render: dateRenderer
		},
		{
			title: translate('RUN.LAST_STOP_CHECK_IN'),
			dataIndex: 'lastStopCheckIn',
			render: dateRenderer
		},
		{
			title: translate('RUN.LAST_STOP_CHECK_OUT'),
			dataIndex: 'lastStopCheckOut',
			render: dateRenderer
		},
		{
			title: translate('HISTORY.ADDRESS'),
			dataIndex: 'address'
		},
		{
			title: translate('HISTORY.CUSTOMER_NAME'),
			dataIndex: 'customerName'
		}
	];

	const getRunTasks = (): RunTask[] => {
		const runTasks: RunTask[] = [];
		tasks.forEach((task, index) => {
			const wayPoints = sortWaypointsByPosition([...task.way_points]);
			const firstWayPoint: WayPoint = first(wayPoints);
			const lastWayPoint: WayPoint = last(wayPoints);
			const displayedPriority = task.priority && task.priority !== task.id ? task.priority : undefined;
			const runTask: Partial<RunTask> = {
				index: index + 1,
				id: task.id,
				externalId: task.external_id || task.id.toString(),
				eta: lastWayPoint.eta,
				priority: displayedPriority,
				title: task.title,
				address: lastWayPoint.address,
				customerName: task.customer?.name
			};
			if (firstWayPoint) {
				runTask.firstStopCheckIn = firstWayPoint.checkin_time;
				runTask.firstStopCheckOut = firstWayPoint.checkout_time;
			}
			if (task.way_points.length > 1 && lastWayPoint) {
				runTask.lastStopCheckIn = lastWayPoint.checkin_time;
				runTask.lastStopCheckOut = lastWayPoint.checkout_time;
			}

			runTasks.push(runTask as RunTask);
		});

		return runTasks;
	};

	return (
		<div className="run-table">
			<Table dataSource={getRunTasks()} columns={columns as any} pagination={false} rowKey="id" size="small" />
		</div>
	);
};

export default RunTableComponent;
export const RunTable = translate()(RunTableComponent);
