import { useState, useCallback } from 'react';

interface FilterTypes {
	service_plan: number[];
	service_area: number[];
	slot_name: string;
}

export interface UseTableFilters {
	filters: FilterTypes;
	updateFilters: (newFilters) => void;
	resetFilters: (filterKey?: string) => void;
}

const DEFAULT_FILTERS: FilterTypes = {
	service_plan: [],
	service_area: [],
	slot_name: ''
};

export const useTableFilters = () => {
	const [filters, setFilters] = useState(DEFAULT_FILTERS);

	const updateFilters = useCallback(
		newFilters => {
			setFilters({ ...filters, ...newFilters });
		},
		[setFilters, filters]
	);

	const resetFilters = useCallback(
		(filterKey?: string) => {
			if (filterKey) {
				updateFilters({ ...filters, [filterKey]: DEFAULT_FILTERS[filterKey] });
			} else {
				updateFilters({ ...DEFAULT_FILTERS });
			}
		},
		[filters, updateFilters]
	);

	return { filters, updateFilters, resetFilters } as unknown as UseTableFilters;
};
