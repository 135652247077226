import { PlannedDeliveryWindow } from '@bringg/dashboard-sdk';
import { PlannedDeliveryWindowsEventType } from 'bringg-web/features/planned-delivery-windows/calendar/planned-delivery-windows-calendar';
import { plannedDeliveryWindowsRangeDates } from 'bringg-web/features/planned-delivery-windows/services/planned-delivery-windows-range-dates';

const create = (
	pdw: PlannedDeliveryWindow,
	rangeWeek: { startDate: string; endDate: string },
	timezone: string
): PlannedDeliveryWindowsEventType => {
	const [startTime, endTime] = plannedDeliveryWindowsRangeDates.getDatesBetweenRange(
		pdw.start_time,
		pdw.end_time,
		rangeWeek,
		timezone
	);
	return {
		id: pdw.id,
		title: pdw.name,
		start: startTime,
		end: endTime,
		cutoff: pdw.cutoff,
		team_id: pdw.team_id,
		service_area_ids: pdw.service_area_ids,
		service_plan_ids: pdw.service_plan_ids
	};
};
export const plannedDeliveryWindowsEventCreator = { create };
