import { MomentInput } from 'moment';

export interface RunsData {
	id: number;
	externalId: string;
	driverName: string;
	routeTitle: string;
	startTime: MomentInput;
	endTime?: MomentInput;
	routeId?: number;
	tasksCount: number;
	status: string | React.ReactNode;
}

export enum RUNS_FILTER_TYPES {
	ALL_RUNS = 'all',
	OPEN_RUNS = 'open',
	CLOSED_RUNS = 'closed'
}

export const INITIAL_PAGE = 1;
export const MAX_PAGE_SIZE = 1000;
export const DEAFULT_PAGE_SIZE = 25;
