import { getRootEnv } from '@bringg-frontend/bringg-web-infra';
import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { getRoot } from 'mobx-easy';
import {
	EventTriggerTasksManualArg,
	FactType,
	ManualTriggerInputParam,
	ManualTriggerResponse,
	PrimitiveManualTriggerParamDataType,
	TasksManualParams
} from '@bringg/types';

import { registerAction } from 'bringg-web/services/cross-application/cross-application';
import RootStore from 'bringg-web/stores/root-store';
import { TasksAsyncOperationStore } from './tasks-async-operation-store';

export const MANUAL_TRIGGER_CLICKED = 'manual_trigger_clicked';

export interface ExecuteTriggerData {
	trigger: TasksManualParams;
	selectedTaskIds: number[];
}

export class ManualTriggerStore {
	trigger: TasksManualParams;

	selectedTaskIds: number[] = [];
	manualTriggerFormModalOpen: boolean;
	forceModalOpen: boolean;
	args: EventTriggerTasksManualArg[];
	failedTasksIds: number[];
	allowToForceActionsOnMatching: boolean;
	allowToForceActionsOnAll: boolean;
	isSubmitting = false;

	asyncOperations: TasksAsyncOperationStore[] = [];

	private cleanupGlobalListener: () => void;

	constructor() {
		makeObservable(this, {
			trigger: observable,
			selectedTaskIds: observable,
			manualTriggerFormModalOpen: observable,
			forceModalOpen: observable,
			args: observable,
			failedTasksIds: observable,
			allowToForceActionsOnMatching: observable,
			allowToForceActionsOnAll: observable,
			triggerClicked: action,
			submitManualTriggerForm: action,
			forceAction: action,
			resetCurrentTrigger: action,
			failedTasks: computed,
			asyncOperations: observable,
			startAsyncOperation: action,
			dispose: action
		});

		this.cleanupGlobalListener = registerAction(
			MANUAL_TRIGGER_CLICKED,
			({ trigger, selectedTaskIds }: ExecuteTriggerData) => {
				runInAction(() => {
					this.triggerClicked({ trigger, selectedTaskIds }).catch(err => {
						console.error(`failed to trigger "${trigger.name}"`, err);
					});
				});
			},
			getRootEnv().dashboardSdk.sdk.crossAppTransport
		);
	}

	resetAfterRemoveRoot() {
		this.dispose();
		this.cleanupGlobalListener();
		this.cleanupGlobalListener = () => undefined;
	}

	async triggerClicked({ trigger, selectedTaskIds }: ExecuteTriggerData) {
		this.resetCurrentTrigger();
		this.selectedTaskIds = selectedTaskIds;
		this.trigger = trigger;

		if (!this.trigger.vars?.length) {
			const response = await this.executeTrigger(this.selectedTaskIds, false, false);
			await this.handleTriggeringResponse(response);

			return;
		}

		this.manualTriggerFormModalOpen = true;
	}

	async submitManualTriggerForm(values: EventTriggerTasksManualArg[]) {
		this.isSubmitting = true;
		this.manualTriggerFormModalOpen = false;

		try {
			this.args = mapManualTriggerArg((this.trigger as TasksManualParams).vars, values);

			const response = await this.executeTrigger(this.selectedTaskIds, false, false);
			await this.handleTriggeringResponse(response);
		} finally {
			this.isSubmitting = false;
		}
	}

	async handleTriggeringResponse(response: ManualTriggerResponse) {
		const { request_id: requestId } = response;

		const {
			all_actions_executed: allActionsExecuted,
			unmatched_task_ids: failedTasksIds,
			allow_to_force_actions_for_matching_conditions: allowToForceActionsForMatching,
			allow_to_force_actions_for_all: allowToForceActionsForAll,
			workflow_id: workflowId
		} = response.executions[0];

		if (allActionsExecuted) {
			await this.startAsyncOperation(workflowId, requestId);
			return;
		}

		const actuallyFailedTasks = (failedTasksIds || []).filter(taskId => {
			return this.selectedTaskIds.some(id => id === taskId);
		}, []);

		const tasksStore = getRoot<RootStore>().data.tasksStore;
		const missingTasks = actuallyFailedTasks.filter(taskId => !tasksStore.get(taskId));

		if (missingTasks.length > 0) {
			await tasksStore.loadMany(missingTasks);
		}

		runInAction(() => {
			this.failedTasksIds = actuallyFailedTasks;
			this.allowToForceActionsOnAll = allowToForceActionsForAll;
			this.allowToForceActionsOnMatching = allowToForceActionsForMatching;
			this.forceModalOpen = true;
		});
	}

	abortDynamicFormModal() {
		if (this.isSubmitting) {
			return;
		}
		this.resetCurrentTrigger();
	}

	resetCurrentTrigger() {
		this.trigger = undefined;
		this.selectedTaskIds = [];
		this.manualTriggerFormModalOpen = false;
		this.forceModalOpen = false;
		this.args = undefined;
		this.failedTasksIds = [];
		this.isSubmitting = false;
	}

	dispose() {
		this.resetCurrentTrigger();
		this.asyncOperations.forEach(asyncOperation => asyncOperation.dispose());
		this.asyncOperations = [];
	}

	// eslint-disable-next-line @typescript-eslint/require-await
	private async executeTrigger(taskIds: number[], forceActionForAll: boolean, forceActionForMatching: boolean) {
		return getRootEnv().dashboardSdk.sdk.v2.workflows().manualTrigger(this.trigger.workflow_id, {
			fact_type: FactType.Task,
			fact_ids: taskIds,
			force_actions_for_all: forceActionForAll,
			force_actions_for_matching_conditions: forceActionForMatching,
			args: this.args
		});
	}

	async forceAction(applyOnAll: boolean) {
		this.forceModalOpen = false;

		try {
			const response = await this.executeTrigger(this.selectedTaskIds, applyOnAll, !applyOnAll);

			const { request_id: requestId } = response;
			const workflowId = response.executions[0].workflow_id;

			let tasksToRun;
			if (!applyOnAll && this.failedTasks.length) {
				tasksToRun = this.selectedTaskIds.filter(taskId => this.failedTasksIds.indexOf(taskId) < 0);
			}
			await this.startAsyncOperation(workflowId, requestId, tasksToRun);
		} catch (err) {
			console.error('failed to trigger ', err);
		}
	}

	async startAsyncOperation(workflowId: number, requestId: string, selectedTasksIds?: number[]) {
		this.asyncOperations.push(
			(
				await TasksAsyncOperationStore.create({
					triggerName: this.trigger.name,
					workflowId,
					requestId,
					taskIds: selectedTasksIds || this.selectedTaskIds,
					onClose: store => {
						runInAction(() => {
							const storeIndex = this.asyncOperations.indexOf(store);
							if (storeIndex > -1) {
								this.asyncOperations.splice(storeIndex, 1);
							}
						});
					}
				})
			).startAsyncOperation()
		);
	}

	get failedTasks() {
		const tasksStore = getRoot<RootStore>().data.tasksStore;

		return this.failedTasksIds.map(id => ({
			id,
			externalId: tasksStore.get(id).external_id
		}));
	}
}

export function mapManualTriggerArg(vars: ManualTriggerInputParam[], params: Record<string, any>) {
	return Object.entries(params).map(([key, value]) => {
		const dataType = vars?.find(v => v.name === key)?.data_type;
		let varValue: any;

		switch (dataType) {
			case PrimitiveManualTriggerParamDataType.DATE:
				varValue = value.format('YYYY-MM-DD');
				break;
			case PrimitiveManualTriggerParamDataType.TIME:
				varValue = value.format('HH:mm');
				break;
			case PrimitiveManualTriggerParamDataType.BOOLEAN:
				varValue = !!value;
				break;
			case PrimitiveManualTriggerParamDataType.DATETIME:
				varValue = value.format('YYYY-MM-DDTHH:mm');
				break;
			default:
				varValue = value;
		}

		return {
			name: key,
			value: varValue,
			data_type: dataType
		} satisfies EventTriggerTasksManualArg;
	});
}
