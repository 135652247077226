import React from 'react';

import {
	actions,
	makePropGetter,
	ensurePluginOrder,
	useGetLatest,
	useMountedLayoutEffect,
	type Row
} from 'react-table';

export type UseRowSelectAdditionalInstanceProps<D extends Record<string, unknown>> = {
	getRowSelectable?: (row: Row<D>) => boolean;
	selectableNonGroupedRowsById?: { [key: string]: Row<D> };
};

// Warning! The plugin have the same pluginName as standard lib useRowSelect
// you should use either standard useRowSelect or customized one, but not both at the same time
const pluginName = 'useRowSelect';

// Actions
actions.resetSelectedRows = 'resetSelectedRows';
actions.toggleAllRowsSelected = 'toggleAllRowsSelected';
actions.toggleRowSelected = 'toggleRowSelected';
actions.toggleAllPageRowsSelected = 'toggleAllPageRowsSelected';

export const useRowSelect = hooks => {
	hooks.stateReducers.push(reducer);
	hooks.useInstance.push(useInstance);
	hooks.prepareRow.push(prepareRow);

	Object.assign(hooks, {
		getToggleRowSelectedProps: [defaultGetToggleRowSelectedProps],
		getToggleAllRowsSelectedProps: [defaultGetToggleAllRowsSelectedProps],
		getToggleAllPageRowsSelectedProps: [defaultGetToggleAllPageRowsSelectedProps]
	});
};

useRowSelect.pluginName = pluginName;

const defaultGetRowSelectable = () => true;

const defaultGetToggleRowSelectedProps = (props, { instance, row }) => {
	const { manualRowSelectedKey = 'isSelected', getRowSelectable = defaultGetRowSelectable } = instance;
	// use getRowSelectable to verify if should be disabled
	const disabled = !getRowSelectable(row);
	let checked = false;

	if (row.original && row.original[manualRowSelectedKey]) {
		checked = true;
	} else {
		checked = row.isSelected;
	}

	return [
		props,
		{
			onChange: e => {
				row.toggleRowSelected(e.target.checked);
			},
			style: {
				cursor: 'pointer'
			},
			checked,
			title: 'Toggle Row Selected',
			indeterminate: row.isSomeSelected,
			// can use this prop to display: none in column render
			disabled
		}
	];
};

const defaultGetToggleAllRowsSelectedProps = (props, { instance }) => [
	props,
	{
		onChange: e => {
			instance.toggleAllRowsSelected(e.target.checked);
		},
		style: {
			cursor: 'pointer'
		},
		checked: instance.isAllRowsSelected,
		title: 'Toggle All Rows Selected',
		indeterminate: Boolean(!instance.isAllRowsSelected && Object.keys(instance.state.selectedRowIds).length)
	}
];

const defaultGetToggleAllPageRowsSelectedProps = (props, { instance }) => [
	props,
	{
		onChange(e) {
			instance.toggleAllPageRowsSelected(e.target.checked);
		},
		style: {
			cursor: 'pointer'
		},
		checked: instance.isAllPageRowsSelected,
		title: 'Toggle All Current Page Rows Selected',
		indeterminate: Boolean(
			!instance.isAllPageRowsSelected && instance.page.some(({ id }) => instance.state.selectedRowIds[id])
		)
	}
];

// eslint-disable-next-line max-params
function reducer(state, action, previousState, instance) {
	if (action.type === actions.init) {
		return {
			selectedRowIds: {},
			...state
		};
	}

	if (action.type === actions.resetSelectedRows) {
		return {
			...state,
			selectedRowIds: instance.initialState.selectedRowIds || {}
		};
	}

	if (action.type === actions.toggleAllRowsSelected) {
		const { value: setSelected } = action;
		const {
			isAllRowsSelected,
			// what if there isn't any??
			selectableNonGroupedRowsById
		} = instance;

		const selectAll = typeof setSelected !== 'undefined' ? setSelected : !isAllRowsSelected;

		// Only remove/add the rows that are visible on the screen
		//  Leave all the other rows that are selected alone.
		const selectedRowIds = Object.assign({}, state.selectedRowIds);

		// use selectableNonGroupedRowsById instead of nonGroupedRowsById
		if (selectAll) {
			Object.keys(selectableNonGroupedRowsById).forEach(rowId => {
				selectedRowIds[rowId] = true;
			});
		} else {
			Object.keys(selectableNonGroupedRowsById).forEach(rowId => {
				delete selectedRowIds[rowId];
			});
		}

		return {
			...state,
			selectedRowIds
		};
	}

	// could also add protection to prevent changing not selectable rows
	// in addition to disabling / hiding related checkbox
	if (action.type === actions.toggleRowSelected) {
		const { id, value: setSelected } = action;
		const { rowsById, selectSubRows = true, getSubRows } = instance;
		const isSelected = state.selectedRowIds[id];
		const shouldExist = typeof setSelected !== 'undefined' ? setSelected : !isSelected;

		if (isSelected === shouldExist) {
			return state;
		}

		const newSelectedRowIds = { ...state.selectedRowIds };

		const handleRowById = rowId => {
			const row = rowsById[rowId];

			if (!row.isGrouped) {
				if (shouldExist) {
					newSelectedRowIds[rowId] = true;
				} else {
					delete newSelectedRowIds[rowId];
				}
			}

			if (selectSubRows && getSubRows(row)) {
				getSubRows(row).forEach(subRow => {
					if (instance.getRowSelectable(subRow, getSubRows)) {
						handleRowById(subRow.id);
					}
				});
			}
		};

		handleRowById(id);

		return {
			...state,
			selectedRowIds: newSelectedRowIds
		};
	}

	// need to think if we can support allPageRows with new approach
	// or we can postpone it
	if (action.type === actions.toggleAllPageRowsSelected) {
		const { value: setSelected } = action;
		const { page, rowsById, selectSubRows = true, isAllPageRowsSelected, getSubRows } = instance;

		const selectAll = typeof setSelected !== 'undefined' ? setSelected : !isAllPageRowsSelected;

		const newSelectedRowIds = { ...state.selectedRowIds };

		const handleRowById = id => {
			const row = rowsById[id];

			if (!row.isGrouped) {
				if (selectAll) {
					newSelectedRowIds[id] = true;
				} else {
					delete newSelectedRowIds[id];
				}
			}

			if (selectSubRows && getSubRows(row)) {
				getSubRows(row).forEach(subRow => handleRowById(subRow.id));
			}
		};

		page.forEach(row => handleRowById(row.id));

		return {
			...state,
			selectedRowIds: newSelectedRowIds
		};
	}
	return state;
}

function useInstance(instance) {
	const {
		data,
		rows,
		getHooks,
		plugins,
		rowsById,
		nonGroupedRowsById = rowsById,
		autoResetSelectedRows = true,
		state: { selectedRowIds },
		dispatch,
		page,
		getSubRows,
		// should be passed as additional param to useTable hook
		getRowSelectable = defaultGetRowSelectable
	} = instance;

	ensurePluginOrder(
		plugins,
		['useFilters', 'useGroupBy', 'useSortBy', 'useExpanded', 'usePagination'],
		'useRowSelect'
	);

	const selectedFlatRows = React.useMemo(() => {
		const selectedFlatRowsAccumulator = [];

		rows.forEach(row => {
			const isSelected = getRowIsSelected(row, selectedRowIds, getSubRows, getRowSelectable);
			const nextRow = Object.assign(row, {
				isSelected: Boolean(isSelected),
				isSomeSelected: isSelected === null
			});

			if (isSelected) {
				selectedFlatRowsAccumulator.push(nextRow);
			}
		});

		return selectedFlatRowsAccumulator;
	}, [rows, selectedRowIds, getSubRows, getRowSelectable]);

	// selectableNonGroupedRowsById to store in instance
	const selectableNonGroupedRowsById = React.useMemo(() => {
		return Object.entries(nonGroupedRowsById).reduce((acc, [key, row]) => {
			// @ts-ignore
			if (getRowSelectable(row)) {
				acc[key] = row;
			}
			return acc;
		}, {});
	}, [nonGroupedRowsById, getRowSelectable]);

	let isAllRowsSelected = Boolean(
		// In case we need to account for all rows not only selectable
		// Object.keys(nonGroupedRowsById).length && Object.keys(selectedRowIds).length
		Object.keys(selectableNonGroupedRowsById).length && Object.keys(selectedRowIds).length
	);

	let isAllPageRowsSelected = isAllRowsSelected;

	if (isAllRowsSelected) {
		// if (Object.keys(nonGroupedRowsById).some(id => !selectedRowIds[id])) {
		//   isAllRowsSelected = false
		// }
		if (Object.keys(selectableNonGroupedRowsById).some(id => !selectedRowIds[id])) {
			isAllRowsSelected = false;
		}
	}

	// need to think how to support page logic
	// or postpone until it is needed
	if (!isAllRowsSelected) {
		if (page && page.length && page.some(({ id }) => !selectedRowIds[id])) {
			isAllPageRowsSelected = false;
		}
	}

	const getAutoResetSelectedRows = useGetLatest(autoResetSelectedRows);

	useMountedLayoutEffect(() => {
		if (getAutoResetSelectedRows()) {
			dispatch({ type: actions.resetSelectedRows });
		}
	}, [dispatch, data]);

	const toggleAllRowsSelected = React.useCallback(
		value => dispatch({ type: actions.toggleAllRowsSelected, value }),
		[dispatch]
	);

	const toggleAllPageRowsSelected = React.useCallback(
		value => dispatch({ type: actions.toggleAllPageRowsSelected, value }),
		[dispatch]
	);

	const toggleRowSelected = React.useCallback(
		(id, value) => dispatch({ type: actions.toggleRowSelected, id, value }),
		[dispatch]
	);

	const getInstance = useGetLatest(instance);

	const getToggleAllRowsSelectedProps = makePropGetter(getHooks().getToggleAllRowsSelectedProps, {
		instance: getInstance()
	});

	const getToggleAllPageRowsSelectedProps = makePropGetter(getHooks().getToggleAllPageRowsSelectedProps, {
		instance: getInstance()
	});

	Object.assign(instance, {
		selectedFlatRows,
		isAllRowsSelected,
		isAllPageRowsSelected,
		toggleRowSelected,
		toggleAllRowsSelected,
		getToggleAllRowsSelectedProps,
		getToggleAllPageRowsSelectedProps,
		toggleAllPageRowsSelected,
		selectableNonGroupedRowsById
	});
}

function prepareRow(row, { instance }) {
	Object.assign(row, {
		toggleRowSelected: set => instance.toggleRowSelected(row.id, set),
		getToggleRowSelectedProps: makePropGetter(instance.getHooks().getToggleRowSelectedProps, {
			instance,
			row
		})
	});
}

function getRowIsSelected(row, selectedRowIds, getSubRows, getRowSelectable) {
	if (selectedRowIds[row.id] && getRowSelectable(row)) {
		// extended with getRowSelectable(row) for disabled items
		return true;
	}

	const subRows = getSubRows(row).filter(row => getRowSelectable(row));

	if (subRows && subRows.length) {
		let allChildrenSelected = true;
		let someSelected = false;

		subRows.forEach(subRow => {
			// Bail out early if we know both of these
			if (someSelected && !allChildrenSelected) {
				return;
			}

			if (getRowIsSelected(subRow, selectedRowIds, getSubRows, getRowSelectable)) {
				someSelected = true;
			} else {
				allChildrenSelected = false;
			}
		});

		if (allChildrenSelected) {
			return true;
		}

		return someSelected ? null : false;
	}

	return false;
}
