import React, { forwardRef, useContext, useEffect, useState } from 'react';

import ReactDOM from 'react-dom';
import classNames from 'classnames';
import { BringgFontIcons, BringgIcon } from '@bringg/bringg-icons';
import { Checkbox } from '@bringg/react-components';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';

import { DraggableListContext } from '../../utils/store-context';

import styles from './draggable-list-item.module.scss';

export interface Props {
	checked: boolean;
	dragDisabled: boolean;
	id: string;
	title: string;
	onSelectCheckbox: (event: CheckboxChangeEvent) => void;
	checkboxDisabled?: boolean;
	isDragging?: boolean;
	handlerId?: any;
	withFadeIn?: boolean;
}

export const DraggableListItem = forwardRef<HTMLDivElement, Props>(
	(
		{
			id,
			title,
			checked,
			dragDisabled,
			onSelectCheckbox,
			checkboxDisabled,
			isDragging,
			handlerId,
			withFadeIn,
			...otherProps
		},
		ref
	) => {
		const draggableList = useContext(DraggableListContext);
		const [fadeIn, setFadeIn] = useState(false);
		const [fadeOut, setFadeOut] = useState(false);

		useEffect(() => {
			if (withFadeIn) {
				setFadeIn(withFadeIn);

				setTimeout(() => {
					setFadeIn(false);
				}, 500);
			}
		}, [withFadeIn]);

		const handleCheckboxChange = (e: CheckboxChangeEvent) => {
			setFadeOut(true);
			onSelectCheckbox?.(e);
		};

		const child = (
			<div
				ref={ref}
				data-handler-id={handlerId}
				data-test-id={`item-${id}`}
				className={classNames(styles.draggableItem, {
					[styles.dragDisabled]: dragDisabled,
					[styles.dragging]: isDragging,
					[styles.fadeIn]: fadeIn,
					[styles.fadeOut]: fadeOut
				})}
				{...otherProps}
				role="listitem"
			>
				<BringgIcon iconName={BringgFontIcons.Drag} />
				<Checkbox
					id={id}
					className={styles.checkBox}
					defaultChecked={checked}
					disabled={checkboxDisabled}
					onChange={handleCheckboxChange}
					data-test-id={`checkbox-${id}`}
				>
					{title}
				</Checkbox>
			</div>
		);

		if (!isDragging) return child;

		// most of the use cases of the draggable-list is in a popover
		// so we need to render the dragged element in a portal to prevent the position: fixed and transform styling to go off
		return ReactDOM.createPortal(child, draggableList?.dragPortal.current || document.body);
	}
);

export default DraggableListItem;
