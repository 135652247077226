import React from 'react';
import { useTranslation } from 'react-i18next';
import { useObserver } from 'mobx-react';
import { TimeService } from '@bringg/frontend-utils';
import { BringgFontIcons, BringgIcon } from '@bringg/bringg-icons';
import { useMerchantConfiguration } from '../../../recipes';
import TimezoneService from '../../../services/timezone/timezone-service';
import Driver from '../../../stores/drivers/domain-object/driver';
import { Translate } from '../../../translation';
import DeliveryBlock from '../stores/domain-objects/delivery-block';

interface Props {
	startTime: string;
	endTime: string;
	driversWithConflictedBlocks: Map<Driver, DeliveryBlock>;
	timezone?: string;
}

const MESSAGE_TIME_FORMAT = 'h:mm A';
const DELIVERY_BLOCK_TIME_FORMAT = 'hh:mm A';

const MoveBlockConfirmationModal: React.FC<Props> = ({ startTime, endTime, driversWithConflictedBlocks, timezone }) => {
	const { t } = useTranslation();
	const { hour_format: hoursFormat } = useMerchantConfiguration();

	const momentTz = TimezoneService.getMomentTimezone(timezone);
	const formattedRequestedTime = `${TimeService.format(
		momentTz(startTime),
		MESSAGE_TIME_FORMAT,
		hoursFormat
	)} - ${TimeService.format(momentTz(endTime), MESSAGE_TIME_FORMAT, hoursFormat)}`;

	const formatConflictedTime = (startTime: string, endTime: string) => {
		return `${TimeService.format(momentTz(startTime), DELIVERY_BLOCK_TIME_FORMAT)} - ${TimeService.format(
			momentTz(endTime),
			DELIVERY_BLOCK_TIME_FORMAT
		)}`;
	};

	const title = `${t('DELIVERY_BLOCKS.MOVE_BLOCK_CONFIRM_MESSAGE_PART1')} ${formattedRequestedTime} ${t(
		'DELIVERY_BLOCKS.MOVE_BLOCK_CONFIRM_MESSAGE_PART2'
	)} ${driversWithConflictedBlocks.size} ${t('DELIVERY_BLOCKS.MOVE_BLOCK_CONFIRM_MESSAGE_PART3')}`;

	return useObserver(() => (
		<div className="move-block-confirmation-content">
			<div className="title">{title}</div>

			<div className="drivers-list">
				{Array.from(driversWithConflictedBlocks.entries()).map(([driver, deliveryBlock]) => (
					<div key={driver.id} className="driver-conflict">
						<div className="driver-info">
							<BringgIcon className="dot" iconName={BringgFontIcons.Circle} />
							{driver.name}
						</div>
						<div data-test-id="driver-conflicted-time">
							{formatConflictedTime(deliveryBlock.start_time, deliveryBlock.end_time)}
						</div>
					</div>
				))}
			</div>

			<div className="message">
				<Translate text="DELIVERY_BLOCKS.MOVE_BLOCK_ARE_YOU_SURE" />
			</div>
		</div>
	));
};

export default MoveBlockConfirmationModal;
