import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { reaction } from 'mobx';
import { RouteComponentProps } from 'react-router';
import CompaniesStore from '../../../stores/companies/companies-store';
import { Spinner } from '../../../components';
import CompanyCard from '../company-card/company-card';
import CompanyTables from '../company-tables/company-tables';

interface MatchParams {
	id: string;
}

interface Props extends RouteComponentProps<MatchParams> {
	companiesStore?: CompaniesStore;
}

class CompanyContainer extends Component<Props, {}> {
	disposeReaction: () => void = null;

	componentDidMount() {
		this.disposeReaction = reaction(
			() => this.props.match.params.id,
			companyId => {
				this.props.companiesStore.fetch(Number(companyId));
				this.props.companiesStore.fetchUsers(Number(companyId));
			},
			{
				fireImmediately: true
			}
		);
	}

	componentWillUnmount() {
		this.disposeReaction();
	}

	render() {
		const companyId = Number(this.props.match.params.id);
		const company = this.props.companiesStore.get(companyId);

		if (!company) {
			return <Spinner />;
		}

		return (
			<div className="company-container">
				<CompanyCard company={company} />
				<CompanyTables dispatchers={company.dispatchers} drivers={company.drivers} />
			</div>
		);
	}
}

export default inject('companiesStore')(observer(CompanyContainer));
