import React from 'react';

import classNames from 'classnames';
import { BringgFontIcons, BringgIcon } from '@bringg/bringg-icons';
import { Progress } from '@bringg/react-components';

import { Translation } from '../../types';

import style from './index.module.scss';

interface Props {
	translations: Pick<Translation, 'progress' | 'allSuccess' | 'someSuccess'>;

	totalNumberOfActions: number;
	currentNumberOfActionsSucceeded: number;
	numberOfCompleted: number;

	isCompleted: boolean;
}

const SuccessRow = ({
	translations,
	totalNumberOfActions,
	currentNumberOfActionsSucceeded,
	numberOfCompleted,
	isCompleted
}: Props) => {
	const isAllFailures = isCompleted && currentNumberOfActionsSucceeded === 0;
	const isAllSuccess = isCompleted && currentNumberOfActionsSucceeded === totalNumberOfActions;

	if (isAllFailures) {
		return null;
	}

	const completedIcon = isCompleted && (
		<BringgIcon
			key="completed-icon"
			iconName={BringgFontIcons.SelectedOutline}
			className={classNames(style.iconStatus, style.success)}
		/>
	);

	const currentSuccessPercents = (numberOfCompleted / totalNumberOfActions) * 100;
	const progress = !isCompleted && (
		<Progress
			key="not-completed-progress-circle"
			type="circle"
			percent={currentSuccessPercents}
			strokeLinecap="butt"
			strokeWidth={15}
			size={20}
			className={classNames(style.progress, style.iconStatus)}
			// Don't show the progress text
			showInfo={false}
			strokeColor="var(--gray-600)"
			trailColor={'var(--gray-200)'}
		/>
	);

	const completedContent = isCompleted && (
		<span className={style.completedWrapper} key="completed-progress-content">
			<span className={classNames(style.numberOfItems, style.completed)}>{currentNumberOfActionsSucceeded}</span>
			{isAllSuccess ? translations.allSuccess : translations.someSuccess}
		</span>
	);

	const inProgressContent = !isCompleted && (
		<span className={style.inProgress} key="not-completed-progress-content">
			<span className={style.numberOfItems}>
				<span className={classNames(style.successfulItems)}>{currentNumberOfActionsSucceeded}</span>
				<span>/{totalNumberOfActions}</span>
			</span>
			{translations.progress}
		</span>
	);

	return (
		<div className={style.row}>
			{completedIcon}
			{progress}

			<span className={style.text}>
				{completedContent}
				{inProgressContent}
			</span>
		</div>
	);
};

export default SuccessRow;
