import React, { memo } from 'react';
import { AdjustPopoverOverflow, Button, DropdownMenu, Tooltip } from '@bringg/react-components';
import { BringgFontIcons, BringgIcon } from '@bringg/bringg-icons';

import styles from './template-tag.module.scss';

export type PlaceholderData = {
	type: string;
	info: string;
	values?: PlaceholderData[];
	disabled?: boolean;
};

interface TemplateTagProps {
	labels: PlaceholderData[];
	textDescription: string;
	onClick(type: string): void;
}

export const TemplateTag = memo(({ textDescription, labels, onClick }: TemplateTagProps) => {
	return (
		<>
			<div className={styles.description}>{textDescription}</div>
			<div className={styles.labelContainer}>
				{labels.map(label => (
					<Tooltip title={label.info} key={label.type}>
						{label.values ? (
							<DropdownMenu
								adjustOverflow={AdjustPopoverOverflow.ADJUST_INTO_VIEW}
								trigger={['click']}
								items={label.values.map(({ type, info, disabled }) => ({
									key: type,
									disabled,
									label: (
										<Tooltip title={info} key={type}>
											{type}
										</Tooltip>
									),
									onClick: () => onClick(type)
								}))}
							>
								<Button className={styles.labelButton} data-test-id={label.type}>
									<div>{label.type}</div>
									<BringgIcon iconName={BringgFontIcons.Chevron} />
								</Button>
							</DropdownMenu>
						) : (
							<Button
								className={styles.labelButton}
								data-test-id={label.type}
								onClick={() => onClick(label.type)}
							>
								{label.type}
							</Button>
						)}
					</Tooltip>
				))}
			</div>
		</>
	);
});
