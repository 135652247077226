import i18next from 'i18next';
import { RuleCompareOperator } from '@bringg/types';
import { ConditionType } from '@bringg/react-components';

import ArrayConditionStore from '../../stores/array-condition-store';
import { ContentArrayType } from './workflow-content-generator';
import RulesRepo from '../../stores/rules-repo';
import ConditionStore from '../../stores/condition-store';
import RuleStore from '../../stores/rule-store';
import {
	addPath,
	fieldValuesMapper,
	getArrayOperatorTranslation,
	getEntityTranslation,
	getNumericOperatorTranslation,
	getStringOperatorTranslations
} from './utils';
import {
	and,
	andOnlyIf,
	condition,
	conditions,
	from,
	is,
	isNot,
	ofKey,
	or,
	that,
	to
} from '../use-automation-translations';

const getMultiConditionMapper = (condition: ConditionStore | ArrayConditionStore): ContentArrayType[] => {
	const isNumericCondition =
		condition.conditionMetadata.type === ConditionType.NUMBER ||
		condition.conditionMetadata.type === ConditionType.NUMBER_ARRAY;
	const content: ContentArrayType[] = [
		{
			title: isNumericCondition
				? getNumericOperatorTranslation(condition.operator)
				: getStringOperatorTranslations(condition.operator)
		}
	];

	if (
		condition.operator === RuleCompareOperator.IN_RANGE ||
		condition.operator === RuleCompareOperator.ARRAY_ELEMENT_IN_RANGE
	) {
		content.push(
			{
				title: i18next.t(from),
				isPale: true
			},
			{
				title: `${condition.value[0]}`
			},
			{
				title: i18next.t(to),
				isPale: true
			},
			{
				title: `${condition.value[1]}`
			}
		);
	} else {
		content.push({
			title: condition.comparable ? condition.displayComparable : `${condition.value || ''}`
		});
	}
	return content;
};

const getArrayConditionMapper = (condition: ConditionStore, isFull: boolean): ContentArrayType[] => {
	const content = [];

	content.push(
		addPath(condition.displayPath),
		{
			title: getArrayOperatorTranslation(condition.operator)
		},
		{
			title: i18next.t(ofKey),
			isPale: true
		},
		fieldValuesMapper(condition.value as number[], condition.conditionMetadata.options, isFull)
	);

	return content;
};

const getDatetimeConditionMapper = (condition: ConditionStore): ContentArrayType[] => {
	const content = [];

	content.push(
		addPath(condition.displayPath),
		{
			title: i18next.t(is),
			isPale: true
		},
		{
			title: getNumericOperatorTranslation(condition.operator)
		}
	);

	if (condition.displayComparable) {
		content.push(addPath(condition.displayComparable));
	}

	return content;
};

const getStringArrayConditionMapper = (condition: ArrayConditionStore): ContentArrayType[] => {
	const content = [];

	content.push(
		{
			title: getEntityTranslation(condition.factType),
			isPale: true
		},
		{
			title: getArrayOperatorTranslation(condition.arrayValueType)
		},
		addPath(condition.displayPath),
		{
			title: i18next.t(that),
			isPale: true
		},
		{
			title: condition.operator ? getStringOperatorTranslations(condition.operator) : ''
		},
		{
			title: (condition.value as string[]).join(', ')
		}
	);

	return content;
};

const getBooleanArrayConditionMapper = (condition: ArrayConditionStore): ContentArrayType[] => {
	return [
		{
			title: getEntityTranslation(condition.factType),
			isPale: true
		},
		{ title: getArrayOperatorTranslation(condition.arrayValueType) },

		{ title: `${condition.container} ${i18next.t(that)}`, isPale: true },

		{
			title: condition.value[0] ? i18next.t(is) : i18next.t(isNot)
		},
		addPath(condition.displayPath)
	];
};

const getNumberArrayConditionMapper = (condition: ArrayConditionStore, isFull: boolean): ContentArrayType[] => {
	if (condition.conditionMetadata.options) {
		return getListValues(condition, isFull);
	}

	const content: ContentArrayType[] = [
		{
			title: getEntityTranslation(condition.factType),
			isPale: true
		},
		{ title: getArrayOperatorTranslation(condition.arrayValueType) },
		{ title: `${condition.container} ${i18next.t(that)}`, isPale: true }
	];

	content.push(addPath(condition.displayPath));

	content.push(...getMultiConditionMapper(condition));

	return content;
};

const getListValues = (condition: ConditionStore | ArrayConditionStore, isFull: boolean) => {
	return [
		addPath(condition.displayPath),
		{
			title: i18next.t(
				condition.operator === RuleCompareOperator.IN ||
					condition.operator === RuleCompareOperator.ARRAY_ELEMENT_IN
					? is
					: isNot
			),
			isPale: true
		},
		fieldValuesMapper(condition.value as number[], condition.conditionMetadata.options, isFull)
	];
};

export const getAtomicConditionMapper = (
	condition: ConditionStore | ArrayConditionStore,
	isFull: boolean
): ContentArrayType[] => {
	let content = [];

	switch (condition.conditionMetadata?.type) {
		case ConditionType.LIST:
			content = getListValues(condition, isFull);
			break;

		case ConditionType.BOOLEAN:
			content.push(
				{
					title: getEntityTranslation(condition.factType),
					isPale: true
				},
				{
					title: condition.value[0] ? i18next.t(is) : i18next.t(isNot)
				},
				addPath(condition.displayPath)
			);
			break;

		case ConditionType.ARRAY:
			content.push(...getArrayConditionMapper(condition as ConditionStore, isFull));
			break;

		case ConditionType.DATETIME:
			content.push(...getDatetimeConditionMapper(condition as ConditionStore));
			break;

		case ConditionType.STRING_ARRAY:
			content.push(...getStringArrayConditionMapper(condition as ArrayConditionStore));
			break;

		case ConditionType.NUMBER_ARRAY:
			content.push(...getNumberArrayConditionMapper(condition as ArrayConditionStore, isFull));
			break;

		case ConditionType.BOOLEAN_ARRAY:
			content.push(...getBooleanArrayConditionMapper(condition as ArrayConditionStore));
			break;

		case ConditionType.NUMBER:
		case ConditionType.STRING:
			content.push(...getMultiConditionMapper(condition as ConditionStore));
			break;

		default:
			return [];
	}
	return content;
};

export const conditionsMapper = (rules: RulesRepo, isFull: boolean): ContentArrayType[] => {
	if (rules.isEmpty) {
		return [];
	}

	const content: ContentArrayType[] = [];

	rules.rules.forEach((orRule, allRulesIndex) => {
		orRule.conditions.forEach((condition, index) => {
			switch (condition.conditionMetadata?.type) {
				case ConditionType.NUMBER:
				case ConditionType.STRING:
					content.push(addPath(condition.displayPath), { title: i18next.t(is), isPale: true });
					(condition as RuleStore).conditions.forEach((cond, ruleStoreIndex, arr) => {
						if (ruleStoreIndex > 0 && ruleStoreIndex < arr.length) {
							content.push({
								title: i18next.t(or),
								isPale: true
							});
						}
						content.push(...getAtomicConditionMapper(cond as ConditionStore, isFull));
					});
					break;

				default:
					content.push(...getAtomicConditionMapper(condition as ConditionStore, isFull));
			}

			if (index < orRule.conditions.length - 1) {
				content.push({ title: i18next.t(and), isPale: true });
			}
		});

		if (allRulesIndex < rules.rules.length - 1) {
			content.push({ title: i18next.t(or), isPale: true });
		}
	});
	if (!isFull) {
		const conditionsTranslation = rules.conditionsLength > 1 ? i18next.t(conditions) : i18next.t(condition);
		return [
			{
				title: `${i18next.t(andOnlyIf)} ${rules.conditionsLength} ${conditionsTranslation}`,
				isPale: true
			}
		];
	}

	content.unshift({ title: i18next.t(andOnlyIf), isPale: true });
	return content;
};
