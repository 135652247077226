import React, { memo, useMemo } from 'react';
import moment from 'moment';

// TODO: move to shared utils folder to eliminate dependency on automation-workflows feature
import { getFormatByMerchant } from 'bringg-web/features/automation-workflows/utils/helpers';

interface Props {
	date: string;
}

function DateCell({ date }: Props) {
	const format = getFormatByMerchant();

	const formattedDate = useMemo(() => {
		const momentDate = moment(date);

		return `${momentDate.format('DD/MM/YY')} ${momentDate.format(format)}`;
	}, [date, format]);

	return <span> {formattedDate}</span>;
}

export default memo(DateCell);
