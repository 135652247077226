import React, { memo, useMemo, useCallback, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { CustomAttributeDataType } from '@bringg/types';
import {
	FilterBar2,
	FilterBar2ListItem,
	FilterBar2Values,
	getDefaultFilterBarTranslations
} from '@bringg/react-components';

import { AttributeStatusEnum, AttributeMandatoryEnum, AttributeDriverAppEnum } from '../utils';
import type { UseTableFilters } from '../hooks/use-table-filters';

interface Props {
	filtersState: UseTableFilters;
}

function FiltersPanel({ filtersState }: Props) {
	const { t } = useTranslation();

	const { filters, updateFilters, resetFilters } = filtersState;

	const [additionalFiltersNames, setAdditionalFiltersNames] = useState(['']);

	const filterBarTranslations = useMemo(() => getDefaultFilterBarTranslations(t), [t]);

	const suggestionOptions = useMemo(
		() => [
			{ value: AttributeStatusEnum.ALL, label: t('CUSTOM_ATTRIBUTES.FILTERS.BOTH') },
			{ value: AttributeStatusEnum.ACTIVE, label: t('CUSTOM_ATTRIBUTES.FILTERS.ACTIVE') },
			{ value: AttributeStatusEnum.SUGGESTED, label: t('CUSTOM_ATTRIBUTES.FILTERS.SUGGESTED') }
		],
		[t]
	);

	const dataTypeOptions = useMemo(
		() => [
			{
				value: CustomAttributeDataType.Number,
				label: t('CUSTOM_ATTRIBUTES.NUMBER_TYPE')
			},
			{
				value: CustomAttributeDataType.String,
				label: t('CUSTOM_ATTRIBUTES.STRING_TYPE')
			},
			{
				value: CustomAttributeDataType.Boolean,
				label: t('CUSTOM_ATTRIBUTES.BOOLEAN_TYPE')
			},
			{
				value: CustomAttributeDataType.Enum,
				label: t('CUSTOM_ATTRIBUTES.ENUM_TYPE')
			}
		],
		[t]
	);

	const mandatoryOptions = useMemo(
		() => [
			{ value: AttributeMandatoryEnum.ALL, label: t('CUSTOM_ATTRIBUTES.FILTERS.BOTH') },
			{
				value: AttributeMandatoryEnum.MANDATORY,
				label: t('CUSTOM_ATTRIBUTES.FILTERS.IS_MANDATORY')
			},
			{
				value: AttributeMandatoryEnum.NOT_MANDATORY,
				label: t('CUSTOM_ATTRIBUTES.FILTERS.NOT_MANDATORY')
			}
		],
		[t]
	);

	const driverAppOptions = useMemo(
		() => [
			{ value: AttributeDriverAppEnum.ALL, label: t('CUSTOM_ATTRIBUTES.FILTERS.BOTH') },
			{
				value: AttributeDriverAppEnum.DRIVER_APP_ENABLED,
				label: t('CUSTOM_ATTRIBUTES.FILTERS.DRIVER_APP')
			},
			{
				value: AttributeDriverAppEnum.DRIVER_APP_DISABLED,
				label: t('CUSTOM_ATTRIBUTES.FILTERS.DRIVER_APP_DISABLED')
			}
		],
		[t]
	);

	const handleAdditionalAdded = useCallback((keys: string[]) => setAdditionalFiltersNames(keys), []);

	const filterInputs: FilterBar2ListItem[] = useMemo(() => {
		return [
			{
				type: FilterBar2.TYPES.SEARCH,
				applyFilterOnValueChange: true,
				name: 'searchText',
				placeholder: t(`CUSTOM_ATTRIBUTES.FILTERS.TEXT_SEARCH`)
			},
			{
				type: FilterBar2.TYPES.RADIO,
				applyFilterOnValueChange: true,
				name: 'suggestion',
				title: t(`CUSTOM_ATTRIBUTES.FILTERS.STATE`),
				values: suggestionOptions
			},
			{
				type: FilterBar2.TYPES.SELECT,
				multiselect: true,
				applyFilterOnValueChange: true,
				name: 'data_type',
				title: t(`CUSTOM_ATTRIBUTES.FILTERS.TYPE`),
				values: dataTypeOptions
			},
			{
				additional: true,
				type: FilterBar2.TYPES.RADIO,
				applyFilterOnValueChange: true,
				name: 'required',
				title: t(`CUSTOM_ATTRIBUTES.FILTERS.IS_MANDATORY`),
				values: mandatoryOptions
			},
			{
				additional: true,
				type: FilterBar2.TYPES.RADIO,
				applyFilterOnValueChange: true,
				name: 'show_in_mobile_app',
				title: t(`CUSTOM_ATTRIBUTES.FILTERS.DRIVER_APP`),
				values: driverAppOptions
			}
		];
	}, [t]);

	const initialFilterValues = useMemo(() => filters as unknown as FilterBar2Values, []);

	return (
		<div className="custom-attributes-filter">
			<FilterBar2
				initialValues={initialFilterValues}
				onReset={resetFilters}
				list={filterInputs}
				resetToInitial
				initialAdditional={additionalFiltersNames}
				onAdditional={handleAdditionalAdded}
				translations={filterBarTranslations}
				// be aware the way apply whole new obj to filter observer maybe will rerender the whole filterbar
				onFilter={updateFilters}
			/>
		</div>
	);
}

export default memo(FiltersPanel);
