export const labelRegex = /{{(.*?)}}/g;
const ZWSP = '\u200b';

const matchText = str => str.matchAll(labelRegex);

export const findMismatchLabels = (str: string, labels: Set<string>): string[] => {
	const mismatch = [];
	if (!str) {
		return mismatch;
	}

	const matches = matchText(str);
	for (const match of matches) {
		if (!labels.has(match[1])) {
			mismatch.push(match[1]);
		}
	}

	return mismatch;
};

export const getDisplayMismatch = mismatch => mismatch.map(match => `'{${ZWSP}{${match}}}'`);
