import React from 'react';
import { Button } from '@bringg/react-components';
import { useTranslation } from 'react-i18next';

interface Props {
	isEditMode: boolean;
	updatePlannedDeliveryWindow: () => void;
	deletePlannedDeliveryWindow: () => void;
	closeRecurrenceTypeModalAndCancel: () => void;
	loader: boolean;
}

const RecurrenceTypeModalFooter = ({
	isEditMode,
	updatePlannedDeliveryWindow,
	deletePlannedDeliveryWindow,
	closeRecurrenceTypeModalAndCancel,
	loader
}: Props) => {
	const { t } = useTranslation();
	return (
		<>
			<Button type="text" onClick={closeRecurrenceTypeModalAndCancel}>
				{t('PLANNED_DELIVERY_WINDOWS_RECURRENCE_MODAL_FOOTER.CANCEL')}
			</Button>
			<Button
				type="primary"
				danger={!isEditMode}
				onClick={isEditMode ? updatePlannedDeliveryWindow : deletePlannedDeliveryWindow}
				loading={loader}
			>
				{isEditMode
					? t('PLANNED_DELIVERY_WINDOWS_RECURRENCE_MODAL_FOOTER.UPDATE')
					: t('PLANNED_DELIVERY_WINDOWS_RECURRENCE_MODAL_FOOTER.DELETE')}
			</Button>
		</>
	);
};

export default RecurrenceTypeModalFooter;
