import React from 'react';

import { observer } from 'mobx-react';
import { Button, DatePicker, DropdownMenu, Select } from '@bringg/react-components';
import { useTranslation } from 'react-i18next';
import moment, { Moment } from 'moment-timezone';
import { BringgFontIcons, BringgIcon } from '@bringg/bringg-icons';
import { DropdownMenuItem } from '@bringg/react-components/dist/components/dropdown-menu/dropdown-menu';
import { TriggerType } from '@bringg/react-components/dist/components/popover/popover';
import { dateUtils } from '@bringg-frontend/utils';

import { useStores } from '../../../recipes';
import { NO_TEAM } from '../planned-delivery-windows-view';
import { CalendarViewMode } from 'bringg-web/features/planned-delivery-windows/calendar/planned-delivery-windows-calendar';

import styles from './planned-delivery-windows-toolbar.module.scss';

export type DropdownData = {
	items: DropdownMenuItem[];
	trigger: TriggerType[];
	text: string;
};

interface Props {
	selectedTeam: number;
	onTeamChange: (teamId: number) => void;
	changeCalendarRangeDates?: (startTime: string, endTime: string) => void;
	calendarRangeDates?: { startDate: string; endDate: string };
	timezone: string;
	dropdownData: DropdownData;
	calendarViewMode?: CalendarViewMode;
	setCalendarViewMode?: React.Dispatch<React.SetStateAction<CalendarViewMode>>;
}

const PlannedDeliveryWindowsToolbar = ({
	selectedTeam,
	onTeamChange,
	calendarRangeDates,
	changeCalendarRangeDates,
	timezone,
	dropdownData,
	calendarViewMode,
	setCalendarViewMode
}: Props) => {
	const { teamsStore } = useStores();
	const { t } = useTranslation();
	const byMerchant = { id: NO_TEAM, name: t('PLANNED_DELIVERY_WINDOWS.BY_MERCHANT') };
	const options = teamsStore.all.map(({ id, name }) => ({ id, name }));
	options.unshift(byMerchant);

	const onTeamSelect = teamId => {
		onTeamChange(teamId);
	};

	const calendarViewModeOptions = [
		{ id: CalendarViewMode.week, name: t('PLANNED_DELIVERY_WINDOWS_TOOLBAR_CALENDAR_VIEW.WEEK_VIEW') },
		{ id: CalendarViewMode.day, name: t('PLANNED_DELIVERY_WINDOWS_TOOLBAR_CALENDAR_VIEW.DAY_VIEW') }
	];

	const onCalendarViewModeSelect = (calendarViewModeSelected: CalendarViewMode) => {
		const isWeekMode = calendarViewModeSelected === CalendarViewMode.week;
		let startRange = moment(calendarRangeDates.startDate).tz(timezone).startOf('week').toISOString();
		let endRange = moment(calendarRangeDates.startDate).tz(timezone).endOf(calendarViewModeSelected).toISOString();

		if (!isWeekMode) {
			const currentDayDate = dateUtils.getCurrentDayDateBetweenRangeDates(
				calendarRangeDates.startDate,
				calendarRangeDates.endDate,
				timezone
			);

			startRange = currentDayDate.clone().startOf('day').toISOString();
			endRange = currentDayDate.clone().endOf('day').toISOString();
		}

		changeCalendarRangeDates(startRange, endRange);
		setCalendarViewMode(calendarViewModeSelected);
	};

	const getDayText = () => {
		const fullDateFormat = 'DD MMM YYYY';
		return (
			<span className={styles.weekRangeDates}>{`${moment(calendarRangeDates.startDate)
				.tz(timezone)
				.format(fullDateFormat)}`}</span>
		);
	};

	const getWeekText = () => {
		const startRange = moment(calendarRangeDates.startDate).tz(timezone);
		const endRange = moment(calendarRangeDates.endDate).tz(timezone);
		const isSameMonth = startRange.month() === endRange.month();
		const isSameYear = startRange.year() === endRange.year();
		const fullDateFormat = 'DD MMM YYYY';
		const dateAndMonthFormat = 'DD MMM';
		const startDateFormat = isSameMonth && isSameYear ? 'DD' : isSameYear ? dateAndMonthFormat : fullDateFormat;
		const endDateFormat = fullDateFormat;
		return (
			<>
				<span className={styles.weekNumber}>{`${t('PLANNED_DELIVERY_WINDOWS_TOOLBAR.WEEK_TEXT')} ${moment(
					calendarRangeDates.startDate
				)
					.tz(timezone)
					.format('ww')} `}</span>
				<span className={styles.weekRangeDates}>{`(${moment(calendarRangeDates.startDate)
					.tz(timezone)
					.format(startDateFormat)} - ${moment(calendarRangeDates.endDate)
					.tz(timezone)
					.format(endDateFormat)})`}</span>
			</>
		);
	};

	const goToNext = () => {
		changeCalendarRangeDates(
			moment(calendarRangeDates.startDate).tz(timezone).add(1, calendarViewMode).toISOString(),
			moment(calendarRangeDates.endDate).tz(timezone).add(1, calendarViewMode).toISOString()
		);
	};

	const goToPrev = () => {
		changeCalendarRangeDates(
			moment(calendarRangeDates.startDate).tz(timezone).subtract(1, calendarViewMode).toISOString(),
			moment(calendarRangeDates.endDate).tz(timezone).subtract(1, calendarViewMode).toISOString()
		);
	};

	return (
		<div className={styles.toolbar}>
			<div className={styles.teamSelectionWrapper}>
				<Select
					className={styles.teamSelect}
					defaultValue={selectedTeam}
					options={options}
					showSearch
					onSelect={onTeamSelect}
					filterOption={(input, option) => option.title.toLowerCase().includes(input.toLowerCase())}
					placeholder={t('PLANNED_DELIVERY_WINDOWS_TOOLBAR.TEAM_SELECTION_INPUT')}
				/>

				<span className={styles.teamTimezone}>{`(${timezone || moment.tz.guess()})`}</span>
			</div>
			<div className={styles.weekPickerWrapper}>
				{calendarRangeDates && changeCalendarRangeDates && (
					<>
						<div className={styles.weekPicker}>
							<div className={styles.weekPickerText}>
								{calendarViewMode === CalendarViewMode.week ? getWeekText() : getDayText()}
							</div>
							<DatePicker
								value={moment(calendarRangeDates.startDate).tz(timezone)}
								className={styles.weekDatePicker}
								picker={calendarViewMode === CalendarViewMode.week ? calendarViewMode : 'date'}
								onChange={(date: Moment) =>
									changeCalendarRangeDates(
										date.tz(timezone).startOf(calendarViewMode).toISOString(),
										date.tz(timezone).endOf(calendarViewMode).toISOString()
									)
								}
								data-test-id="planned-delivery-windows-toolbar-range-picker"
								format=" "
								allowClear={false}
							/>
						</div>
						<Button
							icon={<BringgIcon iconName={BringgFontIcons.ChevronLeft} />}
							data-test-id="prev-week-button"
							onClick={goToPrev}
							shape="circle"
							type="text"
						/>
						<Button
							icon={<BringgIcon iconName={BringgFontIcons.ChevronRight} />}
							onClick={goToNext}
							shape="circle"
							type="text"
							data-test-id="next-week-button"
						/>
					</>
				)}
			</div>
			<div className={styles.actions}>
				{calendarViewMode && (
					<Select
						className={styles.calendarViewMode}
						defaultValue={calendarViewMode}
						options={calendarViewModeOptions}
						onSelect={onCalendarViewModeSelect}
						placeholder={t('PLANNED_DELIVERY_WINDOWS_TOOLBAR_CALENDAR_VIEW.CALENDAR_VIEW_MODE_PLACEHOLDER')}
					/>
				)}
				<div className={styles.addButton}>
					<DropdownMenu items={dropdownData.items} trigger={dropdownData.trigger}>
						<Button type="primary">
							{dropdownData.text} <BringgIcon iconName={BringgFontIcons.Chevron} />
						</Button>
					</DropdownMenu>
				</div>
			</div>
		</div>
	);
};

export default observer(PlannedDeliveryWindowsToolbar);
