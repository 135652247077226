import { Vehicle } from '@bringg/types';
import { VehicleGroup } from '@bringg/dashboard-sdk/dist/Vehicle/Vehicle.consts';
import { getRoot } from 'mobx-easy';
import RootStore from '../root-store';

export function allNonTrailersByTeam(teamId: number): Vehicle[] {
	return getAllByTeam(teamId).filter(vehicle => !vehicle.is_trailer);
}

export function allTrailersByTeam(teamId: number): Vehicle[] {
	return getAllByTeam(teamId).filter(vehicle => vehicle.is_trailer);
}

function getAllByTeam(teamId: number) {
	return getRoot<RootStore>().data.vehiclesStore.getGroup(VehicleGroup.Team, teamId);
}
