import React from 'react';

import ReactDOM from 'react-dom';

import { GroupHeader, GroupHeaders } from './components/group-headers/group-headers';

export const renderHeaderComponent = (
	timelineHeaders: GroupHeader[],
	timelineContainerRef: React.RefObject<HTMLDivElement>
): void => {
	let container = timelineContainerRef.current?.querySelector('.gantt-headers-container');

	if (!container) {
		container = document.createElement('div');
		container.className = 'gantt-headers-container';
		timelineContainerRef.current?.querySelector('.vis-timeline')?.appendChild(container);
	}

	ReactDOM.render(
		<GroupHeaders headers={timelineHeaders} timelineContainer={timelineContainerRef.current} />,
		container
	);
};
