import { useMemo } from 'react';

import { useTranslation } from 'react-i18next';

import type { BatchActionStatusTranslation } from './index';

export function useBatchActionStatusTranslation(title: React.ReactNode | string): BatchActionStatusTranslation {
	const { t } = useTranslation();

	return useMemo(
		() => ({
			title,
			starting: t('BATCH_ACTION_STATUS.GENERIC.STARTING'),
			progress: t('BATCH_ACTION_STATUS.GENERIC.IN_PROGRESS'),
			allSuccess: t('BATCH_ACTION_STATUS.GENERIC.ALL_SUCCESS'),
			someSuccess: t('BATCH_ACTION_STATUS.GENERIC.SOME_SUCCESS'),
			allFailed: t('BATCH_ACTION_STATUS.GENERIC.ALL_FAILED'),
			someFailed: t('BATCH_ACTION_STATUS.GENERIC.SOME_FAILED'),
			whatColumn: t('BATCH_ACTION_STATUS.GENERIC.COLUMN_NAME.WHAT'),
			failureReasonColumn: t('BATCH_ACTION_STATUS.GENERIC.COLUMN_NAME.FAILURE_REASON')
		}),
		[title, t]
	);
}
