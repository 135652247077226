import { useMemo } from 'react';

import { observer } from 'mobx-react';
import { BringgFontIcons, BringgIcon } from '@bringg/bringg-icons';
import { DropdownMenu } from '@bringg/react-components';
import { useTranslation } from 'react-i18next';
import { Portal } from '@bringg-frontend/bringg-web-infra';

import { executeAction } from 'bringg-web/services/cross-application/cross-application';
import { HelpMenuAction } from 'bringg-web/services/cross-application/cross-application.actions';
import { useStores } from 'bringg-web/recipes';

import './help-menu.scss';

type MenuItem = { key: string; label: JSX.Element; onClick?: () => unknown };

export const HelpMenu = observer(() => {
	const { t } = useTranslation();
	const {
		authStore,
		merchantConfigurationsStore: { configuration }
	} = useStores();

	// data-id attributes for analytics
	const items = useMemo(() => {
		const menuItems: MenuItem[] = [
			{
				key: 'whats_new',
				label: (
					<a
						data-id="whats_new"
						className="help-menu-item campaign"
						href="https://help.bringg.com/docs/whats-new"
						target="_blank"
						rel="noreferrer"
					>
						<BringgIcon className="help-menu-item-icon campaign" iconName={BringgFontIcons.Campaign} />
						{t('HELP_MENU.WHATS_NEW')}
					</a>
				)
			},
			{
				key: 'help_center',
				label: (
					<a
						data-id="help_center"
						className="help-menu-item"
						href="https://help.bringg.com/"
						target="_blank"
						rel="noreferrer"
					>
						<BringgIcon className="help-menu-item-icon" iconName={BringgFontIcons.AutoStories} />
						{t('HELP_MENU.HELP_CENTER')}
					</a>
				)
			}
		];

		if (configuration.enable_support_ticket_on_web) {
			menuItems.push({
				key: 'submit_ticket',
				label: (
					<div data-id="submit_ticket" className="help-menu-item">
						<BringgIcon className="help-menu-item-icon" iconName={BringgFontIcons.Headset} />
						{t('HELP_MENU.SUBMIT_TICKET')}
					</div>
				),
				onClick: () => {
					executeAction(authStore.crossAppTransport, HelpMenuAction.SUBMIT_TICKET);
				}
			});
		}

		return menuItems;
	}, [t, authStore.crossAppTransport, configuration.enable_support_ticket_on_web]);

	return (
		<DropdownMenu
			// destroyOnHide = true for resize on navigation sidebar collapse
			destroyOnHide
			overlayClassName="help-menu-overlay"
			placement="right"
			noArrow={false}
			trigger={['click']}
			items={items}
		>
			<div className="help-menu-container" data-test-id="help-menu-button">
				<BringgIcon className="help-menu-title-icon" iconName={BringgFontIcons.Help} />
				<span className="help-menu-title">{t('HELP_MENU.TITLE')}</span>
			</div>
		</DropdownMenu>
	);
});

export function HelpMenuPortal() {
	return <Portal element={<HelpMenu />} nodeId="help-menu-portal" />;
}
