import { useTranslationKeys } from '@bringg-frontend/utils';

export const translationKeys = {
	importBasic: 'RATES_CSV.IMPORT_BASIC',
	importSurcharge: 'RATES_CSV.IMPORT_SURCHARGE',
	exportBasic: 'RATES_CSV.EXPORT_BASIC',
	exportSurcharge: 'RATES_CSV.EXPORT_SURCHARGE',
	confirm: 'MODAL.CONFIRM',
	errorConfirm: 'RATES_CSV.UPLOAD_ERROR_COMNFIRM',
	learnMore: 'RATES_CSV.LEARN_MORE',
	readMore: 'RATES_CSV.READ_MORE',
	tooltip: 'RATES_CSV.TOOLTIP',
	aboutTitle: 'RATES_CSV.ABOUT_TITLE',
	aboutDescription: 'RATES_CSV.ABOUT_DESCRIPTION',
	aboutCheckbox: 'RATES_CSV.ABOUT_CHECKBOX',
	uploadTitle: 'RATES_CSV.UPLOAD_TITLE',
	uploadDescription: 'RATES_CSV.UPLOAD_DESCRIPTION',
	uploadErrorTitle: 'RATES_CSV.UPLOAD_ERROR_TITLE',
	uploadErrorDescription: 'RATES_CSV.UPLOAD_ERROR_DESCRIPTION',
	inProgressTitle: 'RATES_CSV.IN_PROGRESS_TITLE',
	inProgressDescription: 'RATES_CSV.UPLOAD_DESCRIPTION',
	exportSuccess: 'RATES_CSV.EXPORT_SUCCESS'
};

export function useRateCardCsvTranslations() {
	return useTranslationKeys<typeof translationKeys>(translationKeys);
}
