import React from 'react';

import { observer } from 'mobx-react';
import { PopOver, Tag, TagType } from '@bringg/react-components';

import useAutomationTranslations from '../../../utils/use-automation-translations';

interface ReadOnlyTagProps {
	isWorkflowSensitive?: boolean;
}

const ReadOnlyTag = ({ isWorkflowSensitive }: ReadOnlyTagProps) => {
	const { readOnly, readOnlyInfo } = useAutomationTranslations();
	const tag = <Tag type={TagType.readOnly}>{readOnly}</Tag>;
	if (isWorkflowSensitive) {
		return (
			<PopOver trigger={['hover']} content={<div style={{ whiteSpace: 'pre-line' }}>{readOnlyInfo}</div>}>
				{tag}
			</PopOver>
		);
	}
	return tag;
};

export default observer(ReadOnlyTag);
