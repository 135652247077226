import { getRootEnv } from '@bringg-frontend/bringg-web-infra';
import { RuleCategory } from '@bringg/types';

import MetadataStore from './metadata-store';

class MetadataRepo {
	private disposeAC = new AbortController();
	metadata: MetadataStore;

	load = async (categoryId: RuleCategory): Promise<void> => {
		const metadata = await getRootEnv().dashboardSdk.sdk.rules.getMetadata({
			category_id: categoryId
		});
		this.metadata = new MetadataStore(metadata, this.disposeAC.signal);
	};

	dispose() {
		this.disposeAC.abort();
		this.disposeAC = new AbortController();
	}
}

export default MetadataRepo;
