import { ExclusionWindow } from '@bringg/types';
import moment from 'moment-timezone';

function isEventBetweenExclusionWindow(exclusionWindow: ExclusionWindow, event, timezone) {
	const isEventStartAfterExclusionWindowStart = moment(event.start)
		.tz(timezone)
		.isSameOrAfter(exclusionWindow.start_time);
	const isEventEndTimeBeforeExclusionWindowEnd = moment(event.end)
		.tz(timezone)
		.isSameOrBefore(exclusionWindow.end_time);

	return isEventStartAfterExclusionWindowStart && isEventEndTimeBeforeExclusionWindowEnd;
}

function isSameServiceAreas(exclusionWindow: ExclusionWindow, event): boolean {
	const isEventHaveMoreServiceAreas = event.service_area_ids.length > exclusionWindow.service_area_ids.length;
	if (isEventHaveMoreServiceAreas) {
		return false;
	}
	const serviceAreaSetOfExclusionWindow = new Set(exclusionWindow.service_area_ids);
	const isHaveSameServiceAreas = event.service_area_ids.every(serviceAreaId =>
		serviceAreaSetOfExclusionWindow.has(serviceAreaId)
	);
	return isHaveSameServiceAreas;
}
const isExcludingEvent = (exclusionWindows: ExclusionWindow[], event, timezone: string) => {
	return exclusionWindows.some(exclusionWindow => {
		const eventBetweenExclusionWindow = isEventBetweenExclusionWindow(exclusionWindow, event, timezone);
		if (!eventBetweenExclusionWindow) {
			return false;
		}

		const isExclusionWindowEmpty = !exclusionWindow.service_plan_id && !exclusionWindow.service_area_ids.length;
		if (isExclusionWindowEmpty) {
			return true;
		}

		const isNoServicePlan = !event.service_plan_ids.length && exclusionWindow.service_plan_id === null;
		const isSameServicePlan =
			isNoServicePlan || exclusionWindow.service_plan_id === (event.service_plan_ids[0] || event.service_plan_id);
		if (!isSameServicePlan) {
			return false;
		}

		const sameServiceAreas = isSameServiceAreas(exclusionWindow, event);
		if (!sameServiceAreas) {
			return false;
		}

		const exclusionWindowTasksCount = exclusionWindow.tasks_count ?? 0;
		const exclusionWindowTasksLimit = exclusionWindow.tasks_limit ?? 0;

		if (!exclusionWindowTasksLimit) {
			return true;
		}

		if (exclusionWindowTasksCount < exclusionWindowTasksLimit) {
			return false;
		}

		return true;
	});
};
export const exclusionWindowChecker = { isExcludingEvent };
