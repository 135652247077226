import React, { useCallback, useState } from 'react';
import Modal from '@bringg/react-components/dist/components/modal/modal';
import { useTranslation } from 'react-i18next';
import Input from '@bringg/react-components/dist/components/floating-placeholder-input/input';
import RangeCalendar from '@bringg/react-components/dist/components/range-calendar/range-calendar';
import { Switch } from '@bringg/react-components/dist/components/switch/switch';
import { Controller, UseFormMethods } from 'react-hook-form';
import { Moment } from 'moment';

export interface TimeoffForm {
	id: number;
	name: string;
	dates: [Moment, Moment];
	repeatYearly: boolean;
}

interface Props {
	visible: boolean;
	onCancel: any;
	onSubmit: any;
	form: UseFormMethods<TimeoffForm>;
}

const TimeOffModal: React.FC<Props> = ({ visible, onCancel, onSubmit, form }: Props) => {
	const { t } = useTranslation();
	const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
	const { formState, control, handleSubmit } = form;

	const handleOnSubmit = useCallback(async () => {
		setIsSubmitting(true);
		await onSubmit();
		setIsSubmitting(false);
	}, [onSubmit]);

	return (
		<Modal
			title={t('TIMEOFF.MODAL_TITLE')}
			width={400}
			visible={visible}
			className="timeoff-modal"
			okText={t('TIMEOFF.SAVE')}
			okButtonProps={{
				form: 'timeoff-form',
				htmlType: 'submit',
				loading: isSubmitting,
				disabled: !formState.isDirty
			}}
			cancelText={t('TIMEOFF.CANCEL')}
			onCancel={onCancel}
			cancelButtonProps={{ danger: true, type: 'primary' }}
		>
			<form id="timeoff-form" className="timeoff-form" onSubmit={handleSubmit(handleOnSubmit)}>
				<div className="required-field">
					<Controller
						as={<Input data-test-id="name-input" placeholder={t('TIMEOFF.NAME')} />}
						control={control}
						rules={{ required: true }}
						name="name"
					/>
				</div>
				<div className="required-field">
					<Controller
						as={
							<RangeCalendar
								className="range-picker"
								separator="-"
								placeholder={[t('TIMEOFF.START_DATE'), t('TIMEOFF.END_DATE')]}
							/>
						}
						control={control}
						rules={{ required: true }}
						name="dates"
					/>
				</div>
				<div className="repeat-yearly-container">
					<span>{t('TIMEOFF.REPEAT_YEARLY')}</span>
					<Controller
						control={control}
						name="repeatYearly"
						render={({ value, onChange }) => (
							<Switch data-test-id="repeat-switch" checked={value} onChange={onChange} />
						)}
					/>
				</div>
			</form>
		</Modal>
	);
};

export default TimeOffModal;
