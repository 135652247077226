import toLower from 'lodash/toLower';

export function camelCaseToSnakeCase(text: string): string {
	return toLower((text || '').replace(/([a-z])([A-Z])/g, '$1_$2'));
}

export function capitalizeFirstLetter(text: string): string {
	return `${text.charAt(0).toUpperCase()}${text.slice(1)}`;
}

export const sortByString = (a, b, key) => {
	const fa = toLower(a[key]);
	const fb = toLower(b[key]);

	if (fa < fb) {
		return -1;
	}
	if (fa > fb) {
		return 1;
	}
	return 0;
};

export const SortByStringWithNumber = (array: any, key: string): any[] => {
	return array.sort((a, b) => a[key].localeCompare(b[key], undefined, { numeric: true, sensitivity: 'base' }));
};

/**
 * Convert number to string but with some constrains:
 * In case the number of digits in the number as an integer is greater than N, then use only the integer part
 * if not only get N digits number (for more examples look at the tests)
 * @example
 */
export const getUpToNFractionDigitsFromNumber = (number: number, n: number): string => {
	const numberOfIntegerDigits = number.toFixed(0).length;

	return number.toFixed(Math.max(n - numberOfIntegerDigits, 0));
};

export const middleWordEllipsis = (value: string, maxLen: number, charactersFromEnd = 5) => {
	if (value.length > maxLen) {
		return (
			value.substr(0, maxLen - charactersFromEnd) +
			'...' +
			value.substr(value.length - charactersFromEnd, value.length)
		);
	}
	return value;
};
