import React, { useState } from 'react';

import { AutoComplete, Option } from '@bringg/react-components';
import { BringgGeocodedAddress } from '@bringg/types';
import _debounce from 'lodash/debounce';
import _reduce from 'lodash/reduce';
import _isFunction from 'lodash/isFunction';
import _isEmpty from 'lodash/isEmpty';
import { AutoCompleteProps } from '@bringg/react-components/dist/components/auto-complete/auto-complete';
import { getRootEnv } from '@bringg-frontend/bringg-web-infra';

import { formConfig } from '../../consts';
import withLoading, { Status, Status as LoadingStatus } from './with-loading/with-loading';

const EnhancedAutoComplete = withLoading(AutoComplete);

export const getAddressDetails = async (
	addressId: string,
	fetchAddressDetails = async addressId => getRootEnv().geocodingService.getPlaceDetails(addressId)
): Promise<BringgGeocodedAddress> => {
	return fetchAddressDetails(addressId);
};

export const getAddressAutoComplete = async (
	address,
	fetchOptions = async address => getRootEnv().geocodingService.fetchAutocomplete(address)
) => {
	try {
		const options = await fetchOptions(address);
		return _reduce(
			options,
			(optionsObject, { address, place_id }) => {
				return {
					...optionsObject,
					[address]: { placeId: place_id, address }
				};
			},
			{}
		);
	} catch (err) {
		console.error(err);
		return [];
	}
};

export const setAutoCompleteOptions = _debounce(async (address, setOptions, fetchOptions = getAddressAutoComplete) => {
	if (!address) {
		return setOptions([]);
	}
	const options = await fetchOptions(address);
	setOptions(options);
}, formConfig.debounceAddress.intervalInMillis);

interface Props {
	onSearch?: (address: string) => void;
	onSelect: (
		address: string,
		getAddressDetailsPromise: Promise<BringgGeocodedAddress>,
		placeId: string
	) => void | Promise<void>;
	name?: string;
	className?: string;
	placeholder?: string;
	defaultValue?: string;
	onAddressStatusChanged?: (status: LoadingStatus) => void;
	otherProps?: Partial<AutoCompleteProps>;
	disabled?: boolean;
	value?: string;
	onChange?: (address: string) => void;
}

const AddressAutoComplete = ({
	name,
	defaultValue,
	className,
	placeholder,
	onSearch,
	onSelect,
	onAddressStatusChanged,
	...otherProps
}: Props) => {
	const [addressOptions, setAddressOptions] = useState([]);
	const [addressStatus, setAddressStatus] = useState<LoadingStatus>(LoadingStatus.Idle);

	const handleAddressStatusChanged = (status: LoadingStatus) => {
		setAddressStatus(status);
		if (_isFunction(onAddressStatusChanged)) {
			onAddressStatusChanged(status);
		}
	};

	const _onSearch = (address: string) => {
		setAutoCompleteOptions(address, setAddressOptions);

		if (_isEmpty(address)) {
			handleAddressStatusChanged(Status.Idle);
		} else {
			handleAddressStatusChanged(Status.Fail);
		}

		if (_isFunction(onSearch)) {
			onSearch(address);
		}
	};

	const _onSelect = async (address: string) => {
		const { placeId } = addressOptions[address];
		try {
			handleAddressStatusChanged(LoadingStatus.Loading);
			await onSelect(address, getAddressDetails(placeId), placeId);
			handleAddressStatusChanged(LoadingStatus.Success);
		} catch (err) {
			handleAddressStatusChanged(LoadingStatus.Fail);
		}
	};

	return (
		<EnhancedAutoComplete
			onSearch={_onSearch}
			onSelect={_onSelect}
			placeholder={placeholder}
			name={name}
			defaultValue={defaultValue}
			className={className}
			status={addressStatus}
			{...otherProps}
		>
			{Object.values(addressOptions).map(({ address }) => {
				return (
					<Option key={address} value={address}>
						{address}
					</Option>
				);
			})}
		</EnhancedAutoComplete>
	);
};

export default AddressAutoComplete;
