import { computed, observable, makeObservable } from 'mobx';
import { ActualBreak, BreakType } from '@bringg/types';
import moment, { Moment } from 'moment';
import _omitBy from 'lodash/omitBy';
import _isNil from 'lodash/isNil';
import { DeliveryBlockBreakFormValue } from '../../modal/form/delivery-block-form';

export enum LocationOption {
	ANYWHERE = 0,
	TEAM_LOCATION = 1
}

export type DeliveryBlockBreakId = string | number; // for new one we use uuid()

class DeliveryBlockBreak {
	id: DeliveryBlockBreakId = null;
	delivery_block_id = null;
	delivery_block_schedule_id = null;
	merchant_id = null;
	team_id = null;
	start_time: string = null;
	end_time: string = null;
	length = 0;
	break_type: BreakType = BreakType.Fixed;
	location_option: LocationOption = LocationOption.ANYWHERE;
	actual_breaks: ActualBreak[] = [];

	constructor(deliveryBlockBreak?: Partial<DeliveryBlockBreakFormValue>, date?: Moment) {
		makeObservable(this, {
			start_time: observable,
			end_time: observable,
			length: observable,
			break_type: observable,
			location_option: observable,
			actual_breaks: observable,
			isFlexBreak: computed,
			durationInMinutes: computed
		});

		if (deliveryBlockBreak) {
			this.length = deliveryBlockBreak.length;
			this.break_type = deliveryBlockBreak.breakType;
			this.location_option = deliveryBlockBreak.locationOption;
			this.start_time = this.convertTime(deliveryBlockBreak.startTime, date);
			this.end_time = this.convertTime(deliveryBlockBreak.endTime, date);
		}
	}

	get isFlexBreak(): boolean {
		return this.break_type === BreakType.Flex;
	}

	get durationInMinutes(): number {
		const { start_time, end_time } = this;

		if (_isNil(start_time) || _isNil(end_time)) {
			return 0;
		}

		return moment.duration(moment(this.end_time).diff(this.start_time)).asMinutes();
	}

	setDeliveryBlockBreak(deliveryBlockBreak: Bringg.DeliveryBlockBreak | Partial<DeliveryBlockBreak>) {
		Object.assign(this, _omitBy(deliveryBlockBreak, _isNil));
	}

	convertTime(time: Moment, date?: Moment): string {
		return date.set({ hour: time.hours(), minutes: time.minutes() }).toISOString();
	}
}

export default DeliveryBlockBreak;
