import { PickupDropoffOption, Task, TaskStatus, TaskTypeId, WayPoint } from '@bringg/types';
import { isNil } from 'lodash';

const isValidLatLng = (lat: number | undefined, lng: number | undefined): boolean =>
	lat && lng && lat > -90 && lat < 90 && lng > -180 && lng < 180;

export const getValidWaypointsLatLng = (way_points: WayPoint[]): WayPoint[] =>
	way_points.map(wp => {
		if (isValidLatLng(wp.lat, wp.lng)) return wp;
		return { ...wp, lat: 0, lng: 0 };
	});

const TASK_CLOSED_STATUSES = new Set([TaskStatus.Rejected, TaskStatus.Done, TaskStatus.Cancelled]);
export const isOpenTask = (task: Task) => {
	if (!task) {
		return false;
	}
	return !isNil(task.invalidated) && task.ready_to_execute && !TASK_CLOSED_STATUSES.has(task.status);
};

export const isPlanningTask = (task: Task) => {
	if (!task) {
		return false;
	}
	// according to ruby api there also should be not_ready_to_execute_and_invisible === false
	// but this field is rarely present in task response structure
	return !isNil(task.invalidated) && task.ready_to_execute === false && !TASK_CLOSED_STATUSES.has(task.status);
};

export const getActionableWaypoint = (task: Task): WayPoint => {
	let activePickupDropOff: PickupDropoffOption;

	const wayPoints = (task.way_points || []).filter(wp => !wp.hidden);

	switch (task.task_type_id) {
		case TaskTypeId.PICKUP:
			activePickupDropOff = PickupDropoffOption.PICKUP;
			break;
		case TaskTypeId.DELIVERY:
			activePickupDropOff = PickupDropoffOption.DROPOFF;
			break;
		case TaskTypeId.PICKUP_AND_DELIVERY:
			activePickupDropOff = PickupDropoffOption.BOTH;
			break;
		default:
			activePickupDropOff = undefined;
	}

	const actionableWayPoint = wayPoints.find(
		wp => typeof activePickupDropOff === 'number' && wp.pickup_dropoff_option === activePickupDropOff
	);

	return actionableWayPoint || wayPoints.slice(-1)[0];
};

export const TASK_TYPE_TRANSLATIONS = {
	[TaskTypeId.PICKUP]: 'TASK_TYPE_NAMES.PICKUP',
	[TaskTypeId.RETURN_TASK]: 'TASK_TYPE_NAMES.RETURN',
	[TaskTypeId.DELIVERY]: 'TASK_TYPE_NAMES.DELIVERY',
	[TaskTypeId.PICKUP_AND_DELIVERY]: 'TASK_TYPE_NAMES.PICKUP_DELIVERY',
	[TaskTypeId.CLICK_AND_COLLECT]: 'TASK_TYPE_NAMES.CONSUMER_COLLECTION',
	[TaskTypeId.SERVICE]: 'TASK_TYPE_NAMES.SERVICE',
	[TaskTypeId.ROAMING]: 'TASK_TYPE_NAMES.CANVASSING',
	UNDEFINED: 'TASK_TYPE_NAMES.UNDEFINED'
};
