import React from 'react';
import { ConfirmModal } from '@bringg/react-components';
import { Translate } from '../../../translation';
import { Button } from '../../../components';

interface Props {
	bulkClose: () => {};
	selectedItemsCount: number;
}

const showConfirm = fn => {
	ConfirmModal({
		title: <Translate text="RUNS.CONFIRM_MODAL_TITLE" />,
		content: <Translate text="RUNS.CONFIRM_MODAL_CONTENT" />,
		okText: <Translate text="RUNS.MODAL_OK" />,
		cancelText: <Translate text="RUNS.MODAL_CANCEL" />,
		onOk() {
			return fn();
		}
	});
};

const RunsHeader: React.FunctionComponent<Props> = (props: Props) => {
	const { bulkClose, selectedItemsCount } = props;

	return (
		<div className="runs-header">
			<Button
				type="ghost"
				size="small"
				onClick={() => {
					showConfirm(bulkClose);
				}}
				disabled={selectedItemsCount === 0}
			>
				<span>
					<Translate text="RUNS.END" />
				</span>
				<span>{selectedItemsCount}</span>
				<span>
					<Translate text="RUNS.RUNS" />
				</span>
			</Button>
		</div>
	);
};

export default RunsHeader;
