import { action, makeObservable, observable } from 'mobx';
import {
	ApplicationUuid,
	Inventory,
	InventoryRejectDetails,
	ReasonToChangeInventory,
	TaskInventory
} from '@bringg/types';
import { getEnv } from 'mobx-easy';

import BaseDomainStore from '../core/base-domain-store';
import { RootEnv } from '../root-env';
import { getSharedRootStore } from '../root-store';

class TaskInventoriesStore extends BaseDomainStore<TaskInventory> {
	isFetchingTaskRejectDetails: Set<number> = new Set();
	inventoriesRejectDetailsByTaskId: { [taskId: number]: { [key: string]: InventoryRejectDetails[] } } = {};
	userIdsWithAvailableInventoriesByTaskId: { [taskId: number]: number[] } = {};

	constructor() {
		super();

		makeObservable(this, {
			inventoriesRejectDetailsByTaskId: observable,
			userIdsWithAvailableInventoriesByTaskId: observable,
			getTaskInventoriesRejectDetails: action
		});
	}

	async getTaskInventoriesRejectDetails(taskId: number): Promise<void> {
		const { dashboardSdk } = getEnv<RootEnv>();

		if (this.isFetchingTaskRejectDetails.has(taskId)) {
			return;
		}

		try {
			const task = getSharedRootStore().data.tasksStore.get(taskId);
			const taskInventories = task.task_inventories ? [...task.task_inventories] : [];
			const rejectedInventoriesIds = this.getRejectedTaskInventoriesIds(taskInventories);

			if (
				rejectedInventoriesIds.some(
					taskInventoryId => !this.inventoriesRejectDetailsByTaskId[taskId]?.[taskInventoryId]
				)
			) {
				this.isFetchingTaskRejectDetails.add(taskId);
				const rejectDetailsResult = await dashboardSdk.sdk.taskInventory.getTaskInventoriesRejectDetails(
					rejectedInventoriesIds
				);

				this.inventoriesRejectDetailsByTaskId[taskId] = rejectDetailsResult;
			}
		} catch (e) {
			console.error(`Failed to get task inventories reject details for task: ${taskId}`, e);
		} finally {
			this.isFetchingTaskRejectDetails.delete(taskId);
		}
	}

	getRejectedTaskInventoriesIds = (inventories: TaskInventory[]): number[] => {
		const rejectedInventoriesIds = [];
		inventories.forEach((inventory: TaskInventory) => {
			if (inventory.rejected_quantity > 0) {
				rejectedInventoriesIds.push(inventory.id);
			}
		});
		return rejectedInventoriesIds;
	};

	getTaskInventoriesByWayPoint = (taskId: number, wayPointId: number): TaskInventory[] => {
		try {
			const taskInventories = this.findTaskInventoriesByWayPoint(taskId, wayPointId);
			taskInventories.forEach((taskInventory: TaskInventory) => {
				const updatedTaskInventory = taskInventory;
				const rejectDetails = this.inventoriesRejectDetailsByTaskId[taskId]?.[taskInventory.id];
				if (rejectDetails?.length) {
					updatedTaskInventory.inventory_reject_details = rejectDetails;
				}
				return updatedTaskInventory;
			});
			return taskInventories;
		} catch (e) {
			console.error('Failed to: get task inventories by way point: ', e);
			return [];
		}
	};

	async getUsersWithAvailableFloatingInventory(taskId: number): Promise<void> {
		const application = getSharedRootStore().data.merchantConfigurationsStore.findApplication(
			ApplicationUuid.FloatingInventoryApp
		);

		if (!application || !getSharedRootStore().data.tasksStore.get(taskId)) {
			return;
		}

		const { dashboardSdk } = getEnv<RootEnv>();
		this.userIdsWithAvailableInventoriesByTaskId[taskId] =
			await dashboardSdk.sdk.floatingInventory.getUsersWithAvailableFloatingInventory(taskId);
	}

	async getAllReasonsToChangeInventory(tagId: number): Promise<ReasonToChangeInventory[]> {
		const { dashboardSdk } = getEnv<RootEnv>();

		return dashboardSdk.sdk.reasonToChangeInventory.getAll(tagId);
	}

	async massTaskInventoriesUpdate(
		taskId: number,
		taskInventories: TaskInventory[],
		reasonToChangeInventoryId: number,
		reasonToChangeInventoryText: string
	): Promise<boolean> {
		const { dashboardSdk } = getEnv<RootEnv>();

		const updatedTaskInventories = await dashboardSdk.sdk.taskInventory.massQuantityUpdate(
			taskInventories,
			reasonToChangeInventoryId,
			reasonToChangeInventoryText
		);

		if (updatedTaskInventories.length !== taskInventories.length) {
			return false;
		}

		return true;
	}

	async inventoryUpdate(taskId: number, inventoryToUpdate: Inventory): Promise<boolean> {
		const { dashboardSdk } = getEnv<RootEnv>();
		const updateResult = await dashboardSdk.sdk.inventory.update(inventoryToUpdate.id, inventoryToUpdate);
		const { inventory, success } = updateResult;

		if (!success) {
			return false;
		}

		const task = getSharedRootStore().data.tasksStore.get(taskId);
		const taskInventories = task.task_inventories ? [...task.task_inventories] : [];

		taskInventories.forEach(taskInventory => {
			if (taskInventory.inventory_id === inventory.id) {
				taskInventory.inventory = inventory;
			}
		});

		task.task_inventories = taskInventories;

		return true;
	}

	private findTaskInventoriesByWayPoint(taskId: number, waypointId: number): TaskInventory[] {
		const task = getSharedRootStore().data.tasksStore.get(taskId);

		const wayPointInventories = (task?.task_inventories || []).filter(
			(taskInventory: TaskInventory) => taskInventory.way_point_id === waypointId
		);

		const taskInventories = (wayPointInventories || []).map((taskInventory: TaskInventory) => {
			taskInventory.inventories = wayPointInventories.filter(
				wayPointInventory => wayPointInventory.parent_task_inventory_id == taskInventory.id
			);
			return taskInventory;
		});

		return taskInventories.filter(inventory => !inventory.parent_task_inventory_id);
	}
}

export default TaskInventoriesStore;
