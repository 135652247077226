import React from 'react';

import { BringgInput, BringgTextArea, FormItem } from '@bringg/react-components';
import { BringgFontIcons, BringgIcon } from '@bringg/bringg-icons';
import { useTranslation } from 'react-i18next';
import type { FormListFieldData } from 'antd/lib/form';

import { useInputRules } from '../../hooks';
import type { PlaceholderData } from 'bringg-web/components/text-template';

import formStyles from './template-details.module.scss';

interface Props {
	languageCode: string;
	field: FormListFieldData;
	templatePlaceholders?: PlaceholderData[];
	setCursorPositionByEvent?: (
		event: React.KeyboardEvent<HTMLTextAreaElement> | React.MouseEvent<HTMLTextAreaElement, MouseEvent>
	) => void;
}

export const LanguageTabContent = ({ languageCode, field, setCursorPositionByEvent, templatePlaceholders }: Props) => {
	const { t } = useTranslation();
	const { defaultInputRules, templateInputRules } = useInputRules(t, templatePlaceholders);

	return (
		<React.Fragment key={`${languageCode}-${field.key}`}>
			<label
				className={`${formStyles.ContentFormInputLabel} ${formStyles.ContentFormInputLabelRequired} text-ellipsis`}
			>
				{t('EMAIL_TEMPLATES.SUBJECT_LABEL')}
			</label>
			<FormItem {...field} key={`${field.key}-subject`} name={[field.name, 'subject']} rules={defaultInputRules}>
				<BringgInput
					data-test-id={`${languageCode}-subject-input`}
					allowClear={{
						clearIcon: <BringgIcon iconName={BringgFontIcons.CloseCircle} />
					}}
					placeholder={t('EMAIL_TEMPLATES.SUBJECT_INPUT_PLACEHOLDER')}
				/>
			</FormItem>
			<label
				className={`${formStyles.ContentFormInputLabel} ${formStyles.ContentFormInputLabelRequired} text-ellipsis`}
			>
				{t('EMAIL_TEMPLATES.EMAIL_CONTENT_LABEL')}
			</label>
			<FormItem {...field} key={`${field.key}-content`} name={[field.name, 'content']} rules={templateInputRules}>
				<BringgTextArea
					rows={12}
					data-test-id={`${languageCode}-content-input`}
					onKeyUp={setCursorPositionByEvent}
					onMouseUp={setCursorPositionByEvent}
					className={formStyles.languageContent}
					// TODO: Add max length
					// maxLength={5120}
				/>
			</FormItem>
		</React.Fragment>
	);
};
