import { useMemo } from 'react';

import { useTranslation } from 'react-i18next';

import { DraggableListSelectorTranslations } from '../types';

export function useDraggableListSelectorTranslations(): DraggableListSelectorTranslations {
	const { t } = useTranslation();

	return useMemo(
		() => ({
			title: t('DRAGGABLE_LIST_SELECTOR.GENERIC.TITLE'),
			defaultButton: t('DRAGGABLE_LIST_SELECTOR.GENERIC.DEFAULT_BUTTON'),
			searchPlaceholder: t('DRAGGABLE_LIST_SELECTOR.GENERIC.SEARCH_PLACEHOLDER'),
			selected: t('DRAGGABLE_LIST_SELECTOR.GENERIC.SELECTED'),
			nonSelected: t('DRAGGABLE_LIST_SELECTOR.GENERIC.UNSELECTED'),
			applyButton: t('DRAGGABLE_LIST_SELECTOR.GENERIC.APPLY'),
			cancelButton: t('DRAGGABLE_LIST_SELECTOR.GENERIC.CANCEL'),
			empty: t('DRAGGABLE_LIST_SELECTOR.GENERIC.EMPTY')
		}),
		[t]
	);
}
