import { EndOfWeekDay } from '@bringg/types';
import { RelativeOptions } from 'bringg-web/features/planned-delivery-windows/modals/slots-modal/wizard-steps/configuration-step/time-slots/cutoff-radio-group/cutoff.types';
import moment from 'moment';
import { PlannedDeliveryWindow } from '@bringg/dashboard-sdk';

export const allWeek = [
	EndOfWeekDay.Sunday,
	EndOfWeekDay.Monday,
	EndOfWeekDay.Tuesday,
	EndOfWeekDay.Wednesday,
	EndOfWeekDay.Friday,
	EndOfWeekDay.Saturday,
	EndOfWeekDay.Thursday
];
export type Duration = {
	hours: number;
	minutes: number;
};
export type PlannedDeliveryWindowOmitted = Omit<PlannedDeliveryWindow, 'merchant_id' | 'fee'>;
export type PlannedDeliveryWindowCreate = Omit<PlannedDeliveryWindow, 'merchant_id' | 'fee' | 'id'>;
export enum PlannedDeliveryWindowsStepWizard {
	CONFIGURATION = 'CONFIGURATION',
	TABLE = 'TABLE'
}
export const initialRelativeCutoff = {
	days: 0,
	hours: 0,
	minutes: 0,
	relativeOption: RelativeOptions.BEFORE
};

export const initialDailyCutoff = (timezone: string) => ({
	time: moment().tz(timezone).startOf('day').toISOString(),
	days: 0
});
