import React, { Component } from 'react';

import { observer } from 'mobx-react';
import i18next from 'i18next';
import { ActionData } from '@bringg/types/types/action_data';
import { StickyContainer, Sticky } from 'react-sticky';
import { withErrorBoundary } from '@bringg-frontend/bringg-web-infra';

import { ActionsData } from 'bringg-web/features/actions-data';
import notification from 'bringg-web/services/notification';
import ActionsConfigurationsStore from 'bringg-web/stores/actions-configuration/actions-configuration-store';
import ActionsConfigurationHeader from '../actions-configuration-header/actions-configuration-header';

interface Props {
	actionsConfigurationsStore: ActionsConfigurationsStore;
	isNew?: boolean;
	onDone: () => void;
}

interface State {
	scrollDown: boolean;
}

class ActionsConfigurationForm extends Component<Props, State> {
	pageBottom: any = React.createRef();

	state = {
		scrollDown: false
	};

	componentDidUpdate() {
		if (this.state.scrollDown) {
			this.scrollIntoView();
		}
	}

	onSave = async () => {
		const { actionsConfigurationsStore, isNew, onDone } = this.props;
		const { isValid } = actionsConfigurationsStore.current.validation;
		let response;

		if (!isValid) {
			notification.error(
				i18next.t('ACTION_CONFIGURATION.UPDATE_FAILED'),
				i18next.t('ACTION_CONFIGURATION.ERROR.FIELDS_NOT_VALID')
			);
		} else {
			if (isNew) {
				response = await actionsConfigurationsStore.create();
			} else {
				response = await actionsConfigurationsStore.current.update();
			}

			if (response.success) {
				notification.success(i18next.t('ACTION_CONFIGURATION.UPDATE_SUCCESS'));
			} else {
				notification.error(i18next.t('ACTION_CONFIGURATION.UPDATE_FAILED'), response.message);
			}

			onDone();
		}
	};

	onAddActionData = () => {
		this.setState({ scrollDown: true });
	};

	setRef = e => {
		this.pageBottom = e;
	};

	scrollIntoView() {
		this.pageBottom.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
		this.setState({ scrollDown: false });
	}

	render() {
		const actionsConfiguration = this.props.actionsConfigurationsStore.current;

		return (
			<StickyContainer className="actions-configuration-container">
				<Sticky>
					{({ style, isSticky }) => (
						<h6 style={{ ...style }} className={isSticky ? 'sticky' : ''}>
							<ActionsConfigurationHeader
								disableSelectLevel={!this.props.isNew}
								actionsConfiguration={actionsConfiguration}
								onAddActionData={this.onAddActionData}
								onSave={this.onSave}
								onCancel={this.props.onDone}
							/>
						</h6>
					)}
				</Sticky>
				<div className="actions-data-list" data-test-id="action-data-list">
					{actionsConfiguration.action_data.map((actionsData: ActionData) => {
						return (
							<ActionsData
								actionsData={actionsData}
								onFieldUpdate={updatedFields =>
									actionsConfiguration.editActionData(actionsData.id, updatedFields)
								}
								level={actionsConfiguration.level}
								onDeleted={() => actionsConfiguration.deleteActionData(actionsData.id)}
								key={actionsData.id}
							/>
						);
					})}
				</div>
				<div ref={this.setRef} />
			</StickyContainer>
		);
	}
}

export default withErrorBoundary(observer(ActionsConfigurationForm));
