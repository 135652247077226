import React from 'react';
import { BringgFontIcons, BringgIcon } from '@bringg/bringg-icons';

export interface Icon {
	title: string;
	color: string;
}

interface Props {
	icons: Icon[];
}

const MapLegendInformation = ({ icons }: Props) => {
	if (!icons.length) {
		return null;
	}

	// eslint-disable-next-line react/no-array-index-key
	const iconsList = icons.map((icon, index) => <IconItem key={index} icon={icon} />);

	return (
		<div className="map-legend-information-container">
			<div className="map-legend-information-list">{iconsList}</div>
		</div>
	);
};

const IconItem = ({ icon }: { icon: Icon }) => {
	return (
		<div className="map-legend-information-item">
			<BringgIcon iconName={BringgFontIcons.Location} style={{ color: icon.color }} />
			<div className="map-legend-information-item-title">{icon.title}</div>
		</div>
	);
};

export default MapLegendInformation;
