import { getRootEnv } from '@bringg-frontend/bringg-web-infra';
import isFunction from 'lodash/isFunction';
import {
	DeliveryWindowsApi,
	ExclusionWindowApiV2,
	OptimizationResultApi,
	PlannedDeliveryWindowsApi,
	ServiceAreaApi
} from '@bringg/dashboard-sdk';
import { BringgDashboardSDK } from '@bringg/dashboard-sdk/dist/BringgDashboardSDK';
import TeamConfigurationApi from '@bringg/dashboard-sdk/dist/TeamConfiguration/TeamConfigurationApi';
import { CollapseLinkedTasksListenerStore } from '@bringg-frontend/shared-tasks-data';

import CompaniesStore from './companies/companies-store';
import CrewsStore from './crew/crews-store';
import UserTypeStore from './user-type/user-type-store';
import DriversStore from './drivers/drivers-store';
import TagsStore from './tags/tags-store';
import TeamsStore from './teams/teams-store';
import NotificationTemplatesStore from './notification-templates/notification-templates-store';
import CustomerConfigurationsStore from './customer-configurations/customer-configurations-store';
import MerchantStore from './merchant/merchant-store';
import MerchantConfigurationsStore from './merchant-configurations/merchant-configurations-store';
import VrpAutoDispatchAnalyticsStore from './vrp-auto-dispatch-analytics/vrp-auto-dispatch-analytics-store';
import ActionsConfigurationsStore from './actions-configuration/actions-configuration-store';
import TagCustomerConfigurationsStore from './tag-customer-configurations/tag-customer-configurations-store';
import MerchantCustomerNotificationsStore from './customer-notifications/merchant-customer-notifications-store';
import TagCustomerNotificationsStore from './customer-notifications/tag-customer-notifications-store';
import TagMerchantConfigurationsStore from './tag-merchant-configurations/tag-merchant-configurations-store';
import UsersStore from './users/users-store';
import ApplicationMerchantConfigurationStore from './application-merchant-configuration/application-merchant-configuration-store';
import ApplicationTeamConfigurationStore from './application-team-configuration/application-team-configuration-store';
import SkillsStore from './skills/skills-store';
import ApplicationStore from './application/application-store';
import StoreAppStore from './application-merchant-configuration/applications/store-app-app/store-app-store';
import FleetsStore from './fleets/fleets-store';
import FleetsConfigurationStore from './fleets-configuration/fleets-configurations-store';
import ExclusionWindowsStore from './exclusion-windows/exclusion-windows-store';
import VehicleTypesStore from './vehicle-types/vehicle-types-store';
import SoftRolloutStore from './soft-rollout-store/soft-rollout-store';
import ConditionsSetStore from '../features/fleets-configuration/conditions-set/conditions-set-store';
import PlannedRoutesStore from './planned-routes/planned-routes-store';
import DeliveryCatalogStore from './delivery-providers/delivery-catalog-store';
import ServicePlansStore from './service-plans-store/service-plans-store';
import PackagesStore from './packages/packages-store';
import TaskRelatedFleetRepo from './task-related-fleet/task-related-fleet-repo';
import OptimizationStore from './optimization/optimization-store';
import CustomAttributesStore from './custom-attributes/custom-attributes-store';
import ServiceAreasStore from './service-areas-store/service-areas-store';
import TasksStoreAdapter from 'bringg-web/stores/tasks/tasks-store-adapter';
import TaskInventoriesStore from 'bringg-web/stores/tasks/task-inventories-store';
import RouteOptimizationConfigurationStore from 'bringg-web/stores/route-optimization-configuration-store/route-optimization-configuration-store';
import { ManualTriggerStore } from 'bringg-web/features/automation-workflows/manual-triggers/manual-trigger-store';
import RunsStoreAdapter from 'bringg-web/stores/runs/runs-store-adapter';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const initializationMap = new Map<string, any>([
	['softRolloutStore', SoftRolloutStore],
	['companiesStore', CompaniesStore],
	['crewsStore', CrewsStore],
	['userTypeStore', UserTypeStore],
	['driversStore', DriversStore],
	['tagsStore', TagsStore],
	['tagCustomerConfigurationsStore', TagCustomerConfigurationsStore],
	['tagMerchantConfigurationsStore', TagMerchantConfigurationsStore],
	['teamsStore', TeamsStore],
	['notificationTemplatesStore', NotificationTemplatesStore],
	['customerConfigurationsStore', CustomerConfigurationsStore],
	['merchantCustomerNotificationsStore', MerchantCustomerNotificationsStore],
	['tagCustomerNotificationsStore', TagCustomerNotificationsStore],
	['merchantStore', MerchantStore],
	['merchantConfigurationsStore', MerchantConfigurationsStore],
	['vrpAutoDispatchAnalyticsStore', VrpAutoDispatchAnalyticsStore],
	['actionsConfigurationsStore', ActionsConfigurationsStore],
	['usersStore', UsersStore],
	['applicationMerchantConfigurationStore', ApplicationMerchantConfigurationStore],
	['applicationTeamConfigurationStore', ApplicationTeamConfigurationStore],
	['applicationStore', ApplicationStore],
	['tasksStore', TasksStoreAdapter],
	['taskInventoriesStore', TaskInventoriesStore],
	['skillsStore', SkillsStore],
	['storeAppStore', StoreAppStore],
	['plannedRoutesStore', PlannedRoutesStore],
	['runStore', RunsStoreAdapter],
	['fleetsStore', FleetsStore],
	['fleetsConfigurationStore', FleetsConfigurationStore],
	['taskRelatedFleetRepo', TaskRelatedFleetRepo],
	['conditionsSetStore', ConditionsSetStore],
	['exclusionWindowsStore', ExclusionWindowsStore],
	['vehicleTypesStore', VehicleTypesStore],
	['servicePlansStore', ServicePlansStore],
	['packagesStore', PackagesStore],
	['deliveryCatalogStore', DeliveryCatalogStore],
	['optimizationStore', OptimizationStore],
	['customAttributesStore', CustomAttributesStore],
	['serviceAreasStore', ServiceAreasStore],
	['routeOptimizationConfigurationStore', RouteOptimizationConfigurationStore],
	['manualTriggerStore', ManualTriggerStore],
	['collapseLinkedTasksListenerStore', CollapseLinkedTasksListenerStore]
]);

class DataStore {
	companiesStore: CompaniesStore;
	crewsStore: CrewsStore;
	userTypeStore: UserTypeStore;
	driversStore: DriversStore;
	tagsStore: TagsStore;
	tagCustomerConfigurationsStore: TagCustomerConfigurationsStore;
	tagMerchantConfigurationsStore: TagMerchantConfigurationsStore;
	teamsStore: TeamsStore;
	notificationTemplatesStore: NotificationTemplatesStore;
	customerConfigurationsStore: CustomerConfigurationsStore;
	merchantCustomerNotificationsStore: MerchantCustomerNotificationsStore;
	tagCustomerNotificationsStore: TagCustomerNotificationsStore;
	merchantStore: MerchantStore;
	collapseLinkedTasksListenerStore: CollapseLinkedTasksListenerStore;
	merchantConfigurationsStore: MerchantConfigurationsStore;
	vrpAutoDispatchAnalyticsStore: VrpAutoDispatchAnalyticsStore;
	actionsConfigurationsStore: ActionsConfigurationsStore;
	usersStore: UsersStore;
	applicationMerchantConfigurationStore: ApplicationMerchantConfigurationStore;
	applicationTeamConfigurationStore: ApplicationTeamConfigurationStore;
	applicationStore: ApplicationStore;
	tasksStore: TasksStoreAdapter;
	taskInventoriesStore: TaskInventoriesStore;
	skillsStore: SkillsStore;
	runStore: RunsStoreAdapter;
	storeAppStore: StoreAppStore;
	plannedRoutesStore: PlannedRoutesStore;
	fleetsStore: FleetsStore;
	fleetsConfigurationStore: FleetsConfigurationStore;
	taskRelatedFleetRepo: TaskRelatedFleetRepo;
	conditionsSetStore: ConditionsSetStore;
	exclusionWindowsStore: ExclusionWindowsStore;
	vehiclesStore: BringgDashboardSDK['v2']['vehicles'];
	vehicleTypesStore: VehicleTypesStore;
	softRolloutStore: SoftRolloutStore;
	servicePlansStore: ServicePlansStore;
	packagesStore: PackagesStore;
	deliveryCatalogStore: DeliveryCatalogStore;
	optimizationStore: OptimizationStore;
	plannedDeliveryWindows: PlannedDeliveryWindowsApi;
	serviceArea: ServiceAreaApi;
	customAttributesStore: CustomAttributesStore;
	serviceAreasStore: ServiceAreasStore;
	routeOptimizationConfigurationStore: RouteOptimizationConfigurationStore;
	deliveryWindows: DeliveryWindowsApi;
	exclusionWindows: ExclusionWindowApiV2;
	teamConfiguration: TeamConfigurationApi;
	taskManagedAttributes: BringgDashboardSDK['v2']['taskManagedAttributes'];
	manualTriggerStore: ManualTriggerStore;
	optimizationResult: OptimizationResultApi;

	constructor() {
		// eslint-disable-next-line no-restricted-syntax
		for (const [key, Clazz] of initializationMap.entries()) {
			this[key] = new Clazz();
		}
		this.plannedDeliveryWindows = getRootEnv().dashboardSdk.sdk.v2.plannedDeliveryWindows;
		this.serviceArea = getRootEnv().dashboardSdk.sdk.v2.serviceAreas;
		this.deliveryWindows = getRootEnv().dashboardSdk.sdk.v2.deliveryWindows;
		this.exclusionWindows = getRootEnv().dashboardSdk.sdk.v2.exclusionWindow;
		this.teamConfiguration = getRootEnv().dashboardSdk.sdk.teamConfiguration;
		this.taskManagedAttributes = getRootEnv().dashboardSdk.sdk.v2.taskManagedAttributes;
		this.vehiclesStore = getRootEnv().dashboardSdk.sdk.v2.vehicles;
		this.optimizationResult = getRootEnv().dashboardSdk.sdk.v2.optimizationResultApi;
	}

	afterRootInit() {
		// eslint-disable-next-line no-restricted-syntax
		for (const key of initializationMap.keys()) {
			if (isFunction(this[key].afterRootInit)) {
				this[key].afterRootInit();
			}
		}

		this.vehiclesStore.updateStoreOnEvents();
	}

	resetAfterRemoveRoot() {
		// eslint-disable-next-line no-restricted-syntax
		for (const key of initializationMap.keys()) {
			if (isFunction(this[key].resetAfterRemoveRoot)) {
				try {
					this[key].resetAfterRemoveRoot();
				} catch (e) {
					console.error(`Failed to reset ${key}`, e);
				}
			}
		}
	}
}

export default DataStore;
