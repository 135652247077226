import React from 'react';
import { EditableText } from '@bringg/react-components';
import { useTranslation } from 'react-i18next';
import styles from './modal-header.module.scss';
import { BringgFontIcons } from '@bringg/bringg-icons';
import TitleWithIcon from 'bringg-web/features/planned-delivery-windows/modals/slots-modal/title-with-icon/title-with-icon';

export interface Props {
	onChangeName: (name: string) => void;
	name: string;
}

export const ModalHeader = ({ onChangeName, name }: Props) => {
	const { t } = useTranslation();
	return (
		<TitleWithIcon
			data-test-id="planned-delivery-windows-modal-header-name"
			title={
				<EditableText
					onValueChange={onChangeName}
					value={name}
					className={styles.name}
					buttonType="link"
					placeholder={t('PLANNED_DELIVERY_WINDOWS_MODAL.NAME')}
				/>
			}
			icon={BringgFontIcons.Calendar}
		/>
	);
};
