import React from 'react';
import { PureButton } from '@bringg/react-components';
import './blocked-rollout-modal-body.scss';

interface Props {
	translations: Translations;
	img?: string;
	onConfirm: () => void;
}

export interface Translations {
	title: string;
	description: string;
	ok: string;
}

const BlockedRolloutModalBody: React.FC<Props> = ({ translations, img, onConfirm }) => {
	return (
		<div className="blocked-rollout-modal-body">
			<div className="blocked-rollout-content">
				<div className="blocked-rollout-title">{translations.title}</div>
				<div className="blocked-rollout-description">{translations.description}</div>
				<img src={img} alt="rollout image" className="blocked-rollout-modal-image" />
			</div>
			<div className="blocked-rollout-buttons-bar">
				<PureButton className="confirm-button" onClick={onConfirm}>
					{translations.ok}
				</PureButton>
			</div>
		</div>
	);
};

export default BlockedRolloutModalBody;
