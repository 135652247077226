import _ from 'lodash';

export function removeUndefinedKeysDeep<T extends object>(obj: T): T {
	if (typeof obj !== 'object') {
		return obj;
	}

	return removeUndefinedKeysDeepMutate(_.cloneDeep(obj));
}

function removeUndefinedKeysDeepMutate<T extends object>(obj: T, seen: WeakSet<any> = new WeakSet()): T {
	if (seen.has(obj)) {
		return obj;
	}

	seen.add(obj);

	Object.entries(obj).forEach(([key, value]) => {
		if (Array.isArray(obj) && value === undefined) {
			// Don't remove undefined values from arrays
			return;
		}

		if (value === undefined) {
			delete obj[key];
		}

		if (typeof value === 'object' && value !== null) {
			obj[key] = removeUndefinedKeysDeepMutate(value, seen);
		}
	});

	return obj;
}
