import { action, computed, makeObservable, observable } from 'mobx';
import {
	ActionCoreData,
	ActionType,
	AlertSeverity,
	DisplayFact,
	FactType,
	GenericActions,
	SharingMethod
} from '@bringg/types';

import CustomerNotificationActionStore from './customer-notification-action-store';
import GenericActionStore from './generic-action-store';
import AlertActionStore from './alert-action-store';
import { TriggerStore, UpdateEntityActionRepo, CustomWebhookActionStore } from './internal';
import { mapActionsFromServer } from '../utils/mapper';
import {
	ActionFamilyType,
	ClientAction,
	ClientCalculateAvailability,
	ClientCancelTaskAction,
	ClientCustomWebhook,
	ClientEmailSharedLocationAction,
	ClientGenericAction,
	ClientNarrowActionableWpTimeWindowAction,
	ClientOptimizationAction,
	ClientShareLocationAction,
	ClientUpdateEntityAction
} from '../utils/types';
import OverrideActions from './override-actions-store';
import OptimizationActionStore from './optimization-action-store';
import CancelTaskActionStore from './cancel-task-action-store';
import CalculateAvailabilityActionStore from '../stores/calculate-availability-action-store';
import NarrowActionableWpTimeWindowActionStore from './narrow-actionable-wp-time-window-action-store';
import EmailCustomerNotificationActionStore from './email-customer-notification-action-store';

export interface ActionFamily {
	type: ActionFamilyType;
	actionType?: ActionType;
	title: string;
	factType?: FactType;
	disabled?: boolean;
	isWebhook?: boolean;
}

const emptyAction: { [key in ActionFamilyType]: ClientAction } = {
	alert: {
		type: ActionFamilyType.NOTIFY,
		actions: [],
		severity: AlertSeverity.Medium
	},
	updateEntity: {
		type: ActionFamilyType.UPDATE_ENTITY,
		actions: []
	},
	genericAction: {
		type: ActionFamilyType.GENERIC_ACTION,
		actions: []
	},
	sharedLocation: {
		type: ActionFamilyType.COSTUMER_NOTIFICATION,
		translation: {},
		sharingMethod: SharingMethod.Phone
	},
	emailSharedLocation: {
		type: ActionFamilyType.EMAIL_COSTUMER_NOTIFICATION,
		template_id: undefined
	},
	optimization: {
		type: ActionFamilyType.OPTIMIZATION,
		action_type: ActionType.OPTIMIZATION,
		data: null
	},
	cancelTask: {
		type: ActionFamilyType.CANCEL_TASK,
		action_type: ActionType.CANCEL_TASK,
		data: null
	},
	calculateAvailabilityState: {
		type: ActionFamilyType.CALCULATE_AVAILABILITY_STATE,
		action_type: ActionType.CALCULATE_AVAILABILITY_STATE,
		data: {
			from_days_ahead: undefined,
			to_days_ahead: undefined
		}
	},
	narrowActionableWpTimeWindow: {
		type: ActionFamilyType.NARROW_ACTIONABLE_WP_TIME_WINDOW,
		action_type: ActionType.NARROW_ACTIONABLE_WP_TIME_WINDOW,
		bufferInMinutes: null
	},
	customWebhook: {
		type: ActionFamilyType.CUSTOM_WEBHOOK,
		action_type: null,
		data: {
			urls: [],
			model_config: null
		}
	}
};

class ActionsRepo {
	workflowPredefinedId: number;
	triggerStore: TriggerStore;
	actions: (
		| AlertActionStore
		| UpdateEntityActionRepo
		| GenericActionStore
		| CustomerNotificationActionStore
		| EmailCustomerNotificationActionStore
		| OptimizationActionStore
		| CancelTaskActionStore
		| CalculateAvailabilityActionStore
		| NarrowActionableWpTimeWindowActionStore
		| CustomWebhookActionStore
	)[] = [];
	factType: FactType;
	displayFact: DisplayFact;
	overrideAction: OverrideActions;

	constructor(
		workflowPredefinedId: number,
		actions: ActionCoreData[],
		factType: FactType,
		displayFact: DisplayFact,
		triggerStore?: TriggerStore
	) {
		makeObservable(this, {
			actions: observable,
			flatActions: computed,
			addAction: action,
			removeAction: action,
			reset: action,
			isValid: computed
		});

		this.factType = factType;
		this.displayFact = displayFact;
		this.workflowPredefinedId = workflowPredefinedId;
		this.overrideAction = new OverrideActions([]);
		this.triggerStore = triggerStore;

		const mappedActions = mapActionsFromServer(actions);

		Object.keys(mappedActions).forEach(familyType => {
			this.addAction(familyType as ActionFamilyType, mappedActions[familyType]);
		});
	}

	addAction = (type: ActionFamilyType, _action?: ClientAction) => {
		const actionToInit = {
			id: this.workflowPredefinedId,
			type,
			...(_action || emptyAction[type])
		};

		const actionByType = this.actions.find(a => a.type === type);

		if (actionByType && type === ActionFamilyType.UPDATE_ENTITY) {
			(actionByType as UpdateEntityActionRepo).addActionToRepo();
			return;
		}

		switch (type) {
			case ActionFamilyType.NOTIFY:
				this.actions.push(new AlertActionStore(actionToInit, this));
				break;

			case ActionFamilyType.UPDATE_ENTITY:
				this.actions.push(
					new UpdateEntityActionRepo((_action || emptyAction[type]) as ClientUpdateEntityAction, this)
				);
				break;
			case ActionFamilyType.GENERIC_ACTION:
				(_action as ClientGenericAction).actions.forEach(genericAction => {
					this.actions.push(new GenericActionStore((genericAction as GenericActions).action_type, this));
				});
				break;
			case ActionFamilyType.COSTUMER_NOTIFICATION:
				this.actions.push(new CustomerNotificationActionStore(actionToInit as ClientShareLocationAction, this));
				break;
			case ActionFamilyType.EMAIL_COSTUMER_NOTIFICATION:
				this.actions.push(
					new EmailCustomerNotificationActionStore(actionToInit as ClientEmailSharedLocationAction, this)
				);
				break;
			case ActionFamilyType.OPTIMIZATION:
				this.actions.push(new OptimizationActionStore(_action as ClientOptimizationAction, this));
				break;
			case ActionFamilyType.CANCEL_TASK:
				this.actions.push(
					new CancelTaskActionStore((_action || emptyAction[type]) as ClientCancelTaskAction, this)
				);
				break;
			case ActionFamilyType.NARROW_ACTIONABLE_WP_TIME_WINDOW:
				this.actions.push(
					new NarrowActionableWpTimeWindowActionStore(
						(_action || emptyAction[type]) as ClientNarrowActionableWpTimeWindowAction,
						this
					)
				);
				break;
			case ActionFamilyType.CALCULATE_AVAILABILITY_STATE:
				this.actions.push(
					new CalculateAvailabilityActionStore(
						(_action || emptyAction[type]) as ClientCalculateAvailability,
						this
					)
				);
				break;
			case ActionFamilyType.CUSTOM_WEBHOOK:
				this.actions.push(
					new CustomWebhookActionStore((_action || emptyAction[type]) as ClientCustomWebhook, this)
				);
				break;
			default:
				console.error('Unknown action type: ', type);
		}
	};

	removeAction = (actionGuid: number) => {
		this.actions = this.actions.filter(_action => _action.guid !== actionGuid);
	};

	get flatActions() {
		return this.actions.reduce(
			(flatArr, actionsFamily) =>
				flatArr.concat(
					(actionsFamily as AlertActionStore | UpdateEntityActionRepo).actions || [
						{
							type: actionsFamily.type,
							action_type: (actionsFamily as GenericActionStore).actionType
						}
					]
				),
			[]
		);
	}

	get isValid() {
		return this.actions.length > 0 && this.actions.every(curAction => curAction.isValid);
	}

	reset = () => {
		this.actions = [];
	};

	get canComplete() {
		return this.actions.every(action => action.canComplete);
	}

	setDisplayFact = (displayFact: DisplayFact) => {
		this.displayFact = displayFact;
	};
}

export default ActionsRepo;
