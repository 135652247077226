import React, { useMemo, useState } from 'react';

import {
	FilterBar2,
	FilterBar2ListItem,
	type FilterBar2Value,
	FilterBar2Values,
	getDefaultFilterBarTranslations
} from '@bringg/react-components';
import { useTranslation } from 'react-i18next';
import moment, { Moment } from 'moment';
import { FILTER_CONDITIONS } from '@bringg/types';
import classNames from 'classnames';
import { mapTeamToFilter } from 'bringg-web';

import { Schedule } from 'bringg-web/features/delivery-blocks-v2/settings/schedules/delivery-blocks-schedules';
import useDateFormat from 'bringg-web/hooks/use-date-format';
import useStores from 'bringg-web/recipes/use-stores';
import { timezoneProvider } from 'bringg-web/services/timezone/timezone-provider';

import styles from './template-filter-bar.module.scss';

interface Props {
	deliveryBlockSchedules: Schedule[];
	onDeliveryBlockSchedulesChange: (schedules: Schedule[]) => void;
	className?: string;
}

const TemplateFilterBar = ({ deliveryBlockSchedules, onDeliveryBlockSchedulesChange, className }: Props) => {
	const dateFormat = useDateFormat();
	const { t } = useTranslation();
	const [initialSchedules] = useState<Schedule[]>(deliveryBlockSchedules);
	const { teamsStore } = useStores();
	const teamOptions = useMemo<FilterBar2Value[]>(() => {
		return mapTeamToFilter(teamsStore.all, teamsStore);
	}, [teamsStore, teamsStore.all]);
	const timezone = timezoneProvider.getTimezoneByMerchant();

	const isNameIncludesQuery = (name: string, query: string) => {
		return name.toLowerCase().includes(query.toLowerCase());
	};

	const areTeamIdsIncludeTeamId = (teamIds: number[], teamId: number) => {
		return teamIds.includes(teamId);
	};

	const isDateInRange = (date: string, range: Moment[]) => {
		if (!date) {
			return false;
		}
		const dateMoment = moment(date).tz(timezone).startOf('day');
		const startDate = range[0].tz(timezone).startOf('day');
		const endDate = range[1].tz(timezone).endOf('day');

		return dateMoment >= startDate && dateMoment <= endDate;
	};

	const isConditionValid = (condition: string, valueA: number, value: string) => {
		const valueB = Number(value);
		switch (condition) {
			case FILTER_CONDITIONS.EQUALS: {
				return valueA === valueB;
			}
			case FILTER_CONDITIONS.NOT_EQUALS: {
				return valueA !== valueB;
			}
			case FILTER_CONDITIONS.IS_EMPTY: {
				return valueA === 0 || valueA == null;
			}
			case FILTER_CONDITIONS.IS_NOT_EMPTY: {
				return !!valueA && valueA > 0;
			}
			case FILTER_CONDITIONS.GREATER_THAN: {
				return valueA > valueB;
			}
			case FILTER_CONDITIONS.GREATER_THAN_OR_EQUALS: {
				return valueA > valueB || valueA === valueB;
			}
			case FILTER_CONDITIONS.LESS_THAN: {
				return valueA == null || valueA < valueB;
			}
			case FILTER_CONDITIONS.LESS_THAN_OR_EQUALS: {
				return valueA < valueB || valueA === valueB;
			}
		}

		return false;
	};

	const onChange = (update: { [key: string]: any }) => {
		const { searchString, teams, dates, capacity } = update;
		const filteredBySearch = searchString
			? initialSchedules.filter(schedule => isNameIncludesQuery(schedule.template_name, searchString))
			: initialSchedules;
		const filteredByTeam =
			teams?.length > 0
				? filteredBySearch.filter(schedule => areTeamIdsIncludeTeamId(teams, schedule.team_id))
				: filteredBySearch;
		const filteredByDate = dates
			? filteredByTeam.filter(
					schedule => isDateInRange(schedule.start_time, dates) || isDateInRange(schedule.end_time, dates)
			  )
			: filteredByTeam;
		const filteredByCapacity = capacity
			? filteredByDate.filter(schedule =>
					isConditionValid(capacity.condition, schedule.original_capacity, capacity.value)
			  )
			: filteredByDate;

		onDeliveryBlockSchedulesChange(filteredByCapacity);
	};

	const onReset = () => {
		Object.assign(deliveryBlockSchedules, initialSchedules);
		onDeliveryBlockSchedulesChange(initialSchedules);
	};

	const initialAdditional = [];
	const initialValues = [];

	const filterList: FilterBar2ListItem[] = [
		{
			type: FilterBar2.TYPES.SEARCH,
			name: 'searchString',
			title: t('DELIVERY_BLOCKS_SCHEDULES.SEARCH'),
			placeholder: t('DELIVERY_BLOCKS_SCHEDULES.SEARCH')
		},
		{
			name: 'teams',
			title: t(`DELIVERY_BLOCKS_SCHEDULES.TITLE_TEAMS`),
			placeholder: t('DELIVERY_BLOCKS_SCHEDULES.SELECT_TEAMS'),
			type: FilterBar2.TYPES.TEAMS,
			applyFilterOnValueChange: true,
			multiselect: true,
			values: teamOptions
		},
		{
			type: FilterBar2.TYPES.DATE_RANGE,
			name: 'dates',
			title: t('DELIVERY_BLOCKS_SCHEDULES.DATE_RANGE'),
			placeholder: t('DELIVERY_BLOCKS_SCHEDULES.DATE_RANGE'),
			applyFilterOnValueChange: true
		},
		{
			type: FilterBar2.TYPES.NUMBER_CONDITION,
			name: 'capacity',
			title: t('DELIVERY_BLOCKS_SCHEDULES.TITLE_CAPACITY'),
			placeholder: t('DELIVERY_BLOCKS_SCHEDULES.TITLE_CAPACITY'),
			applyFilterOnValueChange: true,
			minValue: 0
		}
	];

	return (
		<FilterBar2
			className={classNames(className, styles.templateFilterBar)}
			data-test-id="filter-bar"
			list={filterList}
			initialValues={initialValues as unknown as FilterBar2Values}
			initialAdditional={initialAdditional}
			translations={getDefaultFilterBarTranslations(t)}
			onFilter={onChange}
			onReset={onReset}
			dateFormat={dateFormat}
		>
			{() => <></>}
		</FilterBar2>
	);
};

export default TemplateFilterBar;
