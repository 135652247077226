import React, { useState } from 'react';

import { Button, Tooltip } from '@bringg/react-components';
import { BringgFontIcons, BringgIcon } from '@bringg/bringg-icons';
import Draggable from 'react-draggable';
import classNames from 'classnames';

import styles from './multiple-select-modal.module.scss';

export interface ActionButton {
	icon: BringgFontIcons;
	text: string;
	onClick: () => void;
	className?: string;
	dataTestId?: string;
	tooltip?: JSX.Element | string;
	tooltipOnClick?: JSX.Element | string;
	disabled?: boolean;
}
interface Props {
	selectedCount: number;
	selectedTypeName: string;
	actionButtons: ActionButton[];
	buttonsDisabled?: boolean;
	color?: string;
	onCloseModal: () => void;
	parentSelector?: string;
}

const MultipleSelectModal = ({
	selectedTypeName,
	actionButtons,
	buttonsDisabled,
	color,
	selectedCount,
	onCloseModal,
	parentSelector
}: Props) => {
	const [dragModalDisabled, setDragModalDisabled] = useState(true);

	const onMouseOver = () => {
		if (dragModalDisabled) {
			setDragModalDisabled(false);
		}
	};
	const onMouseOut = () => {
		setDragModalDisabled(true);
	};

	const getButtonContent = (button: ActionButton) => {
		return (
			<span>
				<Button
					onClick={button.onClick}
					type="text"
					className={classNames(styles.button, button.className)}
					disabled={buttonsDisabled || button.disabled}
					data-test-id={button.dataTestId}
				>
					<BringgIcon iconName={button.icon} className={styles.buttonIcon} />
					<span className={styles.buttonSpan}>{button.text}</span>
				</Button>
			</span>
		);
	};

	const getTooltipProps = (button: ActionButton) => {
		if (button.tooltip) {
			return { title: button.tooltip };
		}
		return {
			trigger: ['click'],
			color: 'white',
			className: 'multiselect-modal-action',
			title: button.tooltipOnClick
		};
	};

	return (
		<Draggable disabled={dragModalDisabled} bounds={parentSelector || 'body'}>
			<div className={styles.modal} data-test-id="multi-select-modal">
				<div className={styles.modalBody}>
					<div className={styles.numberOfTasksBlock} style={{ background: color }}>
						<div
							className={styles.cursorMove}
							onMouseOver={onMouseOver}
							onMouseOut={onMouseOut}
							data-test-id="multiple-select-draggable-action"
						>
							<BringgIcon iconName={BringgFontIcons.Drag} />
						</div>
						<span className={styles.numberOfTasks} data-test-id="number-of-selected">
							{selectedCount}
						</span>
					</div>
					<span className={styles.selectedText}>{selectedTypeName}</span>
					<div className={classNames(styles.actions, 'multiselect-modal-actions')}>
						{actionButtons.map(button => {
							return (
								<div key={button.text}>
									{button.tooltip || button.tooltipOnClick ? (
										<Tooltip placement="top" {...getTooltipProps(button)}>
											{getButtonContent(button)}
										</Tooltip>
									) : (
										getButtonContent(button)
									)}
								</div>
							);
						})}
					</div>
					<Button
						data-test-id="multiple-select-close-button"
						className={styles.close}
						onClick={onCloseModal}
						icon={<BringgIcon iconName={BringgFontIcons.Close} />}
						type="ghost"
						size="large"
					/>
				</div>
			</div>
		</Draggable>
	);
};

export default MultipleSelectModal;
