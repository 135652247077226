import { useTranslationKeys } from '@bringg-frontend/utils';

export const translationKeys = {
	pageTitle: 'HISTORY_PAGE.TITLE',
	errorSaveColumns: 'HISTORY_PAGE.FAILED_TO_SAVE_COLUMNS',
	errorFetchHistory: 'HISTORY_PAGE.FAILED_TO_LOAD_TASKS',
	columnPicker: 'HISTORY_PAGE.COLUMN_PICKER_TITLE',
	id: 'DISPATCH_LIST.ID',
	tag: 'DISPATCH_LIST.TAG',
	price: 'DISPATCH_LIST.PRICE',
	title: 'DISPATCH_LIST.ORIGIN_TITLE',
	team: 'DISPATCH_LIST.TEAM',
	driver: 'DISPATCH_LIST.DRIVER',
	fleet: 'DISPATCH_LIST.FLEET',
	skills: 'DISPATCH_LIST.SKILLS',
	ranks: 'DISPATCH_LIST.RANKS',
	routeName: 'DISPATCH_LIST.ROUTE_NAME',
	priority: 'DISPATCH_LIST.PRIORITY',
	timeWindow: 'DISPATCH_LIST.TIME_WINDOW',
	orderStatus: 'DISPATCH_LIST.LEGEND_BY_ORDER_STATUS',
	customerNameFirst: 'HISTORY_PAGE.WAY_POINTS_FIRST_CUSTOMER_NAME',
	customerNameLast: 'HISTORY_PAGE.WAY_POINTS_LAST_CUSTOMER_NAME',
	customerEmailLast: 'DISPATCH_LIST.WAY_POINTS_LAST_CUSTOMER_EMAIL',
	scheduledForFirst: 'DISPATCH_LIST.WAY_POINTS_FIRST_SCHEDULED_AT',
	scheduledForLast: 'DISPATCH_LIST.WAY_POINTS_LAST_SCHEDULED_AT',
	fullAddressFirst: 'DISPATCH_LIST.WAY_POINTS_FIRST_FULL_ADDRESS',
	fullAddressLast: 'DISPATCH_LIST.WAY_POINTS_LAST_FULL_ADDRESS',
	planningPublished: 'DISPATCH_LIST.PLANNING_PUBLISHED',
	planningDone: 'DISPATCH_LIST.DONE_PLANNING',
	servicePlan: 'DISPATCH_LIST.SERVICE_PLAN_NAME',
	addressTypeFirst: 'DISPATCH_LIST.ADDRESS_TYPE_NAME_FIRST',
	addressTypeLast: 'DISPATCH_LIST.ADDRESS_TYPE_NAME_LAST',
	origin: 'DISPATCH_LIST.ORIGIN_TITLE',
	order: 'HISTORY_PAGE.ORDER_TITLE',
	orderCreated: 'DISPATCH_LIST.ORDER_CREATED',
	addressFirst: 'DISPATCH_LIST.WAY_POINTS_FIRST_ADDRESS',
	addressLast: 'DISPATCH_LIST.WAY_POINTS_LAST_ADDRESS',
	cityFirst: 'DISPATCH_LIST.WAY_POINTS_FIRST_CITY',
	cityLast: 'DISPATCH_LIST.WAY_POINTS_LAST_CITY',
	phoneFirst: 'DISPATCH_LIST.WAY_POINTS_FIRST_PHONE',
	phoneLast: 'DISPATCH_LIST.WAY_POINTS_LAST_PHONE',
	zipcodeFirst: 'DISPATCH_LIST.WAY_POINTS_FIRST_ZIPCODE',
	zipcodeLast: 'DISPATCH_LIST.WAY_POINTS_LAST_ZIPCODE',
	orderType: 'DISPATCH_LIST.TASK_TYPE',
	runId: 'DISPATCH_LIST.RUN_ID',
	default: 'GLOBAL.DEFAULT',
	orderOnTime: 'HISTORY_PAGE.ORDER_ON_TIME',
	orderLate: 'HISTORY_PAGE.ORDER_LATE',
	orderLateLegend: 'HISTORY_PAGE.LEGEND_BY_LATE_STATUS',
	startedTime: 'HISTORY_PAGE.LEGEND_BY_STARTED_TIME',
	endedTime: 'HISTORY_PAGE.LEGEND_BY_ENDED_TIME',
	taskTypeId: 'HISTORY_PAGE.LEGEND_BY_TASK_TYPE_ID',
	ORDER_TYPE_2: 'HISTORY_PAGE.ORDER_PICKUP',
	ORDER_TYPE_3: 'HISTORY_PAGE.ORDER_RETURN',
	ORDER_TYPE_4: 'HISTORY_PAGE.ORDER_DELIVERY',
	ORDER_TYPE_5: 'HISTORY_PAGE.ORDER_PICK_UP_AND_DELIVERY',
	ORDER_TYPE_6: 'HISTORY_PAGE.ORDER_CLICK_AND_COLLECT',
	ORDER_TYPE_7: 'HISTORY_PAGE.ORDER_SERVICE',
	ORDER_TYPE_8: 'HISTORY_PAGE.ORDER_ROAMING',
	companyId: 'HISTORY_PAGE.COMPANY_ID',
	routePrefix: 'DISPATCH_LIST.ROUTE'
};

export type TranslationKeys = typeof translationKeys;

export function useHistoryTranslation() {
	return useTranslationKeys<TranslationKeys>(translationKeys);
}
