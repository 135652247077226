import React from 'react';
import { Player } from '../../../components';

interface Props {
	onTimeChange: Function;
	sliderLength: number;
}

const RunPlayer: React.SFC<Props> = (props: Props) => {
	const { onTimeChange, sliderLength } = props;

	return (
		<div className="run-player">
			<Player onTimeChange={onTimeChange} sliderLength={sliderLength} />
		</div>
	);
};

export default RunPlayer;
