import { getRootEnv } from '@bringg-frontend/bringg-web-infra';
import { getRoot } from 'mobx-easy';
import { ApplicationUuid } from '@bringg/types';

import type RootStore from '../../root-store';

export default async (taskId: number): Promise<number[]> => {
	const application = getRoot<RootStore>().data.merchantConfigurationsStore.findApplication(
		ApplicationUuid.FloatingInventoryApp
	);
	if (!application || !taskId) return;
	return await getRootEnv().dashboardSdk.sdk.floatingInventory.getUsersWithAvailableFloatingInventory(taskId);
};
