import {
	FloatingInventoryCustody,
	OWNERSHIP_ORIGIN,
	FloatingInventoryOwnerType,
	FloatingInventoryStatus
} from '@bringg/types';

export const ownershipOriginTranslations = {
	[OWNERSHIP_ORIGIN.DRIVER_API]: 'FLOATING_INVENTORY.OWNERSHIP_ORIGIN.DRIVER_API',
	[OWNERSHIP_ORIGIN.WEB_API]: 'FLOATING_INVENTORY.OWNERSHIP_ORIGIN.WEB_API',
	[OWNERSHIP_ORIGIN.SERVICES_API]: 'FLOATING_INVENTORY.OWNERSHIP_ORIGIN.SERVICES_API',
	[OWNERSHIP_ORIGIN.FLOATING_INVENTORY_APP]: 'FLOATING_INVENTORY.OWNERSHIP_ORIGIN.FLOATING_INVENTORY_APP'
};

export const statusTranslations = {
	[FloatingInventoryStatus.AVAILABLE]: 'FLOATING_INVENTORY.STATUS.AVAILABLE',
	[FloatingInventoryStatus.MISSING]: 'FLOATING_INVENTORY.STATUS.MISSING',
	[FloatingInventoryStatus.USED]: 'FLOATING_INVENTORY.STATUS.USED'
};

export const ownerTypeTranslations = {
	[FloatingInventoryOwnerType.DRIVER]: 'FLOATING_INVENTORY.OWNER_TYPE.DRIVER',
	[FloatingInventoryOwnerType.TEAM]: 'FLOATING_INVENTORY.OWNER_TYPE.TEAM',
	[FloatingInventoryOwnerType.NONE]: 'FLOATING_INVENTORY.OWNER_TYPE.NONE'
};

export const custodyTranslations = {
	[FloatingInventoryCustody.UNKNOWN]: 'FLOATING_INVENTORY.CUSTODY.UNKNOWN',
	[FloatingInventoryCustody.DISPATCHER]: 'FLOATING_INVENTORY.CUSTODY.DISPATCHER',
	[FloatingInventoryCustody.DRIVER]: 'FLOATING_INVENTORY.CUSTODY.DRIVER',
	[FloatingInventoryCustody.CUSTOMER]: 'FLOATING_INVENTORY.CUSTODY.CUSTOMER',
	[FloatingInventoryCustody.MISSING]: 'FLOATING_INVENTORY.CUSTODY.MISSING',
	[FloatingInventoryCustody.VEHICLE]: 'FLOATING_INVENTORY.CUSTODY.VEHICLE'
};
