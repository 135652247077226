import React, { useMemo } from 'react';
import TitleWithIcon from 'bringg-web/features/planned-delivery-windows/modals/slots-modal/title-with-icon/title-with-icon';
import { BringgFontIcons } from '@bringg/bringg-icons';
import styles from './conditions.module.scss';
import { Select } from '@bringg/react-components';
import { ServiceAreaFilterGroups } from '@bringg/dashboard-sdk/dist/ServiceArea/v2/service-area.consts';
import { useStores } from 'bringg-web/recipes';
import { useTranslation } from 'react-i18next';
import { NO_TEAM } from 'bringg-web/features/planned-delivery-windows/planned-delivery-windows-view';
import { filterOptionByTitle } from 'bringg-web/services/utils';

interface Props {
	teamId: number;
	servicePlanIds: number[];
	serviceAreasIds: number[];
	updateServicePlanIds: (servicePlanId: number[]) => void;
	updateServiceAreasIds: (serviceAreasIds: number[]) => void;
}
const Conditions = ({
	teamId,
	servicePlanIds,
	serviceAreasIds,
	updateServiceAreasIds,
	updateServicePlanIds
}: Props) => {
	const { t } = useTranslation();
	const { serviceArea, servicePlansStore } = useStores();

	const serviceAreaOptions = useMemo(
		() => serviceArea.getGroup(ServiceAreaFilterGroups.Teams, teamId).map(({ id, name }) => ({ id, name })),
		[teamId]
	);

	const servicePlanOptions = useMemo(
		() => servicePlansStore.getAll.sort((a, b) => (a.name > b.name ? 1 : -1)).map(({ id, name }) => ({ id, name })),
		[teamId]
	);

	return (
		<div className={styles.conditions}>
			<TitleWithIcon title={t('PLANNED_DELIVERY_WINDOWS_MODAL_CONDITIONS.TITLE')} icon={BringgFontIcons.List} />
			<div className={styles.conditionsDropdown}>
				<span>{t('PLANNED_DELIVERY_WINDOWS_MODAL_CONDITIONS.SERVICE_PLAN_TEXT')}</span>
				<Select
					className={styles.selectItem}
					showSearch
					mode="multiple"
					options={servicePlanOptions}
					value={servicePlanIds}
					data-test-id="conditions-service-plan"
					maxTagCount={2}
					maxTagTextLength={6}
					placeholder={t('PLANNED_DELIVERY_WINDOWS_MODAL_CONDITIONS.SERVICE_PLAN_PLACEHOLDER')}
					onChange={(selectedServicePlanIds: number[]) => updateServicePlanIds(selectedServicePlanIds)}
					filterOption={filterOptionByTitle}
				/>
				<span>{t('PLANNED_DELIVERY_WINDOWS_MODAL_CONDITIONS.SERVICE_AREAS_TEXT')}</span>
				<Select
					className={styles.selectItem}
					showSearch
					data-test-id="conditions-service-areas"
					mode="multiple"
					disabled={teamId === NO_TEAM}
					options={serviceAreaOptions}
					maxTagCount={2}
					maxTagTextLength={6}
					value={serviceAreasIds}
					placeholder={t('PLANNED_DELIVERY_WINDOWS_MODAL_CONDITIONS.SERVICE_AREAS_PLACEHOLDER')}
					onChange={(selectedServiceAreasIds: number[]) => updateServiceAreasIds(selectedServiceAreasIds)}
					filterOption={filterOptionByTitle}
				/>
			</div>
		</div>
	);
};

export default Conditions;
