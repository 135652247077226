import React, { memo } from 'react';
import type { Row } from 'react-table';

import GroupedRow from './grouped-row';
import { shallowDiffers } from '../../../utils';

interface GroupedRowMemoProps<T extends Record<string, unknown> = Record<string, unknown>> {
	row: Row<T>;
	isExpanded?: boolean;
	isSelected?: boolean;
	isSomeSelected?: boolean;
	leafRowsCount?: number;
}

const GroupedRowMemo = <T extends Record<string, unknown> = Record<string, unknown>>({
	isSomeSelected,
	row
}: GroupedRowMemoProps<T>) => {
	return <GroupedRow row={row} isSomeSelected={isSomeSelected} />;
};

const areEqual = (prevProps: GroupedRowMemoProps, nextProps: GroupedRowMemoProps) => {
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const { row: prevRow, ...prevRest } = prevProps;
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const { row: nextRow, ...nextRest } = nextProps;
	return !shallowDiffers(prevRest, nextRest);
};
export default memo(GroupedRowMemo, areEqual) as typeof GroupedRowMemo;
