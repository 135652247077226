import { getRootEnv } from '@bringg-frontend/bringg-web-infra';
import React, { PureComponent } from 'react';

import _find from 'lodash/find';
import { getEnv } from 'mobx-easy';
import { RootEnv } from '@bringg-frontend/bringg-web-infra';

import Widgets from './widgets';
import { WIDGET_TYPES } from './dashboard-consts';
import { preparationStatusWidget } from './widgets/preparation-widget';

interface State {
	fetching: boolean;
	widgets?: Bringg.Widget[];
}

export default class DashboardContainer extends PureComponent<null, State> {
	state = {
		fetching: true,
		widgets: [] as Bringg.Widget[]
	};

	async componentDidMount() {
		const widgets: any = await getRootEnv().dashboardSdk.sdk.dashboard.getWidgetsStructure();
		this.setState({
			fetching: false,
			widgets
		});
	}

	getWidgetByType = (type: number) =>
		_find(this.state.widgets, (widget: Bringg.Widget) => widget.widget_type === type);

	public render() {
		const preparationWidget = this.getWidgetByType(WIDGET_TYPES.PREPARATION_STATUS);
		return (
			<>
				{preparationWidget ? (
					<Widgets.PreparationDelayWidget widget={preparationWidget as preparationStatusWidget} />
				) : null}
			</>
		);
	}
}
