import { useCallback, useEffect, useState } from 'react';

import { observer } from 'mobx-react';
import moment from 'moment';
import { EndOfWeekDay } from '@bringg/types';
import { Button, Notification, Space } from '@bringg/react-components';
import { SpeedFactor } from '@bringg/dashboard-sdk/dist/SpeedFactor/Service/SpeedFactor.service';

import Calendar from '../../components/calendar/calendar';
import EventDialog from './event-dialog';
import { EventFormData, normalizeEvents } from '../data-utils';
import UpdateDialog from './update-dialog';
import { useSpeedFactorTranslation } from '../translations';
import { useStores } from 'bringg-web/recipes';
import { timezoneProvider } from 'bringg-web/services/timezone/timezone-provider';

export const allWeek = [
	EndOfWeekDay.Sunday,
	EndOfWeekDay.Monday,
	EndOfWeekDay.Tuesday,
	EndOfWeekDay.Wednesday,
	EndOfWeekDay.Friday,
	EndOfWeekDay.Saturday,
	EndOfWeekDay.Thursday
];

export const TrafficCalendar = observer(({ serviceAreaId }) => {
	const { serviceAreasStore } = useStores();

	const [calendarEvents, setCalendarEvents] = useState([]);
	const [open, setOpen] = useState(false);
	const [selectedEvent, setSelectedEvent] = useState(null);
	const [modifiedEvent, setModifiedEvent] = useState(null);
	const [originalModifiedEvent, setOriginalModifiedEvent] = useState(null);
	const [originalModifiedEventIndex, setOriginalModifiedEventIndex] = useState(null);

	const timezone = timezoneProvider.getTimezoneByMerchant();

	const translations = useSpeedFactorTranslation();

	const speedFactors: SpeedFactor[] = serviceAreasStore.getSpeedFactors(serviceAreaId);

	useEffect(() => {
		const fetchData = async () => {
			try {
				await serviceAreasStore.fetchAll();
				await serviceAreasStore.fetchSpeedFactors(serviceAreaId);
			} catch (err) {
				// eslint-disable-next-line no-console
				console.log(err);
				Notification.error('Failed to load speed factors');
			}
		};

		fetchData();
	}, [serviceAreasStore, serviceAreaId]);

	useEffect(() => {
		if (speedFactors) {
			setCalendarEvents(normalizeEvents(speedFactors, 2, null, true)); // Generate events for 2 years plus previous year
		}
	}, [speedFactors]);

	const handleOk = () => {
		setOpen(false);
		setSelectedEvent(null);
		setModifiedEvent(null);
		serviceAreasStore.fetchSpeedFactors(serviceAreaId);
	};

	const handleCancel = () => {
		setOpen(false);
		setSelectedEvent(null);
		setModifiedEvent(null);
	};

	const handleModifiedEventSubmit = () => {
		setModifiedEvent(null);
		setSelectedEvent(null);
		setOriginalModifiedEvent(null);
		setOriginalModifiedEventIndex(null);
		serviceAreasStore.fetchSpeedFactors(serviceAreaId);
	};

	const handleSelectEvent = useCallback(
		event => {
			setSelectedEvent(event);
			setOpen(true);
		},
		[selectedEvent]
	);

	const handleModifiedEventCancel = () => {
		setModifiedEvent(null);
		setSelectedEvent(null);

		if (originalModifiedEvent) {
			calendarEvents[originalModifiedEventIndex] = originalModifiedEvent;

			setCalendarEvents(calendarEvents);
			setOriginalModifiedEvent(null);
			setOriginalModifiedEventIndex(null);
		}
	};

	useEffect(() => {
		// Temporary event replacement in calendarEvents to avoid modified event jump while submitting next modal
		if (modifiedEvent && !originalModifiedEvent) {
			const originalModified = calendarEvents.find(item => item === modifiedEvent.event);
			const originalModifiedIndex = calendarEvents.indexOf(modifiedEvent.event);

			setOriginalModifiedEvent(originalModified);
			setOriginalModifiedEventIndex(originalModifiedIndex);

			calendarEvents[originalModifiedIndex] = {
				...originalModified,
				start: modifiedEvent.start,
				end: modifiedEvent.end
			};

			setCalendarEvents(calendarEvents);
		}
	}, [calendarEvents, modifiedEvent, originalModifiedEvent]);

	const handleEventResizeOrDrop = event => {
		setModifiedEvent(event);
	};

	const getModifiedEventPayload = useCallback((eventData): EventFormData => {
		return {
			effectiveDates: [moment(eventData.event.originalStart), moment(eventData.event.originalEnd)],
			intervals: [moment(eventData.start), moment(eventData.end)],
			factor: eventData.event.factor,
			timeFrame: 'specificHours'
		};
	}, []);

	if (!speedFactors) return <div data-test-id="traffic-calendar">Loading...</div>;

	return (
		<div data-test-id="traffic-calendar">
			<Space direction="vertical" style={{ display: 'flex' }}>
				<Space style={{ display: 'flex', justifyContent: 'end' }}>
					<Button type="primary" onClick={() => setOpen(true)}>
						{translations.addButton}
					</Button>
				</Space>
				<Calendar
					events={calendarEvents}
					views={['week']}
					toolbar={true}
					selected={selectedEvent}
					onSelectEvent={handleSelectEvent}
					onEventResize={handleEventResizeOrDrop}
					onEventDrop={handleEventResizeOrDrop}
					timezone={timezone}
				/>
			</Space>

			{open && (
				<EventDialog
					open={open}
					onSubmit={handleOk}
					onCancel={handleCancel}
					event={selectedEvent}
					serviceAreaId={serviceAreaId}
					speedFactors={speedFactors}
					timezone={timezone}
				/>
			)}

			{modifiedEvent && (
				<UpdateDialog
					open
					onOk={handleModifiedEventSubmit}
					onCancel={handleModifiedEventCancel}
					event={modifiedEvent.event}
					values={getModifiedEventPayload(modifiedEvent)}
					speedFactors={speedFactors}
				/>
			)}
		</div>
	);
});

export default TrafficCalendar;
