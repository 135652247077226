import React from 'react';

import { Switch } from '@bringg/react-components';

interface Props {
	isEnabled: boolean;
	isLoading: boolean;
	isDisabled?: boolean;
	onChange: (checked: boolean) => void;
}

const stopPropagation = (_, e) => {
	e.stopPropagation();
};

const ActiveSwitch = ({ isEnabled, isLoading, isDisabled, onChange }: Props) => {
	return (
		<Switch
			disabled={isDisabled}
			onClick={stopPropagation}
			checked={isEnabled}
			loading={isLoading}
			onChange={onChange}
			data-test-id="workflow-active-switch"
		/>
	);
};

export default ActiveSwitch;
