import React, { Component } from 'react';

import { inject, observer } from 'mobx-react';
import { observable, action, makeObservable } from 'mobx';
import { DynamicFormModal } from '@bringg-frontend/dynamic-form-modal';

import { Button, Link } from '../../../components';
import { Translate } from '../../../translation';
import UserTypeStore from '../../../stores/user-type/user-type-store';
import formFields from './user-types-columns';
import UserType from '../../../stores/user-type/domain-objects/user-type';

interface Props {
	userTypeStore?: UserTypeStore;
}

class UserTypesHeader extends Component<Props, any> {
	visible = false;

	constructor(props: Props) {
		super(props);

		makeObservable(this, {
			visible: observable,
			toggleModal: action
		});
	}

	toggleModal = () => {
		this.visible = !this.visible;
	};

	save = async userType => {
		const newUserType: UserType = new UserType(userType, this.props.userTypeStore);
		await newUserType.create();
	};

	cancel = () => {
		this.toggleModal();
	};

	render() {
		const { visible, toggleModal, save, cancel } = this;

		return (
			<div className="user-types-header">
				<Button onClick={toggleModal} className="add-user-type">
					<Translate text="USER_TYPE.ADD" />
				</Button>

				<Button className="back-to-drivers">
					<Link to="/drivers/">
						<Translate text="USER_TYPE.DRIVERS" />
					</Link>
				</Button>

				<DynamicFormModal
					className="create-user-types-modal"
					visible={visible}
					title={<Translate text="USER_TYPE.CREATE_MODAL_TITLE" />}
					okText={(<Translate text="USER_TYPE.SUBMIT_MODAL_BTN" />) as any}
					cancelText={(<Translate text="USER_TYPE.CANCEL" />) as any}
					onClose={cancel}
					onSubmit={save}
					formFields={formFields()}
					numberOfColumns={1}
					layout="horizontal"
					destroyOnClose
				/>
			</div>
		);
	}
}

export default inject('userTypeStore')(observer(UserTypesHeader));
