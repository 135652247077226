import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { BringgFontIcons, BringgIcon } from '@bringg/bringg-icons';
import { BringgInput, Radio, Button, PopOver, Notification, Form, FormItem, useForm } from '@bringg/react-components';
import { observer } from 'mobx-react';
import { ServiceArea, TeamServiceArea } from '@bringg/types';
import { pick } from 'lodash';

import { coordinatesToPolygon } from 'bringg-web/features/service-area/utils/coordinates-to-polygon';
import IconButton from '../../../components/icon-button/icon-button';
import { useStores } from 'bringg-web/recipes';
import { ServiceAreaCoordinatesInput } from 'bringg-web/features/service-area/form/coordinates-input';
import { checkIfPolygon } from 'bringg-web/features/service-area/utils/check-if-polygon';
import { ServiceAreaZipcodesInput } from 'bringg-web/features/service-area/form/zipcodes-input';
import { ServiceAreaTeamsSection } from 'bringg-web/features/service-area/form/teams-section';
import { enclosePolygon } from 'bringg-web/features/service-area/utils/enclose-polygon';
import { useHasFeatureFlag } from 'bringg-web/utils/feature-flags';
import { SpeedFactorSection } from './speed-factor-section';

import './form.scss';

export type Props = {
	onClose: () => void;
	onSubmit: (serviceArea: Partial<ServiceArea>, assignedTeams: Partial<TeamServiceArea>[]) => Promise<void> | void;
	showTeamsSection?: boolean;
};

enum INPUT_TYPE {
	ZIPCODES = 'zipcodes',
	COORDINATES = 'coordinates'
}

type Rule = React.ComponentProps<typeof FormItem>['rules'][number];

const FORMATS_EXAMPLES = {
	WKT: `((46.293993066542,-65.633228238232),
(46.5380398537,-65.670820333973),
(46.677582793109,-65.540101473468))`,
	ARRAY: `[ [ 34.72300240485674, 31.71964660985236 ],
[ 35.055570476380296, 31.867570572717327 ],
[ 34.76613562548141, 31.924757966818206 ] ]`,
	CSV: `lat1, long1
lat2, long2`
};

const initialForm = {
	name: null,
	external_id: null,
	coordinates: null,
	zipcodes: [],
	assignedTeams: []
};

export const ServiceAreaForm = observer(({ onClose, onSubmit, showTeamsSection = false }: Props) => {
	const { t } = useTranslation();
	const [pastedCoordinates, setPastedCoordinates] = useState<string>(null);
	const [inputType, setInputType] = useState<INPUT_TYPE>(INPUT_TYPE.COORDINATES);
	const [isSaving, setIsSaving] = useState(false);
	const { serviceAreaViewStore } = useStores();
	const [form] = useForm();
	const [isCoordinatesChanged, setCoordinatesChanged] = useState(false);

	const speedFactorEnabled = useHasFeatureFlag('enable_speed_factor_for_sa');

	const onClear = useCallback(() => {
		serviceAreaViewStore.focusServiceArea({ ...serviceAreaViewStore.focusedServiceArea, polygon: null });
	}, [serviceAreaViewStore]);

	const close = useCallback(() => {
		onClear();
		serviceAreaViewStore.focusServiceArea(null);
		onClose();
	}, [serviceAreaViewStore, onClear, onClose]);

	const onApply = useCallback(() => {
		let polygon;
		try {
			polygon = coordinatesToPolygon(pastedCoordinates);
			checkIfPolygon(polygon);
		} catch (ex) {
			return Notification.error(t('SERVICE_AREA.CANT_PARSE'));
		}

		polygon = enclosePolygon(polygon);

		serviceAreaViewStore.focusServiceArea({ ...serviceAreaViewStore.focusedServiceArea, polygon });
		serviceAreaViewStore.centerMap();
		setCoordinatesChanged(false);
	}, [serviceAreaViewStore, pastedCoordinates, t]);

	const focusedServiceAreaId = serviceAreaViewStore.focusedServiceArea?.id;

	useEffect(() => {
		form.setFieldsValue({
			assignedTeams: serviceAreaViewStore.focusedServiceArea?.assignedTeams || [],
			name: serviceAreaViewStore.focusedServiceArea?.name || null,
			external_id: serviceAreaViewStore.focusedServiceArea?.external_id || null,
			zipcodes: serviceAreaViewStore.focusedServiceArea?.zipcodes || []
		});

		if (
			serviceAreaViewStore.focusedServiceArea?.zipcodes?.length ||
			serviceAreaViewStore.focusedServiceArea?.polygon?.length
		) {
			setInputType(
				serviceAreaViewStore.focusedServiceArea?.zipcodes?.length ? INPUT_TYPE.ZIPCODES : INPUT_TYPE.COORDINATES
			);
		}
	}, [serviceAreaViewStore.focusedServiceArea?.id, form]); // update form only when new sa is selected

	useEffect(() => {
		const coordinates =
			serviceAreaViewStore.focusedServiceArea?.polygon?.map(coords => coords.join(', ')).join('\n') || null;
		form.setFieldsValue({
			coordinates
		});

		setPastedCoordinates(coordinates);
	}, [serviceAreaViewStore.focusedServiceArea?.polygon, form]); // update coordinates only when polygon changed

	const applyDisabled = useMemo(() => {
		return !pastedCoordinates || !isCoordinatesChanged;
	}, [pastedCoordinates, isCoordinatesChanged]);

	const submitDisabled = useMemo(() => {
		const sa = serviceAreaViewStore.focusedServiceArea;
		if (!sa) {
			return true;
		}

		return !sa.name || !(sa.polygon || sa.zipcodes) || !form.isFieldsTouched() || !applyDisabled || isSaving;
	}, [serviceAreaViewStore.focusedServiceArea, form, applyDisabled, isSaving]);

	const submit = () => {
		const record = pick(serviceAreaViewStore.focusedServiceArea, [
			'id',
			'name',
			'external_id',
			'polygon',
			'zipcodes'
		]);

		record.name = record.name.trim();
		record.external_id = record.external_id?.trim();

		if (record.zipcodes?.length) {
			record.zipcodes = record.zipcodes.map(value => value.trim());
		}

		setIsSaving(true); // this should be called in sync function to prevent form from being submitted twice
		Promise.resolve(onSubmit(record, serviceAreaViewStore.focusedServiceArea.assignedTeams))
			.then(() => {
				setInputType(INPUT_TYPE.COORDINATES);
				form.resetFields();
			})
			.finally(() => setIsSaving(false));
	};

	const onChange = updates => {
		if (updates.coordinates) {
			setCoordinatesChanged(true);
			setPastedCoordinates(updates.coordinates);
		} else {
			serviceAreaViewStore.focusServiceArea({ ...serviceAreaViewStore.focusedServiceArea, ...updates });
		}
	};

	const formInfo = useMemo(() => {
		return (
			<div className="service-area-popover">
				<h3 className="info-header">{t('SERVICE_AREA.INFO_HEADER')}</h3>
				<div className="info-description">{t('SERVICE_AREA.INFO_DESCRIPTION')}</div>
				<div className="info-label">{t('SERVICE_AREA.LAT_LNG')}</div>
				<pre>{FORMATS_EXAMPLES.WKT}</pre>
				<div className="info-label">{t('SERVICE_AREA.GEO_JSON')}</div>
				<pre>{FORMATS_EXAMPLES.ARRAY}</pre>
				<div className="info-label">{t('SERVICE_AREA.CSV')}</div>
				<pre>{FORMATS_EXAMPLES.CSV}</pre>
			</div>
		);
	}, [t]);

	const radioButtonDisabled = useMemo(() => {
		return Boolean(
			serviceAreaViewStore.focusedServiceArea?.polygon?.length ||
				serviceAreaViewStore.focusedServiceArea?.zipcodes?.length
		);
	}, [serviceAreaViewStore.focusedServiceArea]);

	const validateNameValue = async (_: any, value: string) => {
		const existing = serviceAreaViewStore.allByName[value];
		const isNotValid = existing && existing.id != serviceAreaViewStore?.focusedServiceArea?.id;

		return isNotValid ? Promise.reject(t('SERVICE_AREA.DUPLICATED_NAME')) : Promise.resolve();
	};

	const nameRules: Rule[] = useMemo(() => {
		return [
			{ required: true, message: t('SERVICE_AREA.CANT_BE_EMPTY') },
			{ max: 250, message: t('SERVICE_AREA.EXCEED_250_CHARS') },
			{ validator: validateNameValue }
		];
	}, [t]);

	return (
		<Form
			form={form}
			initialValues={initialForm}
			className="service-area-form"
			autoComplete="off"
			onSubmit={submit}
			onValuesChange={onChange}
		>
			<div className="flex">
				<FormItem name="name" rules={nameRules}>
					<BringgInput
						data-test-id="service-area-name"
						size="large"
						bordered={false}
						placeholder={t('SERVICE_AREA.NAME')}
					/>
				</FormItem>

				<IconButton icon={BringgFontIcons.Close} onClick={close} />
			</div>

			<h2 className="header-info">{t('SERVICE_AREA.AREA')}</h2>

			<div className="service-area-external-id">
				<div className="external-id-label">{t('SERVICE_AREA.EXTERNAL_ID')}:</div>
				<FormItem name="external_id" rules={[{ max: 250, message: t('SERVICE_AREA.EXCEED_250_CHARS') }]}>
					<BringgInput placeholder="1234567890123" data-test-id="service-area-external-id" bordered={false} />
				</FormItem>
			</div>

			<div className="header-description">{t('SERVICE_AREA.AREA_DESCRIPTION')}</div>
			<div className="form-label">{t('SERVICE_AREA.SERVICE_AREA_BY')}</div>
			<Radio.Group
				disabled={radioButtonDisabled}
				value={inputType}
				onChange={e => setInputType(e.target.value)}
				className="radio-group flex"
			>
				<Radio value={INPUT_TYPE.COORDINATES}>
					{t('SERVICE_AREA.COORDINATES')}
					<PopOver
						align={{ targetOffset: [20, 5] }}
						placement="bottomLeft"
						content={formInfo}
						trigger="click"
					>
						<BringgIcon className="info-icon" iconName={BringgFontIcons.Info} />
					</PopOver>
				</Radio>
				<Radio value={INPUT_TYPE.ZIPCODES}>{t('SERVICE_AREA.ZIPCODES')}</Radio>
			</Radio.Group>

			{inputType === INPUT_TYPE.COORDINATES && <ServiceAreaCoordinatesInput />}
			{inputType === INPUT_TYPE.ZIPCODES && <ServiceAreaZipcodesInput />}

			{inputType === INPUT_TYPE.COORDINATES && (
				<div className="buttons-container">
					<Button data-test-id="service-area-clear" type="link" onClick={onClear}>
						{t('SERVICE_AREA.CLEAR')}
					</Button>
					<Button disabled={applyDisabled} data-test-id="service-area-apply" onClick={onApply} type="primary">
						{t('SERVICE_AREA.APPLY')}
					</Button>
				</div>
			)}
			{inputType === INPUT_TYPE.COORDINATES && showTeamsSection && speedFactorEnabled && focusedServiceAreaId ? (
				<SpeedFactorSection serviceAreaId={focusedServiceAreaId} />
			) : (
				<></>
			)}

			{showTeamsSection && (
				<FormItem name="assignedTeams">
					<ServiceAreaTeamsSection />
				</FormItem>
			)}

			<div className="buttons-container submit-buttons">
				<Button data-test-id="service-area-discard" type="link" onClick={close}>
					{t('SERVICE_AREA.DISCARD')}
				</Button>
				<Button data-test-id="service-area-submit" disabled={submitDisabled} htmlType="submit" type="primary">
					{t('SERVICE_AREA.SAVE')}
				</Button>
			</div>
		</Form>
	);
});
