import i18n from 'i18next';
import { isArray as _isArray } from 'lodash';
import {
	Attribute,
	Condition,
	ConditionType,
	getRandomGuid
} from '@bringg/react-components/dist/features/conditions/rule/rule-utils';
import { FreeText, Numeric } from '@bringg/react-components/dist/features/conditions/';
import type {
	AllConditions,
	AnyConditions,
	ConditionProperties,
	MasterRateTermCardsPayload,
	NestedCondition
} from '@bringg/types';
import { AttributeDatatype, FactType, MetadataResponse, RuleCompareOperator, TopLevelCondition } from '@bringg/types';

import type { Metadata, Rule, ServerAttributes } from '../rule-types';

export const getMetadataByAttr = (metadata, condition) => {
	if (metadata[condition.path]) {
		return metadata[condition.path];
	}
	if (condition.any) {
		return metadata[((condition as AnyConditions).any[0] as ConditionProperties)?.path];
	}
	return null;
};

export const getListConditionOperator = (condition: Pick<Condition, 'path'>) =>
	condition.path === 'team_ids' ? OPERATORS.ARRAY_INTERSECTION_HAS_ONE : OPERATORS.IN;

export const mapRulesFromServer = (rules, metadata): Rule[] =>
	rules.map(rule => {
		const allConditions = (rule.definition?.conditions as AllConditions)?.all || [];
		const hardConstraint = rule.definition?.hard_constraint ?? false;
		const { id, description, enabled } = rule;
		return {
			conditions: mapConditionsFromServer(allConditions, metadata),
			guid: getRandomGuid(),
			id,
			description,
			enabled,
			hardConstraint
		};
	});

export const OPERATORS = {
	EQUAL: 'EQUALS',
	IN: 'IN',
	IN_RANGE: 'IN_RANGE',
	CHANGED: 'CHANGED',
	ARRAY_INTERSECTION_HAS_ONE: RuleCompareOperator.ARRAY_INTERSECTION_HAS_ONE
};

export const mapConditionToServer = (
	condition: Omit<Condition, 'id' | 'name'>,
	factType: FactType = FactType.Task
): TopLevelCondition | NestedCondition => {
	const base = {
		path: condition.path,
		fact_type: factType
	};

	switch (condition.type) {
		case ConditionType.ARRAY:
			return {
				...base,
				operator: condition.operator,
				value: condition.value
			};
		case ConditionType.BOOLEAN:
			return {
				...base,
				operator: OPERATORS.IN,
				value: condition.value
			};
		case ConditionType.LIST:
			return {
				...base,
				operator: getListConditionOperator(condition),
				value: condition.value
			};
		case ConditionType.NUMBER:
			return {
				any: (condition.value as Numeric[]).reduce((values, value) => {
					values.push({
						...base,
						operator: value.operator,
						value:
							value.operator === OPERATORS.IN_RANGE ? [value.fromValue, value.toValue] : value.fromValue
					});
					return values;
				}, [])
			};
		case ConditionType.STRING:
			return {
				any: (condition.value as FreeText[]).reduce((values, freeText) => {
					values.push({
						...base,
						operator: freeText.operator,
						value: freeText.value
					});
					return values;
				}, [])
			};

		default:
			return {
				any: []
			};
	}
};

export const mapRulesToServer = (rules, base = {}) =>
	rules.map(rule => {
		if (!rule.conditions === undefined) {
			rule.conditions = [];
		}

		const conditions = rule.conditions.map(condition => mapConditionToServer(condition));
		const { id, description, hardConstraint, enabled } = rule;

		return {
			...base,
			id,
			definition: {
				conditions: {
					all: conditions
				},
				hard_constraint: hardConstraint || false
			},
			description,
			enabled
		};
	});

export const getConditionValue = (condition, type: ConditionType) => {
	switch (type) {
		case ConditionType.BOOLEAN:
		case ConditionType.LIST:
		case ConditionType.ARRAY:
			return condition.value;
		case ConditionType.NUMBER:
			return condition.any.map(line => ({
				id: getRandomGuid(),
				operator: line.operator,
				fromValue: _isArray(line.value) ? line.value[0] : line.value,
				toValue: (_isArray(line.value) && line.value[1]) || undefined
			}));
		case ConditionType.STRING:
			return condition.any.map(line => ({
				id: getRandomGuid(),
				operator: line.operator,
				value: line.value
			}));
		default:
			return {};
	}
};

export const mapConditionsFromServer = (conditions: NestedCondition[], metadata: Metadata) => {
	return conditions
		.map(condition => mapSingleCondition(condition, getMetadataByAttr(metadata, condition)))
		.filter(Boolean);
};

export const mapSingleCondition = (condition: NestedCondition, metadataByAttribute: ServerAttributes): Condition => {
	if (!metadataByAttribute) {
		return null;
	}

	let type;
	if (metadataByAttribute.type) {
		({ type } = metadataByAttribute);
	} else {
		console.warn('metadataByAttribute type is missing, ignoring...', { metadataByAttribute });
		return null;
	}

	return {
		name: metadataByAttribute.display_path,
		path: metadataByAttribute.path,
		type: metadataByAttribute.type,
		value: getConditionValue(condition, type),
		options: metadataByAttribute.options,
		id: getRandomGuid(),
		operator: (condition as ConditionProperties).operator as RuleCompareOperator
	};
};

export const getType = (type: AttributeDatatype): ConditionType => {
	switch (type) {
		case AttributeDatatype.Number:
			return ConditionType.NUMBER;
		case AttributeDatatype.Boolean:
			return ConditionType.BOOLEAN;
		case AttributeDatatype.String:
			return ConditionType.STRING;
		case AttributeDatatype.Array:
			return ConditionType.ARRAY;
		case AttributeDatatype.Datetime:
			return ConditionType.DATETIME;
		case AttributeDatatype.StringArray:
			return ConditionType.STRING_ARRAY;
		case AttributeDatatype.NumberArray:
			return ConditionType.NUMBER_ARRAY;
		case AttributeDatatype.BooleanArray:
			return ConditionType.BOOLEAN_ARRAY;
		default:
			return undefined;
	}
};

export const supportedTypes = [
	AttributeDatatype.String,
	AttributeDatatype.Boolean,
	AttributeDatatype.Number,
	AttributeDatatype.Array
];

export const mappedSupportedTypes = new Set([
	ConditionType.LIST,
	ConditionType.BOOLEAN,
	ConditionType.NUMBER,
	ConditionType.STRING,
	ConditionType.ARRAY
]);

export const mapAttributeFromServer = (
	{ data_type, title, values, attributes, path }: ServerAttributes,
	display_path = ''
): Attribute => {
	const translatedTitle = i18n.t(title);
	const newDisplayPath = display_path ? `${display_path}.${translatedTitle}` : translatedTitle;
	const options = (values || []).map(({ value, key }) => ({
		value: i18n.t(value, value),
		key
	}));
	let type;
	if (data_type === AttributeDatatype.Array) {
		type = ConditionType.ARRAY;
	} else if (values) {
		type = ConditionType.LIST;
	} else {
		type = getType(data_type);
	}
	return {
		type,
		title: translatedTitle,
		path,
		options: options.length ? options : undefined,
		attributes: attributes ? attributes.map(attr => mapAttributeFromServer(attr, newDisplayPath)) : undefined,
		display_path: newDisplayPath,
		identifier: title.replace(/[.]/g, '-').toLowerCase()
	};
};

export const filterUnsupportedAttributes = attributes => {
	const getNestedAttributes = (result, attribute) => {
		if (supportedTypes.includes(attribute.data_type)) {
			result.push(attribute);
			return result;
		}
		if (attribute.attributes) {
			const attrs = attribute.attributes.reduce(getNestedAttributes, []);
			if (attrs.length) {
				result.push({ ...attribute, attributes: attrs });
			}
		}
		return result;
	};
	return attributes.reduce(getNestedAttributes, []);
};

export const getAttributes = (
	metadata: MetadataResponse,
	shouldFilterUnsupported = false,
	factType = FactType.Task
): Attribute[] => {
	const { attributes } = metadata.facts.find(fact => fact.type === factType) || { attributes: [] };
	const attributesToMap = shouldFilterUnsupported ? filterUnsupportedAttributes(attributes) : attributes;
	return attributesToMap.map(attr => mapAttributeFromServer(attr));
};

export const getFlatAttribute = attributes => {
	return attributes.reduce((acc, attr) => {
		if (!attr.attributes) {
			acc[attr.path] = attr;
		} else {
			const flatAttr = getFlatAttribute(attr.attributes);
			return { ...acc, ...flatAttr };
		}
		return acc;
	}, {});
};

export const getOptions = (attributes: Attribute[]): MasterRateTermCardsPayload['options'] => {
	return attributes.reduce((acc, attribute) => {
		if (attribute.type === 'BOOLEAN') {
			acc.push({
				path: attribute.path,
				values: [
					{
						key: true,
						value: 'TRUE'
					},
					{
						key: false,
						value: 'FALSE'
					}
				]
			});
		}

		if (attribute.options && attribute.options.length) {
			acc.push({
				path: attribute.path,
				values: attribute.options
			});
		}

		return acc;
	}, []);
};
