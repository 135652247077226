import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { FleetEnvType, FleetStatus } from '@bringg/types';
import { Button, Menu } from '@bringg/react-components';
import { Connect } from '../delivery-catalog-connect';
import { FALLBACK_LOGO } from '../../../consts';

interface DeliveryCatalogConnectMenuProps {
	list: Connect[];
	selected: Connect;
	onSelect: (key: string) => void;
	onOpenDeliveryCatalog: () => void;
}

const DeliveryCatalogConnectMenu = ({
	list = [],
	onSelect,
	selected,
	onOpenDeliveryCatalog
}: DeliveryCatalogConnectMenuProps) => {
	const { t } = useTranslation();

	const getTag = (connection): JSX.Element => {
		let status;

		if (connection.status === FleetStatus.Draft) {
			status = 'drafts';
		} else if (connection.status === FleetStatus.Pending) {
			status = 'pending';
		} else if (connection.status === FleetStatus.Paused) {
			status = 'inactive';
		} else if (connection.status === FleetStatus.Active) {
			status = 'active';
		} else if (connection.status === FleetStatus.Failed) {
			status = 'failed';
		}

		if (status) {
			return (
				<div className={`delivery-catalog-connect-menu-tag delivery-catalog-connect-menu-tag-${status}`}>
					{t(`DELIVERY_PROVIDER_CATALOG_CONNECT.STATUSES.${status.toUpperCase()}`)}
				</div>
			);
		}

		return null;
	};

	const menuItem = useMemo(
		() =>
			list.map(connection => ({
				key: connection.id,
				label: (
					<>
						<div className="delivery-catalog-connect-menu-info">
							<div
								className="delivery-catalog-connect-menu-logo"
								style={{
									backgroundImage: `url(${
										connection?.delivery_provider?.absolute_logo_path || FALLBACK_LOGO
									})`
								}}
							/>

							<span className="delivery-catalog-connect-menu-name" title={connection.name}>
								{connection?.name}
							</span>

							{connection?.env_type === FleetEnvType.Staging && (
								<>
									<span>&nbsp;|&nbsp;</span>
									<span className="delivery-catalog-connect-menu-staging">
										{t('DELIVERY_PROVIDER_CATALOG_CONNECT.STATUSES.STAGING')}
									</span>
								</>
							)}
						</div>
						{getTag(connection)}
					</>
				)
			})),
		[list]
	);

	return (
		<div className="delivery-catalog-connect-menu">
			<Menu
				onSelect={({ key }) => onSelect(key as string)}
				selectedKeys={[String(selected?.id)]}
				items={menuItem}
			/>
			<div className="delivery-catalog-connect-more">
				<p>{t('DELIVERY_PROVIDER_CATALOG_CONNECT.FIND_NEW.TITLE')}</p>
				<Button type="link" onClick={onOpenDeliveryCatalog}>
					{t('DELIVERY_PROVIDER_CATALOG_CONNECT.FIND_NEW.LINK')}
				</Button>
			</div>
		</div>
	);
};

export default DeliveryCatalogConnectMenu;
