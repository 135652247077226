import React from 'react';
import { PopOver } from '@bringg/react-components';
import { DeliveryBlocksEvent } from '../delivery-blocks-calendar-view';
import DeliveryBlockEventContent from './delivery-blocks-event-content';

interface Props {
	event: DeliveryBlocksEvent;
	title: string;
}

export enum EventStyles {
	FULL_BACKGROUND = 'rgba(59, 135, 224, 0.88)',
	AVAILABLE_BACKGROUND = 'rgba(255, 255, 255, 0.85)',
	BORDER = '1px solid #cecece',
	FULL_TEXT_COLOR = 'white',
	AVAILABLE_TEXT_COLOR = '#a7a7a7'
}

export const eventStyleGetter = (
	{ userIds, originalCapacity, deliveryBlocksResources }: DeliveryBlocksEvent,
	isNewModalOfDeliveryBlock: boolean
) => {
	const isDeliveryBlockFull = isNewModalOfDeliveryBlock
		? originalCapacity !== 0 && deliveryBlocksResources.length === originalCapacity
		: userIds.length >= originalCapacity;
	const style = {
		backgroundColor: isDeliveryBlockFull ? EventStyles.FULL_BACKGROUND : EventStyles.AVAILABLE_BACKGROUND,
		border: EventStyles.BORDER,
		color: isDeliveryBlockFull ? EventStyles.FULL_TEXT_COLOR : EventStyles.AVAILABLE_TEXT_COLOR
	};

	return { style };
};

const MOUSE_HOVER_DELAY = 0.5;

const DeliveryBlockEvent = ({ event, title }: Props) => {
	return (
		<PopOver
			overlayClassName="delivery-block-popover"
			mouseEnterDelay={MOUSE_HOVER_DELAY}
			content={<DeliveryBlockEventContent event={event} title={title} />}
		>
			<DeliveryBlockEventContent event={event} title={title} />
		</PopOver>
	);
};

export default DeliveryBlockEvent;
