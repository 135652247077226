import { getRootEnv } from '@bringg-frontend/bringg-web-infra';
import { action, autorun, observable, makeObservable } from 'mobx';
import _isNil from 'lodash/isNil';
import _isEmpty from 'lodash/isEmpty';
import _first from 'lodash/first';
import _sumBy from 'lodash/sumBy';
import _debounce from 'lodash/debounce';
import { getEnv } from 'mobx-easy';
import { RootEnv } from '@bringg-frontend/bringg-web-infra';

const ANALYTICS_POLLING_INTERVAL = 60 * 2 * 1000;
const ANALYTICS_DEBOUNCE_TIME = 1000;

export const vrpAutoDispatchAnalyticsExpanded = 'vrpAutoDispatchAnalyticsExpanded';

export const ASSIGN_ORIGINS = Object.freeze({
	AUTO_DISPATCH: 'AUTODISPATCH',
	OTHER: 'OTHER'
});

export type VrpAutoDispatchAnalytic = {
	assignOrigin: string;
	assigned: number;
	assignedPercentage: number;
	deliveredPercentage: number;
	avgTimeAtDoor: number;
};

class VrpAutoDispatchAnalyticsStore {
	expanded = true;
	analytics: VrpAutoDispatchAnalytic[] = [];
	isFetched = false;
	lastUpdated: Date = null;
	lastVrpRun: Date = null;
	aggregationTime = 3; // hours
	private pollingIntervalId: number = null;
	private updateDisposer: any;

	constructor() {
		makeObservable(this, {
			expanded: observable,
			analytics: observable.shallow,
			isFetched: observable,
			lastUpdated: observable,
			lastVrpRun: observable,
			aggregationTime: observable,
			loadExpandedConfiguration: action,
			setExpanded: action,
			setLastUpdated: action,
			setLastVrpRun: action,
			setAnalytics: action,
			setIsFetched: action,
			setAggregationTime: action,
			startPolling: action
		});

		this.loadExpandedConfiguration();
	}

	loadExpandedConfiguration = () => {
		const expanded = JSON.parse(localStorage.getItem(vrpAutoDispatchAnalyticsExpanded));
		if (!_isNil(expanded)) {
			this.expanded = expanded;
		}
	};

	setExpanded = (expanded: boolean) => {
		localStorage.setItem(vrpAutoDispatchAnalyticsExpanded, expanded.toString());
		this.expanded = JSON.parse(expanded.toString());
	};

	setLastUpdated = (lastUpdated: Date) => {
		this.lastUpdated = lastUpdated;
	};

	setLastVrpRun = (lastVrpRun: Date) => {
		this.lastVrpRun = lastVrpRun;
	};

	setAnalytics = (analytics: VrpAutoDispatchAnalytic[]) => {
		this.analytics = analytics;
		this.setLastUpdated(new Date());
	};

	setIsFetched = (isFetched: boolean) => {
		this.isFetched = isFetched;
	};

	setAggregationTime = aggregationTime => {
		this.aggregationTime = aggregationTime;
	};

	disposeUpdater = () => {
		this.updateDisposer();
	};

	startPolling = () => {
		this.pollAnalytics();

		this.updateDisposer = autorun(async () => {
			if (this.expanded) {
				await this.updateAnalytics();
			}
		});
	};

	stopPolling = () => {
		clearTimeout(this.pollingIntervalId);
	};

	dispose = () => {
		this.stopPolling();
		this.disposeUpdater();
	};

	pollAnalytics = () => {
		this.updateAnalytics().catch(() => {
			console.error('update analytics failed');
		});

		// @ts-ignore
		this.pollingIntervalId = setTimeout(this.pollAnalytics.bind(this), ANALYTICS_POLLING_INTERVAL);
	};

	updateAnalytics = async () => {
		this.setAnalytics(await this.getAnalytics());
	};

	updateLastVrpRun = () => {
		// using the then syntax to fix eslint error
		this.updateAnalytics()
			.then(() => {
				this.setLastVrpRun(new Date());
			})
			.catch(e => {
				console.error('update analytics failed', e);
			});
	};

	subscribeToVrpDoneEvent = () => {
		getRootEnv().dashboardSdk.sdk.vrpAutoDispatchAnalytic.onDoneEvent(
			_debounce(this.updateLastVrpRun, ANALYTICS_DEBOUNCE_TIME)
		);
	};

	fetch = async () => {
		const analytics: VrpAutoDispatchAnalytic[] = await this.getAnalytics();
		this.setAnalytics(analytics);
		this.setIsFetched(true);
	};

	fetchAndStartPolling = async () => {
		await this.fetch();
		this.subscribeToVrpDoneEvent();
		this.startPolling();
	};

	getLastVrpRunMilliseconds = () => {
		return this.lastVrpRun ? new Date().getTime() - this.lastVrpRun.getTime() : 0;
	};

	getAnalytics = async () => {
		try {
			const timeBackMinutes = this.aggregationTime * 60;
			const response = await getRootEnv().dashboardSdk.sdk.vrpAutoDispatchAnalytic.getAll({
				time_back_minutes: timeBackMinutes
			});

			if (response.success && !_isEmpty(response.result)) {
				return this.normalizeRawAnalytics(response.result);
			}

			return [];
		} catch {
			return [];
		}
	};

	normalizeRawAnalytics = (rawAnalytics): VrpAutoDispatchAnalytic[] => {
		const totalTask = _sumBy(rawAnalytics, 'count');

		let parsedAnalytics: VrpAutoDispatchAnalytic[] = rawAnalytics.map(
			({ assign_origin, count, count_delivered_on_time, out_the_door_time }) => {
				return {
					assignOrigin: assign_origin,
					assigned: count,
					assignedPercentage: Math.round((count / totalTask) * 100), // percentage
					deliveredPercentage: Math.round((count_delivered_on_time / count) * 100), // percentage
					avgTimeAtDoor: out_the_door_time > 0 ? out_the_door_time * 1000 : 0 // to millisecond,
				} as VrpAutoDispatchAnalytic;
			}
		);

		// populate the missing metric
		if (parsedAnalytics.length === 1) {
			const { assignOrigin } = _first(parsedAnalytics);
			parsedAnalytics = [...parsedAnalytics, this.getDefaultAnalytic(assignOrigin)];
		}

		// maintain the UI order
		if (_first(parsedAnalytics).assignOrigin === ASSIGN_ORIGINS.OTHER) {
			parsedAnalytics.reverse();
		}

		return parsedAnalytics;
	};

	getDefaultAnalytic = (assignOrigin: string): VrpAutoDispatchAnalytic => {
		return {
			assignOrigin: this.getOppositeOrigin(assignOrigin),
			assigned: 0,
			assignedPercentage: 0,
			deliveredPercentage: 0,
			avgTimeAtDoor: 0
		} as VrpAutoDispatchAnalytic;
	};

	getOppositeOrigin = (assignOrigin: string) => {
		if (assignOrigin === ASSIGN_ORIGINS.AUTO_DISPATCH) {
			return ASSIGN_ORIGINS.OTHER;
		}
		return ASSIGN_ORIGINS.AUTO_DISPATCH;
	};
}

export default VrpAutoDispatchAnalyticsStore;
