import { useEffect, useState } from 'react';

import { getRoot } from 'mobx-easy';
import { isEmpty } from 'lodash';
import moment from 'moment';
import { LayoutType, TaskStatus as LateStatus, TaskStatusTranslations } from '@bringg/react-components';
import { TaskStatus as OrderStatus, Task } from '@bringg/types';
import FormattedTimeWindow from '@bringg/react-components/dist/components/date-display/formatted-time-window';
import { usePhoneNumberFormatter } from '@bringg-frontend/hooks';

import RootStore from 'bringg-web/stores/root-store';
import { TranslationKeys } from '../../translations';
import { DriverLink, OrderLink } from './links';
import TaskStatus from './task-status';
import { timezoneProvider } from 'bringg-web/services/timezone/timezone-provider';
import { getActionableWaypoint } from 'bringg-web/stores/tasks/task-utils';
import { useStores } from 'bringg-web/recipes';

export type Column = {
	id: string;
	title: string;
	dataIndex: string | (string | number)[];
	active: boolean;
	render?: (key: any, record: any) => any;
	required?: boolean;
	ellipsis?: boolean;
};

const LateOrder = (isLate: boolean, t: Partial<TranslationKeys>) => {
	const translations = {
		accepted: t.orderOnTime,
		late: t.orderLate
	} as unknown as TaskStatusTranslations;

	const status = isLate ? OrderStatus.Late : OrderStatus.Accepted;

	return <LateStatus status={status} translations={translations} />;
};

const Company = ({ id }: { id: number | null }) => {
	const [company, setCompany] = useState(null);
	const { companiesStore } = useStores();
	useEffect(() => {
		companiesStore.fetchAll();
	}, [companiesStore]);

	useEffect(() => {
		if (id) {
			const fetched = companiesStore.get(id);

			setCompany(fetched);
		}
	}, [companiesStore, id]);

	if (!company) {
		return null;
	}

	return <span>{company.name}</span>;
};

const RouteNamepartial = ({ routeName, runId, t }) => {
	const { runStore } = useStores();

	if (!isEmpty(routeName)) {
		return <span>{routeName}</span>;
	}

	if (!isEmpty(runId)) {
		const run = runStore.get(runId);

		const id = run.external_id || run.id;

		return (
			<span>
				{t.routePrefix}-{id}
			</span>
		);
	}

	return null;
};

const PhoneNumberCell = ({ text }: { text: string }) => <>{usePhoneNumberFormatter()(text)}</>;

const renderDate = (date: string, format: string, record: Task): string => {
	const timezone: string = getTimezone(record);
	const timezoneFormatted: string = timezone ? ` (${timezone})` : '';
	const momentDate = moment(date).tz(timezone);

	return momentDate.isValid() ? `${momentDate.format(format)}${timezoneFormatted}` : '';
};

const getLastWaypoint = (record: Task) => {
	if (record.way_points.length === 0) return null;

	return record.way_points[1] ?? record.way_points[0];
};

const getTimezone = (record: Task): string =>
	timezoneProvider.getTimezoneByTask(record) || timezoneProvider.getTimezoneByMerchant();

export const getColumns = (t: Partial<TranslationKeys>, dateTimeFormat: string, hourStringFormat: string): Column[] => {
	const { get: getTeamById } = getRoot<RootStore>().data.teamsStore;

	return [
		{
			id: 'id',
			title: t.id,
			dataIndex: 'id',
			render: (_, record) => <OrderLink orderId={record.id}>{record.external_id || record.id}</OrderLink>,
			active: true,
			required: true,
			ellipsis: true
		},
		{ id: 'tag', title: t.tag, dataIndex: ['tag', 'tag'], active: true, ellipsis: true },
		{ id: 'price', title: t.price, dataIndex: 'total_price', active: true, ellipsis: true },
		{
			id: 'customerFirst',
			title: t.customerNameFirst,
			dataIndex: ['way_points', 0, 'customer', 'name'],
			active: false,
			ellipsis: true
		},
		{
			id: 'customerLast',
			title: t.customerNameLast,
			dataIndex: ['way_points', 1, 'customer', 'name'],
			render: (_, record) => {
				return getLastWaypoint(record)?.customer?.name;
			},
			active: false,
			ellipsis: true
		},
		{
			id: 'customerEmailLast',
			title: t.customerEmailLast,
			dataIndex: ['way_points', 1, 'customer', 'email'],
			active: false,
			ellipsis: true
		},
		{ id: 'skills', title: t.skills, dataIndex: 'required_skills', active: false, ellipsis: true },
		{ id: 'rank', title: t.ranks, dataIndex: 'rank', active: false, ellipsis: true },
		{
			id: 'routeName',
			title: t.routeName,
			dataIndex: 'route_name',
			active: false,
			ellipsis: true,
			render: (route_name, record) => <RouteNamepartial routeName={route_name} runId={record.run_id} t={t} />
		},
		{ id: 'fleet', title: t.fleet, dataIndex: ['fleet', 'name'], active: true, ellipsis: true },
		{ id: 'priority', title: t.priority, dataIndex: 'priority', active: false, ellipsis: true },
		{
			id: 'late',
			title: t.orderLateLegend,
			dataIndex: 'late',
			active: false,
			ellipsis: true,
			render: (isLate: boolean) => LateOrder(isLate, t)
		},
		{
			id: 'timeWindow',
			title: t.timeWindow,
			dataIndex: ['way_points'],
			render: (_, record) => {
				const wayPoint = getActionableWaypoint(record);
				const timezone = getTimezone(record);

				return (
					wayPoint?.no_earlier_than &&
					wayPoint?.no_later_than && (
						<span>
							<FormattedTimeWindow
								firstDate={wayPoint.no_earlier_than}
								secondDate={wayPoint.no_later_than}
								hourFormat={hourStringFormat}
								template={LayoutType.INLINE}
								timezone={timezone}
							/>
							{timezone && ` (${timezone})`}
						</span>
					)
				);
			},
			active: true,
			ellipsis: true
		},
		{
			id: 'scheduledForFirst',
			title: t.scheduledForFirst,
			dataIndex: ['way_points', 0, 'scheduled_at'],
			render: (date, record) => renderDate(date, dateTimeFormat, record),
			active: false,
			ellipsis: true
		},
		{
			id: 'scheduledForLast',
			title: t.scheduledForLast,
			render: (date, record) => renderDate(date, dateTimeFormat, record),
			dataIndex: ['way_points', 1, 'scheduled_at'],
			active: true,
			ellipsis: true
		},
		{
			id: 'fullAddressFirst',
			title: t.fullAddressFirst,
			dataIndex: ['way_points', 0, 'full_address'],
			active: false,
			ellipsis: true
		},
		{
			id: 'fullAddressLast',
			title: t.fullAddressLast,
			dataIndex: ['way_points', 1, 'full_address'],
			render: (_, record) => {
				return getLastWaypoint(record)?.full_address;
			},
			active: false,
			ellipsis: true
		},
		{
			id: 'planningPublished',
			title: t.planningPublished,
			dataIndex: 'planning_published',
			active: false,
			ellipsis: true
		},
		{
			id: 'driver',
			title: t.driver,
			dataIndex: ['user', 'name'],
			active: true,
			render: (_, record) => <DriverLink driverId={record.user?.id}>{record.user?.name}</DriverLink>,
			ellipsis: true
		},
		{ id: 'servicePlan', title: t.servicePlan, dataIndex: ['service_plan', 'name'], active: true, ellipsis: true },
		{
			id: 'planningDone',
			title: t.planningDone,
			dataIndex: 'planning_done',
			active: false,
			render: t => t.toString(),
			ellipsis: true
		},
		{
			id: 'addressTypeFirst',
			title: t.addressTypeFirst,
			dataIndex: ['way_points', 0, 'address_type'],
			active: false,
			ellipsis: true
		},
		{
			id: 'addressTypeLast',
			title: t.addressTypeLast,
			dataIndex: ['way_points', 1, 'address_type'],
			active: false,
			ellipsis: true
		},
		{ id: 'origin', title: t.origin, dataIndex: 'origin_title', active: false, ellipsis: true },
		{ id: 'order', title: t.order, dataIndex: 'title', active: false, ellipsis: true },
		{
			id: 'orderCreated',
			title: t.orderCreated,
			dataIndex: 'created_at',
			render: (date, record) => renderDate(date, dateTimeFormat, record),
			active: false,
			ellipsis: true
		},
		{
			id: 'addressFirst',
			title: t.addressFirst,
			dataIndex: ['way_points', 0, 'address'],
			active: false,
			ellipsis: true
		},
		{
			id: 'addressLast',
			title: t.addressLast,
			dataIndex: ['way_points', 1, 'address'],
			active: false,
			ellipsis: true
		},
		{
			id: 'phoneFirst',
			title: t.phoneFirst,
			dataIndex: ['way_points', 0, 'customer', 'phone'],
			render: text => <PhoneNumberCell text={text} />,
			active: false,
			ellipsis: true
		},
		{
			id: 'phoneLast',
			title: t.phoneLast,
			dataIndex: ['way_points', 1, 'customer', 'phone'],
			render: text => <PhoneNumberCell text={text} />,
			active: false,
			ellipsis: true
		},
		{ id: 'cityFirst', title: t.cityFirst, dataIndex: ['way_points', 0, 'city'], active: false, ellipsis: true },
		{ id: 'cityLast', title: t.cityLast, dataIndex: ['way_points', 1, 'city'], active: false, ellipsis: true },
		{
			id: 'zipcodeFirst',
			title: t.zipcodeFirst,
			dataIndex: ['way_points', 0, 'zipcode'],
			active: true,
			ellipsis: true
		},
		{
			id: 'zipcodeLast',
			title: t.zipcodeLast,
			dataIndex: ['way_points', 1, 'zipcode'],
			active: false,
			ellipsis: true
		},
		{
			id: 'taskTypeId',
			title: t.taskTypeId,
			dataIndex: ['task_type_id'],
			render: taskTypeId => t[`ORDER_TYPE_${taskTypeId}`],
			active: false,
			ellipsis: true
		},
		{
			id: 'team',
			title: t.team,
			dataIndex: 'team_ids',
			render: team_ids => team_ids.map((team_id: number) => getTeamById(team_id)?.name).join(', '),
			active: true,
			ellipsis: true
		},
		{
			id: 'startedTime',
			title: t.startedTime,
			dataIndex: ['started_time'],
			render: (date, record) => renderDate(date, dateTimeFormat, record),
			active: false,
			ellipsis: true
		},
		{
			id: 'endedTime',
			title: t.endedTime,
			dataIndex: ['ended_time'],
			render: (date, record) => renderDate(date, dateTimeFormat, record),
			active: false,
			ellipsis: true
		},
		{
			id: 'orderStatus',
			title: t.orderStatus,
			dataIndex: 'status',
			render: (status: number) => <TaskStatus status={status} />,
			active: true,
			ellipsis: true
		},
		{ id: 'runId', title: t.runId, dataIndex: 'run_id', active: true, ellipsis: true },
		{
			id: 'companyId',
			title: t.companyId,
			dataIndex: 'company_id',
			render: (id: number | null) => <Company id={id} />,
			active: false,
			ellipsis: true
		}
	];
};
