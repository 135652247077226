import React, { Component } from 'react';

import moment from 'moment';
import { Timeline as VisTimelineCtor, DataSet } from 'vis-timeline/standalone/umd/vis-timeline-graph2d';
import 'vis-timeline/styles/vis-timeline-graph2d.min.css';
import _difference from 'lodash/difference';
import _intersection from 'lodash/intersection';
import _ from 'lodash';
import type {
	DateType,
	IdType,
	Timeline as VisTimeline,
	TimelineAnimationOptions,
	TimelineGroup,
	TimelineItem,
	TimelineOptions
} from 'vis-timeline/standalone/umd';

import { GANTT_ITEM_ROW_CLASS } from './components/timeline-items/consts';
import type { CustomTime, SelectionOptions, TimelineEventHandler, TimelineEventsHandlers } from './gantt-types';
import { visTimelineEvents } from './gantt-types';

const HANDLER_SUFFIX = 'Handler';

type Props = {
	items?: TimelineItem[];
	groups?: TimelineGroup[];
	options?: TimelineOptions;
	selection?: IdType[];
	customTimes?: CustomTime[];
	selectionOptions?: SelectionOptions;
	animate?: boolean | Record<string, unknown>;
	currentTime?: DateType;
	onItemsChange?: () => void;
	loadingGroups?: number[] | null;
	timeZone?: string;

	// Hidden is to signal when the timeline is hidden so we can avoid updating it and save performance
	// If you don't need the timeline anymore just unmount it
	hidden?: boolean;
	isMultiselectEnabled?: boolean;
	customEvent?: (event, timeline) => void;
} & TimelineEventsHandlers;

interface state {}

export class Timeline extends Component<Props, state> {
	static defaultProps: Props = {
		items: [],
		groups: [],
		options: {},
		selection: [],
		customTimes: [],
		loadingGroups: [],
		hidden: false
	};

	// @ts-ignore
	public timeline: Readonly<VisTimeline>;

	readonly _items: DataSet<TimelineItem>;
	readonly _groups: DataSet<TimelineGroup>;
	readonly _ref = React.createRef<HTMLDivElement>();
	isFirstRender = true;

	_prevProps?: Props;

	lazyUpdateComponent: ((nextProps: Props) => any) & { cancel: () => void };

	constructor(props: Props) {
		super(props);
		this._items = new DataSet<TimelineItem>();
		this._groups = new DataSet<TimelineGroup>();

		// Debounce as update here can be costly and sometimes they get in bursts
		this.lazyUpdateComponent = _.debounce(this.updateComponent.bind(this), 10, {
			// We want the last time to be called as it's with the latest props
			trailing: true
		});
	}

	componentWillUnmount() {
		this.timeline?.destroy();
		this.isFirstRender = false;

		this.lazyUpdateComponent.cancel();
	}

	componentDidMount() {
		if (this._ref.current) {
			Object.defineProperty(this, 'timeline', {
				value: new VisTimelineCtor(this._ref.current, this._items, this._groups, this.props.options),
				writable: false
			});
		}

		if (this.props.isMultiselectEnabled) {
			//I wanted to register for the event handler of the 'vis-timeline' library before their events because the library is not well-maintained,
			// and their multi-select feature crashes in certain cases, which I'll mention later.
			// Since they don't provide an API that allows this, we had to do it this way.
			// The reasons we had to register to the library are:
			// 	1) There is a lack of synchronization in the library when saving selected objects; some items are stored in arrays, and others are not.
			// 	2) The only way we managed to differentiate the objects we wanted to enable 'multiple selection' for was by their 'id' being of type number and having a custom field called 'customData.type === 'task'."
			//  3) Using Shift/CMD/CTRL + Click crashing when try to choose Task+Cluster | Task+Break
			const eventFunc = event => this.props.customEvent?.(event, this.timeline);
			// @ts-ignore
			this.timeline.dom.centerContainer.hammer[0]._handlers.tap.unshift(eventFunc);
			// @ts-ignore
			this.timeline.dom.centerContainer.hammer[0]._handlers.press.unshift(eventFunc);
			// @ts-ignore
			this.timeline.dom.centerContainer.hammer[0]._handlers.panstart.unshift(eventFunc);
			// @ts-ignore
			this.timeline.dom.centerContainer.hammer[0]._handlers['hammer.input'].unshift(eventFunc);
		}

		for (const event of visTimelineEvents) {
			const eventHandler = this.props[`${event}${HANDLER_SUFFIX}` as TimelineEventHandler];
			if (eventHandler) {
				this.timeline.on(event, eventHandler);
			}
		}

		this.init();
	}

	shouldComponentUpdate(nextProps: Props) {
		if (!this._prevProps) {
			this._prevProps = Object.assign({}, this.props);
		}

		if (this.isFirstRender) {
			this.isFirstRender = false;

			this.updateComponent(nextProps);
			return true;
		}

		if (!nextProps.hidden) {
			this.lazyUpdateComponent(nextProps);
		}
		return false;
	}

	updateComponent(nextProps: Props) {
		if (!this._prevProps) return;

		const prevProps = this._prevProps;
		this._prevProps = undefined;

		const { items, groups, options, selection, customTimes, currentTime, loadingGroups } = prevProps;

		const {
			items: nextItems,
			groups: nextGroups,
			options: nextOptions,
			selection: nextSelection,
			customTimes: nextCustomTimes,
			currentTime: nextCurrentTime,
			loadingGroups: nextLoadingGroups,
			...nextHandlers
		} = nextProps;

		const itemsChange = items !== nextItems;
		const groupsChange = groups !== nextGroups;
		const optionsChange = options !== nextOptions;
		const customTimesChange = customTimes !== nextCustomTimes;
		const selectionChange = selection !== nextSelection;
		const currentTimeChange = currentTime !== nextCurrentTime;
		const isLoadingChange = loadingGroups !== nextLoadingGroups;

		if (groupsChange) {
			this.syncDataSet(this._groups, nextProps.groups);
		}

		if (itemsChange) {
			this.syncDataSet(this._items, nextProps.items);

			if (prevProps.onItemsChange) {
				prevProps.onItemsChange();
			}
		}

		if (optionsChange) {
			if (prevProps.timeZone) {
				(nextProps.options as TimelineOptions).moment = date => moment(date).tz(prevProps.timeZone as string);
			}

			this.timeline.setOptions(nextProps.options as TimelineOptions);
		}

		if (customTimesChange) {
			this.updateCustomTimes(customTimes, nextProps.customTimes);
		}

		if (selectionChange) {
			this.updateSelection(nextProps.selection as IdType[], nextProps.selectionOptions as SelectionOptions);
		}

		if (currentTimeChange) {
			this.timeline.setCurrentTime(nextProps.currentTime as DateType);
		}

		Object.entries(nextHandlers).forEach(([handlerName, action]) => {
			if (prevProps[handlerName] !== action) {
				const eventName = handlerName.substring(0, handlerName.indexOf(HANDLER_SUFFIX));
				this.timeline.off(eventName, prevProps[handlerName]);
				// after upgrading typescript- received an error that action is not in the right type
				//  TODO: filter the nextHandlers props to valid actions
				// @ts-ignore
				this.timeline.on(eventName, action);
			}
		});

		if (nextLoadingGroups) {
			if (isLoadingChange) {
				this.toggleGrayOutLoadingRow(nextLoadingGroups);
			}
			this.removeGrayedOutOnRowsIfNeeded(nextLoadingGroups);
		}
	}

	syncDataSet(dataSet: DataSet<TimelineGroup | TimelineItem>, newData?: (TimelineGroup | TimelineItem)[]): void {
		const currentIds = dataSet.get().map(item => item.id);
		const removedIds = _difference(
			currentIds,
			(newData || []).map(item => item.id)
		);
		dataSet.remove(removedIds);
		dataSet.update(newData); // add or update
	}

	updateCustomTimes(prevCustomTimes: CustomTime[] = [], customTimes: CustomTime[] = []) {
		// diff the custom times to decipher new, removing, updating
		const customTimeKeysPrev = Object.keys(prevCustomTimes);
		const customTimeKeysNew = Object.keys(customTimes);
		const customTimeKeysToAdd = _difference(customTimeKeysNew, customTimeKeysPrev);
		const customTimeKeysToRemove = _difference(customTimeKeysPrev, customTimeKeysNew);
		const customTimeKeysToUpdate = _intersection(customTimeKeysPrev, customTimeKeysNew);

		customTimeKeysToRemove.forEach(id => {
			this.timeline.removeCustomTime(id);
		});
		customTimeKeysToAdd.forEach(id => {
			const datetime = customTimes.find(ct => ct.id === id)?.datetime;
			datetime && this.timeline.addCustomTime(datetime, id);
		});
		customTimeKeysToUpdate.forEach(id => {
			const datetime = customTimes.find(ct => ct.id === id)?.datetime;
			datetime && this.timeline.setCustomTime(datetime, id);
		});
	}

	updateSelection(selection: IdType | IdType[] = [], selectionOptions: SelectionOptions): void {
		this.timeline.setSelection(selection, selectionOptions as Required<SelectionOptions>);
	}

	grayOutRowIfNeeded(rowId: number): void {
		const grayOutElement = document.getElementsByClassName(`gray-out-${rowId}`);
		if (!grayOutElement.length) {
			const element = document.querySelector(
				`.vis-foreground .vis-group.${GANTT_ITEM_ROW_CLASS}.${GANTT_ITEM_ROW_CLASS}-${rowId}`
			);
			const div = document.createElement('div');
			div.classList.add(`gray-out`, `gray-out-${rowId}`);
			element && element.appendChild(div);
		}
	}

	removeGrayedOutOnRowsIfNeeded(runIdsInProgressSet: number[]): void {
		const grayOutElements = document.getElementsByClassName(`gray-out`);
		Array.from(grayOutElements).forEach(element => {
			const rowId = Number(element.className.split('-').slice(-1)[0]);
			if (!runIdsInProgressSet.includes(rowId)) {
				element.remove();
			}
		});
	}

	toggleGrayOutLoadingRow(runIdsInProgressSet: number[]) {
		runIdsInProgressSet.forEach((rowId: number) => {
			this.grayOutRowIfNeeded(rowId);
		});
	}

	init() {
		const {
			items,
			groups,
			options,
			selection,
			selectionOptions = {},
			customTimes,
			animate = true,
			currentTime
		} = this.props;

		const timelineOptions = options || {};

		if (animate) {
			// If animate option is set, we should animate the timeline to any new
			// start/end values instead of jumping straight to them
			delete timelineOptions['start'];
			delete timelineOptions['end'];

			if (timelineOptions.start && timelineOptions.end) {
				this.timeline.setWindow(timelineOptions.start, timelineOptions.end, {
					animation: animate
				} as TimelineAnimationOptions);
			}
		}

		this.timeline.setOptions(timelineOptions);

		if (groups && groups?.length > 0) {
			this._groups.add(groups);
		}

		if (items && items?.length > 0) {
			this._items.add(items);
		}

		this.updateSelection(selection, selectionOptions);

		if (currentTime) {
			this.timeline.setCurrentTime(currentTime);
		}

		this.updateCustomTimes([], customTimes);
	}

	render() {
		return <div className="timeline" ref={this._ref} />;
	}
}
