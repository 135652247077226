import React, { useCallback, useState } from 'react';

import classNames from 'classnames';
import { BringgFontIcons, BringgIcon } from '@bringg/bringg-icons';

interface Props {
	removeGroup(): void;
}

export const RemoveGroupButton = React.memo(({ removeGroup }: Props) => {
	const [isDisabled, setDisabled] = useState(false);

	const safeRemove = useCallback(() => {
		setDisabled(true);
		removeGroup();
	}, [removeGroup]);

	return (
		<div
			onClick={safeRemove}
			data-test-id="timeline-remove-row-button"
			className={classNames('group-remove-button-component', 'remove-button', { disabled: isDisabled })}
		>
			<BringgIcon iconName={BringgFontIcons.Close} className="close-icon" />
		</div>
	);
});
