import React, { useMemo, useState } from 'react';

import { AdjustPopoverOverflow, Button, DropdownMenu, Modal, Notification } from '@bringg/react-components';
import { toJS } from 'mobx';
import { BringgFontIcons, BringgIcon } from '@bringg/bringg-icons';
import { useTranslation } from 'react-i18next';
import { ServiceAreaFilterGroups } from '@bringg/dashboard-sdk/dist/ServiceArea/v2/service-area.consts';
import { PlannedDeliveryWindow } from '@bringg/dashboard-sdk';
import { PlannedDeliveryWindowsBulkEditRequest } from '@bringg/dashboard-sdk/dist/PlannedDeliveryWindows/planned-delivery-windows.consts';
import { dateUtils } from '@bringg-frontend/utils';

import { useStores } from 'bringg-web/recipes';
import ModalHeader from 'bringg-web/features/planned-delivery-windows/modals/multi-slot-modal/header/modal-header';
import ModalFooter from 'bringg-web/features/planned-delivery-windows/modals/multi-slot-modal/footer/modal-footer';
import {
	initialDataOFShowElements,
	ShowElementsMultiEditMode
} from 'bringg-web/features/planned-delivery-windows/modals/multi-slot-modal/planned-delivery-windows-multi-slot-modal.consts';
import CustomTreeDropdownSelect from 'bringg-web/features/planned-delivery-windows/modals/multi-slot-modal/custom-tree-dropdown-select/custom-tree-dropdown-select';
import CutoffRadioGroup from 'bringg-web/features/planned-delivery-windows/modals/slots-modal/wizard-steps/configuration-step/time-slots/cutoff-radio-group/cutoff-radio-group';
import {
	CutoffEnum,
	DailyCutoff,
	RelativeCutoff
} from 'bringg-web/features/planned-delivery-windows/modals/slots-modal/wizard-steps/configuration-step/time-slots/cutoff-radio-group/cutoff.types';
import {
	initialDailyCutoff,
	initialRelativeCutoff
} from 'bringg-web/features/planned-delivery-windows/modals/slots-modal/planned-delivery-windows-slots.consts';
import useTimeFormat from 'bringg-web/hooks/use-time-format';
import { cutoffCalculation } from 'bringg-web/features/planned-delivery-windows/services/cutoff-calculation';
import RecurrenceTypeModal from 'bringg-web/features/planned-delivery-windows/modals/recurrence-type-modal/recurrence-type-modal';
import { RecurrenceType } from 'bringg-web/features/planned-delivery-windows/modals/recurrence-type-modal/recurrence-type-modal.consts';

import styles from './planned-delivery-windows-multi-slot-modal.module.scss';

type PlannedDeliveryWindowsServiceIds = 'service_plan_ids' | 'service_area_ids';

interface Props {
	modalVisible: boolean;
	timezone: string;
	selectedEventsIds: Set<number>;
	closeModal: () => void;
	closeMultiSelectModal: () => void;
	getRecurrenceOfPlannedDeliveryWindow: (plannedDeliveryWindow: PlannedDeliveryWindow) => [string, string];
	refetchPlannedDeliveryWindows: () => Promise<void>;
}
const PlannedDeliveryWindowsMultiSlotModal = ({
	modalVisible,
	timezone,
	selectedEventsIds,
	closeModal,
	getRecurrenceOfPlannedDeliveryWindow,
	closeMultiSelectModal,
	refetchPlannedDeliveryWindows
}: Props) => {
	const { t } = useTranslation();
	const {
		servicePlansStore,
		serviceArea,
		merchantConfigurationsStore,
		plannedDeliveryWindows: plannedDeliveryWindowsStore
	} = useStores();
	const [loading, setLoading] = useState({ recurrenceModal: false, slotModal: false });
	const [showElements, setShowElements] = useState<ShowElementsMultiEditMode>(initialDataOFShowElements);
	const [cutoffType, setCutoffType] = useState<CutoffEnum>(CutoffEnum.RELATIVE);
	const [recurrenceTypeModalVisible, setRecurrenceTypeModalVisible] = useState(false);
	const [relativeCutoff, setRelativeCutoff] = useState<RelativeCutoff>(initialRelativeCutoff);
	const [dailyCutoff, setDailyCutoff] = useState<DailyCutoff>(initialDailyCutoff(timezone));
	const [selectedPlannedDeliveryWindows, setSelectedPlannedDeliveryWindows] = useState(
		[...selectedEventsIds].map(eventId => toJS(plannedDeliveryWindowsStore.get(eventId)))
	);
	const use12Hours = dateUtils.use12Hours(merchantConfigurationsStore.hourFormat);
	const format = useTimeFormat();
	const servicePlanOptions = useMemo(
		() =>
			servicePlansStore.getAll
				.sort((a, b) => (a.name > b.name ? 1 : -1))
				.map(({ id, name }) => ({
					value: id,
					title: name,
					children: []
				})),
		[selectedEventsIds]
	);

	const serviceAreaOptions = useMemo(
		() =>
			serviceArea
				.getGroup(ServiceAreaFilterGroups.Teams, selectedPlannedDeliveryWindows[0].team_id)
				.sort((a, b) => (a.name > b.name ? 1 : -1))
				.map(({ id, name }) => ({
					value: id,
					title: name,
					children: []
				})),
		[selectedEventsIds]
	);

	const isSelectedByProperty = (id: number, property: PlannedDeliveryWindowsServiceIds) =>
		selectedPlannedDeliveryWindows.every(pdw => pdw[property].includes(id));

	const getIndeterminate = (id: number, property: PlannedDeliveryWindowsServiceIds) => {
		const length = selectedPlannedDeliveryWindows.filter(pdw => pdw[property].includes(id)).length;

		return {
			indeterminate: !isSelectedByProperty(id, property) && length > 0,
			length
		};
	};

	const dropDownMenuItems = [
		{
			key: '1',
			label: t('PLANNED_DELIVERY_WINDOWS_MODAL.CUTOFF_TEXT'),
			onClick: () => setShowElements(prev => ({ ...prev, cutoff: true })),
			disabled: showElements.cutoff
		},
		{
			key: '2',
			label: t('PLANNED_DELIVERY_WINDOWS_MODAL_CONDITIONS.SERVICE_PLAN_TEXT'),
			onClick: () => setShowElements(prev => ({ ...prev, service_plan_ids: true })),
			disabled: showElements.service_plan_ids
		},
		{
			key: '3',
			label: t('PLANNED_DELIVERY_WINDOWS_MODAL_CONDITIONS.SERVICE_AREAS_TEXT'),
			onClick: () => setShowElements(prev => ({ ...prev, service_area_ids: true })),
			disabled: showElements.service_area_ids || !selectedPlannedDeliveryWindows[0].team_id
		}
	];

	const onCheckboxChange = (id: number, isSelected: boolean, property: PlannedDeliveryWindowsServiceIds) => {
		const updatedSelectedPlannedDeliveryWindows = selectedPlannedDeliveryWindows.map(pdw => {
			const newIds = isSelected
				? Array.from(new Set([...pdw[property], id]))
				: pdw[property].filter(propertyId => propertyId !== id);
			return {
				...pdw,
				[property]: newIds
			};
		});
		setSelectedPlannedDeliveryWindows(updatedSelectedPlannedDeliveryWindows);
	};

	const getSelectedIds = (property: PlannedDeliveryWindowsServiceIds) => {
		const selectedIds = [];
		selectedPlannedDeliveryWindows
			.filter(pdw => pdw[property].length > 0)
			.forEach(pdw => {
				pdw[property].forEach(id => selectedIds.push(id));
			});
		return selectedIds;
	};

	const onCancel = () => {
		closeModal();
	};
	const prepareDataForBulkUpdate = (recurrenceType: RecurrenceType) => {
		let updatedData = selectedPlannedDeliveryWindows;
		if (showElements.cutoff) {
			updatedData = selectedPlannedDeliveryWindows.map(plannedDeliveryWindow => {
				let cutoff = cutoffCalculation.calculateDaily(dailyCutoff, plannedDeliveryWindow.start_time, timezone);
				if (cutoffType === CutoffEnum.RELATIVE) {
					cutoff = cutoffCalculation.calculateRelative(relativeCutoff);
				}

				return {
					...plannedDeliveryWindow,
					cutoff
				};
			});
		}
		const plannedDeliveryWindowsToEdit: PlannedDeliveryWindowsBulkEditRequest = {
			recurrence_type: recurrenceType,
			planned_delivery_windows: updatedData.map(plannedDeliveryWindow => {
				const [recurrenceStartDate, recurrenceEndDate] =
					getRecurrenceOfPlannedDeliveryWindow(plannedDeliveryWindow);
				return {
					recurrence_start_date: recurrenceStartDate,
					recurrence_end_date: recurrenceEndDate,
					planned_delivery_window: plannedDeliveryWindow
				};
			})
		};

		return plannedDeliveryWindowsToEdit;
	};

	const onUpdate = async (recurrenceType: RecurrenceType) => {
		setLoading(prevState => ({ ...prevState, recurrenceModal: true }));
		const plannedDeliveryWindowsToEdit = prepareDataForBulkUpdate(recurrenceType);
		try {
			await plannedDeliveryWindowsStore.bulkUpdate(plannedDeliveryWindowsToEdit);
			Notification.success(t('PLANNED_DELIVERY_WINDOWS.UPDATE_SUCCESS'));
			await refetchPlannedDeliveryWindows();
			setRecurrenceTypeModalVisible(false);
			onCancel();
			closeMultiSelectModal();
		} catch (e) {
			Notification.error(t('PLANNED_DELIVERY_WINDOWS.UPDATE_FAILED'));
			setRecurrenceTypeModalVisible(false);
			setLoading(prevState => ({ ...prevState, recurrenceModal: false }));
			console.error('failed to edit multiple slots: ', e);
		}
	};

	const openRecurrenceModal = () => {
		setLoading(prevState => ({ ...prevState, slotModal: true }));
		setRecurrenceTypeModalVisible(true);
	};
	const closeRecurrenceModal = () => {
		setLoading(prevState => ({ ...prevState, slotModal: false }));
		setRecurrenceTypeModalVisible(false);
	};

	const onHide = (stateName: 'service_plan_ids' | 'service_area_ids' | 'cutoff') => {
		setShowElements(prevState => ({ ...prevState, [stateName]: false }));
		setSelectedPlannedDeliveryWindows(prevState => {
			return prevState.map(pdw => {
				const value = plannedDeliveryWindowsStore.get(pdw.id)[stateName];

				return { ...pdw, [stateName]: value };
			});
		});
	};

	const isDisabled = !showElements.service_area_ids && !showElements.service_plan_ids && !showElements.cutoff;

	return (
		<>
			<Modal
				width={960}
				visible={modalVisible}
				destroyOnClose
				title={
					<ModalHeader selectedPlannedDeliveryWindows={selectedPlannedDeliveryWindows} timezone={timezone} />
				}
				wrapClassName={styles.plannedDeliveryWindowsSlotModalWrapper}
				className={styles.PlannedDeliveryWindowsModal}
				onCancel={closeModal}
				footer={
					<ModalFooter
						onCancel={onCancel}
						disabled={isDisabled}
						isLoading={loading.slotModal}
						onUpdate={openRecurrenceModal}
					/>
				}
			>
				<DropdownMenu
					items={dropDownMenuItems}
					trigger={['click']}
					placement="bottom"
					adjustOverflow={AdjustPopoverOverflow.ADJUST}
				>
					<Button
						type="default"
						className={styles.multiEditDropdown}
						icon={<BringgIcon iconName={BringgFontIcons.Plus} />}
					>
						{t('PLANNED_DELIVERY_WINDOWS_MODAL.ADD_PROPERTY')}
					</Button>
				</DropdownMenu>
				<div className={styles.conditionElements}>
					{showElements.cutoff && (
						<>
							<CutoffRadioGroup
								format={format}
								use12Hours={use12Hours}
								cutoffType={cutoffType}
								setCutoffType={setCutoffType}
								relativeCutoff={relativeCutoff}
								setRelativeCutoff={setRelativeCutoff}
								dailyCutoff={dailyCutoff}
								setDailyCutoff={setDailyCutoff}
								timeDuration={{ hours: 0, minutes: 0 }}
								startTimeMinutes={0}
								isEditMode={false}
								timezone={timezone}
							/>
							<Button
								className={styles.eraseButton}
								onClick={() => onHide('cutoff')}
								type="text"
								icon={<BringgIcon iconName={BringgFontIcons.EyeOff} />}
							></Button>
						</>
					)}
					{showElements.service_plan_ids && (
						<>
							<CustomTreeDropdownSelect
								selectedIds={getSelectedIds('service_plan_ids')}
								options={servicePlanOptions}
								updateCheckbox={(id, isSelected) =>
									onCheckboxChange(id, isSelected, 'service_plan_ids')
								}
								getIndeterminate={id => getIndeterminate(id, 'service_plan_ids')}
								isSelected={id => isSelectedByProperty(id, 'service_plan_ids')}
								placeholder={t('PLANNED_DELIVERY_WINDOWS_MODAL_CONDITIONS.SERVICE_PLAN_PLACEHOLDER')}
								selectAllTagText={t(
									'PLANNED_DELIVERY_WINDOWS_MODAL_CONDITIONS.SERVICE_PLAN_SELECTED_ALL_TAG_TEXT'
								)}
								dataTestId="conditions-service-plan"
								selectAllText={t('PLANNED_DELIVERY_WINDOWS_MODAL_CONDITIONS.SELECT_ALL_TEXT')}
								labelText={t('PLANNED_DELIVERY_WINDOWS_MODAL_CONDITIONS.SERVICE_PLAN_TEXT')}
								slotText={t('PLANNED_DELIVERY_WINDOWS_MULTI_SLOTS_MODAL.SLOTS_TEXT')}
							/>
							<Button
								className={styles.eraseButton}
								onClick={() => onHide('service_plan_ids')}
								type="text"
								icon={<BringgIcon iconName={BringgFontIcons.EyeOff} />}
							></Button>
						</>
					)}

					{showElements.service_area_ids && (
						<>
							<CustomTreeDropdownSelect
								selectedIds={getSelectedIds('service_area_ids')}
								options={serviceAreaOptions}
								updateCheckbox={(id, isSelected) =>
									onCheckboxChange(id, isSelected, 'service_area_ids')
								}
								getIndeterminate={id => getIndeterminate(id, 'service_area_ids')}
								isSelected={id => isSelectedByProperty(id, 'service_area_ids')}
								placeholder={t('PLANNED_DELIVERY_WINDOWS_MODAL_CONDITIONS.SERVICE_AREAS_PLACEHOLDER')}
								selectAllTagText={t(
									'PLANNED_DELIVERY_WINDOWS_MODAL_CONDITIONS.SERVICE_AREAS_SELECTED_ALL_TAG_TEXT'
								)}
								dataTestId="conditions-service-area"
								selectAllText={t('PLANNED_DELIVERY_WINDOWS_MODAL_CONDITIONS.SELECT_ALL_TEXT')}
								labelText={t('PLANNED_DELIVERY_WINDOWS_MODAL_CONDITIONS.SERVICE_AREAS_TEXT')}
								slotText={t('PLANNED_DELIVERY_WINDOWS_MULTI_SLOTS_MODAL.SLOTS_TEXT')}
							/>
							<Button
								className={styles.eraseButton}
								onClick={() => onHide('service_area_ids')}
								type="text"
								icon={<BringgIcon iconName={BringgFontIcons.EyeOff} />}
							></Button>
						</>
					)}
				</div>
			</Modal>
			<RecurrenceTypeModal
				visible={recurrenceTypeModalVisible}
				isEditMode={true}
				closeAndCancel={closeRecurrenceModal}
				onUpdate={onUpdate}
				onDelete={() => null}
				recurrenceDisabled={{ current_and_following: false, current: false, all: false }}
			/>
		</>
	);
};

export default PlannedDeliveryWindowsMultiSlotModal;
