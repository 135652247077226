import React, { useState, useEffect } from 'react';
import { DatePicker, InputNumber, Radio } from '@bringg/react-components';
import moment, { Moment } from 'moment';
import { Options } from 'rrule';
import { useTranslation } from 'react-i18next';
import './ends-at.scss';
import useDateFormat from 'bringg-web/hooks/use-date-format';

export interface Props {
	onUntilChanged: (until: Date | null) => void;
	onCountChanged: (count: number | null) => void;
	rruleOptions: Partial<Options>;
	timezone: string;
}

enum RepeatOptions {
	AFTER,
	ON,
	NEVER
}

export const EndsAt = ({ rruleOptions, onUntilChanged, onCountChanged, timezone }: Props) => {
	const { t } = useTranslation();
	const dateFormat = useDateFormat();
	const [selectedRepeat, setSelectedRepeat] = useState<RepeatOptions>(RepeatOptions.NEVER);
	useEffect(() => {
		const { until, count } = rruleOptions;
		if (until) {
			setSelectedRepeat(RepeatOptions.ON);
		}

		if (count) {
			setSelectedRepeat(RepeatOptions.AFTER);
		}
	}, [rruleOptions]);

	const onDateChange = (date: Moment | null) => {
		onUntilChanged(date ? date.endOf('day').toDate() : null);
	};

	const onCountInputChange = (count: string | number | null) => {
		onCountChanged(count ? +count : null);
	};

	const clearUntilAndCount = () => {
		onDateChange(null);
		onCountInputChange(null);
	};

	const onGroupRadioChanged = event => {
		const { value } = event.target;
		setSelectedRepeat(value);
		if (RepeatOptions.ON === value && !rruleOptions.until) {
			onDateChange(moment().tz(timezone));
		} else if (RepeatOptions.AFTER === value && !rruleOptions.count) {
			onCountInputChange(1);
		} else {
			clearUntilAndCount();
		}
	};

	return (
		<div className="recurrence-ends-at">
			<span>
				<b>{t('DELIVERY_BLOCK_MODAL.RECURRENCE.ENDS_AT')}</b>
			</span>
			<Radio.Group
				className="recurrence-ends-at-radio-group"
				value={selectedRepeat}
				onChange={onGroupRadioChanged}
			>
				<div className="recurrence-ends-at-item-on">
					<Radio value={RepeatOptions.ON}>{t('DELIVERY_BLOCK_MODAL.RECURRENCE.ON')}</Radio>
					<DatePicker
						placeholder={t('DELIVERY_BLOCK_MODAL.RECURRENCE.SELECT_DATE')}
						disabled={selectedRepeat !== RepeatOptions.ON}
						className="recurrence-ends-at-item-on-date"
						format={dateFormat}
						allowClear={false}
						value={rruleOptions.until ? moment(rruleOptions.until).tz(timezone) : null}
						onChange={onDateChange}
					/>
				</div>

				<Radio value={RepeatOptions.AFTER}>
					<div className="recurrence-ends-at-item">
						{t('DELIVERY_BLOCK_MODAL.RECURRENCE.AFTER')}
						<InputNumber
							disabled={selectedRepeat !== RepeatOptions.AFTER}
							className="recurrence-ends-at-item-after-input"
							value={rruleOptions.count}
							placeholder={t('DELIVERY_BLOCK_MODAL.RECURRENCE.AFTER')}
							onChange={onCountInputChange}
							min={1}
						/>
						{t('DELIVERY_BLOCK_MODAL.RECURRENCE.OCCURENCES')}
					</div>
				</Radio>
				<Radio value={RepeatOptions.NEVER}>
					<div className="recurrence-ends-at-item">{t('DELIVERY_BLOCK_MODAL.RECURRENCE.NEVER')}</div>
				</Radio>
			</Radio.Group>
		</div>
	);
};
