import { useCallback, useEffect, useRef, useState } from 'react';

import { Notification, Modal } from '@bringg/react-components';
import { BatchActionStatusToast, Failure, useBatchActionStatusTranslation } from '@bringg-frontend/batch-action-status';
import { observer } from 'mobx-react';

import resourceUploader from 'bringg-web/services/resource-upload/resource-uploader';
import ResourcesResultsModal from 'bringg-web/features/resources/resource-results-modal';

async function sleep(timeout) {
	return new Promise(resolve => {
		setTimeout(resolve, timeout);
	});
}

export enum Phase {
	queued = 'queued',
	downloading = 'downloading',
	processing = 'processing',
	completed = 'completed'
}

export type ResourceUploadStatus = {
	uploaded: number;
	imported: number;
	errors_url: string;
	failed: number;
	phase: string;
};

export type Props = {
	resourceId: string;
	onClose: () => void;
	onUploadFinished?: () => void;
	timeout?: number;
	title: React.ReactNode;
	translations: Record<string, string>;
	renderFailures?: (failures: Failure[]) => React.ReactNode;
	renderFooter?: (failures: Failure[]) => React.ReactNode;
	width?: number;
	className?: string;
	processFailures?: (failures: Failure[]) => Failure[];
	mergedActions?: number;
	minimized?: boolean;
	errorsFileRender?: (status: ResourceUploadStatus) => React.ReactNode;
};

const POLLING_TIMEOUT = 2_000;

const ResourceAsyncOperationStatus = ({
	resourceId,
	onClose,
	onUploadFinished,
	timeout = POLLING_TIMEOUT,
	title,
	translations: t,
	renderFailures,
	renderFooter,
	width,
	className,
	processFailures,
	mergedActions = 0,
	minimized: minimizedProp = false,
	errorsFileRender
}: Props) => {
	const translations = useBatchActionStatusTranslation(title);
	const [status, setStatus] = useState<ResourceUploadStatus>(null);
	const [failures, setFailures] = useState([]);
	const isMounted = useRef(true);
	const [minimized, setMinimized] = useState(minimizedProp);

	useEffect(() => {
		return () => {
			isMounted.current = false;
		};
	}, []);

	useEffect(() => {
		async function fetchStatus(id) {
			if (!isMounted.current) {
				return;
			}

			const resp = (await resourceUploader.getProgress(id)) as unknown as { status: ResourceUploadStatus };
			setStatus(
				resp
					? {
							...resp.status,
							uploaded: resp.status.uploaded + mergedActions
					  }
					: null
			);

			if (resp?.status && resp.status.phase !== Phase.completed) {
				await sleep(timeout);
				try {
					await fetchStatus(id);
				} catch (ex) {
					console.error(ex);
					Notification.error(t.failedToFetchStatus);
				}
			} else {
				onUploadFinished && onUploadFinished();
			}
		}

		if (resourceId) {
			void fetchStatus(resourceId);
		}
	}, [resourceId, onUploadFinished, timeout, t, mergedActions]);

	useEffect(() => {
		if (!status) {
			return;
		}

		if (status.failed > 0 && status.errors_url) {
			fetch(status.errors_url)
				.then(async resp => resp.text())
				.then(failuresString => {
					let result = failuresString
						.split('\n')
						.filter(string => Boolean(string.trim()))
						.map(string => {
							const failure = JSON.parse(string);

							return {
								id: failure.index,
								title: failure.result.message
							};
						});

					if (processFailures) {
						result = processFailures(result as Failure[]);
					}

					setFailures(result);
				})
				.catch(ex => {
					console.error(ex);
					Notification.error(t.failedToFetchStatus);
				});
		}
	}, [status, t, processFailures]);

	const onCloseCallback = useCallback(() => {
		setStatus(null);
		setFailures([]);
		onClose();
	}, [onClose]);

	if (!status) {
		return null;
	}

	if (![Phase.processing, Phase.completed].includes(status.phase as Phase)) {
		if (minimized) {
			Notification.info(t.parsingFile);
			return null;
		}

		return <Modal maskClosable={false} open title={t.parsingFile} footer={null} />;
	}

	const sharedProps = {
		translations,
		failures,
		className,
		totalNumberOfActions: status.uploaded,
		currentNumberOfActionsSucceeded:
			status.phase === Phase.completed && (!status.errors_url || failures.length)
				? Math.max(status.uploaded - failures.length, 0)
				: status.imported,
		onClose: onCloseCallback
	};

	return minimized ? (
		<BatchActionStatusToast
			key={resourceId}
			id={resourceId}
			onClose={onCloseCallback}
			hideFailureReasonColumn
			onMaximize={minimizedProp ? null : () => setMinimized(false)}
			errorsFileRender={errorsFileRender && errorsFileRender(status)}
			{...sharedProps}
		/>
	) : (
		<ResourcesResultsModal
			onMinimize={() => setMinimized(true)}
			renderFailures={renderFailures}
			renderFooter={renderFooter}
			width={width}
			{...sharedProps}
		/>
	);
};

export default observer(ResourceAsyncOperationStatus);
