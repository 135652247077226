import { getRootEnv } from '@bringg-frontend/bringg-web-infra';
import { observable, computed, action, makeObservable } from 'mobx';
import { getEnv } from 'mobx-easy';
import { Fleet, FleetType, OpenFleetCreationData } from '@bringg/types';
import { BringgDashboardSDK } from '@bringg/dashboard-sdk';
import _isNumber from 'lodash/isNumber';
import { RootEnv } from '@bringg-frontend/bringg-web-infra';

import BaseDomainStore from '../core/base-domain-store';
import FleetTemplate from './domain-objects/fleet-template';

export type FleetTemplateSelection = {
	[key: string]: boolean;
};

class FleetsStore extends BaseDomainStore<Fleet> {
	fleetTemplates: Map<number, FleetTemplate> = new Map();

	constructor() {
		super();

		makeObservable(this, {
			fleetTemplates: observable.shallow,
			getFleetTemplateIds: computed,
			getFleetTemplateById: action,
			registerFleets: action
		});
	}

	fetch = async (fleetId: number) => {
		if (this.get(fleetId)) {
			return this.get(fleetId);
		}

		const { dashboardSdk } = getRootEnv();
		const fleet = await dashboardSdk.sdk.fleets.getById(fleetId);
		if (!fleet) return null;

		this.set(fleet);
		return fleet;
	};

	fetchAll = async () => {
		const { dashboardSdk } = getRootEnv();
		try {
			const fleets = await dashboardSdk.sdk.fleets.getAll();
			this.setBatch(fleets);
		} catch (error) {
			console.error('failed fetching fleets', error);
		}
	};

	get getFleetTemplateIds(): number[] {
		return Array.from(this.fleetTemplates.keys());
	}

	getFleetTemplateById(id: number): FleetTemplate {
		return this.fleetTemplates.get(id);
	}

	fetchData = async (): Promise<void> => {
		await this.fetchAvailableFleets();
		await this.fetchMerchantFleets();
	};

	async fetchAvailableFleets(): Promise<void> {
		const { dashboardSdk } = getRootEnv();
		const fleetTemplates = await dashboardSdk.sdk.fleetTemplates.getAvailableOpenFleets();
		fleetTemplates.forEach(fleetTemplate =>
			this.fleetTemplates.set(fleetTemplate.id, new FleetTemplate(fleetTemplate))
		);
	}

	fetchMerchantFleets = async (): Promise<void> => {
		const { dashboardSdk } = getRootEnv();
		const fleets = await dashboardSdk.sdk.fleets.getAllConfigs();

		fleets.forEach(fleet => {
			if (_isNumber(fleet.fleet_template_id)) {
				const template = this.fleetTemplates.get(fleet.fleet_template_id);
				if (template) {
					template.setFleetId(fleet.id);
				}
			}
		});
	};

	async registerFleets(fleetTemplateSelection: FleetTemplateSelection) {
		const { dashboardSdk } = getRootEnv();

		await Promise.all(
			Object.keys(fleetTemplateSelection).map(async key => {
				const fleetTemplateId = +key;

				if (fleetTemplateSelection[fleetTemplateId] === true) {
					await this.handleSelectedFleet(fleetTemplateId, dashboardSdk.sdk);
				} else {
					await this.handleUnselectedFleet(fleetTemplateId, dashboardSdk.sdk);
				}
			})
		);
	}

	async handleSelectedFleet(fleetTemplateId: number, sdk: BringgDashboardSDK) {
		const fleetTemplate = this.fleetTemplates.get(fleetTemplateId);
		if (!fleetTemplate.isRegistered) {
			const openFleetData: OpenFleetCreationData = { fleet_template_id: fleetTemplateId };
			await sdk.fleets.create(FleetType.SelfIntegrated, openFleetData);
		}
	}

	handleEnableHybridDeliveryNetwork = async (): Promise<boolean> => {
		const { dashboardSdk } = getRootEnv();
		try {
			const fleetRes = await dashboardSdk.sdk.fleets.create(FleetType.Internal);
			const merchant = { ...dashboardSdk.sdk.merchant.get(), internal_fleet_id: fleetRes?.fleet?.id };
			await dashboardSdk.sdk.merchant.update(merchant);
			return true;
		} catch (error) {
			return false;
		}
	};

	async handleUnselectedFleet(fleetTemplateId: number, sdk: BringgDashboardSDK) {
		const fleetTemplate = this.fleetTemplates.get(fleetTemplateId);
		if (fleetTemplate.isRegistered) {
			await sdk.fleets.delete(fleetTemplate.fleet_id);
		}
	}
}

export default FleetsStore;
