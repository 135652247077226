import { getRootEnv } from '@bringg-frontend/bringg-web-infra';
import type { MasterRateTermCardsPayload, PostRateCardTermsResponse, RateCardTerm } from '@bringg/types';
import {
	Currency,
	FactType,
	MetadataResponse,
	RateCardEntityType,
	RateCardTermType,
	RuleCategory
} from '@bringg/types';
import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { getRoot } from 'mobx-easy';
import { Attribute, getRandomGuid } from '@bringg/react-components/dist/features/conditions/rule/rule-utils';
import { cloneDeep, keyBy as _keyBy, orderBy as _orderBy, partition as _partition } from 'lodash';
import type { ClientRateCardTerm } from '@bringg/react-components';

import notification from 'bringg-web/services/notification';
import ChangeTracker from '../../../rule-engine/utils/change-tracker';
import { getAttributes, getFlatAttribute, getOptions } from '../../../rule-engine/utils/mapper';
import { generateNewRateTerm, mapRateTermsFromServer, mapRateTermsToServer } from '../rate-card-utils';
import { validate } from '../../../rule-engine/utils/condition-validator';
import type { RateTermsByType } from '../types';
import RootStore from '../../../../stores/root-store';

interface TrackedStructure {
	useForQuotes: boolean;
	items: ClientRateCardTerm[];
}

const validateRate = (term: ClientRateCardTerm): boolean => term.rate_base && term.rate_amount > 0;

class RateCardStore {
	pendingUpdate = false;
	terms: ClientRateCardTerm[] = [];
	attributes: Attribute[] = [];

	changeTracker = ChangeTracker<ClientRateCardTerm, TrackedStructure>();
	attributesByPath: { [path: string]: Attribute };
	options: MasterRateTermCardsPayload['options'];
	useForQuotes = false;
	entityType: RateCardEntityType;
	entityId: number;
	merchantCurrency: Currency = Currency.USD;
	deletedTerms: Record<number, boolean> = {};
	metadata: MetadataResponse;

	constructor() {
		makeObservable(this, {
			pendingUpdate: observable,
			terms: observable.shallow,
			attributes: observable.shallow,
			removeTermById: action,
			updateTermById: action,
			removeTermsById: action,
			duplicateTermById: action,
			addTerm: action,
			discardChanges: action,
			saveTerms: action,
			setUpdatedTerms: action,
			setCreatedTerms: action,
			updateTermsAfterSave: action,
			termsAreValid: computed,
			isDirty: computed,
			rateTermsByType: computed,
			useForQuotes: observable,
			setUseForQuotes: action,
			merchantCurrency: observable
		});
	}

	load = async (entityType: RateCardEntityType, entityId: number) => {
		this.cleanState();
		this.entityType = entityType;
		this.entityId = entityId;
		const { sdk } = getRootEnv().dashboardSdk;

		const termsPromise = sdk.ratesApi.getRates({ entityType, entityId });
		const metadataPromise = this.metadata
			? this.metadata
			: sdk.rules.getMetadata({ category_id: RuleCategory.Rate });
		const [metadata, ratesResponse] = await Promise.all([metadataPromise, termsPromise]);
		this.metadata = metadata;
		const sortedTerms = _orderBy(ratesResponse.terms, ['id']);

		runInAction(() => {
			this.attributes = getAttributes(metadata, true, FactType.Rate);
			this.attributesByPath = getFlatAttribute(this.attributes);
			this.options = getOptions(Object.values(this.attributesByPath));
			this.terms = mapRateTermsFromServer(sortedTerms, this.attributesByPath);
			this.useForQuotes = ratesResponse.use_for_quotes;
			this.changeTracker.setOriginData({
				useForQuotes: this.useForQuotes,
				items: this.terms
			});
			this.merchantCurrency =
				getRoot<RootStore>().data.merchantConfigurationsStore.configuration.price_currency || Currency.USD;
		});
	};

	cleanState = () => {
		this.deletedTerms = {};
	};

	removeTermById = (guid: ClientRateCardTerm['guid']) => {
		const itemIndexToRemove = this.terms.findIndex(t => t.guid === guid);
		if (itemIndexToRemove === -1) {
			return;
		}
		const nextTerms = this.terms.slice();
		const [itemToRemove] = nextTerms.splice(itemIndexToRemove, 1);
		if (itemToRemove.id) {
			this.deletedTerms[itemToRemove.id] = true;
		}
		this.terms = nextTerms;
	};

	updateTermById = (guid: ClientRateCardTerm['guid'], updater: Partial<ClientRateCardTerm>) => {
		this.terms = this.terms.map(term =>
			term.guid === guid
				? {
						...term,
						...updater
				  }
				: term
		);
	};

	addTerm = (type: RateCardTermType) => {
		const newTerm = generateNewRateTerm(type, this.merchantCurrency);
		this.terms = [...this.terms, newTerm];
		return newTerm;
	};

	duplicateTermById = (guid: number) => {
		const targetTerm = this.terms.find(term => term.guid === guid);
		if (!targetTerm) {
			notification.error('Not able to find Term to duplicate');
			return;
		}
		// TODO: figure out if duplications of id for condition items will cause any errors
		//  doesn't look so at the moment
		const newTerm = Object.assign(cloneDeep(targetTerm), { guid: getRandomGuid() });
		delete newTerm.id;
		this.terms = [...this.terms, newTerm];
	};

	discardChanges = () => {
		const { items, useForQuotes } = this.changeTracker.getOriginData();
		this.terms = items;
		this.useForQuotes = useForQuotes;
		this.cleanState();
	};

	saveTerms = async () => {
		this.pendingUpdate = true;
		const [exist, created] = _partition(this.terms, term => Boolean(term.id));
		const updated = exist.reduce((acc, term) => {
			const itemUpdater = this.changeTracker.getItemShallowDiff(term);
			if (itemUpdater) {
				acc.push({ ...itemUpdater, id: term.id });
			}
			return acc;
		}, []);

		const deletedIdsList = Object.keys(this.deletedTerms).map(id => Number(id));
		const payload = {
			created: created?.length ? mapRateTermsToServer(created) : undefined,
			updated: updated?.length ? mapRateTermsToServer(updated) : undefined,
			deleted: deletedIdsList.length ? deletedIdsList : undefined,
			entity_type: this.entityType,
			entity_id: this.entityId,
			use_for_quotes: this.useForQuotes
		} as PostRateCardTermsResponse;

		const { sdk } = getRootEnv().dashboardSdk;
		try {
			const response = await sdk.ratesApi.postRates(payload);
			this.updateTermsAfterSave(response);
			this.pendingUpdate = false;
		} catch (e) {
			this.pendingUpdate = false;
			throw e;
		}
	};

	importTerms = async (type: RateCardTermType, file: File): Promise<PostRateCardTermsResponse> => {
		const { sdk } = getRootEnv().dashboardSdk;

		const result = await sdk.ratesApi.importRateCardTerms({
			options: this.options,
			entity_id: this.entityId,
			term_type: type,
			file
		});

		this.removeTermsById(result.deleted);
		this.updateTermsAfterSave(result);

		return result;
	};

	exportTerms = async (type: RateCardTermType): Promise<[string, string]> => {
		const { sdk } = getRootEnv().dashboardSdk;

		return Promise.all([
			sdk.ratesApi.exportRateCardTerms({
				options: this.options,
				entity_id: this.entityId,
				term_type: type
			}),
			sdk.ratesApi.exportMasterRateCardTerms({
				options: this.options
			})
		]);
	};

	setUseForQuotes = (useForQuotes: boolean) => {
		this.useForQuotes = useForQuotes;
	};

	setUpdatedTerms = (updated: RateCardTerm[]) => {
		const mappedUpdatedItemsById = _keyBy(mapRateTermsFromServer(updated, this.attributesByPath), 'id');
		this.terms = this.terms.filter(term => Boolean(term.id)).map(term => mappedUpdatedItemsById[term.id] ?? term);
	};

	setCreatedTerms = (created: RateCardTerm[]) => {
		const mappedCreatedTerms = mapRateTermsFromServer(created, this.attributesByPath);
		this.terms = [...this.terms, ...mappedCreatedTerms];
	};

	removeTermsById = (ids: number[] = []) => {
		this.terms = this.terms.filter(term => !ids.includes(term.id));
	};

	updateTermsAfterSave = ({
		created = [],
		updated = [],
		deleted = [],
		use_for_quotes
	}: PostRateCardTermsResponse) => {
		this.setUpdatedTerms(updated);
		this.setCreatedTerms(created);
		this.changeTracker.setOriginData({
			useForQuotes: use_for_quotes,
			items: this.terms
		});
		deleted.forEach(id => {
			delete this.deletedTerms[id];
		});
	};

	get termsAreValid() {
		return !this.terms.some(term => !validate(term.conditions) || !validateRate(term));
	}

	get isDirty() {
		return this.changeTracker.isDirty({
			useForQuotes: this.useForQuotes,
			items: this.terms
		});
	}

	get rateTermsByType() {
		return this.terms.reduce<RateTermsByType>(
			(acc, term) => {
				if (term.type === RateCardTermType.Base) {
					acc.base.push(term);
				} else if (term.type === RateCardTermType.Surcharge) {
					acc.surcharge.push(term);
				}
				return acc;
			},
			{ base: [], surcharge: [] }
		);
	}
}

export default RateCardStore;
