import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Button, Modal } from 'antd';
import every from 'lodash/every';
import { WithTranslation, withTranslation } from 'react-i18next';
import { BringgException } from '@bringg/dashboard-sdk/dist/Core/BringgException';
import SharingMode from '../../sharing-mode/sharing-mode';
import notification from '../../../../services/notification';
import NotificationTemplateList from '../../notification-template-list/notification-template-list';
import MerchantCustomerNotificationsStore from '../../../../stores/customer-notifications/merchant-customer-notifications-store';
import { Translate } from '../../../../translation';
import CustomerNotificationSettings from '../../customer-notification-settings/customer-notification-settings';
import { LanguageSelect } from 'bringg-web/components/language-select';

interface Props {
	merchantCustomerNotificationsStore?: MerchantCustomerNotificationsStore;
	dictionary?: any;
}

class CustomerNotificationsContainer extends Component<Props & WithTranslation> {
	updateConfigurations = async () => {
		if (!this.isAllowedToSaveTemplates()) {
			notification.error(this.props.t('CUSTOMER_CONFIGURATION_VIEW.ALL_VALUES_MUST_BE_FILLED'));
			return;
		}
		try {
			await this.props.merchantCustomerNotificationsStore.updateConfigurations();
			notification.success(this.props.t('CUSTOMER_CONFIGURATION_VIEW.CONFIGURATION_SAVED_SUCCESSFULLY'));
		} catch (e) {
			notification.error(
				this.props.t('CUSTOMER_CONFIGURATION_VIEW.CONFIGURATION_FAILED_TO_SAVE'),
				(e as BringgException).details as string
			);
		}
	};

	deleteLanguageTemplates = buttonClickEvent => {
		buttonClickEvent.preventDefault();
		const confirmPayload = {
			title: this.props.t('CUSTOMER_CONFIGURATION_VIEW.ARE_U_SURE_TO_DELETE'),
			okText: this.props.t('CUSTOMER_CONFIGURATION_VIEW.DELETE'),
			okType: 'danger' as const,
			cancelText: this.props.t('CUSTOMER_CONFIGURATION_VIEW.CANCEL'),
			onOk: async () => {
				try {
					await this.props.merchantCustomerNotificationsStore.deleteLanguageTemplates();
					notification.success(this.props.t('CUSTOMER_CONFIGURATION_VIEW.TEMPLATES_WERE_DELETED'));
				} catch (e) {
					notification.error(
						this.props.t('CUSTOMER_CONFIGURATION_VIEW.TEMPLATES_FAILED_TO_DELETE'),
						(e as BringgException).details as string
					);
				}
			}
		};
		Modal.confirm(confirmPayload);
	};

	isAllowedToDeleteTemplates = () => {
		const { isSelectedTemplatesNew, isDefaultLanguage } = this.props.merchantCustomerNotificationsStore;
		return !isDefaultLanguage && !isSelectedTemplatesNew;
	};

	isAllowedToSaveTemplates = () => {
		const { selectedLanguageTemplates, customerConfiguration } = this.props.merchantCustomerNotificationsStore;
		return every(selectedLanguageTemplates, template => {
			return !customerConfiguration[template.notification_type] || template.template.length > 0;
		});
	};

	render() {
		const { dictionary, merchantCustomerNotificationsStore } = this.props;
		const {
			customerConfiguration,
			merchantConfiguration,
			selectedLanguageTemplates,
			selectedLanguageCode,
			defaultLanguageCode,
			availableLanguages,
			selectLanguageCode
		} = merchantCustomerNotificationsStore;
		return (
			<div className="notification-templates-container">
				<SharingMode configuration={merchantConfiguration} />
				<LanguageSelect
					selectedLanguageCode={selectedLanguageCode}
					defaultLanguageCode={defaultLanguageCode}
					availableLanguages={availableLanguages}
					selectedLanguageCodeFunc={selectLanguageCode}
				/>
				<CustomerNotificationSettings customerConfiguration={customerConfiguration} />
				<NotificationTemplateList
					notificationTemplates={selectedLanguageTemplates}
					dictionary={dictionary}
					customerConfiguration={customerConfiguration}
					enableFreeSmsMode={merchantConfiguration.enable_free_sms_mode}
				/>
				<Button className="update-button" htmlType="submit" onClick={this.updateConfigurations}>
					<Translate text="CUSTOMER_CONFIGURATION_VIEW.UPDATE_BUTTON" />
				</Button>
				{this.isAllowedToDeleteTemplates() && (
					<Button
						className="delete-button"
						danger
						type="primary"
						htmlType="submit"
						onClick={this.deleteLanguageTemplates}
					>
						<Translate text="CUSTOMER_CONFIGURATION_VIEW.CLEAR_BUTTON" />
					</Button>
				)}
			</div>
		);
	}
}

export default withTranslation()(
	inject('merchantCustomerNotificationsStore')(observer(CustomerNotificationsContainer))
);
