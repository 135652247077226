import React, { memo, useCallback, useState, useRef, useEffect } from 'react';

import { Button, BringgInput, Tooltip } from '@bringg/react-components';
import { BringgFontIcons, BringgIcon } from '@bringg/bringg-icons';
import classNames from 'classnames';
import { DropTargetMonitor, useDrag, useDrop, XYCoord } from 'react-dnd';
import { useTranslation } from 'react-i18next';

import './key-value-table.scss';

export interface KeyValueDefinition {
	key: string;
	value: string;
	name: string;
}

interface Props {
	definition: KeyValueDefinition;
	index: number;
	onDefinitionChange: (index: number, definition: KeyValueDefinition) => void;
	onDelete: (index: number) => void;
	moveItem: (dragIndex: number, hoverIndex: number) => void;
	onDropItem?: () => void;
}

function DefinitionRow({ definition, index, onDefinitionChange, onDelete, moveItem, onDropItem }: Props) {
	const { t } = useTranslation();

	const [name, setName] = useState(definition.name);
	const [value, setValue] = useState(definition.value);

	const handleDefinitionValueChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
		setValue(event.target.value);
	}, []);

	const handleDefinitionNameChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
		setName(event.target.value);
	}, []);

	useEffect(() => {
		onDefinitionChange(index, { ...definition, name, value });
	}, [name, value]);

	const ref = useRef<HTMLDivElement>(null);
	const [{ handlerId }, drop] = useDrop({
		accept: 'item',
		collect(monitor) {
			return {
				handlerId: monitor.getHandlerId()
			};
		},
		hover(item: any, monitor: DropTargetMonitor) {
			if (!ref.current) {
				return;
			}

			const dragIndex = item.index;
			const hoverIndex = index;

			// Don't replace items with themselves
			if (dragIndex === hoverIndex) {
				return;
			}

			// Determine rectangle on screen
			const hoverBoundingRect = ref.current?.getBoundingClientRect();

			// Get vertical middle
			// eslint-disable-next-line @typescript-eslint/no-magic-numbers
			const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

			// Determine mouse position
			const clientOffset = monitor.getClientOffset();

			// Get pixels to the top
			const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

			// Only perform the move when the mouse has crossed half of the items height
			// When dragging downwards, only move when the cursor is below 50%
			// When dragging upwards, only move when the cursor is above 50%

			// Dragging downwards
			if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
				return;
			}

			// Dragging upwards
			if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
				return;
			}

			// Time to actually perform the action
			moveItem(dragIndex, hoverIndex);

			// Note: we're mutating the monitor item here!
			// Generally it's better to avoid mutations,
			// but it's good here for the sake of performance
			// to avoid expensive index searches.
			Object.assign(item, { index: hoverIndex });
		}
	});

	const [{ isDragging }, drag] = useDrag({
		type: 'item',
		item: () => ({ value, index }),
		collect: monitor => ({
			isDragging: monitor.isDragging()
		}),
		// Moved drop handler from useDrop here to update state upon drop out of drop container
		end: onDropItem
	});

	drag(drop(ref));

	return (
		<div
			ref={ref}
			role="listitem"
			data-handler-id={handlerId}
			className={classNames('enum-definition-list-item', { dragging: isDragging })}
		>
			<span className="value-col">
				<BringgIcon iconName={BringgFontIcons.Drag} />
				<BringgInput
					autoFocus={!definition.name && !definition.value}
					value={value}
					maxLength={64}
					placeholder={t('CUSTOM_ATTRIBUTES.ENUM_DEFINITION.VALUE_PLACEHOLDER')}
					onChange={handleDefinitionValueChange}
				/>
			</span>
			<span className="name-col">
				<BringgInput
					value={name}
					maxLength={256}
					placeholder={t('CUSTOM_ATTRIBUTES.ENUM_DEFINITION.NAME_PLACEHOLDER')}
					onChange={handleDefinitionNameChange}
				/>
			</span>
			<Tooltip overlayClassName="remove-enum-item-popover" title={t('GLOBAL.DELETE')} placement="top">
				<Button
					tabIndex={-1}
					type="link"
					shape="circle"
					className="remove-button"
					icon={<BringgIcon iconName={BringgFontIcons.Trash} />}
					onClick={() => onDelete(index)}
				/>
			</Tooltip>
		</div>
	);
}

export default memo(DefinitionRow);
