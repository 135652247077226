import { getRootEnv } from '@bringg-frontend/bringg-web-infra';
import { action, computed, observable, makeObservable } from 'mobx';
import { getEnv } from 'mobx-easy';
import i18next from 'i18next';
import { BringgException } from '@bringg/dashboard-sdk/dist/Core/BringgException';
import { RootEnv } from '@bringg-frontend/bringg-web-infra';

import Company from './domain-objects/company';
import notification from '../../services/notification';

class CompaniesStore {
	companies: Map<number, Company> = new Map();
	companyUsers: Map<number, Partial<Bringg.User>[]> = new Map();
	isFetched = false;

	constructor() {
		makeObservable(this, {
			companies: observable.shallow,
			companyUsers: observable.shallow,
			isFetched: observable,
			setFetched: action,
			add: action,
			addCompanyUsers: action,
			getAll: computed,
			fetchAll: action
		});
	}

	setFetched() {
		this.isFetched = true;
	}

	add = (company: Bringg.Company) => {
		this.companies.set(company.id, new Company(company, this));
	};

	addCompanyUsers = (companyId: number, users: Partial<Bringg.User>[]) => {
		this.companyUsers.set(companyId, users);
	};

	get getAll(): Company[] {
		return Array.from(this.companies.values());
	}

	get = (id: number) => {
		return this.companies.get(id);
	};

	fetch = async (id: number) => {
		if (this.companies.get(id)) {
			return;
		}

		try {
			const result = await getRootEnv().dashboardSdk.sdk.companies.get(id);

			this.add(result.company);
		} catch (e) {
			notification.error(i18next.t('COMPANIES.FAILED_TO_GET_COMPANY'), (e as BringgException).details as string);
		}
	};

	fetchUsers = async (id: number) => {
		try {
			const result = await getRootEnv().dashboardSdk.sdk.companies.getUsers(id);

			this.addCompanyUsers(id, result.users as Partial<Bringg.User>[]);
		} catch (e) {
			notification.error(
				i18next.t('COMPANIES.FAILED_TO_GET_COMPANY_USERS'),
				(e as BringgException).details as string
			);
		}
	};

	create = async (company: Bringg.Company) => {
		try {
			const result = await getRootEnv().dashboardSdk.sdk.companies.create(company);

			this.add(result.company);

			notification.success(i18next.t('COMPANIES.CREATE_SUCCESS'));
		} catch (e) {
			notification.error(i18next.t('COMPANIES.CREATE_FAILED'), (e as BringgException).details as string);
		}
	};

	fetchAll = async () => {
		if (!this.isFetched) {
			const result = await getRootEnv().dashboardSdk.sdk.companies.getAll({});

			result.companies.map(this.add);

			this.setFetched();
		}
	};
}

export default CompaniesStore;
