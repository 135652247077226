import { getSharedRootStore, hasFeatureFlag } from '@bringg-frontend/global-stores';

export const useHasFeatureFlag = (featureFlag: string, team?: Bringg.Team) => {
	return hasFeatureFlag(getSharedRootStore().data.usersStore.currentUser, featureFlag, team);
};

export const useGetFeatureFlag = (featureFlag: string) => {
	const currentUser = getSharedRootStore().data.usersStore.currentUser;

	return currentUser && currentUser.feature_flags?.[featureFlag];
};
