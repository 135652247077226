import React from 'react';
import { CapacityMetric, Modal, UserInfoList, UsersAutoComplete } from '@bringg/react-components';
import _reject from 'lodash/reject';
import _find from 'lodash/find';
import _toNumber from 'lodash/toNumber';
import _isNumber from 'lodash/isNumber';
import { User } from '@bringg/types';
import i18next from 'i18next';
import { Translate } from '../../../../translation';
import Driver from '../../../../stores/drivers/domain-object/driver';
import { DeliveryBlockModalViewMode } from '../delivery-block-modal';

interface Props {
	selectedDriversIds: number[];
	drivers: Driver[];
	onDriversRemove: (id: number) => void;
	onDriverSelect: (id: number) => void;
	deliveryBlockOriginalCapacity?: number;
	viewMode?: DeliveryBlockModalViewMode;
	deliveryBlockStartTime: string;
	deliveryBlockEndTime: string;
	deliveryBlockId: number;
}

interface State {
	searchValue: string;
	drivers: Driver[];
	unavailableDriverIdToSelect: number;
	isModalOpen: boolean;
}

class DeliveryBlocksDriversPanel extends React.Component<Props, State> {
	state = {
		searchValue: '',
		drivers: this.props.drivers
			? this.props.drivers.filter(driver => !this.props.selectedDriversIds.includes(driver.id))
			: [],
		unavailableDriverIdToSelect: null,
		isModalOpen: false
	};

	toggleModal = () => this.setState(({ isModalOpen }) => ({ isModalOpen: !isModalOpen }));

	handleSearch = searchValue => {
		const { drivers, selectedDriversIds } = this.props;
		this.setState({ searchValue }, () => {
			this.setState({
				drivers: drivers.filter(
					({ id, name }) =>
						name.toLowerCase().includes(searchValue.toLowerCase()) && !selectedDriversIds.includes(id)
				)
			});
		});
	};

	handleModalUnavailableDriverAssign = () => {
		const { unavailableDriverIdToSelect } = this.state;
		this.onSelect(unavailableDriverIdToSelect, true);
		this.toggleModal();
		this.setState({ unavailableDriverIdToSelect: null });
	};

	handleModalCancel = () => {
		this.toggleModal();
		this.setState({ unavailableDriverIdToSelect: null });
	};

	onSelect = (id, confirmed = false) => {
		const { onDriverSelect, deliveryBlockStartTime, deliveryBlockEndTime, deliveryBlockId } = this.props;
		const { drivers } = this.state;

		const driver = _find(drivers, { id: _toNumber(id) });

		const blocksToIgnore = _isNumber(deliveryBlockId) ? [deliveryBlockId] : [];

		if (
			!driver?.isAvailableAtRequestedBlock(deliveryBlockStartTime, deliveryBlockEndTime, blocksToIgnore) &&
			!confirmed
		) {
			this.setState({ unavailableDriverIdToSelect: id });
			this.toggleModal();
			return;
		}

		this.setState(prevState => ({
			searchValue: prevState.searchValue,
			drivers: _reject(prevState.drivers, { id: _toNumber(id) })
		}));

		onDriverSelect(_toNumber(id));
	};

	onRemove = id => {
		const { drivers, onDriversRemove } = this.props;
		this.setState(prevState => ({
			drivers: [...prevState.drivers, _find(drivers, { id: _toNumber(id) })]
		}));

		onDriversRemove(id);
	};

	onFocus = () => {
		this.handleSearch('');
	};

	getSelectedDrivers = () => {
		const { deliveryBlockStartTime, deliveryBlockEndTime, deliveryBlockId } = this.props;
		const blocksToIgnore = _isNumber(deliveryBlockId) ? [deliveryBlockId] : [];

		return this.props.drivers
			.filter(({ id }) => this.props.selectedDriversIds.includes(_toNumber(id)))
			.map(driver =>
				this.getDriverWithAvailability(driver, deliveryBlockStartTime, deliveryBlockEndTime, blocksToIgnore)
			);
	};

	getDriversOptions = () => {
		const { deliveryBlockStartTime, deliveryBlockEndTime, deliveryBlockId } = this.props;
		const blocksToIgnore = _isNumber(deliveryBlockId) ? [deliveryBlockId] : [];

		return this.state.drivers.map(driver =>
			this.getDriverWithAvailability(driver, deliveryBlockStartTime, deliveryBlockEndTime, blocksToIgnore)
		);
	};

	getDriverWithAvailability = (
		driver: Driver,
		deliveryBlockStartTime: string,
		deliveryBlockEndTime: string,
		blocksToIgnore: number[]
	) => {
		const isAvailable = driver.isAvailableAtRequestedBlock(
			deliveryBlockStartTime,
			deliveryBlockEndTime,
			blocksToIgnore
		);

		return isAvailable ? driver : { ...driver, name: `${driver.name} (${i18next.t('GLOBAL.NA')})` };
	};

	disableUserSelect = () => this.props.selectedDriversIds.length >= this.props.deliveryBlockOriginalCapacity;

	onUserClickHandler = (user: User) => {
		window.open(`/#/drivers/${user.id}`, '_blank', 'noopener noreferrer');
	};

	render() {
		const {
			handleSearch,
			onSelect,
			onRemove,
			onFocus,
			getSelectedDrivers,
			getDriversOptions,
			disableUserSelect,
			onUserClickHandler,
			handleModalUnavailableDriverAssign,
			handleModalCancel
		} = this;
		const { deliveryBlockOriginalCapacity, viewMode, selectedDriversIds } = this.props;
		const { searchValue, isModalOpen } = this.state;
		return (
			<div className="delivery-blocks-drivers-panel">
				<CapacityMetric capacity={selectedDriversIds.length} maxCapacity={deliveryBlockOriginalCapacity} />
				{(viewMode === DeliveryBlockModalViewMode.CREATE || viewMode === DeliveryBlockModalViewMode.EDIT) && (
					<UsersAutoComplete
						className="drivers-panel-select"
						disabled={disableUserSelect()}
						onFocus={onFocus}
						placeholder={<Translate text="DELIVERY_BLOCKS.SELECT_USERS" />}
						onSearch={handleSearch}
						onSelect={id => onSelect(id)}
						showArrow={false}
						value={searchValue}
						users={getDriversOptions()}
					/>
				)}

				<UserInfoList
					height={435}
					users={getSelectedDrivers()}
					onRemove={onRemove}
					displayRemoveButton={viewMode !== DeliveryBlockModalViewMode.VIEW}
					onUserClick={onUserClickHandler}
				/>

				<Modal
					title={i18next.t('GLOBAL.UNAVAILABLE_DRIVERS')}
					visible={isModalOpen}
					onCancel={handleModalCancel}
					onOk={handleModalUnavailableDriverAssign}
					okText={i18next.t('GLOBAL.PROCEED')}
					cancelText={i18next.t('GLOBAL.CANCEL')}
					style={{ marginTop: 200 }}
				>
					<Translate text="DELIVERY_BLOCKS.DRIVER_NOT_AVAILABLE_MESSAGE" />
				</Modal>
			</div>
		);
	}
}

export default DeliveryBlocksDriversPanel;
