import React from 'react';
import MetricUnit from './metric-unit';
import { Select } from 'antd';
import { VrpAutoDispatchAnalytic } from '../../stores/vrp-auto-dispatch-analytics/vrp-auto-dispatch-analytics-store';
import _last from 'lodash/last';
import { Translate } from '../../translation';
import i18next from 'i18next';
import _isEmpty from 'lodash/isEmpty';

const { Option } = Select;

interface Props {
	analytics: VrpAutoDispatchAnalytic[];
	lastUpdated: Date;
	onAggregationTimeSelect: (value: number) => void;
}

interface State {
	lastUpdatedText: string;
}

export const LAST_UPDATED_INTERVAL = 60 * 1000;
export const AnalyticsTimeOptions = [1, 2, 3]; // hours;

class VrpAutoDispatchAnalytics extends React.Component<Props, State> {
	dateIntervalTimerId: number;

	state = {
		lastUpdatedText: ''
	};

	componentDidMount() {
		this.updateLastUpdatedText();
		// @ts-ignore
		this.dateIntervalTimerId = setInterval(this.updateLastUpdatedText, LAST_UPDATED_INTERVAL);
	}

	updateLastUpdatedText = () => {
		this.setState({ lastUpdatedText: this.calcLastUpdatedText() });
	};

	calcLastUpdatedText = () => {
		const { lastUpdated } = this.props;
		const diff = new Date().getTime() - lastUpdated.getTime();
		const minutes = Math.round(diff / (60 * 1000));
		return this.getLastUpdatedText(minutes);
	};

	getLastUpdatedText = minutes => {
		return minutes !== 0
			? `${i18next.t('VRP_AD.ANALYTICS.UPDATED')} ${minutes} ${i18next.t('VRP_AD.ANALYTICS.MINUTES_AGO')}`
			: `${i18next.t('VRP_AD.ANALYTICS.UPDATED')} ${i18next.t('VRP_AD.ANALYTICS.NOW')}`;
	};

	getAnalyticsTimesOptions = () => {
		return AnalyticsTimeOptions.map(value => (
			<Option key={value.toString()} value={value}>
				{value} hours
			</Option>
		));
	};

	componentWillUnmount() {
		clearInterval(this.dateIntervalTimerId);
	}

	render() {
		const { getAnalyticsTimesOptions } = this;
		const { lastUpdatedText } = this.state;
		const { analytics, onAggregationTimeSelect } = this.props;
		const [autoAssigned, manualAssigned] = !_isEmpty(analytics) ? analytics : [null, null];
		return (
			<div className="vrp-auto-dispatch-analytics">
				<div className="vrp-auto-dispatch-analytics-hours-select">
					<p>
						<Translate text={'VRP_AD.ANALYTICS.SELECT'} />
					</p>
					<Select defaultValue={_last(AnalyticsTimeOptions)} onChange={onAggregationTimeSelect}>
						{getAnalyticsTimesOptions()}
					</Select>
				</div>
				<div className="vrp-auto-dispatch-analytics-metrics">
					{autoAssigned && manualAssigned ? (
						<>
							<MetricUnit
								className="left-metric-unit"
								title={'VRP_AD.ANALYTICS.AUTO_ASSIGNED'}
								assignedCount={autoAssigned.assigned}
								assignedPercentage={autoAssigned.assignedPercentage}
								deliveredOnTimePercentage={autoAssigned.deliveredPercentage}
								avgTimeAtDoor={autoAssigned.avgTimeAtDoor}
							/>
							<MetricUnit
								className="right-metric-unit"
								title={'VRP_AD.ANALYTICS.MANUAL_ASSIGNED'}
								assignedCount={manualAssigned.assigned}
								assignedPercentage={manualAssigned.assignedPercentage}
								deliveredOnTimePercentage={manualAssigned.deliveredPercentage}
								avgTimeAtDoor={manualAssigned.avgTimeAtDoor}
							/>
						</>
					) : (
						<div className="no-statistics-available">
							<Translate text={'VRP_AD.ANALYTICS.NO_STATISTICS'} />
						</div>
					)}
				</div>
				<div className="vrp-auto-dispatch-analytics-last-updated">{lastUpdatedText}</div>
			</div>
		);
	}
}

export default VrpAutoDispatchAnalytics;
