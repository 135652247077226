export enum WIDGET_TYPES {
	TABLE = 1,
	COUNTER = 2,
	GAUGE = 3,
	LINE = 4,
	BARS = 5,
	PIE = 6,
	VERTICAL_TABLE = 7,
	REFLECTING_GAUGE = 8,
	PREPARATION_STATUS = 12
}

export const POLLING_TIMEOUT = 5 * 60 * 1000;
