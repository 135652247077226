import { getRootEnv } from '@bringg-frontend/bringg-web-infra';
import { action, computed, observable, makeObservable } from 'mobx';
import { getRoot, getEnv } from 'mobx-easy';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import _noop from 'lodash/noop';
import some from 'lodash/some';
import orderBy from 'lodash/orderBy';
import isNil from 'lodash/isNil';
import isNumber from 'lodash/isNumber';
import isUndefined from 'lodash/isUndefined';
import { RootEnv } from '@bringg-frontend/bringg-web-infra';

import CustomerNotificationsStore from './customer-notifications-store';
import { EDITABLE_NOTIFICATION_TYPES } from '../../features/customer-notifications/notification-template/notification-template.consts';
import { registerAction } from '../../services/cross-application/cross-application';
import RootStore from '../root-store';

class TagCustomerNotificationsStore extends CustomerNotificationsStore {
	loading = true;
	selectedTagId: number = null;
	unRegister: () => void = _noop;

	constructor() {
		super();

		makeObservable(this, {
			loading: observable,
			selectedTagId: observable,
			setLoading: action,
			setSelectedTagId: action,
			isLoading: computed,
			tagId: computed,
			selectedTagCustomerConfiguration: computed,
			selectedTagMerchantConfiguration: computed,
			selectedLanguageTemplates: computed,
			availableLanguages: computed
		});
	}

	registerTagSelectedAction() {
		this.unRegister = registerAction(
			'TAG_SELECTED',
			async (tagId: number) => this.handleTagChanged(tagId),
			getRootEnv().dashboardSdk.sdk.crossAppTransport
		);
	}

	setLoading = (loading: boolean) => {
		this.loading = loading;
	};

	setSelectedTagId = (tagId: number) => {
		this.selectedTagId = tagId;
	};

	get isLoading(): boolean {
		return this.loading;
	}

	get tagId(): number {
		return this.selectedTagId;
	}

	get selectedTagCustomerConfiguration() {
		return getRoot<RootStore>().data.tagCustomerConfigurationsStore.getByTag(this.selectedTagId);
	}

	get selectedTagMerchantConfiguration() {
		return getRoot<RootStore>().data.tagMerchantConfigurationsStore.getByTag(this.selectedTagId);
	}

	get selectedLanguageTemplates() {
		return getRoot<RootStore>().data.notificationTemplatesStore.getByLanguage(
			this.selectedLanguageCode,
			this.tagId
		);
	}

	get availableLanguages(): any {
		const { notificationTemplatesStore } = getRoot<RootStore>().data;
		const { availableLanguages } = notificationTemplatesStore;
		const languages = availableLanguages.map(language => {
			const templates = notificationTemplatesStore.getByLanguage(language.code, this.tagId);
			const hasTemplates = !isEmpty(templates) && some(templates, ({ id }) => isNumber(id));
			return { ...language, hasTemplates };
		});
		return orderBy(languages, ['hasTemplates', 'name'], 'desc');
	}

	handleTagChanged = async (tagId: number) => {
		const { tagMerchantConfigurationsStore, tagCustomerConfigurationsStore } = getRoot<RootStore>().data;
		this.setLoading(true);
		await this.fetchOrInitConfiguration(tagMerchantConfigurationsStore, tagId);
		await this.fetchOrInitConfiguration(tagCustomerConfigurationsStore, tagId);
		this.setSelectedTagId(tagId);
		this.selectLanguageCode(this.selectedLanguageCode);
		this.setLoading(false);
	};

	selectLanguageCode = (code: string) => {
		this.updateLanguageCode(code);
		this.fillMissingTemplates(code);
	};

	initConfigurations = async () => {
		await Promise.all([
			getRoot<RootStore>().data.notificationTemplatesStore.fetchAll(),
			getRoot<RootStore>().data.merchantConfigurationsStore.fetch()
		]);

		this.selectLanguageCode(this.defaultLanguageCode);
		this.setFetched();
	};

	fillMissingTemplates = (language: string) => {
		const { notificationTemplatesStore } = getRoot<RootStore>().data;
		EDITABLE_NOTIFICATION_TYPES.forEach(templateType => {
			const template = {
				language,
				notification_type: templateType,
				tag_id: this.selectedTagId
			};
			if (isUndefined(notificationTemplatesStore.get(template))) {
				notificationTemplatesStore.set(template);
			}
		});
	};

	updateConfigurations = async () => {
		const promises = [];

		promises.push(this.selectedTagCustomerConfiguration.upsert());
		promises.push(this.selectedTagMerchantConfiguration.upsert());
		promises.push(this.installRequiredApplications(this.selectedTagCustomerConfiguration));

		map(this.selectedLanguageTemplates, template => {
			if (template.template.length > 0) {
				promises.push(getRoot<RootStore>().data.notificationTemplatesStore.update(template));
			}
		});

		return Promise.all(promises);
	};

	deleteLanguageTemplates = async () => {
		const promises = map(this.selectedLanguageTemplates, async template =>
			getRoot<RootStore>().data.notificationTemplatesStore.delete(template)
		);

		await Promise.all(promises);
		this.updateLanguageCode(this.defaultLanguageCode);
	};

	async fetchOrInitConfiguration(configurationsStore, tagId: number) {
		const localConfiguration = configurationsStore.getByTag(tagId);
		if (localConfiguration && localConfiguration.id) {
			return;
		}
		const configuration = await configurationsStore.fetch(tagId);
		if (isNil(configuration) && isNil(localConfiguration)) {
			configurationsStore.set({ tag_id: tagId });
		}
	}
}

export default TagCustomerNotificationsStore;
