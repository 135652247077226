import { DraggableLocation } from 'react-beautiful-dnd';

import { DraggableItem, UserDraggableItem } from '../types';

export const reorder = (list: UserDraggableItem[], startIndex: number, endIndex: number) => {
	const result = Array.from(list);
	const [removed] = result.splice(startIndex, 1);
	result.splice(endIndex, 0, removed);

	return result;
};

export const move = ({
	draggableId,
	sourceList,
	destinationList,
	droppableSource,
	droppableDestination,
	withFadeIn
}: {
	draggableId: string;
	sourceList: UserDraggableItem[];
	destinationList: UserDraggableItem[];
	droppableSource: DraggableLocation;
	droppableDestination: DraggableLocation;
	withFadeIn?: boolean;
}) => {
	const sourceClone = Array.from(sourceList);
	const destClone = Array.from(destinationList);
	const removedIndex = sourceClone.findIndex(item => item.id === draggableId);

	const [removed] = sourceClone.splice(removedIndex, 1);

	destClone.splice(droppableDestination.index, 0, {
		...removed,
		withFadeIn
	});

	return {
		[droppableSource.droppableId]: sourceClone,
		[droppableDestination.droppableId]: destClone
	};
};

export const checkDirty = (selectedList: UserDraggableItem[], selectedValues: DraggableItem[]) => {
	return (
		selectedList.length !== selectedValues.length ||
		selectedList.some((item, index) => item.id !== selectedValues[index].id)
	);
};

export const mapListForApply = (list: UserDraggableItem[]): DraggableItem[] =>
	list.map(({ id, title, required }) => ({ id, title, required }));
