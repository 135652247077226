export const requiredRule = { required: true, message: 'Value is required' };

export const minLength = (minLength: number): any => {
	return { min: minLength, message: `Minimum length is ${minLength}` };
};

export const maxLength = (maxLength: number): any => {
	return { max: maxLength, message: `Maximum length is ${maxLength}` };
};

export const validateType = (type: 'string' | 'number' | 'boolean' | 'integer' | 'date' | 'email'): any => {
	return { type: type, message: `Value must be ${type}` };
};

export const regexRule = (pattern: RegExp) => {
	return { pattern, message: `Value does not match pattern` };
};
