import { Button, TimePicker } from '@bringg/react-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { DELIVERY_BLOCK_TIME_FORMAT } from 'bringg-web/features/delivery-blocks-v2/modal/form/delivery-block-form';
import { DeliveryBlockRecharge } from '@bringg/types';
import { BringgFontIcons, BringgIcon } from '@bringg/bringg-icons';
import moment from 'moment-timezone';
import { Moment } from 'moment/moment';
import './recharge-item.scss';

interface Props {
	deliveryBlockRecharge: DeliveryBlockRecharge;
	rechargeDuration: number;
	use12Hours: boolean;
	timezone: string;
	onStartTimeChange: (moment: Moment, date: string) => void;
	onRemove: () => void;
	isStartTimeValid: { result: boolean; errorMsg?: string };
}

const RechargeItem = ({
	deliveryBlockRecharge,
	rechargeDuration,
	use12Hours,
	timezone,
	onStartTimeChange,
	onRemove,
	isStartTimeValid
}: Props) => {
	const { t } = useTranslation();

	return (
		<div className="delivery-block-recharge-time-and-location-container">
			<div className="delivery-block-recharge-time-and-location">
				<span className="recharge-duration-text">
					{t('DELIVERY_BLOCK_MODAL.RELOAD.RELOAD_DURATION', { duration_time: rechargeDuration })}
				</span>
				<TimePicker
					onChange={onStartTimeChange}
					use12Hours={use12Hours}
					allowClear={false}
					format={DELIVERY_BLOCK_TIME_FORMAT}
					showNow={false}
					value={moment(deliveryBlockRecharge.start_time).tz(timezone)}
				/>
				<span className="recharge-location-text">{t('DELIVERY_BLOCK_MODAL.RELOAD.AT_THE')}</span>
				<Button
					data-test-id="recharge-trash-icon"
					className="recharge-trash-icon-button"
					type="link"
					icon={<BringgIcon className="recharge-trash-icon" iconName={BringgFontIcons.Trash} />}
					onClick={onRemove}
				/>
			</div>
			{!isStartTimeValid.result && (
				<div className="delivery-block-recharge-error">
					<BringgIcon iconName={BringgFontIcons.Warning} />
					{t(isStartTimeValid.errorMsg)}
				</div>
			)}
		</div>
	);
};

export default RechargeItem;
