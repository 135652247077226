import { TFunction } from 'i18next';

export function getValidateMessages(t: TFunction) {
	return {
		required: `\${label} ${t('CREATE_USER_MODAL.REQUIRED')}`,
		types: {
			email: `\${label} ${t('CREATE_USER_MODAL.EMAIL_INVALID')}`,
			number: `\${label} ${t('CREATE_USER_MODAL.NUMBER_INVALID')}`
		},
		string: {
			range: `\${label} ${t('CREATE_USER_MODAL.STRING_RANGE_INVALID')}`
		},
		number: {
			range: `\${label} ${t('CREATE_USER_MODAL.NUMBER_RANGE_INVALID')}`
		}
	};
}
