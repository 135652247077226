import { ComponentProps } from 'react';
import { Select } from '@bringg/react-components';

type Props = Omit<ComponentProps<typeof Select>, 'getPopupContainer'>;

const getPopupContainer = triggerNode => triggerNode.closest('.select-wrapper');

const SelectWithStickyPopup = (props: Props) => <Select {...props} getPopupContainer={getPopupContainer} />;

export default SelectWithStickyPopup;
