import { getEnv } from 'mobx-easy';
import { v4 as uuid } from 'uuid';
import {
	OptimizationInProgressRealTime,
	OptimizationResponseFailed,
	RouteOptimizationResult
} from '@bringg/dashboard-sdk/dist/RouteOptimization/RouteOptimization.consts';
import { OPTIMIZATION_TYPE } from '@bringg/types';
import { RootEnv } from '@bringg-frontend/bringg-web-infra';

import { ResourceWithBlockData } from './types';

export const USE_OWN_FLEET_OPTIMIZATION_SERVICE_ENDPOINTS = 'use_own_fleet_optimization_service_endpoints';

export interface GetOptimizationRequestProps {
	teamId: string;
	selectedResourcesIds: number[];
	onOptimizationStart?: (uuid: string) => any;
	onOptimizationDone: (routeOptimizationResult: RouteOptimizationResult | OptimizationResponseFailed) => any;
	onOptimizationProgressUpdate: (progressData: OptimizationInProgressRealTime) => any;
	isOptimizeNewAndSaveExisting: boolean;
	tasksIds: number[];
	startTime: string;
	endTime: string;
	resourcesList?: ResourceWithBlockData[];
}

export const getOptimizationRequest = async ({
	teamId,
	selectedResourcesIds,
	onOptimizationStart,
	onOptimizationProgressUpdate,
	onOptimizationDone,
	isOptimizeNewAndSaveExisting,
	tasksIds,
	startTime,
	endTime,
	resourcesList
}: GetOptimizationRequestProps) => {
	const { routeOptimizationApi, routeOptimizationService } = getEnv<RootEnv>().dashboardSdk.sdk;

	const requestUuid = uuid();
	onOptimizationStart?.(requestUuid);

	routeOptimizationService.registerToOptimization(requestUuid, {
		onDone: onOptimizationDone,
		onFailed: onOptimizationDone,
		onInProgress: onOptimizationProgressUpdate
	});

	let updateResourcesIds;
	if (isOptimizeNewAndSaveExisting) {
		updateResourcesIds = selectedResourcesIds.map(resourceId => {
			if (resourceId < 0) {
				const selectedResource = resourcesList?.find(resource => resource.id === resourceId);
				return selectedResource.originalResource.id;
			}
			return resourceId;
		});
	}

	return routeOptimizationApi.optimize({
		optimize_for: 'custom',
		optimization_type: OPTIMIZATION_TYPE.COMBINED,
		request_uuid: requestUuid,
		task_ids: tasksIds,
		preserve_current_runs: isOptimizeNewAndSaveExisting,
		team_id: parseInt(teamId, 10),
		scheduled_window: {
			start_time: startTime,
			end_time: endTime
		},
		delivery_block_resource_ids: updateResourcesIds || selectedResourcesIds
	});
};

export const applyOptimizationResults = async (
	optimizationResults: Array<RouteOptimizationResult | OptimizationResponseFailed>
) => {
	const { routeOptimizationApi } = getEnv<RootEnv>().dashboardSdk.sdk;
	const optimizationResultsToAssign = optimizationResults
		.filter(result => !(result as OptimizationResponseFailed).error)
		.map(({ team_id, request_uuid }) => ({ team_id, request_uuid }));

	await routeOptimizationApi.assignUsersByIds(optimizationResultsToAssign);
};
