import { computed, makeObservable, override, action, observable } from 'mobx';
import { ActionCoreData, ActionType, CustomWebhookActionMetadata, CustomWebhookData, ModelConfig } from '@bringg/types';
import i18next from 'i18next';

import { ActionsRepo, workflowsRootStore } from './internal';
import ActionBaseStore from './action-base-store';
import { ActionFamilyType, ClientCustomWebhook } from '../utils/types';
import { webhookModelProvider } from 'bringg-web/features/automation-workflows/actions/webhook/webhook-model-provider';

class CustomWebhookActionStore extends ActionBaseStore {
	type = ActionFamilyType.CUSTOM_WEBHOOK;
	actionType: ActionType;
	urls: string[];
	authenticationMethodId: number;
	modelConfig: ModelConfig;

	constructor(actionToInit: ClientCustomWebhook, actionsRepo: ActionsRepo) {
		super(actionsRepo);
		makeObservable(this, {
			urls: observable,
			authenticationMethodId: observable,
			modelConfig: observable,
			actionData: override,
			title: computed,
			url: computed,
			isValid: computed,
			setUrl: action,
			updateModelConfig: action,
			setAuthenticationMethod: action
		});

		this.actionType = actionToInit.action_type;
		this.urls = actionToInit.data?.urls || [];
		this.authenticationMethodId = actionToInit?.data?.webhook_authentication_configuration_id;

		this.initModelConfig(actionToInit.data?.model_config);
	}

	initModelConfig = async (modelConfig: ModelConfig) => {
		if (modelConfig) {
			this.modelConfig = modelConfig;
			return;
		}

		await webhookModelProvider.loadAll();
		this.modelConfig = webhookModelProvider.getModelConfigByModel(this.actionData?.base_model);
	};

	get actionData(): CustomWebhookActionMetadata {
		const { metadataRepo } = workflowsRootStore.getStore();

		const actionDataByActionType = Object.values(metadataRepo.metadata.metadata.actions).find(_actionData => {
			return (_actionData as CustomWebhookActionMetadata).action_type === this.actionType;
		});

		return actionDataByActionType as CustomWebhookActionMetadata;
	}

	get mappedToServer(): ActionCoreData {
		const data: CustomWebhookData = {
			urls: this.urls,
			model_config: this.modelConfig
		};

		if (this.authenticationMethodId) {
			data.webhook_authentication_configuration_id = this.authenticationMethodId;
		}

		return {
			action_type: this.actionType,
			data
		} as ActionCoreData;
	}

	getSelectedFromModelConfig = (model: ModelConfig, fields): string[] => {
		fields.push(...(model.fields || []));

		model.relations?.forEach(relation => {
			const innerFields = this.getSelectedFromModelConfig(relation, fields);
			fields.push(...innerFields);
		});

		return fields;
	};

	get isValid() {
		let hasSelectedFields;
		const treeDataNotSet = this.modelConfig && !webhookModelProvider.getSelectedFields().length;

		if (treeDataNotSet) {
			hasSelectedFields = this.getSelectedFromModelConfig(this.modelConfig, []).length;
		} else {
			hasSelectedFields = webhookModelProvider.getSelectedFields().length;
		}

		return /^http.*$/.test(this.url) && this.modelConfig && hasSelectedFields;
	}

	get title(): string {
		const titleKey = this.actionData.translation_string;

		return i18next.t(titleKey);
	}

	get url(): string {
		return this.urls[0];
	}

	setUrl = (url: string) => {
		this.urls[0] = url;
	};

	setAuthenticationMethod = (methodId: number) => {
		this.authenticationMethodId = methodId;
	};

	updateModelConfig = (keys: string[]) => {
		this.modelConfig = webhookModelProvider.buildModelConfigFromTree(this.actionData.base_model, keys);
	};
}

export default CustomWebhookActionStore;
