import React, { FunctionComponent } from 'react';
import { useObserver } from 'mobx-react';
import TableWrapper from '@bringg/react-components/dist/components/table/table';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import Translate from '../../../translation/translator';
import Skill from '../../../stores/skills/domain-object/skill';
import notification from '@bringg/react-components/dist/components/notification/notification';

const getColumns = (t: TFunction) => {
	return [
		{
			title: t('SKILLS.NAME'),
			dataIndex: 'name',
			defaultSortOrder: 'descend' as any
		},
		{
			title: '',
			dataIndex: '',
			render: (_, record: Skill) => {
				const onDelete = async e => {
					const skillName = record.name;

					try {
						await record.delete();

						notification.success(t('SKILLS.DELETED_SUCCESSFULLY'), skillName);
					} catch (e) {
						console.error(`Failed to delete skills`, e);
						notification.error(`${skillName} ${t('SKILLS.FAILED_TO_DELETE')}`);
					}
				};

				return (
					<span className="delete-button" onClick={onDelete}>
						<Translate text="SKILLS.DELETE" />
					</span>
				);
			}
		}
	];
};

interface Props {
	skills: Skill[];
	isLoading?: boolean;
}

const SkillsTable: FunctionComponent<Props> = ({ skills, isLoading = false }) => {
	const { t } = useTranslation();

	return useObserver(() => (
		<TableWrapper
			className="override-antd-table"
			dataSource={skills}
			loading={isLoading}
			columns={getColumns(t)}
			rowKey="id"
		/>
	));
};

export default SkillsTable;
