import { useCallback } from 'react';

import { actions, ActionType, TableInstance, type TableState } from 'react-table';

export type UseSetColumnWidthsInstanceProps = {
	setColumnWidths?: (columnsWidths: Record<string, number>) => void;
};

const pluginName = 'useSetColumnWidths';

// Make sure these don't overlap with action names for other resize actions
actions.setColumnWidths = 'setColumnWidths';

export const useSetColumnWidths = hooks => {
	hooks.stateReducers.push(reducer);
	hooks.useInstance.push(useInstance);
};

useSetColumnWidths.pluginName = pluginName;

function reducer(state: TableState, action: ActionType) {
	if (action.type === actions.setColumnWidths) {
		return {
			...state,
			columnResizing: {
				...state.columnResizing,
				isResizingColumn: false,
				columnWidths: action.value
			}
		};
	}

	return state;
}

function useInstance(instance: TableInstance) {
	const { dispatch } = instance;

	const setColumnWidths = useCallback(
		columnWidths => {
			dispatch({ type: actions.setColumnWidths, value: columnWidths });
		},
		[dispatch]
	);

	Object.assign(instance, {
		setColumnWidths
	});
}
