import React from 'react';
import { Button } from '@bringg/react-components';
import { BringgFontIcons, BringgIcon } from '@bringg/bringg-icons';
import styles from './transfer-arrows.module.scss';
import { ListsType } from '../types';

interface Props {
	onTransferArrowClick: (originList: ListsType) => void;
	isFromSourceDisabled: boolean;
	isFromTargetDisabled: boolean;
}

const TransferArrows = ({ onTransferArrowClick, isFromSourceDisabled, isFromTargetDisabled }: Props) => {
	return (
		<div className={styles.arrowsContainer}>
			<div className={styles.decorativeLine} />
			<Button
				icon={<BringgIcon iconName={BringgFontIcons.ArrowRight} />}
				onClick={() => onTransferArrowClick(ListsType.SOURCE_LIST)}
				data-test-id="transfer-from-source"
				shape="circle"
				disabled={isFromSourceDisabled}
				className={styles.arrowButton}
			/>
			<Button
				icon={<BringgIcon iconName={BringgFontIcons.ArrowLeft} />}
				onClick={() => onTransferArrowClick(ListsType.TARGET_LIST)}
				data-test-id="transfer-from-target"
				shape="circle"
				disabled={isFromTargetDisabled}
				className={styles.arrowButton}
			/>
		</div>
	);
};

export default TransferArrows;
