import React, { memo } from 'react';

import { useTranslation } from 'react-i18next';

import { AttachmentsLoader } from '../attachments-loader';
import { SubmittingStatusEnum } from '../consts';

import styles from './submit-confirmation.module.scss';

// until ts bumped to 5+, https://github.com/microsoft/TypeScript/pull/50528
export type SubmittingStatus = 0 | 1 | 2 | 3;

interface Props {
	title: string;
	ticketId?: string;
	ticketNumber?: string;
	submittingStatus?: SubmittingStatus;
}

export const SubmitConfirmation = memo(({ title, ticketId, ticketNumber, submittingStatus }: Props) => {
	const { t } = useTranslation();

	return (
		<div className={styles.submitConfirmation}>
			<p className={styles.confirmationTitle}>{t('NEW_SUBMIT_TICKET.SUBMITTED_TICKET_TITLE')}</p>
			<p className={styles.ticketInfo}>
				{t('NEW_SUBMIT_TICKET.TICKET_ID')}:&nbsp;
				<a
					href={`https://bringg.my.site.com/supportcenter/s/case/${ticketId}`}
					className="link"
					target="_blank"
					rel="noopener noreferrer"
				>
					{ticketNumber}
				</a>
			</p>
			{submittingStatus === SubmittingStatusEnum.AttachmentsUploading ? (
				<AttachmentsLoader />
			) : (
				<>
					<p className={styles.ticketInfo}>{`${t('NEW_SUBMIT_TICKET.TICKET_SUBJECT')}: ${title}`}</p>
					<p className={styles.confirmationMessage}>{t('NEW_SUBMIT_TICKET.SUBMITTED_TICKET_MESSAGE')}</p>

					<img src="images/astro_optimization_error.svg" />
				</>
			)}
		</div>
	);
});
