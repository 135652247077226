import useTeamsTreeData from '../../hooks/use-teams-tree-data';

export enum OptimizationConfigurationManifestTypes {
	CHECKBOX = 'CHECKBOX',
	NUMBER_INPUT = 'NUMBER_INPUT',
	PERCENTAGE_INPUT = 'PERCENTAGE_INPUT',
	STRING_INPUT = 'STRING_INPUT',
	CONDITIONAL_TIME_INPUT = 'CONDITIONAL_TIME_INPUT',
	NUMBERS_RANGE_PICKER = 'NUMBERS_RANGE_PICKER',
	DROPDOWN = 'DROPDOWN',
	TOGGLE = 'TOGGLE'
}

export interface OptimizationAttribute {
	id: string;
	type: OptimizationConfigurationManifestTypes;
	title: string;
	default?: string | number | boolean;
	value?: string;
	isValid: boolean;
	constraints: {
		required: boolean;
	};
	section: 'general' | 'route' | 'vehicleAndDriver';
	visible_by_ff?: string;
	isSubField?: boolean;
	disabled?: boolean;
	containerId?: string;
}

export interface OptimizationNumberAttribute extends OptimizationAttribute {
	type: OptimizationConfigurationManifestTypes.NUMBER_INPUT;
	validationErrorMsg?: string;
	constraints: {
		min: number;
		max: number;
		required: boolean; //shouldn't be here
	};
	tooltipTitle?: string;
}

export interface OptimizationStringAttribute extends OptimizationAttribute {
	type: OptimizationConfigurationManifestTypes.STRING_INPUT;
	constraints: {
		minLength: number;
		maxLength: number;
		required: boolean;
	};
	tooltipTitle?: string;
}

export interface OptimizationCheckboxAttribute extends Omit<OptimizationAttribute, 'constraints' | 'value'> {
	value: boolean;
	type: OptimizationConfigurationManifestTypes.CHECKBOX;
	tooltipTitle?: string;
	sub_fields_if_enabled?: GenericParameter[];
}

export interface OptimizationSliderAttribute extends OptimizationAttribute {
	type: OptimizationConfigurationManifestTypes.NUMBERS_RANGE_PICKER;
	tooltipTitle: string;
	start: {
		value: number;
		label?: string;
	};
	end: {
		value: number;
		label?: string;
	};
	constraints: {
		required: boolean;
	};
}

export interface OptimizationToggleAttribute extends OptimizationAttribute {
	type: OptimizationConfigurationManifestTypes.TOGGLE;
	tooltipTitle?: string;
	left: {
		id: string;
		value: boolean;
		title: string;
	};
	right: {
		id: string;
		value: boolean;
		title: string;
	};
	sub_fields_if_enabled?: GenericParameter[];
}

export interface OptimizationTimeAttribute extends Omit<OptimizationAttribute, 'value'> {
	value?: string;
	type: OptimizationConfigurationManifestTypes.CONDITIONAL_TIME_INPUT;
	tooltipTitle?: string;
	checkbox?: boolean;
	constraints: {
		required: boolean;
	};
	options: {
		id: string;
		title: string;
		timePicker?: boolean;
		timePickerValueKey?: string;
		default?: boolean;
		beta?: boolean;
	}[];
}

export interface OptimizationDropdownAttribute extends OptimizationAttribute {
	type: OptimizationConfigurationManifestTypes.DROPDOWN;
	tooltipTitle?: string;
	allowEmpty?: boolean;
	options: {
		id: string;
		title: string;
		default?: boolean;
	}[];
}
export interface OptimizationPercentageAttribute extends OptimizationAttribute {
	type: OptimizationConfigurationManifestTypes.PERCENTAGE_INPUT;
	prompt: string;
	validationErrorMsg?: string;
	constraints: {
		min: number;
		minPercent?: number;
		max: number;
		maxPercent?: number;
		required: boolean;
	};
	tooltipTitle?: string;
}

export type GenericParameter =
	| OptimizationNumberAttribute
	| OptimizationStringAttribute
	| OptimizationCheckboxAttribute
	| OptimizationSliderAttribute
	| OptimizationDropdownAttribute
	| OptimizationTimeAttribute
	| OptimizationToggleAttribute
	| OptimizationPercentageAttribute;

export type OptimizationConfigurationManifest = {
	generalParameters: GenericParameter[];
	routeParameters: GenericParameter[];
	vehicleAndDriverParameters: GenericParameter[];
};

export interface OptimizationConfigurationView {
	id?: number;
	configurationName?: string;
	description?: string;
	configuration: GenericParameter[];
	teams?: number[];
	defaultConfiguration: boolean;
}

type HandleChangeParams = [string, { value: any; isValid: boolean; validationErrorMsg?: string }];
export type HandleChange = (...args: HandleChangeParams) => void;

export type TeamsTreeData = ReturnType<typeof useTeamsTreeData>;
export type EnrichedTeamsTreeData = Extract<TeamsTreeData, any[]>[number] & { optimizationId?: number };

export type OptimizationSettingsFormContextType = {
	handleChange: HandleChange;
};
