import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { toJS } from 'mobx';
import { ActionData, AnswerData as AnswerDataEntity } from '@bringg/types/types/action_data';
import { BringgFontIcons, BringgIcon } from '@bringg/bringg-icons';
import { Input, Row } from '@bringg/react-components';
import Translate from '../../../translation/translator';
import { Option, SelectWithStickyPopup as Select } from '../../../components';
import { actionValueToType } from '../action-data.consts';
import ActionsConfigurationsStore from '../../../stores/actions-configuration/actions-configuration-store';

interface Props {
	answer: AnswerDataEntity;
	updateAnswer: (answer: AnswerDataEntity) => void;
	deleteAnswer: () => void;
	actionsConfigurationsStore?: ActionsConfigurationsStore;
	parentQuestionId?: number;
}

class AnswerData extends Component<Props> {
	goToActionTitle = (actionData: ActionData) => {
		return `${actionData.title} (${actionValueToType.get(actionData.value)})`;
	};

	onSelectGoTo = (goToId: number) => {
		this.props.updateAnswer({ go_to_id: goToId });
	};

	goToActions = () => {
		const { parentQuestionId } = this.props;
		return toJS(this.props.actionsConfigurationsStore.current.action_data).filter(
			({ id }) => id !== parentQuestionId
		);
	};

	render() {
		const { answer, updateAnswer } = this.props;

		return (
			<Row justify="space-between">
				<div>
					<div>
						<Translate text="ACTION_CONFIGURATION.ANSWER" />
					</div>
					<Input
						placeholder=""
						type="text"
						onChange={e => updateAnswer({ text: e.target.value })}
						value={answer.text}
						data-test-id="action-data-answer"
						className="action-data-input answer-input"
					/>
				</div>
				<div>
					<div>
						<Translate text="ACTION_CONFIGURATION.GO_TO" />
					</div>
					<Select
						data-test-id="action-data-go-to"
						onSelect={this.onSelectGoTo}
						value={answer.go_to_id}
						className="action-data-input go-to-select"
						placeholder={<Translate text="ACTION_CONFIGURATION.NO_GO_TO_ID" />}
					>
						{this.goToActions().map(actionData => (
							<Option key={actionData.id} value={actionData.id} data-test-id="action-data-go-to-option">
								{this.goToActionTitle(actionData)}
							</Option>
						))}
						<Option key={0} value={0}>
							<Translate text="ACTION_CONFIGURATION.NO_GO_TO_ID" />
						</Option>
					</Select>
				</div>
				<div>
					<div className="delete-icon" onClick={this.props.deleteAnswer}>
						<BringgIcon iconName={BringgFontIcons.Close} />
					</div>
				</div>
			</Row>
		);
	}
}

export default inject('actionsConfigurationsStore')(observer(AnswerData));
