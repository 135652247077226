import React from 'react';
import { useTranslation } from 'react-i18next';
import { ExtendedFloatingInventory, FloatingInventoryOwnerType } from '@bringg/types';
import { Table } from '../../../components';
import {
	OpenEventLogParams,
	FloatingInventoryTableItemData,
	NO_INFO,
	TABLE_HEIGHT,
	TABLE_SIZE,
	dateRenderer,
	ownerTypeTranslations,
	ownershipOriginTranslations,
	statusTranslations,
	OwnerName
} from '..';
import styles from '../floating-inventories.module.scss';

interface Props {
	floatingInventories: ExtendedFloatingInventory[];
	openEventLogModal: (value: OpenEventLogParams) => void;
	loading: boolean;
}

const FloatingInventoriesTable = ({ floatingInventories, openEventLogModal, loading }: Props) => {
	const { t } = useTranslation();

	const ownerTypeRenderer = (ownerType, record) => (
		<span data-test-id={'owner-type-column-' + record.id}>
			{ownerType === FloatingInventoryOwnerType.NONE ? NO_INFO : t(ownerTypeTranslations[ownerType])}
		</span>
	);

	const ownershipOriginRenderer = (ownershipOrigin, record) => (
		<span data-test-id={'ownership-origin-column-' + record.id}>
			{ownershipOrigin ? t(ownershipOriginTranslations[ownershipOrigin]) : NO_INFO}
		</span>
	);

	const statusRenderer = (status, record) => (
		<span data-test-id={'status-column-' + record.id}>{status ? t(statusTranslations[status]) : NO_INFO}</span>
	);

	const eventLogRenderer = (_, record: FloatingInventoryTableItemData) => (
		<div
			role="button"
			aria-label={t('FLOATING_INVENTORIES.EVENT_LOG.ARIA_LABEL')}
			className={styles['floating-inventories-event-log-button']}
			onClick={() => openEventLogModal({ id: record.id, name: record.name })}
		>
			{t('FLOATING_INVENTORIES.COLUMNS.EVENT_LOG')}
		</div>
	);

	const renderOwnerName = (_, record: FloatingInventoryTableItemData) => (
		<OwnerName id={record.ownerId} ownerType={record.ownerType as FloatingInventoryOwnerType} />
	);

	const getColumns = () => {
		const columns = [
			{
				title: t('FLOATING_INVENTORIES.COLUMNS.SKU'),
				dataIndex: 'sku',
				className: 'sku',
				ellipsis: true
			},
			{
				title: t('FLOATING_INVENTORIES.COLUMNS.NAME'),
				dataIndex: 'name',
				className: 'name',
				ellipsis: true
			},
			{
				title: t('FLOATING_INVENTORIES.COLUMNS.SERIAL'),
				dataIndex: 'serialNumber',
				className: 'serialNumber',
				ellipsis: true
			},
			{
				title: t('FLOATING_INVENTORIES.COLUMNS.OWNER_TYPE'),
				dataIndex: 'ownerType',
				className: 'ownerType',
				render: ownerTypeRenderer,
				width: '6.5%'
			},
			{
				title: t('FLOATING_INVENTORIES.COLUMNS.OWNER'),
				dataIndex: 'ownerId',
				ellipsis: true,
				render: renderOwnerName
			},
			{
				title: t('FLOATING_INVENTORIES.COLUMNS.OWNERSHIP_ORIGIN'),
				dataIndex: 'ownershipOrigin',
				className: 'ownershipOrigin',
				render: ownershipOriginRenderer,
				width: '9%'
			},
			{
				title: t('FLOATING_INVENTORIES.COLUMNS.STATUS'),
				dataIndex: 'status',
				className: 'status',
				render: statusRenderer,
				ellipsis: true,
				width: '6%'
			},
			{
				title: t('FLOATING_INVENTORIES.COLUMNS.CREATED'),
				dataIndex: 'createdAt',
				className: 'createdAt',
				render: dateRenderer,
				ellipsis: true,
				width: '8.5%'
			},
			{
				title: t('FLOATING_INVENTORIES.COLUMNS.EVENT_LOG'),
				dataIndex: 'id',
				className: 'activityLogButton',
				width: '7%',
				render: eventLogRenderer
			}
		];

		return columns;
	};

	const normalizedFloatingInventories = floatingInventories.map(
		fi =>
			({
				id: fi.id,
				sku: fi.sku,
				name: fi.name,
				serialNumber: fi.serial_number,
				ownerType: fi.owner_type,
				ownerId: fi.user_id || fi.team_id,
				ownershipOrigin: fi.ownership_origin,
				status: fi.status,
				createdAt: fi.created_at
			} as FloatingInventoryTableItemData)
	);

	return (
		<Table
			loading={loading}
			dataSource={normalizedFloatingInventories}
			columns={getColumns()}
			rowKey={'id'}
			rowClassName={record => `id_${record.id} sn_${record.serialNumber}`}
			size={TABLE_SIZE}
			scroll={{ y: TABLE_HEIGHT }}
			data-test-id={'floating-inventories-table'}
		/>
	);
};

export default FloatingInventoriesTable;
