import React from 'react';
// eslint-disable-next-line no-restricted-imports
import { Form } from 'antd';
import { User } from '@bringg/types';
import { useTranslation } from 'react-i18next';
import { getValidateMessages } from '../../../utils/validate-messages';
import {
	AddressField,
	CompanyField,
	EmailField,
	ExternalIDField,
	JobDescriptionField,
	LanguageField,
	NameField,
	PasswordField,
	PhoneField
} from '../form-fields';

interface Props {
	form: any;
	onSubmit: (user: Partial<User & { password: string }>) => void;
}

export default function AdminForm({ form, onSubmit }: Props) {
	const { t } = useTranslation();

	return (
		<Form
			validateMessages={getValidateMessages(t)}
			validateTrigger={['onBlur']}
			autoComplete="do-not-autofill"
			scrollToFirstError
			colon={false}
			onFinish={onSubmit}
			form={form}
			className="dispatcher-form"
		>
			<div className="form-group">
				<h4>{t('CREATE_USER_MODAL.MANDATORY_DETAILS')}</h4>
				<NameField />
				<ExternalIDField />
				<EmailField required />
				<PasswordField required />
				<PhoneField required={false} />
				<AddressField form={form} />
				<CompanyField />
				<JobDescriptionField />
				<LanguageField />
			</div>
		</Form>
	);
}
