import { TaskStatus } from '@bringg/types';

// TODO: place this logic somewhere to make it reusable all over Bringg. e.g. - @bringg/frontend-utils
export const getStatusTranslationKey = (status: number) => {
	switch (status) {
		case TaskStatus.Created:
			return 'UTILS.ORDER_STATUS_NOT_ASSIGNED';
		case TaskStatus.Assigned:
			return 'UTILS.ORDER_STATUS_ASSIGNED';
		case TaskStatus.OnTheWay:
			return 'UTILS.ORDER_STATUS_ON_THE_WAY';
		case TaskStatus.CheckedIn:
			return 'UTILS.ORDER_STATUS_CHECKED_IN';
		case TaskStatus.Done:
			return 'UTILS.ORDER_STATUS_DONE';
		case TaskStatus.Late:
			return 'UTILS.ORDER_STATUS_LATE';
		case TaskStatus.Accepted:
			return 'UTILS.ORDER_STATUS_ACCEPTED';
		case TaskStatus.Cancelled:
			return 'UTILS.ORDER_STATUS_CANCELLED';
		case TaskStatus.Rejected:
			return 'UTILS.ORDER_STATUS_REJECTED';
		case TaskStatus.Unacknowledged:
			return 'UTILS.ORDER_STATUS_UNACKNOWLEDGED';
		case TaskStatus.Pending:
			return 'UTILS.ORDER_STATUS_PENDING';

		default:
			return 'UTILS.ORDER_STATUS_UNKNOWN';
	}
};

export function getErrorDetailsTranslationKey(details = ''): string | null {
	if (details.includes('email has already been taken')) {
		return 'CREATE_USER_MODAL.EMAIL_TAKEN';
	}

	if (details.includes('phone has already been taken')) {
		return 'CREATE_USER_MODAL.PHONE_TAKEN';
	}

	if (details.includes('external_id has already been taken')) {
		return 'CREATE_USER_MODAL.EMPLOYEE_ID_TAKEN';
	}

	if (details.includes('password Failed validation rules')) {
		return 'CREATE_USER_MODAL.FAILED_PASSWORD_VALIDATION';
	}

	return null;
}
