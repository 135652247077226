import { useState, useCallback } from 'react';

interface FilterTypes {
	searchText: string;
}

export interface UseTableFilters {
	filters: FilterTypes;
	updateFilters: (newFilters) => void;
	resetFilters: (filterKey?: string) => void;
}

const DEFAULT_FILTERS: FilterTypes = {
	searchText: ''
};

export const useTableFilters = () => {
	const [filters, setFilters] = useState(DEFAULT_FILTERS);

	const updateFilters = useCallback(newFilters => {
		setFilters({ ...filters, ...newFilters });
	}, []);

	const resetFilters = useCallback(
		(filterKey?: string) => {
			if (filterKey) {
				updateFilters({ ...filters, [filterKey]: DEFAULT_FILTERS[filterKey] });
			} else {
				updateFilters(DEFAULT_FILTERS);
			}
		},
		[filters]
	);

	return { filters, updateFilters, resetFilters } as UseTableFilters;
};
