import React from 'react';
import { Menu } from '@bringg/react-components';
import { BringgFontIcons, BringgIcon } from '@bringg/bringg-icons';

import { TFunction } from 'react-i18next';
import { ServicePlan } from '@bringg/types';

export const menu = (
	record: ServicePlan,
	setSelectedRecord: (value: ServicePlan) => void,
	setIsUpdateServiceModalOpen: (value: React.SetStateAction<boolean>) => void,
	t: TFunction
) => (
	<Menu
		items={[
			{
				key: 'SERVICE_PLAN.EDIT',
				label: t('SERVICE_PLAN.EDIT'),
				className: 'service-plan-edit-button',
				icon: <BringgIcon iconName={BringgFontIcons.Pencil} className="menu-pencil" />,
				onClick: () => {
					setSelectedRecord(record);
					setIsUpdateServiceModalOpen(true);
				}
			}
		]}
	/>
);
