import React from 'react';
import { BringgFontIcons, BringgIcon } from '@bringg/bringg-icons';
import { Tooltip, Button } from '@bringg/react-components';

import './styles.scss';

export const FavoriteButton = ({ isActive, onClick }: any) => {
	return (
		<Tooltip placement="top" title="Favorite" destroyTooltipOnHide={{ keepParent: false }}>
			<Button
				className="icon-button favorite-button"
				icon={
					isActive ? (
						<BringgIcon iconName={BringgFontIcons.HeartFilled} />
					) : (
						<BringgIcon iconName={BringgFontIcons.Heart} />
					)
				}
				shape="circle"
				type="ghost"
				onClick={onClick}
			/>
		</Tooltip>
	);
};
