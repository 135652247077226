import { Modal, Radio } from '@bringg/react-components';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
	visible: boolean;
	onOk: (shouldOverride: boolean) => void;
	onCancel: () => void;
}
const TimeoffAttentionModal: React.FC<Props> = ({ visible, onOk, onCancel }) => {
	const { t } = useTranslation();
	const [shouldOverride, setShouldOverride] = useState<boolean>(false);

	const handleOnOk = useCallback(() => {
		onOk(shouldOverride);
	}, [onOk, shouldOverride]);

	return (
		<Modal
			className="timeoff-attention-modal"
			visible={visible}
			title={t('TIMEOFF.ATTENTION')}
			onOk={handleOnOk}
			onCancel={onCancel}
			cancelText={t('TIMEOFF.CANCEL')}
			okText={t('TIMEOFF.CONTINUE')}
			cancelButtonProps={{ danger: true, type: 'primary' }}
		>
			<span>{t('TIMEOFF.ATTENTION_DESCRIPTION')}</span>
			<Radio.Group onChange={event => setShouldOverride(event.target.value)}>
				<Radio value={false} checked={!shouldOverride}>
					{t('TIMEOFF.ADD_TO_EXISTING')}
				</Radio>
				<Radio value={true} checked={shouldOverride}>
					{t('TIMEOFF.OVERRIDE_EXISTING')}
				</Radio>
			</Radio.Group>
		</Modal>
	);
};

export default TimeoffAttentionModal;
