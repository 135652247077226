import { getRoot } from 'mobx-easy';
import { action, computed, makeObservable, observable } from 'mobx';
import i18next from 'i18next';
import { ActionType, AlertDisplayCategory, AlertSeverity, ConsoleAction, UserRole } from '@bringg/types';
import { Notification } from '@bringg/react-components';

import RootStore from 'bringg-web/stores/root-store';
import { workflowsRootStore } from '../internal';
import { workflowsProvider } from '../../data-providers/workflows-provider';

interface AutomationAlert {
	id: number;
	severity: AlertSeverity;
	title: string;
	description_rich_text?: string;
	display_category?: AlertDisplayCategory;
	role: UserRole;
}

class WorkflowAlertsStore {
	loading: boolean;

	constructor() {
		makeObservable(this, {
			loading: observable,
			load: action,
			isLoading: computed,
			alertTypes: computed,
			alerts: computed,
			configurationsFilteredByUserRole: computed,
			configurationsWithAlertByUserRole: computed
		});

		this.load();
	}

	load = async () => {
		if (workflowsProvider.isLoaded()) {
			return;
		}
		this.loading = true;
		try {
			await workflowsProvider.loadAll();
		} catch (e) {
			console.error('failed to load alerts configurations', e);
			Notification.error(i18next.t('ALERTS_CONFIGURATION.FAILED_TO_LOAD_SETS'));
		} finally {
			this.loading = false;
		}
	};

	get isLoading() {
		return this.loading;
	}

	get configurationsFilteredByUserRole(): Set<number> {
		const filteredByNotifiers = this.configurationsWithAlertByUserRole.map(conf => conf.id);

		return new Set(filteredByNotifiers);
	}

	get configurationsWithAlertByUserRole(): AutomationAlert[] {
		const { isAdmin, isDispatcher } = getRoot<RootStore>().data.usersStore;

		return this.alerts.filter(
			alert => (alert.role === UserRole.Admin && isAdmin) || (alert.role === UserRole.Dispatcher && isDispatcher)
		);
	}

	get configurationsWithAlertByDisplayCategory(): Record<AlertDisplayCategory, AutomationAlert[]> {
		return this.configurationsWithAlertByUserRole.reduce<Record<AlertDisplayCategory, AutomationAlert[]>>(
			(acc, alert) => {
				if (alert.display_category && acc[alert.display_category]) {
					acc[alert.display_category].push(alert);
				}
				return acc;
			},
			{
				[AlertDisplayCategory.TASK]: [],
				[AlertDisplayCategory.DRIVER]: [],
				[AlertDisplayCategory.OPTIMIZATION]: []
			}
		);
	}

	get alerts(): AutomationAlert[] {
		const workflows = workflowsRootStore.getStore().workflowRepo.getAll();

		return workflows
			.map(workflow => ({
				id: workflow.id,
				workflowTitle: workflow.title,
				enabled: workflow.enabled,
				notifyActions: workflow.actions.flatActions.filter(
					action => action.action_type === ActionType.ALERT
				) as ConsoleAction[]
			}))
			.filter(({ enabled, notifyActions }) => enabled && notifyActions.length)
			.map(({ id, workflowTitle, notifyActions }) => {
				const action = notifyActions.find(action => action.data.type) || notifyActions[0];
				return {
					id: action.data.type || id,
					severity: action.data.severity ?? AlertSeverity.Medium,
					title: action.data.title || workflowTitle,
					display_category: action.data.display_category,
					description_rich_text: action.data.description_rich_text,
					role: action.data.role
				};
			});
	}

	get alertTypes(): number[] {
		return this.alerts.map(({ id }) => id);
	}

	getAlertById(id: number): AutomationAlert | undefined {
		return this.alerts.find(alert => alert.id === id);
	}
}

export default WorkflowAlertsStore;
