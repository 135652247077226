import { Link } from 'react-router-dom';

export function OrderLink({ orderId, children }) {
	return (
		<Link to={`/history/${orderId}`} target="_blank" rel="noopener noreferrer">
			{children}
		</Link>
	);
}

export function DriverLink({ driverId, children }) {
	return <Link to={`/drivers/${driverId}`}>{children}</Link>;
}
