import React from 'react';
import { Form, FormInstance, Select } from 'antd';
import { FormItemWrapper, TimePicker } from '@bringg/react-components';
import _isNil from 'lodash/isNil';
import moment from 'moment';
import i18next from 'i18next';
import {
	BREAK_INTERVAL,
	BREAK_MAX_SIZE,
	DELIVERY_BLOCK_TIME_FORMAT,
	DeliveryBlockFormValues
} from '../delivery-block-form';
import FlexBreakMessage from '../../flex-break-message/flex-break-message';
import { Translate } from '../../../../../translation';
import TimezoneService from '../../../../../services/timezone/timezone-service';
import { breakService } from 'bringg-web/features/delivery-blocks-v2/services/break-service';

interface Props {
	form?: FormInstance;
	deliveryBlock?: DeliveryBlockFormValues;
	deliveryBlockTeamTimezone?: string;
	isEditMode?: boolean;
	breakBufferInMinutes?: number;
}

interface State {
	breakSize: number;
}

const getBreakOptions = () => {
	const { createBreakText, generateBreakSizes } = breakService;
	const options = generateBreakSizes(BREAK_INTERVAL, BREAK_MAX_SIZE).map(breakTime => (
		<Select.Option key={breakTime} value={breakTime}>
			{createBreakText(breakTime)}
		</Select.Option>
	));

	options.unshift(
		<Select.Option key="no break" value={0}>
			No Break
		</Select.Option>
	);

	return options;
};

class DeliveryBlockFromBreaks extends React.Component<Props, State> {
	state = {
		breakSize: 0
	};

	componentDidMount() {
		const newBreakSize = this.getBreakSize();
		if (newBreakSize !== this.state.breakSize) {
			this.setState({ breakSize: newBreakSize });
		}
	}

	componentDidUpdate(prevProps: Readonly<Props>) {
		if (prevProps.isEditMode !== this.props.isEditMode) {
			this.setBreakSize();
		}
	}

	setBreakSize = () => {
		this.setState({ breakSize: this.getBreakSize() });
	};

	onBreakSizeSelect = breakSize => {
		this.setState({ breakSize }, () => {
			if (breakSize === 0 || _isNil(breakSize)) {
				this.setNoBreakTimeRange();
				return;
			}

			this.onBreakStartTimeChange(this.props.form.getFieldValue('breakStartTime'));
		});
	};

	onBreakStartTimeChange = breakStart => {
		const { form, deliveryBlockTeamTimezone } = this.props;
		const momentTz = TimezoneService.getMomentTimezone(deliveryBlockTeamTimezone);
		form.setFieldsValue({
			breakStartTime: breakStart || momentTz(),
			breakEndTime: momentTz(breakStart || new Date()).add(this.state.breakSize, 'minutes')
		});
	};

	onBreakEndTimeChange = breakEnd => {
		const { form, deliveryBlockTeamTimezone } = this.props;
		const momentTz = TimezoneService.getMomentTimezone(deliveryBlockTeamTimezone);
		form.setFieldsValue({
			breakStartTime: momentTz(breakEnd || new Date()).subtract(this.state.breakSize, 'minutes'),
			breakEndTime: breakEnd || momentTz()
		});
	};

	setNoBreakTimeRange = () => {
		this.props.form.setFieldsValue({
			breakStartTime: null,
			breakEndTime: null
		});
	};

	getBreakSize = () => {
		const { isEditMode, deliveryBlock } = this.props;
		const { calcBreakSize } = breakService;

		return isEditMode && deliveryBlock && deliveryBlock.breakStartTime && deliveryBlock.breakEndTime
			? calcBreakSize(deliveryBlock.breakStartTime.toISOString(), deliveryBlock.breakEndTime.toISOString())
			: 0;
	};

	validateBreak = async (rule, value) => {
		if (this.state.breakSize !== 0) {
			const { form } = this.props;
			const { startTime, endTime } = form.getFieldsValue(['startTime', 'endTime']);
			const requestedBreakTime = moment(value.format(DELIVERY_BLOCK_TIME_FORMAT), DELIVERY_BLOCK_TIME_FORMAT);
			if (
				!requestedBreakTime.isBetween(
					moment(startTime.format(DELIVERY_BLOCK_TIME_FORMAT), DELIVERY_BLOCK_TIME_FORMAT),
					moment(endTime.format(DELIVERY_BLOCK_TIME_FORMAT), DELIVERY_BLOCK_TIME_FORMAT)
				)
			) {
				return Promise.reject(i18next.t('DELIVERY_BLOCKS.VALIDATION_MESSAGES.BREAK'));
			}
		}
		return Promise.resolve();
	};

	render() {
		const { onBreakSizeSelect, validateBreak, onBreakStartTimeChange, onBreakEndTimeChange } = this;
		const { deliveryBlock, form, breakBufferInMinutes } = this.props;
		const { breakSize } = this.state;

		const flexBreakStartTime = form.getFieldValue('breakStartTime') || deliveryBlock.breakStartTime;
		const flexBreakEndTime = form.getFieldValue('breakEndTime') || deliveryBlock.breakEndTime;

		return (
			<>
				<Form.Item label={<Translate text="DELIVERY_BLOCKS.BREAK" />}>
					<Select value={breakSize} onSelect={onBreakSizeSelect}>
						{getBreakOptions()}
					</Select>
				</Form.Item>

				<Form.Item
					label={<Translate text="DELIVERY_BLOCKS.TIME_RANGE" />}
					labelCol={{ xs: { span: 8 } }}
					wrapperCol={{ xs: { span: 16 } }}
					style={{ marginBottom: 0 }}
				>
					<FormItemWrapper
						name="breakStartTime"
						initialValue={breakSize !== 0 ? deliveryBlock.breakStartTime : null}
						rules={[{ validator: validateBreak }]}
						className="time-range"
					>
						<TimePicker
							placeholder=""
							disabled={breakSize === 0 || _isNil(breakSize)}
							onChange={onBreakStartTimeChange}
							use12Hours
							allowClear={false}
							suffixIcon={<></>}
							format={DELIVERY_BLOCK_TIME_FORMAT}
						/>
					</FormItemWrapper>
					<div className="time-range-separator">-</div>

					<FormItemWrapper
						name="breakEndTime"
						initialValue={breakSize !== 0 ? deliveryBlock.breakEndTime : null}
						rules={[{ validator: validateBreak }]}
						className="time-range"
					>
						<TimePicker
							placeholder=""
							disabled={breakSize === 0 || _isNil(breakSize)}
							onChange={onBreakEndTimeChange}
							use12Hours
							allowClear={false}
							suffixIcon={<></>}
							format={DELIVERY_BLOCK_TIME_FORMAT}
						/>
					</FormItemWrapper>

					{breakBufferInMinutes && breakSize ? (
						<FlexBreakMessage
							breakSize={breakSize}
							breakBuffer={breakBufferInMinutes}
							breakStartTime={flexBreakStartTime}
							breakEndTime={flexBreakEndTime}
						/>
					) : (
						<></>
					)}
				</Form.Item>
			</>
		);
	}
}

export default DeliveryBlockFromBreaks;
