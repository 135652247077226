import React, { useEffect } from 'react';

import { Checkbox, InputNumber, Option, Select } from '@bringg/react-components';
import { TFunction } from 'i18next';
import { RRule, Frequency, Options, Weekday } from 'rrule';
import { useTranslation } from 'react-i18next';
import { EndOfWeekDay } from '@bringg/types';
import { rotateArray } from '@bringg-frontend/utils';

import { allRecurringFrequencies } from '../../../../../components/recurring/recurring.consts';
import { defaultFrequencyLabels } from '../recurrence.const';

import './frequencies.scss';

export interface Props {
	rruleOptions: Partial<Options>;
	onRecurrenceTypeSelect: (frequency: number) => void;
	onIntervalChange: (interval: number) => void;
	onAtChange: (bymonthday: number) => void;
	onWeekDaysChange: (weekDays: number[]) => void;
	deliveryBlockDate: number;
	endOfWeekDay: EndOfWeekDay;
	recurringFrequencies?: number[];
}

const getOptions = (t: TFunction, endDayOfWeek: EndOfWeekDay) => {
	const week = [
		{ label: t('WEEK_DAYS.SU'), value: RRule.SU.weekday },
		{ label: t('WEEK_DAYS.MO'), value: RRule.MO.weekday },
		{ label: t('WEEK_DAYS.TU'), value: RRule.TU.weekday },
		{ label: t('WEEK_DAYS.WE'), value: RRule.WE.weekday },
		{ label: t('WEEK_DAYS.TH'), value: RRule.TH.weekday },
		{ label: t('WEEK_DAYS.FR'), value: RRule.FR.weekday },
		{ label: t('WEEK_DAYS.SA'), value: RRule.SA.weekday }
	];

	switch (endDayOfWeek) {
		case EndOfWeekDay.Friday:
			rotateArray(week, 1, true);
			return week;

		case EndOfWeekDay.Sunday:
			rotateArray(week, 1, false);
			break;

		case EndOfWeekDay.Monday:
			rotateArray(week, 2, false);
			break;

		case EndOfWeekDay.Tuesday:
			rotateArray(week, 3, false);
			break;

		case EndOfWeekDay.Wednesday:
			rotateArray(week, 3, true);
			break;

		case EndOfWeekDay.Thursday:
			rotateArray(week, 2, true);
			break;

		default:
			break;
	}
	return week;
};

export const MAX_MONTH_INTERVALS = 12;
export const MAX_WEEK_INTERVALS = 4;

export const Frequencies = ({
	rruleOptions,
	onRecurrenceTypeSelect,
	onIntervalChange,
	onAtChange,
	onWeekDaysChange,
	deliveryBlockDate,
	endOfWeekDay,
	recurringFrequencies: recurringFrequenciesProp
}: Props) => {
	const { t } = useTranslation();
	useEffect(() => {
		if (rruleOptions.freq === Frequency.MONTHLY && !rruleOptions.bymonthday) {
			onAtChange(deliveryBlockDate);
		}
	}, [rruleOptions]);

	const getMaxInterval = () => {
		if (rruleOptions.freq === Frequency.WEEKLY) {
			return MAX_WEEK_INTERVALS;
		}
		if (rruleOptions.freq === Frequency.MONTHLY) {
			return MAX_MONTH_INTERVALS;
		}

		return null;
	};

	const recurringFrequencies = recurringFrequenciesProp ?? allRecurringFrequencies;

	return (
		<div className="recurrence-frequencies">
			<Select
				placeholder={t('DELIVERY_BLOCK_MODAL.RECURRENCE.RECURRENCE_TYPE')}
				className="recurrence-type"
				value={rruleOptions.freq || recurringFrequencies[0]}
				onSelect={onRecurrenceTypeSelect}
				blockBodyClicks
			>
				{recurringFrequencies.map(recurringFrequency => (
					<Option key={recurringFrequency} value={recurringFrequency}>
						{t(`DELIVERY_BLOCK_MODAL.RECURRENCES.${defaultFrequencyLabels[recurringFrequency]}_OPTION`)}
					</Option>
				))}
			</Select>

			{rruleOptions.freq === Frequency.WEEKLY && (
				<Checkbox.Group
					className="days-in-week"
					options={getOptions(t, endOfWeekDay)}
					value={(rruleOptions?.byweekday as Weekday[])?.map(day => day.weekday)}
					onChange={weekDays => onWeekDaysChange(weekDays as number[])}
				/>
			)}

			{[Frequency.WEEKLY, Frequency.DAILY, Frequency.MONTHLY].includes(rruleOptions.freq) && (
				<div className="recurrence-every">
					<span>{t('DELIVERY_BLOCK_MODAL.RECURRENCES.EVERY')}</span>
					<div>
						<InputNumber
							min={1}
							max={getMaxInterval()}
							className="every-frequency"
							value={rruleOptions.interval || 1}
							onChange={interval => {
								onIntervalChange(+interval);
							}}
							placeholder={t('DELIVERY_BLOCK_MODAL.RECURRENCE.EVERY_FREQUENCY')}
						/>
					</div>
					{rruleOptions.freq === Frequency.DAILY && <span>{t('DELIVERY_BLOCK_MODAL.RECURRENCE.DAY')}</span>}
					{rruleOptions.freq === Frequency.WEEKLY && <span>{t('DELIVERY_BLOCK_MODAL.RECURRENCE.WEEK')}</span>}
					{rruleOptions.freq === Frequency.MONTHLY && (
						<span>{t('DELIVERY_BLOCK_MODAL.RECURRENCE.MONTH')}</span>
					)}
				</div>
			)}

			{rruleOptions.freq === Frequency.MONTHLY && rruleOptions.bymonthday != null && (
				<div className="recurrence-at-the">
					<span>{t('DELIVERY_BLOCK_MODAL.RECURRENCE.AT_THE')}</span>
					<InputNumber
						min={1}
						max={31}
						placeholder={t('DELIVERY_BLOCK_MODAL.RECURRENCE.AT_THE')}
						onChange={bymonthday => onAtChange(+bymonthday)}
						defaultValue={rruleOptions.bymonthday as number}
					/>
				</div>
			)}
		</div>
	);
};
