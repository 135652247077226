import { getRootEnv } from '@bringg-frontend/bringg-web-infra';
import { PlannedRoute } from '@bringg/types';
import { getEnv } from 'mobx-easy';
import { flatten } from 'lodash';
import { RootEnv } from '@bringg-frontend/bringg-web-infra';

import BaseDomainStore from '../core/base-domain-store';

const MAX_ITEMS_PER_GET_REQUEST = 350;

function splitIntoChunks<T>(items: T[], chunkSize: number): T[][] {
	if (!items?.length) return [];
	const chunksCount = Math.ceil(items.length / chunkSize);
	const chunks = [];

	for (let i = 0; i < chunksCount; i += 1) {
		const isLastChunk = i + 1 === chunksCount;
		chunks.push(items.slice(i * chunkSize, isLastChunk ? items.length : (i + 1) * chunkSize));
	}
	return chunks;
}

class PlannedRoutesStore extends BaseDomainStore<PlannedRoute> {
	batchGet = async (ids: number[], options?: { signal?: AbortSignal }): Promise<PlannedRoute[]> => {
		if (!ids.length) return [];

		const response = await getRootEnv().dashboardSdk.sdk.plannedRoutes.getBatch(ids, options);
		this.setBatch(response);

		return response;
	};

	getAllByTeam = async (teamId: number): Promise<PlannedRoute[]> => {
		return getRootEnv().dashboardSdk.sdk.plannedRoutes.getAllByTeam(teamId);
	};

	getAllByTeams = async (teamIds: number[]): Promise<PlannedRoute[]> => {
		if (!teamIds.length) {
			return [];
		}

		const { sdk } = getRootEnv().dashboardSdk;
		if (teamIds.length <= MAX_ITEMS_PER_GET_REQUEST) {
			return sdk.plannedRoutes.getBatchByTeamIds(teamIds);
		}
		return flatten(
			await Promise.all(
				// eslint-disable-next-line @typescript-eslint/require-await
				splitIntoChunks(teamIds, MAX_ITEMS_PER_GET_REQUEST).map(async chunk =>
					sdk.plannedRoutes.getBatchByTeamIds(chunk)
				)
			)
		);
	};
}

export default PlannedRoutesStore;
