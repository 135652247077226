import { useRef, useState } from 'react';

import { createBtnFromDefinition } from './use-buttons-layer';

import styles from '../bringg-google-map-provider.module.scss';

type UseTrafficLayerProps = {
	map: google.maps.Map | null;
	allowTrafficLayer: boolean;
	trafficButtonTranslation?: string;
};

export const useTrafficLayer = ({ map, allowTrafficLayer, trafficButtonTranslation }: UseTrafficLayerProps) => {
	const [trafficLayerInstance, setTrafficLayerInstance] = useState<google.maps.TrafficLayer | null>(null);
	const [trafficLayerEnabled, setTrafficLayerEnabled] = useState(false);
	const buttonIndexRef = useRef<number | null>(null);
	const controlPosition = google.maps.ControlPosition.BOTTOM_CENTER;

	const renderButton = () => {
		if (!trafficLayerInstance) {
			const tempTrafficLayerInstance = new google.maps.TrafficLayer();
			setTrafficLayerInstance(tempTrafficLayerInstance);
		}

		const controlButton = createBtnFromDefinition({
			text: trafficButtonTranslation || 'Traffic',
			className: styles.trafficButton,
			clickHandler: e => {
				e.preventDefault();
				setTrafficLayerEnabled(prev => !prev);
			},
			'data-test-id': 'traffic-btn'
		});

		map?.controls[controlPosition].push(controlButton);

		buttonIndexRef.current = 1;
	};

	const removeAll = () => {
		trafficLayerEnabled && setTrafficLayerEnabled(false);
		if (trafficLayerInstance) {
			trafficLayerInstance?.setMap(null);
			setTrafficLayerInstance(null);
		}
		if (buttonIndexRef?.current !== null) {
			buttonIndexRef.current = null;
			map?.controls[controlPosition]?.clear();
		}
	};

	if (!map || !allowTrafficLayer) {
		removeAll();
		return;
	}

	if (buttonIndexRef?.current === null) {
		renderButton();
		return;
	}

	if (!!trafficLayerInstance?.getMap() !== trafficLayerEnabled) {
		trafficLayerInstance?.setMap(trafficLayerEnabled ? map : null);
	}
};
