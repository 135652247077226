import React from 'react';
import './setting-page-title.scss';

interface SettingPageTitleProps {
	title?: string;
	children?: React.ReactNode;
}

const SettingPageTitle = ({ title, children: actions }: SettingPageTitleProps) => {
	return (
		<div className="setting-page-title">
			{actions}
			{!!title && <h2>{title}</h2>}
		</div>
	);
};

export default SettingPageTitle;
