import { useTranslationKeys } from '@bringg-frontend/utils';

export const translationKeys = {
	pageTitle: 'SPEED_FACTORS.PAGE.TITLE',
	pageSubtitle: 'SPEED_FACTORS.PAGE.SUBTITLE',
	addButton: 'SPEED_FACTORS.PAGE.ADD_BUTTON',
	sidebarButton: 'SPEED_FACTORS.SIDEBAR.BUTTON',
	sidebarTitle: 'SPEED_FACTORS.SIDEBAR.TITLE',
	sidebarSubtitle: 'SPEED_FACTORS.SIDEBAR.SUBTITLE',
	eventTitle: 'SPEED_FACTORS.EVENT_DIALOG.TITLE',
	eventSubtitle: 'SPEED_FACTORS.EVENT_DIALOG.SUBTITLE',
	eventOk: 'SPEED_FACTORS.EVENT_DIALOG.OK_BUTTON',
	eventCancel: 'SPEED_FACTORS.EVENT_DIALOG.CANCEL_BUTTON',
	eventUpdate: 'SPEED_FACTORS.EVENT_DIALOG.UPDATE_BUTTON',
	eventRemove: 'SPEED_FACTORS.EVENT_DIALOG.REMOVE_BUTTON',
	eventEffectiveDatesLabel: 'SPEED_FACTORS.EVENT_DIALOG.EFFECTIVE_DATES_LABEL',
	eventEffectiveDatesDescription: 'SPEED_FACTORS.EVENT_DIALOG.EFFECTIVE_DATES_DESCRIPTION',
	eventFactorLabel: 'SPEED_FACTORS.EVENT_DIALOG.FACTOR_LABEL',
	eventFactorDescription: 'SPEED_FACTORS.EVENT_DIALOG.FACTOR_DESCRIPTION',
	eventApplicableDaysLabel: 'SPEED_FACTORS.EVENT_DIALOG.APPLICABLE_DAYS_LABEL',
	eventTimeFrameLabel: 'SPEED_FACTORS.EVENT_DIALOG.TIME_FRAME_LABEL',
	eventTimeFrameFullDay: 'SPEED_FACTORS.EVENT_DIALOG.TIME_FRAME_FULL_DAY',
	eventTimeFrameHours: 'SPEED_FACTORS.EVENT_DIALOG.TIME_FRAME_HOURS',
	updateTitle: 'SPEED_FACTORS.UPDATE_DIALOG.TITLE',
	updateSubtitle: 'SPEED_FACTORS.UPDATE_DIALOG.SUBTITLE',
	updateOk: 'SPEED_FACTORS.UPDATE_DIALOG.OK_BUTTON',
	updateCancel: 'SPEED_FACTORS.UPDATE_DIALOG.CANCEL_BUTTON',
	updateCurrent: 'SPEED_FACTORS.UPDATE_DIALOG.OPTIONS.CURRENT',
	updateCurrentAndFollowing: 'SPEED_FACTORS.UPDATE_DIALOG.OPTIONS.CURRENT_AND_FOLLOWING',
	updateAll: 'SPEED_FACTORS.UPDATE_DIALOG.OPTIONS.ALL',
	removeTitle: 'SPEED_FACTORS.REMOVE_DIALOG.TITLE',
	removeSubtitle: 'SPEED_FACTORS.REMOVE_DIALOG.SUBTITLE',
	removeOk: 'SPEED_FACTORS.REMOVE_DIALOG.OK_BUTTON',
	removeCancel: 'SPEED_FACTORS.REMOVE_DIALOG.CANCEL_BUTTON',
	removeCurrent: 'SPEED_FACTORS.REMOVE_DIALOG.OPTIONS.CURRENT',
	removeCurrentAndFollowing: 'SPEED_FACTORS.REMOVE_DIALOG.OPTIONS.CURRENT_AND_FOLLOWING',
	removeAll: 'SPEED_FACTORS.REMOVE_DIALOG.OPTIONS.ALL',
	overrideTitle: 'SPEED_FACTORS.OVERRIDE_DIALOG.TITLE',
	overrideSubtitle: 'SPEED_FACTORS.OVERRIDE_DIALOG.SUBTITLE',
	overrideOk: 'SPEED_FACTORS.OVERRIDE_DIALOG.OK_BUTTON',
	overrideCancel: 'SPEED_FACTORS.OVERRIDE_DIALOG.CANCEL_BUTTON',
	fromDate: 'PLANNED_DELIVERY_WINDOWS_MODAL.EFFECTIVE_START_DATE',
	toDate: 'PLANNED_DELIVERY_WINDOWS_MODAL.EFFECTIVE_END_DATE',
	su: 'WEEK_DAYS.SU',
	mo: 'WEEK_DAYS.MO',
	tu: 'WEEK_DAYS.TU',
	we: 'WEEK_DAYS.WE',
	th: 'WEEK_DAYS.TH',
	fr: 'WEEK_DAYS.FR',
	sa: 'WEEK_DAYS.SA',
	from: 'PLANNED_DELIVERY_WINDOWS_MODAL.FROM',
	to: 'PLANNED_DELIVERY_WINDOWS_MODAL.TO'
};

export type TranslationKeys = typeof translationKeys;

export function useSpeedFactorTranslation() {
	return useTranslationKeys<TranslationKeys>(translationKeys);
}
