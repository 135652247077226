import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Button, Modal } from 'antd';
import { WithTranslation, withTranslation } from 'react-i18next';
import every from 'lodash/every';
import { BringgException } from '@bringg/dashboard-sdk/dist/Core/BringgException';
import SharingMode from '../../sharing-mode/sharing-mode';
import { LanguageSelect } from 'bringg-web/components/language-select';
import notification from '../../../../services/notification';
import NotificationTemplateList from '../../notification-template-list/notification-template-list';
import Translate from '../../../../translation/translator';
import CustomizeTrackingUrl from '../../customize-tracking-url/customize-tracking-url';
import TagCustomerNotificationsStore from '../../../../stores/customer-notifications/tag-customer-notifications-store';
import CustomerNotificationSettings from '../../customer-notification-settings/customer-notification-settings';

interface Props {
	tagCustomerNotificationsStore?: TagCustomerNotificationsStore;
	dictionary?: any;
}

class CustomerNotificationsContainer extends Component<Props & WithTranslation> {
	updateConfigurations = async () => {
		if (!this.isAllowedToSaveTemplates()) {
			notification.error(this.props.t('CUSTOMER_CONFIGURATION_VIEW.ALL_VALUES_MUST_BE_FILLED'));
			return;
		}
		try {
			await this.props.tagCustomerNotificationsStore.updateConfigurations();
			notification.success(this.props.t('CUSTOMER_CONFIGURATION_VIEW.CONFIGURATION_SAVED_SUCCESSFULLY'));
		} catch (e) {
			notification.error(
				this.props.t('CUSTOMER_CONFIGURATION_VIEW.CONFIGURATION_FAILED_TO_SAVE'),
				(e as BringgException).details as string
			);
		}
	};

	isAllowedToSaveTemplates = () => {
		const { selectedLanguageTemplates, selectedTagCustomerConfiguration } =
			this.props.tagCustomerNotificationsStore;
		return every(selectedLanguageTemplates, template => {
			return !selectedTagCustomerConfiguration[template.notification_type] || template.template.length > 0;
		});
	};

	isAllowedToDeleteTemplates = () => {
		const { isSelectedTemplatesNew, isDefaultLanguage } = this.props.tagCustomerNotificationsStore;
		return !isDefaultLanguage && !isSelectedTemplatesNew;
	};

	deleteLanguageTemplates = buttonClickEvent => {
		buttonClickEvent.preventDefault();
		const confirmPayload = {
			title: this.props.t('CUSTOMER_CONFIGURATION_VIEW.ARE_U_SURE_TO_DELETE'),
			okText: this.props.t('CUSTOMER_CONFIGURATION_VIEW.DELETE'),
			okType: 'danger' as const,
			cancelText: this.props.t('CUSTOMER_CONFIGURATION_VIEW.CANCEL'),
			onOk: async () => {
				try {
					await this.props.tagCustomerNotificationsStore.deleteLanguageTemplates();
					notification.success(this.props.t('CUSTOMER_CONFIGURATION_VIEW.TEMPLATES_WERE_DELETED'));
				} catch (e) {
					notification.error(
						this.props.t('CUSTOMER_CONFIGURATION_VIEW.TEMPLATES_FAILED_TO_DELETE'),
						(e as BringgException).details as string
					);
				}
			}
		};
		Modal.confirm(confirmPayload);
	};

	render() {
		const {
			selectedLanguageTemplates,
			selectedLanguageCode,
			defaultLanguageCode,
			availableLanguages,
			selectLanguageCode,
			selectedTagCustomerConfiguration,
			selectedTagMerchantConfiguration
		} = this.props.tagCustomerNotificationsStore;
		const { dictionary } = this.props;
		return (
			<div className="notification-templates-container">
				<SharingMode configuration={selectedTagMerchantConfiguration} />
				<CustomizeTrackingUrl tagCustomerConfiguration={selectedTagCustomerConfiguration} />
				<LanguageSelect
					selectedLanguageCode={selectedLanguageCode}
					defaultLanguageCode={defaultLanguageCode}
					availableLanguages={availableLanguages}
					selectedLanguageCodeFunc={selectLanguageCode}
				/>
				<CustomerNotificationSettings customerConfiguration={selectedTagCustomerConfiguration} />
				<NotificationTemplateList
					notificationTemplates={selectedLanguageTemplates}
					dictionary={dictionary}
					customerConfiguration={selectedTagCustomerConfiguration}
					enableFreeSmsMode={false}
				/>
				{this.isAllowedToDeleteTemplates() && (
					<Button
						className="delete-button"
						danger
						type="primary"
						htmlType="submit"
						onClick={this.deleteLanguageTemplates}
					>
						<Translate text="CUSTOMER_CONFIGURATION_VIEW.CLEAR_BUTTON" />
					</Button>
				)}
				<Button className="update-button" htmlType="submit" onClick={this.updateConfigurations}>
					<Translate text="CUSTOMER_CONFIGURATION_VIEW.UPDATE_BUTTON" />
				</Button>
			</div>
		);
	}
}

export default withTranslation()(inject('tagCustomerNotificationsStore')(observer(CustomerNotificationsContainer)));
