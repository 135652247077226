// this component should be over ALL POSSIBLE current and new components
export const SUPPORT_TICKET_ZINDEX = 2000;

export enum VALID_TICKET_TYPES {
	'question' = 'question',
	'incident' = 'incident',
	'new request' = 'new request'
}

export const ROUTE_OPTIMIZATION_FUNCTIONALITY = 'Route Optimization';

export const ALLOWED_ATTACHMENTS_MIME_TYPES = [
	'image/*',
	'audio/*',
	'video/*',
	'application/msword', // .doc
	'application/vnd.ms-excel', // .xls
	'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // .docx
	'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // .xlsx
	'application/vnd.ms-powerpoint', // .ppt
	'application/vnd.openxmlformats-officedocument.presentationml.presentation', // .pptx
	'application/pdf',
	'text/csv'
].join(', ');

export enum SubmittingStatusEnum {
	Submitted,
	NotSubmitted,
	TicketCreateLoading,
	AttachmentsUploading
}
