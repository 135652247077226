import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import { ChatConversationType, ChatMessage, ChatMessageText, ChatMessageType } from '@bringg/types';
import { BringgFontIcons, BringgIcon } from '@bringg/bringg-icons';
import ChatConversation from '../stores/domain-objects/chat-conversation';
import { Avatar } from '../../../components';
import ChatStore from '../stores/chat-store';

const IMAGE_MESSAGE_TEXT = 'Image';

interface Props {
	conversation: ChatConversation;
	chatStore?: ChatStore;
}

class ChatListItem extends Component<Props, {}> {
	getLastMessageText = (lastMessage: ChatMessage) => {
		return (
			lastMessage &&
			(lastMessage.type === ChatMessageType.TEXT
				? (lastMessage as ChatMessageText).payload.text
				: IMAGE_MESSAGE_TEXT)
		);
	};

	render() {
		const { conversation } = this.props;
		const { last_message } = conversation;
		const isCurrentChat = this.props.chatStore.isCurrentChat(conversation.id);

		const message = this.getLastMessageText(last_message);
		const isTaskConversation =
			conversation.conversation_type === ChatConversationType.task || conversation.conversation_type === 'task';

		return (
			<div
				className={`chat-item ${isCurrentChat ? 'selected' : ''}`}
				onClick={() => this.props.chatStore.selectCurrentChat(conversation.id)}
			>
				{isTaskConversation ? (
					<div className="task-icon">
						<BringgIcon iconName={BringgFontIcons.Location} />
					</div>
				) : (
					<Avatar className="chat-item-image" src={conversation.getImageUrl} />
				)}
				<div className="chat-info">
					<div className="chat-title">{conversation.getTitle}</div>
					<div className="chat-message">{message}</div>
				</div>
				<div className="chat-numbers">
					<div className={`chat-time ${conversation.unread_count > 0 ? 'green' : null}`}>
						{last_message ? moment(last_message.server_timestamp).format('HH:mm') : null}
					</div>
					{conversation.unread_count > 0 ? (
						<span className="chat-unread-count">{conversation.unread_count}</span>
					) : null}
				</div>
			</div>
		);
	}
}

export default inject('chatStore')(observer(ChatListItem));
