import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Row } from 'antd';
import { Translate } from '../../../translation';
import { Link, Table } from '../../../components';

interface Props {
	drivers: Partial<Bringg.User>[];
	dispatchers: Partial<Bringg.User>[];
}

class CompanyTables extends Component<Props, {}> {
	TABLE_SIZE = 'small' as const;

	getColumns = () => {
		return [
			{
				sortDirections: ['descend', 'ascend'],
				title: <Translate text="DRIVERS.ID" />,
				dataIndex: 'id',
				sorter: (a, b) => a.id - b.id,
				render: (id: number) => {
					return (
						<span data-test-id="driverId">
							<Link to={`/drivers/${id}`}>{id}</Link>
						</span>
					);
				}
			},
			{
				sortDirections: ['descend', 'ascend'],
				title: <Translate text="DRIVERS.NAME" />,
				sorter: (a, b) => a.name.localeCompare(b.name),
				dataIndex: 'name',
				render: (name, user) => {
					return (
						<span data-test-id="driverName">
							<Link to={`/drivers/${user.id}`}>{user.name}</Link>
						</span>
					);
				}
			},
			{
				sortDirections: ['descend', 'ascend'],
				title: <Translate text="DRIVERS.PHONE" />,
				dataIndex: 'phone',
				sorter: (a, b) => a.phone.localeCompare(b.phone),
				render: text => {
					return <span data-test-id="driverPhone">{text}</span>;
				}
			},
			{
				sortDirections: ['descend', 'ascend'],
				title: <Translate text="DRIVERS.JOB_DESCRIPTION" />,
				sorter: (a, b) => a.job_description.localeCompare(b.job_description),
				dataIndex: 'job_description',
				render: text => {
					return <span data-test-id="driverJob">{text}</span>;
				}
			}
		];
	};

	render() {
		const { drivers, dispatchers } = this.props;

		return (
			<div className="companies-table">
				{dispatchers.length ? (
					<Row>
						<span>
							<Translate text="USER_CONFIGURATION_VIEW.DISPATCHERS" />
						</span>
						<Table // need to add fixed header after antd update
							className="company-dispatcher-table"
							dataSource={dispatchers}
							columns={this.getColumns() as any}
							pagination={false}
							rowKey="id"
							size={this.TABLE_SIZE}
						/>
					</Row>
				) : null}
				{drivers.length ? (
					<Row>
						<span>
							<Translate text="TEAMS.DRIVERS" />
						</span>
						<Table
							className="company-drivers-table"
							dataSource={drivers}
							columns={this.getColumns() as any}
							pagination={false}
							rowKey="id"
							size={this.TABLE_SIZE}
						/>
					</Row>
				) : null}
			</div>
		);
	}
}

export default observer(CompanyTables);
