export interface NotificationsConfiguration {
	task_created?: boolean;
	customer_on_the_way?: boolean;
	customer_arrived?: boolean;
}

export interface ApplicationConfiguration {
	notifications_configuration?: NotificationsConfiguration;
}

export interface ApplicationMerchantConfiguration {
	notifications_configuration?: NotificationsConfiguration;
}

// TODO: add ability to get application config without installing the app
export const tmpDefaultNotificationConfiguration = {
	task_created: false,
	customer_on_the_way: false,
	customer_arrived: false
};
