import moment, { Moment } from 'moment-timezone';
import { dateUtils } from '@bringg-frontend/utils';

import { DailyCutoff } from 'bringg-web/features/planned-delivery-windows/modals/slots-modal/wizard-steps/configuration-step/time-slots/cutoff-radio-group/cutoff.types';

const updateDays = (
	days: number,
	dailyCutoff: DailyCutoff,
	updateDailyCutoff: (updatedData: Partial<DailyCutoff>) => void,
	startRangeTimeInMinutes: number,
	timezone: string
) => {
	const time = moment(dailyCutoff.time);
	const sumOfDailyCutoff = dateUtils.sumOfMinutes(time.hours(), time.minutes());
	const startRangeTimeHours = dateUtils.addMinutesToLastMonday(startRangeTimeInMinutes, timezone).hours();

	if (days === 0 && time.hours() > startRangeTimeHours) {
		const resetTime = time.clone().set({ hours: startRangeTimeHours, minutes: 0 });
		updateDailyCutoff({ days, time: resetTime.toISOString() });
	} else if (days === 7 && sumOfDailyCutoff > dateUtils.hoursToMinutes(12)) {
		const resetTime = time.clone().set({ hours: 12, minutes: 0 });
		updateDailyCutoff({ days, time: resetTime.toISOString() });
	} else {
		updateDailyCutoff({ days });
	}
};
const updateTime = (time: Moment, updateDailyCutoff: (updatedData: Partial<DailyCutoff>) => void) => {
	updateDailyCutoff({ time: time.toISOString() });
};

export const dailyCutoffUpdater = { updateTime, updateDays };
