import { useState } from 'react';

import { CustomModal } from '@bringg/react-components';
import _intersection from 'lodash/intersection';

import notification from '../../../services/notification';
import useFleetConfigurationStore from '../use-fleet-configuration-store';
import { useFleetsConfigurationTranslations } from '../use-fleet-configuration-translations';
import { Rule } from '../../../stores/fleets-configuration/fleets-configurations-store';

interface PatchedRule {
	fleet_router_rules_group_id: string;
	rule_teams?: {
		team_ids: {
			removed: string[];
		};
	};
	enabled?: boolean;
}

export const getRemovedTeams = (firstRule: Rule, secondRule: Rule) => {
	const ruleTeams = Object.keys(firstRule.selectedTeams).filter(teamId => !!firstRule.selectedTeams[teamId]);
	const currentRuleTeams = Object.keys(secondRule.selectedTeams).filter(teamId => !!secondRule.selectedTeams[teamId]);

	return {
		fleet_router_rules_group_id: secondRule.id,
		rule_teams: {
			team_ids: {
				removed: _intersection(ruleTeams, currentRuleTeams)
			}
		}
	};
};

export const formatRulesToServer = (rule: Rule, rules: Rule[]) => {
	const mappedRules: PatchedRule[] = rules.map(currentRule => getRemovedTeams(rule, currentRule));

	mappedRules.push({
		fleet_router_rules_group_id: rule.id,
		enabled: true
	});

	return mappedRules;
};

const useConflictRulesModal = () => {
	const [rule, setRule] = useState<Rule>(null);

	const { fleetsConfigurationStore } = useFleetConfigurationStore();

	const { networkError, disableRulesModalTeamsLevel, disableRulesModalMerchantLevel } =
		useFleetsConfigurationTranslations();

	const { isOpen: isModalOpen, open: openModal, close: closeModal } = CustomModal.useModalState(false);

	const onDisableRules = async () => {
		const rules = fleetsConfigurationStore.getConflictRulesIfRuleWouldEqual(rule)[rule.id] || [];

		const mappedRules = rules.map(currentRule => ({
			fleet_router_rules_group_id: currentRule.id,
			enabled: false
		}));

		mappedRules.push({
			fleet_router_rules_group_id: rule.id,
			enabled: true
		});

		try {
			await fleetsConfigurationStore.patchRules(mappedRules);
		} catch (err) {
			notification.error(networkError);
		} finally {
			setRule(null);
			closeModal();
		}
	};

	const onCancel = () => {
		closeModal();
	};

	return {
		isModalOpen,
		openModal,
		translations: rule?.isMerchantLevel ? disableRulesModalMerchantLevel : disableRulesModalTeamsLevel,
		setRule,
		onCancel,
		onDisableRules
	};
};

export default useConflictRulesModal;
