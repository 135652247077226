import React from 'react';

import { EditableTitle } from '@bringg/react-components';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import styles from './delivery-block-template-titles.module.scss';

interface Props {
	name: string;
	description: string;
	onChangeName: (name: string) => void;
	onChangeDescription: (description: string) => void;
	disabled?: boolean;
}

const DeliveryBlockTemplateTitles = ({ name, description, onChangeName, onChangeDescription, disabled }: Props) => {
	const { t } = useTranslation();

	return (
		<div className={classNames(styles.titleMainContainer, { [styles.disabled]: disabled })}>
			<div
				className={classNames(styles.titleContainer, { [styles.disabled]: disabled })}
				data-test-id="delivery-block-template-main-title-container"
			>
				<div>
					<span className={styles.deliveryBlockTemplateTitleStar}>*</span>
					<EditableTitle
						data-test-id="delivery-block-template-name"
						className={styles.deliveryBlockTemplateTitle}
						placeholder={t('DELIVERY_BLOCKS_TEMPLATE.TITLE_PLACEHOLDER')}
						onValueChange={onChangeName}
						value={name}
					/>
				</div>
				<EditableTitle
					className={styles.deliveryBlockTemplateDescription}
					placeholder={t('DELIVERY_BLOCKS_TEMPLATE.DESCRIPTION_PLACEHOLDER')}
					onValueChange={onChangeDescription}
					value={description}
				/>
			</div>
		</div>
	);
};

export default DeliveryBlockTemplateTitles;
