import React from 'react';
import { useTranslation } from 'react-i18next';
import {
	FloatingInventoryHistoryEventTypes,
	FloatingInventoryHistory,
	FloatingInventoryOwnerType
} from '@bringg/types';
import { DateDisplay } from '@bringg/react-components';
import useDateTimeFormat from 'bringg-web/hooks/use-date-time-format';
import { Table } from 'bringg-web/components';
import { dateRenderer, custodyTranslations, getOwnerType } from '../../consts';
import { OwnerName } from '../..';
import { getEventLogDetailsTranslation } from '..';
import styles from '../../floating-inventories.module.scss';

const EVENT_LOG_TABLE_HEIGHT = 224;

type TableData = {
	id: number;
	ownerId: number;
	custody: string;
	details: string;
	ownerType: FloatingInventoryOwnerType;
	eventType: FloatingInventoryHistoryEventTypes;
};

type EventLogTableProps = {
	isLoading: boolean;
	floatingInventoryHistories: FloatingInventoryHistory[];
	lastUpdated: string;
};

const EventLogTable = ({ isLoading, floatingInventoryHistories, lastUpdated }: EventLogTableProps) => {
	const { t } = useTranslation();
	const dateFormat = useDateTimeFormat();

	const renderOwnerName = (_, record: TableData) => (
		<OwnerName id={record.ownerId} ownerType={record.ownerType as FloatingInventoryOwnerType} />
	);

	const getColumns = () => [
		{
			title: t('FLOATING_INVENTORY.EVENT_LOG.TABLE_COLUMNS.DATE'),
			dataIndex: 'date',
			className: 'date',
			render: dateRenderer,
			ellipsis: true,
			width: '17%'
		},
		{
			title: t('FLOATING_INVENTORY.EVENT_LOG.TABLE_COLUMNS.OWNER_NAME'),
			dataIndex: 'ownerId',
			className: 'owner',
			ellipsis: true,
			render: renderOwnerName
		},
		{
			title: t('FLOATING_INVENTORY.EVENT_LOG.TABLE_COLUMNS.CUSTODY'),
			dataIndex: 'custody',
			className: 'custody',
			ellipsis: true,
			width: '13%'
		},
		{
			title: t('FLOATING_INVENTORY.EVENT_LOG.TABLE_COLUMNS.DETAILS'),
			dataIndex: 'details',
			className: 'details',
			ellipsis: true,
			width: '47.5%'
		}
	];

	const normalizedTableData = floatingInventoryHistories.map(log => {
		const ownerType = getOwnerType({ userId: log.user_id, teamId: log.team_id });
		return {
			id: log.offset,
			date: log.happened_at,
			ownerId: log.user_id || log.team_id,
			custody: t(custodyTranslations[log.custody]),
			ownerType,
			eventType: log.event_type,
			details: getEventLogDetailsTranslation({
				t,
				eventType: log.event_type,
				custody: log.custody,
				ownershipOrigin: log.ownership_origin,
				ownerType
			})
		} as TableData;
	});

	return (
		<div data-test-id={'event-log-table-container'}>
			<div className={styles['floating-inventories-event-log-table-last-update']}>
				<span className={styles['floating-inventories-event-log-last-update']}>
					{t('FLOATING_INVENTORY.EVENT_LOG.LAST_UPDATED')}
				</span>
				{DateDisplay({ date: lastUpdated, format: dateFormat })}
			</div>
			<Table
				loading={isLoading}
				className={!isLoading && styles['floating-inventories-event-log-table']}
				columns={getColumns()}
				rowKey="id"
				rowClassName={record => `${record.eventType}-${record.id}`}
				dataSource={normalizedTableData}
				scroll={{ y: EVENT_LOG_TABLE_HEIGHT }}
			/>
		</div>
	);
};

export default EventLogTable;
