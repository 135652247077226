import i18next from 'i18next';
import moment from 'moment';
import parser from 'cron-parser';
import { AttributeDatatype, EventTriggerTasksManualParams, TriggerParamsValueType } from '@bringg/types';

import { StringTriggerValues, triggerChangeNoValuesOptions, TriggerContainer } from '../../utils/types';
import { ContentArrayType } from './workflow-content-generator';
import {
	addPath,
	fieldValuesMapper,
	getChangedTranslations,
	getNumericOperatorTranslation,
	stringValueMapper
} from './utils';
import { when, every, at, manual, withInputs } from '../use-automation-translations';
import TriggerStore from '../../stores/trigger-store';
import { getFormatByMerchant } from '../helpers';
import { getDayDisplayValue } from 'bringg-web/features/automation-workflows/triggers/recurring/cron-helper';

export const triggerMapper = (trigger: TriggerStore, isFull): ContentArrayType[] => {
	if (!trigger.findTriggerMetadata) {
		return [];
	}

	let content;
	switch (trigger.findTriggerMetadata.family) {
		case TriggerContainer.Event_Happened:
			content = [
				{
					title: i18next.t(when),
					isPale: true
				},
				...eventHappenedMapper(trigger, isFull)
			];
			break;

		case TriggerContainer.Date_Arrived:
			content = dateArrivedMapper(trigger);
			break;

		case TriggerContainer.Field_Changed:
			content = [
				{
					title: i18next.t(when),
					isPale: true
				},
				...fieldChangedMapper(trigger, isFull)
			];
			break;
		case TriggerContainer.Recurring:
			content = recurringMapper(trigger);
			break;

		case TriggerContainer.Manual:
			content = [
				{
					title: i18next.t(when),
					isPale: true
				},
				{
					title: i18next.t(manual),
					isPale: true
				},
				...manualMapper(trigger)
			];
			break;

		default:
			content = [];
			break;
	}
	return content;
};

const getChangeTo = (change: TriggerParamsValueType): ContentArrayType => {
	const title = getChangedTranslations(change);
	return {
		title,
		isPale: triggerChangeNoValuesOptions.has(change)
	};
};

const eventHappenedMapper = (trigger: TriggerStore, isFull): ContentArrayType[] => {
	const content: ContentArrayType[] = [addPath(trigger.findTriggerMetadata.display_path)];

	if (trigger.linkingKey) {
		const linkingKey = {
			title: trigger.linkingKey,
			isPale: true
		};

		if (trigger.datatype === AttributeDatatype.String) {
			trigger.value != null &&
				content.push(...stringValueMapper(trigger.value as StringTriggerValues, linkingKey));
		} else {
			content.push(linkingKey);
			content.push(fieldValuesMapper(trigger.value as number[], trigger.findTriggerMetadata?.values, isFull));
		}
	}

	return content;
};

const manualMapper = (trigger: TriggerStore): ContentArrayType[] => {
	const content: ContentArrayType[] = [{ title: (trigger as EventTriggerTasksManualParams).value?.name }];
	if (trigger.dynamicVariables.length) {
		content.push({
			title: i18next.t(withInputs),
			isPale: true
		});
		trigger.dynamicVariables.forEach((manualField, index, arr) => {
			const isLast = index === arr.length - 1;
			content.push({
				title: `${manualField.name}${isLast ? '' : ', '}`
			});
		});
	}
	return content;
};

const recurringMapper = (trigger: TriggerStore): ContentArrayType[] => {
	const cronExp = parser.parseExpression((trigger.value as string) || '');
	const format = getFormatByMerchant();

	const time = moment({
		minutes: cronExp.fields.minute[0],
		hours: cronExp.fields.hour[0]
	});

	return [
		{
			title: i18next.t(every),
			isPale: true
		},
		{
			title: getDayDisplayValue(cronExp)
		},
		{
			title: i18next.t(at),
			isPale: true
		},
		{
			title: time.format(format)
		}
	];
};

const dateArrivedMapper = (trigger: TriggerStore): ContentArrayType[] => {
	const relative = i18next.t(
		trigger.relative === -1 ? 'PROGRAMMABLE_TRIGGERS.BEFORE' : 'PROGRAMMABLE_TRIGGERS.AFTER'
	);
	const content = [];

	content.push(
		{
			title: `${Math.abs(trigger.value as number)}`
		},
		{
			title: trigger.units
		},
		{
			title: relative
		},
		addPath(trigger.findTriggerMetadata.display_path)
	);

	return content;
};

const fieldChangedNumberMapper = (trigger: TriggerStore): ContentArrayType[] => {
	const content: ContentArrayType[] = [getChangeTo(trigger.changedTo)];

	if (triggerChangeNoValuesOptions.has(trigger.changedTo)) {
		return content;
	}

	content.push(
		{
			title: getNumericOperatorTranslation(trigger.operator)
		},
		{
			title: `${trigger.value}`
		}
	);

	return content;
};

const fieldChangedBooleanMapper = (trigger: TriggerStore): ContentArrayType[] => {
	const isNotChanged = !triggerChangeNoValuesOptions.has(trigger.changedTo);
	const content: ContentArrayType[] = [];

	content.push(getChangeTo(trigger.changedTo));

	if (isNotChanged) {
		content.push({
			title: trigger.mappedValue as string
		});
	}

	return content;
};

const fieldChangedMapper = (trigger: TriggerStore, isFull = false) => {
	const content: ContentArrayType[] = [addPath(trigger.findTriggerMetadata.display_path)];
	const isChanged = triggerChangeNoValuesOptions.has(trigger.changedTo);

	if (trigger.findTriggerMetadata.values) {
		const mappedValue = fieldValuesMapper(trigger.value as number[], trigger.findTriggerMetadata?.values, isFull);
		content.push(getChangeTo(trigger.changedTo));

		if (mappedValue.title) {
			content.push({
				...mappedValue
			});
		}

		return content;
	}

	switch (trigger.datatype) {
		case AttributeDatatype.Boolean:
			content.push(...fieldChangedBooleanMapper(trigger));
			break;

		case AttributeDatatype.Number:
			content.push(...fieldChangedNumberMapper(trigger));
			break;

		case AttributeDatatype.Datetime:
			content.push(...fieldChangedNumberMapper(trigger));
			if (!isChanged) {
				content.push({ title: trigger.units });
			}
			break;

		default:
			content.push(getChangeTo(trigger.changedTo));
			break;
	}

	return content;
};
