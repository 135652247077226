import { Authentication, DashboardSdk, Environment, CredentialsStorageType } from '@bringg/frontend-utils';
import { ConfigKeys } from '@bringg/types';
import { getEnv } from 'mobx-easy';

import { AppConfig } from './config';
import { GeoCodingService } from './geocoding-service';
import { LocaleService } from './locale-service';

export interface RootEnv {
	localeService: LocaleService;
	dashboardSdk: DashboardSdk;
	authService: Authentication;
	geocodingService: GeoCodingService;
	envConfig: Environment;
}

export const createRootEnv = async (): Promise<RootEnv> => {
	const envConfig: Environment = await AppConfig.getEnvironmentConfig();
	// @ts-ignore
	const commonRegionConfigKeys: ConfigKeys = window['COMMON_REGION_CONFIG_KEYS'];

	const dashboardSdk = new DashboardSdk({
		config: envConfig,
		appName: 'Bringg Dashboard',
		commonRegionConfigKeys
	});
	const authService = new Authentication(CredentialsStorageType.LocalStorage, dashboardSdk);
	const geocodingService = new GeoCodingService(dashboardSdk);
	const localeService = new LocaleService();

	return {
		localeService,
		dashboardSdk,
		authService,
		envConfig,
		geocodingService
	};
};

export function getRootEnv() {
	return getEnv<RootEnv>();
}
