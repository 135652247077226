import React, { Component } from 'react';
import { Col, Row } from 'antd';
import { BringgFontIcons, BringgIcon } from '@bringg/bringg-icons';
import _map from 'lodash/map';
import { observer } from 'mobx-react';
import Translate from '../../../translation/translator';
import { Select, Button, Option, PopOver } from '../../../components';
import Crew from '../../../stores/crew/domain-objects/crew';

interface Props {
	crew?: Crew;
}

interface State {
	editMode: boolean;
}

class CrewsCard extends Component<Props, State> {
	state = {
		editMode: false
	};

	componentDidMount() {
		if (!this.props.crew.id) {
			this.setState({ editMode: true });
		}
	}

	toggleEdit = () => {
		if (this.state.editMode) {
			this.props.crew.reset();
		}

		this.setState(prev => ({ editMode: !prev.editMode }));
	};

	confirmDelete = (): void => {
		this.props.crew.delete();
	};

	onCancel = () => {
		if (this.props.crew.id) {
			this.toggleEdit();
		} else {
			this.props.crew.cancel();
		}
	};

	onSave = () => {
		this.props.crew.save();
		this.setState({ editMode: false });
	};

	mapTeamOptions = () => {
		return _map(this.props.crew.availableDriversByTeam, ({ id, name }) => {
			return (
				<Option title={name} key={id.toString()} value={id}>
					{name}
				</Option>
			);
		});
	};

	render() {
		const { crew } = this.props;
		const isNewCrew = !crew.id;
		const { editMode } = this.state;

		return (
			<div className="crews-card-container crews-space-top">
				<Row>
					<Col span={3}>
						{crew ? (
							<span className="crews-card-id">
								<Translate text="CREWS.ID" />
								<span className="crew-card-id-text">{crew.id}</span>
							</span>
						) : null}
						<BringgIcon iconName={BringgFontIcons.Menu} style={{ color: '#cfcfcf' }} />
					</Col>
					<Col span={3}>
						<span className="crews-primary-driver card-input-widths">
							<span className="crews-title">
								<Translate text="CREWS.PRIMARY_DRIVER" />
							</span>
							<Select
								onSelect={crew.setPrimaryDriver}
								disabled={!editMode}
								size="small"
								value={crew && crew.primary_driver ? crew.primary_driver.name : null}
							>
								{this.mapTeamOptions()}
							</Select>
						</span>
					</Col>
					<Col span={4}>
						<span className="crews-secondary-drivers card-input-widths">
							<span className="crews-title">
								<Translate text="CREWS.ADDITIONAL_DRIVERS" />
							</span>
							<Select
								onChange={crew.setSecondaryDrivers}
								disabled={!editMode}
								size="small"
								mode="multiple"
								value={crew ? crew.drivers.map(driver => driver.id) : []}
							>
								{this.mapTeamOptions()}
							</Select>
						</span>
					</Col>

					<Col span={4}>
						<span className="card-input-widths">
							<span className="crews-title">
								<Translate text="CREWS.PLANNED_ROUTE" />
							</span>
							<Select
								onChange={crew.setPlannedRoute}
								disabled={!editMode}
								size="small"
								value={crew && crew.planned_route ? crew.planned_route.title : null}
							>
								{_map(crew.getPlannedRoutes, plannedRoute => (
									<Option title={plannedRoute.title} key={plannedRoute.id} value={plannedRoute.id}>
										{plannedRoute.title}
									</Option>
								))}
							</Select>
						</span>
					</Col>

					<Col span={4} push={6}>
						<span className="card-buttons">
							<span className="crews-card-separator">
								{' '}
								<BringgIcon iconName={BringgFontIcons.Menu} style={{ color: '#cfcfcf' }} />
							</span>
							<span>
								{isNewCrew ? (
									<Button className="card-apply-btn" onClick={this.onSave}>
										<Translate text="CREWS.CREATE" />
									</Button>
								) : null}
								{!isNewCrew && editMode ? (
									<Button className="card-apply-btn" onClick={this.onSave}>
										<Translate text="CREWS.APPLY" />
									</Button>
								) : null}
								{isNewCrew || editMode ? (
									<Button className="card-cancel-btn" onClick={this.onCancel}>
										<Translate text="CREWS.CANCEL" />
									</Button>
								) : null}
							</span>
							{!isNewCrew && !editMode ? (
								<span>
									<Button className="card-edit-btn" onClick={this.toggleEdit}>
										<BringgIcon iconName={BringgFontIcons.Pencil} />
									</Button>
								</span>
							) : null}
							<PopOver
								trigger="click"
								title={<Translate text="CREWS.CONFIRM" />}
								placement="topRight"
								content={
									<Button onClick={this.confirmDelete} danger>
										<Translate text="CREWS.DELETE" />
									</Button>
								}
							>
								{!isNewCrew && (
									<Button className="card-delete-btn">
										<BringgIcon iconName={BringgFontIcons.Trash} />
									</Button>
								)}
							</PopOver>
						</span>
					</Col>
				</Row>
			</div>
		);
	}
}

export default observer(CrewsCard);
