import MerchantConfiguration from 'bringg-web/stores/merchant-configurations/domain-objects/merchant-configuration';

export const hasConfiguration = (
	merchantConfiguration?: MerchantConfiguration,
	privilegeName?: keyof MerchantConfiguration
): boolean => {
	if (!merchantConfiguration) {
		return false;
	}

	return Boolean(merchantConfiguration[privilegeName]);
};
