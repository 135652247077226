import {
	RUN_HISTORY_TYPES,
	HOME_EVENT_TYPES,
	OFFLINE_ONLINE_EVENT_TYPES,
	SHIFT_TYPES,
	PREPARATION_STATUS,
	TASK_STATUS
} from '../features/run/run.consts';
import moment from 'moment';
import { invert } from 'lodash';
import { RunHistoryEvent } from '@bringg/types';
import i18next from 'i18next';

enum TYPE_CLASS {
	UNKNOWN = 0,
	ALL = 1,
	COMMENT = 2,
	INFO = 3,
	CHECKOUT = 4,
	CHECKIN = 5,
	SHARE_LOCATION = 6
}

class TranslationService {
	getTaskHistoryDescription = (task_change_history: any): string => {
		// make sure it's a status change just in case
		task_change_history.descriptionText = 'unknown';
		task_change_history.typeClass = TYPE_CLASS.INFO;

		if (task_change_history.changed_field === 'status') {
			var old_value = parseInt(task_change_history.old_value, 10);
			var new_value = parseInt(task_change_history.new_value, 10);
			switch (old_value) {
				case TASK_STATUS.CREATED: //created
					if (new_value === TASK_STATUS.CREATED) {
						task_change_history.descriptionText = i18next.t('ACTIVITY_LOG.CREATED');
					} else if ([TASK_STATUS.ASSIGNED, TASK_STATUS.ACCEPTED].indexOf(new_value) !== -1) {
						if (task_change_history.assigned_user_name) {
							task_change_history.descriptionText =
								i18next.t('ACTIVITY_LOG.ASSIGNED_TO') + ' ' + task_change_history.assigned_user_name;
						} else {
							task_change_history.descriptionText = i18next.t('ACTIVITY_LOG.ASSIGNED');
						}
					} else if ([TASK_STATUS.ASSIGNED, TASK_STATUS.REJECTED].indexOf(new_value) !== -1) {
						task_change_history.descriptionText = i18next.t('ACTIVITY_LOG.REJECTED_TAKEN_FROM_DRIVER');
					} else if (new_value === TASK_STATUS.CHECKED_IN) {
						task_change_history.descriptionText = i18next.t('ACTIVITY_LOG.ARRIVED');
						task_change_history.typeClass = TYPE_CLASS.CHECKIN;
					}
					break;
				case TASK_STATUS.ASSIGNED:
					if (TASK_STATUS.ACCEPTED === new_value) {
						if (task_change_history.assigned_user_name) {
							task_change_history.descriptionText =
								i18next.t('ACTIVITY_LOG.ACCEPTED_BY') + ' ' + task_change_history.assigned_user_name;
						} else {
							task_change_history.descriptionText = i18next.t('ACTIVITY_LOG.ACCEPTED');
						}
					} else if (TASK_STATUS.REJECTED === new_value) {
						if (task_change_history.assigned_user_name) {
							task_change_history.descriptionText =
								i18next.t('ACTIVITY_LOG.ORDER_REJECTED_BY') +
								' ' +
								task_change_history.assigned_user_name;
						} else {
							task_change_history.descriptionText = i18next.t('ACTIVITY_LOG.ORDER_REJECTED');
						}
					} else if (TASK_STATUS.CREATED === new_value) {
						if (task_change_history.assigned_user_name) {
							task_change_history.descriptionText =
								i18next.t('ACTIVITY_LOG.REMOVED_FROM_DRIVER') +
								' - ' +
								task_change_history.assigned_user_name;
						} else {
							task_change_history.descriptionText = i18next.t('ACTIVITY_LOG.REMOVED_FROM_DRIVER');
						}
					}
					break;
				case TASK_STATUS.ACCEPTED:
					if (TASK_STATUS.ON_THE_WAY === new_value) {
						if (task_change_history.assigned_user_name) {
							task_change_history.descriptionText =
								i18next.t('ACTIVITY_LOG.STARTED_BY') + ' ' + task_change_history.assigned_user_name;
						} else {
							task_change_history.descriptionText = i18next.t('ACTIVITY_LOG.STARTED');
						}
					}
					if (TASK_STATUS.ASSIGNED === new_value) {
						if (task_change_history.assigned_user_name) {
							task_change_history.descriptionText =
								i18next.t('ACTIVITY_LOG.REASSIGNED_TO') + ' ' + task_change_history.assigned_user_name;
						} else {
							task_change_history.descriptionText = i18next.t('ACTIVITY_LOG.REASSIGNED');
						}
					}
					break;
				case TASK_STATUS.ON_THE_WAY:
					if (TASK_STATUS.CHECKED_IN === new_value) {
						task_change_history.descriptionText = i18next.t('ACTIVITY_LOG.ARRIVED');
						task_change_history.typeClass = TYPE_CLASS.CHECKIN;
					}
					break;
				case TASK_STATUS.CHECKED_IN:
					if (TASK_STATUS.DONE === new_value) {
						task_change_history.descriptionText = i18next.t('ACTIVITY_LOG.LEFT');
						task_change_history.typeClass = TYPE_CLASS.CHECKOUT;
					} else if (TASK_STATUS.ON_THE_WAY === new_value) {
						task_change_history.descriptionText = i18next.t('ACTIVITY_LOG.LEFT');
						task_change_history.typeClass = TYPE_CLASS.CHECKOUT;
					}
					break;
				case TASK_STATUS.CANCELLED:
					task_change_history.descriptionText = i18next.t('ACTIVITY_LOG.CANCELLED');
					break;
				case TASK_STATUS.REJECTED:
					task_change_history.descriptionText = i18next.t('ACTIVITY_LOG.REJECTED');
					break;
				case TASK_STATUS.UNACKNOWLEDGED:
					if (TASK_STATUS.CREATED === new_value) {
						task_change_history.descriptionText = i18next.t('ACTIVITY_LOG.ORDER_ACKNOWLEDGED');
					}
					break;
			}

			if (task_change_history.descriptionText === 'unknown') {
				switch (new_value) {
					case TASK_STATUS.CREATED:
						task_change_history.descriptionText = i18next.t('ACTIVITY_LOG.ORDER_CREATED');
						break;
					case TASK_STATUS.ASSIGNED:
						if (task_change_history.assigned_user_name) {
							task_change_history.descriptionText =
								i18next.t('ACTIVITY_LOG.ORDER_REASSIGNED_TO') +
								' ' +
								task_change_history.assigned_user_name;
						} else {
							task_change_history.descriptionText = i18next.t('ACTIVITY_LOG.ORDER_REASSIGNED');
						}
						break;
					case TASK_STATUS.DONE:
						task_change_history.descriptionText = i18next.t('ACTIVITY_LOG.ORDER_DONE');
						break;
					case TASK_STATUS.CANCELLED:
						task_change_history.descriptionText = i18next.t('ACTIVITY_LOG.ORDER_CANCELLED');
						break;
					case TASK_STATUS.ON_THE_WAY:
						task_change_history.descriptionText = i18next.t('ACTIVITY_LOG.ORDER_ON_THE_WAY');
						break;
					case TASK_STATUS.UNACKNOWLEDGED:
						task_change_history.descriptionText = i18next.t('ACTIVITY_LOG.ORDER_UNACKNOWLEDGED');
						break;
					case TASK_STATUS.CHECKED_IN:
						task_change_history.descriptionText = i18next.t('ACTIVITY_LOG.ARRIVED');
						task_change_history.typeClass = TYPE_CLASS.CHECKIN;
						break;
				}
			}
		} else if (task_change_history.changed_field === 'tip') {
			task_change_history.descriptionText =
				i18next.t('ACTIVITY_LOG.CLIENT_SUBMIT_TIP') + ' : ' + task_change_history.new_value;
		} else if (task_change_history.event === 1 || task_change_history.event === 26) {
			// application event or webhooks_event
			task_change_history.descriptionText = task_change_history.changed_field;
		} else if (task_change_history.event === 2) {
			// postpone notification
			task_change_history.descriptionText = i18next.t('ACTIVITY_LOG.POSTPONED_OR_REJECTED');
		} else if (task_change_history.event === 3) {
			// late notification
			if (
				task_change_history.old_value === 'true' ||
				task_change_history.old_value === 't' ||
				task_change_history.old_value === true
			) {
				task_change_history.descriptionText = i18next.t('ACTIVITY_LOG.MARKED_ON_TIME');
			} else {
				task_change_history.descriptionText = i18next.t('ACTIVITY_LOG.MARKED_LATE');
				if (task_change_history.new_value.length > 10) {
					// to support old histories as well 24/05/2015
					task_change_history.descriptionText += ' (' + task_change_history.new_value + ')';
				}
			}
		} else if (task_change_history.event === 4) {
			// Delivery person pressed not now
			task_change_history.descriptionText =
				'(' + task_change_history.new_value + ')' + i18next.t('ACTIVITY_LOG.DRIVER_NOT_ACCEPT_ASK_REMINDER');
		} else if (task_change_history.event === 5) {
			// shared_location_sent
			task_change_history.descriptionText =
				i18next.t('ACTIVITY_LOG.SHARED_WITH_CUSTOMER') +
				' (' +
				task_change_history.old_value +
				') ' +
				i18next.t('ACTIVITY_LOG.AT') +
				' ' +
				task_change_history.changed_field;
			task_change_history.typeClass = TYPE_CLASS.SHARE_LOCATION;
			if (task_change_history.new_value) {
				task_change_history.descriptionText += ': ' + task_change_history.new_value;
			}
		} else if (task_change_history.event === 6) {
			// client alert
			var alertType;
			switch (task_change_history.changed_field) {
				case '1': //geolocation
					alertType = i18next.t('ACTIVITY_LOG.GEOLOCATION');
					break;
				case '2': //unaccepted
					alertType = i18next.t('ACTIVITY_LOG.DRIVER_PRESSED_REJECT');
					break;
				case '6': //stays_too_long_on_site
					alertType = i18next.t('ACTIVITY_LOG.DRIVER_TOO_LONG_ON_SITE');
					break;
				default:
					alertType = i18next.t('ACTIVITY_LOG.OTHER_ALERT') + ' (' + task_change_history.changed_field + ')';
			}
			task_change_history.descriptionText = i18next.t('ACTIVITY_LOG.CLIENT_ALERT') + ': [' + alertType + ']';
		} else if (task_change_history.event === 7) {
			// estimations updated
			task_change_history.descriptionText =
				task_change_history.changed_field + ': ' + moment.utc(task_change_history.new_value).toDate();
		} else if (task_change_history.event === 8) {
			// marked as done
			task_change_history.descriptionText =
				i18next.t('ACTIVITY_LOG.MARKED_AS_DONE') + ': ' + task_change_history.changed_field;
		} else if (task_change_history.event === 9) {
			// activity_change
			var activityName = 'Unknown';
			switch (task_change_history.changed_field) {
				case '5':
					activityName = i18next.t('ACTIVITY_LOG.DRIVING');
					break;
				case '4':
					activityName = i18next.t('ACTIVITY_LOG.BICYCLING');
					break;
				case '2':
					activityName = i18next.t('ACTIVITY_LOG.WALKING');
					break;
				case '3':
					activityName = i18next.t('ACTIVITY_LOG.RUNNING');
					break;
				case '1':
					activityName = i18next.t('ACTIVITY_LOG.STATIONARY');
					break;
			}
			task_change_history.descriptionText =
				i18next.t('ACTIVITY_LOG.CHANGED_REPORTED_ACTIVITY') + ': ' + activityName;
		} else if (task_change_history.event === 10) {
			// admiistrator notification
			task_change_history.descriptionText =
				i18next.t('ACTIVITY_LOG.ADMIN_NOTIFIED') + ': ' + task_change_history.changed_field;
		} else if (task_change_history.event === 11) {
			// driver rated
			task_change_history.descriptionText =
				i18next.t('ACTIVITY_LOG.DRIVER_RATED') +
				': ' +
				task_change_history.changed_field +
				' ' +
				i18next.t('ACTIVITY_LOG.STARS');
		} else if (task_change_history.event === 12) {
			// shared location cancelled
			task_change_history.descriptionText =
				i18next.t('ACTIVITY_LOG.SHARING_LOCATION_CANCELLED') + ': ' + task_change_history.changed_field;
		} else if (task_change_history.event === 13) {
			// customer activity
			task_change_history.descriptionText =
				i18next.t('ACTIVITY_LOG.CUSTOMER_ETA_UPDATE') +
				' : ' +
				task_change_history.old_value +
				', driver is: ' +
				task_change_history.new_value;
		} else if (task_change_history.event === 14) {
			// driver rejected
			task_change_history.descriptionText =
				i18next.t('ACTIVITY_LOG.ORDER_REJECTED_BY') + ' ' + task_change_history.new_value;
		} else if (task_change_history.event === 15) {
			// driver dismissed
			task_change_history.descriptionText =
				i18next.t('ACTIVITY_LOG.ORDER_DISMISSED_BY') + ' ' + task_change_history.new_value;
		} else if (task_change_history.event === 17) {
			// customer added note
			task_change_history.descriptionText = i18next.t('ACTIVITY_LOG.CUSTOMER_ADDED_NOTE');
		} else if (task_change_history.event === 18) {
			// driver lost GPS
			task_change_history.descriptionText = i18next.t('ACTIVITY_LOG.DRIVER_LOST_GPS_SIGNAL');
		} else if (task_change_history.event === 19) {
			// driver regained GPS
			task_change_history.descriptionText = i18next.t('ACTIVITY_LOG.DRIVER_REGAINED_GPS_SIGNAL');
		} else if (task_change_history.event === 20) {
			// client_sent_tip
			task_change_history.descriptionText = i18next.t('ACTIVITY_LOG.CUSTOMER_ADDED_TIP');
		} else if (task_change_history.event === 21) {
			// driver_lost_gps_reception
			task_change_history.descriptionText = i18next.t('ACTIVITY_LOG.DRIVER_LOST_GPS_SIGNAL');
		} else if (task_change_history.event === 22) {
			// driver_found_gps_reception
			task_change_history.descriptionText = i18next.t('ACTIVITY_LOG.DRIVER_FOUND_GPS_SIGNAL');
		} else if (task_change_history.event === 23) {
			// auto_dispatch_no_drivers_found
			task_change_history.descriptionText = i18next.t('ACTIVITY_LOG.NO_DRIVERS_FOUND');
		} else if (task_change_history.event === 24) {
			// task started
			task_change_history.descriptionText =
				i18next.t('ACTIVITY_LOG.ORDER_STARTED_DISTANCE_TO_FIRST_STOP') +
				': ' +
				task_change_history.old_value +
				'' +
				i18next.t('ACTIVITY_LOG.KM');
		} else if (task_change_history.event === 25) {
			// task cancelled
			task_change_history.descriptionText =
				i18next.t('ACTIVITY_LOG.ORDER_CANCELLED') + ': ' + task_change_history.changed_field;
		} else if (task_change_history.event === 26) {
			// webhooks event
			task_change_history.descriptionText = i18next.t('ACTIVITY_LOG.WEBHOOKS_EVENT');
		} else if (task_change_history.event === 27) {
			// checking out geofence
			task_change_history.descriptionText = i18next.t('ACTIVITY_LOG.DRIVER_CHECKIN_FAR_FROM_LOCATON');
		} else if (task_change_history.event === 28) {
			// shared via push
			task_change_history.descriptionText = i18next.t('ACTIVITY_LOG.SHARED_VIA_PUSH');
		} else if (task_change_history.event === 29) {
			// customer called driver
			task_change_history.descriptionText = i18next.t('ACTIVITY_LOG.CUSTOMER_CALLED_DRIVER');
		} else if (task_change_history.event === 30) {
			// driver called customer
			task_change_history.descriptionText = i18next.t('ACTIVITY_LOG.DRIVER_CALLED_CUSTOMER');
		} else if (task_change_history.event === 31) {
			// customer smsed driver
			task_change_history.descriptionText = i18next.t('ACTIVITY_LOG.CUSTOMER_SMSED_DRIVER');
		} else if (task_change_history.event === 32) {
			// driver smsed customer
			task_change_history.descriptionText = i18next.t('ACTIVITY_LOG.DRIVER_SMSED_CUSTOMER');
		} else if (task_change_history.event === 33) {
			// driver smsed customer
			task_change_history.descriptionText =
				i18next.t('ACTIVITY_LOG.CUSTOMER_ETA_UPDATE') +
				' : ' +
				task_change_history.old_value +
				', driver is: ' +
				task_change_history.new_value;
		} else if (task_change_history.event === 34) {
			// customer declined forwarded delivery
			task_change_history.descriptionText = i18next.t('ACTIVITY_LOG.FORWARDING_DECLINED');
		} else if (task_change_history.event === 35) {
			// customer accepted forwarded delivery
			task_change_history.descriptionText = i18next.t('ACTIVITY_LOG.FORWARDING_ACCEPTED');
		} else if (task_change_history.event === 36) {
			// customer accepted forwarded delivery
			task_change_history.descriptionText = i18next.t('ACTIVITY_LOG.TASK_RESCHEDULED');
		} else if (task_change_history.event === 37) {
			// customer accepted forwarded delivery
			task_change_history.descriptionText = i18next.t('ACTIVITY_LOG.DISTANCE_TRAVELED_UPDATED_MANUALLY');
		} else if (task_change_history.event === 40) {
			// driver pressed "not now"
			task_change_history.descriptionText = i18next.t('ACTIVITY_LOG.NOTNOW_TASK');
		} else if (task_change_history.event === 41) {
			// task changed preparation status
			var keyToStatus = invert(PREPARATION_STATUS);
			var oldPrepStatus = keyToStatus[parseInt(task_change_history.old_value, 10)] || 'Unknown';
			var newPrepStatus = keyToStatus[parseInt(task_change_history.new_value, 10)] || 'Unknown';
			task_change_history.descriptionText =
				i18next.t('ACTIVITY_LOG.PREPARATION_STATUS_CHANGED') + ': ' + oldPrepStatus + ' to ' + newPrepStatus;
		} else if (task_change_history.event === 42) {
			// task broadcasted
			task_change_history.descriptionText = i18next.t('ACTIVITY_LOG.BROADCASTED');
		} else if (task_change_history.event === 43) {
			// route optimization ran on a task
			task_change_history.descriptionText = i18next.t('ACTIVITY_LOG.ROUTE_OPTIMIZED');
		} else if (task_change_history.event === 49) {
			// WAY_POINT_SLA_CHANGED
			task_change_history.descriptionText = i18next.t('ACTIVITY_LOG.WAY_POINT_SLA_CHANGED');

			if (task_change_history.data && task_change_history.data.reason) {
				task_change_history.descriptionText +=
					' ' +
					i18next.t('ACTIVITY_LOG.WAY_POINT_SLA_CHANGED_REASON') +
					' ' +
					task_change_history.data.reason;
			}
		}

		return task_change_history.descriptionText;
	};

	getShiftDescription = (shiftEvent: RunHistoryEvent): string => {
		if (shiftEvent.event_sub_type === SHIFT_TYPES.START) {
			return i18next.t('RUN.SHIFT_START_EVENT');
		}
		if (shiftEvent.event_sub_type === SHIFT_TYPES.END) {
			return i18next.t('RUN.SHIFT_END_EVENT');
		}

		return i18next.t('RUN.BAD_SHIFT_EVENT');
	};

	getOfflineOnlineDescription = (shiftEvent: RunHistoryEvent): string => {
		if (shiftEvent.event_sub_type === OFFLINE_ONLINE_EVENT_TYPES.ONLINE) {
			return i18next.t('RUN.ONLINE_EVENT');
		}
		if (shiftEvent.event_sub_type === OFFLINE_ONLINE_EVENT_TYPES.OFFLINE) {
			return i18next.t('RUN.OFFLINE_EVENT');
		}

		return i18next.t('RUN.BAD_OFFLINE_ONLINE_EVENT');
	};

	getHomeEventDescription = (shiftEvent: RunHistoryEvent): string => {
		if (shiftEvent.event_sub_type === HOME_EVENT_TYPES.ARRIVED) {
			return i18next.t('RUN.HOME_ARRIVED_EVENT');
		}
		if (shiftEvent.event_sub_type === HOME_EVENT_TYPES.LEFT) {
			return i18next.t('RUN.HOME_LEFT_EVENT');
		}

		return i18next.t('RUN.BAD_HOME_EVENT');
	};

	getAlertDescripition = (shiftEvent: RunHistoryEvent): string => {
		return i18next.t('RUN.LOW_BATTERY_ALERT');
	};

	getText = (event: RunHistoryEvent): string => {
		if (event.event_type === RUN_HISTORY_TYPES.TASK_HISTORY) {
			return this.getTaskHistoryDescription(event.data);
		}
		if (event.event_type === RUN_HISTORY_TYPES.SHIFT) {
			return this.getShiftDescription(event);
		}
		if (event.event_type === RUN_HISTORY_TYPES.OFFLINE_ONLINE_EVENT) {
			return this.getOfflineOnlineDescription(event);
		}
		if (event.event_type === RUN_HISTORY_TYPES.HOME_EVENT) {
			return this.getHomeEventDescription(event);
		}
		if (event.event_type === RUN_HISTORY_TYPES.ALERT) {
			return this.getAlertDescripition(event);
		}

		return event.text || '';
	};

	getEventType = (event: RunHistoryEvent): string => {
		if (event.event_type === RUN_HISTORY_TYPES.TASK_HISTORY) {
			return i18next.t('RUN.TASK_HISTORY_EVENT');
		}
		if (event.event_type === RUN_HISTORY_TYPES.SHIFT) {
			return i18next.t('RUN.SHIFT_EVENT');
		}
		if (event.event_type === RUN_HISTORY_TYPES.OFFLINE_ONLINE_EVENT) {
			return i18next.t('RUN.OFFLINE_ONLINE_EVENT');
		}
		if (event.event_type === RUN_HISTORY_TYPES.HOME_EVENT) {
			return i18next.t('RUN.HOME_EVENT');
		}
		if (event.event_type === RUN_HISTORY_TYPES.ALERT) {
			return i18next.t('RUN.ALERT_EVENT');
		}

		return '';
	};
}

export default new TranslationService();
