import { getRootEnv } from '@bringg-frontend/bringg-web-infra';
import { getEnv, getRoot } from 'mobx-easy';
import {
	FloatingInventoriesRequest,
	FloatingInventoriesResponse,
	FloatingInventoryHistoryResponse,
	ApplicationUuid
} from '@bringg/types';
import { CommonOptions } from '@bringg/dashboard-sdk/dist/Core/RouteGenerator';
import { RootEnv } from '@bringg-frontend/bringg-web-infra';

import RootStore from '../root-store';

export const getFloatingInventories = async (
	queryString: FloatingInventoriesRequest,
	commonOptions?: CommonOptions
): Promise<FloatingInventoriesResponse> => {
	return await getRootEnv().dashboardSdk.sdk.v2.floatingInventories.loadAll(queryString, commonOptions);
};

export const getFloatingInventoryHistory = async (
	queryString: {
		id: number;
	},
	commonOptions?: CommonOptions
): Promise<FloatingInventoryHistoryResponse> => {
	return await getRootEnv().dashboardSdk.sdk.v2.floatingInventories.loadHistory(queryString, commonOptions);
};

export const getUsersWithAvailableFloatingInventory = async (taskId: number): Promise<number[]> => {
	const application = getRoot<RootStore>().data.merchantConfigurationsStore.findApplication(
		ApplicationUuid.FloatingInventoryApp
	);
	if (!application || !taskId) return;
	return await getRootEnv().dashboardSdk.sdk.v2.floatingInventories.loadUsersWithAvailableFloatingInventory(taskId);
};
