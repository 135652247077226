import React, { useState } from 'react';

import { useObserver } from 'mobx-react';
import { Tabs } from '@bringg/react-components';
// eslint-disable-next-line import/no-extraneous-dependencies
import SpinnerWrapper from '@bringg/react-components/dist/components/spinner/spinner';
import { Fleet } from '@bringg/react-components/dist/features/fleet-router/rule-form/priority-configuration/priority-configuration';
import { withErrorBoundary } from '@bringg-frontend/bringg-web-infra';
import { DeliveryProvider } from '@bringg/types';

import SettingPageTitle from 'bringg-web/components/settings/setting-page-title/setting-page-title';
import DeliveryFleetRouter from 'bringg-web/features/fleets-configuration/delivery-fleet-router/delivery-fleet-router';
import { State } from './types';
import { useFleetsConfigurationTranslations } from './use-fleet-configuration-translations';
import { Rule } from '../../stores/fleets-configuration/fleets-configurations-store';
import useTeamsById from './use-teams-by-id';
import useFleetConfigurationStore from './use-fleet-configuration-store';
import useStores from '../../recipes/use-stores';
import DeliveryCatalog from '../../components/delivery-catalog/delivery-catalog';
import DeliveryCatalogConnect from '../../components/delivery-catalog-connect/delivery-catalog-connect';
import { hasFeatureFlag } from '../../utils/feature-flags';
import FleetsListComponent from './fleets-list/fleets-list';

import './fleets-configuration.scss';

export const getConflictRulesByTeamId = (selectedRule: Rule, rules: Rule[], selector?: string) => {
	const conflicts = new Map<string, (Rule | string)[]>();
	if (!selectedRule) return conflicts;
	rules.forEach(rule => {
		if (rule.isEnabled && rule.id !== selectedRule.id) {
			Object.keys(rule.selectedTeams).forEach(teamId => {
				const rulesArr = conflicts.get(teamId) || [];
				rulesArr.push(selector ? rule[selector] : rule);
				conflicts.set(teamId, rulesArr);
			});
		}
	});
	return conflicts;
};

const TAB_KEYS = {
	fleetList: 'fleetList',
	deliveryCatalog: 'deliveryCatalog',
	deliveryCatalogConnect: 'deliveryCatalogConnect',
	ruleList: 'ruleList'
};

const getTabItems = ({
	isCatalogConnectEnabled,
	activeTabKey,
	selectedFleet,
	tabsTranslation,
	selectedDeliveryProvider,
	handleOpenDeliveryCatalog,
	handleOpenFleetsList
}) => {
	const tabItems = [];

	if (isCatalogConnectEnabled) {
		tabItems.push({
			label: 'My Delivery Providers',
			key: TAB_KEYS.deliveryCatalogConnect,
			children: activeTabKey === TAB_KEYS.deliveryCatalogConnect && (
				<DeliveryCatalogConnect
					deliveryProvider={selectedDeliveryProvider}
					onOpenDeliveryCatalog={handleOpenDeliveryCatalog}
				/>
			)
		});
	}

	if (!isCatalogConnectEnabled) {
		tabItems.push({
			label: tabsTranslation.conditionEngine,
			key: TAB_KEYS.fleetList,
			children: <FleetsListComponent fleet={selectedFleet} />
		});
	}

	tabItems.push(
		{ label: tabsTranslation.routeSettings, key: TAB_KEYS.ruleList, children: <DeliveryFleetRouter /> },
		{
			label: 'Discover Delivery Providers',
			key: TAB_KEYS.deliveryCatalog,
			children: activeTabKey === TAB_KEYS.deliveryCatalog && (
				<DeliveryCatalog onOpenFleetsList={handleOpenFleetsList} />
			)
		}
	);

	return tabItems;
};

export const FleetsConfiguration: React.FC = () => {
	const { usersStore, merchantConfigurationsStore } = useStores();
	const { currentUser } = usersStore;
	const { fleetsConfigurationStore, state: fleetConfigurationStoreState } = useFleetConfigurationStore();
	const { state: teamsState } = useTeamsById();
	const [selectedFleet, setSelectedFleet] = useState<Fleet>(null);
	const [selectedDeliveryProvider, setSelectedDeliveryProvider] = useState<DeliveryProvider>(null);
	const isCatalogConnectEnabled =
		hasFeatureFlag(currentUser, 'enable_catalog_connect') || merchantConfigurationsStore.deliveryHubMainMenuUI;
	const [activeTabKey, setActiveTabKey] = useState(
		isCatalogConnectEnabled ? TAB_KEYS.deliveryCatalogConnect : TAB_KEYS.fleetList
	);

	const { tabsTranslation } = useFleetsConfigurationTranslations();

	const handleTabClick = (key: string) => {
		// TODO: open selected fleet in fleets tab
		setActiveTabKey(key);
		setSelectedDeliveryProvider(null);
	};

	const handleOpenFleetsList = deliveryProvider => {
		if (isCatalogConnectEnabled) {
			setSelectedDeliveryProvider(deliveryProvider);
			setActiveTabKey(TAB_KEYS.deliveryCatalogConnect);
		} else {
			const { fleets } = fleetsConfigurationStore;
			const fleet = Object.values(fleets).find(({ name }) => name === deliveryProvider.name);

			setSelectedFleet(fleet);
			setActiveTabKey(TAB_KEYS.fleetList);
		}
	};

	const handleOpenDeliveryCatalog = () => {
		setSelectedFleet(null);
		setSelectedDeliveryProvider(null);

		setActiveTabKey(TAB_KEYS.deliveryCatalog);
	};

	return useObserver(() => {
		const states = new Set([teamsState, fleetConfigurationStoreState]);

		if (states.has(State.Idle) || states.has(State.Loading)) {
			return <SpinnerWrapper className="spinner-center" />;
		}

		return (
			<div className="fleets-configuration">
				<SettingPageTitle title="Fleets Settings" />

				<Tabs
					activeKey={activeTabKey}
					onTabClick={handleTabClick}
					className="fleets-tabs"
					items={getTabItems({
						isCatalogConnectEnabled,
						activeTabKey,
						selectedFleet,
						tabsTranslation,
						selectedDeliveryProvider,
						handleOpenDeliveryCatalog,
						handleOpenFleetsList
					})}
				/>
			</div>
		);
	});
};

export default withErrorBoundary(FleetsConfiguration);
