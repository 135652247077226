import { action, computed, makeObservable, observable } from 'mobx';
import i18next from 'i18next';
import { ActionType } from '@bringg/types';
import { ArrayManipulationViewStore } from '@bringg-frontend/utils';

import WorkflowStore from '../../stores/workflow-store';
import { TriggerContainer } from '../../utils/types';
import { getActionGroupHeader, getTriggerFamilyGroupHeader, getTriggerGroupHeader } from './utils/grouping-utils';
import { optimizationAction } from '../../utils/use-automation-translations';
import { workflowsRootStore } from '../../stores/internal';
import { notifyActionOptions } from 'bringg-web/automation-platform-exports';

const filterKeys = {
	kSearch: Symbol('kSearch'),
	kEnabled: Symbol('kEnabled'),
	kTeams: Symbol('kTeams'),
	kTriggerFamily: Symbol('kTriggerFamily'),
	kActions: Symbol('kActions')
};

const sortKeys = {
	kDateCreated: Symbol('kDateCreated'),
	kLastModified: Symbol('kLastModified')
};

// Computed values are not permitted in an enum with string valued members.
export enum AutomationGroupingType {
	NONE = 'none',
	TRIGGER = 'trigger',
	ACTION = 'action',
	TRIGGER_FAMILY = 'trigger-family'
}

class AutomationBoardViewStore extends ArrayManipulationViewStore<WorkflowStore> {
	groupBy: AutomationGroupingType;

	constructor() {
		super();
		makeObservable(this, {
			groupBy: observable,
			setSearchQuery: action,
			setEnabled: action,
			setTeams: action,
			setTriggerFamily: action,
			setActions: action,
			setGroupingBy: action,
			hasSearchQuery: computed
		});

		this.init();
	}

	init() {
		this.setGroupingBy(AutomationGroupingType.NONE);
	}

	get data(): WorkflowStore[] {
		return workflowsRootStore.getStore().workflowRepo.getAll();
	}

	setSearchQuery(query: string | undefined) {
		if (!query) {
			this.removeFilter(filterKeys.kSearch);
			return;
		}

		const lowerCaseQuery = `${query}`.toLowerCase();

		this.setFilter(filterKeys.kSearch, item => {
			return (
				item.title.toLowerCase().includes(lowerCaseQuery) ||
				item.content.fullSentence.toLowerCase().includes(lowerCaseQuery)
			);
		});
	}

	get hasSearchQuery() {
		return this.hasFilter(filterKeys.kSearch);
	}

	setEnabled(isEnabled: boolean | undefined) {
		if (isEnabled === undefined) {
			this.removeFilter(filterKeys.kEnabled);
			return;
		}

		this.setFilter(filterKeys.kEnabled, item => item.enabled === isEnabled);
	}

	setTeams(teamIds: number[]) {
		if (!teamIds?.length) {
			this.removeFilter(filterKeys.kTeams);
			return;
		}

		this.setFilter(
			filterKeys.kTeams,
			item =>
				!item.team_ids || item.team_ids.length === 0 || item.team_ids.some(teamId => teamIds.includes(teamId))
		);
	}

	setTriggerFamily(triggerFamilies: TriggerContainer[]) {
		if (!triggerFamilies?.length) {
			this.removeFilter(filterKeys.kTriggerFamily);
			return;
		}

		this.setFilter(filterKeys.kTriggerFamily, item => triggerFamilies.includes(item.trigger.family));
	}

	setActions(actionTypes: ActionType[]) {
		if (!actionTypes?.length) {
			this.removeFilter(filterKeys.kActions);
			return;
		}

		this.setFilter(filterKeys.kActions, item => {
			// flat actions is not cached as they are not called within reaction
			const { flatActions } = item.actions;
			return actionTypes.some(actionType => flatActions.find(({ action_type }) => action_type === actionType));
		});
	}

	sortByDateCreated() {
		this.setSort(
			sortKeys.kDateCreated,
			(a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
		);
	}

	sortByLastModified() {
		this.setSort(
			sortKeys.kLastModified,
			(a, b) => new Date(b.updated_at).getTime() - new Date(a.updated_at).getTime()
		);
	}

	setGroupingBy(groupingType: AutomationGroupingType) {
		if (this.groupBy === groupingType) {
			return;
		}

		this.groupBy = groupingType;

		switch (groupingType) {
			case AutomationGroupingType.TRIGGER_FAMILY:
				this.setGroup(getTriggerFamilyGroupHeader);
				break;

			case AutomationGroupingType.TRIGGER:
				this.setGroup(getTriggerGroupHeader);
				break;

			case AutomationGroupingType.ACTION: {
				const allActionsOptions = {
					...notifyActionOptions,
					...workflowsRootStore.getStore().metadataRepo.metadata.actionsByType,
					[ActionType.OPTIMIZATION]: i18next.t(optimizationAction)
				};

				this.setGroup(getActionGroupHeader(allActionsOptions));
				break;
			}

			default:
				this.removeGroup();
				break;
		}
	}
}

export default AutomationBoardViewStore;
