import React from 'react';

interface Props {
	className?: string;
}

export const FlagIcon = ({ className = '' }: Props) => {
	return (
		<svg
			className={className}
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M18.159 9.006c.299-.058.523.036.673.284.15.232.134.457-.045.675-.344.422-.725.8-1.143 1.135a8.09 8.09 0 0 1-1.099.786c-.314.174-.643.334-.986.48-.344.13-.576.218-.696.262-.12.029-.224.05-.314.065a19.325 19.325 0 0 1-3.027.416 7.532 7.532 0 0 0-3.723 1.223v4.352c0 .117-.059.175-.178.175h-.718c-.12 0-.18-.058-.18-.174L6.718 7.312C6.24 7.094 6 6.73 6 6.222c0-.335.12-.618.358-.85.254-.248.553-.372.897-.372.404 0 .733.16.988.48.269.319.336.69.202 1.111 1.346-.073 2.744.275 4.195 1.045.733.392 1.428.704 2.086.936.673.218 1.242.356 1.705.414.464.057.823.086 1.077.086a6.45 6.45 0 0 0 .65-.066z"
				fill="#718096"
			/>
		</svg>
	);
};
