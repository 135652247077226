import { useCallback, useState } from 'react';

import { Button, Modal } from '@bringg/react-components';
import { ModalProps } from '@bringg/react-components/dist/components/modal/modal';
import notification from '@bringg/react-components/dist/components/notification/notification';
import ResourceUploadType from '@bringg/dashboard-sdk/dist/ResourceUpload/ResourceUploadType';
import { ResourceUploadResult } from '@bringg/dashboard-sdk/dist/ResourceUpload/ResourceResults';

import { useServiceAreaTranslation } from './translations';
import BringgDragger from 'bringg-web/components/bringg-dragger/bringg-dragger';
import resourceUploader from 'bringg-web/services/resource-upload/resource-uploader';
import DownloadCsvTemplate from 'bringg-web/components/download-csv-template/download-csv-template';
import { DONE_EVENT, TEMPLATE_HEADERS, TEMPLATE_ROWS } from 'bringg-web/features/service-area/constants';

export type Props = {
	onUploadStarted: (result: ResourceUploadResult) => void;
} & ModalProps;

function ServiceAreaUploadModal({ onUploadStarted, ...other }: Props) {
	const translations = useServiceAreaTranslation();
	const [file, setFile] = useState<Blob>(null);
	const [uploading, setUploading] = useState(false);

	const uploadFile = useCallback(async () => {
		setUploading(true);

		try {
			onUploadStarted(
				await resourceUploader.prepareAndProcess(file, ResourceUploadType.ServiceAreas, DONE_EVENT, {})
			);
		} catch (ex) {
			notification.error(translations.uploadFailed);
		} finally {
			setUploading(false);
		}
	}, [file, translations, onUploadStarted]);

	return (
		<Modal
			destroyOnClose
			footer={
				<Button
					data-test-id="service-area-upload"
					type="primary"
					onClick={uploadFile}
					disabled={uploading || !file}
				>
					{translations.upload}
				</Button>
			}
			{...other}
		>
			<h3>{translations.uploadTitle}</h3>
			<small className="service-area-secondary-text">{translations.uploadDescription}</small>
			<DownloadCsvTemplate fileName={translations.templateName} headers={TEMPLATE_HEADERS} rows={TEMPLATE_ROWS} />
			<BringgDragger accept={'.csv'} style={{ marginTop: '16px' }} onFileUploaded={setFile} />
		</Modal>
	);
}

export default ServiceAreaUploadModal;
