import { useCallback, useMemo } from 'react';

import { getRootEnv } from '@bringg-frontend/bringg-web-infra';
import { FilterBar2, getDefaultFilterBarTranslations } from '@bringg/react-components';
import { useTranslation } from 'react-i18next';
import type { FilterBar2ListItem, FilterBar2Value, FilterBar2Values } from '@bringg/react-components';
import { observer } from 'mobx-react';
import { FleetType } from '@bringg/types';
import { LookupConsts } from '@bringg/dashboard-sdk';

import { mapTeamToFilter } from 'bringg-web';
import { useStores } from 'bringg-web/recipes';
import getFilters from './filter-items';
import useDateFormat from 'bringg-web/hooks/use-date-format';
import useTimeFormat from 'bringg-web/hooks/use-time-format';
import Company from 'bringg-web/stores/companies/domain-objects/company';

const FiltersPanel = ({ onFilter }) => {
	const { t } = useTranslation();
	const dateFormat = useDateFormat();
	const timeFormat = useTimeFormat();

	const filterBarTranslations = useMemo(() => getDefaultFilterBarTranslations(t), [t]);

	const { teamsStore, tagsStore, fleetsStore, servicePlansStore, companiesStore } = useStores();

	const teamOptions = useMemo<FilterBar2Value[]>(() => {
		return mapTeamToFilter(teamsStore.all, teamsStore);
	}, [teamsStore, teamsStore.all]);

	const tagOptions = useMemo<FilterBar2Value[]>(() => {
		return tagsStore.getAll.map(tag => ({ label: tag.tag, value: tag.id }));
	}, [tagsStore, tagsStore.getAll]);

	const fleetOptions = useMemo<FilterBar2Value[]>(() => {
		return fleetsStore.all.map(fleet => {
			const isInternalFleet = fleet.fleet_type === FleetType.Internal;
			return { value: fleet.id, label: isInternalFleet ? t('ASSIGN_FLEET.INTERNAL_FLEET_TEXT') : fleet.name };
		});
	}, [fleetsStore, fleetsStore.all]);

	const servicePlanOptions = useMemo<FilterBar2Value[]>(() => {
		return servicePlansStore.getAll.map(plan => ({ label: plan.name, value: plan.id }));
	}, [servicePlansStore, servicePlansStore.getAll]);

	const handleFiltersChanged = useCallback((newFilters: FilterBar2Values) => {
		onFilter(newFilters);
	}, []);

	const handleDriverSearch = async (value: string) => {
		const lookUp = getRootEnv().dashboardSdk.sdk.v2.lookup;

		const params: LookupConsts.BaseSearchQuery = {
			query: value,
			size: 100
		};

		const listValues = await lookUp.getUsers({ ...params, roles: ['driver'] });

		return listValues?.items?.map(item => ({ label: item.name, value: item.id })) ?? [];
	};

	const companyOptions = useMemo<FilterBar2Value[]>(() => {
		companiesStore.fetchAll();

		return companiesStore.getAll.map((company: Company) => ({ label: company.name, value: company.id }));
	}, [companiesStore.isFetched]);

	const filterInputs = useMemo<FilterBar2ListItem[]>(() => {
		return getFilters(
			t,
			dateFormat,
			timeFormat,
			teamOptions,
			tagOptions,
			fleetOptions,
			servicePlanOptions,
			companyOptions,
			handleDriverSearch
		);
	}, [t, teamOptions, tagOptions, fleetOptions, servicePlanOptions]);

	return (
		<FilterBar2
			// This comment added in order to fix linter error
			list={filterInputs}
			onFilter={handleFiltersChanged}
			translations={filterBarTranslations}
		/>
	);
};

export default observer(FiltersPanel);
