import { action, computed, makeObservable, observable } from 'mobx';
import i18next from 'i18next';
import { ActionType } from '@bringg/types';
import { workflowsRootStore } from './internal';
import { ActionFamily } from './actions-repo';
import { notifyActionTypes } from '../utils/mapper';
import { ActionFamilyType } from '../utils/types';
import { notify } from '../utils/use-automation-translations';

class OverrideActionsStore {
	_overrideActions: ActionType[];

	constructor(actions: ActionType[]) {
		makeObservable(this, {
			_overrideActions: observable,
			set: action,
			actions: computed,
			actionsData: computed
		});

		this._overrideActions = actions;
	}

	set = (actions: ActionType[]) => {
		this._overrideActions = actions;
	};

	get actions(): ActionType[] {
		return this._overrideActions;
	}

	get actionsData(): ActionFamily[] {
		const { metadataRepo } = workflowsRootStore.getStore();
		const metadataActions = metadataRepo.metadata.actions;

		const triggerActionData = metadataActions.filter(({ actionType }) =>
			this._overrideActions.includes(Number(actionType))
		);

		const notifyActions = this._overrideActions.some(action => notifyActionTypes.includes(action));

		if (notifyActions) {
			triggerActionData.push({
				type: ActionFamilyType.NOTIFY,
				title: i18next.t(notify)
			});
		}

		const optimizationActions = this._overrideActions.some(action => action === ActionType.OPTIMIZATION);

		if (optimizationActions) {
			const { actions } = metadataRepo.metadata.metadata;

			actions.optimization &&
				triggerActionData.push({
					type: ActionFamilyType.OPTIMIZATION,
					title: i18next.t(actions.optimization.translation_string),
					disabled: actions.optimization.is_read_only
				});
		}

		return triggerActionData;
	}
}

export default OverrideActionsStore;
