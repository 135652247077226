import React from 'react';
import { FormItemWrapper, TimePicker } from '@bringg/react-components';
import { Form, FormInstance } from 'antd';
import i18next from 'i18next';
import { Moment } from 'moment';
import { DELIVERY_BLOCK_TIME_FORMAT, DeliveryBlockFormValues } from '../delivery-block-form';
import Recurring from '../../../../../components/recurring/recurring';
import {
	getFrequenciesLabels,
	getFrequenciesOptionsLabels,
	getRepeatLabels
} from './delivery-block-form-recurring-labels';
import { Translate } from '../../../../../translation';

interface Props {
	form?: FormInstance;
	deliveryBlock?: DeliveryBlockFormValues;
	isEditMode?: boolean;
	onBlockTimeChanged?: (startTime: Moment, endTime: Moment) => void;
}

class DeliveryBlockFormRecurring extends React.Component<Props> {
	onBlockStartTimeChange = async startTime => {
		const { form, onBlockTimeChanged } = this.props;

		form.setFieldsValue({ startTime });
		await form.validateFields(['endTime', 'breakStartTime', 'breakEndTime']);
		onBlockTimeChanged(startTime, form.getFieldValue('endTime'));
	};

	onBlockEndTimeChange = async endTime => {
		const { form, onBlockTimeChanged } = this.props;

		form.setFieldsValue({ endTime });
		await form.validateFields(['breakStartTime', 'breakEndTime']);
		onBlockTimeChanged(endTime, form.getFieldValue('startTime'));
	};

	endTimeValidator = async (rule, value) => {
		const startTime = this.props.form.getFieldValue('startTime');
		if (startTime > value) {
			return Promise.reject(i18next.t('DELIVERY_BLOCKS.VALIDATION_MESSAGES.END_TIME'));
		}

		return Promise.resolve();
	};

	render() {
		const { onBlockStartTimeChange, onBlockEndTimeChange, endTimeValidator } = this;
		const { isEditMode } = this.props;

		return (
			<>
				<Form.Item
					label={<Translate text="DELIVERY_BLOCKS.TIME" />}
					labelCol={{ xs: { span: 8 } }}
					wrapperCol={{ xs: { span: 16 } }}
					style={{ marginBottom: 0 }}
				>
					<FormItemWrapper name="startTime" rules={[{ required: true }]} className="time-range">
						<TimePicker
							onChange={onBlockStartTimeChange}
							allowClear={false}
							suffixIcon={<></>}
							format={DELIVERY_BLOCK_TIME_FORMAT}
							use12Hours
							showNow={false}
						/>
					</FormItemWrapper>
					<span className="time-range-separator">-</span>

					<FormItemWrapper
						name="endTime"
						rules={[{ required: true, validator: endTimeValidator }]}
						className="time-range"
					>
						<TimePicker
							onChange={onBlockEndTimeChange}
							allowClear={false}
							suffixIcon={<></>}
							format={DELIVERY_BLOCK_TIME_FORMAT}
							use12Hours
							showNow={false}
						/>
					</FormItemWrapper>
				</Form.Item>

				<FormItemWrapper
					name="ical"
					valuePropName="ical"
					wrapperCol={{
						xs: 24
					}}
				>
					<Recurring
						frequenciesLabels={getFrequenciesLabels()}
						frequenciesOptionsLabels={getFrequenciesOptionsLabels()}
						repeatLabels={getRepeatLabels()}
						disabled={isEditMode}
					/>
				</FormItemWrapper>
			</>
		);
	}
}

export default DeliveryBlockFormRecurring;
