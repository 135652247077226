import { action, observable, makeObservable } from 'mobx';

class ChatView {
	isFetching = false;
	newMessageText = '';

	chat_ref: Element = null;

	constructor() {
		makeObservable(this, {
			isFetching: observable,
			newMessageText: observable,
			setText: action,
			setFetching: action
		});
	}

	scrollBottom() {
		return this.chat_ref ? this.chat_ref.scrollTo({ top: this.chat_ref.scrollHeight, behavior: 'auto' }) : null;
	}

	scrollTop() {
		return this.chat_ref ? this.chat_ref.scrollTo({ top: 0, behavior: 'auto' }) : null;
	}

	setRef = (ref: Element) => {
		this.chat_ref = ref;
	};

	setText = (text: string) => {
		this.newMessageText = text;
	};

	setFetching(status: boolean) {
		this.isFetching = status;
	}
}

export default ChatView;
