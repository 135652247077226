import React, { FunctionComponent, useEffect } from 'react';
import { Row } from 'antd';
import AvailableFleetsForm from './available-fleets/available-fleets-form/available-fleets-form';
import FormPage from '@bringg/react-pages/dist/components/form-page/form-page';
import useStores from '../../recipes/use-stores';
import { FleetTemplateSelection } from '../../stores/fleets/fleets-store';

const FleetRouter: FunctionComponent = () => {
	const { fleetsStore } = useStores();

	const onSubmit = async (selectedFleetTemplates: FleetTemplateSelection): Promise<FleetTemplateSelection> => {
		await fleetsStore.registerFleets(selectedFleetTemplates);
		return onInitValues();
	};

	const onInitValues = async (): Promise<FleetTemplateSelection> => {
		await fleetsStore.fetchData();
		return createFleetCurrentSelection();
	};

	const createFleetCurrentSelection = (): FleetTemplateSelection => {
		const fleetSelection: FleetTemplateSelection = {};

		fleetsStore.getFleetTemplateIds.forEach(fleetTemplateId => {
			const fleetTemplate = fleetsStore.getFleetTemplateById(fleetTemplateId);
			fleetSelection[fleetTemplateId] = fleetTemplate.isRegistered;
		});

		return fleetSelection;
	};

	useEffect(() => {
		const init = async () => {
			await fleetsStore.fetchData();
		};

		init();
	}, [fleetsStore]);

	return (
		<Row className="fleet-router">
			<FormPage
				onSubmit={onSubmit}
				onInitValues={onInitValues}
				component={AvailableFleetsForm}
				useSaveGuard={false}
			/>
		</Row>
	);
};

export default FleetRouter;
