import { useCallback, useMemo, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { Table, Notification, DateRangePicker } from '@bringg/react-components';
import { observer } from 'mobx-react';
import moment, { Moment } from 'moment';
import { confirm } from '@bringg/react-components/dist/components/modal/modal';
import { ServiceArea, TeamServiceArea } from '@bringg/types';
import { BringgFontIcons, BringgIcon } from '@bringg/bringg-icons';

import { useStores } from 'bringg-web/recipes';
import { Button } from 'bringg-web/components';
import { formatTableTime } from 'bringg-web/features/service-area/utils/format-service-area-time';
import useTimeFormat from 'bringg-web/hooks/use-time-format';

import './table.scss';

export type Props = {
	onEdit: (serviceArea: Partial<ServiceArea>) => void;
	readonly?: boolean;
	teamId?: number;
};

export const ServiceAreaTable = observer(({ onEdit, readonly = false, teamId = null }: Props) => {
	const { serviceAreaViewStore, serviceAreasStore } = useStores();
	const { t } = useTranslation();
	const hourFormat = useTimeFormat();
	const [openedTeam, setOpenedTeam] = useState(null);

	const dataSource = useMemo(
		() =>
			serviceAreaViewStore.withoutNewRecord.map(serviceArea => ({
				...serviceArea,
				updatedAtFormatted: moment(serviceArea.updated_at).format('DD.MM.YY, HH:mm')
			})),
		[serviceAreaViewStore.withoutNewRecord]
	);

	const onDelete = useCallback(
		async serviceArea => {
			try {
				await serviceAreasStore.delete(serviceArea.id);
				Notification.success(t('SERVICE_AREA.SUCCESSFULLY_REMOVED'));
			} catch (ex) {
				console.error(ex);
				Notification.error(t('SERVICE_AREA.FAILED_TO_REMOVE'));
			}
		},
		[serviceAreaViewStore, t]
	);

	const openConfirmationModal = useCallback(
		record => {
			confirm({
				title: t('SERVICE_AREA.CONFIRM_REMOVE'),
				async onOk() {
					return onDelete(record);
				}
			});
		},
		[onDelete, t]
	);

	const onDateUpdate = useCallback(
		(serviceArea, assignedTeam: TeamServiceArea) => (values?: Moment[]) => {
			if (!assignedTeam) {
				return;
			}

			if (!values) {
				assignedTeam.effective_start_time = null;
				assignedTeam.effective_end_time = null;
			} else {
				assignedTeam.effective_start_time = values[0] ? values[0].startOf('day').toISOString() : null;
				assignedTeam.effective_end_time = values[1] ? values[1].endOf('day').toISOString() : null;
			}
		},
		[]
	);

	const onPickerClose = useCallback(
		async serviceArea => {
			setOpenedTeam(null);
			try {
				await serviceAreasStore.updateAllTeamsServiceAreas(serviceArea.id, serviceArea.assignedTeams);
				Notification.success(t('SERVICE_AREA.SUCCESSFULLY_UPDATED'));
			} catch (ex) {
				console.error(ex);
				Notification.error(t('SERVICE_AREA.FAILED_TO_UPDATE'));
			}
		},
		[serviceAreasStore, t]
	);

	const onDateClick = useCallback(
		(assignedTeam: TeamServiceArea) => () => {
			if (!readonly) {
				setOpenedTeam(assignedTeam);
			}
		},
		[setOpenedTeam, readonly]
	);

	const columns = useMemo(
		() =>
			[
				{
					key: 'id',
					title: t('SERVICE_AREA.ID'),
					dataIndex: 'id'
				},
				{
					key: 'id',
					title: t('SERVICE_AREA.SERVICE_AREA_NAME'),
					dataIndex: 'name'
				},
				{
					key: 'id',
					title: t('SERVICE_AREA.EXTERNAL_ID'),
					dataIndex: 'external_id'
				},
				teamId
					? null
					: {
							key: 'id',
							title: t('SERVICE_AREA.TEAMS'),
							dataIndex: ['assignedTeams', 'length']
					  },
				{
					key: 'id',
					title: t('SERVICE_AREA.AREA_TYPE'),
					render: (_, record) => t(record.areaType)
				},
				{
					key: 'id',
					title: t('SERVICE_AREA.UPDATED_AT'),
					dataIndex: 'updatedAtFormatted',
					sorter: (a, b) => new Date(b.updated_at).getTime() - new Date(a.updated_at).getTime()
				},
				teamId
					? {
							key: 'id',
							title: t('SERVICE_AREA.EFFECTIVE_DATE'),
							sorter: (a, b) => {
								const getEffectiveTime = serviceArea =>
									new Date(
										serviceArea.assignedTeams.find(
											team => team.team_id === teamId
										)?.effective_start_time
									).getTime();

								return getEffectiveTime(a) - getEffectiveTime(b);
							},
							render(_, record) {
								const assignedTeam = record.assignedTeams.find(team => team.team_id === teamId);
								return (
									<div>
										{openedTeam?.id !== assignedTeam?.id && (
											<div
												data-test-id="team-service-area-time"
												onClick={onDateClick(assignedTeam)}
											>
												{formatTableTime(
													assignedTeam?.effective_start_time,
													assignedTeam?.effective_end_time
												)}
											</div>
										)}
										{openedTeam?.id === assignedTeam?.id && (
											<DateRangePicker
												data-test-id="team-service-area-date-picker"
												hourFormat={hourFormat}
												translations={{ fromDate: '', toDate: '' }}
												open
												startDate={assignedTeam?.effective_start_time}
												endDate={assignedTeam?.effective_end_time}
												allowEmpty={[true, true]}
												onChange={onDateUpdate(record, assignedTeam)}
												onOpenChange={open => {
													if (open) {
														return;
													}

													void onPickerClose(record);
												}}
											/>
										)}
									</div>
								);
							}
					  }
					: null,
				readonly
					? null
					: {
							key: 'id',
							title: t('SERVICE_AREA.ACTION'),
							render: (_, record) => (
								<>
									<Button type="link" onClick={() => onEdit(record)}>
										<BringgIcon iconName={BringgFontIcons.Pencil} />
										{t('SERVICE_AREA.EDIT')}
									</Button>
									<Button type="link" danger onClick={() => openConfirmationModal(record)}>
										<BringgIcon iconName={BringgFontIcons.Trash} />
										{t('SERVICE_AREA.DELETE')}
									</Button>
								</>
							)
					  }
			].filter(Boolean),
		[t, onEdit, openConfirmationModal, readonly, teamId, hourFormat, onDateUpdate, openedTeam, onDateClick]
	);

	const pagination = useMemo(
		() => ({
			position: ['bottomLeft' as const],
			defaultPageSize: 25,
			pageSizeOptions: [25, 50, 100],
			total: dataSource.length,
			showSizeChanger: true,
			showTotal: (total, range) => `${range[0]}-${range[1]} out of ${total}`
		}),
		[dataSource.length]
	);

	return (
		<Table
			className="service-area-table"
			data-test-id="service-area-table"
			pagination={pagination}
			loading={!serviceAreasStore.isFetched}
			dataSource={dataSource}
			columns={columns}
		/>
	);
});
