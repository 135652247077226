import { useMemo, useState } from 'react';

import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop';
import { Calendar as BigCalendar } from 'react-big-calendar';
import classNames from 'classnames';
import moment from 'moment';

import { customMomentLocalizerTimezone } from 'bringg-web/features/planned-delivery-windows/calendar/custom-moment-localizer-timezone';
import { getFormatByMerchant } from 'bringg-web/features/automation-workflows/utils/helpers';

import styles from './calendar.module.scss';

type Props = {
	events: any;
	toolbar: any;
	selected: any;
	onSelectEvent?: any;
	onEventDrop?: any;
	onEventResize?: any;
	views?: any;
	step?: number;
	loading?: boolean;
	timezone: string;
};

const Calendar = ({
	events,
	toolbar,
	selected,
	onSelectEvent,
	onEventDrop,
	onEventResize,
	views,
	loading = false,
	timezone
}: Props) => {
	const DragAndDropCalendar = withDragAndDrop(BigCalendar);
	const timeFormat = getFormatByMerchant();

	// NOTE: Next two lines were copied from delivery window calendar
	// TODO: consider to move this outside delivery window feature
	const localizer = customMomentLocalizerTimezone(moment, timezone);

	const [date, setDate] = useState(new Date());

	const formats = useMemo(
		() => ({
			timeGutterFormat: timeFormat,
			eventTimeRangeFormat: (range, culture, localizer) => {
				return `${localizer.format(range.start, timeFormat, culture)} – ${localizer.format(
					range.end,
					timeFormat,
					culture
				)}`;
			}
		}),
		[]
	);

	const handleNavigate = date => {
		setDate(date);
	};

	return (
		<div className={classNames(styles.calendar, loading && styles.loading)}>
			<DragAndDropCalendar
				components={{
					timeGutterHeader: () => {
						return <div>{moment.tz(timezone).format('Z z')}</div>;
					}
				}}
				localizer={localizer}
				startAccessor="start"
				endAccessor="end"
				defaultView="week"
				views={views}
				step={60}
				timeslots={1}
				date={date}
				toolbar={toolbar}
				events={events}
				onSelectEvent={onSelectEvent}
				selected={selected}
				onEventDrop={onEventDrop}
				onEventResize={onEventResize}
				onNavigate={handleNavigate}
				// @ts-ignore
				style={{ height: '95vh' }}
				showMultiDayTimes
				formats={formats}
			/>
		</div>
	);
};

export default Calendar;
