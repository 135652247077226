import React from 'react';
import { Input } from 'antd';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import NotificationTemplatePreview from '../notification-template-preview/notification-template-preview';
import NotificationTemplateModel from '../../../stores/notification-templates/domain-objects/notification-template';
import { transformText } from '../helper';

interface Props {
	notificationTemplate: NotificationTemplateModel;
	dictionary: any;
	className?: string;
	placeholder?: string;
}

const NotificationTemplate = ({ notificationTemplate, placeholder, dictionary, className }: Props) => {
	const { t } = useTranslation();

	return (
		<div className={`notification-template ${className || ''}`}>
			<div className="edit-area" data-test-id="notification-text-area">
				<Input.TextArea
					className="input"
					value={notificationTemplate.template}
					onChange={({ target }) => notificationTemplate.setTemplate(target.value)}
					placeholder={t(placeholder)}
					data-test-id="notification-text-area-input"
				/>
				{dictionary && (
					<NotificationTemplatePreview
						className="preview-component"
						text={transformText(notificationTemplate.template, dictionary)}
					/>
				)}
			</div>
		</div>
	);
};

export default observer(NotificationTemplate);
