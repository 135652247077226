import React from 'react';

import classnames from 'classnames';
import get from 'lodash/get';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import { BringgFontIcons, BringgIcon } from '@bringg/bringg-icons';
import { Collapse, Tooltip } from '@bringg/react-components';
import { DEFAULT_PANEL_KEY } from '@bringg/react-components/dist/components/collapse/collapse';
import type { CustomAttributeRes, Task } from '@bringg/types';
import { CustomAttributeDataType } from '@bringg/types';

import AttributeListItem from '../attribute-list-item/attribute-list-item';
import { getDisplayValue, getEnumDefinitionItem } from '../../utils/extractor';

import './custom-attributes-list.scss';

export interface CustomAttributesCollapseListProps {
	task: Task;
	customAttributes: CustomAttributeRes[];
}

export interface CustomAttributesCollapseListItemProps {
	task: Task;
	attribute: CustomAttributeRes;
}

const CustomAttributesCollapseListItem: React.FC<CustomAttributesCollapseListItemProps> = ({ task, attribute }) => {
	const { t } = useTranslation();
	const { name, description, path, data_type: dataType, enum_definition } = attribute;

	const value = get(task, path);
	const mono = typeof value === 'boolean' || value === null || value === undefined; // better to show with monospace font

	const isEnumType = dataType === CustomAttributeDataType.Enum;
	const displayValue =
		value === undefined ? t('CUSTOM_ATTRIBUTES.EMPTY').toLowerCase() : String(getDisplayValue(attribute, value));
	let displayTooltip;

	if (isEnumType) {
		const record = getEnumDefinitionItem(enum_definition, value);

		if (record) {
			const tooltipTitle = (
				<div>
					<div>
						{t('CUSTOM_ATTRIBUTES.ENUM_DEFINITION.NAME')}: {record.name}
					</div>
					<div>
						{t('CUSTOM_ATTRIBUTES.ENUM_DEFINITION.VALUE')}: {record.value}
					</div>
				</div>
			);
			displayTooltip = (
				<Tooltip title={tooltipTitle} placement="top">
					&nbsp;
					<BringgIcon iconName={BringgFontIcons.Info} />
				</Tooltip>
			);
		}
	}

	return (
		<AttributeListItem
			className="custom-attributes-list-item"
			name={name}
			description={description}
			displayValue={displayValue}
			displayTooltip={displayTooltip}
			attributeValueClassName={classnames('custom-attributes-list-item-value', { mono, enum: isEnumType })}
		/>
	);
};

const CustomAttributesCollapseList: React.FC<CustomAttributesCollapseListProps> = ({ task, customAttributes }) => {
	const { t } = useTranslation();

	return (
		<div className="custom-attributes-list-container">
			<Collapse
				testId="custom-attributes-list"
				key="custom-attributes-list"
				defaultActiveKey={DEFAULT_PANEL_KEY}
				removeContentPadding
			>
				<Collapse.Panel key={DEFAULT_PANEL_KEY} header={t('CUSTOM_ATTRIBUTES.ORDER_PAGE.TITLE')}>
					{customAttributes.map(attribute => (
						<CustomAttributesCollapseListItem key={attribute.id} task={task} attribute={attribute} />
					))}
				</Collapse.Panel>
			</Collapse>
		</div>
	);
};

export default observer(CustomAttributesCollapseList);
