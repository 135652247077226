import React from 'react';
import { inject, observer } from 'mobx-react';
import ChatListItem from '../chat-list-item/chat-list-item';
import ChatStore from '../stores/chat-store';

interface Props {
	chatStore?: ChatStore;
}

const ChatList = ({ chatStore }: Props) => {
	const conversations = chatStore.getConversations;

	return (
		<div className="chat-list">
			{conversations.map(conversation => (
				<ChatListItem key={conversation.id.toString()} conversation={conversation} />
			))}
		</div>
	);
};

export default inject('chatStore')(observer(ChatList));
