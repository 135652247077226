import { BringgFontIcons, BringgIcon } from '@bringg/bringg-icons';
import { Button, EditableTitle } from '@bringg/react-components';
import notification from '@bringg/react-components/dist/components/notification/notification';
import classNames from 'classnames';
import { observer } from 'mobx-react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import TaskRejectReasonStore from '../../stores/task-reject-reasons-store';

const TaskRejectReasonsItem: React.FC<{ taskRejectReason: TaskRejectReasonStore }> = ({ taskRejectReason }) => {
	const { t } = useTranslation();
	const [hovering, setHovering] = useState(false);

	const updateReason = async (reason: string): Promise<void> => {
		const { success } = await taskRejectReason.updateTaskRejectReason({ reason });

		if (!success) {
			notification.error(t('TASK_REJECT_REASON_CONFIG.ITEM.ERROR_UPDATE'));
		}
	};

	const deleteReason = async (): Promise<void> => {
		if (!taskRejectReason.isLoading) {
			const { success } = await taskRejectReason.delete();
			if (!success) {
				notification.error(t('TASK_REJECT_REASON_CONFIG.ITEM.ERROR_DELETE'));
			}
		}
	};

	return (
		<div
			className="task-reject-reasons-item"
			key={taskRejectReason.id}
			onMouseOver={() => {
				setHovering(true);
			}}
			onMouseLeave={() => setHovering(false)}
		>
			<div className="task-reject-reasons-content">
				<div className="task-reject-reasons-id">{taskRejectReason.id}</div>
				<EditableTitle
					maxLength={56}
					className="task-reject-reasons-reason"
					value={taskRejectReason.reason}
					onValueChange={taskRejectReason.setReason}
					placeholder={''}
					onApplyChanges={updateReason}
					loading={taskRejectReason.isSaving}
					forceShowEditIcon={hovering}
				/>
			</div>
			<div className="task-reject-reasons-delete-container">
				<div>
					<Button
						data-test-id={`task-reject-reasons-delete-button-${taskRejectReason.id}`}
						className={classNames('task-reject-reasons-delete', { 'hide-delete-button': !hovering })}
						onClick={deleteReason}
						type="link"
						size="small"
						icon={
							<BringgIcon
								data-test-id="task-reject-reasons-delete-icon"
								iconName={BringgFontIcons.Trash}
							/>
						}
						shape="circle"
					/>
				</div>
			</div>
		</div>
	);
};

export default observer(TaskRejectReasonsItem);
