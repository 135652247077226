import { hasFeatureFlag, teamHasFeatureFlag } from '@bringg-frontend/global-stores';

import useStores from '../recipes/use-stores';

export { hasFeatureFlag, teamHasFeatureFlag };

export const useHasFeatureFlag = (featureFlag: string, team?: Bringg.Team) => {
	const {
		usersStore: { currentUser }
	} = useStores();

	return hasFeatureFlag(currentUser, featureFlag, team);
};

export const useGetFeatureFlag = (featureFlag: string) => {
	const {
		usersStore: { currentUser }
	} = useStores();

	return currentUser && currentUser.feature_flags?.[featureFlag];
};
