import { useMemo } from 'react';

import { useTranslation } from 'react-i18next';
import { EnumValues } from 'enum-values';

import { EmployeeStateFilter, EmployeeAvailabilityState } from '../types';

interface EmployeeStateFilterItem {
	id: EmployeeStateFilter;
	translationKey: string;
	name: string;
	title: string;
}

export const EmployeeStateFilterOptions = new Set(EnumValues.getValues<EmployeeStateFilter>(EmployeeStateFilter));

export function useEmployeeStateFilterItems({
	employeeStateFilterTypeCounts,
	selectedValue
}: {
	employeeStateFilterTypeCounts: Record<EmployeeStateFilter, number>;
	selectedValue: EmployeeStateFilter;
}): {
	items: EmployeeStateFilterItem[];
	selectedItem?: EmployeeStateFilterItem;
} {
	const { t } = useTranslation();

	const items = useMemo(
		() =>
			[
				{
					id: EmployeeStateFilter.ALL,
					translationKey: 'MAP.ALL_DRIVERS',
					title: `${t('MAP.ALL_DRIVERS')} (${employeeStateFilterTypeCounts[EmployeeStateFilter.ALL]})`
				},
				{
					id: EmployeeStateFilter.ONLINE,
					translationKey: 'MAP.ONLINE',
					title: `${t('MAP.ONLINE')} (${employeeStateFilterTypeCounts[EmployeeStateFilter.ONLINE]})`
				},
				{
					id: EmployeeStateFilter.ON_SHIFT,
					translationKey: 'MAP.ON_SHIFT',
					title: `${t('MAP.ON_SHIFT')} (${employeeStateFilterTypeCounts[EmployeeStateFilter.ON_SHIFT]})`
				},
				{
					id: EmployeeStateFilter.ON_SHIFT_OFFLINE,
					translationKey: 'MAP.ON_SHIFT_AND_OFFLINE',
					title: `${t('MAP.ON_SHIFT_AND_OFFLINE')} (${
						employeeStateFilterTypeCounts[EmployeeStateFilter.ON_SHIFT_OFFLINE]
					})`
				},
				{
					id: EmployeeStateFilter.OFF_SHIFT,
					translationKey: 'MAP.OFF_SHIFT',
					title: `${t('MAP.OFF_SHIFT')} (${employeeStateFilterTypeCounts[EmployeeStateFilter.OFF_SHIFT]})`
				}
			].map(item => ({ ...item, name: t(item.translationKey) })),
		[t, employeeStateFilterTypeCounts]
	);

	const selectedItem = items.find(item => item.id === selectedValue);

	return { items, selectedItem };
}

export const EmployeeAvailabilityStateOptions = new Set(
	EnumValues.getValues<EmployeeAvailabilityState>(EmployeeAvailabilityState)
);

interface EmployeeTasksStateFilterItem {
	id: EmployeeAvailabilityState;
	translationKey: string;
	name: string;
}

export function useEmployeeAvailabilityStateFilterItems(): {
	items: EmployeeTasksStateFilterItem[];
} {
	const { t } = useTranslation();

	const items = useMemo(
		() => [
			{
				id: EmployeeAvailabilityState.HAS_TASKS,
				translationKey: 'MAP.EMPLOYEES_FILTER.EMPLOYEE_TASKS_STATE.HAS_TASKS',
				name: t('MAP.EMPLOYEES_FILTER.EMPLOYEE_TASKS_STATE.HAS_TASKS')
			},
			{
				id: EmployeeAvailabilityState.NO_TASKS,
				translationKey: 'MAP.EMPLOYEES_FILTER.EMPLOYEE_TASKS_STATE.HAS_NO_TASKS',
				name: t('MAP.EMPLOYEES_FILTER.EMPLOYEE_TASKS_STATE.HAS_NO_TASKS')
			}
		],
		[t]
	);

	return { items };
}
