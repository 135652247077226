import { getRootEnv } from '@bringg-frontend/bringg-web-infra';
import { action, makeObservable, observable } from 'mobx';
import _noop from 'lodash/noop';
import { FormFields } from '@bringg-frontend/dynamic-form-modal';

import { ModalAction } from '../../../services/cross-application/cross-application.actions';
import { registerAction } from '../../../services/cross-application/cross-application';

class CrossAppDynamicFormModalView {
	visible = false;
	title = '';
	okText = '';
	formFields: FormFields[] = [];
	onSubmit?: (...args: any[]) => void = null;
	unRegister: () => void = _noop;

	constructor() {
		makeObservable(this, {
			visible: observable,
			initiateModal: action,
			closeModal: action
		});
	}

	register = () => {
		this.unRegister = registerAction(
			ModalAction.OPEN_DYNAMIC_FORM_MODAL,
			this.initiateModal,
			getRootEnv().dashboardSdk.sdk.crossAppTransport
		);
	};

	// cross app action
	initiateModal = (
		title: string,
		formFields: FormFields[],
		onSubmit: (...args: any[]) => void = null,
		okText = 'Ok'
	) => {
		Object.assign(this, {
			title,
			formFields,
			okText,
			onSubmit,
			visible: true
		});
	};

	closeModal = () => {
		this.visible = false;
	};
}

export default CrossAppDynamicFormModalView;
