import React, { useCallback, useEffect, useState } from 'react';

import { getRootEnv } from '@bringg-frontend/bringg-web-infra';
import CSVUploader from '@bringg/react-components/dist/components/csv-uploader/csv-uploader';
import { useTranslation } from 'react-i18next';
import { CsvUploadResult, Header } from '@bringg/react-components/dist/components/csv-uploader/csv-uploader.consts';
import { MerchantConfigurationCSVField, PrivilegeTypes } from '@bringg/types';

import { getTasksCsvOptions } from './add-orders-csv.consts';
import { Translate } from '../../translation';
import notification from '../../services/notification';
import { registerAction } from '../../services/cross-application/cross-application';
import useStores from '../../recipes/use-stores';
import { ModalAction } from '../../services/cross-application/cross-application.actions';
import { useHasAccess } from '../../utils/privileges';

const AddOrdersCSV: React.FC = () => {
	const [headersOptions, setHeadersOptions] = useState([]);
	const { merchantConfigurationsStore, authStore } = useStores();
	const [isVisible, setVisibility] = useState(false);
	const { t } = useTranslation();

	const allowEditHeaders = useHasAccess(PrivilegeTypes.TASKS_CSV_IMPORTER_EDIT_HEADERS);

	useEffect(() => {
		merchantConfigurationsStore.fetchTaskCsvFields();

		setHeadersOptions(
			getTasksCsvOptions(authStore.merchant.time_zone).map(option => {
				return {
					key: option.value,
					...option
				};
			})
		);

		registerAction(ModalAction.OPEN_TASKS_CSV_IMPORTER_V2, toggleModalVisibility, authStore.crossAppTransport);
	}, []);

	const handleOnUpload = useCallback(async (updatedHeaders: Header[], csvFile: Blob): Promise<CsvUploadResult> => {
		try {
			if (allowEditHeaders) {
				await merchantConfigurationsStore.setTaskCsvFields(updatedHeaders as MerchantConfigurationCSVField[]);
			}

			const importResponse = await getRootEnv().dashboardSdk.sdk.v2.tasks.importTasksCsv(csvFile);

			if (!importResponse.success) {
				notification.error(t('TASKS_CSV_UPLOADER.ERROR_MESSAGE'));
				return;
			}

			return {
				errors: importResponse.errors.map(error => {
					return `${t('TASKS_CSV_UPLOADER.LINE')} ${error.line}: ${error.error}`;
				}),
				failed_number: importResponse.failed_importing || 0,
				imported_number: importResponse.imported_orders,
				uploaded_number: (importResponse.failed_importing || 0) + importResponse.imported_orders
			};
		} catch (e) {
			notification.error(t('TASKS_CSV_UPLOADER.ERROR_MESSAGE'));
		}
	}, []);

	const toggleModalVisibility = useCallback(() => {
		setVisibility(!isVisible);
	}, [isVisible]);

	return (
		<>
			{isVisible && (
				<CSVUploader
					modalProps={{
						title: <Translate text="TASKS_CSV_UPLOADER.TITLE" />,
						okText: <Translate text="TASKS_CSV_UPLOADER.UPLOAD" />,
						cancelText: <Translate text="TASKS_CSV_UPLOADER.CANCEL" />,
						afterClose: toggleModalVisibility
					}}
					dragAndDropProps={{
						className: 'csv-uploader-drag-and-drop'
					}}
					headers={merchantConfigurationsStore.taskCsvFields}
					headersOptions={headersOptions}
					onUpload={handleOnUpload}
					description={t('TASKS_CSV_UPLOADER.DESCRIPTION')}
					totalRowsText={t('TASKS_CSV_UPLOADER.TOTAL_ROWS')}
					columnsSelectedText={t('TASKS_CSV_UPLOADER.COLUMNS_SELECTED')}
					autoCompleteProps={{ dropdownClassName: 'csv-autocomplete-dropdown', disabled: !allowEditHeaders }}
					showFirstRow={false}
					templateFileName={t('TASKS_CSV_UPLOADER.TEMPLATE_FILE_NAME')}
					downloadTemplateButtonText={t('TASKS_CSV_UPLOADER.DOWNLOAD_TEMPLATE')}
					uploadText={t('TASKS_CSV_UPLOADER.UPLOAD')}
					finishText={t('TASKS_CSV_UPLOADER.FINISH')}
					linesWithErrorsText={t('TASKS_CSV_UPLOADER.LINES_WITH_ERRORS')}
					linesUploadedText={t('TASKS_CSV_UPLOADER.LINES_UPLOADED')}
					linesImportedText={t('TASKS_CSV_UPLOADER.LINES_IMPORTED')}
				/>
			)}
		</>
	);
};

export default AddOrdersCSV;
