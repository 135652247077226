import React from 'react';

import { Button } from '@bringg/react-components';
import { BringgFontIcons, BringgIcon } from '@bringg/bringg-icons';
import { useTranslation } from 'react-i18next';

import { PlannedDeliveryWindowsStepWizard } from 'bringg-web/features/planned-delivery-windows/modals/slots-modal/planned-delivery-windows-slots.consts';
import { ModalLoading } from 'bringg-web/features/planned-delivery-windows/modals/slots-modal/planned-delivery-windows-slots-modal';

import styles from './modal-footer.module.scss';

interface Props {
	stepWizard: PlannedDeliveryWindowsStepWizard;
	setStepWizard: React.Dispatch<React.SetStateAction<PlannedDeliveryWindowsStepWizard>>;
	createWindows: () => void;
	onCancel: () => void;
	generateWindows: () => void;
	daysInWeekLength: number;
	isEditMode: boolean;
	plannedDeliveryWindowsModalLoaders: ModalLoading;
	openRecurrenceTypeModalWithEditMode: () => void;
	openRecurrenceTypeModalWithDeleteMode: () => void;
	isPlannedDeliveryWindowChanged: boolean;
	windowsLength: number;
}

const ModalFooter = ({
	stepWizard,
	setStepWizard,
	onCancel,
	createWindows,
	generateWindows,
	daysInWeekLength,
	isEditMode,
	plannedDeliveryWindowsModalLoaders,
	isPlannedDeliveryWindowChanged,
	openRecurrenceTypeModalWithEditMode,
	openRecurrenceTypeModalWithDeleteMode,
	windowsLength
}: Props) => {
	const { t } = useTranslation();
	return (
		<div>
			{isEditMode && (
				<Button
					className={styles.removeButton}
					type="default"
					danger
					onClick={openRecurrenceTypeModalWithDeleteMode}
					loading={plannedDeliveryWindowsModalLoaders.remove}
					disabled={plannedDeliveryWindowsModalLoaders.update}
				>
					{t('PLANNED_DELIVERY_WINDOWS_MODAL_FOOTER.REMOVE')}
				</Button>
			)}
			{stepWizard === PlannedDeliveryWindowsStepWizard.TABLE && (
				<Button
					className={styles.backButton}
					type="default"
					icon={<BringgIcon iconName={BringgFontIcons.ArrowLeft} />}
					onClick={() => setStepWizard(PlannedDeliveryWindowsStepWizard.CONFIGURATION)}
					disabled={plannedDeliveryWindowsModalLoaders.create}
				>
					{t('PLANNED_DELIVERY_WINDOWS_MODAL_FOOTER.BACK')}
				</Button>
			)}
			<Button type="link" onClick={onCancel}>
				{t('PLANNED_DELIVERY_WINDOWS_MODAL_FOOTER.CANCEL')}
			</Button>
			{stepWizard === PlannedDeliveryWindowsStepWizard.CONFIGURATION && !isEditMode && (
				<Button
					type="primary"
					onClick={() => {
						generateWindows();
						setStepWizard(PlannedDeliveryWindowsStepWizard.TABLE);
					}}
					disabled={daysInWeekLength === 0}
				>
					{t('PLANNED_DELIVERY_WINDOWS_MODAL_FOOTER.GENERATE')}
				</Button>
			)}
			{stepWizard === PlannedDeliveryWindowsStepWizard.TABLE && !isEditMode && (
				<Button
					type="primary"
					onClick={createWindows}
					disabled={!windowsLength}
					loading={plannedDeliveryWindowsModalLoaders.create}
				>
					{t('PLANNED_DELIVERY_WINDOWS_MODAL_FOOTER.CREATE')}
				</Button>
			)}
			{isEditMode && (
				<Button
					type="primary"
					onClick={openRecurrenceTypeModalWithEditMode}
					loading={plannedDeliveryWindowsModalLoaders.update}
					disabled={plannedDeliveryWindowsModalLoaders.remove || !isPlannedDeliveryWindowChanged}
				>
					{t('PLANNED_DELIVERY_WINDOWS_MODAL_FOOTER.UPDATE')}
				</Button>
			)}
		</div>
	);
};

export default ModalFooter;
