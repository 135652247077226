import { getRootEnv } from '@bringg-frontend/bringg-web-infra';
import { action, observable, computed, toJS, makeObservable } from 'mobx';
import { getEnv } from 'mobx-easy';
import { ServicePlan } from '@bringg/types';
import { ServicePlanEntity } from '@bringg/dashboard-sdk/dist/ServicePlans/ServicePlansApi';

class ServicePlansStore {
	isFetched = false;
	servicePlans: Map<number, ServicePlan> = new Map();

	constructor() {
		makeObservable(this, {
			isFetched: observable,
			setIsFetched: action,
			servicePlans: observable,
			getAll: computed,
			fetchAll: action,
			createServicePlan: action,
			editServicePlan: action
		});
	}

	setIsFetched = isFetched => {
		this.isFetched = isFetched;
	};

	get getAll() {
		return toJS(Array.from(this.servicePlans.values()));
	}

	get = (id: number) => {
		return this.servicePlans.get(id);
	};

	async fetchAll() {
		const result = await getEnv<any>().dashboardSdk.sdk.servicePlans.getAll();
		result.forEach(servicePlan => this.servicePlans.set(servicePlan.id, servicePlan));

		this.setIsFetched(true);
	}

	createServicePlan = async (newServicePlan: ServicePlanEntity) => {
		const { dashboardSdk } = getRootEnv();

		const servicePlan = await dashboardSdk.sdk.servicePlans.create(newServicePlan);

		this.servicePlans.set(servicePlan.id, servicePlan);

		return servicePlan;
	};

	editServicePlan = async ({ id, ...servicePlanEntity }: ServicePlan) => {
		const { dashboardSdk } = getRootEnv();

		const servicePlan = await dashboardSdk.sdk.servicePlans.update(id, servicePlanEntity as ServicePlanEntity);

		this.servicePlans.set(id, servicePlan);

		return servicePlan;
	};
}

export default ServicePlansStore;
