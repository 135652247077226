import React, { useContext } from 'react';

import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import { BringgInput } from '@bringg/react-components';

import InputLabelWithTooltip from './../input-label-with-tooltip/input-label-with-tooltip';
import { OptimizationSettingsFormContext } from '../optimization-configuration-form/optimization-configuration-form';
import { OptimizationSettingsFormContextType, OptimizationStringAttribute } from '../../types';

import './input.scss';

const StringInput = ({ title, value = '', id, tooltipTitle, isSubField, disabled }: OptimizationStringAttribute) => {
	const { t } = useTranslation();
	const { handleChange } = useContext<OptimizationSettingsFormContextType>(OptimizationSettingsFormContext);

	return (
		<div
			className={classnames('route-optimization-input-container', 'route-optimization-field-container', {
				'sub-field': isSubField
			})}
		>
			<InputLabelWithTooltip id={id} title={t(title)} tooltip={tooltipTitle ? t(tooltipTitle) : null} />
			<BringgInput
				className="route-optimization-input"
				id={id}
				status=""
				value={value}
				placeholder={value}
				onChange={event => handleChange(id, { value: event.target?.value, isValid: true })}
				disabled={disabled}
			/>
		</div>
	);
};

export default StringInput;
