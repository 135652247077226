import { useEffect } from 'react';
import _isNil from 'lodash/isNil';
import { CustomModal } from '@bringg/react-components';
import notification from '../../../services/notification';
import { useTranslation } from 'react-i18next';
import useStores from '../../../recipes/use-stores';
import { Rollout } from '../../../stores/soft-rollout-store/consts';

export const useRolloutModal = rollout => {
	const { t } = useTranslation();
	const { softRolloutStore } = useStores();
	const { isOpen: isModalOpen, open: openModal, close: closeModal } = CustomModal.useModalState(false);

	const onConfirmModal = async () => {
		try {
			const shouldReload = !rollout.is_enabled;
			await softRolloutStore.updateUserRollout(rollout, { is_enabled: true });
			closeModal();
			if (shouldReload) window.location.reload();
		} catch (err) {
			notification.error(t('ROLLOUT.FAILED_TO_UPDATE_ROLLOUT'));
		}
	};

	const onCancelModal = async () => {
		closeModal();
		try {
			await softRolloutStore.updateUserRollout(rollout, { is_enabled: false });
		} catch (err) {
			console.error('failed to update user rollouts', err);
		}
	};

	const modalImage = rollout?.image_url
		? `/images/rollout_images/${rollout.image_url}`
		: '/images/rollout_images/rollout.png';

	useEffect(() => {
		if (_isNil(rollout)) {
			return;
		}

		const updateModalOnRolloutChange = () => {
			if (_isNil(rollout.user_rollout)) {
				openModal();
			} else {
				closeModal();
			}
		};

		updateModalOnRolloutChange();
	}, [rollout]);

	return {
		isModalOpen,
		onConfirmModal,
		onCancelModal,
		modalImage,
		translations: formatModalTranslations(rollout, t)
	};
};

const formatModalTranslations = (rollout: Rollout, t) => {
	if (!rollout) {
		return;
	}

	return {
		title: t(rollout.translations.modal_title),
		description: t(rollout.translations.modal_description),
		settingsText: t('ROLLOUT.MODAL.SETTINGS'),
		cancel: t('ROLLOUT.DONT_SWITCH'),
		apply: t('ROLLOUT.SWITCH'),
		ok: t('ROLLOUT.OK')
	};
};
