import React, { useEffect, useState } from 'react';
import { Modal } from '@bringg/react-components';
import { BringgFontIcons, BringgIcon } from '@bringg/bringg-icons';
import { getFloatingInventoryHistory } from 'bringg-web/stores/floating-inventories/floating-inventories-store';
import { FloatingInventoriesEventLogHeader, FloatingInventoriesEventLogTable } from '..';
import styles from '../../floating-inventories.module.scss';

const EVENT_LOG_TABLE_CONTAINER_WIDTH = 960;

type ContainerProps = {
	floatingInventoryId: number;
	floatingInventoryName: string;
	isVisible?: boolean;
	onClose?: () => void;
};

const FloatingInventoryEventLogModal = ({ floatingInventoryId, floatingInventoryName, onClose }: ContainerProps) => {
	const [lastUpdated, setlastUpdated] = useState<string>();
	const [tableData, setTableData] = useState([]);
	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		(async () => {
			try {
				const response = await getFloatingInventoryHistory({ id: floatingInventoryId });
				if (!response.success) throw new Error('Request was not succesful.');

				setlastUpdated(new Date().toISOString());
				setTableData(response.floating_inventory_histories);
			} catch (error) {
				console.error('Failed to load data ' + error);
				onClose();
			} finally {
				setIsLoading(false);
			}
		})();
	}, [floatingInventoryId]);

	return (
		<Modal
			visible={!!floatingInventoryId}
			footer={null}
			onCancel={onClose}
			width={EVENT_LOG_TABLE_CONTAINER_WIDTH}
			wrapClassName={styles['floating-inventories-event-log-modal']}
			closable={false}
			data-test-id={'floating-inventory-event-log-modal'}
		>
			<div className={styles['floating-inventories-event-log-title-icon']}>
				<BringgIcon iconName={BringgFontIcons.InfoFilled} />
			</div>
			<div className={styles['floating-inventories-event-log-container']}>
				<FloatingInventoriesEventLogHeader title={floatingInventoryName} onClose={onClose} />
				<FloatingInventoriesEventLogTable
					isLoading={isLoading}
					floatingInventoryHistories={tableData}
					lastUpdated={lastUpdated}
				/>
			</div>
		</Modal>
	);
};

export default FloatingInventoryEventLogModal;
