import { useMemo } from 'react';

import { BringgFontIcons, BringgIcon } from '@bringg/bringg-icons';
import { Button, BringgPopover } from '@bringg/react-components';
import { DraggableItem, DraggableListSelector } from '@bringg-frontend/draggable-list-selector';

import { useHistoryTranslation } from 'bringg-web/features/order-history-page/translations';
import { Column } from './columns';

import styles from './column-picker.module.scss';

type Props = {
	columns: Column[];
	onChange: (columns: Column[]) => void;
	onDefault: () => { selectedValues: DraggableItem[]; unselectedValues: DraggableItem[] };
};

const ColumnPicker = ({ columns, onChange, onDefault }: Props) => {
	const translations = useHistoryTranslation();

	const columnsConfig = useMemo(() => {
		return {
			selectedValues: columns.filter(c => c.active),
			unselectedValues: columns.filter(c => !c.active)
		};
	}, [columns]);

	const handleApply = (activeColumns: DraggableItem[]) => {
		const selectedColumns = activeColumns.map(ac => {
			const column = columns.find(c => c.id === ac.id);
			return { ...column, active: true };
		});

		const unselectedColumns = columns
			.filter(column => !selectedColumns.some(c => c.id === column.id))
			.map(column => ({ ...column, active: false }));

		const newColumns = [...selectedColumns, ...unselectedColumns];

		onChange(newColumns);
	};

	return (
		<>
			<BringgPopover
				content={
					<DraggableListSelector
						className={styles.root}
						translations={{
							title: translations.columnPicker,
							defaultButton: translations.default,
							searchPlaceholder: 'Search Column',
							selected: 'Selected',
							nonSelected: 'Unselected',
							applyButton: 'Apply',
							cancelButton: 'Cancel',
							empty: 'Empty'
						}}
						selectedValues={columnsConfig.selectedValues}
						unselectedValues={columnsConfig.unselectedValues}
						changeImmediate
						withSearch
						getDefault={onDefault}
						onApply={handleApply}
					/>
				}
			>
				<Button
					data-test-id="column-picker"
					size="large"
					icon={<BringgIcon iconName={BringgFontIcons.Columns} />}
				/>
			</BringgPopover>
		</>
	);
};

export default ColumnPicker;
