import React from 'react';

import { TimePicker, TimeRangePicker } from '@bringg/react-components';
import moment, { Moment } from 'moment/moment';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { dateUtils } from '@bringg-frontend/utils';

import { durationCalculate } from 'bringg-web/features/planned-delivery-windows/services/duration-calculate';
import { Duration } from 'bringg-web/features/planned-delivery-windows/modals/slots-modal/planned-delivery-windows-slots.consts';

import styles from './times.module.scss';

export interface Props {
	timezone: string;
	use12Hours: boolean;
	format: string;
	updateTimes: (startTime: Moment, endTime: Moment) => void;
	startTimeMinutes: number;
	endTimeMinutes: number;
	timeDuration: Duration;
	updateTimeDuration: (duration: Duration) => void;
	isEditMode: boolean;
}

export const Times = ({
	timeDuration,
	updateTimeDuration,
	startTimeMinutes,
	endTimeMinutes,
	updateTimes,
	use12Hours,
	format,
	isEditMode,
	timezone
}: Props) => {
	const { t } = useTranslation();
	const [hoursDiff, minutesDiff] = dateUtils
		.getDiffAsHoursAndMinutes(
			dateUtils.addMinutesToLastMonday(endTimeMinutes, timezone),
			dateUtils.addMinutesToLastMonday(startTimeMinutes, timezone)
		)
		.split(':');

	const onTimeRangeChanged = (values: [Moment, Moment]) => {
		const [startRange, endRange] = values;
		const halfHour = 30;
		dateUtils.resetMinutesIfIsNotEqual(startRange, halfHour);
		dateUtils.resetMinutesIfIsNotEqual(endRange, halfHour);
		updateTimes(startRange, endRange);
	};

	const onTimeDurationChanged = (value: Moment) => {
		const sumOfValueMinutes = dateUtils.hoursToMinutes(value.hours()) + value.minutes();
		const duration = durationCalculate.getDurations(startTimeMinutes, endTimeMinutes, timezone);
		const durationInMinutes = dateUtils.hoursToMinutes(duration.hours) + duration.minutes;

		if (sumOfValueMinutes > durationInMinutes) {
			updateTimeDuration({ hours: duration.hours, minutes: duration.minutes });
			return;
		} else {
			updateTimeDuration({ hours: value.hours(), minutes: sumOfValueMinutes < 30 ? 30 : value.minutes() });
		}
	};

	return (
		<div className={styles.times}>
			<div className={styles.flexColumn}>
				<span className={styles.label}>{t('PLANNED_DELIVERY_WINDOWS_MODAL.TIME_RANGE')}</span>
				<div className={styles.timeRangePickerWrapper}>
					<TimeRangePicker
						className={styles.timeRangePicker}
						minuteStep={30}
						onChange={onTimeRangeChanged}
						use12Hours={use12Hours}
						format={format}
						value={[
							moment(dateUtils.addMinutesToLastMonday(startTimeMinutes, timezone)),
							moment(dateUtils.addMinutesToLastMonday(endTimeMinutes, timezone))
						]}
						allowClear={false}
						placeholder={[t('PLANNED_DELIVERY_WINDOWS_MODAL.FROM'), t('PLANNED_DELIVERY_WINDOWS_MODAL.TO')]}
					/>
					<div className={styles.timeRangeToText}>
						<span className={styles.timeRangeToTextTitle}>
							{t('PLANNED_DELIVERY_WINDOWS_MODAL.TIME_SLOTS_CONTAINER')}
						</span>
						<span className={styles.timeRangeToTextValue}>
							{` ${hoursDiff} ${t('GLOBAL.HOURS_SHORT')} ${minutesDiff} ${t('GLOBAL.MINUTES_SHORT')}`}
						</span>
					</div>
				</div>
			</div>
			{!isEditMode && (
				<div className={classNames(styles.flexColumn, styles.duration)}>
					<span className={styles.label}>{t('PLANNED_DELIVERY_WINDOWS_MODAL.TIME_WINDOWS_DURATION')}</span>
					<div>
						<TimePicker
							use12Hours={false}
							format={dateUtils.TWENTY_FOUR_HOURS}
							value={moment().set({ ...timeDuration })}
							minuteStep={30}
							onChange={onTimeDurationChanged}
							showNow={false}
							allowClear={false}
							placeholder={t('PLANNED_DELIVERY_WINDOWS_MODAL.TIME_WINDOWS_DURATION_TIME_PICKER')}
							disabledTime={() => ({
								disabledHours: () => {
									const durationHours = dateUtils
										.addMinutesToLastMonday(endTimeMinutes, timezone)
										.diff(dateUtils.addMinutesToLastMonday(startTimeMinutes, timezone), 'hours');
									return [...Array(dateUtils.HOURS_IN_DAY).keys()].filter(
										hourInDay => hourInDay > durationHours
									);
								},
								disabledMinutes: hour => {
									const duration = durationCalculate.getDurations(
										startTimeMinutes,
										endTimeMinutes,
										timezone
									);
									const durationInMinutes =
										dateUtils.hoursToMinutes(duration.hours) + duration.minutes;
									const selectHourInMinutes = dateUtils.hoursToMinutes(hour);
									const halfHour = 30;
									if (hour === 0) {
										return [0];
									}
									if (durationInMinutes - selectHourInMinutes === 0) {
										return [halfHour];
									}
									return [];
								}
							})}
						/>
					</div>
				</div>
			)}
		</div>
	);
};
