import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { getRootEnv } from '@bringg-frontend/bringg-web-infra';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import { Select, Option, Button, Checkbox, Modal, Tooltip } from '@bringg/react-components';
import { OrderPageTabs } from '@bringg/types';
import { BringgFontIcons, BringgIcon } from '@bringg/bringg-icons';

import { useStores } from 'bringg-web/recipes';
import { hasInternalFleet } from 'bringg-web/stores/fleets/fleet-checker';
import notification from 'bringg-web/services/notification';
import { hasFeatureFlag } from 'bringg-web/utils/feature-flags';

import './web-user-interface.scss';

export interface DashboardUiConfigurationOnMerchantLevelForm {
	default_order_page_tab: number;
	show_empty_teams: boolean;
	show_empty_drivers: boolean;
	use_cluster_linked_orders: boolean;
}

export const orderPageOptions = [
	{
		translationKey: 'TAB_DEFAULT_OPTION',
		value: OrderPageTabs.default
	},
	{
		translationKey: 'DISPATCHER_ACKNOWLEDGE.NOTE',
		value: OrderPageTabs.notes
	},
	{
		translationKey: 'ORDER.ATTACHMENTS',
		value: OrderPageTabs.attachments
	},
	{
		translationKey: 'REPORTS.INVENTORIES',
		value: OrderPageTabs.inventories
	},
	{
		translationKey: 'ORDER.TRACKING_LINKS',
		value: OrderPageTabs.tracking
	}
];

const WebUserInterface: React.FC = () => {
	const { merchantConfigurationsStore, fleetsStore, usersStore } = useStores();
	const { t } = useTranslation();
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [showHybridFleetModal, setIsHybridFleetModalOpen] = useState<boolean>(false);
	const { enableFleets } = merchantConfigurationsStore;
	const preventEnablingInternalFleet = hasFeatureFlag(usersStore.currentUser, 'prevent_enabling_internal_fleet');
	const isHybridDeliveryNetworkDisabled = hasInternalFleet();

	const defaultValues = useMemo(() => {
		return {
			default_order_page_tab: merchantConfigurationsStore.defaultOrderPageTabOnMerchantLevel,
			show_empty_teams: merchantConfigurationsStore.showEmptyTeams,
			show_empty_drivers: merchantConfigurationsStore.showEmptyDrivers,
			use_cluster_linked_orders: merchantConfigurationsStore.useClusterLinkedOrdersMerchantLevel
		};
	}, [
		merchantConfigurationsStore.defaultOrderPageTabOnMerchantLevel,
		merchantConfigurationsStore.showEmptyDrivers,
		merchantConfigurationsStore.showEmptyTeams,
		merchantConfigurationsStore.useClusterLinkedOrdersMerchantLevel
	]);

	useEffect(() => {
		const fetchData = async () => {
			await merchantConfigurationsStore.fetch();
			setIsLoading(false);
		};

		fetchData();
	}, []);

	const form = useForm<DashboardUiConfigurationOnMerchantLevelForm>({
		mode: 'onChange',
		defaultValues
	});

	const { control, handleSubmit } = form;
	const handleFormSubmit = useCallback(async () => {
		const dashboardUiConfigurationOnMerchantLevel = form.getValues();
		try {
			setIsLoading(true);
			await getRootEnv().dashboardSdk.sdk.merchantConfiguration.setDashboardUiConfigurationOnMerchantLevel(
				dashboardUiConfigurationOnMerchantLevel
			);
			await merchantConfigurationsStore.forceFetch();
		} catch (e) {
			form.reset({
				default_order_page_tab: merchantConfigurationsStore.defaultOrderPageTabOnMerchantLevel
			});
		} finally {
			setIsLoading(false);
		}
	}, [merchantConfigurationsStore, form]);

	const onHybridFleetWarningApprove = async () => {
		setIsLoading(true);
		const response = await fleetsStore.handleEnableHybridDeliveryNetwork();
		if (response) {
			notification.success('WEB_USER_INTERFACE.ENABLE_HYBRID_DELIVERY_NETWORK.SUCCESS');
		} else {
			notification.error('WEB_USER_INTERFACE.ENABLE_HYBRID_DELIVERY_NETWORK.ERROR');
		}
		setIsLoading(false);
		setIsHybridFleetModalOpen(false);
	};

	return (
		<div className="web-user-interface">
			<form>
				<div className="controller-container">
					<div className="controller-title">{t('DEFAULT_ORDER_PAGE_TAB')}</div>
					<Controller
						render={({ value, onChange }) => (
							<Select onChange={onChange} value={value} className="controller-select default-tab">
								{orderPageOptions.map(option => (
									<Option value={option.value} key={option.value}>
										{t(option.translationKey)}
									</Option>
								))}
							</Select>
						)}
						control={control}
						name="default_order_page_tab"
					/>
				</div>

				<div className="controller-container align-start">
					<div className="controller-title">{t('WEB_USER_INTERFACE.SHOW_EMPTY_TEAMS')}</div>
					<Controller
						className="controller-checkbox empty-teams"
						render={({ value, onChange }) => (
							<Checkbox onChange={event => onChange(event.target.checked)} checked={value} />
						)}
						control={control}
						name="show_empty_teams"
					/>
				</div>

				<div className="controller-container align-start">
					<div className="controller-title">{t('WEB_USER_INTERFACE.SHOW_EMPTY_DRIVERS')}</div>
					<Controller
						className="controller-checkbox empty-drivers"
						render={({ value, onChange }) => (
							<Checkbox onChange={event => onChange(event.target.checked)} checked={value} />
						)}
						control={control}
						name="show_empty_drivers"
					/>
				</div>

				{!preventEnablingInternalFleet && (isHybridDeliveryNetworkDisabled || enableFleets) && (
					<div className="controller-container align-start">
						<div className="controller-title align-center">
							{t('WEB_USER_INTERFACE.ENABLE_HYBRID_DELIVERY_NETWORK')}
							<Tooltip title={t('WEB_USER_INTERFACE.ENABLE_HYBRID_DELIVERY_NETWORK_TOOLTIP')}>
								<BringgIcon className="info-icon" iconName={BringgFontIcons.Info} />
							</Tooltip>
						</div>
						<Checkbox
							data-test-id="hybrid-checkbox"
							disabled={isHybridDeliveryNetworkDisabled}
							checked={isHybridDeliveryNetworkDisabled}
							onClick={() => setIsHybridFleetModalOpen(true)}
						/>
					</div>
				)}

				<div className="controller-container align-start">
					<div className="controller-title align-center">
						{t('WEB_USER_INTERFACE.USE_CLUSTER_LINKED_ORDERS')}
					</div>
					<Controller
						className="controller-checkbox use_cluster_linked_orders"
						render={({ value, onChange }) => (
							<Checkbox onChange={event => onChange(event.target.checked)} checked={value} />
						)}
						control={control}
						name="use_cluster_linked_orders"
					/>
				</div>

				<Button
					onClick={handleSubmit(handleFormSubmit)}
					disabled={isLoading}
					type="primary"
					className="btn-update"
				>
					{t('GLOBAL.UPDATE')}
				</Button>
			</form>

			<Modal
				visible={showHybridFleetModal}
				className="hybrid-fleet-warning-modal"
				okText={t('YES')}
				cancelText={t('GENERAL.NO')}
				onOk={onHybridFleetWarningApprove}
				onCancel={() => setIsHybridFleetModalOpen(false)}
				title={
					<div className="modal-title">
						<BringgIcon className="warning-icon" iconName={BringgFontIcons.PrompedMessage} />
						<span className="warning-title">{t('WEB_USER_INTERFACE.FLEET_WARNING_TITLE')}</span>
					</div>
				}
			>
				<p className="warning-body">
					{t('WEB_USER_INTERFACE.FLEET_WARNING_TEXT')}
					<a
						className="warning-link"
						href="https://help.bringg.com/r/Ops-Manager-Guide/Manage-your-Delivery-Network-with-Bringg-s-Delivery-Hub"
						target="_blank"
						rel="noopener noreferrer"
					>
						{t('LEARN_MORE')}
					</a>
				</p>
			</Modal>
		</div>
	);
};

export default WebUserInterface;
