import { useMemo } from 'react';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import type { RateCardTermTranslations } from '@bringg/react-components';

interface BooleanKeys {
	trueKey: string;
	falseKey: string;
}

interface RatesRootTranslations {
	discardChanges: string;
	seeRateSummary: string;
	saveAndDeploy: string;
	backToEdit: string;
	errorTitle: string;
	errorBody: string;
	basicFee: string;
	surchargeFee: string;
	addNewTerm: string;
	useForQuotes: string;
	useForQuotesTooltip: string;
	removeModalTitle: string;
	removeModalBody: string;
	cancel: string;
	delete: string;
	readonlyMessage: string;
}

interface TranslationsReturnType {
	termTranslations: RateCardTermTranslations;
	rootTranslations: RatesRootTranslations;
}

const useRateCardTranslations = (booleanKeys: BooleanKeys): TranslationsReturnType => {
	const { t } = useTranslation();

	return useMemo<TranslationsReturnType>(
		() => ({
			termTranslations: {
				numeric: {
					addRow: t('CONDITIONS.NUMERIC.ADD_A_ROW'),
					equals: t('CONDITIONS.NUMERIC.EQUALS'),
					notEquals: t('CONDITIONS.NUMERIC.NOT_EQUALS'),
					lessThan: t('CONDITIONS.NUMERIC.LESS_THAN'),
					lessThanOrEquals: t('CONDITIONS.NUMERIC.LESS_THAN_OR_EQUALS'),
					greaterThan: t('CONDITIONS.NUMERIC.GREATER_THAN'),
					greaterThanOrEquals: t('CONDITIONS.NUMERIC.GREATER_THAN_OR_EQUALS'),
					inRange: t('CONDITIONS.NUMERIC.IN_RANGE'),
					removeTooltipText: t('GLOBAL.REMOVE'),
					validations: {
						onlyPositive: t('ALERTS_CONFIGURATION.CONDITIONS.VALIDATION.ONLY_POSITIVE'),
						toGreaterThanFrom: t('ALERTS_CONFIGURATION.CONDITIONS.VALIDATION.TO_GREATER_THAN_FROM')
					}
				},
				array: {
					hasOne: t('CONDITIONS.ARRAY.ARRAY_INTERSECTION_HAS_ONE'),
					hasAll: t('CONDITIONS.ARRAY.ARRAY_INTERSECTION_HAS_ALL'),
					operatorPlaceholder: t('CONDITIONS.ARRAY.OPERATOR_SELECT_PLACEHOLDER'),
					valuePlaceHolder: t('CONDITIONS.ARRAY.VALUE_SELECT_PLACEHOLDER')
				},
				freeText: {
					addRow: t('CONDITIONS.STRING.ADD_A_ROW'),
					equals: t('CONDITIONS.STRING.EQUALS'),
					notEquals: t('CONDITIONS.STRING.NOT_EQUALS'),
					include: t('CONDITIONS.STRING.INCLUDE'),
					exclude: t('CONDITIONS.STRING.EXCLUDE'),
					removeTooltipText: t('GLOBAL.REMOVE')
				},
				addAttribute: t('CONDITIONS.ADD_ATTRIBUTE'),
				getBooleanTranslations: (attribute_name: string) => ({
					trueOption: i18next.t(booleanKeys.trueKey, { attribute_name }),
					falseOption: i18next.t(booleanKeys.falseKey, { attribute_name }),
					removeTooltipText: t('GLOBAL.REMOVE')
				}),
				removeTooltipText: t('GLOBAL.REMOVE'),
				copyTooltipText: t('GLOBAL.DUPLICATE'),
				rateTitle: t('RATES.RATE'),
				enableTerm: t('RATES.ENABLE_TERM'),
				disableTerm: t('RATES.DISABLE_TERM'),
				inactiveTerm: t('GLOBAL.INACTIVE')
			},
			rootTranslations: {
				backToEdit: t('GLOBAL.BACK_TO_EDIT'),
				saveAndDeploy: t('GLOBAL.SAVE_AND_DEPLOY'),
				discardChanges: t('CONDITIONS.DISCARD_CHANGES'),
				seeRateSummary: t('RATES.PREVIEW_RATE'),
				basicFee: t('RATES.BASIC_FEE'),
				surchargeFee: t('RATES.SURCHARGE_FEE'),
				addNewTerm: t('RATES.ADD_NEW_TERM'),
				// Might use some more informative texting here, but anyway it is an edge case
				errorTitle: t('ERROR_BOUNDARY_PAGE.TITLE'),
				errorBody: t('ERROR_BOUNDARY_PAGE.SUBTITLE'),
				useForQuotes: t('RATES.USE_FOR_QUOTES'),
				useForQuotesTooltip: t('RATES.USE_FOR_QUOTES_TOOLTIP'),
				removeModalTitle: t('RATES.MODAL_DELETE_TITLE'),
				removeModalBody: t('RATES.DELETE_BODY'),
				cancel: t('GLOBAL.CANCEL'),
				delete: t('GLOBAL.DELETE'),
				readonlyMessage: t('RATES.READONLY_NOTICE_MESSAGE')
			}
		}),
		[t, booleanKeys]
	);
};

export default useRateCardTranslations;
