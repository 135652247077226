import React, { useCallback, useEffect, useRef, useState } from 'react';

import { ActivityTypes, User } from '@bringg/types';
import { useTranslation } from 'react-i18next';
import { Collapse, Divider, Form } from '@bringg/react-components';

import { getValidateMessages } from '../../../utils/validate-messages';
import {
	AddressField,
	AllowedUserTypesField,
	CompanyField,
	DefaultUserActivityField,
	EmailField,
	ExternalIDField,
	JobDescriptionField,
	NameField,
	PasswordField,
	PhoneField,
	SkillsField,
	TeamsField
} from '../form-fields';
import { useMerchantConfiguration } from '../../../recipes';
import { useIsAdmin } from 'bringg-web/utils/privileges';

const COLLAPSE_ANIMATION_TIME = 250;

interface Props {
	form: any;
	onSubmit: (user: Partial<User & { password: string }>) => void;
}

export default function DriverForm({ form, onSubmit }: Props) {
	const scrollToEl = useRef(null);
	const { t } = useTranslation();
	const { secured_setup, enable_secured_user_passwords, enable_teams: enableTeams } = useMerchantConfiguration();

	const [emailPasswordRequired, setEmailPasswordRequired] = useState(false);
	const [phoneRequired, setPhoneRequired] = useState(false);

	const [activePanel, setActivePanel] = useState<string[] | string>([]);

	useEffect(() => {
		if (!activePanel.length || !scrollToEl) {
			return;
		}

		setTimeout(() => {
			scrollToEl.current.scrollIntoView({ behavior: 'smooth' });
		}, COLLAPSE_ANIMATION_TIME);
	}, [activePanel, scrollToEl]);

	const setRequiredFields = useCallback(() => {
		const hasPhone = Boolean(form.getFieldValue('phone'));
		const hasEmail = Boolean(form.getFieldValue('email'));
		const hasPassword = Boolean(form.getFieldValue('password'));
		setEmailPasswordRequired(!hasPhone || hasPassword || hasEmail);
		setPhoneRequired(!hasEmail);
	}, [form]);

	useEffect(() => {
		setRequiredFields();
	}, [setRequiredFields]);

	const validatePhoneOrEmail = useCallback(
		(value: string, callback: (message?: string) => void) => {
			const values = form.getFieldsValue(true);
			const isSecured = secured_setup || enable_secured_user_passwords;
			const message = isSecured
				? t('CREATE_USER_MODAL.PHONE_OR_EMAIL_REQUIRED')
				: t('CREATE_USER_MODAL.PHONE_OR_EMAIL_PASSWORD_REQUIRED');
			const noPassword = isSecured ? false : !values.password;

			return (!values.email || noPassword) && !values.phone ? callback(message) : callback();
		},
		[form, t, secured_setup, enable_secured_user_passwords]
	);

	const isOnlyDispatcher = !useIsAdmin();

	return (
		<Form
			validateMessages={getValidateMessages(t)}
			validateTrigger={['onBlur']}
			autoComplete="do-not-autofill"
			scrollToFirstError
			colon={false}
			onSubmit={onSubmit}
			form={form}
			className="driver-form"
			onValuesChange={setRequiredFields}
			initialValues={{ default_user_activity: ActivityTypes.DRIVING }}
		>
			<div className="form-group">
				<h4>{t('CREATE_USER_MODAL.MANDATORY_DETAILS')}</h4>
				<div className="sub-header">{t('CREATE_USER_MODAL.WARNING_MESSAGE')}</div>
				<NameField />
				<PhoneField required={phoneRequired} validator={(_, value, cb) => validatePhoneOrEmail(value, cb)} />
				<EmailField required={emailPasswordRequired} validator={(_, v, cb) => validatePhoneOrEmail(v, cb)} />
				<PasswordField required={emailPasswordRequired} validator={(_, v, cb) => validatePhoneOrEmail(v, cb)} />
				<AddressField form={form} />
				<TeamsField required={isOnlyDispatcher && enableTeams} />

				<Collapse ghost onChange={setActivePanel}>
					<Collapse.Panel
						forceRender
						className="driver-form-collapse"
						showArrow={false}
						header={t(
							activePanel.length
								? 'CREATE_USER_MODAL.HIDE_ADVANCED_FIELDS'
								: 'CREATE_USER_MODAL.SHOW_ADVANCED_FIELDS'
						)}
						key="1"
					>
						<Divider className="divider-margin" />
						<h4>{t('CREATE_USER_MODAL.ADVANCED_DETAILS')}</h4>
						<ExternalIDField />
						<AllowedUserTypesField form={form} />
						<SkillsField />
						<DefaultUserActivityField />
						<CompanyField />
						<JobDescriptionField className="full-width" />
						<div ref={scrollToEl} className="dummy" />
					</Collapse.Panel>
				</Collapse>
			</div>
		</Form>
	);
}
