import React, { FunctionComponent } from 'react';
import Checkbox from '@bringg/react-components/dist/components/checkbox/checkbox';
import Input from '@bringg/react-components/dist/components/floating-placeholder-input/input';
import Row from '@bringg/react-components/dist/components/row/row';
import Col from '@bringg/react-components/dist/components/col/col';
import Translate from '../../../translation/translator';
import { Divider } from 'antd';

interface Props {
	allowRating: boolean;
	ratingScreenTitle: string;
	onChange: <T>(paramName: string, value: T) => void;
}

const RatingScreen: FunctionComponent<Props> = ({ allowRating, ratingScreenTitle, onChange }) => {
	return (
		<div className="rating-screen">
			<Divider>
				<h3>
					<Translate text={'RATING_CONFIGURATION.RATING_SCREEN'} />
				</h3>
			</Divider>
			<Row className="customer-rating-field">
				<Col xs={20}>
					<Translate text={'RATING_CONFIGURATION.SHOW_RATING_SCREEN'} />
				</Col>
				<Col xs={4}>
					<Checkbox checked={allowRating} onChange={e => onChange('allowRating', e.target.checked)} />
				</Col>
			</Row>
			<Row className="rating-screen-title">
				<Col xs={24}>
					<Input
						isDisabled={allowRating === false}
						placeholder={<Translate text={'RATING_CONFIGURATION.RATING_SCREEN_TITLE'} />}
						value={ratingScreenTitle}
						onChange={e => onChange('ratingScreenTitle', e.target.value)}
					/>
				</Col>
			</Row>
		</div>
	);
};

export default RatingScreen;
