import React from 'react';

import { inject, observer } from 'mobx-react';
import { Collapse } from 'antd';
import _first from 'lodash/first';
import { MetricType } from '@bringg/react-components/dist/components/metric-counter/metric-counter';
import { withErrorBoundary } from '@bringg-frontend/bringg-web-infra';

import VrpAutoDispatchAnalyticsStore from 'bringg-web/stores/vrp-auto-dispatch-analytics/vrp-auto-dispatch-analytics-store';
import { Spinner, MetricCounter } from 'bringg-web/components';
import { Translate } from 'bringg-web/translation';
import VrpAutoDispatchAnalytics from './vrp-auto-dispatch-analytics';

const { Panel } = Collapse;

const WARN_THRESHOLD = 3 * 60 * 1000;

interface Props {
	vrpAutoDispatchAnalyticsStore?: VrpAutoDispatchAnalyticsStore;
}

class VrpAutoDispatchAnalyticsContainer extends React.Component<Props> {
	async componentDidMount() {
		const { fetchAndStartPolling, expanded } = this.props.vrpAutoDispatchAnalyticsStore;
		if (expanded) {
			await fetchAndStartPolling();
		}
	}

	componentWillUnmount() {
		this.props.vrpAutoDispatchAnalyticsStore.dispose();
	}

	handleCollapseChange = async (expanded: boolean) => {
		const { fetchAndStartPolling, dispose, setExpanded } = this.props.vrpAutoDispatchAnalyticsStore;
		if (expanded) {
			await fetchAndStartPolling();
		} else {
			dispose();
		}

		setExpanded(expanded);
	};

	render() {
		const { handleCollapseChange } = this;
		const {
			expanded,
			analytics,
			lastUpdated,
			lastVrpRun,
			isFetched,
			getLastVrpRunMilliseconds,
			setAggregationTime
		} = this.props.vrpAutoDispatchAnalyticsStore;

		return (
			<Collapse
				bordered={false}
				className="vrp-auto-dispatch-metric-container"
				defaultActiveKey={expanded ? ['1'] : []}
				onChange={async expanded => handleCollapseChange(!!_first(expanded))}
				expandIconPosition="start"
			>
				<Panel
					header={
						<div
							className={
								getLastVrpRunMilliseconds() < WARN_THRESHOLD
									? 'vrp-auto-dispatch-metric-panel-header'
									: 'vrp-auto-dispatch-metric-panel-header warn'
							}
						>
							{lastVrpRun ? (
								<MetricCounter
									metricValue={new Date().getTime() - lastVrpRun.getTime()}
									metricType={MetricType.TIME}
									className="vrp-auto-dispatch-analytics-last-run"
								/>
							) : null}
							<Translate
								text={lastVrpRun ? 'VRP_AD.ANALYTICS.LAST_RUN' : 'VRP_AD.ANALYTICS.STATISTICS'}
							/>
						</div>
					}
					key="1"
					className="vrp-auto-dispatch-metric-panel"
				>
					{isFetched ? (
						<VrpAutoDispatchAnalytics
							analytics={analytics}
							lastUpdated={lastUpdated}
							onAggregationTimeSelect={setAggregationTime}
						/>
					) : (
						<Spinner className="vrp-auto-dispatch-spinner" />
					)}
				</Panel>
			</Collapse>
		);
	}
}

export default withErrorBoundary(inject('vrpAutoDispatchAnalyticsStore')(observer(VrpAutoDispatchAnalyticsContainer)));
