import { RateCardRateBase } from '@bringg/types';

const defaultPrecision = 2;

export const getOperator = (base: RateCardRateBase) => (base === RateCardRateBase.Flat ? '=' : 'x');

export const getAmountWithPrecision = (value: number): string => {
	const [, decimal] = String(value).split('.');
	return decimal?.length > defaultPrecision ? String(value) : value.toFixed(defaultPrecision);
};
