import React, { LegacyRef } from 'react';

import $ from 'jquery';
import { useObserver } from 'mobx-react-lite';

import ApplicationConfigurationForm from '../../application-configuration-form/application-configuration-form';

import { ApplicationUuid } from '@bringg/types';
import Form from 'react-jsonschema-form';

import './team-settings.scss';

import { useTranslation } from 'react-i18next';
import { withErrorBoundary } from '@bringg-frontend/bringg-web-infra';

import { useIsAdmin } from '../../../utils/privileges';

interface Props {
	teamId: number;
}
const TeamSettings: React.FC<Props> = ({ teamId }) => {
	const formRef = React.createRef() as LegacyRef<Form<unknown>>;
	const isAdmin = useIsAdmin();
	const { t } = useTranslation();

	const onFormRender = (pluginIndex: number) => {
		const formElement = $(formRef?.['current']?.formElement);
		if (formElement.length && formElement.find('#root_jspritRequestHeaders__title').length) {
			const delayedAssignments = formElement.find('#root_plugins_' + pluginIndex).clone();
			//remove all form elements beside delayed assignment plugin
			if (delayedAssignments.length) {
				formElement.find('.field').remove();
				delayedAssignments.prependTo(formElement);
				formElement.find('legend').text(t('TEAMS.CONFIGURATION_FORM.AUTO_DISPATCH_ASSIGNMENTS_TITLE'));
				formElement.find('.form-group').first().remove();
				formElement.find('.field-object #root_options__title').remove();
				const optionToShow = formElement.find('.field-boolean').eq(2).clone();
				const form = formElement.find('.field-object');
				form.find('#root_options').remove();
				optionToShow.appendTo(form);
				const helpText = $(
					'<div>' + t('TEAMS.CONFIGURATION_FORM.ONLY_ONE_ORDER_PER_DRIVER_DESCRIPTION') + '</div>'
				);
				helpText.appendTo(form);

				if (!isAdmin) {
					$('#root_options_skipAssignToBusyDrivers').prop('disabled', true);
					$('.team-settings-container .btn-info').prop('disabled', true);
					$('.team-settings-container .delete-button').prop('disabled', true);
				}
			}
		} else if (!isAdmin) {
			$('.team-settings-container .create-conf-button').prop('disabled', true);
		}
	};

	const disableForm = (disable: boolean, merchantAssignToBusyDrivers?: boolean) => {
		setTimeout(() => {
			const formElement = $(formRef?.['current']?.formElement);
			if (disable) {
				formElement.find('#root_options_skipAssignToBusyDrivers').attr('disabled', true);
				formElement.find('#root_options_skipAssignToBusyDrivers')[0].checked = merchantAssignToBusyDrivers;
				formElement.find(':submit').hide();
			} else {
				formElement.find('#root_options_skipAssignToBusyDrivers').attr('disabled', false);
				formElement.find(':submit').show();
			}
		});
	};

	return useObserver(() => (
		<div className="team-settings-container" data-test-id="team-settings-container">
			<ApplicationConfigurationForm
				appUuid={ApplicationUuid.VrpAutoDispatch as ApplicationUuid}
				teamId={teamId}
				formRef={formRef}
				onFormRender={onFormRender}
				disableForm={disableForm}
			/>
		</div>
	));
};

export default withErrorBoundary(TeamSettings);
