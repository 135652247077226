import { useCallback, useState } from 'react';

import { TableState } from 'react-table';
import type { CustomAttributeRes } from '@bringg/types';

import {
	AttributeStatusEnum,
	AttributeMandatoryEnum,
	columnIds,
	DEFAULT_COLUMN_WIDTH,
	AttributeDriverAppEnum
} from '../utils';
import { getTableAndColumnsWidthDiff } from 'bringg-web/components/bringg-react-table/utils';
import type { UseTableFilters } from './use-table-filters';

export const useControlledTableState = (
	filters: UseTableFilters['filters'],
	isVisible: boolean,
	isLoading: boolean,
	tableContainerRef,
	columns
) => {
	const [minTableWidthInited, setMinTableWidthInited] = useState(false);

	return useCallback(
		(state: TableState<CustomAttributeRes>) => {
			const nextState: TableState<CustomAttributeRes> = { ...state };
			const { searchText, suggestion, data_type, required, show_in_mobile_app } = filters;

			nextState.filters = [];

			if (searchText?.trim()) {
				const value = searchText.trim();
				nextState.filters.push({ id: columnIds.NAME, value });
				nextState.filters.push({ id: columnIds.PATH, value });
			}

			if (data_type?.length) {
				nextState.filters.push({ id: columnIds.DATA_TYPE, value: data_type });
			}

			if (suggestion) {
				nextState.filters.push({
					id: columnIds.SUGGESTION,
					value: suggestion === AttributeStatusEnum.SUGGESTED
				});
			}

			if (required) {
				nextState.filters.push({
					id: columnIds.REQUIRED,
					value: required === AttributeMandatoryEnum.MANDATORY
				});
			}

			if (show_in_mobile_app) {
				nextState.filters.push({
					id: columnIds.DRIVER_APP,
					value: show_in_mobile_app === AttributeDriverAppEnum.DRIVER_APP_ENABLED
				});
			}

			if (!minTableWidthInited && tableContainerRef && columns && isVisible && !isLoading) {
				const mappedColumns = columns.map(({ id, width, disableResizing }) => {
					return {
						id,
						width: width || DEFAULT_COLUMN_WIDTH,
						disableResizing
					};
				});

				const diff = getTableAndColumnsWidthDiff(mappedColumns, tableContainerRef.current.clientWidth);

				if (diff > 0) {
					const adjustableColumns = mappedColumns.filter(({ disableResizing }) => !disableResizing);
					const additionalWidthPerColumn = Math.floor(diff / adjustableColumns.length);

					const nextColumnWidths = adjustableColumns
						.map(item => ({ ...item, width: (item.width as number) + additionalWidthPerColumn }))
						.reduce((acc, { id, width }) => Object.assign(acc, { [id]: width }), {});

					nextState.columnResizing.columnWidths = nextColumnWidths;
				}

				setMinTableWidthInited(true);
			}

			return nextState;
		},
		[filters, minTableWidthInited, isVisible, isLoading, tableContainerRef, columns]
	);
};
