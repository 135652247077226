import React, { useCallback } from 'react';
import { Col, Form, FormInstance, Row, Select } from 'antd';
import moment from 'moment';
import { TFunction } from 'i18next';
import { BreakType } from '@bringg/types';
import { useTranslation } from 'react-i18next';
import { FormItemWrapper, LabelNumberKeyboardInput, TimePicker } from '@bringg/react-components';
import AddBreak from './add-break/add-break';
import RemoveBreak from './remove-break/remove-break';
import { DeliveryBlockBreakId, LocationOption } from '../../../stores/domain-objects/delivery-block-break';
import { DELIVERY_BLOCK_TIME_FORMAT, DeliveryBlockBreaksFormValue } from '../delivery-block-form';
import {
	BREAK_TYPE_OPTIONS,
	getEndTimeText,
	getStartTimeText,
	getTimesSeparator,
	LOCATION_OPTIONS
} from './breaks.consts';

const BREAKS_FIELD = 'deliveryBlocksBreaks';

interface Props {
	form?: FormInstance;
	onBreakRemoved: (id: DeliveryBlockBreakId) => void;
	onBreakAdded: () => void;
}

const getBreakTypesOptions = (t: TFunction) => {
	return BREAK_TYPE_OPTIONS.map(breakType => (
		<Select.Option key={breakType} value={BreakType[breakType]}>
			{t(`DELIVERY_BLOCKS.${breakType.toUpperCase()}_BREAK`)}
		</Select.Option>
	));
};

const getLocationOptions = (t: TFunction) => {
	return LOCATION_OPTIONS.map(locationOption => (
		<Select.Option key={locationOption} value={LocationOption[locationOption]}>
			{t(`DELIVERY_BLOCKS.${locationOption}`)}
		</Select.Option>
	));
};

const FORM_LAYOUT_PROPS = {
	labelCol: { xs: { span: 10 } },
	wrapperCol: { xs: { span: 14 } }
};

export const DeliveryBlockBreaks = ({ form, onBreakRemoved, onBreakAdded }: Props) => {
	const { t } = useTranslation();
	const { getFieldValue } = form;

	const breaks: DeliveryBlockBreaksFormValue = getFieldValue(BREAKS_FIELD);

	const onFieldChanged = useCallback(
		(id, field, value) => {
			const deliveryBlocksBreaks: DeliveryBlockBreaksFormValue = form.getFieldValue(BREAKS_FIELD);
			deliveryBlocksBreaks.get(id)[field] = value;
			form.setFieldsValue({
				deliveryBlocksBreaks
			});
		},
		[form]
	);

	const validateBreak = useCallback(
		async (rule, value) => {
			const { startTime, endTime } = form.getFieldsValue(['startTime', 'endTime']);
			const requestedBreakTime = moment(value.format(DELIVERY_BLOCK_TIME_FORMAT), DELIVERY_BLOCK_TIME_FORMAT);
			if (
				!requestedBreakTime.isBetween(
					moment(startTime.format(DELIVERY_BLOCK_TIME_FORMAT), DELIVERY_BLOCK_TIME_FORMAT),
					moment(endTime.format(DELIVERY_BLOCK_TIME_FORMAT), DELIVERY_BLOCK_TIME_FORMAT)
				)
			) {
				return Promise.reject(t('DELIVERY_BLOCKS.VALIDATION_MESSAGES.BREAK'));
			}
			return Promise.resolve();
		},
		[form, t]
	);

	return (
		<div className="delivery-blocks-breaks">
			{Array.from(breaks.entries()).map(([id, deliveryBlockBreak], index) => (
				<div key={id}>
					<FormItemWrapper
						name={`[${id}].breakType`}
						initialValue={deliveryBlockBreak.breakType}
						className="time-range"
						label={`${t('DELIVERY_BLOCKS.BREAK')}-${index + 1}`}
						{...FORM_LAYOUT_PROPS}
					>
						<Select onSelect={value => onFieldChanged(id, 'breakType', value)}>
							{getBreakTypesOptions(t)}
						</Select>
					</FormItemWrapper>
					<Form.Item noStyle shouldUpdate>
						{({ getFieldValue }) =>
							getFieldValue(`[${id}].breakType`) === BreakType.Flex ? (
								<FormItemWrapper
									name={`[${id}].length`}
									label={`${t('DELIVERY_BLOCKS.LENGTH')}`}
									className="time-range"
									initialValue={deliveryBlockBreak.length}
									{...FORM_LAYOUT_PROPS}
								>
									<LabelNumberKeyboardInput
										label={`${t('DELIVERY_BLOCKS.MINUTES')}`}
										minValue={1}
										maxValue={240}
										onChange={value => onFieldChanged(id, 'length', value)}
										className="breaks-length"
									/>
								</FormItemWrapper>
							) : null
						}
					</Form.Item>
					<Row>
						<Col span={12}>{getStartTimeText(deliveryBlockBreak.breakType, t)}</Col>
						<Col span={12}>{getEndTimeText(deliveryBlockBreak.breakType, t)}</Col>
					</Row>

					<Row>
						<Col span={10}>
							<FormItemWrapper
								name={`[${id}].startTime`}
								initialValue={deliveryBlockBreak.startTime}
								rules={[{ validator: validateBreak }]}
								className="time-range"
							>
								<TimePicker
									suffixIcon={<></>}
									onChange={value => onFieldChanged(id, 'startTime', value)}
									use12Hours
									allowClear={false}
									format={DELIVERY_BLOCK_TIME_FORMAT}
									showNow={false}
								/>
							</FormItemWrapper>
						</Col>
						<Col span={4} className="time-range-separator-break">
							{getTimesSeparator(deliveryBlockBreak.breakType)}
						</Col>
						<Col span={10}>
							<FormItemWrapper
								name={`[${id}].endTime`}
								initialValue={deliveryBlockBreak.endTime}
								rules={[{ validator: validateBreak }]}
								className="time-range"
							>
								<TimePicker
									suffixIcon={<></>}
									onChange={value => onFieldChanged(id, 'endTime', value)}
									use12Hours
									allowClear={false}
									format={DELIVERY_BLOCK_TIME_FORMAT}
									showNow={false}
								/>
							</FormItemWrapper>
						</Col>
					</Row>
					<FormItemWrapper
						name={`[${id}].locationOption`}
						initialValue={deliveryBlockBreak.locationOption}
						className="time-range"
						label={t('DELIVERY_BLOCKS.DEFINE_LOCATION')}
						{...FORM_LAYOUT_PROPS}
					>
						<Select onSelect={value => onFieldChanged(id, 'locationOption', value)}>
							{getLocationOptions(t)}
						</Select>
					</FormItemWrapper>
					<RemoveBreak onBreakRemoved={() => onBreakRemoved(id)} />
					<div className="break-separator" />
				</div>
			))}
			<AddBreak onBreakAdded={onBreakAdded} useSmallIcon />
		</div>
	);
};
