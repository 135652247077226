import { getRootEnv } from '@bringg-frontend/bringg-web-infra';
import { WindowEventTypes } from '@bringg/frontend-utils';
import _includes from 'lodash/includes';
import { getEnv } from 'mobx-easy';
import { RootEnv } from '@bringg-frontend/bringg-web-infra';

const ORIGIN_SUFFIX = 'bringg.com';

export default class WindowCommunicationService {
	static isDev() {
		const { envConfig } = getRootEnv();

		return (
			envConfig.environment === 'development' ||
			_includes(envConfig.regions[envConfig.default_region].web_api_url, 'qa.bringg.com')
		);
	}

	static messageHandler(currentWindow, type, data) {
		return event => {
			if (!event.data && !event.origin) {
				return;
			}

			if (!event.origin.endsWith(ORIGIN_SUFFIX) && !WindowCommunicationService.isDev()) {
				const msg = `Received unwanted message from ${event.origin}`;
				console.error(msg);
				return;
			}

			if (event.data === type) {
				currentWindow.postMessage(data, '*');
			}
		};
	}

	private static postMessage(currentWindow, type, data) {
		let currWindow = currentWindow;

		if (!currWindow) {
			currWindow = document.querySelector('iframe').contentWindow;
		}

		const messageEventHandler = WindowCommunicationService.messageHandler(currWindow, type, data);

		window.addEventListener('message', messageEventHandler, false);

		setTimeout(() => {
			window.removeEventListener('message', messageEventHandler);
			// eslint-disable-next-line @typescript-eslint/no-magic-numbers
		}, 15000);
	}

	static sendCredentials(currentWindow) {
		const { dashboardSdk } = getRootEnv();
		WindowCommunicationService.postMessage(currentWindow, WindowEventTypes.RequestCredentials, {
			type: WindowEventTypes.Credentials,
			token: dashboardSdk.sdk.session.user.authentication_token.toString(),
			region: dashboardSdk.sdk.session.user.region.toString()
		});
	}

	static sendInitialData(currentWindow, data) {
		data.type = WindowEventTypes.InitialData;
		WindowCommunicationService.postMessage(currentWindow, WindowEventTypes.RequestInitialData, data);
	}
}
