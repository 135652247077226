import { action, computed, makeObservable, observable } from 'mobx';
import {
	ActionType,
	ChangeType,
	EntityUpdateAction,
	ManualTriggerParamDataType,
	PrimitiveManualTriggerParamDataType,
	SingleUpdateEntityConfiguration,
	UpdateEntityConfigurationData,
	UpdateFieldMetadata
} from '@bringg/types';
import { filterObject } from '@bringg-frontend/utils';

import { ActionsRepo } from './internal';
import { ActionFamilyType, ClientUpdateEntityAction } from '../utils/types';
import UpdateEntityActionStore from './update-entity-action-store';
import ActionBaseStore from './action-base-store';

class UpdateEntityActionRepo extends ActionBaseStore {
	actions: UpdateEntityActionStore[];

	type = ActionFamilyType.UPDATE_ENTITY;
	constructor(actionToInit: ClientUpdateEntityAction, actionsRepo: ActionsRepo) {
		super(actionsRepo);

		makeObservable(this, {
			actions: observable,
			addActionToRepo: action,
			removeAction: action,
			mappedToServer: computed,
			isValid: computed,
			availableAttributes: computed
		});
		this.actions = actionToInit.actions.map(
			_action => new UpdateEntityActionStore(this, _action as SingleUpdateEntityConfiguration)
		);

		if (!this.actions.length) {
			this.addActionToRepo();
		}
	}

	addActionToRepo = (identifier = '') => {
		this.actions.push(new UpdateEntityActionStore(this, { identifier }));
	};

	removeAction = (action: UpdateEntityActionStore) => {
		this.actions = this.actions.filter(ac => ac !== action);
	};

	private getStaticSpecificData(updateAction): UpdateEntityConfigurationData {
		return {
			type: ChangeType.SPECIFIC_VALUE,
			value: updateAction.value
		};
	}

	private getDynamicSpecificData(
		updateAction: UpdateEntityActionStore,
		dataType: ManualTriggerParamDataType
	): UpdateEntityConfigurationData {
		return {
			type: ChangeType.SPECIFIC_VALUE,
			name: updateAction.value as string,
			data_type: dataType
		};
	}

	private getStaticRelativeData(updateAction: UpdateEntityActionStore): UpdateEntityConfigurationData {
		return {
			type: ChangeType.RELATIVE_VALUE,
			delta: updateAction.value as number,
			operator: updateAction.operator,
			relative_to_identifier: updateAction.relativeToIdentifier,
			date_unit: updateAction.unitsOfTime,
			round_to: updateAction.roundTo
		};
	}

	private getDynamicRelativeData(updateAction: UpdateEntityActionStore): UpdateEntityConfigurationData {
		return {
			type: ChangeType.RELATIVE_VALUE,
			name: `${updateAction.value}`,
			operator: updateAction.operator,
			relative_to_identifier: updateAction.relativeToIdentifier,
			data_type: PrimitiveManualTriggerParamDataType.NUMBER
		};
	}

	get mappedToServer(): EntityUpdateAction {
		const configurations: SingleUpdateEntityConfiguration[] = this.actions
			.map(updateAction => {
				const dynamicParam = this.triggerStore?.dynamicVariables.find(key => key.name === updateAction.value);

				if (dynamicParam && updateAction.changeType === ChangeType.RELATIVE_VALUE) {
					return {
						identifier: updateAction.identifier,
						data: this.getDynamicRelativeData(updateAction)
					};
				}

				if (dynamicParam) {
					return {
						identifier: updateAction.identifier,
						data: this.getDynamicSpecificData(updateAction, dynamicParam.data_type)
					};
				}

				if (updateAction.changeType === ChangeType.RELATIVE_VALUE) {
					return {
						identifier: updateAction.identifier,
						data: this.getStaticRelativeData(updateAction)
					};
				}

				return {
					identifier: updateAction.identifier,
					data: this.getStaticSpecificData(updateAction)
				};
			})
			.map(configuration => ({
				identifier: configuration.identifier,
				data: filterObject(configuration.data, value => value !== undefined)
			}));

		return {
			action_type: ActionType.ENTITY_UPDATE,
			data: {
				configurations
			}
		};
	}

	get isValid() {
		return this.actions.every(action => action.isValid);
	}

	private sortAttributes = (a, b) => {
		const aDisabled = a.disabled ? 1 : 0;
		const bDisabled = b.disabled ? 1 : 0;

		return (a.display_path?.toLowerCase() > b.display_path?.toLowerCase() ? 1 : -1) && aDisabled - bDisabled;
	};

	get availableAttributes() {
		return this.updateItemsByFact
			.filter(
				_action =>
					(!_action.supported_display_fact ||
						_action.supported_display_fact.includes(this.actionsRepo.displayFact)) &&
					(!_action.values || _action.values.length > 0)
			)
			.map(_action => {
				const isDisabled = !!this.actions.find(
					_updateAction => _updateAction.identifier === _action.identifier
				);

				return {
					..._action,
					disabled: isDisabled
				};
			})
			.sort(this.sortAttributes);
	}

	get canComplete() {
		const allActionsMetadata = this.updateItemsByFact;

		const invalidActions = allActionsMetadata
			.map(metadata => (metadata.values && !metadata.values.length ? metadata.identifier : undefined))
			.filter(Boolean);

		if (!invalidActions.length) {
			return true;
		}

		return this.isCurrentActionsAreOneOfTheInvalid(invalidActions);
	}

	isCurrentActionsAreOneOfTheInvalid(invalidActionsIds: UpdateFieldMetadata['identifier'][]): boolean {
		const actionIds = new Set(this.actions.map(({ identifier }) => identifier));
		return invalidActionsIds.every(id => !actionIds.has(id));
	}
}

export default UpdateEntityActionRepo;
