import React, { useEffect, useState } from 'react';

import { getRootEnv } from '@bringg-frontend/bringg-web-infra';
import { useTranslation } from 'react-i18next';
import { Button, Tabs } from '@bringg/react-components';
import { useHistory, useLocation } from 'react-router-dom';
import { Portal } from '@bringg-frontend/bringg-web-infra';
import { EmptyState } from '@bringg-frontend/ui';

import DeliveryBlocksSchedules, { Schedule } from './schedules/delivery-blocks-schedules';
import { useStores } from 'bringg-web/recipes';
import TemplateFilterBar from 'bringg-web/features/delivery-blocks-v2/settings/template-filter-bar/template-filter-bar';

import './delivery-blocks-settings.scss';

export enum DeliveryBlocksSettingsTab {
	SCHEDULES = 'DELIVERY_BLOCK_SCHEDULES.TITLE',
	ACTIVE_SCHEDULE = 'DELIVERY_BLOCK_ACTIVE_SCHEDULE.TITLE'
}

export const DELIVERY_BLOCKS_SETTINGS = 'delivery blocks settings';
export const EMPTY_SCHEDULE_ID = -1;

const DeliveryBlocksSettings = () => {
	const [activeTab, setActiveTab] = useState<DeliveryBlocksSettingsTab>(DeliveryBlocksSettingsTab.SCHEDULES);
	const [deliveryBlocksSchedules, setDeliveryBlocksSchedules] = useState<Schedule[]>([]);
	const [deliveryBlocksSchedulesVisibility, setDeliveryBlocksSchedulesVisibility] = useState<boolean>(false);
	const { t } = useTranslation();
	const { teamsStore } = useStores();

	const history = useHistory();
	const location = useLocation();

	useEffect(() => {
		const fetchTemplates = async () => {
			const result = await getRootEnv().dashboardSdk.sdk.deliveryBlocks.getTemplates();
			const schedules = result.flatMap(({ id, name, template_schedules }) => {
				if (template_schedules.length === 0) {
					template_schedules.push({
						id: EMPTY_SCHEDULE_ID
					});
				}
				return template_schedules.map(schedule => ({ ...schedule, template_id: id, template_name: name }));
			});
			setDeliveryBlocksSchedules(() => {
				setDeliveryBlocksSchedulesVisibility(true);
				return schedules;
			});
		};

		const fetchAllTeams = async () => {
			await teamsStore.fetchAll();
		};

		const fetch = async () => {
			await Promise.all([fetchTemplates(), fetchAllTeams()]);
		};

		fetch();
	}, []);

	const navigateToCreateTemplate = () => {
		history.push({ pathname: location.pathname.concat('new'), state: { from: DELIVERY_BLOCKS_SETTINGS } });
	};

	const navigateToEditTemplate = (templateId: number) => {
		history.push({ pathname: location.pathname.concat(templateId), state: { from: DELIVERY_BLOCKS_SETTINGS } });
	};

	const schedules = () => {
		if (!deliveryBlocksSchedulesVisibility) {
			return;
		}

		return (
			<div className="delivery-blocks-schedules-tab-container">
				<Button
					className="delivery-blocks-schedules-create-schedule"
					onClick={() => navigateToCreateTemplate()}
				>
					{t('DELIVERY_BLOCKS_SCHEDULES.CREATE_SCHEDULE')}
				</Button>
				<TemplateFilterBar
					className="delivery-blocks-schedules-filter-bar"
					deliveryBlockSchedules={deliveryBlocksSchedules}
					onDeliveryBlockSchedulesChange={setDeliveryBlocksSchedules}
				/>
				<span className="delivery-blocks-schedules-count-title">
					{t('DELIVERY_BLOCKS_SCHEDULES.COUNT_TITLE', { count: deliveryBlocksSchedules.length })}
				</span>
				{deliveryBlocksSchedules.length > 0 ? (
					<DeliveryBlocksSchedules
						deliveryBlockSchedules={deliveryBlocksSchedules}
						navigateToEditTemplate={navigateToEditTemplate}
						onDeliveryBlockSchedulesChanged={setDeliveryBlocksSchedules}
					/>
				) : (
					<EmptyState
						title={'DELIVERY_BLOCKS_SETTINGS.EMPTY_STATE_TITLE'}
						description={'DELIVERY_BLOCKS_SETTINGS.EMPTY_STATE_TEXT'}
					/>
				)}
			</div>
		);
	};

	return (
		<div className="delivery-blocks-settings-container" data-test-id="delivery-blocks-settings-container">
			<span className="delivery-blocks-settings-title">{t('DELIVERY_BLOCKS_SETTINGS.TITLE')}</span>
			<div className="delivery-blocks-settings-tabs-container">
				<Tabs
					activeKey={activeTab}
					onChange={key => setActiveTab(key as DeliveryBlocksSettingsTab)}
					className="delivery-blocks-settings-tabs"
					destroyInactiveTabPane
					items={[
						{
							label: t(DeliveryBlocksSettingsTab.SCHEDULES),
							key: DeliveryBlocksSettingsTab.SCHEDULES,
							className: 'delivery-blocks-settings-tab',
							children: schedules()
						}
					]}
				/>
			</div>
		</div>
	);
};
const DeliveryBlocksSettingsPortal = () => (
	<Portal element={<DeliveryBlocksSettings />} nodeId="delivery-blocks-settings-portal" />
);

export default DeliveryBlocksSettingsPortal;
