import React, { MutableRefObject, useMemo, useRef } from 'react';

import ReactDOM from 'react-dom';
import ReactDOMServer from 'react-dom/server';
import type { TimelineItem, TimelineOptions } from 'vis-timeline/types/entry-standalone';
import classNames from 'classnames';

import { GroupRowCells } from '../components/group-row-cells/group-row-cells';
import { getVisTimelineBaseOptions, setGanttOptions } from '../vis-timeline-base-options';
import {
	getIntervalTimeByMS,
	isUserChangedItemDuration,
	isUserChangedItemGroup,
	isUserChangedItemPositionInTime,
	notifyItemMoveSuccess
} from '../gantt-timeline-logic';
import type {
	ExtendedTimelineItem,
	TimelineClusterItem,
	VisTimelineGroupExtended,
	GanttOptions,
	GanttTimeLineItem,
	TimelineItemValuesHistory,
	GroupRow
} from '../gantt-types';
import type { GroupHeader } from '../components/group-headers/group-headers';
import { LATE_ITEM, TIMELINE_ITEM_PREFIX } from '../components/timeline-items/consts';
import Item from '../components/timeline-items/timeline-item';

const BUFFER_TO_SCROLL = 0.05;
type Direction = 'right' | 'left' | 'up' | 'down';

interface Props {
	apiRef: MutableRefObject<any>;
	options?: GanttOptions;
	timelineHeaders: GroupHeader[];
	preventUnnecessaryClick: MutableRefObject<boolean>;
	setVisRenderCompleted: (boolean) => void;
	onItemMoveEnded?: (item: GanttTimeLineItem, valuesHistory: TimelineItemValuesHistory) => Promise<boolean>;
	adjustItemBeforeMoveEnded?: (
		item: GanttTimeLineItem,
		valuesHistory: TimelineItemValuesHistory
	) => GanttTimeLineItem;
	removeTooltip: () => void;
	rows?: GroupRow[];
	inProgressList: Map<number, string>;
	getClusterContent?: (item: ExtendedTimelineItem, cluster: TimelineClusterItem) => string;
	getClusterClassName?: (cluster: TimelineClusterItem) => string;
	isReadOnly: boolean;
}

export const useGanttTimelineOptions = ({
	apiRef,
	options,
	timelineHeaders,
	preventUnnecessaryClick,
	setVisRenderCompleted,
	onItemMoveEnded,
	adjustItemBeforeMoveEnded,
	removeTooltip,
	rows,
	inProgressList,
	getClusterContent,
	getClusterClassName,
	isReadOnly
}: Props) => {
	const mouseRef = useRef<{ x: number; y: number; direction?: Direction; mouseClickTime?: Date }>({
		x: 0,
		y: 0
	});

	let isMoving = false;
	let firstTime = true;
	let timoutId;
	let draggedItemStartTime;
	let draggedItemEndTime;

	const onMouseOver = event => {
		const oldx = mouseRef.current.x;
		const oldy = mouseRef.current.y;

		if (event.pageX > oldx) {
			mouseRef.current.direction = 'right';
		} else if (event.pageY > oldy) {
			mouseRef.current.direction = 'down';
		} else if (event.pageY < oldy) {
			mouseRef.current.direction = 'up';
		} else if (event.pageX < oldx) {
			mouseRef.current.direction = 'left';
		}
		mouseRef.current.mouseClickTime = event.time;
		mouseRef.current.x = event.pageX;
		mouseRef.current.y = event.pageY;
	};

	function getValuesForVerticalScrolling(item: TimelineItem) {
		const heightOfMaxGroupInView = document.querySelector('.vis-center')?.clientHeight;

		const existingScrollElement = document.querySelector('.vis-content');
		const scrollTranslateY =
			existingScrollElement && new WebKitCSSMatrix(window.getComputedStyle(existingScrollElement).transform).m42;

		const rowHeight = document.querySelector('.vis-group-level-0')?.scrollHeight;
		const itemRowIndex = rows?.findIndex(row => row.rowId === item.group);
		const itemRowInPixel =
			rowHeight && itemRowIndex && itemRowIndex !== -1 ? rowHeight * (itemRowIndex + 1) : undefined;
		const nextGroupID = itemRowIndex && rows?.[itemRowIndex + 1]?.rowId;
		const previousGroupID = itemRowIndex && rows?.[itemRowIndex - 1]?.rowId;

		return { itemRowInPixel, heightOfMaxGroupInView, scrollTranslateY, rowHeight, nextGroupID, previousGroupID };
	}

	const scrollHorizontalByDirection = (item: TimelineItem, direction: Direction): void => {
		if (
			(direction === 'right' && mouseRef.current.direction === 'left') ||
			(direction === 'left' && mouseRef.current.direction === 'right')
		) {
			isMoving = false;
			return;
		}
		const endTimeline = apiRef.current.timeline.getWindow().end;
		const startTimeline = apiRef.current.timeline.getWindow().start;
		const baseMoveTime = startTimeline.getTime() + (endTimeline.getTime() - startTimeline.getTime()) / 2;
		const targetDateInMS =
			direction === 'right'
				? baseMoveTime + getIntervalTimeByMS(apiRef)
				: baseMoveTime - getIntervalTimeByMS(apiRef);

		apiRef.current.timeline.moveTo(
			targetDateInMS,
			{
				animation: {
					duration: 400,
					easingFunction: 'easeInOutQuad'
				}
			},
			() => {
				if (isMoving && item.end) {
					const itemDiff = new Date(item.end).getTime() - new Date(item.start).getTime();
					const { start: startTimelineAfterMove, end: endTimelineAfterMove } =
						apiRef.current.timeline.getWindow();

					const intervalTimeDivided = getIntervalTimeByMS(apiRef) / 2;

					if (direction === 'right') {
						item.start = new Date(endTimelineAfterMove.getTime() - itemDiff - intervalTimeDivided);
						item.end = new Date(endTimelineAfterMove.getTime() - intervalTimeDivided);
					} else {
						item.start = new Date(startTimelineAfterMove.getTime() + intervalTimeDivided);
						item.end = new Date(startTimelineAfterMove.getTime() + itemDiff + intervalTimeDivided);
					}

					scrollHorizontalByDirection(item, direction);
				}
			}
		);
	};

	function scrollVerticalByDirection(item: TimelineItem, direction?: Direction, scrollTranslateY?: number) {
		if (
			(direction === 'up' && mouseRef.current.direction === 'down') ||
			(direction === 'down' && mouseRef.current.direction === 'up')
		) {
			isMoving = false;
			return;
		}

		if (isMoving) {
			clearTimeout(timoutId);
			timoutId = setTimeout(() => {
				const { rowHeight, nextGroupID, previousGroupID } = getValuesForVerticalScrolling(item);

				if (scrollTranslateY && rowHeight) {
					const calculateToScrollInPX =
						direction === 'up' ? scrollTranslateY + rowHeight : scrollTranslateY - rowHeight;

					// @ts-ignore
					const newTranslateY = apiRef.current.timeline._setScrollTop(calculateToScrollInPX);
					// @ts-ignore
					apiRef.current.timeline.emit('_change', { queue: true });

					if (direction === 'up' && previousGroupID) {
						item.group = previousGroupID;
					} else if (direction === 'down' && nextGroupID) {
						item.group = nextGroupID;
					}

					scrollVerticalByDirection(item, direction, newTranslateY);
				}
			}, 400);
		}
	}

	function handleItemMoving(item: TimelineItem, visCallback: (item: TimelineItem | null) => void) {
		if (firstTime) {
			apiRef.current.timeline.on('mouseMove', onMouseOver);
			firstTime = false;
		}
		const moveItemEndTimeOnly = draggedItemStartTime.getTime() === new Date(item.start).getTime();
		const moveItemStartTimeOnly = item.end && draggedItemEndTime.getTime() === new Date(item.end).getTime();

		const { start: startTimeline, end: endTimeline } = apiRef.current.timeline.getWindow();
		const {
			itemRowInPixel = 0,
			heightOfMaxGroupInView = 0,
			scrollTranslateY = 0,
			rowHeight = 0
		} = getValuesForVerticalScrolling(item);

		const isResizeItem = [moveItemEndTimeOnly, moveItemStartTimeOnly].filter(Boolean).length === 1;

		if (
			(item.end &&
				mouseRef.current.direction === 'right' &&
				(item.end as number) > endTimeline.getTime() - getIntervalTimeByMS(apiRef) * BUFFER_TO_SCROLL) ||
			(mouseRef.current?.mouseClickTime?.getTime() as number) >
				endTimeline.getTime() - getIntervalTimeByMS(apiRef) * BUFFER_TO_SCROLL
		) {
			isMoving = true;
			scrollHorizontalByDirection(item, 'right');
		} else if (
			(mouseRef.current.direction === 'left' &&
				item.start < startTimeline.getTime() + getIntervalTimeByMS(apiRef) * BUFFER_TO_SCROLL) ||
			(mouseRef.current?.mouseClickTime?.getTime() as number) <
				startTimeline.getTime() + getIntervalTimeByMS(apiRef) * BUFFER_TO_SCROLL
		) {
			isMoving = true;
			scrollHorizontalByDirection(item, 'left');
		} else if (
			mouseRef.current.direction === 'down' &&
			itemRowInPixel >= heightOfMaxGroupInView + Math.abs(scrollTranslateY as number)
		) {
			isMoving = true;
			scrollVerticalByDirection(item, 'down', scrollTranslateY as number);
		} else if (
			mouseRef.current.direction === 'up' &&
			itemRowInPixel - rowHeight < Math.abs(scrollTranslateY as number)
		) {
			isMoving = true;
			scrollVerticalByDirection(item, 'up', scrollTranslateY as number);
		}
		if (mouseRef.current?.mouseClickTime) {
			const itemDuration = (item.end && new Date(item.end).getTime() - new Date(item.start).getTime()) || 0;
			const itemStart = moveItemStartTimeOnly
				? item.start
				: new Date(mouseRef.current.mouseClickTime.getTime() - itemDuration);

			const startTimeline = apiRef.current.timeline.getWindow().start;

			const mouseClickTime = mouseRef.current.mouseClickTime.getTime();
			const endOfItemFromMouseClickDistance = (item.end && new Date(item.end).getTime() - mouseClickTime) || 0;

			const isMouseOnElement =
				item.end && mouseClickTime > (item.start as number) && mouseClickTime < (item.end as number);

			const itemStartAfterMove = moveItemStartTimeOnly
				? item.start
				: new Date(mouseRef.current.mouseClickTime.getTime() - itemDuration + endOfItemFromMouseClickDistance);

			if (isMouseOnElement && itemStartAfterMove > startTimeline && itemStart > startTimeline) {
				item.end =
					item.end && moveItemStartTimeOnly
						? new Date(item.end)
						: new Date(mouseRef.current.mouseClickTime.getTime() + endOfItemFromMouseClickDistance);
				item.start = moveItemEndTimeOnly ? item.start : new Date(item.end.getTime() - itemDuration);
			} else if (itemStartAfterMove < startTimeline || itemStart < startTimeline) {
				item.end = moveItemStartTimeOnly ? item.end : startTimeline.getTime() + itemDuration;
				item.start = moveItemEndTimeOnly ? item.start : startTimeline;
			} else {
				if (!isMouseOnElement) {
					if (isResizeItem) {
						item.end = moveItemStartTimeOnly ? item.end : mouseRef.current.mouseClickTime;
						item.start = moveItemEndTimeOnly ? item.start : mouseRef.current.mouseClickTime;
					} else if (
						mouseRef.current.direction === 'right' &&
						!moveItemStartTimeOnly &&
						mouseRef.current.mouseClickTime >= startTimeline
					) {
						item.start = itemStartAfterMove;
						item.end = moveItemStartTimeOnly ? item.end : new Date(item.start).getTime() + itemDuration;
					} else if (mouseRef.current.direction === 'left' && !moveItemEndTimeOnly) {
						item.end = mouseRef.current.mouseClickTime;
						item.start = moveItemEndTimeOnly ? item.start : new Date(item.end).getTime() - itemDuration;
					}
				}
			}
		}
		visCallback(item);
	}

	const extendTimelineOptions = useMemo((): TimelineOptions => {
		const baseOptions = getVisTimelineBaseOptions(options?.timezone);

		const optionsClone: TimelineOptions = {
			...baseOptions,
			// @ts-ignore
			groupTemplate: (group: VisTimelineGroupExtended): string | HTMLElement | null => {
				if (!group) {
					return null;
				}
				const component = (
					<GroupRowCells
						rowId={Number(group.id)}
						rowCells={group.dataCells}
						columnsIds={timelineHeaders.map(header => header.id)}
						defaultColumnWidth={options?.defaultColumnWidth || 0}
						inProgressGroups={inProgressList}
					/>
				);

				const container = document.createElement('div');
				ReactDOM.render(component, container);
				return container;
			},
			template: (item: ExtendedTimelineItem, element: HTMLElement, clusterItem: TimelineClusterItem): string => {
				let timelineItem = item;

				if (clusterItem?.isCluster) {
					const firstInCluster = getFirstItemInCluster(clusterItem.items);
					if (firstInCluster.customData) {
						firstInCluster.customData.countItems = clusterItem.items.length;
					}
					clusterItem.style = firstInCluster?.style;
					clusterItem.className = getClusterClassNames(clusterItem, getClusterClassName);
					timelineItem = { ...firstInCluster };
					timelineItem.content = getClusterContent?.(timelineItem, clusterItem) || '+';
				}

				return ReactDOMServer.renderToStaticMarkup(
					<Item item={timelineItem} isCluster={clusterItem?.isCluster} />
				);
			},
			onInitialDrawComplete: () => {
				setVisRenderCompleted(true);
			},
			// @ts-ignore
			onMove: async (item: ExtendedTimelineItem, visCallback: (item: ExtendedTimelineItem | null) => void) => {
				isMoving = false;
				firstTime = true;
				draggedItemStartTime = undefined;
				draggedItemEndTime = undefined;
				apiRef.current.timeline.off('mouseMove', onMouseOver);

				preventUnnecessaryClick.current = true;

				const currentStart = (item.start as Date).toISOString();
				const currentEnd = (item.end as Date)?.toISOString();
				const userChangedDuration = isUserChangedItemDuration(item, currentStart, currentEnd);

				if (options?.hideAllItemsRanges) {
					options.hideAllItemsRanges();
				}

				if (isReadOnly) {
					visCallback(null);
					return;
				}

				//@ts-ignore item.group is type number and not string | number
				if (options.isOutOfGroupRange && options.isOutOfGroupRange(item.group, currentStart, currentEnd)) {
					options?.onOutOfGroupRange?.();
					visCallback(null);
					return;
				}

				if (!options?.supportItemDurationChange && userChangedDuration) {
					// user change the duration of the item. dont allow unless configured
					visCallback(null);
					return;
				}

				const isValid = options?.isNewItemPositionValid?.(item);

				if (isValid === false) {
					visCallback(null);
					return;
				}

				if (options?.onItemDroppedBeforeCurrentTime) {
					if ((item.start as number) < new Date().getTime()) {
						options?.onItemDroppedBeforeCurrentTime();

						visCallback(null);
						return;
					}
				}

				if (isUserChangedItemGroup(item) || isUserChangedItemPositionInTime(item) || userChangedDuration) {
					onItemMoveEnded &&
						(await notifyItemMoveSuccess(item, onItemMoveEnded, visCallback, adjustItemBeforeMoveEnded));
					return;
				}
			},
			onMoving: (item: TimelineItem, visCallback: (item: TimelineItem | null) => void) => {
				const disableMultiselectDragging = apiRef.current.timeline.getSelection().length > 1;
				if (isReadOnly || disableMultiselectDragging) {
					return;
				}

				if (options?.showItemRange) {
					options.showItemRange(item as ExtendedTimelineItem);
				}
				removeTooltip();

				if (!draggedItemStartTime || !draggedItemEndTime) {
					draggedItemStartTime = item.start;
					draggedItemEndTime = item.end;
				}

				handleItemMoving(item, visCallback);
			}
		};

		return setGanttOptions(optionsClone, options);
	}, [options, onItemMoveEnded, timelineHeaders, adjustItemBeforeMoveEnded, inProgressList]);

	return { extendTimelineOptions };
};

const getFirstItemInCluster = (items: ExtendedTimelineItem[]) => {
	return items.reduce((firstItem, item) => {
		if (!firstItem.content || firstItem.content > item.content) {
			// eslint-disable-next-line no-param-reassign
			firstItem = item;
		}

		return firstItem;
	}, {} as ExtendedTimelineItem);
};

const getClusterClassNames = (clusterItem: TimelineClusterItem, getClusterClassName) => {
	const late = clusterItem.items.some(item => item.customData?.late);
	const classById = clusterItem.items
		.map(item => {
			let className = '';
			className += TIMELINE_ITEM_PREFIX + item.id;
			return className;
		})
		.join(' ');

	const customClasses = getClusterClassName?.(clusterItem);

	return classNames('gantt-item', { 'bringg-icon-clock': late }, { [LATE_ITEM]: late }, classById, customClasses);
};
