import TableWrapper from '@bringg/react-components/dist/components/table';
import React from 'react';
import { TFunction } from 'i18next';
import { ExclusionWindow, PrivilegeTypes } from '@bringg/types';
import { BringgFontIcons, BringgIcon } from '@bringg/bringg-icons';
import { useTranslation } from 'react-i18next';
import { isNil as _isNil, get as _get } from 'lodash';
import { Button } from '@bringg/react-components';
import TimezoneService from '../../../services/timezone/timezone-service';
import { useHasAccess } from '../../../utils/privileges';

const getRepeatTranslation = (repeat: boolean, t: TFunction): string => (repeat ? t('TIMEOFF.ON') : t('TIMEOFF.OFF'));

export const formatDatesRange = (startTime: string, endTime: string, teamTz: string, repeat: boolean): string => {
	const momentTz = TimezoneService.getMomentTimezone(teamTz);
	const format = repeat ? 'MMM DD' : 'MMM DD, YYYY';
	const formattedStartTime = momentTz(startTime).format(format);
	const formattedEndTime = momentTz(endTime).format(format);

	if (formattedStartTime === formattedEndTime) {
		return formattedStartTime;
	}

	return `${formattedStartTime} - ${formattedEndTime}`;
};

export const sorter = (timeoff1: ExclusionWindow, timeoff2: ExclusionWindow, path: string): number => {
	const firstValue = _get(timeoff1, path);
	const secondValue = _get(timeoff2, path);

	if (!secondValue) {
		return 1;
	}

	if (!firstValue) {
		return -1;
	}

	return firstValue < secondValue ? -1 : 1;
};

const getColumns = (
	t: TFunction,
	teamTz: string,
	editCallback?: (timeoff: ExclusionWindow) => void,
	deleteCallback?: (timeoffId: number) => void,
	hasAccess?: boolean
) => {
	const columns = [
		{
			title: t('TIMEOFF.NAME'),
			dataIndex: 'name',
			sorter: (a, b) => sorter(a, b, 'name')
		},
		{
			title: t('TIMEOFF.TIME_RANGE'),
			render: (timeoff: ExclusionWindow) => (
				<span>{formatDatesRange(timeoff.start_time, timeoff.end_time, teamTz, timeoff.repeat)}</span>
			),
			defaultSortOrder: 'ascend' as any,
			sorter: (a, b) => sorter(a, b, 'start_time')
		},
		{
			title: t('TIMEOFF.REPEAT'),
			dataIndex: 'repeat',
			render: (repeat: boolean) => <span>{getRepeatTranslation(repeat, t)}</span>,
			sorter: (a, b) => sorter(a, b, 'repeat'),
			width: '180px'
		}
	];

	if (!_isNil(editCallback) && !_isNil(deleteCallback) && hasAccess) {
		columns.push({
			render: (timeoff: ExclusionWindow) => (
				<div className="actions-container">
					<Button type="link" onClick={() => editCallback(timeoff)} data-test-id="edit-timeoff">
						<BringgIcon iconName={BringgFontIcons.Pencil} />
					</Button>
					<Button
						type="link"
						className="trash-icon"
						onClick={() => deleteCallback(timeoff.id)}
						data-test-id="remove-timeoff"
					>
						<BringgIcon iconName={BringgFontIcons.Trash} />
					</Button>
				</div>
			),
			width: '120px'
		} as any);
	}

	return columns;
};

interface Props {
	data: ExclusionWindow[];
	isLoading: boolean;
	onEdit: (timeoff: ExclusionWindow) => void;
	onDelete: (timeoffId: number) => void;
	timezone: string;
}

const TimeoffTable: React.FC<Props> = ({ data, onEdit, onDelete, isLoading, timezone }: Props) => {
	const { t } = useTranslation();
	const hasAccess = useHasAccess(PrivilegeTypes.EDIT_EXCLUSION_WINDOWS);

	return (
		<TableWrapper
			className="override-antd-table"
			dataSource={data}
			columns={getColumns(t, timezone, onEdit, onDelete, hasAccess)}
			rowKey="id"
			loading={isLoading}
		/>
	);
};

export default TimeoffTable;
