import React from 'react';
import { inject, observer } from 'mobx-react';
import { BringgFontIcons, BringgIcon } from '@bringg/bringg-icons';
import { ChatConversationType } from '@bringg/types';
import ChatStore from '../stores/chat-store';
import { Avatar, Link } from '../../../components';

interface Props {
	chatStore?: ChatStore;
}

const ChatWindowHeader = ({ chatStore }: Props) => {
	const chat = chatStore.getCurrentChat;
	const isChatTypeTask = chat.conversation_type === ChatConversationType.task || chat.conversation_type === 'task';

	return chat ? (
		<div className="chat-window-header">
			<span className="chat-window-header-image">
				{isChatTypeTask ? (
					<div className="task-icon">
						<BringgIcon iconName={BringgFontIcons.Location} />
					</div>
				) : (
					<Avatar key={chat.id} className="chat-item-image" src={chat.getImageUrl} />
				)}
			</span>
			<span className="chat-window-header-title">
				<Link to={isChatTypeTask ? `/history/${chat.task_id}` : `/drivers/${chat.driver_id}`}>
					{chat.getTitle}
				</Link>
			</span>
		</div>
	) : null;
};

export default inject('chatStore')(observer(ChatWindowHeader));
