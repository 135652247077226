import { RootStore } from '@bringg-frontend/bringg-web-legacy';
import { CronExpression, DayOfTheWeekRange } from 'cron-parser';
import i18next from 'i18next';
import { getRoot } from 'mobx-easy';
import moment from 'moment/moment';

import { and, day } from '../../utils/use-automation-translations';

export const DAYS_IN_WEEK = 7;

export const getRecurringOptions = (): { key: number; value: string }[] => {
	const { firstDayOfWeek } = getRoot<RootStore>().data.merchantConfigurationsStore;
	const options = moment.weekdays().map((dayString, index) => ({
		key: index,
		value: dayString
	}));

	if (firstDayOfWeek === 0) {
		return options;
	}

	options.push(options.shift());
	return options;
};

export const getDayDisplayValue = (cron: CronExpression): string => {
	const isEveryDaySelected = cron.fields.dayOfWeek.length >= DAYS_IN_WEEK;
	const recurringOptions = getRecurringOptions();

	if (isEveryDaySelected) {
		return i18next.t(day);
	}

	return recurringOptions
		.filter(day => cron.fields.dayOfWeek.includes(day.key as DayOfTheWeekRange))
		.map(({ value }) => value)
		.join(', ')
		.replace(/, ([^,]*)$/, ` ${i18next.t(and)} $1`);
};
