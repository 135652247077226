export default [
	{
		featureType: 'all',
		elementType: 'labels.text',
		stylers: [
			{
				color: '#878787'
			}
		]
	},
	{
		featureType: 'all',
		elementType: 'labels.text.stroke',
		stylers: [
			{
				visibility: 'off'
			}
		]
	},
	{
		featureType: 'administrative.country',
		elementType: 'geometry.stroke',
		stylers: [
			{
				color: '#606f81'
			}
		]
	},
	{
		featureType: 'administrative.country',
		elementType: 'labels.text',
		stylers: [
			{
				color: '#3a5563'
			}
		]
	},
	{
		featureType: 'administrative.province',
		elementType: 'labels.text.fill',
		stylers: [
			{
				color: '#3a5563'
			}
		]
	},
	{
		featureType: 'administrative.locality',
		elementType: 'labels.text',
		stylers: [
			{
				color: '#3a5563'
			}
		]
	},
	{
		featureType: 'administrative.neighborhood',
		elementType: 'labels.text',
		stylers: [
			{
				color: '#607682'
			},
			{
				visibility: 'on'
			}
		]
	},
	{
		featureType: 'administrative.neighborhood',
		elementType: 'labels.text.stroke',
		stylers: [
			{
				visibility: 'off'
			}
		]
	},
	{
		featureType: 'landscape.man_made',
		elementType: 'geometry.fill',
		stylers: [
			{
				gamma: '2'
			},
			{
				saturation: '-100'
			},
			{
				lightness: '53'
			},
			{
				hue: '#009aff'
			}
		]
	},
	{
		featureType: 'landscape.man_made',
		elementType: 'geometry.stroke',
		stylers: [
			{
				color: '#4a6572'
			},
			{
				gamma: '2.00'
			}
		]
	},
	{
		featureType: 'landscape.natural.landcover',
		elementType: 'geometry.fill',
		stylers: [
			{
				hue: '#00c3ff'
			},
			{
				saturation: '-52'
			},
			{
				lightness: '69'
			},
			{
				visibility: 'on'
			}
		]
	},
	{
		featureType: 'poi',
		elementType: 'all',
		stylers: [
			{
				visibility: 'simplified'
			}
		]
	},
	{
		featureType: 'poi.attraction',
		elementType: 'all',
		stylers: [
			{
				visibility: 'off'
			}
		]
	},
	{
		featureType: 'poi.business',
		elementType: 'all',
		stylers: [
			{
				visibility: 'off'
			}
		]
	},
	{
		featureType: 'poi.business',
		elementType: 'geometry.fill',
		stylers: [
			{
				visibility: 'on'
			}
		]
	},
	{
		featureType: 'poi.government',
		elementType: 'all',
		stylers: [
			{
				visibility: 'off'
			}
		]
	},
	{
		featureType: 'poi.medical',
		elementType: 'labels.text.fill',
		stylers: [
			{
				color: '#a15e5e'
			}
		]
	},
	{
		featureType: 'poi.park',
		elementType: 'geometry.fill',
		stylers: [
			{
				color: '#bcf1c1'
			}
		]
	},
	{
		featureType: 'poi.park',
		elementType: 'labels.text.fill',
		stylers: [
			{
				visibility: 'off'
			},
			{
				color: '#42a93c'
			}
		]
	},
	{
		featureType: 'poi.park',
		elementType: 'labels.text.stroke',
		stylers: [
			{
				weight: '0.01'
			}
		]
	},
	{
		featureType: 'poi.place_of_worship',
		elementType: 'all',
		stylers: [
			{
				visibility: 'off'
			}
		]
	},
	{
		featureType: 'poi.sports_complex',
		elementType: 'all',
		stylers: [
			{
				visibility: 'off'
			}
		]
	},
	{
		featureType: 'road.highway',
		elementType: 'geometry.stroke',
		stylers: [
			{
				visibility: 'off'
			}
		]
	},
	{
		featureType: 'road.highway',
		elementType: 'labels.text.fill',
		stylers: [
			{
				color: '#6b90a3'
			}
		]
	},
	{
		featureType: 'road.arterial',
		elementType: 'geometry.fill',
		stylers: [
			{
				gamma: '7.48'
			},
			{
				color: '#ebf3f7'
			}
		]
	},
	{
		featureType: 'road.arterial',
		elementType: 'geometry.stroke',
		stylers: [
			{
				color: '#c2d6e0'
			},
			{
				visibility: 'on'
			}
		]
	},
	{
		featureType: 'road.arterial',
		elementType: 'labels.text.fill',
		stylers: [
			{
				color: '#6b90a3'
			}
		]
	},
	{
		featureType: 'road.local',
		elementType: 'geometry.fill',
		stylers: [
			{
				color: '#ebf3f7'
			}
		]
	},
	{
		featureType: 'road.local',
		elementType: 'geometry.stroke',
		stylers: [
			{
				visibility: 'on'
			},
			{
				color: '#dfe6f4'
			}
		]
	},
	{
		featureType: 'road.local',
		elementType: 'labels.text.fill',
		stylers: [
			{
				color: '#6b90a3'
			}
		]
	},
	{
		featureType: 'transit.station',
		elementType: 'all',
		stylers: [
			{
				visibility: 'off'
			}
		]
	},
	{
		featureType: 'transit.station.bus',
		elementType: 'all',
		stylers: [
			{
				visibility: 'off'
			}
		]
	},
	{
		featureType: 'transit.station.bus',
		elementType: 'geometry',
		stylers: [
			{
				visibility: 'on'
			}
		]
	},
	{
		featureType: 'water',
		elementType: 'all',
		stylers: [
			{
				color: '#aee0f4'
			}
		]
	},
	{
		featureType: 'water',
		elementType: 'labels',
		stylers: [
			{
				color: '#3091ce'
			},
			{
				visibility: 'on'
			}
		]
	},
	{
		featureType: 'water',
		elementType: 'labels.text.fill',
		stylers: [
			{
				color: '#3091ce'
			}
		]
	}
] as google.maps.MapTypeStyle[];
