import React, { forwardRef, useContext, useMemo } from 'react';

import classNames from 'classnames';

import TableInstanceContext from '../../contexts/table-instance-context';
import { GroupedRowMemo, TableRowMemo } from './components';
import type { RenderRowProps } from '../../types';
import { TestIds } from 'bringg-web/consts';

interface Props extends RenderRowProps {
	className?: string;
}

const ListChildRowMemo = forwardRef<HTMLDivElement, Props>(({ index, style, className }: Props, ref) => {
	const {
		prepareRow,
		rows,
		totalColumnsWidth,
		state: { selectedRowIds }
	} = useContext(TableInstanceContext);

	const row = rows[index];
	prepareRow(row);

	const { style: rowStyle, ...restRow } = row.getRowProps({ style });
	const rowStyleMemo = useMemo(() => {
		return {
			...rowStyle,
			width: totalColumnsWidth
		};
		//  rowStyle removed from deps intentionally in our case this should not change ever
	}, [totalColumnsWidth, style]);

	if (row.isGrouped) {
		// This is fix for group isSomeSelected logic, if group has a depth > 1, meaning this group also belongs to a group
		// default isSomeSelected logic stops showing isSomeSelected state for parent group if any leafRow is selected
		// maybe this will be fixed in newer version of react-table package
		const isSomeSelected = !row.isSelected && row.leafRows.some(({ id }) => selectedRowIds[id]);
		// TODO: figure out if it is possible to use row instead of row attributes
		// first time I've tried it seemed like row is mutated, so we are not able to use row ref for diffing
		return (
			<div
				ref={ref}
				{...restRow}
				style={rowStyleMemo}
				className="table-group-row"
				data-test-id={TestIds.TABLE_GROUP_ROW_MEMO}
			>
				<GroupedRowMemo
					key={row.id}
					row={row}
					isExpanded={row.isExpanded}
					isSelected={row.isSelected}
					isSomeSelected={isSomeSelected}
					leafRowsCount={row.leafRows?.length}
				/>
			</div>
		);
	}

	// Memoization is getting messy, discover its pros / cons in this particular case
	return (
		<div
			ref={ref}
			{...restRow}
			style={rowStyleMemo}
			className={classNames('table-row', className)}
			data-testid={TestIds.TABLE_ROW_MEMO}
			data-test-id={TestIds.TABLE_ROW_MEMO}
			data-alert-id={row.id}
		>
			<TableRowMemo
				key={row.id}
				row={row}
				cells={row.cells}
				rowWidth={totalColumnsWidth}
				isSelected={row.isSelected}
				isGrouped={row.isGrouped}
				original={row.original}
			/>
		</div>
	);
});

export default ListChildRowMemo;
