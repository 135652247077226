import React, { useCallback, useEffect, useState } from 'react';
import { debounce, isNil, get } from 'lodash';
import { FleetEnvType, FleetStatus, PrivilegeTypes } from '@bringg/types';
import {
	Button,
	Col,
	BringgInput as Input,
	InputPassword,
	InputNumber,
	Radio,
	Row,
	Switch,
	ReadOnlyMessage,
	Notification,
	Divider,
	Form,
	FormItem,
	useForm
} from '@bringg/react-components';
import { useTranslation } from 'react-i18next';
import { useHasAccess } from 'bringg-web/utils/privileges';
import { BringgFontIcons, BringgIcon } from '@bringg/bringg-icons';
import { Connect, TEMPORARY_CONNECTION } from '../delivery-catalog-connect';
import DeliveryCatalogConnectStatus from './delivery-catalog-connect-status';
import DeliveryCatalogContactUsModal from '../../delivery-catalog/delivery-catalog-contact-us-modal';
import { useStores } from '../../../recipes';

export interface ConnectionFieldsValues {
	data: Record<string, unknown>;
	env_type: FleetEnvType;
	name: string;
}

const serializeToForm = (connection: Connect): ConnectionFieldsValues => {
	return {
		name: connection?.name,
		data: connection?.data,
		env_type: isNil(connection?.env_type) ? FleetEnvType.Staging : connection.env_type
	};
};

const serializeFromForm = (values: ConnectionFieldsValues): ConnectionFieldsValues => {
	return {
		...values
	};
};

interface DeliveryCatalogConnectContentProps {
	connection: Connect;
	onCreate: (values: ConnectionFieldsValues) => Promise<void>;
	onUpdate: (id: number | string, values: ConnectionFieldsValues) => Promise<void>;
	onDelete: (id: number | string) => Promise<void>;
	onVerify: (id: number | string, data: ConnectionFieldsValues) => Promise<void>;
}

export enum Errors {
	VALIDATION_FAILED = 'VALIDATION_FAILED',
	FLEET_NAME_ON_MERCHANT_ALREADY_EXISTS = 'FLEET_NAME_ON_MERCHANT_ALREADY_EXISTS'
}

const DeliveryCatalogConnectContent = ({
	connection,
	onCreate,
	onUpdate,
	onVerify,
	onDelete
}: DeliveryCatalogConnectContentProps) => {
	const { deliveryCatalogStore } = useStores();
	const { t } = useTranslation();
	const [form] = useForm();

	const deliveryProvider = deliveryCatalogStore.deliveryProviders.get(connection?.delivery_provider_id);

	const [isSaving, setIsSaving] = useState(false);

	const isOneTouchEnabled = get(deliveryProvider, 'bringg_turnkey', false);
	const [isEmailModalActive, setIsEmailModalActive] = useState(false);

	const canSeeCredentials = useHasAccess(PrivilegeTypes.ENABLE_SETTING_PAGE);
	const deliveryProviderDescription = input => {
		const mailRegexp = /([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/;
		return (
			<>
				{input.split(new RegExp(mailRegexp, 'gi')).map(part => (
					<React.Fragment key={part}>
						{part.match(new RegExp(mailRegexp), 'i') ? <a href={`mailto:${part}`}>{part}</a> : part}
					</React.Fragment>
				))}
			</>
		);
	};

	useEffect(() => {
		form.resetFields();
		form.setFieldsValue(serializeToForm(connection));
	}, [connection?.id]);

	const handleSubmitOnSuccess = values => {
		const fields = [
			...Object.keys(values).map(key => ({
				name: [key] as string[],
				errors: []
			})),
			...Object.keys(values.data).map(key => ({
				name: ['data', key] as string[],
				errors: []
			}))
		];

		form.setFields(fields);
	};

	const handleSubmitOnError = error => {
		const { title, details } = error?.data ?? {};

		let fields = [];

		if (title === Errors.FLEET_NAME_ON_MERCHANT_ALREADY_EXISTS) {
			Notification.error({
				message: t('DELIVERY_PROVIDER_CATALOG_CONNECT.ERRORS.FLEET_NAME_ON_MERCHANT_ALREADY_EXISTS')
			});
		} else if (title === Errors.VALIDATION_FAILED) {
			fields = Object.entries(details).map(([key, value]) => ({
				name: ['data', key] as string[],
				errors: [t(`DELIVERY_PROVIDER_CATALOG_CONNECT.ERRORS.${value}`)]
			}));
		}

		if (fields.length > 0) {
			form.setFields(fields);
		} else {
			throw error;
		}
	};

	const handleChange = async () => {
		const values = form.getFieldsValue();

		try {
			setIsSaving(true);

			if (connection.id === TEMPORARY_CONNECTION) {
				await onCreate(serializeFromForm(values));
			} else {
				await onUpdate(connection.id, serializeFromForm(values));
			}

			handleSubmitOnSuccess(values);
		} catch (error) {
			handleSubmitOnError(error);
		} finally {
			setIsSaving(false);
		}
	};

	const handleSubmit = async () => {
		const values = form.getFieldsValue();

		try {
			setIsSaving(true);

			await onVerify(connection.id, serializeFromForm(values));
			await onUpdate(connection.id, serializeFromForm(values));

			handleSubmitOnSuccess(values);
		} catch (error) {
			handleSubmitOnError(error);
		} finally {
			setIsSaving(false);
		}
	};

	const onChange = useCallback(debounce(handleChange, 1000, { trailing: true }), [handleChange]);

	if (!connection) {
		return null;
	}

	const getField = ([key, config]): JSX.Element => {
		const isDisabled = (connection as Connect)?.status === FleetStatus.Active;
		const label = config.displayText;
		const name = ['data', key];
		const rules = [
			{
				required: config.required,
				message: `${config.displayText} is required`
			}
		];

		if (config.type === 'string') {
			return (
				<FormItem name={name} key={key} label={label} rules={rules}>
					{config.secured ? (
						<InputPassword
							autoComplete="new-password"
							placeholder={config.placeholder}
							disabled={isDisabled}
						/>
					) : (
						<Input placeholder={config.placeholder} disabled={isDisabled} />
					)}
				</FormItem>
			);
		}

		if (config.type === 'number') {
			return (
				<FormItem name={name} key={key} label={label} rules={rules}>
					<InputNumber type="number" placeholder={config.placeholder} disabled={isDisabled} />
				</FormItem>
			);
		}

		if (config.type === 'boolean') {
			return (
				<FormItem name={name} key={key} label={label} valuePropName="checked" shouldUpdate rules={rules}>
					<Switch onChange={handleChange} disabled={isDisabled} />
				</FormItem>
			);
		}

		return null;
	};

	const handleDelete = async () => {
		await onDelete(connection.id);
	};

	const handleSendEmail = async params => {
		await deliveryCatalogStore.sendEmail(params);
	};

	const toggleEmailModal = () => {
		setIsEmailModalActive(!isEmailModalActive);
	};

	return (
		<Form form={form} onChange={onChange} onSubmit={handleSubmit} className="delivery-catalog-connect-form">
			<div className="delivery-catalog-connect-body">
				<div className="delivery-catalog-connect-block">
					<Row>
						<Col span={9}>
							<div className="delivery-catalog-connect-block">
								<h3>Type of connection</h3>
								<FormItem name="env_type" className="delivery-catalog-connect-env">
									<Radio.Group
										disabled={!canSeeCredentials || connection.status === FleetStatus.Active}
									>
										<Radio value={FleetEnvType.Staging}>
											{t('DELIVERY_PROVIDER_CATALOG_CONNECT.FORM.STAGING')}
										</Radio>
										<Radio value={FleetEnvType.Production}>
											{t('DELIVERY_PROVIDER_CATALOG_CONNECT.FORM.PRODUCTION')}
										</Radio>
									</Radio.Group>
								</FormItem>
							</div>
						</Col>

						<Col span={1}>
							<div className="delivery-catalog-connect-block-vertical-divider" />
						</Col>

						<Col span={14}>
							<DeliveryCatalogConnectStatus status={connection?.status} />
						</Col>
					</Row>
				</div>

				<Divider />

				<div className="delivery-catalog-connect-block">
					<Row gutter={20}>
						<Col span={12}>
							<h3>{t('DELIVERY_PROVIDER_CATALOG_CONNECT.FORM.CREDENTIALS')}</h3>

							<p>
								{t('DELIVERY_PROVIDER_CATALOG_CONNECT.FORM.CREDENTIALS_LABEL_FIRST')}
								<br />
								{t('DELIVERY_PROVIDER_CATALOG_CONNECT.FORM.CREDENTIALS_LABEL_SECOND')}{' '}
								<Button type="link" onClick={toggleEmailModal}>
									{t('DELIVERY_PROVIDER_CATALOG_CONNECT.FORM.CREDENTIALS_LEARN_CONTACT_BRINGG')}
								</Button>
								{isOneTouchEnabled && (
									<>
										<br />
										{t('DELIVERY_PROVIDER_CATALOG_CONNECT.FORM.CREDENTIALS_LABEL_THIRD')}{' '}
										<Button type="link" onClick={toggleEmailModal}>
											{t(
												'DELIVERY_PROVIDER_CATALOG_CONNECT.FORM.CREDENTIALS_LEARN_CONTACT_CLICK_HERE'
											)}
										</Button>
									</>
								)}
							</p>

							{isEmailModalActive && (
								<DeliveryCatalogContactUsModal
									name={connection?.name}
									onSend={handleSendEmail}
									onClose={toggleEmailModal}
								/>
							)}

							{canSeeCredentials ? (
								<div className="delivery-catalog-connect-inputs">
									{Object.entries(
										connection?.delivery_provider?.setup_configuration?.fields ?? {}
									).map(getField)}
								</div>
							) : (
								<ReadOnlyMessage
									showMessage
									message={t(
										'DELIVERY_PROVIDER_CATALOG_CONNECT.CREDENTIALS_AVAILABLE_ONLY_FOR_ADMINS'
									)}
								/>
							)}
						</Col>

						<Col span={12}>
							{connection?.delivery_provider?.description && (
								<div className="delivery-catalog-connect-description">
									{deliveryProviderDescription(connection?.delivery_provider?.description)}
								</div>
							)}
						</Col>
					</Row>
				</div>
			</div>

			{canSeeCredentials && (
				<div className="delivery-catalog-connect-footer">
					<Row justify="space-between" align="middle">
						<Col>
							{isSaving && (
								<div className="delivery-catalog-connect-footer-loader">
									<BringgIcon iconName={BringgFontIcons.Redo} spin />
									<span>{t('DELIVERY_PROVIDER_CATALOG_CONNECT.FOOTER.SAVING')}</span>
								</div>
							)}
						</Col>
						<Col>
							<Row justify="end" gutter={[22, 0]}>
								<Col>
									<Button
										disabled={
											!Number.isFinite(connection.status) ||
											connection.status === FleetStatus.Active
										}
										onClick={handleDelete}
										type="text"
									>
										{connection.status === FleetStatus.Active
											? t('DELIVERY_PROVIDER_CATALOG_CONNECT.FOOTER.DISCARD_CHANGES')
											: t('DELIVERY_PROVIDER_CATALOG_CONNECT.FOOTER.DELETE')}
									</Button>
								</Col>
								<Col>
									<Button
										disabled={
											!Number.isFinite(connection.status) ||
											connection.status === FleetStatus.Active
										}
										type="primary"
										htmlType="submit"
									>
										{t('DELIVERY_PROVIDER_CATALOG_CONNECT.FOOTER.SUBMIT')}
									</Button>
								</Col>
							</Row>
						</Col>
					</Row>
				</div>
			)}
		</Form>
	);
};

export default DeliveryCatalogConnectContent;
