import React, { useEffect, useState } from 'react';

import TableWrapper from '@bringg/react-components/dist/components/table/table';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import _get from 'lodash/get';
import { ServiceArea } from '@bringg/types';
import _isEmpty from 'lodash/isEmpty';
import { observer } from 'mobx-react';
import { usePhoneNumberFormatter } from '@bringg-frontend/hooks';

import { useHasFeatureFlag } from 'bringg-web/utils/feature-flags';
import { USE_EXTERNAL_ID_FF } from 'bringg-web/consts';
import { Link } from '../../../components';
import { useStores } from '../../../recipes';
import ServiceAreasSelect from '../service-areas-select/service-areas-select';

type ExtendedUser = Bringg.User & { service_area: string };

type GetColumnsOptions = {
	t: TFunction;
	serviceAreas: ServiceArea[];
	replaceIdByExternalId: boolean;
};

const PhoneNumberCell = ({ text }: { text: string }) => (
	<span data-test-id="driverPhone">{usePhoneNumberFormatter()(text)}</span>
);

const getColumns = ({ t, serviceAreas, replaceIdByExternalId }: GetColumnsOptions) => {
	const idColumn = replaceIdByExternalId
		? {
				title: t('DRIVERS.EXTERNAL_ID'),
				dataIndex: 'external_id',
				render: (text: string, driver: Bringg.User) => (
					<Link to={`/drivers/${driver.id}`}>
						<span data-test-id="driverExternalId">{text}</span>
					</Link>
				),
				sorter: (a, b) => sorter(a, b, 'external_id'),
				defaultSortOrder: 'descend' as any
		  }
		: {
				title: t('DRIVERS.ID'),
				dataIndex: 'id',
				render: (text: string, driver: Bringg.User) => (
					<Link to={`/drivers/${driver.id}`}>
						<span data-test-id="driverId">{text}</span>
					</Link>
				),
				sorter: (a, b) => sorter(a, b, 'id'),
				defaultSortOrder: 'descend' as any
		  };

	const columns = [
		idColumn,
		{
			title: t('DRIVERS.NAME'),
			dataIndex: 'name',
			sorter: (a, b) => sorter(a, b, 'name'),
			render: text => {
				return <span data-test-id="driverName">{text}</span>;
			}
		},
		{
			title: t('DRIVERS.PHONE'),
			dataIndex: 'phone',
			sorter: (a, b) => sorter(a, b, 'phone'),
			render: text => <PhoneNumberCell text={text} />
		},
		{
			title: t('DRIVERS.SERVICE_AREAS'),
			dataIndex: 'area',
			width: 350,
			sorter: (a, b, sortOrder) => {
				if (sortOrder === 'descend') {
					if (a.service_area === undefined) {
						return -1;
					}
					if (b.service_area === undefined) {
						return 1;
					}
				}

				return a.service_area?.localeCompare(b.service_area);
			},
			render: (_, driver: Bringg.User) => {
				return <ServiceAreasSelect serviceAreas={serviceAreas} driver={driver} />;
			}
		},
		{
			title: t('DRIVERS.JOB_DESCRIPTION'),
			dataIndex: 'job_description',
			sorter: (a, b) => sorter(a, b, 'job_description'),
			render: text => {
				return <span data-test-id="driverJob">{text}</span>;
			}
		}
	];
	return columns;
};

export const sorter = (driver1: Bringg.User, driver2: Bringg.User, path: string): number => {
	const firstValue = _get(driver1, path);
	const secondValue = _get(driver2, path);

	if (!secondValue) {
		return 1;
	}

	if (!firstValue) {
		return -1;
	}

	return firstValue < secondValue ? -1 : 1;
};

interface Props {
	isLoading: boolean;
	onSelectedChange: any;
	selectedKeys: number[];
	teamId: number;
}

const DriversTable: React.FC<Props> = observer(({ isLoading, onSelectedChange, selectedKeys, teamId }) => {
	const { t } = useTranslation();
	const { driversStore, teamsStore } = useStores();
	const [serviceAreas, setServiceAreas] = useState<ServiceArea[]>([]);
	const drivers = driversStore.allByTeam(teamId);
	const [enrichedDrivers, setEnrichedDrivers] = useState([]);
	const replaceIdByExternalId = useHasFeatureFlag(USE_EXTERNAL_ID_FF);

	useEffect(() => {
		let mount = true;

		async function getAllServiceAreas() {
			if (_isEmpty(drivers)) return;
			const serviceAreas = await teamsStore.getServiceAreas(teamId, false);

			if (mount) {
				setServiceAreas(serviceAreas ?? []);
			}
		}

		getAllServiceAreas();

		return () => {
			mount = false;
		};
	}, [drivers]);

	useEffect(() => {
		if (_isEmpty(drivers)) return;
		const serviceAreaObj = serviceAreas.reduce((acc, curr) => ({ ...acc, [curr.id]: curr }), {});
		const driversWithServiceArea = drivers.map(driver => {
			if (driver.service_area_id) {
				(driver as unknown as ExtendedUser).service_area = serviceAreaObj[driver.service_area_id]?.name;
			}

			return driver;
		});

		setEnrichedDrivers(driversWithServiceArea);
	}, [serviceAreas]);

	return (
		<TableWrapper
			rowSelection={{ onChange: onSelectedChange, selectedRowKeys: selectedKeys }}
			className="override-antd-table"
			dataSource={enrichedDrivers}
			columns={getColumns({ t, serviceAreas, replaceIdByExternalId })}
			loading={isLoading}
			rowKey="id"
		/>
	);
});

export default DriversTable;
