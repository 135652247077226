import { ActionDataType } from '../../types/common.consts';

export type SelectEntity = {
	title: string;
	disabled?: boolean;
};

export const wayPointTypes: Map<number, SelectEntity> = new Map([
	[0, { title: 'None', disabled: true }],
	[1, { title: 'All' }],
	[2, { title: 'First' }],
	[3, { title: 'Last' }],
	[4, { title: 'Customer' }],
	[5, { title: 'Warehouse' }]
]);

export const taskTypes: Map<number, SelectEntity> = new Map([
	[1, { title: 'None', disabled: true }],
	[2, { title: 'Pickup' }],
	[3, { title: 'Return' }],
	[4, { title: 'Delivery' }],
	[5, { title: 'Pickup and Delivery' }]
]);

// Deprecated https://bringg.atlassian.net/browse/BRNGG-9333
export const displayModes: Map<number, string> = new Map([
	[0, 'Manual'],
	[1, 'After Check-in'],
	[2, 'Before Check-out']
]);

export const action_data_rules: Map<number, string> = new Map([
	[0, 'Allowed'],
	[1, 'After Check-in'],
	[2, 'Before Check-out (mandatory)'],
	// https://bringg.atlassian.net/browse/BRNGG-16195
	// [3, 'Default'],
	// [4, 'Un-allowed'],
	[5, 'Before Start Tasks']
]);

export const user_state_actions_rules: Map<number, string> = new Map([
	[0, 'Before Shift Start'],
	[1, 'Before Shift End']
]);

export enum ActionValue {
	AskQuestion = 'ask_question',
	Form = 'form',
	RejectInventory = 'reject_inventory',
	TakeNote = 'take_note',
	TakeSignature = 'take_signature',
	TakePicture = 'take_picture',
	TakeScan = 'take_scan',
	CancelTask = 'cancel_task',
	OpenIframe = 'open_iframe',
	VerifyPinCode = 'verification_pin_code'
}

export const actionValueToType: Map<string, ActionDataType> = new Map([
	[ActionValue.AskQuestion, ActionDataType.QUESTION],
	[ActionValue.Form, ActionDataType.ACTION_FORM],
	[ActionValue.RejectInventory, ActionDataType.REJECT_INVENTORY],
	[ActionValue.TakeNote, ActionDataType.TAKE_NOTE],
	[ActionValue.TakeSignature, ActionDataType.TAKE_SIGNATURE],
	[ActionValue.TakePicture, ActionDataType.TAKE_PICTURE],
	[ActionValue.TakeScan, ActionDataType.TAKE_SCAN],
	[ActionValue.CancelTask, ActionDataType.CANCEL_TASK],
	[ActionValue.OpenIframe, ActionDataType.OPEN_IFRAME],
	[ActionValue.VerifyPinCode, ActionDataType.VERIFICATION_PIN_CODE]
]);

export enum AllowShareWithCustomer {
	DoNotAllow,
	AllowUnchecked,
	AllowChecked
}

export const AllowShareWithCustomerOptions: Map<string, number> = new Map([
	['do_not_allow', AllowShareWithCustomer.DoNotAllow],
	['allow_unchecked', AllowShareWithCustomer.AllowUnchecked],
	['allow_checked', AllowShareWithCustomer.AllowChecked]
]);
