import { action, computed, observable, makeObservable } from 'mobx';

import OAuthApp from './domain-objects/oauth-app';

class OauthAppView {
	currentApp: OAuthApp = null;

	constructor() {
		makeObservable(this, {
			currentApp: observable.shallow,
			newApp: action,
			setCurrent: action,
			current: computed
		});
	}

	public newApp(): void {
		this.currentApp = new OAuthApp();
	}

	public setCurrent(app: OAuthApp): void {
		this.currentApp = app;
	}

	get current(): OAuthApp {
		return this.currentApp;
	}
}

export default OauthAppView;
