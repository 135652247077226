import React from 'react';

import './group-row-cells.scss';

export interface ExtendedGroupCell {
	content: string | JSX.Element;
	width?: number; // px
}

export type GroupCell = string | JSX.Element | ExtendedGroupCell;

export interface GroupDataProps {
	rowId: number;
	rowCells: GroupCell[];
	columnsIds: number[];
	defaultColumnWidth: number;
	inProgressGroups?: Map<number, string>;
}

export const GroupRowCells = (props: GroupDataProps) => {
	const { rowId, rowCells, defaultColumnWidth, columnsIds, inProgressGroups } = props;
	const loadingStatusClass = inProgressGroups?.has(rowId) ? 'loading-row' : '';

	return (
		<div
			data-test-id={`gantt-timeline-row-${rowId}-cells`}
			className={`group-row-cells-component row-container ${loadingStatusClass}`}
		>
			{rowCells.map((cell, i) => {
				const tooltip = typeof cell === 'string' ? cell : null;
				return (
					<div
						key={i}
						data-test-id={`cell-in-column-id-${columnsIds[i]}`}
						className="cell"
						style={{ width: (cell as ExtendedGroupCell).width || defaultColumnWidth }}
						// @ts-ignore
						title={tooltip}
					>
						{(cell as ExtendedGroupCell).content || cell}
					</div>
				);
			})}
		</div>
	);
};
