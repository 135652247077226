import React, { useCallback, useEffect, useRef } from 'react';

import withDragAndDrop, { withDragAndDropProps } from 'react-big-calendar/lib/addons/dragAndDrop';
import { Calendar, Event, stringOrDate } from 'react-big-calendar';
import moment from 'moment-timezone';
import { DeliveryWindow, PlannedDeliveryWindow } from '@bringg/dashboard-sdk';
import { ExclusionWindowsFilterGroups } from '@bringg/dashboard-sdk/dist/ExclusionWindow/v2/exclusion-window.consts';
import { Notification, Spinner } from '@bringg/react-components';
import classNames from 'classnames';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { dateUtils } from '@bringg-frontend/utils';

import PlannedDeliveryWindowsEvent from './components/event/planned-delivery-windows-event';
import { plannedDeliveryWindowsEventCreator } from 'bringg-web/features/planned-delivery-windows/services/planned-delivery-windows-event-creator';
import { customMomentLocalizerTimezone } from 'bringg-web/features/planned-delivery-windows/calendar/custom-moment-localizer-timezone';
import { useStores } from 'bringg-web/recipes';
import { NO_TEAM } from 'bringg-web/features/planned-delivery-windows/planned-delivery-windows-view';
import { exclusionWindowChecker } from 'bringg-web/features/planned-delivery-windows/services/exclusion-window-checker';
import { useHasFeatureFlag } from 'bringg-web/utils/feature-flags';
import { selectionService } from 'bringg-web/services/selection/selection-service';

import styles from './planned-delivery-windows-calendar.module.scss';

export enum CalendarViewMode {
	day = 'day',
	week = 'week'
}

interface Props {
	plannedDeliveryWindows: PlannedDeliveryWindow[];
	calendarLoading: boolean;
	openModalWithPlannedDeliveryWindow: (plannedDeliveryWindowId: number) => void;
	calendarRangeDates: { startDate: string; endDate: string };
	openRecurrenceTypeModalWithEditMode: (updatedPlannedDeliveryWindow: Partial<PlannedDeliveryWindow>) => void;
	openRecurrenceTypeModalWithDeleteMode: (id: number) => void;
	timezone: string;
	deliveryWindowsByPlannedDeliveryWindowId: Map<number, DeliveryWindow>;
	selectedTeamId: number;
	selectedEventsIds: Set<number>;
	setSelectedEventsIds: React.Dispatch<React.SetStateAction<Set<number>>>;
	clearSelectedEventsIds: () => void;
	calendarViewMode: CalendarViewMode;
}

export type PlannedDeliveryWindowsEventType = {
	id: number;
	title: string;
	start: Date;
	end: Date;
	cutoff: number;
	service_area_ids: number[];
	service_plan_ids: number[];
	team_id: number;
};

export type CalendarPlannedDeliveryWindowDroppedEvent = {
	event: Event & PlannedDeliveryWindowsEventType;
	start: stringOrDate;
	end: stringOrDate;
};

const PlannedDeliveryWindowsCalendar = ({
	plannedDeliveryWindows,
	calendarLoading,
	openModalWithPlannedDeliveryWindow,
	calendarRangeDates,
	openRecurrenceTypeModalWithEditMode,
	openRecurrenceTypeModalWithDeleteMode,
	timezone,
	deliveryWindowsByPlannedDeliveryWindowId,
	selectedTeamId,
	selectedEventsIds,
	setSelectedEventsIds,
	clearSelectedEventsIds,
	calendarViewMode
}: Props) => {
	const clickRef = useRef(null);
	const { t } = useTranslation();
	const DragAndDropCalendar = withDragAndDrop(Calendar);
	const localizer = customMomentLocalizerTimezone(moment, timezone);
	const { exclusionWindows } = useStores();
	const isPDWMultiSelectEnabled = useHasFeatureFlag('pdw_multi_select');

	useEffect(() => {
		return () => {
			window.clearTimeout(clickRef?.current);
		};
	}, []);

	const onEventResizeOrDrops = (droppedEvent: CalendarPlannedDeliveryWindowDroppedEvent) => {
		if (selectedEventsIds.size > 1) {
			return;
		}

		const startTime = moment(droppedEvent.start).tz(timezone);
		let endTime = moment(droppedEvent.end).tz(timezone);

		if (startTime.isSame(droppedEvent.event.start) && endTime.isSame(droppedEvent.event.end)) {
			return;
		}

		const nextStartOfDay = moment(droppedEvent.event.start).tz(timezone).startOf('day').add(1, 'day');
		if (endTime.isSame(nextStartOfDay)) {
			endTime = moment(droppedEvent.event.start).tz(timezone).endOf('day');
		}

		if (startTime.date() !== endTime.date()) {
			Notification.error(t('PLANNED_DELIVERY_WINDOWS_CALENDAR_NOTIFICATION.WINDOW_CANNOT_BE_ON_TWO_DAYS'));
			return;
		}

		openRecurrenceTypeModalWithEditMode({
			id: droppedEvent.event.id,
			start_time: dateUtils.diffMinutesFromLastMonday(startTime),
			end_time: dateUtils.diffMinutesFromLastMonday(endTime)
		});
	};

	const customComponents: withDragAndDropProps['components'] = {
		event: props => {
			// @ts-ignore
			const plannedDeliveryWindowId = props.event.id;
			const fillRatio = `${Math.ceil(
				(deliveryWindowsByPlannedDeliveryWindowId.get(plannedDeliveryWindowId)?.fill_ratio || 0) * 100
			)}%`;
			return (
				<PlannedDeliveryWindowsEvent
					// @ts-ignore
					event={props.event}
					title={props.title}
					openModalWithPlannedDeliveryWindow={openModalWithPlannedDeliveryWindow}
					openRecurrenceTypeModalWithDeleteMode={openRecurrenceTypeModalWithDeleteMode}
					selectedEventsIdsLength={selectedEventsIds.size}
					fillRatio={fillRatio}
					timezone={timezone}
				/>
			);
		},
		day: {
			header: day => {
				// @ts-ignore
				return <div>{day.label}</div>;
			}
		}
	};
	const exclusionWindowsByTeam = [
		...exclusionWindows.getGroup(ExclusionWindowsFilterGroups.Team, null),
		...(selectedTeamId !== NO_TEAM
			? exclusionWindows.getGroup(ExclusionWindowsFilterGroups.Team, selectedTeamId)
			: [])
	];

	const getSlotStyle = useCallback(
		(date, ...args) => {
			const isTimeColumn = args[0] === undefined;
			if (isTimeColumn) return;

			const isExclusionSlot = exclusionWindowsByTeam.some(exclusionWindow => {
				return moment(date)
					.tz(timezone)
					.isBetween(
						exclusionWindow.start_time,
						moment(exclusionWindow.end_time).set({ second: 0, ms: 0 }),
						null,
						'[)'
					);
			});

			if (isExclusionSlot) {
				const dataTestId = moment(date).tz(timezone).format('yyyy-MM-DD-HH:mm');
				return {
					className: styles.exclusionSlotBackground,
					'data-test-id': dataTestId
				};
			}
		},
		[selectedTeamId, exclusionWindowsByTeam]
	);

	const getEventStyle = useCallback(
		event => {
			const isEventExcluded = exclusionWindowChecker.isExcludingEvent(exclusionWindowsByTeam, event, timezone);
			const isSelected = selectedEventsIds.has(event.id);

			return {
				className: classNames(isEventExcluded ? styles.exclusionEvent : styles.event, {
					[styles.selectedEvent]: isSelected
				})
			};
		},
		[selectedEventsIds, plannedDeliveryWindows, exclusionWindowsByTeam]
	);

	const eventsToView = React.useMemo(
		() =>
			plannedDeliveryWindows.map(pdw => {
				return plannedDeliveryWindowsEventCreator.create(pdw, calendarRangeDates, timezone);
			}),
		[plannedDeliveryWindows, calendarRangeDates, timezone]
	);

	const onSelectSlot = useCallback(() => {
		// Why we need this setTimeout? read it : https://jquense.github.io/react-big-calendar/examples/index.html?path=/docs/props--on-select-slot
		window.clearTimeout(clickRef?.current);
		clickRef.current = window.setTimeout(() => {
			if (selectedEventsIds.size) {
				clearSelectedEventsIds();
			}
		}, 0);
		return false;
	}, [selectedEventsIds]);

	const onSelectEvent = useCallback(
		(pdw: PlannedDeliveryWindowsEventType, event) => {
			if (!selectionService.isMultiSelect(event)) {
				if (!selectedEventsIds.size) {
					openModalWithPlannedDeliveryWindow(pdw.id);
				}
				return;
			}

			const isSelected = selectedEventsIds.has(pdw.id);
			setSelectedEventsIds(prevState => {
				const prevSelectedEventsIds = new Set(prevState);
				if (isSelected) {
					prevSelectedEventsIds.delete(pdw.id);
				} else {
					prevSelectedEventsIds.add(pdw.id);
				}
				return prevSelectedEventsIds;
			});
		},
		[selectedEventsIds, openModalWithPlannedDeliveryWindow]
	);

	return (
		<div className={styles.calendar}>
			{calendarLoading && <Spinner className={styles.spinner} />}
			<div className={classNames({ [styles.disabledMode]: calendarLoading })}>
				<DragAndDropCalendar
					// @ts-ignore
					components={customComponents}
					events={eventsToView}
					localizer={localizer}
					defaultView="week"
					views={['week', 'day']}
					view={calendarViewMode}
					startAccessor="start"
					endAccessor="end"
					step={30}
					slotPropGetter={getSlotStyle}
					eventPropGetter={getEventStyle}
					date={calendarRangeDates.startDate}
					onNavigate={useCallback(() => null, [])}
					tooltipAccessor={useCallback(() => null, [])}
					onEventDrop={onEventResizeOrDrops}
					onSelecting={useCallback(() => false, [])}
					onSelectSlot={isPDWMultiSelectEnabled ? onSelectSlot : null}
					onSelectEvent={isPDWMultiSelectEnabled ? onSelectEvent : null}
					timeslots={2}
					onEventResize={onEventResizeOrDrops}
					toolbar={false}
					selectable={isPDWMultiSelectEnabled}
				/>
			</div>
		</div>
	);
};

export default observer(PlannedDeliveryWindowsCalendar);
