import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { each as _each, isNil as _isNil } from 'lodash';
import { FilterBar2Values } from '@bringg/react-components';
import {
	ExtendedFloatingInventory,
	FloatingInventoriesRequest,
	FloatingInventoryOwnerType,
	FloatingInventoryStatus
} from '@bringg/types';
import { useStores } from 'bringg-web/recipes';
import { openErrorNotification } from 'bringg-web/services/utils';
import { getFloatingInventories } from 'bringg-web/stores/floating-inventories/floating-inventories-store';
import {
	getPageFromRouteParams,
	getPageSizeFromRouteParams,
	getQueryString,
	FloatingInventoriesFilters,
	FloatingInventoriesFilterItems,
	getIdsFromQueryParams,
	mapTeamsToFilter,
	getValuesFromQueryParams
} from '../floating-inventories-filters';
import { OpenEventLogParams, FloatingInventoriesEventLogModal, FloatingInventoriesTable } from '..';
import styles from '../floating-inventories.module.scss';

const FloatingInventoriesContainer = () => {
	const history = useHistory();
	const location = useLocation();
	const { teamsStore, driversStore } = useStores();
	const [floatingInventories, setFloatingInventories] = useState<ExtendedFloatingInventory[]>([]);
	const [currentEventLog, setCurrentEventLog] = useState<OpenEventLogParams>(null);
	const [loading, setLoading] = useState(true);
	const [page] = useState(getPageFromRouteParams(location));
	const [pageSize] = useState(getPageSizeFromRouteParams(location));
	const [filterQuery, setFilterQuery] = useState({} as FloatingInventoriesRequest);
	const [filterItems, setFilterItems] = useState<FloatingInventoriesFilterItems>({});

	const openEventLogModal = (newEventLog: OpenEventLogParams) => {
		setCurrentEventLog(newEventLog);
	};

	const closeEventLogModal = () => setCurrentEventLog(null);

	const initByRouteParams = async () => {
		const params = new URLSearchParams(location.search);
		const query: FloatingInventoriesRequest = {
			page,
			items: pageSize,
			team_ids: getIdsFromQueryParams(params, 'team_ids'),
			status: getValuesFromQueryParams(params, 'status') as FloatingInventoryStatus[],
			owner_type: getValuesFromQueryParams(params, 'owner_type') as FloatingInventoryOwnerType[],
			sku: params.get('sku')
		};

		await getFilterItems();

		onQueryChange(query);
	};

	const getFilterItems = async () => {
		try {
			const teamsByIds = await teamsStore.fetchAll();

			setFilterItems({
				team_ids: mapTeamsToFilter(teamsByIds)
			});
		} catch (error) {
			console.error('error fetching data: error:', error, ',params: ', filterQuery);
			openErrorNotification(error);
		}
	};

	const onQueryChange = (query: FloatingInventoriesRequest) => {
		if (!query) return;

		setFilterQuery(query);
		pushToHistory(query.page, query.items, query);
		updateFloatingInventories(query);
	};

	const pushToHistory = (currPage?: number, size?: number, query?: FloatingInventoriesRequest) => {
		history.push({
			pathname: '/floating-inventories-monitor',
			search: `?page=${currPage || page}&limit=${size || pageSize}${getQueryString(query)}`
		});
	};

	const updateFloatingInventories = async (query: FloatingInventoriesRequest): Promise<void> => {
		setLoading(true);
		try {
			const floatingInventoriesResponse = await getFloatingInventories(query);
			setFloatingInventories(floatingInventoriesResponse.floating_inventories);
		} catch (error) {
			console.error('error fetching floating inventories: error:', error, ',params: ', filterQuery);
			openErrorNotification(error);
		} finally {
			setLoading(false);
		}
	};

	const getFilterResults = (filterQuery: FloatingInventoriesRequest): void => {
		const query: FloatingInventoriesRequest = { page, items: pageSize };

		_each(filterQuery, (val, key) => {
			if (!_isNil(val)) {
				query[key] = val;
			}
		});

		onQueryChange(query);
	};

	const resetFilter = (field?: string) => {
		if (!field) {
			onResetQuery();
			return;
		}

		onQueryChange({ ...filterQuery, [field]: null });
	};

	const onResetQuery = () => {
		setFilterQuery({} as FloatingInventoriesRequest);
		pushToHistory();
		updateFloatingInventories({ page, items: pageSize });
	};

	useEffect(() => {
		teamsStore.fetchAll();
		driversStore.fetchAll();
		initByRouteParams();
	}, []);

	return (
		<div className={styles['floating-inventories-container']} data-test-id={'floating-inventories-container'}>
			<FloatingInventoriesFilters
				getFilterResults={query => getFilterResults(query)}
				filterItems={filterItems}
				initialValues={filterQuery as FilterBar2Values}
				onResetFilter={resetFilter}
			/>

			<div
				className={styles['floating-inventories-table-container']}
				data-test-id={'floating-inventories-table-container'}
			>
				<FloatingInventoriesTable
					floatingInventories={floatingInventories}
					loading={loading}
					openEventLogModal={openEventLogModal}
				/>
			</div>
			{!!currentEventLog?.id && (
				<FloatingInventoriesEventLogModal
					floatingInventoryId={currentEventLog.id}
					floatingInventoryName={currentEventLog.name}
					onClose={closeEventLogModal}
				/>
			)}
		</div>
	);
};

export default FloatingInventoriesContainer;
