import React from 'react';

import { observer } from 'mobx-react';
import { AttributePath, Tooltip } from '@bringg/react-components';
import { BringgFontIcons, BringgIcon } from '@bringg/bringg-icons';

import WorkflowContentGenerator, { ContentArrayType } from '../../utils/content-mapper/workflow-content-generator';

import './workflow-content.scss';

interface WorkflowContentProps {
	content: WorkflowContentGenerator;
	isExpanded?: boolean;
	'data-ignore-open-workflow'?: string;
}

const mapContent = (value: ContentArrayType, id: string, hideTooltip = false) => {
	// do not assign value.title as a key - will cause a bug - https://bringg.atlassian.net/browse/BRNGG-11000
	const mappedContent = (
		<span className={value.isPale ? '' : 'bold'} key={id}>
			{value.isPath ? <AttributePath path={value.title} /> : value.title}&nbsp;
		</span>
	);
	if (value.tooltipContent) {
		const title = Array.isArray(value.tooltipContent)
			? value.tooltipContent.map(t => mapContent(t, id, true))
			: value.tooltipContent;

		if (hideTooltip) {
			return title;
		}

		return (
			<Tooltip placement="top" title={title} key={title}>
				{mappedContent}
			</Tooltip>
		);
	}
	return mappedContent;
};

const WorkflowContent = ({ content, isExpanded, ...rest }: WorkflowContentProps) => {
	return (
		<div {...rest} className="workflow-content" data-test-id="workflow-content" aria-expanded={isExpanded}>
			<span>
				{content.mappedSection('trigger', isExpanded).map((c, i) => mapContent(c, `${c.title}_${i}`))}
				{content.mappedSection('conditions', isExpanded).map((c, i) => mapContent(c, `${c.title}_${i}`))}
			</span>

			<div className="content-actions">
				<BringgIcon iconName={BringgFontIcons.BreakTo} className="content-arrow-icon" />
				<span className="action-content">
					{content.mappedSection('actions', isExpanded).map((c, i) => mapContent(c, `${c.title}_${i}`))}
				</span>
			</div>
		</div>
	);
};

export default observer(WorkflowContent);
