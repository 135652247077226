import { useMemo } from 'react';

import { useTranslation } from 'react-i18next';

export const useRichTextEditorTranslations = () => {
	const { t } = useTranslation();

	return useMemo(
		() => ({
			paragraph: t('RICH_TEXT_EDITOR.PARAGRAPH'),
			headingH1: t('RICH_TEXT_EDITOR.HEADING_1'),
			headingH2: t('RICH_TEXT_EDITOR.HEADING_2'),
			headingH3: t('RICH_TEXT_EDITOR.HEADING_3'),
			headingH4: t('RICH_TEXT_EDITOR.HEADING_4')
		}),
		[t]
	);
};
