import React from 'react';
import { Frequency, Options } from 'rrule';
import RecurringDaily from './daily/recurring-frequency-daily';
import RecurringMonthly from './monthly/recurring-frequency-monthly';
import RecurringWeekly from './weekly/recurring-frequency-weekly';
import { RecurringFrequenciesOptionsLabels } from '../recurring.labels';

interface Props {
	options: Options;
	onOptionsChange: (options: Partial<Options>) => void;
	disabled: boolean;
	frequenciesOptionsLabels: RecurringFrequenciesOptionsLabels;
}

const RecurringFrequencies = ({ options, onOptionsChange, disabled, frequenciesOptionsLabels }: Props) => {
	return (
		<div>
			{options.freq === Frequency.DAILY && (
				<RecurringDaily
					options={options}
					onOptionsChange={onOptionsChange}
					disabled={disabled}
					daysLabel={frequenciesOptionsLabels.daysLabel}
					everyLabel={frequenciesOptionsLabels.everyLabel}
				/>
			)}

			{options.freq === Frequency.WEEKLY && (
				<RecurringWeekly
					options={options}
					onOptionsChange={onOptionsChange}
					disabled={disabled}
					everyLabel={frequenciesOptionsLabels.everyLabel}
					weeksLabel={frequenciesOptionsLabels.weeksLabel}
					onLabel={frequenciesOptionsLabels.onLabel}
					weekDaysLabels={frequenciesOptionsLabels.weekDaysLabels}
				/>
			)}

			{options.freq === Frequency.MONTHLY && (
				<RecurringMonthly
					options={options}
					onOptionsChange={onOptionsChange}
					disabled={disabled}
					everyLabel={frequenciesOptionsLabels.everyLabel}
					monthsLabel={frequenciesOptionsLabels.monthsLabel}
					onLabel={frequenciesOptionsLabels.onLabel}
				/>
			)}
		</div>
	);
};

export default RecurringFrequencies;
