import _shuffle from 'lodash/shuffle';
import _random from 'lodash/random';

/**
 * Shuffle an array and guarantee that it's shuffled
 * lodash.shuffle method does not guarantee that the array will be shuffled as there is a tiny chance it will end up being in the same order
 */
export function shuffleGuaranteed<T>(arr: T[]): T[] {
	if (arr.length < 2) {
		throw new Error('passed array must have at least 2 items');
	}

	const clonedArr = [...arr];

	// Picking random index that is not the last index
	const randomIndexToOmit = _random(0, clonedArr.length - 2);

	const [omittedItem] = clonedArr.splice(randomIndexToOmit, 1);

	const shuffledArray = _shuffle(clonedArr);

	// Adding the item to the end as it was never there
	shuffledArray.push(omittedItem);

	return shuffledArray;
}

/*
Rotate forward by 2 rotates => [1,2,3,4,5] => [4,5,1,2,3]
Rotate backwards by 2 rotates => [1,2,3,4,5] => [3,4,5,1,2]
*/

export const rotateArray = (array: any, numberOfRotates: number, forward: boolean) => {
	for (let i = 0; i < numberOfRotates; i += 1) {
		if (forward) {
			array.unshift(array.pop());
		} else {
			array.push(array.shift());
		}
	}
	return array;
};

export type GroupByFn<T> = (obj: T, index: number, array: T[]) => string;

/**
 *
 * @param array - The array groupBy() was called upon
 * @param groupByFn -A function to execute for each element in the array. It should return a value that can get coerced into a property key (string or symbol) indicating the group of the current element.
 * @returns An object with properties for all groups, each assigned to an array containing the elements of the associated group.
 */
export const groupBy = <T>(array: T[], groupByFn: GroupByFn<T>) => {
	return array.reduce((grouped, obj, index) => {
		const property = groupByFn(obj, index, array);
		grouped[property] = grouped[property] ?? [];
		grouped[property].push(obj);

		return grouped;
	}, {} as Record<string, T[]>);
};

export const binarySearch = <T>(arr: T[], value: any, compareFunction: (item: T, value: any) => number): number => {
	let left = 0;
	let right = arr.length - 1;

	while (left <= right) {
		const mid = Math.floor((left + right) / 2);

		const comparison = compareFunction(arr[mid], value);
		if (comparison === 0) {
			return mid;
		} else if (comparison < 0) {
			left = mid + 1;
		} else {
			right = mid - 1;
		}
	}

	return left;
};

export const moveItem = <T>(arr: T[], oldIndex: number, newIndex: number) => {
	const removedItem = arr.splice(oldIndex, 1)[0];
	arr.splice(newIndex, 0, removedItem);
};
