import React, { Component } from 'react';
import { Col, Input, Row } from '@bringg/react-components';
import { inject, observer } from 'mobx-react';
import _some from 'lodash/some';
import { ActionsConfigurationLevel } from '../../../types/common.consts';
import { Button, Option, Select } from '../../../components';
import Translate from '../../../translation/translator';
import ActionsConfiguration from '../../../stores/actions-configuration/domain-objects/actions-configuration';
import ActionsConfigurationsStore from '../../../stores/actions-configuration/actions-configuration-store';

import './actions-configuration-header.scss';

interface Props {
	disableSelectLevel?: boolean;
	actionsConfiguration: ActionsConfiguration;
	actionsConfigurationsStore?: ActionsConfigurationsStore;
	onAddActionData: () => void;
	onCancel: () => void;
	onSave: () => void;
}

class ActionsConfigurationHeader extends Component<Props> {
	onLevelSelect = (level: ActionsConfigurationLevel) => {
		this.props.actionsConfiguration.setLevel(level);
	};

	onTitleChanged = (title: string) => {
		this.props.actionsConfiguration.setTitle(title);
	};

	onAddActionData = () => {
		this.props.actionsConfiguration.addNewActionData();
		this.props.onAddActionData();
	};

	isOptionDisabled = (level: ActionsConfigurationLevel): boolean => {
		return level === ActionsConfigurationLevel.USER || this.shouldDisableShift(level);
	};

	shouldDisableShift = (level: ActionsConfigurationLevel) => {
		return (
			level === ActionsConfigurationLevel.SHIFT &&
			_some(
				this.props.actionsConfigurationsStore.getAll,
				action => action.level === ActionsConfigurationLevel.SHIFT
			)
		);
	};

	render() {
		const { actionsConfiguration } = this.props;
		const { isValid, message } = actionsConfiguration.validation;
		const disableTitle = actionsConfiguration.level === ActionsConfigurationLevel.USER;
		return (
			<div className="actions-configuration-header">
				<Row gutter={10}>
					<Col>
						<div>
							<Translate text="ACTION_CONFIGURATION.TITLE" />
						</div>
						<Input
							placeholder=""
							type="text"
							isDisabled={disableTitle}
							onChange={e => this.onTitleChanged(e.target.value)}
							value={this.props.actionsConfiguration.title}
							className="action-data-input"
							data-test-id="action-configuration-title"
						/>
					</Col>
					<Col>
						<div>
							<Translate text="ACTION_CONFIGURATION.LEVEL" />
						</div>
						<Select
							disabled={this.props.disableSelectLevel}
							onSelect={this.onLevelSelect}
							value={this.props.actionsConfiguration.level}
							data-test-id="action-configuration-level"
							className="action-data-input"
						>
							{Object.values(ActionsConfigurationLevel).map(level => (
								<Option
									key={level}
									value={level}
									disabled={this.isOptionDisabled(level)}
									data-test-id="action-configuration-level-option"
								>
									<Translate text={level} />
								</Option>
							))}
						</Select>
					</Col>
				</Row>
				<Row>
					<Button onClick={this.onAddActionData} className="add-button" data-test-id="add-action-data">
						<Translate text="ACTION_CONFIGURATION.ADD_ACTION_DATA" />
					</Button>
					<Button
						disabled={!isValid}
						onClick={this.props.onSave}
						className="save-button"
						data-test-id="action-configuration-save"
					>
						<Translate text="ACTION_CONFIGURATION.SAVE" />
					</Button>
					<Button
						onClick={this.props.onCancel}
						className="cancel-button"
						data-test-id="action-configuration-cancel"
					>
						<Translate text="ACTION_CONFIGURATION.CANCEL" />
					</Button>
				</Row>
				<div className="actions-configuration-error">{message}</div>
			</div>
		);
	}
}

export default inject('actionsConfigurationsStore')(observer(ActionsConfigurationHeader));
