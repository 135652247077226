import { action, computed, makeObservable } from 'mobx';
import { ActionType } from '@bringg/types';
import { ArrayManipulationViewStore } from '@bringg-frontend/utils';

import { workflowsRootStore } from '../../stores/internal';
import PredefinedAlertStore, {
	PREDEFINED_AVAILABLE_ACTIONS_TYPE
} from '../../stores/alerts-configuration/predefined-alert-store';
import { TemplateType } from '../../templates-board/components/templates-filter-bar/types';

import { TemplateStore } from './index';

const filterKeys = {
	kSearch: Symbol('kSearch'),
	kTemplateType: Symbol('kTemplateType'),
	kActions: Symbol('kActions')
};

function isPredefinedAlertStore(item: TemplateStore | PredefinedAlertStore): item is PredefinedAlertStore {
	return (item as PredefinedAlertStore).isPredefined;
}

class TemplateBoardViewStore extends ArrayManipulationViewStore<TemplateStore | PredefinedAlertStore> {
	constructor() {
		super();
		makeObservable(this, {
			setSearchQuery: action,
			hasSearchQuery: computed,

			setTemplateType: action,
			setActions: action
		});
	}

	get data(): (TemplateStore | PredefinedAlertStore)[] {
		const { templateRepo, predefinedAlertRepo } = workflowsRootStore.getStore();

		const templates = templateRepo.available;
		const templatesIds = new Set(
			templates.map(({ predefined_id, id }) => {
				// todo - return predefined_id after https://bringg.atlassian.net/browse/BRNGG-11365 id completed
				if (predefined_id !== undefined) {
					return predefined_id;
				}
				return id;
			})
		);

		// Remove the predefined alerts that have matching template
		const predefinedWithoutMatchingTemplates = predefinedAlertRepo
			.available(true)
			.filter(({ id }) => !templatesIds.has(id));

		return (templates as unknown as (TemplateStore | PredefinedAlertStore)[]).concat(
			predefinedWithoutMatchingTemplates
		);
	}

	setSearchQuery(query: string | undefined) {
		if (!query) {
			this.removeFilter(filterKeys.kSearch);
			return;
		}

		const lowerCaseQuery = `${query}`.toLowerCase();

		this.setFilter(filterKeys.kSearch, item => {
			if (item.title.toLowerCase().includes(lowerCaseQuery)) {
				return true;
			}

			if (isPredefinedAlertStore(item)) {
				return item.description.toLowerCase().includes(lowerCaseQuery);
			}

			return item.content.fullSentence.toLowerCase().includes(lowerCaseQuery);
		});
	}

	get hasSearchQuery() {
		return this.hasFilter(filterKeys.kSearch);
	}

	setTemplateType(templateType: TemplateType | undefined) {
		if (templateType === undefined) {
			this.removeFilter(filterKeys.kTemplateType);
			return;
		}

		this.setFilter(filterKeys.kTemplateType, item => {
			const isItemPredefined = isPredefinedAlertStore(item);
			if (templateType === TemplateType.PREDEFINED) {
				return isItemPredefined;
			}

			return !isItemPredefined;
		});
	}

	setActions(actionTypes: ActionType[]) {
		if (!actionTypes?.length) {
			this.removeFilter(filterKeys.kActions);
			return;
		}

		this.setFilter(filterKeys.kActions, item => {
			const itemActions: ActionType[] = isPredefinedAlertStore(item)
				? PREDEFINED_AVAILABLE_ACTIONS_TYPE
				: // flat actions is not cached as they are not called within reaction
				  item.actions.flatActions.map(({ action_type }) => action_type);

			return actionTypes.some(actionType => itemActions.includes(actionType));
		});
	}
}

export default TemplateBoardViewStore;
