import { DefaultColumnFilter, DefaultGroupHeader } from './components';

// Direct any questions to the BILLING TEAM
export const DEFAULT_COLUMN = {
	Filter: DefaultColumnFilter,
	Group: DefaultGroupHeader,
	minWidth: 100,
	maxWidth: 1600,
	width: 220
};

export * from './consts';
export * from './plugin-hooks';
export { default as BringgReactTable } from './bringg-react-table';
export { default as VirtualListTableContainer } from './components/table-container/virtual-list-table-container';
export { default as BasicTableContainer } from './components/table-container/basic-table-container';
export { default as ListChildRow } from './components/row/list-child-row';
export { default as ListChildRowMemo } from './components/row/list-child-row-memo';
export { TableHeader } from './components';
export { default as TableInstanceContext } from './contexts/table-instance-context';
export * from './utils';

export type { BaseRecord, ReactWindowRenderRowProp, AntCheckboxToggleRowsSelectedProps, RenderRowProps } from './types';
export type { RenderTableContainerProps } from './bringg-react-table';
