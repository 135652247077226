import { DeliveryBlockResource } from '@bringg/types';
import { getDaysBetweenDates, getStartOfDay } from '@bringg-frontend/utils';

import { useStores } from 'bringg-web/recipes';
import {
	ResourceWithBlockData,
	SelectedDriversAndVehiclesFromExistingRoutes,
	SelectedResourceInTeam,
	SelectedResources
} from './types';

const useResourceCheckbox = () => {
	const { optimizationStore } = useStores();

	const createCheckboxDataByResources = (
		resourcesList: ResourceWithBlockData[],
		selectedResources: SelectedResources,
		isOptimizeNewAndSaveExisting: boolean,
		selectedDriversAndVehiclesFromExistingRoutes
	) => {
		resourcesList.forEach(resource => {
			const resourceDates = getDaysBetweenDates(resource.startTime, resource.endTime, null);

			for (const date of Object.keys(resourceDates)) {
				if (!selectedResources[date]) {
					selectedResources[date] = { selectAllInDate: true, checkboxesByTeam: {} };
				}
				const selectedResourcesByTeamAndDate = createResourceCheckboxDataObject({
					isOptimizeNewAndSaveExisting,
					resource,
					selectedDriversAndVehiclesFromExistingRoutes,
					selectedResourcesInTeamByDate: selectedResources[date].checkboxesByTeam[resource.teamId],
					deliveryBlockStartOfDay: date
				});
				if (!selectedResources[date].checkboxesByTeam[resource.teamId]) {
					selectedResources[date].checkboxesByTeam[resource.teamId] = {} as SelectedResourceInTeam;
				}
				selectedResources[date].checkboxesByTeam[resource.teamId] = selectedResourcesByTeamAndDate;
				if (!selectedResourcesByTeamAndDate.selectAllInTeam) {
					selectedResources[date].selectAllInDate = false;
				}
			}
		});

		return selectedResources;
	};

	const isResourceSelectedOnPreviousOptimization = (
		isOptimizeNewAndSaveExisting,
		resource,
		selectedDriversAndVehiclesFromExistingRoutes,
		deliveryBlockStartOfDay
	): boolean => {
		return (
			isOptimizeNewAndSaveExisting &&
			(selectedDriversAndVehiclesFromExistingRoutes.users[deliveryBlockStartOfDay]?.[resource.user_id] ||
				selectedDriversAndVehiclesFromExistingRoutes.vehicles[deliveryBlockStartOfDay]?.[resource.vehicle_id])
		);
	};

	const createResourceCheckboxDataObject = ({
		isOptimizeNewAndSaveExisting,
		resource,
		selectedDriversAndVehiclesFromExistingRoutes,
		selectedResourcesInTeamByDate,
		deliveryBlockStartOfDay
	}: {
		isOptimizeNewAndSaveExisting: boolean;
		resource: DeliveryBlockResource;
		selectedDriversAndVehiclesFromExistingRoutes: SelectedDriversAndVehiclesFromExistingRoutes;
		selectedResourcesInTeamByDate?: SelectedResourceInTeam;
		deliveryBlockStartOfDay: string;
	}): SelectedResourceInTeam => {
		const isResourceSelected = isResourceSelectedOnPreviousOptimization(
			isOptimizeNewAndSaveExisting,
			resource,
			selectedDriversAndVehiclesFromExistingRoutes,
			deliveryBlockStartOfDay
		);
		if (selectedResourcesInTeamByDate) {
			const selectedResources = selectedResourcesInTeamByDate;
			if (isOptimizeNewAndSaveExisting) {
				selectedResources.selectedIds[resource.id] = {
					isChecked: !!isResourceSelected,
					disabled: !!isResourceSelected
				};
				if (!isResourceSelected) {
					selectedResources.selectAllInTeam = false;
				}
			} else {
				selectedResources.selectedIds[resource.id] = {
					isChecked: true,
					disabled: false
				};
			}
			return selectedResources;
		}

		return {
			selectAllInTeam: isOptimizeNewAndSaveExisting ? !!isResourceSelected : true,
			selectedIds: {
				[resource.id]: isOptimizeNewAndSaveExisting
					? { isChecked: !!isResourceSelected, disabled: !!isResourceSelected }
					: { isChecked: true, disabled: false }
			}
		};
	};

	const sortResourcesByResourcesInUse = (
		resources: ResourceWithBlockData[],
		selectedResources: SelectedResources
	) => {
		const resourcesInUseOnExistingRuns = [];
		const resourcesNotInUse = [];
		resources.forEach(resource => {
			const resourceStartTime = getStartOfDay(resource.startTime);
			const resourceCheckBoxSelectedStatus =
				selectedResources[resourceStartTime].checkboxesByTeam[resource.teamId].selectedIds[resource.id];
			if (resourceCheckBoxSelectedStatus.disabled) {
				resourcesInUseOnExistingRuns.push(resource);
			} else {
				resourcesNotInUse.push(resource);
			}
		});
		return [...resourcesInUseOnExistingRuns, ...resourcesNotInUse];
	};

	const isNoResourceSelected = () => {
		if (optimizationStore.selectedResourcesIds) {
			for (const resourcesByDate of Object.values(optimizationStore.selectedResourcesIds)) {
				if (resourcesByDate.selectAllInDate) {
					return false;
				}
				for (const resourceByTeam of Object.values(resourcesByDate.checkboxesByTeam)) {
					if (resourceByTeam.selectAllInTeam) {
						return false;
					}
					const checkedResource = Object.values(resourceByTeam.selectedIds).find(
						selectedResource => selectedResource.isChecked
					);
					if (checkedResource) {
						return false;
					}
				}
			}
		}
		return true;
	};

	return {
		createCheckboxDataByResources,
		createResourceCheckboxDataObject,
		isResourceSelectedOnPreviousOptimization,
		sortResourcesByResourcesInUse,
		isNoResourceSelected
	};
};

export default useResourceCheckbox;
