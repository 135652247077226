import { NO_TEAM } from 'bringg-web/features/planned-delivery-windows/planned-delivery-windows-view';
import { getRoot } from 'mobx-easy';
import RootStore from 'bringg-web/stores/root-store';

export const getValidTeam = (teamId: number): number => {
	const team = getRoot<RootStore>().data.teamsStore.get(teamId);
	if (team) {
		return teamId;
	}
	return NO_TEAM;
};
