import { RRule, Frequency } from 'rrule';
import i18next from 'i18next';
import { RECURRING_NO_REPEAT_FREQUENCY } from '../../../../../components/recurring/recurring.consts';
import {
	RecurringFrequenciesOptionsLabels,
	RecurringRepeatLabels
} from '../../../../../components/recurring/recurring.labels';

const getFrequenciesOptionsLabels = (): RecurringFrequenciesOptionsLabels => ({
	everyLabel: i18next.t('DELIVERY_BLOCKS.RECURRING.EVERY'),
	onLabel: i18next.t('DELIVERY_BLOCKS.RECURRING.ON'),
	daysLabel: i18next.t('DELIVERY_BLOCKS.RECURRING.DAYS'),
	weeksLabel: i18next.t('DELIVERY_BLOCKS.RECURRING.WEEKS'),
	monthsLabel: i18next.t('DELIVERY_BLOCKS.RECURRING.MONTHS'),
	weekDaysLabels: {
		[RRule.SU.weekday]: i18next.t('DELIVERY_BLOCKS.RECURRING.WEEK_DAYS.SU'),
		[RRule.MO.weekday]: i18next.t('DELIVERY_BLOCKS.RECURRING.WEEK_DAYS.MO'),
		[RRule.TU.weekday]: i18next.t('DELIVERY_BLOCKS.RECURRING.WEEK_DAYS.TU'),
		[RRule.WE.weekday]: i18next.t('DELIVERY_BLOCKS.RECURRING.WEEK_DAYS.WE'),
		[RRule.TH.weekday]: i18next.t('DELIVERY_BLOCKS.RECURRING.WEEK_DAYS.TH'),
		[RRule.FR.weekday]: i18next.t('DELIVERY_BLOCKS.RECURRING.WEEK_DAYS.FR'),
		[RRule.SA.weekday]: i18next.t('DELIVERY_BLOCKS.RECURRING.WEEK_DAYS.SA')
	}
});

const getRepeatLabels = (): RecurringRepeatLabels => ({
	timesLabel: i18next.t('DELIVERY_BLOCKS.RECURRING.REPEAT.TIMES'),
	endRepeatLabel: i18next.t('DELIVERY_BLOCKS.RECURRING.REPEAT.END_REPEAT'),
	afterLabel: i18next.t('DELIVERY_BLOCKS.RECURRING.REPEAT.AFTER'),
	onLabel: i18next.t('DELIVERY_BLOCKS.RECURRING.REPEAT.ON'),
	repeat: i18next.t('DELIVERY_BLOCKS.REPEAT')
});

const getFrequenciesLabels = () => ({
	[RECURRING_NO_REPEAT_FREQUENCY]: i18next.t('DELIVERY_BLOCKS.RECURRING.NO_REPEAT'),
	[Frequency.DAILY]: i18next.t('DELIVERY_BLOCKS.RECURRING.DAILY'),
	[Frequency.WEEKLY]: i18next.t('DELIVERY_BLOCKS.RECURRING.WEEKLY'),
	[Frequency.MONTHLY]: i18next.t('DELIVERY_BLOCKS.RECURRING.MONTHLY')
});

export { getFrequenciesOptionsLabels, getRepeatLabels, getFrequenciesLabels };
