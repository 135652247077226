import { getDropOffWayPoint } from '@bringg-frontend/utils';
import { Task, WayPoint } from '@bringg/types';
import moment from 'moment';

export const getWaypointRange = (
	tasks: Partial<Task>[],
	minimumHours?: number
): { startTime: null | Date; endTime: null | Date } => {
	let minNoEarlierThan = null;
	let maxNoLaterThan = null;

	for (const task of tasks) {
		const wayPoint = getDropOffWayPoint(task) || ({} as WayPoint);

		const wpNoEarlierThen = wayPoint.no_earlier_than || wayPoint.first_attempt_promise_no_earlier_than;

		if (minNoEarlierThan > wpNoEarlierThen || (!minNoEarlierThan && wpNoEarlierThen)) {
			minNoEarlierThan = wpNoEarlierThen;
		}

		const wpNoLaterThen = wayPoint.no_later_than || wayPoint.first_attempt_promise_no_later_than;
		if (maxNoLaterThan < wpNoLaterThen || (!maxNoLaterThan && wpNoLaterThen)) {
			maxNoLaterThan = wpNoLaterThen;
		}
	}

	if (minimumHours && !minNoEarlierThan && !maxNoLaterThan) {
		return {
			startTime: new Date(),
			endTime: moment().add(minimumHours, 'hours').toDate()
		};
	}

	return {
		startTime: minNoEarlierThan && new Date(minNoEarlierThan),
		endTime: maxNoLaterThan && new Date(maxNoLaterThan)
	};
};
