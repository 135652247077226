import { useCallback, useEffect, useMemo, useState } from 'react';

import { EnumDefinitionItem } from '@bringg/types';
import { useTranslation } from 'react-i18next';
import { head } from 'lodash';

interface Props {
	keyValueDefinition: EnumDefinitionItem[];
	onEnumDefinitionChange: (enumDefinition: EnumDefinitionItem[]) => void;
	allowEmpty?: boolean;
	translations: {
		errors: {
			emptyRowMessage: string;
			valueAndNameNotEmpty: string;
			noSameValue: string;
		};
	};
}

const createUniqDefinitionItemKey = (index: number) => {
	return `${index + 1}-${Date.now()}`;
};

const getEmptyDefinitionItem = (index = 0): EnumDefinitionUI => {
	return {
		value: '',
		name: '',
		key: createUniqDefinitionItemKey(index)
	};
};

export interface EnumDefinitionUI extends EnumDefinitionItem {
	value: string;
	key: string;
}

export function useKeyValueDefinitionTableState({
	keyValueDefinition,
	onEnumDefinitionChange,
	allowEmpty = false,
	translations: { errors }
}: Props) {
	const { t } = useTranslation();

	const [definitionList, setDefinitionList] = useState<EnumDefinitionUI[]>([]);

	useEffect(() => {
		const definitionList: EnumDefinitionUI[] = keyValueDefinition?.length
			? keyValueDefinition.map(({ name, value: originalValue }, index) => ({
					name,
					// on UI value casted to string for straightforward validation
					value: String(originalValue),
					key: createUniqDefinitionItemKey(index + 1)
			  }))
			: [getEmptyDefinitionItem()];

		setDefinitionList(definitionList);
	}, [keyValueDefinition]);

	const enumDefinitionError = useMemo(() => {
		let error = '';
		const enumValues = new Set();

		if (!allowEmpty && !definitionList.length) {
			error = errors.emptyRowMessage;
			return error;
		}

		for (const definition of definitionList) {
			const { value, name } = definition;

			const trimmedValue = String(value).trim();
			const trimmedName = String(name).trim();
			if (!trimmedValue.length || !trimmedName.length) {
				error = errors.valueAndNameNotEmpty;
				return error;
			}

			enumValues.add(value);
		}

		if (enumValues.size !== definitionList.length) {
			error = errors.noSameValue;
			return error;
		}

		return error; /* eslint-disable-line */
	}, [definitionList, allowEmpty, t, errors]);

	useEffect(() => {
		if (!enumDefinitionError) {
			onEnumDefinitionChange(
				definitionList.map(({ value: originValue, name }) => {
					// since value is type of string on UI in case user entered `12345` this should be saved as number type
					const valueAsNumber = Number(originValue);

					return {
						value: Number.isFinite(valueAsNumber) ? valueAsNumber : originValue,
						name
					};
				})
			);
		}
	}, [enumDefinitionError, definitionList]);

	const handleDefinitionChange = useCallback(
		(index: number, newDefinition: EnumDefinitionUI) => {
			const newEnumDefinition = [...definitionList];
			newEnumDefinition[index] = newDefinition;

			setDefinitionList(newEnumDefinition);
		},
		[definitionList]
	);

	const handleDelete = useCallback(
		(deleteIndex: number) => {
			setDefinitionList(definitionList.filter((_, index) => index !== deleteIndex));
		},
		[definitionList]
	);

	const addNewValue = useCallback(() => {
		setDefinitionList([...definitionList, getEmptyDefinitionItem(definitionList.length)]);
	}, [definitionList]);

	const [orderedDefinitionList, setOrderedDefinitionList] = useState<EnumDefinitionUI[]>([]);

	useEffect(() => {
		setOrderedDefinitionList(definitionList);
	}, [definitionList]);

	const handleMoveItem = useCallback(
		(dragIndex: number, hoverIndex: number) => {
			const newOrder = [...orderedDefinitionList];

			const dragItem = head(newOrder.splice(dragIndex, 1));
			newOrder.splice(hoverIndex, 0, dragItem!);

			setOrderedDefinitionList(newOrder);
		},
		[orderedDefinitionList]
	);

	const handleDroppedItem = useCallback(() => {
		setDefinitionList(orderedDefinitionList);
	}, [orderedDefinitionList]);

	return {
		definitionList,
		setDefinitionList,
		enumDefinitionError,
		handleDefinitionChange,
		handleDelete,
		addNewValue,
		orderedDefinitionList,
		handleMoveItem,
		handleDroppedItem
	};
}
