import React from 'react';
import { useTranslation } from 'react-i18next';
import { Tooltip } from '@bringg/react-components';
import { BringgFontIcons, BringgIcon } from '@bringg/bringg-icons';

import styles from './fastest-rule-validation.module.scss';

const translationKey = 'FLEETS.DISABLED_TYPE.FASTEST';
const linkUrl = 'https://help.bringg.com/r/Ops-Manager-Guide/Manage-your-Delivery-Network-with-Bringg-s-Delivery-Hub';

const FastestRuleValidation = () => {
	const { t } = useTranslation();

	return (
		<div>
			{t(translationKey)}
			<a href={linkUrl} target="_blank" rel="noopener noreferrer" className={styles.link}>
				{t('LEARN_MORE')}
			</a>
		</div>
	);
};

export const FastestRuleValidationTooltip = () => {
	const { t } = useTranslation();

	return (
		<Tooltip placement="right" title={t(translationKey)}>
			<BringgIcon
				iconName={BringgFontIcons.PrompedMessage}
				className={styles.warningIcon}
				data-test-id="fastest-fleet-warning"
			/>
		</Tooltip>
	);
};

export default FastestRuleValidation;
