import React, { Component } from 'react';

import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

interface Props {
	children: JSX.Element[];
	onFieldMove: (removedIndex: number, addedIndex: number) => void;
	isDragDisabled?: boolean;
}

export default class DraggableList extends Component<Props> {
	public static defaultProps = {
		className: '',
		isDragDisabled: false
	};

	onDragEnd = result => {
		if (!result.destination) {
			return;
		}

		this.props.onFieldMove(result.source.index, result.destination.index);
	};

	render() {
		return (
			<DragDropContext onDragEnd={this.onDragEnd}>
				<Droppable droppableId="droppable">
					{provided => (
						<div data-test-id="droppable-container" {...provided.droppableProps} ref={provided.innerRef}>
							{this.props.children.map((child, index) => (
								<Draggable
									isDragDisabled={this.props.isDragDisabled}
									key={child.key}
									draggableId={String(child.key)}
									index={index}
								>
									{provided => (
										<div
											data-test-id="draggable-item"
											ref={provided.innerRef}
											{...provided.draggableProps}
											{...provided.dragHandleProps}
										>
											{child}
										</div>
									)}
								</Draggable>
							))}
							{provided.placeholder}
						</div>
					)}
				</Droppable>
			</DragDropContext>
		);
	}
}
