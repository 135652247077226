import { AutoCompleteAddress, BringgGeocodedAddress } from '@bringg/types';
import DashboardSdk from '@bringg/frontend-utils/dist/services/dashboard-sdk';

export class GeoCodingService {
	static logPrefix = 'GeoCodingService';
	dashboardSdk: DashboardSdk;

	constructor(sdk: DashboardSdk) {
		this.dashboardSdk = sdk;
	}

	// @ts-ignore
	fetchAutocomplete = async (address: any): Promise<AutoCompleteAddress[]> => {
		const logPrefix = `${GeoCodingService.logPrefix} - fetchAutocomplete`;
		try {
			const res = await this.dashboardSdk.sdk.geocoding.autoComplete(address);

			if (!res.success) {
				const err = `${logPrefix}: Success returned false with message: ${res.message}`;
				console.error(err);
			}

			return res.addresses;
		} catch (err) {
			console.error(`${logPrefix}: error: ${err}`);
		}
	};

	// @ts-ignore
	getPlaceDetails = async (placeId): Promise<BringgGeocodedAddress> => {
		const logPrefix = `${GeoCodingService.logPrefix} - getPlaceDetails`;
		try {
			const res = await this.dashboardSdk.sdk.geocoding.getPlaceDetails(placeId);

			if (!res.success) {
				console.error(`${logPrefix}: Success returned false with message: ${res.message}`);
			}

			return res.address_details;
		} catch (err) {
			console.error(`${logPrefix}: error: ${err}`);
		}
	};
}
