import React from 'react';
import { BringgFontIcons, BringgIcon } from '@bringg/bringg-icons';
import { BringgPopover, Button } from '@bringg/react-components';

const VehicleProfilesActions = ({ record, setVehicleType, setRecordToDelete, t }) => {
	const popoverContent = (
		<div className="actions-popover">
			<Button
				type="link"
				icon={<BringgIcon iconName={BringgFontIcons.Pencil} />}
				onClick={() => setVehicleType(record)}
				data-test-id={`edit-${record.id}`}
			>
				{t('VEHICLE_PROFILES.ACTION.EDIT')}
			</Button>
			<Button
				type="link"
				icon={<BringgIcon iconName={BringgFontIcons.Trash} />}
				onClick={() => setRecordToDelete(record)}
				data-test-id={`delete-${record.id}`}
			>
				{t('VEHICLE_PROFILES.ACTION.DELETE.BUTTON_TEXT')}
			</Button>
		</div>
	);

	return (
		<BringgPopover content={popoverContent} destroyOnHide>
			<Button
				className="hidden-action-item"
				shape="circle"
				type="link"
				icon={<BringgIcon iconName={BringgFontIcons.MenuHorizontal} />}
				data-test-id={`action-item-${record.id}`}
			/>
		</BringgPopover>
	);
};

export default VehicleProfilesActions;
