import { throttle } from 'lodash';
import type { DebouncedFunc, ThrottleSettings } from 'lodash';

export type BufferedItem<T extends object, A> = T & { _action?: A };

export type ThrottledFuncWithBuffer<T extends object, A = string> = DebouncedFunc<CallbackWrapper<T, A>>;

type Callback<T extends object> = {
	(items: T[]): unknown;
};

type CallbackWrapper<T extends object, A> = {
	(items: T[], action?: A): unknown;
};

type BufferingHandler<T extends object, A> = {
	(items: T[], prevBuffer: BufferedItem<T, A>[], action?: A): BufferedItem<T, A>[];
};

type BufferingOptions<T extends object, A> = {
	dataBufferingHandler?: BufferingHandler<T, A>;
};

export const throttleWithBuffer = <ItemType extends object, BufferActions = string>(
	callback: Callback<BufferedItem<ItemType, BufferActions>>,
	wait: number,
	options: BufferingOptions<ItemType, BufferActions> = {},
	throttleOptions: ThrottleSettings = { leading: false, trailing: true }
) => {
	let dataBuffer: BufferedItem<ItemType, BufferActions>[] = [];

	const flushDataBuffer = () => (dataBuffer = []);
	const callbackWithDataFlush: Callback<BufferedItem<ItemType, BufferActions>> = items => {
		flushDataBuffer();
		return callback(items);
	};
	const throttledCallback: DebouncedFunc<Callback<ItemType>> = throttle(callbackWithDataFlush, wait, throttleOptions);
	const dataBufferingHandler: BufferingHandler<ItemType, BufferActions> =
		options.dataBufferingHandler ||
		(items => {
			dataBuffer.push(...items);
			return dataBuffer;
		});
	const throttleWrapper: ThrottledFuncWithBuffer<ItemType, BufferActions> = (items, action) => {
		dataBuffer = dataBufferingHandler(items, dataBuffer.slice(), action);
		return throttledCallback(dataBuffer);
	};

	throttleWrapper.flush = () => {
		flushDataBuffer();
		return throttledCallback.flush();
	};

	throttleWrapper.cancel = () => {
		flushDataBuffer();
		return throttledCallback.cancel();
	};

	return throttleWrapper;
};
