import React, { FC, useMemo } from 'react';
import classNames from 'classnames';
import { Spinner } from '@bringg/react-components';

import type { TableInstance } from 'react-table';

import TableInstanceContext from './contexts/table-instance-context';
import type { ReactWindowRenderRowProp, RenderRowProps } from './types';

import './bringg-react-table.scss';

export interface RenderTableContainerProps<T extends Record<string, unknown>> extends ReactWindowRenderRowProp {
	tableInstance: TableInstance<T>;
}

export interface BringgReactTableProps<T extends Record<string, unknown> = Record<string, unknown>> {
	tableInstance: TableInstance<T>;
	TableContainerComponent: React.FC<RenderTableContainerProps<T>>;
	RenderRow: FC<RenderRowProps>;
	id?: string;
	className?: string;
	isLoadingMoreItems?: boolean;
	sticky?: boolean;
	tableContainerCbRef?: React.RefCallback<HTMLDivElement>;
}

const BringgReactTable = <T extends Record<string, unknown>>(props: BringgReactTableProps<T>) => {
	const {
		tableInstance,
		className,
		TableContainerComponent,
		RenderRow,
		isLoadingMoreItems,
		sticky = true,
		id,
		tableContainerCbRef
	} = props;
	const { getTableProps } = tableInstance;

	// Kinda dirty hack to break memoization for TableContextInstance
	// need to think of a better way
	const tableInstanceMemo = useMemo(() => ({ ...tableInstance }), [tableInstance.state]);
	const wrapperClassName = classNames('react-table-wrapper', {
		[className]: className,
		sticky
	});

	return (
		<div className={wrapperClassName} {...getTableProps()} id={id} ref={tableContainerCbRef}>
			{isLoadingMoreItems && (
				<div className="loading-more-items-indicator" data-test-id="alerts-table-loading-more">
					<Spinner size="large" /> Loading more items...
				</div>
			)}
			<TableInstanceContext.Provider value={tableInstanceMemo}>
				<TableContainerComponent RenderRow={RenderRow} tableInstance={tableInstanceMemo} />
			</TableInstanceContext.Provider>
		</div>
	);
};

// Direct any questions to the BILLING TEAM
export default BringgReactTable;
