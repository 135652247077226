import { useCallback, useState, useMemo } from 'react';

import { Radio, Space } from '@bringg/react-components';
import { Notification } from '@bringg/react-components';

import { useStores } from 'bringg-web/recipes';
import DialogBox from '../../components/dialog-box/dialog-box';
import { ActionType } from '../data-utils';
import { useSpeedFactorTranslation } from '../translations';

const RemoveDialog = ({ open, onOk, onCancel, event, speedFactors = [] }) => {
	const { serviceAreasStore } = useStores();
	const [loading, setLoading] = useState(false);
	const [actionType, setActionType] = useState<ActionType>('current');

	const t = useSpeedFactorTranslation();

	const options = useMemo(() => {
		return [
			{ label: t.removeCurrent, value: 'current' },
			{ label: t.removeCurrentAndFollowing, value: 'current_and_following' },
			{ label: t.removeAll, value: 'all' }
		];
	}, [t]);

	const handleActionChange = e => {
		setActionType(e.target.value);
	};

	const handleOk = useCallback(async () => {
		setLoading(true);

		try {
			const response = await serviceAreasStore.deleteSpeedFactor(event, actionType, speedFactors);

			Notification.success(response.message);

			onOk();
		} catch (error) {
			const message = error instanceof Error ? error.message : String(error);

			Notification.error(message);
		} finally {
			setLoading(false);
		}
	}, [event, actionType]);

	return (
		<DialogBox
			title={t.removeTitle}
			subtitle={t.removeSubtitle}
			open={open}
			onOk={handleOk}
			onCancel={onCancel}
			okText={t.removeOk}
			okButtonProps={{ disabled: loading }}
			destroyOnClose
			danger
		>
			<Radio.Group value={actionType} onChange={handleActionChange}>
				<Space direction="vertical" size="small">
					{options.map(option => (
						<Radio value={option.value} style={{ fontWeight: 400 }} key={option.value}>
							{option.label}
						</Radio>
					))}
				</Space>
			</Radio.Group>
		</DialogBox>
	);
};

export default RemoveDialog;
