import React, { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import { ExclusionWindow, PrivilegeTypes } from '@bringg/types';
import { ConfirmModal, Notification } from '@bringg/react-components';

import { useStores } from 'bringg-web/recipes';
import PlannedDeliveryWindowsToolbar, {
	DropdownData
} from 'bringg-web/features/planned-delivery-windows/toolbar/planned-delivery-windows-toolbar';
import ExclusionWindowModal from 'bringg-web/features/planned-delivery-windows/modals/exclusion-window-modal/exclusion-window-modal';
import ExclusionWindowTable from 'bringg-web/features/planned-delivery-windows/exclusion-windows/exclusion-windows-table';
import useTimeFormat from 'bringg-web/hooks/use-time-format';
import { NO_TEAM } from 'bringg-web/features/planned-delivery-windows/planned-delivery-windows-view';
import useDateTimeFormat from 'bringg-web/hooks/use-date-time-format';
import { useHasAccess } from 'bringg-web/utils/privileges';
import { timezoneProvider } from 'bringg-web/services/timezone/timezone-provider';

import styles from './exclusion-window-view.module.scss';

interface Props {
	setTeamInUrl: (teamId: number) => void;
	selectedTeamId: number;
}
const ExclusionWindowView = ({ setTeamInUrl, selectedTeamId }: Props) => {
	const [exclusionModalVisible, setExclusionModalVisible] = useState(false);
	const [exclusionWindowsList, setExclusionWindowsList] = useState<ExclusionWindow[]>([]);
	const [exclusionWindowToEdit, setExclusionWindowToEdit] = useState<ExclusionWindow>(null);
	const [exclusionWindowTableLoader, setExclusionWindowTableLoader] = useState(false);
	const { t } = useTranslation();
	const { exclusionWindows } = useStores();
	const dateTimeFormat = useDateTimeFormat();
	const hourFormat = useTimeFormat();
	const hasAccessToEdit = useHasAccess(PrivilegeTypes.EDIT_EXCLUSION_WINDOWS);
	const timezone =
		selectedTeamId === NO_TEAM
			? timezoneProvider.getTimezoneByMerchant()
			: timezoneProvider.getTimezoneByTeamId(selectedTeamId);

	useEffect(() => {
		fetchExclusionWindowsAndSetToView(selectedTeamId, false);
	}, []);

	const fetchExclusionWindowsAndSetToView = async (teamId: number, useCache = true) => {
		setExclusionWindowTableLoader(true);
		const promises: Promise<ExclusionWindow[]>[] = [exclusionWindows.loadAllWithoutTeam(null, { useCache })];
		if (teamId !== NO_TEAM) {
			promises.push(exclusionWindows.loadAllByTeam({ team_id: teamId }, { useCache }));
		}
		const [exclusionByCompany, exclusionByTeam] = await Promise.all(promises);

		setExclusionWindowsList(() => {
			if (exclusionByTeam) {
				return [...exclusionByCompany, ...exclusionByTeam];
			}
			return exclusionByCompany;
		});
		setExclusionWindowTableLoader(false);
	};

	const onChangeTeam = async (teamId: number) => {
		setTeamInUrl(teamId);
		await fetchExclusionWindowsAndSetToView(teamId);
	};

	const onExclusionWindowCreated = (createdExclusionWindow: ExclusionWindow[]) => {
		const exclusionWindowsToAdd = createdExclusionWindow.filter(
			exclusionWindow =>
				exclusionWindow.team_id === selectedTeamId ||
				(exclusionWindow.team_id === null && selectedTeamId === NO_TEAM)
		);
		setExclusionWindowsList(prevState => [...prevState, ...exclusionWindowsToAdd]);
	};

	const openExclusionWindowModalWithEditMode = (id: number) => {
		const exclusionWindow = exclusionWindowsList.find(ew => ew.id === id);
		setExclusionWindowToEdit(exclusionWindow);
		setExclusionModalVisible(true);
	};

	const onCloseExclusionWindowModal = () => {
		setExclusionModalVisible(false);
		setExclusionWindowToEdit(null);
	};

	const onDeleteOpenConfirmModal = (id: number) => {
		ConfirmModal({
			title: t('EXCLUSION_WINDOW_VIEW_DELETE_CONFIRM_MODAL.ARE_YOU_SURE'),
			okText: t('EXCLUSION_WINDOW_VIEW_DELETE_CONFIRM_MODAL.IM_SURE'),
			cancelText: t('EXCLUSION_WINDOW_VIEW_DELETE_CONFIRM_MODAL.CANCEL'),
			okType: 'danger',
			content: `${t('EXCLUSION_WINDOW_VIEW_CONFIRM_MODAL.ARE_YOU_SURE_WANT_TO_DELETE_EXCLUSION')} ${id}`,
			onOk: async () => {
				try {
					await exclusionWindows.delete(id);
					setExclusionWindowsList(exclusionWindowsList.filter(exclusionWindow => exclusionWindow.id !== id));
					Notification.success(t('EXCLUSION_WINDOW_VIEW_DELETE_CONFIRM_MODAL.SUCCESS_TO_DELETE'));
				} catch (e) {
					Notification.error(t('EXCLUSION_WINDOW_VIEW_DELETE_CONFIRM_MODAL.FAILED_TO_DELETE'));
					console.error('cannot delete exclusion window: ', e);
				}
			}
		});
	};

	const updateLocalState = (id: number) => {
		const foundExclusionWindow = exclusionWindows.get(id);
		setExclusionWindowsList(prevState =>
			prevState.map(item => (item.id === foundExclusionWindow.id ? foundExclusionWindow : item))
		);
	};

	const getDropdownData = (): DropdownData => {
		return {
			trigger: ['click'],
			text: t('PDW_ALL_LIST_DROPDOWN.CREATE_BUTTON'),
			items: [
				{
					key: '1',
					label: t('EXCLUSION_WINDOWS_MODAL_BUTTON_DROPDOWN.ADD_EXCLUSION_WINDOW'),
					onClick: () => setExclusionModalVisible(true),
					disabled: !hasAccessToEdit
				}
			]
		};
	};

	return (
		<div className={styles.exclusionWindowsView}>
			<div className={styles.allSchedulesToolbar}>
				<PlannedDeliveryWindowsToolbar
					selectedTeam={selectedTeamId}
					onTeamChange={onChangeTeam}
					dropdownData={getDropdownData()}
					timezone={timezone}
				/>
			</div>
			<div className={styles.exclusionWindowsTable}>
				<ExclusionWindowTable
					format={dateTimeFormat}
					selectedTeamId={selectedTeamId}
					exclusionWindows={exclusionWindowsList}
					openExclusionWindowModalWithEditMode={openExclusionWindowModalWithEditMode}
					tableLoading={exclusionWindowTableLoader}
					onDeleteOpenConfirmModal={onDeleteOpenConfirmModal}
					timezone={timezone}
				/>
			</div>

			{exclusionModalVisible && (
				<ExclusionWindowModal
					onCreate={onExclusionWindowCreated}
					onEdit={updateLocalState}
					isVisible={exclusionModalVisible}
					title={
						exclusionWindowToEdit
							? t('EXCLUSION_WINDOWS_MODAL_EDIT_MODE.TITLE')
							: t('EXCLUSION_WINDOWS_MODAL_ADD_MODE.TITLE')
					}
					closeModal={onCloseExclusionWindowModal}
					timeFormat={hourFormat}
					teamId={selectedTeamId}
					exclusionWindow={exclusionWindowToEdit}
				/>
			)}
		</div>
	);
};

export default observer(ExclusionWindowView);
