import isFunction from 'lodash/isFunction';

export const transformText = (text: string, dictionary) => {
	return Object.keys(dictionary).reduce((text, key) => {
		const value = dictionary[key];
		const replaceValue = isFunction(value) ? value() : value;
		return text.replace(new RegExp(key, 'g'), replaceValue);
	}, text);
};
// eslint-disable-next-line
export const buildDictionary = (userName: string, merchantNameFunc: Function) => {
	return [
		{
			key: '{{customer}}',
			value: () => userName,
			description: 'the Customer Name'
		},
		{
			key: '{{merchant}}',
			value: merchantNameFunc,
			description: 'your Company Name'
		},
		{
			key: '{{link}}',
			value: 'https://app.bringg.com/s.html?9f67d952',
			description: 'the tracking URL'
		},
		{
			key: '{{user}}',
			value: 'Driver',
			description: 'the Driver Name'
		},
		{
			key: '{{job}}',
			value: 'Job Description',
			description: 'the Job Description'
		},
		{
			key: '{{title}}',
			value: 'Order title',
			description: 'Order Title'
		},
		{
			key: '{{id}}',
			value: 'Order ID',
			description: 'Order ID'
		},
		{
			key: '{{way_point_customer}}',
			value: 'Customer Name',
			description: "way point's customer's name"
		},
		{
			key: '{{first_name}}',
			value: 'Customer First Name',
			description: "customer's first name"
		},
		{
			key: '{{time_window}}',
			value: '[From - To]',
			description: "order's delivery time window"
		},
		{
			key: '{{address}}',
			value: 'Street, number and address',
			description: 'delivery address'
		},
		{
			key: '{{team_description}}',
			value: 'Team Description',
			description: 'description of the team'
		},
		{
			key: '{{uuid}}',
			value: 'Unique ID',
			description: 'will be changed to unique ID for the notification'
		},
		{
			key: '{{verification_pin_code}}',
			value: '0000',
			description: '4 digit pin code to verify consumer by a driver'
		}
	];
};
