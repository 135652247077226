/* eslint-disable import/order */
import React from 'react';

import ReactDOM from 'react-dom';
import { I18nextProvider, initReactI18next } from 'react-i18next';
import * as mobx from 'mobx';
import { Provider } from 'mobx-react';
import i18next from 'i18next';
import XHR from 'i18next-xhr-backend';
import { createRootEnv } from '@bringg-frontend/bringg-web-infra';
import { AppConfig } from '@bringg-frontend/bringg-web-infra';
import { registerAction, executeAction } from '@bringg-frontend/global-stores';

// We don't import the entire lib only the needed parts for initialization so the React.lazy on the Routes will actually split the bundle
import { App, storesInitializer } from '@bringg-frontend/bringg-web-legacy/for-app';
import { phoneNumberService } from '@bringg-frontend/utils';

// It may seem that most of the app is in the Routes file but actually, all the stores and some other things are imported in this file and the App.tsx
// Which reduce the bundle and the source map significantly when we use the code splitting
const Routes = React.lazy(async () => import('./Routes'));

const SdkActions = Object.freeze({
	UPDATE_SDK: 'sdk updated'
});

async function loadReactOnLoad() {
	// Meaning onload event already fired, this happens on first load of the React app
	if (document.readyState === 'complete') {
		await loadReact();
		return;
	}

	window.addEventListener('load', loadReact);
}

async function loadReact() {
	try {
		window._bringg = { mobx, phoneNumberService };

		if (window.initSdk) {
			// eslint-disable-next-line @typescript-eslint/await-thenable
			await window.initSdk(window._bringg);
		}

		await initReactApp();
	} catch (error) {
		console.error('Failed to initReactApp', error);
	}
}

loadReactOnLoad();

async function initReactApp() {
	// eslint-disable-next-line @typescript-eslint/no-misused-promises
	const rootEnv = await createRootEnv();
	const { dashboardSdk } = rootEnv;

	try {
		await dashboardSdk.init();
	} catch (error) {
		console.error('Failed to connect SDK in react app', error);
	}

	// on bringg web logout re init react
	registerAction(
		SdkActions.UPDATE_SDK,
		() => {
			ReactDOM.unmountComponentAtNode(document.getElementById('root'));
			initReactApp();
		},
		dashboardSdk.sdk.crossAppTransport
	);

	let resolve;
	const nakedPromise = new Promise(prResolve => {
		resolve = prResolve;
	});

	await i18next
		.use(initReactI18next)
		.use(XHR)
		.init(AppConfig.getTranslationConfig(), () => {
			resolve();
		});

	const rootStore = storesInitializer.init(rootEnv);
	const { views, data } = rootStore;
	const { authStore } = views;

	await views.authStore.afterLoginEvents();
	views.authStore.setLogin(true);
	views.initLogRocket();

	await nakedPromise;
	// on this point i18next is initialized and we start language setup - on the day we remove login from angular we can remove all this flow
	views.syncLanguageWithAngular();

	ReactDOM.render(
		<I18nextProvider i18n={i18next}>
			<Provider {...views} {...data}>
				<App Routes={Routes} />
			</Provider>
		</I18nextProvider>,
		document.getElementById('root'),
		() => executeAction(authStore.crossAppTransport, 'REACT_DONE_RENDERED')
	);
}
