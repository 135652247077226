import { getRootEnv } from '@bringg-frontend/bringg-web-infra';
import { observable, makeObservable, action } from 'mobx';
import { getRoot } from 'mobx-easy';

import RootStore from '../root-store';
import UserType from './domain-objects/user-type';
import BaseDomainStore from '../core/base-domain-store';

const emptyUserType = {
	id: 0,
	title: 'No Active Role'
};

class UserTypeStore extends BaseDomainStore<UserType> {
	isFetched = false;

	constructor() {
		super();

		makeObservable(this, {
			isFetched: observable,
			setIsFetched: action
		});
	}

	fetchAll = async (): Promise<UserType[]> => {
		const { merchantConfigurationsStore } = getRoot<RootStore>().data;
		if (merchantConfigurationsStore.configuration.enable_user_types) {
			const { user_types: userTypes }: any = await getRootEnv().dashboardSdk.sdk.userTypes.getAll();
			this.set(new UserType(emptyUserType, this)); // add empty user role as no active role
			userTypes.forEach(userType => this.set(new UserType(userType, this)));
			this.setIsFetched(true);
			return this.all;
		}
		return [];
	};

	setIsFetched = (isFetch: boolean) => {
		this.isFetched = isFetch;
	};
}

export default UserTypeStore;
