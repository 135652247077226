import { useState, useEffect } from 'react';

import { isEqual } from 'lodash';
import { FleetRule } from '@bringg/react-components';
import { RuleType } from '@bringg/react-components/dist/features/fleet-router/rule-form/rule-form';
import { FallbackMethod } from '@bringg/react-components/dist/features/fleet-router/rule-form/priority-configuration/fallback/use-fallback';
import { Condition } from '@bringg/react-components/dist/features/conditions/rule/rule-utils';
import { SelectedTeams } from '@bringg/react-components/dist/features/fleet-router/rule-form/teams-section/teams-section';
import { Attribute } from '@bringg/react-components/dist/features/conditions/rule/rule-utils';
import { RadioValues } from '@bringg/react-components/dist/features/conditions/fleet-rule/fleet-rule-utils';
import { EarlyEtaStrategy } from '@bringg/types';

import useFleetRuleEngineTranslations from '../../rule-engine/use-fleet-rule-engine-translations';
import { FleetRule as FleetConfiguration } from 'bringg-web/stores/fleets-configuration/fleets-configurations-store';
import {
	createRuleConfig,
	getFleetsByIds,
	handleDrag,
	ruleTypeMap,
	validateRuleForm,
	getAttributesLength,
	filteredAttributes,
	handleRatio,
	updateDraggableOptions,
	processInputValue,
	handleRatioItemError,
	FleetsOptions,
	FallbackOptions,
	mapFleetRatio,
	FleetsRatio,
	FleetRuleItemProps,
	validateFleetsIds,
	mapFleets,
	validateEquality,
	validateTeams
} from './rule-list-utils';

export const FleetRuleItem = (props: FleetRuleItemProps) => {
	const {
		rule,
		index,
		teams,
		fleets,
		attributes,
		isOpen = false,
		onDelete,
		onSave,
		onToggleRule,
		onDuplicate,
		onDragChange,
		featureFlags
	} = props;

	const [ruleForm, updateRuleForm] = useState<FleetConfiguration>(rule);
	const [ruleCache, updateRuleCache] = useState<FleetConfiguration>(rule);
	const [selectedFleets, setSelectedFleets] = useState<string[]>(rule.priorityConfiguration?.selectedFleetIds || []);
	const [saveButtonDisabled, setSaveButtonDisabled] = useState(true);
	const [discardButtonDisabled, setDiscardButtonDisabled] = useState(true);
	const [draggableOptions, setDraggableOptions] = useState(
		getFleetsByIds({
			fleets,
			ids: rule.priorityConfiguration?.selectedFleetIds || [],
			fleetsRatioMap: rule.fleetsRatio
		})
	);

	const [fleetsRatio, setFleetsRatio] = useState(
		draggableOptions.map(fleet => ({
			fleet_id: fleet.value,
			ratio: rule.fleetsRatio?.[fleet.value] || 0
		}))
	);

	const [invalidPercents, setInvalidPercents] = useState(false);
	const [zeroAllocation, setZeroAllocation] = useState(false);
	const [totalPercentage, setTotalPercentage] = useState(
		Object.values(rule.fleetsRatio).reduce((acc, value) => acc + value, 0)
	);

	const [markInvalidPercentage, setMarkInvalidPercentage] = useState(false);

	useEffect(() => {
		setRuleForm({
			...ruleForm,
			fleetsRatio: Array.isArray(fleetsRatio) ? mapFleetRatio(fleetsRatio) : fleetsRatio
		});
	}, [fleetsRatio]);

	useEffect(() => {
		if (featureFlags?.pickupEtaAvailable) {
			setDraggableOptions([]);
		} else {
			setDraggableOptions(
				getFleetsByIds({
					fleets,
					ids: ruleForm.priorityConfiguration?.selectedFleetIds || [],
					fleetsRatioMap: ruleForm.fleetsRatio
				})
			);
		}
	}, [ruleForm.ruleType]);

	useEffect(() => {
		if (!isEqual(ruleCache, rule)) {
			updateRuleForm(rule);
			updateRuleCache(rule);
		}
	}, [rule]);

	useEffect(() => {
		if (rule.priorityConfiguration?.selectedFleetIds.length > 0) {
			const fleetsById = getFleetsByIds({
				fleets,
				ids: rule.priorityConfiguration?.selectedFleetIds || [],
				fleetsRatioMap: rule.fleetsRatio
			});

			setDraggableOptions(fleetsById);
			setFleetsRatio(
				fleetsById.map(fleet => ({
					fleet_id: fleet.value,
					ratio: fleet.ratio
				}))
			);
		}
	}, [fleets, rule.priorityConfiguration?.selectedFleetIds]);

	useEffect(() => {
		recalculatePercents(draggableOptions);
	}, [draggableOptions]);

	const { translations, itemTranslations } = useFleetRuleEngineTranslations({
		trueKey: 'CONDITIONS.BOOLEAN.TRUE',
		falseKey: 'CONDITIONS.BOOLEAN.FALSE'
	});

	const ALL_VALUE = 'All';

	const multiSelectOptions =
		teams.length > 0
			? [
					{ value: ALL_VALUE, key: itemTranslations.all },
					...teams.map(team => ({ value: team.name, key: team.id }))
			  ]
			: [];

	const methodRadioOptions = [
		{ value: RadioValues.CHEAPEST, key: itemTranslations.cheapest },
		{ value: RadioValues.FASTEST, key: itemTranslations.fastest },
		{ value: RadioValues.PRIORITY, key: itemTranslations.priority }
	];

	const fallbackRadioOptions = [
		{ value: FallbackOptions.FAST, key: itemTranslations.fallback.fast },
		{ value: FallbackOptions.CHEAP, key: itemTranslations.fallback.cheap }
	];

	const methodDropdownTypeOptions = [
		{ value: EarlyEtaStrategy.DROPOFF, key: itemTranslations.byDropoff },
		{ value: EarlyEtaStrategy.PICKUP, key: itemTranslations.byPickup }
	];

	const methodRadioOptionsWithRatio = [
		{ value: RadioValues.CHEAPEST, key: itemTranslations.cheapest },
		{ value: RadioValues.FASTEST, key: itemTranslations.fastest },
		{ value: RadioValues.PRIORITY, key: itemTranslations.priority },
		{ value: RadioValues.RATIO, key: itemTranslations.ratio }
	];

	const ruleTypeToAssignmentMethod = {
		[RuleType.cheap]: RadioValues.CHEAPEST,
		[RuleType.fast]: RadioValues.FASTEST,
		[RuleType.priority]: RadioValues.PRIORITY,
		[RuleType.ratio]: RadioValues.RATIO
	};

	const settingsMenuItems = [
		{ name: itemTranslations.duplicate, icon: 'Copy' },
		{ name: itemTranslations.delete, icon: 'Trash' }
	];

	const disableButtons = (value: boolean): void => {
		setSaveButtonDisabled(value);
		setDiscardButtonDisabled(value);
	};

	const validateRule = newRule => {
		const validations = [
			validateFleetsIds(newRule),
			validateRatio(newRule),
			validateTeams(newRule),
			validateEquality(newRule, rule)
		];

		return validations.every(validation => validation);
	};

	const setRuleForm = (newRule): void => {
		clearErrors(draggableOptions);

		if (featureFlags?.pickupEtaAvailable) {
			const isValid = validateRule(newRule);

			disableButtons(!isValid);
		} else {
			const rulesEqual = isEqual(newRule, rule);
			const fleetsRatiosValues = Object.values(newRule.fleetsRatio);
			const noRatios = fleetsRatiosValues.length === 0;

			if (noRatios && ruleForm.ruleType === RuleType.ratio) {
				disableButtons(true);
			} else {
				disableButtons(rulesEqual);
			}
		}

		updateRuleForm(newRule);
	};

	const onDiscard = (): void => {
		setSelectedFleets(rule.priorityConfiguration?.selectedFleetIds || []);

		const draggableOptions = getFleetsByIds({
			fleets,
			ids: rule.priorityConfiguration?.selectedFleetIds || [],
			fleetsRatioMap: rule.fleetsRatio
		});

		setDraggableOptions(draggableOptions);

		setFleetsRatio(
			draggableOptions.map(fleet => ({
				fleet_id: fleet.value,
				ratio: rule.fleetsRatio?.[fleet.value] || 0
			}))
		);

		setRuleForm({
			...rule,
			fleetsRatio: Array.isArray(rule.fleetsRatio) ? mapFleetRatio(rule.fleetsRatio) : rule.fleetsRatio
		});

		clearErrors(draggableOptions);
	};

	const validateRatio = (newRule = null): boolean => {
		clearErrors(draggableOptions);

		let rule = ruleForm;

		if (featureFlags?.pickupEtaAvailable) {
			rule = newRule;
		}

		if (rule.ruleType !== RuleType.ratio) {
			return true;
		}

		if (featureFlags?.pickupEtaAvailable) {
			const fleetsRatiosValues = Object.values(rule.fleetsRatio);

			const noRatios = fleetsRatiosValues.length === 0;

			if (noRatios) {
				return false;
			}
		}

		const totalPercents = draggableOptions.reduce((acc, fleet) => acc + fleet.ratio, 0);

		if (totalPercents !== 100) {
			setInvalidPercents(true);
		}

		const haveZeroAllocation = Object.values(fleetsRatio).some(ratio => ratio.ratio === 0);

		if (haveZeroAllocation) {
			setZeroAllocationError(true);
		}

		if (totalPercents !== 100 || haveZeroAllocation) {
			return false;
		}

		return true;
	};

	const setZeroAllocationError = (value: boolean): void => {
		const fleetsRatioWithError = handleRatioItemError(draggableOptions);
		setDraggableOptions(fleetsRatioWithError);
		setZeroAllocation(value);
	};

	const clearErrors = (draggableOptions): void => {
		setInvalidPercents(false);
		setZeroAllocation(false);
		setDraggableOptions(draggableOptions.map(fleet => ({ ...fleet, error: false })));
		setMarkInvalidPercentage(false);
	};

	const saveRule = async (): Promise<void> => {
		const validatedForm: FleetConfiguration = validateRuleForm({ ruleForm });

		let validatedRatio;

		if (featureFlags?.pickupEtaAvailable) {
			validatedRatio = validateRatio(validatedForm);
		} else {
			validatedRatio = validateRatio();
		}

		if (validatedRatio === false) {
			return;
		}

		setRuleForm(validatedForm);

		const config = createRuleConfig({ rule: validatedForm, fleets: selectedFleets, featureFlags });

		const saved = await onSave(config);

		if (saved) {
			disableButtons(true);
		}
	};

	const handleSettingsClick = async (action: string): Promise<void> => {
		if (action === itemTranslations.delete) {
			await onDelete({ ruleId: ruleForm.id, conditionsId: ruleForm.conditionEntity?.id || null });
		} else if (action === itemTranslations.duplicate) {
			const config = createRuleConfig({ rule: ruleForm, fleets: selectedFleets, featureFlags });
			onDuplicate(config);
		}
	};

	const onNameChange = (name: string): void => {
		setRuleForm({ ...ruleForm, name });
	};

	const handleMethodChange = (e): void => {
		clearErrors(draggableOptions);

		let updatedForm;

		if (featureFlags?.pickupEtaAvailable) {
			setSelectedFleets([]);
			updatedForm = { ...ruleForm, ruleType: ruleTypeMap[e.target.value], priorityConfiguration: {} };
		} else {
			updatedForm = { ...ruleForm, ruleType: ruleTypeMap[e.target.value] };
		}

		setRuleForm(updatedForm);
	};

	const handleFallbackChange = (e): void => {
		setRuleForm({
			...ruleForm,
			priorityConfiguration: {
				...ruleForm.priorityConfiguration,
				...(e.target.value === 'priority' ? { selectedFleetIds: [] } : {}),
				fallbackMethod: e.target.value as FallbackMethod
			}
		});
	};

	const handleTeamsChange = (value: string[]): void => {
		const previousTeams = ruleForm.selectedTeams;

		const newTeams: Record<string, boolean> = value.includes(ALL_VALUE)
			? teams.reduce((acc, team) => ({ ...acc, [team.id]: true }), {})
			: value.reduce((acc, teamId) => ({ ...acc, [teamId]: true }), {});

		const updatedTeams: Record<string, boolean> = Object.keys(previousTeams).reduce(
			(acc, key) => {
				acc[key] = newTeams[key] ?? false;
				return acc;
			},
			{ ...newTeams }
		);

		setRuleForm({ ...ruleForm, selectedTeams: updatedTeams });
	};

	const handleMultiselectChange = (array: string[]): void => {
		setRuleForm({
			...ruleForm,
			priorityConfiguration: { ...ruleForm.priorityConfiguration, selectedFleetIds: array }
		});

		setSelectedFleets(array);

		const newDraggableOptions = getFleetsByIds({
			fleets,
			ids: array,
			fleetsRatioMap: Array.isArray(ruleForm.fleetsRatio)
				? mapFleetRatio(ruleForm.fleetsRatio)
				: ruleForm.fleetsRatio
		});

		setDraggableOptions(newDraggableOptions);
	};

	const onChangeFallbackCheckbox = (): void => {
		const value = !ruleForm.priorityConfiguration?.isFallbackMethodEnabled;

		setRuleForm({
			...ruleForm,
			priorityConfiguration: { ...ruleForm.priorityConfiguration, isFallbackMethodEnabled: value }
		});
	};

	const onChangeCheckbox = (): void => {
		const value = !ruleForm.isMerchantLevel;

		setRuleForm({ ...ruleForm, isMerchantLevel: value });
	};

	const handleChangeConditions = (conditions: Condition[]): void => {
		setRuleForm({ ...ruleForm, conditionEntity: { ...ruleForm.conditionEntity, conditions } });
	};

	const handleAttributesAvailability = (teams: SelectedTeams): boolean =>
		Object.values(teams).every(value => value === false) && !ruleForm.isMerchantLevel;

	const handleDragOption = (result): void => {
		if (!result.destination) {
			return;
		}

		const handeledItems = handleDrag({ options: draggableOptions, result });

		const newFleetIdsWithPriority: string[] = Object.values(handeledItems.map((item: any) => item.value));

		setSelectedFleets(newFleetIdsWithPriority);
		setDraggableOptions(handeledItems);
		setRuleForm({
			...ruleForm,
			priorityConfiguration: {
				...ruleForm.priorityConfiguration,
				selectedFleetIds: newFleetIdsWithPriority
			}
		});
	};

	const handleAttributes = ({ attributes, ruleForm }: { attributes: Attribute[]; ruleForm: FleetConfiguration }) => {
		const usedAttributes = ruleForm.conditionEntity?.conditions?.map(({ path }) => path) || [];

		return filteredAttributes({ attributes, usedAttributes });
	};

	const recalculatePercents = (draggableOptions: FleetsOptions[]): void => {
		const totalPercents = draggableOptions.reduce((acc, fleet) => acc + fleet.ratio, 0);

		setTotalPercentage(totalPercents);

		if (totalPercents !== 100) {
			setMarkInvalidPercentage(true);
		}
	};

	const handlePercentageInput =
		fleet =>
		({ target: { value: rawValue } }) => {
			clearErrors(draggableOptions);

			const value = processInputValue(rawValue);

			if (isNaN(value)) {
				return;
			}

			if (value > 100) {
				return;
			}

			const updatedRatio: FleetsRatio[] = handleRatio({
				value,
				fleet,
				fleetsRatio: draggableOptions.map(fleet => ({
					fleet_id: fleet.value,
					ratio: fleet.ratio
				}))
			});

			const updatedDraggableOptions = updateDraggableOptions({ draggableOptions, updatedRatio });

			setFleetsRatio(updatedRatio);
			setDraggableOptions(updatedDraggableOptions);
		};

	const handleRemoveDraggableOption = currentFleet => () => {
		const newDraggableOptions = draggableOptions.filter(fleet => fleet.value !== currentFleet.value);
		const newFleetsRatio = fleetsRatio.filter(fleet => fleet.fleet_id !== currentFleet.value);

		setDraggableOptions(newDraggableOptions);
		setFleetsRatio(newFleetsRatio);
		setSelectedFleets(newDraggableOptions.map(fleet => fleet.value));
	};

	const handleMethodDropdownChange = (action): void => {
		setRuleForm({
			...ruleForm,
			earlyEtaStrategy: action
		});
	};

	return (
		<div className="fleet-rule">
			<FleetRule
				translations={translations}
				disabled={!rule.isEnabled}
				isOpen={isOpen}
				fleetRuleHeaderOptions={{
					name: ruleForm.name,
					settingsMenuItems,
					placeholder: itemTranslations.ruleNamePlaceholder,
					index: index + 1,
					isRuleEnabled: rule.isEnabled
				}}
				fleetRuleHeaderHandlers={{
					handleSettingsClick,
					onNameChange
				}}
				fleetRuleRightSideOptions={{
					methodRadioValue: ruleTypeToAssignmentMethod[ruleForm.ruleType] || RadioValues.CHEAPEST,
					fallbackRadioValue: ruleForm.priorityConfiguration?.fallbackMethod ?? FallbackMethod.FAST,
					fallbackRadioOptions,
					methodRadioOptions: featureFlags.enableRatioPercentage
						? methodRadioOptionsWithRatio
						: methodRadioOptions,
					multiSelectOptions: mapFleets(fleets),
					draggableOptions,
					multiSelectValue: selectedFleets,
					rightCheckboxValue: ruleForm.priorityConfiguration?.isFallbackMethodEnabled ?? false,
					shouldShowPercentageInput: ruleForm.ruleType === RuleType.ratio,
					isDragDisabled: ruleForm.ruleType === RuleType.ratio,
					showPercentageTips: ruleForm.ruleType === RuleType.ratio,
					showZeroPercentageError: ruleForm.ruleType === RuleType.ratio,
					totalPercentage,
					invalidPercents,
					shouldShowRemoveDraggableOption: ruleForm.ruleType === RuleType.ratio,
					markInvalidPercentage,
					methodDropdownTypeOptions,
					methodDropdownTypeValue: ruleForm.earlyEtaStrategy || EarlyEtaStrategy.DROPOFF
				}}
				fleetRuleRightSideHandlers={{
					handleDragOption,
					handleMethodChange,
					handleFallbackChange,
					handleMultiselectChange,
					handlePercentageInput,
					onChangeCheckbox: onChangeFallbackCheckbox,
					handleRemoveDraggableOption,
					handleMethodDropdownChange
				}}
				fleetRuleLeftSideOptions={{
					leftCheckboxValue: ruleForm.isMerchantLevel,
					attributes: handleAttributes({ ruleForm, attributes }),
					attributesDisabled:
						handleAttributesAvailability(ruleForm.selectedTeams) ||
						!rule.isEnabled ||
						ruleForm.conditionEntity?.conditions?.length === getAttributesLength({ attributes }),
					conditions: ruleForm.conditionEntity?.conditions ?? [],
					disabledMultiSelect: ruleForm.isMerchantLevel,
					multiSelectValue: ruleForm.isMerchantLevel
						? []
						: Object.keys(ruleForm.selectedTeams).filter(teamId => !!ruleForm.selectedTeams[teamId]),
					multiSelectOptions
				}}
				fleetRuleLeftSideHandlers={{
					onChangeCheckbox,
					handleMultiselectChange: handleTeamsChange
				}}
				fleetRuleFooterOptions={{
					saveButtonDisabled: saveButtonDisabled || invalidPercents || zeroAllocation,
					discardButtonDisabled
				}}
				fleetRuleFooterHandlers={{
					onDiscard,
					onSave: saveRule
				}}
				handleChangeConditions={handleChangeConditions}
				toggleEnabled={value => onToggleRule({ value, id: ruleForm.id })}
				setHoveredParent={onDragChange}
				featureFlags={featureFlags}
			/>
		</div>
	);
};
