import React from 'react';

const ErrorDisplay: React.FunctionComponent<{ errors: string[] }> = props => {
	return (
		<div>
			{props.errors
				.filter(error => !!error)
				.map((error, index) => (
					<div key={index}>{error}</div>
				))}
		</div>
	);
};

export default ErrorDisplay;
