import { getRootEnv } from '@bringg-frontend/bringg-web-infra';
import { getEnv } from 'mobx-easy';
import _omit from 'lodash/omit';
import { action, computed, observable, makeObservable } from 'mobx';
import {
	DeliveryProviderCapabilities,
	FleetBillingData,
	FleetEnvType,
	FleetQuote,
	FleetStatus,
	FleetType,
	TaskRelatedFleet
} from '@bringg/types';
import { RootEnv } from '@bringg-frontend/bringg-web-infra';

import Task from '../tasks/domain-object/task';

class TaskRelatedFleetStore implements TaskRelatedFleet, FleetQuote {
	related_task_id: Task['id'];

	// TaskRelatedFleet
	id: number;
	name?: string;
	capabilities?: DeliveryProviderCapabilities;
	is_capable_by_delivery_term: boolean | null;
	fleet_type: FleetType;
	delivery_provider_id?: number;
	merchant_id: number;
	type?: string;
	rules?: any;
	data?: any;
	fleet_template_id?: number;
	external_id?: string;
	billing_data?: FleetBillingData;
	created_at?: string;
	updated_at?: string;
	delete_at?: string;
	env_type: FleetEnvType;
	status: FleetStatus;

	private _is_quote_data_fetched = false;
	// FleetQuote
	currency_code?: string;
	delivery_window?: string;
	delivery_window_id?: number;
	dropoff_date?: string;
	fee?: number;
	pickup_date?: string;
	quote_external_id?: number;

	constructor(fleet: TaskRelatedFleet, relatedTaskId: Task['id']) {
		makeObservable<TaskRelatedFleetStore, '_is_quote_data_fetched' | 'update'>(this, {
			name: observable,
			capabilities: observable,
			is_capable_by_delivery_term: observable,
			_is_quote_data_fetched: observable,
			currency_code: observable,
			delivery_window: observable,
			delivery_window_id: observable,
			dropoff_date: observable,
			fee: observable,
			pickup_date: observable,
			quote_external_id: observable,
			update: action,
			fetchQuoteData: action,
			is_quote_data_fetched: computed
		});

		this.update(fleet);
		this.related_task_id = relatedTaskId;
	}

	private update(fleet: Partial<TaskRelatedFleetStore | { _is_quote_data_fetched: boolean }>) {
		Object.assign(this, fleet);
	}

	async fetchQuoteData() {
		if (this._is_quote_data_fetched) {
			return;
		}

		if (this.capabilities && !this.capabilities.get_quote) {
			this.update({
				pickup_date: new Date().toISOString(),
				_is_quote_data_fetched: true
			});
			return;
		}

		const { sdk } = getRootEnv().dashboardSdk;
		try {
			const result = await sdk.fleets.askForQuote(this.related_task_id, this.id);

			this.update({
				// No need to update `fleet_type` as we already have it from TaskRelatedFleet
				// No need for `fleet_id` as there is the `id` field
				..._omit(result, ['fleet_id', 'fleet_type']),

				_is_quote_data_fetched: true
			});
		} catch (error) {
			console.error('Ask fleet quote failed', error);
			this.update({
				_is_quote_data_fetched: true
			});
		}
	}

	get is_quote_data_fetched(): boolean {
		return this._is_quote_data_fetched;
	}
}

export default TaskRelatedFleetStore;
