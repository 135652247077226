import { getRootEnv } from '@bringg-frontend/bringg-web-infra';
import { getEnv, getRoot } from 'mobx-easy';
import { TaskVehiclePair } from '@bringg/dashboard-sdk/dist/Run/Service/runs.service';
import type { Run } from '@bringg/types';
import type { RunModel, UpdateRunOptions, RunQueryOptions } from '@bringg/dashboard-sdk/dist/Run/run-types';
import { ApplicationUuid } from '@bringg/types';
import { RootEnv } from '@bringg-frontend/bringg-web-infra';

import BaseDomainStore from '../core/base-domain-store';
import RootStore from 'bringg-web/stores/root-store';

class RunStore extends BaseDomainStore<RunModel> {
	private DEFAULT_PAGE_SIZE = 200;

	afterUserLoggedIn = () => {
		getRootEnv().dashboardSdk.sdk.runs.attachSubscriptions();
	};

	afterUserLoggedOut = () => {
		const { dashboardSdk } = getRootEnv();

		dashboardSdk.sdk.runs.unsubscribe();
		dashboardSdk.sdk.runs.runStore.store.clearStore();
	};

	loadAll = async (query: RunQueryOptions = {}): Promise<RunModel[]> => {
		try {
			return await getRootEnv().dashboardSdk.sdk.runs.loadAll({
				items: this.DEFAULT_PAGE_SIZE,
				...query
			});
		} catch (e) {
			console.error(e);
			return [];
		}
	};

	load = async (id: number, force?: boolean): Promise<RunModel> => {
		return force ? getRootEnv().dashboardSdk.sdk.runs.forceLoad(id) : getRootEnv().dashboardSdk.sdk.runs.load(id);
	};

	getFromStore = (id: number): RunModel => {
		return getRootEnv().dashboardSdk.sdk.runs.runStore.store.getItem(id);
	};

	addToStore = (run: Run) => {
		getRootEnv().dashboardSdk.sdk.runs.runStore.set(run);
	};

	removeFromStore = (runId: number) => {
		getRootEnv().dashboardSdk.sdk.runs.runStore.store.removeFromStore(runId);
	};

	getAllFromStore = (): Map<number, RunModel> => {
		return getRootEnv().dashboardSdk.sdk.runs.runStore.store.getItemsMap();
	};

	update = async (id: number, options: UpdateRunOptions): Promise<Run> => {
		return getRootEnv().dashboardSdk.sdk.runs.update(id, options);
	};

	assignVehicles = async (runId: number, vehicleId: number, tasksIds: TaskVehiclePair[]) => {
		return await getRootEnv().dashboardSdk.sdk.runs.assignVehicles(runId, vehicleId, tasksIds);
	};

	unassignVehicles = async (runId: number) => {
		return await getRootEnv().dashboardSdk.sdk.runs.unassignVehicles(runId);
	};

	isPlannedRunsEnabled = () => {
		const { data } = getRoot<RootStore>();

		return (
			data.merchantConfigurationsStore.enablePlannedRoutes ||
			data.applicationStore.getApplication(ApplicationUuid.RouteOptimizer2)
		);
	};

	batchGetByIds = async (ids: number[]): Promise<RunModel[]> => {
		try {
			return await getRootEnv().dashboardSdk.sdk.runs.loadAll({ ids });
		} catch (e) {
			console.error(e);
			return [];
		}
	};
}

export default RunStore;
