import React from 'react';
import { WayPointLocation } from '../../../types/common.consts';
import { Map } from '../../../components';

interface Props {
	driverLocation: google.maps.LatLng;
	baseLocation: google.maps.LatLng;
	route: google.maps.LatLng[];
	center: google.maps.LatLng;
	wayPointsLocations: WayPointLocation[];
}

export default class RunMap extends React.Component<Props> {
	public render() {
		return (
			<div className="run-map">
				<Map
					containerElement={<div className={'map-container'} />}
					mapElement={<div className={'map-element'} />}
					base={this.props.baseLocation}
					path={this.props.route}
					center={this.props.center}
					wayPointsLocations={this.props.wayPointsLocations}
					location={this.props.driverLocation}
				/>
			</div>
		);
	}
}
