import React, { Component } from 'react';
import { Button, Select, Option, Link } from 'bringg-web/components/';
import { Col, Row } from 'antd';
import Translate from 'bringg-web/translation/translator';
import { inject, observer } from 'mobx-react';
import CrewsStore from 'bringg-web/stores/crew/crews-store';
import TeamsStore from 'bringg-web/stores/teams/teams-store';
import CrewsCard from '../crews-card/crews-card';

interface Props {
	crewsStore: CrewsStore;
	teamsStore: TeamsStore;
}

const CrewsEmptyPlaceholder = () => (
	<div className="crews-container-empty">
		<Translate text="CREWS.EMPTY" />
	</div>
);

class CrewsContainer extends Component<Props, {}> {
	async componentDidMount() {
		this.props.crewsStore.subscribe();
		await this.props.teamsStore.fetchAll();
		await this.props.crewsStore.fetchData();
	}

	componentWillUnmount() {
		this.props.crewsStore.clearStore();
		this.props.crewsStore.unsubscribe();
	}

	render() {
		const crews = this.props.crewsStore.getAll;
		const { newCrew, selectTeam, selectedTeamId, initNewCrew } = this.props.crewsStore;
		const allTeams = this.props.teamsStore.all;

		return (
			<div className="crews-container">
				<Row className="crews-header">
					<Col span={6}>
						<Select size="small" onSelect={selectTeam} value={selectedTeamId} className="team-select">
							{allTeams.map(({ id, name }) => (
								<Option key={id.toString()} value={id}>
									{name}
								</Option>
							))}
						</Select>
					</Col>
					<Col span={4} push={14}>
						<Link to="/drivers/">
							{' '}
							<Button className="drivers-btn float-right">
								<Translate text="CREWS.DRIVERS" />
							</Button>
						</Link>
						<Button className="add-crew-btn float-right" onClick={initNewCrew}>
							<Translate text="CREWS.ADD" />
						</Button>
					</Col>
				</Row>

				{newCrew && (
					<div className="new-crew">
						<span className="new-crew-title">Create New Crew</span>
						<CrewsCard crew={newCrew} />
					</div>
				)}

				{crews.length ? crews.map(crew => <CrewsCard key={crew.id} crew={crew} />) : <CrewsEmptyPlaceholder />}
			</div>
		);
	}
}

export default inject('crewsStore', 'teamsStore')(observer(CrewsContainer));
