import React, { useCallback, useEffect, useMemo, useState } from 'react';

import Modal from '@bringg/react-components/dist/components/modal/modal';
import { useTranslation } from 'react-i18next';
import Input from '@bringg/react-components/dist/components/floating-placeholder-input/input';
import { Controller, UseFormMethods } from 'react-hook-form';
import { Select } from '@bringg/react-components';
import { ParkingSpot, Skill, Vehicle, VehicleType } from '@bringg/types';
import { isNil as _isNil, find as _find } from 'lodash';

export interface VehicleForm {
	name: string;
	externalId?: string;
	licensePlate: string;
	model?: string;
	vehicleTypeId?: number;
	color?: string;
	trailerId?: string;
	skills?: string[];
	truckId?: string;
	defaultParkingSpotId: number;
}

interface Props {
	onCancel: any;
	onSubmit: any;
	form: UseFormMethods<VehicleForm>;
	vehicleTypes: VehicleType[];
	parkingSpots: ParkingSpot[];
	skills: Skill[];
	trucks: Vehicle[];
	trailers: Vehicle[];
}

const VehicleModal = ({ onCancel, onSubmit, form, vehicleTypes, parkingSpots, skills, trucks, trailers }: Props) => {
	const { t } = useTranslation();
	const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
	const { formState, control, handleSubmit, watch } = form;

	const { vehicleTypeId } = watch();

	const handleOnSubmit = useCallback(async () => {
		setIsSubmitting(true);
		await onSubmit();
		setIsSubmitting(false);
	}, [onSubmit]);

	const skillsOptions = skills.map(skill => ({ name: skill.name, id: skill.name }));

	const vehicleTypesOptions = useMemo(() => {
		return vehicleTypes.map(vehicleType => ({ name: vehicleType.title, id: vehicleType.id }));
	}, [vehicleTypes]);

	useEffect(() => {
		const defaultVehicleTypeId = vehicleTypes.find(item => item.default_for_merchant === true)?.id;
		form.setValue('vehicleTypeId', defaultVehicleTypeId, {
			shouldValidate: true
		});
	}, [vehicleTypes]);

	const parkingSpotsOptions = useMemo(() => {
		return parkingSpots.map(parkingSpot => ({ name: parkingSpot.name, id: parkingSpot.id }));
	}, [parkingSpots]);

	const trucksOptions = useMemo(() => {
		return trucks.map(truck => ({ name: truck.license_plate, id: truck.id }));
	}, [trucks]);

	const trailersOptions = useMemo(() => {
		return trailers.map(trailer => ({ name: trailer.license_plate, id: trailer.id }));
	}, [trailers]);

	const isVehicleTrailerType = useMemo((): boolean => {
		return !_isNil(vehicleTypeId) && _find(vehicleTypes, { id: vehicleTypeId }).is_trailer;
	}, [vehicleTypeId, vehicleTypes]);

	return (
		<Modal
			visible
			title={t('TEAM_VEHICLES.ADD_VEHICLE')}
			width={400}
			className="vehicle-modal"
			okText={t('GLOBAL.SAVE')}
			okButtonProps={{
				form: 'vehicle-form',
				htmlType: 'submit',
				loading: isSubmitting,
				disabled: !formState.isDirty || !formState.isValid
			}}
			cancelText={t('GLOBAL.CANCEL')}
			onCancel={onCancel}
			cancelButtonProps={{ danger: true, type: 'primary' }}
			destroyOnClose
		>
			<form id="vehicle-form" className="vehicle-form" onSubmit={handleSubmit(handleOnSubmit)}>
				<div className="required-field">
					<Controller
						as={<Input data-test-id="name-field" placeholder={t('GLOBAL.NAME')} />}
						control={control}
						rules={{ required: true }}
						name="name"
					/>
				</div>
				<Controller
					as={<Input data-test-id="external-id-field" placeholder={t('GLOBAL.EXTERNAL_ID')} />}
					control={control}
					name="externalId"
				/>
				<div className="required-field">
					<Controller
						as={
							<Select
								options={vehicleTypesOptions}
								data-test-id="vehicle-type-field"
								placeholder={t('TEAM_VEHICLES.VEHICLE_TYPE')}
							/>
						}
						control={control}
						rules={{ required: true }}
						name="vehicleTypeId"
					/>
				</div>
				<div className="required-field">
					<Controller
						as={<Input data-test-id="license-plate-field" placeholder={t('TEAM_VEHICLES.LICENSE_PLATE')} />}
						control={control}
						rules={{ required: true }}
						name="licensePlate"
					/>
				</div>
				<Controller
					as={<Input data-test-id="model-field" placeholder={t('GLOBAL.MODEL')} />}
					control={control}
					name="model"
				/>
				<Controller
					as={<Input data-test-id="color-field" placeholder={t('GLOBAL.COLOR')} />}
					control={control}
					name="color"
				/>
				{isVehicleTrailerType && (
					<Controller
						as={
							<Select
								options={trucksOptions}
								data-test-id="truck-id-field"
								placeholder={t('TEAM_VEHICLES.TRUCK_ID')}
							/>
						}
						control={control}
						name="truckId"
					/>
				)}
				{!isVehicleTrailerType && (
					<Controller
						as={
							<Select
								options={trailersOptions}
								data-test-id="trailer-id-field"
								placeholder={t('TEAM_VEHICLES.TRAILER_ID')}
							/>
						}
						control={control}
						name="trailerId"
					/>
				)}
				<Controller
					as={
						<Select
							mode="tags"
							data-test-id="skills-field"
							options={skillsOptions}
							placeholder={t('GLOBAL.SKILLS')}
						/>
					}
					control={control}
					name="skills"
				/>
				<Controller
					as={
						<Select
							options={parkingSpotsOptions}
							data-test-id="default_parking_spot_field"
							placeholder={t('VEHICLES.DEFAULT_PARKING_SPOT')}
						/>
					}
					control={control}
					name="defaultParkingSpotId"
				/>
			</form>
		</Modal>
	);
};

export default VehicleModal;
