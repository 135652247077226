import { getRootEnv } from '@bringg-frontend/bringg-web-infra';
import { action, computed, observable, makeAutoObservable } from 'mobx';
import { getEnv } from 'mobx-easy';
import i18next from 'i18next';
import _isEmpty from 'lodash/isEmpty';
import _includes from 'lodash/includes';
import _toLower from 'lodash/toLower';
import _isNil from 'lodash/isNil';
import { BringgException } from '@bringg/dashboard-sdk/dist/Core/BringgException';
import { RootEnv } from '@bringg-frontend/bringg-web-infra';

import RootStore from '../root-store';
import ActionsConfiguration, { normalizeActionsConfiguration } from './domain-objects/actions-configuration';
import {
	ActionsConfigurationLevel,
	ActionsConfiguration as ActionsConfigurationEntity
} from '../../types/common.consts';
import InventoryActionsConfiguration from './domain-objects/inventory-actions-configuration';
import TaskConfiguration from './domain-objects/task-configuration';
import ShiftActionsConfiguration from './domain-objects/shift-actions-configuration';

interface ActionsConfigurationsStore {
	getRoot: () => RootStore;
}

class ActionsConfigurationsStore {
	actionsConfigurations: Map<string, ActionsConfiguration> = new Map();
	filterText = '';
	current: ActionsConfiguration = undefined;

	constructor() {
		makeAutoObservable(this, {
			actionsConfigurations: observable.shallow,
			filterText: observable,
			current: observable,
			setCurrent: action,
			clearCurrent: action,
			setFilterText: action,
			addInventoryConfig: action,
			addUserConfig: action,
			addTaskConfig: action,
			delete: action,
			getAll: computed,
			getAllFiltered: computed,
			currentLevel: computed,
			create: action
		});
	}

	getKey = (id: number, level: ActionsConfigurationLevel): string => {
		return `${id}-${level}`;
	};

	setCurrent = (actionsConfiguration: ActionsConfiguration) => {
		this.current = actionsConfiguration;
	};

	clearCurrent = () => {
		this.current = null;
	};

	setFilterText(filterText: string) {
		this.filterText = filterText;
	}

	addInventoryConfig = (actionConfig: ActionsConfigurationEntity) => {
		const key: string = this.getKey(actionConfig.id, ActionsConfigurationLevel.INVENTORY);
		this.actionsConfigurations.set(key, new InventoryActionsConfiguration(actionConfig));
	};

	addUserConfig = (taskActionConfig: ActionsConfigurationEntity, shiftActionConfig: ActionsConfigurationEntity) => {
		const taskActionsKey: string = this.getKey(taskActionConfig.id, ActionsConfigurationLevel.USER);
		this.actionsConfigurations.set(taskActionsKey, new ActionsConfiguration(taskActionConfig));

		if (!_isEmpty(shiftActionConfig.action_data)) {
			const shiftActionsKey: string = this.getKey(taskActionConfig.id, ActionsConfigurationLevel.SHIFT);
			this.actionsConfigurations.set(shiftActionsKey, new ShiftActionsConfiguration(shiftActionConfig));
		}
	};

	addTaskConfig = (actionConfig: ActionsConfigurationEntity) => {
		const key: string = this.getKey(actionConfig.id, ActionsConfigurationLevel.ORDER);
		this.actionsConfigurations.set(key, new TaskConfiguration(actionConfig));
	};

	delete = async (id: number, level: ActionsConfigurationLevel) => {
		const key: string = this.getKey(id, level);
		const actionConfig: ActionsConfiguration = this.actionsConfigurations.get(key);

		try {
			const response = await actionConfig.delete();

			if (response.success) {
				this.actionsConfigurations.delete(key);
			}

			return response;
		} catch (e) {
			return { success: false, message: (e as BringgException).details };
		}
	};

	get getAll(): ActionsConfiguration[] {
		return Array.from(this.actionsConfigurations.values());
	}

	get getAllFiltered(): ActionsConfiguration[] {
		const actionsConfigs: ActionsConfiguration[] = this.getAll;

		return _isEmpty(this.filterText)
			? actionsConfigs
			: actionsConfigs.filter(actionsConfig =>
					_includes(_toLower(actionsConfig.title), _toLower(this.filterText))
			  );
	}

	get currentLevel(): ActionsConfigurationLevel {
		return _isNil(this.current) ? null : this.current.level;
	}

	create = async () => {
		if (_isNil(this.current)) {
			return { success: false, message: 'no current configuration' };
		}

		const { title, level, action_data: rawActionData } = this.current;
		const action_data = normalizeActionsConfiguration(rawActionData, level);
		let entityToAdd: ActionsConfiguration;
		let result;

		try {
			switch (level) {
				case ActionsConfigurationLevel.ORDER:
					result = await getRootEnv().dashboardSdk.sdk.taskConfigurations.create({
						title,
						action_data
					});
					entityToAdd = result.task_configuration;
					break;
				case ActionsConfigurationLevel.INVENTORY:
					result = await getRootEnv().dashboardSdk.sdk.inventoryActionsConfigurations.create({
						title,
						action_data
					});
					entityToAdd = result.inventory_actions_configuration;
					break;
				case ActionsConfigurationLevel.USER:
					result = await getRootEnv().dashboardSdk.sdk.userConfigurations.update({ action_data });
					entityToAdd = result;
					break;
				case ActionsConfigurationLevel.SHIFT:
					result = await getRootEnv().dashboardSdk.sdk.userConfigurations.update({
						user_state_actions: action_data
					});
					if (!_isEmpty(action_data)) {
						// This typo is intentional, this is how the server returns it. It is used like that in other repositories as well.
						entityToAdd = result.user_configration;
					}
					break;
				default:
					result = { success: false };
			}

			if (entityToAdd && result.success) {
				this.actionsConfigurations.set(
					this.getKey(entityToAdd.id, level),
					Object.assign(entityToAdd, { level })
				);
			}

			return result;
		} catch (e) {
			return { success: false, message: (e as BringgException).details };
		}
	};

	fetchAll = async () => {
		const promises = [
			getRootEnv().dashboardSdk.sdk.inventoryActionsConfigurations.getAll(),
			getRootEnv().dashboardSdk.sdk.taskConfigurations.getAllActionData(),
			getRootEnv().dashboardSdk.sdk.userConfigurations.getActionData()
		];

		const [inventoryResult, taskResult, userResult] = await Promise.all(promises);

		inventoryResult.inventory_actions_configurations.map(config => this.addInventoryConfig(config));
		taskResult.task_configurations.map(config => this.addTaskConfig(config));
		const userConfig = userResult.user_configuration || { action_data: [], user_state_actions: [] };
		this.addUserConfig(
			{
				...userConfig,
				title: i18next.t('ACTION_CONFIGURATION.DEFAULT_USER_TITLE')
			},
			{
				...userConfig,
				action_data: userConfig.user_state_actions,
				title: i18next.t('ACTION_CONFIGURATION.DEFAULT_SHIFT_TITLE'),
				level: ActionsConfigurationLevel.SHIFT
			}
		);

		this.clearCurrent();
	};
}

export default ActionsConfigurationsStore;
