import i18next from 'i18next';
import {
	ArrayType,
	FactType,
	NumericOperator,
	RuleCompareOperator,
	StringOperator,
	TriggerParamsValueType
} from '@bringg/types';
import { StringTriggerValues } from '../../utils/types';
import { ContentArrayType } from './workflow-content-generator';
import { multipleChoice } from '../use-automation-translations';

export const addPath = (title): ContentArrayType => ({
	title,
	isPath: true
});

export const MAX_DISPLAY_VALUE = 4;

export const fieldValuesMapper = (value: number[], options = [], isFull): ContentArrayType => {
	if (!value) return { title: '' };

	const triggerValue = (value as number[]).map(
		val => options.find(option => option.key === val || option.key === Number(val))?.value
	);
	let valuesString;

	const { length } = triggerValue;
	const summary = `${i18next.t(multipleChoice)} (${length})`;
	switch (true) {
		case length === 0:
			return { title: '' };

		case length === 1:
			return { title: triggerValue[0] };

		case length > 1 && length <= MAX_DISPLAY_VALUE:
			return {
				title: isFull ? triggerValue.join(', ') : summary,
				tooltipContent: !isFull ? triggerValue.join(', ') : null,
				isPale: !isFull
			};
		default:
			valuesString = triggerValue.join(', ');
			return {
				title: summary,
				tooltipContent: `${valuesString}`,
				isPale: true
			};
	}
};

export const stringValueMapper = (
	triggerValue: StringTriggerValues,
	linkingKey: ContentArrayType
): ContentArrayType[] => {
	const content = [];

	triggerValue.forEach(({ value, operator }, index, arr) => {
		if (index < arr.length) {
			content.push(linkingKey);
		}

		content.push({
			title: `${getStringOperatorTranslations(operator)} ${value}`
		});
	});

	return content;
};

export const getChangedTranslations = (changed): string => {
	switch (changed) {
		case TriggerParamsValueType.ChangedTo:
			return i18next.t('PROGRAMMABLE_TRIGGERS.CHANGED_TO');
		case TriggerParamsValueType.ChangedBy:
			return i18next.t('PROGRAMMABLE_TRIGGERS.CHANGED_BY');
		case TriggerParamsValueType.Added:
			return i18next.t('PROGRAMMABLE_TRIGGERS.ADDED');
		case TriggerParamsValueType.Removed:
			return i18next.t('PROGRAMMABLE_TRIGGERS.REMOVED');
		case TriggerParamsValueType.Changed:
		default:
			return i18next.t('PROGRAMMABLE_TRIGGERS.CHANGED');
	}
};

export const getStringOperatorTranslations = (operator: RuleCompareOperator | StringOperator) =>
	i18next.t(`CONDITIONS.STRING.${operator}`);

export const getNumericOperatorTranslation = (operator: NumericOperator | RuleCompareOperator): string =>
	i18next.t(`CONDITIONS.NUMERIC.${operator}`);

export const getArrayOperatorTranslation = (operator: RuleCompareOperator | ArrayType): string =>
	i18next.t(`CONDITIONS.ARRAY.${operator}`);

export const getEntityTranslation = (factType: FactType) =>
	i18next.t(`RULE_ENGINE.FACTS.${factType?.toUpperCase()}.TITLE`).toLowerCase();

export const getIndefiniteByFactType = (factType: FactType) =>
	i18next.t(`RULE_ENGINE.FACTS.${factType?.toUpperCase()}.INDEFINITE`, '');
