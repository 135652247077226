import { computed, toJS, makeObservable } from 'mobx';
import BaseViewModelDomainObject from '../../base-view-model-domain-object';
import DeliveryCatalogStore from '../delivery-catalog-store';

const DELIVERY_PROVIDERS_ENTITY = 'deliveryProviders';

class DeliveryProvider extends BaseViewModelDomainObject<DeliveryProvider> {
	id: number = null;
	name: string = null;
	favorite = false;
	setup_configuration: {
		fields: Record<string, unknown>;
	} = null;
	absolute_logo_path: string;
	description: string;
	connected: boolean;

	constructor(store: DeliveryCatalogStore, deliveryProvider?: Partial<Bringg.DeliveryProvider>) {
		super(store, DELIVERY_PROVIDERS_ENTITY);

		makeObservable(this, {
			asJson: computed
		});

		this.set(deliveryProvider);
	}

	set(deliveryProvider: Partial<DeliveryProvider> | Partial<Bringg.DeliveryProvider>) {
		super.set(deliveryProvider);
	}

	get asJson(): Partial<DeliveryProvider> {
		return {
			...toJS(this)
		};
	}
}

export default DeliveryProvider;
