import {
	MetadataValue,
	AttributeDatatype,
	TriggerMetadataProperties,
	FactType,
	CreateWorkflowResponse,
	NumericOperator,
	TimeTriggerUnitOfTime,
	SingleGenericActionMetadata,
	ArrayType,
	UpdateFieldMetadata,
	ConsoleAction,
	WebhookAction,
	SMSAction,
	EmailAction,
	ActionType,
	SingleUpdateEntityConfiguration,
	AlertSeverity,
	DisplayFact,
	RecurringTriggerParams,
	TriggerParamsValueType,
	OptimizationAction,
	UserRole,
	ActionCoreData,
	StringOperator,
	CancelTaskAction,
	CalculateAvailabilityAction,
	ShareLocationData,
	EmailSharedLocationData,
	NarrowActionableWpTimeWindowData,
	CustomWebhookData,
	EventTriggerTasksManualParams
} from '@bringg/types';
import { ConditionType } from '@bringg/react-components';

import { ConditionStore, PlaceholderCondition, ArrayConditionStore } from '../stores/internal';

export type Workflow = {
	guid?: number;
	id?: number;
} & Omit<CreateWorkflowResponse, 'id'>;

export type ChangeOptions = {
	id: TriggerParamsValueType | string;
	name: string;
	dropDownKey: string;
}[];

export enum Relative {
	before = -1,
	after = 1
}

export const triggerChangeNoValuesOptions = new Set([
	TriggerParamsValueType.Changed,
	TriggerParamsValueType.Added,
	TriggerParamsValueType.Removed
]);

export enum TriggerContainer {
	Field_Changed = 'Field_Changed',
	Date_Arrived = 'Date_Arrived',
	Event_Happened = 'Event_Happened',
	Recurring = 'Recurring',
	Manual = 'Manual'
}

export type TriggerValues = (number | string)[];
type BooleanTriggerValue = boolean;
type NumberTriggerValue = number;
type StringTriggerValue = string;

export interface StringTriggerValueOperator {
	value: string;
	operator: StringOperator;
}

export type StringTriggerValues = StringTriggerValueOperator[];

export type TriggerValue =
	| TriggerValues
	| BooleanTriggerValue
	| NumberTriggerValue
	| StringTriggerValue
	| StringTriggerValues
	| RecurringTriggerParams
	| EventTriggerTasksManualParams['value'];

export type MappedTriggerData = {
	id: string;
	family: TriggerContainer;
	displayFamily: string;
	display_path: string;
	title: string;
	items?: MappedTriggerData[];
	values?: MetadataValue[];
	properties?: TriggerMetadataProperties;
	linking_key?: string;
	datatype?: AttributeDatatype;
	factType: FactType;
	display_fact: DisplayFact;
	actions: ActionType[];
};

export type ComparableConditionValue = {
	path: string;
	fact_type: FactType;
};

export type ArrayConditionValueInnerValue = string[] | number | number[] | boolean[];

export type ArrayConditionValue = {
	value: ArrayConditionValueInnerValue;
	type: ArrayType;
};
export type ConditionValues = TriggerValue | ComparableConditionValue | ArrayConditionValue;

export type MappedAttribute = {
	type?: ConditionType;
	title: string;
	disabled?: boolean;
	options?: { key: string | number | boolean; value: string }[];
	items?: MappedAttribute[];
	changeable?: boolean;
	path: string;
	display_path: string;
	identifier: string;
	disable_sorting?: boolean;
	factType: FactType;
	container?: string;
};

export type MappedTrigger = {
	id: string;
	changedTo: TriggerParamsValueType;
	units: TimeTriggerUnitOfTime;
	relative: Relative;
	operator: NumericOperator;
	value: TriggerValue;
};

export type AtomicCondition = ConditionStore | PlaceholderCondition | ArrayConditionStore;

export type UpdateFieldActionData = UpdateFieldMetadata & ActionDataType;

export type GenericActionData = SingleGenericActionMetadata & ActionDataType;

type ActionDataType = {
	display_path: string;
	items?: [];
	disabled?: boolean;
};

export enum AutomationsTab {
	AUTOMATION = 'automation',
	TEMPLATES = 'templates',
	SYSTEM_WORKFLOWS = 'legacy-alerts'
}

export enum ActionFamilyType {
	NOTIFY = 'alert',
	UPDATE_ENTITY = 'updateEntity',
	GENERIC_ACTION = 'genericAction',
	COSTUMER_NOTIFICATION = 'sharedLocation',
	EMAIL_COSTUMER_NOTIFICATION = 'emailSharedLocation',
	OPTIMIZATION = 'optimization',
	CANCEL_TASK = 'cancelTask',
	CALCULATE_AVAILABILITY_STATE = 'calculateAvailabilityState',
	NARROW_ACTIONABLE_WP_TIME_WINDOW = 'narrowActionableWpTimeWindow',
	CUSTOM_WEBHOOK = 'customWebhook'
}

export type ClientAlertData = (ConsoleAction | WebhookAction | SMSAction | EmailAction) & {
	guid: number;
};

export type ClientOptimizationAction = { type: ActionFamilyType.OPTIMIZATION } & OptimizationAction;
export type ClientUpdateEntityAction = {
	type: ActionFamilyType.UPDATE_ENTITY;
	actions: SingleUpdateEntityConfiguration[];
};
export type ClientAlertAction = {
	type: ActionFamilyType.NOTIFY;
	title?: string;
	severity?: AlertSeverity;
	description_rich_text?: string;
	actions: {
		guid: number;
		action_type: ActionType;
		data: {
			role?: UserRole;
			isRoleBased?: boolean;
			userIds?: number[];
			title?: string;
		};
	}[];
};

export type ClientShareLocationAction = {
	type: ActionFamilyType.COSTUMER_NOTIFICATION;
	actionType?: ActionType;
	sharingMethod?: ShareLocationData['sharing_method'];
	translation?: ShareLocationData['sms']['translation'];
	privateMode?: ShareLocationData['private_mode'];
};

export type ClientEmailSharedLocationAction = {
	type: ActionFamilyType.EMAIL_COSTUMER_NOTIFICATION;
	actionType?: ActionType;
	template_id?: EmailSharedLocationData['template_id'];
};

export type ClientNarrowActionableWpTimeWindowAction = {
	type: ActionFamilyType.NARROW_ACTIONABLE_WP_TIME_WINDOW;
	action_type?: ActionType;
	bufferInMinutes?: NarrowActionableWpTimeWindowData['buffer_in_minutes'];
};

export type ClientGenericAction = {
	type: ActionFamilyType.GENERIC_ACTION;
	actions: ActionCoreData[];
};

export type ClientCancelTaskAction = {
	type: ActionFamilyType.CANCEL_TASK;
} & CancelTaskAction;

export type ClientCalculateAvailability = {
	type: ActionFamilyType.CALCULATE_AVAILABILITY_STATE;
	action_type: ActionType;
} & CalculateAvailabilityAction;

export type ClientCustomWebhook = {
	type: ActionFamilyType.CUSTOM_WEBHOOK;
} & { action_type: ActionType; data: CustomWebhookData };

export type ClientAction =
	| ClientOptimizationAction
	| ClientUpdateEntityAction
	| ClientAlertAction
	| ClientShareLocationAction
	| ClientGenericAction
	| ClientCancelTaskAction
	| ClientCalculateAvailability
	| ClientNarrowActionableWpTimeWindowAction
	| ClientEmailSharedLocationAction
	| ClientCustomWebhook;
