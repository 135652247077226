import React from 'react';

import { RouteComponentProps } from 'react-router';

import { Portal } from 'bringg-web/components';
import EmployeeList from './employee-list';

const EmployeeListPortal = React.memo<RouteComponentProps>(
	function EmployeeListPortal(props) {
		// Angular add this query param, it only matter for us for the first render
		// as we override it by mistake
		const search = new URLSearchParams(props.location.search);
		const cameFromEmployeeInFocus = search.has('employee');

		return (
			<Portal
				element={<EmployeeList cameFromEmployeeInFocus={cameFromEmployeeInFocus} />}
				nodeId="employee_list_container"
			/>
		);
	},
	(prev, next) => {
		// Don't rerender on search params changes as this would cause the filtering to flicker
		return prev.location.pathname === next.location.pathname;
	}
);

export default EmployeeListPortal;
