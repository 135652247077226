import { getRootEnv } from '@bringg-frontend/bringg-web-infra';
import React, { useCallback, useEffect, useState } from 'react';

import { Button, Col, ConfirmModal, IndexedLabel, Row, Select } from '@bringg/react-components';
// eslint-disable-next-line no-restricted-imports
import { Input, Form, Divider } from 'antd';
import { omit } from 'lodash';
import { getEnv } from 'mobx-easy';
import { ApplicationUuid } from '@bringg/types';
import CopyToClipboard from '@bringg/react-components/dist/components/copy-to-clipboard/copy-to-clipboard';
import { BringgFontIcons, BringgIcon } from '@bringg/bringg-icons';
import { useTranslation } from 'react-i18next';
import { RootEnv } from '@bringg-frontend/bringg-web-infra';

import { filterOption } from 'bringg-web/services/utils';
import { useStores } from '../../recipes';
import { getHostFromEmail } from '../../utils/email-utils';
import { SSO_CONFIGURATION_PAGE } from '../../consts';
import { extractPrivilegeId } from '../../utils/privileges';
import { SamlUrlInput } from './saml-url-input';

import './styles.scss';

const requiredFields = ['idp_sso_target_url', 'idp_entity_id', 'idp_cert_fingerprint', 'privilege_id'];

const SSOConfiguration = () => {
	const { t } = useTranslation();
	const [isLoading, setIsLoading] = useState(true);
	const [isSaving, setIsSaving] = useState(false);

	const [privileges, setPrivileges] = useState([]);
	const [samlConfigurationExists, setSamlConfigurationExists] = useState<boolean>(false);
	const [ssoUrl, setSSOUrl] = useState<string>();
	const [audienceUrl, setAudienceUrl] = useState<string>();
	const [validateUrl, setValidateUrl] = useState<string>();

	const [formTouched, setFormTouched] = useState<boolean>(false);

	const [form] = Form.useForm();

	const { envConfig, dashboardSdk } = getRootEnv();
	const { user } = dashboardSdk.sdk.session;

	const {
		usersStore: { currentUser },
		merchantStore,
		applicationMerchantConfigurationStore: { fetchOrCreate, updateConfiguration }
	} = useStores();

	useEffect(() => {
		const fetchPrivileges = async () => {
			try {
				setIsLoading(true);
				const response = await getRootEnv().dashboardSdk.sdk.privileges.getAll();

				setPrivileges(response);
			} finally {
				setIsLoading(false);
			}
		};

		const fetchExistingSAMLConfiguration = async () => {
			try {
				const { data: configuration } = await getRootEnv()
					.dashboardSdk.sdk.applicationMerchantConfiguration.getByAppUuid(ApplicationUuid.SingleSignOn)
					.catch(() => ({ data: undefined }));

				if (configuration) {
					const existingSAMLConfiguration = {
						idp_sso_target_url: configuration.idp_settings.idp_sso_target_url,
						idp_entity_id: configuration.idp_settings.idp_entity_id,
						idp_cert_fingerprint: configuration.idp_settings.idp_cert_fingerprint,
						idp_slo_target_url: configuration.idp_settings.idp_slo_target_url,
						privilege_id: parseInt(extractPrivilegeId(configuration.idp_values_map.privilege_id), 10)
					};

					setSamlConfigurationExists(true);
					form.setFieldsValue(existingSAMLConfiguration);
				}
			} finally {
				setIsLoading(false);
			}
		};

		fetchPrivileges();
		fetchExistingSAMLConfiguration();
		merchantStore.fetch();
	}, []);

	useEffect(() => {
		if (!merchantStore.merchant?.uuid) {
			return;
		}

		setSSOUrl(
			`${envConfig.regions[user.region].web_api_url}/users/saml/auth?merchant_uuid=${merchantStore.merchant.uuid}`
		);
		setAudienceUrl(
			`${envConfig.regions[user.region].web_api_url}/users/saml/metadata?merchant_uuid=${
				merchantStore.merchant.uuid
			}`
		);
		setValidateUrl(
			`${envConfig.regions[user.region].web_api_url}/users/saml/sign_in?merchant_uuid=${
				merchantStore.merchant.uuid
			}`
		);
	}, [merchantStore.merchant, envConfig]);

	const onSubmit = useCallback(
		async data => {
			const defaultIdpValuesMap = (userData, formData) => ({
				team_external_ids: '{{teams | s_join}}',
				email: `{{email| s_default_email: '${getHostFromEmail(userData.email)}'}}`,
				name: '{{first_name}} {{last_name}}',
				privilege_id: `{{privilege_id | s_default: '${formData.privilege_id}'}}`,
				dispatcher: "{{dispatcher | s_default: 'true'}}",
				admin: "{{admin | s_default: 'false'}}"
			});
			const defaultSamlSettings = () => ({
				saml_create_user: true,
				saml_default_user_key: 'email',
				saml_update_user: false,
				saml_use_subject: true
			});

			try {
				setIsSaving(true);

				const { configuration } = await fetchOrCreate(ApplicationUuid.SingleSignOn);
				const response = await updateConfiguration(ApplicationUuid.SingleSignOn, {
					idp_values_map: {
						...(configuration?.idp_values_map ?? defaultIdpValuesMap(currentUser, data)),
						privilege_id: `{{privilege_id | s_default: '${data.privilege_id}'}}`
					},
					idp_settings: {
						...configuration?.idp_settings,
						...omit(data, 'privilege_id'),
						name_identifier_format: 'urn:oasis:names:tc:SAML:1.1:nameid-format:emailAddress',
						issuer: audienceUrl
					},
					saml_settings: configuration?.saml_settings ?? defaultSamlSettings()
				});

				if (response) {
					setSamlConfigurationExists(true);
					setFormTouched(false);
				}
			} finally {
				setIsSaving(false);
			}
		},
		[audienceUrl, currentUser, fetchOrCreate, updateConfiguration]
	);

	const disconnectSSO = async () => {
		try {
			setIsLoading(true);

			await getRootEnv().dashboardSdk.sdk.applicationMerchantConfiguration.removeApplicationByUuid(
				ApplicationUuid.SingleSignOn
			);
			form.resetFields();
			setFormTouched(false);
			setSamlConfigurationExists(false);
		} finally {
			setIsLoading(false);
		}
	};

	const clearForm = useCallback(() => {
		ConfirmModal({
			title: t('SSO_CONFIGURATION.CLEAR_ALL_FIELDS'),
			content: t('SSO_CONFIGURATION.CLEAR_ALL_FIELD_MODAL_BODY'),
			onOk: () => form.resetFields()
		});
	}, [form, t]);

	const removeAlertConfirmation = useCallback(() => {
		ConfirmModal({
			content: t('SSO_CONFIGURATION.DELETE_SSO_CONFIGURATION_MODAL_BODY'),
			onOk: disconnectSSO
		});
	}, [disconnectSSO, t]);

	return (
		<div className="sso-configuration-form">
			<Row>
				<Col span={10} className="sso-configuration-container">
					<div className="form-header">
						<h3>{t('SSO_CONFIGURATION.SSO_HEADER')}</h3>
					</div>

					<div className="sub-header">{t('SSO_CONFIGURATION.SSO_SUBHEADER')}</div>

					<a href={SSO_CONFIGURATION_PAGE.learnMorePage} target="_blank" rel="noopener noreferrer">
						{t('SSO_CONFIGURATION.SSO_FIRST_STEP_HELP')}
						<BringgIcon className="external-link" iconName={BringgFontIcons.ExternalLink} />
					</a>

					<div className="steps">
						<div className="first-step">
							<IndexedLabel index={1} />
							<div className="step-header">{t('SSO_CONFIGURATION.SSO_FIRST_STEP')}</div>
						</div>
						<div className="second-step">
							<IndexedLabel index={2} />
							<div className="step-header">{t('SSO_CONFIGURATION.SSO_SECOND_STEP')}</div>
						</div>
					</div>

					<div>
						<Button type="link" className="light-button float-right" onClick={clearForm}>
							{t('SSO_CONFIGURATION.CLEAR_ALL_FIELDS')}
						</Button>
					</div>

					<Form
						colon={false}
						form={form}
						layout="vertical"
						onFinish={onSubmit}
						onValuesChange={() => {
							const requiredFieldsTouched = form.isFieldsTouched(requiredFields, true);

							if (requiredFieldsTouched) {
								form.validateFields()
									.then(() => setFormTouched(true))
									.catch(err => {
										setFormTouched(err.errorFields.length === 0);
									});
							} else {
								setFormTouched(false);
							}
						}}
					>
						<div className="form-group">
							<SamlUrlInput
								label="IdP Single Sign-On (SSO) Target URL"
								name="idp_sso_target_url"
								data-test-id="idp_sso_target_url"
								placeholder="Ex. https://dev-01779332.okta.com"
								rules={[
									{
										required: true
									}
								]}
							/>

							<Form.Item
								label="IdP Entity ID"
								name="idp_entity_id"
								data-test-id="idp_entity_id"
								rules={[{ required: true }]}
							>
								<Input placeholder="Ex. https://www.okta.com/exk7p2ya8iiY5Hkw95d8" />
							</Form.Item>

							<Form.Item
								label="IDP Cert Fingerprint"
								name="idp_cert_fingerprint"
								data-test-id="idp_cert_fingerprint"
								rules={[
									{
										required: true,
										pattern: /^((\d|\w){2}:*){19,32}(\d|\w){2}/gi,
										message: t('SSO_CONFIGURATION.INVALID_FINGERPRINT')
									}
								]}
							>
								<Input placeholder="Ex. F2:18:CA:..." />
							</Form.Item>

							<SamlUrlInput
								label="Single Logout (SLO) Target URL"
								name="idp_slo_target_url"
								data-test-id="idp_slo_target_url"
								placeholder="Ex. http://www.okta.com/131412dwd"
							/>

							<Form.Item
								label="Privilege"
								name="privilege_id"
								data-test-id="privilege_id"
								rules={[{ required: true }]}
								tooltip={t('SSO_CONFIGURATION.PRIVILEGE_INFO')}
							>
								<Select
									showSearch
									placeholder={t('SSO_CONFIGURATION.SELECT_PRIVILEGE')}
									filterOption={filterOption}
									options={privileges.map(privilege => ({
										...privilege,
										label: privilege.name,
										render: (
											<div>
												{privilege.name} (ID: <b>{privilege.id}</b>)
											</div>
										)
									}))}
								/>
							</Form.Item>
						</div>

						<Button
							className="delete-configuration-button"
							type="link"
							disabled={isLoading || !samlConfigurationExists}
							onClick={removeAlertConfirmation}
						>
							{t('SSO_CONFIGURATION.DELETE')}
						</Button>
						<div className="float-right">
							<Button
								key="save"
								className="save"
								type="primary"
								loading={isSaving}
								disabled={!formTouched}
								onClick={form.submit}
							>
								{t(isSaving ? 'SSO_CONFIGURATION.SAVING' : 'SSO_CONFIGURATION.SAVE')}
							</Button>
						</div>
					</Form>
				</Col>

				<Col span={1} />
				<Col span={1}>
					<Divider className="panels-divider" type="vertical" />
				</Col>

				<Col span={12} className="related-links-container">
					<div className="form-header">
						<h3>{t('SSO_CONFIGURATION.RELATED_LINKS')}</h3>
					</div>
					<div className="related-links">
						<div>{t('SSO_CONFIGURATION.COPY_LINKS')}</div>
						<CopyToClipboard showText={false} text={ssoUrl}>
							<Button type="link">{t('SSO_CONFIGURATION.COPY_SSO_URL')}</Button>
						</CopyToClipboard>
						<CopyToClipboard showText={false} text={audienceUrl}>
							<Button type="link">{t('SSO_CONFIGURATION.COPY_AUDIENCE_URL')}</Button>
						</CopyToClipboard>
					</div>

					<div className="convert-cert-to-fingerprint">
						<div>{t('SSO_CONFIGURATION.CONVERT_CERT_TO_FINGERPRINGT')}</div>
						<a href="https://www.samltool.com/fingerprint.php" target="_blank" rel="noopener noreferrer">
							SHA 256 <BringgIcon className="external-link" iconName={BringgFontIcons.ExternalLink} />
						</a>
					</div>

					<div>
						<div className="form-header">
							<h3>{t('SSO_CONFIGURATION.SETUP_VALIDATION')}</h3>
						</div>
						<CopyToClipboard showText={false} text={validateUrl}>
							<Button type="link">{t('SSO_CONFIGURATION.COPY_URL')}</Button>{' '}
						</CopyToClipboard>
						{t('SSO_CONFIGURATION.AND_PASTE_IT')}
					</div>
				</Col>
			</Row>
		</div>
	);
};

export default SSOConfiguration;
