import React from 'react';

import { Button, DropdownMenu } from '@bringg/react-components';
import { BringgFontIcons, BringgIcon } from '@bringg/bringg-icons';

import useAutomationTranslations from '../../../../utils/use-automation-translations';

import styles from './extra-actions.module.scss';

interface Props {
	duplicate: () => any;
	delete: () => any;
	['data-test-id']?: string;
}

const ExtraActions = ({ duplicate, delete: deleteFn, 'data-test-id': dataTestId = 'workflow-extra-action' }: Props) => {
	const { duplicate: duplicateText, delete: deleteText } = useAutomationTranslations();

	return (
		<DropdownMenu
			data-test-id={dataTestId}
			items={[
				{
					key: `duplicate`,
					className: styles.menuItem,
					label: (
						<span data-test-id={`${dataTestId}-duplicate`}>
							<BringgIcon iconName={BringgFontIcons.Copy} className={styles.icon} />
							{duplicateText}
						</span>
					),
					onClick: duplicate
				},
				{
					key: 'delete',
					className: styles.menuItem,
					label: (
						<span data-test-id={`${dataTestId}-delete`}>
							<BringgIcon iconName={BringgFontIcons.Trash} className={styles.icon} />
							{deleteText}
						</span>
					),
					onClick: deleteFn
				}
			]}
			trigger={['click']}
			placement="bottom-end"
			// Must destroy on hide as if not, after duplicating, the menu button of the workflow that duplicate from will be shown next time at his previous position
			// and not where the card was moved to
			destroyOnHide
		>
			<Button
				aria-label="actions"
				shape="circle"
				className="actions-button"
				type="link"
				icon={<BringgIcon iconName={BringgFontIcons.MenuHorizontal} />}
				data-test-id="actions-button"
			/>
		</DropdownMenu>
	);
};

export default ExtraActions;
