// Doing this like that so if this file get imported BEFORE the translation initialized we would have the translations
import { ActionType } from '@bringg/types';
import i18next from 'i18next';

export const notifyActionOptions = {
	get [ActionType.ALERT]() {
		return i18next.t('PROGRAMMABLE_ACTIONS.ACTIONS.BRINGG_PLATFORM');
	},
	get [ActionType.INTERNAL_SMS]() {
		return i18next.t('PROGRAMMABLE_ACTIONS.ACTIONS.SMS');
	},
	get [ActionType.EMAIL]() {
		return i18next.t('PROGRAMMABLE_ACTIONS.ACTIONS.EMAIL');
	},
	get [ActionType.WEBHOOK]() {
		return i18next.t('PROGRAMMABLE_ACTIONS.ACTIONS.WEBHOOK');
	}
};
