import React, { ReactElement } from 'react';

import { getRootEnv } from '@bringg-frontend/bringg-web-infra';
import { ConfigurationAudit } from '@bringg/types/types/configuration_audit';
import type { GetConfigurationsPayload } from '@bringg/dashboard-sdk/dist/Reports/Service/reports.service';

import ChangesDiffPopup from './changes-diff-popup';
import { formatTime } from './utils';

export interface ConfigurationAuditRow {
	key: number;
	changeTime: string;
	configurationModel: string;
	configurationId: number;
	changedFields: string[];
	changedBy: string;
	changes: ReactElement;
}

const QUERY_LIMIT = 1000;
const defaultRequestParams = {
	limit: QUERY_LIMIT,
	offset: 0,
	sort: 'desc'
} as GetConfigurationsPayload;

function getReportsService() {
	return getRootEnv().dashboardSdk.sdk.reports;
}
const remapedResponse = (row: ConfigurationAudit, index: number): ConfigurationAuditRow => {
	return { ...row, key: index, changeTime: formatTime(row.changeTime), changes: <ChangesDiffPopup row={row} /> };
};

const getConfigurationsAudit = async (queryParams = {}): Promise<ConfigurationAuditRow[]> => {
	try {
		const response = await getReportsService().getConfigurations({ ...defaultRequestParams, ...queryParams });

		return response.map((e, index) => remapedResponse(e, index));
	} catch (e) {
		console.error('Failed to retrieve Configuration Audits', e);

		return [];
	}
};

export default getConfigurationsAudit;
