import React, { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { BringgInput } from '@bringg/react-components';
import { observer } from 'mobx-react';
import type { Task, Task as TaskType } from '@bringg/types';
import { BringgFontIcons, BringgIcon } from '@bringg/bringg-icons';

import TaskDetails from './task-details';
import FleetAssignerTable from './fleet-assigner-table';
import FleetAssignerTaskTable from './fleet-assigner-task-table';
import TaskRelatedFleetStore from '../../stores/task-related-fleet/task-related-fleet-store';
import { timezoneProvider } from 'bringg-web/services/timezone/timezone-provider';

import './fleet-assigner-modal-body.scss';

export interface FleetAssignerModalBodyProps {
	task?: Task;
	gridSelectedTasks?: TaskType[];
	relatedFleets: TaskRelatedFleetStore[];
	selectedFleetId: TaskRelatedFleetStore['id'] | null;
	setSelectedFleetId: (newSelectedFleetId: TaskRelatedFleetStore['id'] | null) => void;
}

const FleetAssignerModalBody = ({
	relatedFleets,
	task,
	gridSelectedTasks,
	selectedFleetId,
	setSelectedFleetId
}: FleetAssignerModalBodyProps) => {
	const { t } = useTranslation();
	const [search, setSearch] = useState('');
	const [filteredFleets, setFilteredFleets] = useState<TaskRelatedFleetStore[]>([]);
	const shouldRenderTaskTable = gridSelectedTasks && gridSelectedTasks.length;

	useEffect(() => {
		if (!relatedFleets) {
			return;
		}

		if (!search) {
			setFilteredFleets(relatedFleets);
			return;
		}

		const query = search.toLowerCase();
		const foundFleets = relatedFleets.filter(fleet => fleet.name?.toLowerCase().includes(query));

		setFilteredFleets(foundFleets);
	}, [relatedFleets, search]);

	return (
		<div className="delivery-provider-assigner">
			<div className="delivery-provider-assigner-description">
				{shouldRenderTaskTable ? t('ASSIGN_FLEET.DESCRIPTION_MULTIPLE') : t('ASSIGN_FLEET.DESCRIPTION')}
			</div>
			{shouldRenderTaskTable ? (
				<FleetAssignerTaskTable gridSelectedTasks={gridSelectedTasks} />
			) : (
				task && <TaskDetails task={task} />
			)}

			<BringgInput
				className="search-row-input"
				placeholder={t('ASSIGN_FLEET.SEARCH_PLACEHOLDER')}
				value={search}
				onChange={e => setSearch(e.target.value)}
				suffix={<BringgIcon iconName={BringgFontIcons.Search} />}
			/>

			<FleetAssignerTable
				fleets={filteredFleets}
				selectedFleetId={selectedFleetId}
				setSelectedFleetId={setSelectedFleetId}
				timezone={timezoneProvider.getTimezoneByTask(task)}
				gridSelectedTasks={gridSelectedTasks}
			/>
		</div>
	);
};

export default observer(FleetAssignerModalBody);
