import React, { memo, MouseEventHandler } from 'react';
import classNames from 'classnames';
import { BringgFontIcons, BringgIcon } from '@bringg/bringg-icons';

import './icon-button.scss';

interface Props {
	icon: BringgFontIcons;
	text?: string;
	className?: string;
	onClick?: MouseEventHandler<HTMLButtonElement>;
	disabled?: boolean;
	title?: string;
}

const IconButton = ({ className, icon, text, disabled, ...rest }: Props) => (
	<button type="button" className={classNames('icon-button', className)} {...rest} disabled={disabled}>
		<BringgIcon iconName={icon} />
		{text}
	</button>
);

export default memo(IconButton) as typeof IconButton;
