import { useTranslation } from 'react-i18next';

export const useCsvTranslations = (): any => {
	const { t } = useTranslation();

	return {
		description: t('TEAM.ZIP_CODES_UPLOAD.DESCRIPTION'),
		totalRowsText: t('TEAM.ZIP_CODES_UPLOAD.TOTAL_ROWS'),
		columnsSelectedText: t('TEAM.ZIP_CODES_UPLOAD.COLUMNS_SELECTED'),
		uploadText: t('GLOBAL.UPLOAD'),
		finishText: t('GLOBAL.COMPLETE'),
		linesWithErrorsText: t('TEAM.ZIP_CODES_UPLOAD.LINES_WITH_ERRORS'),
		linesUploadedText: t('TEAM.ZIP_CODES_UPLOAD.LINES_UPLOADED'),
		linesImportedText: t('TEAM.ZIP_CODES_UPLOAD.LINES_IMPORTED')
	};
};
