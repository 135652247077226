import { getRoot, wrapRoot } from 'mobx-easy';

import TemplatesRootStore from '../templates-root-store';

export const TEMPLATES_WRAPPER = 'templates';

const getStore = () => getRoot<TemplatesRootStore>(TEMPLATES_WRAPPER);

const createRoot = () => {
	try {
		return getRoot<TemplatesRootStore>(TEMPLATES_WRAPPER);
	} catch (error) {
		return wrapRoot({ wrapperName: TEMPLATES_WRAPPER, env: {}, RootStore: TemplatesRootStore });
	}
};

export const TemplatesRoot = { createRoot, getStore };
