import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { State } from './types';
import { useStores } from '../../recipes';
import notification from '../../services/notification';

const useConditionsSetStore = fleetId => {
	const { conditionsSetStore } = useStores();
	const [state, setState] = useState<State>(State.Idle);
	const { t } = useTranslation();

	useEffect(() => {
		const init = async () => {
			setState(State.Loading);
			try {
				await conditionsSetStore.load(fleetId);
				setState(State.Done);
			} catch (err) {
				notification.error(t('CONDITIONS.FAILED_LOADING_CONDITIONS'));
				setState(State.Failed);
			}
		};

		init();
	}, [fleetId]);

	return { conditionsSetStore, state };
};

export default useConditionsSetStore;
