import { WorkflowResponse } from '@bringg/types';
import { workflowsRootStore } from '../stores/internal';
import WorkflowStore from '../stores/workflow-store';

function map(workflow: WorkflowResponse, params: any) {
	const { workflowRepo } = workflowsRootStore.getStore();

	// the patch api function doesn't return the full object in the response. so need to merge with the existing object.
	if (params?.action === 'patch') {
		return {
			current: new WorkflowStore({
				...workflowRepo.get(workflow.id).original,
				...workflow
			})
		};
	}

	return { current: new WorkflowStore(workflow) };
}

export const workflowsStoreMapper = { map };
