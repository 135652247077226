import React from 'react';
import { Input } from 'antd';
import { observer } from 'mobx-react';
import Translate from '../../../translation/translator';
import TagCustomerConfiguration from '../../../stores/tag-customer-configurations/tag-customer-configuration';

interface Props {
	tagCustomerConfiguration: TagCustomerConfiguration;
}

const CustomizeTrackingUrl = ({ tagCustomerConfiguration }: Props) => {
	return (
		<div className="customized-tracking-url">
			<Translate text="CUSTOMER_CONFIGURATION_VIEW.CUSTOMIZED_TRACKING_URL" />
			<Input
				value={tagCustomerConfiguration.customized_tracking_url}
				onChange={({ target }) => tagCustomerConfiguration.setValue('customized_tracking_url', target.value)}
			/>
		</div>
	);
};

export default observer(CustomizeTrackingUrl);
