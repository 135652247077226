import React, { forwardRef } from 'react';

import classNames from 'classnames';
import { BringgFontIcons, BringgIcon } from '@bringg/bringg-icons';
import { Checkbox } from '@bringg/react-components';

import styles from './draggable-list-item.module.scss';

export interface Props {
	checked: boolean;
	dragDisabled: boolean;
	id: string;
	title: string;
	onSelectCheckbox: (event) => void;
	checkboxDisabled?: boolean;
	isDragging?: boolean;
	handlerId?: any;
}

export const DraggableListItem = forwardRef<HTMLDivElement, Props>(
	(
		{ id, title, checked, dragDisabled, onSelectCheckbox, checkboxDisabled, isDragging, handlerId, ...otherProps },
		ref
	) => {
		return (
			<div
				ref={ref}
				data-handler-id={handlerId}
				data-test-id={`item-${id}`}
				className={classNames(styles.draggableItem, {
					[styles.dragDisabled]: dragDisabled,
					[styles.dragging]: isDragging
				})}
				{...otherProps}
				role="listitem"
			>
				<BringgIcon iconName={BringgFontIcons.Drag} />
				<Checkbox
					id={id}
					className="item-checkbox-label"
					checked={checked}
					disabled={checkboxDisabled}
					onChange={onSelectCheckbox}
				>
					{title}
				</Checkbox>
			</div>
		);
	}
);

export default DraggableListItem;
