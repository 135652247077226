export enum SupportedPlaceholders {
	Merchant = 'Merchant',
	ScheduleLink = 'Schedule link',
	ScheduleReadyLink = 'Schedule ready link',
	PickupLink = 'Pickup link',
	TrackingLink = 'Tracking link',
	CustomerName = 'Customer name',
	DriverName = 'Driver name',
	JobDescription = 'Job description',
	OrderTitle = 'Order title',
	OrderId = 'Order id',
	WaypointCustomerName = "Way point's customer's name",
	CustomerFirstName = "Customer's first name",
	DeliveryAddress = 'Delivery address',
	DeliveryTimeWindow = "Order's delivery time window",
	TeamDescription = 'Team description',
	NotificationId = 'Unique notification ID'
}
