import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { getRoot } from 'mobx-easy';
import { AlertConfigurations } from '@bringg/types';

import RootStore from 'bringg-web/stores/root-store';
import { UNSUPPORTED_ALERTS_IDS } from '../../../../consts/index';
import PredefinedAlertStore from './predefined-alert-store';

class PredefinedAlertRepo {
	predefinedAlerts: PredefinedAlertStore[] = [];

	constructor() {
		makeObservable(this, {
			predefinedAlerts: observable,
			load: action,
			remove: action,
			enabled: computed,
			updateMerchantConfig: action
		});
	}

	load = () => {
		if (this.predefinedAlerts.length) return;
		this.predefinedAlerts = [];
		const { alert_configurations: predefined, alerts_types: allPredefined } =
			getRoot<RootStore>().data.merchantConfigurationsStore?.configuration || {};

		const alertTypes = new Set(
			[...Object.values(allPredefined || {}), ...Object.keys(predefined || {})].map(alertType =>
				typeof alertType === 'number' ? alertType : parseInt(alertType, 10)
			)
		);

		alertTypes.forEach(alertType => {
			if (UNSUPPORTED_ALERTS_IDS.has(alertType)) {
				return;
			}

			this.predefinedAlerts.push(
				PredefinedAlertStore.create(
					predefined?.[alertType] || {
						// Default to true as this match the backend behavior
						admin: true,
						dispatcher: true,
						webhooks: false
					},
					alertType
				)
			);
		});
	};

	get enabled(): PredefinedAlertStore[] {
		return this.predefinedAlerts.filter(alert => alert.isEnabled);
	}

	available(includePending = false): PredefinedAlertStore[] {
		return this.predefinedAlerts.filter(alert => !alert.isEnabled || (includePending && alert.isAdding));
	}

	add = async (predefinedAlertType: number) => {
		await this.updateMerchantConfig(predefinedAlertType);

		return this.predefinedAlerts.find(predefined => predefined.id === predefinedAlertType);
	};

	async updateMerchantConfig(predefinedAlertType: number) {
		const config = {
			admin: false,
			dispatcher: true,
			webhooks: false
		};

		const { merchantConfigurationsStore } = getRoot<RootStore>().data;
		if (!merchantConfigurationsStore.configuration.alert_configurations) {
			merchantConfigurationsStore.configuration.alert_configurations = {} as AlertConfigurations;
		}
		merchantConfigurationsStore.configuration.alert_configurations[predefinedAlertType] = config;

		try {
			await merchantConfigurationsStore.configuration.update();
		} catch (error) {
			// Rollback
			runInAction(() => {
				merchantConfigurationsStore.configuration.alert_configurations[predefinedAlertType] = {
					admin: false,
					dispatcher: false,
					webhooks: false
				};
			});

			throw error;
		}
	}

	remove = async (alert: PredefinedAlertStore) => {
		try {
			await alert.remove();
		} catch (e) {
			console.error('failed to remove alert: ', e);
			throw e;
		}
	};
}

export default PredefinedAlertRepo;
