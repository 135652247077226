import React, { FunctionComponent } from 'react';
import { Col, Row } from 'antd';
import { DeliveryBlockInfo } from '../delivery-block-card';
import DeliveryBlockBreak, { LocationOption } from '../../../stores/domain-objects/delivery-block-break';
import { useTranslation } from 'react-i18next';
import { BreakType } from '@bringg/types';
import TimezoneDate from '../../../../../components/timezone-date/timezone-date';
import { DELIVERY_BLOCK_TIME_FORMAT } from '../../form/delivery-block-form';
import { getEndTimeText, getStartTimeText, getTimesSeparator } from '../../form/breaks/breaks.consts';

interface Props {
	deliveryBlockBreaks?: DeliveryBlockBreak[];
	teamTimezone: string;
}

const getBreakTypeKey = (breakType: BreakType = BreakType.Fixed): string => {
	return `DELIVERY_BLOCKS.${BreakType[breakType].toUpperCase()}_BREAK`;
};

const getLocationKey = (locationOption: LocationOption = LocationOption.ANYWHERE): string => {
	return `DELIVERY_BLOCKS.${LocationOption[locationOption].toUpperCase()}`;
};

const BreaksCard: FunctionComponent<Props> = ({ deliveryBlockBreaks, teamTimezone }: Props) => {
	const { t } = useTranslation();

	return (
		<div className="breaks-card">
			{deliveryBlockBreaks.map((blockBreak, index) => (
				<div className="break-card" key={blockBreak.id}>
					<DeliveryBlockInfo
						label={`${t('DELIVERY_BLOCKS.BREAK')}-${index + 1}`}
						info={t(getBreakTypeKey(blockBreak.break_type))}
					/>
					{blockBreak.break_type === BreakType.Flex && (
						<DeliveryBlockInfo label={t('DELIVERY_BLOCKS.LENGTH')} info={blockBreak.length} />
					)}
					<Row>
						<Col span={12}>
							<label>{getStartTimeText(blockBreak.break_type, t)}</label>
						</Col>
						<Col span={12}>
							<label>{getEndTimeText(blockBreak.break_type, t)}</label>
						</Col>
					</Row>
					<Row>
						<Col span={10} className="info">
							<TimezoneDate
								timezone={teamTimezone}
								date={blockBreak.start_time}
								format={DELIVERY_BLOCK_TIME_FORMAT}
							/>
						</Col>
						<Col span={4}>{getTimesSeparator(blockBreak.break_type)}</Col>
						<Col span={10} className="info">
							<TimezoneDate
								timezone={teamTimezone}
								date={blockBreak.end_time}
								format={DELIVERY_BLOCK_TIME_FORMAT}
							/>
						</Col>
					</Row>
					<DeliveryBlockInfo
						label={t('DELIVERY_BLOCKS.LOCATION')}
						info={t(getLocationKey(blockBreak.location_option))}
					/>
					<div className="break-separator" />
				</div>
			))}
		</div>
	);
};

export default BreaksCard;
