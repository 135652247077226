import classnames from 'classnames';

import { ButtonPropType } from '../../types';

import styles from '../bringg-google-map-provider.module.scss';

export const createBtnFromDefinition = (btn: ButtonPropType) => {
	const controlButton = document.createElement('div');
	const buttonText = btn.text;

	controlButton.className = classnames(styles.mapControlButton, btn.className);
	controlButton.textContent = buttonText;
	controlButton.title = buttonText;
	controlButton['role'] = 'button';

	controlButton.addEventListener('click', btn.clickHandler);
	controlButton.setAttribute('data-test-id', btn['data-test-id']);

	return controlButton;
};

type useControlButtonProps = {
	map: google.maps.Map | null;
	buttons?: ButtonPropType[];
};

export const useButtonsLayer = ({ map, buttons }: useControlButtonProps) => {
	const controlPosition = google.maps.ControlPosition.TOP_LEFT;

	if (!map) {
		return;
	}

	const renderButtons = () => {
		map.controls[controlPosition].clear();

		buttons?.forEach((btn, index) => {
			const centerControl = createBtnFromDefinition(btn);

			if (index === 0) {
				centerControl.classList.add(styles.first);
			}

			map.controls[google.maps.ControlPosition.TOP_LEFT].push(centerControl);
		});
	};

	renderButtons();
};
