import React, { useEffect, useMemo, useState } from 'react';
import { useObserver } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { Spinner, Layout, LayoutContent, LayoutSider, Tabs } from '@bringg/react-components';
import { DeliveryProvider } from '@bringg/types';
import {
	FleetEnvType,
	FleetStatus,
	FleetType
} from '@bringg/dashboard-sdk/dist/DeliveryCatalog/DeliveryCatalog.consts';
import classNames from 'classnames';

import DeliveryCatalogConnectName from './components/delivery-catalog-connect-name';
import DeliveryCatalogConnectMenu from './components/delivery-catalog-connect-menu';
import DeliveryCatalogConnectContent from './components/delivery-catalog-connect-content';
import DeliveryCatalogDataSetup from './components/delivery-catalog-connect-data-setup/delivery-catalog-connect-data-setup';
import ConditionsSet from '../../features/fleets-configuration/conditions-set/conditions-set';
import RateCard from '../../features/fleets-configuration/rate-card/rate-card';
import { useStores } from '../../recipes';
import { useHasFeatureFlag } from '../../utils/feature-flags';

import './delivery-catalog-connect.scss';

export const TEMPORARY_CONNECTION = 'TEMPORARY_CONNECTION';

export interface Connect {
	id: number | 'TEMPORARY_CONNECTION';
	fleet_type: FleetType;
	status: FleetStatus;
	env_type?: FleetEnvType;
	name?: string;
	data?: Record<string, unknown>;
	delivery_provider_id?: number;
	delivery_provider: {
		setup_configuration: DeliveryProvider['setup_configuration'];
		absolute_logo_path: DeliveryProvider['logo'];
		description: DeliveryProvider['description'];
	};
}

interface DeliveryCatalogConnectProps {
	deliveryProvider: {
		id: number;
		name?: string;
		setup_configuration?: DeliveryProvider['setup_configuration'];
		absolute_logo_path?: DeliveryProvider['logo'];
		description?: DeliveryProvider['description'];
		fleet_legacy_id?: FleetType;
	};
	onOpenDeliveryCatalog: () => void;
	className?: string;
}

const DeliveryCatalogConnect = ({
	deliveryProvider,
	onOpenDeliveryCatalog,
	className
}: DeliveryCatalogConnectProps) => {
	const { t } = useTranslation();
	const { deliveryCatalogStore } = useStores();
	const [selectedConnection, setConnection] = useState(null);
	const [temporaryConnection, setTemporaryConnection] = useState(null);
	const [activeTab, setActiveTab] = useState('setup');
	const [fleetType, setFleetType] = useState(null);

	const isConfigurable = Boolean(selectedConnection?.delivery_provider?.setup_configuration?.fields);
	const isRealConnectionSelected = selectedConnection && selectedConnection.id !== TEMPORARY_CONNECTION;

	const rateCardsEnabled = useHasFeatureFlag('enable_rate_cards');

	useEffect(() => {
		deliveryCatalogStore.fetchConnections().then(() => {
			if (deliveryProvider) {
				const existConnection = deliveryCatalogStore.allConnections.find(
					connection => connection.delivery_provider_id === deliveryProvider.id
				);
				if (existConnection) {
					// Edit
					setSelectedConnection(existConnection);
				} else {
					// Temporary
					const connection = {
						id: TEMPORARY_CONNECTION,
						name: deliveryProvider.name,
						delivery_provider: deliveryProvider,
						delivery_provider_id: deliveryProvider.id,
						fleet_type: deliveryProvider.fleet_legacy_id
					};

					setTemporaryConnection(connection);
					setSelectedConnection(connection);
				}
			} else {
				// Default
				setSelectedConnection(deliveryCatalogStore.lastConnection);
			}
		});
	}, [deliveryProvider]);

	const activeKey = useMemo(() => {
		if (activeTab === 'delivery_terms' && !isRealConnectionSelected) {
			return 'setup';
		}

		if (activeTab === 'setup' && !isConfigurable) {
			return 'delivery_terms';
		}

		if (activeTab === 'data_fields' && !deliveryCatalogStore.isDataFieldsAvailable) {
			return 'delivery_terms';
		}

		return activeTab;
	}, [
		selectedConnection,
		activeTab,
		isConfigurable,
		isRealConnectionSelected,
		deliveryCatalogStore.isDataFieldsAvailable
	]);

	const getDataFields = fleetType => {
		deliveryCatalogStore.fetchDataFields(fleetType).then();
		setFleetType(fleetType);
	};
	const setSelectedConnection = connection => {
		if (connection && connection.fleet_type !== fleetType) {
			getDataFields(connection.fleet_type);
		}

		setConnection(connection);
	};
	const handleSelect = id => {
		const connection = deliveryCatalogStore.connections.get(Number(id));
		setTemporaryConnection(null);
		setSelectedConnection(connection);
	};

	const handleCreate = async values => {
		const connection = await deliveryCatalogStore.createConnection(values, deliveryProvider.id);

		setTemporaryConnection(null);
		setSelectedConnection(connection);
	};

	const handleUpdate = async (id, values) => {
		setSelectedConnection(await deliveryCatalogStore.updateConnection(id, values));
	};

	const handleVerify = async (id, values) => {
		setSelectedConnection(await deliveryCatalogStore.verifyConnection(id, values));
	};

	const handleDelete = async id => {
		await deliveryCatalogStore.deleteConnection(id);

		setSelectedConnection(deliveryCatalogStore.lastConnection);
	};

	// const handleTerminate = async id => {
	// 	setSelectedConnection(await deliveryCatalogStore.terminateConnection(id));
	// };

	const getTabItems = () => {
		const items: any = [
			{
				label: t('DELIVERY_PROVIDER_CATALOG_CONNECT.TABS.DELIVERY_TERMS'),
				key: 'delivery_terms',
				disabled: !isRealConnectionSelected,
				children: isRealConnectionSelected && <ConditionsSet fleet={selectedConnection} />
			}
		];

		if (rateCardsEnabled) {
			items.push({
				label: t('FLEETS.RATE_CARDS'),
				key: 'rate_cards',
				disabled: !isRealConnectionSelected,
				children: isRealConnectionSelected && <RateCard connection={selectedConnection} />
			});
		}

		items.push({
			label: t('DELIVERY_PROVIDER_CATALOG_CONNECT.TABS.SETUP'),
			key: 'setup',
			disabled: !isConfigurable,
			children: (
				<DeliveryCatalogConnectContent
					connection={selectedConnection}
					onCreate={handleCreate}
					onUpdate={handleUpdate}
					onVerify={handleVerify}
					onDelete={handleDelete}
				/>
			)
		});

		items.push({
			label: (
				<span>
					{selectedConnection && deliveryCatalogStore.isMissMandatoryData && (
						<i className="bringg-icon bringg-icon-warning"></i>
					)}
					Data Fields
				</span>
			),
			key: 'data_fields',
			disabled: !deliveryCatalogStore.isDataFieldsAvailable || !selectedConnection,
			children: <DeliveryCatalogDataSetup />
		});

		return items;
	};

	return useObserver(() => (
		<div className={classNames('delivery-catalog-connect', className)}>
			{deliveryCatalogStore.isFetching ? (
				<Spinner size="default" className="flex-centered" />
			) : (
				<Layout hasSider>
					<LayoutSider width={325}>
						<DeliveryCatalogConnectMenu
							list={[...deliveryCatalogStore.allConnections, temporaryConnection].filter(Boolean)}
							onSelect={handleSelect}
							selected={selectedConnection}
							onOpenDeliveryCatalog={onOpenDeliveryCatalog}
						/>
					</LayoutSider>
					<LayoutContent>
						<Tabs
							className="delivery-catalog-connect-tabs"
							activeKey={activeKey}
							onChange={setActiveTab}
							tabBarExtraContent={{
								left: (
									<DeliveryCatalogConnectName
										connection={selectedConnection}
										onUpdate={handleUpdate}
										onCreate={handleCreate}
									/>
								)
							}}
							items={getTabItems()}
						/>
					</LayoutContent>
				</Layout>
			)}
		</div>
	));
};

export default DeliveryCatalogConnect;
