import React from 'react';

import { BringgFontIcons, BringgIcon } from '@bringg/bringg-icons';

import './spinner.scss';

const Spinner = () => {
	return <BringgIcon iconName={BringgFontIcons.Loader} spin data-test-id="ganttSpinner" />;
};

export default Spinner;
