import React, { useState } from 'react';

import { PostRateCardTermsResponse, RateCardTermType } from '@bringg/types';
import { BringgFontIcons, BringgIcon } from '@bringg/bringg-icons';
import { Button, Col, Row, Modal, Checkbox, Spinner, Tooltip, Notification } from '@bringg/react-components';
import { downloadFile } from '@bringg-frontend/utils';

import BringgDragger from 'bringg-web/components/bringg-dragger/bringg-dragger';
import { useRateCardCsvTranslations } from './translations';
import { testIds } from './test-ids';

import styles from './rate-card-csv.module.scss';

interface Props {
	termType: RateCardTermType;
	onImport: (termType: RateCardTermType, file: File) => Promise<PostRateCardTermsResponse>;
	onExport: (termType: RateCardTermType) => Promise<[string, string]>;
}

export const ACKNOWLEDGE_KEY = 'rate-card.csv-acknowledge';

const MODAL_WIDTH = 592;
const LINK_LEARN_MORE = 'https://bringg.com';
const LINK_READ_MORE = 'https://bringg.com';

type AcknowledgeModalProps = {
	visible: boolean;
	onConfirm: (acknowledge: boolean) => void;
	onClose: () => void;
	translations: Record<string, string>;
};

const AcknowledgeModal = ({ visible, onConfirm, onClose, translations }: AcknowledgeModalProps) => {
	const [acknowledge, setAcknowledge] = useState(false);

	return (
		<Modal
			className={styles.rateCardCsvModal}
			visible={visible}
			width={MODAL_WIDTH}
			destroyOnClose
			maskClosable={true}
			okText={translations.confirm}
			onCancel={onClose}
			onOk={() => onConfirm(acknowledge)}
		>
			<div>
				<h4>{translations.aboutTitle}</h4>
				<div>
					<p>{translations.aboutDescription}</p>
					<Checkbox
						checked={acknowledge}
						onChange={event => setAcknowledge(event.target.checked)}
						data-test-id={testIds.acknowledgeCheckbox}
					>
						{translations.aboutCheckbox}
					</Checkbox>
				</div>
			</div>
		</Modal>
	);
};

type UploadModalProps = {
	translations: Record<string, string>;
	visible: boolean;
	inProgress: boolean;
	onClose: () => void;
	onFile: (file: File) => void;
};

const UploadModal = ({ translations, visible, inProgress, onClose, onFile }: UploadModalProps) => (
	<Modal
		className={styles.rateCardCsvModal}
		visible={visible}
		width={MODAL_WIDTH}
		destroyOnClose
		okText={translations.confirm}
		onCancel={onClose}
		footer={null}
	>
		<div className={styles.rateCardCsvModal}>
			<h4>
				<BringgIcon iconName={BringgFontIcons.CsvFile} />
				<span>{inProgress ? translations.inProgressTitle : translations.uploadTitle}</span>
			</h4>

			{inProgress ? (
				<div>
					<div className={styles.rateCardCsvModalLoader}>
						<Spinner size="large" />
						{translations.inProgressDescription}
					</div>
				</div>
			) : (
				<>
					<div className={styles.rateCardCsvModalBody}>
						<p className={styles.rateCardCsvModalDescription}>{translations.uploadDescription}</p>
					</div>
					<BringgDragger
						className={styles.rateCardCsvModalDragger}
						accept={'.csv'}
						maxCount={1}
						height={144}
						onFileUploaded={onFile}
					/>
				</>
			)}
		</div>
	</Modal>
);

type UploadErrorModalProps = {
	visible: boolean;
	translations: Record<string, string>;
	onClose: () => void;
};

const UploadErrorModal = ({ visible, translations, onClose }: UploadErrorModalProps) => (
	<Modal
		className={styles.rateCardCsvModal}
		visible={visible}
		width={MODAL_WIDTH}
		destroyOnClose
		okText={translations.errorConfirm}
		cancelButtonProps={{ hidden: true }}
		onCancel={onClose}
		closeIcon={null}
		onOk={onClose}
	>
		<div>
			<h4 className={styles.rateCardCsvModalTitle}>
				<BringgIcon iconName={BringgFontIcons.WarningCircle} color="#f04438" />
				<span>{translations.uploadErrorTitle}</span>
			</h4>

			<div className={styles.rateCardCsvModalBody}>
				<p className={styles.rateCardCsvModalDescription}>{translations.uploadErrorDescription}</p>
				<a href={LINK_LEARN_MORE} target="_blank" rel="noreferrer">
					{translations.learnMore}
				</a>
			</div>
		</div>
	</Modal>
);

export const RateCardCsv = ({ termType, onImport, onExport }: Props) => {
	const translations = useRateCardCsvTranslations();

	const isAcknowledged = Boolean(localStorage.getItem(ACKNOWLEDGE_KEY));

	const [showAcknowledgeModal, setShowAcknowledgeModal] = useState(false);
	const [showUploadModal, setShowUploadModal] = useState(false);
	const [showErrorModal, setShowErrorModal] = useState(false);

	const [inProgress, setInProgress] = useState(false);

	const onAcknowledge = acknowledge => {
		if (acknowledge) {
			localStorage.setItem(ACKNOWLEDGE_KEY, 'true');
		}

		setShowAcknowledgeModal(false);
		setShowUploadModal(true);
	};

	const onClickImport = () => {
		if (isAcknowledged) {
			setShowUploadModal(true);
		} else {
			setShowAcknowledgeModal(true);
		}
	};

	const onSubmit = async (file: File) => {
		try {
			setInProgress(true);
			await onImport(termType, file);
		} catch (error) {
			setShowErrorModal(true);
		} finally {
			setShowUploadModal(false);
			setInProgress(false);
		}
	};

	const onClickExport = async () => {
		const [rateCardCSV, masterCSV] = await onExport(termType);

		downloadFile(new File([rateCardCSV], `rate-card-${termType}-terms.csv`, { type: 'text/csv' }));
		downloadFile(new File([masterCSV], 'rate-card-master.csv', { type: 'text/csv' }));

		Notification.success(translations.exportSuccess);
	};

	return (
		<div className={styles.rateCardCsvHeader}>
			<AcknowledgeModal
				translations={translations}
				visible={showAcknowledgeModal}
				onClose={() => setShowAcknowledgeModal(false)}
				onConfirm={onAcknowledge}
			/>

			<UploadModal
				translations={translations}
				visible={showUploadModal}
				inProgress={inProgress}
				onClose={() => setShowUploadModal(false)}
				onFile={onSubmit}
			/>

			<UploadErrorModal
				translations={translations}
				visible={showErrorModal}
				onClose={() => setShowErrorModal(false)}
			/>

			<Row gutter={16}>
				<Col>
					<Button size="small" onClick={onClickImport} data-test-id={testIds.importButton}>
						{termType === RateCardTermType.Base ? translations.importBasic : translations.importSurcharge}
					</Button>
				</Col>
				<Col>
					<Button size="small" onClick={onClickExport} data-test-id={testIds.exportButton}>
						{termType === RateCardTermType.Base ? translations.exportBasic : translations.exportSurcharge}
					</Button>
				</Col>
				<Col>
					<Tooltip
						placement="top"
						title={
							<div>
								<span>{translations.tooltip}</span>&nbsp;
								<a href={LINK_READ_MORE} target="_blank" rel="noreferrer">
									{translations.readMore}
								</a>
							</div>
						}
					>
						<BringgIcon iconName={BringgFontIcons.Info} style={{ fontSize: '24px', color: '#718096' }} />
					</Tooltip>
				</Col>
			</Row>
		</div>
	);
};
