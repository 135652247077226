import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RateCardEntityType } from '@bringg/types';
import notification from 'bringg-web/services/notification';
import { State } from '../../types';
import RateCardStore from '../store/rate-card-store';

const useRateCardLoad = (rateCardStore: RateCardStore, entityType: RateCardEntityType, entityId: number) => {
	const [rateCardInitState, setState] = useState<State>(State.Idle);
	const { t } = useTranslation();

	useEffect(() => {
		const init = async () => {
			setState(State.Loading);
			try {
				await rateCardStore.load(entityType, entityId);
				setState(State.Done);
			} catch (err) {
				console.error('fail to load rates', err);
				notification.error(t('RATES.FAILED_LOADING_RATES'));
				setState(State.Failed);
			}
		};

		init();
	}, [entityType, entityId]);

	return { rateCardInitState };
};

export default useRateCardLoad;
