import React from 'react';
import './with-title.scss';
import { Button } from '@bringg/react-components';
import classNames from 'classnames';

const withTitle = Component => {
	return ({ onTitleClick = undefined, clickText = '', placeholder, className, bordered = false, ...otherProps }) => {
		return (
			<div className={classNames('with-title', className)}>
				<span className="with-title-title">{placeholder}</span>
				{onTitleClick && (
					<Button className="ant-btn ant-btn-primary ant-btn-sm title-button" onClick={onTitleClick}>
						{clickText}
					</Button>
				)}
				<Component {...otherProps} bordered={bordered} />
			</div>
		);
	};
};

export default withTitle;
