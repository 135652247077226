import { getRootEnv } from '@bringg-frontend/bringg-web-infra';
import { action, computed, observable, makeObservable } from 'mobx';
import { Team as BringgTeam } from '@bringg/types';
import { getEnv, getRoot } from 'mobx-easy';
import { RootEnv } from '@bringg-frontend/bringg-web-infra';

import BaseDomainObject from '../../base-domain-object';
import TeamsStore from '../teams-store';
import DeliveryBlock from 'bringg-web/features/delivery-blocks-v2/stores/domain-objects/delivery-block';
import Driver from '../../drivers/domain-object/driver';
import RootStore from 'bringg-web/stores/root-store';

const TEAMS_ENTITY_NAME = 'teams';

interface Team extends BringgTeam {
	childrenTeamIds: number[];
}
class Team extends BaseDomainObject<Team> {
	id: number;
	childrenTeamIds: number[] = [];
	name = '';
	address = '';
	description = '';
	lat = 0;
	lng = 0;
	contact_phone = '';
	external_id = '';
	service_zipcodes: string[] = [];
	service_boroughs: string[] = [];
	service_cities: string[] = [];
	country_code = '';
	time_zone = '';
	parent_team_id = 0;

	constructor(store: TeamsStore, team: Partial<Team> | Partial<Bringg.Team>) {
		super(store, TEAMS_ENTITY_NAME);

		makeObservable(this, {
			name: observable,
			address: observable,
			description: observable,
			lat: observable,
			lng: observable,
			contact_phone: observable,
			external_id: observable,
			service_zipcodes: observable,
			service_boroughs: observable,
			service_cities: observable,
			country_code: observable,
			time_zone: observable,
			parent_team_id: observable,
			drivers: computed,
			localizationTimezone: computed,
			update: action,
			asJson: computed
		});

		this.store = store;
		this.set(team);
	}

	set = (team: Partial<Team> | Partial<Bringg.Team>) => {
		Object.assign(this, team);
	};

	get deliveryBlocks(): DeliveryBlock[] {
		return this.store.deliveryBlocksByTeam(this.id);
	}

	get drivers(): Driver[] {
		return getRoot<RootStore>().data.driversStore.allByTeam(this.id);
	}

	get localizationTimezone() {
		if (getRoot<RootStore>().data.merchantConfigurationsStore.localizeTaskTimezonesByTeams) {
			return this.time_zone;
		}

		return null;
	}

	update = async (team: Partial<Team>) => {
		await getRootEnv().dashboardSdk.sdk.teams.update(team.id, team);
		this.set({ ...this.asJson, ...team });
	};

	get asJson() {
		// extend this overtime;
		return {
			id: this.id,
			name: this.name,
			address: this.address,
			description: this.description,
			lat: this.lat,
			lng: this.lng,
			contact_phone: this.contact_phone,
			external_id: this.external_id,
			service_zipcodes: this.service_zipcodes,
			service_boroughs: this.service_boroughs,
			service_cities: this.service_cities,
			country_code: this.country_code,
			time_zone: this.time_zone,
			parent_team_id: this.parent_team_id,
			childrenTeamIds: this.childrenTeamIds
		};
	}
}

export default Team;
