import React from 'react';

import { BringgIcon } from '@bringg/bringg-icons';

import { TIMELINE_ITEM_PREFIX } from '../consts';
import type { ExtendedTimelineItem } from '../../../gantt-types';

interface Props {
	item: ExtendedTimelineItem;
}

export const SingleItem = React.memo(({ item }: Props) => {
	const convertItemText = (item: ExtendedTimelineItem) => {
		if (item.customData?.icon) {
			return <BringgIcon iconName={item.customData.icon} />;
		}
		return item.content;
	};

	return (
		<>
			<span className="item-text">{convertItemText(item) || item.id}</span>
			{item.type === 'box' && (
				<style>
					{/* for changing line & dot color as item's color */}
					{`.${TIMELINE_ITEM_PREFIX}${item.id}.vis-item.vis-line, .${TIMELINE_ITEM_PREFIX}${item.id}.vis-item.vis-dot {
			${item.style}
		  }`}
				</style>
			)}
		</>
	);
});
