import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { InputNumber } from 'antd';
import has from 'lodash/has';
import get from 'lodash/get';
import CustomerConfiguration from '../../../stores/customer-configurations/domain-objects/customer-configuration';
import TagCustomerConfiguration from '../../../stores/tag-customer-configurations/tag-customer-configuration';
import CheckboxEnableOption from './checkbox-enable-option';
import { Translate } from '../../../translation';

interface Props {
	customerConfiguration: CustomerConfiguration | TagCustomerConfiguration;
	enableModeKey: string;
	showPrivateMode: boolean;
	privateModeKey?: string;
	enableFreeSmsMode: boolean;
}

class AlertOnEtaDelayEnableOption extends Component<Props, {}> {
	updateMinutes = ({ target }) => {
		this.props.customerConfiguration.setValue('alert_on_eta_delay_minutes', target.value);
	};

	render() {
		const { customerConfiguration } = this.props;
		const minutes = get(customerConfiguration, 'alert_on_eta_delay_minutes', 0);
		const showCustomerMinutesBefore = has(customerConfiguration, 'alert_customer_minutes_before_eta');
		return (
			<div className="enable-option">
				{showCustomerMinutesBefore && (
					<div>
						<Translate text="CUSTOMER_CONFIGURATION_VIEW.ALERT_ON_ETA_DELAY_PRE" />
						<InputNumber
							defaultValue={minutes}
							className="input-number"
							onBlur={this.updateMinutes}
							data-test-id="eta-delay-minutes-input"
						/>
						<Translate text="CUSTOMER_CONFIGURATION_VIEW.ALERT_ON_ETA_DELAY_POST" />
					</div>
				)}
				<CheckboxEnableOption {...this.props} />
			</div>
		);
	}
}

export default observer(AlertOnEtaDelayEnableOption);
