import React from 'react';
import { LabelNumberInput } from '@bringg/react-components';
import { Options, RRule, Weekday } from 'rrule';
import { Checkbox } from 'antd';
import _get from 'lodash/get';
import _compact from 'lodash/compact';
import _reduce from 'lodash/reduce';

interface Props {
	options: Options;
	onOptionsChange: (options: Partial<Options>) => void;
	disabled: boolean;
	everyLabel: string;
	weeksLabel: string;
	onLabel: string;
	weekDaysLabels: { [key: number]: string };
}

interface ByWeekDayProps {
	onWeekDaysChange: (byweekday: Weekday[]) => void;
	selectedWeekDays: Weekday[];
	disabled: boolean;
	weekDaysLabels: { [key: number]: string };
}

const rruleWeekDays = new Map([
	[RRule.SU.weekday, RRule.SU],
	[RRule.MO.weekday, RRule.MO],
	[RRule.TU.weekday, RRule.TU],
	[RRule.WE.weekday, RRule.WE],
	[RRule.TH.weekday, RRule.TH],
	[RRule.FR.weekday, RRule.FR],
	[RRule.SA.weekday, RRule.SA]
]);

const RecurringByWeekDay = ({ selectedWeekDays, onWeekDaysChange, disabled, weekDaysLabels }: ByWeekDayProps) => {
	const transformToRRuleWeekDay = checkedWeekDays => {
		onWeekDaysChange(checkedWeekDays.map(weekDay => rruleWeekDays.get(weekDay)));
	};

	const getSelectedWeekDays = () => _compact(selectedWeekDays).map(({ weekday }) => weekday);

	const generateWeeklyOptionsWithLabels = () =>
		_reduce(
			Array.from(rruleWeekDays.values()),
			(weekDays, { weekday }) => [...weekDays, { label: _get(weekDaysLabels, weekday), value: weekday }],
			[]
		);

	return (
		<Checkbox.Group
			className="recurring-frequency-weekly"
			options={generateWeeklyOptionsWithLabels()}
			disabled={disabled}
			value={getSelectedWeekDays()}
			onChange={transformToRRuleWeekDay}
		/>
	);
};

const RecurringWeekly = ({
	options,
	onOptionsChange,
	disabled,
	everyLabel,
	onLabel,
	weeksLabel,
	weekDaysLabels
}: Props) => {
	const onIntervalChange = interval => onOptionsChange({ interval });
	const onWeekDaysUpdate = byweekday => onOptionsChange({ byweekday });

	return (
		<>
			<div className="recurring-frequency">
				<label>{everyLabel}:</label>
				<LabelNumberInput
					label={weeksLabel}
					minValue={1}
					value={options.interval}
					onChange={onIntervalChange}
					disabled={disabled}
				/>
			</div>

			<div className="recurring-frequency">
				<label>{onLabel}:</label>
				<RecurringByWeekDay
					onWeekDaysChange={onWeekDaysUpdate}
					disabled={disabled}
					selectedWeekDays={[].concat(options.byweekday)}
					weekDaysLabels={weekDaysLabels}
				/>
			</div>
		</>
	);
};

export default RecurringWeekly;
