import { getRootEnv } from '@bringg-frontend/bringg-web-infra';
import { getEnv } from 'mobx-easy';
import { TaskRejectReasonsApi } from '@bringg/dashboard-sdk';
import { RootEnv } from '@bringg-frontend/bringg-web-infra';

import TaskRejectReasonStore from './task-reject-reasons-store';
import { taskRejectReasonsStoreMapper } from './data-providers/task-reject-reasons-store-mapper';

export default class TaskRejectReasonsRootStore {
	taskRejectReasonRepo: TaskRejectReasonsApi<TaskRejectReasonStore>;

	init() {
		this.taskRejectReasonRepo = getRootEnv().dashboardSdk.sdk.v2.createTaskRejectReasons<TaskRejectReasonStore>(
			taskRejectReasonsStoreMapper.map
		);
	}
}
