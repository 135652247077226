import { PrivilegeTypes } from '@bringg/types';
import isUndefined from 'lodash/isUndefined';
import isBoolean from 'lodash/isBoolean';
import find from 'lodash/find';
import useStores from '../recipes/use-stores';

export const isAdmin = (currentUser: Bringg.User): boolean => currentUser && currentUser.admin;
export const isDispatcher = (currentUser: Bringg.User): boolean => currentUser && currentUser.dispatcher;

export const hasAccess = (currentUser: Bringg.User, privilegeName?: string, subPrivilegeName?: string): boolean => {
	if (!currentUser) {
		return false;
	}

	if (isAdmin(currentUser)) {
		return true;
	}

	const privilege = currentUser.authorization_flags[privilegeName];

	if (currentUser.authorization_flags && !isUndefined(privilege)) {
		if (subPrivilegeName) {
			const privilegeValue = privilege[subPrivilegeName];
			const defaultSubPrivilege = find(privilege.values, { field: privilege.default });

			return isBoolean(privilegeValue) ? privilegeValue : defaultSubPrivilege.default;
		}
		return privilege.value;
	}

	return true;
};

export const useHasAccess = (privilegeName: PrivilegeTypes, subPrivilegeName?: string): boolean => {
	const {
		usersStore: { currentUser }
	} = useStores();
	return hasAccess(currentUser, privilegeName, subPrivilegeName);
};

export const useIsAdmin = (): boolean => {
	const {
		usersStore: { currentUser }
	} = useStores();
	return isAdmin(currentUser);
};

export const hasAccessCompanyUsersOnly = (currentUser: Bringg.User): boolean => {
	if (isAdmin(currentUser)) {
		return false;
	}
	const accessCompanyUsersOnly =
		currentUser?.authorization_flags?.[PrivilegeTypes.ACCESS_USERS]?.[PrivilegeTypes.ACCESS_COMPANY_USERS_ONLY];
	if (isBoolean(accessCompanyUsersOnly)) {
		return accessCompanyUsersOnly;
	}
	return false;
};

export const extractPrivilegeId = (privilege: string): string => {
	const [match] = /\d+/.exec(privilege);

	return match;
};
