import { Task } from '@bringg/types';
import { last } from 'lodash';
import moment from 'moment';
import sortWaypointsByPosition from './sortWaypointsByPosition';

const sortRunTasksByLastWaypointEta = (tasks: Task[]): Task[] => {
	return tasks.sort((a, b) => {
		const etaA: string = last(sortWaypointsByPosition([...a.way_points])).eta;
		const etaB: string = last(sortWaypointsByPosition([...b.way_points])).eta;
		// eslint-disable-next-line
		if (etaA == etaB) return a.priority - b.priority;
		if (!etaA || !etaB) return !etaA ? -1 : 1;
		return moment(etaA) > moment(etaB) ? 1 : -1;
	});
};

export default sortRunTasksByLastWaypointEta;
