import { useEffect, useState } from 'react';
import { useStores } from '../../recipes';
import { State } from './types';
import notification from '../../services/notification';
import { useTranslation } from 'react-i18next';

export type Teams = { [id: string]: { id: string; name: string } };

const useTeamsById = () => {
	const [teams, setTeams] = useState<Teams>({});
	const [state, setState] = useState<State>(State.Idle);
	const { teamsStore } = useStores();
	const { t } = useTranslation();

	useEffect(() => {
		const init = async () => {
			setState(State.Loading);
			try {
				const teams = await teamsStore.fetchAll();
				const teamsById = teams.reduce((acc, team) => {
					const id = team.id.toString();
					acc[id] = { id, name: team.name };
					return acc;
				}, {});
				setTeams(teamsById);
				setState(State.Done);
			} catch (err) {
				notification.error(t('GLOBAL.FAILED_LOADING_TEAMS'));
				setState(State.Failed);
			}
		};

		init();
	}, []);

	return { teams, state };
};

export default useTeamsById;
