export const FileDropSVG = () => (
	<svg width="184" viewBox="0 0 184 81" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M135.703 80.7251H47.2952C40.8522 80.7251 34.76 78.2255 30.1387 73.6899C25.4799 69.1168 22.916 63.0515 22.916 56.6137C22.916 50.176 25.4799 44.1107 30.1387 39.5376C33.7849 35.9557 38.3499 33.6464 43.2794 32.8293C43.2794 32.7194 43.2767 32.6096 43.2767 32.4997C43.2767 14.7752 57.6979 0.354004 75.4251 0.354004C81.9995 0.354004 88.3166 2.33381 93.6934 6.07642C97.4789 8.71259 100.621 12.0882 102.96 15.9996C106.732 13.6608 111.083 12.407 115.611 12.407C127.637 12.407 137.632 21.2558 139.433 32.7837C144.472 33.5553 149.141 35.886 152.86 39.5376C157.516 44.1107 160.083 50.1733 160.083 56.6137C160.083 63.0541 157.519 69.1168 152.86 73.6899C148.239 78.2282 142.146 80.7251 135.703 80.7251Z"
			fill="#EFF4F8"
		/>
		<path
			d="M41.1698 15.4142C41.9508 14.6332 43.2171 14.6332 43.9982 15.4142L55.1378 26.5539L47.6482 34.0435C46.8672 34.8245 46.8672 36.0909 47.6482 36.8719L55.0955 44.3192L38.829 60.5858C38.0479 61.3669 36.7816 61.3669 36.0005 60.5858L17.4136 41.9988C16.6325 41.2178 16.6325 39.9515 17.4136 39.1704L41.1698 15.4142Z"
			fill="#F8FAFB"
		/>
		<path
			d="M55.1378 26.5539L43.9982 15.4142C43.2171 14.6332 41.9508 14.6332 41.1698 15.4142L17.4136 39.1704C16.6325 39.9515 16.6325 41.2178 17.4136 41.9988L36.0005 60.5858C36.7816 61.3669 38.0479 61.3669 38.829 60.5858L55.0955 44.3192M55.1378 26.5539L47.6482 34.0435C46.8672 34.8245 46.8672 36.0909 47.6482 36.8719L55.0955 44.3192M55.1378 26.5539V33.9385L55.0955 44.3192"
			stroke="#CBD5E0"
			strokeWidth="1.5"
			strokeLinejoin="round"
		/>
		<path
			d="M31.5951 44.9295C31.3435 45.1812 30.9316 45.1823 30.7022 44.9103C30.4353 44.5938 30.2351 44.2976 30.1016 44.0216C29.9031 43.6114 29.8452 43.2261 29.9279 42.8655C30.0073 42.5083 30.2157 42.161 30.5531 41.8236L32.0962 40.2805C32.3211 40.0556 32.4534 39.8273 32.4931 39.5958C32.5328 39.3642 32.4914 39.1244 32.3691 38.8763C32.3248 38.7866 32.2707 38.6952 32.2069 38.6024C31.9995 38.3006 31.9885 37.8874 32.2475 37.6285C32.5064 37.3695 32.9198 37.3799 33.2197 37.5901C33.3116 37.6546 33.4019 37.7096 33.4904 37.755C33.7385 37.8774 33.9783 37.9187 34.2098 37.8791C34.4447 37.836 34.6729 37.7037 34.8945 37.4821L36.4475 35.9291C36.7882 35.5884 37.1389 35.38 37.4994 35.3039C37.86 35.2279 38.2437 35.2907 38.6505 35.4925C38.9273 35.6275 39.2203 35.8263 39.5296 36.0888C39.7997 36.3179 39.797 36.7276 39.5466 36.978C39.3016 37.223 38.9134 37.2158 38.6157 37.0387C38.5674 37.01 38.5195 36.9842 38.4719 36.9611C38.2668 36.8619 38.0634 36.8338 37.8616 36.8768C37.6632 36.9165 37.4597 37.0405 37.2513 37.2489L35.7628 38.7374C35.4354 39.0649 35.0798 39.2319 34.6961 39.2385C34.3257 39.2482 33.9302 39.1074 33.5097 38.816C33.4899 38.8023 33.463 38.8045 33.446 38.8215C33.4287 38.8388 33.4268 38.8661 33.441 38.8859C33.7415 39.3032 33.8853 39.6986 33.8724 40.0721C33.8625 40.4525 33.6938 40.8064 33.3663 41.1339L31.863 42.6373C31.6546 42.8457 31.5305 43.0491 31.4908 43.2476C31.4478 43.4494 31.4776 43.6511 31.5801 43.8529C31.6017 43.8975 31.6259 43.9424 31.6526 43.9874C31.8314 44.2887 31.8428 44.6818 31.5951 44.9295Z"
			fill="#01C8B5"
		/>
		<path
			d="M32.6588 46.8706C32.3853 46.6392 32.388 46.2248 32.6414 45.9715C32.889 45.7239 33.281 45.7323 33.5845 45.9072C33.6269 45.9316 33.669 45.9538 33.7109 45.9737C33.9127 46.0763 34.1144 46.106 34.3162 46.063C34.518 46.0266 34.7231 45.9043 34.9315 45.6959L36.4249 44.2024C36.7524 43.8749 37.108 43.7079 37.4917 43.7013C37.8682 43.6916 38.2619 43.8338 38.6729 44.1279C38.6927 44.1421 38.72 44.14 38.7373 44.1228C38.7544 44.1057 38.7566 44.0788 38.7428 44.059C38.4514 43.6384 38.3089 43.2413 38.3153 42.8677C38.3252 42.4873 38.4939 42.1334 38.8214 41.8059L40.3198 40.3075C40.5282 40.0991 40.6523 39.8957 40.692 39.6972C40.735 39.4954 40.7068 39.292 40.6076 39.0869C40.5867 39.0429 40.563 38.9985 40.5367 38.9538C40.3604 38.6542 40.3484 38.2645 40.5942 38.0187C40.8427 37.7701 41.2491 37.7676 41.4772 38.035C41.7458 38.35 41.9471 38.646 42.0812 38.9232C42.2797 39.3333 42.3392 39.7171 42.2598 40.0743C42.1805 40.4315 41.9704 40.7805 41.6297 41.1212L40.0866 42.6643C39.8617 42.8892 39.7277 43.1191 39.6847 43.354C39.645 43.5855 39.6864 43.8253 39.8088 44.0734C39.8542 44.1619 39.9092 44.2521 39.9737 44.3441C40.1839 44.644 40.1943 45.0573 39.9353 45.3163C39.6763 45.5753 39.2632 45.5643 38.9614 45.3569C38.8685 45.2931 38.7772 45.239 38.6874 45.1947C38.4394 45.0723 38.1995 45.031 37.968 45.0707C37.7365 45.1104 37.5099 45.241 37.2882 45.4627L35.7352 47.0157C35.3979 47.3531 35.0489 47.5598 34.6883 47.6359C34.3245 47.7153 33.9375 47.6557 33.5273 47.4573C33.2546 47.3253 32.9651 47.1298 32.6588 46.8706Z"
			fill="#01C8B5"
		/>
		<path
			d="M93.1698 15.4142C93.9508 14.6332 95.2171 14.6332 95.9982 15.4142L107.138 26.5539L99.6482 34.0435C98.8672 34.8245 98.8672 36.0909 99.6482 36.8719L107.096 44.3192L90.829 60.5858C90.0479 61.3669 88.7816 61.3669 88.0005 60.5858L69.4136 41.9988C68.6325 41.2178 68.6325 39.9515 69.4136 39.1704L93.1698 15.4142Z"
			fill="#F8FAFB"
		/>
		<path
			d="M107.138 26.5539L95.9982 15.4142C95.2171 14.6332 93.9508 14.6332 93.1698 15.4142L69.4136 39.1704C68.6325 39.9515 68.6325 41.2178 69.4136 41.9988L88.0005 60.5858C88.7816 61.3669 90.0479 61.3669 90.829 60.5858L107.096 44.3192M107.138 26.5539L99.6482 34.0435C98.8672 34.8245 98.8672 36.0909 99.6482 36.8719L107.096 44.3192M107.138 26.5539V33.9385L107.096 44.3192"
			stroke="#CBD5E0"
			strokeWidth="1.5"
			strokeLinejoin="round"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M92.1847 24.8922C91.8788 24.5864 91.3829 24.5864 91.077 24.8922C90.7712 25.1981 90.7712 25.6941 91.077 25.9999L95.877 30.7999C96.1829 31.1058 96.6788 31.1058 96.9847 30.7999C97.2906 30.4941 97.2906 29.9981 96.9847 29.6922L92.1847 24.8922ZM89.2309 27.846C88.925 27.5402 88.4291 27.5402 88.1232 27.846C87.8173 28.1519 87.8173 28.6479 88.1232 28.9537L92.9232 33.7537C93.2291 34.0596 93.725 34.0596 94.0309 33.7537C94.3368 33.4479 94.3368 32.9519 94.0309 32.6461L89.2309 27.846ZM85.1693 30.7999C85.4752 30.494 85.9711 30.494 86.277 30.7999L99.2001 43.723C99.506 44.0289 99.506 44.5248 99.2001 44.8307C98.8942 45.1366 98.3983 45.1366 98.0924 44.8307L85.1693 31.9076C84.8635 31.6017 84.8634 31.1058 85.1693 30.7999ZM83.3232 33.7538C83.0173 33.4479 82.5214 33.4479 82.2155 33.7538C81.9096 34.0596 81.9096 34.5556 82.2155 34.8615L95.1386 47.7845C95.4444 48.0904 95.9404 48.0904 96.2463 47.7845C96.5521 47.4787 96.5521 46.9827 96.2463 46.6768L83.3232 33.7538ZM79.2616 36.7076C79.5675 36.4017 80.0634 36.4017 80.3693 36.7076L93.2924 49.6307C93.5983 49.9366 93.5983 50.4325 93.2924 50.7384C92.9865 51.0443 92.4906 51.0443 92.1847 50.7384L79.2616 37.8153C78.9557 37.5094 78.9557 37.0135 79.2616 36.7076ZM77.4155 39.6615C77.1096 39.3556 76.6136 39.3556 76.3078 39.6615C76.0019 39.9674 76.0019 40.4633 76.3078 40.7692L89.2308 53.6923C89.5367 53.9981 90.0327 53.9981 90.3385 53.6923C90.6444 53.3864 90.6444 52.8904 90.3385 52.5846L77.4155 39.6615Z"
			fill="#01C8B5"
		/>
		<path
			d="M145.17 15.4142C145.951 14.6332 147.217 14.6332 147.998 15.4142L159.138 26.5539L151.648 34.0435C150.867 34.8245 150.867 36.0909 151.648 36.8719L159.096 44.3192L142.829 60.5858C142.048 61.3669 140.782 61.3669 140.001 60.5858L121.414 41.9988C120.633 41.2178 120.633 39.9515 121.414 39.1704L145.17 15.4142Z"
			fill="#F8FAFB"
		/>
		<path
			d="M159.138 26.5539L147.998 15.4142C147.217 14.6332 145.951 14.6332 145.17 15.4142L121.414 39.1704C120.633 39.9515 120.633 41.2178 121.414 41.9988L140.001 60.5858C140.782 61.3669 142.048 61.3669 142.829 60.5858L159.096 44.3192M159.138 26.5539L151.648 34.0435C150.867 34.8245 150.867 36.0909 151.648 36.8719L159.096 44.3192M159.138 26.5539V33.9385L159.096 44.3192"
			stroke="#CBD5E0"
			strokeWidth="1.5"
			strokeLinejoin="round"
		/>
		<path
			d="M140.926 54.2664L140.926 54.2668C140.922 54.2923 140.911 54.3178 140.892 54.3396C140.874 54.3614 140.85 54.3774 140.825 54.3861C140.799 54.3948 140.774 54.3956 140.752 54.3905C140.73 54.3855 140.711 54.3747 140.696 54.3603L128.377 42.0409C128.353 42.017 128.346 41.9979 128.343 41.9834C128.339 41.9655 128.34 41.9411 128.35 41.9137C128.36 41.8863 128.376 41.8632 128.396 41.8469C128.413 41.8327 128.437 41.8193 128.476 41.8141L136.787 40.821C136.787 40.821 136.787 40.821 136.787 40.821C136.808 40.8187 136.827 40.821 136.843 40.8268L137.088 40.1176L136.843 40.8268C136.86 40.8326 136.875 40.8418 136.887 40.8535C136.898 40.8653 136.908 40.8798 136.913 40.8966L137.608 40.6575L136.913 40.8966C136.919 40.9135 136.921 40.9327 136.919 40.953L136.059 48.1946L135.943 49.1732L136.917 49.0245L141.676 48.298L141.676 48.298L141.679 48.2975C141.728 48.2899 141.76 48.3049 141.782 48.327C141.804 48.349 141.819 48.3813 141.811 48.4297L141.811 48.4297L141.811 48.4336L140.926 54.2664ZM148.674 40.7357C149.399 41.461 149.402 42.6576 148.642 43.4174C147.882 44.1772 146.685 44.1744 145.96 43.4492C145.235 42.7239 145.232 41.5272 145.992 40.7674C146.752 40.0076 147.948 40.0104 148.674 40.7357Z"
			stroke="#01C8B5"
			strokeWidth="1.5"
		/>
	</svg>
);
