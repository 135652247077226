import React from 'react';
import { DATE_FORMAT } from '../../vehicle-assignment-history.consts';
import { MomentInput } from 'moment';
import { Date as DateComponent, Table } from '../../../../components';
import Translate from '../../../../translation/translator';
import { Driver, VehicleChange } from '@bringg/types';
import { TablePaginationConfig } from 'antd';

interface Props {
	assignments: VehicleChange[];
	optionalDrivers?: Driver[];
	onRowClicked?: (record: any, index: number) => React.HTMLAttributes<HTMLElement>;
}

const showTotal = (total: number, range: [number, number]): React.ReactNode => {
	return (
		<span>
			{range[0]}-{range[1]} of {total} records
		</span>
	);
};

const pagination: TablePaginationConfig = {
	position: ['bottomLeft'],
	pageSize: 13,
	showTotal: showTotal
};

export default class AssignmentsTable extends React.Component<Props> {
	getTableColumns = () => {
		return [
			{
				title: <Translate text="VEHICLE.START_TIME" />,
				dataIndex: 'start_time',
				render: date => this.dateRenderer(date, 'startTime')
			},
			{
				title: <Translate text="VEHICLE.END_TIME" />,
				dataIndex: 'end_time',
				render: date => this.dateRenderer(date, 'endTime')
			},
			{
				title: <Translate text="VEHICLE.ASSIGNED_DRIVER" />,
				dataIndex: 'new_driver',
				render: driverId => this.driverRenderer(driverId, 'driver'),
				className: 'driver-column'
			}
		];
	};

	dateRenderer = (date: MomentInput, dataTestId?: string) => (
		<span data-test-id={dataTestId}>
			<DateComponent format={DATE_FORMAT} date={date} />
		</span>
	);

	driverRenderer = (driverId: number, dataTestId?: string) => {
		const driver: Driver = this.props.optionalDrivers.find(driver => driver.id === driverId);

		return <span data-test-id={dataTestId}>{driver ? driver.name : <Translate text="VEHICLE.UNASSIGNED" />}</span>;
	};

	render() {
		return (
			<Table
				dataSource={this.props.assignments}
				columns={this.getTableColumns()}
				pagination={pagination}
				rowKey="id"
				size="middle"
				onRow={this.props.onRowClicked}
			/>
		);
	}
}
