import React, { useEffect, useRef, useState } from 'react';

import { observer } from 'mobx-react';
// eslint-disable-next-line no-restricted-imports
import { Form } from 'antd';
import i18next from 'i18next';
import _get from 'lodash/get';
import classNames from 'classnames';
import moment, { Moment } from 'moment';
import { Modal } from '@bringg/react-components';

import DeliveryBlocksDriversPanel from './drivers-panel/delivery-blocks-drivers-panel';
import DeliveryBlockForm, { DeliveryBlockFormValues } from './form/delivery-block-form';
import DeliveryBlockCard from './card/delivery-block-card';
import getDeliveryBlockModalFooter from './delivery-block-modal-footer';
import DeliveryBlockModalView from './delivery-block-modal-view';
import RecurringOptionsModal, { RecurringOptions } from './recurring-options-modal/recurring-options-modal';
import TimezoneService from '../../../services/timezone/timezone-service';
import DeliveryBlock from '../stores/domain-objects/delivery-block';
import Driver from '../../../stores/drivers/domain-object/driver';
import { getSortedDriversByAvailabilityAndName } from '../delivery-blocks-utils';
import { timezoneProvider } from 'bringg-web/services/timezone/timezone-provider';

interface Props {
	onClose?: (reFetch?: boolean) => void;
	visible?: boolean;
	deliveryBlockModalView: DeliveryBlockModalView;
	currentCalendarDay?: Moment;
}

export enum DeliveryBlockModalViewMode {
	CREATE,
	EDIT,
	VIEW
}

export type DeliveryBlockCreateValues = DeliveryBlockFormValues & {
	userIds: number[];
};

export type ApplyRecurringOption = (recurringOption: RecurringOptions) => void;
export type CancelRecurringOption = () => void;

const formatDeliveryBlockModalTitle = (name, startTime, endTime, teamTimezone) => {
	const momentTz = TimezoneService.getMomentTimezone(teamTimezone);
	return `${name} - ${momentTz(startTime).format('ddd, MMM DD, YYYY - hh:mm a')}
  - ${momentTz(endTime).format('hh:mm a')}`;
};

const getDeliveryBlockModalTime = (deliveryBlock: DeliveryBlock) => {
	if (deliveryBlock) {
		return {
			startTime: deliveryBlock.start_time || moment().toISOString(),
			endTime: deliveryBlock.end_time || moment().add(1, 'hour').toISOString()
		};
	}

	return { startTime: moment().toISOString(), endTime: moment().add(1, 'hour').toISOString() };
};

export interface DeliveryTimes {
	startTime: string;
	endTime: string;
}

const DeliveryBlockModal = (props: Props) => {
	const { deliveryBlockModalView, visible, currentCalendarDay } = props;
	const { loadingState, deliveryBlock, viewMode, setEditMode, cancelEditMode, isDeletable, isEditable } =
		deliveryBlockModalView;
	const [form] = Form.useForm();
	const [deliveryBlockTimes, setDeliveryBlockTimes] = useState<DeliveryTimes>(
		getDeliveryBlockModalTime(deliveryBlockModalView.deliveryBlock)
	);
	const withBreaks =
		!deliveryBlockModalView.useOldBreaks &&
		_get(deliveryBlockModalView, 'deliveryBlock.delivery_block_breaks.length') > 0;

	const [hasBreaks, setHasBreaks] = useState<boolean>(withBreaks);

	const applyRecurringOption = useRef<ApplyRecurringOption>(null);
	const cancelRecurringOption = useRef<CancelRecurringOption>(null);

	useEffect(() => {
		setHasBreaks(withBreaks);
	}, [viewMode]);

	const createDeliveryBlockTitle = () => {
		const { viewMode, deliveryBlock } = deliveryBlockModalView;
		if (viewMode === DeliveryBlockModalViewMode.CREATE) {
			return i18next.t('DELIVERY_BLOCKS.NEW_BLOCK');
		}

		if (deliveryBlock) {
			const { name, start_time, end_time, team_id } = deliveryBlock;
			return formatDeliveryBlockModalTitle(
				name,
				start_time,
				end_time,
				timezoneProvider.getTimezoneByTeamId(team_id)
			);
		}

		return i18next.t('DELIVERY_BLOCKS.DELIVERY_BLOCK');
	};

	const sortedDrivers: Driver[] = getSortedDriversByAvailabilityAndName(
		deliveryBlockModalView.drivers,
		deliveryBlockTimes,
		deliveryBlockModalView?.deliveryBlock?.id
	);

	const onCancelEdit = () => {
		cancelEditMode();
		form.resetFields();
	};

	const onCancel = () => {
		props.onClose();

		if (viewMode !== DeliveryBlockModalViewMode.VIEW && form) {
			form.resetFields();
		}
	};

	const recurringUpdated = ical => Boolean(ical);
	const recurringOptionModalPromise = async (): Promise<RecurringOptions> =>
		new Promise((resolve, reject) => {
			applyRecurringOption.current = resolve;
			cancelRecurringOption.current = reject;
		});
	const getRecurringOption = async () => {
		deliveryBlockModalView.setRecurringOptionsModalVisible(true);
		return recurringOptionModalPromise();
	};
	const createOrUpdateBlock = async (values, recurringOption?: RecurringOptions) => {
		deliveryBlockModalView.setLoadingState(true);
		await deliveryBlockModalView.createOrUpdate(
			{
				...values,
				userIds: deliveryBlockModalView.selectedDriversIds
			},
			recurringOption
		);

		form.resetFields();
		if (deliveryBlockModalView.viewMode === DeliveryBlockModalViewMode.CREATE) {
			props.onClose(recurringUpdated(values.ical));
		} else {
			deliveryBlockModalView.resetViewMode();
		}
	};

	const onDelete = async () => {
		const { onClose } = props;

		if (deliveryBlockModalView.deliveryBlock.isRecurring) {
			const recurringOption = await getRecurringOption();
			await deliveryBlockModalView.onDelete(recurringOption);
			onClose(true);
			return;
		}

		await deliveryBlockModalView.onDelete();
		onClose();
	};

	const handleSubmit = () => {
		form.validateFields()
			.then(async values => {
				try {
					if (
						recurringUpdated(values.ical) &&
						deliveryBlockModalView.viewMode === DeliveryBlockModalViewMode.EDIT
					) {
						const recurringOption = await getRecurringOption();
						await createOrUpdateBlock(values, recurringOption);
						return;
					}
					await createOrUpdateBlock(values);
				} catch (e) {
					console.error(e);
				} finally {
					deliveryBlockModalView.setLoadingState(false);
				}
			})
			.catch(info => {
				console.error('Validate Failed:', info);
			});
	};

	const handleDeliveryBlockTimeChanged = (startTime: Moment, endTime: Moment) => {
		setDeliveryBlockTimes({
			startTime: moment(deliveryBlockTimes.startTime)
				.set({ hour: startTime.hour(), minute: startTime.minute() })
				.toISOString(),
			endTime: moment(deliveryBlockTimes.endTime)
				.set({ hour: endTime.hour(), minute: endTime.minute() })
				.toISOString()
		});
	};

	const handleDeliveryBlockDateChanged = (date: Moment | null) => {
		if (!date) {
			setDeliveryBlockTimes({ startTime: null, endTime: null });
			return;
		}

		const startTime = moment(deliveryBlockTimes.startTime)
			.clone()
			.set({ year: date.year(), month: date.month(), date: date.date() })
			.toISOString();

		const endTime = moment(deliveryBlockTimes.endTime)
			.clone()
			.set({ year: date.year(), month: date.month(), date: date.date() })
			.toISOString();

		setDeliveryBlockTimes({
			startTime,
			endTime
		});
	};

	const onRecurringOptionApply = (recurringOption: RecurringOptions) => {
		deliveryBlockModalView.setRecurringOptionsModalVisible(false);
		applyRecurringOption.current(recurringOption);
	};

	const onRecurringOptionCancel = () => {
		deliveryBlockModalView.setRecurringOptionsModalVisible(false);
		cancelRecurringOption.current();
	};

	return (
		<Modal
			className={classNames('delivery-block-modal', {
				'delivery-block-modal-with-break': hasBreaks
			})}
			visible={visible}
			title={createDeliveryBlockTitle()}
			onCancel={onCancel}
			width={hasBreaks ? 1100 : 705}
			footer={getDeliveryBlockModalFooter(
				viewMode,
				setEditMode,
				onCancelEdit,
				handleSubmit,
				onCancel,
				onDelete,
				loadingState,
				isDeletable,
				isEditable
			)}
		>
			<div className="delivery-block-modal-container">
				<DeliveryBlocksDriversPanel
					onDriverSelect={deliveryBlockModalView.onDriverSelect}
					onDriversRemove={deliveryBlockModalView.onDriverRemove}
					drivers={sortedDrivers}
					selectedDriversIds={deliveryBlockModalView.selectedDriversIds}
					viewMode={viewMode}
					deliveryBlockOriginalCapacity={deliveryBlockModalView.originalCapacity}
					deliveryBlockStartTime={deliveryBlockTimes.startTime}
					deliveryBlockEndTime={deliveryBlockTimes.endTime}
					deliveryBlockId={deliveryBlock?.id}
				/>
				{viewMode === DeliveryBlockModalViewMode.VIEW ? (
					<DeliveryBlockCard
						deliveryBlock={deliveryBlock}
						breakBuffer={deliveryBlockModalView.breakBuffer}
						useOldBreaks={deliveryBlockModalView.useOldBreaks}
					/>
				) : (
					<DeliveryBlockForm
						deliveryBlock={deliveryBlock}
						breakBuffer={deliveryBlockModalView.breakBuffer}
						isEditMode={viewMode === DeliveryBlockModalViewMode.EDIT}
						form={form}
						onCapacityChange={deliveryBlockModalView.onOriginalCapacityChange}
						minimumCapacity={deliveryBlockModalView.minimumCapacity}
						useOldBreaks={deliveryBlockModalView.useOldBreaks}
						onBreaksChanged={setHasBreaks}
						hasBreaks={hasBreaks}
						onBlockTimeChanged={handleDeliveryBlockTimeChanged}
						onDateChanged={handleDeliveryBlockDateChanged}
						currentCalendarDay={currentCalendarDay}
					/>
				)}
			</div>

			<RecurringOptionsModal
				onApply={onRecurringOptionApply}
				onCancel={onRecurringOptionCancel}
				visible={deliveryBlockModalView.recurringOptionsModalVisible}
			/>
		</Modal>
	);
};

export default observer(DeliveryBlockModal);
