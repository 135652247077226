import React, { useEffect, useMemo, useRef, useState } from 'react';

import { isEqual } from 'lodash';
import classNames from 'classnames';

import GoogleMapStyles from './google-map-design';
import { DEFAULT_MAX_ZOOM, DEFAULT_MIN_ZOOM, setMapCenter, setMapZoom } from './utils';
import { useBoundsHandler, useButtonsLayer, useGoogleMarkers, useGooglePolylines, useTrafficLayer } from './hooks';
import { MapOptionsProps } from '../types';
import usePrevious from '../use-previous';

import styles from './bringg-google-map-provider.module.scss';

const BringgGoogleMapProvider = ({
	markers,
	mapCenter,
	mapZoom,
	maxZoom,
	minZoom,
	polylines,
	mapElementClassName,
	fullscreenControl,
	allowTrafficLayer,
	translations,
	buttons,
	isPlaying,
	forceRecenterOnAddingMarkers,
	forceRecenterOnUpdatingMarkers,
	selectedMarkers,
	onSelectMarker,
	clearSelectedMarkers,
	onMapClick,
	boundsPadding,
	options = {}
}: MapOptionsProps) => {
	const mapRef = useRef<HTMLDivElement>(null);
	const [map, setMap] = useState<google.maps.Map | null>(null);
	const [shouldAutoFocusMap, setShouldAutoFocusMap] = useState<boolean>(true);
	const prevMarkers = usePrevious(markers);
	const prevMarkersLength = usePrevious(markers?.length);

	const markersList = useMemo(() => {
		return markers || [];
	}, [markers]);

	const infoWindow = useMemo(() => {
		return new google.maps.InfoWindow({
			content: ''
		});
	}, []);

	useEffect(() => {
		let newMap: google.maps.Map;
		if (mapRef.current) {
			newMap = new google.maps.Map(mapRef.current, {
				center: mapCenter,
				styles: GoogleMapStyles,
				zoom: mapZoom,
				maxZoom: maxZoom || DEFAULT_MAX_ZOOM,
				minZoom: minZoom || DEFAULT_MIN_ZOOM,
				fullscreenControl,
				controlSize: 24,
				mapTypeControlOptions: {
					position: google.maps.ControlPosition.RIGHT_BOTTOM
				},
				streetViewControlOptions: {
					position: google.maps.ControlPosition.RIGHT_CENTER
				},
				...options
			});

			newMap.addListener('dragstart', () => {
				setShouldAutoFocusMap(false);
			});

			newMap.addListener('bounds_changed', () => {
				// @ts-ignore
				if (newMap?.systemZoomChange) {
					// @ts-ignore
					newMap.systemZoomChange = false;
				} else {
					setShouldAutoFocusMap(false);
				}
			});

			setMap(newMap);

			google.maps.event.addListener(newMap, 'click', (e: google.maps.MapMouseEvent) => {
				clearSelectedMarkers?.();
				onMapClick?.(e);
			});
		}

		return () => {
			google.maps.event.clearInstanceListeners(newMap);
		};
	}, []);

	useEffect(() => {
		if (!map || !mapCenter) return;
		setMapCenter(map, mapCenter);
		mapZoom && setMapZoom(map, mapZoom);
	}, [map, mapCenter]);

	useEffect(() => {
		if (!map) return;
		mapZoom && setMapZoom(map, mapZoom);
	}, [map, mapZoom]);

	useEffect(() => {
		if (isPlaying && !shouldAutoFocusMap) {
			setShouldAutoFocusMap(true);
		}
	}, [isPlaying]);

	const shouldPanMap =
		(forceRecenterOnAddingMarkers && prevMarkersLength !== markers?.length) ||
		(forceRecenterOnUpdatingMarkers && !isEqual(prevMarkers, markers));

	useGoogleMarkers(
		markersList,
		map,
		infoWindow,
		shouldAutoFocusMap,
		onSelectMarker,
		selectedMarkers,
		forceRecenterOnAddingMarkers
	);

	useGooglePolylines(map, polylines);
	useBoundsHandler(map, markers, polylines, shouldAutoFocusMap || shouldPanMap, boundsPadding);

	useTrafficLayer({
		trafficButtonTranslation: translations?.traffic,
		allowTrafficLayer: !!allowTrafficLayer,
		map
	});
	useButtonsLayer({ map, buttons: buttons || [] });

	return (
		<div
			ref={mapRef}
			data-test-id={'bringg-google-map-provider'}
			className={classNames(styles.bringgGoogleMapContainer, mapElementClassName)}
		/>
	);
};

export default BringgGoogleMapProvider;
