import { action, computed, observable, makeObservable } from 'mobx';
import { MapboxMarkerData } from '@bringg/react-components/dist/components/mapbox/mapbox';
import { Anchor, IdType, ImageUrl, Popup, Size } from '@bringg/react-components/dist/types/maps.consts';
import { Location as BringgLocation } from '@bringg/types';
import _isEqual from 'lodash/isEqual';
import _isNil from 'lodash/isNil';

interface Marker extends MapboxMarkerData {
	defaultIcon?: JSX.Element | ImageUrl;
}

class Marker {
	id: IdType;
	location: BringgLocation;
	icon?: JSX.Element | ImageUrl;
	color?: string;
	text?: string;
	popup?: Popup;
	anchor?: Anchor;
	size?: Size;

	isIconLocked = false;

	constructor(marker: Partial<Marker>) {
		makeObservable(this, {
			id: observable,
			location: observable,
			icon: observable.ref,
			color: observable,
			text: observable,
			popup: observable.shallow,
			anchor: observable,
			size: observable,
			update: action
		});

		this.update(marker);
	}

	update(marker: Partial<Marker> = {}) {
		Object.assign(this, marker);
	}
}

export enum MarkerType {
	DRIVERS,
	TASKS,
	TEAMS
}

class DispatchMapMarkersView {
	private markersMap: Map<MarkerType, Map<IdType, Marker>> = new Map();

	constructor() {
		makeObservable<DispatchMapMarkersView, 'markersMap' | 'init' | 'triggerMapUpdateForMarker'>(this, {
			markersMap: observable,
			init: action,
			removeOldMarkers: action,
			set: action,
			triggerMapUpdateForMarker: action,
			driversMarkers: computed,
			tasksMarkers: computed,
			teamsMarkers: computed,
			lockedMarkers: computed
		});

		this.init();
	}

	private init() {
		this.markersMap.set(MarkerType.DRIVERS, new Map());
		this.markersMap.set(MarkerType.TASKS, new Map());
		this.markersMap.set(MarkerType.TEAMS, new Map());
	}

	addOrUpdateMarker(
		markerType: MarkerType,
		markerId: IdType,
		marker: Partial<Marker>,
		isIconLocked?,
		forceUpdate = false
	) {
		const markersMap = this.markersMap.get(markerType);

		if (!markersMap.has(markerId)) {
			this.set(markerType, markerId, new Marker(marker));
		} else {
			const currentMarker = markersMap.get(markerId);

			if (forceUpdate || this.shouldUpdateMarker(currentMarker, marker)) {
				currentMarker.update(marker);
			}

			if (!_isNil(isIconLocked)) {
				currentMarker.isIconLocked = isIconLocked;
			}

			this.triggerMapUpdateForMarker(markerType, markerId, currentMarker);
		}
	}

	removeOldMarkers(updatedMarkersIds: { [key: number]: boolean }, markerType: MarkerType) {
		const currentMarkersIds = this.markersMap.get(markerType).keys();

		// eslint-disable-next-line no-restricted-syntax
		for (const markerId of currentMarkersIds) {
			if (!updatedMarkersIds[markerId]) {
				this.removeMarker(markerType, markerId);
			}
		}
	}

	private triggerMapUpdateForMarker(markerType: MarkerType, markerId: IdType, marker: Marker) {
		this.removeMarker(markerType, markerId);
		this.set(markerType, markerId, marker);
	}

	public set(markerType: MarkerType, markerId: IdType, marker: Marker) {
		this.markersMap.get(markerType).set(markerId, marker);
	}

	removeMarker(markerType: MarkerType, markerId: IdType) {
		if (this.markersMap.get(markerType).has(markerId)) {
			this.markersMap.get(markerType).delete(markerId);
		}
	}

	private getMarkersList(markerType: MarkerType) {
		return Array.from(this.markersMap.get(markerType).values());
	}

	get driversMarkers(): Marker[] {
		return this.getMarkersList(MarkerType.DRIVERS);
	}

	get tasksMarkers(): Marker[] {
		return this.getMarkersList(MarkerType.TASKS);
	}

	get teamsMarkers(): Marker[] {
		return this.getMarkersList(MarkerType.TEAMS);
	}

	get lockedMarkers(): { [key: number]: Marker[] } {
		const lockedTasks = this.tasksMarkers.filter(marker => marker.isIconLocked);
		const lockedDrivers = this.driversMarkers.filter(marker => marker.isIconLocked);
		const lockedTeams = this.teamsMarkers.filter(marker => marker.isIconLocked);

		return {
			[MarkerType.TASKS]: lockedTasks,
			[MarkerType.DRIVERS]: lockedDrivers,
			[MarkerType.TEAMS]: lockedTeams
		};
	}

	get(markerType: MarkerType, markerId: IdType): Marker {
		if (!this.markersMap.get(markerType).has(markerId)) {
			return null;
		}

		return this.markersMap.get(markerType).get(markerId);
	}

	private shouldUpdateMarker(currentMarker: Marker, newMarker: Partial<Marker>): boolean {
		const shouldUpdateIcon = this.shouldUpdateMarkerIcon(currentMarker, newMarker);
		const shouldUpdateLocation = !_isEqual(currentMarker.location, newMarker.location);
		const shouldUpdateText = !_isEqual(currentMarker.text, newMarker.text);

		return shouldUpdateIcon || shouldUpdateLocation || shouldUpdateText;
	}

	private shouldUpdateMarkerIcon(currentMarker: Marker, newMarker: Partial<Marker>): boolean {
		if (currentMarker.isIconLocked) {
			return false;
		}

		const sameIconType = typeof currentMarker.icon === typeof newMarker.icon;

		if (!sameIconType) {
			return true;
		}

		if (typeof currentMarker.icon === 'string') {
			return currentMarker.icon !== newMarker.icon;
		}

		return false;
	}
}

export default DispatchMapMarkersView;
