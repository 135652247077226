import React from 'react';
import { observer } from 'mobx-react';
import { DatePicker } from 'antd';
import { Moment } from 'moment';
import _isFunction from 'lodash/isFunction';
import { Button } from '@bringg/react-components';
import { BringgFontIcons, BringgIcon } from '@bringg/bringg-icons';
import { DeliveryBlocksViewMode } from '../delivery-blocks-toolbar';
import { TimeRange } from '../delivery-block-toolbar-view';

const { WeekPicker } = DatePicker;

interface Props {
	timeRange: TimeRange;
	setNext: () => void;
	setPrevious: () => void;
	setTimeRange?: (date: Moment) => void;
	mode: DeliveryBlocksViewMode;
}

const createCurrentWeekText = (timeRange: TimeRange) => {
	return `${timeRange.startDate.clone().startOf('week').format('DD')} -
     ${timeRange.endDate.clone().endOf('week').format('DD, MMM, YYYY')}`;
};

const createCurrentDayText = (timeRange: TimeRange) => {
	return timeRange.startDate.format('DD, MMM, YYYY');
};

class DeliveryBlocksNavigator extends React.Component<Props> {
	onChange = (date: Moment) => {
		if (_isFunction(this.props.setTimeRange)) {
			this.props.setTimeRange(date);
		}
	};

	render() {
		const { onChange } = this;
		const { setNext, setPrevious, mode, timeRange } = this.props;
		return (
			<div className="rbc-btn-group">
				<div className="delivery-blocks-scheduler-navigator">
					<Button
						className="toolbar-navigation-button angles toolbar-navigation-button-left"
						onClick={setPrevious}
					>
						<BringgIcon iconName={BringgFontIcons.ChevronLeft} className="toolbar-chevron" />
					</Button>
					<div className="current-week">
						{mode === DeliveryBlocksViewMode.Week ? (
							<>
								<div className="week-text">{createCurrentWeekText(timeRange)}</div>
								<WeekPicker
									allowClear={false}
									className="delivery-blocks-time-picker"
									value={timeRange.startDate}
									onChange={onChange}
									format=" "
								/>
							</>
						) : (
							<>
								<div className="day-text">{createCurrentDayText(timeRange)}</div>
								<DatePicker
									allowClear={false}
									className="delivery-blocks-time-picker"
									value={timeRange.startDate}
									onChange={onChange}
									format=" "
								/>
							</>
						)}
					</div>
					<Button
						className="toolbar-navigation-button angles toolbar-navigation-button-right"
						onClick={setNext}
					>
						<BringgIcon iconName={BringgFontIcons.ChevronRight} className="toolbar-chevron" />
					</Button>
				</div>
			</div>
		);
	}
}

export default observer(DeliveryBlocksNavigator);
