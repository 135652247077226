import { getRootEnv } from '@bringg-frontend/bringg-web-infra';
import { action, computed, observable, makeObservable } from 'mobx';
import each from 'lodash/each';
import isEmpty from 'lodash/isEmpty';
import some from 'lodash/some';
import { v4 as uuidv4 } from 'uuid';
import isNil from 'lodash/isNil';
import { WebhookAuthenticationConfiguration } from '@bringg/types';

import WebhooksAppStore from '../webhooks-app-store';
import { Condition, WebhookData, WebhookAMC, WebhookFieldsTree } from '../webhooks.consts';
import {
	formatTreeToServer,
	formatTreeFromServer,
	formatTreeToSelectedFields
} from './server-webhook-fields-adapter/server-webhook-fields-adapter';

interface Webhook {
	setConditionKey: (conditionKey: string) => void;
	setConditionValue: (conditionValue: string) => void;
	setWebhookFieldsTree: (newTree: WebhookFieldsTree) => void;
	setWebhookAuthenticationConfigurationId: (id: number) => void;
	setWebhookAuthenticationConfiguration: (param: WebhookAuthenticationConfiguration) => void;
	setHeaders: (headers: Record<string, string>) => void;
}

class Webhook {
	urlsData: Map<string, string> = new Map();

	key: string;

	isFlexible = false;

	newStructure: boolean; // Continue to support old flexible structure

	conditionKey: string;

	conditionValue: string;

	private _webhookFieldsTree: WebhookFieldsTree;

	uuid: string;

	webhooksAppStore: WebhooksAppStore;

	webhookAuthenticationConfigurationId: number | undefined;

	webhookAuthenticationConfiguration: WebhookAuthenticationConfiguration | undefined;

	headersData: Record<string, string>;

	constructor(webhookData: WebhookData, webhooksAppStore: WebhooksAppStore) {
		makeObservable<Webhook, '_webhookFieldsTree'>(this, {
			conditionKey: observable,
			conditionValue: observable,
			_webhookFieldsTree: observable,
			urlsData: observable.shallow,
			key: observable,
			isFlexible: observable,
			newStructure: observable,
			setKey: action,
			addUrl: action,
			deleteUrl: action,
			updateUrl: action,
			setIsFlexible: action,
			updateNodeValues: action,
			resetWebhookFieldsTree: action,
			urls: computed,
			webhookFieldsTree: computed,
			isValid: computed,
			setConditionKey: action,
			setConditionValue: action,
			setWebhookFieldsTree: action,
			webhookAuthenticationConfigurationId: observable,
			setWebhookAuthenticationConfigurationId: action,
			webhookAuthenticationConfiguration: observable,
			setWebhookAuthenticationConfiguration: action,
			setHeaders: action,
			headersData: observable
		});

		each(webhookData.urls, this.addUrl);
		this.key = webhookData.key;
		this.conditionKey = webhookData.condition && webhookData.condition.key;
		this.conditionValue = webhookData.condition && webhookData.condition.value;
		this.uuid = webhookData.uuid;
		this.webhooksAppStore = webhooksAppStore;
		this._webhookFieldsTree = this.getWebhookTree(webhookData.model_config);
		this.isFlexible = webhookData.enableFlexibleWebhook ?? false;
		this.newStructure = webhookData.newStructure ?? false;
		this.webhookAuthenticationConfigurationId = webhookData.webhook_authentication_configuration_id;
		this.headersData = webhookData.headers;
	}

	static cloneWebhook(webhook: Webhook): Webhook {
		return new Webhook(webhook.toJson(), webhook.webhooksAppStore);
	}

	setConditionKey = (conditionKey: string) => {
		this.conditionKey = conditionKey;
	};

	setConditionValue = (conditionValue: string) => {
		this.conditionValue = conditionValue;
	};

	setWebhookFieldsTree = (webhookFieldsTree: WebhookFieldsTree) => {
		this._webhookFieldsTree = webhookFieldsTree;
	};

	setWebhookAuthenticationConfigurationId = (id: number) => {
		this.webhookAuthenticationConfigurationId = id;
	};

	setWebhookAuthenticationConfiguration = (
		webhookAuthenticationConfiguration: WebhookAuthenticationConfiguration
	) => {
		this.webhookAuthenticationConfiguration = webhookAuthenticationConfiguration;
	};

	doesSupportFlexibleWebhook(): boolean {
		const webhookType = this.webhooksAppStore?.applicationConfig?.[this.key];
		return Boolean(webhookType?.baseModel);
	}

	isOnlyFlexible(): boolean {
		const webhookType = this.webhooksAppStore?.applicationConfig?.[this.key];
		return Boolean(webhookType?.onlyFlexible);
	}

	getWebhookTree(webhookFieldsConfiguration?: WebhookAMC): WebhookFieldsTree {
		if (this.doesSupportFlexibleWebhook()) {
			const webhookType = this.webhooksAppStore?.applicationConfig?.[this.key];
			return formatTreeFromServer(webhookType, this.webhooksAppStore.webhookModels, webhookFieldsConfiguration);
		}
		return { tree: null, nodeValues: null, rootId: null };
	}

	setKey = (key: string) => {
		this.key = key;
		this.resetWebhookFieldsTree();
		this.setIsFlexible(this.doesSupportFlexibleWebhook());
	};

	addUrl = (url = ''): void => {
		this.urlsData.set(uuidv4(), url);
	};

	deleteUrl = (uuid: string): void => {
		this.urlsData.delete(uuid);
	};

	updateUrl = (uuid: string, url: string): void => {
		this.urlsData.set(uuid, url);
	};

	setIsFlexible = (isFlexible: boolean) => {
		this.isFlexible = isFlexible;
		this.newStructure = isFlexible;
	};

	updateNodeValues = newNodeValues => {
		this.setWebhookFieldsTree({ ...this.webhookFieldsTree, nodeValues: newNodeValues });
	};

	resetWebhookFieldsTree = () => {
		this.setWebhookFieldsTree(this.getWebhookTree());
	};

	save = async () => {
		if (isNil(this.uuid)) {
			this.uuid = uuidv4();
		}

		await this.webhooksAppStore.updateWebhook(this);
	};

	delete = async () => {
		await this.webhooksAppStore.deleteWebhook(this.uuid);
	};

	test = async urlUuid => {
		const { dashboardSdk } = getRootEnv();
		await dashboardSdk.sdk.application.executeAction('simulate_webhook', {
			key: this.key,
			url: this.urlsData.get(urlUuid),
			condition: this.buildCondition(),
			model_config: this.isFlexible
				? formatTreeToServer(
						this.webhookFieldsTree.tree,
						this.webhookFieldsTree.rootId,
						this.webhookFieldsTree.nodeValues
				  )
				: null
		});
	};

	get selectedFields(): string[][] {
		return formatTreeToSelectedFields(this.webhookFieldsTree.tree, this.webhookFieldsTree.nodeValues);
	}

	toJson(): WebhookData {
		return {
			uuid: this.uuid,
			key: this.key,
			urls: Array.from(this.urlsData.values()),
			condition: this.buildCondition(),
			model_config: this.isFlexible
				? formatTreeToServer(
						this.webhookFieldsTree.tree,
						this.webhookFieldsTree.rootId,
						this.webhookFieldsTree.nodeValues
				  )
				: null,
			enableFlexibleWebhook: this.isFlexible,
			newStructure: this.newStructure,
			webhook_authentication_configuration_id: this.webhookAuthenticationConfigurationId,
			headers: this.headersData
		};
	}

	buildCondition(): Condition {
		return this.conditionKey ? { key: this.conditionKey, value: this.conditionValue } : undefined;
	}

	get urls(): [string, string][] {
		return Array.from(this.urlsData.entries());
	}

	get webhookFieldsTree(): WebhookFieldsTree {
		return this._webhookFieldsTree;
	}

	get isValid(): boolean {
		if (this.conditionKey && isEmpty(this.conditionValue)) {
			return false;
		}

		if (this.urls.length === 0 || some(this.urls, ([url]) => isEmpty(url))) {
			return false;
		}

		if (isEmpty(this.key)) {
			return false;
		}

		return true;
	}

	get headers(): Record<string, string> {
		return this.headersData ?? {};
	}

	setHeaders = (headers: Record<string, string>) => {
		this.headersData = headers;
	};
}

export default Webhook;
