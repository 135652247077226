import { TFunction } from 'i18next';
import { FilterBar2, FilterBar2ListItem, FilterBar2Value } from '@bringg/react-components';
import { TaskStatus, TaskTypeId } from '@bringg/types';
import moment, { Moment } from 'moment';
import { getSharedRootStore, hasFeatureFlag } from '@bringg-frontend/global-stores';

const rangeDateTranslation = (t: TFunction) => ({
	today: t('FILTER_BAR.TODAY'),
	yesterday: t('FILTER_BAR.YESTERDAY'),
	last3Days: t('FILTER_BAR.THREE_DAYS_AGO'),
	last7Days: t('FILTER_BAR.LAST_7_DAYS'),
	fromDate: t('FILTER_BAR.FROM_DATE'),
	toDate: t('FILTER_BAR.TO_DATE')
});

const defaultDateTimePickerRanges = (t: TFunction) => {
	return {
		[rangeDateTranslation(t).today]: [moment().startOf('day'), moment().endOf('day')],
		[rangeDateTranslation(t).yesterday]: [
			moment().subtract(1, 'd').startOf('day'),
			moment().subtract(1, 'd').endOf('day')
		],
		[rangeDateTranslation(t).last3Days]: [moment().subtract(2, 'd').startOf('day'), moment().endOf('day')],
		[rangeDateTranslation(t).last7Days]: [moment().subtract(6, 'd').startOf('day'), moment().endOf('day')]
	} as { [name: string]: [moment.Moment, moment.Moment] };
};

const disabledDate = (current: Moment): boolean => {
	const currentUser = getSharedRootStore().data.usersStore.currentUser;
	// TODO: remove fetaure_flag with typo. This should be done after migration to a correct feature_flag for merchants
	// Details in ticket: https://bringg.atlassian.net/browse/BRNGG-35867
	const show45Days =
		hasFeatureFlag(currentUser, 'enalbe_history_page_45_days_max') ||
		hasFeatureFlag(currentUser, 'enable_history_page_45_days_max');

	if (!current) {
		return false;
	}

	if (show45Days) {
		return current.isBefore(moment().subtract(45, 'days')) || current.isAfter();
	} else {
		return current.isAfter();
	}
};

const getFilters = (
	t: TFunction,
	dateFormat: string,
	timeFormat: string,
	teamOptions: FilterBar2Value[],
	tagOptions: FilterBar2Value[],
	fleetOptions: FilterBar2Value[],
	servicePlanOptions: FilterBar2Value[],
	companyOptions: FilterBar2Value[],
	handleDriverSearch: any
): FilterBar2ListItem[] => {
	return [
		{
			type: FilterBar2.TYPES.SEARCH,
			name: 'search',
			placeholder: t('TASK.SEARCH')
		},
		{
			type: FilterBar2.TYPES.SELECT,
			name: 'status',
			title: t('DISPATCH_LIST.LEGEND_BY_ORDER_STATUS'),
			values: [
				{ label: 'Done', value: TaskStatus.Done },
				{ label: 'Cancelled', value: TaskStatus.Cancelled }
			],
			multiselect: true,
			tooltip: t('DISPATCH_LIST.LEGEND_BY_ORDER_STATUS')
		},
		{
			type: FilterBar2.TYPES.TEAMS,
			multiselect: true,
			name: 'teams',
			title: t('FILTER_BAR.TEAMS_TEXT'),
			placeholder: t('FILTER_BAR.TEAMS_TEXT'),
			values: teamOptions,
			tooltip: t('FILTER_BAR.TOOLTIP.TEAMS')
		},
		{
			type: FilterBar2.TYPES.SELECT,
			multiselect: true,
			name: 'drivers',
			title: t('FILTER_BAR.DRIVERS'),
			placeholder: t('HISTORY_PAGE.FILTER_BAR.DRIVERS_PLACEHOLDER'),
			values: [],
			additional: true,
			tooltip: t('FILTER_BAR.TOOLTIP.DRIVERS_TOOLTIP'),
			customProps: {
				onSearch: handleDriverSearch
			}
		},
		{
			type: FilterBar2.TYPES.SELECT,
			multiselect: true,
			name: 'fleets',
			title: t('DISPATCH_LIST.FLEETS'),
			placeholder: t('DISPATCH_LIST.FLEETS'),
			values: fleetOptions,
			additional: true,
			tooltip: t('FILTER_BAR.TOOLTIP.FLEETS')
		},
		{
			type: FilterBar2.TYPES.SELECT,
			multiselect: true,
			name: 'tags',
			title: t('FILTER_BAR.TAGS_TEXT'),
			placeholder: t('FILTER_BAR.TAGS_TEXT'),
			values: tagOptions,
			additional: true,
			tooltip: t('FILTER_BAR.TOOLTIP.TAGS')
		},
		{
			type: FilterBar2.TYPES.SELECT,
			multiselect: true,
			name: 'service_plans',
			title: t('FILTER_BAR.SERVICE_PLANS'),
			placeholder: t('FILTER_BAR.SERVICE_PLANS'),
			values: servicePlanOptions,
			additional: true,
			tooltip: t('FILTER_BAR.TOOLTIP.SERVICE_PLANS')
		},
		{
			type: FilterBar2.TYPES.DATE_RANGE,
			name: 'sla_window',
			title: t('FILTER_BAR.SLA_WINDOW'),
			tooltip: t('FILTER_BAR.TOOLTIP.SLA'),
			additional: true,
			customProps: {
				disabledDate: disabledDate,
				showTime: { format: timeFormat, defaultValue: [moment().startOf('day'), moment().endOf('day')] },
				format: `${dateFormat} ${timeFormat}`,
				ranges: defaultDateTimePickerRanges(t),
				placeholder: [rangeDateTranslation(t).fromDate, rangeDateTranslation(t).toDate]
			}
		},
		{
			type: FilterBar2.TYPES.DATE_RANGE,
			name: 'time_window',
			title: t('FILTER_BAR.DATES'),
			tooltip: t('FILTER_BAR.TOOLTIP.DATES'),
			additional: true,
			customProps: {
				disabledDate: disabledDate,
				showTime: { format: timeFormat, defaultValue: [moment().startOf('day'), moment().endOf('day')] },
				format: `${dateFormat} ${timeFormat}`,
				ranges: defaultDateTimePickerRanges(t),
				placeholder: [rangeDateTranslation(t).fromDate, rangeDateTranslation(t).toDate]
			}
		},
		{
			type: FilterBar2.TYPES.DROPDOWN_SEARCH,
			name: 'customer_name_first',
			title: t('HISTORY_PAGE.WAY_POINTS_FIRST_CUSTOMER_NAME'),
			tooltip: t('FILTER_BAR.TOOLTIP.WAY_POINTS_FIRST_CUSTOMER_NAME'),
			additional: true
		},
		{
			type: FilterBar2.TYPES.DROPDOWN_SEARCH,
			name: 'customer_name_last',
			title: t('HISTORY_PAGE.WAY_POINTS_LAST_CUSTOMER_NAME'),
			tooltip: t('FILTER_BAR.TOOLTIP.WAY_POINTS_LAST_CUSTOMER_NAME'),
			additional: true
		},
		{
			type: FilterBar2.TYPES.DROPDOWN_SEARCH,
			name: 'customer_email',
			title: t('DISPATCH_LIST.WAY_POINTS_LAST_CUSTOMER_EMAIL'),
			tooltip: t('FILTER_BAR.TOOLTIP.WAY_POINTS_LAST_CUSTOMER_EMAIL'),
			additional: true
		},
		{
			type: FilterBar2.TYPES.DROPDOWN_SEARCH,
			name: 'customer_phone',
			title: t('DISPATCH_LIST.WAY_POINTS_LAST_CUSTOMER_PHONE'),
			tooltip: t('FILTER_BAR.TOOLTIP.WAY_POINTS_LAST_CUSTOMER_PHONE'),
			additional: true
		},
		{
			type: FilterBar2.TYPES.DROPDOWN_SEARCH,
			name: 'address_first',
			title: t('DISPATCH_LIST.WAY_POINTS_FIRST_ADDRESS'),
			tooltip: t('FILTER_BAR.TOOLTIP.WAY_POINTS_FIRST_ADDRESS'),
			additional: true
		},
		{
			type: FilterBar2.TYPES.DROPDOWN_SEARCH,
			name: 'address_last',
			title: t('DISPATCH_LIST.WAY_POINTS_LAST_ADDRESS'),
			tooltip: t('FILTER_BAR.TOOLTIP.WAY_POINTS_LAST_ADDRESS'),
			additional: true
		},
		{
			type: FilterBar2.TYPES.DROPDOWN_SEARCH,
			name: 'city_first',
			title: t('DISPATCH_LIST.WAY_POINTS_FIRST_CITY'),
			tooltip: t('FILTER_BAR.TOOLTIP.WAY_POINTS_FIRST_CITY'),
			additional: true
		},
		{
			type: FilterBar2.TYPES.DROPDOWN_SEARCH,
			name: 'city_last',
			title: t('DISPATCH_LIST.WAY_POINTS_LAST_CITY'),
			tooltip: t('FILTER_BAR.TOOLTIP.WAY_POINTS_LAST_CITY'),
			additional: true
		},
		{
			type: FilterBar2.TYPES.SELECT,
			name: 'late',
			title: t('HISTORY_PAGE.LEGEND_BY_LATE_STATUS'),
			values: [
				{ label: t('HISTORY_PAGE.ORDER_ON_TIME'), value: 0 },
				{ label: t('HISTORY_PAGE.ORDER_LATE'), value: 1 }
			],
			multiselect: true,
			tooltip: t('HISTORY_PAGE.LEGEND_BY_LATE_STATUS'),
			additional: true
		},
		{
			type: FilterBar2.TYPES.DATE_RANGE,
			name: 'started_time',
			title: t('HISTORY_PAGE.LEGEND_BY_STARTED_TIME'),
			customProps: {
				disabledDate: disabledDate,
				showTime: { format: timeFormat, defaultValue: [moment().startOf('day'), moment().endOf('day')] },
				format: `${dateFormat} ${timeFormat}`,
				ranges: defaultDateTimePickerRanges(t),
				placeholder: [rangeDateTranslation(t).fromDate, rangeDateTranslation(t).toDate]
			},
			tooltip: t('HISTORY_PAGE.LEGEND_BY_STARTED_TIME'),
			additional: true
		},
		{
			type: FilterBar2.TYPES.DATE_RANGE,
			name: 'ended_time',
			title: t('HISTORY_PAGE.LEGEND_BY_ENDED_TIME'),
			customProps: {
				disabledDate: disabledDate,
				showTime: { format: timeFormat, defaultValue: [moment().startOf('day'), moment().endOf('day')] },
				format: `${dateFormat} ${timeFormat}`,
				ranges: defaultDateTimePickerRanges(t),
				placeholder: [rangeDateTranslation(t).fromDate, rangeDateTranslation(t).toDate]
			},
			tooltip: t('HISTORY_PAGE.LEGEND_BY_ENDED_TIME'),
			additional: true
		},
		{
			type: FilterBar2.TYPES.SELECT,
			name: 'task_type_id',
			title: t('HISTORY_PAGE.LEGEND_BY_TASK_TYPE_ID'),
			values: [
				{ label: t('HISTORY_PAGE.ORDER_PICKUP'), value: TaskTypeId.PICKUP },
				{ label: t('HISTORY_PAGE.ORDER_RETURN'), value: TaskTypeId.RETURN_TASK },
				{ label: t('HISTORY_PAGE.ORDER_DELIVERY'), value: TaskTypeId.DELIVERY },
				{ label: t('HISTORY_PAGE.ORDER_PICK_UP_AND_DELIVERY'), value: TaskTypeId.PICKUP_AND_DELIVERY },
				{ label: t('HISTORY_PAGE.ORDER_CLICK_AND_COLLECT'), value: TaskTypeId.CLICK_AND_COLLECT },
				{ label: t('HISTORY_PAGE.ORDER_SERVICE'), value: TaskTypeId.SERVICE },
				{ label: t('HISTORY_PAGE.ORDER_ROAMING'), value: TaskTypeId.ROAMING }
			],
			multiselect: true,
			tooltip: t('HISTORY_PAGE.LEGEND_BY_TASK_TYPE_ID'),
			additional: true
		},
		{
			type: FilterBar2.TYPES.SELECT,
			multiselect: true,
			name: 'company_id',
			title: t('HISTORY_PAGE.COMPANY_ID'),
			placeholder: t('HISTORY_PAGE.COMPANY_ID'),
			values: companyOptions,
			additional: true,
			tooltip: t('FILTER_BAR.TOOLTIP.COMPANY_ID')
		}
	];
};

export default getFilters;
