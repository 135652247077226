import { useTranslation } from 'react-i18next';

import useRuleEngineTranslations, { BooleanKeys } from './use-rule-engine-translations';

const useFleetRuleEngineTranslations = (booleanKeys: BooleanKeys) => {
	const { t } = useTranslation();
	const conditionTranslations = useRuleEngineTranslations(booleanKeys);

	const translations = {
		deleteTitle: t('FLEET_RULES.DELETE_TITLE'),
		deleteBody: t('FLEET_RULES.DELETE_DESCRIPTION'),
		cancel: t('FLEET_RULES.CANCEL'),
		delete: t('FLEET_RULES.DELETE'),
		body: {
			fleetRuleLeftSide: {
				defineAttributes: t('FLEET_RULES.DEFINE_ATTRIBUTES'),
				setAsOverallDefault: t('FLEET_RULES.SET_AS_OVERALL_DEFAULT'),
				tooltipText: t('FLEET_RULES.TOOLTIP_TEXT'),
				selectTeams: t('FLEET_RULES.SELECT_TEAMS'),
				multiselectPlaceholder: t('FLEET_RULES.SELECT_FROM_LIST'),
				...conditionTranslations
			},
			fleetRuleRightSide: {
				assignmentMethod: t('FLEET_RULES.ASSIGNMENT_METHOD'),
				assignBy: t('FLEET_RULES.ASSIGN_BY'),
				selectCarrier: t('FLEET_RULES.SELECT_CARRRIER'),
				multiselectPlaceholder: t('FLEET_RULES.SELECT_FROM_LIST'),
				fallback: t('FLEET_RULES.FALLBACK'),
				tooltipText: t('FLEET_RULES.FALLBACK_TOOLTIP_TEXT'),
				totalPercentageLabel: t('FLEET_RULES.TOTAL_PERCENTAGE_LABEL'),
				ratioTip: t('FLEET_RULES.RATIO_TIP'),
				ratioAllocation: t('FLEET_RULES.RATIO_ALLOCATION'),
				totalPercentageError: t('FLEET_RULES.TOTAL_PERCENTAGE_ERROR'),
				dropdownTypeTitle: t('FLEET_RULES.DROPDOWN_TYPE_TITLE'),
				dropdownTypeTooltip: t('FLEET_RULES.DROPDOWN_TYPE_TOOLTIP')
			}
		},
		footer: {
			discard: t('FLEET_RULES.DISCARD'),
			save: t('FLEET_RULES.SAVE')
		}
	};

	const itemTranslations = {
		duplicate: t('FLEET_RULES.DUPLICATE'),
		delete: t('FLEET_RULES.DELETE'),
		all: t('FLEET_RULES.ALL'),
		ruleNamePlaceholder: t('FLEET_RULES.RULE_NAME_PLACEHOLDER'),
		fallback: {
			cheap: t('FLEET_RULES.FALLBACK_CHEAP'),
			fast: t('FLEET_RULES.FALLBACK_FAST')
		},
		cheapest: t('FLEET_RULES.CHEAPEST'),
		fastest: t('FLEET_RULES.FASTEST'),
		priority: t('FLEET_RULES.PRIORITY'),
		ratio: t('FLEET_RULES.RATIO'),
		ratioError: t('FLEET_RULES.RATIO_ERROR'),
		zeroRatioError: t('FLEET_RULES.ZERO_RATIO_ERROR'),
		byPickup: t('FLEET_RULES.BY_PICKUP'),
		byDropoff: t('FLEET_RULES.BY_DROPOFF')
	};

	return { translations, itemTranslations };
};

export default useFleetRuleEngineTranslations;
