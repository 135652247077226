import React from 'react';

import { BatchActionStatusToast, useBatchActionStatusTranslation } from '@bringg-frontend/batch-action-status';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';

import { TasksAsyncOperationStore } from './tasks-async-operation-store';

const TasksAsyncOperationStatus = ({ store }: { store: TasksAsyncOperationStore }) => {
	const translations = useBatchActionStatusTranslation(store.triggerName);

	return (
		<BatchActionStatusToast
			key={store.id}
			id={store.id}
			translations={translations}
			// we treat the totalNumberOfActions as the total number of tasks as action in workflow is implementation detail
			totalNumberOfActions={store.totalNumberOfTasks}
			failures={store.failures}
			hideFailureReasonColumn
			onClose={() => store.dispose()}
			currentNumberOfActionsSucceeded={store.successfulItemsCount}
			failureRenderer={failure => <Link to={`/history/${failure.id}`}>{failure.title}</Link>}
		/>
	);
};

export default observer(TasksAsyncOperationStatus);
