import { getRootEnv } from '@bringg-frontend/bringg-web-infra';
import { getEnv } from 'mobx-easy';
import { Rollout as ServerRollout, UserRollout } from '@bringg/dashboard-sdk/dist/Rollouts/Rollouts.consts';
import _find from 'lodash/find';
import { RootEnv } from '@bringg-frontend/bringg-web-infra';

import { Rollout, translationKeys } from './consts';

class SoftRolloutStore {
	rollouts: Rollout[];

	loadConfiguration = async () => {
		const rollouts = await getRootEnv().dashboardSdk.sdk.rollouts.getAll();
		const formattedRollouts = SoftRolloutStore.formatRolloutsFromServer(rollouts);
		if (SoftRolloutStore.validateRolloutsTranslations(formattedRollouts)) {
			this.rollouts = formattedRollouts;
		}
	};

	get currentPageRollout(): Rollout {
		const hashPath = window.location.hash;
		return _find(this.rollouts, rollout => {
			const regex = new RegExp(rollout.route_path);
			return regex.test(hashPath);
		});
	}

	async updateUserRollout(rollout: Rollout, payload: Partial<UserRollout>) {
		const { sdk } = getRootEnv().dashboardSdk;
		rollout.user_rollout = await sdk.rollouts.createOrUpdateUserRollout({
			rollout_id: rollout.id,
			payload
		});
	}

	private static formatRolloutsFromServer(rollouts: ServerRollout[]): Rollout[] {
		return rollouts.map(SoftRolloutStore.formatRolloutFromServer);
	}

	private static formatRolloutFromServer(rollout: ServerRollout): Rollout {
		const {
			description_key,
			disabled_bar_text_key,
			enabled_bar_text_key,
			title_key,
			...rolloutWithoutTranslations
		} = rollout;
		return {
			...rolloutWithoutTranslations,
			user_rollout: rollout.user_rollouts?.[0],
			translations: {
				modal_description: description_key,
				modal_title: title_key,
				disabled_bar_text: disabled_bar_text_key,
				enabled_bar_text: enabled_bar_text_key
			}
		};
	}

	private static validateRolloutsTranslations(rollouts: Rollout[]): boolean {
		return rollouts.every(rollout => translationKeys.every(key => rollout.translations[key]));
	}
}

export default SoftRolloutStore;
