import { RunStatus } from '@bringg/dashboard-sdk';
import { FilterBar2Value } from '@bringg/react-components';
import { TFunction } from 'i18next';
import { Moment } from 'moment';

import { IFilterOption } from 'bringg-web/types/common.consts';
import { RUNS_FILTER_TYPES } from '../runs.consts';

export const statusOptions: IFilterOption<RUNS_FILTER_TYPES>[] = [
	{
		value: RUNS_FILTER_TYPES.ALL_RUNS,
		title: 'RUNS.ALL_RUNS'
	},
	{
		value: RUNS_FILTER_TYPES.OPEN_RUNS,
		title: 'RUNS.OPEN_RUNS'
	},
	{
		value: RUNS_FILTER_TYPES.CLOSED_RUNS,
		title: 'RUNS.CLOSED_RUNS'
	}
];

export interface RunsFilterItems {
	ids?: FilterBar2Value[];
	run_ids?: FilterBar2Value[];
	task_ids?: FilterBar2Value[];
	team_ids?: FilterBar2Value[];
	by_title_ids?: FilterBar2Value[];
	driver_ids?: FilterBar2Value[];
}

export interface RunFiltersParams {
	ids?: number[];
	page?: number;
	items?: number;
	run_status?: RunStatus;
	by_title_ids?: number[];
	run_ids?: number[];
	task_ids?: number[];
	team_ids?: number[];
	driver_ids?: number[];
	from_date?: string | Moment;
	to_date?: string | Moment;
}

export const runDateTranslations = (t: TFunction) => ({
	date: t('FILTER_BAR.DATE'),
	dates: t('FILTER_BAR.DATES'),
	datesTooltip: t('FILTER_BAR.ROUTES.DATES_TOOLTIP'),
	today: t('FILTER_BAR.TODAY'),
	yesterday: t('FILTER_BAR.YESTERDAY'),
	last2Days: t('FILTER_BAR.TWO_DAYS_AGO'),
	last7Days: t('FILTER_BAR.LAST_7_DAYS'),
	last30Days: t('FILTER_BAR.LAST_30_DAYS'),
	fromDate: t('FILTER_BAR.FROM_DATE'),
	toDate: t('FILTER_BAR.TO_DATE')
});

export const runIdTranslations = (t: TFunction) => ({
	runId: t('FILTER_BAR.ROUTES.RUN_ID'),
	runIdTooltip: t('FILTER_BAR.ROUTES.RUN_ID_TOOLTIP')
});
export const runTitleTranslations = (t: TFunction) => ({
	runTitle: t('FILTER_BAR.ROUTES.RUN_TITLE'),
	runTitleTooltip: t('FILTER_BAR.ROUTES.RUN_TITLE_TOOLTIP')
});
export const runStatusTranslations = (t: TFunction) => ({
	runStatus: t('FILTER_BAR.ROUTES.RUN_STATUS'),
	runStatusTooltip: t('FILTER_BAR.ROUTES.RUN_STATUS_TOOLTIP'),
	runStatusOpen: t('FILTER_BAR.ROUTES.RUN_STATUS_OPEN'),
	runStatusClosed: t('FILTER_BAR.ROUTES.RUN_STATUS_CLOSED')
});
export const runOrderIdTranslations = (t: TFunction) => ({
	orderId: t('FILTER_BAR.ROUTES.ORDER_ID'),
	orderIdTooltip: t('FILTER_BAR.ROUTES.ORDER_ID_TOOLTIP')
});

export const runTeamNameTranslations = (t: TFunction) => ({
	teamName: t('FILTER_BAR.ROUTES.TEAM_NAME'),
	teamNameTooltip: t('FILTER_BAR.ROUTES.TEAM_NAME_TOOLTIP')
});

export const runDriverNameTranslations = (t: TFunction) => ({
	driverName: t('FILTER_BAR.ROUTES.DRIVER_NAME'),
	driverNameTooltip: t('FILTER_BAR.ROUTES.DRIVER_NAME_TOOLTIP')
});

export const runFiltersTranslations = (t: TFunction) => ({
	status: t('FILTER_BAR.STATUS'),
	statusTooltip: t('FILTER_BAR.STATUS_TOOLTIP'),
	runTitle: t('FILTER_BAR.ROUTE_NAME'),
	runTitleTooltip: t('FILTER_BAR.ROUTE_NAME_TOOLTIP'),
	runDate: t('FILTER_BAR.ROUTE_DATE'),
	runDateTooltip: t('FILTER_BAR.ROUTE_DATE_TOOLTIP')
});
