import { action, computed, observable, makeObservable } from 'mobx';
import map from 'lodash/map';
import some from 'lodash/some';
import isUndefined from 'lodash/isUndefined';
import { ApplicationUuid } from '@bringg/types';
import RootStore from '../root-store';
import CustomerConfiguration from '../customer-configurations/domain-objects/customer-configuration';
import TagCustomerConfiguration from '../tag-customer-configurations/tag-customer-configuration';
import { getRoot } from 'mobx-easy';

abstract class CustomerNotificationsStore {
	isFetched = false;
	selectedLanguageCode = 'en';

	constructor() {
		makeObservable(this, {
			isFetched: observable,
			selectedLanguageCode: observable,
			setFetched: action,
			updateLanguageCode: action,
			defaultLanguageCode: computed,
			isDefaultLanguage: computed,
			isSelectedTemplatesNew: computed
		});
	}

	setFetched = () => {
		this.isFetched = true;
	};

	updateLanguageCode = (code: string) => {
		this.selectedLanguageCode = code;
	};

	get defaultLanguageCode() {
		return getRoot<RootStore>().data.merchantConfigurationsStore.defaultLanguageCode;
	}

	get isDefaultLanguage() {
		return this.defaultLanguageCode === this.selectedLanguageCode;
	}

	abstract get selectedLanguageTemplates();

	get isSelectedTemplatesNew() {
		return some(this.selectedLanguageTemplates, template => isUndefined(template.id));
	}

	deleteLanguageTemplates = async () => {
		const promises = map(this.selectedLanguageTemplates, async template =>
			getRoot<RootStore>().data.notificationTemplatesStore.delete(template)
		);

		await Promise.all(promises);
		this.updateLanguageCode(this.defaultLanguageCode);
	};

	installRequiredApplications = async (customerConfiguration: CustomerConfiguration | TagCustomerConfiguration) => {
		const { applicationStore } = getRoot<RootStore>().data;

		if (
			customerConfiguration.alert_on_customer_pickup &&
			!applicationStore.isApplicationInstalled(ApplicationUuid.CustomerExperienceApp)
		) {
			await applicationStore.installApplication(ApplicationUuid.CustomerExperienceApp);
		}
	};
}

export default CustomerNotificationsStore;
