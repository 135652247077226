import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import {
	ActionCoreData,
	AlertRuleDefinition,
	ClientTriggerParams,
	CreateWorkflowRequest,
	datetime,
	UpdateWorkflowRequest,
	PatchWorkflowRequest,
	FactType,
	Views,
	DisplayFact
} from '@bringg/types';
import { difference, removeUndefinedKeysDeep } from '@bringg-frontend/utils';

import { workflowsRootStore } from './internal';
import WorkflowContentGenerator, { ContentType } from '../utils/content-mapper/workflow-content-generator';
import { mapBaseWorkflowToServer } from '../utils/mapper';
import { getRandomGuid } from '../utils/helpers';
import TriggerStore from './trigger-store';
import { Workflow } from '../utils/types';
import ActionsRepo from './actions-repo';
import RulesRepo from './rules-repo';
import { workflowResetListener } from './workflow-reset-service';

export enum WorkflowLoading {
	TITLE_UPDATE,
	ENABLED_UPDATE,
	SAVING_WORKFLOW
}

export const emptyWorkflow: Workflow = {
	title: '',
	guid: getRandomGuid(),
	trigger: {} as ClientTriggerParams,
	rule: {
		any: [
			{
				all: []
			}
		]
	} as AlertRuleDefinition,
	actions: [] as ActionCoreData[],
	team_ids: [],
	enabled: true,
	created_by_user_id: null,
	updated_by_user_id: null,
	template_id: null,
	relevantViews: []
};

class WorkflowStore {
	id: number;
	title: string;
	enabled: boolean;
	trigger: TriggerStore;
	rules: RulesRepo;
	actions: ActionsRepo;
	team_ids: number[] | undefined;
	template_id: number;
	created_at: datetime;
	updated_at: datetime;
	updated_by_user_id: number;
	loadingStatus: WorkflowLoading[] = [];
	isReadOnly: boolean;

	public original: Workflow;
	private contentHolder: WorkflowContentGenerator;

	private accessControl: AbortController;

	constructor(workflow: Workflow = emptyWorkflow) {
		makeObservable(this, {
			title: observable,
			enabled: observable,
			trigger: observable,
			loadingStatus: observable,
			created_at: observable,
			updated_at: observable,
			rules: observable.ref,
			actions: observable,
			team_ids: observable.ref,
			template_id: observable,
			updated_by_user_id: observable,
			update: action,
			setTeams: action,
			setTitle: action,
			init: action,
			discardChanges: action,
			save: action,
			setEnabled: action,
			setLoadingStatus: action,
			clearLoadingStatus: action,
			isDirty: computed,
			isTitleDirty: computed,
			changes: computed,
			isValid: computed,
			isSaving: computed,
			isSavingTitle: computed,
			isSavingEnable: computed,
			mappedWorkflowToServer: computed,
			isExpandable: computed,
			factType: computed,
			displayFact: computed
		});

		this.original = removeUndefinedKeysDeep(workflow);
		this.isReadOnly = workflow.is_read_only;
		this.accessControl = new AbortController();

		this.init();

		if (this.id) {
			this.contentHolder = new WorkflowContentGenerator(this);
		}

		workflowResetListener(this, this.accessControl.signal);
	}

	dispose(): void {
		this.accessControl.abort();
		this.accessControl = new AbortController();
	}

	init = () => {
		this.update({ ...this.original });
		this.trigger = new TriggerStore(this.original.trigger);
		this.rules = new RulesRepo(this.original.rule, this.factType, this.displayFact);
		this.actions = new ActionsRepo(
			this.original.predefined_id || this.id,
			this.original.actions,
			this.factType,
			this.displayFact,
			this.trigger
		);

		if (this.trigger?.actions) {
			this.actions.overrideAction.set(this.trigger.actions);
		}
	};

	update(workflow: Partial<Workflow>) {
		Object.assign(this, workflow);
	}

	setTeams = (teamIds: number[]) => {
		this.team_ids = teamIds;
	};

	setTitle = (title: string) => {
		this.title = title;
	};

	updateWorkflow = async (propsToUpdate: PatchWorkflowRequest) => {
		let response: WorkflowStore;

		const loadingStatus =
			propsToUpdate.title !== undefined ? WorkflowLoading.TITLE_UPDATE : WorkflowLoading.ENABLED_UPDATE;

		this.setLoadingStatus(loadingStatus);

		try {
			response = await workflowsRootStore.getStore().workflowRepo.patch(this.id, {
				...propsToUpdate
			});
			const { title, enabled, updated_at, updated_by_user_id } = response;

			this.update({ title, enabled, updated_at, updated_by_user_id });

			return {
				success: true
			};
		} catch (err) {
			console.error('failed to update workflow ', err);

			const { title, enabled } = this.original;
			this.update({ title, enabled });
		} finally {
			this.clearLoadingStatus(loadingStatus);
		}

		return {
			success: false
		};
	};

	get isSaving() {
		return this.loadingStatus.includes(WorkflowLoading.SAVING_WORKFLOW);
	}

	get isSavingTitle() {
		return this.loadingStatus.includes(WorkflowLoading.TITLE_UPDATE);
	}

	get isSavingEnable() {
		return this.loadingStatus.includes(WorkflowLoading.ENABLED_UPDATE);
	}

	setEnabled = (enabled: boolean) => {
		this.enabled = enabled;
	};

	setLoadingStatus = (status: WorkflowLoading) => {
		this.loadingStatus.push(status);
	};

	clearLoadingStatus = (statusToClear: WorkflowLoading) => {
		this.loadingStatus = this.loadingStatus.filter(status => status !== statusToClear);
	};

	discardChanges = () => {
		this.init();
	};

	get mappedWorkflowToServer(): CreateWorkflowRequest | UpdateWorkflowRequest {
		return removeUndefinedKeysDeep(mapBaseWorkflowToServer(this));
	}

	get isDirty(): boolean {
		return Object.keys(this.changes).length > 0;
	}

	get isTitleDirty(): boolean {
		return this.mappedWorkflowToServer.title !== this.original.title;
	}

	get changes() {
		return difference(this.mappedWorkflowToServer, this.original, true);
	}

	get isValid(): boolean {
		return (
			Array.isArray(this.team_ids) &&
			this.trigger.isValid &&
			this.rules.rules.every(rule => rule.isValid) &&
			this.actions.isValid
		);
	}

	delete = async (): Promise<void> => {
		await workflowsRootStore.getStore().workflowRepo.delete(this.id);
		this.dispose();
	};

	async save(): Promise<{ success: boolean }> {
		let response;
		const workflow = mapBaseWorkflowToServer(this);

		this.setLoadingStatus(WorkflowLoading.SAVING_WORKFLOW);

		try {
			if (this.id) {
				response = await workflowsRootStore
					.getStore()
					.workflowRepo.update(this.id, workflow as UpdateWorkflowRequest);
				runInAction(() => {
					this.contentHolder = new WorkflowContentGenerator(this);
				});
			} else {
				response = await workflowsRootStore.getStore().workflowRepo.create(workflow as CreateWorkflowRequest);
				runInAction(() => {
					this.id = response.id;
					this.contentHolder = new WorkflowContentGenerator(this);
				});
			}
			return {
				success: true
			};
		} catch (err) {
			console.error('failed to update workflow ', err);
		} finally {
			this.clearLoadingStatus(WorkflowLoading.SAVING_WORKFLOW);
		}
		return {
			success: false
		};
	}

	get content() {
		return this.contentHolder;
	}

	get isExpandable() {
		return this.contentHolder.sections.some(section => {
			const fullContent = this.contentHolder.mappedSection(section as keyof ContentType, true);
			const partialContent = this.contentHolder.mappedSection(section as keyof ContentType, false);
			const diff = difference(fullContent, partialContent);
			return (diff as [])?.length > 0;
		});
	}

	get factType(): FactType {
		return this.trigger.factType;
	}

	get displayFact(): DisplayFact {
		return this.trigger.displayFact;
	}

	isViewSupported = (view: Views) => this.original.relevantViews?.includes(view);
}

export default WorkflowStore;
