import { useTranslation } from 'react-i18next';

import { AutomationsTab } from './types';

export const is = 'AUTOMATION.TRIGGER.PREFIX.IS';
export const isNot = 'AUTOMATION.TRIGGER.PREFIX.IS_NOT';
export const andOnlyIf = 'AUTOMATION.TRIGGER.PREFIX.AND_ONLY_IF';
export const thenNotify = 'AUTOMATION.ACTIONS.PREFIX.THEN_NOTIFY';
export const notify = 'AUTOMATION.NOTIFY';
export const update = 'GLOBAL.UPDATE';
export const thenUpdate = 'AUTOMATION.ACTIONS.PREFIX.THEN_UPDATE';
export const then = 'AUTOMATION.ACTIONS.PREFIX.THEN';
export const thenSendWebhook = 'AUTOMATION.ACTIONS.PREFIX.THEN_SEND_WEBHOOK';
export const via = 'AUTOMATION.TRIGGER.PHRASE.VIA';
export const and = 'AUTOMATION.TRIGGER.PREFIX.AND';
export const from = 'AUTOMATION.TRIGGER.PREFIX.FROM';
export const to = 'AUTOMATION.TRIGGER.PREFIX.TO';
export const or = 'AUTOMATION.TRIGGER.PREFIX.OR';
export const changed = 'PROGRAMMABLE_TRIGGERS.CHANGED';
export const condition = 'AUTOMATION.CONDITIONS.CONDITION';
export const conditions = 'AUTOMATION.CONDITIONS.TITLE';
export const multipleChoice = 'AUTOMATION.TRIGGER.PHRASE.MULTIPLE_CHOICE';
export const attributes = 'AUTOMATION.ACTION.PREFIX.ATTRIBUTES';
export const when = 'AUTOMATION.TRIGGER.PREFIX.WHEN';
export const ofKey = 'AUTOMATION.TRIGGER.PREFIX.OF';
export const something = 'AUTOMATION.TRIGGER.PREFIX.SOMETHING';
export const that = 'AUTOMATION.TRIGGER.PREFIX.THAT';
export const thenDoThis = 'AUTOMATION.ACTIONS.PREFIX.THEN_DO_THIS';
export const someAmount = 'AUTOMATION.TRIGGER.PREFIX.SOME_AMOUNT';
export const someReason = 'AUTOMATION.ACTIONS.CANCEL_TASK.SOME_REASON';
export const theCancelWithReason = 'AUTOMATION.ACTIONS.CANCEL_TASK.PREFIX';
export const unavailableActions = 'AUTOMATION.EDITOR.UNAVAILABLE_ACTIONS';
export const every = 'AUTOMATION.TRIGGER.PREFIX.EVERY';
export const day = 'AUTOMATION.TRIGGER.PREFIX.DAY';
export const at = 'AUTOMATION.TRIGGER.PREFIX.AT';
export const optimizationAction = 'RULE_ENGINE.ACTION.OPTIMIZATION.TITLE';
export const calcAvailabilityAction = 'RULE_ENGINE.ACTION.CALCULATE_AVAILABILITY_STATE.TITLE';
export const cancelTaskAction = 'RULE_ENGINE.ACTION.CANCEL_TASK.TITLE';
export const manual = 'AUTOMATION.TRIGGER.MANUAL.WHEN_MANUAL_TRIGGER_CALLED';
export const withInputs = 'AUTOMATION.TRIGGER.MANUAL.WHEN_MANUAL_WITH_INPUTS';
export const typePlaceholder = 'AUTOMATION.TRIGGER.MANUAL.TYPE';
export const allowOverrideMatchingCondition = 'AUTOMATION.TRIGGER.MANUAL.DISPLAY_WARNING_MATCHING';
export const allowOverrideAllCondition = 'AUTOMATION.TRIGGER.MANUAL.DISPLAY_WARNING_ALL';
export const relativeValue = 'AUTOMATION.ACTIONS.PREFIX.RELATIVE';
export const nearest = 'AUTOMATION.ACTIONS.RELATIVE.NEAREST';
export const rounded = 'AUTOMATION.ACTIONS.RELATIVE.ROUNDED';
export const notRounded = 'AUTOMATION.ACTIONS.RELATIVE.NOT_ROUNDED';
export const minutes = 'AUTOMATION.ACTIONS.UPDATE.MINUTES';
export const hours = 'AUTOMATION.ACTIONS.UPDATE.HOURS';
export const days = 'AUTOMATION.ACTIONS.UPDATE.DAYS';
export const create = 'AUTOMATION.EDITOR.CREATE';

const useAutomationTranslations = () => {
	const { t } = useTranslation();
	return {
		whenThisHappens: t('AUTOMATION.TRIGGER.PREFIX.WHEN_THIS_HAPPENS'),
		allowOverrideCondition: t('AUTOMATION.TRIGGER.MANUAL.DISPLAY_WARNING'),
		allowOverrideMatchingCondition: t(allowOverrideMatchingCondition),
		allowOverrideAllCondition: t(allowOverrideAllCondition),
		andOnlyIf: t(andOnlyIf),
		and: t(and),
		something: t(something),
		someAmount: t(someAmount),
		someReason: t(someReason),
		theCancelWithReason: t(theCancelWithReason),
		isKey: t(is),
		isNot: t(isNot),
		ofKey: t(ofKey),
		withKey: t('AUTOMATION.TRIGGER.PREFIX.WITH'),
		or: t('AUTOMATION.TRIGGER.PREFIX.OR'),
		to: t(to),
		from: t(from),
		when: t('AUTOMATION.TRIGGER.PREFIX.WHEN'),
		newAutomationButton: t('AUTOMATION.NEW_AUTOMATION_BUTTON'),
		automationTitle: t('AUTOMATION.TITLE'),
		tabs: {
			[AutomationsTab.AUTOMATION]: t('AUTOMATION.TAB_AUTOMATION'),
			[AutomationsTab.TEMPLATES]: t('AUTOMATION.TAB_TEMPLATES'),
			[AutomationsTab.SYSTEM_WORKFLOWS]: t('AUTOMATION.TAB_LEGACY')
		},
		that: t(that),
		noAutomation: t('AUTOMATION.EMPTY_STATE.NO_AUTOMATION'),
		checkOut: t('AUTOMATION.EMPTY_STATE.CHECK_OUT'),
		byClickingHere: t('AUTOMATION.EMPTY_STATE.BY_CLICKING_HERE'),
		comingSoon: t('AUTOMATION.EMPTY_STATE.COMING_SOON'),
		pageOnWork: t('AUTOMATION.EMPTY_STATE.PAGE_ON_WORK'),
		noLegacyAlerts: t('AUTOMATION.LEGACY_ALERTS.EMPTY_STATE.TITLE'),
		noLegacyDescriptionFirst: t('AUTOMATION.LEGACY_ALERTS.EMPTY_STATE.DESCRIPTION_FIRST'),
		noLegacyDescriptionLast: t('AUTOMATION.LEGACY_ALERTS.EMPTY_STATE.DESCRIPTION_LAST'),
		triggerTitle: t('AUTOMATION.TRIGGER.TITLE'),
		conditionsTitle: t(conditions),
		actionsTitle: t('AUTOMATION.ACTIONS.TITLE'),
		triggersPlaceholder: t('AUTOMATION.SEARCH_MENU.TRIGGERS_PLACEHOLDER'),
		actionsPlaceholder: t('AUTOMATION.SEARCH_MENU.ACTIONS_PLACEHOLDER'),
		editHeader: t('AUTOMATION.EDITOR.EDIT_HEADER'),
		unavailableActions: t(unavailableActions),
		newHeader: t('AUTOMATION.EDITOR.NEW_HEADER'),
		editorDefaultTitle: t('AUTOMATION.EDITOR.TITLE_PLACEHOLDER'),
		saveChanges: t('AUTOMATION.EDITOR.SAVE_CHANGES'),
		backConfirmPopup: {
			title: t('AUTOMATION.EDITOR.CONFIRM_DIALOG.TITLE'),
			exitEditTitle: t('AUTOMATION.EDITOR.CONFIRM_DIALOG.TITLE_INVALID'),
			content: t('AUTOMATION.EDITOR.CONFIRM_DIALOG.CONTENT'),
			keepEditing: t('AUTOMATION.EDITOR.CONFIRM_DIALOG.KEEP_EDITING')
		},
		create: t(create),
		backTo: prevTab =>
			t('AUTOMATION.EDITOR.BACK_TO', {
				prevTab
			}),
		teamsSelector: t('AUTOMATION.EDITOR.TEAMS_SELECTOR'),
		selectAll: t('MULTI_SELECT.SELECT_ALL'),
		discardChanges: t('AUTOMATION.EDITOR.CANCEL_CHANGES'),
		allTeams: t('AUTOMATION.EDITOR.ALL_TEAMS'),
		thenDoThis: t(thenDoThis),
		thenNotify: t(thenNotify),
		then: t(then),
		thenSendWebhook: t(thenSendWebhook),
		addTitle: t('AUTOMATION.ACTIONS.TITLE_PLACEHOLDER'),
		customerNotification: t('RULE_ENGINE.ACTION.SHARED_LOCATION.TITLE'),
		customerNotificationDescription: t('RULE_ENGINE.ACTION.SHARED_LOCATION.DESCRIPTION'),
		customerNotificationError: t('RULE_ENGINE.ACTION.SHARED_LOCATION.ERROR'),
		customerNotificationTextDescription: t('RULE_ENGINE.ACTION.SHARED_LOCATION.TEXT_DESCRIPTION'),
		customerNotificationSms: t('RULE_ENGINE.ACTION.SHARED_LOCATION.SMS'),
		customerNotificationWebhook: t('RULE_ENGINE.ACTION.SHARED_LOCATION.WEBHOOK'),
		customerNotificationPrivateModeTextDescription: t(
			'RULE_ENGINE.ACTION.SHARED_LOCATION.PRIVATE_MODE_TEXT_DESCRIPTION'
		),
		customerNotificationPrivateModeTooltip: t('RULE_ENGINE.ACTION.SHARED_LOCATION.PRIVATE_MODE_TOOLTIP'),
		emailCustomerNotification: {
			description: t('RULE_ENGINE.ACTION.EMAIL_BY_TEMPLATE.DESCRIPTION'),
			templateRequired: t('RULE_ENGINE.ACTION.EMAIL_BY_TEMPLATE.TEMPLATE_REQUIRED'),
			templatePlaceholder: t('RULE_ENGINE.ACTION.EMAIL_BY_TEMPLATE.TEMPLATE_PLACEHOLDER'),
			templatesManagerLink: t('RULE_ENGINE.ACTION.EMAIL_BY_TEMPLATE.TEMPLATES_MANAGER_LINK')
		},
		narrowTimeWindowBufferMinutes: t('RULE_ENGINE.ACTION.NARROW_ACTIONABLE_WP_TIME_WINDOW.MINUTES'),
		narrowTimeWindowBufferBy: t('RULE_ENGINE.ACTION.NARROW_ACTIONABLE_WP_TIME_WINDOW.BY'),
		alertTitleLabel: t('AUTOMATION.ACTIONS.TITLE_LABEL'),
		alertDescriptionLabel: t('AUTOMATION.ACTIONS.ADDITIONAL_TEXT_LABEL'),
		optimizationAction: t(optimizationAction),
		calcAvailabilityAction: t(calcAvailabilityAction),
		cancelTaskAction: t(cancelTaskAction),
		optimizationActionDescription: t('RULE_ENGINE.ACTION.OPTIMIZATION.DESCRIPTION'),
		doNotSaveExisting: t('AUTOMATION.ACTIONS.OPTIMIZATION.DO_NOT_SAVE_EXISTING'),
		saveExisting: t('AUTOMATION.ACTIONS.OPTIMIZATION.SAVE_EXISTING'),
		doNotKeepSequence: t('AUTOMATION.ACTIONS.OPTIMIZATION.DO_NOT_KEEP_SEQUENCE'),
		keepSequence: t('AUTOMATION.ACTIONS.OPTIMIZATION.KEEP_SEQUENCE'),
		daysAhead: t('RULE_ENGINE.ACTION.OPTIMIZATION.DAYS_AHEAD'),
		daysAheadPlaceholder: t('AUTOMATION.ACTIONS.DAYS_AHEAD.PLACEHOLDER'),
		timeRange: t('RULE_ENGINE.ACTION.OPTIMIZATION.TIME_RANGE'),
		daysAheadOptions: {
			today: t('AUTOMATION.ACTIONS.DAYS_AHEAD.TODAY'),
			tomorrow: t('AUTOMATION.ACTIONS.DAYS_AHEAD.TOMORROW'),
			twoDays: t('AUTOMATION.ACTIONS.DAYS_AHEAD.TWO_DAYS'),
			threeDays: t('AUTOMATION.ACTIONS.DAYS_AHEAD.THREE_DAYS'),
			fourDays: t('AUTOMATION.ACTIONS.DAYS_AHEAD.FOUR_DAYS'),
			fiveDays: t('AUTOMATION.ACTIONS.DAYS_AHEAD.FIVE_DAYS'),
			sixDays: t('AUTOMATION.ACTIONS.DAYS_AHEAD.SIX_DAYS'),
			sevenDays: t('AUTOMATION.ACTIONS.DAYS_AHEAD.SEVEN_DAYS')
		},
		placeholderTextDescription: t('AUTOMATION.ACTIONS.PLACEHOLDER_TEXT_DESCRIPTION'),
		alertTitleError: t('AUTOMATION.ACTIONS.TITLE_ERROR_MSG'),
		placeholderError: match =>
			t('AUTOMATION.ACTIONS.TITLE_ERROR_PLACEHOLDER_MSG', {
				match
			}),
		via: t(via),
		every: t(every),
		day: t(day),
		at: t(at),
		multipleChoice: t(multipleChoice),
		somethingHappens: t('AUTOMATION.TRIGGER.PREFIX.SOMETHING_HAPPENS'),
		onlyPositive: t('ALERTS_CONFIGURATION.CONDITIONS.VALIDATION.ONLY_POSITIVE'),
		toGreaterThanFrom: t('ALERTS_CONFIGURATION.CONDITIONS.VALIDATION.TO_GREATER_THAN_FROM'),
		success: {
			savedWorkflowSuccessfully: t('AUTOMATION.SAVE_WORKFLOWS_SUCCESSFULLY'),
			workflowDeleted: t('AUTOMATION.WORKFLOW_DELETED_SUCCESSFULLY'),
			workflowDuplicated: t('AUTOMATION.WORKFLOW_DUPLICATED_SUCCESSFULLY')
		},
		error: {
			failedToLoadWorkflows: t('ALERTS_CONFIGURATION.FAILED_TO_LOAD_WORKFLOWS'),
			failedToLoadTemplates: t('ALERTS_CONFIGURATION.FAILED_TO_LOAD_TEMPLATES'),
			failedToSaveWorkflow: t('AUTOMATION.FAILED_TO_SAVE_WORKFLOWS'),
			failedToDeleteWorkflow: t('AUTOMATION.FAILED_TO_DELETE_WORKFLOW'),
			failedToDuplicateWorkflow: t('AUTOMATION.FAILED_TO_DUPLICATE_WORKFLOW'),
			failedToCreateWorkflowFromTemplate: t('AUTOMATION.FAILED_TO_CREATE_WORKFLOW_FROM_TEMPLATE'),
			failedToCreatePredefined: t('ALERTS_CONFIGURATION.ALERT_FAILED_TO_SAVE')
		},
		filtering: {
			automation: {
				search: t('AUTOMATION.FILTERING.SEARCH_PLACEHOLDER'),
				actions: t('AUTOMATION.ACTIONS.TITLE'),
				status: {
					title: t('AUTOMATION.FILTERING.STATUS.NAME'),
					active: t('AUTOMATION.FILTERING.STATUS.ACTIVE'),
					inactive: t('AUTOMATION.FILTERING.STATUS.INACTIVE')
				},
				teams: t('AUTOMATION.FILTERING.TEAMS.PLACEHOLDER'),
				triggerFamily: t('AUTOMATION.TRIGGER.TITLE_FAMILY')
			},

			templates: {
				search: t('AUTOMATION.FILTERING.SEARCH_PLACEHOLDER'),
				actions: t('AUTOMATION.ACTIONS.TITLE'),
				templateType: {
					title: t('AUTOMATION.FILTERING.TEMPLATE_TYPE.TITLE'),
					legacy: t('AUTOMATION.TAB_LEGACY'),
					custom: t('AUTOMATION.FILTERING.TEMPLATE_TYPE.CUSTOM')
				}
			}
		},
		sorting: {
			lastModified: t('AUTOMATION.SORTING.LAST_MODIFIED'),
			dateCreated: t('AUTOMATION.SORTING.DATE_CREATED'),
			prefix: t('AUTOMATION.SORTING.PREFIX')
		},
		grouping: {
			none: t('AUTOMATION.GROUPING.NONE'),
			trigger: t('AUTOMATION.TRIGGER.TITLE'),
			action: t('AUTOMATION.ACTIONS.TITLE'),
			recurringTimeZone: t('AUTOMATION.TRIGGER.RECURRING_TIME'),
			triggerFamily: t('AUTOMATION.TRIGGER.TITLE_FAMILY'),
			prefix: t('AUTOMATION.GROUPING.PREFIX')
		},
		thenUpdate: t(thenUpdate),
		update: t(update),
		toAlsoHave: t('AUTOMATION.ACTIONS.PREFIX.TO_ALSO_HAVE'),
		current: t('AUTOMATION.ACTIONS.PREFIX.CURRENT'),
		relativeValue: t(relativeValue),
		specificValue: t('AUTOMATION.ACTIONS.PREFIX.SPECIFIC'),
		active: t('AUTOMATION.ACTIVE'),
		duplicate: t('GLOBAL.DUPLICATE'),
		delete: t('GLOBAL.DELETE'),
		teams: t('AUTOMATION.TEAMS'),
		createdBy: t('AUTOMATION.CREATED_BY'),
		updatedBy: t('AUTOMATION.UPDATED_BY'),
		createdDate: t('AUTOMATION.CREATED_DATE'),
		updated: t('AUTOMATION.UPDATED'),
		notify: t('AUTOMATION.NOTIFY'),
		alert: t('GLOBAL.ALERT'),
		updateTask: t('AUTOMATION.UPDATE_TASK'),
		showMore: t('AUTOMATION.SHOW_MORE'),
		showLess: t('AUTOMATION.SHOW_LESS'),
		readOnly: t('GLOBAL.READ_ONLY'),
		readOnlyInfo: t('AUTOMATION.READ_ONLY_INFO'),
		lengthSummary: (total: number): string => {
			return t('AUTOMATION.TOTAL_NUMBER', {
				total
			});
		},
		workflowsByView: {
			title: t('AUTOMATION.BY_VIEW.TITLE'),
			help: view => t(`AUTOMATION.BY_VIEW.HELP.${view}`),
			views: view => t(`AUTOMATION.BY_VIEW.VIEWS.${view}`),
			descriptionPrefix: t('AUTOMATION.BY_VIEW.DESCRIPTION.PREFIX'),
			descriptionSuffix: (data: { count: number; view: string }) =>
				t('AUTOMATION.BY_VIEW.DESCRIPTION.SUFFIX', data),
			emptyState: {
				prefix: t('AUTOMATION.BY_VIEW.EMPTY_STATE.PREFIX'),
				suffix: view => t('AUTOMATION.BY_VIEW.EMPTY_STATE.SUFFIX', { view }),
				automationRules: t('AUTOMATION.BY_VIEW.EMPTY_STATE.AUTOMATION_RULES'),
				templatesMenu: t('AUTOMATION.BY_VIEW.EMPTY_STATE.TEMPLATES_MENU')
			}
		},
		noAutomationFound: t('AUTOMATION.FILTERING.NO_RESULTS'),
		noAutomationFoundDescription: t('AUTOMATION.FILTERING.NO_RESULTS_DESCRIPTION'),
		calculateAvailabilityDescription: t('AUTOMATION.ACTIONS.CALCULATE_AVAILABILITY_STATE.DESCRIPTION'),
		manual: {
			manualTriggerCalled: t('AUTOMATION.TRIGGER.MANUAL.WHEN_MANUAL_TRIGGER_CALLED'),
			manualCalledWithInputs: t(withInputs),
			required: t('AUTOMATION.TRIGGER.MANUAL.FIELD_REQUIRED'),
			select: t('AUTOMATION.TRIGGER.MANUAL.SELECT'),
			fieldName: t('AUTOMATION.TRIGGER.MANUAL.FIELD_NAME'),
			fieldType: t('AUTOMATION.TRIGGER.MANUAL.FIELD_TYPE'),
			nonUniqueFieldError: t('AUTOMATION.TRIGGER.MANUAL.NON_UNIQUE'),
			typePlaceholder: t(typePlaceholder)
		},
		minutes: t(minutes),
		hours: t(hours),
		days: t(days),
		forceManualModalAction: {
			title: t('AUTOMATION.TRIGGER.MANUAL.MODAL.TITLE'),
			description: t('AUTOMATION.TRIGGER.MANUAL.MODAL.DESCRIPTION'),
			confirmText: t('AUTOMATION.TRIGGER.MANUAL.MODAL.CONFIRM_FORCE_ACTION_TEXT'),
			runOnMatching: t('AUTOMATION.TRIGGER.MANUAL.MODAL.APPLY_ON_MATCHING'),
			runOnAll: t('AUTOMATION.TRIGGER.MANUAL.MODAL.APPLY_ON_ALL'),
			dismiss: t('AUTOMATION.TRIGGER.MANUAL.MODAL.CANCEL')
		},
		rounded: t(rounded),
		notRounded: t(notRounded),
		nearest: t(nearest)
	};
};

export default useAutomationTranslations;
