import { useCallback, useMemo } from 'react';

import useFilterQuery from './use-filter-query';
import { UpdateURLStrategy } from './types';

interface Options<T> {
	resetOtherParamsOnChange?: boolean;
	updateURLStrategy?: UpdateURLStrategy;
	defaultValue?: T;
	defaultOptions?: { updateSearch: false } | { updateSearch: true; resetOthersParams?: boolean };
}

const useSearchParam = <T>(
	param: string,
	{
		defaultValue,
		resetOtherParamsOnChange = false,
		updateURLStrategy = UpdateURLStrategy.ADD,
		defaultOptions
	}: Options<T> = {}
): [T, (valueOrSetter: T | ((prev: T) => T)) => void] => {
	const paramArray = useMemo(() => [param], [param]);

	const { filterObject, setFilterObject } = useFilterQuery<Record<string, T>>({
		trackedKeys: paramArray,
		defaultOptions,
		defaultValue: defaultValue ? { [param]: defaultValue } : {},
		resetUntrackedParamsOnChange: resetOtherParamsOnChange,
		updateURLStrategy
	});

	const setFilterParam = useCallback(
		(valueOrSetter: T | ((prev: T) => T)) => {
			if (typeof valueOrSetter === 'function') {
				setFilterObject(prev => {
					return { [param]: (valueOrSetter as (prev: T) => T)(prev[param]) };
				});
				return;
			}

			setFilterObject({ [param]: valueOrSetter });
		},
		[setFilterObject, param]
	);

	return [filterObject[param], setFilterParam];
};

export default useSearchParam;
