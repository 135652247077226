import React, { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { Modal, Select, Option } from '@bringg/react-components';
import { DeliveryBlock } from '@bringg/types';
import { sortByString } from '@bringg-frontend/utils';

import useStores from 'bringg-web/recipes/use-stores';
import useTimeFormat from 'bringg-web/hooks/use-time-format';

import './select-driver-team.scss';

export interface ModalParams {
	driverUserOverlapTeamsIds: number[];
	driverId: number;
	onCloseModal: (isSuccess: boolean) => void;
}
export interface Props {
	onClose: () => void;
	modalParams: ModalParams;
}

const SelectDriverTeam: React.FC<Props> = (props: Props) => {
	const { onClose, modalParams } = props;
	const { teamsStore, driversStore, merchantConfigurationsStore } = useStores();
	const { t } = useTranslation();
	const [teamsOptions, setTeamsOptions] = useState([]);
	const [selectedTeamId, setSelectedTeamId] = useState(undefined);
	const hourFormat = useTimeFormat();

	const teamTimezone = (teamId: number, time?: Date | string) => {
		const team = teamsStore.get(teamId);

		const checkTime = time || moment();
		return moment(checkTime).tz(team.time_zone);
	};

	const getCurrentDeliveryBlock = (deliveriesBlocks: DeliveryBlock[]) => {
		const relevantDeliveryBlock = deliveriesBlocks.find(block => {
			const isDeliveryBlockNow = teamTimezone(block.team_id).isBetween(
				teamTimezone(block.team_id, block.start_time).subtract(1, 'hour'),
				block.end_time
			);
			if (isDeliveryBlockNow) {
				return block;
			}
			return undefined;
		});
		return relevantDeliveryBlock;
	};

	const getNextDeliveryBlock = (currentActiveDeliveryBlock, checkedBlocked) => {
		const currentActiveDeliveryBlockTime = teamTimezone(
			currentActiveDeliveryBlock.team_id,
			currentActiveDeliveryBlock.start_time
		);

		const checkedBlockTime = teamTimezone(checkedBlocked.team_id, checkedBlocked.start_time);

		const currentSelectedBlockDiffFromNow = currentActiveDeliveryBlockTime.diff(
			teamTimezone(currentActiveDeliveryBlock.team_id)
		);
		const checkedBlockDiffFromNow = checkedBlockTime.diff(teamTimezone(currentActiveDeliveryBlock.team_id));
		if (checkedBlockDiffFromNow < currentSelectedBlockDiffFromNow && checkedBlockDiffFromNow > 0) {
			return checkedBlocked;
		}
		if (currentSelectedBlockDiffFromNow > 0) {
			return currentActiveDeliveryBlock;
		}

		return undefined;
	};

	const getRelevantDeliveryBlock = (deliveryDictionaryByTeam, checkedBlocked) => {
		const currentActiveDeliveryBlock = deliveryDictionaryByTeam[checkedBlocked.team_id];
		const activeDeliveryBlock = getCurrentDeliveryBlock([currentActiveDeliveryBlock, checkedBlocked]);
		if (activeDeliveryBlock) {
			return activeDeliveryBlock;
		}
		return getNextDeliveryBlock(currentActiveDeliveryBlock, checkedBlocked);
	};

	const getDeliveryBlockByTeam = async () => {
		const driverDeliveryBlock = await driversStore.fetchDriverTodayDeliveryBlock(modalParams.driverId);
		const deliveryDictionaryByTeam = {};
		driverDeliveryBlock.forEach(block => {
			if (deliveryDictionaryByTeam[block.team_id]) {
				const activeDeliveryBlock = getRelevantDeliveryBlock(deliveryDictionaryByTeam, block);
				if (activeDeliveryBlock) {
					deliveryDictionaryByTeam[block.team_id] = activeDeliveryBlock;
				}
			} else if (teamTimezone(block.team_id, block.end_time).isAfter(teamTimezone(block.team_id))) {
				deliveryDictionaryByTeam[block.team_id] = block;
			}
		});
		return deliveryDictionaryByTeam;
	};

	const getFormatHour = (time, timeZone) => {
		return `${moment(time).tz(timeZone).format(hourFormat)}`;
	};

	const createDropDownOptions = deliveryDictionaryByTeam => {
		return modalParams.driverUserOverlapTeamsIds.map(teamId => {
			const team = teamsStore.get(teamId);
			const startTime = deliveryDictionaryByTeam[teamId]?.start_time;
			const endTime = deliveryDictionaryByTeam[teamId]?.end_time;
			const deliveryBlock = startTime
				? `${getFormatHour(startTime, team.time_zone)} - ${getFormatHour(endTime, team.time_zone)}`
				: '';
			return { id: teamId, name: team.name, deliveryBlock };
		});
	};

	const createTeamsOptions = async () => {
		const teamsOptionsForDropDown = [];
		if (merchantConfigurationsStore.configuration.allow_start_shift_on_all_teams) {
			teamsOptionsForDropDown.push({
				id: null,
				name: t('DRIVERS.SELECT_DRIVER_TEAM_MODAL.ALL_TEAMS'),
				deliveryBlock: ''
			});
			setSelectedTeamId(null);
		}
		try {
			const [deliveryDictionaryByTeam] = await Promise.all([getDeliveryBlockByTeam(), teamsStore.fetchAll()]);
			const teams = createDropDownOptions(deliveryDictionaryByTeam).sort((firstItem, secondItem) =>
				sortByString(firstItem, secondItem, 'name')
			);

			setTeamsOptions([...teamsOptionsForDropDown, ...teams]);
		} catch (e) {
			console.error(e);
		}
	};

	useEffect(() => {
		createTeamsOptions();
	}, []);

	const onOkModal = async () => {
		const isSuccess = await driversStore.startDriverShift(modalParams.driverId, selectedTeamId);
		modalParams.onCloseModal(isSuccess);
		if (isSuccess) {
			onClose();
		}
	};

	return (
		<Modal
			visible
			okText={t('DRIVERS.SELECT_DRIVER_TEAM_MODAL.SELECT')}
			onCancel={onClose}
			data-test-id="select-driver-modal"
			width={430}
			onOk={onOkModal}
			mask={false}
			okButtonProps={{ disabled: selectedTeamId === undefined }}
		>
			<div className="connected-multiple-text">
				<div>{t('DRIVERS.SELECT_DRIVER_TEAM_MODAL.DRIVER_CONNECTED_MULTIPLE_TEAMS')}</div>
				<div>{t('DRIVERS.SELECT_DRIVER_TEAM_MODAL.CHOOSE_DRIVER_FOR_SHIFT')}</div>
			</div>
			<Select
				placeholder={t('DRIVERS.SELECT_DRIVER_TEAM_MODAL.SELECT_TEAM')}
				className="team-select"
				value={selectedTeamId}
				onSelect={teamId => setSelectedTeamId(teamId)}
			>
				{teamsOptions.map(team => (
					<Option key={team.id} value={team.id} className="team-select-option" data-test-id={team.name}>
						<div>{team.name}</div>
						<div>{team.deliveryBlock}</div>
					</Option>
				))}
			</Select>
		</Modal>
	);
};

export default SelectDriverTeam;
