import React from 'react';
import { Task, WayPoint } from '@bringg/types';
import { Translate } from '../../../translation';
import { DateDisplay } from '@bringg/react-components/dist/components';
import { useObserver } from 'mobx-react';

interface TaskPopupProps {
	task: Task;
}

const DATE_FORMAT = 'L LT';

const TaskWayPoints: React.FC<{ wayPoints: WayPoint[] }> = ({ wayPoints }) => {
	const taskWayPoints = wayPoints.map(wayPoint => <TaskWayPoint key={wayPoint.id} wayPoint={wayPoint} />);

	return <ul className="dest-marker-way-points">{taskWayPoints}</ul>;
};

const TaskWayPoint: React.FC<{ wayPoint: WayPoint }> = ({ wayPoint }) => (
	<li className="dest-marker-way-point">
		{wayPoint.scheduled_at && (
			<span>
				<DateDisplay format={DATE_FORMAT} date={wayPoint.scheduled_at} />
				:&nbsp;
			</span>
		)}
		<span>{wayPoint.address}</span>
	</li>
);

const TaskPopup: React.FC<TaskPopupProps> = ({ task }) => {
	const customerName = task.customer?.name;
	const userName = task.user?.name;

	return useObserver(() => (
		<div className="marker-tooltip">
			{task.title && <h4>{task.title}</h4>}
			{task.address && <span>({task.address})</span>}
			ID: <span style={{ textDecoration: 'underline' }}>{task.external_id}</span>
			{customerName && (
				<div>
					<Translate text="ORDER.CUSTOMER_NAME" />: {customerName}
				</div>
			)}
			{userName && (
				<div>
					<Translate text="ORDER.DRIVER_NAME" />: {userName}
				</div>
			)}
			<div className="dest-marker-separator"></div>
			{task.way_points.length} <Translate text="DISPATCH.STOPS_ALONG_THE_WAY" />:
			<TaskWayPoints wayPoints={task.way_points} />
		</div>
	));
};

export default TaskPopup;
