import React from 'react';

import { Portal } from '@bringg-frontend/bringg-web-infra';
import { DynamicFormModal } from '@bringg-frontend/dynamic-form-modal';

const DynamicFormModalPortal = props => {
	return <Portal element={<DynamicFormModal {...props} />} nodeId={'dynamic-form-modal-node'} />;
};

export default DynamicFormModalPortal;
