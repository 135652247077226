import React, { useCallback, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useObserver } from 'mobx-react';
import _uniq from 'lodash/uniq';
import _pull from 'lodash/pull';
import _isNumber from 'lodash/isNumber';
import { Modal } from '@bringg/react-components';
import { BringgFontIcons, BringgIcon } from '@bringg/bringg-icons';

import bringgNotification from '../../../services/notification';
import DeliveryBlock from '../stores/domain-objects/delivery-block';
import Driver from '../../../stores/drivers/domain-object/driver';
import ControlledAssignComponent from './controlled-assign-component';
import { Translate } from '../../../translation';

import './delivery-block-driver-assign.scss';

interface Props {
	deliveryBlock: DeliveryBlock;
	availableDrivers: Driver[];
	unavailableDrivers: Driver[];
	driver?: Driver;
	isNewModalOfDeliveryBlock?: boolean;
}

export const getWarningIcon = () => {
	return (
		<BringgIcon
			data-test-id="warning-icon"
			className="delivery-block-select-driver-info-icon"
			iconName={BringgFontIcons.Info}
		/>
	);
};

const DeliveryBlockDriverAssign: React.FC<Props> = ({
	deliveryBlock,
	availableDrivers,
	unavailableDrivers,
	driver,
	isNewModalOfDeliveryBlock = false
}) => {
	const { t } = useTranslation();
	const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
	const [driverIdToAssign, setDriverToAssign] = useState<number>(null);
	const [isLoading, setIsLoading] = useState<boolean>(false);

	const toggleModal = () => setIsModalOpen(isOpen => !isOpen);

	const assignDriver = useCallback(
		async (driverId: number, confirmed = false) => {
			try {
				let payload = {};
				if (_isNumber(driverId)) {
					const isUnavailableDriver = unavailableDrivers.find(({ id }) => driverId === id);

					if (isUnavailableDriver && !confirmed) {
						setDriverToAssign(driverId);
						toggleModal();
						return;
					}

					if (isNewModalOfDeliveryBlock) {
						const resource = deliveryBlock.delivery_block_resources.find(
							currentResource =>
								currentResource.user_id === driver?.id || (!driver && currentResource.user_id === null)
						);
						if (resource) {
							resource.user_id = driverId;
						}
					} else {
						const userIds = _uniq([...deliveryBlock.user_ids, driverId]);
						payload = {
							user_ids: driver ? _pull(userIds, driver.id) : userIds
						};
					}

					await deliveryBlock.viewTransactionUpdate(payload);
					bringgNotification.success(t('DELIVERY_BLOCKS.ASSIGN_MESSAGE'));
					return;
				}

				if (!driver) {
					return;
				}

				if (isNewModalOfDeliveryBlock) {
					const resource = deliveryBlock.delivery_block_resources.find(
						currentResource => currentResource.user_id === driver.id
					);
					if (resource) {
						resource.user_id = null;
					}
				} else {
					payload = { user_ids: _pull(deliveryBlock.user_ids, driver.id) as number[] };
				}

				await deliveryBlock.viewTransactionUpdate(payload);
				bringgNotification.success(t('DELIVERY_BLOCKS.UNASSIGN_MESSAGE'));
			} catch (error) {
				console.error(error);
				bringgNotification.error(t('DELIVERY_BLOCKS.ASSIGN_MESSAGE_FAILED'));
			}
		},
		[driver, isNewModalOfDeliveryBlock, deliveryBlock, t, unavailableDrivers]
	);

	const handleModalUnavailableDriverAssign = async () => {
		setIsLoading(true);
		await assignDriver(driverIdToAssign, true);
		setIsLoading(false);
		setDriverToAssign(null);
		toggleModal();
	};

	const handleModalCancel = () => {
		setDriverToAssign(null);
		toggleModal();
	};

	const getDriverItem = (driverItem): JSX.Element => {
		return (
			<div className="driver-item">
				{driverItem?.name}
				{unavailableDrivers.find(unavailableDriver => unavailableDriver.id === driverItem?.id) ? (
					getWarningIcon()
				) : (
					<></>
				)}
			</div>
		);
	};

	return useObserver(() => {
		const drivers: Driver[] = availableDrivers.concat(driver || [], unavailableDrivers);

		return (
			<div>
				<div className="assign-component-controller">
					<ControlledAssignComponent
						items={drivers}
						renderOption={driver => getDriverItem(driver)}
						selectedItemId={driver?.id}
						onSelectedItem={assignDriver}
						translations={{ unassigned: t('GLOBAL.UNASSIGNED'), selectItem: t('GLOBAL.UNASSIGNED') }}
					/>
				</div>

				<Modal
					confirmLoading={isLoading}
					title={t('GLOBAL.UNAVAILABLE_DRIVERS')}
					visible={isModalOpen}
					onCancel={handleModalCancel}
					onOk={handleModalUnavailableDriverAssign}
					okText={t('GLOBAL.PROCEED')}
					cancelText={t('GLOBAL.CANCEL')}
				>
					<Translate text="DELIVERY_BLOCKS.DRIVER_NOT_AVAILABLE_MESSAGE" />
				</Modal>
			</div>
		);
	});
};

export default DeliveryBlockDriverAssign;
