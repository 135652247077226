import { getRootEnv } from '@bringg-frontend/bringg-web-infra';
import moment, { MomentInput } from 'moment';
import { action, computed, observable, makeObservable } from 'mobx';
import { first, groupBy, last } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { BringgException } from '@bringg/dashboard-sdk/dist/Core/BringgException';
import { ChatConversationType, ChatConversationTypeWithNames, ChatMessage, ChatMessageType, User } from '@bringg/types';

import { chatRootStore } from 'bringg-web/features/chat/stores/chat-root-store';

export const CHAT_MESSAGE_STATUS = {
	NEW_MESSAGES: 'new_messages',
	OLD_MESSAGES: 'old_messages'
};

export const ITEMS_NUM = 40;

export type ChatMessageRequest = {
	page: number;
	id: number;
	before?: MomentInput;
	after?: MomentInput;
	items: number;
};

class ChatConversation {
	id: number;
	title?: string;
	image_url?: string;
	task_id?: number;
	external_id?: string;
	driver_id?: number;
	driver?: User;
	created_at: MomentInput;
	conversation_type: ChatConversationTypeWithNames;
	user_ids: number[];
	last_read: string;
	messages?: ChatMessage[] = [];

	isFetched?: boolean = false;
	unread_count = 0;

	users?: User[] = [];
	last_message: ChatMessage = null;

	hasMoreOldMessages?: boolean = false;
	hasMoreNewMessages?: boolean = true;

	messagesByDays: { [k: string]: ChatMessage[] } = null;

	constructor(conversation: Bringg.ChatConversation) {
		makeObservable(this, {
			isFetched: observable,
			unread_count: observable,
			users: observable,
			last_message: observable,
			hasMoreOldMessages: observable,
			hasMoreNewMessages: observable,
			messagesByDays: observable,
			setUsers: action,
			addUser: action,
			setMessages: action,
			setOldMessagesStatus: action,
			setNewMessagesStatus: action,
			setFetched: action,
			resetUnreadCount: action,
			addUnreadCount: action,
			updateLastMessage: action,
			getImageUrl: computed,
			getTitle: computed
		});

		Object.assign(this, conversation);
		this.fetchUserIfNotExist(conversation.driver_id);
	}

	setUsers(users: User[]) {
		this.users = users;
	}

	addUser(user: User) {
		this.users.push(user);
	}

	setMessages(messages: ChatMessage[]) {
		this.messages = messages;
		this.messagesByDays = groupBy(messages, message =>
			moment(message.server_timestamp).startOf('day').toISOString()
		);
	}

	setOldMessagesStatus(hasNextPage: boolean) {
		this.hasMoreOldMessages = hasNextPage;
	}

	setNewMessagesStatus(hasNextPage: boolean) {
		this.hasMoreNewMessages = hasNextPage;
	}

	setFetched() {
		this.isFetched = true;
	}

	resetUnreadCount() {
		this.unread_count = 0;
	}

	addUnreadCount() {
		this.unread_count += 1;
	}

	updateLastMessage(lastMessage?: ChatMessage) {
		this.last_message = lastMessage || last(this.messages);
	}

	get getImageUrl(): string {
		let imageUrl = this.image_url;

		if (this.isConversationTypeEqual(this.conversation_type, ChatConversationType.driver_and_team_dispatchers)) {
			const driver = this.getUser(this.driver_id);

			imageUrl = driver && driver.profile_image;
		}

		return imageUrl;
	}

	get getTitle(): string {
		let { title } = this;

		if (this.isConversationTypeEqual(this.conversation_type, ChatConversationType.driver_and_team_dispatchers)) {
			const driver = this.getUser(this.driver_id);

			title = driver && driver.name;
		}

		if (this.isConversationTypeEqual(this.conversation_type, ChatConversationType.task)) {
			title = `#${this.external_id || this.task_id.toString()}`;
		}

		return title;
	}

	async getMessages() {
		if (this.isFetched) {
			this.handleMessagesFetched();
		} else {
			await this.handleMessagesFetching();
		}
	}

	async handleMessagesFetching() {
		if (this.unread_count === 0) {
			const before = moment(this.last_read).toISOString();
			await this.fetchMessages(CHAT_MESSAGE_STATUS.OLD_MESSAGES, {
				page: 1,
				id: this.id,
				before,
				items: ITEMS_NUM
			});
			this.setFetched();
			chatRootStore.getStore().chatView.scrollBottom();
		} else {
			const lastRead = moment(this.last_read).toISOString();

			await this.fetchMessages(CHAT_MESSAGE_STATUS.NEW_MESSAGES, {
				page: 1,
				id: this.id,
				after: lastRead,
				items: this.unread_count > ITEMS_NUM ? this.unread_count : ITEMS_NUM
			});
			await this.fetchMessages(CHAT_MESSAGE_STATUS.OLD_MESSAGES, {
				page: 1,
				id: this.id,
				before: lastRead,
				items: ITEMS_NUM
			});
			this.setFetched();
		}

		this.handleMessagesFetched();
	}

	async fetchMessages(type: string, request: ChatMessageRequest) {
		const { chatView } = chatRootStore.getStore();

		chatView.setFetching(true);

		const response = await getRootEnv().dashboardSdk.sdk.chat.fetchMessages(request);
		const { has_next_page } = response;

		chatView.setFetching(false);

		if (type === CHAT_MESSAGE_STATUS.OLD_MESSAGES) {
			this.setOldMessagesStatus(has_next_page);
		}
		if (type === CHAT_MESSAGE_STATUS.NEW_MESSAGES) {
			this.setNewMessagesStatus(has_next_page);
		}

		return response;
	}

	async loadUsers() {
		const promises = [];

		this.user_ids.forEach(id => promises.push(this.fetchUser(id)));

		const users = await Promise.all(promises);

		this.setUsers(users);
	}

	async fetchUserIfNotExist(userId: string | number) {
		try {
			if (userId && !this.getUser(userId)) {
				const user = await this.fetchUser(userId);
				this.addUser(user);
			}
		} catch (error) {
			if ((error as BringgException).statusCode !== 200) throw error;
		}
	}

	async fetchOlderMessages() {
		if (this.hasMoreOldMessages && !chatRootStore.getStore().chatView.isFetching) {
			const firstMessage: Partial<ChatMessage> = first(this.messages) || {};
			const response = await this.fetchMessages(CHAT_MESSAGE_STATUS.OLD_MESSAGES, {
				page: 1,
				id: this.id,
				items: ITEMS_NUM,
				before: firstMessage.server_timestamp
			});

			chatRootStore.getStore().chatView.chat_ref.scrollTo({
				top: 78 * response.messages.length,
				behavior: 'auto'
			});
		}
	}

	async fetchNewerMessages() {
		if (this.hasMoreNewMessages && !chatRootStore.getStore().chatView.isFetching) {
			const lastMessage: Partial<ChatMessage> = last(this.messages) || {};
			await this.fetchMessages(CHAT_MESSAGE_STATUS.NEW_MESSAGES, {
				page: 1,
				id: this.id,
				items: ITEMS_NUM,
				after: lastMessage.server_timestamp
			});
		}
	}

	async fetchUser(id: string | number): Promise<User> {
		return getRootEnv().dashboardSdk.sdk.users.get(Number(id));
	}

	hasMessage(id): boolean {
		return Boolean(this.messages.find(message => message.id === id));
	}

	getUser(id: number | string): User {
		return this.users.find(user => user.id === id);
	}

	sendMessage = async () => {
		if (chatRootStore.getStore().chatView.newMessageText.length < 1) {
			return;
		}

		const request = {
			type: ChatMessageType.TEXT,
			uuid: uuidv4(),
			client_timestamp: new Date().toISOString(),
			payload: { text: chatRootStore.getStore().chatView.newMessageText },
			chat_conversation_id: this.id
		};

		await getRootEnv().dashboardSdk.sdk.chat.createMessages([request]);

		this.updateLastMessage();
		chatRootStore.getStore().chatView.scrollBottom();
		chatRootStore.getStore().chatView.setText('');
	};

	updateLastRead() {
		getRootEnv().dashboardSdk.sdk.chat.updateLastRead(this.id, new Date().toISOString());
	}

	handleMessagesFetched() {
		this.setMessages(getRootEnv().dashboardSdk.sdk.chat.getChatMessages(this.id));

		if (this.unread_count === 0) {
			chatRootStore.getStore().chatView.scrollBottom();
		}
	}

	isConversationTypeEqual = (type: ChatConversationTypeWithNames, otherType: ChatConversationType) => {
		return type === otherType || type === ChatConversationType[otherType];
	};
}

export default ChatConversation;
