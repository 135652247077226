import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { ReadOnlyMessage, Button, Spinner } from '@bringg/react-components';
import { BringgFontIcons, BringgIcon } from '@bringg/bringg-icons';
import { useSticky } from 'react-table-sticky';
import { useSortBy, useTable, useFlexLayout, useResizeColumns, useFilters } from 'react-table';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import type { CustomAttributeRes } from '@bringg/types';
import { useOnScreen } from '@bringg-frontend/hooks';

import { BringgReactTable, BasicTableContainer, ListChildRow } from 'bringg-web/components/bringg-react-table';
import { AttributeDetailsModal, FiltersPanel } from './components';
import { useStores } from '../../recipes';
import notification from 'bringg-web/services/notification';
import { filterTypes, tableColumns } from './utils';
import { useTableFilters, useControlledTableState } from './hooks';
import { alphanumericCaseInsensitiveSortFunction } from 'bringg-web/components/bringg-react-table/utils';

import './styles.scss';

const sortTypes = {
	// this custom alphanumeric func overrides native func in react-table
	alphanumeric: alphanumericCaseInsensitiveSortFunction
};

export const CustomAttributes = observer(() => {
	const {
		customAttributesStore: {
			fetchAll,
			all: allAttributes,
			isLoading,
			get: getAttributeById,
			createAttribute,
			updateAttribute,
			updateMetadataInWorkflows
		}
	} = useStores();
	const { t } = useTranslation();

	const [modalVisible, setModalVisible] = useState(false);
	const [selectedAttribute, setSelectedAttribute] = useState<CustomAttributeRes>(null);

	const isSuggestedAttributesAppears = useMemo(() => {
		if (!allAttributes.length) {
			return false;
		}

		return allAttributes.some(attr => attr.suggestion);
	}, [allAttributes]);

	const componentContainerRef = useRef<HTMLDivElement>(null);
	const tableContainerRef = useRef<HTMLDivElement>(null);
	const isVisible = useOnScreen(componentContainerRef);

	useEffect(() => {
		fetchAll();

		return () => {
			updateMetadataInWorkflows();
		};
	}, []);

	const onCreateModal = useCallback(() => {
		setModalVisible(true);
	}, []);

	const handleSubmit = useCallback(async (attribute: CustomAttributeRes) => {
		const action = attribute.id ? update : create;

		await action(attribute);
	}, []);

	const create = async (attribute: CustomAttributeRes) => {
		try {
			await createAttribute(attribute);
			notification.success(t('CUSTOM_ATTRIBUTES.SUCCESSFULLY_CREATED'));

			handleClose();
		} catch (error) {
			notification.error((error as any)?.data?.title ?? t('CUSTOM_ATTRIBUTES.FAILED_CREATE'));
		}
	};

	const update = async (attribute: CustomAttributeRes) => {
		try {
			await updateAttribute(attribute);

			const notificationMessage = attribute.suggestion
				? t('CUSTOM_ATTRIBUTES.SUCCESSFULLY_CREATED')
				: t('CUSTOM_ATTRIBUTES.SUCCESSFULLY_UPDATED');
			notification.success(notificationMessage);

			handleClose();
		} catch (error) {
			notification.error((error as any)?.data?.title ?? t('CUSTOM_ATTRIBUTES.FAILED_UPDATE'));
		}
	};

	const handleClose = useCallback(() => {
		setModalVisible(false);
		setSelectedAttribute(null);
	}, []);

	const openEditModal = useCallback((id: number) => {
		setSelectedAttribute(getAttributeById(id));
		setModalVisible(true);
	}, []);

	const columns = useMemo(() => {
		return tableColumns(t, openEditModal);
	}, [t, openEditModal]);

	const tableFiltersState = useTableFilters();

	const useControlledState = useControlledTableState(
		tableFiltersState.filters,
		isVisible,
		isLoading,
		tableContainerRef,
		columns
	);

	const tableInstance = useTable<CustomAttributeRes>(
		{
			filterTypes,
			data: allAttributes,
			columns,
			sortTypes,
			useControlledState
		},
		useFlexLayout,
		useFilters,
		useSortBy,
		useSticky,
		useResizeColumns
	);

	return (
		<>
			<div className="custom-attributes" ref={componentContainerRef}>
				<div className="custom-attributes-header">
					<h4>{t('CUSTOM_ATTRIBUTES.TITLE')}</h4>
					<p>
						{t('CUSTOM_ATTRIBUTES.DESCRIPTION')}{' '}
						<a
							className="color-like-a-link"
							href="https://help.bringg.com/docs/custom-attribute"
							target="_blank"
							rel="noopener noreferrer"
						>
							{t('CUSTOM_ATTRIBUTES.LEARN_MORE')}
						</a>
					</p>
				</div>
				{isSuggestedAttributesAppears && (
					<ReadOnlyMessage message={t('CUSTOM_ATTRIBUTES.SUGGESTED_ATTRIBUTES_NOTICE')} showMessage />
				)}
				<div className="custom-attributes-content">
					<div className="custom-attributes-content-actions">
						{allAttributes.length ? (
							<FiltersPanel filtersState={tableFiltersState} />
						) : (
							<div className="no-attributes-message">
								<p>
									<span>0&nbsp;</span>
									{t('CUSTOM_ATTRIBUTES.NO_ATTRIBUTES_DEFINED')}
								</p>
							</div>
						)}
						<Button
							type="primary"
							className="create-new-attr"
							icon={<BringgIcon iconName={BringgFontIcons.Plus} />}
							onClick={onCreateModal}
						>
							{t('CUSTOM_ATTRIBUTES.NEW_ATTRIBUTE')}
						</Button>
					</div>
					<div className="custom-attributes-content-table">
						{/* TODO: after merchant-config refactor the table height issue should be fixed other way */}
						<div className="scroll-wrapper" ref={tableContainerRef}>
							{isLoading ? (
								<Spinner size="large" />
							) : (
								<BringgReactTable
									TableContainerComponent={BasicTableContainer}
									RenderRow={ListChildRow}
									tableInstance={tableInstance}
								/>
							)}
						</div>
						<div className="table-summary">
							<div className="table-summary-divider" />
							<div className="table-summary-text">
								<span>{`${t('CUSTOM_ATTRIBUTES.TOTAL')}:`}</span>
								<span>{`${tableInstance?.filteredRows?.length ?? 0} ${t(
									'CUSTOM_ATTRIBUTES.ATTRIBUTES'
								)}`}</span>
							</div>
						</div>
					</div>
				</div>
			</div>
			{modalVisible && (
				<AttributeDetailsModal
					visible={modalVisible}
					onSubmit={handleSubmit}
					onCancel={handleClose}
					attribute={selectedAttribute}
				/>
			)}
		</>
	);
});
