import React from 'react';

import { BringgFontIcons, BringgIcon } from '@bringg/bringg-icons';

const DOUBLE_DASH = '--';

const EcoIcon = ({ isGreen }) => {
	if (isGreen) {
		return <BringgIcon iconName={BringgFontIcons.Eco} className="vehicle-profiles-page--eco-icon" />;
	}

	return <span>{DOUBLE_DASH}</span>;
};

export default EcoIcon;
