import React from 'react';

// eslint-disable-next-line no-restricted-imports
import { Divider, Form, FormInstance, FormProps, Input } from 'antd';
import { Moment } from 'moment';
import { BreakType } from '@bringg/types';
import { v4 as uuidv4 } from 'uuid';

import DeliveryBlock from '../../stores/domain-objects/delivery-block';
import DeliveryBlockFormDetails from './details/delivery-block-form-details';
import DeliveryBlockFormRecurring from './recurring/delivery-block-form-recurring';
import DeliveryBlockFromBreaks from './breaks/delivery-block-from-breaks';
import TimezoneService from '../../../../services/timezone/timezone-service';
import { DeliveryBlockBreaks } from './breaks/delivery-block-breaks';
import DeliveryBlockBreak, {
	DeliveryBlockBreakId,
	LocationOption
} from '../../stores/domain-objects/delivery-block-break';
import AddBreak from './breaks/add-break/add-break';
import { timezoneProvider } from 'bringg-web/services/timezone/timezone-provider';

interface Props extends FormProps {
	deliveryBlock?: DeliveryBlock;
	breakBuffer?: number;
	isEditMode?: boolean;
	onCapacityChange?: (originalCapacity: number) => void;
	minimumCapacity?: number;
	onBreaksChanged: (hasBreaks: boolean) => void;
	hasBreaks: boolean;
	onBlockTimeChanged?: (startTime: Moment, endTime: Moment) => void;
	onDateChanged?: (date: Moment) => void;
	useOldBreaks?: boolean;
	currentCalendarDay?: Moment;
	form: FormInstance<any>;
}

export type DeliveryBlockFormValues = {
	name: string;
	description: string;
	date: Moment;
	originalCapacity: number;
	startTime: Moment;
	endTime: Moment;
	breakStartTime: Moment;
	breakEndTime: Moment;
	ical: string;
	deliveryBlocksBreaks: DeliveryBlockBreaksFormValue;
};

export const BREAK_INTERVAL = 15; // minutes
export const BREAK_MAX_SIZE = 2 * 60; // minutes

export type DeliveryBlockBreaksFormValue = Map<DeliveryBlockBreakId, DeliveryBlockBreakFormValue>;

export type DeliveryBlockBreakFormValue = {
	startTime: Moment;
	endTime: Moment;
	breakType: BreakType;
	id: DeliveryBlockBreakId;
	length?: number;
	locationOption: LocationOption;
};

const formItemLayout = {
	labelCol: {
		xs: { span: 24 },
		sm: { span: 8 }
	},
	wrapperCol: {
		xs: { span: 10 },
		sm: { span: 16 }
	}
};

export const DELIVERY_BLOCK_DATE_FORMAT = 'ddd, MMM DD, YYYY';
export const DELIVERY_BLOCK_TIME_FORMAT = 'hh:mm A';

class DeliveryBlockForm extends React.Component<Props> {
	initializeDeliveryBlockFormValues = (
		deliveryBlock: DeliveryBlock,
		isEditMode: boolean,
		currentDay: Moment
	): DeliveryBlockFormValues => {
		const timezone = timezoneProvider.getTimezoneByTeamId(deliveryBlock.team_id);
		const momentTz = TimezoneService.getMomentTimezone(timezone);
		return {
			name: deliveryBlock && deliveryBlock.name,
			description: deliveryBlock && deliveryBlock.description,
			date: isEditMode ? momentTz(deliveryBlock.start_time) : currentDay ? momentTz(currentDay) : momentTz(),
			originalCapacity: isEditMode ? deliveryBlock.original_capacity : 1,
			startTime: isEditMode ? momentTz(deliveryBlock.start_time) : momentTz(),
			endTime: isEditMode ? momentTz(deliveryBlock.end_time) : momentTz().add(1, 'hours'),
			breakStartTime: deliveryBlock && deliveryBlock.break_start_time && momentTz(deliveryBlock.break_start_time),
			breakEndTime: deliveryBlock && deliveryBlock.break_end_time && momentTz(deliveryBlock.break_end_time),
			deliveryBlocksBreaks: this.getDeliveryBlocksBreaksFormValues(deliveryBlock.delivery_block_breaks, momentTz),
			ical: deliveryBlock.ical
		};
	};

	getDeliveryBlocksBreaksFormValues = (
		deliveryBlocksBreaks: DeliveryBlockBreak[],
		momentTz
	): DeliveryBlockBreaksFormValue => {
		if (!deliveryBlocksBreaks) {
			return new Map();
		}

		return new Map(
			deliveryBlocksBreaks.map(deliveryBlocksBreak => [
				deliveryBlocksBreak.id || uuidv4(),
				{
					startTime: momentTz(deliveryBlocksBreak.start_time),
					endTime: momentTz(deliveryBlocksBreak.end_time),
					breakType: deliveryBlocksBreak.break_type,
					id: deliveryBlocksBreak.id,
					length: deliveryBlocksBreak.length,
					locationOption: deliveryBlocksBreak.location_option
				}
			])
		);
	};

	onBreakAdded = () => {
		const { form, deliveryBlock, onBreaksChanged } = this.props;
		const timezone = timezoneProvider.getTimezoneByTeamId(deliveryBlock.team_id);
		const momentTz = TimezoneService.getMomentTimezone(timezone);
		const deliveryBlocksBreaks: DeliveryBlockBreaksFormValue = form.getFieldValue('deliveryBlocksBreaks');
		const newBreak = {
			startTime: momentTz(),
			endTime: momentTz(),
			breakType: BreakType.Fixed,
			id: uuidv4(),
			locationOption: LocationOption.ANYWHERE,
			length: 1
		};
		const newDeliveryBlocksBreaks = deliveryBlocksBreaks.set(newBreak.id, newBreak);
		form.setFieldsValue({ deliveryBlocksBreaks: newDeliveryBlocksBreaks });

		if (deliveryBlocksBreaks?.size === 1) {
			onBreaksChanged(true);
		}
		this.forceUpdate();
	};

	onBreakRemoved = id => {
		const { form, onBreaksChanged } = this.props;
		const deliveryBlocksBreaks: DeliveryBlockBreaksFormValue = form.getFieldValue('deliveryBlocksBreaks');
		deliveryBlocksBreaks.delete(id);
		form.setFieldsValue({ deliveryBlocksBreaks });

		if (deliveryBlocksBreaks.size === 0) {
			onBreaksChanged(false);
		}
		this.forceUpdate();
	};

	render() {
		const {
			isEditMode,
			deliveryBlock,
			onCapacityChange,
			minimumCapacity,
			breakBuffer,
			hasBreaks,
			onBlockTimeChanged,
			onDateChanged,
			useOldBreaks,
			currentCalendarDay,
			form
		} = this.props;

		const deliveryBlockFormValues = this.initializeDeliveryBlockFormValues(
			deliveryBlock,
			isEditMode,
			currentCalendarDay
		);
		const timezone = timezoneProvider.getTimezoneByTeamId(deliveryBlock.team_id);

		return (
			<Form
				form={form}
				className="delivery-block-form"
				layout="horizontal"
				{...formItemLayout}
				labelAlign="left"
				hideRequiredMark={true}
				initialValues={{
					deliveryBlocksBreaks: deliveryBlockFormValues.deliveryBlocksBreaks,
					...deliveryBlockFormValues
				}}
				preserve={false}
			>
				<div className="delivery-block-form-left-col">
					<Form.Item name="deliveryBlocksBreaks" hidden={true}>
						<Input />
					</Form.Item>
					<DeliveryBlockFormDetails
						onDateChanged={onDateChanged}
						onCapacityChange={onCapacityChange}
						minimumCapacity={minimumCapacity}
						formItemLayout={formItemLayout}
					/>

					<Divider />

					<DeliveryBlockFormRecurring
						form={form}
						deliveryBlock={deliveryBlockFormValues}
						isEditMode={isEditMode}
						onBlockTimeChanged={onBlockTimeChanged}
					/>

					<Divider />
					{useOldBreaks && (
						<DeliveryBlockFromBreaks
							form={form}
							deliveryBlock={deliveryBlockFormValues}
							deliveryBlockTeamTimezone={timezone}
							isEditMode={isEditMode}
							breakBufferInMinutes={breakBuffer}
						/>
					)}
					{!useOldBreaks && !hasBreaks && <AddBreak onBreakAdded={this.onBreakAdded} />}
				</div>
				{!useOldBreaks && hasBreaks && (
					<div className="delivery-block-form-right-col">
						<DeliveryBlockBreaks
							form={form}
							onBreakAdded={this.onBreakAdded}
							onBreakRemoved={this.onBreakRemoved}
						/>
					</div>
				)}
			</Form>
		);
	}
}

export default DeliveryBlockForm;
