import React, { useCallback } from 'react';

import { VehicleType } from '@bringg/types';
import { Select, Option, Tooltip, FormInstance, FormItem } from '@bringg/react-components';
import { BringgFontIcons, BringgIcon } from '@bringg/bringg-icons';
import { useTranslation } from 'react-i18next';
import { CategoryAttributeName } from '../../vehicle-profiles';

interface VehicleCategorySelectorProps {
	form: FormInstance;
	vehicleCategories: VehicleCategories;
	vehicleType: VehicleType;
}

type VehicleCategories = [
	| {
			id: string;
			name: string;
			type: CategoryAttributeName.VEHICLE_CATEGORY;
	  }
	| {
			id: number;
			name: string;
			type: CategoryAttributeName.VEHICLE_CATEGORY_ID;
	  }
];

const VehicleCategorySelector = ({ form, vehicleType, vehicleCategories }: VehicleCategorySelectorProps) => {
	const { t } = useTranslation();

	const selectVehicleCategory = useCallback(
		value => {
			const selectedCategory = vehicleCategories.find(category => category.id === value);

			form.setFieldValue(selectedCategory.type, value);
		},
		[form, vehicleCategories]
	);
	const initialValue = vehicleType.vehicle_category_id ?? vehicleType.vehicle_category;

	form.setFieldValue('vehicle_category', initialValue);

	return (
		<div className="vehicle-profiles-form--input-group">
			<div className="vehicle-profiles-form--label-text">
				<span className="vehicle-profiles-form--required">*</span>
				{t('VEHICLE_PROFILES.FORM.VEHICLE_CATEGORY')}
				&nbsp;
				<Tooltip
					overlayClassName="attribute-input-tooltip"
					placement="right"
					title={t('VEHICLE_PROFILES.FORM.HELP.VEHICLE_CATEGORY')}
				>
					<BringgIcon className="info-icon" iconName={BringgFontIcons.Info} />
				</Tooltip>
			</div>
			<FormItem
				name="vehicle_category"
				rules={[
					{
						required: true,
						message: t('VEHICLE_PROFILES.ERROR.REQUIRED_VEHICLE_CATEGORY')
					}
				]}
			>
				<Select
					placeholder={t('VEHICLE_PROFILES.FORM.VEHICLE_CATEGORY_PLACEHOLDER')}
					className="vehicle-profiles-form--select"
					data-test-id="vehicle-profiles-form-vehicle-category"
					getPopupContainer={trigger => trigger.parentNode}
					onSelect={selectVehicleCategory}
				>
					{vehicleCategories.map(vehicle_category => (
						<Option
							data-test-id={`select-option-${vehicle_category.name}`}
							key={vehicle_category.id}
							value={vehicle_category.id}
						>
							{vehicle_category.name}
						</Option>
					))}
				</Select>
			</FormItem>
		</div>
	);
};

export default VehicleCategorySelector;
