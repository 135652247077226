import { action, computed, makeObservable } from 'mobx';
import { ConditionBaseStore, RuleStore } from './internal';

class PlaceholderCondition extends ConditionBaseStore {
	parent: RuleStore;
	_value;

	constructor(parent: RuleStore) {
		super();
		makeObservable(this, {
			isValid: computed,
			setPath: action
		});
		this.parent = parent;
		this._value = undefined;
	}

	setPath = newPath => {
		this._path = newPath;
		this.parent.setAndRuleInstance(this);
	};

	get isValid(): boolean {
		return true;
	}

	get canComplete() {
		return true;
	}
}

export default PlaceholderCondition;
