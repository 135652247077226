import React from 'react';

import { Select, TimePicker } from '@bringg/react-components';
import { Moment } from 'moment/moment';
import { useTranslation } from 'react-i18next';
import moment from 'moment-timezone';
import { dateUtils } from '@bringg-frontend/utils';

import { getDaysOptions } from 'bringg-web/features/planned-delivery-windows/modals/slots-modal/wizard-steps/configuration-step/time-slots/cutoff-radio-group/cutoff-options-builder';
import {
	Configuration,
	DailyCutoff
} from 'bringg-web/features/planned-delivery-windows/modals/slots-modal/wizard-steps/configuration-step/time-slots/cutoff-radio-group/cutoff.types';
import { dontLoseFocus, filterOptionByTitle } from 'bringg-web/services/utils';

import styles from './daily-dropdown.module.scss';

interface Props {
	format: string;
	use12Hours: boolean;
	time: Configuration<Moment>;
	days: Configuration<number>;
	dailyCutoff: DailyCutoff;
	startRangeTimeInMinutes: number;
	timezone: string;
}

const DailyDropdown = ({ dailyCutoff, format, use12Hours, time, days, startRangeTimeInMinutes, timezone }: Props) => {
	const { t } = useTranslation();
	const startTimeDate = dateUtils.addMinutesToLastMonday(startRangeTimeInMinutes, timezone);
	const onTimeChange = (value: Moment) => {
		const valueToChange = value.tz(timezone);
		const isMaxDays = dailyCutoff.days === 7 && valueToChange.hours() === 12;
		const isMinDays = dailyCutoff.days === 0 && valueToChange.hours() === startTimeDate.hours();
		if (isMaxDays || isMinDays) {
			valueToChange.set({ minutes: 0 });
		}
		time.onChange(valueToChange);
	};

	const getDisabledTime = () => ({
		disabledHours: () => {
			if (dailyCutoff.days === 7) {
				return [...Array(dateUtils.HOURS_IN_DAY).keys()].filter(hourInDay => hourInDay > 12);
			}
			if (dailyCutoff.days === 0) {
				const hours = startTimeDate.hours();
				return [...Array(dateUtils.HOURS_IN_DAY).keys()].filter(hourInDay => hourInDay > hours);
			}
			return [];
		},
		disabledMinutes: hour => {
			const isMaxDays = dailyCutoff.days === 7 && hour >= 12;
			const isMinDays = dailyCutoff.days === 0 && hour === startTimeDate.hours();
			const [, ...minutesInHour] = [...Array(dateUtils.MINUTES_IN_HOUR).keys()];
			return isMaxDays || isMinDays ? minutesInHour : [];
		}
	});

	const onOk = (date: Moment) => {
		const sumOfDateInMinutes = dateUtils.sumOfMinutes(date.hours(), date.minutes());
		if (dailyCutoff.days === 7 && sumOfDateInMinutes > dateUtils.hoursToMinutes(12)) {
			date.set({ hours: 12, minutes: 0 });
			time.onChange(date);
		}
	};

	return (
		<div className={styles.selects} onClick={dontLoseFocus} data-test-id="daily-dropdown">
			<Select
				className={styles.selectItem}
				data-test-id="daily-dropdown-days"
				showSearch
				options={getDaysOptions(t)}
				value={dailyCutoff.days}
				onChange={days.onChange}
				filterOption={filterOptionByTitle}
				disabled={days.disabled}
			/>
			<TimePicker
				className={styles.selectItem}
				onChange={onTimeChange}
				value={moment(dailyCutoff.time).tz(timezone)}
				disabled={time.disabled}
				format={format}
				onOk={onOk}
				allowClear={false}
				use12Hours={use12Hours}
				showNow={false}
				disabledTime={getDisabledTime}
				placeholder={t('PLANNED_DELIVERY_WINDOW_MODAL_CUTOFF_DAILY_DROPDOWN.TIME_PLACEHOLDER')}
			/>
		</div>
	);
};

export default DailyDropdown;
