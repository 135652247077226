import type { UploadFile } from 'antd/lib/upload';
import { stringify } from 'fast-querystring';
import { last } from 'lodash';

// TODO: need to be moved into a some config
const salesforceUploadUrl = 'https://bringg.my.salesforce-sites.com/services/apexrest/upload';

// to fix issue with types narrowing
export const isUploadTicketAttachmentError = (
	uploadTicketAttachmentResult: UploadTicketAttachmentResult
): uploadTicketAttachmentResult is UploadTicketAttachmentError => {
	return !uploadTicketAttachmentResult.successUpload;
};

type UploadTicketAttachmentSuccessResult = {
	successUpload: true;
	result: Response;
	fileName: string;
};

type UploadTicketAttachmentError = {
	successUpload: false;
	error: unknown;
	fileName: string;
};

export type UploadTicketAttachmentResult = UploadTicketAttachmentSuccessResult | UploadTicketAttachmentError;

export type TicketAttachmentsParams = {
	filesList: UploadFile[];
	caseId: string;
	token: string;
	apiUrl: string;
};

export const uploadTicketAttachments = async ({
	filesList,
	caseId: id,
	token,
	apiUrl: token_validation_url
}: TicketAttachmentsParams): Promise<UploadTicketAttachmentResult[]> => {
	return Promise.all(
		filesList.map(async ({ originFileObj }) => {
			const { name: fileNameWithExtension, type } = originFileObj;
			const { name: fileNameWithOutExtension, extension } = parseFilename(fileNameWithExtension);

			try {
				return {
					fileName: fileNameWithExtension,
					successUpload: true,
					result: await fetch(
						`${salesforceUploadUrl}?${stringify({
							id,
							token,
							token_validation_url
						})}`,
						{
							method: 'POST',
							headers: new Headers({
								'File-Name': fileNameWithOutExtension,
								'File-Extension': extension,
								'Content-Type': type
							}),
							body: originFileObj
						}
					)
				};
			} catch (error) {
				return {
					successUpload: false,
					fileName: fileNameWithExtension,
					error
				};
			}
		})
	);
};

const parseFilename = (fileNameWithExtension: string): { name: string; extension: string } => {
	const extension = last(fileNameWithExtension.split('.'));

	return {
		name: fileNameWithExtension.replace(`.${extension}`, ''),
		extension
	};
};
