import React, { MutableRefObject, useMemo, memo } from 'react';
import { VariableSizeList as List } from 'react-window';
import type { TableInstance } from 'react-table';

import ListInnerElement from './list-inner-element';
import ListInnerElementContext from './list-inner-element-context';
import type { ReactWindowRenderRowProp } from '../../types';

const defaultOverscanCount = 4;

interface WithId {
	id: number | string;
}
const getItemKey = (index: number, data: WithId[]): number | string =>
	data[index] ? data[index].id : 'loader-item-key';

interface Props<T extends Record<string, unknown>> extends ReactWindowRenderRowProp {
	listRef: MutableRefObject<List>;
	tableInstance: TableInstance<T>;
	height: number;
	getItemSize: (index?: number) => number;
	isLoading?: boolean;
	headerHeight?: number;
	overscanCount?: number;
}

const VirtualListTableContainer = <T extends Record<string, unknown>>(props: Props<T>) => {
	const {
		listRef,
		tableInstance,
		RenderRow,
		height,
		getItemSize,
		headerHeight,
		isLoading,
		overscanCount = defaultOverscanCount
	} = props;
	const { rows } = tableInstance;

	const intrinsicHeaderHeight = useMemo(() => {
		return headerHeight === undefined ? getItemSize() : headerHeight;
	}, [headerHeight, getItemSize]);

	const contextValue = useMemo(
		() => ({
			headerHeight: intrinsicHeaderHeight,
			isLoading
		}),
		[intrinsicHeaderHeight, isLoading]
	);

	return (
		<ListInnerElementContext.Provider value={contextValue}>
			<List
				ref={listRef}
				height={height}
				itemCount={rows.length}
				width="100%"
				overscanCount={overscanCount}
				// Gives access to rows in getItemKey / RenderRow functions
				// defines generic T in RenderRowProps<T>
				itemData={rows}
				itemSize={getItemSize}
				itemKey={getItemKey}
				innerElementType={ListInnerElement}
			>
				{RenderRow}
			</List>
		</ListInnerElementContext.Provider>
	);
};

export default memo(VirtualListTableContainer) as typeof VirtualListTableContainer;
