import React from 'react';
import classNames from 'classnames';

export interface ChangesPart {
	count?: number;
	added?: boolean | undefined;
	removed?: boolean | undefined;
	value?: string;
}

interface DiffSpanInterface {
	part: ChangesPart;
}

const DiffSpan = ({ part }: DiffSpanInterface) => {
	const className = classNames({
		'changes-diff-text-added': part.added,
		'changes-diff-text-removed': part.removed
	});

	return <span className={className}>{part.value}</span>;
};

export default DiffSpan;
