import { InitOptions } from 'i18next';
import { Environment } from '@bringg/frontend-utils/dist/common/types';

export class AppConfig {
	public static getTranslationConfig = (useLocal = false): InitOptions => {
		return {
			backend: useLocal
				? undefined
				: {
						loadPath: '/i18n/{{lng}}.json'
				  },
			resources: useLocal ? {} : undefined,
			fallbackLng: {
				en: ['en'],
				default: ['en']
			},
			react: {
				useSuspense: false,
				wait: false,
				bindI18n: 'languageChanged loaded',
				bindStore: 'added removed',
				nsMode: 'fallback'
			},
			lowerCaseLng: true,
			interpolation: {
				escapeValue: false // react already safes from xss according to https://react.i18next.com/legacy-v9/step-by-step-guide
			}
		};
	};

	private static async waitForEnvironmentConfig(): Promise<void> {
		let intervalId: ReturnType<typeof setInterval>;

		return new Promise<void>(resolve => {
			// @ts-ignore
			if (window.envConfig) {
				resolve();
			}

			intervalId = setInterval(() => {
				// @ts-ignore
				if (window.envConfig) {
					resolve();
				}
			}, 0);
		}).then(() => {
			if (intervalId) {
				clearInterval(intervalId);
			}
		});
	}

	public static getEnvironmentConfig = async (): Promise<Environment> => {
		await AppConfig.waitForEnvironmentConfig();

		// @ts-ignore
		return window.envConfig;
	};
}
