import { wrapRoot } from 'mobx-easy';
import { RootEnv } from '@bringg-frontend/bringg-web-infra';

import { workflowsRootStore } from 'bringg-web/features/automation-workflows/stores/workflows-root-store';
import { createStore } from './helpers/create-store';
import RootStore from './root-store';

const storesInitializer = {
	init: (rootEnv: RootEnv): RootStore => {
		const storeDataItems = createStore(rootEnv);
		const rootStore = wrapRoot(storeDataItems);

		workflowsRootStore.getStore();

		return rootStore;
	}
};

export default storesInitializer;
