import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@bringg/react-components';
import { BringgFontIcons, BringgIcon } from '@bringg/bringg-icons';

interface Props {
	onBreakRemoved: () => void;
}

const RemoveBreak: FunctionComponent<Props> = ({ onBreakRemoved }: Props) => {
	const { t } = useTranslation();

	return (
		<Button className="remove-break" onClick={onBreakRemoved} danger>
			<BringgIcon iconName={BringgFontIcons.Trash} />
			<span className="remove-break-text">{t('DELIVERY_BLOCKS.REMOVE_BREAK')}</span>
		</Button>
	);
};

export default RemoveBreak;
