import { action, computed, makeObservable, observable } from 'mobx';
import i18next from 'i18next';
import { BringgFontIcons } from '@bringg/bringg-icons';
import { ActionType, CancelTaskAction, CancelTaskActionMetadata, MetadataValue } from '@bringg/types';
import { ActionFamilyType, ClientCancelTaskAction } from '../utils/types';
import { ActionBaseStore, ActionsRepo, workflowsRootStore } from './internal';

class CancelTaskActionStore extends ActionBaseStore {
	type = ActionFamilyType.CANCEL_TASK;
	actionType = ActionType.CANCEL_TASK;
	cancellationReasonId: number;
	icon = BringgFontIcons.Cancel;

	constructor(initAction: ClientCancelTaskAction, actionsRepo: ActionsRepo) {
		super(actionsRepo);

		makeObservable(this, {
			cancellationReasonId: observable,
			setCancellationReasonId: action,
			title: computed,
			isValid: computed,
			mappedToServer: computed,
			reasonsList: computed,
			cancelReasonValue: computed
		});

		if (initAction.data?.cancellation_reason_id) {
			this.cancellationReasonId = initAction.data.cancellation_reason_id;
		}
	}

	setCancellationReasonId = (key: number) => {
		this.cancellationReasonId = key;
	};

	get cancelReasonValue(): string {
		const { metadataRepo } = workflowsRootStore.getStore();

		return metadataRepo.metadata.cancelTaskReasonsMap.get(this.cancellationReasonId)?.value;
	}

	get mappedToServer(): CancelTaskAction {
		return {
			action_type: ActionType.CANCEL_TASK,
			data: {
				cancellation_reason_id: this.cancellationReasonId
			}
		};
	}

	get isValid(): boolean {
		return this.cancellationReasonId != null;
	}

	get title(): string {
		const titleKey = (this.actionData as CancelTaskActionMetadata).translation_string;

		return i18next.t(titleKey);
	}

	get reasonsList(): MetadataValue[] {
		return (this.actionData as CancelTaskActionMetadata).values;
	}
}

export default CancelTaskActionStore;
