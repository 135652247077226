import React, { useCallback, useEffect, useLayoutEffect, useMemo, useState } from 'react';

export type GroupHeader = { id: number; header: string | JSX.Element; width: number };

interface Props {
	timelineContainer: HTMLDivElement | null;
	headers: GroupHeader[];
}

const VIS_GROUP_CONTAINER_DEFAULT_PADDING = 5; // px

export const GroupHeaders = ({ headers, timelineContainer }: Props) => {
	const [width, setWidth] = useState('');
	const [padding, setPadding] = useState('');
	const [axisHeight, setAxisHeight] = useState<number>(0);

	const memoizedResizeObserver = useMemo(() => {
		const container = timelineContainer?.querySelector('.vis-panel.vis-left') as HTMLDivElement;
		const content = timelineContainer?.querySelector('.vis-panel.vis-left .vis-content') as HTMLDivElement;

		const resizeObserver = new ResizeObserver(entries => {
			let scrollWidth = 0;
			let fullWidth = 0;

			for (const entry of entries) {
				const target = entry.target as HTMLElement;
				scrollWidth = container.offsetWidth - container.clientWidth;
				fullWidth = scrollWidth > 0 ? container.offsetWidth : target.offsetWidth;
			}

			setWidth(fullWidth + 'px');
			setPadding(`0 ${scrollWidth + VIS_GROUP_CONTAINER_DEFAULT_PADDING}px`); // start after scrollbar
		});

		resizeObserver.observe(content, { box: 'border-box' });

		return resizeObserver;
	}, [timelineContainer]);

	useEffect(() => {
		return () => memoizedResizeObserver.disconnect();
	}, [memoizedResizeObserver]);

	const calcHeadersHeight = useCallback(() => {
		const axisTop = timelineContainer?.querySelector('.vis-panel.vis-top') as HTMLDivElement;
		return axisTop.offsetHeight;
	}, [timelineContainer]);

	useLayoutEffect(() => {
		setAxisHeight(calcHeadersHeight());
	}, [calcHeadersHeight, headers]);

	return (
		<div className="group-headers-component headers-container" style={{ height: axisHeight, width, padding }}>
			{headers.map(({ id, header, width: headerWidth }, i) => (
				<div
					key={i}
					data-test-id={`column-header-id-${id}`}
					className="header"
					style={{ minWidth: headerWidth, width: headerWidth }}
				>
					{header}
				</div>
			))}
		</div>
	);
};
