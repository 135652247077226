import { action, computed, makeObservable, observable } from 'mobx';

import { groupBy, GroupByFn } from '..';

type FilterFn<T> = (item: T) => boolean;
type SortCompareFn<T> = (a: T, b: T) => number;
type Key = string | number | symbol;

export const NONE_GROUP_TYPE = 'none';

export abstract class ArrayManipulationViewStore<T> {
	filters = new Map<Key, FilterFn<T>>();
	sort?: SortCompareFn<T>;
	currentSortFnId?: Key;
	groupByFn: GroupByFn<T> | undefined;

	protected constructor() {
		makeObservable(this, {
			data: computed,

			filters: observable,
			sort: observable,
			currentSortFnId: observable,
			groupByFn: observable,

			setFilter: action,
			removeFilter: action,
			setSort: action,
			removeSort: action,
			setGroup: action,
			removeGroup: action,

			resetFilters: action,
			reset: action,

			filtersAsArray: computed,
			viewArray: computed
		});
	}

	hasFilter(key: Key): boolean {
		return this.filters.has(key);
	}

	setFilter(key: Key, filterFn: FilterFn<T>) {
		this.filters.set(key, filterFn);
	}

	removeFilter(key: Key) {
		this.filters.delete(key);
	}

	resetFilters() {
		this.filters.clear();
	}

	reset() {
		this.removeSort();
		this.resetFilters();
		this.removeGroup();
	}

	setSort(sortFnId: Key, sortFn: SortCompareFn<T> | undefined) {
		if (this.currentSortFnId === sortFnId) {
			return;
		}
		this.currentSortFnId = sortFnId;
		this.sort = sortFn;
	}

	removeSort() {
		this.sort = undefined;
		this.currentSortFnId = undefined;
	}

	setGroup(groupByFn: GroupByFn<T>) {
		this.groupByFn = groupByFn;
	}

	removeGroup() {
		this.groupByFn = undefined;
	}

	get filtersAsArray(): FilterFn<T>[] {
		return Array.from(this.filters.values());
	}

	get viewArray(): T[] {
		const { data } = this;
		if (!data.length) {
			return data;
		}

		// If there are no filter we should clone the array, so we won't mutate it in the sort
		const filters = this.filtersAsArray;
		const dataToFilter = filters.length || !this.sort ? data : data.slice(0);

		const filtered = filters.reduce((arrToFilter, filterFn) => arrToFilter.filter(filterFn), dataToFilter);

		if (!this.sort) {
			return filtered;
		}

		return filtered.sort(this.sort);
	}

	get groupedViewArray(): Record<string, T[]> {
		if (this.groupByFn) {
			return groupBy<T>(this.viewArray, this.groupByFn);
		}

		return { [NONE_GROUP_TYPE]: this.viewArray };
	}

	/**
	 * This is how we get the data for filtering and/or sorting
	 */
	abstract get data(): T[];
}
