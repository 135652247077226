import { omit } from 'lodash';
import { FilterBar2Value } from '@bringg/react-components';
import { FloatingInventoriesRequest, Team } from '@bringg/types';
import { INITIAL_PAGE, MAX_PAGE_SIZE, DEAFULT_PAGE_SIZE } from './floating-inventories-filters.consts';

export const filtersAreSet = (query: FloatingInventoriesRequest) => {
	const filters = omit(query, ['page', 'items']);
	return Object.values(filters).length && !!Object.values(filters).filter(val => val).length;
};

export const getIdsFromQueryParams = (params: URLSearchParams, field: keyof FloatingInventoriesRequest) =>
	params.get(field) ? [...params.get(field).split(',').map(Number)] : [];

export const getPageFromRouteParams = (location: Location) => {
	const params = new URLSearchParams(location.search);
	const page = parseInt(params.get('page'), 10);
	if (page && page > 0) {
		return page;
	}
	return INITIAL_PAGE;
};

export const getPageSizeFromRouteParams = (location: Location) => {
	const params = new URLSearchParams(location.search);
	const limit = parseInt(params.get('limit'), 10);
	if (limit && limit > 0 && limit < MAX_PAGE_SIZE) {
		return limit;
	}
	return DEAFULT_PAGE_SIZE;
};

export const getQueryString = (query: FloatingInventoriesRequest) => {
	let queryString = '';
	if (!query) return queryString;

	query.team_ids?.length && (queryString += `team_ids=${query.team_ids}&`);
	query.status?.length && (queryString += `status=${query.status}&`);
	query.owner_type?.length && (queryString += `owner_type=${query.owner_type}&`);
	query.sku && (queryString += `sku=${query.sku}&`);
	return queryString ? `&${queryString}` : '';
};

export const getValuesFromQueryParams = (params: URLSearchParams, field: keyof FloatingInventoriesRequest) =>
	params.get(field) ? [...params.get(field).split(',').map(String)] : [];

export const mapTeamsToFilter = (teams: Team[]): FilterBar2Value[] =>
	teams.map(team => ({
		label: team?.name || team?.external_id,
		value: team?.id
	}));
