import React from 'react';
import { Button } from '@bringg/react-components';
import useAutomationTranslations from '../../utils/use-automation-translations';
import useWorkflowNavigation from '../../hooks/use-workflow-navigation';
import { AutomationsTab } from '../../utils/types';

interface Props {
	className?: string;
}

const AddNewAutomation = ({ className }: Props) => {
	const { goToNewWorkflow } = useWorkflowNavigation();
	const { newAutomationButton } = useAutomationTranslations();

	return (
		<Button
			type="primary"
			size="large"
			className={className}
			data-test-id="add-new-automation"
			onClick={() => goToNewWorkflow({ backTab: AutomationsTab.AUTOMATION })}
		>
			+ {newAutomationButton}
		</Button>
	);
};

export default AddNewAutomation;
