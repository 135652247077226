import { MappedTriggerData } from './types';
import { getFlatList } from './helpers';

export const getFlatTriggersById = (triggersMetadata: MappedTriggerData[]): { [id: string]: MappedTriggerData } => {
	const triggers = {};
	triggersMetadata.forEach(tr => {
		Object.assign(triggers, getFlatList(tr.items, 'id'));
	});
	return triggers;
};
