import { computed, makeObservable, observable, action } from 'mobx';
import { ConditionProperties, DisplayFact, FactType } from '@bringg/types';
import { ConditionType } from '@bringg/react-components';
import i18next from 'i18next';
import { RuleStore, workflowsRootStore } from './internal';
import { is, isNot } from '../utils/use-automation-translations';
import { filterIncomparableAttributes, filterUnsupportedAttributes } from '../utils/attributes-utils';
import { getRandomGuid } from '../utils/helpers';
import { ComparableConditionValue, ConditionValues, MappedAttribute, TriggerValues } from '../utils/types';

abstract class ConditionBaseStore {
	guid: number;
	_path: string;

	abstract _value: ConditionValues;
	abstract parent: RuleStore;

	protected constructor(condition?: Partial<ConditionProperties>) {
		makeObservable(this, {
			_path: observable,
			path: computed,
			conditionMetadata: computed,
			displayPath: computed,
			isArray: computed,
			isMultiValue: computed,
			displayValue: computed,
			container: computed,
			comparableList: computed,
			attributes: computed,
			usedAttributes: computed,
			comparable: computed,
			displayComparable: computed,
			setComparable: action,
			factType: computed,
			displayFact: computed,
			isComparable: computed
		});
		this.guid = getRandomGuid();
		this._path = condition?.path;
	}

	get displayFactType() {
		const { metadataRepo } = workflowsRootStore.getStore();
		return metadataRepo.metadata.displayFactType(this.factType);
	}

	get conditionMetadata(): MappedAttribute {
		if (!this.factType) {
			return {} as MappedAttribute;
		}
		const { metadataRepo } = workflowsRootStore.getStore();
		return metadataRepo.metadata.flatAttributesByPath(this.factType)[this.path];
	}

	get attributes(): MappedAttribute[] {
		const { metadataRepo } = workflowsRootStore.getStore();
		return filterUnsupportedAttributes(
			metadataRepo.metadata.factsByFactType(this.factType, this.displayFact),
			metadataRepo.metadata.getComparable(this.factType)
		);
	}

	get displayPath(): string {
		return this.conditionMetadata?.display_path || this.path;
	}

	get container(): string {
		return this.conditionMetadata?.container?.toLowerCase();
	}

	get path() {
		return this._path;
	}

	get canComplete() {
		const { options } = this.conditionMetadata;
		return !options || !!options.length;
	}

	get isArray(): boolean {
		return (
			this.conditionMetadata.type === ConditionType.NUMBER_ARRAY ||
			this.conditionMetadata.type === ConditionType.STRING_ARRAY ||
			this.conditionMetadata.type === ConditionType.BOOLEAN_ARRAY
		);
	}

	get isMultiValue(): boolean {
		return (
			this.conditionMetadata.type === ConditionType.NUMBER || this.conditionMetadata.type === ConditionType.STRING
		);
	}

	get displayValue(): ConditionValues {
		if (this.conditionMetadata.options) {
			return ((this._value as TriggerValues) || []).map(
				value => this.conditionMetadata.options.find(v => v.key === value)?.value
			);
		}
		if (this.conditionMetadata.type === ConditionType.BOOLEAN) {
			return i18next.t(this._value && this._value[0] === true ? is : isNot) as string;
		}
		return this._value;
	}

	get usedAttributes(): string[] {
		return this.parent.usedAttributes;
	}

	get isComparable(): boolean {
		const { metadataRepo } = workflowsRootStore.getStore();
		const comparableList = metadataRepo.metadata.getComparable(this.factType)(this.path);

		return comparableList.length > 0;
	}

	get comparableList(): MappedAttribute[] {
		const { metadataRepo } = workflowsRootStore.getStore();
		const comparableList = metadataRepo.metadata.getComparable(this.factType)(this.path);
		return filterIncomparableAttributes(
			metadataRepo.metadata.factsByFactType(this.factType, this.displayFact),
			comparableList
		);
	}

	get comparable(): string | undefined {
		return (this._value as ComparableConditionValue)?.path;
	}

	get displayComparable(): string | undefined {
		const { metadataRepo } = workflowsRootStore.getStore();
		return (
			metadataRepo.metadata.flatAttributesByPath(this.factType)[this.comparable]?.display_path || this.comparable
		);
	}

	setComparable(path: string) {
		this._value = {
			path
		} as ComparableConditionValue;
	}

	get factType(): FactType {
		return this.parent.factType;
	}

	get displayFact(): DisplayFact {
		return this.parent.displayFact;
	}
}

export default ConditionBaseStore;
