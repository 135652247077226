import { getRoot, wrapRoot } from 'mobx-easy';
import ChatView from './chat-view';
import ChatStore from './chat-store';

export default class ChatRootStore {
	chatView: ChatView;
	chatStore: ChatStore;

	init() {
		this.chatView = new ChatView();
		this.chatStore = new ChatStore();
	}
}

const CHAT_STORE_WRAPPER = 'chat-root-store';

const getStore = () => {
	try {
		return getRoot<ChatRootStore>(CHAT_STORE_WRAPPER);
	} catch (error) {
		return wrapRoot({ wrapperName: CHAT_STORE_WRAPPER, env: {}, RootStore: ChatRootStore });
	}
};

export const chatRootStore = { getStore };
