import React from 'react';

import { inject, observer } from 'mobx-react';
import _isFunction from 'lodash/isFunction';
import { withErrorBoundary } from '@bringg-frontend/bringg-web-infra';

import { DynamicFormModalPortal } from 'bringg-web/components';
import CrossAppDynamicFormModalView from './cross-app-dynamic-form-modal-view';

interface Props {
	crossAppDynamicFormModalView: CrossAppDynamicFormModalView;
}

class CrossAppDynamicFormModal extends React.Component<Props> {
	componentDidMount() {
		// registers to cross application
		this.props.crossAppDynamicFormModalView.register();
	}

	componentWillUnmount() {
		this.props.crossAppDynamicFormModalView.unRegister();
	}

	handleSubmit = (values: any[]) => {
		const { onSubmit } = this.props.crossAppDynamicFormModalView;
		if (_isFunction(onSubmit)) {
			// cross app action
			onSubmit(values);
		}
	};

	render() {
		const { handleSubmit } = this;
		const { visible, title, okText, formFields, closeModal } = this.props.crossAppDynamicFormModalView;

		return (
			<DynamicFormModalPortal
				visible={visible}
				okText={okText}
				title={title}
				onClose={closeModal}
				onSubmit={handleSubmit}
				formFields={formFields}
			/>
		);
	}
}

export default withErrorBoundary(inject('crossAppDynamicFormModalView')(observer(CrossAppDynamicFormModal)));
