import { cloneDeep as _cloneDeep, isEqual as _isEqual } from 'lodash';

interface TrackedItem {
	id?: number;
}
interface DataWithItems<I extends TrackedItem = TrackedItem> {
	items: I[];
}

function shallowDiff<T extends TrackedItem>(origin: T, target: T): Partial<T> | null {
	// Assumed that isEqual(Object.keys(origin), Object.keys(target)) === true
	// otherwise need to improve method's logic
	const diff = Object.entries(target).reduce<Partial<T>>((acc, [key, value]) => {
		// if (value !== origin[key]) {
		if (!_isEqual(value, origin[key])) {
			acc[key] = value;
		}
		return acc;
	}, {});

	return Object.keys(diff).length ? diff : null;
}

function isDataWithItems(data: TrackedItem[] | DataWithItems): data is DataWithItems {
	return Boolean((data as DataWithItems)?.items);
}

const ChangeTracker = <T extends TrackedItem, D extends T[] | DataWithItems<T> = T[]>() => {
	let originData: D;
	return {
		getOriginData: () => _cloneDeep(originData),
		setOriginData: (origin: D) => {
			originData = _cloneDeep(origin);
		},
		isDirty: (data: D) => !_isEqual(originData, data),
		isItemDirty: (item: T) => {
			const originItem = isDataWithItems(originData)
				? originData.items.find(i => i.id === item.id)
				: (originData as T[]).find(i => i.id === item.id);

			return !_isEqual(originItem, item);
		},
		getItemShallowDiff: (item: T): Partial<T> | null => {
			const originItem = (
				isDataWithItems(originData)
					? originData.items.find(i => i.id === item.id)
					: (originData as T[]).find(i => i.id === item.id)
			) as T;

			if (!originItem) {
				return item;
			}

			return shallowDiff<T>(originItem, item);
		}
	};
};

export default ChangeTracker;
