import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useObserver } from 'mobx-react';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import {
	Button,
	Checkbox,
	Modal,
	RateCardTerm,
	Tabs,
	Tooltip,
	ReadOnlyMessage,
	AntdDivider
} from '@bringg/react-components';
import { TabPane } from '@bringg/react-components/dist/components/tabs/tabs';
import SpinnerWrapper from '@bringg/react-components/dist/components/spinner/spinner';
import { PrivilegeTypes, RateCardEntityType, RateCardRateBase, RateCardTermType } from '@bringg/types';
import { BringgFontIcons, BringgIcon } from '@bringg/bringg-icons';

import { useHasFeatureFlag } from 'bringg-web/utils/feature-flags';
import notification from 'bringg-web/services/notification';
import { useHasAccess } from 'bringg-web/utils/privileges';
import useRateCardLoad from './hooks/use-rate-card-load';
import useRateCardTranslations from './hooks/use-rate-card-translations';
import { PreviewRateModal } from './preview-rate-modal/preview-rate-modal';
import { State } from '../types';
import type { Connect } from '../../../components/delivery-catalog-connect/delivery-catalog-connect';
import { RateCardCsv } from './rate-card-csv/rate-card-csv';
import { RateCardRoot } from './data-provider/rate-card-root';
import { StoreProvider } from './store/helpers/store-context';
import useRateCardStore from './store/helpers/use-rate-card-store';

import './rate-card.scss';

const RATE_TAB_KEYS = {
	base: 'base',
	surcharge: 'surcharge'
};

interface Props {
	connection: Connect;
}

export const RateCard = ({ connection }: Props) => {
	const { t } = useTranslation();
	const [isPreviewModalOpen, setPreviewModalOpen] = useState(false);
	const [openIds, setOpenIds] = useState<number[]>([]);
	const [activeTabKey, setActiveTabKey] = useState(RATE_TAB_KEYS.base);
	const [removeModalConfig, setRemoveModalConfig] = useState({ visible: false, termId: null });

	const readOnly = !useHasAccess(PrivilegeTypes.ENABLE_SETTING_PAGE);
	const hasRateCardCsvFeatureFlag = useHasFeatureFlag('rate_card_csv');

	// TODO: figure out where is the problem FleetMetaData.id is string, but is used as a number
	//  in conditions-set-store -> load -> getRules
	const { rateCardStore } = useRateCardStore();
	const { rateCardInitState } = useRateCardLoad(rateCardStore, RateCardEntityType.Fleet, Number(connection.id));
	const booleanKeys = useMemo(
		() => ({
			trueKey: 'CONDITIONS.BOOLEAN.TRUE',
			falseKey: 'CONDITIONS.BOOLEAN.FALSE'
		}),
		[]
	);
	const { rootTranslations, termTranslations } = useRateCardTranslations(booleanKeys);

	const closePreviewModal = useCallback(() => setPreviewModalOpen(false), []);
	const toggleOpenById = useCallback((toggleId: number) => {
		setOpenIds(prevState => {
			const nextState = prevState.filter(id => id !== toggleId);
			return nextState.length === prevState.length ? prevState.concat(toggleId) : nextState;
		});
	}, []);

	const discardChanges = useCallback(() => {
		const unsavedGuids = rateCardStore.terms.filter(({ id }) => !id).map(({ guid }) => guid);
		rateCardStore.discardChanges();
		if (unsavedGuids.length) {
			setOpenIds(prevState => {
				const nextState = prevState.filter(id => !unsavedGuids.includes(id));
				return nextState.length === prevState.length ? prevState : nextState;
			});
		}
	}, [rateCardStore]);

	const save = useCallback(async () => {
		try {
			await rateCardStore.saveTerms();
			notification.success(i18next.t('RATES.RATES_SUCCESSFULLY_SAVED'));
		} catch {
			notification.error(i18next.t('RATES.FAILED_SAVING_RATES'));
		}
	}, [rateCardStore]);

	const addNewTerm = useCallback(() => {
		let guid;
		if (activeTabKey === RATE_TAB_KEYS.base) {
			({ guid } = rateCardStore.addTerm(RateCardTermType.Base));
		} else if (activeTabKey === RATE_TAB_KEYS.surcharge) {
			({ guid } = rateCardStore.addTerm(RateCardTermType.Surcharge));
		}
		if (guid) {
			toggleOpenById(guid);
		}
	}, [activeTabKey, rateCardStore, toggleOpenById]);

	const closeRemoveModal = useCallback(() => {
		setRemoveModalConfig({ visible: false, termId: null });
	}, []);

	const openRemoveModal = useCallback((id: number) => {
		setRemoveModalConfig({ visible: true, termId: id });
	}, []);

	const handleRemoveConfirm = useCallback(() => {
		rateCardStore.removeTermById(removeModalConfig.termId);
		setRemoveModalConfig({ visible: false, termId: null });
	}, [removeModalConfig.termId]);

	const setUseForQuotes = useCallback(
		e => {
			rateCardStore.setUseForQuotes(e.target.checked);
		},
		[rateCardStore]
	);

	const rateBaseOptions = useMemo(
		() => [
			{ name: 'Flat', id: RateCardRateBase.Flat },
			{ name: 'Miles', id: RateCardRateBase.Miles },
			{ name: 'Units', id: RateCardRateBase.Units },
			{ name: 'Weight', id: RateCardRateBase.Weight }
		],
		[]
	);

	const { terms, rateTermsByType } = rateCardStore;
	const isAnyTerms = Boolean(terms.length);
	const isAnyTermsWithoutActiveBase = isAnyTerms && !rateTermsByType.base.some(({ active }) => active);
	const tabBarExtraContent = useMemo(
		() => ({
			right: (
				<Button
					disabled={!isAnyTerms}
					type="text"
					className="see-rate-summary-button"
					onClick={() => setPreviewModalOpen(true)}
				>
					{rootTranslations.seeRateSummary}
					<BringgIcon iconName={BringgFontIcons.ChevronRight} />
				</Button>
			)
		}),
		[rootTranslations, isAnyTerms]
	);

	const disabledButtonTooltip = Object.entries({
		'RATES.INVALID_TERM': !rateCardStore.termsAreValid,
		'RATES.SURCHARGE_TERM_WITHOUT_BASE': isAnyTermsWithoutActiveBase
	})
		.reduce((parts, [key, flag]) => {
			if (flag) parts.push(t(key));
			return parts;
		}, [])
		.join('. ');

	useEffect(() => {
		// Don't put `rateCardStore.terms` to dependencies.
		// Without them this hook will work only once on state initialization after load is Done
		if (rateCardInitState === State.Done) {
			setOpenIds(rateCardStore.terms.map(({ guid }) => guid));
		}
	}, [setOpenIds, rateCardInitState]);

	return useObserver(() => {
		if (rateCardInitState === State.Idle || rateCardInitState === State.Loading) {
			return <SpinnerWrapper className="spinner-center" />;
		}
		if (rateCardInitState === State.Failed) {
			return (
				<div className="rate-cards-global-error">
					<h3>{rootTranslations.errorTitle}</h3>
					<p>{rootTranslations.errorBody}</p>
				</div>
			);
		}

		return (
			<div className="rate-cards">
				{rateCardStore.pendingUpdate && <SpinnerWrapper className="rate-cards-pending-update" />}
				<ReadOnlyMessage showMessage={readOnly} message={rootTranslations.readonlyMessage} />
				<Tabs
					className="rate-cards-content"
					activeKey={activeTabKey}
					onTabClick={tabKey => setActiveTabKey(tabKey)}
					tabBarExtraContent={tabBarExtraContent}
				>
					<TabPane key={RATE_TAB_KEYS.base} tab={rootTranslations.basicFee} className="rate-cards-tab-base">
						{hasRateCardCsvFeatureFlag && (
							<RateCardCsv
								termType={RateCardTermType.Base}
								onImport={rateCardStore.importTerms}
								onExport={rateCardStore.exportTerms}
							/>
						)}
						{rateCardStore.rateTermsByType.base.map((term, index) => (
							<React.Fragment key={`base-term-${term.guid}`}>
								<RateCardTerm
									isPanelOpen={openIds.includes(term.guid)}
									term={term}
									contextSelector={`base-term-${term.guid}`}
									popupContainerClass="rate-cards-tab-base"
									attributes={rateCardStore.attributes}
									translations={termTranslations}
									rateBaseOptions={rateBaseOptions}
									togglePanelOpenById={toggleOpenById}
									updateTermById={rateCardStore.updateTermById}
									removeTermById={openRemoveModal}
									copyTermById={rateCardStore.duplicateTermById}
									readOnly={readOnly}
								/>
								{index < rateCardStore.rateTermsByType.base.length - 1 ? (
									<AntdDivider plain>{t('CONDITIONS.OR')}</AntdDivider>
								) : null}
							</React.Fragment>
						))}

						<div className="add-term-container">
							<Button
								data-test-id="add-basic-term"
								type="text"
								className="add-term-button"
								onClick={addNewTerm}
								disabled={readOnly || !rateCardStore.termsAreValid}
							>
								{`+ ${rootTranslations.addNewTerm}`}
							</Button>
						</div>
					</TabPane>
					<TabPane
						key={RATE_TAB_KEYS.surcharge}
						tab={rootTranslations.surchargeFee}
						className="rate-cards-tab-surcharge"
					>
						{hasRateCardCsvFeatureFlag && (
							<RateCardCsv
								termType={RateCardTermType.Surcharge}
								onImport={rateCardStore.importTerms}
								onExport={rateCardStore.exportTerms}
							/>
						)}
						{rateCardStore.rateTermsByType.surcharge.map(term => (
							<RateCardTerm
								key={`surcharge-term-${term.guid}`}
								isPanelOpen={openIds.includes(term.guid)}
								term={term}
								popupContainerClass="rate-cards-tab-surcharge"
								contextSelector={`surcharge-term-${term.guid}`}
								attributes={rateCardStore.attributes}
								translations={termTranslations}
								rateBaseOptions={rateBaseOptions}
								togglePanelOpenById={toggleOpenById}
								updateTermById={rateCardStore.updateTermById}
								removeTermById={openRemoveModal}
								copyTermById={rateCardStore.duplicateTermById}
								readOnly={readOnly}
							/>
						))}

						<div className="add-term-container">
							<Button
								data-test-id="add-surcharge-term"
								type="text"
								className="add-term-button"
								onClick={addNewTerm}
								disabled={readOnly || !rateCardStore.termsAreValid}
							>
								{`+ ${rootTranslations.addNewTerm}`}
							</Button>
						</div>
					</TabPane>
				</Tabs>

				<div className="rate-cards-footer">
					<div className="quotes-wrapper">
						<Checkbox
							data-test-id="rate-use-for-quotes"
							checked={rateCardStore.useForQuotes}
							onChange={setUseForQuotes}
							disabled={readOnly}
						>
							{rootTranslations.useForQuotes}
						</Checkbox>
						<Tooltip title={rootTranslations.useForQuotesTooltip}>
							<BringgIcon iconName={BringgFontIcons.Help} />
						</Tooltip>
					</div>
					<div>
						<Button
							className="discard-button"
							onClick={discardChanges}
							disabled={!rateCardStore.isDirty || readOnly}
						>
							{rootTranslations.discardChanges}
						</Button>
						<Tooltip title={disabledButtonTooltip}>
							<span>
								<Button
									type="primary"
									className="save-button"
									onClick={save}
									disabled={
										readOnly ||
										!rateCardStore.isDirty ||
										!rateCardStore.termsAreValid ||
										isAnyTermsWithoutActiveBase
									}
								>
									{rootTranslations.saveAndDeploy}
								</Button>
							</span>
						</Tooltip>
					</div>
				</div>

				<PreviewRateModal visible={isPreviewModalOpen} connection={connection} closeModal={closePreviewModal} />

				<Modal
					visible={removeModalConfig.visible}
					title={rootTranslations.removeModalTitle}
					okText={rootTranslations.delete}
					cancelText={rootTranslations.cancel}
					onOk={handleRemoveConfirm}
					onCancel={closeRemoveModal}
					className="delete-term-modal"
					centered
				>
					{rootTranslations.removeModalBody}
				</Modal>
			</div>
		);
	});
};

const RateCardWithRoot = (props: Props) => {
	const rateCardRootStore = useMemo(() => RateCardRoot.createRoot(), []);

	return (
		<StoreProvider value={rateCardRootStore}>
			<RateCard {...props} />
		</StoreProvider>
	);
};

export default RateCardWithRoot;
