import { FORM_CONTROLS } from '@bringg-frontend/dynamic-form-modal';
import i18next from 'i18next';

export default () => [
	{
		key: 'title',
		control: FORM_CONTROLS.INPUT,
		label: i18next.t('USER_TYPE.TITLE'),
		mandatory: true,
		formItemClassName: 'text-align-left-small-margin'
	},
	{
		key: 'default_for_merchant',
		control: FORM_CONTROLS.CHECKBOX,
		label: i18next.t('USER_TYPE.DEFAULT_FOR_MERCHANT'),
		formItemClassName: 'text-align-left-small-margin',
		valuePropName: 'checked'
	},
	{
		key: 'minimum_tasks_per_shift',
		control: FORM_CONTROLS.INPUT,
		type: 0,
		label: i18next.t('USER_TYPE.MINIMUM_TASKS_PER_SHIFT'),
		mandatory: true,
		formItemClassName: 'text-align-left-small-margin'
	},
	{
		key: 'maximum_tasks_per_shift',
		control: FORM_CONTROLS.INPUT,
		type: 0,
		label: i18next.t('USER_TYPE.MAXIMUM_TASKS_PER_SHIFT'),
		mandatory: true,
		formItemClassName: 'text-align-left-small-margin'
	},
	{
		key: 'cost_per_task',
		control: FORM_CONTROLS.INPUT,
		type: 0,
		label: i18next.t('USER_TYPE.COST_PER_TASK'),
		formItemClassName: 'text-align-left-small-margin',
		initialValue: 0
	},
	{
		key: 'cost_per_shift',
		control: FORM_CONTROLS.INPUT,
		type: 0,
		label: i18next.t('USER_TYPE.COST_PER_SHIFT'),
		formItemClassName: 'text-align-left-small-margin',
		initialValue: 0
	},
	{
		key: 'speed_factor',
		control: FORM_CONTROLS.INPUT,
		type: 0,
		label: i18next.t('USER_TYPE.SPEED_FACTOR'),
		formItemClassName: 'text-align-left-small-margin',
		initialValue: 1,
		tooltip: i18next.t('USER_TYPE.SPEED_FACTOR_DESCRIPTION')
	},
	{
		key: 'is_crowd',
		control: FORM_CONTROLS.CHECKBOX,
		label: i18next.t('USER_TYPE.CROWD'),
		formItemClassName: 'text-align-left-small-margin',
		valuePropName: 'checked'
	}
];
