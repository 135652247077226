import { getRootEnv } from '@bringg-frontend/bringg-web-infra';
import { action, computed, observable, makeObservable } from 'mobx';
import clone from 'lodash/clone';
import { getEnv, getRoot } from 'mobx-easy';
import { User, PlannedRoute } from '@bringg/types';
import { BringgException } from '@bringg/dashboard-sdk/dist/Core/BringgException';
import { RootEnv } from '@bringg-frontend/bringg-web-infra';

import { CreateAndUpdateCrew } from '../../../features/crews/crews.consts';
import notification from '../../../services/notification';
import RootStore from 'bringg-web/stores/root-store';

export default class Crew {
	id: number = null;

	primary_driver?: Partial<User> = null;
	drivers?: Partial<User>[] = [];
	planned_route: Partial<PlannedRoute> = null;
	team_id: number = null;

	originalData: Bringg.Crew = null;
	crewStore = null;

	constructor(crew, crewStore) {
		makeObservable(this, {
			primary_driver: observable,
			drivers: observable,
			planned_route: observable,
			team_id: observable,
			reset: action,
			setPrimaryDriver: action,
			setPlannedRoute: action,
			setSecondaryDrivers: action,
			getPlannedRoutes: computed,
			availableDriversByTeam: computed
		});

		Object.assign(this, crew);

		this.crewStore = crewStore;
		this.originalData = clone(crew);
	}

	reset() {
		Object.assign(this, this.originalData);
	}

	setPrimaryDriver = (id: number) => {
		this.primary_driver = getRoot<RootStore>().data.driversStore.get(id);
	};

	setPlannedRoute = (id: number) => {
		const plannedRoutes = this.crewStore.plannedRoutes.get(this.team_id);
		this.planned_route = plannedRoutes.find(plannedRoute => plannedRoute.id === id);
	};

	setSecondaryDrivers = (driversIds: number[]) => {
		this.drivers = driversIds.map(id => getRoot<RootStore>().data.driversStore.get(id));
	};

	get getPlannedRoutes() {
		return this.crewStore.plannedRoutes.get(this.team_id);
	}

	get availableDriversByTeam() {
		return this.crewStore.availableDrivers.get(this.team_id);
	}

	cancel = () => {
		this.crewStore.cancel();
	};

	delete = async (): Promise<void> => {
		try {
			await getRootEnv().dashboardSdk.sdk.crews.delete(this.id);
			notification.success('Deleted Successfully');
		} catch (e) {
			const { message, details } = e as BringgException;
			notification.error(message, details as string);
		}
	};

	update = async (crew: CreateAndUpdateCrew): Promise<void> => {
		try {
			await getRootEnv().dashboardSdk.sdk.crews.update(this.id, crew);

			notification.success('Updated Successfully');
		} catch (e) {
			const { message, details } = e as BringgException;
			notification.error(message, details as string);
		}
	};

	create = async (crew: CreateAndUpdateCrew): Promise<void> => {
		try {
			const newCrew: Bringg.Crew = await getRootEnv().dashboardSdk.sdk.crews.create(crew);
			this.crewStore.add(new Crew(newCrew, this.crewStore));
			notification.success('Created Successfully');
		} catch (e) {
			const { message, details } = e as BringgException;
			notification.error(message, details as string);
		}
	};

	save = async (): Promise<void> => {
		if (!this.primary_driver) {
			return notification.error('Missing Primary Driver');
		}

		const crew: CreateAndUpdateCrew = {
			primary_driver_id: this.primary_driver.id,
			driver_ids: this.drivers.map(driver => driver.id) || [],
			planned_route_id: this.planned_route && this.planned_route.id,
			team_id: this.team_id
		};

		if (this.id) {
			await this.update(crew);
		} else {
			await this.create(crew);
			this.crewStore.cancel();
		}

		return undefined;
	};
}
