import React, { FunctionComponent, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useObserver } from 'mobx-react';
import { ComponentWithForm } from '@bringg/react-pages/dist/components';
import { FleetTemplateSelection } from '../../../../stores/fleets/fleets-store';

const AvailableFleetsButtons: FunctionComponent<ComponentWithForm<FleetTemplateSelection>> = ({
	form,
	defaultValues
}) => {
	const { t } = useTranslation();
	const { setValue } = form;

	const selectAll = useCallback(() => {
		Object.keys(defaultValues).forEach(key => {
			setValue(key, true, { shouldDirty: true });
		});
	}, [defaultValues, setValue]);

	const clearAll = useCallback(() => {
		Object.keys(defaultValues).forEach(key => {
			setValue(key, false, { shouldDirty: true });
		});
	}, [defaultValues, setValue]);

	return useObserver(() => {
		return (
			<span className="select-buttons">
				<span className="select-all" onClick={selectAll}>
					{t('FLEET_ROUTER.SELECT_ALL_FLEETS')}
				</span>
				<span className="select-separator">|</span>
				<span className="clear-all" onClick={clearAll}>
					{t('FLEET_ROUTER.CLEAR_ALL_FLEETS')}
				</span>
			</span>
		);
	});
};

export default AvailableFleetsButtons;
