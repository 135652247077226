import classNames from 'classnames';
import React from 'react';
import { Link } from 'react-router-dom';

import styles from './link.module.scss';

interface LinkWrapperProps {
	to?: string;
	className?: string;
	title?: string;
	children?: React.ReactNode;
	openInNewTab?: boolean;
}

const LinkWrapper: React.FunctionComponent<LinkWrapperProps> = (props: LinkWrapperProps) => {
	const { className, to, children, openInNewTab, title } = props;
	return (
		<div className={classNames('link-wrapper', styles.link, className)} title={title}>
			<Link data-test-id="link" to={to} target={openInNewTab ? '_blank' : ''} className={className || ''}>
				{children}
			</Link>
		</div>
	);
};

LinkWrapper.defaultProps = {
	to: ''
};

export default LinkWrapper;
