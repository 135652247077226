import React, { useMemo } from 'react';
import { Run } from '@bringg/types';
import { map as _map } from 'lodash';
import { MomentInput } from 'moment';
import { Table, Link, Date } from '../../../components';
import { RunsData } from '../runs.consts';
import { Translate } from '../../../translation';

interface Props {
	runs: Run[];
	onSelect: Function;
	loading: boolean;
	selectedRowKeys: number[];
}

const RunTableComponent = ({ runs, onSelect, loading, selectedRowKeys }: Props) => {
	const TABLE_SIZE = 'small';

	const routeTitleExists = useMemo(() => runs.some(run => run.planned_route_id), [runs]);

	const normalizeRuns = (): RunsData[] => {
		const normalizedRuns: RunsData[] = _map(runs, run => {
			return {
				id: run.id,
				externalId: run.external_id,
				routeTitle: run.route_title,
				driverName: run.driver_name,
				startTime: run.started_at,
				endTime: run.ended_at,
				tasksCount: run.tasks_count,
				status: run.ended_at ? <Translate text="RUNS.CLOSED" /> : <Translate text="RUNS.OPEN" />,
				routeId: run.planned_route_id
			};
		});

		return normalizedRuns;
	};

	const dateRenderer = (date: MomentInput) => <Date format="MMM DD, LT" invalidDateText="----" date={date} />;

	const getColumns = () => {
		const columns = [
			{
				title: <Translate text="ROUTES.EXTERNAL_ID" />,
				dataIndex: 'externalId',
				render: (externalId: string, record: RunsData) => {
					return <Link to={`/runs/${record.id}`}>{externalId}</Link>;
				}
			},
			{
				title: <Translate text="RUNS.ASSIGNED_DRIVER" />,
				dataIndex: 'driverName'
			},
			{
				title: <Translate text="RUNS.START_TIME" />,
				dataIndex: 'startTime',
				render: dateRenderer
			},
			{
				title: <Translate text="RUNS.END_TIME" />,
				dataIndex: 'endTime',
				render: dateRenderer
			},
			{
				title: <Translate text="RUNS.NUMBER_OF_ORDERS" />,
				dataIndex: 'tasksCount'
			},
			{
				title: <Translate text="RUNS.STATUS" />,
				dataIndex: 'status'
			}
		];
		if (routeTitleExists)
			columns.splice(1, 0, {
				title: <Translate text="ROUTES.ROUTE_TITLE" />,
				dataIndex: 'routeTitle'
			});
		return columns;
	};

	const onSelectedRowsChange = selectedRowKeysParam => {
		onSelect(selectedRowKeysParam);
	};

	const normalizedRuns = normalizeRuns();
	const rowSelection = {
		columnWidth: 65,
		selectedRowKeys,
		onChange: onSelectedRowsChange
	};

	return (
		<Table
			loading={loading}
			dataSource={normalizedRuns}
			rowSelection={rowSelection}
			columns={getColumns()}
			pagination={false}
			rowKey="id"
			size={TABLE_SIZE}
		/>
	);
};

export default RunTableComponent;
