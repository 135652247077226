import React from 'react';
import { useTranslation } from 'react-i18next';
import { Tooltip } from '@bringg/react-components';
import { BringgFontIcons, BringgIcon } from '@bringg/bringg-icons';
import { ConfigurationsAuditRestrictedKeys } from '@bringg/types/types/configuration_audit';

import './changed-fields-string.scss';

const ChangedFieldsString = ({ text }: { text: string[] }) => {
	const { t } = useTranslation();
	const renderTooltip = text.some(e => ConfigurationsAuditRestrictedKeys.indexOf(e) >= 0);

	return (
		<span className="configurations-audit-changed-fields">
			<div className="configurations-audit-changed-fields-list">{text.join(', ')}</div>
			{renderTooltip && (
				<div className="configurations-audit-changed-fields-impersonated-tooltip">
					<Tooltip title={t('CONFIGURATION_AUDIT.CHANGED_FIELDS_IMPERSONATED_TOOLTIP')} placement="top">
						<BringgIcon iconName={BringgFontIcons.Info} className="configurations-audit-changed-tooltip" />
					</Tooltip>
				</div>
			)}
		</span>
	);
};

export default ChangedFieldsString;
