import { DatePicker } from 'antd';
import { Moment } from 'moment';
import React from 'react';

const { RangePicker } = DatePicker;

const DEFAULT_FORMAT = 'YYYY-MM-DD';

interface Props<T> {
	onChange: (dates: T[], dateStrings: [string, string]) => void;
	defaultValue?: [T, T];
	format?: string | string[];
}

const RangeCalendar: React.FunctionComponent<Props<Moment>> = props => {
	const { onChange, defaultValue, format } = props;

	return (
		<RangePicker
			showTime={false}
			format={format}
			onChange={onChange}
			defaultValue={defaultValue}
			allowClear={false}
		/>
	);
};

RangeCalendar.defaultProps = {
	format: DEFAULT_FORMAT
};

export default RangeCalendar;
