import React, { Component } from 'react';

import { inject, observer } from 'mobx-react';
import map from 'lodash/map';
import fromPairs from 'lodash/fromPairs';
import { withErrorBoundary } from '@bringg-frontend/bringg-web-infra';

import TagCustomerNotificationsStore from 'bringg-web/stores/customer-notifications/tag-customer-notifications-store';
import MerchantConfigurationsStore from 'bringg-web/stores/merchant-configurations/merchant-configurations-store';
import { FetchedData } from 'bringg-web/components';
import UsersStore from 'bringg-web/stores/users/users-store';
import { buildDictionary } from '../helper';
import CustomerNotificationsContainer from './customer-notifications-container/customer-notifications-container';

interface Props {
	tagCustomerNotificationsStore?: TagCustomerNotificationsStore;
	merchantConfigurationsStore?: MerchantConfigurationsStore;
	usersStore?: UsersStore;
}

class TagsCustomerNotificationsContainer extends Component<Props> {
	legendDictionary: any = {};
	previewDictionary: any = {};

	constructor(props: Props) {
		super(props);
		const userName = this.props.usersStore.currentUser.name;
		this.legendDictionary = buildDictionary(userName, this.getMerchantName);
		this.previewDictionary = fromPairs(map(this.legendDictionary, item => [item.key, item.value])) as {
			key: string;
			value: string | Function;
		};
		this.props.tagCustomerNotificationsStore.registerTagSelectedAction();
	}

	async componentDidMount(): Promise<void> {
		await this.props.tagCustomerNotificationsStore.initConfigurations();
	}

	getMerchantName = () => {
		const { configuration } = this.props.merchantConfigurationsStore;
		return configuration ? configuration.name : '{{merchant}}';
	};

	render() {
		const { tagCustomerNotificationsStore } = this.props;
		const { isLoading } = tagCustomerNotificationsStore;
		return (
			<FetchedData isFetched={!isLoading}>
				<div className="tags-customer-notifications customer-notifications">
					<div className="body">
						<CustomerNotificationsContainer dictionary={this.previewDictionary} />
					</div>
				</div>
			</FetchedData>
		);
	}
}

export default withErrorBoundary(
	inject(
		'tagCustomerNotificationsStore',
		'merchantConfigurationsStore',
		'usersStore'
	)(observer(TagsCustomerNotificationsContainer))
);
