// disable for the whole file as it a base class
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable class-methods-use-this */

import { reaction } from 'mobx';
import { getRoot } from 'mobx-easy';

import { RootStore } from '../root-store-types';
import { getSharedRootStore } from '../root-store';

abstract class LoginLogoutReaction {
	private reactionDisposalUserLogInStateChange;

	afterRootInit() {
		this.reactionDisposalUserLogInStateChange = reaction(
			() => getSharedRootStore().views.authStore.isLoggedIn,
			isLoggedIn => {
				if (isLoggedIn) {
					this.afterUserLoggedIn();
				} else {
					this.afterUserLoggedOut();
				}
			}
		);
	}

	afterUserLoggedIn() {}

	afterUserLoggedOut() {}
}

export default LoginLogoutReaction;
