import { ApplicationUuid, OPTIMIZATION_TYPE, Team } from '@bringg/types';
import useStores from '../../../recipes/use-stores';
import { teamHasFeatureFlag } from 'bringg-web/utils/feature-flags';
import ApplicationMerchantConfigurationStore from 'bringg-web/stores/application-merchant-configuration/application-merchant-configuration-store';

export const useHasCombinedOptimization = (team?: Bringg.Team) => {
	const { applicationMerchantConfigurationStore } = useStores();
	return hasCombinedOptimization(applicationMerchantConfigurationStore, team);
};

export const hasCombinedOptimization = (
	applicationMerchantConfigurationStore: ApplicationMerchantConfigurationStore,
	team: Team
) => {
	const applicationMerchantConfig = applicationMerchantConfigurationStore.getConfiguration(
		ApplicationUuid.RouteOptimizer2
	);
	return (
		teamHasFeatureFlag(team, 'combined_optimization_rollout') ||
		applicationMerchantConfig?.optimization_type === OPTIMIZATION_TYPE.COMBINED
	);
};
