import { getRootEnv } from '@bringg-frontend/bringg-web-infra';
import { action, computed, IReactionDisposer, makeObservable, observable, reaction } from 'mobx';
import { getRoot } from 'mobx-easy';
import i18next from 'i18next';
import moment, { Moment } from 'moment';
import _sortBy from 'lodash/sortBy';
import _first from 'lodash/first';
import _isNil from 'lodash/isNil';
import { BringgException } from '@bringg/dashboard-sdk/dist/Core/BringgException';
import { ActualBreak, ApplicationUuid, ParkingSpot, ServiceArea, TeamConfiguration } from '@bringg/types';
import { VehicleGroup } from '@bringg/dashboard-sdk/dist/Vehicle/Vehicle.consts';
import { BringgDashboardSDK } from '@bringg/dashboard-sdk';

import RootStore from 'bringg-web/stores/root-store';
import TeamsStore from 'bringg-web/stores/teams/teams-store';
import DriversStore from 'bringg-web/stores/drivers/drivers-store';
import MerchantConfigurationsStore from 'bringg-web/stores/merchant-configurations/merchant-configurations-store';
import UserTypeStore from 'bringg-web/stores/user-type/user-type-store';
import applicationMerchantConfigurationStore from 'bringg-web/stores/application-merchant-configuration/application-merchant-configuration-store';
import applicationTeamConfigurationStore from 'bringg-web/stores/application-team-configuration/application-team-configuration-store';
import VehicleTypesStore from 'bringg-web/stores/vehicle-types/vehicle-types-store';
import { deliveryBlocksRootStore } from './delivery-blocks-root-store';
import DeliveryBlocksStore from './delivery-blocks-store';
import { DeliveryBlockModalViewMode } from '../modal/delivery-block-modal';
import DeliveryBlockModalView from '../modal/delivery-block-modal-view';
import DeliveryBlockToolbarView, { TimeRange } from '../toolbar/delivery-block-toolbar-view';
import DeliveryBlocksCalendarView from '../calendar/delivery-blocks-calendar-view';
import notification from '../../../services/notification';
import DeliveryBlockBreak, { DeliveryBlockBreakId } from './domain-objects/delivery-block-break';
import { hasFeatureFlag } from 'bringg-web/utils/feature-flags';
import * as parkingSpotsProvider from '../../../services/parking-spots-provider';
import Team from 'bringg-web/stores/teams/domain-object/team';
import { hasCombinedOptimization } from 'bringg-web/features/delivery-blocks-v2/utils/use-has-combined-optimization';
import DeliveryBlock from './domain-objects/delivery-block';
import { timezoneProvider } from 'bringg-web/services/timezone/timezone-provider';

export type DateTime = Moment | Date;

class DeliveryBlocksView {
	teamsStore: TeamsStore;
	driversStore: DriversStore;
	deliveryBlocksStore: DeliveryBlocksStore;
	merchantConfigurationsStore: MerchantConfigurationsStore;
	userTypesStore: UserTypeStore;
	vehiclesStore: BringgDashboardSDK['v2']['vehicles'];
	vehicleTypesStore: VehicleTypesStore;
	applicationMerchantConfigurationStore: applicationMerchantConfigurationStore;
	applicationTeamConfigurationStore: applicationTeamConfigurationStore;
	deliveryBlocksCalendarView: DeliveryBlocksCalendarView = null;
	deliveryBlockModalView: DeliveryBlockModalView = null;
	deliveryBlockToolbarView: DeliveryBlockToolbarView = null;
	currentTeamId: number = null;
	currentTeamConfiguration: TeamConfiguration = null;
	deliveryBlockModalVisible = false;
	parkingSpots: ParkingSpot[] = [];
	serviceAreas: ServiceArea[] = [];
	private modalDisposer: IReactionDisposer = null;
	isFetching = true;

	constructor() {
		makeObservable(this, {
			deliveryBlocksCalendarView: observable.shallow,
			deliveryBlockModalView: observable.shallow,
			deliveryBlockToolbarView: observable.shallow,
			currentTeamId: observable,
			isFetching: observable,
			parkingSpots: observable,
			serviceAreas: observable,
			deliveryBlockModalVisible: observable,
			useOldBreaks: computed,
			isDeliveryBlocksFetching: computed,
			isUserTypesFetched: computed,
			allTeams: computed,
			breakBuffer: computed,
			currentTeam: computed,
			currentTeamLocalizationTimezone: computed,
			setCurrentTeamId: action,
			setDeliveryBlockModalVisible: action,
			setDeliveryBlockModalView: action,
			setDeliveryBlockToolbarView: action,
			setParkingSpots: action
		});

		const { data } = getRoot<RootStore>();
		this.teamsStore = data.teamsStore;
		this.driversStore = data.driversStore;
		this.deliveryBlocksStore = deliveryBlocksRootStore.getStore().deliveryBlocksStore;
		this.userTypesStore = data.userTypeStore;
		this.merchantConfigurationsStore = data.merchantConfigurationsStore;
		this.vehiclesStore = data.vehiclesStore;
		this.vehicleTypesStore = data.vehicleTypesStore;
		this.applicationMerchantConfigurationStore = data.applicationMerchantConfigurationStore;
		this.applicationTeamConfigurationStore = data.applicationTeamConfigurationStore;

		this.deliveryBlockToolbarView = new DeliveryBlockToolbarView({});

		this.initReactions();
	}

	get useOldBreaks(): boolean {
		return hasFeatureFlag(getRootEnv().dashboardSdk.sdk.session.user, 'use_old_delivery_block_breaks');
	}

	get isDeliveryBlocksFetching() {
		return this.deliveryBlocksStore.isFetching;
	}

	get isUserTypesFetched() {
		return this.userTypesStore.isFetched;
	}

	get allTeams() {
		return this.teamsStore.all;
	}

	get breakBuffer() {
		return this.merchantConfigurationsStore.breakBuffer;
	}

	get currentTeam(): Team {
		return this.teamsStore.get(this.currentTeamId);
	}

	get currentTeamLocalizationTimezone() {
		return this.currentTeam.localizationTimezone;
	}

	setIsFetching = (isFetching: boolean) => {
		this.isFetching = isFetching;
	};

	setCurrentTeamId = (teamId: number) => {
		this.currentTeamId = teamId;
	};

	setParkingSpots = (parkingSpots: ParkingSpot[]) => {
		this.parkingSpots = parkingSpots;
	};

	setServiceAreas = (serviceAreas: ServiceArea[]) => {
		this.serviceAreas = serviceAreas;
	};

	setCurrentTeamConfiguration = teamConfig => {
		this.currentTeamConfiguration = teamConfig;
	};

	setDeliveryBlockModalVisible = (visible: boolean) => {
		this.deliveryBlockModalVisible = visible;
	};

	setDeliveryBlockModalView = (modal: DeliveryBlockModalView) => {
		this.deliveryBlockModalView = modal;
	};

	setDeliveryBlockToolbarView = (toolbar: DeliveryBlockToolbarView) => {
		this.deliveryBlockToolbarView = toolbar;
	};

	initReactions = () => {
		this.modalDisposer = this.createModalDisposerReaction();
	};

	createModalDisposerReaction = () =>
		// NOTE: the delay is to keep the modal closing transition
		reaction(
			() => this.deliveryBlockModalVisible,
			visible => {
				if (!visible) {
					this.setDeliveryBlockModalView(null);
				}
			},
			{ delay: 200 }
		);

	initializeData = async () => {
		this.setIsFetching(true);
		const [teams] = await Promise.all([
			this.teamsStore.fetchAll(),
			this.applicationMerchantConfigurationStore.fetchConfiguration(ApplicationUuid.RouteOptimizer2)
		]);
		const currentTeam = this.teamsStore.get(this.currentTeamId) || _sortBy(teams, 'name')[0];

		this.setCurrentTeamId(currentTeam.id);

		const promises: Promise<unknown>[] = [
			this.merchantConfigurationsStore.fetch(),
			this.userTypesStore.fetchAll(),
			...this.loadCurrentTeamData()
		];

		await Promise.all(promises);

		this.setDeliveryBlockToolbarView(
			new DeliveryBlockToolbarView({
				onTimeRangeUpdate: this.fetchWeek,
				timezone: timezoneProvider.getTimezoneByTeamId(currentTeam.id)
			})
		);
		this.deliveryBlockToolbarView.setCurrentWeek();

		this.deliveryBlocksCalendarView = new DeliveryBlocksCalendarView();
		this.setIsFetching(false);
	};

	get hasAnyResource() {
		const drivers = this.driversStore.allByTeam(this.currentTeamId) || [];
		const vehicles = this.vehiclesStore.getGroup(VehicleGroup.Team, this.currentTeamId) || [];

		const isCombinedOptimization = hasCombinedOptimization(
			getRoot<RootStore>().data.applicationMerchantConfigurationStore,
			this.currentTeam
		);

		return drivers.length > 0 || (isCombinedOptimization && vehicles.length > 0);
	}

	openNewDeliveryBlock = () => {
		const drivers = this.driversStore.allByTeam(this.currentTeamId);

		if (this.hasAnyResource) {
			this.deliveryBlockModalView = new DeliveryBlockModalView({
				deliveryBlock: new DeliveryBlock(this.deliveryBlocksStore, this.emptyDeliveryBlock()),
				drivers,
				breakBuffer: this.breakBuffer,
				viewMode: DeliveryBlockModalViewMode.CREATE,
				refetchWeek: this.updateFetchedWeek.bind(this),
				useOldBreaks: this.useOldBreaks
			});

			this.setDeliveryBlockModalVisible(true);
		}
	};

	openViewDeliveryBlock = (deliveryBlockId: number) => {
		const deliveryBlock = this.deliveryBlocksStore.get(deliveryBlockId);

		this.setDeliveryBlockModalView(
			new DeliveryBlockModalView({
				deliveryBlock,
				drivers: this.driversStore.allByTeam(this.currentTeamId),
				breakBuffer: this.breakBuffer,
				viewMode: DeliveryBlockModalViewMode.VIEW,
				refetchWeek: this.updateFetchedWeek.bind(this),
				useOldBreaks: this.useOldBreaks
			})
		);

		this.setDeliveryBlockModalVisible(true);
	};

	updateDeliveryBlockBreakTime = async (
		deliveryBlockId: number,
		breakId: DeliveryBlockBreakId,
		actualBreakId: number,
		start: DateTime,
		end: DateTime
	) => {
		const deliveryBlock = this.deliveryBlocksStore.get(deliveryBlockId);
		const { start_time, end_time } = deliveryBlock;
		const deliveryBlockBreak = deliveryBlock.delivery_block_breaks.find(({ id }) => id === breakId);

		if (_isNil(deliveryBlockBreak)) {
			const error = new Error('could not find the requested break to update');
			// @ts-ignore
			error.noBreakMessage = i18next.t('DELIVERY_BLOCKS.COULD_NOT_FIND_THE_REQUESTED_BREAK_TO_UPDATE');
			throw error;
		}

		let actualBreakToUpdate: ActualBreak;
		let startLimit = start_time;
		let endLimit = end_time;
		let breakDuration = deliveryBlockBreak.durationInMinutes;

		if (actualBreakId) {
			const { start_time: flexBreakStart, end_time: flexBreakEnd } = deliveryBlockBreak;

			startLimit = flexBreakStart;
			endLimit = flexBreakEnd;

			actualBreakToUpdate = deliveryBlockBreak.actual_breaks.find(({ id }) => id === actualBreakId);
			const { estimated_start_time, estimated_end_time } = actualBreakToUpdate;
			breakDuration = moment.duration(moment(estimated_end_time).diff(estimated_start_time)).asMinutes();
		}

		let startTimeToUpdate = start.toISOString();
		let endTimeToUpdate = end.toISOString();

		if (moment(start).isBefore(startLimit)) {
			startTimeToUpdate = startLimit;
			endTimeToUpdate = moment(startLimit).add(breakDuration, 'minute').toISOString();
		}

		if (moment(end).isAfter(endLimit)) {
			startTimeToUpdate = moment(endLimit).subtract(breakDuration, 'minute').toISOString();
			endTimeToUpdate = endLimit;
		}

		if (actualBreakId) {
			return this.updateDeliveryBlockActualBreak(
				deliveryBlockId,
				deliveryBlockBreak,
				actualBreakToUpdate,
				startTimeToUpdate,
				endTimeToUpdate
			);
		}

		let breakParamsToUpdate: Partial<DeliveryBlockBreak> = {
			start_time: startTimeToUpdate,
			end_time: endTimeToUpdate
		};

		if (deliveryBlockBreak.isFlexBreak) {
			const startTimeDiff = moment
				.duration(moment(startTimeToUpdate).diff(deliveryBlockBreak.start_time))
				.asMinutes();
			const updatedActualBreaks = deliveryBlockBreak.actual_breaks.map(actualBreak => ({
				...actualBreak,
				estimated_start_time: moment(actualBreak.estimated_start_time)
					.add(startTimeDiff, 'minute')
					.toISOString(),
				estimated_end_time: moment(actualBreak.estimated_end_time).add(startTimeDiff, 'minute').toISOString()
			}));

			breakParamsToUpdate = { ...breakParamsToUpdate, actual_breaks: updatedActualBreaks };
		}

		deliveryBlockBreak.setDeliveryBlockBreak(breakParamsToUpdate);
		return this.updateDeliveryBlockBreak(deliveryBlockId, deliveryBlockBreak);
	};

	updateDeliveryBlockBreak = async (deliveryBlockId: number, deliveryBlockBreakToUpdate: DeliveryBlockBreak) => {
		const deliveryBlock = this.deliveryBlocksStore.get(deliveryBlockId);

		deliveryBlock.viewModel.delivery_block_breaks = deliveryBlock.delivery_block_breaks.map(deliveryBlockBreak => {
			if (deliveryBlockBreak.id !== deliveryBlockBreakToUpdate.id) {
				return deliveryBlockBreak;
			}

			return deliveryBlockBreakToUpdate;
		});

		return deliveryBlock.viewTransactionUpdate();
	};

	updateDeliveryBlockActualBreak = async (
		deliveryBlockId: number,
		deliveryBlockBreak: DeliveryBlockBreak,
		actualBreakToUpdate: ActualBreak,
		startTimeToUpdate: string,
		endTimeToUpdate: string
	) => {
		const updatedActualBreak: ActualBreak = {
			...actualBreakToUpdate,
			estimated_start_time: startTimeToUpdate,
			estimated_end_time: endTimeToUpdate
		};
		const updatedActualBreaks = deliveryBlockBreak.actual_breaks.map(actualBreak => {
			if (actualBreak.id !== actualBreakToUpdate.id) {
				return actualBreak;
			}

			return updatedActualBreak;
		});

		deliveryBlockBreak.setDeliveryBlockBreak({ actual_breaks: updatedActualBreaks });
		return this.updateDeliveryBlockBreak(deliveryBlockId, deliveryBlockBreak);
	};

	updateDeliveryBlockTime = async (deliveryBlockId: number, start: DateTime, end: DateTime) => {
		const deliveryBlock = this.deliveryBlocksStore.get(deliveryBlockId);

		const diff = moment(end).diff(start);
		const DayInHoursValue = 24;
		let endTime = end;

		if (moment.duration(diff).asHours() >= DayInHoursValue) {
			endTime = moment(start).clone().add(1, 'd').subtract(1, 'm');
		} else if (moment.duration(diff).asHours() < 1) {
			endTime = moment(start).clone().add(1, 'h');
		}

		if (!deliveryBlock.hasBreak) {
			deliveryBlock.viewModel.start_time = start.toISOString();
			deliveryBlock.viewModel.end_time = endTime.toISOString();
			return deliveryBlock.viewTransactionUpdate();
		}

		const startTimeDiff = moment.duration(moment(start).diff(deliveryBlock.start_time)).asMinutes();

		deliveryBlock.viewModel.delivery_block_breaks = deliveryBlock.delivery_block_breaks.map(deliveryBlockBreak => {
			const deliveryBlockUpdateBreak = new DeliveryBlockBreak();

			const updatedActualBreaks = deliveryBlockBreak.actual_breaks.map(actualBreak => ({
				...actualBreak,
				estimated_start_time: moment(actualBreak.estimated_start_time)
					.add(startTimeDiff, 'minute')
					.toISOString(),
				estimated_end_time: moment(actualBreak.estimated_end_time).add(startTimeDiff, 'minute').toISOString()
			}));

			deliveryBlockUpdateBreak.setDeliveryBlockBreak({
				...deliveryBlockBreak,
				start_time: moment(deliveryBlockBreak.start_time).add(startTimeDiff, 'minute').toISOString(),
				end_time: moment(deliveryBlockBreak.end_time).add(startTimeDiff, 'minute').toISOString(),
				actual_breaks: updatedActualBreaks
			});

			return deliveryBlockUpdateBreak;
		});

		if (this.useOldBreaks) {
			const { start_time, end_time } = _first(deliveryBlock.viewModel.delivery_block_breaks);
			deliveryBlock.viewModel.break_start_time = start_time;
			deliveryBlock.viewModel.break_end_time = end_time;
		}

		deliveryBlock.viewModel.start_time = start.toISOString();
		deliveryBlock.viewModel.end_time = endTime.toISOString();
		return deliveryBlock.viewTransactionUpdate();
	};

	onDragDropDeliveryBlockBreak = async (
		deliveryBlockId: number,
		breakId: DeliveryBlockBreakId,
		actualBreakId: number,
		start: DateTime,
		end: DateTime
	) => {
		try {
			await this.updateDeliveryBlockBreakTime(deliveryBlockId, breakId, actualBreakId, start, end);
			notification.success(i18next.t('DELIVERY_BLOCKS.SUCCESS_MOVED_BREAK'));
		} catch (error) {
			console.error(error);
			notification.error(
				// @ts-ignore
				error?.noBreakMessage || i18next.t('DELIVERY_BLOCKS.FAILED_TO_CREATE_OR_UPDATE'),
				(error as BringgException).details as string
			);
		}
	};

	onDragDropDeliveryBlock = async (id: number, start: DateTime, end: DateTime) => {
		try {
			await this.updateDeliveryBlockTime(id, start, end);
			notification.success(i18next.t('DELIVERY_BLOCKS.SUCCESS_MOVED_BLOCK'));
		} catch (error) {
			console.error(error);
			notification.error(
				i18next.t('DELIVERY_BLOCKS.FAILED_TO_CREATE_OR_UPDATE'),
				(error as BringgException).details as string
			);
		}
	};

	onResizeDeliveryBlock = async (id: number, start: Date | Moment, end: Date | Moment) => {
		const deliveryBlock = this.deliveryBlocksStore.get(id);
		if (deliveryBlock.hasBreak) {
			notification.error(i18next.t('DELIVERY_BLOCKS.CAN_NOT_RESIZE_WITH_BREAKS'));
			return;
		}

		if (moment(start).isSameOrAfter(moment(end))) {
			notification.error(i18next.t('DELIVERY_BLOCKS.BLOCK_END_MUST_BE_AFTER_START'));
			return;
		}

		try {
			await this.updateDeliveryBlockTime(id, start, end);
			notification.success(i18next.t('DELIVERY_BLOCKS.SUCCESS_RESIZE_BLOCK'));
		} catch (error) {
			console.error(error);
			notification.error(
				i18next.t('DELIVERY_BLOCKS.FAILED_TO_CREATE_OR_UPDATE'),
				(error as BringgException).details as string
			);
		}
	};

	closeDeliveryBlockModal = async (reFetch?: boolean) => {
		this.setDeliveryBlockModalVisible(false);

		// need to fetch again after recurring update
		if (reFetch) {
			await this.updateFetchedWeek();
		}
	};

	onTeamSelect = async (teamId, weekRange: TimeRange) => {
		if (teamId === this.currentTeamId) {
			return;
		}

		this.setIsFetching(true);
		this.setCurrentTeamId(teamId);
		this.deliveryBlockToolbarView.setWeek(weekRange.startDate, weekRange.endDate);

		const promises: Promise<unknown>[] = [
			this.fetchWeek(this.deliveryBlockToolbarView.timeRange),
			...this.loadCurrentTeamData()
		];

		await Promise.all(promises);
		this.setIsFetching(false);
	};

	loadCurrentTeamData = () => {
		const isCombinedOptimization = hasCombinedOptimization(
			getRoot<RootStore>().data.applicationMerchantConfigurationStore,
			this.currentTeam
		);
		const promises: Promise<unknown>[] = [
			this.driversStore.fetchByTeam(this.currentTeamId),
			this.applicationTeamConfigurationStore.getConfiguration(ApplicationUuid.RouteOptimizer2, this.currentTeamId)
		];

		if (isCombinedOptimization) {
			promises.push(
				this.vehiclesStore.loadAllByTeam(this.currentTeamId),
				parkingSpotsProvider
					.getDriverParkingSpots(this.currentTeamId)
					.then(parkingSpots => this.setParkingSpots(parkingSpots)),
				this.vehicleTypesStore.fetchAll(),
				this.teamsStore
					.getServiceAreas(this.currentTeamId, false)
					.then(serviceAreas => this.setServiceAreas(serviceAreas))
			);
		}

		return promises;
	};

	async updateFetchedWeek() {
		const { timeRange } = this.deliveryBlockToolbarView;
		await this.fetchWeek(timeRange);
	}

	fetchWeek = async ({ startDate, endDate }: TimeRange) => {
		await this.deliveryBlocksStore.fetch(this.currentTeamId, startDate.unix(), endDate.unix());
	};

	emptyDeliveryBlock = (): Partial<Bringg.DeliveryBlock> => ({
		team_id: this.currentTeamId,
		user_ids: [],
		original_capacity: 1
	});

	disposeReactions = () => {
		if (this.modalDisposer) {
			this.modalDisposer();
		}
	};

	dispose = () => {
		this.disposeReactions();
		this.parkingSpots = [];
		this.setCurrentTeamId(null);
		this.isFetching = true;
	};
}

export default DeliveryBlocksView;
