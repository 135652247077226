import { getRoot } from 'mobx-easy';
import moment from 'moment-timezone';
import { Task } from '@bringg/types';
import _first from 'lodash/first';
import _isNumber from 'lodash/isNumber';
import _isNil from 'lodash/isNil';

import RootStore from 'bringg-web/stores/root-store';

function getTimezoneByTeamId(teamId: number): string {
	const teamTimezone = getRoot<RootStore>().data.teamsStore.get(teamId)?.localizationTimezone;
	return teamTimezone || moment.tz.guess();
}

function getTimezoneByMerchant(): string {
	const merchantTimezone = getRoot<RootStore>().data.merchantStore.merchant?.time_zone;
	return merchantTimezone || moment.tz.guess();
}

function getTimezoneByTask(task: Task): string {
	const teamId = _first(task?.team_ids);

	if (!_isNumber(teamId)) {
		return null;
	}

	const team = getRoot<RootStore>().data.teamsStore.get(teamId);

	if (_isNil(team)) {
		return null;
	}

	return team.localizationTimezone;
}

export const timezoneProvider = { getTimezoneByTeamId, getTimezoneByMerchant, getTimezoneByTask };
