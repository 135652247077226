import React from 'react';

import { Button } from '@bringg/react-components';

import styles from './footer.module.scss';

interface Props {
	onApply: () => void;
	onCancel: () => void;
	isDirty: boolean;
	translations: { apply?: string; cancel?: string };
}

const Footer = ({ onApply, onCancel, isDirty, translations }: Props) => {
	const { apply = 'Apply', cancel = 'Cancel' } = translations;

	return (
		<div className={styles.footer}>
			<Button type="link" onClick={onCancel} data-test-id="cancel-button">
				{cancel}
			</Button>
			<Button type="primary" onClick={onApply} disabled={!isDirty} data-test-id="apply-button">
				{apply}
			</Button>
		</div>
	);
};

export default Footer;
