import React, { useState, useEffect, LegacyRef } from 'react';

import { useTranslation } from 'react-i18next';
import { noop as _noop, find as _find } from 'lodash';
import { Popconfirm } from 'antd';
// eslint-disable-next-line import/no-extraneous-dependencies
import $ from 'jquery';
// eslint-disable-next-line import/no-extraneous-dependencies
import { JSONSchema6 } from 'json-schema';
import TitleField from 'react-jsonschema-form/lib/components/fields/TitleField';
import MultiSchemaField from 'react-jsonschema-form/lib/components/fields/MultiSchemaField';
import ButtonWrapper from '@bringg/react-components/dist/components/button/button';
import Form, { ISubmitEvent, FieldProps } from 'react-jsonschema-form';
import { withErrorBoundary } from '@bringg-frontend/bringg-web-infra';

import { Spinner } from 'bringg-web/components';
import { useStores } from 'bringg-web/recipes';
import notification from 'bringg-web/services/notification';
import ErrorDisplay from './error-display';

import './application-configuration-form.scss';

// Note the actual props for the title field are only partial FieldProps, with only the id, title, required and formContext properties.
const TranslateTitleField = (props: FieldProps) => {
	const { t } = useTranslation();
	const translatedTitle = t(props.title);
	return <TitleField {...props} title={translatedTitle} />;
};

const TranslateMultiSchemaField = (props: FieldProps) => {
	const { t } = useTranslation();
	const translatedOptions = props.options.map((option: JSONSchema6) =>
		option.title ? { ...option, title: t(option.title) } : option
	);

	return <MultiSchemaField {...props} options={translatedOptions} />;
};

const ApplicationConfigurationForm = ({
	appUuid,
	teamId,
	formRef,
	onFormRender = _noop,
	disableForm = _noop
}: {
	appUuid: Bringg.ApplicationUuid;
	teamId?: number;
	formRef?: LegacyRef<Form<unknown>>;
	onFormRender?: (pluginIndex: number) => void;
	disableForm?: (disable: boolean, merchantAssignToBusyDrivers?: boolean) => void;
}) => {
	const { t } = useTranslation();
	const [config, setConfig] = useState<any>(null);
	const [configLoading, setConfigLoading] = useState<boolean>(true);
	const [applicationTakenFromMerchant, setApplicationTakenFromMerchant] = useState<boolean>(false);
	const [schema, setSchema] = useState<JSONSchema6>(null);
	const [schemaError, setSchemaError] = useState<string>(null);
	const [configValueError, setConfigValueError] = useState<string>(null);
	const [pluginIndex, setPluginIndex] = useState<number>(0);
	const [merchantAssignToBusyDrivers, setMerchantAssignToBusyDrivers] = useState<boolean>(null);
	const {
		merchantConfigurationsStore: merchantConfigurations,
		applicationMerchantConfigurationStore: applicationMerchantConfiguration,
		applicationTeamConfigurationStore: applicationTeamConfiguration
	} = useStores();

	const setDelayedAssignmentPluginIndex = (delayAssPlugin, plugins): void => {
		let delayAssPluginToSet = delayAssPlugin;

		if (!delayAssPluginToSet) {
			delayAssPluginToSet = { name: 'DelayAssignments', options: {} };
			plugins.push(delayAssPluginToSet);
		}
		setPluginIndex(plugins.indexOf(delayAssPluginToSet));
	};

	const initApplicationMerchantConfiguration = async (): Promise<void> => {
		let conf = applicationMerchantConfiguration.getConfiguration(appUuid);

		if (!conf) {
			try {
				conf = await applicationMerchantConfiguration.fetchConfiguration(appUuid);
			} catch (error) {
				setConfigValueError(`Failed to get config: ${(error as TypeError).message}`);
				setConfigLoading(false);
			}
		}

		if (merchantAssignToBusyDrivers === null && conf?.plugins) {
			const delayAssPlugin = _find(conf.plugins, { name: 'DelayAssignments' });
			setDelayedAssignmentPluginIndex(delayAssPlugin, conf.plugins);
			setMerchantAssignToBusyDrivers(delayAssPlugin?.options.skipAssignToBusyDrivers || false);
		}

		setConfig(conf);
		setConfigLoading(false);
	};

	const initApplicationTeamConfiguration = async (): Promise<void> => {
		const conf = await applicationTeamConfiguration.getConfiguration(appUuid, teamId);

		if (conf?.plugins) {
			const delayAssPlugin = _find(conf.plugins, { name: 'DelayAssignments' });
			setDelayedAssignmentPluginIndex(delayAssPlugin, conf.plugins);
		}

		setConfig(conf);
		setConfigLoading(false);
	};

	useEffect(() => {
		if (!appUuid) {
			return;
		}

		const asyncEffect = async () => {
			if (!teamId) {
				await initApplicationMerchantConfiguration();
			} else {
				await initApplicationTeamConfiguration();
			}
		};
		asyncEffect();
	}, [appUuid, teamId, applicationMerchantConfiguration, applicationTeamConfiguration]);

	useEffect(() => {
		if (!appUuid) {
			return;
		}

		const application = merchantConfigurations.findApplication(appUuid);
		if (!application?.schema) {
			setSchemaError('Application does not allow configuration editing.');
		} else {
			setSchemaError(null);

			// for team conf page, override title in scheme
			if (teamId) {
				// @ts-ignore
				const plugin = _find(application.schema.properties.plugins.items.anyOf, {
					title: 'Delayed Assignments'
				});
				plugin.properties.options.properties.skipAssignToBusyDrivers.title = t(
					'TEAMS.CONFIGURATION_FORM.ONLY_ONE_ORDER_PER_DRIVER'
				);
			}

			setSchema(application.schema);
		}
	}, [appUuid, merchantConfigurations]);

	useEffect(() => {
		onFormRender(pluginIndex);
	});

	const onSubmit = async (form: ISubmitEvent<any>) => {
		if (teamId) {
			// update checkbox value from element
			_find(form.formData.plugins, { name: 'DelayAssignments' }).options.skipAssignToBusyDrivers = $(
				'#root_options_skipAssignToBusyDrivers'
			)[0].checked;

			const success = await applicationTeamConfiguration.updateConfiguration(appUuid, teamId, form.formData);
			if (success) {
				notification.success(t('ACTION_CONFIGURATION.UPDATE_SUCCESS'));
			} else {
				notification.error(t('ACTION_CONFIGURATION.UPDATE_FAILED'));
			}
		} else {
			applicationMerchantConfiguration.updateConfiguration(appUuid, form.formData);
		}
	};

	const createTeamConf = () => {
		const conf = { plugins: [{ name: 'DelayAssignments', options: { skipAssignToBusyDrivers: false } }] };
		setConfig(conf);
	};

	const removeTeamConf = async () => {
		const conf = await applicationTeamConfiguration.getConfiguration(appUuid, teamId);
		if (!conf) {
			// remove before configuration saved
			setConfig(null);
			notification.success(t('ACTION_CONFIGURATION.DELETE_SUCCESS'));
		} else {
			const success = await applicationTeamConfiguration.removeConfiguration(appUuid, teamId);
			if (success) {
				setConfig(null);
				notification.success(t('ACTION_CONFIGURATION.DELETE_SUCCESS'));
			} else {
				notification.error(t('ACTION_CONFIGURATION.DELETE_FAILED'));
			}
		}
	};

	if (!appUuid) {
		return <>Select an app to edit.</>;
	}

	if (configValueError || schemaError) {
		return <ErrorDisplay errors={[schemaError, configValueError]} />;
	}

	if (configLoading || !schema) {
		return <Spinner className="spinner" />;
	}

	if (teamId && !config) {
		// take config from merchant and disable changes
		setConfigLoading(true);
		initApplicationMerchantConfiguration();
		setApplicationTakenFromMerchant(true);
		return <Spinner className="spinner" />;
	}

	const showDeleteTeamConf = !!(teamId && !applicationTakenFromMerchant && config && Object.keys(config));

	if (applicationTakenFromMerchant) {
		if (!_find(config.plugins, { name: 'DelayAssignments' })) {
			createTeamConf();
		}
		disableForm(true, merchantAssignToBusyDrivers);
	}

	const override = () => {
		setApplicationTakenFromMerchant(false);
		disableForm(false);
	};

	return (
		<div>
			<Form
				schema={schema}
				onSubmit={onSubmit}
				formData={config}
				ref={formRef}
				fields={{
					TitleField: TranslateTitleField,
					AnyOfField: TranslateMultiSchemaField,
					OneOfField: TranslateMultiSchemaField
				}}
			/>
			{applicationTakenFromMerchant && (
				<div>
					<ButtonWrapper className="create-conf-button" type="primary" onClick={override}>
						{t('TEAMS.CONFIGURATION_FORM.OVERRIDE')}
					</ButtonWrapper>
				</div>
			)}
			{showDeleteTeamConf && (
				<div>
					<Popconfirm
						overlayClassName="confirm-delete"
						title={t('ACTION_CONFIGURATION.CONFIRM_DELETE')}
						onConfirm={removeTeamConf}
						okText="YES"
						cancelText="NO"
					>
						<ButtonWrapper danger type="primary" className="delete-button">
							{t('TEAMS.CONFIGURATION_FORM.DELETE')}
						</ButtonWrapper>
					</Popconfirm>
				</div>
			)}
		</div>
	);
};

export default withErrorBoundary(ApplicationConfigurationForm);
