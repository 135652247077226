import React from 'react';
import { Button } from '@bringg/react-components';
import './delivery-block-modal-footer.scss';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

interface Props {
	onOk: () => void;
	onCancel: () => void;
	onDelete: () => void;
	isInEditMode: boolean;
	isEnabled: boolean;
	loading: { ok: boolean; delete: boolean };
}

export const DeliveryBlockModalFooter = ({ onOk, onCancel, onDelete, isInEditMode, isEnabled, loading }: Props) => {
	const { t } = useTranslation();
	return (
		<div className={classNames('delivery-block-modal-footer', { disabled: !isEnabled })}>
			<Button
				className={classNames('delivery-block-modal-footer-delete', {
					'visibility-hidden': !isInEditMode
				})}
				type="link"
				danger
				onClick={onDelete}
				loading={loading.delete}
				disabled={loading.ok || loading.delete || !isEnabled}
			>
				{t('DELIVERY_BLOCK.MODAL.DELETE')}
			</Button>
			<div className="delivery-block-modal-footer-ok-and-cancel">
				<Button
					className="delivery-block-modal-footer-cancel"
					type="default"
					onClick={onCancel}
					disabled={loading.ok || loading.delete || !isEnabled}
				>
					{t('DELIVERY_BLOCK.MODAL.CANCEL_BUTTON_TEXT')}
				</Button>
				<Button
					className="delivery-block-modal-footer-ok"
					type="primary"
					onClick={onOk}
					loading={loading.ok}
					disabled={loading.ok || loading.delete || !isEnabled}
				>
					{isInEditMode ? t('DELIVERY_BLOCK.MODAL.SAVE_CHANGES') : t('DELIVERY_BLOCK.MODAL.CREATE')}
				</Button>
			</div>
		</div>
	);
};
