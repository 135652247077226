import React, { useContext, useCallback } from 'react';

import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import { Select } from '@bringg/react-components';

import InputLabelWithTooltip from './../input-label-with-tooltip/input-label-with-tooltip';
import { OptimizationSettingsFormContext } from '../optimization-configuration-form/optimization-configuration-form';
import { OptimizationDropdownAttribute, OptimizationSettingsFormContextType } from '../../types';

const Dropdown = ({
	options,
	id,
	title,
	value,
	allowEmpty,
	tooltipTitle,
	isSubField,
	constraints: { required },
	disabled,
	containerId
}: OptimizationDropdownAttribute) => {
	const { handleChange } = useContext<OptimizationSettingsFormContextType>(OptimizationSettingsFormContext);
	const { t } = useTranslation();

	const selectOptions = allowEmpty ? [{ id: 'empty_', title: ' ' }, ...options] : options;
	const wrapperId = `dropdown-wrapper-${id}`;

	const getPopupContainer = useCallback(() => document.getElementById(wrapperId), [wrapperId]);

	return (
		<div id={wrapperId} className={classnames('route-optimization-field-container', { 'sub-field': isSubField })}>
			<InputLabelWithTooltip
				id={id}
				title={t(title)}
				required={required}
				tooltip={tooltipTitle ? t(tooltipTitle) : null}
				containerId={containerId}
			/>
			<Select
				className="route-optimization-dropdown"
				style={{ width: 240 }}
				showArrow
				onChange={nextValue => handleChange(id, { value: nextValue, isValid: true })}
				id={id}
				value={value ?? options.find(({ default: d }) => d).title}
				options={selectOptions.map(({ title, id }) => ({ id, name: t(title) }))}
				getPopupContainer={getPopupContainer}
				disabled={disabled}
			/>
		</div>
	);
};

export default Dropdown;
