import React, { createContext, useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { toJS } from 'mobx';
import { cloneDeep, isEqual, isNil, isObject } from 'lodash';
import moment from 'moment';
import { Button, Collapse, ConfirmModal } from '@bringg/react-components';
import { BringgFontIcons, BringgIcon } from '@bringg/bringg-icons';
import { ApplicationTeamConfigurations, User } from '@bringg/types';
import classNames from 'classnames';

import Team from 'bringg-web/stores/teams/domain-object/team';
import { useStores } from 'bringg-web/recipes';
import { hasFeatureFlag } from 'bringg-web/utils/feature-flags';
import {
	enrichTeamsTreeData,
	getValuesWithDefaults,
	isAllAttributesValid
} from 'bringg-web/features/optimization-configuration/utils';
import useTeamsTreeData from '../../../../hooks/use-teams-tree-data';
import { getRelevantConfigurationFieldsWithValue } from '../../optimization-configuration';
import NumberInput from '../input/number-input';
import StringInput from '../input/string-input';
import Checkbox from '../checkbox/checkbox';
import Slider from '../slider/slider';
import PercentageInput from '../percentage-input/percentage-input';
import TwoStateToggleBtn from '../toggle-input/toggle-input';
import Dropdown from '../dropdown/dropdown';
import TimeInput from '../time-input/time-input';
import OptimizationSettingsFormHeader from './form-header/form-header';
import {
	GenericParameter,
	OptimizationConfigurationManifestTypes,
	OptimizationConfigurationView,
	OptimizationSettingsFormContextType
} from './../../types';

import './optimization-configuration-form.scss';

interface OptimizationSettingsProps {
	containerId: string;
	values: OptimizationConfigurationView;
	getRouteOptimizationApplicationATCs: (teamId: number) => ApplicationTeamConfigurations;
	onSubmit: (data: Record<string, any>) => void;
	onCancel: () => void;
	onCreate: () => void;
	createNewEnabled: boolean;
	onChange?: (json: object) => void;
	isTeamOptimizationSupported?: (teamId: number) => boolean;
	allTeams?: Team[];
	isOpenedFromOptimizationSimulator?: boolean;
	isDiscardChangesClicked?: boolean;
	className?: string;
}

const getValuesFromConfiguration = (configurations: GenericParameter[]) => {
	return configurations.reduce((pureValuesAcc, params) => {
		if (isNil(params.value)) {
			return pureValuesAcc;
		}

		const pureValues = {
			[params.id]: params.value
		};

		if (params.type === OptimizationConfigurationManifestTypes.CONDITIONAL_TIME_INPUT) {
			const selectedOption = params.options.find(option => option.id === params.value);

			if (selectedOption && selectedOption.timePicker) {
				Object.assign(pureValues, {
					[selectedOption.timePickerValueKey]: moment(params[selectedOption.timePickerValueKey]).format(
						'HH:mm'
					) // should be always parsed from 24h format
				});
			}
		}

		return Object.assign(pureValuesAcc, pureValues);
	}, {});
};

const createElement = (props: GenericParameter, containerId: string) => {
	switch (props.type) {
		case OptimizationConfigurationManifestTypes.NUMBER_INPUT:
			return <NumberInput {...props} key={props.id} containerId={containerId} />;
		case OptimizationConfigurationManifestTypes.PERCENTAGE_INPUT:
			return <PercentageInput {...props} key={props.id} containerId={containerId} />;
		case OptimizationConfigurationManifestTypes.STRING_INPUT:
			return <StringInput {...props} key={props.id} containerId={containerId} />;
		case OptimizationConfigurationManifestTypes.DROPDOWN:
			return <Dropdown {...props} key={props.id} containerId={containerId} />;
		case OptimizationConfigurationManifestTypes.CHECKBOX:
			return <Checkbox {...props} key={props.id} containerId={containerId} />;
		case OptimizationConfigurationManifestTypes.NUMBERS_RANGE_PICKER:
			return <Slider {...props} key={props.id} containerId={containerId} />;
		case OptimizationConfigurationManifestTypes.TOGGLE:
			return <TwoStateToggleBtn {...props} key={props.id} containerId={containerId} />;
		case OptimizationConfigurationManifestTypes.CONDITIONAL_TIME_INPUT:
			return <TimeInput {...props} key={props.id} containerId={containerId} />;
	}
};

const categorizeFields = (formData, currentUser: User) =>
	formData.reduce(
		(acc, attr: GenericParameter) => {
			if (attr.visible_by_ff ? hasFeatureFlag(currentUser, attr.visible_by_ff) : true) {
				if (attr.section === 'general') acc.generalFields.push(attr);
				else if (attr.section === 'route') acc.routeFields.push(attr);
				else if (attr.section === 'vehicleAndDriver') acc.vehicleAndDriverFields.push(attr);
			}

			return acc;
		},
		{ generalFields: [], routeFields: [], vehicleAndDriverFields: [] }
	);

export const OptimizationSettingsFormContext = createContext<OptimizationSettingsFormContextType | null>(null);

const OptimizationConfigurationForm = ({
	containerId,
	values,
	allTeams,
	getRouteOptimizationApplicationATCs,
	onSubmit,
	onCancel,
	isTeamOptimizationSupported,
	isOpenedFromOptimizationSimulator,
	onChange,
	isDiscardChangesClicked,
	className
}: OptimizationSettingsProps) => {
	const { t } = useTranslation();

	const { routeOptimizationConfigurationStore, usersStore } = useStores();
	const { teamToConfigMap, manifest, merchantTeamSettings } = routeOptimizationConfigurationStore;
	const { currentUser } = usersStore;

	const [name, setName] = useState<string>(values?.configurationName ?? '');
	const [description, setDescription] = useState(values?.description ?? '');
	const [teamIds, setTeamIds] = useState(values?.teams ?? []);

	const [configurationFormData, setConfigurationFormData] = useState<GenericParameter[]>([]);

	useEffect(() => {
		setConfigurationFormData(values?.configuration);
	}, [values?.configuration]);

	useEffect(() => {
		if (isDiscardChangesClicked) {
			onDiscardChangesClick();
		}
	}, [isDiscardChangesClicked]);

	const handleSectionFormDataChange = (id, value) => {
		let nextConfigurationFormData = cloneDeep(configurationFormData);
		const index = nextConfigurationFormData.findIndex(field => field.id === id);

		nextConfigurationFormData[index] = {
			...nextConfigurationFormData[index],
			...(isObject(value) ? value : { value })
		};

		const item = nextConfigurationFormData[index];

		if (
			(item.type === OptimizationConfigurationManifestTypes.CHECKBOX ||
				item.type === OptimizationConfigurationManifestTypes.TOGGLE) &&
			item.sub_fields_if_enabled
		) {
			// hacky way to temporary present value as a default value param to be filled correctly
			// with default values from inside `sub_fields_if_enabled` fields
			// from the call to `getValuesWithDefaults` func and override later with current form values
			// from the call to `getValuesFromConfiguration` func
			item.default = value;

			const nextValues = {
				...getValuesWithDefaults(nextConfigurationFormData),
				...getValuesFromConfiguration(nextConfigurationFormData)
			};

			nextConfigurationFormData = getRelevantConfigurationFieldsWithValue(
				manifest,
				nextValues,
				merchantTeamSettings
			);
		}

		onChange?.({ ...getValuesFromConfiguration(nextConfigurationFormData) });
		setConfigurationFormData(nextConfigurationFormData);
	};

	const handleRestore = () => {
		setConfigurationFormData(
			getRelevantConfigurationFieldsWithValue(manifest, getValuesWithDefaults(manifest), merchantTeamSettings)
		);
	};

	const handleSubmit = () => {
		onSubmit({
			configurationName: name,
			description,
			teams: teamIds,
			configuration: { ...getValuesFromConfiguration(configurationFormData) },
			defaultConfiguration: values?.defaultConfiguration,
			...(values?.id && { id: values?.id })
		});
	};

	const teamsTreeData = enrichTeamsTreeData(useTeamsTreeData(allTeams), toJS(teamToConfigMap));

	const currentRecord = {
		...values,
		configurationName: name,
		description,
		teams: teamIds,
		configuration: configurationFormData
	};

	const formIsValid = isAllAttributesValid(currentRecord);
	const formIsDirty = !isEqual(values, currentRecord);

	const confirmModalProps = {
		title: t('GLOBAL.DISCARD'),
		content: t('OPTIMIZATION_CONFIGURATIONS.LIST.SAVE_PROMPT'),
		okText: t('OPTIMIZATION_CONFIGURATIONS.LIST.DISCARD_ALL_CHANGES'),
		cancelText: t('GLOBAL.CANCEL'),
		onOk: handleRestore
	};

	const { generalFields, routeFields, vehicleAndDriverFields } = categorizeFields(configurationFormData, currentUser);

	const onDiscardChangesClick = () => {
		if (formIsDirty) {
			ConfirmModal(confirmModalProps);
		} else {
			onCancel();
		}
	};

	return (
		<OptimizationSettingsFormContext.Provider
			value={{
				handleChange: handleSectionFormDataChange
			}}
		>
			<div
				className={classNames('route-optimization-settings-form', className)}
				data-test-id="route-optimization-settings-form"
			>
				{isOpenedFromOptimizationSimulator ? null : (
					<OptimizationSettingsFormHeader
						name={name}
						setName={setName}
						description={description}
						setDescription={setDescription}
						teamIds={teamIds}
						setTeamIds={setTeamIds}
						alreadyAssignedTeamIds={values?.teams ?? []}
						isDefaultConfiguration={values.defaultConfiguration}
						teamsTreeData={teamsTreeData}
						getRouteOptimizationApplicationATCs={getRouteOptimizationApplicationATCs}
						isTeamOptimizationSupported={isTeamOptimizationSupported}
					/>
				)}

				<div className="route-optimization-settings-form-body">
					<Collapse
						ghost
						expandIconPosition="end"
						className="optimization-settings-collapse"
						defaultActiveKey={['general', 'route', 'vehicleAndDriver']}
					>
						<Collapse.Panel
							key="general"
							header={
								<>
									<BringgIcon className="collapse-icons" iconName={BringgFontIcons.Settings} />
									<span>{t('OPTIMIZATION_CONFIGURATIONS.MANIFEST.GENERAL_PARAMETERS')}</span>
								</>
							}
						>
							{generalFields.map(fields => createElement(fields, containerId))}
						</Collapse.Panel>
						<Collapse.Panel
							key="route"
							header={
								<>
									<BringgIcon className="collapse-icons" iconName={BringgFontIcons.Route} />
									<span>{t('OPTIMIZATION_CONFIGURATIONS.MANIFEST.ROUTE_PARAMETERS')}</span>
								</>
							}
						>
							{routeFields.map(fields => createElement(fields, containerId))}
						</Collapse.Panel>
						<Collapse.Panel
							key="vehicleAndDriver"
							header={
								<>
									<BringgIcon className="collapse-icons" iconName={BringgFontIcons.Wheel} />
									<span>
										{t('OPTIMIZATION_CONFIGURATIONS.MANIFEST.VEHICLE_AND_DRIVER_PARAMETERS')}
									</span>
								</>
							}
						>
							{vehicleAndDriverFields.map(fields => createElement(fields, containerId))}
						</Collapse.Panel>
					</Collapse>
				</div>
				{isOpenedFromOptimizationSimulator ? null : (
					<div className="route-optimization-settings-form-footer">
						<Button
							type="link"
							className="route-optimization-settings-cancel"
							disabled={!formIsDirty}
							onClick={onDiscardChangesClick}
						>
							{t('OPTIMIZATION_CONFIGURATIONS.MANIFEST.DISCARD')}
						</Button>
						<Button
							type="primary"
							className="route-optimization-settings-submit"
							disabled={!formIsDirty || !formIsValid}
							onClick={handleSubmit}
						>
							{t('OPTIMIZATION_CONFIGURATIONS.MANIFEST.SAVE')}
						</Button>
					</div>
				)}
			</div>
		</OptimizationSettingsFormContext.Provider>
	);
};

export default OptimizationConfigurationForm;
