import { useEffect, useState } from 'react';
import notification from '../../../services/notification';
import { Rollout } from '../../../stores/soft-rollout-store/consts';
import useStores from '../../../recipes/use-stores';
import { useTranslation } from 'react-i18next';

export const useRollout = () => {
	const { t } = useTranslation();
	const { softRolloutStore } = useStores();
	const [rollout, setRollout] = useState<Rollout>();

	useEffect(() => {
		const updateCurrentRollout = () => {
			const rolloutConfig = softRolloutStore.currentPageRollout;
			setRollout(rolloutConfig);
		};

		const load = async () => {
			try {
				await softRolloutStore.loadConfiguration();
			} catch (err) {
				notification.error(t('ROLLOUT.FAILED_TO_LOAD_ROLLOUTS'));
			}
			updateCurrentRollout();
		};

		load();
		window.addEventListener('hashchange', updateCurrentRollout);
		return () => {
			window.removeEventListener('hashchange', updateCurrentRollout);
		};
	}, []);

	return { rollout };
};
