import React, { useEffect } from 'react';

import { observer } from 'mobx-react';
import { withErrorBoundary } from '@bringg-frontend/bringg-web-infra';
import { TasksManualParams } from '@bringg/types';

import ManualTriggerForceActionModal from './manual-trigger-modal/manual-trigger-force-action-modal';
import ManualTriggerFormModal from './manual-trigger-modal/manual-trigger-form-modal';
import { useStores } from 'bringg-web/recipes';
import TasksAsyncOperationStatus from './tasks-async-operation-status';

const ManualTriggersManager = () => {
	const { manualTriggerStore } = useStores();

	useEffect(() => {
		return () => {
			manualTriggerStore.dispose();
		};
	}, []);

	return (
		<>
			{manualTriggerStore?.forceModalOpen && (
				<ManualTriggerForceActionModal
					forceAction={async applyOnAll => manualTriggerStore.forceAction(applyOnAll)}
					allowToForceAllActions={manualTriggerStore.allowToForceActionsOnAll}
					allowToForceMatchingActions={manualTriggerStore.allowToForceActionsOnMatching}
					failedTasks={manualTriggerStore.failedTasks}
					numberOfSelectedTasks={manualTriggerStore.selectedTaskIds.length}
					onClose={() => {
						manualTriggerStore.forceModalOpen = false;
					}}
				/>
			)}
			{manualTriggerStore.manualTriggerFormModalOpen && (
				<ManualTriggerFormModal
					name={(manualTriggerStore.trigger as TasksManualParams).name}
					vars={(manualTriggerStore.trigger as TasksManualParams).vars}
					onClose={() => manualTriggerStore.abortDynamicFormModal()}
					onSubmit={async values => manualTriggerStore.submitManualTriggerForm(values)}
				/>
			)}
			{manualTriggerStore.asyncOperations.map(store => (
				<TasksAsyncOperationStatus key={store.id} store={store} />
			))}
		</>
	);
};

export default withErrorBoundary(observer(ManualTriggersManager));
