import { getRootEnv } from '@bringg-frontend/bringg-web-infra';
import { Package } from '@bringg/types';
import { getEnv } from 'mobx-easy';
import _noop from 'lodash/noop';
import { RootEnv } from '@bringg-frontend/bringg-web-infra';

import { registerAction } from '../../services/cross-application/cross-application';
import { PackagesActions } from '../../services/cross-application/cross-application.actions';
import PrintService from '../../services/print/print';

export type PrintPackageType = 'zpl' | 'pdf';

class PackagesStore {
	constructor() {
		this.initPackagePrintListener();
	}

	async getAllTaskPackages(taskId: number): Promise<Package[]> {
		return getRootEnv().dashboardSdk.sdk.packageApi.getAllTaskPackages({ task_id: taskId });
	}

	initPackagePrintListener() {
		registerAction(
			PackagesActions.PRINT_LABEL_REQUEST,
			(params: { taskIds?: number[]; type?: PrintPackageType }) => {
				if (!params) return;

				const { taskIds = [], type = 'zpl' } = params;
				const promises = taskIds.map(async id => this.getAllTaskPackages(id));

				Promise.all(promises).then(
					results => PrintService.printLabelsFromPackages(results.flat(), type),
					_noop
				);
			},
			getRootEnv().dashboardSdk.sdk.crossAppTransport
		);
	}

	printPackageLabel(taskId: number, type: PrintPackageType) {
		this.getAllTaskPackages(taskId).then(packages => PrintService.printLabelsFromPackages(packages, type), _noop);
	}
}

export default PackagesStore;
