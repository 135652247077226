import React from 'react';
import { Button, Switch } from '@bringg/react-components';
import styles from './modal-footer.module.scss';
import { useTranslation } from 'react-i18next';
interface Props {
	onOk: () => void;
	okText: string;
	onCancel: () => void;
	onRepeatChange: (repeat: boolean) => void;
	repeat: boolean;
	isDisabled: boolean;
	loader: boolean;
}

const ModalFooter = ({ onOk, onCancel, onRepeatChange, repeat, isDisabled, loader, okText }: Props) => {
	const { t } = useTranslation();
	return (
		<div className={styles.modalFooter}>
			<div className={styles.repeat}>
				<Switch defaultChecked={repeat} onChange={onRepeatChange} data-test-id="exclusion-modal-repeat" />
				{t('PLANNED_DELIVERY_WINDOWS_EXCLUSION_MODAL_FOOTER.REPEAT_ANNUALLY')}
			</div>
			<div>
				<Button onClick={onCancel} type="link" data-test-id="exclusion-modal-cancel-button">
					{t('PLANNED_DELIVERY_WINDOWS_EXCLUSION_MODAL_FOOTER.CANCEL')}
				</Button>
				<Button
					onClick={onOk}
					type="primary"
					disabled={isDisabled}
					loading={loader}
					data-test-id="exclusion-modal-create-save-button"
				>
					{okText}
				</Button>
			</div>
		</div>
	);
};

export default ModalFooter;
