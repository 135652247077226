import type { Task } from '@bringg/types';

export const DEFAULT_DATE_FORMAT = 'MMM DD, YYYY';

export const DEFAULT_PLANNED_TASK_FIELDS: (keyof Task)[] = [
	'created_at',
	'external_id',
	'group_leader_id',
	'id',
	'late',
	'priority',
	'scheduled_at',
	'status',
	'team_ids',
	'title',
	'user_id',
	'way_points'
];
