import { conditionsMapper } from './conditions-mapper';
import { actionsMapper } from './actions-mapper';
import { triggerMapper } from './trigger-mapper';
import TriggerStore from '../../stores/trigger-store';
import RulesRepo from '../../stores/rules-repo';
import ActionsRepo from '../../stores/actions-repo';

export type ContentArrayType = {
	title: string;
	tooltipContent?: string | ContentArrayType[];
	isPale?: boolean;
	isPath?: boolean;
};

export type ContentType = {
	trigger: { full: ContentArrayType[]; partial: ContentArrayType[] };
	conditions: { full: ContentArrayType[]; partial: ContentArrayType[] };
	actions: { full: ContentArrayType[]; partial: ContentArrayType[] };
};

type WorkflowSection = keyof ContentType;

class WorkflowContentGenerator {
	private content: ContentType = {
		trigger: {
			full: [],
			partial: []
		},
		conditions: {
			full: [],
			partial: []
		},
		actions: {
			full: [],
			partial: []
		}
	};

	constructor({ trigger, rules, actions }: { trigger: TriggerStore; rules: RulesRepo; actions: ActionsRepo }) {
		this.content.trigger.full = triggerMapper(trigger, true);
		this.content.trigger.partial = triggerMapper(trigger, false);
		this.content.conditions.full = conditionsMapper(rules, true);
		this.content.conditions.partial = conditionsMapper(rules, false);
		this.content.actions.full = actionsMapper(actions, true);
		this.content.actions.partial = actionsMapper(actions, false);
	}

	mappedSection = (section: WorkflowSection, isFull) => {
		const key = isFull ? 'full' : 'partial';
		return this.content[section][key];
	};

	get fullSentence(): string {
		return [this.content.trigger.full, this.content.conditions.full, this.content.actions.full]
			.flatMap(item => item.map(({ title }) => title?.trim()))
			.join(' ');
	}

	get sections(): string[] {
		return Object.keys(this.content);
	}
}

export default WorkflowContentGenerator;
