import React, { memo } from 'react';
import type { Row } from 'react-table';

import TableRow from './table-row';
import { cellsDiffer } from '../../../utils';

interface TableRowMemoProps<T extends Record<string, unknown> = Record<string, unknown>> {
	row: Row<T>;
	isSelected: boolean;
	isGrouped: boolean;
	rowWidth: number;
	cells: Row<T>['cells'];
	original: Row<T>['original'];
}

const TableRowMemo = <T extends Record<string, unknown> = Record<string, unknown>>({ row }: TableRowMemoProps<T>) => {
	return <TableRow<T> row={row} />;
};

const areEqual = (prevProps: TableRowMemoProps, nextProps: TableRowMemoProps) => {
	if (
		prevProps.isSelected !== nextProps.isSelected ||
		prevProps.cells.length !== nextProps.cells.length ||
		prevProps.original !== nextProps.original ||
		prevProps.rowWidth !== nextProps.rowWidth
	) {
		return false;
	}

	return !cellsDiffer(prevProps.cells, nextProps.cells);
};
export default memo(TableRowMemo, areEqual) as typeof TableRowMemo;
