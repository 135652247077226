import React from 'react';
import { observer } from 'mobx-react';
import map from 'lodash/map';
import has from 'lodash/has';
import NotificationTemplateModel from '../../../stores/notification-templates/domain-objects/notification-template';
import { DISPLAYED_NOTIFICATION_COLLECTION } from '../notification-template/notification-template.consts';
import { Translate } from '../../../translation';
import EnableOptions from '../enable-options/enable-options';
import NotificationTemplate from '../notification-template/notification-template';
import CustomerConfiguration from '../../../stores/customer-configurations/domain-objects/customer-configuration';
import TagCustomerConfiguration from '../../../stores/tag-customer-configurations/tag-customer-configuration';

interface Props {
	notificationTemplates: NotificationTemplateModel[];
	customerConfiguration: CustomerConfiguration | TagCustomerConfiguration;
	dictionary?: any;
	enableFreeSmsMode: boolean;
}

const NotificationTemplateList = ({
	notificationTemplates,
	dictionary,
	customerConfiguration,
	enableFreeSmsMode
}: Props) => {
	return (
		<div className="notification-template-list">
			{map(notificationTemplates, notificationTemplate => {
				const { id, language, notification_type } = notificationTemplate;
				const { title, privateModeField, placeholder, selectOptions, enableOptionType } =
					DISPLAYED_NOTIFICATION_COLLECTION[notification_type];
				const showPrivateMode = has(customerConfiguration, privateModeField);
				return (
					<div
						key={`${language}-${notification_type}`}
						className="notification-template-container"
						data-test-id={notification_type.replace(/_/g, '-')}
					>
						<div className="title">
							<Translate text={title} />
						</div>
						<EnableOptions
							customerConfiguration={customerConfiguration}
							privateModeKey={privateModeField}
							showPrivateMode={showPrivateMode}
							enableModeKey={notification_type}
							enableOptions={selectOptions}
							enableOptionType={enableOptionType}
							enableFreeSmsMode={enableFreeSmsMode}
						/>
						<NotificationTemplate
							dictionary={dictionary}
							notificationTemplate={notificationTemplate}
							key={id}
							placeholder={placeholder}
						/>
					</div>
				);
			})}
		</div>
	);
};

export default observer(NotificationTemplateList);
