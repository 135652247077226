import React from 'react';

import { Moment } from 'moment/moment';
import { EndOfWeekDay } from '@bringg/types';

import { TimeSlots } from 'bringg-web/features/planned-delivery-windows/modals/slots-modal/wizard-steps/configuration-step/time-slots/time-slots';
import Conditions from 'bringg-web/features/planned-delivery-windows/modals/slots-modal/wizard-steps/configuration-step/conditions/conditions';
import {
	CutoffEnum,
	DailyCutoff,
	RelativeCutoff
} from 'bringg-web/features/planned-delivery-windows/modals/slots-modal/wizard-steps/configuration-step/time-slots/cutoff-radio-group/cutoff.types';
import {
	Duration,
	PlannedDeliveryWindowOmitted
} from 'bringg-web/features/planned-delivery-windows/modals/slots-modal/planned-delivery-windows-slots.consts';

interface Props {
	timezone: string;
	use12Hours: boolean;
	format: string;
	updateTimes: (startTime: Moment, endTime: Moment) => void;
	plannedDeliveryWindowEditable: PlannedDeliveryWindowOmitted;
	changeEffectiveDates: (effectiveStartDate: string | null, effectiveEndDate: string | null) => void;
	timeDuration: Duration;
	updateTimeDuration: (duration: Duration) => void;
	cutoffType: CutoffEnum;
	setCutoffType: React.Dispatch<React.SetStateAction<CutoffEnum>>;
	relativeCutoff: RelativeCutoff;
	setRelativeCutoff: React.Dispatch<React.SetStateAction<RelativeCutoff>>;
	dailyCutoff: DailyCutoff;
	setDailyCutoff: React.Dispatch<React.SetStateAction<DailyCutoff>>;
	daysInWeek: EndOfWeekDay[];
	setDaysInWeek: React.Dispatch<React.SetStateAction<EndOfWeekDay[]>>;
	teamId: number;
	updateServicePlanIds: (servicePlanIds: number[]) => void;
	updateServiceAreasIds: (serviceAreasIds: number[]) => void;
	isEditMode: boolean;
}

const ConfigurationStep = ({
	use12Hours,
	format,
	updateTimes,
	plannedDeliveryWindowEditable,
	timeDuration,
	updateTimeDuration,
	timezone,
	daysInWeek,
	setDaysInWeek,
	teamId,
	updateServiceAreasIds,
	updateServicePlanIds,
	cutoffType,
	setCutoffType,
	relativeCutoff,
	setRelativeCutoff,
	dailyCutoff,
	setDailyCutoff,
	isEditMode,
	changeEffectiveDates
}: Props) => {
	const getServicePlanIds = () => {
		if (plannedDeliveryWindowEditable.service_plan_ids.length) {
			return plannedDeliveryWindowEditable.service_plan_ids;
		}
		return [plannedDeliveryWindowEditable.service_plan_id].filter(Boolean);
	};
	return (
		<>
			<TimeSlots
				use12Hours={use12Hours}
				format={format}
				timezone={timezone}
				updateTimes={updateTimes}
				plannedDeliveryWindowEditable={plannedDeliveryWindowEditable}
				changeEffectiveDates={changeEffectiveDates}
				timeDuration={timeDuration}
				updateTimeDuration={updateTimeDuration}
				daysInWeek={daysInWeek}
				setDaysInWeek={setDaysInWeek}
				cutoffType={cutoffType}
				setCutoffType={setCutoffType}
				relativeCutoff={relativeCutoff}
				setRelativeCutoff={setRelativeCutoff}
				dailyCutoff={dailyCutoff}
				setDailyCutoff={setDailyCutoff}
				isEditMode={isEditMode}
			/>
			<Conditions
				teamId={teamId}
				servicePlanIds={getServicePlanIds()}
				serviceAreasIds={plannedDeliveryWindowEditable.service_area_ids}
				updateServiceAreasIds={updateServiceAreasIds}
				updateServicePlanIds={updateServicePlanIds}
			/>
		</>
	);
};

export default ConfigurationStep;
