import type { Hooks } from 'react-table';
import { BaseRecord } from '../types';

import { SELECTION_COLUMN_ID } from '../consts';

// useGroupBy somehow changes columns ordering strategy
// columns present in groupBy always placed in front of others and state.columnsOrder can't change it
// Force selection column to always be at 0 index
export function useSelectionColumnForceFirst<T extends BaseRecord>(hooks: Hooks<T>) {
	hooks.visibleColumns.push(cols => {
		const selectionColumnIndex = cols.findIndex(({ id }) => id === SELECTION_COLUMN_ID);

		if (selectionColumnIndex > 0) {
			const nextCols = cols.slice();
			nextCols.splice(selectionColumnIndex, 1);
			return [cols[selectionColumnIndex]].concat(nextCols);
		}

		return cols;
	});
}
