import moment from 'moment-timezone';
import { dateUtils } from '@bringg-frontend/utils';
import { PlannedDeliveryWindow } from '@bringg/dashboard-sdk';

const getDatesBetweenRange = (
	startTimeInMinutes: number,
	endTimeInMinutes: number,
	rangeWeek: { startDate: string; endDate: string },
	timezone: string
): [Date, Date] => {
	const startOfWeek = moment(rangeWeek.startDate);
	const endOfWeek = moment(rangeWeek.endDate);
	const startDate = dateUtils.addMinutesToLastMonday(startTimeInMinutes, timezone);
	const endDate = dateUtils.addMinutesToLastMonday(endTimeInMinutes, timezone);
	const isStartTimeBetweenWeek = startDate.isBetween(startOfWeek, endOfWeek, undefined, '[]');
	const isEndTimeBetweenWeek = endDate.isBetween(startOfWeek, endOfWeek, undefined, '[]');

	if (!isStartTimeBetweenWeek && !isEndTimeBetweenWeek) {
		const startDateWeekDay = startDate.weekday();
		const dateOfCurrentWeekRange = moment(rangeWeek.startDate).tz(timezone).weekday(startDateWeekDay);
		return [
			startDate
				.set({
					date: dateOfCurrentWeekRange.date(),
					month: dateOfCurrentWeekRange.month(),
					year: dateOfCurrentWeekRange.year()
				})
				.toDate(),
			endDate
				.set({
					date: dateOfCurrentWeekRange.date(),
					month: dateOfCurrentWeekRange.month(),
					year: dateOfCurrentWeekRange.year()
				})
				.toDate()
		];
	}

	return [startDate.toDate(), endDate.toDate()];
};

const getBetween = (
	plannedDeliveryWindows: PlannedDeliveryWindow[],
	range: { startDate: string; endDate: string },
	timezone: string
) => {
	return plannedDeliveryWindows.filter(pdw => {
		let isMatch = true;
		if (!pdw.effective_start_date && !pdw.effective_end_date) {
			return true;
		}

		const [pdwStartDate, pdwEndDate] = getDatesBetweenRange(
			pdw.start_time,
			pdw.end_time,
			{
				...range
			},
			timezone
		);

		if (pdw.effective_end_date) {
			isMatch = moment(pdw.effective_end_date).isSameOrAfter(
				moment.min([moment(pdwEndDate), moment(range.endDate)])
			);
		}

		if (pdw.effective_start_date) {
			isMatch =
				isMatch &&
				moment(pdw.effective_start_date).isSameOrBefore(
					moment.max([moment(pdwStartDate), moment(range.startDate)])
				);
		}

		return isMatch;
	});
};
export const plannedDeliveryWindowsRangeDates = {
	getDatesBetweenRange,
	getBetween
};
