import { Entity, RuleCategory } from '@bringg/types';

import { mapRulesFromServer, mapRulesToServer } from 'bringg-web/features/rule-engine/utils/mapper';

export const defaultConditions = {
	conditions: [],
	enabled: true,
	description: ''
};

export const getConditions = async (rules, sdk, metadata) => {
	const conditionEntities = await sdk.rules.getRules(RuleCategory.FleetRouterRulePolicy);

	const rulesMap = new Map();

	conditionEntities.forEach(entity => {
		rulesMap.set(entity.key.entity_id, entity);
	});

	const rulesWithConditions = await Promise.all(
		(rules || []).map(rule => {
			if (rule.conditionEntity) return rule;

			try {
				const conditionsEntity = rulesMap.get(rule.rule_id) || defaultConditions;

				const mapped = mapRulesFromServer([conditionsEntity], metadata);

				rule.conditionEntity = mapped && mapped.length > 0 ? mapped[0] : defaultConditions;
			} catch (e) {
				rule.conditionEntity = defaultConditions;
			}

			return rule;
		})
	);

	return rulesWithConditions;
};

export const getConditionsPayload = id => ({
	key: {
		entity: Entity.FleetRouterRulePolicy,
		entity_id: id
	},
	category_id: RuleCategory.FleetRouterRulePolicy
});

export const updateConditions = async (updatedRule, sdk) => {
	await sdk.rules.saveRules({
		updated: mapRulesToServer([updatedRule.conditionEntity])
	});
};

const sortRule = rule => (rule.isMerchantLevel && rule.isEnabled ? 1 : 0);

export const sortRules = (a, b) => sortRule(a) - sortRule(b);
