import i18next from 'i18next';

import ActionsRepo from '../../stores/actions-repo';
import { ContentArrayType } from './workflow-content-generator';
import {
	multipleChoice,
	thenNotify,
	via,
	attributes,
	and,
	notify,
	then,
	update,
	from,
	to
} from '../use-automation-translations';
import { MAX_DISPLAY_VALUE } from './utils';
import AlertActionStore from '../../stores/alert-action-store';
import { CalculateAvailabilityActionStore, UpdateEntityActionRepo } from '../../stores/internal';
import { notifyActionOptions } from 'bringg-web/automation-platform-exports';

const getAlertActionMapper = (alert: AlertActionStore, isFull: boolean): ContentArrayType => {
	const allActions = alert.groupedActions.map(actionType => {
		return notifyActionOptions[actionType];
	});

	const title =
		alert.groupedActions.length === 1
			? notifyActionOptions[alert.groupedActions[0]]
			: `${i18next.t(multipleChoice)} (${alert.groupedActions.length})`;

	return {
		title: isFull ? allActions.join(', ') : title,
		isPale: allActions.length > MAX_DISPLAY_VALUE
	};
};

const getUpdateActionMapper = (updateAction: UpdateEntityActionRepo, isFull: boolean) => {
	const { actions } = updateAction;
	let title;

	if (isFull) {
		title = actions.map(updateAct => updateAct.actionData?.display_path).join(` ${i18next.t(and)} `);
	} else {
		title =
			actions.length === 1 ? actions[0].actionData?.display_path : `${actions.length} ${i18next.t(attributes)}`;
	}

	return {
		title
	};
};

const getCalculateAvailabilityAction = (
	action: CalculateAvailabilityActionStore,
	isFull: boolean
): ContentArrayType[] => {
	if (!isFull) {
		return [];
	}
	return [
		{
			title: i18next.t(from),
			isPale: true
		},
		{
			title: `${action.fromDays}`
		},
		{
			title: i18next.t(to),
			isPale: true
		},
		{
			title: `${action.toDays}`
		}
	];
};

export const actionsMapper = (actions: ActionsRepo, isFull: boolean): ContentArrayType[] => {
	const notifyAction = actions.actions.find(act => act instanceof AlertActionStore);
	const acc = [];

	actions.actions
		.filter(act => !(act instanceof AlertActionStore))
		.forEach((action, index) => {
			const prefix = index === 0 ? i18next.t(then) : i18next.t(and);

			if (action instanceof UpdateEntityActionRepo) {
				acc.push(
					{
						title: `${prefix} ${i18next.t(update)}`,
						isPale: true
					},
					getUpdateActionMapper(action, isFull)
				);
			} else if (action instanceof CalculateAvailabilityActionStore) {
				acc.push(
					{
						title: `${prefix}`,
						isPale: true
					},
					{
						title: action.title
					},
					...getCalculateAvailabilityAction(action, isFull)
				);
			} else {
				acc.push(
					{
						title: `${prefix}`,
						isPale: true
					},
					{
						title: action.title
					}
				);
			}
		});

	if (notifyAction) {
		const prefix = acc.length > 0 ? `${i18next.t(and)} ${i18next.t(notify)}` : i18next.t(thenNotify);
		acc.push(
			{
				title: `${prefix} ${i18next.t(via)}`,
				isPale: true
			},
			getAlertActionMapper(notifyAction as AlertActionStore, isFull)
		);
	}

	return acc;
};
