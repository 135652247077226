import React, { useCallback } from 'react';
import Translate from '../../../translation/translator';
import isNil from 'lodash/isNil';
import noop from 'lodash/noop';
import classnames from 'classnames';
import ActionsConfiguration from '../../../stores/actions-configuration/domain-objects/actions-configuration';
import { ActionsConfigurationLevel } from '../../../types/common.consts';
import { Table, Tooltip } from '@bringg/react-components';
import { confirm } from '@bringg/react-components/dist/components/modal/modal';
import { useHasFeatureFlag } from 'bringg-web/utils/feature-flags';

type TablePaginationConfig = React.ComponentProps<typeof Table>['pagination'];

interface Props {
	onEdit: (record: ActionsConfiguration) => void;
	onDelete: (record: ActionsConfiguration) => void;
	actionsConfigurations: ActionsConfiguration[];
}

const pagination: TablePaginationConfig = {
	position: ['bottomRight'],
	pageSize: 12
};

const ActionsConfigurationsTable = ({ actionsConfigurations, onEdit, onDelete }: Props) => {
	const editAllowed = useHasFeatureFlag('enabled_fixed_driver_actions_config');

	const openConfirmationModal = useCallback(
		record => {
			confirm({
				title: <Translate text="ACTION_CONFIGURATION.CONFIRM_DELETE" />,
				onOk() {
					return onDelete(record);
				}
			});
		},
		[onDelete]
	);

	const getTableColumns = () => {
		return [
			{
				title: <Translate text="ACTION_CONFIGURATION.ID" />,
				dataIndex: 'id',
				sorter: (a, b) => (a.id || 0) - (b.id || 0)
			},
			{
				title: <Translate text="ACTION_CONFIGURATION.LEVEL" />,
				dataIndex: '',
				render: (text, record) => <Translate text={record.level} />,
				sorter: (a, b) => a.level.localeCompare(b.level)
			},
			{
				title: <Translate text="ACTION_CONFIGURATION.TITLE" />,
				dataIndex: 'title',
				sorter: (a, b) => {
					if (isNil(a.title)) {
						return 1;
					} else if (isNil(b.title)) {
						return -1;
					}

					return a.title.localeCompare(b.title);
				}
			},
			{
				title: '',
				dataIndex: '',
				render: (text, record) => (
					<span className="row-buttons">
						<Tooltip
							title={
								editAllowed
									? ''
									: 'Sorry, this functionality is temporary disabled. Please contact your CSM for further support.'
							}
						>
							{/* TODO uncomment handlers below together with `temporary-disabled` className above after BRNGG-3270 */}
							<span
								className={classnames('edit-button', { 'temporary-disabled': !editAllowed })}
								onClick={() => (editAllowed ? onEdit(record) : noop())}
							>
								<Translate text="ACTION_CONFIGURATION.EDIT" />
							</span>
							{record.level !== ActionsConfigurationLevel.USER ? (
								<span
									className={classnames('delete-button', { 'temporary-disabled': !editAllowed })}
									onClick={() => (editAllowed ? openConfirmationModal(record) : noop())}
								>
									<Translate text="ACTION_CONFIGURATION.DELETE" />
								</span>
							) : (
								<span className="no-delete-button" />
							)}
						</Tooltip>
					</span>
				)
			}
		];
	};

	const getUniqueKey = useCallback(
		(actionsConfig: ActionsConfiguration) => `${actionsConfig.id}_${actionsConfig.level}`,
		[]
	);

	return (
		<Table
			className="actions-configurations-table"
			dataSource={actionsConfigurations}
			columns={getTableColumns()}
			pagination={pagination}
			rowKey={getUniqueKey}
			size="middle"
		/>
	);
};

export default ActionsConfigurationsTable;
