import { getRootEnv } from '@bringg-frontend/bringg-web-infra';
import { action, computed, makeObservable, observable } from 'mobx';
import type { ActionData, ActionFormData, QuestionData } from '@bringg/types';
import { forEach as _forEach, get, isEmpty as _isEmpty, isNil as _isNil, omit as _omit, pick as _pick } from 'lodash';
import { getEnv } from 'mobx-easy';
import { UserConfigurationUpdateResponse } from '@bringg/dashboard-sdk/dist/UserConfiguration/UserConfiguration.consts';
import i18next from 'i18next';
import { RootEnv } from '@bringg-frontend/bringg-web-infra';

import { ActionValue } from '../../../features/actions-data/action-data.consts';
import {
	ActionsConfiguration as ActionsConfigurationEntity,
	ActionsConfigurationLevel
} from '../../../types/common.consts';
import { FieldType } from 'bringg-web/features/actions-data/field-data/field-data.consts';
import { hasRegexValidation } from 'bringg-web/features/actions-data/field-data/field-data-utils';

// default _pick always return an object
const nullablePick: typeof _pick = (data, keys) => {
	return data ? _pick(data, keys) : null;
};

const normalizeFormData = (data: ActionFormData): ActionFormData => {
	if (!data) {
		return null;
	}

	const commonData = ['label', 'key', 'control'];
	const regexData = ['regex', 'regex_error_message'];

	const normalizedFields = data.fields.map(field => {
		switch (field.control) {
			case FieldType.DisplayInventory:
			case FieldType.Address:
				return _pick(field, commonData);
			case FieldType.Field:
				return _pick(field, [
					...commonData,
					...(hasRegexValidation(field) ? regexData : []),
					'mandatory',
					'type'
				]);
			case FieldType.Dropdown:
				return _omit(field, regexData);
			default:
				return _omit(field, [...regexData, 'list_values']);
		}
	});

	return {
		..._pick(data, ['title', 'display_mode', 'disclaimer', 'allow_share_with_customer']),
		fields: normalizedFields
	};
};

export const normalizeActionsConfiguration = (
	actionDataList: ActionData[],
	level: ActionsConfigurationLevel
): ActionData[] => {
	const normalizedList = [];

	if (Array.isArray(actionDataList)) {
		actionDataList.forEach(actionItemData => {
			// Warning! There was a check for non-empty actionDataItem.data
			// both leaving and removing it can cause some bugs, decided to remove it
			const actionItem =
				level === ActionsConfigurationLevel.SHIFT ? actionItemData : _omit(actionItemData, 'is_mandatory');

			const { data, ...restActionItem } = actionItem;
			let normalizedActionItem;

			switch (restActionItem.value) {
				case ActionValue.AskQuestion:
					normalizedActionItem = {
						...restActionItem,
						data: nullablePick(data, ['question', 'answers'])
					};
					break;
				case ActionValue.Form:
					normalizedActionItem = {
						...restActionItem,
						data: normalizeFormData(data as ActionFormData)
					};
					break;
				case ActionValue.OpenIframe:
					normalizedActionItem = {
						...restActionItem,
						data: nullablePick(data, ['url', 'iframe_fulfillment_required'])
					};
					break;
				case ActionValue.VerifyPinCode:
					normalizedActionItem = {
						..._omit(restActionItem, ['is_top_level']),
						// try to avoid hardcode
						rule: 2
					};
					break;
				case ActionValue.TakePicture:
					// types here https://github.com/bringg/hagmonia-admin/blob/master/JSONSchemaGenerator/Types/ActionTypes.ts
					// shows that there is much more to remove, not only from data object
					normalizedActionItem = {
						...restActionItem,
						data: _pick(data, ['allow_share_with_customer'])
					};
					break;
				case ActionValue.TakeSignature:
					normalizedActionItem = {
						...restActionItem,
						data: nullablePick(data, ['allow_share_with_customer', 'disclaimer'])
					};
					break;
				default:
					normalizedActionItem = restActionItem;
			}

			if (normalizedActionItem) {
				// Payload improvement, check if ActionValue requires data at all, and remove if it doesn't
				// if data is totally not present it will be after switch block as null
				if (!Object.prototype.hasOwnProperty.call(actionItemData, 'data')) {
					delete normalizedActionItem.data;
				}
				normalizedList.push(normalizedActionItem);
			}
		});
	}

	return normalizedList;
};

const getUpdaterWithSideEffects = (actionData: ActionData, updatedFields: Partial<ActionData>): Partial<ActionData> => {
	// Be aware some related logic at the moment handled in proxy-state in action-form-data.tsx:fieldUpdate
	// which doesn't seem a good approach
	const updater = Object.assign({}, updatedFields);

	if (updater.value === ActionValue.AskQuestion) {
		if (!actionData.data) {
			updater.data = { answers: [] };
		} else if (!Array.isArray((actionData.data as QuestionData).answers)) {
			updater.data = Object.assign({}, actionData.data, { answers: [] });
		}
	}

	return updater;
};

export default class ActionsConfiguration {
	id: number = null;
	next_action_id = 1;
	level: ActionsConfigurationLevel = ActionsConfigurationLevel.USER;
	title = '';
	action_data: ActionData[] = [];

	constructor(actionsConfiguration: ActionsConfigurationEntity) {
		makeObservable(this, {
			level: observable,
			title: observable,
			action_data: observable,
			setTitle: action,
			setActionData: action,
			setLevel: action,
			addNewActionData: action,
			editActionData: action,
			deleteActionData: action,
			validation: computed,
			cleanUpRemovedGoToId: action
		});

		Object.assign(this, actionsConfiguration);

		if (!Array.isArray(this.action_data)) {
			this.action_data = [];
		}

		if (!_isEmpty(actionsConfiguration.action_data)) {
			const dataIds = this.action_data.map(data => {
				return data.id || 1;
			});

			this.next_action_id = Math.max.apply(Math, [...dataIds]);
		}

		this.fixExistingData();
	}

	fixExistingData = () => {
		_forEach(this.action_data, actionData => {
			if (_isNil(actionData.id)) {
				this.next_action_id += 1;
				// eslint-disable-next-line no-param-reassign
				actionData.id = this.next_action_id;
			}
		});
	};

	setTitle = (title: string) => {
		this.title = title;
	};

	setActionData = (actionData: ActionData[]) => {
		this.action_data = actionData;
	};

	setLevel = (level: ActionsConfigurationLevel) => {
		this.level = level;
		// set default label for All Orders configurations
		if (level === ActionsConfigurationLevel.USER) {
			this.setTitle(i18next.t('ACTION_CONFIGURATION.DEFAULT_USER_TITLE'));
		}
	};

	addNewActionData = () => {
		this.next_action_id += 1;
		const newActionData: ActionData = {
			id: this.next_action_id,
			title: '',
			is_top_level: false,
			is_mandatory: false,
			way_point_type: 0,
			rule: 0,
			value: 'ask_question',
			data: {
				question: '',
				answers: [],
				title: '',
				fields: []
			}
		};

		this.action_data.push(newActionData);
	};

	editActionData = (id: number, updatedFields: Partial<ActionData>) => {
		const actionData: ActionData = this.action_data.find(data => data.id === id);

		if (actionData) {
			Object.assign(actionData, getUpdaterWithSideEffects(actionData, updatedFields));
		}
	};

	cleanUpRemovedGoToId = (id: number) => {
		this.action_data.forEach(({ value, data }) => {
			if (value === ActionValue.AskQuestion) {
				const typedData = data as QuestionData;
				if (typedData.answers?.length) {
					typedData.answers.forEach(answer => {
						if (answer.go_to_id === id) {
							delete answer.go_to_id;
						}
					});
				}
			}
		});
	};

	deleteActionData = (id: number) => {
		const index: number = this.action_data.findIndex(data => data.id === id);

		if (index >= 0) {
			this.action_data.splice(index, 1);
			this.cleanUpRemovedGoToId(id);
		}
	};

	get validation(): { isValid: boolean; message?: string } {
		const isValidString = value => Boolean(typeof value === 'string' && value.trim());

		// TODO: make all messages translatable
		if (!isValidString(this.title)) {
			return { isValid: false, message: 'Configuration must have a Title' };
		}

		if (!(Array.isArray(this.action_data) && this.action_data.length)) {
			return { isValid: false, message: 'Configuration must have at least one Action' };
		}

		for (const actionData of this.action_data) {
			const { value: actionValue, title } = actionData;

			if (actionValue === ActionValue.AskQuestion) {
				if (!isValidString(title)) {
					return { isValid: false, message: 'Question should have a Title text' };
				}
			}

			if (!_isEmpty(actionData.data)) {
				switch (actionValue) {
					case ActionValue.OpenIframe:
						if (!isValidString(get(actionData.data, 'url'))) {
							return { isValid: false, message: 'Open Iframe action must have URL' };
						}
						break;
					case ActionValue.AskQuestion:
						if (!isValidString((actionData.data as QuestionData).question)) {
							return { isValid: false, message: 'Question must have Question text' };
						} else if (!(actionData.data as QuestionData).answers?.length) {
							return { isValid: false, message: 'Question must have some Answers' };
						} else if ((actionData.data as QuestionData).answers.some(({ text }) => !isValidString(text))) {
							return { isValid: false, message: 'Each Question Answer must have a text' };
						}
						break;
					case ActionValue.Form:
						if (!(actionData.data as ActionFormData).fields?.length) {
							return { isValid: false, message: 'Form action must have some fields' };
						} else if ((actionData.data as ActionFormData).fields?.filter(item => !item.key).length) {
							return { isValid: false, message: 'Each Form Field must have a key' };
						}
						break;
					default:
				}
			}
		}

		return { isValid: true, message: '' };
	}

	async delete(): Promise<any> {
		return getRootEnv().dashboardSdk.sdk.userConfigurations.update({ action_data: [] });
	}

	async update(): Promise<UserConfigurationUpdateResponse> {
		return getRootEnv().dashboardSdk.sdk.userConfigurations.update({
			action_data: normalizeActionsConfiguration(this.action_data, this.level)
		});
	}
}
