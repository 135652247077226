import React, { StatelessComponent } from 'react';
import moment from 'moment';
import { Translate } from '../../../translation';
import { Run } from '@bringg/types';

interface RunDetailsProps {
	run: Run;
	currency?: string;
	runCashout?: number;
}

const RunDetails: StatelessComponent<RunDetailsProps> = (props: RunDetailsProps) => {
	const { run, runCashout, currency } = props;
	const runStartedAt = run.started_at
		? Math.floor(moment.duration(moment(run.ended_at).diff(moment(run.started_at))).asHours())
		: false;
	const runEndedAt = run.ended_at
		? Math.floor(moment.duration(moment(run.ended_at).diff(moment(run.started_at))).asMinutes() % 60)
		: false;

	return (
		<div className="run-details">
			<div className="run-orders">
				<span>
					<Translate text={'RUN.ORDERS'} />:{' '}
				</span>
				<span>{run.tasks?.length ?? 0}</span>
			</div>
			{run.distance_traveled && (
				<div className="run-distance">
					<span>
						<Translate text={'RUN.DISTANCE'} />
					</span>
					<span>
						{run.distance_traveled} <Translate text={'RUN.KM'} />
					</span>
				</div>
			)}
			{run.started_at && run.ended_at && (
				<div className="run-runtime">
					<span>
						<Translate text={'RUN.RUN_TIME'} />:{' '}
					</span>
					<span>
						{`${runStartedAt}h `}
						{`${runEndedAt}m`}
					</span>
				</div>
			)}
			{runCashout && (
				<div className="run-cashout">
					<span>
						<Translate text={'RUN.CASHOUT'} />:{' '}
					</span>
					<span>{`${currency} ${runCashout}`}</span>
				</div>
			)}
		</div>
	);
};

RunDetails.defaultProps = {
	currency: '$',
	runCashout: null
};

export default RunDetails;
