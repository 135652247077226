import { Button } from '@bringg/react-components';
import { BringgFontIcons, BringgIcon } from '@bringg/bringg-icons';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './delivery-block-template-header.module.scss';

interface Props {
	goBack: () => void;
}

const DeliveryBlockTemplateHeader = ({ goBack }: Props) => {
	const { t } = useTranslation();

	return (
		<header className={styles.deliveryBlockTemplateHeader}>
			<div className="header-top">
				<Button
					onClick={goBack}
					type="link"
					size="large"
					icon={<BringgIcon iconName={BringgFontIcons.ArrowLeft} />}
					className="workflow-back-button"
					data-test-id="back-button"
				>
					{t('DELIVERY_BLOCKS_TEMPLATE.BACK_TO_SCHEDULES')}
				</Button>
				<div className="editor-title">
					<h3>{t('DELIVERY_BLOCKS_TEMPLATE.HEADER')}</h3>
				</div>
			</div>
		</header>
	);
};

export default DeliveryBlockTemplateHeader;
