import { action, computed, makeObservable, observable } from 'mobx';
import i18next from 'i18next';
import { ActionCoreData, ActionType, EmailSharedLocationData, EmailSharedLocationActionMetadata } from '@bringg/types';

import { ActionsRepo } from './internal';
import ActionBaseStore from './action-base-store';
import { ActionFamilyType, ClientEmailSharedLocationAction } from '../utils/types';

class EmailCustomerNotificationActionStore extends ActionBaseStore {
	type = ActionFamilyType.EMAIL_COSTUMER_NOTIFICATION;
	actionType: ActionType;

	template_id: EmailSharedLocationData['template_id'];

	constructor(actionToInit: ClientEmailSharedLocationAction, actionsRepo: ActionsRepo) {
		super(actionsRepo);
		makeObservable(this, {
			template_id: observable,
			setTemplateId: action,
			title: computed,
			isValid: computed,
			isTemplateIdValid: computed
		});

		this.template_id = actionToInit.template_id;
		this.actionType = ActionType.EMAIL_SHARED_LOCATION;
	}

	get mappedToServer(): ActionCoreData {
		return {
			action_type: ActionType.EMAIL_SHARED_LOCATION,
			data: {
				template_id: this.template_id
			}
		};
	}

	get isTemplateIdValid() {
		return typeof this.template_id === 'number' && this.template_id > 0;
	}

	get isValid(): boolean {
		return this.isTemplateIdValid;
	}

	setTemplateId(template_id: EmailSharedLocationData['template_id']) {
		this.template_id = template_id;
	}

	get title(): string {
		const titleKey = (this.actionData as EmailSharedLocationActionMetadata)?.translation_string;
		return titleKey ? i18next.t(titleKey) : '';
	}
}

export default EmailCustomerNotificationActionStore;
