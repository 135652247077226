import { useEffect, useState } from 'react';

import { observer } from 'mobx-react';
import { Space } from '@bringg/react-components';

import { useStores } from 'bringg-web/recipes';
import Page from './components/page/page';
import FiltersPanel from './components/filters-panel/filters-panel';
import { useHistoryTranslation } from './translations';
import HistoryTable from './components/history-table/history-table';

const HistoryPage = observer(() => {
	const { teamsStore, tagsStore, fleetsStore, servicePlansStore } = useStores();
	const translations = useHistoryTranslation();

	const [filters, setFilters] = useState({});

	useEffect(() => {
		const init = async () =>
			Promise.all([
				teamsStore.fetchAll(),
				tagsStore.fetchAll(),
				fleetsStore.fetchAll(),
				servicePlansStore.fetchAll()
			]);

		init();
	}, []);

	return (
		<Page title={translations.pageTitle}>
			<Space direction="vertical" style={{ display: 'flex' }}>
				<FiltersPanel onFilter={setFilters} />
				<HistoryTable filters={filters} />
			</Space>
		</Page>
	);
});

export default HistoryPage;
