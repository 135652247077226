import { osFinder, OSName } from '@bringg/frontend-utils';

const isMultiSelect = (event): boolean => {
	const osName: OSName = osFinder.find();
	const isClickWithMetaKey = osName === OSName.MacOS && event.metaKey;
	const isClickWithCtrlKey = [OSName.Windows, OSName.Linux].includes(osName) && event.ctrlKey;

	return Boolean(isClickWithMetaKey || isClickWithCtrlKey);
};
export const selectionService = { isMultiSelect };
