import { head } from 'lodash';
import type { FilterTypes } from 'react-table';
import { CustomAttributeResponse } from '@bringg/types';

export const columnIds = {
	ATTRIBUTE_MARKER: 'attribute_marker',
	ATTRIBUTE_NUMBER: 'attrNumber',
	NAME: 'name',
	PATH: 'path',
	ENTITY_TYPE: 'entity_type',
	DATA_TYPE: 'data_type',
	SUGGESTION: 'suggestion',
	ACTION: 'action',
	REQUIRED: 'required',
	DRIVER_APP: 'show_in_mobile_app'
};

export enum AttributeStatusEnum {
	ALL,
	ACTIVE,
	SUGGESTED
}

export enum AttributeMandatoryEnum {
	ALL,
	MANDATORY,
	NOT_MANDATORY
}

export enum AttributeDriverAppEnum {
	ALL,
	DRIVER_APP_ENABLED,
	DRIVER_APP_DISABLED
}

export const filterTypes: FilterTypes<CustomAttributeResponse> = {
	byNameAndPath: (rows, filterIds, value) => {
		return rows.filter(row => {
			return row.original[columnIds.NAME]?.includes(value) || row.original[columnIds.PATH]?.includes(value);
		});
	},
	arrayIncludes: (rows, filterIds, values) => {
		const key = head(filterIds);
		// Be aware can behave unpredictable if !Array.isArray(values)
		const valuesSet = new Set(values);

		return rows.filter(row => {
			return valuesSet.has(row.original[key]);
		});
	},
	boolean: (rows, filterIds, value: boolean) => {
		const key = head(filterIds);
		return rows.filter(row => Boolean(row.original[key]) === value);
	}
};

export const DEFAULT_COLUMN_WIDTH = 150;
