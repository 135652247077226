import {
	ArrayType,
	MathOperator,
	NumericOperator,
	RuleCompareOperator,
	StringOperator,
	TimeTriggerUnitOfTime
} from '@bringg/types';
import { useTranslation } from 'react-i18next';
import { OptionWrapper } from '@bringg/react-components/dist/components/select/select';

import {
	getArrayOperatorTranslation,
	getNumericOperatorTranslation,
	getStringOperatorTranslations
} from './content-mapper/utils';
import { is, isNot } from './use-automation-translations';

export const operatorsWithNoValue = [
	RuleCompareOperator.EMPTY,
	RuleCompareOperator.NOT_EMPTY,
	RuleCompareOperator.ARRAY_ELEMENT_EMPTY,
	RuleCompareOperator.ARRAY_ELEMENT_NOT_EMPTY
];

export const useOperators = (): OptionWrapper[] => {
	const { t } = useTranslation();
	return [
		{
			id: NumericOperator.GREATER_THAN,
			name: getNumericOperatorTranslation(NumericOperator.GREATER_THAN),
			dropDownKey: 'GREATER_THAN'
		},
		{
			id: NumericOperator.LESS_THAN,
			name: getNumericOperatorTranslation(NumericOperator.LESS_THAN),
			dropDownKey: 'LESS_THAN'
		},
		{
			id: NumericOperator.EQUALS,
			name: getNumericOperatorTranslation(NumericOperator.EQUALS),
			dropDownKey: 'EQUALS'
		},
		{
			id: NumericOperator.NOT_EQUALS,
			name: t('CONDITIONS.NUMERIC.NOT_EQUALS'),
			dropDownKey: 'NOT_EQUALS'
		},
		{
			id: NumericOperator.GREATER_THAN_OR_EQUALS,
			name: getNumericOperatorTranslation(NumericOperator.GREATER_THAN_OR_EQUALS),
			dropDownKey: 'GREATER_THAN_OR_EQUALS'
		},
		{
			id: NumericOperator.LESS_THAN_OR_EQUALS,
			name: getNumericOperatorTranslation(NumericOperator.LESS_THAN_OR_EQUALS),
			dropDownKey: 'LESS_THAN_OR_EQUALS'
		}
	];
};

export const useNumericOperators = (): OptionWrapper[] => {
	const operators = useOperators();
	operators.push(
		{
			id: RuleCompareOperator.IN_RANGE,
			name: getNumericOperatorTranslation(RuleCompareOperator.IN_RANGE),
			dropDownKey: RuleCompareOperator.IN_RANGE
		},
		{
			id: RuleCompareOperator.EMPTY,
			name: getNumericOperatorTranslation(RuleCompareOperator.EMPTY),
			dropDownKey: RuleCompareOperator.EMPTY
		},
		{
			id: RuleCompareOperator.NOT_EMPTY,
			name: getNumericOperatorTranslation(RuleCompareOperator.NOT_EMPTY),
			dropDownKey: RuleCompareOperator.NOT_EMPTY
		}
	);
	return operators;
};

export const useCompareAttributesOperators = (): OptionWrapper[] => {
	return [
		{
			id: NumericOperator.GREATER_THAN,
			name: getNumericOperatorTranslation(NumericOperator.GREATER_THAN),
			dropDownKey: 'GREATER_THAN'
		},
		{
			id: NumericOperator.LESS_THAN,
			name: getNumericOperatorTranslation(NumericOperator.LESS_THAN),
			dropDownKey: 'LESS_THAN'
		}
	];
};
export const useDatetimeAttributesOperators = (): OptionWrapper[] => {
	return [
		{
			id: RuleCompareOperator.EMPTY,
			name: getNumericOperatorTranslation(RuleCompareOperator.EMPTY),
			dropDownKey: RuleCompareOperator.EMPTY
		},
		{
			id: RuleCompareOperator.NOT_EMPTY,
			name: getNumericOperatorTranslation(RuleCompareOperator.NOT_EMPTY),
			dropDownKey: RuleCompareOperator.NOT_EMPTY
		}
	];
};

export const useArrayOperators = (): OptionWrapper[] => {
	return [
		{
			id: RuleCompareOperator.ARRAY_INTERSECTION_HAS_ONE,
			name: getArrayOperatorTranslation(RuleCompareOperator.ARRAY_INTERSECTION_HAS_ONE),
			dropDownKey: 'ARRAY_INTERSECTION_HAS_ONE'
		},
		{
			id: RuleCompareOperator.ARRAY_INTERSECTION_HAS_ALL,
			name: getArrayOperatorTranslation(RuleCompareOperator.ARRAY_INTERSECTION_HAS_ALL),
			dropDownKey: 'ARRAY_INTERSECTION_HAS_ALL'
		}
	];
};

export const useNestedArrayOperators = (): OptionWrapper[] => {
	return [
		{
			id: ArrayType.ANY,
			name: getArrayOperatorTranslation(RuleCompareOperator.ARRAY_INTERSECTION_HAS_ONE),
			dropDownKey: 'any'
		},
		{
			id: ArrayType.ALL,
			name: getArrayOperatorTranslation(RuleCompareOperator.ARRAY_INTERSECTION_HAS_ALL),
			dropDownKey: 'all'
		}
	];
};

export const baseStringOperators = [
	StringOperator.EQUALS,
	StringOperator.NOT_EQUALS,
	StringOperator.INCLUDE,
	StringOperator.EXCLUDE
];

export const useStringBaseOperations = (): OptionWrapper[] => {
	return baseStringOperators.map(operator => ({
		id: operator,
		name: getStringOperatorTranslations(operator),
		dropDownKey: operator
	}));
};

export const useStringOperations = (): OptionWrapper[] => {
	return [
		...useStringBaseOperations(),
		{
			id: RuleCompareOperator.EMPTY,
			name: getStringOperatorTranslations(RuleCompareOperator.EMPTY),
			dropDownKey: RuleCompareOperator.EMPTY
		},
		{
			id: RuleCompareOperator.NOT_EMPTY,
			name: getStringOperatorTranslations(RuleCompareOperator.NOT_EMPTY),
			dropDownKey: RuleCompareOperator.NOT_EMPTY
		},
		{
			id: RuleCompareOperator.IN,
			name: getStringOperatorTranslations(RuleCompareOperator.IN),
			dropDownKey: RuleCompareOperator.IN
		},
		{
			id: RuleCompareOperator.NOT_IN,
			name: getStringOperatorTranslations(RuleCompareOperator.NOT_IN),
			dropDownKey: RuleCompareOperator.NOT_IN
		}
	];
};

export const useStringArrayOperations = (): OptionWrapper[] => {
	return [
		{
			id: RuleCompareOperator.ARRAY_ELEMENT_IN,
			name: getStringOperatorTranslations(RuleCompareOperator.ARRAY_ELEMENT_IN),
			dropDownKey: 'EQUALS'
		},
		{
			id: RuleCompareOperator.ARRAY_ELEMENT_NOT_IN,
			name: getStringOperatorTranslations(RuleCompareOperator.ARRAY_ELEMENT_NOT_IN),
			dropDownKey: 'NOT_EQUALS'
		},
		{
			id: RuleCompareOperator.ARRAY_ELEMENT_INCLUDE_ONE_OF_THE_ITEMS_FROM_SUBSTRING_ARRAY,
			name: getStringOperatorTranslations(
				RuleCompareOperator.ARRAY_ELEMENT_INCLUDE_ONE_OF_THE_ITEMS_FROM_SUBSTRING_ARRAY
			),
			dropDownKey: 'CONTAINS'
		},
		{
			id: RuleCompareOperator.ARRAY_ELEMENT_EXCLUDE_ALL_ITEMS_FROM_SUBSTRING_ARRAY,
			name: getStringOperatorTranslations(
				RuleCompareOperator.ARRAY_ELEMENT_EXCLUDE_ALL_ITEMS_FROM_SUBSTRING_ARRAY
			),
			dropDownKey: 'NOT_CONTAINS'
		},
		{
			id: RuleCompareOperator.ARRAY_ELEMENT_EMPTY,
			name: getStringOperatorTranslations(RuleCompareOperator.ARRAY_ELEMENT_EMPTY),
			dropDownKey: RuleCompareOperator.ARRAY_ELEMENT_EMPTY
		},
		{
			id: RuleCompareOperator.ARRAY_ELEMENT_NOT_EMPTY,
			name: getStringOperatorTranslations(RuleCompareOperator.ARRAY_ELEMENT_NOT_EMPTY),
			dropDownKey: RuleCompareOperator.ARRAY_ELEMENT_NOT_EMPTY
		}
	];
};

export const useNumberArrayOperators = (): OptionWrapper[] => {
	return [
		{
			id: RuleCompareOperator.ARRAY_ELEMENT_EQUALS,
			name: getNumericOperatorTranslation(RuleCompareOperator.ARRAY_ELEMENT_EQUALS),
			dropDownKey: 'ARRAY_ELEMENT_EQUALS'
		},
		{
			id: RuleCompareOperator.ARRAY_ELEMENT_NOT_EQUALS,
			name: getNumericOperatorTranslation(RuleCompareOperator.ARRAY_ELEMENT_NOT_EQUALS),
			dropDownKey: 'ARRAY_ELEMENT_NOT_EQUALS'
		},
		{
			id: RuleCompareOperator.ARRAY_ELEMENT_LESS_THAN,
			name: getNumericOperatorTranslation(RuleCompareOperator.ARRAY_ELEMENT_LESS_THAN),
			dropDownKey: 'ARRAY_ELEMENT_LESS_THAN'
		},
		{
			id: RuleCompareOperator.ARRAY_ELEMENT_LESS_THAN_OR_EQUALS,
			name: getNumericOperatorTranslation(RuleCompareOperator.ARRAY_ELEMENT_LESS_THAN_OR_EQUALS),
			dropDownKey: 'ARRAY_ELEMENT_LESS_THAN_OR_EQUALS'
		},
		{
			id: RuleCompareOperator.ARRAY_ELEMENT_GREATER_THAN,
			name: getNumericOperatorTranslation(RuleCompareOperator.ARRAY_ELEMENT_GREATER_THAN),
			dropDownKey: 'ARRAY_ELEMENT_GREATER_THAN'
		},
		{
			id: RuleCompareOperator.ARRAY_ELEMENT_GREATER_THAN_OR_EQUALS,
			name: getNumericOperatorTranslation(RuleCompareOperator.ARRAY_ELEMENT_GREATER_THAN_OR_EQUALS),
			dropDownKey: 'ARRAY_ELEMENT_GREATER_THAN_OR_EQUALS'
		},
		{
			id: RuleCompareOperator.ARRAY_ELEMENT_IN_RANGE,
			name: getNumericOperatorTranslation(RuleCompareOperator.ARRAY_ELEMENT_IN_RANGE),
			dropDownKey: 'ARRAY_ELEMENT_IN_RANGE'
		},
		{
			id: RuleCompareOperator.ARRAY_ELEMENT_EMPTY,
			name: getNumericOperatorTranslation(RuleCompareOperator.ARRAY_ELEMENT_EMPTY),
			dropDownKey: RuleCompareOperator.ARRAY_ELEMENT_EMPTY
		},
		{
			id: RuleCompareOperator.ARRAY_ELEMENT_NOT_EMPTY,
			name: getNumericOperatorTranslation(RuleCompareOperator.ARRAY_ELEMENT_NOT_EMPTY),
			dropDownKey: RuleCompareOperator.ARRAY_ELEMENT_NOT_EMPTY
		}
	];
};

export const useUnitsOptions = (): OptionWrapper[] => {
	const { t } = useTranslation();
	return [
		{ id: TimeTriggerUnitOfTime.MINUTES, name: t('PROGRAMMABLE_TRIGGERS.MINUTES'), dropDownKey: 'minutes' },
		{ id: TimeTriggerUnitOfTime.HOURS, name: t('PROGRAMMABLE_TRIGGERS.HOURS'), dropDownKey: 'hours' },
		{ id: TimeTriggerUnitOfTime.DAYS, name: t('PROGRAMMABLE_TRIGGERS.DAYS'), dropDownKey: 'days' }
	];
};

export const useBooleanOptions = (): OptionWrapper[] => {
	const { t } = useTranslation();
	return [
		{ id: 1, name: t('PROGRAMMABLE_TRIGGERS.TRUE'), dropDownKey: 'true' },
		{ id: 0, name: t('PROGRAMMABLE_TRIGGERS.FALSE'), dropDownKey: 'false' }
	];
};

export const useIsIsntOptions = (): OptionWrapper[] => {
	const { t } = useTranslation();
	return [
		{ id: 1, name: t(is), dropDownKey: 'true' },
		{ id: 0, name: t(isNot), dropDownKey: 'false' }
	];
};

export const useRelativeSelectOptions = (): OptionWrapper[] => {
	const { t } = useTranslation();
	return [
		{
			id: -1,
			name: t('PROGRAMMABLE_TRIGGERS.BEFORE'),
			dropDownKey: 'before'
		},
		{
			id: 1,
			name: t('PROGRAMMABLE_TRIGGERS.AFTER'),
			dropDownKey: 'after'
		}
	];
};

export const useRelativeOperators = (): OptionWrapper[] => {
	const { t } = useTranslation();
	return [
		{
			id: MathOperator.ADDITION,
			name: t('AUTOMATION.ACTIONS.RELATIVE.ADD'),
			dropDownKey: 'add'
		},
		{
			id: MathOperator.SUBTRACTION,
			name: t('AUTOMATION.ACTIONS.RELATIVE.SUBTRACT'),
			dropDownKey: 'subtract'
		}
	];
};
