import { getRootEnv } from '@bringg-frontend/bringg-web-infra';
import { action, computed, observable, makeObservable } from 'mobx';
import { ApplicationTeamConfigurations, ApplicationUuid, Team as BringgTeam } from '@bringg/types';
import { getRoot } from 'mobx-easy';
import { sortByString } from '@bringg-frontend/utils';

import RootStore from '../root-store';
import Team from './domain-object/team';
import { ShiftConfig } from './types';
import { deliveryBlocksRootStore } from 'bringg-web/features/delivery-blocks-v2/stores/delivery-blocks-root-store';

function childrenTeamIdsPlugin(allTeams: BringgTeam[]): (BringgTeam & { childrenTeamIds: number[] })[] {
	const childrenTeamIds: { [key: number]: number[] } = {};

	allTeams.forEach(({ id, parent_team_id }) => {
		if (parent_team_id) {
			if (!childrenTeamIds[parent_team_id]) {
				childrenTeamIds[parent_team_id] = [];
			}
			childrenTeamIds[parent_team_id].push(id);
		}
	});

	return allTeams.map(team => ({
		...team,
		childrenTeamIds: childrenTeamIds[team.id] || []
	}));
}

class TeamsStore {
	teams: Map<number, Team> = new Map();
	routeOptimizationApplicationATCs: Map<number, ApplicationTeamConfigurations> = new Map<
		number,
		ApplicationTeamConfigurations
	>();
	isFetched = false;
	isOptimizationConfigurationFetched = false;

	rootStore = null;

	constructor() {
		makeObservable(this, {
			teams: observable.shallow,
			routeOptimizationApplicationATCs: observable.shallow,
			isFetched: observable,
			isOptimizationConfigurationFetched: observable,
			set: action,
			setAll: action,
			setIsFetched: action,
			deliveryBlocks: computed,
			all: computed
		});
	}

	set = (team: Team) => {
		this.teams.set(team.id, team);
	};

	setAll = (teams: Bringg.Team[]) => {
		teams.forEach(team => {
			this.teams.set(team.id, new Team(this, team));
		});
	};

	setIsFetched = (isFetched: boolean) => {
		this.isFetched = isFetched;
	};

	get deliveryBlocks() {
		return deliveryBlocksRootStore.getStore().deliveryBlocksStore.all;
	}

	deliveryBlocksByTeam = (teamId: number) => {
		return deliveryBlocksRootStore.getStore().deliveryBlocksStore.allByTeam(teamId);
	};

	get all() {
		return Array.from(this.teams.values()).sort((firstItem, secondItem) =>
			sortByString(firstItem, secondItem, 'name')
		);
	}

	get = (id: number) => this.teams.get(id);

	getRouteOptimizationApplicationATCs = (teamId: number): ApplicationTeamConfigurations =>
		this.routeOptimizationApplicationATCs.get(teamId);

	fetchAll = async () => {
		if (this.isFetched) {
			return this.all;
		}

		const teams = childrenTeamIdsPlugin(await getRootEnv().dashboardSdk.sdk.teams.getAll());

		this.setAll(teams);
		this.setIsFetched(true);
		return this.all;
	};

	fetchRouteOptimizationApplicationATCs = async () => {
		if (!getRoot<RootStore>().data.applicationStore.isApplicationInstalled(ApplicationUuid.RouteOptimizer2)) {
			return null;
		}
		if (this.isOptimizationConfigurationFetched) {
			return this.routeOptimizationApplicationATCs;
		}

		const { dashboardSdk } = getRootEnv();
		const configurations = await dashboardSdk.sdk.applicationTeamConfiguration.getConfigurations(
			ApplicationUuid.RouteOptimizer2
		);

		configurations.forEach(configuration => {
			this.routeOptimizationApplicationATCs.set(configuration.team_id, configuration);
		});

		return configurations;
	};

	fetchById = async (teamId: number) => {
		if (this.teams.has(teamId)) {
			return this.get(teamId);
		}

		const team = await getRootEnv().dashboardSdk.sdk.teams.get(teamId);
		this.set(new Team(this, team));
		return team;
	};

	loadById = async (teamId: number) => {
		const team = await getRootEnv().dashboardSdk.sdk.teams.forceGet(teamId);
		this.set(new Team(this, team));
		return team;
	};

	getShifts = async (teamId: number) => {
		return getRootEnv().dashboardSdk.sdk.shifts.getShifts(teamId);
	};

	updateShifts = async (teamId: number, configuration: ShiftConfig[]) => {
		return getRootEnv().dashboardSdk.sdk.shifts.updateShifts(teamId, configuration);
	};

	getBreaks = async (teamId: number) => {
		return getRootEnv().dashboardSdk.sdk.shifts.getBreaks(teamId);
	};

	updateBreaks = async (teamId: number, configuration: ShiftConfig[]) => {
		return getRootEnv().dashboardSdk.sdk.shifts.updateBreaks(teamId, configuration);
	};

	getServiceAreas = async (teamId: number, neighbours: boolean) => {
		return getRoot<RootStore>().data.serviceArea.loadAllByTeams({ teams_ids: [teamId], neighbours });
	};

	assignServiceArea = async (id: number, userId: number) => {
		return getRootEnv().dashboardSdk.sdk.serviceAreas.assign(id, { user_id: userId });
	};

	unassignServiceArea = async (userId: number) => {
		return getRootEnv().dashboardSdk.sdk.serviceAreas.unassign({ user_id: userId });
	};
}

export default TeamsStore;
