import React from 'react';

export const Eco = () => {
	return (
		<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<g id="Component 25">
				<g id="Group 8072">
					<g id="Group 8005">
						<path
							id="Path 33080"
							d="M14.0326 17.7548C14.0326 17.7548 8.39857 19.7898 7.49972 12.1603C7.49972 12.1603 13.1697 12.279 14.4209 17.0105C13.2092 17.2083 10.0812 14.7023 9.80797 14.314C9.84752 14.2564 10.3545 16.1332 14.0326 17.7548Z"
							fill="#00983A"
						/>
						<g id="Group 7994">
							<path
								id="Path 33081"
								d="M10.0804 12.0259C9.96177 10.5374 10.5119 8.54196 11.9177 6C11.9177 6 17.1598 9.23588 16.5917 13.5863C16.4267 14.6291 16.0109 15.6162 15.3801 16.4627C13.9707 15.1324 12.4067 10.674 12.3276 8.87634C12.1191 10.4541 12.2665 12.0586 12.759 13.572C11.9535 12.9182 11.0494 12.3964 10.0804 12.0259V12.0259Z"
								fill="#00983A"
							/>
						</g>
					</g>
					<circle id="Ellipse 1313" cx="12" cy="12" r="11" stroke="#00983A" strokeWidth="2" />
				</g>
			</g>
		</svg>
	);
};
