import React, { useCallback, useEffect, useState } from 'react';

import { useObserver } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { NotificationTypes, RatingReason } from '@bringg/types';
import Spinner from '@bringg/react-components/dist/components/spinner/spinner';
import Button from '@bringg/react-components/dist/components/button/button';
import Col from '@bringg/react-components/dist/components/col/col';
import { withErrorBoundary } from '@bringg-frontend/bringg-web-infra';
import Row from '@bringg/react-components/dist/components/row/row';
import notification from '@bringg/react-components/dist/components/notification/notification';

import NotificationTemplate from 'bringg-web/stores/notification-templates/domain-objects/notification-template';
import { useStores } from 'bringg-web/recipes';
import Translate from 'bringg-web/translation/translator';
import RatingOptions from '../rating-options/rating-options';
import RatingScreen from '../rating-screen/rating-screen';
import PostRating from '../post-rating/post-rating';
import CustomerRatingHeader from '../customer-rating-header/customer-rating-header';

const notificationTypeToField = {
	[NotificationTypes.RatingScreenTitle]: 'ratingScreenTitle',
	[NotificationTypes.PostRatingPositiveTitle]: 'positiveTitle',
	[NotificationTypes.PostRatingNegativeTitle]: 'negativeTittle'
};

interface CustomerRatingData {
	allowRating: boolean;
	showPostRating: boolean;
	allowFreeTextComment: boolean;
	numberOfStars: number;
	ratingScreenTitle: string;
	positiveTitle: string;
	negativeTittle: string;
	ratingOptions: Partial<RatingReason>[];
}

const CustomerRating = () => {
	const { merchantConfigurationsStore, customerConfigurationsStore, notificationTemplatesStore } = useStores();
	const { t } = useTranslation();
	const [customerRatingData, setCustomerRatingData] = useState<CustomerRatingData>(null);
	const [currentLanguage, setLanguage] = useState(null);
	const [isRequestInProgress, setProgress] = useState(false);

	useEffect(() => {
		const init = async () => {
			await Promise.all([
				merchantConfigurationsStore.fetchRatingConfig(),
				notificationTemplatesStore.fetchLanguages(),
				customerConfigurationsStore.fetch()
			]);
			await notificationTemplatesStore.fetchCustomerRatingTemplates(
				merchantConfigurationsStore.ratingConfig.rating_reasons.map(option => `rating_reason_${option.id}`)
			);

			setLanguage(merchantConfigurationsStore.defaultLanguageCode);
		};

		init();
	}, [customerConfigurationsStore, merchantConfigurationsStore, notificationTemplatesStore]);

	useEffect(() => {
		if (currentLanguage) {
			setCustomerRatingData({
				allowRating: merchantConfigurationsStore.configuration.allow_rating,
				showPostRating: merchantConfigurationsStore.ratingConfig.use_rating_reason_screen,
				allowFreeTextComment: merchantConfigurationsStore.ratingConfig.allow_free_text_comment,
				numberOfStars: merchantConfigurationsStore.ratingConfig.rating_reason_screen_rating,
				ratingOptions: merchantConfigurationsStore.ratingConfig.rating_reasons.map(({ id: reasonId }) => ({
					id: reasonId,
					text:
						notificationTemplatesStore.get({
							language: currentLanguage,
							notification_type: `rating_reason_${reasonId}`
						})?.template || ''
				})),
				ratingScreenTitle:
					notificationTemplatesStore.get({
						language: currentLanguage,
						notification_type: NotificationTypes.RatingScreenTitle
					})?.template || '',
				positiveTitle:
					notificationTemplatesStore.get({
						language: currentLanguage,
						notification_type: NotificationTypes.PostRatingPositiveTitle
					})?.template || '',
				negativeTittle:
					notificationTemplatesStore.get({
						language: currentLanguage,
						notification_type: NotificationTypes.PostRatingNegativeTitle
					})?.template || ''
			});
		}
	}, [currentLanguage, merchantConfigurationsStore, notificationTemplatesStore]);

	const onChange: <T>(paramName: string, value: T) => void = (paramName, value) =>
		setCustomerRatingData(prevData => ({
			...prevData,
			[paramName]: value
		}));
	const onChangeCallback = useCallback(onChange, []);

	const onSubmit = async () => {
		try {
			setProgress(true);

			merchantConfigurationsStore.configuration.set({ allow_rating: customerRatingData.allowRating });

			const promises: Promise<any>[] = [
				merchantConfigurationsStore.configuration.update(),
				merchantConfigurationsStore.updateRatingConfig({
					rating_reason_screen_rating: customerRatingData.numberOfStars,
					use_rating_reason_screen: customerRatingData.showPostRating,
					allow_free_text_comment: customerRatingData.allowFreeTextComment,
					rating_reasons: customerRatingData.ratingOptions.map(option => {
						let returnObject = {
							text: option.text
						};

						if (option.id) {
							returnObject = {
								...merchantConfigurationsStore.ratingConfig.rating_reasons.find(
									reason => reason.id === option.id
								),
								...returnObject
							};
						}

						return returnObject as RatingReason;
					})
				})
			];

			const notificationTemplates: Partial<NotificationTemplate>[] = [];

			if (customerRatingData.allowRating) {
				notificationTemplates.push({
					language: currentLanguage,
					notification_type: NotificationTypes.RatingScreenTitle
				});
			}

			if (customerRatingData.showPostRating) {
				notificationTemplates.push(
					{
						language: currentLanguage,
						notification_type: NotificationTypes.PostRatingPositiveTitle
					},
					{
						language: currentLanguage,
						notification_type: NotificationTypes.PostRatingNegativeTitle
					}
				);
			}

			notificationTemplates.forEach(templateData => {
				let template = notificationTemplatesStore.get(templateData);
				const fieldToUpdate = notificationTypeToField[templateData.notification_type];

				if (!template) {
					template = new NotificationTemplate({
						language: currentLanguage,
						notification_type: templateData.notification_type
					});
				}

				template.setTemplate(customerRatingData[fieldToUpdate]);

				promises.push(notificationTemplatesStore.update(template));
			});

			// update all configs
			await Promise.all(promises);

			if (customerRatingData.showPostRating) {
				// need to re-fetch rating config to get ids for notification templates
				await merchantConfigurationsStore.fetchRatingConfig();

				// save rating reason notification template by language
				await Promise.all(
					merchantConfigurationsStore.ratingConfig.rating_reasons.map(async (option, index) => {
						const notificationType = `rating_reason_${option.id}`;
						let template = notificationTemplatesStore.get({
							language: currentLanguage,
							notification_type: notificationType
						});

						if (!template) {
							template = new NotificationTemplate({
								language: currentLanguage,
								notification_type: notificationType
							});
						}

						template.setTemplate(customerRatingData.ratingOptions[index].text);

						return notificationTemplatesStore.update(template);
					})
				);
			}

			notification.success(t('GLOBAL.SAVED_SUCCESSFULLY'));
		} catch (e) {
			console.error(`failed to save customer rating`, e);
			notification.error(t('GLOBAL.FAILED_TO_SAVE'));
		} finally {
			setProgress(false);
		}
	};

	return useObserver(() => {
		return customerRatingData === null ? (
			<Spinner />
		) : (
			<Row className="customer-rating-container">
				<Col xs={14}>
					<CustomerRatingHeader selectedLanguageCode={currentLanguage} setLang={setLanguage} />
					<RatingScreen
						allowRating={customerRatingData.allowRating}
						ratingScreenTitle={customerRatingData.ratingScreenTitle}
						onChange={onChangeCallback}
					/>
					<PostRating
						showPostRating={customerRatingData.showPostRating}
						numberOfStars={customerRatingData.numberOfStars}
						positiveTitle={customerRatingData.positiveTitle}
						negativeTittle={customerRatingData.negativeTittle}
						onChange={onChangeCallback}
					/>
					<RatingOptions
						disabled={customerRatingData.showPostRating === false}
						allowFreeTextComment={customerRatingData.allowFreeTextComment}
						onChange={onChange}
						ratingOptions={customerRatingData.ratingOptions}
					/>
					<Button
						loading={isRequestInProgress}
						disabled={isRequestInProgress}
						className="customer-rating-save-button"
						type="primary"
						onClick={onSubmit}
					>
						<Translate text="RATING_CONFIGURATION.SAVE" />
					</Button>
				</Col>
			</Row>
		);
	});
};

export default withErrorBoundary(CustomerRating);
