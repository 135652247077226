import React from 'react';

import { hasFeatureFlag } from '../../utils/feature-flags';
import useStores from '../../recipes/use-stores';
import { Portal } from 'bringg-web/components';
import DispatchMap from '../dispatch-map/dispatch-map';

const DispatchMapPortal = () => <Portal element={<DispatchMap />} nodeId="dispatch-mapbox-portal" />;

const PortalDispatchPage: React.FC = () => {
	const { usersStore } = useStores();
	const { currentUser } = usersStore;

	if (!hasFeatureFlag(currentUser, 'enable_mapbox')) {
		return null;
	}

	return <DispatchMapPortal />;
};

export default PortalDispatchPage;
