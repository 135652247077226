import moment from 'moment';
import { isNumber } from 'lodash';

export class LocaleService {
	initMomentLocale(languageCode: string, firstDayOfWeek?: number) {
		try {
			require('moment/locale/' + languageCode);
		} catch (error) {
			console.warn(`Failed to load locale for code '${languageCode}'.`);
		}

		if (!isNumber(firstDayOfWeek)) {
			moment.locale(languageCode);
		} else {
			moment.updateLocale(languageCode, {
				week: {
					dow: firstDayOfWeek,
					//This can be different per localization, but the value doesn't really matter.
					//This value means that the first week of the year will be the week that includes Jan 1st.
					doy: firstDayOfWeek + 6
				}
			});
		}
	}
}
