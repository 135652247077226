import { useCallback } from 'react';

import { TableState } from 'react-table';
import type { CustomAttributeRes } from '@bringg/types';

import type { UseTableFilters } from './use-table-filters';

export const useControlledTableState = <T extends Partial<T>>(
	filters: UseTableFilters<T>['filters'],
	filtersFields
) => {
	return useCallback(
		(state: TableState<CustomAttributeRes>) => {
			const nextState: TableState<CustomAttributeRes> = { ...state };

			nextState.filters = [];

			Object.keys(filters).forEach(filter => {
				if (filters[filter]?.trim()) {
					const value = filters[filter].trim();
					filtersFields[filter].forEach(field => nextState.filters.push({ id: field, value }));
				}
			});
			return nextState;
		},
		[filters]
	);
};
