import React from 'react';
import { SkillsPagePortal } from './skills-page';
import TimeOffPortal from './timeoff-portal/time-off-portal';
import WebUserInterfacePortal from './web-user-interface-portal/web-user-interface-portal';
import ServicePlans from './service-plans/setvice-plans';
import CustomAttributesPortal from './custom-attributes-portal/custom-attributes-portal';
import VehicleProfilesPortal from './vehicle-profiles-portal/vehicle-profiles-portal';
import TemplatesPortal from './templates/templates-portal';

const MerchantConfigPortals: React.FC = () => {
	return (
		<>
			<SkillsPagePortal />
			<TimeOffPortal />
			<WebUserInterfacePortal />
			<ServicePlans />
			<CustomAttributesPortal />
			<VehicleProfilesPortal />
			<TemplatesPortal />
		</>
	);
};

export default MerchantConfigPortals;
