import { useState, useEffect, useCallback } from 'react';

import { GoogleMap } from '@react-google-maps/api';
import { observer } from 'mobx-react-lite';
import { MarkerClusterer } from '@googlemaps/markerclusterer';
import { useTranslation } from 'react-i18next';

import ClusterRenderer from 'bringg-web/features/service-area/utils/cluster-renderer';
import { useStores } from 'bringg-web/recipes';
import { attachInfoWindow } from 'bringg-web/features/service-area/info-window';
import AddressAutoComplete from 'bringg-web/components/address-auto-complete/address-auto-complete';
import { openPolygonPath } from 'bringg-web/features/service-area/utils/enclose-polygon';

import './map.scss';

const DEFAULT_ZOOM = 12;
const OPACITY = 0.225;
const ZOOM_ON_TEAM = 15;
const ZOOM_ON_WORLD = 2;

export type Props = {
	onPolygonCreate?: (polygon: google.maps.Polygon) => void;
	onPolygonEdit?: (polygon: google.maps.Polygon) => void;
	readonly?: boolean;
	mapCenter: { lat: number; lng: number } | null;
	teamId?: number;
};

export const ServiceAreaMap = observer(
	({ onPolygonCreate, onPolygonEdit, readonly = false, mapCenter, teamId = null }: Props) => {
		const { serviceAreaViewStore, teamsStore, merchantStore, serviceAreasStore } = useStores();
		const { t } = useTranslation();
		const [, setPolygons] = useState([]);
		const [, setCluster] = useState<MarkerClusterer>(null);
		const [, setTeamMarkers] = useState<google.maps.Marker[]>([]);

		useEffect(() => {
			if (!serviceAreaViewStore.map) {
				return;
			}

			const withPath = serviceAreaViewStore.filtered.filter(serviceArea => serviceArea.path);
			setPolygons(prev => {
				prev.forEach(polygon => {
					google.maps.event.clearInstanceListeners(polygon);
					google.maps.event.clearInstanceListeners(polygon.getPath());
					polygon.setMap(null);
				});

				return withPath.map(serviceArea => {
					const isEditable = serviceArea.id === serviceAreaViewStore.focusedServiceArea?.id;

					const polygon = new google.maps.Polygon({
						map: serviceAreaViewStore.map,
						// for editing do not enclose polygon, user can't move first and last coordinate in one action
						paths: openPolygonPath(serviceArea.path),
						fillColor: serviceArea.color,
						strokeColor: serviceArea.color,
						fillOpacity: OPACITY,
						editable: isEditable
					});

					attachInfoWindow({
						serviceArea,
						t,
						polygon,
						readonly,
						onEdit: () => {
							serviceAreaViewStore.focusServiceArea(serviceArea);
						}
					});

					google.maps.event.addListener(polygon.getPath(), 'set_at', () => onPolygonEdit(polygon));
					google.maps.event.addListener(polygon.getPath(), 'insert_at', () => onPolygonEdit(polygon));

					return polygon;
				});
			});

			setCluster(prev => {
				if (prev) {
					prev.setMap(null);
				}

				return new MarkerClusterer({
					map: serviceAreaViewStore.map,
					renderer: new ClusterRenderer(),
					markers: withPath.map(
						sa =>
							new google.maps.Marker({
								visible: true,
								position: sa.center,
								opacity: 0
							})
					)
				});
			});

			setTeamMarkers(prev => {
				prev.forEach(marker => {
					marker.setMap(null);
				});

				let teamsToRender;
				if (teamId) {
					teamsToRender = teamsStore.teams.get(teamId) ? [teamsStore.teams.get(teamId)] : [];
				} else {
					teamsToRender = teamsStore.all;
				}

				return teamsToRender
					.filter(team => team.lat && team.lng)
					.map(team => {
						const marker = new google.maps.Marker({
							title: team.name,
							map: serviceAreaViewStore.map,
							position: team,
							icon: {
								url: '/images/team_home_v2.png',
								scaledSize: new google.maps.Size(40, 40)
							}
						});

						marker.addListener('click', () => {
							serviceAreaViewStore.map.setCenter(marker.getPosition());
							serviceAreaViewStore.map.setZoom(ZOOM_ON_TEAM);
						});

						return marker;
					});
			});
		}, [
			serviceAreaViewStore,
			t,
			serviceAreaViewStore.map,
			serviceAreaViewStore.focusedServiceArea?.polygon,
			teamsStore,
			onPolygonEdit,
			readonly,
			teamId,
			serviceAreaViewStore.filters
		]);

		const onLoad = map => {
			if (!map) {
				return;
			}

			if (!readonly) {
				const drawingManager = new google.maps.drawing.DrawingManager({
					map,
					drawingControlOptions: {
						position: google.maps.ControlPosition.TOP_LEFT,
						drawingModes: [google.maps.drawing.OverlayType.POLYGON]
					}
				});

				google.maps.event.addListener(drawingManager, 'polygoncomplete', polygon => {
					drawingManager.setDrawingMode(null);
					onPolygonCreate(polygon);
				});
			}

			serviceAreaViewStore.onMapLoad(map);
		};

		useEffect(() => {
			if (!serviceAreaViewStore.map) {
				return;
			}

			if (serviceAreaViewStore.bounds) {
				serviceAreaViewStore.centerMap();
			} else {
				serviceAreaViewStore.map.setZoom(mapCenter ? DEFAULT_ZOOM : ZOOM_ON_WORLD);
				serviceAreaViewStore.map.setCenter(mapCenter ? mapCenter : { lat: 0, lng: 0 });
			}
		}, [serviceAreaViewStore.map, mapCenter]);

		const onSearch = useCallback(
			async (address, _, placeId) => {
				if (!serviceAreaViewStore.map) {
					return;
				}

				const service = new google.maps.places.PlacesService(serviceAreaViewStore.map);
				const viewportPromise: Promise<google.maps.LatLngBounds> = new Promise(resolve => {
					service.getDetails({ placeId, fields: ['geometry'] }, (place, status) => {
						if (status === google.maps.places.PlacesServiceStatus.OK) {
							resolve(place.geometry.viewport);
						}
					});
				});

				serviceAreaViewStore.map.fitBounds(await viewportPromise);
			},
			[serviceAreaViewStore.map]
		);

		if (!merchantStore.merchant || !serviceAreasStore.isFetched || !teamsStore.isFetched) {
			return null;
		}

		return (
			<>
				<AddressAutoComplete
					data-test-id="google-autocomplete"
					placeholder={t('GLOBAL.SEARCH')}
					onSelect={onSearch}
					className="service-area-map-autocomplete"
				/>
				<GoogleMap
					data-test-id="google-maps-component"
					options={{
						streetViewControl: false,
						mapTypeControl: false,
						fullscreenControl: false
					}}
					onLoad={onLoad}
					mapContainerClassName="service-area-map"
				/>
			</>
		);
	}
);
