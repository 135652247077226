import { getRootEnv } from '@bringg-frontend/bringg-web-infra';
import { action, computed, makeObservable, observable } from 'mobx';
import moment, { MomentInput } from 'moment';
import { ChatMessage } from '@bringg/types';
import { ChatConversationConsts } from '@bringg/dashboard-sdk';

import ChatConversation from './domain-objects/chat-conversation';
import { chatRootStore } from 'bringg-web/features/chat/stores/chat-root-store';

const BETWEEN_TIME_IN_MINUTES = 10;
const CONVERSATION_ITEMS_NUM = 100;

class ChatStore {
	chatConversations: ChatConversation[] = [];
	currentChat: ChatConversation = null;
	isFetched = false;
	chatConversationMap: Map<number, ChatConversation> = new Map();

	constructor() {
		makeObservable(this, {
			chatConversations: observable,
			currentChat: observable,
			isFetched: observable,
			chatConversationMap: observable,
			addItem: action,
			removeItem: action,
			unselectChat: action,
			selectCurrentChat: action,
			setFetched: action,
			getConversations: computed,
			getCurrentChat: computed
		});
	}

	addItem = (item: Bringg.ChatConversation) => {
		this.chatConversationMap.set(item.id, new ChatConversation(item));
	};

	removeItem = (itemId: number) => {
		if (this.currentChat && this.currentChat.id === itemId) {
			this.unselectChat();
		}

		this.chatConversationMap.delete(itemId);
	};

	unselectChat() {
		this.currentChat = null;
	}

	selectCurrentChat(id: number) {
		this.currentChat = this.chatConversationMap.get(id);
		this.currentChat.getMessages();
		this.currentChat.resetUnreadCount();
		this.currentChat.updateLastRead();
		this.currentChat.loadUsers();
	}

	setFetched() {
		this.isFetched = true;
	}

	get getConversations(): ChatConversation[] {
		return Array.from(this.chatConversationMap.values());
	}

	get getCurrentChat(): ChatConversation {
		return this.currentChat;
	}

	async fetchConversations() {
		const result: Partial<ChatConversationConsts.ChatConversationsResponse> =
			await getRootEnv().dashboardSdk.sdk.chat.getConversations(1, {
				items: CONVERSATION_ITEMS_NUM
			});
		result.conversations.map(this.addItem);
		this.setFetched();
	}

	attachListeners() {
		getRootEnv().dashboardSdk.sdk.chat.onItemCreated(this.addItem);
		getRootEnv().dashboardSdk.sdk.chat.onItemRemoved(this.removeItem);
		getRootEnv().dashboardSdk.sdk.chat.chatMessage.onItemCreated(this.onNewChatMessage);
		getRootEnv().dashboardSdk.sdk.chat.chatMessage.onListUpdate(this.onChatListUpdate);
	}

	onNewChatMessage = (message: ChatMessage) => {
		if (this.isCurrentChat(message.chat_conversation_id)) {
			this.currentChat.fetchUserIfNotExist(message.user_id);
			if (this.isRecentMessage(message.server_timestamp)) {
				this.updateActiveChat(message);
			}
		} else {
			this.updateNonActiveChat(message);
		}
	};

	onChatListUpdate = () => {
		this.currentChat?.setMessages(getRootEnv().dashboardSdk.sdk.chat.getChatMessages(this.currentChat.id));
	};

	isCurrentUser(userId: number): boolean {
		return getRootEnv().dashboardSdk.sdk.session.user.id === userId;
	}

	isCurrentChat(chatId: number): boolean {
		return this.currentChat && this.currentChat.id === chatId;
	}

	private isRecentMessage(timestamp: MomentInput): boolean {
		return moment(timestamp).isBetween(
			moment().add(BETWEEN_TIME_IN_MINUTES * -1, 'minutes'),
			moment().add(BETWEEN_TIME_IN_MINUTES, 'minutes')
		);
	}

	private updateActiveChat(message: ChatMessage) {
		chatRootStore.getStore().chatView.scrollBottom();
		this.currentChat.updateLastMessage(message);
		this.currentChat.updateLastRead();
	}

	private updateNonActiveChat(message: ChatMessage) {
		const conversation = this.chatConversationMap.get(message.chat_conversation_id);

		if (conversation && !conversation.hasMessage(message.id)) {
			conversation.addUnreadCount();
			conversation.updateLastMessage(message);
		}
	}
}

export default ChatStore;
