import React, { memo } from 'react';
import { CustomAttributeDataType } from '@bringg/types';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import './styles.scss';

interface Props {
	dataType: CustomAttributeDataType;
	suggestion?: boolean;
}

function AttributeDataTypeCell({ dataType, suggestion }: Props) {
	const { t } = useTranslation();
	return (
		<div className="attribute-data-type">
			<span className={classNames({ 'suggested-attribute': suggestion })}>
				{t(`CUSTOM_ATTRIBUTES.${CustomAttributeDataType[dataType].toUpperCase()}_TYPE`)}
			</span>
		</div>
	);
}

export default memo(AttributeDataTypeCell);
