import { MerchantConfiguration } from '@bringg/types';

export type OpenTasksFilter = MerchantConfiguration['open_tasks_filter'];

export const defaultStoreAppFilterSettings: FilterSettings = {
	curbside: true,
	delivery: true
};

export interface FilterSettings {
	[name: string]: boolean;
}

export const formatFilterSettingsToServer = (settings: FilterSettings): OpenTasksFilter => {
	return {
		options: Object.entries(settings).map(([name, selected]) => ({ name, selected }))
	};
};

export const formatFilterSettingsFromServer = (open_tasks_filter: OpenTasksFilter): FilterSettings => {
	return open_tasks_filter.options.reduce((acc, curr) => {
		acc[curr.name] = curr.selected;
		return acc;
	}, {});
};
