import Bringg from '@bringg/types';
import { action, makeObservable } from 'mobx';

export default class Merchant {
	id: number;
	uuid: string;
	lat: number;
	lng: number;
	time_zone: string;

	constructor(config: Partial<Bringg.Merchant>) {
		makeObservable(this, {
			set: action
		});

		Object.assign(this, config);
	}

	set(merchant: Partial<Bringg.Merchant>) {
		Object.assign(this, merchant);
	}
}
