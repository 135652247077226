export const naturalSortByKey = (array: any[], key: string): any[] => {
	const arr = Array.from(array);
	const numbersOrLetters = /(\d+)|(\D+)/g;
	const hasNumber = /\d+/;
	arr.sort(function (a, b) {
		if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) return 0;
		//seperate numbers and letters
		a = a[key].toLowerCase().match(numbersOrLetters);
		b = b[key].toLowerCase().match(numbersOrLetters);
		while (a.length && b.length) {
			const a1 = a.shift();
			const b1 = b.shift();
			if (hasNumber.test(a1) || hasNumber.test(b1)) {
				if (!hasNumber.test(a1)) return 1;
				if (!hasNumber.test(b1)) return -1;
				if (a1 != b1) return a1 - b1;
			} else if (a1 != b1) return a1 > b1 ? 1 : -1;
		}
		return a.length - b.length;
	});
	return arr;
};
