import { getRootEnv } from '@bringg-frontend/bringg-web-infra';
import { TFunction } from 'i18next';
import { getEnv } from 'mobx-easy';
import { LookupConsts } from '@bringg/dashboard-sdk';
import { FloatingInventoryOwnerType, FloatingInventoryStatus } from '@bringg/types';
import { FilterBar2, FilterBar2ListItem, FilterBar2Value } from '@bringg/react-components';
import { RootEnv } from '@bringg-frontend/bringg-web-infra';

import { statusTranslations, ownerTypeTranslations } from '../..';
import { FloatingInventoriesFilterItems } from '../floating-inventories-filters.types';

const mapListValues = (data): FilterBar2Value[] => {
	return data.items.map(item => {
		const matchedKey: string = item.matched_keys[0];
		return { value: item.id, label: item[matchedKey] };
	});
};

const onTeamSearch = async (value: string): Promise<FilterBar2Value[]> => {
	const lookUp = getRootEnv().dashboardSdk.sdk.v2.lookup;
	const params: LookupConsts.BaseSearchQuery = {
		size: 100,
		query: value
	};
	const listValues = await lookUp.getTeams(params);
	return listValues?.items?.length ? mapListValues(listValues) : [];
};

const buildFilterObject = (t: TFunction, filterItems: FloatingInventoriesFilterItems): FilterBar2ListItem[] => {
	const filterList: FilterBar2ListItem[] = [
		{
			type: FilterBar2.TYPES.DROPDOWN_SEARCH,
			name: 'sku',
			title: t('FLOATING_INVENTORIES.COLUMNS.SKU'),
			placeholder: t('FLOATING_INVENTORIES.COLUMNS.SKU'),
			applyFilterOnValueChange: true
		},
		{
			type: FilterBar2.TYPES.SELECT,
			name: 'team_ids',
			title: t('FLOATING_INVENTORIES.FILTER_BAR.TEAM_NAME'),
			placeholder: t('FLOATING_INVENTORIES.FILTER_BAR.TEAM_NAME'),
			multiselect: true,
			values: filterItems.team_ids || [],
			customProps: {
				onSearch: onTeamSearch
			},
			applyFilterOnValueChange: true
		},
		{
			type: FilterBar2.TYPES.SELECT,
			name: 'owner_type',
			title: t('FLOATING_INVENTORIES.COLUMNS.OWNER_TYPE'),
			placeholder: t('FLOATING_INVENTORIES.COLUMNS.OWNER_TYPE'),
			multiselect: true,
			values: [
				{ label: t(ownerTypeTranslations.driver), value: FloatingInventoryOwnerType.DRIVER },
				{ label: t(ownerTypeTranslations.team), value: FloatingInventoryOwnerType.TEAM },
				{ label: t(ownerTypeTranslations.none), value: FloatingInventoryOwnerType.NONE }
			],
			applyFilterOnValueChange: true
		},
		{
			type: FilterBar2.TYPES.SELECT,
			name: 'status',
			title: t('FLOATING_INVENTORIES.COLUMNS.STATUS'),
			placeholder: t('FLOATING_INVENTORIES.COLUMNS.STATUS'),
			multiselect: true,
			values: [
				{ label: t(statusTranslations.available), value: FloatingInventoryStatus.AVAILABLE },
				{ label: t(statusTranslations.missing), value: FloatingInventoryStatus.MISSING },
				{ label: t(statusTranslations.used), value: FloatingInventoryStatus.USED }
			],
			applyFilterOnValueChange: true
		}
	];
	return filterList;
};

export default buildFilterObject;
