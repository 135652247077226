import React, { CSSProperties, useContext, useMemo, ReactChild } from 'react';

import { Spinner } from '@bringg/react-components';

import TableInstanceContext from '../../contexts/table-instance-context';
import ListInnerElementContext from './list-inner-element-context';
import TableHeader from '../table-header';
import NoDataMessage from '../no-data-message';
import { TestIds } from 'bringg-web/consts';

interface Props {
	children: ReactChild[];
	style: CSSProperties;
}

const ListInnerElement = ({ children, style }: Props) => {
	const { headerHeight, isLoading } = useContext(ListInnerElementContext);
	const { headerGroups, getTableBodyProps, totalColumnsWidth } = useContext(TableInstanceContext);

	// const tableBodyStyles = useMemo<CSSProperties>(() => ({ height: bodyHeight - headerHeight }), [
	//   bodyHeight,
	//   headerHeight,
	// ]);
	const tableHeaderStyles = useMemo<CSSProperties>(() => ({ width: totalColumnsWidth }), [totalColumnsWidth]);

	const tableContent = useMemo(() => {
		if (!children?.length) {
			return <NoDataMessage />;
		}

		return (
			<div {...getTableBodyProps()} style={style}>
				{children}
			</div>
		);
	}, [children, style, getTableBodyProps]);

	// style={tableBodyStyles} was applied to table-body, consider as possible source of scroll bugs
	return (
		<div id="innerElementType">
			<TableHeader height={headerHeight} headerGroups={headerGroups} style={tableHeaderStyles} />
			<div className="table-body">
				{isLoading ? (
					<div className="table-body-loader" data-test-id={TestIds.TABLE_LOADING_SPINNER}>
						<Spinner size="large" />
					</div>
				) : (
					tableContent
				)}
			</div>
		</div>
	);
};

export default ListInnerElement;
