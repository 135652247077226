import { getRootEnv } from '@bringg-frontend/bringg-web-infra';
import { action, computed, observable, makeObservable } from 'mobx';
import { getEnv } from 'mobx-easy';
import _findIndex from 'lodash/findIndex';
import _reject from 'lodash/reject';
import _map from 'lodash/map';
import moment from 'moment';
import { Vehicle } from '@bringg/types';
import { RootEnv } from '@bringg-frontend/bringg-web-infra';

import DeliveryBlock from './domain-objects/delivery-block';
import { checkRequestedTimeConflictsWithBlock } from '../delivery-blocks-utils';

class DeliveryBlocksStore {
	deliveryBlocks: Map<number, DeliveryBlock> = new Map();
	deliveryBlocksByTeam: Map<number, DeliveryBlock[]> = new Map();
	isFetching = true;

	constructor() {
		makeObservable(this, {
			deliveryBlocks: observable.shallow,
			deliveryBlocksByTeam: observable.shallow,
			isFetching: observable,
			setIsFetching: action,
			set: action,
			setByTeam: action,
			setDeliveryBlocks: action,
			all: computed
		});
	}

	setIsFetching = (isFetching: boolean) => {
		this.isFetching = isFetching;
	};

	set = (deliveryBlock: DeliveryBlock) => {
		this.deliveryBlocks.set(deliveryBlock.id, deliveryBlock);

		this.updateDeliveryBlockByTeam(deliveryBlock);
	};

	setByTeam = (teamId: number, deliveryBlocks: DeliveryBlock[]) => {
		this.deliveryBlocksByTeam.set(teamId, deliveryBlocks);
	};

	setDeliveryBlocks = (teamId: number, deliveryBlocks) => {
		const deliveryBlockDomainObjects: DeliveryBlock[] = deliveryBlocks.map(
			deliveryBlock => new DeliveryBlock(this, deliveryBlock)
		);

		const otherTeamsBlocks = this.all.filter(({ team_id }) => team_id !== teamId);

		const deliveryBlockEntries: [number, DeliveryBlock][] = [
			...otherTeamsBlocks,
			...deliveryBlockDomainObjects
		].map(deliveryBlock => [deliveryBlock.id, deliveryBlock]);

		this.deliveryBlocks = new Map<number, DeliveryBlock>(deliveryBlockEntries);
		this.deliveryBlocksByTeam.set(teamId, deliveryBlockDomainObjects);
	};

	get all(): DeliveryBlock[] {
		return Array.from(this.deliveryBlocks.values());
	}

	allByDriver = (userId: number, teamId?: number): DeliveryBlock[] => {
		return computed(() => {
			const deliveryBlocks = teamId ? this.deliveryBlocksByTeam.get(teamId) : this.all;
			return deliveryBlocks.filter(
				({ user_ids, delivery_block_resources }) =>
					user_ids.includes(userId) ||
					delivery_block_resources.map(resource => resource.user_id).includes(userId)
			);
		}).get();
	};

	allByVehicle = (teamId: number, vehicleId: number, isTrailer: boolean): DeliveryBlock[] => {
		return this.allByTeam(teamId).filter(deliveryBlock =>
			deliveryBlock.delivery_block_resources
				.map(resource => (isTrailer ? resource.trailer_id : resource.vehicle_id))
				.includes(vehicleId)
		);
	};

	allByTeam = (teamId: number) => {
		return this.deliveryBlocksByTeam.get(teamId);
	};

	get = (id: number) => {
		return this.deliveryBlocks.get(id);
	};

	remove = (deliveryBlockId: number) => {
		const deliveryBlock = this.get(deliveryBlockId);
		this.deliveryBlocks.delete(deliveryBlockId);
		const blocksByTeam = this.deliveryBlocksByTeam.get(deliveryBlock.team_id) || [];
		this.setByTeam(
			deliveryBlock.team_id,
			_reject(blocksByTeam, ({ id }) => id === deliveryBlockId)
		);
	};

	removeMultiple = (teamId: number, deliveryBlockIds: number[]) => {
		const updatedDeliveryBlocks = _reject(this.all, ({ id }) => deliveryBlockIds.includes(id));
		this.setDeliveryBlocks(teamId, updatedDeliveryBlocks);
	};

	fetch = async (teamId: number, startDate: number, endDate: number) => {
		this.setIsFetching(true);

		const result: Bringg.DeliveryBlock[] = await getRootEnv().dashboardSdk.sdk.deliveryBlocks.get(
			teamId,
			startDate,
			endDate
		);

		this.clearDeliveryBlocksByTime(teamId, startDate, endDate);
		this.setDeliveryBlocks(teamId, result);
		this.setIsFetching(false);
	};

	clearDeliveryBlocksByTime = (teamId: number, startDate: number, endDate: number) => {
		const startDateToCheck = moment.unix(startDate).toISOString();
		const endDateToCheck = moment.unix(endDate).toISOString();

		const deliveryBlocksBetweenDates = this.all.filter(
			({ start_time, end_time, team_id: deliveryBlockTeamId }) =>
				deliveryBlockTeamId === teamId &&
				moment(start_time).isSameOrAfter(startDateToCheck) &&
				moment(end_time).isSameOrBefore(endDateToCheck)
		);

		this.removeMultiple(teamId, _map(deliveryBlocksBetweenDates, 'id'));
	};

	private updateDeliveryBlockByTeam = (deliveryBlock: DeliveryBlock) => {
		const deliveryBlocksByTeam = this.deliveryBlocksByTeam.get(deliveryBlock.team_id) || [];
		const deliveryBlockIndex = _findIndex(deliveryBlocksByTeam, ['id', deliveryBlock.id]);

		if (deliveryBlockIndex >= 0) {
			deliveryBlocksByTeam.splice(deliveryBlockIndex, 1);
		}

		this.setByTeam(deliveryBlock.team_id, [...deliveryBlocksByTeam, deliveryBlock]);
	};

	public isAvailableAtRequestedBlock(
		vehicle: Vehicle,
		startTimeToCheck: string,
		endTimeToCheck: string,
		ignoreDeliveryBlockIds: number[] = []
	): boolean {
		return !this.allByVehicle(vehicle.team_id, vehicle.id, vehicle.is_trailer)
			.filter(({ id }) => !ignoreDeliveryBlockIds.includes(id))
			.some(deliveryBlock =>
				checkRequestedTimeConflictsWithBlock(deliveryBlock, startTimeToCheck, endTimeToCheck)
			);
	}
}

export default DeliveryBlocksStore;
