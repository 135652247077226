import { HandlingUnits, Task } from '@bringg/types';
import moment from 'moment/moment';
import { flatten, cloneDeep } from 'lodash';

import { CollapseLinkedTasksData } from './collapse-linked-tasks.consts';

const getLastWayPoint = (task: Task) => {
	return task.way_points[task.way_points.length - 1];
};

const getSumOfMaxHandlingUnits = (collapseLinkTasks: CollapseLinkedTasksData): HandlingUnits => {
	const sumOfHandlingUnits = {} as HandlingUnits;

	collapseLinkTasks.tasks.forEach(task => {
		const taskHandlingUnits: HandlingUnits = task.aggregations?.max_handling_units;
		if (!taskHandlingUnits) {
			return;
		}
		const handlingUnits = Object.entries(taskHandlingUnits);
		handlingUnits.forEach(([key, value]: [string, number]) => {
			const currentValue = sumOfHandlingUnits[key] ?? 0;
			sumOfHandlingUnits[key] = currentValue + value;
		});
	});

	return sumOfHandlingUnits;
};

const getUpdatedUiData = (collapseLinkTasks: CollapseLinkedTasksData) => {
	const uiData = collapseLinkTasks.tasks.map(task => getLastWayPoint(task).ui_data ?? null).filter(Boolean);
	//@ts-ignore
	const ordersTag = uiData.length ? Array.from(new Set(uiData.map(data => data.order_tag)).toString()) : null; //TODO add order_tag to WayPointUiData
	return uiData.length ? { ...uiData[0], order_tag: ordersTag } : null;
};

const getMaxDate = (collapseLinkTasks: CollapseLinkedTasksData, func) => {
	const result = moment.max(collapseLinkTasks.tasks.map(func));
	return result.isValid() ? result : undefined;
};

const getMinDate = (collapseLinkTasks: CollapseLinkedTasksData, func) => {
	const result = moment.min(collapseLinkTasks.tasks.map(func));
	return result.isValid() ? result : undefined;
};

const getSum = (collapseLinkTasks: CollapseLinkedTasksData, func: (task: Task) => number) => {
	return collapseLinkTasks.tasks
		.map(func)
		.filter(Boolean)
		.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
};

const getDistinctList = (collapseLinkTasks: CollapseLinkedTasksData, func: (task: Task) => string) => {
	return Array.from(new Set(collapseLinkTasks.tasks.map(func).filter(Boolean)));
};
const getDistinctFlattenList = (collapseLinkTasks: CollapseLinkedTasksData, func: (task: Task) => string[]) => {
	return Array.from(new Set(flatten(collapseLinkTasks.tasks.map(func).filter(Boolean))));
};

const convertCollapseLinkedTasksToTask = (collapseLinkTasks: CollapseLinkedTasksData): Task => {
	const firstTask: Task = cloneDeep(collapseLinkTasks.tasks[0]);

	const maxPreparationReadyForPickupTimePlanned = getMaxDate(collapseLinkTasks, task =>
		moment(task.preparation_ready_for_pickup_time_planned)
	)?.toISOString();
	const maxFirstScheduledArrival = getMaxDate(collapseLinkTasks, task =>
		moment(getLastWayPoint(task).scheduled_arrival)
	);
	const minNoEarlierThan = getMinDate(collapseLinkTasks, task => moment(getLastWayPoint(task).no_earlier_than));
	const maxNoLaterThan = getMaxDate(collapseLinkTasks, task => moment(getLastWayPoint(task).no_later_than));
	const maxEtl = getMaxDate(collapseLinkTasks, task => moment(getLastWayPoint(task).etl));
	const minEta = getMinDate(collapseLinkTasks, task => moment(getLastWayPoint(task).eta));
	const minWayPointScheduledAt = getMinDate(collapseLinkTasks, task => moment(getLastWayPoint(task).scheduled_at));
	const sumMaxTotalWeight = getSum(collapseLinkTasks, task => task.aggregations?.max_weight || null);
	const minReservedUntil = getMinDate(collapseLinkTasks, task => moment(task.reserved_until))?.toISOString();
	const maxCreatedAt = getMaxDate(collapseLinkTasks, task => moment(task.created_at))?.toISOString();
	const minRank = Math.min(...collapseLinkTasks.tasks.map(task => task.rank));
	const minScheduledAt = getMinDate(collapseLinkTasks, task => moment(task.scheduled_at))?.toISOString();
	const servicePlanNames = getDistinctList(collapseLinkTasks, task => task.service_plan?.name).toString();
	const sumInventoryTotalQuantity = getSum(
		collapseLinkTasks,
		task => task.aggregations?.inventory_total_quantity || 0
	);
	const sumTotalPrice = getSum(collapseLinkTasks, task => task.total_price || 0);
	const sumMaxHandlingUnits = getSumOfMaxHandlingUnits(collapseLinkTasks);
	const requiredSkills = getDistinctFlattenList(collapseLinkTasks, task => task.required_skills);
	const updatedUiData = getUpdatedUiData(collapseLinkTasks);

	const lastWayPoint = getLastWayPoint(firstTask);
	lastWayPoint.etos =
		maxEtl && minEta ? moment.duration(maxEtl.valueOf() - minEta.valueOf()).as('seconds') : undefined;
	lastWayPoint.first_attempt_promise_no_earlier_than = getMinDate(collapseLinkTasks, task =>
		moment(getLastWayPoint(task).first_attempt_promise_no_earlier_than)
	)?.toISOString();
	lastWayPoint.eta = minEta?.toISOString();
	lastWayPoint.etl = maxEtl?.toISOString();
	lastWayPoint.no_later_than = maxNoLaterThan?.toISOString();
	lastWayPoint.no_earlier_than = minNoEarlierThan?.toISOString();
	lastWayPoint.scheduled_at = minWayPointScheduledAt?.toISOString();
	lastWayPoint.scheduled_arrival = maxFirstScheduledArrival?.toISOString();

	const teamId = firstTask.team_ids[0];

	const activeWayPoint = firstTask.active_way_point_id
		? firstTask.way_points.find(task => task.id === firstTask.active_way_point_id)
		: null;

	return {
		...firstTask,
		id: collapseLinkTasks.id,
		external_id: 'COLLAPSE_LINKED_TASKS.MULTI_PICKUP',
		team_ids: [teamId],
		//@ts-ignore
		team_id: teamId,
		way_points: firstTask.way_points,
		preparation_ready_for_pickup_time_planned: maxPreparationReadyForPickupTimePlanned,
		total_weight: sumMaxTotalWeight,
		print_receipt: null,
		reserved_until: minReservedUntil,
		created_at: maxCreatedAt,
		rank: minRank !== 0 ? minRank : null,
		scheduled_at: minScheduledAt,
		service_plan: { ...firstTask.service_plan, name: servicePlanNames },
		total_quantity: sumInventoryTotalQuantity,
		total_price: sumTotalPrice !== 0 ? sumTotalPrice : null,
		aggregations: {
			...firstTask.aggregations,
			inventory_total_quantity: sumInventoryTotalQuantity,
			max_weight: sumMaxTotalWeight,
			max_handling_units: sumMaxHandlingUnits
		},
		requiredSkills: requiredSkills,
		ui_data: updatedUiData,
		priority: firstTask.priority,
		activeWayPoint: activeWayPoint
	};
};

const getPriorities = (tasks: Task[]) => {
	const minPriority = Math.min(...tasks.map(task => task.priority));
	const maxPriority = Math.max(...tasks.map(task => task.priority));
	return `${minPriority}-${maxPriority}`;
};

const generateCollapseLinkedTasksData = (lastTask: Task, task: Task, customerId: number): CollapseLinkedTasksData => {
	const tasks = [lastTask, task];
	const tagIds = Array.from(new Set(tasks.map(task => task.tag_id)));

	return {
		id: lastTask.id * -1,
		customer_id: customerId,
		tasks: tasks,
		run_id: lastTask.run_id,
		tag_ids: tagIds,
		priorities: getPriorities(tasks)
	};
};
export const collapseLinkedTasksGenerator = {
	convertCollapseLinkedTasksToTask,
	generateCollapseLinkedTasksData,
	getPriorities
};
