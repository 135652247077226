import { action, computed, makeObservable, observable } from 'mobx';
import { get as _get, isMatch as _isMatch, isNull as _isNull } from 'lodash';
import { getEnv } from 'mobx-easy';
import { computedFn } from 'mobx-utils';
import type { Application, Location, MerchantConfigurationCSVField, RatingReasonConfig } from '@bringg/types';
import {
	ApplicationMerchantConfigurations,
	ApplicationUuid,
	EndOfWeekDay,
	HourFormat,
	ResizableDataField
} from '@bringg/types';
import { dateUtils } from '@bringg-frontend/utils';

import MerchantConfiguration from './domain-objects/merchant-configuration';
import { RootEnv } from '../root-env';
import { registerAction } from '../other/cross-application';
import { MerchantConfigurationActions } from './cross-application.actions';
import { DEFAULT_DATE_FORMAT } from '../other/consts';

class MerchantConfigurationsStore {
	isFetched = false;
	configuration: MerchantConfiguration = null;
	taskCsvFields: MerchantConfigurationCSVField[] = null;
	ratingConfig: RatingReasonConfig = null;

	constructor() {
		makeObservable(this, {
			isFetched: observable,
			configuration: observable,
			taskCsvFields: observable,
			ratingConfig: observable,
			setRatingReasonConfig: action,
			setFetched: action,
			storeConfiguration: action,
			breakBuffer: computed,
			useClusterLinkedOrdersMerchantLevel: computed,
			useClusterLinkedOrders: computed,
			defaultLanguageCode: computed,
			sharingMode: computed,
			deliveryPin: computed,
			firstDayOfWeek: computed,
			endOfWeekDay: computed,
			merchantLocation: computed,
			hourFormat: computed,
			hourStringFormat: computed,
			dateFormatTimeFirst: computed,
			autoDispatchStatus: computed,
			enableSkills: computed,
			enableTaskPrice: computed,
			allowDispatcherToAccessTaskPrice: computed,
			localizeTaskTimezonesByTeams: computed,
			shouldUseTime12HoursFormat: computed,
			enablePlannedRoutes: computed,
			merchantCountry: computed,
			allowMultiDayOptimization: computed,
			initialLoadingTimeMinutes: computed,
			initialUnloadingTimeMinutes: computed
		});

		this.initCrossAppCommunication();
	}

	private initCrossAppCommunication() {
		// Subscribe to successful updates of Merchant Configuration from ng
		registerAction(
			MerchantConfigurationActions.MERCHANT_CONFIGURATION_UPDATE,
			(params: { merchantConfiguration: Bringg.MerchantConfiguration }) => {
				if (params && params.merchantConfiguration) {
					this.storeConfiguration(params.merchantConfiguration);
				}
			},
			getEnv<RootEnv>().dashboardSdk.sdk.crossAppTransport
		);
	}

	setRatingReasonConfig = (ratingConfig: RatingReasonConfig) => {
		this.ratingConfig = ratingConfig;
	};

	setFetched = () => {
		this.isFetched = true;
	};

	storeConfiguration = (config: Bringg.MerchantConfiguration) => {
		this.configuration = new MerchantConfiguration(config);
	};

	get breakBuffer() {
		return _get(this.configuration, 'scheduled_break_minutes_buffer', 0);
	}

	get defaultLanguageCode() {
		return this.configuration.language_code;
	}

	get sharingMode(): number {
		return this.configuration.sharing_mode;
	}

	get deliveryPin(): string {
		return this.configuration.delivery_pin;
	}

	get allowMultiDayOptimization(): boolean {
		return this.configuration.allow_multi_day_optimization;
	}

	get firstDayOfWeek() {
		return (this.configuration.end_of_week_day + 6) % 7; // 0 - sunday 1 - monday
	}

	get endOfWeekDay(): EndOfWeekDay {
		return this.configuration.end_of_week_day;
	}

	get merchantLocation(): Location {
		const lat = _get(this.configuration, 'lat');
		const lng = _get(this.configuration, 'lng');

		return { lat, lng };
	}

	get hourFormat(): HourFormat {
		return _get(this.configuration, 'hour_format', HourFormat.Default);
	}

	get hourStringFormat(): string {
		return dateUtils.getMerchantTimeFormat(this.hourFormat);
	}

	get dateFormatTimeFirst() {
		return `${this.hourStringFormat}, ${DEFAULT_DATE_FORMAT}`;
	}

	get autoDispatchStatus(): boolean {
		return this.configuration.applications.some(app => _isMatch(app, { name: 'VrpAutoDispatch' }));
	}

	get enableSkills(): boolean {
		return _get(this.configuration, 'enable_skills', false);
	}

	get enableTaskPrice(): boolean {
		return _get(this.configuration, 'enable_task_price', false);
	}

	get enableFleets(): boolean {
		return _get(this.configuration, 'enable_fleets', false);
	}

	get allowDispatcherToAccessTaskPrice(): boolean {
		return _get(this.configuration, 'allow_dispatcher_to_access_task_price', false);
	}

	get localizeTaskTimezonesByTeams(): boolean {
		return _get(this.configuration, 'localize_task_timezones_by_teams', false);
	}

	get defaultOrderPageTab(): number {
		return _get(this.configuration, ['dashboard_ui_configuration', 'default_order_page_tab'], 0) || 0;
	}

	get useClusterLinkedOrdersMerchantLevel(): boolean {
		return _get(this.configuration, ['merchant_dashboard_ui_configuration', 'use_cluster_linked_orders']) ?? true;
	}

	get useClusterLinkedOrders(): boolean {
		return _get(this.configuration, ['dashboard_ui_configuration', 'use_cluster_linked_orders']) ?? true;
	}

	get defaultOrderPageTabOnMerchantLevel(): number {
		return _get(this.configuration, ['merchant_dashboard_ui_configuration', 'default_order_page_tab'], 0) || 0;
	}

	get ordersHistoryFields(): Partial<ResizableDataField>[] | null {
		// This is because of difference in save of configuration. For some unknown reason, orders_history_fields has its own column on dashboard_ui_configuration
		// While other table configurations are stored under table_configuration.
		const userLevel = _get(
			this.configuration,
			['dashboard_ui_configuration', 'table_configuration', 'orders_history_fields'],
			null
		);
		const merchantLevel = _get(this.configuration, ['dashboard_ui_configuration', 'orders_history_fields'], null);

		return _isNull(userLevel) ? merchantLevel : userLevel;
	}

	get showEmptyTeams(): boolean {
		return _get(this.configuration, ['merchant_dashboard_ui_configuration', 'show_empty_teams']) ?? true;
	}

	get showEmptyDrivers(): boolean {
		return _get(this.configuration, ['merchant_dashboard_ui_configuration', 'show_empty_drivers'], false);
	}

	get planning_phase_exists(): boolean {
		return this.configuration.planning_phase_exists || false;
	}

	get uniqueInventoryFields(): string[] {
		return this.configuration.unique_inventory_fields || [];
	}

	get ignoreInventoryQuantityForCapacityCalculation(): boolean {
		return this.configuration.ignore_inventory_quantity_for_capacity_calculation || false;
	}

	get max_distance_for_optimization_in_meters(): number {
		return this.configuration.max_distance_for_optimization_in_meters || 0;
	}

	get enableVehicles(): boolean {
		return this.configuration.enable_vehicles || false;
	}

	get shouldUseTime12HoursFormat(): boolean {
		return this.configuration?.hour_format === 1;
	}

	get deliveryHubMainMenuUI(): boolean {
		return this.configuration?.delivery_hub_main_menu_ui || false;
	}

	get enablePlannedRoutes(): boolean {
		return this.configuration?.enable_planned_routes ?? false;
	}

	get merchantCountry(): string {
		return _get(this.configuration, 'country_code', '');
	}

	findApplication = computedFn((uuid: string): Application => {
		return this.configuration.applications.find(a => a.uuid === uuid);
	});

	findApplicationConfiguration = computedFn(
		(uuid: ApplicationUuid): ApplicationMerchantConfigurations | undefined => {
			const app = this.findApplication(uuid);
			if (!app) return;
			return this.configuration.application_merchant_configurations.find(a => a.application_id === app.id);
		}
	);

	addApplication = (application: Bringg.Application) => {
		this.configuration.addApplication(application);
	};

	updateSharingMode = (mode: number) => {
		this.configuration.updateSharingMode(mode);
	};

	fetch = async () => {
		if (this.isFetched) {
			return;
		}

		const config = await getEnv<RootEnv>().dashboardSdk.sdk.merchantConfiguration.get();
		this.storeConfiguration(config);
		this.setFetched();
	};

	forceFetch = async () => {
		const config = await getEnv<RootEnv>().dashboardSdk.sdk.merchantConfiguration.fetchConfiguration();
		this.configuration.set(config);
	};

	fetchRatingConfig = async () => {
		const config = await getEnv<RootEnv>().dashboardSdk.sdk.merchantConfiguration.getRatingReasonConfiguration();

		this.setRatingReasonConfig(config);
	};

	updateRatingConfig = async (ratingConfig: Partial<RatingReasonConfig>) => {
		const response = await getEnv<RootEnv>().dashboardSdk.sdk.merchantConfiguration.setRatingReasonConfiguration({
			...this.ratingConfig,
			...ratingConfig
		});

		this.setRatingReasonConfig(response);
	};

	update = async () => {
		await this.configuration.update();
	};

	fetchTaskCsvFields = async () => {
		if (_isNull(this.taskCsvFields)) {
			const { dashboardSdk } = getEnv<RootEnv>();
			this.taskCsvFields = await dashboardSdk.sdk.merchantConfiguration.getCsvFields();
		}
	};

	setTaskCsvFields = async (fields: Partial<MerchantConfigurationCSVField>[]) => {
		const { dashboardSdk } = getEnv<RootEnv>();
		this.taskCsvFields = await dashboardSdk.sdk.merchantConfiguration.setCsvFields(fields);
	};

	getMerchantTablesConfiguration = () => {
		return _get(this.configuration, ['dashboard_ui_configuration', 'table_configuration'], {});
	};

	getDPCatalogFilters = () => {
		return _get(this.getMerchantTablesConfiguration(), 'deliveryProvidersCatalogFilters', {});
	};

	getTableConfigurationByTableId = (tableId: string) => {
		const tableConfiguration = this.getMerchantTablesConfiguration();

		return _get(tableConfiguration, tableId, {});
	};

	getDispatchPlanningFiltersConfiguration = () => {
		return _get(
			this.configuration,
			['dashboard_ui_configuration', 'table_configuration', 'taskGridDispatchFilter'],
			{}
		);
	};

	updateDPCatalogFilters = async (deliveryProvidersCatalogFilters: Record<string, unknown>) => {
		const { sdk } = getEnv<RootEnv>().dashboardSdk;

		return sdk.merchantConfiguration.dashboardTableConfigurationUpdate({
			...this.getMerchantTablesConfiguration(),
			deliveryProvidersCatalogFilters
		});
	};

	updateFilterBarConfiguration = async (filterObj: Record<string, unknown>, viewName: string) => {
		const { sdk } = getEnv<RootEnv>().dashboardSdk;

		return sdk.merchantConfiguration.dashboardTableConfigurationUpdate({
			...this.getMerchantTablesConfiguration(),
			taskGridDispatchFilter: {
				...this.getDispatchPlanningFiltersConfiguration(),
				[viewName]: filterObj
			}
		});
	};

	getAdminFields = (field: string) => {
		const fields = _get(this.configuration, field, []);
		return Array.isArray(fields) ? fields : [];
	};

	get initialLoadingTimeMinutes(): number {
		return this.configuration.initial_loading_time_minutes;
	}

	get initialUnloadingTimeMinutes(): number {
		return this.configuration.initial_unloading_time_minutes;
	}
}

export default MerchantConfigurationsStore;
