import React from 'react';

import { Droppable, Draggable } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';

import DraggableListItem from 'bringg-web/components/draggable-list-item/draggable-list-item';
import { ListsType } from '../types';
import ColumnTitle from '../column-title/column-title';

import styles from './drag-drop-container.module.scss';

interface Props {
	listId: ListsType;
	title?: string;
	data: { id: number; title: string }[];
	selectedIdsInIndex: { [id: string]: number };
	onSelectCheckbox: (listType: ListsType, id: string, index: number, isSelected: boolean) => void;
	onSelectAll: (listType: ListsType, isChecked: boolean) => void;
}

const DragDropContainer = ({ listId, title, data, selectedIdsInIndex, onSelectCheckbox, onSelectAll }: Props) => {
	const { t } = useTranslation();
	const selectedCount = Object.keys(selectedIdsInIndex).length;

	const onCheckSelectAll = (event: CheckboxChangeEvent) => {
		onSelectAll(listId, event.target.checked);
	};

	return (
		<div className={styles.container} data-test-id={`${listId}-container`}>
			<ColumnTitle
				header={title}
				selectedCount={selectedCount}
				totalCount={data.length}
				isSelectAll={selectedCount > 0 && selectedCount === data.length}
				onSelectAll={onCheckSelectAll}
			/>
			<Droppable droppableId={`${listId}`}>
				{({ innerRef }) => (
					<div ref={innerRef} className={styles.dropContainer}>
						{data.length ? (
							data.map((element, index) => (
								<Draggable key={element.id} draggableId={`${element.id}`} index={index}>
									{({ draggableProps, dragHandleProps: eventHandlers, innerRef }) => (
										<DraggableListItem
											ref={innerRef}
											checked={selectedIdsInIndex[element.id] !== undefined}
											dragDisabled={false}
											id={`${element.id}`}
											title={element.title}
											onSelectCheckbox={event =>
												onSelectCheckbox(listId, event.target.id, index, event.target.checked)
											}
											{...eventHandlers}
											{...draggableProps}
										/>
									)}
								</Draggable>
							))
						) : (
							<div className={styles.emptyColumn}>
								<span className={styles.emptyMessage}>{t('TRANSFER_MODAL.NO_ITEMS')}</span>
							</div>
						)}
					</div>
				)}
			</Droppable>
		</div>
	);
};

export default DragDropContainer;
