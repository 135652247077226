import React, { useCallback, useEffect, useState } from 'react';

import { useObserver } from 'mobx-react';
import _debounce from 'lodash/debounce';
import _includes from 'lodash/includes';
import { withErrorBoundary } from '@bringg-frontend/bringg-web-infra';

import useStores from 'bringg-web/recipes/use-stores';
import SkillsTable from 'bringg-web/features/tables/skills-table/skills-table';
import SkillsPageActions from '../skills-page-actions/skills-page-actions';
import Skill from 'bringg-web/stores/skills/domain-object/skill';

export const getTopSkills = (skills: Skill[], searchTerm: string) => {
	return searchTerm === '' ? skills : skills.filter(skill => _includes(skill.name, searchTerm));
};

const SEARCH_DEBOUNCE_TIME = 200;

const SkillsPage = () => {
	const [searchTerm, setSearchTerm] = useState('');
	const { skillsStore } = useStores();

	useEffect(() => {
		skillsStore.fetchAll();
	}, [skillsStore]);

	const debounceOnSearch = useCallback(
		_debounce((e: React.ChangeEvent<HTMLInputElement>) => setSearchTerm(e.target.value), SEARCH_DEBOUNCE_TIME),
		[]
	);

	const onChange = useCallback(
		e => {
			e.persist();
			debounceOnSearch(e);
		},
		[debounceOnSearch]
	);

	return useObserver(() => {
		return (
			<div className="skills-page">
				<SkillsPageActions onChange={onChange} />
				<SkillsTable skills={getTopSkills(skillsStore.all, searchTerm)} />
			</div>
		);
	});
};

export default withErrorBoundary(SkillsPage);
