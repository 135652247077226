import { RRule, Frequency } from 'rrule';

export const RECURRING_NO_REPEAT_FREQUENCY = -1;

export const DEFAULT_FREQUENCY_OPTIONS = {
	[Frequency.DAILY]: {
		interval: 1
	},
	[Frequency.WEEKLY]: {
		interval: 1,
		byweekday: [RRule.SU, RRule.MO, RRule.TU, RRule.WE, RRule.TH, RRule.FR, RRule.SA]
	},
	[Frequency.MONTHLY]: {
		interval: 1
	}
};

export const defaultFrequencyLabels = {
	[RECURRING_NO_REPEAT_FREQUENCY]: 'NO_REPEAT',
	[Frequency.DAILY]: 'DAILY',
	[Frequency.WEEKLY]: 'WEEKLY',
	[Frequency.MONTHLY]: 'MONTHLY'
};

export const daysMap = new Map([
	[RRule.SU.weekday, RRule.SU],
	[RRule.MO.weekday, RRule.MO],
	[RRule.TU.weekday, RRule.TU],
	[RRule.WE.weekday, RRule.WE],
	[RRule.TH.weekday, RRule.TH],
	[RRule.FR.weekday, RRule.FR],
	[RRule.SA.weekday, RRule.SA]
]);
