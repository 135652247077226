import React, { memo, useCallback, useState, useMemo } from 'react';

import { useTranslation } from 'react-i18next';
import { BringgFontIcons, BringgIcon } from '@bringg/bringg-icons';
import classNames from 'classnames';
import { Button, useForm, Notification, Drawer, Spinner } from '@bringg/react-components';
import type { Nullable, SupportTicket } from '@bringg/types';
import { executeAction } from '@bringg-frontend/global-stores';
import type { UploadChangeParam } from 'antd/lib/upload';
import { HttpStatusCode } from '@bringg/dashboard-sdk/dist/Core/Http/Enums';
import { first } from 'lodash';

import { AttachmentsNamesList } from 'bringg-web/features/submit-ticket/attachments-names-list';
import { isUploadTicketAttachmentError } from 'bringg-web/features/submit-ticket/upload-ticket-attachments';
import { TicketForm } from './ticket-form';
import { TEST_IDS } from './data-test-ids';
import { useStores } from 'bringg-web/recipes';
import { StoreProvider } from './store/helpers/store-context';
import { SupportTicketRoot } from './store/data-provider/support-ticket-root';
import { useSupportTicketStore } from './store/helpers/use-support-ticket-store';
import { SubmittingStatusEnum, SUPPORT_TICKET_ZINDEX, VALID_TICKET_TYPES } from './consts';
import { SubmitConfirmation, SubmittingStatus } from './submit-confirmation/submit-confirmation';

import styles from './submit-ticket.module.scss';

const TITLE_FIELD_NAME = 'title';

interface ModalParams {
	ticketFields?: {
		title: () => string;
		origin: () => string;
		type: () => string;
		description: () => string;
		affectedFunctionality: () => string;
	};
}

interface Props {
	modalParams: ModalParams;
	onClose: () => void;
}

interface SupportTicketForm extends SupportTicket {
	files?: UploadChangeParam;
}

export const SubmitTicket = memo(({ modalParams, onClose }: Props) => {
	const { t } = useTranslation();
	const [form] = useForm<SupportTicketForm>();
	const {
		usersStore: { currentUser },
		authStore: { merchant, crossAppTransport }
	} = useStores();
	const {
		supportTicketStore: { createSupportTicket, uploadAttachments: uploadAttachmentsStoreHandler }
	} = useSupportTicketStore();
	const [isCollapsed, setIsCollapsed] = useState(false);
	const [submittingStatus, setSubmittingStatus] = useState<SubmittingStatus>(SubmittingStatusEnum.NotSubmitted);
	const [ticketInfo, setTicketInfo] = useState<Nullable<{ caseId: string; caseNumber: string }>>(null);
	const [prefilledInputsDisabled, setPrefilledInputsDisabled] = useState(false);

	const userPhone = useMemo(() => {
		return currentUser.phone?.length ? currentUser.phone : merchant.phone ?? '';
	}, [currentUser, merchant]);

	const defaultValues = useMemo<SupportTicket>(() => {
		let logs = [];
		executeAction(crossAppTransport, 'get_stats_logs', clientStateLogs => (logs = clientStateLogs));
		return {
			origin: 'dashboard',
			type: '',
			userEmail: currentUser.email ?? '',
			description: '',
			userName: currentUser.name ?? '',
			[TITLE_FIELD_NAME]: '',
			userPhone,
			priority: '',
			affected_functionality: '',
			expected_behavior: '',
			date: '',
			task_ids: '',
			user_ids: '',
			logs: logs ?? [],
			files: undefined
		};
	}, [currentUser, userPhone, modalParams, crossAppTransport]);

	const initialValues = useMemo<SupportTicketForm>(() => {
		const ticketFields = {
			origin: modalParams?.ticketFields?.origin(),
			type: modalParams?.ticketFields?.type(),
			description: modalParams?.ticketFields?.description(),
			title: modalParams?.ticketFields?.title(),
			affectedFunctionality: modalParams?.ticketFields?.affectedFunctionality()
		};

		if (ticketFields.title && ticketFields.type) {
			setPrefilledInputsDisabled(true);
		}

		return {
			...defaultValues,
			origin: ticketFields.origin ? ticketFields.origin : 'dashboard',
			type: VALID_TICKET_TYPES[ticketFields.type] ? ticketFields.type : defaultValues.type,
			description: ticketFields.description ? ticketFields.description : defaultValues.description,
			[TITLE_FIELD_NAME]: ticketFields.title ? ticketFields.title : defaultValues[TITLE_FIELD_NAME],
			affected_functionality: ticketFields.affectedFunctionality
				? ticketFields.affectedFunctionality
				: defaultValues.affected_functionality
		};
	}, [currentUser, userPhone, modalParams, defaultValues]);

	const changeModalState = useCallback(() => {
		setIsCollapsed(!isCollapsed);
	}, [isCollapsed]);

	const createTicket = useCallback(async ticket => {
		try {
			const response = await createSupportTicket(ticket);

			if (response?.success) {
				const ticketInfo = { caseId: response.case_id, caseNumber: response.case_number };
				setTicketInfo(ticketInfo);

				return ticketInfo;
			} else {
				Notification.error(response?.message ? response.message : t('SUBMIT_TICKET.SUBMIT_ERROR'));
			}
		} catch (error) {
			console.error(error);
			Notification.error(t('SUBMIT_TICKET.SUBMIT_ERROR'));
		}
	}, []);

	const uploadAttachments = useCallback(async (formAttachments: UploadChangeParam, caseId: string) => {
		try {
			const successUploadedFileNames = (
				await uploadAttachmentsStoreHandler(formAttachments.fileList, caseId)
			).reduce<string[]>((acc, uploadTicketAttachmentsResult) => {
				if (isUploadTicketAttachmentError(uploadTicketAttachmentsResult)) {
					console.error(uploadTicketAttachmentsResult.error);
					Notification.error(
						`${t(`NEW_SUBMIT_TICKET.ATTACHMENT_UPLOAD_ERROR`)} "${uploadTicketAttachmentsResult.fileName}"`
					);
					return acc;
				} else if (HttpStatusCode.OK === uploadTicketAttachmentsResult.result.status) {
					return [...acc, uploadTicketAttachmentsResult.fileName];
				} else {
					uploadTicketAttachmentsResult.result.json().then(console.error);
					return acc;
				}
			}, []);

			if (successUploadedFileNames.length) {
				if (successUploadedFileNames.length === 1) {
					Notification.success(
						t('NEW_SUBMIT_TICKET.ATTACHMENT_UPLOAD_SUCCESS'),
						first(successUploadedFileNames)
					);
				} else {
					Notification.success(
						t('NEW_SUBMIT_TICKET.ATTACHMENTS_UPLOAD_SUCCESS'),
						<AttachmentsNamesList attachmentsNames={successUploadedFileNames} />
					);
				}
			}
		} catch (error) {
			console.error(error);
			Notification.error(t('NEW_SUBMIT_TICKET.ALL_ATTACHMENTS_UPLOAD_ERROR'));
		}
	}, []);

	const submit = useCallback(() => {
		if (submittingStatus === SubmittingStatusEnum.NotSubmitted) {
			form.validateFields()
				.then(async () => {
					const formValues: SupportTicketForm = form.getFieldsValue(true);
					setSubmittingStatus(SubmittingStatusEnum.TicketCreateLoading);

					const ticket = await createTicket(formValues);

					if (formValues.files?.fileList?.length) {
						setSubmittingStatus(SubmittingStatusEnum.AttachmentsUploading);
						await uploadAttachments(formValues.files, ticket.caseId);
					}

					setSubmittingStatus(SubmittingStatusEnum.Submitted);
				})
				.catch(error => {
					console.error(error);
				});
		}
	}, [form, submittingStatus]);

	const resetState = useCallback(() => {
		form.resetFields();
		form.setFieldsValue(defaultValues);
		setTicketInfo(null);
		setPrefilledInputsDisabled(false);
		setSubmittingStatus(SubmittingStatusEnum.NotSubmitted);
	}, [form, defaultValues]);

	return (
		<Drawer
			open
			destroyOnClose
			placement="right"
			className={classNames(styles.submitTicketDrawer, { [styles.collapsed]: isCollapsed })}
			mask={false}
			closable={false}
			footer={null}
			// this component should be over ALL POSSIBLE current and new components
			zIndex={SUPPORT_TICKET_ZINDEX}
			width={454}
		>
			<div className={styles.submitTicket}>
				<div className={styles.modalContainer} data-test-id="modal-container">
					<div className={styles.modalHeader}>
						<div className={styles.title}>
							<div className={styles.text}>
								<p>{t('NEW_SUBMIT_TICKET.TITLE')}</p>
								<span className={styles.helpBadge}>{t('NEW_SUBMIT_TICKET.HELP')}</span>
							</div>
							{isCollapsed ? null : (
								<a
									className="color-like-a-link"
									href="https://bringg.my.site.com/supportcenter"
									target="_blank"
									rel="noopener noreferrer"
								>
									{t('NEW_SUBMIT_TICKET.HELP_PORTAL')}
								</a>
							)}
						</div>
						<div className={styles.headerAction}>
							<Button
								type="link"
								className={styles.headerButton}
								onClick={changeModalState}
								data-test-id={TEST_IDS.changeModalAppearance}
							>
								<BringgIcon
									iconName={isCollapsed ? BringgFontIcons.Maximize : BringgFontIcons.Minimize}
								/>
							</Button>
							<Button
								type="link"
								className={styles.headerButton}
								onClick={onClose}
								data-test-id={TEST_IDS.closeModal}
							>
								<BringgIcon iconName={BringgFontIcons.Close} />
							</Button>
						</div>
					</div>

					{isCollapsed ? null : (
						<div className={styles.modalBody} data-test-id={TEST_IDS.modalBody}>
							{submittingStatus === SubmittingStatusEnum.TicketCreateLoading ? (
								<div className={styles.loaderContainer}>
									<Spinner size="large" />
								</div>
							) : null}
							<div className={styles.ticketFormContainer}>
								{ticketInfo ? (
									<SubmitConfirmation
										title={form.getFieldValue(TITLE_FIELD_NAME)}
										ticketId={ticketInfo.caseId}
										ticketNumber={ticketInfo.caseNumber}
										submittingStatus={submittingStatus}
									/>
								) : (
									<TicketForm
										form={form}
										initialValues={initialValues}
										prefilledInputsDisabled={prefilledInputsDisabled}
									/>
								)}
							</div>
							<div className={styles.footer}>
								{submittingStatus === SubmittingStatusEnum.Submitted ? (
									<>
										<Button
											type="link"
											onClick={resetState}
											data-test-id={TEST_IDS.resetTicketForm}
										>
											{t('NEW_SUBMIT_TICKET.NEW_TICKET')}
										</Button>
										<Button
											type="primary"
											onClick={onClose}
											data-test-id={TEST_IDS.closeTicketFormFooterButton}
										>
											{t('NEW_SUBMIT_TICKET.CLOSE')}
										</Button>
									</>
								) : (
									<>
										<Button
											type="primary"
											onClick={submit}
											data-test-id={TEST_IDS.submitTicketForm}
											disabled={
												submittingStatus === SubmittingStatusEnum.TicketCreateLoading ||
												submittingStatus === SubmittingStatusEnum.AttachmentsUploading
											}
										>
											{t('NEW_SUBMIT_TICKET.SUBMIT')}
										</Button>
									</>
								)}
							</div>
						</div>
					)}
				</div>
			</div>
		</Drawer>
	);
});

export const SubmitTicketWithRoot = (props: Props) => {
	const supportTicketRootStore = useMemo(() => SupportTicketRoot.createRoot(), []);

	return (
		<StoreProvider value={supportTicketRootStore}>
			<SubmitTicket {...props} />
		</StoreProvider>
	);
};
