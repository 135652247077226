import React from 'react';

import { BringgFontIcons, BringgIcon } from '@bringg/bringg-icons';
import { Modal, Space } from '@bringg/react-components';

import css from './dialog-box.module.scss';

type DialogBoxProps = {
	title: string;
	subtitle?: string;
	open: boolean;
	onOk: any;
	onCancel?: any;
	okText?: string;
	cancelText?: string;
	okButtonProps?: any;
	children?: React.ReactNode;
	destroyOnClose?: boolean;
	danger?: boolean;
	warning?: boolean;
};

const DialogBox = ({
	title,
	subtitle,
	open,
	onOk,
	onCancel,
	okText,
	cancelText,
	okButtonProps,
	children,
	destroyOnClose,
	danger,
	warning
}: DialogBoxProps) => {
	// global vars will be used
	let color = '#3091CE';
	// TODO: override via css module! It uses wrong color vars at the moment
	if (warning) {
		color = 'var(--warning-800)';
	}

	if (danger) {
		okButtonProps.danger = danger;
		color = 'var(--danger-600)';
	}

	return (
		<Modal
			destroyOnClose={destroyOnClose}
			okButtonProps={okButtonProps}
			className={css.overrides}
			open={open}
			onOk={onOk}
			onCancel={onCancel}
			okText={okText}
			cancelText={cancelText}
			transitionName=""
			maskTransitionName=""
		>
			<Space style={{ alignItems: 'start' }}>
				<div>
					<BringgIcon iconName={BringgFontIcons.InfoFilled} style={{ fontSize: '24px', color: color }} />
				</div>
				<Space direction="vertical" size="small">
					<div className={css.title}>{title}</div>
					{subtitle && <div className={css.subtitle}>{subtitle}</div>}
					{children && <div className={css.content}>{children}</div>}
				</Space>
			</Space>
		</Modal>
	);
};

export default DialogBox;
