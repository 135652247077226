import React, { createContext, useContext, useEffect, useRef } from 'react';

import { useNotification, Notification, Button } from '@bringg/react-components';
import { BringgFontIcons, BringgIcon } from '@bringg/bringg-icons';

import { Failure, Translation } from './types';
import Content from './content';

import style from './toast.module.scss';

interface Props {
	id: string;
	translations: Translation;
	totalNumberOfActions: number;
	currentNumberOfActionsSucceeded: number;

	/**
	 * This will only close after everything finished successfully
	 * @default 5000
	 */
	closeAfterXMs?: number;

	failures: Failure[];

	/**
	 * If this is true then the failure reason column will be hidden, otherwise, the failures reason column must exist
	 */
	hideFailureReasonColumn?: boolean;

	onClose?: () => void;
	onMaximize?: () => void;
	className?: string;

	errorsFileRender?: React.ReactNode;
	failureRenderer?: (failure: Failure) => React.ReactNode;
}
const PropsContext = createContext<Omit<React.ComponentProps<typeof Content>, 'className'> | undefined>(undefined);

const RenderContentFromContext = () => {
	// the value is not undefined as we set it in the toast component
	// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
	const props = useContext(PropsContext)!;

	return (
		<Content
			className={style.body}
			translations={props.translations}
			failures={props.failures}
			failureRenderer={props.failureRenderer}
			errorsFileRender={props.errorsFileRender}
			hideFailureReasonColumn={props.hideFailureReasonColumn}
			totalNumberOfActions={props.totalNumberOfActions}
			currentNumberOfActionsSucceeded={props.currentNumberOfActionsSucceeded}
		/>
	);
};

const Toast = ({ id, closeAfterXMs = 5000, onClose, onMaximize, className, ...rest }: Props) => {
	const [api, contextHolder] = useNotification();
	const closeTimeoutRef = useRef<ReturnType<typeof setTimeout>>();
	const onCloseCb = useRef(onClose);

	useEffect(() => {
		api.open({
			key: id,
			closeIcon: onMaximize ? <></> : undefined,
			className,
			message: onMaximize ? (
				<div className={style.title}>
					{rest.translations.title}
					<div>
						<Button
							type="ghost"
							icon={
								<BringgIcon
									data-test-id="resource-async-operation-maximize"
									iconName={BringgFontIcons.Maximize}
								/>
							}
							onClick={() => {
								onCloseCb.current = undefined;
								Notification.close(id);
								onMaximize();
							}}
						/>
						<Button
							data-test-id="resource-async-operation-close"
							type="ghost"
							icon={<BringgIcon iconName={BringgFontIcons.Close} />}
							onClick={onClose}
						/>
					</div>
				</div>
			) : (
				rest.translations.title
			),
			description: <RenderContentFromContext />,
			placement: 'bottomRight',
			onClose: () => {
				closeTimeoutRef.current && clearTimeout(closeTimeoutRef.current);
				onCloseCb.current?.();
				onCloseCb.current = undefined;
			},
			duration: null
		});

		return () => {
			Notification.close(id);
			onCloseCb.current?.();
			onCloseCb.current = undefined;
		};
	}, []);

	useEffect(() => {
		if (rest.currentNumberOfActionsSucceeded !== rest.totalNumberOfActions) {
			return;
		}

		closeTimeoutRef.current && clearTimeout(closeTimeoutRef.current);

		closeTimeoutRef.current = setTimeout(() => {
			Notification.close(id);

			onCloseCb.current?.();
			onCloseCb.current = undefined;
		}, closeAfterXMs);

		return () => {
			closeTimeoutRef.current && clearTimeout(closeTimeoutRef.current);
		};
	}, [rest, closeAfterXMs, rest.currentNumberOfActionsSucceeded, rest.totalNumberOfActions, rest.failures, id]);

	// Wrap context with props as the notification does not get updates once rendered
	return (
		<PropsContext.Provider key={id} value={rest}>
			{contextHolder}
		</PropsContext.Provider>
	);
};

export default Toast;
