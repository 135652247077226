import type { Timeline as VisTimeline } from 'vis-timeline/types/entry-standalone';

import type { Timeline } from './timeline';

export interface GanttPublicApi {
	api: VisTimeline;
}

export const getGanttPublicApi = (visTimelineApi: Timeline): GanttPublicApi => {
	return {
		api: visTimelineApi.timeline
	};
};
