import { isNil, get } from 'lodash';
import { CustomAttributeDataType, type CustomAttributeRes } from '@bringg/types';

export const getEnumDefinitionItem = (enumDefinition: CustomAttributeRes['enum_definition'], value: unknown) => {
	if (!Array.isArray(enumDefinition)) {
		return null;
	}
	return enumDefinition.find(item => item.value === value);
};

const getEnumDisplayValue = (attribute: CustomAttributeRes, value: unknown) => {
	const { enum_definition, id } = attribute;
	const enumItem = getEnumDefinitionItem(enum_definition, value);

	if (!enumItem) {
		console.warn(`Custom Attribute ${id} doesn't have Enum Item for ${value}`);
		return value;
	}

	return enumItem.name;
};

export const getDisplayValue = (attribute: CustomAttributeRes, value: unknown) => {
	if (isNil(value)) {
		return value;
	}

	if (attribute.data_type === CustomAttributeDataType.Enum) {
		return getEnumDisplayValue(attribute, value);
	}

	return value;
};

export const extractValueFromExtras = (attribute: CustomAttributeRes, extras: Record<string, unknown>) => {
	return get(extras, attribute.path);
};
