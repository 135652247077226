import { getRootEnv } from '@bringg-frontend/bringg-web-infra';
import { action, computed, observable, makeObservable } from 'mobx';
import { getEnv } from 'mobx-easy';
import { ExclusionWindow } from '@bringg/types';
import {
	isNil as _isNil,
	reject as _reject,
	filter as _filter,
	isEmpty as _isEmpty,
	isNumber as _isNumber
} from 'lodash';
import { ExclusionWindowRequest } from '@bringg/dashboard-sdk/dist/ExclusionWindow/ExclusionWindow.consts';
import { RootEnv } from '@bringg-frontend/bringg-web-infra';

import BaseDomainStore from '../core/base-domain-store';

class ExclusionWindowsStore extends BaseDomainStore<ExclusionWindow> {
	exclusionWindowsByTeam: Map<number, ExclusionWindow[]> = new Map();
	isMerchantExclusionsFetched = false;

	setByTeam = (teamId: number, exclusionWindows: ExclusionWindow[]) => {
		this.exclusionWindowsByTeam.set(teamId, exclusionWindows);
	};

	constructor() {
		super();

		makeObservable(this, {
			exclusionWindowsByTeam: observable.shallow,
			setByTeam: action
		});
	}

	getAll(teamId?: number): ExclusionWindow[] {
		const teamExclusions = this.exclusionWindowsByTeam.get(teamId);

		if (!_isEmpty(teamExclusions)) {
			return teamExclusions;
		}

		return computed(() => _filter(this.all, { team_id: null })).get();
	}

	async fetch(teamId?: number) {
		const response = await getRootEnv().dashboardSdk.sdk.exclusionWindows.getAll(teamId);

		response.exclusion_windows.forEach(this.set);

		if (teamId) {
			this.setByTeam(teamId, response.exclusion_windows);
		}
	}

	async getOrFetch(teamId?: number): Promise<ExclusionWindow[]> {
		if (teamId) {
			if (!this.exclusionWindowsByTeam.has(teamId)) {
				await this.fetch(teamId);
			}
		} else if (!this.isMerchantExclusionsFetched) {
			await this.fetch();
			this.isMerchantExclusionsFetched = true;
		}

		return this.getAll(teamId);
	}

	async create(exclusionWindow: ExclusionWindowRequest) {
		const response = await getRootEnv().dashboardSdk.sdk.exclusionWindows.create(exclusionWindow);

		this.set(response.exclusion_window);

		if (!_isNumber(exclusionWindow.team_id)) {
			return;
		}

		this.setByTeam(exclusionWindow.team_id, [
			...(this.exclusionWindowsByTeam.get(exclusionWindow.team_id) || []),
			response.exclusion_window
		]);
	}

	async update(id: number, exclusionWindow: ExclusionWindowRequest) {
		const response = await getRootEnv().dashboardSdk.sdk.exclusionWindows.update(id, exclusionWindow);

		this.set(response.exclusion_window);

		if (!_isNumber(exclusionWindow.team_id)) {
			return;
		}

		this.setByTeam(exclusionWindow.team_id, [
			..._reject(this.exclusionWindowsByTeam.get(exclusionWindow.team_id) || [], { id }),
			response.exclusion_window
		]);
	}

	async delete(id: number, teamId?: number) {
		await getRootEnv().dashboardSdk.sdk.exclusionWindows.delete(id);

		this.remove(id);

		if (!_isNumber(teamId)) {
			return;
		}

		this.setByTeam(teamId, _reject(this.exclusionWindowsByTeam.get(teamId) || [], { id }));
	}

	async createOrUpdate(exclusionWindow: ExclusionWindowRequest, id?: number) {
		if (_isNil(id)) {
			return this.create(exclusionWindow);
		}

		this.update(id, exclusionWindow);

		return undefined;
	}

	async copyFromMerchant(teamId: number, id?: number): Promise<ExclusionWindow[]> {
		const copiedExclusionWindows = await getRootEnv().dashboardSdk.sdk.exclusionWindows.copyFromMerchant(
			teamId,
			id
		);

		this.setByTeam(teamId, copiedExclusionWindows);

		return copiedExclusionWindows;
	}
}

export default ExclusionWindowsStore;
