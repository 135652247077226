import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
	LegendOption,
	transformDefaultState,
	transformDriver,
	transformOrderStatus,
	transformStopNumber,
	transformTag
} from './utils/legend-option-handler';
import { OptionWrapper } from '@bringg/react-components/dist/components/select/select';
import { Select } from '@bringg/react-components/dist/components';
import DispatchMapMarkersView from '../dispatch-map-markers-view';
import MapLegendInformation, { Icon } from './map-legend-information/map-legend-information';
import { commonColors, getMapLegendTranslations, getTaskStatusColors } from '../utils/utils';
import { useTranslation } from 'react-i18next';
import { Tag, Task } from '@bringg/types';
import { keyBy as _keyBy, chain as _chain, isNil as _isNil } from 'lodash';
import Driver from '../../../stores/drivers/domain-object/driver';
import SpinnerWrapper from '@bringg/react-components/dist/components/spinner/spinner';

export interface Translations {
	default: string;
	orderStatus: string;
	driver: string;
	stopNumber: string;
	tag: string;
}

interface MapboxLegendProps {
	dispatchMapMarkersView: DispatchMapMarkersView;
	tasks: Task[];
	drivers: Driver[];
	tags: Tag[];
	isLoading?: boolean;
}

const MapLegend: React.FC<MapboxLegendProps> = ({
	dispatchMapMarkersView,
	tasks,
	drivers,
	tags,
	isLoading = false
}) => {
	const [selectOptions, setSelectOptions] = useState<OptionWrapper[]>([]);
	const [legendIcons, setLegendIcons] = useState<Icon[]>([]);
	const { t } = useTranslation();
	const translations = useMemo(
		() => getMapLegendTranslations(t),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	);
	const taskStatusColors = useMemo(
		() => getTaskStatusColors(t),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	);
	const groupedTasksByTags = useMemo(
		() =>
			_chain(tasks)
				.filter(task => !_isNil(task.tag_id))
				.groupBy('tag_id')
				.value(),
		[tasks]
	);

	const getTaskStatusIcons = useCallback((): Icon[] => {
		return Object.values(taskStatusColors).map(
			taskStatus => ({ title: taskStatus.name, color: taskStatus.color } as Icon)
		);
	}, [taskStatusColors]);

	const getDriversIcons = useCallback((): Icon[] => {
		return drivers.map((driver, index) => ({ title: driver.name, color: commonColors[index] } as Icon));

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const getStopNumbersIcons = useCallback((): Icon[] => {
		return [
			{ title: t('DISPATCH_LIST.FIRST_STOP'), color: commonColors[0] },
			{ title: t('DISPATCH_LIST.SECOND_STOP'), color: commonColors[1] },
			{ title: t('DISPATCH_LIST.THIRD_STOP'), color: commonColors[2] },
			{ title: t('DISPATCH_LIST.FOURTH_STOP'), color: commonColors[3] },
			{ title: t('DISPATCH_LIST.FIFTH_STOP'), color: commonColors[4] },
			{ title: t('DISPATCH_LIST.SIXTH_STOP'), color: commonColors[5] },
			{ title: t('DISPATCH_LIST.SEVENTH_STOP'), color: commonColors[6] },
			{ title: t('DISPATCH_LIST.EIGHTH_STOP'), color: commonColors[7] },
			{ title: t('DISPATCH_LIST.NINTH_STOP'), color: commonColors[8] },
			{ title: t('DISPATCH_LIST.TENTH_STOP'), color: commonColors[9] }
		];

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const tagsById = useMemo(() => _keyBy(tags, 'id'), [tags]);

	const getTagsIcons = useCallback(
		(groupedTasksByTags): Icon[] => {
			return Object.keys(groupedTasksByTags).map((tagId, index) => {
				const tag = tagsById[tagId];
				return { title: tag.tag, color: commonColors[index] } as Icon;
			});
		},

		// eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	);

	const onOptionSelected = useCallback(
		(value: LegendOption) => {
			setLegendIcons([]);
			transformDefaultState(dispatchMapMarkersView);

			switch (value) {
				case LegendOption.ORDER_STATUS: {
					setLegendIcons(getTaskStatusIcons());
					transformOrderStatus(dispatchMapMarkersView, tasks, taskStatusColors);
					break;
				}

				case LegendOption.DRIVER: {
					setLegendIcons(getDriversIcons());
					transformDriver(dispatchMapMarkersView, drivers, commonColors);
					break;
				}

				case LegendOption.STOP_NUMBER: {
					setLegendIcons(getStopNumbersIcons());
					transformStopNumber(dispatchMapMarkersView, tasks, commonColors);
					break;
				}

				case LegendOption.TAG: {
					setLegendIcons(getTagsIcons(groupedTasksByTags));
					transformTag(dispatchMapMarkersView, groupedTasksByTags, commonColors);
					break;
				}
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	);

	useEffect(() => {
		if (!translations) {
			return;
		}

		setSelectOptions([
			{
				id: LegendOption.DEFAULT,
				name: translations.default
			},
			{
				id: LegendOption.ORDER_STATUS,
				name: translations.orderStatus
			},
			{
				id: LegendOption.DRIVER,
				name: translations.driver
			},
			{
				id: LegendOption.STOP_NUMBER,
				name: translations.stopNumber
			},
			{
				id: LegendOption.TAG,
				name: translations.tag
			}
		]);
	}, [translations]);

	return (
		<div className="mapbox-legend-container">
			{isLoading ? (
				<SpinnerWrapper />
			) : (
				<>
					<Select
						className="map-legend-select"
						onSelect={onOptionSelected}
						placeholder={translations.default}
						options={selectOptions}
						getPopupContainer={(node: HTMLElement) => node.parentNode as HTMLElement}
					></Select>

					<MapLegendInformation icons={legendIcons} />
				</>
			)}
		</div>
	);
};

export default MapLegend;
