import React from 'react';
import Checkbox from '@bringg/react-components/dist/components/checkbox/checkbox';
import Input from '@bringg/react-components/dist/components/floating-placeholder-input/input';
import { Divider } from 'antd';
import Row from '@bringg/react-components/dist/components/row/row';
import Col from '@bringg/react-components/dist/components/col/col';
import Select from '@bringg/react-components/dist/components/select/select';
import Translate from '../../../translation/translator';

const STARS = [
	{ id: 1, name: '1 Star' },
	{ id: 2, name: '2 Stars' },
	{ id: 3, name: '3 Stars' },
	{ id: 4, name: '4 Stars' },
	{ id: 5, name: '5 Stars' }
];

interface Props {
	showPostRating: boolean;
	numberOfStars: number;
	positiveTitle: string;
	negativeTittle: string;
	onChange: <T>(paramName: string, value: T) => void;
}

const PostRating = ({ showPostRating, numberOfStars, positiveTitle, negativeTittle, onChange }: Props) => {
	return (
		<div className="post-rating">
			<Divider>
				<h3>
					<Translate text="RATING_CONFIGURATION.POST_RATING_QUESTION" />
				</h3>
			</Divider>
			<Row className="customer-rating-field">
				<Col xs={20}>
					<Translate text="RATING_CONFIGURATION.SHOW_POST_RATING_REASON_SCREEN" />
				</Col>
				<Col xs={4}>
					<Checkbox checked={showPostRating} onChange={e => onChange('showPostRating', e.target.checked)} />
				</Col>
			</Row>
			<Row className="post-rating-stars customer-rating-field">
				<Col xs={20}>
					<Translate text="RATING_CONFIGURATION.SHOW_WHEN_RATING_ABOVE" />
				</Col>
				<Col xs={4}>
					<Select
						disabled={showPostRating === false}
						className="select-max-width"
						placeholder="Select stars"
						value={numberOfStars}
						onChange={value => onChange('numberOfStars', value)}
						options={STARS}
					/>
				</Col>
			</Row>
			<Row className="post-rating-title customer-rating-field">
				<Col xs={24}>
					<Input
						isDisabled={showPostRating === false}
						placeholder={<Translate text="RATING_CONFIGURATION.POSITIVE_TITLE" />}
						value={positiveTitle}
						onChange={e => onChange('positiveTitle', e.target.value)}
					/>
				</Col>
			</Row>
			<Row className="post-rating-title">
				<Col xs={24}>
					<Input
						isDisabled={showPostRating === false}
						placeholder={<Translate text="RATING_CONFIGURATION.NEGATIVE_TITLE" />}
						value={negativeTittle}
						onChange={e => onChange('negativeTittle', e.target.value)}
					/>
				</Col>
			</Row>
		</div>
	);
};

export default PostRating;
