import _has from 'lodash/has';

export const teamHasFeatureFlag = (team: Bringg.Team, featureFlag: string): boolean => {
	return team?.feature_flags?.[featureFlag];
};

export const hasFeatureFlag = (currentUser: Bringg.User, featureFlag: string, team?: Bringg.Team) => {
	const featureFlagPresent = currentUser && _has(currentUser.feature_flags, featureFlag);

	if (featureFlagPresent) {
		return currentUser.feature_flags[featureFlag];
	}

	if (team) {
		return teamHasFeatureFlag(team, featureFlag);
	}

	return featureFlagPresent;
};
