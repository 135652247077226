import React from 'react';
import { Checkbox } from '@bringg/react-components';
import styles from './column-title.module.scss';

type CheckboxChangeEvent = Parameters<React.ComponentProps<typeof Checkbox>['onChange']>[0];
interface Props {
	header: string;
	selectedCount: number;
	totalCount: number;
	isSelectAll: boolean;
	onSelectAll: (e: CheckboxChangeEvent) => void;
}

const ColumnTitle = ({ header, selectedCount, totalCount, isSelectAll, onSelectAll }: Props) => {
	return (
		<div className={styles.container}>
			<Checkbox data-test-id={`select-all-${header}`} checked={isSelectAll} onChange={onSelectAll}>
				<span>{header}</span>
				<span className={styles.selectedCount}>
					{selectedCount} / {totalCount}
				</span>
			</Checkbox>
		</div>
	);
};

export default ColumnTitle;
