import { TFunction } from 'i18next';
import {
	RelativeCutoff,
	RelativeOptions
} from 'bringg-web/features/planned-delivery-windows/modals/slots-modal/wizard-steps/configuration-step/time-slots/cutoff-radio-group/cutoff.types';
import { Duration } from 'bringg-web/features/planned-delivery-windows/modals/slots-modal/planned-delivery-windows-slots.consts';

export const getDaysOptions = (t: TFunction) =>
	Array.from({ length: 8 }).map((item, index) => ({
		id: index,
		name: `${index} ${t('PLANNED_DELIVERY_WINDOWS_MODAL_CUTOFF_TIME.DAYS')}`
	}));

export const getHoursOptions = (t: TFunction, relativeCutoff: RelativeCutoff, timeDuration: Duration) => {
	const sevenDays = 7;
	const thirteenHours = 13;
	const twentyFiveHours = 25;

	let length = relativeCutoff.days === sevenDays ? thirteenHours : twentyFiveHours;
	if (relativeCutoff.relativeOption === RelativeOptions.AFTER) {
		length = timeDuration.hours + 1;
	}

	return Array.from({ length }).map((item, index) => ({
		id: index,
		name: `${index} ${t('PLANNED_DELIVERY_WINDOWS_MODAL_CUTOFF_TIME.HOUR_SHORT')}`
	}));
};

export const getMinutesOptions = (t: TFunction, relativeCutoff: RelativeCutoff, timeDuration: Duration) => {
	const length =
		relativeCutoff.relativeOption === RelativeOptions.AFTER && relativeCutoff.hours === timeDuration.hours
			? timeDuration.minutes + 1
			: 61;
	return Array.from({ length }).map((item, index) => ({
		id: index,
		name: `${index} ${t('PLANNED_DELIVERY_WINDOWS_MODAL_CUTOFF_TIME.MINUTE_SHORT')}`
	}));
};

export const getRelativeOptions = (t: TFunction) =>
	Object.keys(RelativeOptions).map(relativeOption => ({
		id: relativeOption,
		name: t(`PLANNED_DELIVERY_WINDOWS.RELATIVE_CUTOFF.${relativeOption.toUpperCase()}`)
	}));
