import React, { memo } from 'react';

import { Empty } from '@bringg/react-components';
import { useTranslation } from 'react-i18next';

import { TestIds } from 'bringg-web/consts';

const NoDataMessage = () => {
	const { t } = useTranslation();

	return (
		<div className="no-data-message" data-test-id={TestIds.NO_DATA_MESSAGE}>
			<Empty description={t('EXCEPTIONS_LIST.NO_DATA_AVAILABLE')} />
		</div>
	);
};

export default memo(NoDataMessage);
