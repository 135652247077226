import { useCallback, useMemo, useState } from 'react';

import { DropdownMenu, Modal, DateRangePicker, AdjustPopoverOverflow, TreeSelect } from '@bringg/react-components';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import { Draggable, Droppable, DragDropContext } from 'react-beautiful-dnd';
import moment, { Moment } from 'moment';
import { cloneDeep, orderBy } from 'lodash';
import { BringgFontIcons, BringgIcon } from '@bringg/bringg-icons';
import { TeamServiceArea } from '@bringg/types';
import { datetime, Nullable } from '@bringg/types/types/common';

import { formatCardTime } from 'bringg-web/features/service-area/utils/format-service-area-time';
import { useStores } from 'bringg-web/recipes';
import { useTeamsTreeData } from 'bringg-web/hooks';
import IconButton from 'bringg-web/components/icon-button/icon-button';

import './teams-section.scss';

const MODAL_WIDTH = 734;

function truncateString(str, maxLength) {
	if (str.length > maxLength) {
		return str.slice(0, maxLength) + '...';
	}
	return str;
}

type TimeRange = [Nullable<datetime>, Nullable<datetime>];

export function ServiceAreaTeamList({ teamsMap, assignedTeams = [], onChange }) {
	const { t } = useTranslation();
	const [editTeamRange, setEditTeamRange] = useState<TeamServiceArea>(null);
	const [timeRange, setTimeRange] = useState<TimeRange>([null, null]);

	const handleDragEnd = useCallback(
		result => {
			if (!result.destination) {
				return;
			}

			const { source, destination } = result;
			const newTeams = cloneDeep(assignedTeams);
			const [removed] = newTeams.splice(source.index, 1);
			newTeams.splice(destination.index, 0, removed);
			newTeams.forEach((team, index) => {
				team.priority = index + 1;
			});

			onChange(newTeams);
		},
		[assignedTeams, onChange]
	);

	const onDateUpdate = (values?: Moment[]) => {
		const range: TimeRange = [null, null];

		if (values) {
			range[0] = values[0] ? values[0].startOf('day').toISOString() : null;
			range[1] = values[1] ? values[1].endOf('day').toISOString() : null;
		}

		return setTimeRange(range);
	};

	const onDelete = assignedTeam => onChange(assignedTeams.filter(assigned => assigned !== assignedTeam));

	const getDropdownItems = assignedTeam => [
		{
			key: 0,
			label: (
				<div data-test-id="edit-time-range" className="service-area-team-section-menu-item">
					<BringgIcon iconName={BringgFontIcons.Calendar} />
					{t('SERVICE_AREA.EDIT_TIME_RANGE')}
				</div>
			),
			onClick: () => {
				setEditTeamRange(assignedTeam);
				setTimeRange([assignedTeam.effective_start_time, assignedTeam.effective_end_time]);
			}
		},
		{
			key: 1,
			label: (
				<div data-test-id="go-to-team-page" className="service-area-team-section-menu-item">
					<BringgIcon iconName={BringgFontIcons.Forward} />
					{t('SERVICE_AREA.GO_TO_TEAM_PAGE')}
				</div>
			),
			onClick: () => window.open(`/#/drivers/teams/${assignedTeam.team_id}/info`, '_blank', 'noopener noreferrer')
		},
		{
			key: 2,
			label: (
				<div data-test-id="remove-team" className="service-area-team-section-menu-item">
					<BringgIcon iconName={BringgFontIcons.Trash} />
					{t('SERVICE_AREA.REMOVE_TEAM')}
				</div>
			),
			onClick: () => onDelete(assignedTeam)
		}
	];

	const onOk = useCallback(() => {
		if (editTeamRange.effective_start_time === timeRange[0] && editTeamRange.effective_end_time === timeRange[1]) {
			return onCancel();
		}

		editTeamRange.effective_start_time = timeRange[0];
		editTeamRange.effective_end_time = timeRange[1];

		setTimeRange([null, null]);

		onChange(cloneDeep(assignedTeams));
		setEditTeamRange(null);
	}, [assignedTeams, onChange, timeRange, editTeamRange]);

	const onCancel = useCallback(() => {
		setEditTeamRange(null);
		setTimeRange([null, null]);
	}, [setEditTeamRange, setTimeRange]);

	const rangeValues = useMemo(
		() =>
			[editTeamRange?.effective_start_time, editTeamRange?.effective_end_time].map(value =>
				value ? moment(value) : null
			),
		[editTeamRange]
	) as [Moment, Moment];

	return (
		<DragDropContext onDragEnd={handleDragEnd}>
			{editTeamRange && (
				<Modal
					width={MODAL_WIDTH}
					data-test-id="edit-time-range-modal"
					className="service-area-teams-section-modal"
					open
					onOk={onOk}
					onCancel={onCancel}
					transitionName=""
					maskTransitionName=""
				>
					<h2 className="range-header">{t('SERVICE_AREA.TIME_RANGE')}</h2>
					<div className="range-description">
						{t('SERVICE_AREA.TIME_RANGE_DESCRIPTION', {
							name: truncateString(teamsMap[editTeamRange.team_id]?.name, 50)
						})}
					</div>
					<DateRangePicker
						picker="date"
						dropdownClassName="service-area-teams-section-range-picker"
						translations={{
							fromDate: t('SERVICE_AREA.START_DATE'),
							toDate: t('SERVICE_AREA.END_DATE')
						}}
						open
						startDate={rangeValues[0]}
						endDate={rangeValues[1]}
						allowEmpty={[true, true]}
						onChange={onDateUpdate}
					/>
				</Modal>
			)}
			<Droppable droppableId="teams">
				{dropProvided => (
					<div
						data-test-id="team-section-cards"
						className="team-section-cards"
						{...dropProvided.droppableProps}
						ref={dropProvided.innerRef}
					>
						{assignedTeams.map((team, index) => (
							<Draggable key={team.team_id} draggableId={team.team_id.toString()} index={index}>
								{dragProvided => (
									<div
										className="team-section-card"
										{...dragProvided.draggableProps}
										ref={dragProvided.innerRef}
									>
										<div
											className="centered-container team-section-draggable"
											{...dragProvided.dragHandleProps}
										>
											<BringgIcon iconName={BringgFontIcons.Drag} />
										</div>
										<div className="team-section-main">
											<h2 className="team-section-header">{teamsMap[team.team_id]?.name}</h2>
											<div className="team-section-info">
												{teamsMap[team.team_id]?.external_id && (
													<div className="team-section-badge">
														ID {teamsMap[team.team_id].external_id}
													</div>
												)}
												{formatCardTime(team.effective_start_time, team.effective_end_time) && (
													<div className="team-section-badge">
														{formatCardTime(
															team.effective_start_time,
															team.effective_end_time
														)}
													</div>
												)}
											</div>
										</div>
										<DropdownMenu
											adjustOverflow={AdjustPopoverOverflow.DO_NOT_ADJUST}
											destroyOnHide
											items={getDropdownItems(team)}
											placement="bottom-end"
											trigger={['click']}
										>
											<div className="centered-container">
												<IconButton
													data-test-id="team-section-menu-icon"
													icon={BringgFontIcons.Menu}
												/>
											</div>
										</DropdownMenu>
									</div>
								)}
							</Draggable>
						))}
						{dropProvided.placeholder}
					</div>
				)}
			</Droppable>
		</DragDropContext>
	);
}

export type Props = {
	value?: Partial<TeamServiceArea>[];
	onChange?: (teams: Partial<TeamServiceArea>[]) => void;
};

export const ServiceAreaTeamsSection = observer(({ value = [], onChange }: Props) => {
	const { t } = useTranslation();
	const { teamsStore } = useStores();
	const teams = useTeamsTreeData(teamsStore.all);

	const teamsMap = useMemo(
		() => teamsStore.all.reduce((acc, team) => ({ ...acc, [team.id]: team }), {}),
		[teamsStore.all]
	);

	const onTeamSelected = useCallback(
		teamIds => {
			const selectedTeams = teamsStore.all.filter(team => teamIds.includes(team.id));
			const maxPriority = Math.max(...value.map(assignedTeam => assignedTeam.priority).filter(Number.isInteger)); // could be -Infinity
			const nextPriority = Number.isFinite(maxPriority) ? maxPriority + 1 : 1;

			onChange(
				orderBy(
					selectedTeams.map(team => {
						const assigned = value.find(assignedTeam => assignedTeam.team_id === team.id) || null;

						return {
							// default values
							team_id: team.id,
							priority: nextPriority,
							effective_start_time: new Date().toISOString(),
							// put existing values if assigned exists
							...assigned
						};
					}),
					'priority'
				)
			);
		},
		[teamsStore.all, value, onChange]
	);

	return (
		<div className="teams-section">
			<h2 className="header-info">{t('SERVICE_AREA.TEAMS')}</h2>
			<div className="header-description">{t('SERVICE_AREA.TEAMS_DESCRIPTION')}</div>
			<TreeSelect
				getPopupContainer={trigger => trigger.parentNode}
				allowSelectAll
				selectAllText={t('MULTI_SELECT.SELECT_ALL')}
				placeholder={t('SERVICE_AREA.ADD_TEAM')}
				treeData={teams}
				onChange={onTeamSelected}
				value={value?.map(t => t.team_id).sort() || []}
			/>

			<ServiceAreaTeamList teamsMap={teamsMap} assignedTeams={value} onChange={onChange} />
		</div>
	);
});
