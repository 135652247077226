import React from 'react';
import { Table } from '@bringg/react-components';
import ShiftPart from './shift-part/shift-part';
import { Schedule } from '../../../team-page-logic/team-info-types';
import { UseFormMethods } from 'react-hook-form';
import { getShiftCollection } from './shift-table-logic/shift-table-logic';
import { days } from '../../../team-page-logic/shifts-logic/shifts-logic';
import { useTranslation } from 'react-i18next';
import { useIsAdmin } from '../../../../../utils/privileges';
import { useStores } from '../../../../../recipes';

export const shiftParts = ['firstShift', 'secondShift', 'break'];

const getColumns = t => {
	return [
		{
			title: '',
			dataIndex: 'day',
			width: '150px'
		},
		{
			title: t('TEAM.FIRST_SHIFT'),
			dataIndex: 'firstShift',
			render: ShiftPart
		},
		{
			title: t('TEAM.SECOND_SHIFT'),
			dataIndex: 'secondShift',
			render: ShiftPart
		},
		{
			title: t('TEAM.BREAK'),
			dataIndex: 'break',
			render: ShiftPart
		}
	];
};

interface Props {
	form: UseFormMethods;
	schedule: Schedule;
}

const ShiftTable: React.FC<Props> = ({ form, schedule }: Props) => {
	const { t } = useTranslation();
	const isDisabled = !useIsAdmin();
	const { usersStore } = useStores();

	const dataSource = getShiftCollection(form, days(usersStore.currentUserLanguage), schedule, shiftParts, isDisabled);
	return <Table columns={getColumns(t)} rowKey="day" dataSource={dataSource} className="override-antd-table" />;
};

export default ShiftTable;
