import React, { memo } from 'react';
import { Button } from '@bringg/react-components';
import { useTranslation } from 'react-i18next';

interface Props {
	onAdd: () => void;
}

function AddTemplateButton({ onAdd }: Props) {
	const { t } = useTranslation();

	return (
		<Button type="primary" onClick={onAdd}>
			{t('EMAIL_TEMPLATES.ADD_TEMPLATE')}
		</Button>
	);
}

export default memo(AddTemplateButton);
