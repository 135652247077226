import { getRoot, wrapRoot } from 'mobx-easy';

import { SupportTicketRootStore } from '../support-ticket-root-store';

export const SUPPORT_TICKET_WRAPPER = 'support-ticket';

const getStore = () => getRoot<SupportTicketRootStore>(SUPPORT_TICKET_WRAPPER);

const createRoot = () => {
	try {
		return getRoot<SupportTicketRootStore>(SUPPORT_TICKET_WRAPPER);
	} catch (error) {
		return wrapRoot({ wrapperName: SUPPORT_TICKET_WRAPPER, env: {}, RootStore: SupportTicketRootStore });
	}
};

export const SupportTicketRoot = { createRoot, getStore };
