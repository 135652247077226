import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Input, Row } from '@bringg/react-components';
import {
	QuestionData as QuestionDataEntity,
	ActionData,
	AnswerData as AnswerDataEntity
} from '@bringg/types/types/action_data';
import Translate from '../../../translation/translator';
import AnswerData from '../answer-data/answer-data';

interface Props {
	onFieldUpdate: (actionData: Partial<ActionData>) => void;
	actionsData: ActionData;
}

class QuestionData extends Component<Props> {
	updateAnswer = (index: number, answer: Partial<AnswerDataEntity>) => {
		const data = this.getQuestionData();
		Object.assign(data.answers[index], answer);

		this.props.onFieldUpdate({ data });
	};

	deleteAnswer = (index: number) => {
		const data = this.getQuestionData();
		data.answers.splice(index, 1);

		this.props.onFieldUpdate({ data });
	};

	addAnswer = () => {
		const data = this.getQuestionData();
		data.answers.push({ text: '' });

		this.props.onFieldUpdate({ data });
	};

	updateQuestion = event => {
		const data = this.getQuestionData();
		data.question = event.target.value;

		this.props.onFieldUpdate({ data });
	};

	getQuestionData = (): QuestionDataEntity => {
		const defaultQuestionData = { answers: [] };
		return Object.assign(defaultQuestionData, this.props.actionsData.data || {});
	};

	render() {
		const { actionsData } = this.props;

		return (
			<div className="question-data">
				<Row>
					<div>
						<Translate text="ACTION_CONFIGURATION.QUESTION" />
					</div>
					<Input
						type="text"
						onChange={this.updateQuestion}
						value={this.getQuestionData().question}
						className="action-data-input question-input"
						data-test-id="action-data-question"
						placeholder=""
					/>
				</Row>

				{this.getQuestionData().answers.map((answer, index) => (
					<AnswerData
						// eslint-disable-next-line react/no-array-index-key
						key={index}
						answer={answer}
						updateAnswer={answer => this.updateAnswer(index, answer)}
						deleteAnswer={() => this.deleteAnswer(index)}
						parentQuestionId={actionsData.id}
					/>
				))}

				<Row className="add-answer" onClick={this.addAnswer} data-test-id="action-data-add-answer">
					<Translate text="ACTION_CONFIGURATION.ADD_ANSWER" />
				</Row>
			</div>
		);
	}
}

export default observer(QuestionData);
