import { computed, makeObservable } from 'mobx';
import { getEnv, getRoot } from 'mobx-easy';
import _isObject from 'lodash/isObject';
import _keyBy from 'lodash/keyBy';

import { RootStore } from '../root-store-types';
import { RootEnv } from '../root-env';
import { getSharedRootStore } from '../root-store';

class ApplicationStore {
	constructor() {
		makeObservable(this, {
			installedApplications: computed
		});
	}

	setApplication = (application: Bringg.Application) => {
		const { merchantConfigurationsStore } = getSharedRootStore().data;
		merchantConfigurationsStore.addApplication(application);
	};

	get installedApplications(): Map<Bringg.ApplicationUuid, Bringg.Application> {
		const { merchantConfigurationsStore } = getSharedRootStore().data;
		const applicationsByUuid = _keyBy(merchantConfigurationsStore.configuration.applications, 'uuid');
		return new Map<Bringg.ApplicationUuid, Bringg.Application>(Object.entries(applicationsByUuid) as []);
	}

	getApplication = (appUuid: Bringg.ApplicationUuid): Bringg.Application => {
		return this.installedApplications.get(appUuid);
	};

	isApplicationInstalled = (appUuid: Bringg.ApplicationUuid): boolean => {
		return _isObject(this.getApplication(appUuid));
	};

	async installApplication(appUuid: Bringg.ApplicationUuid): Promise<void> {
		const { dashboardSdk } = getEnv<RootEnv>();
		const application = await dashboardSdk.sdk.applicationMerchantConfiguration.addApplicationByUuid(appUuid);
		this.setApplication(application);
	}
}

export default ApplicationStore;
