import { makeObservable, observable, computed } from 'mobx';
import i18next from 'i18next';
import { GenericAction, SharedLocationActionMetadata, UpdateAction } from '@bringg/types';

import { ActionsRepo, TriggerStore, workflowsRootStore } from './internal';
import { PlaceholdersData } from '../components/automation-text-template/automation-text-template';
import { ActionFamilyType, GenericActionData, UpdateFieldActionData } from '../utils/types';
import { getRandomGuid } from '../utils/helpers';

abstract class ActionBaseStore {
	guid: number;
	id: number;
	type: ActionFamilyType;
	actionsRepo: ActionsRepo;
	triggerStore: TriggerStore;

	protected constructor(actionsRepo: ActionsRepo) {
		makeObservable(this, {
			id: observable,
			actionData: computed,
			placeholdersByFact: computed,
			updateItemsByFact: computed,
			genericItemsByFact: computed,
			placeholdersSet: computed
		});

		this.guid = getRandomGuid();
		this.actionsRepo = actionsRepo;
		this.triggerStore = actionsRepo.triggerStore;
	}

	get actionData() {
		const { metadataRepo } = workflowsRootStore.getStore();
		return metadataRepo.metadata.getActionMetaData(this.type);
	}

	get genericItemsByFact(): GenericActionData[] {
		const { metadataRepo } = workflowsRootStore.getStore();
		const actionData = metadataRepo.metadata.getActionMetaData(this.type);

		return (Object.values((actionData as GenericAction).itemsByFact).flat() || []).map(data => ({
			...data,
			display_path: i18next.t(data.translation_string),
			icon: data.icon
		}));
	}

	get updateItemsByFact(): UpdateFieldActionData[] {
		const { metadataRepo } = workflowsRootStore.getStore();
		const actionData = metadataRepo.metadata.getActionMetaData(this.type);

		return (Object.values((actionData as UpdateAction).itemsByFact).flat() || []).map(data => ({
			...data,
			display_path: i18next.t(data.title)
		}));
	}

	get placeholdersByFact(): PlaceholdersData {
		const { metadataRepo } = workflowsRootStore.getStore();
		const actionData = metadataRepo.metadata.getActionMetaData(this.type);

		return (Object.values((actionData as SharedLocationActionMetadata)?.placeholdersByFact).flat() || []).map(
			({ info_translation_string, type, values }) => ({
				type,
				info: i18next.t(info_translation_string),
				values: values
					? values.map(({ info_translation_string, type }) => ({
							type,
							info: i18next.t(info_translation_string)
					  }))
					: undefined
			})
		);
	}

	get placeholdersSet(): Set<string> {
		return new Set(
			this.placeholdersByFact
				.map(({ type, values }) => {
					// If we have value, don't use the type
					if (values) {
						return values.filter(({ disabled }) => !disabled).map(({ type }) => type);
					}
					return type;
				})
				.flat(Infinity) as string[]
		);
	}

	get canComplete() {
		return true;
	}
}

export default ActionBaseStore;
