import moment from 'moment';
import { DeliveryBlockTemplate } from '@bringg/types/types/delivery_block';
import rechargeValidator from 'bringg-web/features/delivery-blocks-v2/validators/recharge-validator';
import { breakValidator } from 'bringg-web/features/delivery-blocks-v2/validators/break-validator';

const validate = (
	deliveryBlockTemplate: DeliveryBlockTemplate,
	timezone: string,
	allowMultiDayOptimization: boolean,
	onUpdateBreak,
	rechargeDuration?: number
) => {
	const { name } = deliveryBlockTemplate;
	if (!name) {
		return false;
	}

	const deliveryBlockTemplateTimes = {
		startTime: deliveryBlockTemplate.start_time,
		endTime: deliveryBlockTemplate.end_time
	};

	const breaks = deliveryBlockTemplate.template_breaks;

	if (
		breaks.some((breakItem, index) =>
			breakValidator.isBreakInvalid(
				breakItem,
				index,
				breaks,
				allowMultiDayOptimization,
				deliveryBlockTemplateTimes,
				timezone,
				onUpdateBreak
			)
		)
	) {
		return false;
	}

	const recharges = deliveryBlockTemplate.template_recharges;

	return !recharges.some(
		rechargeItem =>
			!rechargeValidator().isRechargeStartTimeValid(
				moment(rechargeItem.start_time).tz(timezone),
				deliveryBlockTemplateTimes,
				rechargeDuration,
				recharges,
				breaks,
				timezone
			).result
	);
};

export const templateValidator = { validate };
