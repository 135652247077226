import React from 'react';
import { Tooltip, Button } from '@bringg/react-components';
import i18next from 'i18next';
import { DeliveryBlockModalViewMode } from './delivery-block-modal';
import { Translate } from '../../../translation';

interface ModalDeleteButtonProps {
	onClick(): void;
	isDeletable: boolean;
	isEditable: boolean;
}

const ModalDeleteButton = (props: ModalDeleteButtonProps) => {
	const { onClick, isDeletable, isEditable } = props;
	if (isDeletable && isEditable) {
		return (
			<Button onClick={onClick} danger className="delete-button">
				<Translate text="DELIVERY_BLOCK.MODAL.DELETE" />
			</Button>
		);
	}
	return (
		<Tooltip
			key="Delete-Tooltip"
			title={i18next.t('DELIVERY_BLOCKS.CAN_NOT_DELETE_WITH_DRIVERS')}
			placement="topLeft"
		>
			<Button key="Delete" onClick={onClick} danger type="primary" className="delete-button" disabled>
				<Translate text="DELIVERY_BLOCK.MODAL.DELETE" />
			</Button>
		</Tooltip>
	);
};

const getDeliveryBlockModalFooter = (
	viewMode: DeliveryBlockModalViewMode,
	onEdit: () => void,
	onEditCancel: () => void,
	onDone: () => void,
	onCancel: () => void,
	onDelete: () => Promise<void>,
	isLoading = false,
	isDeletable = false,
	isEditable = true
) => {
	switch (viewMode) {
		case DeliveryBlockModalViewMode.VIEW:
			return [
				<ModalDeleteButton key="Delete" onClick={onDelete} isDeletable={isDeletable} isEditable={isEditable} />,
				<Button
					key="Edit"
					onClick={onEdit}
					type="primary"
					disabled={!isEditable}
					className="delivery-block-edit-button"
				>
					<Translate text="DELIVERY_BLOCK.MODAL.EDIT" />
				</Button>
			];

		case DeliveryBlockModalViewMode.EDIT:
			return [
				<Button key="Cancel" onClick={onEditCancel}>
					<Translate text="DELIVERY_BLOCK.MODAL.CANCEL" />
				</Button>,
				<Button key="Save" onClick={onDone} type="primary" loading={isLoading}>
					<Translate text="DELIVERY_BLOCK.MODAL.SAVE" />
				</Button>
			];

		case DeliveryBlockModalViewMode.CREATE:
			return [
				<Button key="Cancel" onClick={onCancel}>
					<Translate text="DELIVERY_BLOCK.MODAL.CANCEL" />
				</Button>,
				<Button key="Create" onClick={onDone} type="primary" loading={isLoading}>
					<Translate text="DELIVERY_BLOCK.MODAL.CREATE" />
				</Button>
			];
		default:
			return null;
	}
};

export default getDeliveryBlockModalFooter;
