import React, { useState, useContext } from 'react';

import { TFunction, useTranslation } from 'react-i18next';
import classnames from 'classnames';
import { kebabCase } from 'lodash';
import { InputNumber } from '@bringg/react-components';
import { BringgFontIcons, BringgIcon } from '@bringg/bringg-icons';

import InputLabelWithTooltip from './../input-label-with-tooltip/input-label-with-tooltip';
import { OptimizationSettingsFormContext } from '../optimization-configuration-form/optimization-configuration-form';
import { HandleChange, OptimizationNumberAttribute, OptimizationSettingsFormContextType } from '../../types';

import './input.scss';

const validate =
	(
		setStatus: React.Dispatch<React.SetStateAction<'' | 'error' | undefined>>,
		onChange: HandleChange,
		numberAttribute: OptimizationNumberAttribute,
		t: TFunction
	) =>
	(value: number) => {
		const {
			constraints: { min = -Number.MAX_SAFE_INTEGER, max = Number.MAX_SAFE_INTEGER, required },
			id
		} = numberAttribute;

		if (value <= max && value >= min) {
			setStatus('');
			onChange(id, { value, isValid: true, validationErrorMsg: '' });
			return;
		}

		if (value === null) {
			if (required) {
				setStatus('error');
				onChange(id, {
					value,
					isValid: false,
					validationErrorMsg: t('OPTIMIZATION_CONFIGURATIONS.REQUIRED_VALUE_ERROR_MESSAGE')
				});
			} else {
				onChange(id, {
					value,
					isValid: true
				});
			}
			return;
		}

		setStatus('error');
		onChange(id, {
			value,
			isValid: false,
			validationErrorMsg: `${t('OPTIMIZATION_CONFIGURATIONS.NUMBER_NOT_IN_RANGE')}: ${min} - ${max}`
		});
	};

const NumberInput = (props: OptimizationNumberAttribute) => {
	const {
		title,
		value = '',
		isValid,
		id,
		tooltipTitle,
		validationErrorMsg,
		isSubField,
		disabled,
		containerId
	} = props;
	const [status, setStatus] = useState<'' | 'error'>(isValid ? '' : 'error');
	const { handleChange } = useContext<OptimizationSettingsFormContextType>(OptimizationSettingsFormContext);
	const { t } = useTranslation();

	return (
		<div
			className={classnames('route-optimization-input-container', 'route-optimization-field-container', {
				'sub-field': isSubField
			})}
		>
			<InputLabelWithTooltip
				id={id}
				title={t(title)}
				tooltip={tooltipTitle ? t(tooltipTitle) : null}
				containerId={containerId}
			/>
			<InputNumber
				className="route-optimization-input"
				id={id}
				status={status}
				value={value}
				placeholder={value}
				onChange={nextValue => validate(setStatus, handleChange, props, t)(nextValue as number)}
				data-test-id={kebabCase(tooltipTitle)}
				disabled={disabled}
			/>
			{status === 'error' && (
				<span className="route-optimization-input-error-text">
					<BringgIcon className="route-optimization-input-error-icon" iconName={BringgFontIcons.Warning} />
					{validationErrorMsg}
				</span>
			)}
		</div>
	);
};

export default NumberInput;
