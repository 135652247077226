import React, { useContext } from 'react';

import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Radio } from '@bringg/react-components';

import { OptimizationSettingsFormContext } from '../optimization-configuration-form/optimization-configuration-form';
import InputLabelWithTooltip from './../input-label-with-tooltip/input-label-with-tooltip';
import { OptimizationSettingsFormContextType, OptimizationToggleAttribute } from '../../types';

import './toggle-input.scss';

const TwoStateToggleBtn = (props: OptimizationToggleAttribute) => {
	const { title, left, right, id, tooltipTitle, value, isSubField, disabled, containerId } = props;
	const { handleChange } = useContext<OptimizationSettingsFormContextType>(OptimizationSettingsFormContext);
	const { t } = useTranslation();

	return (
		<div
			className={classnames('two-state-toggle', 'route-optimization-field-container', {
				'sub-field': isSubField
			})}
		>
			<InputLabelWithTooltip
				id={id}
				title={t(title)}
				tooltip={tooltipTitle ? t(tooltipTitle) : null}
				containerId={containerId}
			/>
			<Radio.Group
				className="two-state-toggle-radio-group"
				value={value}
				onChange={event => handleChange(id, { value: event.target?.value, isValid: true })}
			>
				<Radio.Button key={left.id} value={left.value} disabled={disabled}>
					{t(left.title)}
				</Radio.Button>
				<Radio.Button key={right.id} value={right.value} disabled={disabled}>
					{t(right.title)}
				</Radio.Button>
			</Radio.Group>
		</div>
	);
};

export default TwoStateToggleBtn;
