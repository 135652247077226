import React from 'react';

import { Button } from '@bringg/react-components';

import styles from './header.module.scss';

interface Props {
	title: string;
	onDefault: () => void;
	defaultText: string;
}

const Header = ({ title, onDefault, defaultText }: Props) => {
	return (
		<div className={styles.header} data-test-id="draggable-list-header">
			<span className={styles.title}>{title}</span>
			<Button
				className={styles.defaultButton}
				type="link"
				onClick={onDefault}
				data-test-id="draggable-list-selector-default-button"
			>
				{defaultText}
			</Button>
		</div>
	);
};

export default Header;
