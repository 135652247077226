import { getRootEnv } from '@bringg-frontend/bringg-web-infra';
import { FleetType } from '@bringg/types';

export const isExternalFleet = (fleetId: number, fleetType?: number) => {
	if (fleetType) {
		return fleetType !== FleetType.Internal;
	}

	const { dashboardSdk } = getRootEnv();
	const { internal_fleet_id } = dashboardSdk.sdk.merchant.getLocal();

	// when entity don't have fleet_id we consider it as it assigned to an internal fleet
	return fleetId && internal_fleet_id !== fleetId;
};

export const isInternalFleet = (fleetId: number, fleetType?: number) => !isExternalFleet(fleetId, fleetType);

export const hasInternalFleet = () => {
	const { dashboardSdk } = getRootEnv();
	const { internal_fleet_id } = dashboardSdk.sdk.merchant.getLocal();
	return !!internal_fleet_id;
};
