import React from 'react';

import { Button, Collapse } from '@bringg/react-components';
import { BringgFontIcons, BringgIcon } from '@bringg/bringg-icons';
import { DeliveryBlockRecharge, DeliveryBlockResourceLocationType } from '@bringg/types';
import { useTranslation } from 'react-i18next';
import moment, { Moment } from 'moment-timezone';
import classNames from 'classnames';

import RechargeItem from 'bringg-web/features/delivery-blocks-v2/new-modal/recharge/recharge-item/recharge-item';
import { DeliveryBlockBreakBase } from 'bringg-web/features/delivery-blocks-v2/new-modal/breaks/break-types';
import rechargeValidator from 'bringg-web/features/delivery-blocks-v2/validators/recharge-validator';

import './recharge-panel.scss';

const { Panel } = Collapse;

export interface Props {
	key: string | number;
	deliveryBlockRecharges: DeliveryBlockRecharge[];
	deliveryBlockBreaks: DeliveryBlockBreakBase[];
	rechargeDuration: number;
	timezone: string;
	use12Hours: boolean;
	deliveryBlockTimes: { startTime: string; endTime: string };
	format: string;
	onChangeRecharge: (recharges: DeliveryBlockRecharge[]) => void;
	onAddRecharge: (rechargeItem: DeliveryBlockRecharge) => void;
	shouldBeDisabled?: boolean;
}

const MAX_RELOADS_LENGTH = 5;

export const RechargesPanel = ({
	deliveryBlockRecharges,
	deliveryBlockBreaks,
	rechargeDuration,
	use12Hours,
	timezone,
	deliveryBlockTimes,
	onChangeRecharge,
	onAddRecharge,
	shouldBeDisabled,
	...props
}: Props) => {
	const { t } = useTranslation();

	const getHeader = () => {
		return (
			<div data-test-id="recharge-panel-title" className="panel-title">
				<BringgIcon iconName={BringgFontIcons.Cube} className="panel-title-icon" />
				<span className="panel-title-translate">{t('DELIVERY_BLOCK_MODAL.RELOAD.RELOAD_TIME')}</span>
			</div>
		);
	};

	const addRecharge = () => {
		if (!isAddRechargeEnabled) {
			return;
		}

		const startTime =
			deliveryBlockRecharges.length === 0
				? deliveryBlockTimes.startTime
				: moment(deliveryBlockRecharges[deliveryBlockRecharges.length - 1].start_time)
						.tz(timezone)
						.add(rechargeDuration + 1, 'minutes')
						.toISOString();

		const rechargeItem: DeliveryBlockRecharge = {
			start_time: startTime,
			location_type: DeliveryBlockResourceLocationType.TEAM_ADDRESS
		};
		onAddRecharge(rechargeItem);
	};

	const onRechargeStartTimeChange = (time: Moment, index: number) => {
		const blockTimeStartDate = moment(deliveryBlockTimes.startTime).tz(timezone).date();
		const startTime = time.set({ date: blockTimeStartDate });

		deliveryBlockRecharges[index].start_time = startTime.tz(timezone).toISOString();
		onChangeRecharge([...deliveryBlockRecharges]);
	};

	const onRechargeRemove = (index: number) => {
		deliveryBlockRecharges.splice(index, 1);
		onChangeRecharge([...deliveryBlockRecharges]);
	};

	const isAddRechargeEnabled = deliveryBlockRecharges.length < MAX_RELOADS_LENGTH;

	const getAddButton = () => {
		return (
			<Button
				type="link"
				className={classNames('add-recharge-button', { 'add-recharge-button-disabled': !isAddRechargeEnabled })}
				onClick={addRecharge}
				disabled={!isAddRechargeEnabled}
			>
				<BringgIcon iconName={BringgFontIcons.Plus} />
				{t('DELIVERY_BLOCK_MODAL.RELOAD.ADD_RELOAD')}
			</Button>
		);
	};

	return (
		<Panel
			{...props}
			header={getHeader()}
			className="delivery-block-panel recharges-panel disable-when-select-is-open"
		>
			<div
				className={classNames('delivery-block-recharge-panel-container', { disabled: shouldBeDisabled })}
				data-test-id="delivery-block-recharge-panel-container"
			>
				{deliveryBlockRecharges.map((deliveryBlockRecharge: DeliveryBlockRecharge, index) => (
					<RechargeItem
						key={index.toString()}
						deliveryBlockRecharge={deliveryBlockRecharge}
						rechargeDuration={rechargeDuration}
						onStartTimeChange={moment => onRechargeStartTimeChange(moment, index)}
						onRemove={() => onRechargeRemove(index)}
						timezone={timezone}
						use12Hours={use12Hours}
						isStartTimeValid={rechargeValidator().isRechargeStartTimeValid(
							moment(deliveryBlockRecharge.start_time).tz(timezone),
							deliveryBlockTimes,
							rechargeDuration,
							deliveryBlockRecharges,
							deliveryBlockBreaks,
							timezone
						)}
					/>
				))}
				{!shouldBeDisabled && getAddButton()}
			</div>
		</Panel>
	);
};
