import React from 'react';

const FieldTitle = ({ fieldName }: { fieldName: string }) => {
	return (
		<div className="changes-diff-changed-field-name">
			<span>&quot;</span>
			{fieldName}
			<span>&quot;</span>
		</div>
	);
};

export default FieldTitle;
