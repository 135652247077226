import React from 'react';

import moment, { Moment } from 'moment';
import { useTranslation } from 'react-i18next';
import { Button, DatePicker, TimeRangePicker, DateRangePicker } from '@bringg/react-components';

import useTimeFormat from 'bringg-web/hooks/use-time-format';
import useDateTimeFormat from 'bringg-web/hooks/use-date-time-format';

import './optimization-time-frame.scss';

export const MAX_OPTIMIZATION_DAYS = 7;
interface OptimizationTimeFrameProps {
	isMultiDayOptimization: boolean;
	startTime: Moment;
	endTime: Moment;
	onDateSelectionChange: (dates: Moment[]) => void;
	onShowResources: () => void;
	disabled: boolean;
	isShowResourcesButtonDisabled: boolean;
}

const OptimizationTimeFrame = ({
	isMultiDayOptimization,
	startTime,
	endTime,
	onDateSelectionChange,
	onShowResources,
	disabled,
	isShowResourcesButtonDisabled
}: OptimizationTimeFrameProps) => {
	const { t } = useTranslation();
	const hourFormat = useTimeFormat();
	const dateTimeFormat = useDateTimeFormat();

	const onSingleDayOptimizationChange = (newTime: Moment[] | Moment) => {
		let updateStartTime;
		let updateEndTime;
		if (Array.isArray(newTime)) {
			const startHour = newTime[0].hour();
			const startMinutes = newTime[0].minutes();
			const endHour = newTime[1].hour();
			const endMinutes = newTime[1].minutes();
			updateStartTime = startTime.set({ hour: startHour, minute: startMinutes });
			updateEndTime = endTime.set({ hour: endHour, minute: endMinutes });
		} else {
			const year = newTime.get('year');
			const month = newTime.get('month');
			const date = newTime.get('date');
			updateStartTime = startTime.set({ year, month, date });
			updateEndTime = endTime.set({ year, month, date });
		}
		onDateSelectionChange([updateStartTime, updateEndTime]);
	};

	const onDateChange = (updateTime: Moment[]) => {
		const optimizationDurationDays = moment.duration(moment(updateTime[1]).diff(updateTime[0])).asDays();
		if (optimizationDurationDays > MAX_OPTIMIZATION_DAYS) {
			const updateEndTime = updateTime[0].clone().add(MAX_OPTIMIZATION_DAYS - 1, 'days');
			updateTime[1] = updateEndTime;
		}

		onDateSelectionChange(updateTime);
	};

	const disabledDate = current => {
		return current > startTime.clone().add(MAX_OPTIMIZATION_DAYS, 'day').utc().endOf('day');
	};

	return (
		<div className="optimization-time-frame" data-test-id="optimization-time-frame">
			<div className="time-frame-left-side">
				{isMultiDayOptimization ? (
					<DateRangePicker
						allowClear={false}
						startDate={startTime}
						endDate={endTime}
						translations={{ fromDate: '', toDate: '' }}
						onChange={onDateChange}
						hourFormat={hourFormat}
						disabledDate={disabledDate}
						showTime
						format={dateTimeFormat}
						data-test-id="date-range-picker"
						disabled={disabled}
					/>
				) : (
					<div className="single-day-time-selection">
						<DatePicker
							allowClear={false}
							onChange={onSingleDayOptimizationChange}
							value={startTime}
							data-test-id="single-date-picker"
							disabled={disabled}
						/>
						<TimeRangePicker
							allowClear={false}
							value={[startTime, endTime]}
							format={hourFormat}
							onChange={onSingleDayOptimizationChange}
							data-test-id="time-range-picker"
							disabled={disabled}
						/>
					</div>
				)}
				<div className="time-frame-time-zone">
					{t('OPTIMIZATION_MODAL.TIME_ZONE')}: {moment.tz.guess()}
				</div>
			</div>

			<Button
				data-test-id="get-resources-button"
				className="show-resources-button"
				onClick={onShowResources}
				disabled={isShowResourcesButtonDisabled}
			>
				{t('OPTIMIZATION_MODAL.SHOW_RESOURCES')}
			</Button>
		</div>
	);
};

export default OptimizationTimeFrame;
