import { PreferredMethod } from '@bringg/types/types/fleet_router_rules';
import _isNil from 'lodash/isNil';
import _isEmpty from 'lodash/isEmpty';
import {
	PriorityConfigurationType,
	RuleType
} from '@bringg/react-components/dist/features/fleet-router/rule-form/rule-form';
import _partition from 'lodash/partition';
import { SelectedTeams } from '@bringg/react-components/dist/features/fleet-router/rule-form/teams-section/teams-section';
import {
	FleetGroup,
	Rule as ServerRule,
	RuleTeams as ServerRuleTeams
} from '@bringg/dashboard-sdk/dist/FleetRouter/types';
import { FallbackMethod } from '@bringg/react-components/dist/features/fleet-router/rule-form/priority-configuration/fallback/use-fallback';
import { EarlyEtaStrategy } from '@bringg/types';

import { Rule } from './fleets-configurations-store';

// delete when featureFlag pickup_eta_available (pickupEtaAvailable) is removed
export const formatRuleToServer = (rule: Rule): ServerRule =>
	({
		name: rule.name,
		fleet_router_fleets_groups: _formatFleetGroupsToServer(
			rule.ruleType,
			rule.priorityConfiguration,
			rule.fleetsRatio
		),
		rule_teams: _formatTeamsToServer(rule.isMerchantLevel, rule.selectedTeams),
		enabled: rule.isEnabled,
		priority: rule.priority
	} as ServerRule);

// delete formatRuleToServer fn when featureFlag pickup_eta_available (pickupEtaAvailable) is removed
export const formatRuleToServerWithEarlyEtaStrategy = (rule: Rule): ServerRule =>
	({
		name: rule.name,
		fleet_router_fleets_groups: _formatFleetGroupsToServerWithFastestType(
			rule.ruleType,
			rule.priorityConfiguration,
			rule.fleetsRatio,
			rule.earlyEtaStrategy
		),
		rule_teams: _formatTeamsToServer(rule.isMerchantLevel, rule.selectedTeams),
		enabled: rule.isEnabled,
		priority: rule.priority
	} as ServerRule);

// delete when featureFlag pickup_eta_available (pickupEtaAvailable) is removed
export const formatRuleFromServer = (rule: ServerRule): Rule => {
	const ruleFleetsGroups = rule.fleet_router_fleets_groups;

	const result = {
		id: rule.fleet_router_rules_group_id,
		name: rule.name,
		isEnabled: rule.enabled,
		rule_id: rule.rule_id,
		priority: rule.priority,
		fleetsRatio: ruleFleetsGroups[0]?.fleets_ratio
			? _mapFleetsRatioFromServer(ruleFleetsGroups[0]?.fleets_ratio)
			: [],
		..._formatTeamsFromServer(rule.rule_teams),
		ruleType: !_isEmpty(ruleFleetsGroups) ? _formatRuleTypeFromServer(ruleFleetsGroups[0]) : undefined
	} as Rule;

	if ([RuleType.priority, RuleType.ratio].includes(result.ruleType)) {
		result.priorityConfiguration = ruleFleetsGroups
			? _formatPriorityConfigurationFromServer(ruleFleetsGroups)
			: undefined;
	}

	return result;
};

// when featureFlag pickup_eta_available (pickupEtaAvailable) is removed, delete formatRuleFromServer fn
export const formatRuleFromServerWithEarlyEtaStrategy = (rule: ServerRule): Rule => {
	const ruleFleetsGroups = rule.fleet_router_fleets_groups;

	const result = {
		id: rule.fleet_router_rules_group_id,
		name: rule.name,
		isEnabled: rule.enabled,
		rule_id: rule.rule_id,
		priority: rule.priority,
		fleetsRatio: ruleFleetsGroups[0]?.fleets_ratio
			? _mapFleetsRatioFromServer(ruleFleetsGroups[0]?.fleets_ratio)
			: [],
		..._formatTeamsFromServer(rule.rule_teams),
		ruleType: !_isEmpty(ruleFleetsGroups) ? _formatRuleTypeFromServer(ruleFleetsGroups[0]) : undefined,
		earlyEtaStrategy: ruleFleetsGroups[0]?.early_eta_strategy
	} as Rule;

	if ([RuleType.priority, RuleType.ratio, RuleType.fast].includes(result.ruleType)) {
		result.priorityConfiguration = ruleFleetsGroups
			? _formatPriorityConfigurationFromServer(ruleFleetsGroups)
			: undefined;
	}

	return result;
};

interface RuleTeams {
	isMerchantLevel: boolean;
	selectedTeams: SelectedTeams;
}

interface FleetRatioMap {
	[key: string]: number;
}

export const _mapFleetsRatioFromServer = (fleetsRatio): FleetRatioMap =>
	fleetsRatio.reduce((result, fleetRatio) => {
		result[fleetRatio.fleet_id] = fleetRatio.ratio;
		return result;
	}, {});

const _formatTeamsFromServer = (serverRuleTeams: ServerRuleTeams): RuleTeams => {
	const ruleTeams = {
		isMerchantLevel: serverRuleTeams.merchant_level,
		selectedTeams: {}
	} as RuleTeams;

	if (!ruleTeams.isMerchantLevel) {
		// @ts-ignore
		ruleTeams.selectedTeams = serverRuleTeams.team_ids.reduce((result, teamId) => {
			result[teamId] = true;
			return result;
		}, {});
	}

	return ruleTeams;
};

const _formatRuleTypeFromServer = (fleetGroup: FleetGroup): RuleType => {
	switch (fleetGroup.preferred_method) {
		case PreferredMethod.Cheapest:
			return RuleType.cheap;
		case PreferredMethod.Fastest:
			return RuleType.fast;
		case PreferredMethod.Priority:
			return RuleType.priority;
		case PreferredMethod.Ratio:
			return RuleType.ratio;
	}
};

const _formatPriorityConfigurationFromServer = (fleetGroups: FleetGroup[]): PriorityConfigurationType => {
	const firstGroup = fleetGroups[0];
	const secondGroup = fleetGroups[1];
	//@ts-ignore
	return {
		selectedFleetIds: firstGroup?.fleet_ids?.map(String),
		isFallbackMethodEnabled: !_isNil(secondGroup),
		...(!_isNil(secondGroup) && { fallbackMethod: _formatRuleTypeFromServer(secondGroup) })
	};
};

const _formatTeamsToServer = (isMerchantLevel: boolean, selectedTeams: SelectedTeams): ServerRuleTeams => {
	if (isMerchantLevel) {
		return {
			merchant_level: true
		};
	}

	const [added, removed] = _partition(Object.keys(selectedTeams), teamId => selectedTeams[teamId]);

	return {
		merchant_level: false,
		team_ids: {
			added,
			removed
		}
	};
};

// delete when featureFlag pickup_eta_available (pickupEtaAvailable) is removed
const _formatFleetGroupsToServer = (
	ruleType: RuleType,
	priorityConfiguration?: PriorityConfigurationType,
	fleetsRatio?: FleetRatioMap
) => {
	const groups = _getFleetGroupsToServer(ruleType, priorityConfiguration?.selectedFleetIds, fleetsRatio) || [];

	if (priorityConfiguration?.isFallbackMethodEnabled) {
		// @ts-ignore
		groups.push(
			..._getFleetGroupsToServer(
				priorityConfiguration?.fallbackMethod,
				priorityConfiguration?.selectedFleetIds,
				fleetsRatio
			)
		);
	}
	return groups;
};

// delete _formatFleetGroupsToServer when featureFlag pickup_eta_available (pickupEtaAvailable) is removed
const _formatFleetGroupsToServerWithFastestType = (
	ruleType: RuleType,
	priorityConfiguration?: PriorityConfigurationType,
	fleetsRatio?: FleetRatioMap,
	earlyEtaStrategy?: EarlyEtaStrategy
) => {
	const groups =
		_getFleetGroupsToServer(ruleType, priorityConfiguration?.selectedFleetIds, fleetsRatio, earlyEtaStrategy) || [];

	if (priorityConfiguration?.isFallbackMethodEnabled) {
		// @ts-ignore
		groups.push(
			..._getFleetGroupsToServer(
				priorityConfiguration?.fallbackMethod,
				priorityConfiguration?.selectedFleetIds,
				fleetsRatio,
				earlyEtaStrategy
			)
		);
	}
	return groups;
};

const _getFleetGroupsToServer = (
	ruleType: RuleType | FallbackMethod,
	fleetIds?: string[],
	fleetsRatio?: FleetRatioMap,
	earlyEtaStrategy?: EarlyEtaStrategy
): FleetGroup[] => {
	switch (ruleType) {
		case RuleType.cheap:
			return [
				{
					all_fleets: true,
					preferred_method: PreferredMethod.Cheapest
				}
			];
		case RuleType.fast:
			return [
				{
					all_fleets: true,
					preferred_method: PreferredMethod.Fastest,
					...(earlyEtaStrategy ? { early_eta_strategy: earlyEtaStrategy } : {})
				}
			];
		case RuleType.priority:
			return [
				{
					all_fleets: false,
					preferred_method: PreferredMethod.Priority,
					fleet_ids: fleetIds
				}
			];
		case RuleType.ratio:
			return [
				{
					all_fleets: false,
					preferred_method: PreferredMethod.Ratio,
					fleet_ids: fleetIds,
					fleets_ratio: Object.entries(fleetsRatio).map(([fleet_id, ratio]) => ({
						fleet_id,
						ratio
					}))
				}
			];
	}
};
