import { useEffect, useMemo } from 'react';
import { uniqBy, lowerCase } from 'lodash';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import { FormItem, Select, Tooltip } from '@bringg/react-components';
import { FilterTag } from '@bringg/react-components/dist/components/filter-bar-2/components/filter-tag';
import { validateZipOrPostalCode } from 'bringg-web/features/service-area/utils/validate-zip-postal-code';
import { useDuplicatedZipcodes } from 'bringg-web/features/service-area/form/duplicated-zipcodes-hook';
import { useStores } from 'bringg-web/recipes';
import './zipcodes-input.scss';

const separators = [','];

let lastEvent;
const rememberLastKey = event => {
	lastEvent = event;
};

const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
	event.stopPropagation();
};

function WithTooltip({ serviceAreas, children }) {
	if (serviceAreas) {
		return (
			<Tooltip trigger="hover" title={serviceAreas.map(serviceArea => serviceArea.name).join(', ')}>
				{children}
			</Tooltip>
		);
	} else {
		return children;
	}
}
function TrimmedSelect({ value = null, onChange = null, indexedDuplicatedZipcodes }) {
	useEffect(() => {
		document.addEventListener('keydown', rememberLastKey, true);

		return () => {
			document.removeEventListener('keydown', rememberLastKey);
		};
	}, []);

	return (
		<Select
			className="zipcodes-input"
			data-test-id="service-area-zipcodes"
			mode="tags"
			tokenSeparators={separators}
			placeholder="12345, 6789,..."
			value={value}
			onChange={zipcodes => {
				const isDeselect = zipcodes.length < value.length;
				const lastKeyIsEnter = lastEvent?.key === 'Enter' || lastEvent?.keyCode === 13;
				if (isDeselect && lastKeyIsEnter) {
					lastEvent = null;
					return;
				}

				onChange(uniqBy(zipcodes.map(zipcode => zipcode.trim()).filter(Boolean), lowerCase));
			}}
			tagRender={props => {
				const { value, closable, onClose } = props;
				if (!value) {
					return null;
				}

				const serviceAreas = indexedDuplicatedZipcodes[value.toLowerCase()];
				const className = classnames({ 'service-area-zipcode-duplicated': serviceAreas });

				return (
					<span onMouseDown={onPreventMouseDown} className={className}>
						<FilterTag key={value} closable={closable} onClose={onClose}>
							<WithTooltip serviceAreas={serviceAreas}>{value}</WithTooltip>
						</FilterTag>
					</span>
				);
			}}
		/>
	);
}

export const ServiceAreaZipcodesInput = observer(() => {
	const { serviceAreaViewStore } = useStores();
	const { t } = useTranslation();
	const indexedDuplicatedZipcodes = useDuplicatedZipcodes(serviceAreaViewStore);

	const validate = async func =>
		Promise.resolve().then(() => {
			if (!func()) {
				throw new Error("Can't parse");
			}
		});

	const rules = useMemo(() => {
		const validator = async (_, values) =>
			validate(() =>
				values.every(value => {
					const valid = validateZipOrPostalCode(value);

					if (!valid) {
						console.error(`Invalid zipcode: ${value}`);
					}

					return valid;
				})
			);

		return [
			{ required: true, message: t('SERVICE_AREA.CANT_BE_EMPTY') },
			{
				validator,
				message: t('SERVICE_AREA.CANT_PARSE_ZIPCODES')
			}
		];
	}, [t]);

	return (
		<>
			<FormItem validateFirst rules={rules} name="zipcodes">
				<TrimmedSelect indexedDuplicatedZipcodes={indexedDuplicatedZipcodes} />
			</FormItem>
			{Object.keys(indexedDuplicatedZipcodes).length > 0 && (
				<div className="service-area-warning-title">{t('SERVICE_AREA.ZIPCODE_ALREADY_IN_USE')}</div>
			)}
		</>
	);
});
