import React, { useContext } from 'react';

import classnames from 'classnames';
import { kebabCase } from 'lodash';
import { useTranslation } from 'react-i18next';
import { Checkbox } from '@bringg/react-components';

import InputLabelWithTooltip from './../input-label-with-tooltip/input-label-with-tooltip';
import { OptimizationSettingsFormContext } from '../optimization-configuration-form/optimization-configuration-form';
import { OptimizationCheckboxAttribute, OptimizationSettingsFormContextType } from '../../types';

import './checkbox.scss';

const RouteOptimizationCheckbox = ({
	title,
	tooltipTitle,
	id,
	value,
	isSubField,
	disabled,
	containerId
}: Omit<OptimizationCheckboxAttribute, 'type'>) => {
	const { handleChange } = useContext<OptimizationSettingsFormContextType>(OptimizationSettingsFormContext);
	const { t } = useTranslation();

	return (
		<div className={classnames('route-optimization-field-container', { 'sub-field': isSubField })}>
			<Checkbox
				className="route-optimization-field-checkbox"
				checked={value}
				data-test-id={kebabCase(tooltipTitle)}
				onChange={event => handleChange(id, { value: event.target.checked, isValid: true })}
				disabled={disabled}
			/>
			<InputLabelWithTooltip
				id={id}
				title={t(title)}
				className="route-optimization-field-checkbox-title"
				tooltip={tooltipTitle ? t(tooltipTitle) : null}
				containerId={containerId}
			/>
		</div>
	);
};

export default RouteOptimizationCheckbox;
