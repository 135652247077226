import React, { useCallback, useState, useEffect } from 'react';
import moment, { Moment } from 'moment';
import { useTranslation } from 'react-i18next';
import { omitBy, isUndefined } from 'lodash';
import { FilterBar2, FilterBar2ListItem, FilterBarTranslations, useForm } from '@bringg/react-components';
import filterOptions, { BRINGG_ADMIN } from './filter-options';
import getConfigurationsAudit, { ConfigurationAuditRow } from './get-configurations-audit';

interface FilterBarProps {
	setTableValues: (promise: Promise<ConfigurationAuditRow[]>) => void;
	initialValues: {
		changeTime: [Moment, Moment];
	};
	setExportValues: (update: { changeTime: [moment.Moment, moment.Moment] }) => void;
}

const translations = (t: typeof useTranslation) => {
	return {
		reset: t('CONFIGURATION_AUDIT.RESET_FILTER'),
		moreFilters: t('CONFIGURATION_AUDIT.MORE_FILTERS'),
		done: t('CONFIGURATION_AUDIT.APPLY_FILTERS'),
		cancel: t('CONFIGURATION_AUDIT.CANCEL_FILTERS'),
		search: '',
		all: ''
	} as unknown as FilterBarTranslations;
};

const disableDate = (current: Moment): boolean => {
	const now = moment();
	const threeMonthAgo = moment().subtract(3, 'months');

	return current && (current > now || current < threeMonthAgo);
};

const removeEmptyArrays = (obj: Record<string, unknown>) =>
	Object.fromEntries(Object.entries(obj).filter(([, v]) => (Array.isArray(v) ? v.length : true)));

const FilterBar = ({ setTableValues, initialValues, setExportValues }: FilterBarProps) => {
	const [form] = useForm();
	const [changedBy, setChangedBy] = useState([]);
	const [configurationModels, setConfigurationModels] = useState([]);
	const [initialAdditional, setInitialAdditional] = useState([]);
	const { t } = useTranslation();

	useEffect(() => {
		const setOptions = async () => {
			setConfigurationModels(await filterOptions('configurationModels'));
			setChangedBy(await filterOptions('changedBy'));
		};

		setOptions();
	}, []);

	const list: FilterBar2ListItem[] = [
		{
			type: FilterBar2.TYPES.DATE_RANGE,
			name: 'changeTime',
			title: t('CONFIGURATION_AUDIT.CHANGE_TIME'),
			customProps: {
				disabledDate: disableDate,
				placeholder: [t('FILTER_BAR.FROM_DATE'), t('FILTER_BAR.TO_DATE')]
			}
		},
		{
			type: FilterBar2.TYPES.SELECT,
			additional: true,
			name: 'configurationModels',
			title: t('CONFIGURATION_AUDIT.CONFIGURATION_MODEL'),
			placeholder: 'Select',
			multiselect: true,
			values: configurationModels
		},
		{
			type: FilterBar2.TYPES.SELECT,
			additional: true,
			name: 'changedBy',
			title: t('CONFIGURATION_AUDIT.CHANGED_BY'),
			placeholder: 'Select',
			multiselect: true,
			values: changedBy
		}
	];

	const handleDone = useCallback(
		values => {
			const changedByAdmin = (values?.changedBy ?? [])
				.filter(changed => typeof changed === 'string')
				.map(changed => Number(changed.split('|')[0]));
			const changedBy = (values?.changedBy ?? [])
				.filter(changed => typeof changed === 'number')
				.map(changed => (changed !== BRINGG_ADMIN.id ? changed : null));

			const params = removeEmptyArrays({ ...values, changedBy, changedByAdmin });

			setTableValues(getConfigurationsAudit(params));
			setExportValues(values);
		},
		[setTableValues, setExportValues]
	);

	return (
		<FilterBar2
			form={form}
			initialValues={initialValues}
			initialAdditional={initialAdditional}
			list={list}
			resetToInitial
			translations={translations(t)}
			onFilter={handleDone}
			onReset={name => {
				if (name === 'changeTime') {
					form.setFieldsValue({ changeTime: initialValues.changeTime });
				}

				handleDone(omitBy(form.getFieldsValue(true), isUndefined));
			}}
			onAdditional={additional => setInitialAdditional(additional)}
			className="configurations-audit-filter-bar"
		/>
	);
};

export default FilterBar;
