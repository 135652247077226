import React from 'react';
import { BringgFontIcons, BringgIcon } from '@bringg/bringg-icons';
import styles from './title-with-icon.module.scss';
import classNames from 'classnames';

interface Props extends Omit<React.HTMLAttributes<Element>, 'title'> {
	title: string | React.ReactNode;
	icon: BringgFontIcons;
}

const TitleWithIcon = ({ title, icon, ...rest }: Props) => {
	return (
		<div {...rest} className={classNames(styles.titleWithIcon, rest.className)}>
			<BringgIcon iconName={icon} className={styles.icon} />
			<span className={styles.title}>{title}</span>
		</div>
	);
};

export default TitleWithIcon;
