import React, { useEffect, useState } from 'react';

import { isNull } from 'lodash';
import _isNil from 'lodash/isNil';
import _isEmpty from 'lodash/isEmpty';
import classNames from 'classnames';
import { observer } from 'mobx-react';
import RuleForm, {
	RuleType,
	RuleConfig
} from '@bringg/react-components/dist/features/fleet-router/rule-form/rule-form';
import { Attribute } from '@bringg/react-components/dist/features/conditions/rule/rule-utils';
import { Button, CustomModal, RulesList } from '@bringg/react-components';
import { FeatureFlags, PrivilegeTypes } from '@bringg/types';

import { hasInternalFleet } from 'bringg-web/stores/fleets/fleet-checker';
import useTeamsById from '../use-teams-by-id';
import useFleetConfigurationStore from '../use-fleet-configuration-store';
import FleetsConfigurationStore, {
	Rule as RuleT
} from '../../../stores/fleets-configuration/fleets-configurations-store';
import useConflictRulesModalOld from '../conflict-rules-modal/use-conflict-rules-modal';
import useConflictRulesModal from '../conflict-rules-modal/use-conflict-rules';
import notification from '../../../services/notification';
import { useFleetsConfigurationTranslations } from '../use-fleet-configuration-translations';
import ConflictRulesModal from '../conflict-rules-modal/conflict-rules-modal';
import { useHasAccess } from '../../../utils/privileges';
import FastestRuleValidation, { FastestRuleValidationTooltip } from './fastest-rule-validation';
import { FleetRuleItem } from './rule-list-item';
import DraggableList from '../../../components/draggable-list/draggable-list';
import { getConflictRulesByTeamId } from '../fleets-configuration';
import {
	FleetRatioMap,
	getPriority,
	NEW_FLEET_ROUTER_RULES_FF,
	ENABLE_VENDOR_ALLOCATION_RULE_FF,
	ENABLE_FASTEST_FLEET_OPTIONS_FF
} from './rule-list-utils';

import './rules-list-component.scss';
import './rules-list-component-old.scss';

interface Props {
	className?: string;
}

const RulesListComponent = ({ className }: Props) => {
	const { fleetsConfigurationStore } = useFleetConfigurationStore();

	const featureFlags: FeatureFlags = {
		newFleetRouterRuleFlagIsOn: fleetsConfigurationStore?.featureFlags?.[NEW_FLEET_ROUTER_RULES_FF] || false,
		enableRatioPercentage: fleetsConfigurationStore?.featureFlags?.[ENABLE_VENDOR_ALLOCATION_RULE_FF] || false,
		pickupEtaAvailable: fleetsConfigurationStore?.featureFlags?.[ENABLE_FASTEST_FLEET_OPTIONS_FF] || false
	};

	const { teams } = useTeamsById();
	const allTeams = Object.values(teams);
	const [rule, setRule] = useState<RuleT>(null);
	const [conflictModalRule, setConflictModalRule] = useState<RuleT>(null);
	const [enableDrag, setEnableDrag] = useState(true);
	const [fleets, setFleets] = useState([]);
	const [conflicts, setConflicts] = useState([]);

	const conflictsOld = rule?.id ? fleetsConfigurationStore.conflictedRules[rule.id] || [] : [];

	const readOnly = !useHasAccess(PrivilegeTypes.ENABLE_SETTING_PAGE);

	useEffect(() => {
		setConflictModalRule(rule);
	}, [rule]);

	const {
		isModalOpen: isConflictsModalOpenOld,
		openModal: openConflictsModalOld,
		onCancel: onCancelOld,
		onDisableRules: onDisableRulesOld,
		onRemoveTeams: onRemoveTeamsOld,
		translations: translationsOld
	} = useConflictRulesModalOld(conflictModalRule);

	const {
		isModalOpen: isConflictsModalOpen,
		openModal: openConflictsModal,
		onCancel,
		onDisableRules,
		setRule: setConflictingRule,
		translations: conflictTranslations
	} = useConflictRulesModal();

	const isFastestFleetDisabled = hasInternalFleet();

	const rulesListItems = fleetsConfigurationStore.rules.map(rule => ({
		id: rule.id,
		ruleName: rule.name,
		isMerchantLevel: rule.isMerchantLevel,
		teams: Object.keys(rule.selectedTeams)
			.filter(teamId => !!rule.selectedTeams[teamId])
			.map(teamId => teams[teamId]?.name),
		isEnabled: rule.isEnabled,
		extra:
			rule.isEnabled && rule.ruleType === RuleType.fast && isFastestFleetDisabled ? (
				<FastestRuleValidationTooltip />
			) : undefined
	}));

	const {
		ruleFormTranslations,
		fleetsListTranslations,
		networkError,
		validationTranslations,
		mainScreenTranslations
	} = useFleetsConfigurationTranslations();

	const { isOpen: isModalOpen, open: openModal, close: closeModal } = CustomModal.useModalState(false);

	const assignedTeams =
		rule?.id && rule?.isEnabled ? fleetsConfigurationStore.getAssignedTeams(rule.id) : new Set<string>();

	const conflictRulesByTeamId = getConflictRulesByTeamId(rule, fleetsConfigurationStore.rules, 'name') as Map<
		string,
		string[]
	>;

	const { fleets: oldFleets } = fleetsConfigurationStore;

	useEffect(() => {
		if (featureFlags?.newFleetRouterRuleFlagIsOn) {
			if (featureFlags.pickupEtaAvailable) {
				Object.values(fleetsConfigurationStore.fleets).forEach(fleet => {
					setFleets(prevFleets => [
						...prevFleets,
						{
							key: fleet.name,
							value: fleet.id,
							disabled: !(fleet.capabilities?.pickup_eta_available || false)
						}
					]);
				});

				return;
			}

			Object.values(fleetsConfigurationStore.fleets).forEach(fleet => {
				setFleets(prevFleets => [...prevFleets, { key: fleet.name, value: fleet.id }]);
			});
		}
	}, [fleetsConfigurationStore.fleets]);

	const mapRuleToViewModel = ({
		id,
		name,
		ruleType,
		attributes = [] as Attribute[],
		isMerchantLevel,
		selectedTeams,
		isOpen = false,
		fleetsRatio = {} as FleetRatioMap,
		isEnabled,
		conditionEntity = null,
		priorityConfiguration = null,
		earlyEtaStrategy = null
	}) => {
		return {
			id,
			name,
			ruleType,
			attributes,
			isMerchantLevel,
			selectedTeams,
			isEnabled,
			fleetsRatio,
			isOpen,
			priorityConfiguration,
			conditionEntity,
			earlyEtaStrategy,
			extra:
				isEnabled && ruleType === RuleType.fast && isFastestFleetDisabled ? (
					<FastestRuleValidationTooltip />
				) : undefined
		};
	};

	const onDelete = async ({ ruleId, conditionsId }) => {
		try {
			await fleetsConfigurationStore.deleteRule({ ruleId, conditionsId });
		} catch (err) {
			notification.error(networkError);
		}
	};

	const onDeleteOld = async (id: string) => {
		try {
			await fleetsConfigurationStore.deleteRuleOld(id);
			closeModal();
		} catch (err) {
			notification.error(networkError);
		}
	};

	const onToggleRuleOld = async (id: string) => {
		const rule = fleetsConfigurationStore.getRule(id);
		setRule(fleetsConfigurationStore.getRule(id));
		const conflicts = fleetsConfigurationStore.conflictedRules;
		const shouldShowConflictModal = !rule.isEnabled && conflicts[id]?.length > 0;
		if (shouldShowConflictModal) {
			openConflictsModalOld();
		} else {
			try {
				await fleetsConfigurationStore.updateRule({ ...rule, isEnabled: !rule.isEnabled });
			} catch (err) {
				notification.error(networkError);
			}
		}
	};

	const onToggleRule = async ({ value, id }: { value: boolean; id: string }) => {
		const ruleFromDb = fleetsConfigurationStore.getRule(id);

		setConflictingRule(ruleFromDb);

		const currentConflicts = fleetsConfigurationStore.conflictedRules;

		setConflicts(currentConflicts[id] || []);

		const shouldShowConflictModal = !ruleFromDb.isEnabled && currentConflicts[id]?.length > 0;

		if (shouldShowConflictModal) {
			openConflictsModal();
		} else {
			try {
				await fleetsConfigurationStore.updateRule({ ...ruleFromDb, isEnabled: value });
			} catch (err) {
				notification.error(networkError);
			}
		}
	};

	const onApply = async (ruleConfig: RuleConfig) => {
		const rule = { ...ruleConfig, isEnabled: false };
		if (!rule.name) {
			notification.error(validationTranslations.nameRequired);
			return;
		}

		if (rule.ruleType === RuleType.priority && _isEmpty(rule.priorityConfiguration?.selectedFleetIds)) {
			notification.error(validationTranslations.fleetsRequired);
			return;
		}

		try {
			if (_isNil(ruleConfig.id)) {
				// No need to assert no conflict here as well as the rule is disabled by default
				await fleetsConfigurationStore.createRule(rule);
			} else {
				const originalRule = fleetsConfigurationStore.getRule(ruleConfig.id);

				if (originalRule.isEnabled && !originalRule.isMerchantLevel && ruleConfig.isMerchantLevel) {
					const conflicts = fleetsConfigurationStore.getConflictRulesIfRuleWouldEqual({
						...ruleConfig,
						isEnabled: originalRule.isEnabled
					})[ruleConfig.id];

					// Do not allow to change the rule to merchant level if there are conflicts
					if (conflicts.length > 0) {
						setConflictModalRule({ ...rule, isEnabled: originalRule.isEnabled });
						openConflictsModal();
						return;
					}
				}
				await fleetsConfigurationStore.updateRule({ ...rule, isEnabled: originalRule.isEnabled });
			}
			closeModal();
		} catch (err) {
			notification.error(networkError);
		}
	};

	const onAdd = () => {
		const conf = { ...FleetsConfigurationStore.defaultRuleConfigurationOld };
		if (isFastestFleetDisabled) {
			conf.ruleType = RuleType.cheap;
		}
		setRule(conf);
		openModal();
	};

	const onEditRule = (id: string) => {
		setRule(fleetsConfigurationStore.getRule(id));
		openModal();
	};

	const validationsByRuleType = {};

	if (isFastestFleetDisabled) {
		validationsByRuleType[RuleType.fast] = <FastestRuleValidation />;
	}

	const saveRule = async rule => {
		const originalRule = fleetsConfigurationStore.getRule(rule.id);

		if (!rule.name) {
			notification.error(validationTranslations.nameRequired);
			return;
		}

		if (rule.ruleType === RuleType.priority && _isEmpty(rule.priorityConfiguration?.selectedFleetIds)) {
			notification.error(validationTranslations.fleetsRequired);
			return;
		}

		try {
			setConflictingRule(rule);

			const conflicts = fleetsConfigurationStore.getConflictRulesIfRuleWouldEqual({
				...rule,
				isEnabled: true
			})[rule.id];

			setConflicts(conflicts);

			// Do not allow to change the rule to merchant level if there are conflicts
			if (conflicts.length > 0) {
				openConflictsModal();
			} else {
				await fleetsConfigurationStore.updateRule({ ...originalRule, ...rule });

				return true;
			}
		} catch (err) {
			notification.error(networkError);
		}
	};

	const addNewRule = async options => {
		if (isFastestFleetDisabled) {
			options.ruleType = RuleType.cheap;
		}

		const priority = getPriority({ rawList: fleetsConfigurationStore.rules, to: 0 });

		await fleetsConfigurationStore.createRule({ ...options, priority, isEnabled: false });
	};

	const onChangeName = async ({ value, id }) => {
		const rule = fleetsConfigurationStore.getRule(id);

		await fleetsConfigurationStore.updateRule({ ...rule, name: value });
	};

	const onFieldMove = async (fromIndex: number, toIndex: number) => {
		if (!isNull(fromIndex) && !isNull(toIndex)) {
			const fieldsData = [...fleetsConfigurationStore.rules];

			const fieldToMove = fieldsData.splice(fromIndex, 1)[0];

			const priority = getPriority({ rawList: fieldsData, to: toIndex, from: fromIndex });

			fieldsData.splice(toIndex, 0, { ...fieldToMove, priority });

			fleetsConfigurationStore.rules = fieldsData;

			await fleetsConfigurationStore.updateRulePriority({ ...fieldToMove, priority });
		}
	};

	return featureFlags?.newFleetRouterRuleFlagIsOn ? (
		<div className={classNames('fleet-rules-section', className)}>
			<div className="header-container">
				<div>
					<div className="header-title">{mainScreenTranslations.header}</div>
					<div className="header-description">{mainScreenTranslations.description}</div>
				</div>
				<Button
					data-test-id="fleet-rule-add-button"
					type="primary"
					className="fleet-add-button rules-list-add-button"
					onClick={async () => addNewRule(FleetsConfigurationStore.defaultRuleConfiguration)}
					disabled={readOnly}
				>
					{mainScreenTranslations.addRule}
				</Button>
			</div>
			{!readOnly && (
				<ConflictRulesModal
					rules={conflicts}
					translations={conflictTranslations}
					isVisible={isConflictsModalOpen}
					onDisableRules={onDisableRules}
					onCancel={onCancel}
				/>
			)}
			<div className="conditions-set">
				<div className="conditions-rules-list bringg-collapse">
					<DraggableList
						isDragDisabled={!enableDrag}
						onFieldMove={onFieldMove}
						data-test-id="action-data-fields-list"
					>
						{[...fleetsConfigurationStore.rules].map(mapRuleToViewModel).map((rule, index) => (
							<div key={rule.id} className="fleet-rule">
								<FleetRuleItem
									rule={rule}
									isOpen={rule.isOpen || false}
									index={index}
									onSave={saveRule}
									onDelete={onDelete}
									onDuplicate={addNewRule}
									teams={allTeams}
									fleets={fleets}
									attributes={fleetsConfigurationStore.attributes}
									onToggleRule={onToggleRule}
									onChangeName={onChangeName}
									onDragChange={setEnableDrag}
									featureFlags={featureFlags}
								/>
							</div>
						))}
					</DraggableList>
				</div>
			</div>
		</div>
	) : (
		<div className={classNames('fleet-rules-section', className)}>
			<CustomModal isOpen={isModalOpen} bodyClassName="rule-form-modal">
				<RuleForm
					ruleConfig={rule}
					allTeams={allTeams}
					allFleets={oldFleets}
					disabledTeamIds={assignedTeams}
					onApply={onApply}
					onRemove={!_isNil(rule?.id) && onDeleteOld}
					onCancel={closeModal}
					translations={ruleFormTranslations}
					conflictRulesByTeamId={conflictRulesByTeamId}
					readOnly={readOnly}
					validationByRuleType={validationsByRuleType}
				/>
			</CustomModal>
			{!readOnly && (
				<ConflictRulesModal
					rules={conflictsOld}
					translations={translationsOld}
					isVisible={isConflictsModalOpenOld}
					onRemoveTeams={conflictModalRule?.isMerchantLevel ? undefined : onRemoveTeamsOld}
					onDisableRules={onDisableRulesOld}
					onCancel={onCancelOld}
				/>
			)}
			<RulesList
				ruleListItems={rulesListItems}
				onToggle={onToggleRuleOld}
				onAdd={onAdd}
				onDelete={onDeleteOld}
				onClick={onEditRule}
				translations={fleetsListTranslations}
				readOnly={readOnly}
			/>
		</div>
	);
};

export default observer(RulesListComponent);
