import React from 'react';
import type { ColumnInstance } from 'react-table';

interface Props {
	column: ColumnInstance<Record<string, unknown>>;
}

const DefaultColumnFilter = ({ column: { filterValue, setFilter, id } }: Props) => {
	return (
		<input
			title="search-input"
			className="default-filter-input"
			value={filterValue || ''}
			onChange={e => {
				setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
			}}
			placeholder={`Search ${id}...`}
		/>
	);
};

export default DefaultColumnFilter;
