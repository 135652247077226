import React, { useCallback } from 'react';

import classNames from 'classnames';
import { BringgFontIcons, BringgIcon } from '@bringg/bringg-icons';
import type { HeaderGroup } from 'react-table';

import '../../../components/bringg-react-table/react-table-config.d';
import { SELECTION_COLUMN_ID, ACTIONS_COLUMN_ID } from '../consts';

interface TableHeaderProps<T extends Record<string, unknown>> {
	headerGroups: HeaderGroup<T>[];
	height?: number;
	style?: React.CSSProperties;
}

const TableHeader = <T extends Record<string, unknown>>(props: TableHeaderProps<T>) => {
	const { headerGroups, style, height } = props;

	const getSortIcon = useCallback(({ isSorted, isSortedDesc, disableSortBy, canSort }: HeaderGroup<T>) => {
		return (
			canSort &&
			!disableSortBy && (
				<span className={classNames('sorter-wrapper', { sorted: isSorted })}>
					<BringgIcon
						iconName={isSortedDesc ? BringgFontIcons.ArrowDown : BringgFontIcons.ArrowUp}
						className={classNames({ active: isSorted })}
					/>
				</span>
			)
		);
	}, []);

	const getHeaderCell = useCallback(
		(column: HeaderGroup<T>) => {
			if (column.id === SELECTION_COLUMN_ID) {
				return column.render('Header');
			}
			const sortProps = column.canSort ? column.getSortByToggleProps() : {};
			return (
				<div {...sortProps} className="header-wrapper">
					<span className="text-ellipsis">{column.render('Header')}</span>
					{getSortIcon(column)}
				</div>
			);
		},
		[getSortIcon]
	);

	return (
		<div className="table-header">
			<div style={style}>
				{headerGroups.map(headerGroup => (
					<div {...headerGroup.getHeaderGroupProps({ style: { height } })} className="table-row">
						{headerGroup.headers.map(column => {
							const headerProps = column.getHeaderProps();

							// TODO: strange behaviour need to be investigated:
							// for some reason sticky columns will have `position: relative` in their styles
							if (column.sticky) {
								headerProps.style.position = 'sticky';
							}

							return (
								<div
									{...headerProps}
									className={classNames('table-header-cell', {
										'row-selection-cell': column.id === SELECTION_COLUMN_ID,
										'row-actions-cell': column.id === ACTIONS_COLUMN_ID
									})}
								>
									{getHeaderCell(column)}
									{column.canResize && typeof column.getResizerProps === 'function' && (
										<div className="column-resizer">
											<span className="column-resizer-icon" {...column.getResizerProps()} />
										</div>
									)}
								</div>
							);
						})}
					</div>
				))}
			</div>
		</div>
	);
};

export default TableHeader;
