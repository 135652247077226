import { createViewModel, IViewModel } from 'mobx-utils';
import BaseDomainObject from './base-domain-object';

// TODO: remove extending Domain Object, should not have view stuff tied to data
abstract class BaseViewModelDomainObject<T> extends BaseDomainObject<T> {
	protected entityViewModel: T & BaseViewModelDomainObject<T> & IViewModel<T & BaseViewModelDomainObject<T>> = null;

	get viewModel(): T & BaseViewModelDomainObject<T> & IViewModel<T & BaseViewModelDomainObject<T>> {
		if (!this.entityViewModel) {
			this.entityViewModel = createViewModel<T & BaseViewModelDomainObject<T>>(this.store.get(this.id));
		}
		return this.entityViewModel;
	}

	async viewTransactionUpdate(options = {}): Promise<T & BaseViewModelDomainObject<T>> {
		const viewModel = this.viewModel;
		try {
			const asJson = (viewModel as Partial<BaseViewModelDomainObject<T>>).asJson;
			await viewModel.update(asJson, options);
			viewModel.submit();
			return viewModel;
		} catch (error) {
			viewModel.reset();
			throw error;
		}
	}
}

export default BaseViewModelDomainObject;
