import React, { useMemo } from 'react';
import { User, WayPoint } from '@bringg/types';
import { Translate } from '../../../translation';
import { DateDisplay } from '@bringg/react-components/dist/components';
import { useObserver } from 'mobx-react';

interface DriverPopupProps {
	driver: User;
	openTasksGroupedByDrivers: { [key: number]: Task[] };
}

type Task = Bringg.Task & { activeWayPoint?: WayPoint };
const DATE_FORMAT = 'L LT';

const DriverTasks: React.FC<{ driverTasks: Task[] }> = ({ driverTasks }) => {
	const tasks = driverTasks.map(task => <DriverTask key={task.id} task={task} />);

	return <ul className="driver-marker-tasks">{tasks}</ul>;
};

const DriverTask: React.FC<{ task: Task }> = ({ task }) => (
	<li className="driver-marker-task">
		{task.scheduled_at && (
			<span>
				<DateDisplay format={DATE_FORMAT} date={task.scheduled_at} />:
			</span>
		)}
		({task.external_id}) {task.title} {task.activeWayPoint?.address}
	</li>
);

const DriverPopup: React.FC<DriverPopupProps> = ({ driver, openTasksGroupedByDrivers }) => {
	const driverTasks = useMemo(() => {
		return openTasksGroupedByDrivers[driver.id] || [];
	}, [openTasksGroupedByDrivers, driver.id]);

	return useObserver(() => {
		return (
			<div className="marker-tooltip">
				<h4>{driver.name}</h4>

				{driver.job_description && <span>({driver.job_description})</span>}

				<div className="driver-marker-separator"></div>

				<p>
					{driverTasks.length} <Translate text={'MAP.ORDERS_CURRENTLY_ASSIGNED'} />:
				</p>

				<DriverTasks driverTasks={driverTasks} />
			</div>
		);
	});
};

export default DriverPopup;
