import React from 'react';
import type { MutableRefObject } from 'react';

import classnames from 'classnames';
import { get as _get, isString as _isString } from 'lodash';

import type {
	GanttTimeLineItem,
	ExtendedTimelineItem,
	TimelineItemValuesHistory,
	ISOString,
	GroupRow,
	VisTimelineGroupExtended
} from './gantt-types';
import { GANTT_ITEM_ROW_CLASS, LATE_ITEM, TIMELINE_ITEM_PREFIX } from './components/timeline-items/consts';
import type Timeline from './gantt-timeline';
import type { GroupCell } from './components/group-row-cells/group-row-cells';
import { RemoveGroupButton } from './components/remove-group-button/remove-group-button';
import Spinner from './components/spinner/spinner';

const WEEKDAY = 'weekday';
const DAY = 'day';
const HOUR = 'hour';

export const isUserChangedItemDuration = (
	item: ExtendedTimelineItem,
	currentStart: string,
	currentEnd?: string | null
): boolean => {
	if (!currentEnd && currentStart) {
		return false; // is not 'range' item
	}

	return (
		(item.valuesHistory.previousEnd !== currentEnd && item.valuesHistory.previousStart === currentStart) ||
		(item.valuesHistory.previousEnd === currentEnd && item.valuesHistory.previousStart !== currentStart)
	);
};

export const isUserChangedItemGroup = (item: ExtendedTimelineItem): boolean => {
	return item.valuesHistory.previousGroupId !== item.group;
};

export const isUserChangedItemPositionInTime = (item: ExtendedTimelineItem): boolean => {
	const currentStart = new Date(item.start).toISOString();
	const currentEnd = item.end && new Date(item.end)?.toISOString();

	if (!currentEnd) {
		return item.valuesHistory.previousStart !== currentStart;
	}

	return item.valuesHistory.previousEnd !== currentEnd && item.valuesHistory.previousStart !== currentStart;
};

export const getGroupColorStyle = (color: string): string => {
	return `border-color: ${color};
            background-color: ${color};`;
};

export const createVisItemFromGanttItem = (
	item: GanttTimeLineItem,
	preservedHistory?: TimelineItemValuesHistory
): ExtendedTimelineItem => {
	const visItem = {
		id: item.id,
		title: item.id.toString(),
		start: item.start,
		group: item.rowId,
		type: item.customData?.itemType ? item.customData.itemType : item.end ? 'range' : 'box',
		shouldCluster: item.clusterable,
		valuesHistory: preservedHistory ?? {
			originalStart: item.start,
			previousStart: item.start,
			originalEnd: item.end,
			previousEnd: item.end,
			originalGroupId: item.rowId,
			previousGroupId: item.rowId
		},
		customData: item.customData
	} as ExtendedTimelineItem;

	if (item.end) {
		visItem.end = item.end;
	}

	if (item.editable && typeof item.editable === 'object') {
		visItem.editable = {
			updateTime: item.editable.draggable,
			updateGroup: item.editable.draggable && item.editable.draggableToOtherRow
		};
	} else {
		visItem.editable = false;
	}

	if (item.color) {
		visItem.color = item.color;
		visItem.style = getGroupColorStyle(item.color);
	}

	visItem.className = classnames(
		'gantt-item',
		`${TIMELINE_ITEM_PREFIX}${visItem.id}`,
		item.className,
		{ 'bringg-icon-clock': item.customData?.late },
		{ [LATE_ITEM]: item.customData?.late }
	);

	visItem.content = item.content ? (_isString(item.content) ? (item.content as string) : '') : ' '; // take care by 'template'

	return visItem;
};

export const prepareItemForEvent = (item: ExtendedTimelineItem): GanttTimeLineItem => {
	let currentStart: ISOString;
	let currentEnd: ISOString | undefined;
	if (item.start instanceof Date) {
		currentStart = (item.start as Date).toISOString();
		currentEnd = (item.end as Date)?.toISOString();
	} else {
		currentStart = new Date(item.start).toISOString();
		currentEnd = (item.end && new Date(item.end).toISOString()) || undefined;
	}

	const itemForEvent: GanttTimeLineItem = {
		id: Number(item.id),
		rowId: Number(item.group),
		start: currentStart,
		end: currentEnd,
		content: item.content,
		color: item.color,
		className: item.className,
		customData: item.customData
	};
	return itemForEvent;
};

export const notifyItemMoveSuccess = async (
	item: ExtendedTimelineItem,
	onItemMoveEnded: (item: GanttTimeLineItem, valuesHistory: TimelineItemValuesHistory) => Promise<boolean>,
	visCallback: (item: ExtendedTimelineItem | null) => void,
	adjustItemBeforeMoveEnded?: (item: GanttTimeLineItem, valuesHistory: TimelineItemValuesHistory) => GanttTimeLineItem
) => {
	const cloneHistoryForEvent = { ...item.valuesHistory };
	let itemForEvent = prepareItemForEvent(item);

	if (adjustItemBeforeMoveEnded) {
		itemForEvent = adjustItemBeforeMoveEnded(itemForEvent, cloneHistoryForEvent);
		// eslint-disable-next-line no-param-reassign
		item = { ...item, ...createVisItemFromGanttItem(itemForEvent, cloneHistoryForEvent) };
	}

	let success = true;
	visCallback(item); // send back adjusted item to VIS, confirm the move.

	if (onItemMoveEnded) {
		success = await onItemMoveEnded(itemForEvent, cloneHistoryForEvent); // notify parent component
	}

	if (success) {
		updateItemValuesHistory(item);
	} else {
		visCallback(null);
	}
};

export const updateItemValuesHistory = (item: ExtendedTimelineItem): void => {
	item.valuesHistory.previousGroupId = item.group;
	item.valuesHistory.previousStart = item.start;
	item.valuesHistory.previousEnd = item.end;
};

export const getIntervalTimeByMS = (apiRef: MutableRefObject<typeof Timeline>): number => {
	const MS_IN_SECOND = 1000,
		SECONDS_IN_MINUTE = 60,
		MINUTES_IN_HOUR = 60,
		HOURS_IN_DAY_ = 24;

	const step = Number(_get(apiRef, 'current.timeline.timeAxis.step.step', 1));
	const scale: string = _get(apiRef, 'current.timeline.timeAxis.step.scale', HOUR);

	const stepMinutesInMS = MS_IN_SECOND * SECONDS_IN_MINUTE * step;

	if ([WEEKDAY, DAY].includes(scale)) {
		return stepMinutesInMS * HOURS_IN_DAY_ * MINUTES_IN_HOUR;
	}

	if (scale === HOUR) {
		return stepMinutesInMS * MINUTES_IN_HOUR;
	}

	return stepMinutesInMS;
};

export const convertGanttRowToVisGroup = (
	group: GroupRow,
	onRemoveClicked: () => void,
	removeButtonWidth: number | null = 0,
	isGroupLoading = false
): VisTimelineGroupExtended => {
	let cells: GroupCell[] = [];
	if (group.rowCells) {
		cells = [...group.rowCells];

		cells = group.rowCells.map((cell: GroupCell) => {
			if (typeof cell === 'string') {
				return {
					content: (
						<span className={isGroupLoading ? 'loadingGroup' : ''} title={`${cell}`}>
							{cell}
						</span>
					)
				};
			}
			return cell;
		});
		if (removeButtonWidth) {
			const removeButtonCell = {
				content: <RemoveGroupButton removeGroup={onRemoveClicked} />,
				width: removeButtonWidth
			};

			cells = [removeButtonCell, ...cells];
		}

		const loader = {
			content: isGroupLoading ? <Spinner /> : <div />,
			width: 80
		};

		cells = [...cells, loader];
	}

	return {
		order: group.order,
		id: group.rowId,
		className: classnames(GANTT_ITEM_ROW_CLASS, `${GANTT_ITEM_ROW_CLASS}-${group.rowId}`, {
			'error-group': group.hasError
		}),
		content: group.rowId.toString(),
		dataCells: cells
	};
};
