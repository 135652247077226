import React, { useMemo } from 'react';

import { Checkbox } from '@bringg/react-components';
import { BringgFontIcons, BringgIcon } from '@bringg/bringg-icons';
import type { Cell, Row } from 'react-table';

import { SELECTION_COLUMN_ID } from '../../../consts';
import { AntCheckboxToggleRowsSelectedProps } from '../../../types';
import { TestIds } from 'bringg-web/consts';

const SELECTION_KEY = 'isSelected';
const MARGIN_STEP = 15;
const MARGIN_BASE = 15;

interface GroupSelection<T extends Record<string, unknown>> {
	row: Row<T>;
	selectionCell: Cell<T>;
	isSomeSelected?: boolean;
}

const GroupSelection = <T extends Record<string, unknown>>({
	row,
	selectionCell,
	isSomeSelected
}: GroupSelection<T>) => {
	const { style, ...rest } = selectionCell.getCellProps();
	const { indeterminate, ...restSelectedProps } =
		row.getToggleRowSelectedProps() as unknown as AntCheckboxToggleRowsSelectedProps;

	return (
		<div {...rest} className="group-selection-cell" style={style}>
			<Checkbox
				data-test-id={TestIds.GROUPED_ROW_CHECKBOX}
				{...restSelectedProps}
				indeterminate={indeterminate || isSomeSelected}
			/>
		</div>
	);
};

interface GroupedRowProps<T extends Record<string, unknown> = Record<string, unknown>> {
	row: Row<T>;
	isSomeSelected?: boolean;
}

const GroupedRow = <T extends Record<string, unknown> = Record<string, unknown>>(props: GroupedRowProps<T>) => {
	const { row, isSomeSelected } = props;
	const selectionCell: Cell<T> = row.allCells.find(({ column }) => column.id === SELECTION_COLUMN_ID);
	const shouldRenderSelection = row.hasOwnProperty(SELECTION_KEY) && selectionCell;
	// look for a better way to fix flex-box glitch
	// case when selection row must have width: 30px, but due to flex layout it has width: 29.03 i.e.
	const width = `calc(100% - ${selectionCell?.getCellProps().style?.width || 0})`;

	// No need to memo row.getRowProps since it will have the same dependencies which define
	// whether component should re-render or not, keep in mind it could change if new props are added
	const toggleRowExpandedProps = useMemo(
		() => row.getToggleRowExpandedProps({ style: { marginLeft: row.depth * MARGIN_STEP + MARGIN_BASE, width } }),
		[row, width]
	);

	return (
		<>
			{shouldRenderSelection && (
				<GroupSelection row={row} selectionCell={selectionCell} isSomeSelected={isSomeSelected} />
			)}
			<div {...toggleRowExpandedProps} className="table-data-cell">
				<BringgIcon
					className="table-group-row-icon"
					iconName={row.isExpanded ? BringgFontIcons.Chevron : BringgFontIcons.ChevronRight}
				/>
				{row.allCells
					.filter((cell: Cell<T>) => cell.isGrouped === true)
					.map(cell => (
						<span key={cell.column.id}>{cell.render('Group')}</span>
					))}
			</div>
		</>
	);
};

export default GroupedRow;
