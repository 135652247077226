import React from 'react';
import { observer } from 'mobx-react';
import { PrivilegeTypes } from '@bringg/types';
import { BringgFontIcons, BringgIcon } from '@bringg/bringg-icons';
import { Translate } from '../../../../translation';
import Company from '../../../../stores/companies/domain-objects/company';
import { Privilege } from '../../../../components';

interface Props {
	company: Company;
	toggleEditMode: () => void;
}

const CompanyCardInfo = ({ company, toggleEditMode }: Props) => {
	return (
		<div className="company-container">
			<div className="company-card-info">
				<div className="info-block">
					<div className="company-card-label">
						<Translate text="COMPANIES.ID" />
					</div>
					<div className="company-card-value" data-test-id="companyExternalIdValue">
						{company.external_id}
					</div>

					<Privilege privilegeName={PrivilegeTypes.EDIT_COMPANY}>
						<div className="company-edit" onClick={toggleEditMode}>
							<span>
								<Translate text="COMPANIES.EDIT" />
							</span>
							<BringgIcon iconName={BringgFontIcons.Pencil} className="card-button" key="icon" />
						</div>
					</Privilege>
				</div>

				<div className="info-block">
					<div className="company-card-label">
						<Translate text="COMPANIES.NAME" />
					</div>
					<div className="company-card-value" data-test-id="companyNameValue">
						{company.name}
					</div>
				</div>

				<div className="info-block">
					<div className="company-card-label">
						<Translate text="COMPANIES.PHONE" />
					</div>
					<div className="company-card-value" data-test-id="companyPhoneValue">
						{company.phone}
					</div>
				</div>

				<div className="info-block">
					<div className="company-card-label">
						<Translate text="COMPANIES.TAX_ID" />
					</div>
					<div className="company-card-value" data-test-id="companyTaxIdValue">
						{company.tax_id}
					</div>
				</div>

				<div className="info-block">
					<div className="company-card-label">
						<Translate text="COMPANIES.ADDRESS" />
					</div>
					<div className="company-card-value" data-test-id="compantAddressValue">
						{company.address}
					</div>
				</div>
			</div>
		</div>
	);
};

export default observer(CompanyCardInfo);
