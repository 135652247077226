import React from 'react';
import { Button, Tooltip } from 'antd';

import { VpnKey, VpnKeyFilled } from '../icons';

import './styles.scss';

export const TurnkeyButton = ({ title, isActive, onClick }: any) => (
	<Tooltip placement="top" title={title}>
		<Button
			className="icon-button"
			icon={isActive ? <VpnKeyFilled /> : <VpnKey />}
			shape="circle"
			onClick={onClick}
		/>
	</Tooltip>
);
