// TODO: fix eslint errors:
/*  eslint-disable class-methods-use-this */
/*  eslint-disable no-param-reassign */
import { getEnv, getRoot } from 'mobx-easy';
import { action, computed, observable, toJS, makeObservable } from 'mobx';
import { forEach as _forEach, uniq, merge as _merge } from 'lodash';
import { GROUP_VALUES } from '@bringg/dashboard-sdk/dist/Task/Tasks.consts';
import type { TaskGroupValue } from '@bringg/dashboard-sdk/dist/Task/Entity/TaskEntity';
import { Task as TaskType } from '@bringg/types';

import BaseDomainStore from '../core/base-domain-store';
import Task from './domain-object/task';
import { getValidWaypointsLatLng, isOpenTask, isPlanningTask } from './task-utils';
import { RootEnv } from '../root-env';
import { RootStore } from '../root-store-types';
import { APP_CONFIG } from '../other/consts/app-config';
import { emitAnyTaskRunUpdated } from './emit-task-run-updated';
import { getSharedRootStore } from '../root-store';

class TasksStore extends BaseDomainStore<Task> {
	isAllOpenTasksLoading = false;
	isPlanningTasksLoading = false;
	openTasks: Map<number, Task> = new Map();
	planningTasks: Map<number, Task> = new Map();
	isOpenTasksFetched = false;
	isPlanningTasksFetched = false;

	constructor() {
		super();

		makeObservable(this, {
			isAllOpenTasksLoading: observable,
			isPlanningTasksLoading: observable,
			openTasks: observable.shallow,
			planningTasks: observable.shallow,
			setOpenTasks: action,
			setOpenTask: action,
			setBatchTasks: action,
			removeOpenTask: action,
			allOpenTasks: computed,
			setIsAllOpenTasksLoading: action,
			allPlanningTasks: computed,
			setPlanningTasks: action,
			setIsPlanningTasksLoading: action
		});
	}

	afterUserLoggedIn() {
		const { dashboardSdk } = getEnv<RootEnv>();

		if (APP_CONFIG.TASKS_REAL_TIME_UPDATE) {
			this.subscribeToEvents();
		}

		dashboardSdk.sdk.tasks.attachSubscriptions();
	}

	afterUserLoggedOut() {
		const { dashboardSdk } = getEnv<RootEnv>();

		dashboardSdk.sdk.tasks.unsubscribe();
	}

	setOpenTasks = (tasks: Bringg.Task[]) => {
		_forEach(tasks, rawTask => {
			const task = new Task(rawTask);
			if (!this.openTasks.has(task.id)) {
				this.openTasks.set(task.id, task);
			}

			if (!this.has(task.id)) {
				this.set(task);
			}
		});
	};

	setOpenTask(task: Task) {
		if (!this.openTasks.has(task.id)) {
			this.openTasks.set(task.id, task);
		}

		if (!this.has(task.id)) {
			this.set(task);
		}
	}

	removeOpenTask(taskId) {
		if (this.openTasks.has(taskId)) {
			this.openTasks.delete(taskId);
		}
	}

	get allOpenTasks() {
		return Array.from(this.openTasks.values());
	}

	setIsAllOpenTasksLoading(loading: boolean) {
		this.isAllOpenTasksLoading = loading;
	}

	fetchAllOpenTasks = async (): Promise<TaskType[]> => {
		if (this.isOpenTasksFetched) {
			return;
		}
		this.setIsAllOpenTasksLoading(true);

		const { dashboardSdk } = getEnv<RootEnv>();
		const openTasks = await dashboardSdk.sdk.tasks.getOpenTasks();

		this.isOpenTasksFetched = true;
		// tasks/open AND tasks/planning response has `team_ids` field while tasks/batch_get has `teams_ids` instead
		// DB table has `teams_ids` and doesn't have `team_ids`
		this.setOpenTasks(openTasks);
		this.setIsAllOpenTasksLoading(false);
		return openTasks;
	};

	get allPlanningTasks() {
		return Array.from(this.planningTasks.values());
	}

	setPlanningTasks(tasks: Bringg.Task[]) {
		_forEach(tasks, rawTask => {
			const task = new Task(rawTask);
			if (!this.planningTasks.has(task.id)) {
				this.planningTasks.set(task.id, task);
			}

			if (!this.has(task.id)) {
				this.set(task);
			}
		});
	}

	setIsPlanningTasksLoading(loading: boolean): void {
		this.isPlanningTasksLoading = loading;
	}

	fetchAllPlanningTasks = async (additionalColumns?: string[]): Promise<TaskType[]> => {
		if (this.isPlanningTasksFetched) {
			return;
		}
		this.setIsPlanningTasksLoading(true);

		const { dashboardSdk } = getEnv<RootEnv>();
		const planningTasks = await dashboardSdk.sdk.tasks.getPlanningTasks({
			columns: additionalColumns.join(',')
		});
		this.isPlanningTasksFetched = true;
		this.setPlanningTasks(planningTasks);
		this.setIsPlanningTasksLoading(false);
		return planningTasks;
	};

	async fetch(taskId: number, force: boolean): Promise<Task> {
		if (!force && this.get(taskId)) {
			return this.get(taskId);
		}

		const { dashboardSdk } = getEnv<RootEnv>();
		const task = force ? await dashboardSdk.sdk.tasks.forceGet(taskId) : await dashboardSdk.sdk.tasks.get(taskId);

		task.way_points = getValidWaypointsLatLng(task.way_points || []);

		this.set(new Task(task));

		return this.get(taskId);
	}

	setBatchTasks = (rawTasks: Bringg.Task[]): Task[] => {
		const storeTasks = rawTasks.map(rawTask => new Task(rawTask));

		storeTasks.forEach(task => {
			this.items.set(task.id, task);
			if (isOpenTask(task)) {
				this.openTasks.set(task.id, task);
			} else if (isPlanningTask(task)) {
				this.planningTasks.set(task.id, task);
			}
		});

		return storeTasks;
	};

	batchGet = (taskIds: number[]): Task[] => {
		return taskIds.map(taskId => this.get(taskId)).filter(Boolean);
	};

	batchFetch = async (taskIds: number[]): Promise<Task[]> => {
		// Empty taskIds array somehow makes sdk.tasks.batchGet to return { success: false }
		const taskIdsToFetch = uniq(taskIds).filter(id => !this.items.has(id));
		if (!taskIdsToFetch.length) {
			return taskIds.map(taskId => this.get(taskId));
		}

		const {
			dashboardSdk: { sdk }
		} = getEnv<RootEnv>();
		// Warning! Can fail due to exceeded GET request body size
		const tasks = await sdk.tasks.batchGet(taskIdsToFetch);
		// batchFetch doesn't get customer_name field for the tasks
		this.setBatchTasks(tasks);

		return this.batchGet(taskIds);
	};

	async saveEdit(taskId: number, diff: Partial<Task>) {
		const { dashboardSdk } = getEnv<RootEnv>();

		const updatedTask = await dashboardSdk.sdk.tasks.update(taskId, diff);

		this.get(taskId).update(updatedTask);

		// `this.items` and `this.openTasks` not using the same object reference for Tasks
		// ideally `openTasks` should you the same object thats used in `this.items`
		// so when the task in `items` updated => task in `openTask` should updated automatically without this hack from below
		// looks like the problem in `setOpenTasks` method
		if (this.openTasks.has(taskId)) {
			this.openTasks.get(taskId).update(updatedTask);
		}
	}

	async duplicateTask(taskId: number): Promise<number> {
		const { dashboardSdk } = getEnv<RootEnv>();
		const duplicatedTask = await dashboardSdk.sdk.tasks.duplicate(taskId);
		const { id } = duplicatedTask;
		this.set(new Task(duplicatedTask));

		return id;
	}

	subscribeToEvents() {
		const { dashboardSdk } = getEnv<RootEnv>();

		dashboardSdk.sdk.tasks.onCreate((task: Bringg.Task) => {
			if (!this.get(task.id)) {
				this.set(new Task(task));
			}
		});

		dashboardSdk.sdk.tasks.onDelete((taskId: number) => {
			this.remove(taskId);

			this.removeOpenTask(taskId);
		});
		// @ts-ignore
		dashboardSdk.sdk.tasks.subscribeToGroupUpdates((groupValues: TaskGroupValue) => {
			const updatedOpenTasksIds = groupValues.get(GROUP_VALUES.Open);
			if (updatedOpenTasksIds) {
				this.handleOpenTasksUpdate(updatedOpenTasksIds);
			}
		});

		dashboardSdk.sdk.tasks.onUpdate(async (task: Bringg.Task) => {
			// the `this.get()` will return to us an MobX object with Proxy
			// object with proxy fields merged takes around 8 seconds
			// before merge this need to be converted to simple JSON

			if (this.get(task.id)) {
				const localTask = toJS(this.get(task.id));

				const updatedTask = _merge({}, localTask, task);
				this.set(new Task(updatedTask));
				const { id, ready_to_execute } = localTask;

				if (!ready_to_execute && this.openTasks.has(id)) {
					this.openTasks.delete(id);
				}

				await getSharedRootStore().data.taskInventoriesStore.getTaskInventoriesRejectDetails(task.id);

				if (localTask.run_id !== task.run_id) {
					emitAnyTaskRunUpdated();
				}
			} else {
				// Need to watch if this can cause any problems
				// For most cases we can treat task::updated payload the same as task::created
				// except when "status" is not sent
				if (Object.prototype.hasOwnProperty.call(task, 'status')) {
					this.setBatchTasks([task]);
				}
			}
		});
	}

	private handleOpenTasksUpdate(updatedOpenTasksIds: number[]) {
		updatedOpenTasksIds.forEach(updatedTaskId => {
			if (!this.openTasks.has(updatedTaskId) && this.has(updatedTaskId)) {
				this.setOpenTask(this.get(updatedTaskId));
			}
		});
	}
}

export default TasksStore;
