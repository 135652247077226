import { getRootEnv } from '@bringg-frontend/bringg-web-infra';
import { computed, observable, makeObservable } from 'mobx';
import { getEnv, getRoot } from 'mobx-easy';
import _isEmpty from 'lodash/isEmpty';
import _map from 'lodash/map';
import { DeliveryBlockRecharge, DeliveryBlockResource, PrivilegeTypes, Vehicle } from '@bringg/types';
import { RootEnv } from '@bringg-frontend/bringg-web-infra';

import DeliveryBlocksStore from '../delivery-blocks-store';
import BaseViewModelDomainObject from 'bringg-web/stores/base-view-model-domain-object';
import DeliveryBlockBreak from './delivery-block-break';
import Driver from 'bringg-web/stores/drivers/domain-object/driver';
import Team from 'bringg-web/stores/teams/domain-object/team';
import RootStore from 'bringg-web/stores/root-store';

const DELIVERY_BLOCKS_ENTITY = 'deliveryBlocks';

class DeliveryBlock extends BaseViewModelDomainObject<DeliveryBlock> {
	id: number = null;
	team_id: number;
	name = '';
	description = '';
	start_time: string = null;
	end_time: string = null;
	capacity = 0;
	original_capacity = 0;
	break_start_time: string = null;
	break_end_time: string = null;
	delivery_block_schedule_id?: number;
	users: Partial<Bringg.User>[] = [];
	user_ids: number[] = [];
	ical = '';
	delivery_block_breaks: DeliveryBlockBreak[] = [];
	delivery_block_resources: DeliveryBlockResource[] = [];
	delivery_block_recharges: DeliveryBlockRecharge[] = [];
	threshold: number;

	constructor(store: DeliveryBlocksStore, deliveryBlock?: Partial<Bringg.DeliveryBlock> | Partial<DeliveryBlock>) {
		super(store, DELIVERY_BLOCKS_ENTITY);

		makeObservable(this, {
			team_id: observable,
			name: observable,
			description: observable,
			start_time: observable,
			end_time: observable,
			capacity: observable,
			original_capacity: observable,
			break_start_time: observable,
			break_end_time: observable,
			delivery_block_schedule_id: observable,
			users: observable,
			user_ids: observable,
			threshold: observable,
			ical: observable,
			delivery_block_breaks: observable.shallow,
			delivery_block_resources: observable.shallow,
			hasBreak: computed,
			isRecurring: computed,
			isDeletable: computed,
			isEditable: computed,
			hasFlexBreak: computed,
			team: computed,
			teamLocalizationTimezone: computed,
			assignedDrivers: computed,
			asJson: computed,
			assignedDriversByResources: computed,
			assignedVehicles: computed
		});

		this.set(deliveryBlock);
	}

	set(deliveryBlock: Partial<DeliveryBlock> | Partial<Bringg.DeliveryBlock>) {
		super.set(deliveryBlock as DeliveryBlock);

		if (deliveryBlock && deliveryBlock.delivery_block_breaks) {
			this.delivery_block_breaks = _map(deliveryBlock.delivery_block_breaks, blockBreak => {
				const deliveryBlockBreak = new DeliveryBlockBreak();
				deliveryBlockBreak.setDeliveryBlockBreak(blockBreak as DeliveryBlockBreak);
				return deliveryBlockBreak;
			});
		}
	}

	get hasBreak() {
		return Boolean(this.break_end_time && this.break_start_time) || !_isEmpty(this.delivery_block_breaks);
	}

	get isRecurring() {
		return Boolean(this.ical);
	}

	get isDeletable() {
		return _isEmpty(this.user_ids);
	}

	get isEditable() {
		return getRoot<RootStore>().data.usersStore.hasAccess(PrivilegeTypes.EDIT_DELIVERY_BLOCK);
	}

	get hasFlexBreak(): boolean {
		return this.delivery_block_breaks.some(deliveryBlockBreak => deliveryBlockBreak.isFlexBreak);
	}

	create = async (json: Partial<DeliveryBlock>) => {
		const response = await getRootEnv().dashboardSdk.sdk[this.entityName].create(json);
		this.set(response);

		if (!this.isRecurring) {
			this.store.set(this);
		}

		return response;
	};

	get team(): Team {
		return getRoot<RootStore>().data.teamsStore.get(this.team_id);
	}

	get teamLocalizationTimezone() {
		return this.team.localizationTimezone;
	}

	get assignedDrivers(): Driver[] {
		const { driversStore } = getRoot<RootStore>().data;
		return this.user_ids.map(driverId => driversStore.get(driverId));
	}

	get assignedDriversByResources(): Map<number, Driver> {
		const { driversStore } = getRoot<RootStore>().data;
		const assignedDrivers = new Map<number, Driver>();
		this.delivery_block_resources.forEach(resource => {
			if (resource.user_id) {
				const driver = driversStore.get(resource.user_id);
				if (driver) {
					assignedDrivers.set(resource.user_id, driver);
				}
			}
		});
		return assignedDrivers;
	}

	get assignedVehicles(): Map<number, Vehicle> {
		const { vehiclesStore, vehicleTypesStore } = getRoot<RootStore>().data;
		const assignedVehicles = new Map<number, Vehicle>();
		this.delivery_block_resources.forEach(resource => {
			if (resource.vehicle_id) {
				const vehicle: Vehicle = vehiclesStore.get(resource.vehicle_id);
				if (vehicle) {
					const vehicleWithType: Vehicle = {
						...vehicle,
						vehicle_type: vehicleTypesStore.get(vehicle.vehicle_type_id)
					};
					assignedVehicles.set(resource.vehicle_id, vehicleWithType);
				}
			}
		});
		return assignedVehicles;
	}

	get asJson(): Partial<DeliveryBlock> {
		const jsonObject = {
			id: this.id,
			team_id: this.team_id,
			name: this.name,
			description: this.description,
			start_time: this.start_time,
			end_time: this.end_time,
			capacity: this.capacity,
			original_capacity: this.original_capacity,
			break_start_time: this.break_start_time,
			break_end_time: this.break_end_time,
			delivery_block_schedule_id: this.delivery_block_schedule_id,
			users: this.users,
			user_ids: this.user_ids,
			delivery_block_breaks: this.delivery_block_breaks,
			delivery_block_resources: this.delivery_block_resources,
			delivery_block_recharges: this.delivery_block_recharges
		};

		// server does not accept empty ical
		if (this.ical) {
			return { ...jsonObject, ical: this.ical };
		}
		return jsonObject;
	}
}

export default DeliveryBlock;
