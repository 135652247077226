import React from 'react';

import classNames from 'classnames';
import { BringgFontIcons, BringgIcon } from '@bringg/bringg-icons';

import { Translation } from '../../types';

import style from './failure-row-header.module.scss';

interface Props {
	translations: Pick<Translation, 'allFailed' | 'someFailed'>;

	totalNumberOfActions: number;

	isCompleted: boolean;
	numberOfFailures: number;
}

const FailureRowHeader = ({ translations, totalNumberOfActions, isCompleted, numberOfFailures }: Props) => {
	const isAllFailures = isCompleted && numberOfFailures === totalNumberOfActions;

	return (
		<div className={style.row}>
			<BringgIcon iconName={BringgFontIcons.WarningCircle} className={style.iconStatus} />

			<span className={style.text}>
				<span
					className={classNames(style.numberOfItems, {
						[style.completed]: isCompleted
					})}
				>
					{numberOfFailures}
				</span>
				{isAllFailures ? translations.allFailed : translations.someFailed}
			</span>
		</div>
	);
};

export default FailureRowHeader;
