import React from 'react';
import { Col, Row } from 'antd';
import { BringgFontIcons, BringgIcon } from '@bringg/bringg-icons';

interface Props {
	onDeleteField: () => void;
	title: string;
	isExtended: boolean;
}
const ActionFormDataHeader = ({ onDeleteField, title, isExtended }: Props) => {
	return (
		<Row className="field-panel-header" data-test-id="action-data-field-header">
			<Col span={2}>
				<div className="draggable-form-icon">
					<BringgIcon iconName={BringgFontIcons.Menu} />
				</div>
			</Col>
			<Col span={20} className="header-title">
				{title}
				<BringgIcon
					iconName={isExtended ? BringgFontIcons.ChevronUp : BringgFontIcons.Chevron}
					className="collapse-icon"
				/>
			</Col>
			<Col span={2}>
				<div className="delete-form-icon" onClick={onDeleteField} data-test-id="action-data-delete-field">
					<BringgIcon iconName={BringgFontIcons.Close} />
				</div>
			</Col>
		</Row>
	);
};

export default ActionFormDataHeader;
