import React from 'react';
import { Collapse, Table, Tag, Spinner } from '@bringg/react-components';
import { Link } from 'react-router-dom';
import './delivery-catalog-connect-data-setup.scss';
import { useObserver } from 'mobx-react';
import { useStores } from '../../../../recipes';
import { useTranslation } from 'react-i18next';

// eslint-disable-next-line react/prop-types
const TaskFieldsTable = ({ title, data }) => {
	const { t } = useTranslation();
	const { Panel } = Collapse;
	const columns = [
		{
			title: 'Bringg Field',
			dataIndex: 'bringgField',
			key: 'bringgField',
			width: '20%'
		},
		{
			title: 'Bringg Field description',
			dataIndex: 'bringgFieldDescription',
			key: 'bringgFieldDescription',
			ellipsis: true,
			width: '45%'
		},
		{
			title: 'Bringg fallbacks',
			dataIndex: 'bringgFallBacks',
			key: 'bringgFallBacks',
			width: '23%'
		},
		{
			title: 'Data availability',
			dataIndex: 'dataAvailability',
			key: 'dataAvailability',
			className: 'column-data-availability',
			width: '12%',
			render: color => {
				return (
					<Tag type={color} key="dataAvailability" className="data-availability">
						{color === 'green' ? 'Available' : 'No Data'}
					</Tag>
				);
			}
		}
	];

	return (
		<div className="task-fields-table-container">
			<h1>{title}</h1>
			<Table
				isTableWrapper={false}
				locale={{
					emptyText: (
						<div className="empty-text">
							<p>{t('DATA_FIELDS.EMPTY_TEXT_TABLE_EXISTING_FIELDS')}</p>
							<div className="right-icon" />
						</div>
					)
				}}
				tableLayout="fixed"
				style={{ whiteSpace: 'pre' }}
				columns={columns}
				/* eslint-disable-next-line react/prop-types */
				dataSource={data.missingFields}
				pagination={false}
			/>
			<Collapse isBringgCollapse={false}>
				<Panel
					header={
						<div className="panel-container">
							<p>Fields With Data</p>
							<div className="right-icon" />
						</div>
					}
					key="1"
				>
					<Table
						isTableWrapper={false}
						locale={{ emptyText: <p>{t('DATA_FIELDS.EMPTY_TEXT_TABLE_MISSING_FIELDS')}</p> }}
						tableLayout="fixed"
						style={{ whiteSpace: 'pre' }}
						columns={columns}
						/* eslint-disable-next-line react/prop-types */
						dataSource={data.existingFields}
						pagination={false}
						showHeader={false}
					/>
				</Panel>
			</Collapse>
		</div>
	);
};

const DeliveryCatalogDataSetup = () => {
	const { deliveryCatalogStore } = useStores();
	const { dataFields } = deliveryCatalogStore;

	const content = () => {
		if (!dataFields.orderId) {
			return (
				<div className="no-order">
					<h1>No orders in the system yet</h1>
					<p>
						Send at least one order to BRINGG via API or CSV to view the list of mandatory and optional
						fields. After uploading successfully, refresh this page.
					</p>
				</div>
			);
		}

		return (
			<>
				<div className="order-id">
					<p>Data based on order ID:</p>
					<div className="order-id-select">
						{dataFields.orderId}
						<Link
							to={`/history/${dataFields.orderId}`}
							target="_blank"
							className="link-container external-link"
						>
							<p>order ID page</p>
							<div className="external-link-icon" />
						</Link>
						<div />
					</div>
				</div>
				<div className="task-fields">
					<TaskFieldsTable title="Mandatory fields" data={dataFields.mandatory} />
					<TaskFieldsTable title="Optional fields" data={dataFields.optional} />
				</div>
			</>
		);
	};

	return useObserver(() => {
		if (deliveryCatalogStore.isDataFieldsFetching || !dataFields) return <Spinner className="spinner-center" />;

		return (
			<div className="delivery-catalog-connect-data-setup">
				<div className="delivery-catalog-connect-body">
					<div className="header-container">
						<div className="header-top">
							<div>
								<h1>Order Fields</h1>
							</div>
							<div>
								<a
									href="https://developers.bringg.com/reference/create-task"
									target="_blank"
									rel="noopener noreferrer"
									className="link-container"
								>
									<div className="link-icon" />
									<span>Learn more</span>
								</a>
							</div>
						</div>
						<p>
							To connect to this DP, confirm that you`re payloads include all mandatory fields. If a field
							has No Data, update your payload or CSV with the missing field. Then, upload the new order
							to Bringg and confirm that the data is now Available for Bringg to use.
						</p>
					</div>
					{content()}
				</div>
			</div>
		);
	});
};

export default DeliveryCatalogDataSetup;
