import { action, computed, observable, makeObservable } from 'mobx';

import LoginLogoutReaction from './login-logout-reaction';

interface BaseId<T> {
	id?: T;
}

class BaseDomainStore<T extends BaseId<I>, I = T['id']> extends LoginLogoutReaction {
	items: Map<I, T> = new Map();

	constructor() {
		super();

		makeObservable(this, {
			items: observable.shallow,
			set: action,
			setBatch: action,
			all: computed,
			count: computed,
			remove: action
		});
	}

	set = (item: T) => {
		this.items.set(item.id, item);
	};

	setBatch = (items: T[]) => {
		items.forEach(item => {
			this.items.set(item.id, item);
		});
	};

	get all() {
		return Array.from(this.items.values());
	}

	get count(): number {
		return this.items.size;
	}

	remove = (id: I) => {
		this.items.delete(id);
	};

	get = (id: I) => {
		return this.items.get(id);
	};

	has = (id: I) => {
		return this.items.has(id);
	};
}

export default BaseDomainStore;
