import { datetime, TaskInventory, TaskNote, TaskStatus } from '@bringg/types';
import { Task as BringgTask, WayPoint } from '@bringg/dashboard-sdk/dist/ApiTypes/ApiTypes';
import { observable, makeObservable } from 'mobx';
import { SharedLocation } from '@bringg/types';

interface Task extends BringgTask {}

class Task {
	team_ids: number[] = [];
	user_id: number = null;
	tag_id: number = null;
	external_id: string = null;
	required_skills: string[] = [];
	fleet_id: number = null;
	task_notes: TaskNote[] = [];
	task_inventories: TaskInventory[] = [];
	dispatcher_id: number = null;
	status: TaskStatus = TaskStatus.Created;
	shared_locations: SharedLocation[] = [];
	way_points: WayPoint[] = [];
	updated_at: datetime;
	title: string = null;
	address: string = null;
	ready_to_execute: boolean = null;

	constructor(task: BringgTask) {
		makeObservable(this, {
			team_ids: observable,
			user_id: observable,
			tag_id: observable,
			external_id: observable,
			required_skills: observable,
			fleet_id: observable,
			task_notes: observable,
			task_inventories: observable,
			dispatcher_id: observable,
			status: observable,
			shared_locations: observable,
			way_points: observable,
			updated_at: observable,
			title: observable,
			address: observable,
			ready_to_execute: observable
		});

		Object.assign(this, task);
	}

	update(task: BringgTask) {
		Object.assign(this, task);
	}
}

export default Task;
