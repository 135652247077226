import { getRootEnv } from '@bringg-frontend/bringg-web-infra';
import { action, computed, observable, makeObservable } from 'mobx';

const SYSTEM_CREATED_TAG_NAMES = ['Bringg Dashboard', 'TaskCreatorExecutor', 'Service'];

class TagsStore {
	isFetched = false;
	tags: Map<number, Bringg.Tag> = new Map();

	constructor() {
		makeObservable(this, {
			isFetched: observable,
			setIsFetched: action,
			tags: observable.shallow,
			add: action,
			getAll: computed
		});
	}

	setIsFetched = isFetched => {
		this.isFetched = isFetched;
	};

	add = (tag: Bringg.Tag) => {
		this.tags.set(tag.id, tag);
	};

	get getAll() {
		return Array.from(this.tags.values());
	}

	get getAllWithoutSystemCreated() {
		return Array.from(this.tags.values()).filter(tag => !SYSTEM_CREATED_TAG_NAMES.includes(tag.tag));
	}

	async fetchAll() {
		const result: any = await getRootEnv().dashboardSdk.sdk.tags.getAll();

		result.map(this.add);

		this.setIsFetched(true);
	}

	get(id: number) {
		return this.tags.get(id);
	}
}

export default TagsStore;
