import type { Cell } from 'react-table';
import type { Row, IdType } from 'react-table';

const shallowDiffers = <T extends object>(prev: T, next: T) => {
	const prevAttributes = Object.keys(prev);
	for (const attribute of prevAttributes) {
		if (!(attribute in next)) {
			return true;
		}
	}
	const nextAttributes = Object.keys(next);
	for (const attribute of nextAttributes) {
		if (prev[attribute] !== next[attribute]) {
			return true;
		}
	}
	return false;
};

const cellsDiffer = (prevCells: Cell[], nextCells: Cell[]): boolean => {
	if (prevCells.length !== nextCells.length) {
		return true;
	}
	return nextCells.some((nextCell, index) => nextCell.column.id !== prevCells[index].column.id);
};

// calculated difference between all columns width and table container width
const getTableAndColumnsWidthDiff = (columns, tableContainerWidth): number => {
	const columnsWidth = columns.reduce((acc, item) => acc + item.width, 0);

	return tableContainerWidth - columnsWidth;
};

function alphanumericCaseInsensitiveSortFunction<T extends object>(rowA: Row<T>, rowB: Row<T>, columnId: IdType<T>) {
	const valueA = rowA.values[columnId];
	const valueB = rowB.values[columnId];

	if (typeof valueA === 'number' && typeof valueB === 'number') {
		return valueA - valueB;
	} else {
		// this block for supporting native sorting by comparing values one to each other
		return String(valueA)?.localeCompare(String(valueB));
	}
}

export { shallowDiffers, cellsDiffer, getTableAndColumnsWidthDiff, alphanumericCaseInsensitiveSortFunction };
