import { getRootEnv } from '@bringg-frontend/bringg-web-infra';
import { action, computed, observable, runInAction, makeObservable } from 'mobx';
import { keyBy as _keyBy, partition as _partition, orderBy as _orderBy } from 'lodash';
import { Attribute, getRandomGuid } from '@bringg/react-components/dist/features/conditions/rule/rule-utils';
import { Entity, RuleCategory, RuleData, MetadataResponse } from '@bringg/types';

import { getAttributes, getFlatAttribute, mapRulesFromServer, mapRulesToServer } from '../../rule-engine/utils/mapper';
import { Rule } from '../../rule-engine/rule-types';
import { validate } from '../../rule-engine/utils/condition-validator';
import ChangeTracker from '../../rule-engine/utils/change-tracker';

interface SaveResponse {
	created: RuleData[];
	updated: RuleData[];
	deleted: number[];
}

class ConditionsSetStore {
	rules: Rule[] = [];
	attributes: Attribute[] = [];
	changeTracker = ChangeTracker<Rule>();
	attributesByPath: { [path: string]: Attribute };
	fleetId: number;
	deletedRules: { [id: number]: boolean } = {};
	metadata: MetadataResponse;

	constructor() {
		makeObservable(this, {
			rules: observable,
			attributes: observable,
			removeRule: action,
			updateRule: action,
			addRule: action,
			discardChanges: action,
			setUpdatedRules: action,
			setCreatedRules: action,
			updateRulesAfterSave: action,
			rulesAreValid: computed,
			isDirty: computed
		});
	}

	load = async fleetId => {
		this.cleanState();
		this.fleetId = fleetId;
		const { sdk } = getRootEnv().dashboardSdk;
		const rulesPromise = sdk.rules.getRules(RuleCategory.FleetRouterDeliveryTerm, Entity.Fleet, fleetId);
		const metadataPromise = this.metadata
			? this.metadata
			: sdk.rules.getMetadata({ category_id: RuleCategory.FleetRouterDeliveryTerm });
		const [metadata, rules] = await Promise.all([metadataPromise, rulesPromise]);
		this.metadata = metadata;
		const sortedRulesById = _orderBy(rules, ['id']);

		runInAction(() => {
			this.attributes = getAttributes(metadata, true);
			this.attributesByPath = getFlatAttribute(this.attributes);
			this.rules = mapRulesFromServer(sortedRulesById, this.attributesByPath);
			this.changeTracker.setOriginData(this.rules);
		});
	};

	cleanState = () => {
		this.deletedRules = {};
	};

	removeRule = rule => {
		this.rules = this.rules.filter(r => r.guid !== rule.guid);
		if (rule.id) {
			this.deletedRules[rule.id] = true;
		}
	};

	updateRule = updatedRule => {
		this.rules = this.rules.map(rule => (rule.guid === updatedRule.guid ? { ...updatedRule } : rule));
	};

	addRule = () => {
		const newRule = {
			conditions: [],
			guid: getRandomGuid(),
			enabled: true,
			hardConstraint: false
		};
		this.rules = [...this.rules, newRule];
		return newRule;
	};

	discardChanges = () => {
		this.rules = this.changeTracker.getOriginData();
		this.cleanState();
	};

	getPayload = () => ({
		key: {
			entity: Entity.Fleet,
			entity_id: Number(this.fleetId)
		},
		category_id: RuleCategory.FleetRouterDeliveryTerm
	});

	saveRules = async () => {
		const { sdk } = getRootEnv().dashboardSdk;
		const payload = this.getPayload();
		const [exist, created] = _partition(this.rules, rule => !!rule.id);
		const updated = exist.filter(rule => this.changeTracker.isItemDirty(rule));
		const response = await sdk.rules.saveRules({
			created: mapRulesToServer(created, payload),
			updated: mapRulesToServer(updated),
			deleted: Object.keys(this.deletedRules).map(id => Number(id))
		});
		this.updateRulesAfterSave(response as unknown as SaveResponse);
	};

	setUpdatedRules = (updated: RuleData[]) => {
		const mappedUpdatedRulesById = _keyBy(mapRulesFromServer(updated, this.attributesByPath), 'id');
		this.rules = this.rules
			.filter(rule => !!rule.id)
			.map(rule => (mappedUpdatedRulesById[rule.id] ? mappedUpdatedRulesById[rule.id] : rule));
	};

	setCreatedRules = (created: RuleData[]) => {
		const mappedCreatedRules = mapRulesFromServer(created, this.attributesByPath);
		this.rules = [...this.rules, ...mappedCreatedRules];
	};

	updateRulesAfterSave = ({ created, updated, deleted }) => {
		this.setUpdatedRules(updated);
		this.setCreatedRules(created);
		this.changeTracker.setOriginData(this.rules);
		deleted.forEach(id => {
			delete this.deletedRules[id];
		});
	};

	get rulesAreValid() {
		return !this.rules.some(rule => !validate(rule.conditions));
	}

	get isDirty() {
		return this.changeTracker.isDirty(this.rules);
	}
}

export default ConditionsSetStore;
