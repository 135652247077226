import { ConditionType } from '@bringg/react-components';
import { DISPLAY_PATH_SEPARATOR } from './mapper';
import { MappedAttribute } from './types';

export const formatAttributeToLowerCase = (attributePath): string => {
	if (!attributePath) {
		return '';
	}
	return attributePath
		.split(DISPLAY_PATH_SEPARATOR)
		.map(attr => (attr.toUpperCase() === attr ? attr : attr.toLowerCase()))
		.join(DISPLAY_PATH_SEPARATOR);
};

const mappedSupportedTypes = new Set([
	ConditionType.LIST,
	ConditionType.BOOLEAN,
	ConditionType.NUMBER,
	ConditionType.STRING,
	ConditionType.ARRAY,
	ConditionType.STRING_ARRAY,
	ConditionType.NUMBER_ARRAY,
	ConditionType.BOOLEAN_ARRAY
]);

const isAttributeSupported = (attribute: MappedAttribute): boolean => {
	return mappedSupportedTypes.has(attribute.type) && (!attribute.options || attribute.options.length > 0);
};

const isAttributeComparable = (attribute: MappedAttribute, getComparableList): boolean =>
	getComparableList(attribute.path)?.length > 0;

const filterAttributes = (attributes: MappedAttribute[], predicate): MappedAttribute[] => {
	const getNestedAttributes = (result, attribute) => {
		if (predicate(attribute)) {
			result.push(attribute);
			return result;
		}
		if (attribute.items) {
			const attrs = attribute.items.reduce(getNestedAttributes, []);
			if (attrs.length) {
				result.push({ ...attribute, items: attrs });
			}
		}
		return result;
	};
	return attributes.reduce(getNestedAttributes, []);
};

export const filterUnsupportedAttributes = (
	attributes: MappedAttribute[],
	getComparableList: (path: string) => string[]
): MappedAttribute[] =>
	filterAttributes(
		attributes,
		attribute => isAttributeSupported(attribute) || isAttributeComparable(attribute, getComparableList)
	);

export const filterIncomparableAttributes = (attributes: MappedAttribute[], comparable: string[]): MappedAttribute[] =>
	filterAttributes(attributes, attribute => comparable.includes(attribute.path));
