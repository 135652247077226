import React from 'react';

import { Modal } from 'antd';
import { CustomModal } from '@bringg/react-components';
import _intersection from 'lodash/intersection';

import notification from '../../../services/notification';
import useFleetConfigurationStore from '../use-fleet-configuration-store';
import { useFleetsConfigurationTranslations } from '../use-fleet-configuration-translations';
import { Rule } from '../../../stores/fleets-configuration/fleets-configurations-store';
import RemoveTeamsModal, { ConflictedTeams } from '../remove-teams-modal/remove-teams-modal';
import { getConflictRulesByTeamId } from '../fleets-configuration';
import useTeamsById from '../use-teams-by-id';

interface PatchedRule {
	fleet_router_rules_group_id: string;
	rule_teams?: {
		team_ids: {
			removed: string[];
		};
	};
	enabled?: boolean;
}

export const getRemovedTeams = (firstRule: Rule, secondRule: Rule) => {
	const ruleTeams = Object.keys(firstRule.selectedTeams).filter(teamId => !!firstRule.selectedTeams[teamId]);
	const currentRuleTeams = Object.keys(secondRule.selectedTeams).filter(teamId => !!secondRule.selectedTeams[teamId]);
	return {
		fleet_router_rules_group_id: secondRule.id,
		rule_teams: {
			team_ids: {
				removed: _intersection(ruleTeams, currentRuleTeams)
			}
		}
	};
};

export const formatRulesToServer = (rule: Rule, rules: Rule[]) => {
	const mappedRules: PatchedRule[] = rules.map(currentRule => getRemovedTeams(rule, currentRule));

	mappedRules.push({
		fleet_router_rules_group_id: rule.id,
		enabled: true
	});
	return mappedRules;
};

const useConflictRulesModalOld = (rule: Rule) => {
	const { fleetsConfigurationStore } = useFleetConfigurationStore();
	const { networkError, disableRulesModalTeamsLevel, disableRulesModalMerchantLevel, removeTeamsModal } =
		useFleetsConfigurationTranslations();
	const { isOpen: isModalOpen, open: openModal, close: closeModal } = CustomModal.useModalState(false);
	const { teams } = useTeamsById();

	const formatConflictsToModal = (conflictedTeams: Map<string, (Rule | string)[]>) => {
		return Object.keys(rule.selectedTeams)
			.filter(teamId => !!rule.selectedTeams[teamId] && conflictedTeams.get(teamId))
			.map(teamId => {
				return {
					id: teamId,
					name: teams[teamId].name,
					rules: conflictedTeams.get(teamId)
				};
			});
	};

	const onRemoveTeams = () => {
		const conflictedTeams = getConflictRulesByTeamId(rule, fleetsConfigurationStore.rules);
		const conflicts = formatConflictsToModal(conflictedTeams) as ConflictedTeams[];
		let modal;
		const args = {
			rule,
			translations: removeTeamsModal,
			teams: conflicts,
			isVisible: true,
			onRemoveTeams: async () => {
				try {
					const mappedRules = formatRulesToServer(
						rule,
						fleetsConfigurationStore.conflictedRules[rule.id] || []
					);

					await fleetsConfigurationStore.patchRules(mappedRules);
				} catch (err) {
					notification.error(networkError);
				} finally {
					modal.destroy();
					closeModal();
				}
			},
			onCancel: () => {
				modal.destroy();
			}
		};

		modal = Modal.confirm({
			content: <RemoveTeamsModal {...args} />
		});
	};

	const onDisableRules = async () => {
		const rules = fleetsConfigurationStore.getConflictRulesIfRuleWouldEqual(rule)[rule.id] || [];
		const mappedRules = rules.map(currentRule => ({
			fleet_router_rules_group_id: currentRule.id,
			enabled: false
		}));
		mappedRules.push({
			fleet_router_rules_group_id: rule.id,
			enabled: true
		});
		try {
			await fleetsConfigurationStore.patchRules(mappedRules);
		} catch (err) {
			notification.error(networkError);
		} finally {
			closeModal();
		}
	};

	const onCancel = () => {
		closeModal();
	};

	return {
		isModalOpen,
		openModal,
		translations: rule?.isMerchantLevel ? disableRulesModalMerchantLevel : disableRulesModalTeamsLevel,
		onCancel,
		onDisableRules,
		onRemoveTeams
	};
};

export default useConflictRulesModalOld;
