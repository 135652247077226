import { getRootEnv } from '@bringg-frontend/bringg-web-infra';
import type { ChangedBy, ConfigurationFilterOptions } from '@bringg/dashboard-sdk/dist/Reports/Service/reports.service';

export const BRINGG_ADMIN = { name: 'Bringg Admins', id: -1 };

const getFilterOptions = async (name: string) => {
	const reportsService = getRootEnv().dashboardSdk.sdk.reports;
	const options: ConfigurationFilterOptions = await reportsService.getConfigurationsFilterOptions();

	if (name === 'changedBy') {
		return options.changedBy.map((option: ChangedBy) => {
			if (option.isAdmin) {
				return {
					...option,
					id: `${option.id}|admin`
				};
			}

			return option;
		});
	}

	return options[name];
};

const filterOptions = async (name: string) => {
	const result = await getFilterOptions(name);

	if (name === 'changedBy') {
		result.unshift(BRINGG_ADMIN);
		return result.map((item: { id: number; name: string; isAdmin: boolean }) => {
			return {
				value: item.id,
				label: item.name
			};
		});
	}
	return result.map((item: string) => {
		return {
			value: item,
			label: item
		};
	});
};

export default filterOptions;
