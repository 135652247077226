import { default as parsePhoneNumber, type MetadataJson, type CountryCode } from 'libphonenumber-js/core';

import customMetadata from '../../../../metadata/libphonenumber-js.metadata.json';

type Options = {
	countryCode?: string;
	format?: 'INTERNATIONAL' | 'NATIONAL';
};

export class PhoneNumberService {
	formatPhoneNumber = (phone: string, options?: Options): string => {
		if (!phone) {
			return phone;
		}

		const { format, countryCode } = options || { format: 'INTERNATIONAL' };
		const parsed = parsePhoneNumber(phone, countryCode as CountryCode, customMetadata as MetadataJson);

		if (!parsed || !parsed.isValid()) {
			return phone;
		}

		return format === 'NATIONAL' ? parsed.formatNational() : parsed.formatInternational();
	};
}

export const phoneNumberService = new PhoneNumberService();
