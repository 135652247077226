import { action, computed, makeObservable, observable } from 'mobx';
import { ConditionProperties, RuleCompareOperator } from '@bringg/types';
import { ConditionType } from '@bringg/react-components';
import { operatorsWithNoValue } from '../utils/use-options';
import { ConditionBaseStore, RuleStore } from './internal';
import { ConditionValues } from '../utils/types';

class ConditionStore extends ConditionBaseStore {
	_value: ConditionValues;
	operator: RuleCompareOperator;
	parent: RuleStore;

	constructor(parent: RuleStore, condition?: Partial<ConditionProperties>, guid?: number) {
		super(condition);
		makeObservable(this, {
			_value: observable,
			operator: observable,
			setValue: action,
			setFromValue: action,
			setToValue: action,
			setPath: action,
			setOperator: action,
			value: computed,
			isValid: computed
		});
		if (guid) {
			this.guid = guid;
		}
		this.parent = parent;
		this._value = condition?.value;
		this.operator = condition?.operator as RuleCompareOperator;
	}

	setValue = newValue => {
		this._value = newValue;
	};

	setFromValue = fromValue => {
		this._value = [fromValue, this._value[1]];
	};

	setToValue = toValue => {
		this._value = [this._value[0], toValue];
	};

	setPath = newPath => {
		this._path = newPath;
		this.parent.setAndRuleInstance(this);
	};

	setOperator = newOperator => {
		this.operator = newOperator;
		if (newOperator === RuleCompareOperator.IN_RANGE) {
			this._value = Array(2);
		} else if (newOperator === RuleCompareOperator.EMPTY || newOperator === RuleCompareOperator.NOT_EMPTY) {
			this._value = null;
		} else {
			this._value = undefined;
		}
	};

	get isValid(): boolean {
		if (!this.path) {
			return false;
		}
		if (this.conditionMetadata?.type === ConditionType.ARRAY) {
			return !!this.operator && (this._value as number[])?.length > 0;
		}
		if (Array.isArray(this._value)) {
			if (this.operator === RuleCompareOperator.IN_RANGE) {
				return this._value[0] != null && this._value[1] != null;
			}
			return this._value.length > 0;
		}
		if (operatorsWithNoValue.includes(this.operator)) {
			return true;
		}
		return this._value != null;
	}

	get value(): ConditionValues {
		return this._value;
	}
}

export default ConditionStore;
