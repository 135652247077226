import React, { useEffect, useState } from 'react';

import { BringgInput, Modal } from '@bringg/react-components';
import { BringgFontIcons, BringgIcon } from '@bringg/bringg-icons';
import { DragDropContext } from 'react-beautiful-dnd';

import DragDropContainer from './drag-drop-container/drag-drop-container';
import TransferArrows from './transfer-arrows/transfer-arrows';
import { ListsType, TransferItemList } from './types';

import styles from './transfer-modal.module.scss';

export interface Props {
	onClose: () => void;
	title: string;
	sourceTitle: string;
	targetTitle: string;
	targetList: TransferItemList[];
	sourceList: TransferItemList[];
	onApply: (TargetList: TransferItemList[], sourceList: TransferItemList[]) => void;
	className?: string;
	zIndex?: number;
}

const TransferModal = ({
	onClose,
	title,
	targetList,
	sourceList,
	sourceTitle,
	targetTitle,
	onApply,
	className,
	zIndex
}: Props) => {
	const [selectedCheckboxesSource, setSelectedCheckboxesSource] = useState<{ [id: string]: number }>({});
	const [selectedCheckboxesTarget, setSelectedCheckboxesTarget] = useState<{ [id: string]: number }>({});
	const [searchString, setSearchString] = useState('');
	const [updateSourceList, setUpdateSourceList] = useState<TransferItemList[]>([]);
	const [updateTargetList, setUpdateTargetList] = useState<TransferItemList[]>([]);

	const [presentedSourceList, setPresentedSourceList] = useState<TransferItemList[]>([]);
	const [presentedTargetList, setPresentedTargetList] = useState<TransferItemList[]>([]);

	useEffect(() => {
		setUpdateSourceList(sourceList);
		setUpdateTargetList(targetList);
	}, []);

	useEffect(() => {
		const filteredList = updateTargetList.filter(item =>
			item.title.toLowerCase().includes(searchString.toLowerCase())
		);
		setPresentedTargetList(filteredList);
	}, [updateTargetList, searchString]);

	useEffect(() => {
		const filteredList = updateSourceList.filter(item =>
			item.title.toLowerCase().includes(searchString.toLowerCase())
		);
		setPresentedSourceList(filteredList);
	}, [updateSourceList, searchString]);

	const updateSelectedCheckboxOnDragEnd = (
		isDragToSameList: boolean,
		sourceDroppableId: ListsType,
		draggableId: string,
		destinationIndex: number
	) => {
		const updateSelectedSourceCheckboxes = { ...selectedCheckboxesSource };
		const updateSelectedTargetCheckboxes = { ...selectedCheckboxesTarget };
		if (isDragToSameList) {
			if (sourceDroppableId === ListsType.SOURCE_LIST) {
				updateSelectedSourceCheckboxes[draggableId] = destinationIndex;
				setSelectedCheckboxesSource(updateSelectedSourceCheckboxes);
			} else {
				updateSelectedTargetCheckboxes[draggableId] = destinationIndex;
				setSelectedCheckboxesTarget(updateSelectedTargetCheckboxes);
			}
		} else {
			if (sourceDroppableId === ListsType.SOURCE_LIST) {
				delete updateSelectedSourceCheckboxes[draggableId];
				updateSelectedTargetCheckboxes[draggableId] = destinationIndex;
			} else {
				delete updateSelectedTargetCheckboxes[draggableId];
				updateSelectedSourceCheckboxes[draggableId] = destinationIndex;
			}
			setSelectedCheckboxesSource(updateSelectedSourceCheckboxes);
			setSelectedCheckboxesTarget(updateSelectedTargetCheckboxes);
		}
	};

	const onEndDrag = (
		isDragToSameList: boolean,
		sourceList: TransferItemList[],
		destinationList: TransferItemList[],
		sourceIndex: number,
		destinationIndex: number,
		draggableId: string,
		sourceListType: ListsType
	) => {
		const draggedElement = sourceList.splice(sourceIndex, 1);
		destinationList.splice(destinationIndex, 0, draggedElement[0]);

		if (selectedCheckboxesSource[draggableId] !== undefined) {
			updateSelectedCheckboxOnDragEnd(isDragToSameList, sourceListType, draggableId, destinationIndex);
		}
	};

	const onDragEnd = ({ source, destination, draggableId }) => {
		if (destination) {
			const newSourceList = [...updateSourceList];
			const newTargetList = [...updateTargetList];
			const isDragToSameList = source.droppableId === destination.droppableId;
			const theSourceList = source.droppableId === ListsType.SOURCE_LIST ? newSourceList : newTargetList;
			const destinationList = isDragToSameList
				? theSourceList
				: source.droppableId === ListsType.SOURCE_LIST
				? newTargetList
				: newSourceList;

			onEndDrag(
				isDragToSameList,
				theSourceList,
				destinationList,
				source.index,
				destination.index,
				draggableId,
				source.droppableId
			);
			setUpdateSourceList(newSourceList);
			setUpdateTargetList(newTargetList);
		}
	};

	const findSelectedIndex = (listType: ListsType, id: string) => {
		const selectedList = listType === ListsType.SOURCE_LIST ? updateSourceList : updateTargetList;
		return selectedList.findIndex(element => element.id === +id);
	};

	const onSelectCheckBox = (listType: ListsType, id: string, index: number, isSelected: boolean) => {
		const selectedListOnOriginList = searchString.length ? findSelectedIndex(listType, id) : index;
		if (isSelected) {
			if (listType === ListsType.SOURCE_LIST) {
				setSelectedCheckboxesSource({ ...selectedCheckboxesSource, [id]: selectedListOnOriginList });
			} else {
				setSelectedCheckboxesTarget({ ...selectedCheckboxesTarget, [id]: selectedListOnOriginList });
			}
		} else {
			if (listType === ListsType.SOURCE_LIST) {
				const updateList = { ...selectedCheckboxesSource };
				delete updateList[id];
				setSelectedCheckboxesSource(updateList);
			} else {
				const updateList = { ...selectedCheckboxesTarget };
				delete updateList[id];
				setSelectedCheckboxesTarget(updateList);
			}
		}
	};

	const onTransferArrowClick = (originList: ListsType) => {
		let newSourceList = [];
		let newTargetList = [];
		const elementsToMove = [];
		if (originList === ListsType.SOURCE_LIST) {
			Object.values(selectedCheckboxesSource).forEach(index => {
				elementsToMove.push(updateSourceList[index]);
			});
			newSourceList = updateSourceList.filter(item => selectedCheckboxesSource[item.id] === undefined);
			newTargetList = [...elementsToMove, ...updateTargetList];
			setSelectedCheckboxesSource({});
		} else {
			Object.values(selectedCheckboxesTarget).forEach(index => {
				elementsToMove.push(updateTargetList[index]);
			});
			newTargetList = updateTargetList.filter(item => selectedCheckboxesTarget[item.id] === undefined);
			newSourceList = [...elementsToMove, ...updateSourceList];
			setSelectedCheckboxesTarget({});
		}

		setUpdateSourceList(newSourceList);
		setUpdateTargetList(newTargetList);
	};

	const selectAllCheckBoxes = (isChecked: boolean, setCheckBoxFunction, updateList: TransferItemList[]) => {
		if (!isChecked) {
			setCheckBoxFunction({});
		} else {
			const selectAllItems = {};
			updateList.forEach((item, index) => (selectAllItems[item.id] = index));
			setCheckBoxFunction(selectAllItems);
		}
	};

	const onSelectAll = (listType: ListsType, isChecked: boolean) => {
		const setCheckBoxFunction =
			listType === ListsType.SOURCE_LIST ? setSelectedCheckboxesSource : setSelectedCheckboxesTarget;
		const updateList = listType === ListsType.SOURCE_LIST ? updateSourceList : updateTargetList;
		selectAllCheckBoxes(isChecked, setCheckBoxFunction, updateList);
	};

	const onOk = () => {
		onApply(updateSourceList, updateTargetList);
	};

	return (
		<Modal
			visible
			title={title}
			onCancel={onClose}
			width={850}
			onOk={onOk}
			zIndex={zIndex}
			className={className}
			okButtonProps={{ className: 'transfer-list-modal-ok-button' }}
		>
			<BringgInput
				placeholder="Search"
				suffix={<BringgIcon iconName={BringgFontIcons.Search} />}
				onChange={e => setSearchString(e.target.value)}
				data-test-id="search-item-input"
			/>
			<div className={styles.transferLists} data-test-id="transfer-section">
				<DragDropContext onDragEnd={onDragEnd}>
					<DragDropContainer
						listId={ListsType.SOURCE_LIST}
						title={sourceTitle}
						data={presentedSourceList}
						selectedIdsInIndex={selectedCheckboxesSource}
						onSelectCheckbox={onSelectCheckBox}
						onSelectAll={onSelectAll}
					/>
					<TransferArrows
						onTransferArrowClick={onTransferArrowClick}
						isFromSourceDisabled={!Object.keys(selectedCheckboxesSource).length}
						isFromTargetDisabled={!Object.keys(selectedCheckboxesTarget).length}
					/>
					<DragDropContainer
						listId={ListsType.TARGET_LIST}
						title={targetTitle}
						data={presentedTargetList}
						selectedIdsInIndex={selectedCheckboxesTarget}
						onSelectCheckbox={onSelectCheckBox}
						onSelectAll={onSelectAll}
					/>
				</DragDropContext>
			</div>
		</Modal>
	);
};

export default TransferModal;
