import React from 'react';
import { observer } from 'mobx-react';
import type { Task as TaskType } from '@bringg/types';
import { Table } from '@bringg/react-components';
import './fleet-assigner-task-table.scss';

export interface FleetAssignerTaskTableProps {
	gridSelectedTasks: TaskType[];
}

const FleetAssignerTaskTable = ({ gridSelectedTasks }: FleetAssignerTaskTableProps) => {
	const columns = [
		{
			title: 'Id',
			className: 'column-task-id',
			render: task => <span>{task.id}</span>
		},
		{
			title: 'Name',
			className: 'column-task-name',
			render: task => <span>{task.title}</span>
		},
		{
			title: 'Address',
			className: 'column-task-address',
			render: task => {
				const dropOff = task.way_points.filter(w => w.pickup_dropoff_option === 1)[0];
				return <span>{dropOff.address || dropOff.city}</span>;
			}
		}
	];
	return (
		<div className="fleet-tasks-table">
			<Table
				rowKey="id"
				scroll={{ y: 200 }}
				columns={columns}
				dataSource={gridSelectedTasks}
				tableLayout="auto"
			/>
		</div>
	);
};

export default observer(FleetAssignerTaskTable);
