import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { useTranslation } from 'react-i18next';
import {
	BringgInput,
	Button,
	Col,
	Form,
	FormItem,
	Row,
	Select,
	useForm,
	FormList,
	useWatch
} from '@bringg/react-components';
import { observer } from 'mobx-react';
import { BringgFontIcons, BringgIcon } from '@bringg/bringg-icons';
import { EmailBodyUpdateRequest, EmailTemplateCreateRequest, TemplateTypeEnum } from '@bringg/types';
import { debounce } from 'lodash';

import useTemplatesStore from '../../store/helpers/use-templates-store';
import { CONTENT_INITIAL_VALUE, useInitialValues, useInputRules } from '../../hooks';
import { LanguageTabs } from '../template-language';
import { LanguageTabContent } from './language-tab-content';
import { templateLanguagesFieldName, DATA_TEST_IDS } from '../../consts';
import { sanitizeHtmlContentByFF, replacePlaceHoldersWithMockForPreview, getPlaceholdersDummyData } from '../../utils';
import { PlaceholderData, TemplateTag } from 'bringg-web/components/text-template';
import notification from 'bringg-web/services/notification';
import { useStores } from 'bringg-web/recipes';

import styles from './template-details.module.scss';

const INPUT_DEBOUNCE = 500;

interface Props {
	templateId: number;
	onClose: () => void;
}

export const TemplateDetails = observer(({ templateId, onClose }: Props) => {
	const { t } = useTranslation();
	const [form] = useForm();
	const [activeLanguageCode, setActiveLanguageCode] = useState('');
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [cursorPosition, setCursorPosition] = useState({
		dragStart: 0,
		dragEnd: 0
	});
	const [selectedLang, setSelectedLang] = useState<EmailBodyUpdateRequest>();
	const [isSelectedTemplateContentValid, setIsSelectedTemplateContentValid] = useState(true);

	const previewFrameRef = useRef<HTMLIFrameElement>(null);
	const {
		templatesStore: {
			createTemplate,
			updateTemplate,
			fetchPlaceholders,
			templatePlaceholders,
			loadingPlaceholders,
			sendTestEmail
		}
	} = useTemplatesStore();
	const {
		usersStore: { currentUser },
		merchantConfigurationsStore: { hourFormat }
	} = useStores();
	const initialValues = useInitialValues(templateId);
	const emailBody = useWatch<EmailBodyUpdateRequest[]>(templateLanguagesFieldName, form);
	const { defaultInputRules, nameInputRules } = useInputRules(t);

	useEffect(() => {
		const fetchPlaceholdersForTemplate = async () => {
			await fetchPlaceholders();
		};
		fetchPlaceholdersForTemplate();
	}, []);

	const languageCodesUsed = useMemo(() => {
		return emailBody?.map(({ language_code }) => language_code) || [];
	}, [emailBody?.length]);

	const concatenatedTemplateText = useMemo(
		() => emailBody?.reduce((acc, { content }) => acc.concat(content), '') || '',
		[emailBody]
	);

	const placeholders = useMemo<PlaceholderData[]>(() => {
		return templatePlaceholders.map(label => ({
			...label,
			info: t(label.info_translation_string),
			values: label.values
				? label.values.map((val, _, allValuesArr) => ({
						...val,
						info: t(label.info_translation_string),
						disabled: allValuesArr.some(
							value => value.type !== val.type && concatenatedTemplateText.includes(`{{${value.type}}}`)
						)
				  }))
				: undefined
		}));
	}, [concatenatedTemplateText, templatePlaceholders, t]);

	const typeOptions = useMemo(() => {
		return [
			{ name: t('EMAIL_TEMPLATES.TYPE.CUSTOMER_EMAIL'), id: TemplateTypeEnum.customer_notification_email }
			// TODO: future template_type
			// { name: t('EMAIL_TEMPLATES.TYPE.PACKAGE_LABEL'), id: String(TemplateTypeEnum.package_label) },
			// { name: t('EMAIL_TEMPLATES.TYPE.ROUTE_MANIFEST'), id: String(TemplateTypeEnum.route_manifest) }
		];
	}, [t]);

	const renderPreviewTemplate = useCallback(
		debounce((value: string) => previewFrameRef?.current?.setAttribute('srcdoc', value), INPUT_DEBOUNCE),
		[]
	);

	const placeholdersDummyData = useMemo(() => getPlaceholdersDummyData({ hourFormat }), []);

	useEffect(() => {
		if (!selectedLang) {
			return;
		}

		const sanitizedContent = sanitizeHtmlContentByFF(selectedLang.content);

		if (selectedLang.content.length === sanitizedContent.length) {
			setIsSelectedTemplateContentValid(true);
			renderPreviewTemplate(replacePlaceHoldersWithMockForPreview(selectedLang.content, placeholdersDummyData));
		} else {
			setIsSelectedTemplateContentValid(false);
		}
	}, [selectedLang, renderPreviewTemplate, placeholders, t]);

	const sendTestEmailToMerchant = useCallback(async () => {
		try {
			await sendTestEmail({
				body: replacePlaceHoldersWithMockForPreview(selectedLang.content, placeholdersDummyData),
				subject: selectedLang.subject,
				recipients: [currentUser.email]
			});
			notification.success(t('EMAIL_TEMPLATES.TEST_EMAIL_WAS_SENT'));
		} catch (error) {
			notification.error(t('EMAIL_TEMPLATES.FAILED_TO_SEND_TEST_EMAIL'));
		}
	}, [selectedLang, currentUser, placeholdersDummyData, t]);

	const sendEmail = useCallback(() => {
		const inputIndex = languageCodesUsed.findIndex(lang => lang === activeLanguageCode);

		form.validateFields([
			[templateLanguagesFieldName, inputIndex, 'subject'],
			[templateLanguagesFieldName, inputIndex, 'content']
		])
			.then(sendTestEmailToMerchant)
			.catch(() => notification.error(t('EMAIL_TEMPLATES.EMAIL_CANNOT_BE_SENT')));
	}, [form, sendTestEmailToMerchant, languageCodesUsed, activeLanguageCode, t]);

	const create = useCallback(
		async (template: EmailTemplateCreateRequest) => {
			setIsSubmitting(true);

			try {
				await createTemplate(template);
				notification.success(t('EMAIL_TEMPLATES.SUCCESSFULLY_CREATED'));

				setIsSubmitting(false);
				onClose();
			} catch (error) {
				setIsSubmitting(false);
				notification.error((error as any)?.data?.title ?? t('EMAIL_TEMPLATES.FAILED_CREATE'));
			}
		},
		[onClose]
	);

	const update = useCallback(
		async template => {
			setIsSubmitting(true);

			try {
				await updateTemplate(templateId, template);
				notification.success(t('EMAIL_TEMPLATES.SUCCESSFULLY_UPDATED'));

				setIsSubmitting(false);
				onClose();
			} catch (error) {
				setIsSubmitting(false);
				notification.error((error as any)?.data?.title ?? t('EMAIL_TEMPLATES.FAILED_UPDATE'));
			}
		},
		[onClose]
	);

	// switch language if active language removed
	useEffect(() => {
		if (languageCodesUsed.length && !languageCodesUsed.includes(activeLanguageCode)) {
			setActiveLanguageCode(languageCodesUsed[0]);
		}
	}, [languageCodesUsed, activeLanguageCode]);

	// update preview
	useEffect(() => {
		if (emailBody) {
			setSelectedLang(emailBody.find(item => item.language_code === activeLanguageCode));
		}
	}, [emailBody, activeLanguageCode]);

	const setCursorPositionByEvent = (
		event: React.KeyboardEvent<HTMLTextAreaElement> | React.MouseEvent<HTMLTextAreaElement, MouseEvent>
	) => {
		setCursorPosition({
			dragStart: (event.target as HTMLInputElement).selectionStart,
			dragEnd: (event.target as HTMLInputElement).selectionEnd
		});
	};

	const handleTemplateTagClicked = useCallback(
		(tagType: string) => {
			const inputIndex = languageCodesUsed.findIndex(lang => lang === activeLanguageCode);

			const prevValue = form.getFieldValue([templateLanguagesFieldName, inputIndex, 'content']);

			const arr = prevValue.split('');
			arr.splice(
				cursorPosition.dragStart,
				cursorPosition.dragEnd - cursorPosition.dragStart,
				`{{${tagType}}}`
			).join('');
			const newValue = arr.join('');

			form.setFieldValue([templateLanguagesFieldName, inputIndex, 'content'], newValue);
			form.validateFields();
		},
		[form, cursorPosition, languageCodesUsed, activeLanguageCode]
	);

	const sanitizePreviewContent = useCallback(() => {
		const sanitizedContent = sanitizeHtmlContentByFF(selectedLang?.content);

		const inputIndex = languageCodesUsed.findIndex(lang => lang === activeLanguageCode);

		form.setFieldValue([templateLanguagesFieldName, inputIndex, 'content'], sanitizedContent);
		form.validateFields();
	}, [form, languageCodesUsed, activeLanguageCode, selectedLang]);

	const submit = useCallback(() => {
		form.validateFields()
			.then(() => {
				const formValues = form.getFieldsValue(true);

				if (templateId) {
					update(formValues);
				} else {
					create(formValues);
				}
			})
			.catch(info => {
				info?.errorFields?.find(error => {
					// in case form contains errors in dynamic inputs for template languages
					// we need to set tab with errors as active tab
					if (error.name.includes(templateLanguagesFieldName)) {
						// second item in array error.name in dynamic fields is always the same as index of tab
						// tabs build based on languageCodesUsed => index are the same
						// TODO: cover this case with tests
						setActiveLanguageCode(languageCodesUsed[error.name[1]]);
					}
				});
			});
	}, [form, create, update, languageCodesUsed]);

	return (
		<div className={styles.componentBackdrop}>
			<div className={styles.templateDetails}>
				<span className={styles.templateDetailsTitle}>{t('EMAIL_TEMPLATES.SETUP')}</span>
				<div className={styles.Content}>
					<div className={styles.ContentForm}>
						<span className={styles.ContentTitle}>{t('EMAIL_TEMPLATES.CONFIGURATION')}</span>
						<Form form={form} layout="vertical" initialValues={initialValues}>
							<Row>
								<Col span={24}>
									<label
										className={`${styles.ContentFormInputLabel} ${styles.ContentFormInputLabelRequired} text-ellipsis`}
									>
										{t('EMAIL_TEMPLATES.TYPE_INPUT')}
									</label>
									<FormItem name="template_type" rules={defaultInputRules}>
										<Select options={typeOptions} />
									</FormItem>
								</Col>
							</Row>
							<Row>
								<Col span={24}>
									<label
										className={`${styles.ContentFormInputLabel} ${styles.ContentFormInputLabelRequired} text-ellipsis`}
									>
										{t('EMAIL_TEMPLATES.NAME_LABEL')}
									</label>
									<FormItem name="name" rules={nameInputRules}>
										<BringgInput
											allowClear={{
												clearIcon: <BringgIcon iconName={BringgFontIcons.CloseCircle} />
											}}
											placeholder={t('EMAIL_TEMPLATES.NAME_INPUT_PLACEHOLDER')}
										/>
									</FormItem>
								</Col>
							</Row>

							<span className={styles.ContentTitle}>{t('EMAIL_TEMPLATES.LANGUAGES: Languages')}</span>

							<FormList name={templateLanguagesFieldName}>
								{(fields, { add, remove }) => {
									return (
										<LanguageTabs
											activeLanguageCode={activeLanguageCode}
											setActiveLanguageCode={setActiveLanguageCode}
											languageCodesUsed={languageCodesUsed}
											isLoading={loadingPlaceholders}
											onAddLang={languageCode => {
												add({
													subject: '',
													content: CONTENT_INITIAL_VALUE,
													language_code: languageCode
												});
											}}
											onRemoveLang={languageCode => {
												remove(languageCodesUsed.findIndex(code => code === languageCode));
											}}
											getTabContent={(languageCode, index) => (
												<LanguageTabContent
													languageCode={languageCode}
													field={fields[index]}
													templatePlaceholders={placeholders}
													setCursorPositionByEvent={setCursorPositionByEvent}
												/>
											)}
										/>
									);
								}}
							</FormList>
							{placeholders.length > 0 && (
								<TemplateTag
									textDescription={t('EMAIL_TEMPLATES.TEMPLATE_VARIABLES_TITLE')}
									labels={placeholders}
									onClick={handleTemplateTagClicked}
								/>
							)}
						</Form>
					</div>
					<div className={styles.ContentPreview}>
						<span className={styles.ContentTitle}>{t('EMAIL_TEMPLATES.PREVIEW')}</span>
						<span className={styles.ContentSubtitle}>{t('EMAIL_TEMPLATES.PREVIEW_SUBTITLE')}</span>
						<span className={styles.ContentSubtitle}>{t('EMAIL_TEMPLATES.NOTE_TIME_FORMAT')}</span>
						<div className={styles.ContentPreviewContainer}>
							<iframe sandbox="" ref={previewFrameRef} />
							{!isSelectedTemplateContentValid ? (
								<div className={styles.contentErrorMessageContainer}>
									<span>{t('EMAIL_TEMPLATES.TEMPLATE_CONTENT_SANITIZE_ERROR')}</span>
									<Button
										type="primary"
										onClick={sanitizePreviewContent}
										data-test-id={DATA_TEST_IDS.sanitizeHtmlBtn}
									>
										{t('EMAIL_TEMPLATES.SANITIZE_TEMPLATE_CONTENT')}
									</Button>
								</div>
							) : null}
						</div>
					</div>
				</div>
				<div className={styles.Footer}>
					<Button
						type="link"
						onClick={sendEmail}
						disabled={!isSelectedTemplateContentValid}
						data-test-id={DATA_TEST_IDS.sendTestEmailBtn}
					>
						{t('EMAIL_TEMPLATES.TEST_MAIL')}
					</Button>

					<Button type="link" onClick={onClose}>
						{t('GLOBAL.CANCEL')}
					</Button>
					<Button type="primary" disabled={isSubmitting} onClick={submit}>
						{t('GLOBAL.SAVE')}
					</Button>
				</div>
			</div>
		</div>
	);
});
