import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import i18next from 'i18next';
import { VariablesLegend, Col, Row } from '@bringg/react-components';
import ActionsConfigurationsStore from 'bringg-web/stores/actions-configuration/actions-configuration-store';
import ActionsConfiguration from 'bringg-web/stores/actions-configuration/domain-objects/actions-configuration';
import notification from 'bringg-web/services/notification';
import TaskConfiguration from 'bringg-web/stores/actions-configuration/domain-objects/task-configuration';
import Translate from 'bringg-web/translation/translator';
import ActionsConfigurationsTable from '../actions-configurations-table/actions-configurations-table';
import ActionsConfigurationsHeader from '../actions-configurations-header/actions-configurations-header';
import ActionsConfigurationForm from '../../actions-configuration/actions-configuration-container/actions-configuration-form';

interface Props {
	actionsConfigurationsStore?: ActionsConfigurationsStore;
}

enum PageMode {
	VIEW_ALL,
	EDIT,
	ADD
}

interface State {
	mode: PageMode;
}

const LEGEND_OPTIONS = [
	{ key: '#{task.external_id}', description: 'Order External ID' },
	{ key: '#{task.way_points.0.customer.name}', description: 'First Waypoint Customer Name' },
	{ key: '#{task.way_points.1.customer.name}', description: 'Second Waypoint Customer Name' },
	{ key: '#{order_title}', description: 'Order title' },
	{ key: '#{time_window}', description: 'Delivery Window' },
	{ key: '#{date}', description: 'Current Date and time' },
	{ key: '#{inventory_count}', description: 'Inventory Count' }
];
const LEGEND_DESCRIPTION = 'In order to show in the form,\nuse following placeholders:';

class ActionsConfigurationsContainer extends Component<Props, State> {
	state = {
		mode: PageMode.VIEW_ALL
	};

	async componentDidMount() {
		await this.props.actionsConfigurationsStore.fetchAll();
	}

	onDelete = async (record: ActionsConfiguration) => {
		const response = await this.props.actionsConfigurationsStore.delete(record.id, record.level);

		if (response.success) {
			notification.success(i18next.t('ACTIONS_CONFIGURATIONS.DELETE_SUCCESS'));
		} else {
			notification.error(i18next.t('ACTIONS_CONFIGURATIONS.DELETE_FAILED'), response.message);
		}
	};

	onEdit = (current: ActionsConfiguration) => {
		// consider getting actions configuration by id from store
		this.props.actionsConfigurationsStore.setCurrent(current);
		this.setState({ mode: PageMode.EDIT });
	};

	onAdd = () => {
		this.props.actionsConfigurationsStore.setCurrent(new TaskConfiguration({}));
		this.setState({ mode: PageMode.ADD });
	};

	onEditActionsConfigurationDone = () => {
		this.setState({ mode: PageMode.VIEW_ALL }, () => {
			this.props.actionsConfigurationsStore.fetchAll();
		});
	};

	render() {
		const actionsConfigurationsFiltered = this.props.actionsConfigurationsStore.getAllFiltered;

		return (
			<Row className="actions-configurations-page">
				<Col span={14} className="actions-configurations-container">
					{this.state.mode === PageMode.VIEW_ALL ? (
						[
							<ActionsConfigurationsHeader onAdd={this.onAdd} key="1" />,
							<ActionsConfigurationsTable
								actionsConfigurations={actionsConfigurationsFiltered}
								onDelete={this.onDelete}
								onEdit={this.onEdit}
								key="2"
							/>
						]
					) : (
						<ActionsConfigurationForm
							isNew={this.state.mode === PageMode.ADD}
							onDone={this.onEditActionsConfigurationDone}
							actionsConfigurationsStore={this.props.actionsConfigurationsStore}
						/>
					)}
				</Col>
				{this.state.mode !== PageMode.VIEW_ALL && (
					<Col span={10} className="variable-legend-container">
						<VariablesLegend
							dictionary={LEGEND_OPTIONS}
							title={<Translate text="ACTIONS_CONFIGURATIONS.LABEL_LEGEND_TITLE" />}
							description={LEGEND_DESCRIPTION}
						/>
					</Col>
				)}
			</Row>
		);
	}
}

export default inject('actionsConfigurationsStore')(observer(ActionsConfigurationsContainer));
