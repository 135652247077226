import React from 'react';
import { useObserver } from 'mobx-react';
import { chain as _chain, forEach as _forEach, isNil as _isNil } from 'lodash';
import { Location, Task, User } from '@bringg/types';
import { MapboxMarkerData as Marker } from '@bringg/react-components/dist/components/mapbox/mapbox';
import { BringgFontIcons, BringgIcon } from '@bringg/bringg-icons';
import { ImageUrl } from '@bringg/react-components/dist/types/maps.consts';
import DispatchMapMarkersView, { MarkerType } from '../dispatch-map-markers-view';
import Team from '../../../stores/teams/domain-object/team';
import { getDriverMarkerColor } from './utils';
import DriverPopup from '../driver-popup/driver-popup';
import BaseDomainStore from '../../../stores/core/base-domain-store';
import Driver from '../../../stores/drivers/domain-object/driver';
import TaskPopup from '../task-popup/task-popup';
import TasksStoreAdapter from 'bringg-web/stores/tasks/tasks-store-adapter';

export const TASK_MARKER = '/images/task.png';
export const TEAM_MARKER = '/images/team_home.png';
export const DRIVER_MARKER = '/images/driver_icon.png';

type ItemTypes = Team | User | Task;

const isLocationValid = (lat: number, lng: number): boolean => {
	return lat && lng && lat >= -90 && lat <= 90 && lng >= -180 && lng <= 180;
};

export const handleMarkersListUpdate = (
	items: ItemTypes[],
	addMarkerToStoreHandler,
	markerType: MarkerType,
	dispatchMapMarkersView: DispatchMapMarkersView,
	extractLocationHandler?,
	domainStore?: BaseDomainStore<any> | TasksStoreAdapter
) => {
	const updatedMarkersIds = {};

	_forEach(items, item => {
		if (extractLocationHandler) {
			const location = extractLocationHandler(item);
			addMarkerToStoreHandler(dispatchMapMarkersView, item, location);
		} else {
			const { lat, lng } = item;

			if (isLocationValid(lat, lng)) {
				addMarkerToStoreHandler(dispatchMapMarkersView, item, domainStore);
			}
		}

		const { id } = item;
		updatedMarkersIds[id] = true;
	});

	dispatchMapMarkersView.removeOldMarkers(updatedMarkersIds, markerType);
};

export const addTaskMarkerToStore = (
	dispatchMapMarkersView: DispatchMapMarkersView,
	task: Task,
	location: Location
) => {
	const { lat, lng } = location;
	const { id } = task;

	const currentMarker = dispatchMapMarkersView.get(MarkerType.TASKS, id);
	let markerIcon: JSX.Element | ImageUrl = TASK_MARKER;

	if (currentMarker && currentMarker.isIconLocked) {
		markerIcon = currentMarker.icon;
	}

	dispatchMapMarkersView.addOrUpdateMarker(MarkerType.TASKS, id, {
		id,
		location: { lat, lng },
		icon: markerIcon,
		popup: {
			element: <TaskPopup task={task} />,
			anchor: 'bottom',
			offset: { x: 0, y: -60 }
		}
	} as Marker);
};

export const addTeamMarkerToStore = (dispatchMapMarkersView: DispatchMapMarkersView, team: Team) => {
	const { id, lat, lng, name } = team;

	dispatchMapMarkersView.addOrUpdateMarker(MarkerType.TEAMS, id, {
		id,
		location: { lat, lng },
		icon: TEAM_MARKER,
		text: name
	} as Marker);
};

export const DriverMarker: React.FC<{ driver: Driver }> = ({ driver }) => {
	return useObserver(() => {
		const driverColor = getDriverMarkerColor(driver);
		const { name } = driver;

		return (
			<div className="dispatch-map-driver-marker">
				<div className="dispatch-map-driver-marker-circle" style={{ backgroundColor: driverColor }} />
				<div className="dispatch-map-driver-marker-title">{name}</div>
			</div>
		);
	});
};

export const addDriverMarkerToStore = (
	dispatchMapMarkersView: DispatchMapMarkersView,
	driver: Driver,
	tasksStore: TasksStoreAdapter
) => {
	const allOpenTasksGroupedByDrivers = _chain(tasksStore.getOpen())
		.filter(task => !_isNil(task.user_id))
		.groupBy(task => task.user_id)
		.value();

	const handleDriverUpdate = (driver: Driver) => {
		const { id, lat, lng, name } = driver;

		const currentMarker = dispatchMapMarkersView.get(MarkerType.DRIVERS, id);
		let markerIcon: JSX.Element | ImageUrl = DRIVER_MARKER;

		if (currentMarker && currentMarker.isIconLocked) {
			markerIcon = currentMarker.icon;
		}

		dispatchMapMarkersView.addOrUpdateMarker(MarkerType.DRIVERS, id, {
			id,
			location: { lat, lng },
			icon: markerIcon,
			anchor: 'top',
			text: name,
			popup: {
				element: <DriverPopup driver={driver} openTasksGroupedByDrivers={allOpenTasksGroupedByDrivers} />,
				anchor: 'bottom',
				offset: { x: 0, y: 0 }
			}
		} as Marker);
	};

	handleDriverUpdate(driver);
};

export const getMapMarkerIcon = (color: string): JSX.Element => {
	return <BringgIcon iconName={BringgFontIcons.Location} className="dispatch-map-marker-icon" color={color} />;
};
