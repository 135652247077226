import { useMemo } from 'react';

import type { TFunction } from 'i18next';
import type { Rule } from 'antd/lib/form';
import { useHasFeatureFlag } from '@bringg-frontend/hooks';

import { PlaceholderData, findMismatchLabels, getDisplayMismatch } from 'bringg-web/components/text-template';
import { DISABLE_TEMPLATE_SANITIZER_FF } from 'bringg-web/features/templates-manager/consts';

interface UseInputRules {
	defaultInputRules: Rule[];
	templateInputRules: Rule[];
	nameInputRules: Rule[];
}

export const useInputRules = (t: TFunction, templatePlaceholders?: PlaceholderData[]) => {
	const templateSanitizerDisabled = useHasFeatureFlag(DISABLE_TEMPLATE_SANITIZER_FF);
	const MIN_NAME_VALUE_LENGTH = 4;

	const defaultInputRules = useMemo<Rule[]>(() => {
		return [{ required: true, message: t('GLOBAL.FIELD_REQUIRED') }];
	}, [t]);

	const labelsSet = useMemo<Set<string>>(() => {
		if (!templatePlaceholders) {
			return new Set<string>();
		} else {
			return new Set(
				templatePlaceholders
					.map(({ type, values }) => {
						// If we have value, don't use the type
						if (values) {
							return values.map(({ type }) => type);
						}
						return type;
					})
					.flat(Infinity) as string[]
			);
		}
	}, [templatePlaceholders]);

	const templateInputRules = useMemo<Rule[]>(() => {
		return [
			...defaultInputRules,
			{
				validator: async (_, value: string) => {
					const trimmedValue = value?.trim().toLowerCase();
					let valid = true;

					if (trimmedValue) {
						if (!templateSanitizerDisabled) {
							// This logic must be shared with the back-end
							valid =
								/^(<!doctype html>\n)?(<html( lang=".+?")?>)/.test(trimmedValue) &&
								trimmedValue.endsWith('</html>');

							if (!valid) {
								return Promise.reject(t('EMAIL_TEMPLATES.INVALID_HTML_CODE_MESSAGE'));
							}
						}

						const mismatch = findMismatchLabels(value, labelsSet);
						valid = Boolean(mismatch.length === 0);

						return valid
							? Promise.resolve()
							: Promise.reject(() => {
									const mismatchDisplay = getDisplayMismatch(mismatch);
									return t('AUTOMATION.ACTIONS.TITLE_ERROR_PLACEHOLDER_MSG', {
										match: mismatchDisplay.join(', ')
									});
							  });
					}
					return Promise.resolve();
				}
			}
		];
	}, [defaultInputRules, labelsSet, templateSanitizerDisabled, t]);

	const nameInputRules = useMemo<Rule[]>(() => {
		return [
			...defaultInputRules,
			{
				validator: async (_, value: string) => {
					const trimmedValue = value?.trim();

					if (trimmedValue?.length < MIN_NAME_VALUE_LENGTH) {
						return Promise.reject(
							t('GLOBAL.MINIMUM_INPUT_LENGTH_VALUE', { minLength: MIN_NAME_VALUE_LENGTH })
						);
					}
					return Promise.resolve();
				}
			}
		];
	}, [defaultInputRules, labelsSet, t]);

	return { defaultInputRules, templateInputRules, nameInputRules } as UseInputRules;
};
