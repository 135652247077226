import React from 'react';
import { Checkbox } from 'antd';
import { observer } from 'mobx-react';
import Translate from '../../../translation/translator';

interface Props {
	checked: boolean;
	onChange: (target) => void;
	translateKey: string;
	translateDefault?: string;
	testAtrr?: string;
}

const CheckboxOption = ({ checked, onChange, translateKey, translateDefault, testAtrr }: Props) => {
	return (
		<div className="checkbox-template">
			<Checkbox checked={checked} onChange={onChange} data-test-id={testAtrr} />
			<span className="label">
				<Translate text={translateKey} defaultValue={translateDefault} />
			</span>
		</div>
	);
};

export default observer(CheckboxOption);
