import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { action, observable, makeObservable } from 'mobx';
import Company from '../../../stores/companies/domain-objects/company';
import CompanyCardInfo from './company-card-info/company-card-info';
import CompanyCardEdit from './company-card-edit/company-card-edit';
import { Card } from '../../../components';

interface Props {
	company: Company;
}

class CompanyCard extends Component<Props, {}> {
	isEditMode = false;

	constructor(props: Props) {
		super(props);

		makeObservable(this, {
			isEditMode: observable,
			toggleEditMode: action
		});
	}

	toggleEditMode = () => {
		this.isEditMode = !this.isEditMode;
	};

	render() {
		const { isEditMode, toggleEditMode } = this;
		const { company } = this.props;

		return (
			<Card className="company-card">
				{isEditMode ? (
					<CompanyCardEdit toggleEditMode={toggleEditMode} company={company} />
				) : (
					<CompanyCardInfo toggleEditMode={toggleEditMode} company={company} />
				)}
			</Card>
		);
	}
}

export default observer(CompanyCard);
