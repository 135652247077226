import { ResizableDataField } from '@bringg/types';
import { sortBy, keyBy } from 'lodash';

import { Column } from 'bringg-web/features/order-history-page/components/history-table/columns';

export const dataFieldsToColumns = (dataFields: Partial<ResizableDataField>[], defaultColumns: Column[]): Column[] => {
	const indexedDataFields = keyBy(dataFields, 'id');

	return sortBy(
		defaultColumns.map(column => {
			const dataField = indexedDataFields[column.id];

			if (!dataField) {
				return column;
			}

			return {
				...column,
				active: Boolean(dataField.active)
			};
		}),
		column => indexedDataFields[column.id]?.position ?? Infinity
	);
};

export const columnsToDataFields = (columns: Column[]): Partial<ResizableDataField>[] => {
	return columns.map(({ id, active }, position) => ({
		id,
		active: Boolean(active),
		position
	}));
};
