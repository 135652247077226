export const TEST_IDS = {
	modalContainer: 'modal-container',
	changeModalAppearance: 'change-modal-appearance',
	closeModal: 'close-modal',
	modalBody: 'modal-body',
	submitTicketForm: 'submit-ticket-form',
	userEmailInput: 'user-email-input',
	userPhoneInput: 'user-phone-input',
	closeTicketFormFooterButton: 'close-ticket-form-footer-button',
	resetTicketForm: 'reset-ticket-form'
};
