import { useEffect, useState } from 'react';

import { LatLng } from '@bringg/types';

type useRoutePlayerAnimationProps = {
	isPlaying: boolean;
	routeIndex: number;
	routeLocations: LatLng[];
	onRouteIndexChange: (newRouteIndex: number) => void;
	onUpdateDriverMarkerLocation: () => void;
	pause: () => void;
};

const useRoutePlayerAnimation = ({
	isPlaying,
	routeIndex,
	routeLocations,
	onRouteIndexChange,
	onUpdateDriverMarkerLocation,
	pause
}: useRoutePlayerAnimationProps) => {
	const isRouteIndexValid = (testedRouteIndex: number) => testedRouteIndex < routeLocations.length - 1;

	const [lastRunTimestamp, setLastRunTimestamp] = useState<number>();
	const routeLength = routeLocations.length;

	useEffect(() => {
		if (!isPlaying) {
			setLastRunTimestamp(undefined);
			return;
		}
		let animationFrameId: number;
		// Request the next frame of animation as long as the end has not been reached
		if (isRouteIndexValid(routeIndex)) {
			if (!lastRunTimestamp) {
				setLastRunTimestamp(performance.now());
				animationFrameId = requestAnimationFrame(animateRoutePlayerIfNeeded);
				return;
			}
			const currentTimestamp = performance.now();
			const elapsed = currentTimestamp - lastRunTimestamp;
			const percentage = 0.01;
			const relativeRouteIndex = Math.round((routeIndex + elapsed * percentage) % routeLength);
			setLastRunTimestamp(currentTimestamp);

			if (relativeRouteIndex !== routeIndex) {
				onRouteIndexChange(relativeRouteIndex);
			} else {
				animationFrameId = requestAnimationFrame(animateRoutePlayerIfNeeded);
			}
		}
		return () => {
			if (animationFrameId) {
				cancelAnimationFrame(animationFrameId);
			}
		};
	}, [routeIndex]);

	const animateRoutePlayerIfNeeded = () => {
		if (!shouldAnimateRoutePlayer()) {
			pause();
			return;
		}
		onUpdateDriverMarkerLocation();

		if (!isRouteIndexValid(routeIndex)) return;

		onRouteIndexChange(routeIndex + 1);
	};

	const shouldAnimateRoutePlayer = () => !!routeLocations[routeIndex];

	return { animateRoutePlayerIfNeeded, isRouteIndexValid };
};

export default useRoutePlayerAnimation;
