import React, { memo, useEffect, useMemo } from 'react';

import { useTranslation } from 'react-i18next';
import {
	FilterBar2,
	FilterBar2ListItem,
	FilterBar2Values,
	getDefaultFilterBarTranslations
} from '@bringg/react-components';
import { PlannedDeliveryWindow } from '@bringg/types';

import { useStores } from 'bringg-web/recipes';
import type { UseTableFilters } from '../hooks/use-table-filters';

import styles from './styles.module.scss';

interface Props {
	filtersState: UseTableFilters;
	deliverySlots: PlannedDeliveryWindow[];
}

function FiltersPanel({ filtersState, deliverySlots }: Props) {
	const { t } = useTranslation();

	const { filters, updateFilters, resetFilters } = filtersState;

	const { serviceAreasStore, servicePlansStore } = useStores();

	useEffect(() => {
		if (serviceAreasStore?.isFetched) return;

		async function fetchAll() {
			await serviceAreasStore?.fetchAll();
		}

		fetchAll();
	}, [serviceAreasStore]);

	useEffect(() => {
		if (servicePlansStore?.isFetched) return;

		async function fetchAll() {
			await servicePlansStore?.fetchAll();
		}

		fetchAll();
	}, [servicePlansStore]);

	const filterBarTranslations = useMemo(() => getDefaultFilterBarTranslations(t), [t]);

	const servicePlanOptions = useMemo(
		() =>
			Array.from(servicePlansStore?.servicePlans ? servicePlansStore?.servicePlans?.values() : []).map(plan => ({
				value: plan.id,
				label: plan.name
			})),
		[servicePlansStore?.servicePlans]
	);

	const serviceAreaOptions = useMemo(
		() => serviceAreasStore?.getAll?.map(area => ({ value: area.id, label: area.name })),
		[serviceAreasStore?.getAll]
	);

	const slotNameOptions = useMemo(
		() => deliverySlots.map(slot => ({ value: slot.name, label: slot.name })),
		[deliverySlots]
	);

	const filterInputs: FilterBar2ListItem[] = useMemo(() => {
		return [
			{
				type: FilterBar2.TYPES.SELECT,
				multiselect: true,
				applyFilterOnValueChange: true,
				name: 'service_area',
				title: t(`DELIVERY_SLOTS.FILTERS.SERVICE_AREA`),
				values: serviceAreaOptions
			},
			{
				type: FilterBar2.TYPES.CHECKBOX,
				applyFilterOnValueChange: true,
				multiselect: true,
				name: 'service_plan',
				title: t(`DELIVERY_SLOTS.FILTERS.SERVICE_PLAN`),
				values: servicePlanOptions
			},
			{
				type: FilterBar2.TYPES.DROPDOWN_SEARCH,
				applyFilterOnValueChange: true,
				name: 'slot_name',
				title: t(`DELIVERY_SLOTS.FILTERS.SLOT_NAME`),
				values: slotNameOptions
			}
		];
	}, [t, serviceAreaOptions, servicePlanOptions, slotNameOptions]);

	const initialFilterValues = useMemo(() => filters as unknown as FilterBar2Values, []);

	return (
		<div className={styles.deliverySlotsFilter} data-test-id="delivery-slots-filter">
			<FilterBar2
				className={styles.slotsFilterBar}
				initialValues={initialFilterValues}
				onReset={resetFilters}
				list={filterInputs}
				resetToInitial
				translations={filterBarTranslations}
				onFilter={updateFilters}
			/>
		</div>
	);
}

export default memo(FiltersPanel);
