import React from 'react';
import { LabelNumberInput } from '@bringg/react-components';
import { Options } from 'rrule';

interface Props {
	options: Options;
	onOptionsChange: (options: Partial<Options>) => void;
	disabled: boolean;
	everyLabel: string;
	daysLabel: string;
}

const RecurringDaily = ({ options, onOptionsChange, disabled, everyLabel, daysLabel }: Props) => {
	const onIntervalChange = interval => onOptionsChange({ interval });

	return (
		<div className="recurring-frequency">
			<label>{everyLabel}:</label>
			<LabelNumberInput
				label={daysLabel}
				minValue={1}
				defaultValue={options.interval}
				onChange={onIntervalChange}
				disabled={disabled}
			/>
		</div>
	);
};

export default RecurringDaily;
