import React, { useEffect } from 'react';

const useScrollEvent = (ref: React.MutableRefObject<HTMLDivElement | null>, selector: string, callback: () => void) => {
	useEffect(() => {
		function onScroll() {
			callback();
		}

		if (ref?.current) {
			document.querySelector(`.${selector}`)?.addEventListener('scroll', onScroll);
		}

		return () => {
			document.querySelector(`.${selector}`)?.removeEventListener('scroll', onScroll);
		};
	}, [ref]);
};

export default useScrollEvent;
