import moment, { Moment } from 'moment-timezone';
import { DeliveryBlockBreakBase } from 'bringg-web/features/delivery-blocks-v2/new-modal/breaks/break-types';
import { BreakType } from '@bringg/types';
import { DeliveryBlockRechargeBase } from 'bringg-web/features/delivery-blocks-v2/new-modal/recharge/recharge-types';

const rechargeValidator = () => {
	const isStartTimeInRange = (rechargeTime: Moment, start: Moment, end: Moment) =>
		rechargeTime.isBetween(start, end, undefined, '[]');
	const isStartTimeInDeliveryBlockRange = (rechargeTime: Moment, deliveryBlockTimes, rechargeDuration: number) => {
		const startOfValidRechargeTime = moment(deliveryBlockTimes.startTime);
		const endOfValidRechargeTime = moment(deliveryBlockTimes.endTime).subtract(rechargeDuration, 'minutes');

		return isStartTimeInRange(rechargeTime, startOfValidRechargeTime, endOfValidRechargeTime);
	};

	const isStartTimeOverlapBreaks = (
		rechargeTime: Moment,
		deliveryBlockBreaks: DeliveryBlockBreakBase[],
		timezone: string
	) => {
		return deliveryBlockBreaks.some((breakItem: DeliveryBlockBreakBase) => {
			if (breakItem.break_type === BreakType.Fixed) {
				const startTime = moment(breakItem.start_time).tz(timezone);
				const endTime = moment(breakItem.end_time).tz(timezone);

				return isStartTimeInRange(rechargeTime, startTime, endTime);
			}
			return false;
		});
	};

	const isRechargeStartTimeAlreadyExists = (
		rechargeItem: Moment,
		deliveryBlockRecharges,
		rechargeDuration: number,
		timezone: string
	) => {
		const duplication = deliveryBlockRecharges.filter(item => {
			const startTime = moment(item.start_time).tz(timezone);
			const endTime = moment(item.start_time).tz(timezone).add(rechargeDuration, 'minutes');
			return isStartTimeInRange(rechargeItem, startTime, endTime);
		});
		return duplication.length > 1;
	};

	const isRechargeStartTimeValid = (
		rechargeTime: Moment,
		deliveryBlockTimes,
		rechargeDuration: number,
		deliveryBlockRechares: DeliveryBlockRechargeBase[],
		deliveryBlockBreaks: DeliveryBlockBreakBase[],
		timezone: string
	) => {
		if (!isStartTimeInDeliveryBlockRange(rechargeTime, deliveryBlockTimes, rechargeDuration)) {
			return {
				result: false,
				errorMsg: 'DELIVERY_BLOCK_MODAL.RELOAD.START_TIME_IS_OUT_OF_BLOCK_RANGE'
			};
		}
		if (isStartTimeOverlapBreaks(rechargeTime, deliveryBlockBreaks, timezone)) {
			return {
				result: false,
				errorMsg: 'DELIVERY_BLOCK_MODAL.RELOAD.START_TIME_OVERLAP_BREAK_RANGE'
			};
		}
		if (isRechargeStartTimeAlreadyExists(rechargeTime, deliveryBlockRechares, rechargeDuration, timezone)) {
			return {
				result: false,
				errorMsg: 'DELIVERY_BLOCK_MODAL.RELOAD.START_TIME_ALREADY_EXISTS'
			};
		}
		return {
			result: true
		};
	};
	return { isRechargeStartTimeValid };
};

export default rechargeValidator;
