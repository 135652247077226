import { QueryClient, QueryClientProvider } from 'react-query';

import { Portal } from '../../components';
import HistoryPage from './history-page';

const queryClient = new QueryClient();

const OrderHistoryPagePortal = () => {
	return (
		<QueryClientProvider client={queryClient}>
			<Portal element={<HistoryPage />} nodeId="order-history-page-portal" />
		</QueryClientProvider>
	);
};

export default OrderHistoryPagePortal;
