import DialogBox from '../../components/dialog-box/dialog-box';
import { useSpeedFactorTranslation } from '../translations';

const OverrideDialog = ({ open, onOk, onCancel, loading }) => {
	const t = useSpeedFactorTranslation();

	return (
		<DialogBox
			title={t.overrideTitle}
			subtitle={t.overrideSubtitle}
			open={open}
			onOk={onOk}
			onCancel={onCancel}
			okText={t.overrideOk}
			okButtonProps={{ disabled: loading }}
			cancelText={t.overrideCancel}
			warning
		/>
	);
};

export default OverrideDialog;
