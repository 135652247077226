import React, { ReactNode, useState } from 'react';
import { PopOver } from '@bringg/react-components';
interface Props {
	content: string | ReactNode;
	children: ReactNode;
}

export const TablePopover = ({ content, children }: Props) => {
	const [isPopoverOpen, setIsPopoverOpen] = useState(false);
	const handleClickChange = (open: boolean) => {
		setIsPopoverOpen(open);
	};

	return (
		<PopOver
			content={content}
			open={isPopoverOpen}
			destroyTooltipOnHide
			trigger="click"
			onOpenChange={handleClickChange}
			placement="bottomLeft"
		>
			{children}
		</PopOver>
	);
};
