import React from 'react';

import { Moment } from 'moment';
import { AutoSizer, List, ListRowProps } from 'react-virtualized';
import { sortBy } from 'lodash';
import { RunHistoryEvent } from '@bringg/types';

import RunHistoryRow from '../run-history-row/run-history-row';
import TranslationService from '../../../../services/translations';
import { useStores } from 'bringg-web';

interface Props {
	historyEvents: RunHistoryEvent[];
}

const getDate = (event: RunHistoryEvent): Moment => {
	const eventData: any = event.data || {};

	return event.date || eventData.created_at;
};

const normalizeData = (historyEvents: RunHistoryEvent[]): RunHistoryEvent[] => {
	const events = historyEvents.filter(historyEvent => {
		return TranslationService.getText(historyEvent) !== 'unknown';
	});

	return sortBy(events, ['date']);
};

const RunHistoryList = (props: Props) => {
	const historyList = normalizeData(props.historyEvents);
	const { tasksStore } = useStores();

	return (
		<div className="run-history-list">
			<AutoSizer>
				{({ height, width }) => (
					<List
						width={width}
						height={height}
						rowCount={historyList.length}
						rowHeight={70}
						rowRenderer={(listRowProps: ListRowProps) => {
							const index = listRowProps.index;
							const history = historyList[index];
							const historyRowText = TranslationService.getText(history);
							const historyData = (history.data as any) || {};
							const taskId = historyData.task_id;

							let id: string | number = taskId;

							if (taskId) {
								id = tasksStore.get(taskId)?.external_id || taskId;
							}

							return (
								<div key={index} style={listRowProps.style}>
									<RunHistoryRow
										id={id}
										eventType={TranslationService.getEventType(history)}
										key={`${index}`}
										counter={index + 1}
										date={getDate(history)}
										text={historyRowText}
									/>
								</div>
							);
						}}
					/>
				)}
			</AutoSizer>
		</div>
	);
};

export default RunHistoryList;
