import { useObserver } from 'mobx-react';
import useStores from './use-stores';
import MerchantConfiguration from '../stores/merchant-configurations/domain-objects/merchant-configuration';

const useMerchantConfiguration = (): MerchantConfiguration => {
	const { merchantConfigurationsStore } = useStores();

	return useObserver(() => merchantConfigurationsStore.configuration);
};

export default useMerchantConfiguration;
