import { find as _find, forEach as _forEach, toNumber as _toNumber } from 'lodash';
import DispatchMapMarkersView, { MarkerType } from '../../dispatch-map-markers-view';
import { StatusColors } from '../../utils/utils';
import Driver from '../../../../stores/drivers/domain-object/driver';
import { getMapMarkerIcon } from '../../utils/markers';
import { Task } from '@bringg/types';

export enum LegendOption {
	DEFAULT,
	ORDER_STATUS,
	DRIVER,
	STOP_NUMBER,
	TAG
}

export const transformDefaultState = (dispatchMapMarkersView: DispatchMapMarkersView) => {
	const lockedMarkersMap = dispatchMapMarkersView.lockedMarkers;

	_forEach(Object.keys(lockedMarkersMap), markersType => {
		const lockedMarkers = lockedMarkersMap[markersType];

		_forEach(lockedMarkers, lockedMarker => {
			const { id, defaultIcon } = lockedMarker;

			if (defaultIcon) {
				lockedMarker.icon = defaultIcon;
				lockedMarker.defaultIcon = null;
			}

			dispatchMapMarkersView.addOrUpdateMarker(_toNumber(markersType), id, lockedMarker, false, true);
		});
	});
};

export const transformOrderStatus = (
	dispatchMapMarkersView: DispatchMapMarkersView,
	tasks: Task[],
	taskStatusColors: StatusColors
) => {
	_forEach(tasks, task => {
		const { id, status } = task;
		const marker = dispatchMapMarkersView.get(MarkerType.TASKS, id);
		const { location, popup, icon: defaultIcon } = marker;

		if (!marker) {
			return;
		}

		const color = taskStatusColors[status].color;
		const icon = getMapMarkerIcon(color);
		dispatchMapMarkersView.addOrUpdateMarker(
			MarkerType.TASKS,
			id,
			{
				id,
				location,
				icon,
				defaultIcon,
				popup
			},
			true,
			true
		);
	});
};

export const transformDriver = (
	dispatchMapMarkersView: DispatchMapMarkersView,
	drivers: Driver[],
	colors: string[]
) => {
	_forEach(drivers, (driver, index) => {
		const { id } = driver;
		const marker = dispatchMapMarkersView.get(MarkerType.DRIVERS, id);
		if (!marker) {
			return;
		}

		const { location, popup, icon: defaultIcon } = marker;

		const color = colors[index];
		const icon = getMapMarkerIcon(color);
		dispatchMapMarkersView.addOrUpdateMarker(
			MarkerType.DRIVERS,
			id,
			{
				id,
				location,
				icon,
				defaultIcon,
				popup
			},
			true,
			true
		);
	});
};

export const transformStopNumber = (
	dispatchMapMarkersView: DispatchMapMarkersView,
	tasks: Task[],
	colors: string[]
) => {
	_forEach(tasks, task => {
		const { id } = task;
		const marker = dispatchMapMarkersView.get(MarkerType.TASKS, id);
		const { location, popup, icon: defaultIcon } = marker;

		if (!marker) {
			return;
		}

		let activeWaypointPosition = 1;
		const { active_way_point_id } = task;

		if (active_way_point_id) {
			const activeWayPoint = _find(task.way_points, { id: active_way_point_id });
			activeWaypointPosition = activeWayPoint.position;
		}

		const color = colors[activeWaypointPosition - 1];
		const icon = getMapMarkerIcon(color);
		dispatchMapMarkersView.addOrUpdateMarker(
			MarkerType.TASKS,
			id,
			{
				id,
				location,
				icon,
				defaultIcon,
				popup
			},
			true,
			true
		);
	});
};

export const transformTag = (
	dispatchMapMarkersView: DispatchMapMarkersView,
	groupedTasksByTags: { [key: string]: Task[] },
	colors: string[]
) => {
	_forEach(Object.keys(groupedTasksByTags), (groupKey, index) => {
		const tasks = groupedTasksByTags[groupKey];

		_forEach(tasks, task => {
			const { id } = task;
			const marker = dispatchMapMarkersView.get(MarkerType.TASKS, id);
			const { location, popup, icon: defaultIcon } = marker;

			if (!marker) {
				return;
			}

			const color = colors[index];
			const icon = getMapMarkerIcon(color);
			dispatchMapMarkersView.addOrUpdateMarker(
				MarkerType.TASKS,
				id,
				{
					id,
					location,
					icon,
					defaultIcon,
					popup
				},
				true,
				true
			);
		});
	});
};
