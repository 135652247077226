import { getRoot, wrapRoot } from 'mobx-easy';
import TaskRejectReasonsRootStore from '../task-reject-reasons-root-store';

export const REJECT_REASONS_WRAPPER = 'reject-reasons-root';

const getStore = () => {
	try {
		return getRoot<TaskRejectReasonsRootStore>(REJECT_REASONS_WRAPPER);
	} catch (error) {
		return wrapRoot({ wrapperName: REJECT_REASONS_WRAPPER, env: {}, RootStore: TaskRejectReasonsRootStore });
	}
};

export const taskRejectReasonsRoot = { getStore };
