import React from 'react';

import { TFunction, useTranslation } from 'react-i18next';
import { Button, EditableTitle, Tooltip, TreeSelect } from '@bringg/react-components';
import { ApplicationTeamConfigurations } from '@bringg/types';

import { EnrichedTeamsTreeData } from '../../../types';

import './form-header.scss';

export type HeaderProps = {
	name: string;
	setName: React.Dispatch<React.SetStateAction<string>>;
	description: string;
	setDescription: React.Dispatch<React.SetStateAction<string>>;
	teamIds: number[];
	setTeamIds: React.Dispatch<React.SetStateAction<number[]>>;
	alreadyAssignedTeamIds: number[];
	teamsTreeData: EnrichedTeamsTreeData[];
	getRouteOptimizationApplicationATCs: (teamId: number) => ApplicationTeamConfigurations;
	isTeamOptimizationSupported: (teamId) => boolean;
	isDefaultConfiguration: boolean;
};

interface CustomTreeLabel {
	label: string;
	t: TFunction;
}

const ReassignableLabel = ({ label, t }: CustomTreeLabel) => (
	<span className="reassignable-team-entry">
		<span className="reassignable-team-label">{label}</span>
		<Button className="reassignable-team-button" type="link">
			{t('OPTIMIZATION_CONFIGURATIONS.MANIFEST.TEAM.REASSIGN')}
		</Button>
	</span>
);

const OptimizationSettingsFormHeader = ({
	name,
	setName,
	description,
	setDescription,
	teamIds: selectedTeams,
	setTeamIds,
	alreadyAssignedTeamIds,
	teamsTreeData,
	isDefaultConfiguration,
	isTeamOptimizationSupported
}: HeaderProps) => {
	const { t } = useTranslation();

	return isDefaultConfiguration ? (
		<div className="optimization-settings-header-empty">
			<p>{t('OPTIMIZATION_CONFIGURATIONS.INIT.SELECT_PRECISE')}</p>
		</div>
	) : (
		<div className="optimization-settings-header">
			<div className="optimization-settings-name-block required-field">
				<EditableTitle
					data-test-id="route-optimization-settings-name"
					className="optimization-settings-name"
					value={name}
					placeholder={t('OPTIMIZATION_CONFIGURATIONS.MANIFEST.OPTIMIZATION_NAME')}
					onValueChange={setName}
				/>
			</div>

			<div className="optimization-settings-description-block">
				<EditableTitle
					value={description}
					placeholder={t('OPTIMIZATION_CONFIGURATIONS.MANIFEST.DESCRIPTION')}
					onValueChange={setDescription}
				/>
			</div>
			<span>{t('OPTIMIZATION_CONFIGURATIONS.INIT.SELECT_PRECISE')}</span>
			<div className="optimization-settings-teams">
				<div className="input-title required-field">{t('OPTIMIZATION_CONFIGURATIONS.MANIFEST.TEAM.TITLE')}</div>
				<TreeSelect
					allowSelectAll
					showArrow
					value={selectedTeams}
					treeData={teamsTreeData.map(team => {
						const assigned =
							team.optimizationId && !selectedTeams.find(selectedTeam => selectedTeam === team.value);
						const teamBelongToOptimizationButNotAssigned = alreadyAssignedTeamIds
							.filter(team => !selectedTeams.includes(team))
							.includes(team.value);
						const isTeamSupported = isTeamOptimizationSupported(team.value);

						if (!isTeamSupported) {
							return {
								...team,
								disabled: !selectedTeams.includes(team.value),
								label: (
									<Tooltip title={t('OPTIMIZATION_CONFIGURATIONS.UNSUPPORTED_TEAM_TOOLTIP')}>
										{team.label}
									</Tooltip>
								)
							};
						}

						if (assigned && !teamBelongToOptimizationButNotAssigned) {
							return {
								...team,
								label: <ReassignableLabel label={team.label} t={t} />
							};
						}

						return team;
					})}
					selectAllText={t('MULTI_SELECT.SELECT_ALL')}
					placeholder={t('OPTIMIZATION_CONFIGURATIONS.MANIFEST.TEAM.SELECT_TEAM')}
					onChange={teamIds => setTeamIds(teamIds as number[])}
					getPopupContainer={trigger => trigger.parentNode}
				/>
			</div>
		</div>
	);
};

export default OptimizationSettingsFormHeader;
