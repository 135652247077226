import React from 'react';

import { Views } from '@bringg/types';

import RulesListComponent from 'bringg-web/features/fleets-configuration/rules-list/rule-list';
import WorkflowsByView from 'bringg-web/features/automation-workflows/components/workflows-by-view/workflows-by-view';

const DeliveryFleetRouter = () => {
	return (
		<div className="singleton">
			<WorkflowsByView view={Views.Fleet} />
			<RulesListComponent />
		</div>
	);
};

export default DeliveryFleetRouter;
