import React, { useContext } from 'react';

import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
// eslint-disable-next-line no-restricted-imports
import { RadioChangeEvent } from 'antd';
import { Radio, TimePicker } from '@bringg/react-components';

import { getFormatByMerchant } from 'bringg-web/features/automation-workflows/utils/helpers';
import { OptimizationSettingsFormContext } from '../optimization-configuration-form/optimization-configuration-form';
import { OptimizationSettingsFormContextType, OptimizationTimeAttribute } from '../../types';
import InputLabelWithTooltip from 'bringg-web/features/optimization-configuration/components/input-label-with-tooltip/input-label-with-tooltip';

import './time-input.scss';

const TimeInput = (props: OptimizationTimeAttribute) => {
	const { title, tooltipTitle, id, value, options, isSubField, disabled, containerId } = props;

	const { handleChange } = useContext<OptimizationSettingsFormContextType>(OptimizationSettingsFormContext);
	const { t } = useTranslation();

	const handleRadioChange = (e: RadioChangeEvent) => handleChange(id, e.target.value);
	const radioValue = value ?? options.find(option => option.default)?.id;

	const format = getFormatByMerchant();

	return (
		<div
			className={classnames('conditional-time-input', 'route-optimization-field-container', {
				'sub-field': isSubField
			})}
		>
			<InputLabelWithTooltip
				id={id}
				title={t(title)}
				tooltip={tooltipTitle ? t(tooltipTitle) : null}
				containerId={containerId}
			/>
			<Radio.Group key={id} value={radioValue} onChange={handleRadioChange}>
				{options.map((option, key) => {
					const shouldShowTimePicker = Boolean(option.timePicker && option.timePickerValueKey);

					if (shouldShowTimePicker) {
						const timePickerValue = props[option.timePickerValueKey];

						return (
							<div key={key}>
								<Radio className="route-optimization-radio" value={option.id} disabled={disabled}>
									{t(option.title)}
									{option.beta === true ? <span className="beta-option">beta</span> : null}
								</Radio>
								{radioValue === option.id ? (
									<TimePicker
										className="route-optimization-time-picker"
										key={key}
										minuteStep={5}
										showNow={false}
										format={format}
										onChange={e => {
											handleChange(id, {
												value: option.id,
												[option.timePickerValueKey]: e,
												isValid: true
											});
										}}
										value={timePickerValue ?? null}
										disabled={disabled}
									/>
								) : null}
							</div>
						);
					}

					return (
						<Radio key={key} className="route-optimization-radio" value={option.id} disabled={disabled}>
							{t(option.title)}
							{option.beta === true ? <span className="beta-option">beta</span> : null}
						</Radio>
					);
				})}
			</Radio.Group>
		</div>
	);
};

export default TimeInput;
