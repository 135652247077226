export enum FilterBarListItemKeys {
	SEARCH = 'search',
	ACTIONS = 'actions',
	TYPE = 'type'
}

export enum TemplateType {
	PREDEFINED = 1,
	CUSTOM = 2
}
