import { getRootEnv } from '@bringg-frontend/bringg-web-infra';
import { datetime, NumberType } from '@bringg/types';
import { action, computed, observable, makeObservable } from 'mobx';
import find from 'lodash/find';
import mapValues from 'lodash/mapValues';
import keyBy from 'lodash/keyBy';

export default class CustomerConfiguration {
	id: number;
	merchant_id: number;
	sharing_mode: number;
	keep_time_window_fixed_on_share = false;
	alert_on_start = false;
	show_delivery_status = false;
	alert_on_eta_delay = false;
	alert_on_eta_delay_minutes = 0;
	alert_on_task_created = false;
	alert_customer_on_schedule_ready = false;
	alert_on_route_started = false;
	alert_on_checkin = false;
	alert_on_task_schedule = false;
	alert_on_checkout = 0;
	alert_customer_on_reschedule = false;
	private_mode_on_start = false;
	private_mode_on_task_created = false;
	private_mode_on_schedule_ready = false;
	private_mode_on_route_started = false;
	private_mode_on_checkin = false;
	private_mode_on_reschedule = false;
	private_mode_on_checkout = false;
	enable_share_with_customer_mms = false;
	alert_customer_minutes_before_eta = 0;
	alert_on_customer_pickup = false;
	rate_us_string = '';
	created_at?: datetime;
	updated_at?: datetime;
	customized_tracking_url: string;
	hide_note_to_driver = false;

	private sms_settings: Bringg.SmsSetting[] = [];

	constructor(config: Partial<Bringg.CustomerConfiguration>) {
		makeObservable<CustomerConfiguration, 'sms_settings'>(this, {
			keep_time_window_fixed_on_share: observable,
			alert_on_start: observable,
			show_delivery_status: observable,
			alert_on_eta_delay: observable,
			alert_on_eta_delay_minutes: observable,
			alert_on_task_created: observable,
			alert_customer_on_schedule_ready: observable,
			alert_on_route_started: observable,
			alert_on_checkin: observable,
			alert_on_task_schedule: observable,
			alert_on_checkout: observable,
			alert_customer_on_reschedule: observable,
			private_mode_on_start: observable,
			private_mode_on_task_created: observable,
			private_mode_on_schedule_ready: observable,
			private_mode_on_route_started: observable,
			private_mode_on_checkin: observable,
			private_mode_on_reschedule: observable,
			private_mode_on_checkout: observable,
			enable_share_with_customer_mms: observable,
			alert_customer_minutes_before_eta: observable,
			alert_on_customer_pickup: observable,
			rate_us_string: observable,
			sms_settings: observable,
			hide_note_to_driver: observable,
			setValue: action,
			setFreeSms: action,
			smsSettings: computed
		});
		Object.assign(this, config);
	}

	setValue(key: string, value: boolean | number | string) {
		this[key] = value;
	}

	setFreeSms(messageType: string, value: boolean) {
		const numberType = value ? NumberType.FreeSms : null;

		const smsSetting = this.findSmsSetting(messageType);
		if (smsSetting) {
			smsSetting.number_type = numberType;
		} else {
			this.sms_settings.push({
				merchant_id: this.merchant_id,
				number_type: numberType,
				message_type: messageType
			});
		}
	}

	findSmsSetting(messageType: string): Bringg.SmsSetting {
		return find(this.sms_settings, { message_type: messageType });
	}

	get smsSettings(): { [key: string]: NumberType } {
		return mapValues(keyBy(this.sms_settings, 'message_type'), 'number_type');
	}

	async update(): Promise<Bringg.CustomerConfiguration> {
		return getRootEnv().dashboardSdk.sdk.customerConfiguration.update(this);
	}
}
