import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Input, Row, Col } from 'antd';
import { observable, makeObservable } from 'mobx';
import { BringgException } from '@bringg/dashboard-sdk/dist/Core/BringgException';
import { Translate } from '../../../../translation';
import Company from '../../../../stores/companies/domain-objects/company';
import { Button } from '../../../../components';
import notification from '../../../../services/notification';
import AddressAutoComplete from '../../../../components/address-auto-complete/address-auto-complete';
import { Status as LoadingStatus } from '../../../../components/address-auto-complete/with-loading/with-loading';

interface Props {
	company: Company;
	toggleEditMode: () => void;
}

class CompanyCardEdit extends Component<Props> {
	// eslint-disable-next-line react/sort-comp
	addressStatus: LoadingStatus = LoadingStatus.Idle;
	initialCompany = { ...this.props.company };

	constructor(props: Props) {
		super(props);

		makeObservable(this, {
			addressStatus: observable
		});
	}

	save = async () => {
		try {
			await this.props.company.update();
			this.props.toggleEditMode();
		} catch (e) {
			const { message, details } = e as BringgException;
			notification.error(message, details as string);
		}
	};

	cancel = () => {
		this.props.company.setExternalId(this.initialCompany.external_id);
		this.props.company.setName(this.initialCompany.name);
		this.props.company.setPhone(this.initialCompany.phone);
		this.props.company.setTaxId(this.initialCompany.tax_id);
		this.props.company.setAddress(
			this.initialCompany.address,
			this.initialCompany.lat,
			this.initialCompany.lng,
			this.initialCompany.street,
			this.initialCompany.city,
			this.initialCompany.zipcode,
			this.initialCompany.house_number
		);
		this.props.toggleEditMode();
	};

	handleAddressSelect = async (address, addressDetailsPromise) => {
		const { lat, lng, street, city, zipcode, house_number } = await addressDetailsPromise;
		this.props.company.setAddress(address, lat, lng, street, city, zipcode, house_number);
	};

	handleAddressStatusChanged = (status: LoadingStatus) => {
		this.addressStatus = status;
	};

	render() {
		const { cancel, save } = this;
		const { company } = this.props;

		return (
			<div className="company-container">
				<div className="company-card-edit">
					<Col className="company-card-edit-input">
						<div className="company-card-edit-row">
							<div className="company-card-label">
								<Translate text="COMPANIES.ID" />
							</div>
							<div className="company-card-value">
								<Input
									data-test-id="inputCompanyExternalId"
									onChange={e => company.setExternalId(e.target.value)}
									value={company.external_id}
								/>
							</div>
						</div>
						<div className="company-card-edit-row">
							<div className="company-card-label">
								<Translate text="COMPANIES.NAME" />
							</div>
							<div className="company-card-value">
								<Input
									data-test-id="inputCompanyName"
									onChange={e => company.setName(e.target.value)}
									value={company.name}
								/>
							</div>
						</div>
						<div className="company-card-edit-row">
							<div className="company-card-label">
								<Translate text="COMPANIES.PHONE" />
							</div>
							<div className="company-card-value">
								<Input
									data-test-id="inputCompanyPhone"
									onChange={e => company.setPhone(e.target.value)}
									value={company.phone}
								/>
							</div>
						</div>
						<div className="company-card-edit-row">
							<div className="company-card-label">
								<Translate text="COMPANIES.TAX_ID" />
							</div>
							<div className="company-card-value">
								<Input
									data-test-id="inputCompanyTaxId"
									onChange={e => company.setTaxId(e.target.value)}
									value={company.tax_id}
								/>
							</div>
						</div>
						<div className="company-card-edit-row">
							<div className="company-card-label">
								<Translate text="COMPANIES.ADDRESS" />
							</div>
							<div className="company-card-value">
								<AddressAutoComplete
									onAddressStatusChanged={this.handleAddressStatusChanged}
									onSelect={this.handleAddressSelect}
									defaultValue={this.initialCompany.address}
									name="company.address"
								/>
							</div>
						</div>
					</Col>
					<Row className="company-card-edit-button">
						<Button data-test-id="cancelButton" size="middle" onClick={cancel} type="default">
							<Translate text="COMPANIES.CANCEL" />
						</Button>
						<Button
							data-test-id="applyButton"
							size="middle"
							onClick={save}
							type="primary"
							disabled={this.addressStatus === LoadingStatus.Fail}
						>
							<Translate text="COMPANIES.APPLY" />
						</Button>
					</Row>
				</div>
			</div>
		);
	}
}

export default observer(CompanyCardEdit);
