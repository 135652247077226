import moment, { max, min, Moment, unitOfTime } from 'moment-timezone';
import { DurationInputArg2 } from 'moment/moment';
import { HourFormat } from '@bringg/types';
import { DateUtils } from '@bringg/common-utils';
/* eslint-disable no-magic-numbers */

const TWELVE_HOURS = 'h:mm A';
const TWELVE_HOURS_V2 = 'hh:mm A';
const TWENTY_FOUR_HOURS = 'HH:mm';
const MINUTES_IN_HOUR = 60;
const HOURS_IN_DAY = 24;

const getMerchantTimeFormat = (merchantHourFormat: HourFormat, useV2 = false) => {
	if (use12Hours(merchantHourFormat)) {
		if (useV2) {
			return TWELVE_HOURS_V2;
		}
		return TWELVE_HOURS;
	}
	return TWENTY_FOUR_HOURS;
};

const use12Hours = (merchantHourFormat: HourFormat) => {
	return merchantHourFormat !== HourFormat.TwentyFourHours;
};

const diffMinutesFromLastMonday = (date: Moment): number => {
	return DateUtils.toMinutesInWeek(date);
};

const addMinutesToLastMonday = (minutes: number, timezone: string): Moment => {
	const monday = moment().tz(timezone).startOf('isoWeek');
	return moment(monday).add(minutes, 'minute');
};

export const isBetween = (
	date: string | number | Moment,
	ranges: (string | number)[] | Moment[],
	type?: unitOfTime.StartOf
): boolean => {
	return moment(date).isBetween(ranges[0], ranges[1], type, '[]');
};

export const subtract = (date: string | number, amount: number, unit: DurationInputArg2) => {
	return moment(date).subtract(amount, unit);
};

export const add = (date: string | number, amount: number, unit: DurationInputArg2) => {
	return moment(date).add(amount, unit);
};

export const setFullDate = (date: Moment, dateTime: string, timezone: string) => {
	return moment(dateTime ?? date)
		.tz(timezone)
		.set({ year: date.year(), month: date.month(), date: date.date() })
		.toISOString();
};

export const setTimeAndDate = (date: Moment, dateTime: string, timezone: string): string => {
	return moment(dateTime)
		.tz(timezone)
		.set({ hour: date.hour(), minute: date.minute(), date: date.date() })
		.toISOString();
};

export const sortDates = (tasksDates: string[]) =>
	tasksDates.sort((a, b) => {
		return new Date(a).valueOf() - new Date(b).valueOf();
	});

export const getStartOfDayMoment = (time: string | Moment, timezone?: string) => {
	if (timezone) {
		return moment.tz(time, timezone).startOf('day');
	}
	return moment(time).startOf('day');
};

export const endOfDay = (time: string | Moment, timezone?: string) => {
	if (timezone) {
		return moment.tz(time, timezone).endOf('day');
	}
	return moment(time).endOf('day');
};

export const getDateFromMoment = (startOfDayString: Moment) => {
	return startOfDayString.toString().slice(0, startOfDayString.toString().indexOf(' GMT'));
};
export const getStartOfDay = (time: string | Moment, timezone?: string) => {
	const startOfDayString = getStartOfDayMoment(time, timezone);
	return startOfDayString.isValid() ? getDateFromMoment(startOfDayString) : '';
};

export const getEndOfDay = (time: string | Moment, timezone?: string) => {
	const endOfDayString = endOfDay(time, timezone);
	return endOfDayString.isValid() ? getDateFromMoment(endOfDayString) : '';
};

const getTimeFromUnixTime = (unixSeconds: number) => {
	return moment.unix(unixSeconds).valueOf();
};

const unixToISOString = (unixSeconds: number) => {
	return moment.unix(unixSeconds).toISOString();
};

export const isOneTimeFrameBetweenOtherTimeFrame = (firstTimeRange: Moment[], secondTimeFrame: Moment[]) => {
	return max(firstTimeRange[0], secondTimeFrame[0]) < min(firstTimeRange[1], secondTimeFrame[1]);
};

export const getDiffAsHoursAndMinutes = (endTime: string | Moment, startTime: string | Moment): string => {
	const diffDuration = moment.duration(moment(endTime).diff(moment(startTime)));
	const hours = Math.floor(diffDuration.asHours());
	const minutes = Math.floor(diffDuration.asMinutes() % 60);

	return `${hours > 9 ? hours : `0${hours}`}:${minutes > 9 ? minutes : `0${minutes}`}`;
};

export const getDaysBetweenDates = (startTime: Date | string, endTime: Date | string, timezone: string) => {
	const days = {};

	if (endTime) {
		const startOfFirstDay = getStartOfDay(moment(startTime), timezone);

		const end = timezone ? moment.tz(endTime, timezone) : moment(endTime);

		for (let day = moment(startOfFirstDay).clone(); day <= end; day.add(1, 'day')) {
			days[getDateFromMoment(day)] = true;
		}
	}

	return days;
};

const daysToMinutes = (days: number) => {
	return days * HOURS_IN_DAY * MINUTES_IN_HOUR;
};

const hoursToMinutes = (hours: number) => {
	return hours * MINUTES_IN_HOUR;
};

const minutesToHours = (minutes: number) => {
	return minutes / MINUTES_IN_HOUR;
};

const getTimeDisplay = (timeValue: number): string => {
	const timeLimit = 9;
	return timeValue > timeLimit ? `${timeValue}` : `0${timeValue}`;
};

const sumOfMinutes = (hours: number, minutes: number) => {
	return dateUtils.hoursToMinutes(hours) + minutes;
};

const resetMinutesIfIsNotEqual = (date: Moment, minutes: number, reset = 0) => {
	if (date.minutes() !== minutes) {
		date.set({ minutes: reset });
	}
};

const getTimeInSelectedDate = (time: Date | Moment | string, date: Date | Moment | string) => {
	return moment.utc(time).set({
		date: moment.utc(date).date(),
		month: moment.utc(date).month(),
		year: moment.utc(date).year(),
		milliseconds: 0
	});
};

const getCurrentDayDateBetweenRangeDates = (startDate: string, endDate: string, timezone: string): Moment => {
	const currentDay = moment().day();
	const daysCount = moment(endDate).diff(startDate, 'day');

	for (let i = 0; i <= daysCount; i++) {
		const date = moment(startDate).tz(timezone).add(i, 'days');
		if (date.day() === currentDay) {
			return date;
		}
	}

	return moment(startDate).tz(timezone);
};

const duration = (value: number, unit: 'seconds' | 'milliseconds' = 'seconds') => {
	const hourFactor = unit === 'seconds' ? 3600 : 3600 * 1000;

	const hours = value / hourFactor;

	const rhours = Math.floor(hours);
	const minutes = (hours - rhours) * 60;
	const rminutes = Math.round(minutes);

	const format = () => `${rhours.toString().padStart(2, '0')}:${rminutes.toString().padStart(2, '0')}`;

	return {
		format
	};
};

export const dateUtils = {
	TWENTY_FOUR_HOURS,
	TWELVE_HOURS,
	MINUTES_IN_HOUR,
	HOURS_IN_DAY,
	add,
	subtract,
	isBetween,
	getDateFromMoment,
	getDaysBetweenDates,
	getStartOfDay,
	getDiffAsHoursAndMinutes,
	isOneTimeFrameBetweenOtherTimeFrame,
	unixToISOString,
	sortDates,
	setFullDate,
	getStartOfDayMoment,
	setTimeAndDate,
	getTimeFromUnixTime,
	use12Hours,
	getMerchantTimeFormat,
	diffMinutesFromLastMonday,
	addMinutesToLastMonday,
	hoursToMinutes,
	minutesToHours,
	daysToMinutes,
	getTimeDisplay,
	sumOfMinutes,
	resetMinutesIfIsNotEqual,
	getTimeInSelectedDate,
	getCurrentDayDateBetweenRangeDates,
	duration
};
