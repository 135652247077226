import React, { forwardRef, ReactElement, useEffect, useState } from 'react';

import { observer } from 'mobx-react';
import { EditableTitle, PureErrorBoundary } from '@bringg/react-components';
import classNames from 'classnames';
import { BringgFontIcons, BringgIcon } from '@bringg/bringg-icons';
import { Link } from 'react-router-dom';
import { airBrakeNotifier, errorNotifier } from '@bringg-frontend/bringg-web-infra';

import notification from 'bringg-web/services/notification';
import WorkflowStore from '../../stores/workflow-store';
import useAutomationTranslations from '../../utils/use-automation-translations';
import ReadOnlyTag from './components/read-only-tag';
import ShowMoreButton from '../show-more-button';
import ExtraActions from './components/extra-actions/extra-actions';
import WorkflowInfoTags from './components/workflow-info-tags';
import ActiveSwitch from './components/active-switch/active-switch';
import WorkflowContent from '../workflow/workflow-content';
import SummaryInfos from './components/summary-info/summary-infos';
import { GenericActionStore, workflowsRootStore } from '../../stores/internal';
import ActionIcon from '../action-icon/action-icon';

import styles from './workflow-card.module.scss';

interface WrapWithLinkProps {
	workflow: WorkflowStore;
	children: ReactElement;
	isReadOnly: boolean;
}

interface Props {
	workflow: WorkflowStore;
	expanded?: boolean;
	viewMode?: boolean;
	shouldScrollToView?: boolean;
}

enum IgnoreWorkflowOpen {
	ALL = 'all',
	CHILDREN = 'children'
}

const OptionalWrapWithLink = ({ workflow, isReadOnly, children }: WrapWithLinkProps) => {
	const linkRef = React.useRef<HTMLAnchorElement>(null);

	useEffect(() => {
		if (!linkRef.current) return;
		const link = linkRef.current;

		function ignoreClickableElements(e: MouseEvent) {
			// After clicking on the title, to lose focus and save you need to click anywhere else
			// so in case the user click on the card, it should not open it
			if (workflow.isTitleDirty) {
				e.preventDefault();
				return;
			}

			if (workflow.isDirty) {
				airBrakeNotifier.notify(new Error('Workflow has pending changes when not in the editor'), {
					workflow_id: workflow.id,
					changes: workflow.changes
				});
			}

			let current = e.target as HTMLElement;

			if (current === e.currentTarget) {
				return;
			}

			while (current && current !== e.currentTarget) {
				const ignoreOpenWorkflow = current.getAttribute('data-ignore-open-workflow') as
					| IgnoreWorkflowOpen
					| undefined;

				if (
					ignoreOpenWorkflow === IgnoreWorkflowOpen.ALL ||
					(ignoreOpenWorkflow === IgnoreWorkflowOpen.CHILDREN && current !== e.target)
				) {
					e.preventDefault();
					return;
				}
				current = current.parentElement;
			}
		}

		// Need to add event listener like this because react onClick not happening
		// fast enough when clicking the link and it won't be called
		link.addEventListener('click', ignoreClickableElements);

		return () => link.removeEventListener('click', ignoreClickableElements);
	}, [workflow, isReadOnly]);

	if (isReadOnly) {
		return children;
	}

	return (
		<Link innerRef={linkRef} to={workflow.id.toString()} className={styles.workflowLink}>
			{children}
		</Link>
	);
};

const WorkflowCard = forwardRef<HTMLDivElement, Props>(
	({ workflow, expanded = false, viewMode = false, shouldScrollToView = false }: Props, ref) => {
		const { workflowRepo } = workflowsRootStore.getStore();
		const {
			editorDefaultTitle,
			error: { failedToSaveWorkflow, failedToDeleteWorkflow, failedToDuplicateWorkflow },
			success: { workflowDeleted, workflowDuplicated }
		} = useAutomationTranslations();
		const [showMore, setShowMore] = useState<boolean>(expanded);
		const scrollToViewRef = React.useRef<HTMLDivElement>(null);

		useEffect(() => {
			setShowMore(expanded);
		}, [expanded]);

		const { id, enabled, isSavingTitle, isSavingEnable, team_ids, trigger, updated_at, updated_by_user_id } =
			workflow;

		useEffect(() => {
			if (!shouldScrollToView) {
				return;
			}

			scrollToViewRef.current.scrollIntoView({
				behavior: 'smooth',
				block: 'start'
			});
		}, [shouldScrollToView]);

		const isReadOnly = workflow.isReadOnly || viewMode;

		const toggleEnabled = async (isEnabled: boolean) => {
			const res = await workflow.updateWorkflow({ enabled: isEnabled });

			if (!res.success) {
				notification.error(failedToSaveWorkflow);
			}
		};

		const updateTitle = async (title: string) => {
			if (!workflow.isTitleDirty) {
				return;
			}

			const res = await workflow.updateWorkflow({ title });

			if (!res.success) {
				notification.error(failedToSaveWorkflow);
			}
		};

		const deleteWorkflow = async () => {
			try {
				await workflow.delete();
				notification.success(workflowDeleted);
			} catch (e) {
				notification.error(failedToDeleteWorkflow);
			}
		};

		const duplicatedWorkflow = async () => {
			try {
				await workflowRepo.duplicate(workflow.id);
				notification.success(workflowDuplicated);
			} catch (e) {
				notification.error(failedToDuplicateWorkflow);
			}
		};

		return (
			<OptionalWrapWithLink workflow={workflow} isReadOnly={isReadOnly}>
				<div
					key={workflow.id}
					ref={ref}
					className={classNames(styles.workflowCard, {
						[styles.viewMode]: viewMode,
						[styles.readOnly]: isReadOnly,
						[styles.startAsHighlighted]: shouldScrollToView
					})}
					data-test-id={`workflow-card-${id}`}
				>
					<div className={styles.header} ref={scrollToViewRef}>
						<div className={styles.title} data-test-id="workflow-header">
							{workflow.actions.actions.map(action => (
								<ActionIcon
									type={action.type}
									icon={(action as GenericActionStore).icon}
									tooltipText={(action as GenericActionStore).title}
									key={action.guid}
								/>
							))}
							{isReadOnly ? (
								<>
									<div data-test-id="workflow-title">{workflow.title || editorDefaultTitle}</div>
									<ReadOnlyTag isWorkflowSensitive={workflow.isReadOnly && !viewMode} />
								</>
							) : (
								<div data-ignore-open-workflow={IgnoreWorkflowOpen.CHILDREN}>
									<EditableTitle
										value={workflow.title}
										onValueChange={workflow.setTitle}
										placeholder={editorDefaultTitle}
										onApplyChanges={updateTitle}
										loading={isSavingTitle}
									/>
								</div>
							)}
						</div>
						<div className={styles.actions} data-ignore-open-workflow={IgnoreWorkflowOpen.ALL}>
							<ActiveSwitch
								isEnabled={enabled}
								isLoading={isSavingEnable}
								isDisabled={isReadOnly}
								onChange={toggleEnabled}
							/>
							<ShowMoreButton
								showMore={showMore}
								setShowMore={setShowMore}
								disabled={!workflow.isExpandable}
							/>
							{!isReadOnly && (
								<ExtraActions
									data-test-id={`${workflow.id}-extra-actions`}
									duplicate={duplicatedWorkflow}
									delete={deleteWorkflow}
								/>
							)}
						</div>
					</div>

					<div
						className={classNames(styles.content, {
							[styles.showLess]: !showMore
						})}
					>
						<WorkflowContent
							content={workflow.content}
							isExpanded={showMore}
							data-ignore-open-workflow={IgnoreWorkflowOpen.CHILDREN}
						/>
					</div>

					<div className={styles.footer} data-ignore-open-workflow={IgnoreWorkflowOpen.CHILDREN}>
						<WorkflowInfoTags teamIds={team_ids} triggerFamily={trigger.family} />
						<SummaryInfos updatedByUserId={updated_by_user_id} updatedAt={updated_at} />
					</div>
				</div>
			</OptionalWrapWithLink>
		);
	}
);

const ResilientWorkflowCard = forwardRef<HTMLDivElement, Props>((props: Props, ref) => {
	return (
		<PureErrorBoundary
			onError={errorNotifier}
			fallback={() => {
				return (
					<div className={classNames(styles.workflowCard, styles.workflowRenderError)}>
						<BringgIcon iconName={BringgFontIcons.Warning} className={styles.warningIcon} />
						<span>
							Failed to display workflow {props?.workflow?.title} (id: <code>{props?.workflow?.id}</code>)
						</span>
						<span>Please contact support</span>
					</div>
				);
			}}
		>
			<WorkflowCard {...props} ref={ref} />
		</PureErrorBoundary>
	);
});

export default observer(ResilientWorkflowCard);
