import React, { useEffect, useMemo, useRef, useState } from 'react';

import ReactDOM from 'react-dom';
import classNames from 'classnames';
import { usePopper } from 'react-popper';
import type { Placement } from '@popperjs/core/lib/enums';
import _isFunction from 'lodash/isFunction';

import { GanttTimeLineItem, ToolTipPlacement } from '../../gantt-types';
import useClickOutside from '../../hooks/use-click-outside';

import './timeline-item-tooltip.scss';

const DEFAULT_TOOLTIP_PLACE: Placement = 'top';

const ARROW_SIZE = 18; // px

export type TooltipTranslations = { [key: string]: string };

export type TooltipRenderFunction = (gantItems: GanttTimeLineItem[], removeTooltip?: () => void) => JSX.Element;

export interface Props {
	ganttItems: GanttTimeLineItem[] | null;
	tooltipRender?: TooltipRenderFunction;
	targetElement: HTMLElement | null;
	removeTooltip: () => void;
	shouldShowTooltipArrow: boolean;
	tooltipContainerSelector?: string;
}

const TimeLineItemTooltip = React.memo(
	({
		ganttItems,
		targetElement,
		tooltipRender,
		removeTooltip,
		shouldShowTooltipArrow,
		tooltipContainerSelector
	}: Props) => {
		const [referenceElement, setReferenceElement] = useState(targetElement);
		const popperElement = useRef<HTMLDivElement>(null);
		const shouldRenderTooltip = targetElement && ganttItems && _isFunction(tooltipRender);

		useEffect(() => {
			setReferenceElement(targetElement);
		}, [targetElement]);

		useClickOutside(popperElement, !!(shouldRenderTooltip && shouldShowTooltipArrow), removeTooltip);

		const { styles, attributes } = usePopper(referenceElement, popperElement.current, {
			placement: DEFAULT_TOOLTIP_PLACE,
			modifiers: [
				{
					name: 'flip',
					options: {
						fallbackPlacements: [ToolTipPlacement.Left, ToolTipPlacement.Right, ToolTipPlacement.Top]
					}
				},
				{
					name: 'offset',
					options: {
						offset: [0, ARROW_SIZE]
					}
				}
			]
		});

		const arrowPlace = useMemo(() => {
			if (attributes && attributes['popper']) {
				const currentPlace = attributes['popper']['data-popper-placement'];
				if (currentPlace.startsWith(ToolTipPlacement.Left)) {
					return ToolTipPlacement.Right;
				} else if (currentPlace.startsWith(ToolTipPlacement.Right)) {
					return ToolTipPlacement.Left;
				} else if (currentPlace.startsWith(ToolTipPlacement.Top)) {
					return ToolTipPlacement.Bottom;
				} else if (currentPlace.startsWith(ToolTipPlacement.Bottom)) {
					return ToolTipPlacement.Top;
				}
			}
			return '';
		}, [attributes]);

		return (
			<>
				{ReactDOM.createPortal(
					<div
						ref={popperElement}
						className="timeline-item-tooltip-container"
						style={styles.popper}
						{...attributes.popper}
					>
						{shouldRenderTooltip && (
							<>
								{tooltipRender(ganttItems, removeTooltip)}
								{shouldShowTooltipArrow && (
									<div
										data-popper-arrow
										data-test-id="tooltip-arrow"
										className={classNames('timeline-item-tooltip-arrow', `arrow-${arrowPlace}`)}
									/>
								)}
							</>
						)}
					</div>,
					document.querySelector(`.${tooltipContainerSelector}`) || document.body
				)}
			</>
		);
	}
);

export default TimeLineItemTooltip;
