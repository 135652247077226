import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { AlertSeverity } from '@bringg/types';
import classNames from 'classnames';

export const SEVERITIES = {
	[AlertSeverity.Low]: { value: 'LOW', color: 'low' },
	[AlertSeverity.Medium]: { value: 'MEDIUM', color: 'medium' },
	[AlertSeverity.High]: { value: 'HIGH', color: 'high' }
};

interface SeverityLabelProps {
	severityLevel: AlertSeverity;
}

export const SeverityLabel = memo(({ severityLevel }: SeverityLabelProps) => {
	const { t } = useTranslation();

	const severity = SEVERITIES[severityLevel];

	return (
		<div className="severity-option">
			<span className={classNames('dot', severity?.color)} />
			<span className="severity-value">
				{severity && t(`ALERTS_CONFIGURATION.SEVERITY_OPTION.${severity.value}`)}
			</span>
		</div>
	);
});
