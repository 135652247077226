import { getRootEnv } from '@bringg-frontend/bringg-web-infra';
import React, { useCallback, useState } from 'react';

import { FormItem, BringgInput } from '@bringg/react-components';
import debounce from 'debounce-promise';
import { getEnv } from 'mobx-easy';
import { BringgFontIcons, BringgIcon } from '@bringg/bringg-icons';
import { useTranslation } from 'react-i18next';
import { RootEnv } from '@bringg-frontend/bringg-web-infra';

const INPUT_DEBOUNCE = 500;

export const SamlUrlInput = ({
	debounceTimeout = INPUT_DEBOUNCE,
	placeholder = '',
	...formItemProps
}: { debounceTimeout?: number; placeholder?: string } & Record<string, any>) => {
	const { t } = useTranslation();
	const [isLoading, setIsLoading] = useState(false);
	const [isValid, setIsValid] = useState(false);

	const onTargetUrlInput = useCallback(
		debounce(async (value: string) => {
			if (!value) {
				return;
			}

			setIsValid(false);
			setIsLoading(true);
			try {
				await getRootEnv().dashboardSdk.sdk.applicationMerchantConfiguration.testSamlUrl(value);
				setIsValid(true);
			} finally {
				setIsLoading(false);
			}
		}, debounceTimeout),
		[debounceTimeout]
	);

	const targetUrlSuffix = isValid ? <BringgIcon iconName={BringgFontIcons.Selected} /> : <span />;

	return (
		<FormItem
			className={`saml-url-input ${isValid ? 'valid' : ''}`}
			{...formItemProps}
			rules={[
				...((formItemProps as any).rules || []),
				{
					validator: async (_, value) => onTargetUrlInput(value),
					message: t('SSO_CONFIGURATION.CANT_GET_URL')
				}
			]}
		>
			<BringgInput
				suffix={isLoading ? <BringgIcon spin iconName={BringgFontIcons.Loader} /> : targetUrlSuffix}
				placeholder={placeholder}
			/>
		</FormItem>
	);
};
