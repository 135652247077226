import React from 'react';
import { BringgFontIcons, BringgIcon } from '@bringg/bringg-icons';
import styles from '../../floating-inventories.module.scss';

type EventLogHeaderProps = {
	title: string;
	onClose: () => void;
};

const EventLogHeader = ({ title, onClose }: EventLogHeaderProps) => {
	return (
		<div
			className={styles['floating-inventories-event-log-header-title']}
			data-test-id={'event-log-header-container'}
		>
			<p title={title.toUpperCase()}>{title.toUpperCase()}</p>
			<div
				role="button"
				className={styles['floating-inventories-event-log-header-close-button']}
				onClick={onClose}
				data-test-id={'close-event-log-button'}
			>
				<BringgIcon iconName={BringgFontIcons.Close} />
			</div>
		</div>
	);
};

export default EventLogHeader;
