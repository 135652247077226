import React from 'react';
import { Button, Modal } from '@bringg/react-components';
import { Rule } from '../../../stores/fleets-configuration/fleets-configurations-store';

import './remove-teams-modal.scss';

interface Translation {
	removeTeams: string;
	cancel: string;
	teamsAssignedToRules: string;
}

export interface ConflictedTeams {
	name: string;
	id: string;
	rules: Rule[];
}

interface Props {
	rule: Rule;
	teams: ConflictedTeams[];
	translations: Translation;
	isVisible: boolean;
	onRemoveTeams(): void;
	onCancel(): void;
}

const RemoveTeamsModal = ({ rule, teams, onRemoveTeams, onCancel, translations, isVisible }: Props) => {
	const { name, ruleType } = rule;
	const getRulesList = (rules: Rule[]) =>
		rules.map(rule => (
			<div className="rule-tag" key={rule.id}>
				{rule.name}
			</div>
		));
	const getTeamsList = () => {
		return (
			<div className="teams-list">
				{teams.map(team => (
					<div className="team-row" key={team.id}>
						<span className="team-name">{team.name}</span>
						{getRulesList(team.rules)}
					</div>
				))}
			</div>
		);
	};

	const title = (
		<div className="title">
			<span className="rule-name">{name}</span>
			<span className="rule-type">{ruleType}</span>
		</div>
	);

	const footer = [
		<Button key="back" onClick={onCancel} type="link" className="cancel">
			{translations.cancel}
		</Button>,
		<Button key="remove-teams" className="remove-teams" type="primary" onClick={onRemoveTeams}>
			{translations.removeTeams}
		</Button>
	];

	return (
		<Modal visible={isVisible} title={title} footer={footer} className="remove-teams-modal" closable={false}>
			<div className="teams-list">{getTeamsList()}</div>
			<div className="indicators">
				<span className="indicator" />
				<span className="indicator-text">{translations.teamsAssignedToRules}</span>
			</div>
		</Modal>
	);
};

export default RemoveTeamsModal;
