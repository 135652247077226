import type { TimelineFormatOption, TimelineOptions } from 'vis-timeline/types/entry-standalone';
import moment, { MomentInput } from 'moment';
import momentTimezone from 'moment-timezone';
import _isNil from 'lodash/isNil';
import _isUndefined from 'lodash/isUndefined';
import _isString from 'lodash/isString';
import _pickBy from 'lodash/pickBy';

import type { ExtendedTimelineItem, GanttOptions } from './gantt-types';

const VisTimelineBaseOptions = Object.freeze<TimelineOptions>({
	height: '100%',
	autoResize: true,
	stack: true, // false == overlap items
	orientation: 'top',
	verticalScroll: true,
	horizontalScroll: true,
	zoomable: true,
	zoomKey: 'ctrlKey',
	margin: {
		axis: 0,
		item: {
			vertical: 4,
			horizontal: 0
		}
	},
	zoomMin: 5 * 60 * 1000, // 5 min, in milliseconds
	zoomMax: 1 * 24 * 60 * 60 * 1000, // 1 day, in milliseconds
	showTooltips: false,
	dataAttributes: ['id', 'group', 'start', 'end'],
	itemsAlwaysDraggable: { item: true, range: true },
	selectable: true,
	multiselectPerGroup: true,
	editable: {
		add: false,
		remove: false,
		overrideItems: true,
		updateTime: true,
		updateGroup: true
	},
	cluster: {
		fitOnDoubleClick: true,
		showStipes: true, // must be true. fix by hack in cluster css. without it we have exceptions because of timeline's bug.
		clusterCriteria: (
			{ shouldCluster: first = true, customData: firstCustomData }: ExtendedTimelineItem,
			{ shouldCluster: second = true, customData: secondCustomData }: ExtendedTimelineItem
		) => {
			const isOfSameType = firstCustomData?.type === secondCustomData?.type;
			return first && second && isOfSameType;
		}
	}
} as TimelineOptions);

export const getVisTimelineBaseOptions = (timezone?: string): TimelineOptions => {
	const timeOptions: TimelineOptions = {
		moment: (date: MomentInput) => {
			return _isString(timezone) ? momentTimezone.tz(date, timezone) : momentTimezone(date);
		},
		locale: momentTimezone.locale(),
		format: {
			minorLabels: {
				hour: moment.localeData().longDateFormat('LT')
			}
		}
	};

	return { ...VisTimelineBaseOptions, ...timeOptions };
};

export function setGanttOptions(visOptions: TimelineOptions, options: GanttOptions = {}): TimelineOptions {
	const format: TimelineFormatOption | undefined = visOptions.format;
	const cluster = visOptions.cluster;

	const {
		formatXaxis,
		minAxisTime,
		maxAxisTime,
		displayStartTime,
		displayEndTime,
		showCurrentTime,
		overlapItems,
		constAxisStep,
		fitClusterOnDoubleClick,
		...restOfOptions
	} = options;

	if (fitClusterOnDoubleClick !== undefined) {
		if (cluster) cluster.fitOnDoubleClick = fitClusterOnDoubleClick;
	}

	if (formatXaxis) {
		// @ts-ignore wrong type of params and return value in the function
		format.minorLabels = formatXaxis;
	}

	return {
		...visOptions,
		format,
		cluster,
		...removeUnnecessaryKeys(restOfOptions),
		..._pickBy(
			{
				stack: _isNil(overlapItems) ? visOptions.stack : !overlapItems,
				showCurrentTime: showCurrentTime ?? visOptions.showCurrentTime,
				start: displayStartTime ?? visOptions.start,
				end: displayEndTime ?? visOptions.end,
				min: minAxisTime ?? visOptions.min,
				max: maxAxisTime ?? visOptions.max,
				timeAxis: constAxisStep ?? visOptions.timeAxis,
				zoomable: options.zoomable ?? visOptions.zoomable,
				zoomKey: options.zoomKey ?? visOptions.zoomKey,
				zoomMax: options.zoomMax ?? visOptions.zoomMax,
				zoomMin: options.zoomMin ?? visOptions.zoomMin,
				onDropObjectOnItem: options.onDropObjectOnItem ?? visOptions.onDropObjectOnItem
			} as TimelineOptions,
			field => !_isUndefined(field)
		)
	};
}

// removing keys not available for the vis timeline to prevent console errors
const removeUnnecessaryKeys = (
	obj: GanttOptions
): Omit<GanttOptions, 'tooltipDelay' | 'defaultColumnWidth' | 'tooltipBy' | 'showRemoveRowButton'> => {
	['tooltipDelay', 'defaultColumnWidth', 'tooltipBy', 'showRemoveRowButton'].forEach(key => {
		delete obj[key];
	});

	return obj;
};
