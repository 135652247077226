import { lazy } from 'react';

import { FallbackComponent } from './components/fallback-component/fallback-component';

export const LazyRichTextEditor = lazy(async () =>
	import('./rich-text-editor').catch(() => {
		console.error('Failed to load chunk with RichTextEditor');
		return { default: FallbackComponent };
	})
);
