import { getRoot } from 'mobx-easy';
import { RuleCategory, Views } from '@bringg/types';

import RootStore from '../../../stores/root-store';
import { workflowsRootStore } from '../stores/workflows-root-store';

function isLoaded() {
	const { metadataRepo } = workflowsRootStore.getStore();

	return metadataRepo.metadata;
}

async function loadAll() {
	const { teamsStore, merchantConfigurationsStore, customAttributesStore } = getRoot<RootStore>().data;
	const { metadataRepo, workflowRepo } = workflowsRootStore.getStore();

	if (metadataRepo.metadata) {
		return;
	}

	await merchantConfigurationsStore.fetch();

	await metadataRepo.load(RuleCategory.Workflow);

	await Promise.all([workflowRepo.loadAll(), teamsStore.fetchAll(), customAttributesStore.fetchAll()]);
}

function getByView(view: Views) {
	const { workflowRepo } = workflowsRootStore.getStore();

	return workflowRepo.getAll().filter(wf => wf.isViewSupported(view));
}

export const workflowsProvider = { isLoaded, loadAll, getByView };
