import { FC } from 'react';

type CountPageProps = {
	count: number;
	limit: number;
	page: number;
	isFirstPage: boolean;
	outOfText: string;
};

const Count: FC<CountPageProps> = ({ count, limit, page, isFirstPage, outOfText }) => {
	const currentPosition = (): string => {
		if (count === 0) {
			return '0';
		}

		if (isFirstPage) {
			return `${1}-${limit > count ? count : limit}`;
		}

		const pageLimit = page * limit;
		const endNumber = pageLimit < count ? pageLimit : count;

		return `${(page - 1) * limit}-${endNumber}`;
	};

	return (
		<div>
			{currentPosition()} {outOfText} {count}
		</div>
	);
};

export default Count;
