import React from 'react';

import { useObserver } from 'mobx-react';

import { hasAccess } from '../../utils/privileges';
import UsersStore from '../../stores/users/users-store';
import { useStores } from '../../recipes';

interface Props {
	privilegeName: string;
	subPrivilegeName?: string;
	usersStore?: UsersStore;
	children: any;
}

const Privilege = ({ children, privilegeName, subPrivilegeName }: Props) => {
	const { usersStore } = useStores();
	const { currentUser } = usersStore;

	const allowed = hasAccess(currentUser, privilegeName, subPrivilegeName);

	return useObserver(() => <>{allowed ? { ...children } : null}</>);
};

export default Privilege;
