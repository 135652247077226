import { last } from 'lodash';
import type { EditorState } from 'lexical';

type ParsedNodeBaseType = {
	children?: ParsedNodeBaseType[];
	text?: string;
};

type ParsedStateBaseType = {
	root: ParsedNodeBaseType;
};

export const isEmptyState = (editorState: EditorState): boolean => {
	if (editorState.isEmpty()) {
		return true;
	}

	const nodesArray = Array.from(editorState._nodeMap.values());

	return nodesArray.length <= 2 && last(nodesArray)?.__size === 0;
};

type GetTextOptions = {
	maxRecursiveCalls?: number;
	cutoffTextLength?: number;
};

export const getTextFromSerializedEditorState = (serializedState: string, options: GetTextOptions = {}) => {
	const { maxRecursiveCalls = 100, cutoffTextLength = 50 } = options;
	let recursiveCalls = 0;
	let parsedState: ParsedStateBaseType;

	const childrenReducer = (source: ParsedNodeBaseType, res: { text: string }) => {
		if (recursiveCalls > maxRecursiveCalls || res.text.length >= cutoffTextLength) {
			return res;
		}
		recursiveCalls++;
		if (source.text) {
			const shouldAddSpace = res.text[res.text.length - 1] !== ' ' && source.text[0] !== ' ';
			res.text = `${res.text}${shouldAddSpace ? ' ' : ''}${source.text}`;
		} else if (source.children) {
			source.children.forEach(child => {
				childrenReducer(child, res);
			});
		}
		return res;
	};

	try {
		parsedState = JSON.parse(serializedState);
		return childrenReducer(parsedState.root, { text: '' }).text;
	} catch (e) {
		console.warn(`Wrong rich text editor JSON string`);
		return '';
	}
};
