import React, { useCallback, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { BringgInput, Button, EditableTitle, FormItem, useWatch } from '@bringg/react-components';
import { BringgIcon, BringgFontIcons } from '@bringg/bringg-icons';
import classNames from 'classnames';

const MAX_LENGTH = 255;
const DESCRIPTION_MAX_EDIT_LENGTH = 125;

const Title = ({ value, onChange }) => {
	const { t } = useTranslation();
	const [isEditMode, setIsEditMode] = useState(false);

	const toggleEditMode = useCallback(() => {
		setIsEditMode(!isEditMode);
	}, [isEditMode]);

	return (
		<div className="vehicle-profile-form-title-wrapper">
			<BringgInput
				value={value}
				onChange={onChange}
				onClick={toggleEditMode}
				onBlur={toggleEditMode}
				name="title"
				placeholder={t('VEHICLE_PROFILES.FORM.TITLE')}
				className="vehicle-profile-form-title-input"
			></BringgInput>
			{!isEditMode && (
				<BringgIcon
					iconName={BringgFontIcons.Pencil}
					className={classNames('edit-title-icon', { 'no-value': !value })}
					key="title"
				/>
			)}
		</div>
	);
};

interface TitleSectionProps {
	resetValues: () => void;
	form: any;
	existingVehicleProfilesTitles: string[];
}

const TitleSection = ({ resetValues, form, existingVehicleProfilesTitles }: TitleSectionProps) => {
	const { t } = useTranslation();
	const titleValue = useWatch('title', form);
	const descriptionValue = useWatch('description', form);

	const setTitle = useCallback(e => form.setFieldValue('title', e?.target?.value), [form]);

	return (
		<div className="vehicle-profile-form--title-editor-wrapper">
			<div className="vehicle-profile-form--title-editor">
				<FormItem
					name="title"
					rules={[
						{
							required: true,
							message: t('VEHICLE_PROFILES.ERROR.REQUIRED_TITLE'),
							type: 'string'
						},
						{
							min: 2,
							message: t('VEHICLE_PROFILES.ERROR.TITLE_LENGTH')
						},
						{
							async validator(_, value: string) {
								const isFieldTouched = form.isFieldTouched('title');
								const isNameDuplicated = existingVehicleProfilesTitles.includes(value.toLowerCase());

								if (isFieldTouched && isNameDuplicated) {
									return Promise.reject(t('VEHICLE_PROFILES.ERROR.TITLE_IN_USE'));
								}
							}
						}
					]}
				>
					<Title value={titleValue} onChange={setTitle} />
				</FormItem>
				<FormItem name="description">
					<EditableTitle
						className="vehicle-profile-description"
						value={descriptionValue}
						placeholder={t('VEHICLE_PROFILES.FORM.VEHICLE_PROFILE_DESCRIPTION')}
						onValueChange={description => form.setFieldValue('description', description)}
						maxLength={MAX_LENGTH}
						maxEditLength={DESCRIPTION_MAX_EDIT_LENGTH}
					/>
				</FormItem>
			</div>
			<div className="vehicle-profile-form--title-editor-reset-button">
				<Button type="link" onClick={resetValues}>
					{t('VEHICLE_PROFILES.FORM.CLEAR_ALL')}
				</Button>
			</div>
		</div>
	);
};

export default TitleSection;
