import React from 'react';
import { useTranslation } from 'react-i18next';
import { DateDisplayOption, getFormattedTimeWindow, LayoutType } from '@bringg/react-components';
import { BreakType } from '@bringg/types';
import { DeliveryBlockBreakBase } from '../break-types';
import { getHoursFromStartOfDay } from '../break/relative-break';
import './breaks-summary.scss';

export interface Props {
	breaks: DeliveryBlockBreakBase[];
	format: string;
	timezone: string;
}

export const BreaksSummary = ({ breaks, format, timezone }: Props) => {
	const { t } = useTranslation();
	return (
		<div className="breaks-summary-items">
			{breaks.length > 0
				? breaks.map((breakItem, index) => (
						<div className="breaks-summary-item" key={index.toString()}>
							<span className="break-type">
								{t(`DELIVERY_BLOCKS.${BreakType[breakItem.break_type].toUpperCase()}_BREAK`)},
							</span>
							{breakItem.break_type !== BreakType.Fixed && (
								<span className="break-length">{`${breakItem.length}${t(
									'DELIVERY_BLOCKS_MODAL.BREAKS.MINUTES'
								)}`}</span>
							)}
							<span className="break-times">
								{breakItem.break_type !== BreakType.Relative &&
									getFormattedTimeWindow(
										breakItem.start_time,
										breakItem.end_time,
										format,
										timezone,
										LayoutType.INLINE,
										'',
										DateDisplayOption.SHOW_DATE_WHEN_DIFF_DAY
									)}
								{breakItem.break_type === BreakType.Relative && (
									<>{`${t('DELIVERY_BLOCK_MODAL.BETWEEN')} ${getHoursFromStartOfDay(
										breakItem.start_time
									)} - ${getHoursFromStartOfDay(breakItem.end_time)} ${t(
										'DELIVERY_BLOCK_MODAL.HOURS'
									)} `}</>
								)}
							</span>
						</div>
				  ))
				: t('DELIVERY_BLOCK_MODAL.BREAKS.NO_SET')}
		</div>
	);
};
