import moment from 'moment';
import { isBetween } from '@bringg-frontend/utils';

import { DeliveryBlockBreakBase } from 'bringg-web/features/delivery-blocks-v2/new-modal/breaks/break-types';
import { ONE_DAY } from 'bringg-web/features/delivery-blocks-v2/new-modal/delivery-block-modal';
import { breakService } from 'bringg-web/features/delivery-blocks-v2/services/break-service';

const isBreakInvalid = (
	breakItem: DeliveryBlockBreakBase,
	breakIndex: number,
	otherBreaks: DeliveryBlockBreakBase[],
	allowMultiDayOptimization: boolean,
	deliveryBlockTimes,
	timezone: string,
	onUpdateBreak
) => {
	const {
		isBreakBetweenBlockTime,
		isBreakBetweenOrSameOtherBreaksTimes,
		isBreakLengthGreaterThanBreakRangeTimes,
		isRelativeBreak
	} = breakService;
	return (
		(allowMultiDayOptimization
			? !validateAndUpdateOvernightBreak(breakItem, breakIndex, deliveryBlockTimes, timezone, onUpdateBreak)
			: !isBreakBetweenBlockTime(breakItem, deliveryBlockTimes)) ||
		isBreakLengthGreaterThanBreakRangeTimes(breakItem) ||
		isBreakBetweenOrSameOtherBreaksTimes(breakItem, otherBreaks) ||
		(isRelativeBreak(breakItem) ? breakItem.end_time < breakItem.start_time : false) ||
		moment(breakItem.start_time).isSame(breakItem.end_time)
	);
};

const validateAndUpdateOvernightBreak = (
	breakItem: DeliveryBlockBreakBase,
	breakIndex: number,
	deliveryBlockTimes,
	timezone: string,
	onUpdateBreak
): boolean => {
	const { isBreakBetweenBlockTime } = breakService;
	if (isBreakBetweenBlockTime(breakItem, deliveryBlockTimes)) {
		return true;
	}

	const breakItemStartTime = moment(breakItem.start_time).clone().add(ONE_DAY, 'day');
	const breakItemEndTime = moment(breakItem.end_time).clone().add(ONE_DAY, 'day');

	if (
		isBetween(breakItemStartTime, [deliveryBlockTimes.startTime, deliveryBlockTimes.endTime]) &&
		isBetween(breakItemEndTime, [breakItemStartTime.toISOString(), deliveryBlockTimes.endTime])
	) {
		onUpdateBreak(breakItem, breakIndex, {
			start_time: breakItemStartTime?.tz(timezone).toISOString(),
			end_time: breakItemEndTime?.tz(timezone).toISOString()
		});
		return true;
	}

	return false;
};

export const breakValidator = { isBreakInvalid, validateAndUpdateOvernightBreak };
