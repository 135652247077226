import { getRootEnv } from '@bringg-frontend/bringg-web-infra';
import { getEnv } from 'mobx-easy';
import { RootEnv } from '@bringg-frontend/bringg-web-infra';

import ActionsConfiguration, { normalizeActionsConfiguration } from './actions-configuration';
import { ActionsConfigurationLevel } from '../../../types/common.consts';

export default class ShiftActionsConfiguration extends ActionsConfiguration {
	level = ActionsConfigurationLevel.SHIFT;

	update = async () => {
		return getRootEnv().dashboardSdk.sdk.userConfigurations.update({
			user_state_actions: normalizeActionsConfiguration(this.action_data, this.level)
		});
	};

	delete = async () => {
		return getRootEnv().dashboardSdk.sdk.userConfigurations.update({
			user_state_actions: []
		});
	};

	// Wait...what? why do we need it here? it is not related to class instance anyhow
	start = async (userId: number, teamId?: number) => {
		return getRootEnv().dashboardSdk.sdk.shifts.startShift(userId, teamId);
	};
}
