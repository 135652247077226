import { datetime } from '@bringg/types';
import { MomentInput } from 'moment';

export enum RUN_HISTORY_TYPES {
	ALL_EVENTS = -1,
	ALERT = 0,
	HOME_EVENT = 1,
	OFFLINE_ONLINE_EVENT = 2,
	SHIFT = 3,
	TASK_HISTORY = 4
}

export enum SHIFT_TYPES {
	START = 0,
	END = 1
}

export enum HOME_EVENT_TYPES {
	ARRIVED = 0,
	LEFT = 1
}

export enum OFFLINE_ONLINE_EVENT_TYPES {
	OFFLINE = 0,
	ONLINE = 1
}

export enum TASK_STATUS {
	CREATED = 0,
	ASSIGNED = 1,
	ON_THE_WAY = 2,
	CHECKED_IN = 3,
	DONE = 4,
	ACCEPTED = 6,
	CANCELLED = 7,
	REJECTED = 8,
	UNACKNOWLEDGED = 9
}

export enum PREPARATION_STATUS {
	ACKNOWLEDGED = 0,
	START_PREPARATION = 1,
	PREPARATION_STARTED = 2,
	READY_FOR_PICK_UP = 3,
	PICKED_UP = 4,
	SEND_TO_PREPARATION_FAILED = 5,
	UNACKNOWLEDGED = 6
}

export interface RunTask {
	id: number;
	externalId: string;
	index: number;
	priority: number;
	title: string;
	scheduledAt: MomentInput;
	firstStopCheckIn: MomentInput;
	firstStopCheckOut: MomentInput;
	lastStopCheckIn: MomentInput;
	lastStopCheckOut: MomentInput;
	address: string;
	customerName: string;
	eta: datetime;
}
