import { find } from 'lodash';
import type { Nullable } from '@bringg/types/types/common';

import { workflowsRootStore } from '../stores/workflows-root-store';

const getDescriptionTextByAlertType = (alertType: number): Nullable<string> => {
	const { workflowAlertsStore } = workflowsRootStore.getStore();
	const alert = find(workflowAlertsStore.alerts, { id: alertType });

	return alert ? alert.description_rich_text : null;
};

export const workflowAlertsProvider = { getDescriptionTextByAlertType };
