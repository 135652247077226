import { computed, makeObservable, observable, runInAction } from 'mobx';

import { workflowsRootStore } from 'bringg-web/automation-platform-exports';
import TemplateStore from './template-store';

export enum FetchStatus {
	NOT_FETCHED = 'not-fetched',
	FETCHING = 'fetching',
	FETCHED = 'fetched'
}

class TemplateRepo {
	templates: TemplateStore[] = [];
	fetchStatus: FetchStatus = FetchStatus.NOT_FETCHED;

	constructor() {
		makeObservable(this, {
			templates: observable,
			fetchStatus: observable,
			available: computed
		});
	}

	load = async () => {
		workflowsRootStore.getStore().predefinedAlertRepo.load();

		if (this.fetchStatus !== FetchStatus.NOT_FETCHED) {
			return;
		}

		await this.fetchTemplates();
	};

	get available(): TemplateStore[] {
		return this.templates.filter(template => template.available);
	}

	fetchTemplates = async (): Promise<void> => {
		try {
			this.fetchStatus = FetchStatus.FETCHING;
			const templates = await workflowsRootStore.getStore().workflowRepo.getTemplates();

			runInAction(() => {
				this.templates = templates
					.map(template => new TemplateStore(template))
					.filter(template => template.canComplete);
				this.fetchStatus = FetchStatus.FETCHED;
			});
		} catch (err) {
			runInAction(() => {
				this.fetchStatus = FetchStatus.NOT_FETCHED;
			});
			console.error('Failed loading workflow templates', err);
		}
	};
}

export default TemplateRepo;
