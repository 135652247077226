import React, { ChangeEvent, Component } from 'react';
import { FieldData as FieldDataEntity } from '@bringg/types';
// eslint-disable-next-line no-restricted-imports
import { Input, Row } from 'antd';
import { Translate } from '../../../../translation';

const DYNAMIC_LABEL_DESCRIPTION = 'If you want to use dynamic values,\nsee the legend on the right';

interface Props {
	fieldData: FieldDataEntity;
	onFieldUpdated: (updatedField: Partial<FieldDataEntity>) => void;
}

const { TextArea } = Input;

export default class DynamicLabel extends Component<Props, any> {
	updateLabel = (updateEvent: ChangeEvent<HTMLTextAreaElement>): void => {
		this.props.onFieldUpdated({ label: updateEvent.target.value });
	};

	updateLabelTitle = (updateEvent: ChangeEvent<HTMLInputElement>): void => {
		this.props.onFieldUpdated({ label_title: updateEvent.target.value });
	};

	render() {
		const { fieldData } = this.props;

		return (
			<div className="dynamic-label">
				<Row>
					<div className="input-label">
						<Translate text="ACTION_CONFIGURATION.FIELD_LABEL_TITLE" />:
					</div>
					<Input
						type="text"
						onChange={this.updateLabelTitle}
						value={fieldData.label_title}
						data-test-id="action-data-field-label-title"
						className="action-data-input"
					/>
				</Row>
				<Row>
					<div className="input-label">
						<Translate text="ACTION_CONFIGURATION.FIELD_LABEL_TEXT" />:
					</div>
					<TextArea
						onChange={this.updateLabel}
						value={fieldData.label}
						data-test-id="action-data-field-label-text"
						className="action-data-input"
						autoSize
					/>
				</Row>
				<Row className="label-description">
					<i className="bringg-icon bringg-icon-info-filled" />
					{DYNAMIC_LABEL_DESCRIPTION}
				</Row>
			</div>
		);
	}
}
