import React, { useCallback, useState } from 'react';

import classNames from 'classnames';
import { BringgFontIcons, BringgIcon } from '@bringg/bringg-icons';

import useRoutePlayerAnimation from '../custom-hooks/bringg-map-route-player-hooks/use-bringg-map-route-player-animation/use-bringg-map-route-player-animation';
import useBringgMapRoutePlayer from '../custom-hooks/bringg-map-route-player-hooks/use-bringg-map-route-player/use-bringg-map-route-player';
import { DISABLED_COLOR, PRIMARY_COLOR } from '../../utils';
import { MAIN_STEPS } from '../consts';
import { RouteLocation } from '../../types';

import styles from './bringg-map-route-player-controls.module.scss';

interface Props {
	routeLocations: RouteLocation[];
	routeIndex: number;
	disabled: boolean;
	onRouteIndexChange: (newIndex: number) => void;
	onUpdateDriverMarkerLocation: (customRouteIndex?: number) => void;
	onTogglePlaying: (isPlaying: boolean) => void;
}

const BringgMapRoutePlayerControls: React.FC<Props> = ({
	routeLocations,
	routeIndex,
	disabled,
	onRouteIndexChange,
	onUpdateDriverMarkerLocation,
	onTogglePlaying
}) => {
	const [isPlaying, setIsPlaying] = useState(false);

	const pause = useCallback(() => {
		if (routeIndex >= routeLocations.length) {
			onRouteIndexChange(0);
			onUpdateDriverMarkerLocation(0);
		}
		onTogglePlaying(false);
		setIsPlaying(false);
	}, [routeIndex, routeLocations]);

	const { animateRoutePlayerIfNeeded, isRouteIndexValid } = useRoutePlayerAnimation({
		isPlaying,
		routeIndex,
		routeLocations,
		onRouteIndexChange,
		onUpdateDriverMarkerLocation,
		pause
	});

	const onRoutePlayerGotToEndOfRoute = () => {
		pause();
		onRouteIndexChange(0);
		onUpdateDriverMarkerLocation(0);
	};
	const onRoutePlayerPlay = () => {
		onUpdateDriverMarkerLocation();
	};

	const onRoutePlayerStop = () => {
		onUpdateDriverMarkerLocation();
	};

	const { playRoutePlayer } = useBringgMapRoutePlayer({
		isPlaying,
		routeLocations,
		routeIndex,
		onRoutePlayerGotToEndOfRoute,
		onRoutePlayerPlay,
		onRoutePlayerStop
	});

	const onPlayPauseBtnClicked = () => {
		if (disabled) {
			return;
		}

		if (isPlaying) {
			pause();
			return;
		}

		onTogglePlaying(true);
		setIsPlaying(true);

		playRoutePlayer();
		animateRoutePlayerIfNeeded();
	};

	const stepForward = useCallback(() => {
		if (disabled) {
			return;
		}
		let newIndex = routeIndex + MAIN_STEPS;
		if (!isRouteIndexValid(newIndex)) {
			newIndex = routeLocations.length - 1;
		}
		onRouteIndexChange(newIndex);
	}, [routeIndex]);

	const stepBackward = useCallback(() => {
		if (disabled) {
			return;
		}
		let newIndex = routeIndex - MAIN_STEPS;
		if (newIndex < 0) {
			newIndex = 0;
		}
		onRouteIndexChange(newIndex);
	}, [routeIndex]);

	const color = disabled ? DISABLED_COLOR : PRIMARY_COLOR;

	return (
		<div className={classNames(styles.bringgMapRoutePlayer)}>
			<button onClick={stepBackward}>
				<BringgIcon
					iconName={BringgFontIcons.ChevronsStart}
					className={classNames(styles.bringgMapRoutePlayerIcon)}
					color={color}
				/>
			</button>
			<button onClick={onPlayPauseBtnClicked}>
				<BringgIcon
					className={classNames(styles.bringgMapRoutePlayerIcon)}
					iconName={isPlaying ? BringgFontIcons.Pause : BringgFontIcons.Play}
					color={color}
				/>
			</button>
			<button onClick={stepForward}>
				<BringgIcon
					className={classNames(styles.bringgMapRoutePlayerIcon)}
					iconName={BringgFontIcons.ChevronsEnd}
					color={color}
				/>
			</button>
		</div>
	);
};

export default BringgMapRoutePlayerControls;
