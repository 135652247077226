import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { observer } from 'mobx-react';
import { DeliveryWindow, PlannedDeliveryWindow } from '@bringg/dashboard-sdk';
import { Button, Notification } from '@bringg/react-components';
import { useTranslation } from 'react-i18next';
import moment from 'moment-timezone';
import { PrivilegeTypes } from '@bringg/types';
import { cloneDeep as _cloneDeep } from 'lodash';
import { MultipleSelectModal } from '@bringg-frontend/multiple-select-modal';
import { BringgFontIcons, BringgIcon } from '@bringg/bringg-icons';
import { PlannedDeliveryWindowsBulkDeleteRequest } from '@bringg/dashboard-sdk/dist/PlannedDeliveryWindows/planned-delivery-windows.consts';
import { useHasFeatureFlag } from '@bringg-frontend/hooks';

import PlannedDeliveryWindowsSlotsModal from './modals/slots-modal/planned-delivery-windows-slots-modal';
import PlannedDeliveryWindowsCalendar, { CalendarViewMode } from './calendar/planned-delivery-windows-calendar';
import PlannedDeliveryWindowsToolbar, { DropdownData } from './toolbar/planned-delivery-windows-toolbar';
import { useStores } from '../../recipes';
import { plannedDeliveryWindowsRangeDates } from 'bringg-web/features/planned-delivery-windows/services/planned-delivery-windows-range-dates';
import RecurrenceTypeModal, {
	RecurrenceDisabled
} from 'bringg-web/features/planned-delivery-windows/modals/recurrence-type-modal/recurrence-type-modal';
import {
	RecurrenceType,
	RecurrenceTypeModalModes
} from 'bringg-web/features/planned-delivery-windows/modals/recurrence-type-modal/recurrence-type-modal.consts';
import ExclusionWindowModal from 'bringg-web/features/planned-delivery-windows/modals/exclusion-window-modal/exclusion-window-modal';
import useTimeFormat from 'bringg-web/hooks/use-time-format';
import { timezoneProvider } from 'bringg-web/services/timezone/timezone-provider';
import { useHasAccess } from 'bringg-web/utils/privileges';
import PlannedDeliveryWindowsMultiSlotModal from 'bringg-web/features/planned-delivery-windows/modals/multi-slot-modal/planned-delivery-windows-multi-slot-modal';
import FiltersPanel from './components/filters-panel';
import { useTableFilters } from './hooks/use-table-filters';

import styles from './planned-delivery-windows-view.module.scss';

export const NO_TEAM = 0;

interface Props {
	setTeamInUrl: (teamId: number) => void;
	selectedTeamId: number;
}

const CONTAINER_ID = 'planned-delivery-container';

const PlannedDeliveryWindowsView = ({ setTeamInUrl, selectedTeamId }: Props) => {
	const hasDeliverySlotsFiltersEnabled = useHasFeatureFlag('delivery_slots_filters_enabled');

	const { t } = useTranslation();
	const [plannedDeliveryWindowModalVisible, setPlannedDeliveryWindowModalVisible] = useState(false);
	const [plannedDeliveryWindowMultiSlotModalVisible, setPlannedDeliveryWindowMultiSlotModalVisible] = useState(false);
	const [recurrenceTypeModalVisible, setRecurrenceTypeModalVisible] = useState(false);
	const [recurrenceTypeModalMode, setRecurrenceTypeModalMode] = useState<RecurrenceTypeModalModes>(
		RecurrenceTypeModalModes.EDIT
	);
	const [calendarViewMode, setCalendarViewMode] = useState<CalendarViewMode>(CalendarViewMode.week);
	const [exclusionModalVisible, setExclusionModalVisible] = useState(false);
	const [calendarLoading, setCalendarLoading] = useState(true);
	const [deliveryWindowsByPlannedDeliveryWindowId, setDeliveryWindowsByPlannedDeliveryWindowId] = useState(
		new Map<number, DeliveryWindow>()
	);
	const [selectedEventsIds, setSelectedEventsIds] = useState<Set<number>>(new Set());
	const [plannedDeliveryWindowsToView, setPlannedDeliveryWindowsToView] = useState<PlannedDeliveryWindow[]>([]);
	const [plannedDeliveryWindowToEdit, setPlannedDeliveryWindowToEdit] = useState<PlannedDeliveryWindow | null>(null);
	const [previousPlannedDeliveryWindow, setPreviousPlannedDeliveryWindow] = useState<PlannedDeliveryWindow | null>(
		null
	);
	const [calendarRangeDates, setCalendarRangeDates] = useState({
		startDate: moment().startOf('week').toISOString(),
		endDate: moment().endOf('week').toISOString()
	});

	const tableFiltersState = useTableFilters();

	const [recurrenceDisabled, setRecurrenceDisabled] = useState<RecurrenceDisabled>({
		current: false,
		current_and_following: false,
		all: false
	});
	const hourFormat = useTimeFormat();
	const timezone =
		selectedTeamId === NO_TEAM
			? timezoneProvider.getTimezoneByMerchant()
			: timezoneProvider.getTimezoneByTeamId(selectedTeamId);

	const { plannedDeliveryWindows, deliveryWindows, exclusionWindows } = useStores();
	const canEditExclusionWindows = useHasAccess(PrivilegeTypes.EDIT_EXCLUSION_WINDOWS);

	useEffect(() => {
		const fetchData = async () => {
			try {
				await onTeamChange(selectedTeamId);
			} catch (e) {
				console.error('failed to load: ', e);
			} finally {
				setCalendarLoading(false);
			}
		};
		fetchData();
		return () => plannedDeliveryWindows.removeAll();
	}, []);

	const setFilteredPlannedDeliveryWindows = (
		plannedDeliveryWindows: PlannedDeliveryWindow[],
		timezone: string,
		rangeDate?: {
			startDate: string;
			endDate: string;
		}
	) => {
		setPlannedDeliveryWindowsToView(
			plannedDeliveryWindowsRangeDates.getBetween(
				plannedDeliveryWindows,
				rangeDate ? rangeDate : calendarRangeDates,
				timezone
			)
		);
	};
	const fetchPlannedDeliveryWindows = async (teamId: number, startDate: string, endDate: string) => {
		setCalendarLoading(true);
		try {
			let plannedDeliveryWindowsResult: PlannedDeliveryWindow[];
			if (teamId !== NO_TEAM) {
				plannedDeliveryWindowsResult = await plannedDeliveryWindows.loadAllByTeam(teamId, startDate, endDate, {
					useCache: false
				});
			} else {
				plannedDeliveryWindowsResult = await plannedDeliveryWindows.loadAllWithoutTeam(startDate, endDate, {
					useCache: false
				});
			}
			const newTimezone =
				teamId === NO_TEAM
					? timezoneProvider.getTimezoneByMerchant()
					: timezoneProvider.getTimezoneByTeamId(teamId);

			setFilteredPlannedDeliveryWindows(plannedDeliveryWindowsResult, newTimezone, {
				startDate,
				endDate
			});
			const plannedDeliveryWindowsIds = plannedDeliveryWindowsResult.map(pdw => pdw.id).filter(Boolean);
			if (plannedDeliveryWindowsIds.length) {
				await fetchDeliveryWindows(plannedDeliveryWindowsIds, startDate, endDate);
			}
		} catch (e) {
			console.error('failed to load pdw: ', e);
		} finally {
			setCalendarLoading(false);
		}
	};

	const fetchDeliveryWindows = async (plannedDeliveryWindowsIds: number[], startTime: string, endTime: string) => {
		const deliveryWindowsResult = await deliveryWindows.loadMany(plannedDeliveryWindowsIds, { startTime, endTime });
		const createdMap = new Map(deliveryWindowsResult.map(item => [item.planned_delivery_window_id, item]));
		setDeliveryWindowsByPlannedDeliveryWindowId(createdMap);
	};
	const onTeamChange = async (teamId: number) => {
		setTeamInUrl(teamId);
		const newTimezone =
			teamId === NO_TEAM
				? timezoneProvider.getTimezoneByMerchant()
				: timezoneProvider.getTimezoneByTeamId(teamId);
		const startDate = moment().tz(newTimezone).startOf('week').toISOString();
		const endDate = moment().tz(newTimezone).endOf('week').toISOString();
		setCalendarRangeDates({ startDate, endDate });
		await Promise.all([
			fetchPlannedDeliveryWindows(teamId, startDate, endDate),
			fetchExclusionWindows(teamId, startDate, endDate)
		]);
	};

	const fetchExclusionWindows = async (teamId: number, startDate: string, endDate: string) => {
		if (teamId === NO_TEAM) {
			await exclusionWindows.loadAllWithoutTeam(
				{ start_time: startDate, end_time: endDate },
				{ useCache: false }
			);
		} else {
			await exclusionWindows.loadAllByTeam(
				{ team_id: teamId, start_time: startDate, end_time: endDate },
				{ useCache: false }
			);
		}
	};
	const changeCalendarRangeDates = async (startDate: string, endDate: string) => {
		setCalendarRangeDates({ startDate, endDate });
		clearSelectedEventsIds();
		await Promise.all([
			fetchPlannedDeliveryWindows(selectedTeamId, startDate, endDate),
			fetchExclusionWindows(selectedTeamId, startDate, endDate)
		]);
	};

	const closePlannedDeliveryWindowModal = () => {
		setPlannedDeliveryWindowModalVisible(false);
		setPlannedDeliveryWindowToEdit(null);
	};

	const updateLocallyPdwState = (updatedPdw: Partial<PlannedDeliveryWindow>) => {
		let previousPdwState: PlannedDeliveryWindow;
		const newPdwToShow = plannedDeliveryWindowsToView.map(pdw => {
			if (pdw.id === updatedPdw.id) {
				previousPdwState = { ...pdw };
				return { ...pdw, ...updatedPdw };
			}
			return pdw;
		});
		setFilteredPlannedDeliveryWindows(newPdwToShow, timezone);

		return previousPdwState;
	};

	const refetchPlannedDeliveryWindows = async () => {
		await fetchPlannedDeliveryWindows(selectedTeamId, calendarRangeDates.startDate, calendarRangeDates.endDate);
	};

	const updatePlannedDeliveryWindow = async (
		updatedPdw: Partial<PlannedDeliveryWindow>,
		recurrenceType: RecurrenceType
	) => {
		setCalendarLoading(true);
		if (!previousPlannedDeliveryWindow) {
			setPreviousPlannedDeliveryWindow(updateLocallyPdwState(updatedPdw));
		}
		try {
			const [recurrenceStartDate, recurrenceEndDate] =
				getRecurrenceOfPlannedDeliveryWindow(plannedDeliveryWindowToEdit);
			await plannedDeliveryWindows.update(updatedPdw.id, updatedPdw, {
				recurrence_type: recurrenceType,
				recurrence_end_date: recurrenceEndDate,
				recurrence_start_date: recurrenceStartDate
			});
			await refetchPlannedDeliveryWindows();

			Notification.success(t('PLANNED_DELIVERY_WINDOWS.UPDATE_SUCCESS'));
		} catch (e) {
			Notification.error(t('PLANNED_DELIVERY_WINDOWS.UPDATE_FAILED'));
			console.error('cannot update pdw: ', e);
			setPreviousPlannedDeliveryWindow(prevState => {
				updateLocallyPdwState(prevState);
				return null;
			});
		} finally {
			setCalendarLoading(false);
		}
	};

	const prepareDataForMultiDelete = (ids: number[], recurrenceType: RecurrenceType) => {
		const plannedDeliveryWindowsToDelete: PlannedDeliveryWindowsBulkDeleteRequest = {
			recurrence_type: recurrenceType,
			planned_delivery_windows: []
		};
		ids.forEach(id => {
			const plannedDeliveryWindow = plannedDeliveryWindows.get(id);
			const [recurrenceStartDate, recurrenceEndDate] =
				getRecurrenceOfPlannedDeliveryWindow(plannedDeliveryWindow);
			plannedDeliveryWindowsToDelete.planned_delivery_windows.push({
				planned_delivery_window: { id },
				recurrence_start_date: recurrenceStartDate,
				recurrence_end_date: recurrenceEndDate
			});
		});
		return plannedDeliveryWindowsToDelete;
	};

	const removePlannedDeliveryWindowsAndUpdateState = async (ids: number[], recurrenceType: RecurrenceType) => {
		setCalendarLoading(true);
		const plannedDeliveryWindowsToDelete = prepareDataForMultiDelete(ids, recurrenceType);

		try {
			await plannedDeliveryWindows.bulkDelete(plannedDeliveryWindowsToDelete);
			plannedDeliveryWindows.removeAll();
			Notification.success(t('PLANNED_DELIVERY_WINDOWS.REMOVE_SUCCESS'));
			setRecurrenceTypeModalVisible(false);
			clearSelectedEventsIds();
			await refetchPlannedDeliveryWindows();
		} catch (e) {
			Notification.error(t('PLANNED_DELIVERY_WINDOWS.REMOVE_FAILED'));
			console.error('cannot remove pdw: ', e);
			setRecurrenceTypeModalVisible(false);
		} finally {
			setCalendarLoading(false);
			setPlannedDeliveryWindowModalVisible(false);
			setPlannedDeliveryWindowToEdit(null);
		}
	};

	const openRecurrenceTypeModalWithEditMode = useCallback(
		(updatedPlannedDeliveryWindow: Partial<PlannedDeliveryWindow>) => {
			let mergedPlannedDeliveryWindow;
			setRecurrenceTypeModalMode(RecurrenceTypeModalModes.EDIT);
			setCalendarLoading(true);
			setPreviousPlannedDeliveryWindow(updateLocallyPdwState(updatedPlannedDeliveryWindow));
			setPlannedDeliveryWindowToEdit(prevState => {
				let foundPlannedDeliveryWindow = prevState;
				if (!foundPlannedDeliveryWindow) {
					foundPlannedDeliveryWindow = plannedDeliveryWindowsToView.find(
						pdw => pdw.id === updatedPlannedDeliveryWindow.id
					);
				}
				mergedPlannedDeliveryWindow = { ...foundPlannedDeliveryWindow, ...updatedPlannedDeliveryWindow };
				return mergedPlannedDeliveryWindow;
			});
			if (mergedPlannedDeliveryWindow.single_day) {
				setRecurrenceDisabled(prevState => ({ ...prevState, current_and_following: true, all: true }));
			}
			setRecurrenceTypeModalVisible(true);
		},
		[plannedDeliveryWindowsToView]
	);
	const openRecurrenceTypeModalWithDeleteMode = useCallback(
		(id: number) => {
			setRecurrenceTypeModalMode(RecurrenceTypeModalModes.REMOVE);
			if (getPlannedDeliveryWindowViewById(id).single_day) {
				setRecurrenceDisabled(prevState => ({ ...prevState, current_and_following: true, all: true }));
			}
			setRecurrenceTypeModalVisible(true);
			setPlannedDeliveryWindowToEdit(plannedDeliveryWindowsToView.find(pdw => pdw.id === id));
		},
		[plannedDeliveryWindowsToView]
	);

	const getPlannedDeliveryWindowViewById = (id: number): PlannedDeliveryWindow => {
		return plannedDeliveryWindowsToView.find(pdw => pdw.id === id);
	};

	const closeRecurrenceTypeModalAndCancel = () => {
		setRecurrenceTypeModalVisible(false);
		setRecurrenceDisabled({ current: false, current_and_following: false, all: false });
		if (recurrenceTypeModalMode === RecurrenceTypeModalModes.EDIT) {
			setPreviousPlannedDeliveryWindow(prevState => {
				updateLocallyPdwState(prevState);
				return null;
			});
		}
		setCalendarLoading(false);
	};

	const getRecurrenceOfPlannedDeliveryWindow = (plannedDeliveryWindow: PlannedDeliveryWindow): [string, string] => {
		const [startDate, endDate] = plannedDeliveryWindowsRangeDates.getDatesBetweenRange(
			plannedDeliveryWindow.start_time,
			plannedDeliveryWindow.end_time,
			calendarRangeDates,
			timezone
		);
		const recurrenceStartDate = moment(startDate).tz(timezone).startOf('day').toISOString();
		const recurrenceEndDate = moment(endDate).tz(timezone).endOf('day').toISOString();

		return [recurrenceStartDate, recurrenceEndDate];
	};

	const updatePlannedDeliveryWindowAndCloseModal = async (recurrenceType: RecurrenceType): Promise<void> => {
		await updatePlannedDeliveryWindow(plannedDeliveryWindowToEdit, recurrenceType);
		setRecurrenceTypeModalVisible(false);
		setRecurrenceDisabled({ current: false, current_and_following: false, all: false });
		closePlannedDeliveryWindowModal();
		setPreviousPlannedDeliveryWindow(null);
	};

	const removePlannedDeliveryWindowWithRecurrence = async (recurrenceType: RecurrenceType) => {
		const ids = selectedEventsIds.size > 1 ? [...selectedEventsIds] : [plannedDeliveryWindowToEdit.id];

		await removePlannedDeliveryWindowsAndUpdateState(ids, recurrenceType);
		setRecurrenceDisabled({ current: false, current_and_following: false, all: false });
	};

	const openModalWithPlannedDeliveryWindow = useCallback(
		(plannedDeliveryWindowId: number) => {
			setPlannedDeliveryWindowToEdit(
				_cloneDeep(plannedDeliveryWindowsToView.find(pdw => pdw.id === plannedDeliveryWindowId))
			);
			setPlannedDeliveryWindowModalVisible(true);
		},
		[plannedDeliveryWindowsToView]
	);

	const getDropdownData = (): DropdownData => {
		return {
			trigger: ['click'],
			text: t('PDW_ALL_LIST_DROPDOWN.CREATE_BUTTON'),
			items: [
				{
					key: '1',
					label: t('PLANNED_DELIVERY_WINDOWS.ADD_TIME_WINDOWS_BUTTON_TEXT'),
					onClick: () => setPlannedDeliveryWindowModalVisible(true)
				},
				{
					key: '2',
					label: t('EXCLUSION_WINDOWS_MODAL_BUTTON_DROPDOWN.ADD_EXCLUSION_WINDOW'),
					onClick: () => setExclusionModalVisible(true),
					disabled: !canEditExclusionWindows
				}
			]
		};
	};

	const filterByServicePlan = (pdw, service_plan) => {
		if (service_plan && service_plan.length > 0) {
			return (
				pdw.service_plan_ids?.some(servicePlanId => service_plan.includes(servicePlanId)) ||
				service_plan.includes(pdw.service_plan_id)
			);
		}

		return true;
	};

	const filterByServiceArea = (pdw, service_area) => {
		if (service_area && service_area.length > 0) {
			return pdw.service_area_ids.some(serviceAreaId => service_area.includes(serviceAreaId));
		}

		return true;
	};

	const filterBySlotName = (pdw, slot_name) => {
		if (slot_name && slot_name !== '') {
			return pdw.name.toLowerCase().includes(slot_name.toLowerCase());
		}

		return true;
	};

	const filterDeliverySlots = useCallback(
		(plannedDeliveryWindows, filters) => {
			if (Object.keys(filters).length === 0) {
				return plannedDeliveryWindows;
			}

			const { service_plan = null, service_area = null, slot_name = null } = filters;

			return plannedDeliveryWindows.filter(
				pdw =>
					filterByServicePlan(pdw, service_plan) &&
					filterByServiceArea(pdw, service_area) &&
					filterBySlotName(pdw, slot_name)
			);
		},
		[tableFiltersState?.filters]
	);

	const plannedDeliveryWindowsMemoized = useMemo(
		() => filterDeliverySlots(plannedDeliveryWindowsToView, tableFiltersState?.filters),
		[plannedDeliveryWindowsToView, tableFiltersState, filterDeliverySlots]
	);

	const clearSelectedEventsIds = useCallback(() => {
		setSelectedEventsIds(new Set<number>());
	}, []);

	const closeMultiSlotModal = () => {
		setPlannedDeliveryWindowMultiSlotModalVisible(false);
	};

	const plannedDeliveryWindowToViewMemoized = useMemo(
		() => plannedDeliveryWindowsToView,
		[plannedDeliveryWindowsToView]
	);

	return (
		<div className={styles.deliveryWindows} id={CONTAINER_ID}>
			<PlannedDeliveryWindowsToolbar
				selectedTeam={selectedTeamId}
				onTeamChange={onTeamChange}
				changeCalendarRangeDates={changeCalendarRangeDates}
				calendarRangeDates={calendarRangeDates}
				timezone={timezone}
				dropdownData={getDropdownData()}
				calendarViewMode={calendarViewMode}
				setCalendarViewMode={setCalendarViewMode}
			/>
			{hasDeliverySlotsFiltersEnabled && (
				<div className={styles.filtersPanel} data-test-id="delivery-slots-filter-panel">
					<FiltersPanel
						filtersState={tableFiltersState}
						deliverySlots={plannedDeliveryWindowToViewMemoized}
					/>
					<Button
						type="text"
						className={styles.editButton}
						onClick={() => {
							setSelectedEventsIds(new Set(plannedDeliveryWindowsMemoized.map(pdw => pdw.id)));
							setPlannedDeliveryWindowMultiSlotModalVisible(true);
						}}
					>
						<BringgIcon iconName={BringgFontIcons.Pencil} />
						{t('PLANNED_DELIVERY_WINDOWS_FILTERS_PANEL.BUTTON_TEXT')}
					</Button>
				</div>
			)}
			<PlannedDeliveryWindowsCalendar
				plannedDeliveryWindows={plannedDeliveryWindowsMemoized}
				calendarLoading={calendarLoading}
				openModalWithPlannedDeliveryWindow={openModalWithPlannedDeliveryWindow}
				calendarRangeDates={calendarRangeDates}
				openRecurrenceTypeModalWithEditMode={openRecurrenceTypeModalWithEditMode}
				openRecurrenceTypeModalWithDeleteMode={openRecurrenceTypeModalWithDeleteMode}
				timezone={timezone}
				deliveryWindowsByPlannedDeliveryWindowId={deliveryWindowsByPlannedDeliveryWindowId}
				selectedTeamId={selectedTeamId}
				selectedEventsIds={selectedEventsIds}
				setSelectedEventsIds={setSelectedEventsIds}
				clearSelectedEventsIds={clearSelectedEventsIds}
				calendarViewMode={calendarViewMode}
			/>
			{plannedDeliveryWindowModalVisible && (
				<PlannedDeliveryWindowsSlotsModal
					modalVisible={plannedDeliveryWindowModalVisible}
					closeModal={closePlannedDeliveryWindowModal}
					timezone={timezone}
					plannedDeliveryWindow={plannedDeliveryWindowToEdit}
					selectedTeam={selectedTeamId}
					refetchPlannedDeliveryWindows={refetchPlannedDeliveryWindows}
					removePdw={removePlannedDeliveryWindowsAndUpdateState}
					updatePdw={updatePlannedDeliveryWindow}
				/>
			)}

			{plannedDeliveryWindowMultiSlotModalVisible && (
				<PlannedDeliveryWindowsMultiSlotModal
					modalVisible={plannedDeliveryWindowMultiSlotModalVisible}
					timezone={timezone}
					selectedEventsIds={selectedEventsIds}
					closeModal={closeMultiSlotModal}
					closeMultiSelectModal={clearSelectedEventsIds}
					getRecurrenceOfPlannedDeliveryWindow={getRecurrenceOfPlannedDeliveryWindow}
					refetchPlannedDeliveryWindows={refetchPlannedDeliveryWindows}
				/>
			)}

			{recurrenceTypeModalVisible && (
				<RecurrenceTypeModal
					visible={recurrenceTypeModalVisible}
					closeAndCancel={closeRecurrenceTypeModalAndCancel}
					isEditMode={recurrenceTypeModalMode === RecurrenceTypeModalModes.EDIT}
					onUpdate={updatePlannedDeliveryWindowAndCloseModal}
					onDelete={removePlannedDeliveryWindowWithRecurrence}
					recurrenceDisabled={recurrenceDisabled}
				/>
			)}
			{exclusionModalVisible && (
				<ExclusionWindowModal
					isVisible={exclusionModalVisible}
					title={t('EXCLUSION_WINDOWS_MODAL_ADD_MODE.TITLE')}
					closeModal={() => setExclusionModalVisible(false)}
					timeFormat={hourFormat}
					teamId={selectedTeamId}
				/>
			)}
			{selectedEventsIds.size > 1 && (
				<MultipleSelectModal
					parentSelector={`#${CONTAINER_ID}`}
					selectedCount={selectedEventsIds.size}
					onCloseModal={clearSelectedEventsIds}
					selectedTypeName={'selected'}
					actionButtons={[
						{
							text: t('PLANNED_DELIVERY_WINDOWS_MULTIPLE_SELECT_MODAL.EDIT_TEXT'),
							icon: BringgFontIcons.Pencil,
							onClick: () => {
								setPlannedDeliveryWindowMultiSlotModalVisible(true);
							}
						},
						{
							text: t('PLANNED_DELIVERY_WINDOWS_MULTIPLE_SELECT_MODAL.DELETE_TEXT'),
							icon: BringgFontIcons.Erase,
							onClick: () => {
								setRecurrenceTypeModalMode(RecurrenceTypeModalModes.REMOVE);
								setRecurrenceTypeModalVisible(true);
							}
						}
					]}
				/>
			)}
		</div>
	);
};

export default observer(PlannedDeliveryWindowsView);
