import { dateUtils } from '@bringg-frontend/utils';

import { Duration } from 'bringg-web/features/planned-delivery-windows/modals/slots-modal/planned-delivery-windows-slots.consts';
import {
	RelativeCutoff,
	RelativeOptions
} from 'bringg-web/features/planned-delivery-windows/modals/slots-modal/wizard-steps/configuration-step/time-slots/cutoff-radio-group/cutoff.types';

const updateRelativeDays = (
	days: number,
	relativeCutoff: RelativeCutoff,
	updateRelativeCutoff: (updatedData: Partial<RelativeCutoff>) => void
) => {
	const updatedRelativeCutoff: Partial<RelativeCutoff> = { days };
	if (days === 7 && relativeCutoff.hours > 12) {
		updatedRelativeCutoff.hours = 12;
	}

	updateRelativeCutoff(updatedRelativeCutoff);
};

const updateRelativeHours = (
	hours: number,
	relativeCutoff: RelativeCutoff,
	updateRelativeCutoff: (updatedData: Partial<RelativeCutoff>) => void,
	timeDuration: Duration
) => {
	const sumOfRelativeCutoffWithoutDays = dateUtils.sumOfMinutes(hours, relativeCutoff.minutes);
	const sumOfTimeDuration = dateUtils.sumOfMinutes(timeDuration.hours, timeDuration.minutes);
	const updatedRelativeCutoff: Partial<RelativeCutoff> = { hours };

	if (relativeCutoff.relativeOption === RelativeOptions.AFTER && sumOfTimeDuration < sumOfRelativeCutoffWithoutDays) {
		Object.assign(updatedRelativeCutoff, timeDuration);
	}

	updateRelativeCutoff(updatedRelativeCutoff);
};

const updateRelativeMinutes = (
	minutes: number,
	relativeCutoff: RelativeCutoff,
	updateRelativeCutoff: (updatedData: Partial<RelativeCutoff>) => void,
	timeDuration: Duration
) => {
	const sumOfRelativeCutoffWithoutDays = dateUtils.sumOfMinutes(relativeCutoff.hours, minutes);
	const sumOfTimeDuration = dateUtils.sumOfMinutes(timeDuration.hours, timeDuration.minutes);
	const updatedRelativeCutoff: Partial<RelativeCutoff> = { minutes };

	if (relativeCutoff.relativeOption === RelativeOptions.AFTER && sumOfTimeDuration < sumOfRelativeCutoffWithoutDays) {
		Object.assign(updatedRelativeCutoff, timeDuration);
	}

	updateRelativeCutoff(updatedRelativeCutoff);
};

const updateRelativeOption = (
	relativeOption: RelativeOptions,
	relativeCutoff: RelativeCutoff,
	updateRelativeCutoff: (updatedData: Partial<RelativeCutoff>) => void,
	timeDuration: Duration
) => {
	const sumOfRelativeCutoffWithoutDays = dateUtils.sumOfMinutes(relativeCutoff.hours, relativeCutoff.minutes);
	const sumOfTimeDuration = dateUtils.sumOfMinutes(timeDuration.hours, timeDuration.minutes);
	const updatedRelativeCutoff: Partial<RelativeCutoff> = { relativeOption };
	if (relativeOption === RelativeOptions.AFTER) {
		updatedRelativeCutoff.days = 0;
	}
	if (relativeOption === RelativeOptions.AFTER && sumOfTimeDuration < sumOfRelativeCutoffWithoutDays) {
		Object.assign(updatedRelativeCutoff, timeDuration);
	}
	updateRelativeCutoff(updatedRelativeCutoff);
};

export const relativeCutoffUpdater = {
	updateRelativeDays,
	updateRelativeHours,
	updateRelativeMinutes,
	updateRelativeOption
};
