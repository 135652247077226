import { HourFormat } from '@bringg/types';
import moment from 'moment';
import { dateUtils } from '@bringg-frontend/utils';

import { useStores } from '../recipes';

const useTimeFormat = () => {
	const { merchantConfigurationsStore } = useStores();
	const { hourFormat } = merchantConfigurationsStore;
	switch (hourFormat) {
		case HourFormat.TwelveHours:
			return dateUtils.TWELVE_HOURS;
		case HourFormat.TwentyFourHours:
			return dateUtils.TWENTY_FOUR_HOURS;
		default:
			return moment.localeData().longDateFormat('LT');
	}
};

export default useTimeFormat;
