import { Select, Option } from '@bringg/react-components';
import React, { Component } from 'react';

interface Props {
	selectedLanguageCode: string;
	defaultLanguageCode: string;
	availableLanguages: any[];
	selectedLanguageCodeFunc: Function;
}

export class LanguageSelect extends Component<Props> {
	render() {
		const { selectedLanguageCode, defaultLanguageCode, availableLanguages, selectedLanguageCodeFunc } = this.props;
		return (
			<Select
				className="language-select"
				value={selectedLanguageCode}
				onSelect={code => selectedLanguageCodeFunc(code)}
			>
				{availableLanguages.map(({ id, code, name, hasTemplates }) => {
					const isDefaultLanguage = defaultLanguageCode === code;
					return (
						<Option key={id.toString()} value={code} className="react-component">
							<div className={`language-select-option ${hasTemplates ? 'select-bold' : 'select-weak'}`}>
								<span className="name">
									{name}
									{isDefaultLanguage ? <span className="default-label"> (Default)</span> : null}
								</span>
							</div>
						</Option>
					);
				})}
			</Select>
		);
	}
}
