import React, { useMemo } from 'react';

import moment from 'moment-timezone';
import { observer } from 'mobx-react';
import { stringOrDate, Event, Calendar } from 'react-big-calendar';
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop';
import { withErrorBoundary } from '@bringg-frontend/bringg-web-infra';

import DeliveryBlockEvent, { eventStyleGetter } from './event/delivery-blocks-event';
import DeliveryBlocksCalendarView from './delivery-blocks-calendar-view';
import { TimeRange } from '../toolbar/delivery-block-toolbar-view';
import TimezoneService from '../../../services/timezone/timezone-service';
import Team from 'bringg-web/stores/teams/domain-object/team';
import { timezoneProvider } from 'bringg-web/services/timezone/timezone-provider';
import { customMomentLocalizerTimezone } from 'bringg-web/features/planned-delivery-windows/calendar/custom-moment-localizer-timezone';

interface Props {
	currentWeek: TimeRange;
	team: Team;
	onEventSelect?: (event: any, e: React.SyntheticEvent<HTMLElement>) => void;
	onEventDrop?: (event: CalendarDeliveryBlocksDroppedEvent) => Promise<void>;
	onEventResize?: (event: any) => Promise<void>;
	deliveryBlocksCalendarView?: DeliveryBlocksCalendarView;
	isEditable?: boolean;
	isNewModalOfDeliveryBlock: boolean;
}

const getCalendarStartTime = () => {
	return moment().hour(6).startOf('hour').toDate();
};

export type CalendarDeliveryBlocksDroppedEvent = {
	event: Event;
	start: stringOrDate;
	end: stringOrDate;
	allDay: boolean;
};

const customComponents = {
	event: DeliveryBlockEvent
};

const DragAndDropCalendar = withDragAndDrop(Calendar);

const DeliveryBlocksCalendar = ({
	team,
	currentWeek,
	deliveryBlocksCalendarView,
	isNewModalOfDeliveryBlock,
	onEventDrop,
	onEventResize,
	onEventSelect,
	isEditable
}: Props) => {
	const startAccessor = event => treatTimezoneDateAsLocaleDate(event.start);
	const endAccessor = event => treatTimezoneDateAsLocaleDate(event.end);

	const timezone = timezoneProvider.getTimezoneByTeamId(team.id);
	const localizer = useMemo(() => customMomentLocalizerTimezone(moment, timezone), [timezone]);

	const treatTimezoneDateAsLocaleDate = (date: Date) => {
		return moment(date).tz(timezone).toDate();
	};

	const getUpdatedTimesInTeamTimezone = (start: stringOrDate, end: stringOrDate) => {
		return {
			start: TimezoneService.getDateAsUtc(start, team.localizationTimezone).toISOString(),
			end: TimezoneService.getDateAsUtc(end, team.localizationTimezone).toISOString()
		};
	};

	const handleEventTimeChange =
		(eventFunc: (event: CalendarDeliveryBlocksDroppedEvent) => Promise<void>) =>
		async (droppedEvent: CalendarDeliveryBlocksDroppedEvent) => {
			if (team.localizationTimezone) {
				await eventFunc({
					...droppedEvent,
					...getUpdatedTimesInTeamTimezone(droppedEvent.start, droppedEvent.end)
				});
			} else {
				await eventFunc(droppedEvent);
			}
		};

	const calendarStartTime = getCalendarStartTime();

	return (
		<div className="delivery-blocks-calendar">
			{deliveryBlocksCalendarView && (
				<DragAndDropCalendar
					localizer={localizer}
					startAccessor={startAccessor}
					endAccessor={endAccessor}
					scrollToTime={calendarStartTime}
					events={deliveryBlocksCalendarView.allDeliveryBlockEventsViewModelsNormalized}
					onSelectEvent={onEventSelect}
					onEventDrop={handleEventTimeChange(onEventDrop)}
					components={customComponents}
					eventPropGetter={event => eventStyleGetter(event, isNewModalOfDeliveryBlock)}
					toolbar={false}
					date={currentWeek.startDate.toDate()}
					defaultView="week"
					views={['week']}
					showMultiDayTimes
					// this is to avoid calendar warning because of custom navigator
					// eslint-disable-next-line @typescript-eslint/no-empty-function
					onNavigate={() => {}}
					// this is to hide the default big calendar tooltip
					tooltipAccessor={() => null}
					onEventResize={handleEventTimeChange(onEventResize)}
					draggableAccessor={() => isEditable}
				/>
			)}
		</div>
	);
};
export default withErrorBoundary(observer(DeliveryBlocksCalendar));
