import React, { memo, useMemo } from 'react';

import { useTranslation } from 'react-i18next';
import {
	FilterBar2,
	FilterBar2ListItem,
	FilterBar2Values,
	getDefaultFilterBarTranslations
} from '@bringg/react-components';

import type { UseTableFilters } from './hooks';

interface Props {
	filtersState: UseTableFilters;
}

function FiltersPanel({ filtersState }: Props) {
	const { t } = useTranslation();

	const { filters, updateFilters, resetFilters } = filtersState;

	const filterBarTranslations = useMemo(() => getDefaultFilterBarTranslations(t), [t]);

	const filterInputs: FilterBar2ListItem[] = useMemo(() => {
		return [
			{
				type: FilterBar2.TYPES.SEARCH,
				applyFilterOnValueChange: true,
				name: 'searchText',
				placeholder: t(`EMAIL_TEMPLATES.FILTERS.TEXT_SEARCH`)
			}
			// TODO: filter for filtering by template_type property
			// {
			// 	type: FilterBar2.TYPES.SELECT,
			// 	applyFilterOnValueChange: true,
			// 	name: 'templateType',
			// 	placeholder: t(`EMAIL_TEMPLATES.FILTERS.TEMPLATE_TYPE_FILTER`),
			// 	values: []
			// }
		];
	}, [t]);

	return (
		<FilterBar2
			initialValues={filters as unknown as FilterBar2Values}
			onReset={resetFilters}
			list={filterInputs}
			translations={filterBarTranslations}
			// be aware the way apply whole new obj to filter observer maybe will rerender the whole filterbar
			onFilter={updateFilters}
		/>
	);
}

export default memo(FiltersPanel);
