import React, { useMemo } from 'react';

import { BringgFontIcons, BringgIcon } from '@bringg/bringg-icons';
import { Tooltip } from '@bringg/react-components';

import useAutomationTranslations from '../../utils/use-automation-translations';
import { ActionFamilyType } from '../../utils/types';

import styles from './action-icon.module.scss';

interface Props {
	type: ActionFamilyType;
	icon?: string;
	tooltipText?: string;
}

const ActionIcon = ({ type, icon, tooltipText }: Props) => {
	const { alert, updateTask, customerNotification, optimizationAction, calcAvailabilityAction } =
		useAutomationTranslations();

	const iconByType = useMemo(() => {
		return {
			[ActionFamilyType.NOTIFY]: {
				title: alert,
				iconName: BringgFontIcons.Notification
			},
			[ActionFamilyType.UPDATE_ENTITY]: {
				title: updateTask,
				iconName: BringgFontIcons.Report
			},
			[ActionFamilyType.COSTUMER_NOTIFICATION]: {
				title: customerNotification,
				iconName: BringgFontIcons.User
			},
			[ActionFamilyType.OPTIMIZATION]: {
				title: optimizationAction,
				iconName: BringgFontIcons.Route
			},
			[ActionFamilyType.CALCULATE_AVAILABILITY_STATE]: {
				title: calcAvailabilityAction,
				iconName: BringgFontIcons.DeliverySlots
			}
		};
	}, [alert, updateTask]);

	const iconName = icon || iconByType[type]?.iconName;

	return (
		<Tooltip title={tooltipText?.toLowerCase() || iconByType[type]?.title.toLowerCase()}>
			<BringgIcon iconName={iconName} className={styles.icon} data-test-id={`action-icon-${iconName}`} />
		</Tooltip>
	);
};

export default ActionIcon;
