export const isPhoneValid = (validatePhone: (query: string) => Promise<boolean>, intervalInMillis = 500) => {
	let timer;

	return async (value: string): Promise<string | boolean> => {
		if (timer) {
			clearTimeout(timer);
		}

		if (!value) {
			return Promise.resolve(true);
		}

		return new Promise<string | boolean>((res, rej) => {
			timer = setTimeout(() => {
				validatePhone(value).then(valid => (valid ? res(valid) : rej(new Error("'phone' is not valid"))));

				timer = null;
			}, intervalInMillis);
		});
	};
};
