import React from 'react';

import { Portal } from '@bringg-frontend/bringg-web-infra';

import ManualTriggersManager from './manual-triggers-manager';

function ManualTriggerPortal() {
	return <Portal element={<ManualTriggersManager />} nodeId="manual-triggers-manager" />;
}

export default ManualTriggerPortal;
