import { getRootEnv } from '@bringg-frontend/bringg-web-infra';
import React from 'react';

import { RouteComponentProps } from 'react-router';
import { getEnv, getRoot } from 'mobx-easy';
import isNumber from 'lodash/isNumber';
import { BringgFontIcons, BringgIcon } from '@bringg/bringg-icons';
import { BringgException } from '@bringg/dashboard-sdk/dist/Core/BringgException';
import { Team, Driver, Vehicle, VehicleChange } from '@bringg/types';
import { RootEnv } from '@bringg-frontend/bringg-web-infra';

import { DriverCard, Link } from 'bringg-web/components';
import bringgNotification from 'bringg-web/services/notification';
import { Translate } from 'bringg-web/translation';
import AssignmentsContainer from '../assignments/assignments-container/assignments-container';
import VehicleCard from '../vehicle-card/vehicle-card';
import RootStore from 'bringg-web/stores/root-store';

interface MatchParams {
	id: string;
}

interface State {
	vehicle?: Vehicle;
	changes?: VehicleChange[];
	currentDriver?: Driver;
	currentDriverTeam?: Team;
	allDrivers: Driver[];
}

interface Props extends RouteComponentProps<MatchParams> {}

const initialized_state = {
	vehicle: undefined,
	changes: [],
	currentDriver: undefined,
	currentDriverTeam: undefined,
	allDrivers: []
};

class VehicleContainer extends React.Component<Props, State> {
	state: State = initialized_state;

	async componentDidMount() {
		await this.getVehicleDetails(this.getIdFromProps(this.props));
	}

	componentDidUpdate(prevProps) {
		const currentId = this.getIdFromProps(this.props);
		const prevId = this.getIdFromProps(prevProps);

		if (currentId !== prevId) {
			this.getVehicleDetails(currentId);
		}
	}

	// eslint-disable-next-line react/sort-comp
	getIdFromProps = (props): number => {
		const { id } = props.match.params;
		return id && parseInt(id, 10) ? Number(props.match.params.id) : 0;
	};

	async getVehicleDetails(vehicleId: number) {
		if (!vehicleId) {
			this.setState(initialized_state);
			return;
		}

		try {
			const vehicle: Vehicle = await getRoot<RootStore>().data.vehiclesStore.load(vehicleId);
			const currentDriverTeam: Team = await getRootEnv().dashboardSdk.sdk.teams.get(vehicle.team_id);
			const allDrivers: Driver[] = await getRootEnv().dashboardSdk.sdk.users.getDriversByTeam(vehicle.team_id);
			const currentDriver: Driver = allDrivers.find(driver => driver.id === vehicle.user_id);
			const changes: VehicleChange[] = await getRoot<RootStore>().data.vehiclesStore.getAssignmentHistory(
				vehicleId
			);

			this.setState({ vehicle, currentDriverTeam, allDrivers, currentDriver, changes });
		} catch (error) {
			bringgNotification.error((error as BringgException).message);
			this.setState(initialized_state);
		}
	}

	onRowClicked = (record: any): React.HTMLAttributes<HTMLElement> => {
		return {
			onClick: () => {
				const currentDriver: Driver = this.state.allDrivers.find(driver => driver.id === record.new_driver);
				if (currentDriver) {
					this.setState({ currentDriver });
				}
			}
		};
	};

	public render() {
		const shiftStatus =
			this.state.currentDriver && isNumber(this.state.currentDriver.active_shift_id) ? (
				<Translate text="VEHICLE.ON_SHIFT" />
			) : (
				<Translate text="VEHICLE.OFF_SHIFT" />
			);

		return (
			<div className="vehicle-container">
				{this.state.vehicle && (
					<>
						<div className="vehicle-card-container">
							<VehicleCard
								vehicle={this.state.vehicle}
								onUpdate={updatedVehicle => this.setState({ vehicle: updatedVehicle })}
							/>
						</div>
						<div className="assignment-history-container">
							<AssignmentsContainer
								assignments={this.state.changes}
								optionalDrivers={this.state.allDrivers}
								onRowClicked={this.onRowClicked}
							/>
						</div>
					</>
				)}
				{this.state.currentDriver && this.state.currentDriverTeam && (
					<div className="driver-card-container">
						<div className="driver-title">{<Translate text="VEHICLE.CURRENTLY_ASSIGNED_TO" />}</div>
						<DriverCard
							shiftStatus={shiftStatus}
							driver={this.state.currentDriver}
							team={this.state.currentDriverTeam}
							showExtraDetails
							nameLink={
								<Link to={`/drivers/${this.state.currentDriver.id}`}>
									<span>{this.state.currentDriver.name}</span>
								</Link>
							}
							iconLink={
								<Link to={`/drivers/${this.state.currentDriver.id}`}>
									<BringgIcon iconName={BringgFontIcons.OpenInNew} />
								</Link>
							}
						/>
					</div>
				)}
			</div>
		);
	}
}

export default VehicleContainer;
