import React from 'react';

import { Portal } from '@bringg-frontend/bringg-web-infra';

import ActionsConfigurations from './actions-configurations-container/actions-configurations-container';

const ActionsConfigurationsPortal = () => (
	<Portal element={<ActionsConfigurations />} nodeId={'actions-configurations'} />
);

export { ActionsConfigurationsPortal };
