import React from 'react';
import { FormItemWrapper, LabelNumberKeyboardInput } from '@bringg/react-components';
import { DatePicker, Input } from 'antd';
import i18next from 'i18next';
import { Moment } from 'moment';
import { DELIVERY_BLOCK_DATE_FORMAT } from '../delivery-block-form';
import { Translate } from '../../../../../translation';

type ColProps = React.ComponentProps<typeof FormItemWrapper>['labelCol'];

interface Props {
	onDateChanged: (date: Moment) => void;
	onCapacityChange?: (originalCapacity: number) => void;
	minimumCapacity?: number;
	formItemLayout?: { [key: string]: ColProps };
}

class DeliveryBlockFormDetails extends React.Component<Props> {
	render() {
		const { onCapacityChange, minimumCapacity, formItemLayout, onDateChanged } = this.props;

		return (
			<>
				<FormItemWrapper
					name="name"
					label={<Translate text="DELIVERY_BLOCKS.NAME" />}
					rules={[{ required: true }]}
					{...formItemLayout}
				>
					<Input />
				</FormItemWrapper>

				<FormItemWrapper
					name="description"
					label={<Translate text="DELIVERY_BLOCKS.DESCRIPTION" />}
					{...formItemLayout}
				>
					<Input />
				</FormItemWrapper>

				<FormItemWrapper
					name="date"
					label={<Translate text="DELIVERY_BLOCKS.SELECT_DATE" />}
					rules={[{ required: true }]}
					{...formItemLayout}
				>
					<DatePicker onChange={onDateChanged} format={DELIVERY_BLOCK_DATE_FORMAT} />
				</FormItemWrapper>

				<FormItemWrapper
					name="originalCapacity"
					label={<Translate text="DELIVERY_BLOCKS.CAPACITY" />}
					className="form-details"
					rules={[{ required: true }]}
					{...formItemLayout}
				>
					<LabelNumberKeyboardInput
						label={i18next.t('DELIVERY_BLOCKS.DRIVERS')}
						onChange={onCapacityChange}
						minValue={minimumCapacity || 1}
						maxValue={250}
						className="capacity-container"
					/>
				</FormItemWrapper>
			</>
		);
	}
}

export default DeliveryBlockFormDetails;
