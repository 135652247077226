import React from 'react';

import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { BringgFontIcons, BringgIcon } from '@bringg/bringg-icons';
import TooltipWrapper from '@bringg/react-components/dist/components/tooltip/tooltip';
import { getFormattedTimeWindow, LayoutType } from '@bringg/react-components';
import { observer } from 'mobx-react';
import { Task } from '@bringg/types';

import { useStores } from '../../recipes';
import { getActionableWaypoint } from 'bringg-web/stores/tasks/task-utils';
import { timezoneProvider } from 'bringg-web/services/timezone/timezone-provider';

import './task-details.scss';

interface Props {
	task: Task;
}

const TaskDetails: React.FC<Props> = ({ task }: Props) => {
	const { t } = useTranslation();
	const { merchantConfigurationsStore } = useStores();

	const getFormattedTimeWindowFromTask = () => {
		const { no_earlier_than, no_later_than } = getActionableWaypoint(task) || {};
		if (!no_earlier_than || !no_later_than) {
			return null;
		}

		return (
			<TooltipWrapper title={t('ASSIGN_FLEET.DELIVERY_WINDOW_TOOLTIP_TEXT')}>
				<span className="task-item dont-break task-detail-with-icon" data-test-id="task-details-time-window">
					<BringgIcon iconName={BringgFontIcons.Clock} className="selected-task-icon" />
					{getFormattedTimeWindow(
						no_earlier_than,
						no_later_than,
						merchantConfigurationsStore.hourStringFormat,
						timezoneProvider.getTimezoneByTask(task),
						LayoutType.INLINE
					)}
				</span>
			</TooltipWrapper>
		);
	};

	return (
		<div className="selected-task">
			<TooltipWrapper title={t('ASSIGN_FLEET.TASK_ID_TOOLTIP_TEXT')}>
				<span className="task-item dont-break">{task.id}</span>
			</TooltipWrapper>

			<TooltipWrapper title={t('ASSIGN_FLEET.TASK_NAME_TOOLTIP_TEXT')}>
				<span className="task-item wrap-text" title={task.title}>
					{task.title}
				</span>
			</TooltipWrapper>

			{getFormattedTimeWindowFromTask()}

			{task.service_plan?.name && (
				<TooltipWrapper title={t('ASSIGN_FLEET.SERVICE_PLAN_TOOLTIP_TEXT')}>
					<span
						className="task-item wrap-text task-detail-with-icon"
						data-test-id="task-details-service-plan-name"
					>
						<BringgIcon iconName={BringgFontIcons.Notification} className="selected-task-icon" />
						<span className="wrap-text" title={task.service_plan.name}>
							{task.service_plan.name}
						</span>
					</span>
				</TooltipWrapper>
			)}

			{!_.isNil(task.aggregations?.max_weight) && (
				<TooltipWrapper title={t('ASSIGN_FLEET.TOTAL_WEIGHT_TOOLTIP_TEXT')}>
					<span className="task-item task-detail-with-icon" data-test-id="task-details-total-weight">
						<BringgIcon iconName={BringgFontIcons.Weight} className="selected-task-icon" />
						<span className="wrap-text" title={task.aggregations.max_weight.toString()}>
							{task.aggregations.max_weight}
						</span>
					</span>
				</TooltipWrapper>
			)}
		</div>
	);
};

export default observer(TaskDetails);
