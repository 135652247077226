import { TFunction } from 'i18next';

export const KM_TO_MILES_MULTIPLIER = 0.621371;
const KM_MULTIPLIER = 1;

export const getLocalizedDistanceMessage = (distanceInMeters: number, countryCode: string, t: TFunction) => {
	const distanceFixed = getLocalizedDistance(distanceInMeters / 1000, countryCode);
	const unit = countryCode === 'us' || countryCode === 'uk' ? t('ORDER.MILES') : t('ORDER.KM');
	return `${distanceFixed} ${unit}`;
};

export const getLocalizedDistance = (distanceInKm: number, countryCode: string) => {
	const unitMultiplier = countryCode === 'us' || countryCode === 'uk' ? KM_TO_MILES_MULTIPLIER : KM_MULTIPLIER;
	const distance = distanceInKm * unitMultiplier;
	return distance.toFixed(1);
};
