import React from 'react';

import { Radio } from '@bringg/react-components';
import { useTranslation } from 'react-i18next';
import { Moment } from 'moment';

import {
	CutoffEnum,
	DailyCutoff,
	RelativeCutoff,
	RelativeOptions
} from 'bringg-web/features/planned-delivery-windows/modals/slots-modal/wizard-steps/configuration-step/time-slots/cutoff-radio-group/cutoff.types';
import RelativeDropdown from 'bringg-web/features/planned-delivery-windows/modals/slots-modal/wizard-steps/configuration-step/time-slots/cutoff-radio-group/relative-dropdown/relative-dropdown';
import DailyDropdown from 'bringg-web/features/planned-delivery-windows/modals/slots-modal/wizard-steps/configuration-step/time-slots/cutoff-radio-group/daily-dropdown/daily-dropdown';
import { dontLoseFocus } from 'bringg-web/services/utils';
import { relativeCutoffUpdater } from 'bringg-web/features/planned-delivery-windows/services/relative-cutoff-updater';
import { dailyCutoffUpdater } from 'bringg-web/features/planned-delivery-windows/services/daily-cutoff-updater';

import styles from './cutoff-radio-group.module.scss';

interface Props {
	format: string;
	use12Hours: boolean;
	cutoffType: CutoffEnum;
	setCutoffType: React.Dispatch<React.SetStateAction<CutoffEnum>>;
	relativeCutoff: RelativeCutoff;
	setRelativeCutoff: React.Dispatch<React.SetStateAction<RelativeCutoff>>;
	dailyCutoff: DailyCutoff;
	setDailyCutoff: React.Dispatch<React.SetStateAction<DailyCutoff>>;
	timeDuration: { hours: number; minutes: number };
	startTimeMinutes: number;
	isEditMode: boolean;
	timezone: string;
}

const CutoffRadioGroup = ({
	format,
	use12Hours,
	cutoffType,
	setCutoffType,
	relativeCutoff,
	setRelativeCutoff,
	dailyCutoff,
	setDailyCutoff,
	timeDuration,
	startTimeMinutes,
	isEditMode,
	timezone
}: Props) => {
	const { t } = useTranslation();

	const updateRelativeCutoff = (updatedData: Partial<RelativeCutoff>) => {
		setRelativeCutoff(prevState => ({ ...prevState, ...updatedData }));
	};

	const updateDailyCutoff = (updatedData: Partial<DailyCutoff>) => {
		setDailyCutoff(prevState => ({ ...prevState, ...updatedData }));
	};

	const onCutoffTypeChanged = event => {
		const cutoffType: CutoffEnum = event.target.value;
		setCutoffType(cutoffType);
	};

	const isDailyCutoff = cutoffType === CutoffEnum.DAILY;
	return (
		<div className={styles.cutoffRadioGroup}>
			<div className={styles.cutoffTitle}>
				<span>{t('PLANNED_DELIVERY_WINDOWS_MODAL.CUTOFF_TEXT')}</span>
			</div>
			<Radio.Group className={styles.radioGroup} defaultValue={cutoffType} onChange={onCutoffTypeChanged}>
				<Radio value={CutoffEnum.RELATIVE} className={styles.radio}>
					<div className={styles.radioContent} data-test-id="cutoff-group-relative">
						<span>{t('PLANNED_DELIVERY_WINDOWS_MODAL.RELATIVE_CUTOFF.BEFORE_DROPDOWN')}</span>
						<div onClick={dontLoseFocus}>
							<RelativeDropdown
								relativeCutoff={relativeCutoff}
								timeDuration={timeDuration}
								days={{
									onChange: (days: number) =>
										relativeCutoffUpdater.updateRelativeDays(
											days,
											relativeCutoff,
											updateRelativeCutoff
										),
									disabled: isDailyCutoff || relativeCutoff.relativeOption === RelativeOptions.AFTER
								}}
								hours={{
									onChange: (hours: number) =>
										relativeCutoffUpdater.updateRelativeHours(
											hours,
											relativeCutoff,
											updateRelativeCutoff,
											timeDuration
										),
									disabled: isDailyCutoff
								}}
								minutes={{
									onChange: (minutes: number) =>
										relativeCutoffUpdater.updateRelativeMinutes(
											minutes,
											relativeCutoff,
											updateRelativeCutoff,
											timeDuration
										),
									disabled: isDailyCutoff
								}}
								relativeOptions={{
									onChange: (relativeOption: RelativeOptions) =>
										relativeCutoffUpdater.updateRelativeOption(
											relativeOption,
											relativeCutoff,
											updateRelativeCutoff,
											timeDuration
										),
									disabled: isDailyCutoff
								}}
							/>
						</div>
						<span>{t('PLANNED_DELIVERY_WINDOWS_MODAL.RELATIVE_CUTOFF.AFTER_DROPDOWN')}</span>
					</div>
				</Radio>
				{!isEditMode && (
					<Radio value={CutoffEnum.DAILY} className={styles.radio}>
						<div className={styles.radioContent} data-test-id="cutoff-group-daily">
							<span>{t('PLANNED_DELIVERY_WINDOWS_MODAL.DAILY_CUTOFF.BEFORE_DROPDOWN')}</span>
							<DailyDropdown
								startRangeTimeInMinutes={startTimeMinutes}
								format={format}
								use12Hours={use12Hours}
								dailyCutoff={dailyCutoff}
								days={{
									onChange: (days: number) =>
										dailyCutoffUpdater.updateDays(
											days,
											dailyCutoff,
											updateDailyCutoff,
											startTimeMinutes,
											timezone
										),
									disabled: !isDailyCutoff
								}}
								time={{
									onChange: (time: Moment) => dailyCutoffUpdater.updateTime(time, updateDailyCutoff),
									disabled: !isDailyCutoff
								}}
								timezone={timezone}
							/>
							<span>{t('PLANNED_DELIVERY_WINDOWS_MODAL.DAILY_CUTOFF.AFTER_DROPDOWN')}</span>
						</div>
					</Radio>
				)}
			</Radio.Group>
		</div>
	);
};

export default CutoffRadioGroup;
