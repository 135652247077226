import React from 'react';
import { Select } from '@bringg/react-components';
import _get from 'lodash/get';
import _pick from 'lodash/pick';
import { RRule, Frequency, Options } from 'rrule';
import classNames from 'classnames';
import RecurringFrequencies from './frequencies/recurring-frequencies';
import RecurringRepeat from './repeat/recurring-repeat';
import {
	DEFAULT_RRULE_OPTIONS,
	defaultFrequencyOptions,
	allRecurringFrequencies,
	RECURRING_NO_REPEAT_FREQUENCY
} from './recurring.consts';
import {
	defaultFrequencyLabels,
	defaultFrequenciesOptionsLabels,
	defaultRepeatLabels,
	RecurringFrequenciesOptionsLabels,
	RecurringRepeatLabels
} from './recurring.labels';

interface Props {
	ical?: string;
	frequencies?: Frequency[];
	frequenciesLabels?: { [key: number]: string };
	frequenciesOptionsLabels?: RecurringFrequenciesOptionsLabels;
	repeatLabels?: RecurringRepeatLabels;
	onChange?: (ical: string) => void;
	disabled?: boolean;
}

interface State {
	options: Options;
	ical: string;
}

class Recurring extends React.PureComponent<Props, State> {
	static defaultProps = {
		frequencies: allRecurringFrequencies
	};

	state = {
		options: {} as Options,
		// eslint-disable-next-line react/no-unused-state
		ical: ''
	};

	static getDerivedStateFromProps(nextProps) {
		if ('ical' in nextProps) {
			return {
				options: nextProps.ical ? RRule.fromString(nextProps.ical).origOptions : DEFAULT_RRULE_OPTIONS,
				ical: nextProps.ical || ''
			};
		}
		return null;
	}

	onOptionsChange = options => this.handleRRuleOptionsChange({ ...this.state.options, ...options });

	handleRRuleOptionsChange = options => {
		if (!('ical' in this.props)) {
			this.updateRRule(options);
		}

		this.triggerIcalChange(this.makeRRuleString(options));
	};

	triggerIcalChange = ical => {
		const { onChange } = this.props;
		if (onChange) {
			onChange(ical);
		}
	};

	getRecurringSelectOptions = () => {
		const { frequencies, frequenciesLabels } = this.props;
		const freqLabelsWithDefault = { ...defaultFrequencyLabels, ...frequenciesLabels };

		return frequencies.map(frequency => ({
			id: frequency,
			name: _get(freqLabelsWithDefault, frequency)
		}));
	};

	onRecurringFrequencySelect = frequency => {
		if (frequency === RECURRING_NO_REPEAT_FREQUENCY) {
			this.handleRRuleOptionsChange({});
			return;
		}

		const repeatOptions = _pick(this.state.options, ['count', 'until']);

		this.handleRRuleOptionsChange({
			...repeatOptions,
			freq: frequency,
			..._get(defaultFrequencyOptions, frequency)
		});
	};

	// eslint-disable-next-line react/no-unused-state
	updateRRule = options => this.setState({ options, ical: this.makeRRuleString(options) });

	makeRRuleString = options => new RRule(options).toString().replace('RRULE:', '');

	render() {
		const { getRecurringSelectOptions, onRecurringFrequencySelect, onOptionsChange } = this;
		const { frequenciesOptionsLabels, repeatLabels, disabled } = this.props;
		const { options } = this.state;

		const freqOptionLabelsWithDefaults = { ...defaultFrequenciesOptionsLabels, ...frequenciesOptionsLabels };
		const repeatLabelsWithDefaults = { ...defaultRepeatLabels, ...repeatLabels };
		return (
			<div className="recurring">
				<div className="recurring-frequency-select">
					<label>{repeatLabelsWithDefaults.repeat}:</label>
					<Select
						className={classNames({ disabled })}
						onSelect={onRecurringFrequencySelect}
						value={options.freq || RECURRING_NO_REPEAT_FREQUENCY}
						options={getRecurringSelectOptions()}
						disabled={disabled}
					/>
				</div>

				{options && options.freq && (
					<>
						<RecurringFrequencies
							options={options}
							onOptionsChange={onOptionsChange}
							disabled={disabled}
							frequenciesOptionsLabels={freqOptionLabelsWithDefaults}
						/>
						<RecurringRepeat
							options={options}
							onOptionsChange={onOptionsChange}
							disabled={disabled}
							repeatLabels={repeatLabelsWithDefaults}
						/>
					</>
				)}
			</div>
		);
	}
}

export default Recurring;
