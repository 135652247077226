import _ from 'lodash';
/**
 * Deep diff between two object, using lodash
 * @param  {Object} object Object compared
 * @param  {Object} base   Object to compare with
 * @param  {boolen} symmetric difference of arrays.
 * @return {Object}        Return a new object who represent the diff
 */
export function difference<T>(object, base, symmetric = false): T {
	function changes<T>(object, base): T {
		return _.transform(object, function (result, value, key) {
			if (!_.isEqual(value, base[key])) {
				if (_.isArray(value) && _.isArray(base[key])) {
					const diffArr = _[symmetric ? 'xor' : 'difference'](value, base[key]);

					if (!_.isEmpty(diffArr)) {
						result[key] = diffArr;
					}
				} else {
					result[key] = _.isObject(value) && _.isObject(base[key]) ? changes(value, base[key]) : value;
				}
			}
		});
	}

	return changes<T>(object, base);
}
