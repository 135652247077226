import { action, computed, observable, makeObservable } from 'mobx';
import { getRoot } from 'mobx-easy';
import { ApplicationUuid } from '@bringg/types';
import _merge from 'lodash/merge';
import RootStore from '../../../root-store';
import {
	ApplicationConfiguration,
	ApplicationMerchantConfiguration,
	tmpDefaultNotificationConfiguration
} from './store-app-store.consts';

interface StoreAppStore {
	setApplicationConfiguration: (applicationConfiguration: ApplicationConfiguration) => void;
	setApplicationMerchantConfiguration: (applicationMerchantConfiguration: ApplicationMerchantConfiguration) => void;
}

class StoreAppStore {
	private applicationMerchantConfiguration: ApplicationMerchantConfiguration = {};

	private applicationConfiguration: ApplicationConfiguration = {};

	constructor() {
		makeObservable<StoreAppStore, 'applicationMerchantConfiguration' | 'applicationConfiguration'>(this, {
			applicationMerchantConfiguration: observable,
			applicationConfiguration: observable,
			fetchConfiguration: action,
			applicationConfig: computed,
			applicationMerchantConfig: computed,
			configuration: computed,
			notificationsConfig: computed,
			isApplicationInstalled: computed,
			setApplicationMerchantConfiguration: action,
			setApplicationConfiguration: action
		});
	}

	setApplicationMerchantConfiguration = (applicationMerchantConfiguration: ApplicationMerchantConfiguration) => {
		this.applicationMerchantConfiguration = applicationMerchantConfiguration;
	};

	setApplicationConfiguration = (applicationConfiguration: ApplicationConfiguration) => {
		this.applicationConfiguration = applicationConfiguration;
	};

	fetchApplicationConfiguration = () => {
		const application = getRoot<RootStore>().data.applicationStore.getApplication(ApplicationUuid.StoreAppApp);

		return application?.configuration;
	};

	fetchApplicationMerchantConfiguration = async () => {
		const { applicationMerchantConfigurationStore } = getRoot<RootStore>().data;
		try {
			await applicationMerchantConfigurationStore.fetchConfiguration(ApplicationUuid.StoreAppApp);
			const applicationMerchantConfiguration = applicationMerchantConfigurationStore.getConfiguration(
				ApplicationUuid.StoreAppApp
			);

			return applicationMerchantConfiguration;
		} catch (err) {
			console.error('could not get store app merchant configuration', err);
			return undefined;
		}
	};

	fetchConfiguration = async (): Promise<void> => {
		const appConfig = await this.fetchApplicationConfiguration();
		const appMerchConfig = await this.fetchApplicationMerchantConfiguration();

		this.setApplicationConfiguration(appConfig);
		this.setApplicationMerchantConfiguration(appMerchConfig);
	};

	updateConfig = async (): Promise<void> => {
		if (!this.isApplicationInstalled) {
			await this.installApp();
		}
		await getRoot<RootStore>().data.applicationMerchantConfigurationStore.updateConfiguration(
			ApplicationUuid.StoreAppApp,
			this.configuration
		);
	};

	installApp = async (): Promise<void> => {
		await getRoot<RootStore>().data.applicationStore.installApplication(ApplicationUuid.StoreAppApp);
	};

	get applicationConfig() {
		return this.applicationConfiguration;
	}

	get applicationMerchantConfig() {
		return this.applicationMerchantConfiguration;
	}

	get configuration() {
		return _merge({}, this.applicationConfig, this.applicationMerchantConfig);
	}

	get notificationsConfig() {
		const { notifications_configuration } = this.configuration;
		return _merge({}, tmpDefaultNotificationConfiguration, notifications_configuration);
	}

	get isApplicationInstalled(): boolean {
		return getRoot<RootStore>().data.applicationStore.isApplicationInstalled(ApplicationUuid.StoreAppApp);
	}
}

export default StoreAppStore;
