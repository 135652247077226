import React, { ComponentProps, Suspense } from 'react';

import { LazyRichTextEditor } from '@bringg-frontend/rich-text-editor';
import { Spinner } from '@bringg/react-components';

import { useRichTextEditorTranslations } from './use-rich-text-editor-translations';

import styles from './rich-text-editor.module.scss';

type Props = Omit<ComponentProps<typeof LazyRichTextEditor>, 'translations'>;

const RichTextEditor = (props: Props) => {
	const translations = useRichTextEditorTranslations();

	return (
		<Suspense fallback={<Spinner className={styles.loaderContainer} />}>
			<LazyRichTextEditor {...props} translations={translations} />
		</Suspense>
	);
};

export default RichTextEditor;
