import { AirbrakeService } from '@bringg/frontend-utils';
import { IErrorNotifier } from '@bringg/frontend-utils/dist/services/airbrake/error-notifier';
import { getSharedRootStore } from '@bringg-frontend/global-stores';

import { AppConfig } from '../config';
import { getRootEnv } from '../create-root-env';

class AirBrakeNotifier {
	private notifier!: IErrorNotifier;

	constructor() {
		this.init();
	}

	async init() {
		const envConfig = await AppConfig.getEnvironmentConfig();
		const { environment, airbrake_project_id, airbrake_project_key } = envConfig;

		const didAirbrakeAlreadyInstrumentedElsewhere =
			window.firstAirbrakeInitSource !== undefined && window.firstAirbrakeInitSource !== 'react';

		if (airbrake_project_id && airbrake_project_key) {
			this.notifier = new AirbrakeService({
				projectId: airbrake_project_id,
				projectKey: airbrake_project_key,
				environment: environment,

				// Disabling it here as the angular part already does this, so we want to avoid duplicate errors
				instrumentation: didAirbrakeAlreadyInstrumentedElsewhere
					? {
							console: false,
							fetch: false,
							onerror: false,
							history: false,
							xhr: false,
							// @ts-expect-error - not in type but still exists
							unhandledrejection: false
					  }
					: undefined
			});
		}
	}

	// for tests
	reset() {
		// @ts-ignore
		this.notifier = undefined;
	}

	// @ts-ignore
	// eslint-disable-next-line
	public notify(error: Error, params: {}) {
		if (this.notifier) {
			const { currentUser } = getSharedRootStore().data.usersStore;
			const { environment } = getRootEnv().envConfig;
			const user_id = currentUser?.id;
			const merchant_id = currentUser?.merchant_id;

			const notifyParams = {
				error,
				params: {
					...params,
					env: environment,
					user_id,
					merchant_id
				},
				context: {
					environment,
					user_id,
					merchant_id
				}
			};

			this.notifier.notify(notifyParams);
		}
	}
}

export const airBrakeNotifier = new AirBrakeNotifier();
