import { useMemo } from 'react';

import { useTranslation } from 'react-i18next';

export function useTranslationKeys<T>(keys: Record<string, string>) {
	const { t } = useTranslation();

	return useMemo(
		() =>
			Object.entries(keys).reduce((acc, [key, value]) => {
				acc[key] = t(value);
				return acc;
			}, {}),
		[t]
	) as T;
}
