import React, { useCallback, useEffect, useState } from 'react';

import { observer, useObserver } from 'mobx-react';
import { FetchedData } from '@bringg/react-components';
import { useTranslation } from 'react-i18next';
import { BringgException } from '@bringg/dashboard-sdk/dist/Core/BringgException';
import { PrivilegeTypes } from '@bringg/types';
import { UpdateURLStrategy, useSearchParam } from '@bringg-frontend/hooks';

import notification from '../../services/notification';
import DeliveryBlocksCalendar from './calendar/delivery-blocks-calendar';
import DeliveryBlockModal from './modal/delivery-block-modal';
import DeliveryBlocksToolbar, { DeliveryBlocksViewMode } from './toolbar/delivery-blocks-toolbar';
import useStores from '../../recipes/use-stores';
import DeliveryBlocksTimeLineCalendar from './timeline-calendar/delivery-blocks-timeline-calendar';
import { NewDeliveryBlockModal, ResourcesPanelProps } from './new-modal/delivery-block-modal';
import { useHasCombinedOptimization } from 'bringg-web/features/delivery-blocks-v2/utils/use-has-combined-optimization';
import { useDeliveryBlocksStore, withDeliveryBlocksStore } from './stores/delivery-blocks-root-store';
import { timezoneProvider } from 'bringg-web/services/timezone/timezone-provider';
import { TimeRange } from 'bringg-web/features/delivery-blocks-v2/toolbar/delivery-block-toolbar-view';

interface Props {
	ResourcesPanel: React.ComponentType<ResourcesPanelProps>;
}
const DeliveryBlocksContainer: React.FC<Props> = ({ ResourcesPanel }: Props) => {
	const { t } = useTranslation();
	const { usersStore, teamsStore } = useStores();
	const { deliveryBlocksView } = useDeliveryBlocksStore();
	const [viewMode, setViewMode] = useState<DeliveryBlocksViewMode>(DeliveryBlocksViewMode.Week);
	const [searchValue, setSearchValue] = useState<string>('');
	const isNewModalOfDeliveryBlock = useHasCombinedOptimization(deliveryBlocksView?.currentTeam) || false;
	const [teamId, setTeamId] = useSearchParam<number>('team_id', {
		defaultValue: null,
		updateURLStrategy: UpdateURLStrategy.REPLACE
	});

	useEffect(() => {
		const init = async () => {
			try {
				deliveryBlocksView.setCurrentTeamId(teamId);
				await deliveryBlocksView.initializeData();
			} catch (e) {
				notification.error(
					t('DELIVERY_BLOCKS.FAILED_TO_GET_DELIVERY_BLOCKS'),
					(e as BringgException).details as string
				);
			}
		};

		init();

		return () => deliveryBlocksView.dispose();
	}, [deliveryBlocksView]);

	const handleCalendarTimeUpdateEvent = useCallback(
		async ({ event: { id }, start, end }) => {
			await deliveryBlocksView.onDragDropDeliveryBlock(id, new Date(start), new Date(end));
		},
		[deliveryBlocksView]
	);

	const onTeamSelect = async (teamId: number, weekRange: TimeRange) => {
		await deliveryBlocksView.onTeamSelect(teamId, weekRange);
		setTeamId(teamId);
	};

	return useObserver(() => {
		const { deliveryBlockToolbarView, deliveryBlockModalView, deliveryBlocksCalendarView, currentTeam } =
			deliveryBlocksView;
		const isEditable = usersStore.hasAccess(PrivilegeTypes.EDIT_DELIVERY_BLOCK);
		return (
			<div className="delivery-blocks-container">
				<FetchedData className="delivery-blocks-spinner" isFetched={teamsStore.isFetched} hideSpinner>
					{deliveryBlockToolbarView.timeRange && (
						<DeliveryBlocksToolbar
							onTeamSelect={onTeamSelect}
							onAddClick={deliveryBlocksView.openNewDeliveryBlock}
							deliveryBlockToolbarView={deliveryBlockToolbarView}
							viewMode={viewMode}
							onViewModeChanged={setViewMode}
							onFilterChange={setSearchValue}
							isEditable={isEditable}
						/>
					)}

					<FetchedData
						className="delivery-blocks-spinner"
						isFetched={!deliveryBlocksView.isDeliveryBlocksFetching && !deliveryBlocksView.isFetching}
						displayChildrenAndSpinner={false}
					>
						{viewMode === DeliveryBlocksViewMode.Week && deliveryBlockToolbarView.timeRange && (
							<DeliveryBlocksCalendar
								onEventSelect={({ id }) => deliveryBlocksView.openViewDeliveryBlock(id)}
								onEventDrop={handleCalendarTimeUpdateEvent}
								onEventResize={handleCalendarTimeUpdateEvent}
								currentWeek={deliveryBlockToolbarView.timeRange}
								deliveryBlocksCalendarView={deliveryBlocksCalendarView}
								team={currentTeam}
								isEditable={isEditable}
								isNewModalOfDeliveryBlock={isNewModalOfDeliveryBlock}
							/>
						)}

						{viewMode === DeliveryBlocksViewMode.Day && deliveryBlockToolbarView.timeRange && (
							<DeliveryBlocksTimeLineCalendar
								onDeliveryBlockClicked={deliveryBlocksView.openViewDeliveryBlock}
								currentDay={deliveryBlockToolbarView.timeRange.startDate.toISOString()}
								selectedTeam={currentTeam}
								onDeliveryBlockTimeUpdated={deliveryBlocksView.onDragDropDeliveryBlock}
								onDeliveryBlockBreakTimeUpdated={deliveryBlocksView.onDragDropDeliveryBlockBreak}
								searchValue={searchValue}
							/>
						)}
					</FetchedData>

					{deliveryBlockModalView &&
						(isNewModalOfDeliveryBlock ? (
							deliveryBlocksView.deliveryBlockModalVisible && (
								<NewDeliveryBlockModal
									visible
									onClose={deliveryBlocksView.closeDeliveryBlockModal}
									deliveryBlock={deliveryBlockModalView.deliveryBlock.asJson}
									parkingSpots={deliveryBlocksView.parkingSpots}
									serviceAreas={deliveryBlocksView.serviceAreas}
									timezone={timezoneProvider.getTimezoneByTeamId(
										deliveryBlockModalView.deliveryBlock.team_id
									)}
									ResourcesPanel={ResourcesPanel}
								/>
							)
						) : (
							<DeliveryBlockModal
								visible={deliveryBlocksView.deliveryBlockModalVisible}
								onClose={deliveryBlocksView.closeDeliveryBlockModal}
								deliveryBlockModalView={deliveryBlockModalView}
								currentCalendarDay={
									viewMode === DeliveryBlocksViewMode.Day
										? deliveryBlockToolbarView.timeRange?.startDate
										: null
								}
							/>
						))}
				</FetchedData>
			</div>
		);
	});
};

export default withDeliveryBlocksStore(observer(DeliveryBlocksContainer));
