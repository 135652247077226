import React from 'react';

import { Portal } from '@bringg-frontend/bringg-web-infra';

import MerchantCustomerNotifications from './merchant/merchant-customer-notifications-container';
import TagsCustomerNotifications from './tags/tags-customer-notifications-container';

const PortalMerchantCustomerNotifications = () => (
	<Portal element={<MerchantCustomerNotifications />} nodeId={'merchant-customer-configuration'} />
);

const PortalTagsCustomerNotifications = () => (
	<Portal element={<TagsCustomerNotifications />} nodeId={'tags-customer-configuration'} />
);

export { MerchantCustomerNotifications, PortalMerchantCustomerNotifications, PortalTagsCustomerNotifications };
