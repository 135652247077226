import type { Moment } from 'moment';
import type {
	TimelineAnimationOptions,
	TimelineEvents,
	TimelineGroup,
	TimelineOptionsZoomKey,
	TimelineTimeAxisOption,
	DateType,
	IdType,
	TimelineItem,
	TimelineEventPropertiesResult,
	TimelineFormatLabelsOption,
	TimelineFormatLabelsFunction
} from 'vis-timeline/types/entry-standalone';

import type { GroupCell } from './components/group-row-cells/group-row-cells';

export type ISOString = string;

export type GanttTimeLineItem = {
	id: number | string;
	rowId: number;
	start: ISOString;
	end?: ISOString;
	content?: string | JSX.Element;
	color?: string;
	editable?:
		| boolean
		| {
				draggable?: boolean;
				draggableToOtherRow?: boolean;
		  };
	clickable?: boolean;
	hoverable?: boolean;
	clusterable?: boolean;
	className?: string;
	customData?: { [key: string]: any };
	selectable?: boolean;
};

export type GroupColumnDefinition = {
	display: string | JSX.Element;
	width?: number;
};

export type GroupRow = {
	rowId: number;
	rowCells?: GroupCell[];
	hasError?: boolean;
	order?: number;
};

export type VisTimelineGroupExtended = TimelineGroup & {
	dataCells: GroupCell[];
};

export enum TooltipEventOption {
	Click,
	Hover
}

export type GanttOptions = {
	defaultColumnWidth?: number;
	showRemoveRowButton?: boolean;
	overlapItems?: boolean;
	showCurrentTime?: boolean;
	displayStartTime?: Date | Moment;
	displayEndTime?: Date | Moment;
	minAxisTime?: Date | Moment;
	maxAxisTime?: Date | Moment;
	constAxisStep?: TimelineTimeAxisOption;
	zoomable?: boolean;
	zoomKey?: TimelineOptionsZoomKey;
	zoomMax?: number; // milliseconds
	zoomMin?: number; // milliseconds
	timezone?: string;
	tooltipBy?: TooltipEventOption;
	tooltipDelay?: number;
	supportItemDurationChange?: true;
	fitClusterOnDoubleClick?: boolean;
	formatXaxis?: TimelineFormatLabelsOption | TimelineFormatLabelsFunction;
	onDropObjectOnItem?: (objectData) => void;
	isOutOfItemRange?: (item: ExtendedTimelineItem, currentStart: string, currentEnd: string) => boolean;
	showItemRange?: (item: ExtendedTimelineItem) => void;
	hideAllItemsRanges?: () => void;
	isOutOfGroupRange?: (groupId: number, currentStart: string, currentEnd: string) => boolean;
	onOutOfGroupRange?: () => void;
	onItemsChange?: () => void;
	onItemDroppedBeforeCurrentTime?: () => void;
	isNewItemPositionValid?: (item: ExtendedTimelineItem) => boolean;
};

export type TimelineClusterItem = {
	// This type added manually because vis d.ts file doesnt include it from some reason
	isCluster: boolean;
	group: { groupId: number };
	items: ExtendedTimelineItem[];
	title?: string;
	style?: string;
	className?: string;
};

export type TimelineItemValuesHistory = {
	originalGroupId: IdType;
	previousGroupId?: IdType;
	originalStart: DateType;
	previousStart?: DateType;
	originalEnd?: DateType;
	previousEnd?: DateType;
};

export type ExtendedTimelineItem = TimelineItem & {
	shouldCluster?: boolean;
	valuesHistory: TimelineItemValuesHistory;
	customData?: { [key: string]: any };
	color?: string;
};

export type ClickFunc = (
	items: GanttTimeLineItem[],
	isCluster: boolean,
	visClickEvent: TimelineEventPropertiesResult
) => void;

export type VisTimelineEvents = TimelineEvents | 'dragover' | 'markerchange' | 'markerchanged';
export type TimelineEventHandler =
	| 'currentTimeTickHandler'
	| 'clickHandler'
	| 'contextmenuHandler'
	| 'doubleClickHandler'
	| 'dragoverHandler'
	| 'dropHandler'
	| 'mouseOverHandler'
	| 'mouseDownHandler'
	| 'mouseUpHandler'
	| 'mouseMoveHandler'
	| 'groupDraggedHandler'
	| 'changedHandler'
	| 'rangechangeHandler'
	| 'rangechangedHandler'
	| 'selectHandler'
	| 'itemoverHandler'
	| 'itemoutHandler'
	| 'timechangeHandler'
	| 'timechangedHandler'
	| 'markerchangeHandler'
	| 'markerchangedHandler'
	| 'scrollHandler'
	| 'verticalDragHandler';

export const visTimelineEvents: ReadonlyArray<VisTimelineEvents> = [
	'currentTimeTick',
	'click',
	'contextmenu',
	'doubleClick',
	'dragover',
	'drop',
	'mouseOver',
	'mouseDown',
	'mouseUp',
	'mouseMove',
	'groupDragged',
	'changed',
	'rangechange',
	'rangechanged',
	'select',
	'itemover',
	'itemout',
	'timechange',
	'timechanged',
	'markerchange',
	'markerchanged',
	//@ts-ignore
	'verticalDrag',
	//@ts-ignore
	'scroll'
];

export type TimelineEventsHandlers = Partial<
	Record<TimelineEventHandler, (properties: TimelineEventPropertiesResult | any) => void>
>;

export type CustomTime = {
	datetime: Date;
	id: string;
};

export type SelectionOptions = { focus?: boolean; animation?: TimelineAnimationOptions };

export enum ToolTipPlacement {
	Right = 'right',
	Left = 'left',
	Top = 'top',
	Bottom = 'bottom'
}
