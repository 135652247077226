import React from 'react';
import moment, { Moment } from 'moment';
import { VehicleChange, Driver } from '@bringg/types';
import { DriversFilter } from '../drives-filter/drivers-filter';
import RangeCalendar from '../../../../components/range-calendar/range-calendar';
import { ALL_DRIVERS } from '../../vehicle-assignment-history.consts';

const DEFAULT_FIRST_DATE: Moment = moment().subtract(1, 'day');
const DEFAULT_SECOND_DATE: Moment = moment();

interface Props {
	assignments: VehicleChange[];
	optionalDrivers: Driver[];
	onFiltered: (filteredAssignments: VehicleChange[]) => void;
}

interface State {
	driversFiltered: number[];
	filterFrom: Moment;
	filterUntil: Moment;
}

export default class AssignmentsFilter extends React.Component<Props, State> {
	state = {
		driversFiltered: [ALL_DRIVERS],
		filterFrom: DEFAULT_FIRST_DATE,
		filterUntil: DEFAULT_SECOND_DATE
	};

	componentDidMount() {
		this.filterAssignments();
	}

	componentDidUpdate(prevProps: Props) {
		if (prevProps.assignments !== this.props.assignments) {
			this.filterAssignments();
		}
	}

	onSelectDrivers = (driversFiltered: number[]): void => {
		this.setState({ driversFiltered }, this.filterAssignments);
	};

	filterAssignments = (): void => {
		const isAllDriver: boolean = this.state.driversFiltered.includes(ALL_DRIVERS);

		const filteredAssignments = this.props.assignments.filter(
			assignment =>
				(isAllDriver || this.state.driversFiltered.includes(assignment.new_driver)) &&
				this.checkDateInRange(moment(assignment.start_time))
		);

		this.props.onFiltered(filteredAssignments);
	};

	onDatesChange = (dates: Moment[]): void => {
		const filterFrom = dates[0];
		const filterUntil = dates[1].endOf('day');

		this.setState({ filterFrom, filterUntil }, this.filterAssignments);
	};

	checkDateInRange = (date: Moment): boolean => {
		return (
			(this.state.filterFrom == null || date >= this.state.filterFrom) &&
			(this.state.filterUntil == null || date <= this.state.filterUntil)
		);
	};

	render() {
		return (
			<div>
				<div className="driver-button">
					<DriversFilter
						optionalDrivers={Array.from(this.props.optionalDrivers)}
						onSelectDrivers={this.onSelectDrivers}
					/>
				</div>
				<RangeCalendar onChange={this.onDatesChange} defaultValue={[DEFAULT_FIRST_DATE, DEFAULT_SECOND_DATE]} />
			</div>
		);
	}
}
