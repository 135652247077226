import i18next from 'i18next';
import {
	ManualTriggerInputParam,
	ManualTriggerParamDataType,
	PrimitiveManualTriggerParamDataType,
	ReferredManualTriggerInputParam,
	ReferredManualTriggerParamDataType
} from '@bringg/types';
import { capitalizeFirstLetter } from '@bringg-frontend/utils';
import { FORM_CONTROLS } from '@bringg-frontend/dynamic-form-modal';
import {
	encodeReferredParam,
	isPrimitiveManualTriggerInputParam,
	toCustomAttributeDataTypeNameWithPrefix
} from '@bringg-frontend/bringg-web-legacy/automation-platform';

const paramTypeToFormControl: Record<ManualTriggerParamDataType, FORM_CONTROLS> = {
	[PrimitiveManualTriggerParamDataType.STRING]: FORM_CONTROLS.TEXTBOX,
	[PrimitiveManualTriggerParamDataType.NUMBER]: FORM_CONTROLS.INPUT,
	[PrimitiveManualTriggerParamDataType.BOOLEAN]: FORM_CONTROLS.CHECKBOX,
	[PrimitiveManualTriggerParamDataType.TIME]: FORM_CONTROLS.TIME,
	[PrimitiveManualTriggerParamDataType.DATE]: FORM_CONTROLS.DATE,
	[PrimitiveManualTriggerParamDataType.DATETIME]: FORM_CONTROLS.DATETIME,
	[PrimitiveManualTriggerParamDataType.DRIVER]: FORM_CONTROLS.USER_SELECTOR,
	[ReferredManualTriggerParamDataType.CUSTOM_ATTRIBUTE]: FORM_CONTROLS.DROP_DOWN
};

const getFieldControl = (fieldType: ManualTriggerParamDataType): FORM_CONTROLS => {
	return paramTypeToFormControl[fieldType];
};

export const configurationContentProvider = {
	fields(referredParams?: ReferredManualTriggerInputParam[]) {
		return {
			primitives: [
				{
					id: PrimitiveManualTriggerParamDataType.STRING,
					name: capitalizeFirstLetter(i18next.t('AUTOMATION.TRIGGER.MANUAL.TYPES.STRING'))
				},
				{
					id: PrimitiveManualTriggerParamDataType.BOOLEAN,
					name: capitalizeFirstLetter(i18next.t('AUTOMATION.TRIGGER.MANUAL.TYPES.BOOLEAN'))
				},
				{
					id: PrimitiveManualTriggerParamDataType.NUMBER,
					name: capitalizeFirstLetter(i18next.t('AUTOMATION.TRIGGER.MANUAL.TYPES.NUMBER'))
				},
				{
					id: PrimitiveManualTriggerParamDataType.TIME,
					name: capitalizeFirstLetter(i18next.t('AUTOMATION.TRIGGER.MANUAL.TYPES.TIME'))
				},
				{
					id: PrimitiveManualTriggerParamDataType.DATE,
					name: capitalizeFirstLetter(i18next.t('AUTOMATION.TRIGGER.MANUAL.TYPES.DATE'))
				},
				{
					id: PrimitiveManualTriggerParamDataType.DATETIME,
					name: capitalizeFirstLetter(i18next.t('AUTOMATION.TRIGGER.MANUAL.TYPES.DATETIME'))
				},
				{
					id: PrimitiveManualTriggerParamDataType.DRIVER,
					name: capitalizeFirstLetter(i18next.t('AUTOMATION.TRIGGER.MANUAL.TYPES.DRIVER'))
				}
			],
			referreds: {
				customAttributes:
					referredParams?.map(param => ({
						id: encodeReferredParam({ dataType: param.data_type, refId: param.ref_id }),
						name: toCustomAttributeDataTypeNameWithPrefix(param.name)
					})) || []
			}
		};
	},
	getFieldId: (field: ManualTriggerInputParam) => {
		if (isPrimitiveManualTriggerInputParam(field)) {
			return field.data_type;
		}

		return encodeReferredParam({ dataType: field.data_type, refId: field.ref_id });
	},
	getFieldControl
};
