import { getRootEnv } from '@bringg-frontend/bringg-web-infra';
import React, { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { getEnv } from 'mobx-easy';
import moment from 'moment';
// eslint-disable-next-line no-restricted-imports
import { Dropdown } from 'antd';
import { Button, Table, BringgInput as Input, Notification } from '@bringg/react-components';
import { BringgFontIcons, BringgIcon } from '@bringg/bringg-icons';
import { TaskConfiguration, ServicePlan } from '@bringg/types';
import { observer } from 'mobx-react';
import { RootEnv } from '@bringg-frontend/bringg-web-infra';

import { useStores } from 'bringg-web/recipes';
import { ServiceModal } from './forms/service-plan-form';
import { menu } from './menu';

import './service-plan.scss';

const formatUpdatedAt = (date: string) => moment(date).format('DD/MM/YY');

const ServicePlans: React.FC = () => {
	const { t } = useTranslation();

	const { servicePlansStore, tagsStore } = useStores();
	const { getAll: servicePlans } = servicePlansStore;
	const { getAll: tags } = tagsStore;
	const [taskConfigurations, setTaskConfigurations] = useState<TaskConfiguration[]>([]);
	const [taskConfigurationsMap, setTaskConfigurationsMap] = useState<Map<number, TaskConfiguration>>(new Map());
	const [isTaskConfigurationsFetched, setIsTaskConfigurationsFetched] = useState<boolean>(false); // must be in dedicated store

	const [searchQuery, setSearchQuery] = useState<string>('');

	useEffect(() => {
		const fetchInitialData = async () => {
			try {
				const [taskConfigurationsData]: [TaskConfiguration[], void, void] = await Promise.all([
					getRootEnv()
						.dashboardSdk.sdk.taskConfigurations.getAllActionData()
						.then(({ success, task_configurations }) => (success ? task_configurations : [])),
					servicePlansStore.fetchAll(),
					tagsStore.fetchAll()
				]);

				setIsTaskConfigurationsFetched(true);
				setTaskConfigurations(taskConfigurationsData);
			} catch (err) {
				console.error(err);
				Notification.error(t('ROUTES_PLANNER.SOMETHING_WENT_WRONG'));
			}
		};

		if (!servicePlansStore.isFetched || !tagsStore.isFetched || !isTaskConfigurationsFetched) {
			fetchInitialData();
		}
	}, [servicePlansStore, tagsStore, isTaskConfigurationsFetched, t]);

	useEffect(() => {
		setTaskConfigurationsMap(new Map(taskConfigurations.map(item => [item.id, item])));
	}, [taskConfigurations]);

	const [isOpen, setIsOpen] = useState(false);
	const [servicePlan, setServicePlan] = useState<ServicePlan | null>(null);
	const names = servicePlans.map(({ name }) => name);
	const externalIds = servicePlans.map(({ external_id }) => external_id);
	const columns = [
		{
			title: t('SERVICE_PLAN.SERVICE.ID'),
			dataIndex: 'id',
			width: 140,
			defaultSortOrder: null,
			showSorterTooltip: false,
			sorter: (a, b) => (Number(a.id) > Number(b.id) ? 1 : -1)
		},
		{
			title: t('SERVICE_PLAN.SERVICE_NAME'),
			dataIndex: 'name'
		},
		{
			title: t('SERVICE_PLAN.EXTERNAL.ID'),
			dataIndex: 'external_id',
			render: externalId => externalId || '-'
		},
		{
			title: t('SERVICE_PLAN.TAST.CONFIGURATION'),
			dataIndex: 'task_configuration_id',
			render: taskConfigurationId => taskConfigurationsMap.get(taskConfigurationId)?.title || '-',
			ellipsis: true
		},
		{
			title: t('SERVICE_PLAN.TAG'),
			dataIndex: 'tag_id',
			render: tagId => tagsStore.get(tagId)?.tag || '-',
			ellipsis: true
		},
		{
			title: t('SERVICE_PLAN.UPDATED.AT'),
			dataIndex: 'updated_at',
			render: (_, record) => formatUpdatedAt(record.updated_at)
		},
		{
			title: t('SERVICE_PLAN.ACTIONS'),
			width: 140,
			render: (_, record) => (
				<Dropdown overlay={menu(record, setServicePlan, setIsOpen, t)}>
					<Button type="link" className="service-plan-action-button">
						<BringgIcon iconName={BringgFontIcons.MenuHorizontal} />
					</Button>
				</Dropdown>
			)
		}
	];

	if (!servicePlansStore.isFetched || !tagsStore.isFetched || !isTaskConfigurationsFetched) {
		return null;
	}

	const filteredServicePlans = searchQuery
		? servicePlans.filter(item =>
				['id', 'name', 'task_configuration_title', 'tag_title', 'external_id'].some(key =>
					String(item[key]).toLowerCase().includes(searchQuery?.toLocaleLowerCase())
				)
		  )
		: servicePlans;

	const handleSubmit = async data => {
		if (data.id) {
			try {
				await servicePlansStore.editServicePlan(data);

				Notification.success(t('SERVICE_PLAN.NOTIFICATION.UPDATE.SUCCESS'));
			} catch {
				Notification.error(t('SERVICE_PLAN.NOTIFICATION.UPDATE.ERROR'));
			}
		} else {
			try {
				await servicePlansStore.createServicePlan(data);

				Notification.success(t('SERVICE_PLAN.NOTIFICATION.CREATE.SUCCESS'));
			} catch {
				Notification.error(t('SERVICE_PLAN.NOTIFICATION.CREATE.ERROR'));
			}
		}
	};

	return (
		<div className="service-plan">
			<div className="service-plans-header">
				<Input
					allowClear
					className="search-service-plan"
					value={searchQuery}
					onChange={event => setSearchQuery(event.target?.value)}
					placeholder={t('SERVICE_PLAN.SEARCH')}
				/>

				<Button onClick={() => setIsOpen(true)} type="primary">
					{t('SERVICE_PLAN.NEW.SERVICE')}
				</Button>
			</div>

			<ServiceModal
				names={names}
				externalIds={externalIds}
				taskConfigurations={taskConfigurations}
				tags={tags}
				isOpen={isOpen}
				onCancel={() => setIsOpen(false)}
				onSubmit={handleSubmit}
				setIsOpen={setIsOpen}
				servicePlan={servicePlan}
				setServicePlan={setServicePlan}
			/>

			{servicePlans.length ? (
				<Table className="service-plans-table" bordered dataSource={filteredServicePlans} columns={columns} />
			) : (
				<div className="no-service-plans-container">
					<div className="no-service-plans">
						<span>{t('SERVICE_PLAN.EMPTY.TABLE')}</span>
					</div>
				</div>
			)}
		</div>
	);
};

export default observer(ServicePlans);
