const countryList = [
	{ value: 'af', text: 'Afghanistan' },
	{ value: 'ax', text: 'Åland islands' },
	{ value: 'al', text: 'Albania' },
	{ value: 'dz', text: 'Algeria' },
	{ value: 'as', text: 'American samoa' },
	{ value: 'ad', text: 'Andorra' },
	{ value: 'ao', text: 'Angola' },
	{ value: 'ai', text: 'Anguilla' },
	{ value: 'aq', text: 'Antarctica' },
	{ value: 'ag', text: 'Antigua and barbuda' },
	{ value: 'ar', text: 'Argentina' },
	{ value: 'am', text: 'Armenia' },
	{ value: 'aw', text: 'Aruba' },
	{ value: 'au', text: 'Australia' },
	{ value: 'at', text: 'Austria' },
	{ value: 'az', text: 'Azerbaijan' },
	{ value: 'bs', text: 'Bahamas' },
	{ value: 'bh', text: 'Bahrain' },
	{ value: 'bd', text: 'Bangladesh' },
	{ value: 'bb', text: 'Barbados' },
	{ value: 'by', text: 'Belarus' },
	{ value: 'be', text: 'Belgium' },
	{ value: 'bz', text: 'Belize' },
	{ value: 'bj', text: 'Benin' },
	{ value: 'bm', text: 'Bermuda' },
	{ value: 'bt', text: 'Bhutan' },
	{ value: 'bo', text: 'Bolivia, plurinational state of' },
	{ value: 'bq', text: 'Bonaire, sint eustatius and saba' },
	{ value: 'ba', text: 'Bosnia and herzegovina' },
	{ value: 'bw', text: 'Botswana' },
	{ value: 'bv', text: 'Bouvet island' },
	{ value: 'br', text: 'Brazil' },
	{ value: 'io', text: 'British indian ocean territory' },
	{ value: 'bn', text: 'Brunei darussalam' },
	{ value: 'bg', text: 'Bulgaria' },
	{ value: 'bf', text: 'Burkina faso' },
	{ value: 'bi', text: 'Burundi' },
	{ value: 'kh', text: 'Cambodia' },
	{ value: 'cm', text: 'Cameroon' },
	{ value: 'ca', text: 'Canada' },
	{ value: 'cv', text: 'Cape verde' },
	{ value: 'ky', text: 'Cayman islands' },
	{ value: 'cf', text: 'Central african republic' },
	{ value: 'td', text: 'Chad' },
	{ value: 'cl', text: 'Chile' },
	{ value: 'cn', text: 'China' },
	{ value: 'cx', text: 'Christmas island' },
	{ value: 'cc', text: 'Cocos (keeling) islands' },
	{ value: 'co', text: 'Colombia' },
	{ value: 'km', text: 'Comoros' },
	{ value: 'cg', text: 'Congo' },
	{ value: 'cd', text: 'Congo, the democratic republic of the' },
	{ value: 'ck', text: 'Cook islands' },
	{ value: 'cr', text: 'Costa rica' },
	{ value: 'ci', text: "Côte d'ivoire" },
	{ value: 'hr', text: 'Croatia' },
	{ value: 'cu', text: 'Cuba' },
	{ value: 'cw', text: 'Curaçao' },
	{ value: 'cy', text: 'Cyprus' },
	{ value: 'cz', text: 'Czech republic' },
	{ value: 'dk', text: 'Denmark' },
	{ value: 'dj', text: 'Djibouti' },
	{ value: 'dm', text: 'Dominica' },
	{ value: 'do', text: 'Dominican republic' },
	{ value: 'ec', text: 'Ecuador' },
	{ value: 'eg', text: 'Egypt' },
	{ value: 'sv', text: 'El salvador' },
	{ value: 'gq', text: 'Equatorial guinea' },
	{ value: 'er', text: 'Eritrea' },
	{ value: 'ee', text: 'Estonia' },
	{ value: 'et', text: 'Ethiopia' },
	{ value: 'fk', text: 'Falkland islands (malvinas)' },
	{ value: 'fo', text: 'Faroe islands' },
	{ value: 'fj', text: 'Fiji' },
	{ value: 'fi', text: 'Finland' },
	{ value: 'fr', text: 'France' },
	{ value: 'gf', text: 'French guiana' },
	{ value: 'pf', text: 'French polynesia' },
	{ value: 'tf', text: 'French southern territories' },
	{ value: 'ga', text: 'Gabon' },
	{ value: 'gm', text: 'Gambia' },
	{ value: 'ge', text: 'Georgia' },
	{ value: 'de', text: 'Germany' },
	{ value: 'gh', text: 'Ghana' },
	{ value: 'gi', text: 'Gibraltar' },
	{ value: 'gr', text: 'Greece' },
	{ value: 'gl', text: 'Greenland' },
	{ value: 'gd', text: 'Grenada' },
	{ value: 'gp', text: 'Guadeloupe' },
	{ value: 'gu', text: 'Guam' },
	{ value: 'gt', text: 'Guatemala' },
	{ value: 'gg', text: 'Guernsey' },
	{ value: 'gn', text: 'Guinea' },
	{ value: 'gw', text: 'Guinea-bissau' },
	{ value: 'gy', text: 'Guyana' },
	{ value: 'ht', text: 'Haiti' },
	{ value: 'hm', text: 'Heard island and mcdonald islands' },
	{ value: 'va', text: 'Holy see (vatican city state)' },
	{ value: 'hn', text: 'Honduras' },
	{ value: 'hk', text: 'Hong kong' },
	{ value: 'hu', text: 'Hungary' },
	{ value: 'is', text: 'Iceland' },
	{ value: 'in', text: 'India' },
	{ value: 'id', text: 'Indonesia' },
	{ value: 'ir', text: 'Iran, islamic republic of' },
	{ value: 'iq', text: 'Iraq' },
	{ value: 'ie', text: 'Ireland' },
	{ value: 'im', text: 'Isle of man' },
	{ value: 'il', text: 'Israel' },
	{ value: 'it', text: 'Italy' },
	{ value: 'jm', text: 'Jamaica' },
	{ value: 'jp', text: 'Japan' },
	{ value: 'je', text: 'Jersey' },
	{ value: 'jo', text: 'Jordan' },
	{ value: 'kz', text: 'Kazakhstan' },
	{ value: 'ke', text: 'Kenya' },
	{ value: 'ki', text: 'Kiribati' },
	{ value: 'kp', text: "Korea, democratic people's republic of" },
	{ value: 'kr', text: 'Korea, republic of' },
	{ value: 'kw', text: 'Kuwait' },
	{ value: 'kg', text: 'Kyrgyzstan' },
	{ value: 'la', text: "Lao people's democratic republic" },
	{ value: 'lv', text: 'Latvia' },
	{ value: 'lb', text: 'Lebanon' },
	{ value: 'ls', text: 'Lesotho' },
	{ value: 'lr', text: 'Liberia' },
	{ value: 'ly', text: 'Libya' },
	{ value: 'li', text: 'Liechtenstein' },
	{ value: 'lt', text: 'Lithuania' },
	{ value: 'lu', text: 'Luxembourg' },
	{ value: 'mo', text: 'Macao' },
	{ value: 'mk', text: 'Macedonia, the former yugoslav republic of' },
	{ value: 'mg', text: 'Madagascar' },
	{ value: 'mw', text: 'Malawi' },
	{ value: 'my', text: 'Malaysia' },
	{ value: 'mv', text: 'Maldives' },
	{ value: 'ml', text: 'Mali' },
	{ value: 'mt', text: 'Malta' },
	{ value: 'mh', text: 'Marshall islands' },
	{ value: 'mq', text: 'Martinique' },
	{ value: 'mr', text: 'Mauritania' },
	{ value: 'mu', text: 'Mauritius' },
	{ value: 'yt', text: 'Mayotte' },
	{ value: 'mx', text: 'Mexico' },
	{ value: 'fm', text: 'Micronesia, federated states of' },
	{ value: 'md', text: 'Moldova, republic of' },
	{ value: 'mc', text: 'Monaco' },
	{ value: 'mn', text: 'Mongolia' },
	{ value: 'me', text: 'Montenegro' },
	{ value: 'ms', text: 'Montserrat' },
	{ value: 'ma', text: 'Morocco' },
	{ value: 'mz', text: 'Mozambique' },
	{ value: 'mm', text: 'Myanmar' },
	{ value: 'na', text: 'Namibia' },
	{ value: 'nr', text: 'Nauru' },
	{ value: 'np', text: 'Nepal' },
	{ value: 'nl', text: 'Netherlands' },
	{ value: 'nc', text: 'New caledonia' },
	{ value: 'nz', text: 'New zealand' },
	{ value: 'ni', text: 'Nicaragua' },
	{ value: 'ne', text: 'Niger' },
	{ value: 'ng', text: 'Nigeria' },
	{ value: 'nu', text: 'Niue' },
	{ value: 'nf', text: 'Norfolk island' },
	{ value: 'mp', text: 'Northern mariana islands' },
	{ value: 'no', text: 'Norway' },
	{ value: 'om', text: 'Oman' },
	{ value: 'pk', text: 'Pakistan' },
	{ value: 'pw', text: 'Palau' },
	{ value: 'pa', text: 'Panama' },
	{ value: 'pg', text: 'Papua new guinea' },
	{ value: 'py', text: 'Paraguay' },
	{ value: 'pe', text: 'Peru' },
	{ value: 'ph', text: 'Philippines' },
	{ value: 'pn', text: 'Pitcairn' },
	{ value: 'pl', text: 'Poland' },
	{ value: 'pt', text: 'Portugal' },
	{ value: 'pr', text: 'Puerto rico' },
	{ value: 'qa', text: 'Qatar' },
	{ value: 're', text: 'Réunion' },
	{ value: 'ro', text: 'Romania' },
	{ value: 'ru', text: 'Russian federation' },
	{ value: 'rw', text: 'Rwanda' },
	{ value: 'bl', text: 'Saint barthélemy' },
	{ value: 'sh', text: 'Saint helena, ascension and tristan da cunha' },
	{ value: 'kn', text: 'Saint kitts and nevis' },
	{ value: 'lc', text: 'Saint lucia' },
	{ value: 'mf', text: 'Saint martin (french part)' },
	{ value: 'pm', text: 'Saint pierre and miquelon' },
	{ value: 'vc', text: 'Saint vincent and the grenadines' },
	{ value: 'ws', text: 'Samoa' },
	{ value: 'sm', text: 'San marino' },
	{ value: 'st', text: 'Sao tome and principe' },
	{ value: 'sa', text: 'Saudi arabia' },
	{ value: 'sn', text: 'Senegal' },
	{ value: 'rs', text: 'Serbia' },
	{ value: 'sc', text: 'Seychelles' },
	{ value: 'sl', text: 'Sierra leone' },
	{ value: 'sg', text: 'Singapore' },
	{ value: 'sx', text: 'Sint maarten (dutch part)' },
	{ value: 'sk', text: 'Slovakia' },
	{ value: 'si', text: 'Slovenia' },
	{ value: 'sb', text: 'Solomon islands' },
	{ value: 'so', text: 'Somalia' },
	{ value: 'za', text: 'South africa' },
	{ value: 'gs', text: 'South georgia and the south sandwich islands' },
	{ value: 'ss', text: 'South sudan' },
	{ value: 'es', text: 'Spain' },
	{ value: 'lk', text: 'Sri lanka' },
	{ value: 'sd', text: 'Sudan' },
	{ value: 'sr', text: 'Suriname' },
	{ value: 'sj', text: 'Svalbard and jan mayen' },
	{ value: 'sz', text: 'Swaziland' },
	{ value: 'se', text: 'Sweden' },
	{ value: 'ch', text: 'Switzerland' },
	{ value: 'sy', text: 'Syrian arab republic' },
	{ value: 'tw', text: 'Taiwan, province of china' },
	{ value: 'tj', text: 'Tajikistan' },
	{ value: 'tz', text: 'Tanzania, united republic of' },
	{ value: 'th', text: 'Thailand' },
	{ value: 'tl', text: 'Timor-leste' },
	{ value: 'tg', text: 'Togo' },
	{ value: 'tk', text: 'Tokelau' },
	{ value: 'to', text: 'Tonga' },
	{ value: 'tt', text: 'Trinidad and tobago' },
	{ value: 'tn', text: 'Tunisia' },
	{ value: 'tr', text: 'Turkey' },
	{ value: 'tm', text: 'Turkmenistan' },
	{ value: 'tc', text: 'Turks and caicos islands' },
	{ value: 'tv', text: 'Tuvalu' },
	{ value: 'ug', text: 'Uganda' },
	{ value: 'ua', text: 'Ukraine' },
	{ value: 'ae', text: 'United arab emirates' },
	{ value: 'gb', text: 'United kingdom' },
	{ value: 'us', text: 'United states' },
	{ value: 'um', text: 'United states minor outlying islands' },
	{ value: 'uy', text: 'Uruguay' },
	{ value: 'uz', text: 'Uzbekistan' },
	{ value: 'vu', text: 'Vanuatu' },
	{ value: 've', text: 'Venezuela, bolivarian republic of' },
	{ value: 'vn', text: 'Viet nam' },
	{ value: 'vg', text: 'Virgin islands, british' },
	{ value: 'vi', text: 'Virgin islands, u.s.' },
	{ value: 'wf', text: 'Wallis and futuna' },
	{ value: 'eh', text: 'Western sahara' },
	{ value: 'ye', text: 'Yemen' },
	{ value: 'zm', text: 'Zambia' },
	{ value: 'zw', text: 'Zimbabwe' }
];

export default countryList;
