import React, { useMemo } from 'react';

import { useTranslation } from 'react-i18next';

import { useStores } from 'bringg-web/recipes';
import useAutomationTranslations from '../../../utils/use-automation-translations';
import { TriggerContainer } from '../../../utils/types';
import InfoTags from '../../info-tags/info-tags';

interface Props {
	teamIds: number[];
	triggerFamily: TriggerContainer;
}

const WorkflowInfoTags = ({ teamIds, triggerFamily }: Props) => {
	const { t } = useTranslation();
	const { teamsStore } = useStores();
	const { allTeams } = useAutomationTranslations();

	const teams = useMemo(() => {
		if (teamIds.length === 0) {
			return allTeams;
		}

		if (teamIds.length === 1) {
			return teamsStore.get(teamIds[0])?.name;
		}

		return `${teamIds.length} Teams`;
	}, [teamIds, teamsStore, allTeams]);

	return <InfoTags tags={[teams, t(`AUTOMATION.TRIGGER.${triggerFamily.toUpperCase()}_TITLE`)]} />;
};

export default WorkflowInfoTags;
