import sanitizeHtml from 'sanitize-html-react';
import { allowedTags, allowedAttributes, additionalSelfClosing } from '@bringg/common-utils';
import { getRoot } from 'mobx-easy';
import { hasFeatureFlag } from '@bringg-frontend/global-stores';
import { dateUtils } from '@bringg-frontend/utils';
import moment from 'moment/moment';
import { HourFormat } from '@bringg/types';

import { labelRegex } from 'bringg-web/components/text-template';
import type RootStore from 'bringg-web/stores/root-store';
import { DISABLE_TEMPLATE_SANITIZER_FF } from './consts';
import { SupportedPlaceholders } from './types';

const removeRepeatedEmptyLines = (text: string): string => {
	// Get rid of ZWSP before removing repeated empty lines
	return text.replace(/\u200B/g, ' ').replace(/\n(\s+)?\n(\s+)?\n/g, '\n\n');
};

export const sanitizeHtmlContent = (previewContent: string): string => {
	// sanitize-html doesn't support <!doctype> anyhow at the moment
	// https://github.com/apostrophecms/sanitize-html/issues/131
	const [firstLine, ...restLines] = previewContent.split('\n');
	const hasDoctype = /^<!doctype html>$/i.exec(firstLine.trim());
	const contentToSanitize = (hasDoctype ? restLines : [firstLine, ...restLines]).join('\n');

	const sanitizedContent = sanitizeHtml(contentToSanitize, {
		allowedTags,
		allowedAttributes,
		selfClosing: sanitizeHtml.defaults.selfClosing.concat(additionalSelfClosing)
	});

	const result = removeRepeatedEmptyLines(sanitizedContent);

	return hasDoctype ? `<!doctype html>\n${result}` : result;
};

export const sanitizeHtmlContentByFF = (previewContent: string) => {
	const { currentUser } = getRoot<RootStore>().data.usersStore;
	const isSanitizerDisabled = hasFeatureFlag(currentUser, DISABLE_TEMPLATE_SANITIZER_FF);

	return isSanitizerDisabled ? previewContent : sanitizeHtmlContent(previewContent);
};

export const replacePlaceHoldersWithMockForPreview = (content: string, placeholders: Record<string, string>) => {
	let newContent = content;

	const matches = newContent.matchAll(labelRegex);
	for (const match of matches) {
		// remove `{{`` on the start of match and `}}` on the end
		const placeholderName = match[0].replace(/[{}]/g, '');

		if (placeholders[placeholderName]) {
			newContent = newContent.replace(match[0], placeholders[placeholderName]);
		}
	}

	return newContent;
};

type PlaceholdersConfig = {
	hourFormat: HourFormat;
};
export const getPlaceholdersDummyData = ({ hourFormat }: PlaceholdersConfig): Record<SupportedPlaceholders, string> => {
	const timeFormat = dateUtils.getMerchantTimeFormat(hourFormat, true);

	return {
		[SupportedPlaceholders.Merchant]: 'Galactic Delivery',
		[SupportedPlaceholders.ScheduleLink]: 'https://www.google.com/search?q=schedule',
		[SupportedPlaceholders.ScheduleReadyLink]: 'https://www.google.com/search?q=schedule+ready',
		[SupportedPlaceholders.PickupLink]: 'https://www.google.com/search?q=pickup',
		[SupportedPlaceholders.TrackingLink]: 'https://www.google.com/search?q=tracking',
		[SupportedPlaceholders.CustomerName]: 'Meggie Carter',
		[SupportedPlaceholders.DriverName]: 'John Solo',
		[SupportedPlaceholders.JobDescription]: 'Delivery fast and reliable',
		[SupportedPlaceholders.OrderTitle]: 'Furniture for Meggie',
		[SupportedPlaceholders.OrderId]: 'furniture-delivery-for-meggie',
		[SupportedPlaceholders.WaypointCustomerName]: 'Walt Whitman',
		[SupportedPlaceholders.CustomerFirstName]: 'Meggie',
		[SupportedPlaceholders.DeliveryAddress]: 'Santa Anita 15009, Peru',
		[SupportedPlaceholders.DeliveryTimeWindow]: `${moment().add(30, 'minutes').format(timeFormat)} - ${moment()
			.add(60, 'minute')
			.format(timeFormat)}`,
		[SupportedPlaceholders.TeamDescription]: 'The Best Team',
		[SupportedPlaceholders.NotificationId]: 'cRAMnqEn'
	};
};
