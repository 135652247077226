import React, { useEffect, useRef, useState } from 'react';

import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
// eslint-disable-next-line no-restricted-imports
import { Button, Input, notification } from 'antd';
import { FleetEnvType, FleetStatus } from '@bringg/types';
import { useOutsideClick } from '@bringg-frontend/hooks';

import { Connect, TEMPORARY_CONNECTION } from '../delivery-catalog-connect';
import { Edit } from '../icons/edit';
import { ConnectionFieldsValues } from './delivery-catalog-connect-content';
import { FALLBACK_LOGO } from '../../../consts';

interface DeliveryCatalogConnectNameProps {
	connection: Connect;
	onUpdate: (id: number | string, values: { name: string }) => Promise<void>;
	onCreate: (values: ConnectionFieldsValues) => Promise<void>;
}

enum Errors {
	FLEET_NAME_ON_MERCHANT_ALREADY_EXISTS = 'FLEET_NAME_ON_MERCHANT_ALREADY_EXISTS'
}

const DeliveryCatalogConnectName = ({ connection, onUpdate, onCreate }: DeliveryCatalogConnectNameProps) => {
	const { t } = useTranslation();
	const ref = useRef<HTMLDivElement>();

	const [name, setName] = useState(null);
	const [isEditable, setIsEditable] = useState(false);
	const isDisabled = !connection || connection?.status === FleetStatus.Active;

	useEffect(() => {
		setName(connection?.name);
	}, [connection?.name]);

	useEffect(() => {
		setIsEditable(false);
	}, [connection?.id]);

	const onDone = async () => {
		if (!isEditable) {
			return;
		}

		if (connection.id !== TEMPORARY_CONNECTION && name === connection.name) {
			setIsEditable(false);
			return;
		}

		try {
			if (connection.id === TEMPORARY_CONNECTION) {
				await onCreate({ name, data: {}, env_type: FleetEnvType.Staging });
			} else {
				await onUpdate(connection.id, { name });
			}

			setIsEditable(false);
		} catch (error) {
			const { title } = (error as any)?.data ?? {};

			if (title === Errors.FLEET_NAME_ON_MERCHANT_ALREADY_EXISTS) {
				notification.error({
					message: t('DELIVERY_PROVIDER_CATALOG_CONNECT.ERRORS.FLEET_NAME_ON_MERCHANT_ALREADY_EXISTS')
				});
			}

			throw error;
		}
	};

	const onOutside = () => {
		if (isEditable) {
			setName(connection?.name);
			setIsEditable(false);
		}
	};

	// eslint-disable-next-line @typescript-eslint/no-misused-promises
	useOutsideClick(ref, onOutside);

	return (
		<div className="delivery-catalog-connect-name">
			<div
				className="delivery-catalog-connect-name-logo"
				style={{
					backgroundImage: `url(${connection?.delivery_provider?.absolute_logo_path || FALLBACK_LOGO})`
				}}
			/>

			{isEditable ? (
				<div className="delivery-catalog-connect-name-input" ref={ref}>
					<Input value={name} onChange={event => setName(event.target.value)} onPressEnter={onDone} />
				</div>
			) : (
				<div
					className={classNames('delivery-catalog-connect-name-title', {
						'delivery-catalog-connect-name-title-disabled': isDisabled
					})}
				>
					<Button type="text" onClick={isDisabled ? undefined : () => setIsEditable(true)}>
						{connection?.name}
						{Number.isInteger(connection?.id) && connection?.status !== FleetStatus.Draft && (
							<span className="delivery-catalog-connect-name-id">#{connection.id}</span>
						)}
						{!isDisabled && <Edit />}
					</Button>
				</div>
			)}
		</div>
	);
};

export default DeliveryCatalogConnectName;
