import { getRootEnv } from '@bringg-frontend/bringg-web-infra';
import { useEffect, useState } from 'react';

import { Skill } from '@bringg/types';
import { BringgDashboardSDK } from '@bringg/dashboard-sdk';
import { getEnv } from 'mobx-easy';
import { RootEnv } from '@bringg-frontend/bringg-web-infra';

function useSkills(): Skill[] {
	const dashboardSdk: BringgDashboardSDK = getRootEnv().dashboardSdk.sdk;

	const [skills, setSkills] = useState<Skill[]>([]);

	useEffect(() => {
		if (!dashboardSdk) {
			setSkills([]);
			return;
		}

		async function fetchSkills() {
			setSkills(await dashboardSdk.skills.getAll());
		}

		fetchSkills();
	}, [dashboardSdk]);

	return skills;
}

export default useSkills;
