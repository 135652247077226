import React, { PureComponent } from 'react';
import isNil from 'lodash/isNil';
import { Translate } from '../../../translation';
import moment from 'moment';

interface Props {
	text: string;
	className?: string;
}

export const SINGLE_BYTE_MESSAGE_LENGTH = 160;
export const DOUBLE_BYTE_MESSAGE_LENGTH = 70;

export const requiredAmountOfMessagesForText = text => {
	const isDoubleByteEncoded = !isNil(text.split('').find(char => char.charCodeAt(0) > 255));
	const charactersPerMessage = isDoubleByteEncoded ? DOUBLE_BYTE_MESSAGE_LENGTH : SINGLE_BYTE_MESSAGE_LENGTH;
	return Math.ceil(text.length / charactersPerMessage);
};

export default class NotificationTemplatePreview extends PureComponent<Props> {
	getMessagesCount = () => requiredAmountOfMessagesForText(this.props.text);

	getTimeInDay = () => {
		return moment().format('LT');
	};

	render() {
		const { text, className } = this.props;
		return (
			<div
				className={`notification-template-preview ${className || ''}`}
				data-test-id="notification-text-area-preview"
			>
				<div className="preview">
					<div className="preview-message">{text}</div>
					<div className="label">{this.getTimeInDay()}</div>
				</div>

				<div className="messages-counter">
					<span className="highlight">{text.length} </span>
					<Translate text="CUSTOMER_CONFIGURATION_VIEW.CHARACTERS" /> (
					<span className="highlight">{this.getMessagesCount()} </span>
					<Translate text="CUSTOMER_CONFIGURATION_VIEW.TEXT_MESSAGES" />)
				</div>
			</div>
		);
	}
}
