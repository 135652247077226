import { getRootEnv } from '@bringg-frontend/bringg-web-infra';
import { ServiceArea, TeamServiceArea } from '@bringg/types';
import { action, observable, computed, toJS, makeObservable } from 'mobx';
import { groupBy } from 'lodash';
import {
	CreateSpeedFactor,
	SpeedFactor,
	UpdateSpeedFactor,
	UpdateSpeedFactorSeries
} from '@bringg/dashboard-sdk/dist/SpeedFactor/Service/SpeedFactor.service';

import {
	ActionType,
	buildCreateSpeedFactorsOnDelete,
	buildCreateSpeedFactorsOnUpdate,
	CalendarEvent,
	buildCreateSpeedFactorPayload,
	validateFormData,
	validateOverlapping,
	buildCreateSpeedFactorsOnOverride,
	EventFormData
} from 'bringg-web/features/service-area/traffic-matrix/data-utils';

class ServiceAreasStore {
	serviceAreas: Map<number, ServiceArea> = new Map();
	teamsServiceAreas: Map<number, TeamServiceArea[]> = new Map();
	serviceAreasSpeedFactors: Map<number, SpeedFactor[]> = new Map();
	serviceAreasGroupByTeam: Map<number, TeamServiceArea[]> = new Map();

	isFetched = false;
	isTeamsFetched = false;

	constructor() {
		makeObservable(this, {
			serviceAreas: observable,
			teamsServiceAreas: observable,
			serviceAreasGroupByTeam: observable.ref,
			getAll: computed,
			fetchAll: action,
			forceFetchAll: action,
			fetchServiceArea: action,
			fetchTeamsServiceAreas: action,
			isFetched: observable,
			isTeamsFetched: observable,
			updateAllTeamsServiceAreas: action,
			create: action,
			update: action,
			delete: action,

			/* Speed Factors */
			serviceAreasSpeedFactors: observable.shallow,
			fetchSpeedFactors: action
		});
	}

	get getAll() {
		const all = Array.from(this.serviceAreas.values());

		return toJS(all);
	}

	async fetchAll() {
		return Promise.all([this.fetchServiceArea(), this.fetchTeamsServiceAreas()]);
	}

	async forceFetchAll() {
		this.isFetched = false;
		this.isTeamsFetched = false;
		return Promise.all([this.fetchServiceArea(), this.fetchTeamsServiceAreas()]);
	}

	async fetchServiceArea() {
		if (this.isFetched) {
			return;
		}

		const result = await getRootEnv().dashboardSdk.sdk.serviceAreas.getAll();

		this.serviceAreas = new Map(result.service_areas.map(sa => [sa.id, sa]));
		this.isFetched = true;
	}

	async fetchTeamsServiceAreas() {
		if (this.isTeamsFetched) {
			return;
		}

		const { teams_service_areas } = await getRootEnv().dashboardSdk.sdk.teamServiceAreas.getAll();

		this.serviceAreasGroupByTeam = new Map(
			Object.entries(groupBy(teams_service_areas, 'team_id')).map(([id, teamSA]) => [Number(id), teamSA])
		);

		this.teamsServiceAreas = new Map(
			Object.entries(groupBy(teams_service_areas, 'service_area_id')).map(([id, team]) => [Number(id), team])
		);

		this.isTeamsFetched = true;
	}

	async updateAllTeamsServiceAreas(serviceAreaId: number, teamServiceAreas: Partial<TeamServiceArea>[] = []) {
		const { teams_service_areas } = await getRootEnv().dashboardSdk.sdk.teamServiceAreas.updateAll({
			service_area_id: serviceAreaId,
			team_service_areas: teamServiceAreas as TeamServiceArea[]
		});

		this.serviceAreas.get(serviceAreaId).team_ids = teamServiceAreas.map(team => team.team_id);
		this.teamsServiceAreas.set(serviceAreaId, teams_service_areas);
		if (teamServiceAreas.length) {
			// we need to update team=>SA grouping for each selected team
			teamServiceAreas.forEach(teamServiceArea => {
				this.serviceAreasGroupByTeam.set(teamServiceArea.team_id, teams_service_areas);
			});
		} else {
			// in case teams was deleted we need to for through each team in serviceAreasGroupByTeam
			// and filter out serviceAreaId from teamCurrentServiceAreas
			this.serviceAreasGroupByTeam.forEach((teamCurrentServiceAreas, teamId) => {
				this.serviceAreasGroupByTeam.set(
					teamId,
					teamCurrentServiceAreas.filter(({ service_area_id }) => service_area_id !== serviceAreaId)
				);
			});
		}
		return teams_service_areas;
	}

	async create(serviceArea: Partial<ServiceArea>) {
		const { service_area } = await getRootEnv().dashboardSdk.sdk.serviceAreas.create({
			service_area: serviceArea
		});

		this.serviceAreas.set(service_area.id, service_area);
		return service_area;
	}

	async update(serviceArea: Partial<ServiceArea>) {
		const { service_area } = await getRootEnv().dashboardSdk.sdk.serviceAreas.update(serviceArea.id, {
			service_area: serviceArea
		});

		this.serviceAreas.set(service_area.id, service_area);
		return service_area;
	}

	async delete(id: number) {
		await getRootEnv().dashboardSdk.sdk.serviceAreas.delete(id);
		this.serviceAreas.delete(id);
	}

	/* Service Area Speed Factors*/

	async fetchSpeedFactors(serviceAreaId: number) {
		const result = await getRootEnv().dashboardSdk.sdk.speedFactor.list(serviceAreaId);

		if (result.success) {
			this.serviceAreasSpeedFactors.set(serviceAreaId, result.read);

			return result.read;
		}

		return [];
	}

	getSpeedFactors(serviceAreaId: number) {
		return this.serviceAreasSpeedFactors.get(serviceAreaId);
	}

	protected async createSpeedFactorAPI(payload: CreateSpeedFactor) {
		return getRootEnv().dashboardSdk.sdk.speedFactor.create(payload);
	}

	async createSpeedFactors(formData: EventFormData, speedFactors: SpeedFactor[], override: boolean) {
		const result = validateFormData(formData);
		if (!result.valid) throw new Error(result.message);

		const overlapsResult = validateOverlapping(formData, speedFactors);

		if (!overlapsResult.valid) {
			if (!override) throw new Error(result.message);

			await this.deleteOverlappedSpeedFactors(overlapsResult.speedFactors, formData);
		}

		const payloads = buildCreateSpeedFactorPayload(formData);
		const promises = payloads.map(this.createSpeedFactorAPI.bind(this));

		await Promise.all(promises);

		return { message: 'Event successfully created!' };
	}

	private async updateSpeedFactorAPI(payload: UpdateSpeedFactor) {
		return getRootEnv().dashboardSdk.sdk.speedFactor.update(payload);
	}

	private async updateSpeedFactorsAPI(payload: UpdateSpeedFactorSeries) {
		return getRootEnv().dashboardSdk.sdk.speedFactor.updateSeries(payload);
	}

	async updateSpeedFactor(
		formData: EventFormData,
		event: CalendarEvent,
		type: ActionType,
		speedFactors: SpeedFactor[],
		override: boolean
	) {
		const result = validateFormData(formData);
		if (!result.valid) throw new Error(result.message);

		const overlapsResult = validateOverlapping(formData, speedFactors, event, type);

		if (!overlapsResult.valid) {
			if (!override) throw new Error(result.message);

			await this.deleteOverlappedSpeedFactors(overlapsResult.speedFactors, formData, event, type);
		}

		const payloads = buildCreateSpeedFactorsOnUpdate(event, speedFactors, type, formData);
		const promises = payloads.map(this.createSpeedFactorAPI.bind(this));

		await this.deleteSpeedFactorsAPI(event.originalEvent);

		await Promise.all(promises);

		return { message: 'Events successfully updated!' };
	}

	private async deleteSpeedFactorAPI(speedFactor: SpeedFactor) {
		return getRootEnv().dashboardSdk.sdk.speedFactor.delete(speedFactor.id);
	}

	private async deleteSpeedFactorsAPI(speedFactor: SpeedFactor) {
		return getRootEnv().dashboardSdk.sdk.speedFactor.deleteSeries(speedFactor.original_uuid);
	}

	async deleteSpeedFactor(event: CalendarEvent, type: ActionType, events: SpeedFactor[]) {
		const response = { message: 'All events successfully deleted!' };

		const speedFactor = event.originalEvent;

		if (type === 'all') {
			await this.deleteSpeedFactorsAPI(speedFactor);
		} else if (type === 'current' || type === 'current_and_following') {
			await this.deleteSpeedFactorsAPI(speedFactor);

			const payloads = buildCreateSpeedFactorsOnDelete(event, events, type);
			const promises = payloads.map(this.createSpeedFactorAPI.bind(this));
			await Promise.all(promises);

			response.message = 'Event successfully deleted!';
		} else {
			throw Error('Not implemented');
		}

		return response;
	}

	private async deleteOverlappedSpeedFactors(
		speedFactors: SpeedFactor[],
		formData: EventFormData,
		event?: CalendarEvent,
		action?: ActionType
	) {
		for (const speedFactor of speedFactors) {
			// Delete speedfactor by id
			await this.deleteSpeedFactorAPI(speedFactor);

			// Create speedfactors with new ranges if needed
			const payloads = buildCreateSpeedFactorsOnOverride(speedFactor, formData, event, action);
			const promises = payloads.map(this.createSpeedFactorAPI.bind(this));
			await Promise.all(promises);
		}
	}
}

export default ServiceAreasStore;
