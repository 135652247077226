import React, { useCallback } from 'react';

import Dragger, { DraggerProps } from 'antd/lib/upload/Dragger';
import { UploadChangeParam } from 'antd/lib/upload';
import { Button } from '@bringg/react-components';

import { FileDropSVG } from 'bringg-web/components/bringg-dragger/file-drop-icon';
import { useDraggerTranslation } from './translations';

import styles from './bringg-dragger.module.scss';

export interface BringgDraggerProps extends DraggerProps {
	onFileUploaded: (file: File) => void;
	children?: JSX.Element;
}

interface IDummyRequest {
	onSuccess?: (body: any, xhr?: XMLHttpRequest) => void;
}

const dummyRequest = ({ onSuccess }: IDummyRequest) => {
	setTimeout(() => {
		onSuccess('ok');
	}, 0);
};

function DefaultDrop() {
	const translations = useDraggerTranslation();

	return (
		<div className={styles.bringgDragger}>
			<FileDropSVG />
			<small className={styles.description}>
				{translations.dropHere}{' '}
				<Button className={styles.button} type="link">
					{translations.browseFiles}
				</Button>
			</small>
		</div>
	);
}

function BringgDragger({ onFileUploaded, children, style = null, ...other }: BringgDraggerProps) {
	const fileChangedHandler = useCallback(
		(uploadResponse: UploadChangeParam) => {
			if (uploadResponse.file.status === 'done') {
				onFileUploaded(uploadResponse.file.originFileObj);
			}
		},
		[onFileUploaded]
	);

	return (
		<Dragger
			maxCount={1}
			onChange={fileChangedHandler}
			customRequest={dummyRequest}
			style={{ display: 'flex', alignItems: 'center', ...style }}
			{...other}
		>
			{children ? children : <DefaultDrop />}
		</Dragger>
	);
}

export default BringgDragger;
