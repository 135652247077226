import React from 'react';

import { Tooltip, Button } from '@bringg/react-components';
import { BringgIcon, BringgFontIcons } from '@bringg/bringg-icons';

import useAutomationTranslations from '../utils/use-automation-translations';

interface Props {
	showMore: boolean;
	setShowMore: (showMoreOrCb: boolean | ((prev: boolean) => boolean)) => void;
	disabled?: boolean;
}

const ShowMoreButton = ({ showMore, setShowMore, disabled }: Props) => {
	const { showMore: showMoreTranslation, showLess } = useAutomationTranslations();
	return (
		<Tooltip title={showMore ? showLess : showMoreTranslation}>
			<Button
				shape="circle"
				type="link"
				icon={<BringgIcon iconName={showMore ? BringgFontIcons.ShowLess : BringgFontIcons.ShowMore} />}
				onClick={e => {
					e.stopPropagation();
					setShowMore(prev => !prev);
				}}
				disabled={disabled}
				data-test-id="show-more-button"
			/>
		</Tooltip>
	);
};

export default ShowMoreButton;
