import { omit } from 'lodash';
import { Currency, FactType, RateCardRateBase } from '@bringg/types';
import { getRandomGuid } from '@bringg/react-components/dist/features/conditions/rule/rule-utils';
import type { RateCardTerm, RateCardTermType, AllConditions } from '@bringg/types';
import type { ClientRateCardTerm } from '@bringg/react-components';

import { mapConditionsFromServer, mapConditionToServer } from '../../rule-engine/utils/mapper';

export const mapRateTermsFromServer = (terms: RateCardTerm[], metadata): ClientRateCardTerm[] =>
	terms.map(term => {
		const allConditions = (term.rule_definition.conditions as AllConditions).all || [];
		return {
			guid: getRandomGuid(),
			conditions: mapConditionsFromServer(allConditions, metadata),
			...omit(term, ['rule_definition'])
		};
	});

export const mapRateTermsToServer = (clientTerms: ClientRateCardTerm[]) =>
	clientTerms.map<Partial<RateCardTerm>>(term => {
		const { conditions: clientConditions, ...rest } = term;
		delete rest.guid;

		if (!clientConditions) {
			return rest;
		}

		return {
			...rest,
			rule_definition: {
				conditions: {
					all: clientConditions.map(condition => mapConditionToServer(condition, FactType.Rate))
				}
			}
		};
	});

export const generateNewRateTerm = (type: RateCardTermType, currency: Currency): ClientRateCardTerm => ({
	type,
	active: true,
	// Seems ok to share guid generator across rules, if not can easily implement it separately for RateTerms
	guid: getRandomGuid(),
	rate_base: RateCardRateBase.Flat,
	rate_amount: 1,
	rate_currency: currency,
	conditions: [],
	// Can use translations here if needed
	term_title: 'Untitled Rate Card',
	rate_title: 'Rate Title'
});
