import { Translations } from '@bringg/react-components/dist/features/fleet-router/rule-form/rule-form';
import { useTranslation } from 'react-i18next';

export const useFleetsConfigurationTranslations = () => {
	const { t } = useTranslation();

	const ruleFormTranslations: Translations = {
		name: t('FLEETS.NAME'),
		type: t('FLEETS.TYPE'),
		editRule: t('FLEETS.EDIT_RULE'),
		fallbackHeader: t('FLEETS.FALLBACK'),
		fallbackTooltip: t('FLEETS.FALLBACK_TOOLTIP_TEXT'),
		teamSectionTooltip: t('FLEETS.INFO_TOOLTIP_TEXT'),
		setToSpecificTeams: t('FLEETS.SET_TO_SPECIFIC_TEAMS'),
		noSelectedFleets: t('FLEETS.NO_SELECTED_FLEETS'),
		noMoreFleetsToAdd: t('FLEETS.NO_MORE_FLEETS_TO_ADD'),
		addFleet: t('FLEETS.ADD_FLEET'),
		showSelected: t('FLEETS.SHOW_SELECTED'),
		teamSectionDescription: t('FLEETS.HERE_YOU_CAN_PICK_THE_TEAMS_TO_BE_AFFECTED'),
		searchPlaceholder: t('FLEETS.SEARCH'),
		teamSectionTitle: t('FLEETS.APPLY_TO_TEAMS'),
		cancel: t('FLEETS.CANCEL'),
		apply: t('FLEETS.APPLY'),
		remove: t('FLEETS.REMOVE'),
		cheap: t('FLEETS.CHEAP'),
		fast: t('FLEETS.FAST'),
		selectAll: t('FLEETS.SELECT_ALL'),
		priority: t('FLEETS.PRIORITY'),
		disabledTeamTranslation: t('FLEETS.TEAM_ALREADY_ASSIGNED'),
		teamsAssignedToRules: t('FLEETS.TEAMS_ASSIGNED_TO_RULE'),
		readOnlyModeMessage: t('FLEETS.FLEET_ROUTER_ACCESS_ADMIN_ONLY'),
		internalFleetName: t('ASSIGN_FLEET.INTERNAL_FLEET_TEXT')
	};

	const fleetsListTranslations = {
		merchantLevel: t('FLEETS.MERCHANT_LEVEL'),
		more: t('FLEETS.MORE'),
		teams: t('FLEETS.TEAMS'),
		add: t('FLEETS.ADD'),
		header: t('FLEETS.RULE'),
		filterTeams: t('FLEETS.FILTER_TEAMS'),
		internalFleet: t('ASSIGN_FLEET.INTERNAL_FLEET_TEXT')
	};

	const validationTranslations = {
		nameRequired: t('FLEETS.NAME_REQUIRED'),
		fleetsRequired: t('FLEETS.FLEETS_REQUIRED'),
		duplicateMerchantLeventEnabled: t('FLEETS.ONLY_ONE_MERCHANT_LEVEL_RULE_ENABLED')
	};

	const headerTranslation = t('FLEETS.FLEET_ROUTING');

	const tabsTranslation = {
		conditionEngine: t('FLEETS.CONDITION_ENGINE'),
		routeSettings: t('FLEETS.ROUTE_SETTINGS'),
		deliveryCatalog: t('FLEETS.DISCOVER_DELIVERY_PROVIDERS')
	};

	const networkError = t('FLEETS.FAILED_REACHING_SERVER');

	const baseDisableRulesModal = {
		title: t('FLEETS.ERROR_CONFLICT_BETWEEN_RULES_TITLE'),
		rule: t('FLEETS.RULE'),
		cancel: t('FLEETS.CANCEL'),
		disableRules: t('FLEETS.DISABLE_RULES'),
		removeTeams: t('FLEETS.REMOVE_TEAMS')
	};

	const disableRulesModalTeamsLevel = {
		...baseDisableRulesModal,
		description: t('FLEETS.ERROR_CONFLICT_BETWEEN_RULES_TEAM_LEVEL_DESCRIPTION')
	};

	const disableRulesModalMerchantLevel = {
		...baseDisableRulesModal,
		description: t('FLEETS.ERROR_CONFLICT_BETWEEN_RULES_MERCHANT_LEVEL_DESCRIPTION')
	};

	const removeTeamsModal = {
		removeTeams: t('FLEETS.REMOVE_TEAMS'),
		cancel: t('FLEETS.CANCEL'),
		teamsAssignedToRules: t('FLEETS.TEAMS_ASSIGNED_TO_RULE')
	};

	const mainScreenTranslations = {
		addRule: t('FLEETS.ADD_RULE'),
		description: t('FLEETS.DESCRIPTION'),
		header: t('FLEETS.HEADER_TEXT')
	};

	return {
		ruleFormTranslations,
		fleetsListTranslations,
		networkError,
		validationTranslations,
		disableRulesModalTeamsLevel,
		disableRulesModalMerchantLevel,
		removeTeamsModal,
		tabsTranslation,
		mainScreenTranslations,
		headerTranslation
	};
};
