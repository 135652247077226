import { useCallback, useState } from 'react';
import { TableState } from 'react-table';
import type { TemplateType } from '@bringg/types';

import { getTableAndColumnsWidthDiff } from 'bringg-web/components/bringg-react-table/utils';
import { columnIds } from '../consts';
import type { UseTableFilters } from './use-table-filters';

export const useControlledTableState = (
	filters: UseTableFilters['filters'],
	isVisible: boolean,
	isLoading: boolean,
	tableContainerRef,
	columns
) => {
	const [minTableWidthInited, setMinTableWidthInited] = useState(false);

	return useCallback(
		(state: TableState<TemplateType>) => {
			const nextState: TableState<TemplateType> = { ...state };
			const { searchText } = filters;

			nextState.filters = [];

			if (searchText?.trim()) {
				const value = searchText.trim();
				nextState.filters.push({ id: columnIds.NAME, value });
			}

			if (!minTableWidthInited && tableContainerRef && columns && isVisible && !isLoading) {
				const mappedColumns = columns.map(({ id, width, disableResizing }) => {
					return {
						id,
						width: width || 150,
						disableResizing
					};
				});

				const diff = getTableAndColumnsWidthDiff(mappedColumns, tableContainerRef.current.clientWidth);

				if (diff > 0) {
					const adjustableColumns = mappedColumns.filter(({ disableResizing }) => !disableResizing);
					const additionalWidthPerColumn = Math.floor(diff / adjustableColumns.length);

					const nextColumnWidths = adjustableColumns
						.map(item => ({ ...item, width: (item.width as number) + additionalWidthPerColumn }))
						.reduce((acc, { id, width }) => Object.assign(acc, { [id]: width }), {});

					nextState.columnResizing.columnWidths = nextColumnWidths;
				}

				setMinTableWidthInited(true);
			}

			return nextState;
		},
		[filters, minTableWidthInited, isVisible, isLoading, tableContainerRef, columns]
	);
};
