import React from 'react';

export const EcoFilled = () => {
	return (
		<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<g id="Component 24">
				<g id="Group 8072">
					<circle id="Ellipse 1313" cx="12" cy="12" r="11" fill="#00983A" stroke="#00983A" strokeWidth="2" />
					<g id="Group 8005">
						<path
							id="Path 33080"
							d="M14.0316 17.7541C14.0316 17.7541 8.3976 19.7891 7.49874 12.1597C7.49874 12.1597 13.1687 12.2783 14.4199 17.0099C13.2083 17.2076 10.0802 14.7016 9.80699 14.3133C9.84654 14.2558 10.3535 16.1326 14.0316 17.7541Z"
							fill="white"
						/>
						<g id="Group 7994">
							<path
								id="Path 33081"
								d="M10.0804 12.0259C9.96177 10.5374 10.5119 8.54196 11.9177 6C11.9177 6 17.1598 9.23588 16.5917 13.5863C16.4267 14.6291 16.0109 15.6162 15.3801 16.4627C13.9707 15.1324 12.4067 10.674 12.3276 8.87634C12.1191 10.4541 12.2665 12.0586 12.759 13.572C11.9535 12.9182 11.0494 12.3964 10.0804 12.0259V12.0259Z"
								fill="white"
							/>
						</g>
					</g>
				</g>
			</g>
		</svg>
	);
};
