import React from 'react';
import { ActionsConfigurationsPortal } from '../actions-configurations';
import { TaskRejectReasonsConfigPortal } from '../task-reject-reasons-config/task-reject-reasons-config-portal';

const DriverConfigurationsPortals: React.FC = () => {
	return (
		<>
			<ActionsConfigurationsPortal />
			<TaskRejectReasonsConfigPortal />
		</>
	);
};

export default DriverConfigurationsPortals;
