import { action, computed, makeObservable, observable } from 'mobx';
import i18next from 'i18next';
import {
	ActionType,
	AttributeDatatype,
	ChangeType,
	EntityDataType,
	EventTriggerTasksManualParams,
	ManualTriggerInputParam,
	ManualTriggerParamDataType,
	MathOperator,
	PrimitiveManualTriggerParamDataType,
	ReferredManualTriggerParamDataType,
	SingleUpdateEntityConfiguration,
	TimeTriggerUnitOfTime,
	UpdateEntityConfigurationData,
	UpdateEntityData,
	UpdateFieldMetadata,
	UpdateRelativeValueData,
	UpdateValueTypes
} from '@bringg/types';

import { getRandomGuid } from '../utils/helpers';
import { UpdateFieldActionData } from '../utils/types';
import { TriggerStore, UpdateEntityActionRepo } from './internal';

const initialRoundToOption = (actionValue: number, roundTo?: number) =>
	actionValue && !roundTo ? false : roundTo ? true : null;

class UpdateEntityActionStore {
	identifier: string;
	UpdateEntityActionStore;
	guid: number;
	parent: UpdateEntityActionRepo;
	value: UpdateValueTypes;
	unitsOfTime: TimeTriggerUnitOfTime;
	changeType: ChangeType;
	relativeToIdentifier: string;
	operator: MathOperator;
	action_type: ActionType;
	triggerStore: TriggerStore;
	roundTo: number;
	roundToOption: boolean = null;

	constructor(parent: UpdateEntityActionRepo, data: Partial<SingleUpdateEntityConfiguration>) {
		this.guid = getRandomGuid();
		this.parent = parent;
		this.action_type = ActionType.ENTITY_UPDATE;

		makeObservable(this, {
			identifier: observable,
			value: observable,
			unitsOfTime: observable,
			changeType: observable,
			relativeToIdentifier: observable,
			operator: observable,
			roundTo: observable,
			roundToOption: observable,
			actionData: computed,
			mappedValue: computed,
			relativeIdentifiers: computed,
			relativeDisplay: computed,
			isValid: computed,
			setValue: action,
			setChangeType: action,
			setOperator: action,
			setRelativeToIdentifier: action,
			reset: action,
			setRoundTo: action,
			setRoundToOption: action,
			dynamicVariablesByActionType: computed
		});
		this.triggerStore = parent.triggerStore;
		this.identifier = data.identifier;

		if (!data.data) {
			return;
		}

		const configurationData = data.data;
		this.changeType = configurationData.type;
		this.unitsOfTime = (configurationData as UpdateRelativeValueData).date_unit;
		this.roundTo = (configurationData as UpdateRelativeValueData).round_to;

		if (isStatic(configurationData)) {
			switch (configurationData.type) {
				case ChangeType.RELATIVE_VALUE:
					this.handleRelative(configurationData, configurationData.delta);
					break;
				case ChangeType.SPECIFIC_VALUE:
					this.value = configurationData.value;
					break;
				default:
					throw new Error('The change type not exist in static');
			}
		} else {
			switch (configurationData.type) {
				case ChangeType.RELATIVE_VALUE:
					this.handleRelative(configurationData, configurationData.name);
					break;
				case ChangeType.SPECIFIC_VALUE:
					this.value = configurationData.name;
					break;
				default:
					throw new Error('The change type not exist in dynamic');
			}
		}

		this.roundToOption = initialRoundToOption(this.getValueNumber, this.roundTo);
	}

	private handleRelative(
		configurationData: { operator?: MathOperator; relative_to_identifier: UpdateFieldMetadata['identifier'] },
		value: number | string
	) {
		this.operator = configurationData.operator;
		this.relativeToIdentifier = configurationData.relative_to_identifier;
		this.value = value;
	}

	get actionData(): UpdateFieldActionData | undefined {
		return this.parent.updateItemsByFact?.find(
			a =>
				a.identifier === this.identifier &&
				a.supported_display_fact?.includes(this.parent.actionsRepo.displayFact)
		);
	}

	get displayPath() {
		return this.actionData?.display_path;
	}

	setIdentifier = id => {
		this.identifier = id;
	};

	setValue = value => {
		this.value = value;
	};

	setUnitOfTime = (units: TimeTriggerUnitOfTime) => {
		this.unitsOfTime = units;
	};

	setChangeType = change => {
		this.changeType = change;
	};

	setRelativeToIdentifier = (identifier: string) => {
		this.relativeToIdentifier = identifier;
	};

	setOperator = operator => {
		this.operator = operator;
	};

	setRoundTo = (roundTo: number | undefined) => {
		this.roundTo = roundTo;
	};

	setRoundToOption = (roundToOption: boolean) => {
		this.roundToOption = roundToOption;
	};

	reset = () => {
		this.value = null;
		this.changeType = null;
		this.relativeToIdentifier = null;
		this.operator = null;
		this.unitsOfTime = null;
		this.roundTo = null;
		this.roundToOption = null;
	};

	get mappedValue() {
		const data = this.actionData;
		if (!data) {
			return this.value;
		}

		if (data.data_type === AttributeDatatype.Array) {
			return ((this.value as string[]) || []).map(val => data.values.find(v => v.key === val)?.value);
		}

		if (data.values?.length) {
			const dynamicField = this.dynamicVariables?.find(val => val.name === this.value)?.name;
			return dynamicField || data.values.find(val => val.key === this.value)?.value;
		}

		if (data.data_type === AttributeDatatype.Boolean) {
			const dynamicField = this.dynamicVariables?.find(val => val.name === this.value)?.name;
			if (!dynamicField) {
				return i18next.t(this.value === 1 ? 'PROGRAMMABLE_TRIGGERS.TRUE' : 'PROGRAMMABLE_TRIGGERS.FALSE');
			}
			return dynamicField;
		}

		return this.value;
	}

	get relativeIdentifiers() {
		return this.actionData?.relative_to_identifiers?.map(item => ({
			id: item.identifier,
			name: i18next.t(item.title),
			dropDownKey: `relative-${item.identifier}`
		}));
	}

	get dynamicVariables(): { name: string; dropDownKey: ManualTriggerParamDataType }[] {
		if (!this.triggerStore?.dynamicVariables) {
			return [];
		}
		return this.triggerStore?.dynamicVariables?.map(item => ({
			name: item.name,
			dropDownKey: item.data_type
		}));
	}

	get relativeDisplay(): string {
		if (!this.relativeToIdentifier) return '';
		return this.relativeIdentifiers.find(item => item.id === this.relativeToIdentifier).name;
	}

	private isValueValid(): boolean {
		const data = this.actionData;

		if (!data) {
			return false;
		}

		if (data.data_type === AttributeDatatype.Array) {
			return (this.value as string[])?.length > 0;
		}

		if (data.values?.length) {
			return (
				data.values.some(val => val.key === this.value) ||
				this.dynamicVariables?.some(
					field => field.dropDownKey === ReferredManualTriggerParamDataType.CUSTOM_ATTRIBUTE
				)
			);
		}

		if (data.data_type === AttributeDatatype.Boolean) {
			return (
				this.value === 1 ||
				this.value === 0 ||
				this.dynamicVariables?.some(field => field.dropDownKey === PrimitiveManualTriggerParamDataType.BOOLEAN)
			);
		}

		return !!this.value;
	}

	private isRelativeValid(): boolean {
		if (this.changeType === ChangeType.SPECIFIC_VALUE) {
			return true;
		}

		const hasRelativeParams = !!(this.relativeToIdentifier && this.operator);

		if (this.actionData.data_type === AttributeDatatype.Datetime) {
			const hasRoundToOptionSelected =
				this.roundToOption !== null && (this.roundToOption ? !!this.roundTo : true);

			return hasRelativeParams && !!this.unitsOfTime && hasRoundToOptionSelected;
		}

		return hasRelativeParams;
	}

	get isValid(): boolean {
		if (!this.identifier || !this.isValueValid()) {
			return false;
		}

		const isRelative = this.actionData.relative_to_identifiers?.length > 0;
		if (isRelative) {
			return this.isRelativeValid();
		}

		return true;
	}

	get getValueNumber(): number {
		if (this.triggerStore?.dynamicVariables.some(key => key.name === this.value)) {
			return null;
		}
		return this.value as number;
	}

	get dynamicVariablesByActionType(): ManualTriggerInputParam[] {
		return (
			(this.triggerStore as EventTriggerTasksManualParams)?.value?.vars?.filter(param => {
				const isCustomAttributeDataType =
					param.data_type === ReferredManualTriggerParamDataType.CUSTOM_ATTRIBUTE &&
					this.actionData.identifier === `$CALCULATED_CA.${param.ref_id}`;

				if (isCustomAttributeDataType) {
					return true;
				}

				switch (this.actionData.data_type) {
					case AttributeDatatype.Number:
						return param.data_type === PrimitiveManualTriggerParamDataType.NUMBER;
					case AttributeDatatype.String:
						if (this.actionData.entity_data_type === EntityDataType.Driver) {
							return param.data_type === PrimitiveManualTriggerParamDataType.DRIVER;
						}
						return param.data_type === PrimitiveManualTriggerParamDataType.STRING;
					case AttributeDatatype.Datetime:
						return (
							param.data_type === PrimitiveManualTriggerParamDataType.DATE ||
							param.data_type === PrimitiveManualTriggerParamDataType.TIME ||
							param.data_type === PrimitiveManualTriggerParamDataType.DATETIME
						);
					default:
						return false;
				}
			}) || []
		);
	}
}

export default UpdateEntityActionStore;

function isStatic(configurationData: UpdateEntityConfigurationData): configurationData is UpdateEntityData {
	return configurationData['value'] || configurationData['delta'];
}
