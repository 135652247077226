import { action, computed, makeObservable, observable } from 'mobx';
import { taskRejectReasonsRoot } from './data-providers/task-reject-reasons-root';

export type TaskRejectReason = {
	id: number;
	reason: string;
};
export const emptyTaskRejectReason: TaskRejectReason = {
	reason: '',
	id: null
};

export enum LoadingStatus {
	saving = 'saving',
	deleting = 'deleting'
}

class TaskRejectReasonStore {
	id: number;
	reason: string;
	loadingStatus: LoadingStatus;

	public original: TaskRejectReason;

	constructor(taskRejectReason: TaskRejectReason = emptyTaskRejectReason) {
		makeObservable(this, {
			id: observable,
			reason: observable,
			loadingStatus: observable,
			update: action,
			setReason: action,
			init: action,
			create: action,
			setLoading: action,
			isSaving: computed,
			isDeleting: computed,
			isLoading: computed
		});

		this.original = taskRejectReason;

		this.init();
	}

	init = () => {
		this.update({ ...this.original });
	};

	update(taskRejectReason: Partial<TaskRejectReason>) {
		Object.assign(this, taskRejectReason);
	}

	setReason = (reason: string) => {
		this.reason = reason;
	};

	setLoading = (status: LoadingStatus) => {
		this.loadingStatus = status;
	};

	get isSaving() {
		return this.loadingStatus === LoadingStatus.saving;
	}

	get isDeleting() {
		return this.loadingStatus === LoadingStatus.deleting;
	}

	get isLoading() {
		return !!this.loadingStatus;
	}

	updateTaskRejectReason = async (propsToUpdate: { reason: string }) => {
		this.setLoading(LoadingStatus.saving);
		try {
			if (!propsToUpdate.reason) {
				throw Error('Invalid reason');
			}

			if (propsToUpdate.reason !== this.original.reason) {
				const response = await taskRejectReasonsRoot
					.getStore()
					.taskRejectReasonRepo.patch(this.id, { ...propsToUpdate });
				const { reason } = response;

				this.update({ reason });
			}

			return {
				success: true
			};
		} catch (err) {
			const { reason } = this.original;
			this.update({ reason });
		} finally {
			this.setLoading(null);
		}

		return {
			success: false
		};
	};

	create = async (): Promise<{ success: boolean }> => {
		this.setLoading(LoadingStatus.saving);
		try {
			const response = await taskRejectReasonsRoot
				.getStore()
				.taskRejectReasonRepo.create({ reason: this.reason });
			this.id = response.id;

			return {
				success: true
			};
		} catch (err) {
			console.error('failed to create taskRejectReason', err);
		} finally {
			this.setLoading(null);
		}
		return {
			success: false
		};
	};

	delete = async (): Promise<{ success: boolean }> => {
		this.setLoading(LoadingStatus.deleting);
		try {
			await taskRejectReasonsRoot.getStore().taskRejectReasonRepo.delete(this.id);
			return {
				success: true
			};
		} catch (err) {
			console.error('failed to delete taskRejectReason', err);
		} finally {
			this.setLoading(null);
		}
		return {
			success: false
		};
	};
}

export default TaskRejectReasonStore;
