import moment from 'moment';
import { HourFormat } from '@bringg/types';
import { getSharedRootStore } from '@bringg-frontend/global-stores';

export const useDateTimeFormat = () => {
	return `${moment.localeData().longDateFormat('L')}, ${getTimeFormat()}`;
};

export const getTimeFormat = () => {
	switch (getHourFormat()) {
		case HourFormat.TwelveHours:
			return 'hh:mm A';
		case HourFormat.TwentyFourHours:
			return 'HH:mm';
		default:
			return moment.localeData().longDateFormat('LT');
	}
};

export const getHourFormat = () => {
	return getSharedRootStore().data.merchantConfigurationsStore.hourFormat;
};
