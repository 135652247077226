import React, { useMemo, useState } from 'react';
import { ButtonProps, Form, Input } from 'antd';
import { useTranslation } from 'react-i18next';
import { Modal } from '@bringg/react-components';
import { useStores } from '../../recipes';
import './delivery-catalog.scss';

type Rule = React.ComponentProps<typeof Form.Item>['rules'][number];

const DeliveryCatalogContactUsModal = ({ name, onSend, onClose }) => {
	const [isFinished, setIsFinished] = useState(false);
	const [form] = Form.useForm();
	const { t } = useTranslation();

	const {
		usersStore: { currentUser }
	} = useStores();

	const initialValues = useMemo(() => {
		return {
			title: name,
			text: `${t('DELIVERY_PROVIDER_CATALOG.EMAIL.BODY')} ${name}`,
			from: currentUser.email
		};
	}, [name, currentUser]);

	const handleOk = () => {
		form.validateFields().then(params => {
			onSend(params);
			setIsFinished(true);
			form.resetFields();
		});
	};

	const handleFinish = () => {
		onClose();
		setIsFinished(false);
	};

	const cancelButtonProps: ButtonProps = useMemo(() => ({ type: 'link' }), []);
	const emailInputRules: Rule = useMemo(() => ({ required: true, type: 'email', message: 'Email is required' }), []);

	if (isFinished) {
		return (
			<Modal
				visible
				title={t('DELIVERY_PROVIDER_CATALOG.EMAIL.TITLE')}
				onOk={handleFinish}
				onCancel={onClose}
				okText="Continue"
				cancelButtonProps={{ style: { display: 'none' } }}
				closable={false}
			>
				{t('DELIVERY_PROVIDER_CATALOG.EMAIL.RESPONSE')}
			</Modal>
		);
	}
	return (
		<Modal
			visible
			width={430}
			title={t('DELIVERY_PROVIDER_CATALOG.EMAIL.TITLE_2')}
			okText="Send"
			cancelButtonProps={cancelButtonProps}
			onOk={handleOk}
			onCancel={onClose}
			closable={false}
			className="delivery-catalog-email-modal"
		>
			<Form form={form} layout="vertical" initialValues={initialValues}>
				<Form.Item name="title">
					<Input disabled />
				</Form.Item>

				<Form.Item name="text" label={t('DELIVERY_PROVIDER_CATALOG.EMAIL.BECAUSE')}>
					<Input.TextArea rows={4} />
				</Form.Item>

				<Form.Item
					className="email-input"
					name="from"
					label={t('DELIVERY_PROVIDER_CATALOG.EMAIL.REACH_ME_AT')}
					rules={[emailInputRules]}
				>
					<Input />
				</Form.Item>
				<span className="email-description">{t('DELIVERY_PROVIDER_CATALOG.EMAIL.DESCRIPTION')}</span>
			</Form>
		</Modal>
	);
};

export default DeliveryCatalogContactUsModal;
