import { Location, TaskStatus, User, UserStatus, WayPoint } from '@bringg/types';
import { isEmpty as _isEmpty } from 'lodash';
import { Translations as MapLegendTranslations } from '../map-legend/map-legend';
import { TFunction } from 'i18next';

type Task = Bringg.Task & { activeWayPoint?: WayPoint };
export type StatusColors = { [key: number]: { color: string; name: string } };

export const extractLocationFromTask = (task: Task): Location => {
	let locationObject: Task | WayPoint = { lat: null, lng: null, merchant_id: null };

	if (task.lat && task.lng) {
		locationObject = task;
	} else if (task.activeWayPoint) {
		locationObject = task.activeWayPoint;
	} else if (!_isEmpty(task.way_points)) {
		locationObject = task.way_points[0];
	}

	const { lat, lng } = locationObject;
	return { lat, lng };
};

export const STATUS_COLORS = {
	[UserStatus.FREE]: '#000',
	[UserStatus.ASSIGNED]: '#000',
	accepted: '#000',
	[UserStatus.CHECKED_IN]: '#658cd2',
	[UserStatus.ON_THE_WAY]: '#658cd2',
	[UserStatus.LATE]: '#e65963',
	offline: '#ccc'
};

export const commonColors = [
	'#3cb247',
	'#e99b6f',
	'#d3bf53',
	'#35cf70',
	'#bc428e',
	'#5c7cb9',
	'#41a561',
	'#d9422f',
	'#d83145',
	'#41a889',
	'#326a38',
	'#b1699c',
	'#a089e7',
	'#6786e2',
	'#4883ef',
	'#805f27',
	'#78aa1f',
	'#714d94',
	'#a52d68',
	'#f08db5',
	'#bea5ea',
	'#3295e9',
	'#8c9252',
	'#6ece5c',
	'#44b6b7',
	'#c18e22',
	'#ddb026',
	'#e26cb2',
	'#d6216b',
	'#d3ad72',
	'#b6c142',
	'#853dab',
	'#b4bd78',
	'#ac379f',
	'#829fdd',
	'#416098',
	'#e03fa6',
	'#ec6568',
	'#5370ed',
	'#bf6acd',
	'#3ecd8f',
	'#42ceaf',
	'#ac3a18',
	'#f64085',
	'#bdbc21',
	'#d795b8',
	'#b93d3f',
	'#576524',
	'#ca6b5d',
	'#eb9519',
	'#6ac884',
	'#947422',
	'#a05db0',
	'#8b73b9',
	'#ba5f2c',
	'#d02f56',
	'#8d4a78',
	'#6f8623',
	'#4e59bd',
	'#e78e8e',
	'#8e60da',
	'#c6607b',
	'#9b573e',
	'#d191cd',
	'#bb9845',
	'#a2c635',
	'#b07947',
	'#dc8de7',
	'#517f33',
	'#e5682b',
	'#348ebf',
	'#a3394a',
	'#435ca6',
	'#ea6a8c',
	'#389225',
	'#b39420',
	'#7a781f',
	'#974d5e',
	'#2baad3',
	'#b8721d',
	'#d56ae0',
	'#4fd256',
	'#79ac3d',
	'#d8487a',
	'#61b928',
	'#f0b255',
	'#2a775f',
	'#65a64b',
	'#a2b356',
	'#974327',
	'#88b86c',
	'#a3a02f',
	'#3e8c5e',
	'#3dd0e2',
	'#e28c3b',
	'#91cf65',
	'#7ec095',
	'#f27c5e',
	'#2d7a2c'
];

export const getTaskStatusColors = (t: TFunction): StatusColors => ({
	[TaskStatus.Created]: { color: commonColors[0], name: t('UTILS.ORDER_STATUS_NOT_ASSIGNED') },
	[TaskStatus.Assigned]: { color: commonColors[1], name: t('UTILS.ORDER_STATUS_ASSIGNED') },
	[TaskStatus.OnTheWay]: { color: commonColors[2], name: t('UTILS.ORDER_STATUS_ON_THE_WAY') },
	[TaskStatus.CheckedIn]: { color: commonColors[3], name: t('UTILS.ORDER_STATUS_CHECKED_IN') },
	[TaskStatus.Done]: { color: commonColors[4], name: t('UTILS.ORDER_STATUS_DONE') },
	[TaskStatus.Late]: { color: commonColors[5], name: t('UTILS.ORDER_STATUS_LATE') },
	[TaskStatus.Accepted]: { color: commonColors[6], name: t('UTILS.ORDER_STATUS_ACCEPTED') },
	[TaskStatus.Cancelled]: { color: commonColors[7], name: t('UTILS.ORDER_STATUS_CANCELLED') },
	[TaskStatus.Rejected]: { color: commonColors[8], name: t('UTILS.ORDER_STATUS_REJECTED') },
	[TaskStatus.Unacknowledged]: { color: commonColors[9], name: t('UTILS.ORDER_STATUS_UNACKNOWLEDGED') },
	[TaskStatus.Pending]: { color: commonColors[10], name: t('UTILS.ORDER_STATUS_UNKNOWN') }
});

export const getDriverMarkerColor = (driver: User): string => {
	if (driver.status === 'online') {
		if (driver.sub) {
			return STATUS_COLORS[driver.sub];
		}
	}

	return STATUS_COLORS[driver.status] || STATUS_COLORS.accepted;
};

export const getMapLegendTranslations = (t: TFunction): MapLegendTranslations => ({
	default: t('DISPATCH_LIST.LEGEND_BY_DEFAULT'),
	orderStatus: t('DISPATCH_LIST.LEGEND_BY_ORDER_STATUS'),
	driver: t('DISPATCH_LIST.LEGEND_BY_DRIVER'),
	stopNumber: t('DISPATCH_LIST.LEGEND_BY_STOP_NUMBER'),
	tag: t('DISPATCH_LIST.LEGEND_BY_TAG')
});
