import React, { Ref, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { getRootEnv } from '@bringg-frontend/bringg-web-infra';
import { Link, useParams } from 'react-router-dom';
import moment from 'moment';
import { isNaN, last, sortBy } from 'lodash';
import { BringgFontIcons, BringgIcon } from '@bringg/bringg-icons';
import { LocationEvent, Run, RunHistoryEvent, Task, Team, User } from '@bringg/types';
import classNames from 'classnames';
import {
	BreadcrumbItemExtendedProps,
	BreadCrumbs,
	Col,
	Divider,
	DriverCard,
	Row,
	Spacing,
	Spinner
} from '@bringg/react-components';
import { observer } from 'mobx-react';

import { WayPointLocation } from 'bringg-web/types/common.consts';
import { RunDetails, RunHistory, RunMap, RunPlayer, RunTable } from '..';
import sortWaypointsByPosition from '../utils/sortWaypointsByPosition';
import sortRunTasksByLastWaypointEta from '../utils/sortRunTasksByLastWaypointEta';
import { useStores } from '../../../recipes';
import { Translate } from '../../../translation';

enum MapLoadingStatus {
	LOADING = 'LOADING',
	NO_DATA = 'NO_DATA',
	DATA_LOADED = 'DATA_LOADED'
}

type RunHistoryEventsContainerProps = {
	runHistoryEvents: RunHistoryEvent[];
};

const RunHistoryEventsContainer = observer(({ runHistoryEvents }: RunHistoryEventsContainerProps) => (
	<>
		{runHistoryEvents ? (
			<div className="run-history">
				<RunHistory runHistories={runHistoryEvents} />
			</div>
		) : (
			<div className={classNames('run-history-loading')}>
				<Spinner className="run-history-spinner" size="large" />
			</div>
		)}
	</>
));

type RunDriverCardContainerProps = {
	isRunLoaded: boolean;
	driver: User;
	team: Team;
};

const RunDriverCardContainer = ({ isRunLoaded, driver, team }: RunDriverCardContainerProps) => {
	const nameLink = <>{!!driver && <Link to={`/drivers/${driver.id}`}>{driver.name}</Link>}</>;
	const iconLink = (
		<>
			{!!driver && (
				<Link to={`/drivers/${driver.id}`}>
					<BringgIcon iconName={BringgFontIcons.OpenInNew} />
				</Link>
			)}
		</>
	);
	const driverTeamLoaded = !!driver && !!team;
	return (
		<div className={classNames({ 'run-driver-loading': !driverTeamLoaded })}>
			{isRunLoaded ? (
				<div className="run-driver">
					{driverTeamLoaded ? (
						<DriverCard nameLink={nameLink} iconLink={iconLink} team={team} driver={driver} />
					) : (
						<Translate text="RUN.NO_DATA" />
					)}
				</div>
			) : (
				<Spinner className="driver-card-spinner" size="large" />
			)}
		</div>
	);
};

type RunTableContainerProps = {
	tasks: Task[];
};

const RunTableContainer = ({ tasks }: RunTableContainerProps) => (
	<>
		{tasks ? (
			<RunTable tasks={tasks} />
		) : (
			<div className={classNames('run-table-loading')}>
				<Spinner className="run-tasks-spinner" size="large" />
			</div>
		)}
	</>
);

type RunMapContainerProps = {
	run: Run;
	mapLoadingStatus: MapLoadingStatus;
	hasLocationEvents: boolean;
	runDurationInSeconds: number;
	onPlayerTimeChange: (value: number) => void;
	baseLocation: google.maps.LatLng;
	driverLocation: google.maps.LatLng;
	runStartLocation: google.maps.LatLng;
	wayPointLocationsFromRun: WayPointLocation[];
	mapRoute: google.maps.LatLng[];
};

const RunMapContainer = ({
	run,
	mapLoadingStatus,
	hasLocationEvents,
	runDurationInSeconds,
	onPlayerTimeChange,
	baseLocation,
	driverLocation,
	runStartLocation,
	wayPointLocationsFromRun,
	mapRoute
}: RunMapContainerProps) => (
	<div className={classNames({ 'run-container-map': mapLoadingStatus !== MapLoadingStatus.NO_DATA })}>
		{mapLoadingStatus === MapLoadingStatus.LOADING ? (
			<Spinner className="run-map-spinner" size="large" />
		) : (
			<div data-test-id="run-map">
				{mapLoadingStatus === MapLoadingStatus.DATA_LOADED ? (
					<>
						{!!run?.ended_at && hasLocationEvents && (
							<RunPlayer onTimeChange={onPlayerTimeChange} sliderLength={runDurationInSeconds} />
						)}
						<RunMap
							baseLocation={baseLocation}
							wayPointsLocations={wayPointLocationsFromRun}
							center={runStartLocation}
							driverLocation={driverLocation}
							route={mapRoute}
						/>
					</>
				) : (
					<Translate text="RUN.NO_DATA" />
				)}
			</div>
		)}
	</div>
);

const RunContainer: React.FC = () => {
	const { runStore, driversStore, teamsStore, tasksStore } = useStores();
	const { id: idParam } = useParams();
	const runId = Number.isInteger(parseInt(idParam, 10)) ? parseInt(idParam, 10) : null;
	const [locationEvents, setLocationEvents] = useState<LocationEvent[]>();
	const [runHistoryEvents, setRunHistoryEvents] = useState<RunHistoryEvent[]>();
	const [driverLocation, setDriverLocation] = useState<google.maps.LatLng>();
	const [run, setRun] = useState<Run | null>(null);
	const [driver, setDriver] = useState(null);
	const [team, setTeam] = useState(null);
	const baseLocation = useMemo(
		() => (team?.lat && team?.lng ? new window.google.maps.LatLng(team.lat, team.lng) : null),
		[team?.lat, team?.lng]
	);
	const runTasks = useMemo(() => (run?.tasks ? sortRunTasksByLastWaypointEta([...run.tasks]) : null), [run?.tasks]);
	const runDurationInSeconds = useMemo(
		() => (run?.ended_at ? moment(run.ended_at).diff(moment(run.started_at), 'seconds') : null),
		[run?.ended_at]
	);
	const runStartLocation = useMemo(
		() => (run ? new window.google.maps.LatLng(run.start_lat, run.start_lng) : null),
		[run?.start_lat, run?.start_lng]
	);

	const mapRoute = useMemo(
		() =>
			locationEvents
				? locationEvents.map(location => new window.google.maps.LatLng(location.lat, location.lng))
				: null,
		[locationEvents]
	);

	const wayPointLocationsFromRun = useMemo(() => {
		if (!runTasks) return null;

		let waypointCounter = 0;
		return runTasks.flatMap(task => {
			if (!task?.way_points) return [];

			const wayPoints = sortWaypointsByPosition([...task.way_points]);
			const { lat, lng, id } = last(wayPoints);
			if (!lat || !lng) return [];
			waypointCounter++;
			return [
				{
					location: new window.google.maps.LatLng(lat, lng),
					wayPointPosition: waypointCounter,
					id
				}
			];
		});
	}, [runTasks]);

	const driverLocationsMapByTime: Map<number, google.maps.LatLng> = useMemo(() => {
		const tempDriverLocationsMapByTime = new Map();
		if (run?.ended_at && locationEvents) {
			locationEvents.forEach(location => {
				const coordinates = new window.google.maps.LatLng(location.lat, location.lng);
				const unixTime = moment(location.happened_at).set('seconds', 0).unix();
				tempDriverLocationsMapByTime.set(unixTime, coordinates);
			});
		}
		return tempDriverLocationsMapByTime;
	}, [run, locationEvents]);

	const fetchLocationEvents = async () => {
		const locationEventResponse: LocationEvent[] = await getRootEnv().dashboardSdk.sdk.v2.runs.getLocations(runId);
		setLocationEvents(sortBy(locationEventResponse, ['happened_at']));

		if (!locationEventResponse?.length) return;

		const firstLocationEvent = locationEventResponse[0];
		if (!firstLocationEvent) return;

		const { lat, lng } = firstLocationEvent;
		if (isNaN(lat) || isNaN(lng)) return;

		const driverStartLocation = new window.google.maps.LatLng(lat, lng);
		setDriverLocation(driverStartLocation);
	};

	const fetchRunHistoryEvents = async () => {
		const runHistoryEventsResponse: RunHistoryEvent[] = await getRootEnv().dashboardSdk.sdk.v2.runs.getHistory(
			runId
		);
		const taskIds = runHistoryEventsResponse
			.map(event => (event.data as Record<string, unknown>)?.task_id as number)
			.filter(Boolean);
		const uniqueTaskIds = Array.from(new Set(taskIds));

		if (uniqueTaskIds.length) {
			tasksStore.loadMany(uniqueTaskIds).finally(() => {
				setRunHistoryEvents(runHistoryEventsResponse);
			});
		} else {
			setRunHistoryEvents(runHistoryEventsResponse);
		}
	};

	const getData = async () => {
		if (!runId) return;

		fetchLocationEvents();
		fetchRunHistoryEvents();

		const run = await runStore.load(runId, true);
		if (!run) return;

		try {
			if (run?.user_id) {
				const driverResponse = await driversStore.fetch(run.user_id);
				if (driverResponse) setDriver(driverResponse);
			}

			if (run?.team_id) {
				const teamResponse = await teamsStore.fetchById(run.team_id);
				if (teamResponse) setTeam(teamResponse);
			}
		} finally {
			setRun(run);
		}
	};

	useEffect(() => {
		getData();
	}, []);

	const breadcrumbItems: Ref<BreadcrumbItemExtendedProps[]> = useRef([
		{
			children: <Translate text="RUN.RUNS" />,
			href: '/#/runs'
		},
		{
			children: <Translate text="RUN.RUN" />,
			text: `#${runId}`
		}
	]);

	const onPlayerTimeChange = useCallback(
		(value: number) => {
			if (!run) return;

			const startRunTime = moment(run.started_at);
			const currentTime = startRunTime.add(runDurationInSeconds * value, 'seconds').set('seconds', 0);
			const newDriverLocation = driverLocationsMapByTime.get(currentTime.unix());
			if (!newDriverLocation) return;

			setDriverLocation(newDriverLocation);
		},
		[run, runDurationInSeconds, driverLocationsMapByTime]
	);

	const getMapLoadingStatus = () => {
		if (run && driverLocation && mapRoute && wayPointLocationsFromRun) return MapLoadingStatus.DATA_LOADED;
		if (!run || !locationEvents) return MapLoadingStatus.LOADING;
		if (!run.tasks?.length || (!locationEvents?.length && !driverLocation)) return MapLoadingStatus.NO_DATA;
		return MapLoadingStatus.LOADING;
	};

	if (!runId) return <></>;

	return (
		<div className="run-container" key={runId}>
			<div className="run-header">
				<BreadCrumbs items={breadcrumbItems.current} />
				<div className="run-details-wrapper">
					{run ? <RunDetails run={run} /> : <Spinner className="run-details-spinner" size="large" />}
				</div>
			</div>
			<Row gutter={15}>
				<Col span={5}>
					<div className="runs-details">
						<RunDriverCardContainer isRunLoaded={!!run} team={team} driver={driver} />
						<Divider spacing={Spacing.THREE} />
						<RunHistoryEventsContainer runHistoryEvents={runHistoryEvents} />
					</div>
				</Col>
				<Col span={19}>
					<RunTableContainer tasks={runTasks} />
					<RunMapContainer
						mapLoadingStatus={getMapLoadingStatus()}
						baseLocation={baseLocation}
						driverLocation={driverLocation}
						hasLocationEvents={!!locationEvents?.length}
						mapRoute={mapRoute}
						onPlayerTimeChange={onPlayerTimeChange}
						run={run}
						runStartLocation={runStartLocation}
						runDurationInSeconds={runDurationInSeconds}
						wayPointLocationsFromRun={wayPointLocationsFromRun}
					/>
				</Col>
			</Row>
		</div>
	);
};

export default observer(RunContainer);
