import { getRootEnv } from '@bringg-frontend/bringg-web-infra';
import { useEffect, useState } from 'react';

import { BringgDashboardSDK } from '@bringg/dashboard-sdk';
import { getEnv } from 'mobx-easy';
import { get } from 'lodash';
import { RootEnv } from '@bringg-frontend/bringg-web-infra';

export type UserTypeApiError = {
	succes: boolean;
	message: string;
};

function useUserTypes(): Bringg.UserType[] | UserTypeApiError {
	const dashboardSdk: BringgDashboardSDK = getRootEnv().dashboardSdk.sdk;

	const [userTypes, setUserTypes] = useState<Bringg.UserType[] | UserTypeApiError>([]);

	useEffect(() => {
		if (!dashboardSdk) {
			setUserTypes([]);
			return;
		}

		async function fetchUserTypes() {
			try {
				const { user_types } = await dashboardSdk.userTypes.getAll();
				setUserTypes(user_types);
			} catch (err) {
				setUserTypes({ succes: false, message: get(err, 'details') });

				return;
			}
		}

		fetchUserTypes();
	}, [dashboardSdk]);

	return userTypes;
}

export default useUserTypes;
