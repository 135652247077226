import React, { KeyboardEvent, MouseEvent, useMemo, useState } from 'react';

import { Controller, useForm } from 'react-hook-form';
import classnames from 'classnames';
import { BringgTextArea } from '@bringg/react-components';

import useAutomationTranslations from '../../utils/use-automation-translations';
import {
	PlaceholderData,
	findMismatchLabels,
	getDisplayMismatch,
	TemplateTag
} from 'bringg-web/components/text-template';

import styles from './automation-text-template.module.scss';

export type PlaceholdersData = PlaceholderData[];

interface AutomationTextTemplateProps {
	title: string;
	infoText: string;
	isRequired?: boolean;
	isRequiredError?: string;
	setTitle(title: string): void;
	labels: PlaceholdersData;
	maxLength?: number;
	showCount?: boolean;
	['data-test-id']?: string;
}

const MAX_LENGTH = 160;

const AutomationTextTemplate = ({
	title = '',
	infoText,
	isRequired,
	isRequiredError,
	setTitle,
	labels,
	maxLength,
	showCount = true,
	'data-test-id': dataTestId = 'title-text-area'
}: AutomationTextTemplateProps) => {
	const { addTitle, placeholderError, placeholderTextDescription } = useAutomationTranslations();
	const [cursorPosition, setCursorPosition] = useState({
		dragStart: title?.length || 0,
		dragEnd: title?.length || 0
	});

	const labelsSet = useMemo(() => {
		return new Set(
			labels
				.map(({ type, values }) => {
					// If we have value, don't use the type
					if (values) {
						return values.filter(({ disabled }) => !disabled).map(({ type }) => type);
					}
					return type;
				})
				.flat(Infinity) as string[]
		);
	}, [labels]);

	const { control, errors } = useForm({ mode: 'all' });

	const hasTitleError = errors?.title?.message || (isRequired && !title);

	const mismatch = findMismatchLabels(title, labelsSet);
	const mismatchDisplay = getDisplayMismatch(mismatch);

	const errorText = mismatch.length > 0 ? placeholderError(mismatchDisplay.join(', ')) : isRequiredError;
	const showError = mismatch.length > 0 || hasTitleError;

	const addTextToTemplate = (text: string) => {
		const arr = title.split('');
		arr.splice(cursorPosition.dragStart, cursorPosition.dragEnd - cursorPosition.dragStart, `{{${text}}}`).join('');
		const newTitle = arr.join('');

		setTitle(newTitle);
	};

	const setCursorPositionByEvent = (e: KeyboardEvent | MouseEvent | UIEvent) => {
		setCursorPosition({
			dragStart: (e.target as HTMLInputElement).selectionStart,
			dragEnd: (e.target as HTMLInputElement).selectionEnd
		});
	};

	return (
		<>
			<div className={styles.inputTitle}>{infoText}</div>
			<form>
				<Controller
					name="title"
					control={control}
					rules={{ required: isRequired }}
					render={({ onChange }) => (
						<BringgTextArea
							autoSize={{ minRows: 3 }}
							placeholder={addTitle}
							showCount={showCount}
							maxLength={showCount ? maxLength || MAX_LENGTH : undefined}
							data-text-id={dataTestId}
							value={title}
							onChange={e => {
								setTitle(e.target.value);
								onChange(e);
							}}
							className={classnames({ 'has-error': showError })}
							onKeyUp={setCursorPositionByEvent}
							onMouseUp={setCursorPositionByEvent}
						/>
					)}
				/>
				{showError && (
					<span className="error-message" data-test-id="title-error">
						{errorText}
					</span>
				)}
			</form>
			{labels.length > 0 && (
				<TemplateTag textDescription={placeholderTextDescription} labels={labels} onClick={addTextToTemplate} />
			)}
		</>
	);
};

export default AutomationTextTemplate;
