import React from 'react';
import { SoftRolloutBar, SoftRolloutModal, CustomModal } from '@bringg/react-components';
import _isNil from 'lodash/isNil';
import './soft-rollout.scss';
import { useRollout } from './hooks/use-rollout';
import { useRolloutModal } from './hooks/use-rollout-modal';
import { useRolloutBar } from './hooks/use-rollout-bar';
import BlockedRolloutModalBody from './components/blocked-rollout-modal-body/blocked-rollout-modal-body';

const SoftRollout = () => {
	const { rollout } = useRollout();
	const {
		isModalOpen,
		onConfirmModal,
		onCancelModal,
		modalImage,
		translations: modalTranslations
	} = useRolloutModal(rollout);
	const { onToggleBar, isBarOpen, onSwitch, translations: barTranslations, elementId } = useRolloutBar(rollout);

	return (
		!_isNil(rollout) && (
			<div className="soft-rollout">
				{rollout.block_switching ? (
					<CustomModal isOpen={isModalOpen} bodyClassName="blocked-rollout-modal">
						<BlockedRolloutModalBody
							translations={modalTranslations}
							img={modalImage}
							onConfirm={onConfirmModal}
						/>
					</CustomModal>
				) : (
					<CustomModal isOpen={isModalOpen} bodyClassName="soft-rollout-modal-body">
						<SoftRolloutModal
							translations={modalTranslations}
							img={<img src={modalImage} alt="rollout image" className="soft-rollout-modal-image" />}
							onConfirm={onConfirmModal}
							onCancel={onCancelModal}
						/>
					</CustomModal>
				)}
				<SoftRolloutBar
					translations={barTranslations}
					isOpen={isBarOpen}
					onToggle={onToggleBar}
					onClickSwitch={onSwitch}
					blockedSwitching={rollout.block_switching}
					elementId={elementId}
				/>
			</div>
		)
	);
};

export default SoftRollout;
