import { computed, observable, makeObservable } from 'mobx';
import { getRoot } from 'mobx-easy';
import { UserStatus } from '@bringg/types';
import type { datetime, OnlineStatus } from '@bringg/types';

import BaseDomainObject from '../../base-domain-object';
import DriversStore from '../drivers-store';
import UserType from '../../user-type/domain-objects/user-type';
import RootStore from '../../root-store';
import { checkRequestedTimeConflictsWithBlock } from '../../../features/delivery-blocks-v2/delivery-blocks-utils';
import { deliveryBlocksRootStore } from 'bringg-web/features/delivery-blocks-v2/stores/delivery-blocks-root-store';
import DeliveryBlock from 'bringg-web/features/delivery-blocks-v2/stores/domain-objects/delivery-block';

interface Driver extends Bringg.Driver {}

class Driver extends BaseDomainObject<Bringg.Driver> {
	id: number;

	lat: number = null;
	lng: number = null;
	status: OnlineStatus = null;
	sub: UserStatus = null;
	battery: number = null;
	eta_to_home: datetime = null;
	user_type_id: number = null;
	name: string = null;

	constructor(driversStore: DriversStore, driver: Bringg.Driver) {
		super(driversStore, 'users');

		makeObservable(this, {
			lat: observable,
			lng: observable,
			status: observable,
			sub: observable,
			battery: observable,
			eta_to_home: observable,
			user_type_id: observable,
			name: observable,
			userTypeModel: computed,
			assignedDeliveryBlocks: observable,
			asJson: computed
		});

		this.set(driver);
	}

	get userTypeModel(): UserType {
		return getRoot<RootStore>().data.userTypeStore.get(this.user_type_id);
	}

	assignedDeliveryBlocks(teamId?: number): DeliveryBlock[] {
		return deliveryBlocksRootStore.getStore().deliveryBlocksStore.allByDriver(this.id, teamId);
	}

	isAvailableAtRequestedBlock(
		startTimeToCheck: string,
		endTimeToCheck: string,
		ignoreDeliveryBlockIds: number[] = [],
		teamId?: number
	): boolean {
		return !this.assignedDeliveryBlocks(teamId)
			.filter(({ id }) => !ignoreDeliveryBlockIds.includes(id))
			.some(deliveryBlock =>
				checkRequestedTimeConflictsWithBlock(deliveryBlock, startTimeToCheck, endTimeToCheck)
			);
	}

	findConflictedBlockWithRequestedTime(
		startTimeToCheck: string,
		endTimeToCheck: string,
		ignoreDeliveryBlockIds: number[] = [],
		teamId?: number
	): DeliveryBlock {
		return this.assignedDeliveryBlocks(teamId)
			.filter(({ id }) => !ignoreDeliveryBlockIds.includes(id))
			.find(deliveryBlock =>
				checkRequestedTimeConflictsWithBlock(deliveryBlock, startTimeToCheck, endTimeToCheck)
			);
	}

	get asJson(): Partial<Bringg.Driver> {
		return {
			id: this.id,
			merchant_id: this.merchant_id,
			name: this.name,
			status: this.status,
			sub: this.sub,
			lat: this.lat,
			lng: this.lng
		};
	}
}

export default Driver;
