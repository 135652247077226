import React from 'react';

import _first from 'lodash/first';
import _last from 'lodash/last';
import classNames from 'classnames';
import { DateDisplay } from '@bringg/react-components';

import { RouteLocation } from '../../types';

import styles from './bringg-map-route-player-times.module.scss';

interface Props {
	routeLocations: RouteLocation[];
	timeFormat: string;
	timeZone?: string;
}

const BringgMapRoutePlayerTimes: React.FC<Props> = ({ routeLocations, timeFormat, timeZone }) => {
	const routeFirstLocation = _first(routeLocations)?.happened_at;
	const routeLastLocation = _last(routeLocations)?.happened_at;
	return (
		<div data-test-id={'bringg-map-route-player-times'} className={classNames(styles.bringgMapRoutePlayerTimes)}>
			<DateDisplay
				className={classNames(styles.bringgMapRoutePlayerStartTime)}
				date={routeFirstLocation && new Date(routeFirstLocation)}
				format={timeFormat}
				timezone={timeZone}
			/>
			<DateDisplay
				className={classNames(styles.bringgMapRoutePlayerEndTime)}
				date={routeLastLocation && new Date(routeLastLocation)}
				format={timeFormat}
				timezone={timeZone}
			/>
		</div>
	);
};

export default BringgMapRoutePlayerTimes;
