import React from 'react';
import { DeliveryProviderEcoFriendlyType } from '@bringg/types';
import { Tooltip } from '@bringg/react-components';

import { Eco } from '../icons';

interface Props {
	status: DeliveryProviderEcoFriendlyType;
	tooltip: string;
}

export const EcoFriendlyStatus = ({ status, tooltip }: Props) => {
	const showIcon =
		status === DeliveryProviderEcoFriendlyType.Yes || status === DeliveryProviderEcoFriendlyType.Partial;

	return showIcon ? (
		<Tooltip placement="top" title={tooltip}>
			<span>
				<Eco />
			</span>
		</Tooltip>
	) : null;
};
