import React, { memo } from 'react';
import classNames from 'classnames';

import './styles.scss';

interface Props {
	suggestion: boolean;
}

function AttributeMarkerCell({ suggestion }: Props) {
	return <div className={classNames('attribute-marker', suggestion ? 'suggested' : 'active')} />;
}

export default memo(AttributeMarkerCell);
