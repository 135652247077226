import React from 'react';

import TableHeader from '../table-header';
import NoDataMessage from '../no-data-message';
import type { RenderTableContainerProps } from '../../bringg-react-table';
import type { BaseRecord } from '../../types';

const BasicTableContainer = <T extends BaseRecord>(props: RenderTableContainerProps<T>) => {
	const { tableInstance, RenderRow } = props;
	return (
		<>
			<TableHeader headerGroups={tableInstance.headerGroups} />
			<div className="table-body">
				{tableInstance.rows.length ? (
					tableInstance.rows.map((row, index) => <RenderRow key={row.id} index={index} />)
				) : (
					<NoDataMessage />
				)}
			</div>
		</>
	);
};

export default BasicTableContainer;
