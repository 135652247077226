import { getRootEnv } from '@bringg-frontend/bringg-web-infra';
import { datetime, NumberType } from '@bringg/types';
import { action, computed, observable, makeObservable } from 'mobx';
import { getEnv } from 'mobx-easy';
import find from 'lodash/find';
import mapValues from 'lodash/mapValues';
import keyBy from 'lodash/keyBy';
import { RootEnv } from '@bringg-frontend/bringg-web-infra';

export default class TagCustomerConfiguration {
	id: number;
	merchant_id: number;
	tag_id: number;
	sharing_mode: number;
	alert_on_start = false;
	show_delivery_status = false;
	alert_on_task_created = false;
	alert_customer_on_schedule_ready = false;
	alert_on_route_started = false;
	alert_on_checkin = false;
	alert_on_task_schedule = false;
	alert_on_checkout = 0;
	alert_customer_on_reschedule = false;
	customized_tracking_url = '';
	alert_customer_on_late: boolean;
	alert_customer_on_late_buffer_minutes: number;
	alert_customer_on_late_text: string;
	alert_on_task_acknowledged: boolean;
	alert_on_customer_pickup: boolean;
	enable_share_with_customer_mms = false;
	keep_time_window_fixed_on_share = false;
	alert_on_eta_delay: boolean;
	alert_on_eta_delay_string: string;
	alert_on_eta_delay_minutes: number;
	alert_customer_minutes_before_eta?: number;
	created_at?: datetime;
	updated_at?: datetime;

	private sms_settings: Bringg.SmsSetting[] = [];

	constructor(config: Partial<Bringg.TagCustomerConfiguration>) {
		makeObservable<TagCustomerConfiguration, 'sms_settings'>(this, {
			alert_on_start: observable,
			show_delivery_status: observable,
			alert_on_task_created: observable,
			alert_customer_on_schedule_ready: observable,
			alert_on_route_started: observable,
			alert_on_checkin: observable,
			alert_on_task_schedule: observable,
			alert_on_checkout: observable,
			alert_customer_on_reschedule: observable,
			customized_tracking_url: observable,
			alert_customer_on_late: observable,
			alert_customer_on_late_buffer_minutes: observable,
			alert_customer_on_late_text: observable,
			alert_on_task_acknowledged: observable,
			alert_on_customer_pickup: observable,
			enable_share_with_customer_mms: observable,
			keep_time_window_fixed_on_share: observable,
			alert_on_eta_delay: observable,
			alert_on_eta_delay_string: observable,
			alert_on_eta_delay_minutes: observable,
			alert_customer_minutes_before_eta: observable,
			sms_settings: observable,
			setValue: action,
			setFreeSms: action,
			smsSettings: computed
		});

		Object.assign(this, config);
	}

	setValue(key: string, value: boolean | number | string) {
		this[key] = value;
	}

	setFreeSms(messageType: string, value: boolean) {
		const smsSetting = find(this.sms_settings, { message_type: messageType }) as Bringg.SmsSetting;

		const numberType = value ? NumberType.FreeSms : null;

		if (smsSetting) {
			smsSetting.number_type = numberType;
		} else {
			this.sms_settings.push({
				merchant_id: this.merchant_id,
				number_type: numberType,
				message_type: messageType
			});
		}
	}

	get smsSettings(): { [key: string]: NumberType } {
		return mapValues(keyBy(this.sms_settings, 'message_type'), 'number_type');
	}

	async upsert(): Promise<Bringg.TagCustomerConfiguration> {
		let response;
		if (this.id) {
			response = await getRootEnv().dashboardSdk.sdk.tagCustomerConfigurations.update(this.id, this);
		} else {
			response = await getRootEnv().dashboardSdk.sdk.tagCustomerConfigurations.create(this.tag_id, this);
		}
		return response.tag_customer_configuration;
	}
}
