import React from 'react';
import moment, { Moment } from 'moment-timezone';
import { Button, InputNumber, Option, Select, TimeRangePicker } from '@bringg/react-components';
import { BringgFontIcons, BringgIcon } from '@bringg/bringg-icons';
import { BreakType, datetime } from '@bringg/types';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import {
	BREAK_TYPE_OPTIONS,
	BREAK_TYPE_OPTIONS_WITH_RELATIVE,
	LOCATION_OPTIONS
} from '../../../modal/form/breaks/breaks.consts';
import { LocationOption } from '../../../stores/domain-objects/delivery-block-break';
import { RelativeBreak } from './relative-break';
import './break-item.scss';
import { DeliveryBlockBreakBase } from '../break-types';
import { ONE_DAY } from '../../delivery-block-modal';
import { breakValidator } from 'bringg-web/features/delivery-blocks-v2/validators/break-validator';
import { breakService } from 'bringg-web/features/delivery-blocks-v2/services/break-service';

export interface Props {
	use12Hours: boolean;
	format: string;
	breakItem: DeliveryBlockBreakBase;
	breakIndex: number;
	timezone: string;
	onDeleteBreak: (breakItem: DeliveryBlockBreakBase) => void;
	onUpdateBreak: (breakIndex: number, updatedProperties: Partial<DeliveryBlockBreakBase>) => void;
	deliveryBlockTimes: { startTime: string; endTime: string };
	deliveryBlockBreaks: DeliveryBlockBreakBase[];
	allowMultiDayOptimization: boolean;
	relativeBreakEnabled: boolean;
}

const MINUTES_IN_HOUR = 60;
export const convertHoursToMinutes = (hours: number | string) => {
	return Number(hours) * MINUTES_IN_HOUR;
};

export const convertMinutesToHours = (minutes: number | string) => {
	return Number(minutes) / MINUTES_IN_HOUR;
};

export const BreakItem = ({
	use12Hours,
	format,
	breakItem,
	breakIndex,
	timezone,
	onDeleteBreak,
	onUpdateBreak,
	deliveryBlockTimes,
	deliveryBlockBreaks,
	allowMultiDayOptimization,
	relativeBreakEnabled
}: Props) => {
	const { t } = useTranslation();
	const { isBreakBetweenBlockTime, isBreakBetweenOrSameOtherBreaksTimes, isBreakLengthGreaterThanBreakRangeTimes } =
		breakService;
	const isRelativeBreak = breakItem.break_type === BreakType.Relative;

	const onRangeTimesChanged = (timeRanges: Moment[] | null) => {
		if (!timeRanges) return;

		const blockTimeStartDate = moment(deliveryBlockTimes.startTime).date();
		const startTime = timeRanges[0].set({ date: blockTimeStartDate });
		const endTime = timeRanges[1].set({ date: blockTimeStartDate });

		if (startTime.isAfter(endTime)) {
			endTime.add(ONE_DAY, 'day');
		}

		onUpdateBreak(breakIndex, {
			start_time: startTime?.tz(timezone).toISOString(),
			end_time: endTime?.tz(timezone).toISOString()
		});
	};

	const onStartTimeChanged = (startTime: datetime) => {
		onUpdateBreak(breakIndex, { start_time: startTime });
	};

	const onEndTimeChanged = (endTime: datetime) => {
		onUpdateBreak(breakIndex, { end_time: endTime });
	};

	const onLengthChanged = (length: number) => {
		onUpdateBreak(breakIndex, { length });
	};

	const onBreakTypeChanged = (type: BreakType) => {
		if (type === BreakType.Relative) {
			const TWO_HOURS = 2;
			const EIGHT_HOURS = 2;
			onUpdateBreak(breakIndex, {
				start_time: moment(0).add(MINUTES_IN_HOUR, 'minutes').toISOString(),
				end_time: moment(0)
					.add(MINUTES_IN_HOUR * TWO_HOURS, 'minutes')
					.toISOString(),
				threshold: MINUTES_IN_HOUR * EIGHT_HOURS,
				break_type: type
			});
		} else {
			onUpdateBreak(breakIndex, {
				start_time: moment(deliveryBlockTimes.startTime).startOf('day').add(8, 'hours').toISOString(),
				end_time: moment(deliveryBlockTimes.startTime).startOf('day').add(9, 'hours').toISOString(),
				break_type: type
			});
		}
	};

	const breakInvalid = breakValidator.isBreakInvalid(
		breakItem,
		breakIndex,
		deliveryBlockBreaks,
		allowMultiDayOptimization,
		deliveryBlockTimes,
		timezone,
		onUpdateBreak
	);

	const getErrorMessages = () => {
		const errorMessages = [];
		if (
			allowMultiDayOptimization
				? !breakValidator.validateAndUpdateOvernightBreak(
						breakItem,
						breakIndex,
						deliveryBlockTimes,
						timezone,
						onUpdateBreak
				  )
				: !isBreakBetweenBlockTime(breakItem, deliveryBlockTimes)
		) {
			errorMessages.push(t('DELIVERY_BLOCK_MODAL.BREAKS.TIMES_OUT_OF_BLOCK_RANGE'));
		}
		if (isBreakBetweenOrSameOtherBreaksTimes(breakItem, deliveryBlockBreaks)) {
			errorMessages.push(t('DELIVERY_BLOCK_MODAL.BREAKS.BREAKS_CONFLICT'));
		}
		if (moment(breakItem.start_time).isSame(breakItem.end_time)) {
			errorMessages.push(t('DELIVERY_BLOCK_MODAL.BREAKS.BREAK_TIMES_ARE_SAME'));
		}
		if (isBreakLengthGreaterThanBreakRangeTimes(breakItem)) {
			errorMessages.push(t('DELIVERY_BLOCK_MODAL.BREAKS.BREAK_LENGTH_GREATER_THAN_BREAK_RANGE'));
		}

		if (isRelativeBreak && breakItem.end_time < breakItem.start_time) {
			errorMessages.push(t('DELIVERY_BLOCK_MODAL.BREAKS.BREAK_END_TIME_LOWER_THAN_START_TIME'));
		}

		return errorMessages.join(', ');
	};

	return (
		<div className={classNames('break-item', { 'relative-break': isRelativeBreak })}>
			<div
				className={classNames('break-item-data', {
					'relative-break-mode': isRelativeBreak
				})}
			>
				<div className={classNames('flex', { 'column-direction': isRelativeBreak })}>
					<div className="flex align-items-center">
						<Select
							placeholder={t('DELIVERY_BLOCK_MODAL.BREAK_TYPE_PLACEHOLDER')}
							className="break-type"
							value={breakItem.break_type}
							onSelect={type => onBreakTypeChanged(type)}
						>
							{(relativeBreakEnabled ? BREAK_TYPE_OPTIONS_WITH_RELATIVE : BREAK_TYPE_OPTIONS).map(
								breakTypeItem => (
									<Option key={breakTypeItem} value={BreakType[breakTypeItem]}>
										{t(`DELIVERY_BLOCKS.${breakTypeItem.toUpperCase()}_BREAK`)}
									</Option>
								)
							)}
							;
						</Select>
						{isRelativeBreak && (
							<>
								<span>{t('DELIVERY_BLOCK_MODAL.IF_ROUTE_NO_LONGER_THAN')}</span>
								<InputNumber
									placeholder="select"
									className="break-threshold"
									value={convertMinutesToHours(breakItem.threshold)}
									onChange={threshold =>
										onUpdateBreak(breakIndex, { threshold: convertHoursToMinutes(threshold) })
									}
									formatter={value => `${value} ${t('DELIVERY_BLOCK_MODAL.HOURS')}`}
									step={0.5}
									min={1}
									max={24}
									parser={value => value.replace(t('DELIVERY_BLOCK_MODAL.HOURS'), '')}
								/>
							</>
						)}
					</div>
					<div className={classNames('flex', 'align-items-center', { 'margin-12': isRelativeBreak })}>
						{isRelativeBreak && (
							<RelativeBreak
								breakItem={breakItem}
								onFromChanged={onStartTimeChanged}
								onToChanged={onEndTimeChanged}
								onLengthChanged={onLengthChanged}
								isError={breakInvalid}
							/>
						)}
						{breakItem.break_type === BreakType.Flex && (
							<InputNumber
								className="break-length"
								placeholder={t('DELIVERY_BLOCK_MODAL.BREAK_LENGTH_PLACEHOLDER')}
								min={1}
								value={breakItem.length}
								onChange={value => {
									onLengthChanged(Number(value));
								}}
							/>
						)}
						{!isRelativeBreak && (
							<div className="break-range-wrapper">
								<TimeRangePicker
									allowClear={false}
									allowEmpty={[true, true]}
									className={classNames('break-range', { 'range-error': breakInvalid })}
									use12Hours={use12Hours}
									format={format}
									onChange={onRangeTimesChanged}
									value={[
										moment(breakItem.start_time).tz(timezone),
										moment(breakItem.end_time).tz(timezone)
									]}
									placeholder={[t('DELIVERY_BLOCK_MODAL.FROM'), t('DELIVERY_BLOCK_MODAL.TO')]}
									order={!allowMultiDayOptimization}
								/>
							</div>
						)}
						<span className="break-item-at">at</span>
						<Select
							placeholder="select"
							className="break-location-option"
							dropdownClassName="break-location-option-main"
							value={breakItem.location_option}
							onSelect={locationValue => onUpdateBreak(breakIndex, { location_option: locationValue })}
						>
							{LOCATION_OPTIONS.map(locationOption => (
								<Option key={locationOption} value={LocationOption[locationOption]}>
									{t(`DELIVERY_BLOCK_MODAL.BREAKS.${locationOption}`)}
								</Option>
							))}
						</Select>
					</div>
				</div>
				<Button
					className="break-delete"
					type="link"
					size="large"
					shape="circle"
					onClick={() => onDeleteBreak(breakItem)}
				>
					<BringgIcon iconName={BringgFontIcons.Trash} />
				</Button>
			</div>
			<div className="break-item-errors">
				{breakInvalid && (
					<>
						<BringgIcon iconName={BringgFontIcons.Warning} /> {getErrorMessages()}
					</>
				)}
			</div>
		</div>
	);
};
