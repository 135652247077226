import React, { useEffect, useState } from 'react';

import { Button, Collapse, Tooltip } from '@bringg/react-components';
import { BringgFontIcons, BringgIcon } from '@bringg/bringg-icons';
import { Views } from '@bringg/types';
import { observer } from 'mobx-react';

import WorkflowsList from '../../automations-board/components/workflows-list/workflows-list';
import useWorkflowNavigation from '../../hooks/use-workflow-navigation';
import useAutomationTranslations from '../../utils/use-automation-translations';
import { workflowsProvider } from '../../data-providers/workflows-provider';

import styles from './workflows-by-view.module.scss';

interface WorkflowsByViewProps {
	view: Views;
}

const EmptyState = ({ view }: WorkflowsByViewProps) => {
	const { goToAutomationBoard, goToTemplatesTab } = useWorkflowNavigation();
	const {
		workflowsByView: {
			views,
			emptyState: { prefix, suffix, automationRules, templatesMenu }
		}
	} = useAutomationTranslations();

	const viewTranslation = views(view);

	return (
		<div className={styles.emptyState}>
			<div>
				<span>{prefix}</span>
				<Button type="link" onClick={() => goToAutomationBoard({})} className={styles.goToButton}>
					{automationRules}
				</Button>
				<span>{suffix(viewTranslation)}</span>
				<Button type="link" onClick={() => goToTemplatesTab()} className={styles.goToButton}>
					{templatesMenu}
				</Button>
			</div>
		</div>
	);
};

const WorkflowsByView = ({ view }: WorkflowsByViewProps) => {
	const [workflows, setWorkflows] = useState([]);
	const [isOpen, setIsOpen] = useState(false);
	const { goToAutomationBoard } = useWorkflowNavigation();
	const {
		automationTitle,
		workflowsByView: { views, descriptionSuffix, help, title, descriptionPrefix }
	} = useAutomationTranslations();

	useEffect(() => {
		const loadWorkflows = async () => {
			await workflowsProvider.loadAll();
			setWorkflows(workflowsProvider.getByView(view));
		};

		loadWorkflows();
	}, []);

	const viewTranslation = views(view);

	const header = (
		<div className={styles.collapseHeader}>
			<div className={styles.collapseHeaderTitle}>{title}</div>
			<Tooltip title={help(view)}>
				<BringgIcon iconName={BringgFontIcons.Help} />
			</Tooltip>
		</div>
	);

	return (
		<div className={styles.workflowsByView}>
			<Collapse activeKey={isOpen ? 'workflow-panel' : ''} onChange={() => setIsOpen(open => !open)}>
				<Collapse.Panel key="workflow-panel" header={header}>
					{workflows.length > 0 ? (
						<div className={styles.collapseBody}>
							<div className={styles.description}>
								{descriptionPrefix}
								<Button
									type="link"
									onClick={() => goToAutomationBoard({})}
									className={styles.goToButton}
								>
									{automationTitle}
								</Button>
								{descriptionSuffix({ count: workflows.length, view: viewTranslation })}
							</div>
							<WorkflowsList workflows={workflows} viewMode />
						</div>
					) : (
						<EmptyState view={view} />
					)}
				</Collapse.Panel>
			</Collapse>
		</div>
	);
};

export default observer(WorkflowsByView);
