import React, { useCallback, useEffect, useRef, useState } from 'react';

import { action, reaction } from 'mobx';
import { useObserver } from 'mobx-react';
import { FullscreenControl, Map as MapboxMap } from 'mapbox-gl';
import { Mapbox } from '@bringg/react-components';
import SpinnerWrapper from '@bringg/react-components/dist/components/spinner/spinner';
import { withErrorBoundary } from '@bringg-frontend/bringg-web-infra';
import { Task } from '@bringg/types';

import useStores from '../../recipes/use-stores';
import DispatchMapMarkersView, { MarkerType } from './dispatch-map-markers-view';
import { executeAction, useCrossApp } from '../../services/cross-application/cross-application';
import { extractLocationFromTask } from './utils/utils';
import {
	addDriverMarkerToStore,
	addTaskMarkerToStore,
	addTeamMarkerToStore,
	handleMarkersListUpdate
} from './utils/markers';
import Team from '../../stores/teams/domain-object/team';
import Driver from '../../stores/drivers/domain-object/driver';
import MapLegend from './map-legend/map-legend';
import { MapboxActions } from '../../services/cross-application/cross-application.actions';

const dispatchMapMarkersView = new DispatchMapMarkersView();

const DispatchMap: React.FC = () => {
	const mapRef = useRef<MapboxMap>(null);
	const [isReady, setIsReady] = useState({
		drivers: false,
		tasks: false,
		teams: false,
		tags: false
	});
	const { authStore, tasksStore, teamsStore, driversStore, tagsStore } = useStores();

	const onMapLoaded = useCallback((map: MapboxMap) => {
		map.addControl(new FullscreenControl());
		mapRef.current = map;
	}, []);

	const [mapboxOptions, setMapboxOptions] = useState(null);

	useEffect(() => {
		setMapboxOptions({
			apiKey: authStore.mapboxAccessToken,
			mapboxOptions: {
				style: 'mapbox://styles/bringg/cl0taf34y00q014qbypr0bbm9'
			},
			zoom: 2,
			clusterData: { shouldCluster: true, clusterBelowZoom: 7 },
			onLoaded: onMapLoaded
		});
	}, [authStore, onMapLoaded]);

	const handleDriversUpdate = useCallback(
		(drivers: Driver[]) => {
			handleMarkersListUpdate(
				drivers,
				addDriverMarkerToStore,
				MarkerType.DRIVERS,
				dispatchMapMarkersView,
				null,
				tasksStore
			);
		},
		[tasksStore]
	);

	const handleTasksUpdate = useCallback(
		action((tasks: Task[]) => {
			handleMarkersListUpdate(
				tasks,
				addTaskMarkerToStore,
				MarkerType.TASKS,
				dispatchMapMarkersView,
				extractLocationFromTask
			);
		}),
		[]
	);

	const handleOpenTasksResponse = useCallback(
		(openTasks: Task[]) => {
			tasksStore.setOpen(openTasks);
		},
		[tasksStore, handleTasksUpdate]
	);

	useEffect(() => {
		const initData = async () => {
			await Promise.all([teamsStore.fetchAll(), tagsStore.fetchAll()]);
			setIsReady(prevState => ({ ...prevState, teams: true, tags: true }));
			reaction(
				() => teamsStore.all.length,
				() => handleTeamsUpdate(teamsStore.all),
				{ fireImmediately: true }
			);
		};

		initData();

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		setIsReady(prevState => ({ ...prevState, tasks: true }));
		reaction(
			() => tasksStore.getOpen().length,
			() => handleTasksUpdate(tasksStore.getOpen()),
			{ fireImmediately: true }
		);
	}, []);

	useEffect(() => {
		const initDrivers = async () => {
			await driversStore.fetchAll({ options: { onlyOnlineDriver: true } });
			setIsReady(prevState => ({ ...prevState, drivers: true }));
			reaction(
				() => driversStore.onlineDrivers.length,
				() => handleDriversUpdate(driversStore.onlineDrivers),
				{ fireImmediately: true }
			);
		};

		initDrivers();
	}, [driversStore, handleDriversUpdate]);

	useEffect(() => {
		executeAction(authStore.crossAppTransport, 'DISPATCH_MAPBOX_DATA_REQUEST');
	}, []);

	useCrossApp(
		MapboxActions.DISPATCH_MAPBOX_DATA_RESPONSE,
		({ openTasks }) => {
			handleOpenTasksResponse(openTasks);
		},
		authStore.crossAppTransport
	);

	useCrossApp(
		MapboxActions.DISPATCH_MAPBOX_SELECTED_TASK,
		({ selectedTask }) => {
			if (!selectedTask) return;
			const { lat, lng } = extractLocationFromTask(selectedTask);
			if (mapRef.current) {
				mapRef.current.flyTo({
					center: [lng, lat]
				});
			}
		},
		authStore.crossAppTransport
	);

	const handleTeamsUpdate = useCallback((teams: Team[]) => {
		handleMarkersListUpdate(teams, addTeamMarkerToStore, MarkerType.TEAMS, dispatchMapMarkersView);
	}, []);

	return useObserver(() => {
		const { driversMarkers, tasksMarkers, teamsMarkers } = dispatchMapMarkersView;
		let markers = [];

		if (isReady.teams) {
			markers = markers.concat(teamsMarkers);
		}

		if (isReady.drivers) {
			markers = markers.concat(driversMarkers);
		}

		if (isReady.tasks) {
			markers = markers.concat(tasksMarkers);
		}

		const shouldRenderMap = isReady.tasks && isReady.drivers && isReady.teams;
		const shouldRenderLegend = isReady.tasks && isReady.drivers && isReady.tags;
		const markersToRender = Promise.resolve(markers);

		return (
			<>
				{!shouldRenderMap && (
					<div className="dispatch-map-mapbox-spinner">
						<SpinnerWrapper />
					</div>
				)}

				{shouldRenderMap && mapboxOptions?.apiKey && (
					<Mapbox {...mapboxOptions} markers={markersToRender}>
						<MapLegend
							dispatchMapMarkersView={dispatchMapMarkersView}
							tasks={tasksStore.getOpen()}
							drivers={driversStore.onlineDrivers}
							tags={tagsStore.getAll}
							isLoading={!shouldRenderLegend}
						/>
					</Mapbox>
				)}
			</>
		);
	});
};

export default withErrorBoundary(DispatchMap);
