import { useEffect, useState } from 'react';

import { ExtendedMarkerData, IdType } from '../../types';
import usePrevious from '../../use-previous';
import {
	addGoogleMarker,
	MARKER_REPOSITION_ANIMATION_DELAY,
	MARKER_REPOSITION_ANIMATION_DELTA,
	MARKER_REPOSITION_ANIMATION_DISTANCE_LIMIT,
	removeEntityFromMap,
	setMapCenter,
	shouldDisplayTooltip,
	shouldDisplayTooltipForSelectedMarker
} from '../utils';
import { getDataForMarker, removeDataForMarker, setDataForMarker } from '../utils/markers-to-marker-data';

export const useGoogleMarkers = (
	markers: ExtendedMarkerData[],
	map: google.maps.Map | null,
	infoWindow: google.maps.InfoWindow,
	shouldAutoFocusMap: boolean,
	onSelectMarker?: (isCtrlClick: boolean, markerId: IdType) => void,
	selectedMarkers?: IdType[],
	forceRecenterOnAddingMarkers?: boolean
) => {
	const [markerInstanceByIdMap, setMarkersInstanceByIdMap] = useState<Map<IdType, google.maps.Marker>>(new Map());
	const [timeoutByMarkerIdMap, setTimeoutByMarkerIdMap] = useState<Map<IdType, NodeJS.Timeout>>(new Map());
	const prevSelectedMarkers: IdType[] = usePrevious(selectedMarkers) || [];

	const removeMarker = (
		markerId: IdType,
		markerToRemove: google.maps.Marker,
		timeoutsTemp?: Map<IdType, NodeJS.Timeout>
	) => {
		removeEntityFromMap(markerToRemove);
		const timeoutToClear = timeoutsTemp?.get(markerId);
		timeoutToClear && clearTimeout(timeoutToClear);
	};

	const selectMarkersToRedraw = () => {
		const markersToRedraw = prevSelectedMarkers
			.filter(x => !(selectedMarkers || []).includes(x))
			.concat((selectedMarkers || []).filter(x => !prevSelectedMarkers.includes(x)));
		return markersToRedraw;
	};

	const redrawSelectedMarkers = (markerInstanceMap: Map<IdType, google.maps.Marker>) => {
		if (!map || (!markers && !selectedMarkers)) return;
		const markersToRedraw = selectMarkersToRedraw();
		const currentlyOpenMarkerId = infoWindow.anchor
			? getDataForMarker(infoWindow.anchor as google.maps.Marker)?.id
			: undefined;
		markersToRedraw?.forEach(markerId => {
			const markerToRemove = markerInstanceByIdMap.get(markerId);
			if (markerToRemove) {
				removeMarker(markerId, markerToRemove);
				removeDataForMarker(markerToRemove);
			}

			const markerIndex = markers.findIndex(m => m.id === markerId);
			const markerData = { ...markers[markerIndex] };
			const googleMarker = addGoogleMarker({
				map,
				markerData: markerData,
				infoWindow,
				onSelectMarker,
				selectedMarkers,
				hideAnimation: true,
				shouldOpenTooltipOnCreation:
					currentlyOpenMarkerId === markerId &&
					shouldDisplayTooltipForSelectedMarker(selectedMarkers, markerId)
			});
			if (googleMarker) {
				markerInstanceMap.set(markerId, googleMarker);
				setDataForMarker(googleMarker, markerData);
			}
		});
	};

	const updateOpacity = (marker: ExtendedMarkerData, markerInstance: google.maps.Marker) => {
		if (marker.opacity != null && markerInstance?.getOpacity() !== marker.opacity) {
			markerInstance.setOpacity(marker.opacity);
		} else if (marker.opacity == null && markerInstance?.getOpacity()) {
			markerInstance.setOpacity(null);
		}
	};

	const updateMarkerLabel = (marker: ExtendedMarkerData, markerInstance: google.maps.Marker) => {
		const markerLabel = markerInstance?.getLabel();
		if (marker.text && markerLabel && typeof markerLabel === 'object' && markerLabel?.text !== marker.text) {
			markerLabel.text = marker.text;
			markerInstance.setLabel(markerLabel);
		} else if (markerLabel && typeof markerLabel === 'object' && marker.text == null) {
			markerLabel.text = '';
			markerInstance.setLabel(null);
		}
	};
	useEffect(() => {
		if (!map || (!markers && !markerInstanceByIdMap.size)) return;
		const temp = new Map(markerInstanceByIdMap);
		const timeoutsTemp = new Map(timeoutByMarkerIdMap);
		const markerIdsToRemove = new Set(temp.keys());

		if (markers?.length) {
			markers.forEach((m: ExtendedMarkerData) => {
				if (!m) return;

				let markerInstance = temp.get(m.id);
				if (!markerInstance) {
					markerInstance = addGoogleMarker({
						map,
						markerData: m,
						infoWindow,
						onSelectMarker,
						selectedMarkers
					});
					if (!markerInstance) {
						return;
					}

					temp.set(m.id, markerInstance);
					setDataForMarker(markerInstance, m);
					return;
				}

				setDataForMarker(markerInstance, m);

				markerIdsToRemove.delete(m.id);

				if (
					markerInstance.getPosition()?.lat() !== m.location.lat ||
					markerInstance.getPosition()?.lng() !== m.location.lng
				) {
					const moveToLatLng = new google.maps.LatLng(m.location.lat, m.location.lng);
					const markerPosition = markerInstance?.getPosition();
					if (markerPosition && isMoveToLocationTooFar(markerPosition, moveToLatLng)) {
						const timeoutInstance = timeoutsTemp.get(m.id);
						timeoutInstance && clearTimeout(timeoutInstance);
						markerInstance.setPosition(moveToLatLng);
						if (shouldAutoFocusMap && m.shouldAutoFocus) {
							setMapCenter(map, m.location);
						}
					} else {
						const markerAnimation = runMoveMarkerAnimation(markerInstance, m, map, shouldAutoFocusMap);
						markerAnimation && timeoutsTemp.set(m.id, markerAnimation);
					}
				}

				updateOpacity(m, markerInstance);
				updateMarkerLabel(m, markerInstance);
			});
		}

		markerIdsToRemove.forEach(id => {
			const markerToRemove = temp.get(id);
			markerToRemove && removeMarker(id, markerToRemove, timeoutsTemp);
			temp.delete(id);
		});

		redrawSelectedMarkers(temp);

		setMarkersInstanceByIdMap(temp);
		setTimeoutByMarkerIdMap(timeoutsTemp);

		return () => timeoutByMarkerIdMap.forEach(value => value && clearTimeout(value));
	}, [map, markers, shouldAutoFocusMap, selectedMarkers]);

	useEffect(() => {
		if (map && forceRecenterOnAddingMarkers && !shouldAutoFocusMap) {
			markers.forEach(m => {
				if (!m) {
					return;
				}
				setMapCenter(map, m.location);
			});
		}
	}, [markers?.length]);

	useEffect(() => {
		if (!shouldDisplayTooltip(selectedMarkers)) {
			return;
		}

		const markerToShowTooltip = markers.find(m => shouldDisplayTooltipForSelectedMarker(selectedMarkers, m.id));

		if (!markerToShowTooltip) {
			return;
		}

		if (infoWindow.getContent() !== markerToShowTooltip.tooltip) {
			infoWindow.setContent(markerToShowTooltip.tooltip);
		}
	}, [selectedMarkers, markers, infoWindow]);

	return markerInstanceByIdMap;
};

const runMoveMarkerAnimation = (
	marker: google.maps.Marker,
	markerData: ExtendedMarkerData,
	map: google.maps.Map,
	shouldAutoFocusMap: boolean
) => {
	let timeoutInstance;
	const current = marker.getPosition();
	const moveTo = new google.maps.LatLng(markerData.location.lat, markerData.location.lng);

	const deltalat = (moveTo.lat() - (current?.lat() || 0)) / MARKER_REPOSITION_ANIMATION_DELTA;
	const deltalng = (moveTo.lng() - (current?.lng() || 0)) / MARKER_REPOSITION_ANIMATION_DELTA;

	for (let i = 0; i < MARKER_REPOSITION_ANIMATION_DELTA; i++) {
		timeoutInstance = setTimeout(() => {
			let lat = marker.getPosition()?.lat() || 0;
			let lng = marker.getPosition()?.lng() || 0;
			lat += deltalat;
			lng += deltalng;
			const latlng = new google.maps.LatLng(lat, lng);
			marker.setPosition(latlng);
			if (shouldAutoFocusMap && markerData.shouldAutoFocus) {
				setMapCenter(map, latlng);
			}
		}, MARKER_REPOSITION_ANIMATION_DELAY * i);
	}

	return timeoutInstance;
};

const isMoveToLocationTooFar = (current: google.maps.LatLng, moveTo: google.maps.LatLng) => {
	const latSorted = [moveTo.lat(), current.lat()].sort((a, b) => b - a);
	const lngSorted = [moveTo.lng(), current.lng()].sort((a, b) => b - a);

	const latSum = latSorted[0] - latSorted[1];
	const lngSum = lngSorted[0] - lngSorted[1];

	const distanceSum = latSum + lngSum;
	return distanceSum > MARKER_REPOSITION_ANIMATION_DISTANCE_LIMIT;
};
