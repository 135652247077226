import React from 'react';

import { Portal } from '@bringg-frontend/bringg-web-infra';

import WebUserInterface from 'bringg-web/features/web-user-interface/web-user-interface';

const WebUserInterfacePortal = () => {
	return <Portal element={<WebUserInterface />} nodeId="web-user-interface-page" />;
};

export default WebUserInterfacePortal;
