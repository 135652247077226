import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { Modal, Spinner } from '@bringg/react-components';
import type { Fleet as FleetType, Task as TaskType } from '@bringg/types';
import { observer } from 'mobx-react';
import { executeAction } from '../../services/cross-application/cross-application';
import TaskRelatedFleetStore from '../../stores/task-related-fleet/task-related-fleet-store';
import { useStores } from '../../recipes';
import notification from '../../services/notification';
import FleetAssignerModalFooter from './fleet-assigner-modal-footer';
import FleetAssignerModalBody from './fleet-assigner-modal-body';
import './fleet-assigner-modal.scss';

export interface FleetAssignerModalProps {
	onClose: () => void;
	modalParams: {
		taskId: TaskType['id'];
		gridSelectedItems?: TaskType[];
		onSelectedFleet: (selectedFleetId?: FleetType['id']) => unknown;
	};
}

const FleetAssignerModal = ({ onClose, modalParams }: FleetAssignerModalProps) => {
	const { t } = useTranslation();
	const { authStore } = useStores();
	const [isAssigning, setIsAssigning] = useState(false);

	const { taskRelatedFleetRepo, tasksStore } = useStores();
	const task = tasksStore.get(modalParams.taskId);
	const gridSelectedTasks = modalParams.gridSelectedItems;
	const shouldRenderTasksTable = gridSelectedTasks && gridSelectedTasks.length;
	let fleets = getFleets();
	const [isAssigningInternalFleetAvailable, setIsAssigningInternalFleetAvailable] = useState(false);
	const [isFetching, setIsFetching] = useState(true);
	const [selectedFleetId, setSelectedFleetId] = useState<TaskRelatedFleetStore['id']>(null);
	const [error, setError] = useState<string>();

	useEffect(() => {
		let isMounted = true;
		setIsFetching(true);

		async function fetchTaskAndFleets(taskId) {
			const [taskResult, fleetResult] = await Promise.allSettled([
				tasksStore.get(taskId) || tasksStore.load(taskId),
				taskRelatedFleetRepo.fetchByTaskId(taskId, true)
			]);

			if (!isMounted) {
				return;
			}

			fleets = getFleets();

			setIsFetching(false);

			if (taskResult.status === 'rejected' && fleetResult.status === 'rejected') {
				setError('ASSIGN_FLEET.FAILED_TO_FETCH_FLEETS_AND_TASK');
			} else if (taskResult.status === 'rejected') {
				setError('ASSIGN_FLEET.FAILED_TO_FETCH_TASK');
			} else if (fleetResult.status === 'rejected') {
				setError('ASSIGN_FLEET.FAILED_TO_FETCH_FLEETS');
			}

			// Even if the promise failed we want to test with an empty array
			const fetchedFleets = (fleetResult as PromiseFulfilledResult<TaskRelatedFleetStore[]>)?.value || [];

			const INTERNAL_FLEET_TYPE = 33;
			setIsAssigningInternalFleetAvailable(
				!fetchedFleets.some(({ fleet_type }) => fleet_type === INTERNAL_FLEET_TYPE)
			);

			await taskRelatedFleetRepo.updateWithQuoteDataByTaskId(taskId);
		}

		if (shouldRenderTasksTable) {
			const tasksId = gridSelectedTasks.map(task => task.id);
			tasksId.forEach(id => {
				Promise.resolve(id).then(fetchTaskAndFleets);
			});
		} else {
			fetchTaskAndFleets(modalParams.taskId);
		}

		return () => {
			isMounted = false;
		};
	}, []);

	function getFleets() {
		if (!shouldRenderTasksTable) {
			return taskRelatedFleetRepo.getByTaskId(modalParams.taskId);
		}

		const fleets = gridSelectedTasks
			.map(task => task.id)
			.reduce((all, id) => {
				const fleetsPerTask = taskRelatedFleetRepo.getByTaskId(id);
				if (!all.length && fleetsPerTask && fleetsPerTask.length) {
					return all.concat(fleetsPerTask);
				}

				return fleetsPerTask ? _.intersectionBy(all, fleetsPerTask, 'id') : all;
			}, []);

		return fleets.length ? fleets : null;
	}

	const getSelectedFleetName = useCallback(() => {
		if (selectedFleetId && fleets.length) {
			const selectedFleet = fleets.filter(fleet => fleet.id === selectedFleetId)[0];
			return selectedFleet ? selectedFleet.fleet_name : null;
		}

		return null;
	}, [selectedFleetId, fleets]);

	const updateTask = async (taskToUpdate: TaskType, fleetId: number) => {
		if (taskToUpdate.fleet_id === fleetId) {
			return;
		}

		await tasksStore.update(taskToUpdate.id, {
			fleet_id: fleetId,
			user_id: null
		});
	};

	const assignFleet = useCallback(
		async (isInternalFleet: boolean) => {
			const MODAL_CLOSED_EVENT = 'FLEET_ASSIGNMENT_MODAL_CLOSED';
			// Workaround for internal fleet to keep the same behavior (which doesn't do anything)
			if (isInternalFleet) {
				onClose();
				return;
			}

			if (!selectedFleetId) {
				return;
			}

			setIsAssigning(true);

			try {
				if (gridSelectedTasks.length) {
					gridSelectedTasks.map(async task => {
						await updateTask(task, selectedFleetId);
					});
				} else {
					await updateTask(task, selectedFleetId);
				}

				notification.success(t('ASSIGN_FLEET.ASSIGN_SUCCESS'));
			} catch (e) {
				setError('ASSIGN_FLEET.ASSIGN_FAILED');

				return;
			} finally {
				setIsAssigning(false);
			}

			modalParams.onSelectedFleet(selectedFleetId);
			if (authStore && authStore.crossAppTransport) {
				executeAction(authStore.crossAppTransport, MODAL_CLOSED_EVENT);
			}
			onClose();
		},
		[modalParams, onClose, task, selectedFleetId, gridSelectedTasks]
	);

	return (
		<Modal
			title={shouldRenderTasksTable ? t('ASSIGN_FLEET.TITLE_MULTIPLE') : t('ASSIGN_FLEET.TITLE')}
			className="delivery-provider-assigner-modal"
			visible
			width={800}
			onCancel={onClose}
			footer={
				<FleetAssignerModalFooter
					error={error && t(error)}
					isAssigningInternalFleetAvailable={isAssigningInternalFleetAvailable}
					isAssigning={isAssigning}
					isAssignButtonDisabled={!selectedFleetId || isFetching || !task}
					assign={assignFleet}
					gridSelectedTasks={gridSelectedTasks}
					selectedFleetName={getSelectedFleetName}
				/>
			}
		>
			{isFetching ? (
				<Spinner className="delivery-provider-assigner-modal-spinner" data-test-id="fleet-assigner-spinner" />
			) : (
				<FleetAssignerModalBody
					task={task}
					gridSelectedTasks={gridSelectedTasks}
					relatedFleets={fleets}
					selectedFleetId={selectedFleetId}
					setSelectedFleetId={setSelectedFleetId}
				/>
			)}
		</Modal>
	);
};

export default observer(FleetAssignerModal);
