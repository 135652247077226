import { createContext, useContext } from 'react';

import { RootStoreGetter, withStore } from './with-root-store';

export const generateStoreHelpers = <C>(rootStoreGetter: RootStoreGetter<C>) => {
	const StoreContext = createContext<C>(undefined as unknown as C);
	const StoreProvider = StoreContext.Provider;
	const useStoreContext = () => useContext(StoreContext);

	const withRootStore = withStore(StoreProvider, rootStoreGetter);

	return { useStoreContext, withRootStore };
};
