import { useStores } from '../recipes';

const useTeams = () => {
	const { teamsStore } = useStores();

	return {
		teams: teamsStore.all,
		getRouteOptimizationApplicationATCs: teamsStore.getRouteOptimizationApplicationATCs,
		fetchAllTeams: teamsStore.fetchAll,
		fetchOptimizationConfigurations: teamsStore.fetchRouteOptimizationApplicationATCs,
		get: teamsStore.get
	};
};

export default useTeams;
