import { useEffect, useState } from 'react';

import { ExtendedPolylineData } from '../../types';
import { IdType } from '../../types';
import { addGooglePolyline, removeEntityFromMap } from '../utils';

export const useGooglePolylines = (map: google.maps.Map | null, polylines?: ExtendedPolylineData[]) => {
	const [polylineInstanceByIdMap, setPolylineInstanceByIdMap] = useState<Map<IdType, google.maps.Polyline>>(
		new Map()
	);
	useEffect(() => {
		if (!map || (!polylines && !polylineInstanceByIdMap.size)) return;
		const temp = new Map(polylineInstanceByIdMap);
		const polylineIdsToRemove = new Set(temp.keys());

		if (polylines?.length) {
			polylines.filter(Boolean).forEach((p: ExtendedPolylineData) => {
				const polylineInstance = temp.get(p.id);
				if (polylineInstance) {
					polylineInstance.setPath([...p.path]);
					temp.set(p.id, polylineInstance);
				} else {
					temp.set(p.id, addGooglePolyline({ map, polylineData: p }));
				}
				polylineIdsToRemove.delete(p.id);
			});
		}

		polylineIdsToRemove.forEach(id => {
			const polylineToRemove = temp.get(id);
			polylineToRemove && removeEntityFromMap(polylineToRemove);
			temp.delete(id);
		});
		setPolylineInstanceByIdMap(temp);
	}, [map, polylines]);

	return polylineInstanceByIdMap;
};
