import { Task } from '@bringg/types';
import { isExternalFleet } from 'bringg-web/stores/fleets/fleet-checker';
import { isMultiTeamTasksSelected, isTaskClickAndCollect } from 'bringg-web/utils/tasks-utils';

const ASSIGN_USER_ERROR_CODES = {
	noOrders: 1,
	multiTeamsSelected: 2,
	externalTeamSelected: 4,
	clickAndCollectSelected: 8
};

const ERROR_MESSAGE_BY_CODE = {
	[ASSIGN_USER_ERROR_CODES.noOrders]: 'DISPATCH_LIST.TOOLTIP_NO_ORDERS',
	[ASSIGN_USER_ERROR_CODES.multiTeamsSelected]: 'DISPATCH_LIST.TOOLTIP_DIFFERENT_TEAMS',
	[ASSIGN_USER_ERROR_CODES.externalTeamSelected]: 'DISPATCH_LIST.TOAST_SOME_TASKS_BELONG_TO_EXTERNAL_FLEET',
	[ASSIGN_USER_ERROR_CODES.clickAndCollectSelected]: 'DISPATCH_LIST.TOAST_SOME_TASKS_ARE_CLICK_AND_COLLECT',
	[ASSIGN_USER_ERROR_CODES.externalTeamSelected]: 'DISPATCH_LIST.TOAST_CLICK_COLLECT_AND_EXTERNAL_FLEET'
};

const bulkAssignValidations = [
	{
		validation: tasks => tasks === undefined || tasks.length === 0,
		errCode: ASSIGN_USER_ERROR_CODES.noOrders
	},
	{
		validation: isMultiTeamTasksSelected,
		errCode: ASSIGN_USER_ERROR_CODES.multiTeamsSelected
	}
];

const specificTaskValidations = [
	{
		validation: (task: Task) => isExternalFleet(task.fleet_id),
		errCode: ASSIGN_USER_ERROR_CODES.externalTeamSelected
	},
	{
		validation: isTaskClickAndCollect,
		errCode: ASSIGN_USER_ERROR_CODES.clickAndCollectSelected
	}
];

const processValidationResult = (tasks: Task | Task[], validations) => {
	let errSum = 0;
	let canAssign = true;
	validations.forEach(checker => {
		if (checker.validation(tasks)) {
			canAssign = false;
			errSum = errSum || checker.errCode;
		}
	});
	return { canAssign, errSum };
};

const validateTasksFilter = (tasks: Task[], validations) => {
	const assignableTasks = [];
	let errSumTotal = 0;
	tasks.forEach(task => {
		const { canAssign, errSum } = processValidationResult(task, validations);
		errSumTotal = errSumTotal || errSum;
		if (canAssign) {
			assignableTasks.push(task);
		}
	});
	return { canAssign: assignableTasks.length > 0, assignableTasks, errSum: errSumTotal, errMessage: '' };
};

const isAllowedToAssignSelectedTasks = (tasks: Task[], isAllowAssignDriverToTask) => {
	const isAllowedToBulkAssign = processValidationResult(tasks, bulkAssignValidations);
	const validateAssignSelectedTasks = validateTasksFilter(tasks, specificTaskValidations);
	validateAssignSelectedTasks.errSum += isAllowedToBulkAssign.errSum;
	validateAssignSelectedTasks.canAssign = isAllowAssignDriverToTask && isAllowedToBulkAssign.canAssign;
	validateAssignSelectedTasks.errMessage = ERROR_MESSAGE_BY_CODE[validateAssignSelectedTasks.errSum.toString()] || '';
	return validateAssignSelectedTasks;
};

export const assignDriverValidator = {
	isAllowedToAssignSelectedTasks
};
