import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import moment from 'moment';
import { BringgFontIcons, BringgIcon } from '@bringg/bringg-icons';
import { BringgInput, DateTabs } from '@bringg/react-components';
import useDateFormat from 'bringg-web/hooks/use-date-format';
import styles from './optimization-filters.module.scss';

interface Props {
	onSearch: (value: string) => void;
	isMultiDayOptimization: boolean;
	onSelectedDateChange?: (date: string, searchString: string) => void;
	selectedDate?: string;
	tabsDates: string[];
	className?: string;
	dateTabsClassName?: string;
	initSearchValue?: string;
}

const OptimizationFilters = ({
	onSearch,
	isMultiDayOptimization,
	onSelectedDateChange,
	selectedDate,
	tabsDates,
	className,
	dateTabsClassName,
	initSearchValue
}: Props) => {
	const { t } = useTranslation();
	const dateFormat = useDateFormat();

	const [searchString, setSearchString] = useState(initSearchValue || '');

	const onSearchValueChange = (searchValue: string) => {
		setSearchString(searchValue);
		onSearch(searchValue);
	};

	const datesForTabs = useMemo(() => {
		return tabsDates.map(date => ({
			date,
			day: t(`WEEK_DAYS.${moment(date).utc().format('dd').toUpperCase()}`)
		}));
	}, [tabsDates, t]);

	return (
		<div className={classNames(styles.optimizationFilters, className)}>
			<BringgInput
				placeholder={t('RUN_USER_ASSIGNMENT.SEARCH_PLACEHOLDER')}
				value={searchString}
				data-test-id="search-resource-input"
				onChange={e => onSearchValueChange(e.target.value)}
				suffix={<BringgIcon iconName={BringgFontIcons.Search} />}
			/>
			{isMultiDayOptimization && (
				<DateTabs
					value={selectedDate}
					onChange={date => onSelectedDateChange(date, searchString)}
					dates={datesForTabs}
					dateFormat={dateFormat.replace(/Y/g, '').replace(/^\W|\W$|\W\W/, '')}
					className={dateTabsClassName}
				/>
			)}
		</div>
	);
};

export default OptimizationFilters;
