import React, { memo, useCallback, useMemo, useState } from 'react';
import { BringgPopover, Button, Modal } from '@bringg/react-components';
import { BringgFontIcons, BringgIcon } from '@bringg/bringg-icons';
import { useTranslation } from 'react-i18next';

import notification from 'bringg-web/services/notification';

import styles from './styles.module.scss';

interface Props {
	id: number;
	templateName: string;
	onEdit: (id: number) => void;
	onDelete: (id: number) => Promise<void>;
}

function ActionsCell({ id, templateName, onEdit, onDelete }: Props) {
	const { t } = useTranslation();
	const [popoverVisible, setPopoverVisible] = useState(false);
	const [isDeleting, setIsDeleting] = useState(false);
	const [deleteModalShown, setDeleteModalShown] = useState(false);

	const okButtonProps = useMemo(() => ({ disabled: isDeleting }), [isDeleting]);

	const editTemplate = useCallback(() => {
		onEdit(id);
		setPopoverVisible(false);
	}, [id]);

	const handleDelete = useCallback(async () => {
		if (isDeleting) {
			return;
		}

		setIsDeleting(true);

		try {
			await onDelete(id);

			notification.success(t('EMAIL_TEMPLATES.SUCCESSFULLY_DELETED'), `${t('GLOBAL.NAME')}: ${templateName}`);
		} catch (error) {
			notification.error(t('EMAIL_TEMPLATES.FAILED_DELETE'));
		} finally {
			setIsDeleting(false);
			onDeleteModalClose();
		}
	}, [id, t, isDeleting, onDelete, templateName]);

	const showDeleteModal = useCallback(() => {
		setDeleteModalShown(true);
	}, []);

	const onDeleteModalClose = useCallback(() => {
		setDeleteModalShown(false);
	}, []);

	const PopoverContent = () => (
		<div className={styles.actionsPopover}>
			<Button type="link" icon={<BringgIcon iconName={BringgFontIcons.Pencil} />} onClick={editTemplate}>
				{t('EMAIL_TEMPLATES.EDIT')}
			</Button>
			<Button type="link" icon={<BringgIcon iconName={BringgFontIcons.Trash} />} onClick={showDeleteModal}>
				{t('EMAIL_TEMPLATES.REMOVE')}
			</Button>
		</div>
	);

	return (
		<>
			<BringgPopover
				content={<PopoverContent />}
				destroyOnHide
				visible={popoverVisible}
				onVisibleChange={setPopoverVisible}
			>
				<Button shape="circle" type="link" icon={<BringgIcon iconName={BringgFontIcons.MenuHorizontal} />} />
			</BringgPopover>
			<Modal
				visible={deleteModalShown}
				title={t('EMAIL_TEMPLATES.DELETE_MODAL_TITLE')}
				onOk={handleDelete}
				onCancel={onDeleteModalClose}
				okButtonProps={okButtonProps}
				cancelText={t('EMAIL_TEMPLATES.DO_NOT_DELETE')}
				okText={t('GLOBAL.YES')}
			>
				{t('EMAIL_TEMPLATES.DELETE_MODAL_CONTENT')}
			</Modal>
		</>
	);
}

export default memo(ActionsCell);
