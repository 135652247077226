import React, { useEffect, useMemo, useState } from 'react';

import { BringgFontIcons, BringgIcon } from '@bringg/bringg-icons';
import { useTranslation } from 'react-i18next';
import { Button, Collapse, DatePicker, InputNumber } from '@bringg/react-components';
import classNames from 'classnames';
import { DeliveryBlockTemplateSchedule, Team as TeamType } from '@bringg/types';
import moment, { Moment } from 'moment';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { RRule } from 'rrule';
import { dateUtils } from '@bringg-frontend/utils';

import useDateFormat from 'bringg-web/hooks/use-date-format';
import TransferModal from 'bringg-web/features/transfer-modal/transfer-modal';
import TeamsTable from '../teams-table/teams-table';
import { TransferItemList } from 'bringg-web/features/transfer-modal/types';
import Team from 'bringg-web/stores/teams/domain-object/team';
import { makeRRuleString } from 'bringg-web/services/rrule/rrule-service';
import useStores from 'bringg-web/recipes/use-stores';

import styles from './teams-panel.module.scss';

const { Panel } = Collapse;

interface Props {
	key: string | number;
	templateSchedules: Partial<DeliveryBlockTemplateSchedule>[];
	timezone: string;
	scheduleIdsToRemove: (scheduleIds: number[]) => void;
	schedulesToAddChanged: (schedules: Partial<DeliveryBlockTemplateSchedule>[]) => void;
	schedulesToUpdateChanged: (schedules: Partial<DeliveryBlockTemplateSchedule>[]) => void;
	onTemplateScheduleChanged: (templateSchedules: Partial<DeliveryBlockTemplateSchedule>[]) => void;
	teamsMap: Map<number, TeamType>;
	ical: string;
	schedulesToAdd: Partial<DeliveryBlockTemplateSchedule>[];
	schedulesToUpdate: Partial<DeliveryBlockTemplateSchedule>[];
	templateStartTime: string;
	templateEndTime: string;
}

const TeamsPanel = ({
	templateSchedules,
	onTemplateScheduleChanged,
	timezone,
	schedulesToAddChanged,
	schedulesToUpdateChanged,
	scheduleIdsToRemove,
	teamsMap,
	ical,
	schedulesToAdd,
	schedulesToUpdate,
	templateStartTime,
	templateEndTime,
	...props
}: Props) => {
	const { t } = useTranslation();
	const { teamsStore } = useStores();
	const [teams, setTeams] = useState<Team[]>([]);
	const [capacity, setCapacity] = useState<number>();
	const [startDate, setStartDate] = useState<Moment>();
	const [endDate, setEndDate] = useState<Moment>();
	const [unselectedTeams, setUnselectedTeams] = useState<{ id: number; title: string }[]>([]);
	const [selectedTeams, setSelectedTeams] = useState<{ id: number; title: string }[]>([]);
	const [isTeamsSelectModalOpen, setIsTeamsSelectModalOpen] = useState(false);
	const dateFormat = useDateFormat();
	useEffect(() => {
		const fetchAllTeams = async () => {
			const result = await teamsStore.fetchAll();
			setTeams(result);
		};
		fetchAllTeams();
	}, []);

	useEffect(() => {
		if (teams.length) {
			createTeamsListForTransfer();
		}
	}, [teams]);

	const selectedTeamsIds = useMemo(() => {
		return selectedTeams.map(team => team.id);
	}, [selectedTeams]);

	const createTeamsListForTransfer = () => {
		const teamsInTransferFormat = teams.map(team => ({ id: team.id, title: team.name })) || [];
		setUnselectedTeams(teamsInTransferFormat);
	};

	const onCapacityChanged = (capacity: number) => {
		setCapacity(capacity);
	};

	const onStartDateChanged = (date: Moment) => {
		const startTimeDate = moment(startDate);
		date.set({ h: startTimeDate.hour(), m: startTimeDate.minute(), s: startTimeDate.second() });
		setStartDate(date);
	};

	const onEndDateChanged = (date: Moment) => {
		setEndDate(date);
	};

	const onApplySelectedTeams = (sourceList: TransferItemList[], destinationList: TransferItemList[]) => {
		setUnselectedTeams(sourceList);
		setSelectedTeams(destinationList);
		setIsTeamsSelectModalOpen(false);
	};

	const isEndDatePickerEnabled = ical !== '';

	const disabledStartDates = (current: Moment): boolean => {
		return current && current.isBefore(moment().tz(timezone), 'day');
	};

	const disabledEndDates = (current: Moment): boolean => {
		return startDate ? current.isBefore(startDate, 'day') : disabledStartDates(current);
	};

	const isAddTeamsEnabled = () => {
		if (!selectedTeamsIds.length) {
			return false;
		}
		if (!capacity) {
			return false;
		}
		if (!startDate) {
			return false;
		}

		if (endDate && moment(startDate).isAfter(moment(endDate))) {
			return false;
		}
		return true;
	};

	const buildSchedulesToCreate = (
		ids?: number[],
		editStartDate?: string,
		editEndDate?: string,
		originalCapacity?: number
	) => {
		const schedules = [];
		let until: Moment;
		(ids || selectedTeamsIds).forEach(teamId => {
			const team = teamsMap.get(teamId);
			const timezoneOffset =
				moment()
					.tz(team.time_zone || timezone)
					.utcOffset() / 60;
			const shouldAddDay = timezoneOffset < 0;

			const deliveryBlockStartTime = dateUtils
				.getTimeInSelectedDate(templateStartTime, editStartDate || startDate)
				.toISOString();

			const deliveryBlockEndTime = dateUtils
				.getTimeInSelectedDate(templateEndTime, editStartDate || startDate)
				.toISOString();

			const options = RRule.fromString(ical).origOptions;

			let updateIcalOptions = { ...options };

			if (editEndDate || endDate) {
				until = dateUtils.getTimeInSelectedDate(templateStartTime, editEndDate || endDate);

				updateIcalOptions = {
					...options,
					until: until
						.clone()
						.add(shouldAddDay ? 1 : 0, 'd')
						.toDate()
				};
			}

			const newIcal = makeRRuleString(updateIcalOptions);

			const schedule = {
				team_id: teamId,
				end_time: deliveryBlockEndTime,
				start_time: deliveryBlockStartTime,
				termination_time: editEndDate || endDate ? until.toISOString() : '',
				original_capacity: originalCapacity || capacity,
				ical: newIcal
			};

			schedules.push(schedule);
		});
		return schedules;
	};

	const onEditTeams = (ids?: number[], startTime?: string, endTime?: string, originalCapacity?: number) => {
		const schedules = buildSchedulesToCreate(ids, startTime, endTime, originalCapacity);
		onTemplateScheduleChanged(templateSchedules.concat(schedules));
		const filteredSchedulesToAdd = schedules.filter(schedule => !schedulesToAdd.includes(schedule));
		schedulesToAddChanged(schedulesToAdd.concat(filteredSchedulesToAdd));
	};

	const onAddTeams = () => {
		onEditTeams();
		initTeamForm();
		createTeamsListForTransfer();
	};

	const initTeamForm = () => {
		setSelectedTeams([]);
		setCapacity(null);
		setStartDate(null);
		setEndDate(null);
	};

	const getHeader = () => {
		return (
			<div data-test-id="teams-panel-title" className={styles.panelTitle}>
				<BringgIcon iconName={BringgFontIcons.Users} className={styles.panelTitleIcon} />
				<span className={styles.panelTitleTranslate}>{t('DELIVERY_BLOCK_TEMPLATE.TEAMS.TITLE')}</span>
			</div>
		);
	};

	return (
		<>
			<Panel
				{...props}
				header={getHeader()}
				className={classNames(styles.teamsPanel, 'delivery-block-panel disable-when-select-is-open')}
			>
				<div className={styles.teamsPanelContainer}>
					<div className={styles.teamsPanelActions}>
						<div className={styles.teamsSelectContainer}>
							<span className={styles.panelSubTitle}>
								{t('DELIVERY_BLOCK_TEMPLATE.TEAMS.TEAMS_TITLE')}
							</span>
							<Button onClick={() => setIsTeamsSelectModalOpen(true)} type="link">
								<div>
									<span>+ {t('DELIVERY_BLOCK_TEMPLATE.SELECT_TEAMS')}</span>
									{selectedTeams.length ? (
										<span className={styles.selectedTeamsBadge}>
											{Object.keys(selectedTeams).length}
										</span>
									) : null}
								</div>
							</Button>
						</div>
						<div className={styles.teamsCapacityContainer}>
							<span className={styles.panelSubTitle}>{t('DELIVERY_BLOCKS.TEAMS.CAPACITY_TITLE')}</span>
							<InputNumber
								id="capacity-input"
								value={capacity}
								min={1}
								onChange={value => onCapacityChanged(+value)}
								className={styles.capacityInput}
								placeholder={t('DELIVERY_BLOCK_TEMPLATE.TEAMS.CAPACITY')}
								data-test-id="capacity-input"
							/>
						</div>
						<div className={styles.teamsStartDateContainer}>
							<span className={styles.panelSubTitle}>
								{t('DELIVERY_BLOCK_TEMPLATE.START_DATE_TITLE')}
							</span>
							<DatePicker
								data-test-id="template-start-date-input"
								className="delivery-block-template-team-start-date-input"
								allowClear={false}
								value={startDate}
								onChange={onStartDateChanged}
								placeholder={t('DELIVERY_BLOCK_TEMPLATE.TEAMS.SELECT_DATE')}
								format={dateFormat}
								disabledDate={disabledStartDates}
							/>
						</div>
						<div className={styles.teamsEndDateContainer}>
							<span className={styles.panelSubTitle}>{t('DELIVERY_BLOCK_TEMPLATE.END_DATE_TITLE')}</span>
							<DatePicker
								data-test-id="template-end-date-input"
								className="delivery-block-template-team-end-date-input"
								allowClear={false}
								value={endDate}
								onChange={onEndDateChanged}
								placeholder={t('DELIVERY_BLOCK_TEMPLATE.TEAMS.SELECT_DATE')}
								format={dateFormat}
								disabled={!isEndDatePickerEnabled}
								disabledDate={disabledEndDates}
							/>
						</div>
						<Button
							className={styles.addTeamsButton}
							type="link"
							disabled={!isAddTeamsEnabled()}
							onClick={onAddTeams}
						>
							{t('DELIVERY_BLOCK_TEMPLATE.TEAMS.ADD_TEAMS')}
						</Button>
					</div>
					{templateSchedules.length ? (
						<TeamsTable
							ical={ical}
							templateSchedules={templateSchedules}
							timezone={timezone}
							teams={teams}
							teamsMap={teamsMap}
							onEditTeams={onEditTeams}
							onTemplateScheduleChanged={onTemplateScheduleChanged}
							scheduleIdsToRemove={scheduleIdsToRemove}
							schedulesToAddChanged={schedulesToAddChanged}
							schedulesToAdd={schedulesToAdd}
							schedulesToUpdateChanged={schedulesToUpdateChanged}
							schedulesToUpdate={schedulesToUpdate}
							templateStartTime={templateStartTime}
							templateEndTime={templateEndTime}
						/>
					) : null}
				</div>
			</Panel>
			{isTeamsSelectModalOpen && (
				<DndProvider backend={HTML5Backend}>
					<TransferModal
						onClose={() => setIsTeamsSelectModalOpen(false)}
						title={t('DELIVERY_BLOCK_TEMPLATE.TEAMS_CUSTOMIZATION')}
						sourceList={unselectedTeams}
						targetList={selectedTeams}
						sourceTitle={t('DELIVERY_BLOCK_TEMPLATE.TEAMS_SELECT_TEAMS')}
						targetTitle={t('DELIVERY_BLOCK_TEMPLATE.TEAMS_SELECT_SELECTED_TEAMS')}
						onApply={onApplySelectedTeams}
					/>
				</DndProvider>
			)}
		</>
	);
};

export default TeamsPanel;
