import { getRootEnv } from '@bringg-frontend/bringg-web-infra';
import { action, computed, observable, makeObservable } from 'mobx';
import { getEnv, getRoot } from 'mobx-easy';
import { pickBy, identity, orderBy, isEmpty } from 'lodash';
import {
	DeliveryProviderFilters,
	FleetStatus,
	FleetType,
	GetFavouritesPayload,
	SendNotificationPayload
} from '@bringg/dashboard-sdk/dist/DeliveryCatalog/DeliveryCatalog.consts';
import { FleetEnvType } from '@bringg/types';
import { RootEnv } from '@bringg-frontend/bringg-web-infra';

import DeliveryProvider from './domain-objects/delivery-provider';
import RootStore from '../root-store';
import { KM_TO_MILES_MULTIPLEXER } from '../../consts';
import { Connect } from '../../components/delivery-catalog-connect/delivery-catalog-connect';
import { ConnectionFieldsValues } from '../../components/delivery-catalog-connect/components/delivery-catalog-connect-content';
import { transformToDataFields } from 'bringg-web/components/delivery-catalog-connect/components/delivery-catalog-connect-data-setup/transformToDataFields';

interface Connection {
	id: number;
	fleet_type: FleetType;
	status: FleetStatus;
	env_type?: FleetEnvType;
	name?: string;
	data?: Record<string, unknown>;
	delivery_provider_id?: number;
	delivery_provider: {
		setup_configuration: Bringg.DeliveryProvider['setup_configuration'];
		absolute_logo_path: string | null;
		description: Bringg.DeliveryProvider['description'];
	};
}

class DeliveryCatalogStore {
	deliveryProviders: Map<number, DeliveryProvider> = new Map();
	filters: Array<string> = [];
	connections: Map<number, Connection> = new Map();
	allProvidersAlreadyFetched = false;
	isFetching = true;
	isDataFieldsFetching = false;
	dataFields = null;
	constructor() {
		makeObservable(this, {
			deliveryProviders: observable.shallow,
			filters: observable.shallow,
			connections: observable.shallow,
			isFetching: observable,
			setIsFetching: action,
			isDataFieldsFetching: observable,
			setIsDataFieldsFetching: action,
			setDeliveryProviders: action,
			setDataFields: action,
			dataFields: observable,
			set: action,
			setConnections: action,
			all: computed,
			allConnections: computed,
			lastConnection: computed,
			isDataFieldsAvailable: computed,
			isMissMandatoryData: computed
		});
	}

	setIsFetching = (isFetching: boolean) => {
		this.isFetching = isFetching;
	};

	setIsDataFieldsFetching = (isFetching: boolean) => {
		this.isDataFieldsFetching = isFetching;
	};

	setDeliveryProviders = deliveryProviders => {
		const deliveryProviderEntries: [number, DeliveryProvider][] = deliveryProviders
			.map(deliveryProvider => new DeliveryProvider(this, deliveryProvider))
			.map(deliveryProvider => [deliveryProvider.id, deliveryProvider]);

		this.deliveryProviders = new Map<number, DeliveryProvider>(deliveryProviderEntries);
	};

	setDataFields = data => {
		this.dataFields = data;
	};

	setConnections = (connections: Connection[]) => {
		const connectionEntries: [number, Connection][] = connections.map(connection => [connection.id, connection]);

		this.connections = new Map<number, Connection>(connectionEntries);
	};

	set = (deliveryProvider: DeliveryProvider) => {
		this.deliveryProviders.set(deliveryProvider.id, new DeliveryProvider(this, deliveryProvider));
	};

	get all(): DeliveryProvider[] {
		/**
		 * Show connected first
		 */
		return orderBy(Array.from(this.deliveryProviders.values()), 'connected', 'desc');
	}

	get allConnections(): Connect[] {
		return Array.from(this.connections.values());
	}

	get lastConnection(): Connect {
		return this.allConnections[this.allConnections.length - 1];
	}

	get isDataFieldsAvailable(): boolean {
		return !!this.dataFields;
	}

	get isMissMandatoryData(): boolean {
		if (this.dataFields) {
			if (!this.dataFields.orderId) return false;

			return !isEmpty(this.dataFields.mandatory.missingFields);
		}

		return false;
	}

	fetch = async (filterData?: DeliveryProviderFilters) => {
		if (this.allProvidersAlreadyFetched && isEmpty(filterData)) {
			this.setDeliveryProviders(this.all);
			return;
		}

		this.setIsFetching(true);

		const { deliveryCatalogGeneralEnv: deliveryCatalog } = getRootEnv().dashboardSdk.sdk;
		const convertedUnits = {
			max_delivery_radius: DeliveryCatalogStore.convertUnitDimensions(filterData?.max_delivery_radius)
		};

		const filters = pickBy({ ...filterData, ...convertedUnits }, identity);

		const deliveryProviders = await deliveryCatalog.getProviders({
			destination_uuid: '6946e284-65f5-4083-b5da-d32cca820ee7',
			filters
		});

		const favoritesDeliveryProviders = await deliveryCatalog.getUsersFavorites();
		const connectedDeliveryProviders = (await deliveryCatalog.getMerchantConnectionsList()).records.map(
			({ delivery_provider_id }) => delivery_provider_id
		);

		let preparedDeliveryProviders;

		/**
		 * Add favorite and connected
		 */
		preparedDeliveryProviders = deliveryProviders.map(deliveryProvider => {
			return {
				...deliveryProvider,
				favorite: favoritesDeliveryProviders.includes(deliveryProvider.id),
				connected: connectedDeliveryProviders.includes(deliveryProvider.id)
			};
		});

		/**
		 * Filter by favorite
		 */
		if (filters.favorites) {
			preparedDeliveryProviders = preparedDeliveryProviders.filter(deliveryProvider => deliveryProvider.favorite);
		}

		this.setDeliveryProviders(preparedDeliveryProviders);
		this.setIsFetching(false);
		this.allProvidersAlreadyFetched = isEmpty(filterData);
	};

	fetchConnections = async () => {
		this.setIsFetching(true);

		await this.fetch();

		const { deliveryCatalogGeneralEnv: deliveryCatalog } = getRootEnv().dashboardSdk.sdk;
		const connections = await deliveryCatalog.getMerchantConnectionsList();

		const extendedConnections = connections.records.map(connection => {
			const deliveryProvider = this.deliveryProviders.get(connection.delivery_provider_id);

			return { ...connection, delivery_provider: deliveryProvider };
		});

		this.setConnections(extendedConnections);
		this.setIsFetching(false);
	};

	fetchDataFields = async fleetType => {
		this.setIsDataFieldsFetching(true);

		try {
			const validationRules = await this.fetchValidationRules(fleetType);
			const mappingRules = await this.fetchMappingRules(fleetType);
			const order = await this.fetchBasedOrder();

			if (isEmpty(validationRules) || isEmpty(mappingRules)) {
				this.setDataFields(null);
			} else {
				const dataFields = transformToDataFields({ order, validationRules, mappingRules });
				this.setDataFields(dataFields);
			}
		} catch (e) {
			this.setDataFields(null);
		}

		this.setIsDataFieldsFetching(false);
	};

	fetchValidationRules = async (fleetType: FleetType) => {
		const { deliveryCatalog } = getRootEnv().dashboardSdk.sdk;
		const response = await deliveryCatalog.getFleetValidationRules({ fleetType });
		return response;
	};

	fetchMappingRules = async (fleetType: FleetType) => {
		const { deliveryCatalog } = getRootEnv().dashboardSdk.sdk;
		const response = await deliveryCatalog.getFleetMappingRules({ fleetType });
		return response;
	};
	fetchBasedOrder = async () => {
		const { tasks, teams } = getRootEnv().dashboardSdk.sdk;
		const response = await tasks.closedTaskByQuery();
		const task = response.tasks[0];

		if (isEmpty(task)) {
			return;
		}

		if (task.team_ids[0]) {
			const team = await teams.get(task.team_ids[0]);
			// @ts-ignore
			task.team = {
				...team
			};
		}
		return task;
	};

	updateConnection = async (id: number, values: ConnectionFieldsValues) => {
		const { deliveryCatalogGeneralEnv: deliveryCatalog } = getRootEnv().dashboardSdk.sdk;
		const connection = this.connections.get(id);
		const deliveryProvider = await deliveryCatalog.getDeliveryById({
			id: connection.delivery_provider_id,
			destination_uuid: '6946e284-65f5-4083-b5da-d32cca820ee7'
		});

		const response = await deliveryCatalog.updateMerchantConnection({
			id,
			name: connection.name,
			env_type: connection.env_type,
			...values,
			delivery_provider: deliveryProvider as Bringg.DeliveryProvider
		});

		this.connections.set(id, response);
		return this.connections.get(id);
	};

	verifyConnection = async (id: number, values: ConnectionFieldsValues) => {
		const { deliveryCatalogGeneralEnv: deliveryCatalog } = getRootEnv().dashboardSdk.sdk;
		const connection = this.connections.get(id);
		const deliveryProvider = await deliveryCatalog.getDeliveryById({
			id: connection.delivery_provider_id,
			destination_uuid: '6946e284-65f5-4083-b5da-d32cca820ee7'
		});
		const response = await deliveryCatalog.verifyMerchantConnectionCredentials({
			id,
			delivery_provider: deliveryProvider as Bringg.DeliveryProvider,
			...values
		});

		this.connections.set(id, { ...this.connections.get(id), ...response });

		return this.connections.get(id);
	};

	createConnection = async (values: ConnectionFieldsValues, deliveryProviderId: number) => {
		const { deliveryCatalogGeneralEnv: deliveryCatalog } = getRootEnv().dashboardSdk.sdk;
		const deliveryProvider = await deliveryCatalog.getDeliveryById({
			id: deliveryProviderId,
			destination_uuid: '6946e284-65f5-4083-b5da-d32cca820ee7'
		});

		const response = await deliveryCatalog.createMerchantConnection({
			name: deliveryProvider.name,
			delivery_provider: deliveryProvider as Bringg.DeliveryProvider,
			env_type: FleetEnvType.Staging,
			...values
		});

		/**
		 * Add connected
		 */
		const updatedDP = this.deliveryProviders.get(deliveryProviderId);
		updatedDP.connected = true;
		this.set(updatedDP);

		this.connections.set(response.id, response);
		return this.connections.get(response.id);
	};

	deleteConnection = async (id: number) => {
		const connection = this.connections.get(id);
		const { deliveryCatalogGeneralEnv: deliveryCatalog } = getRootEnv().dashboardSdk.sdk;
		await deliveryCatalog.deleteMerchantConnection({ ids: [id] });

		/**
		 * Remove connected
		 */
		const updatedDP = this.deliveryProviders.get(connection.delivery_provider_id);
		updatedDP.connected = false;
		this.set(updatedDP);

		this.connections.delete(id);
	};

	terminateConnection = async (id: number) => {
		const { deliveryCatalogGeneralEnv: deliveryCatalog } = getRootEnv().dashboardSdk.sdk;

		await deliveryCatalog.pauseMerchantConnection({ ids: [id] });
		this.connections.set(id, { ...this.connections.get(id), status: 1 });

		return this.connections.get(id);
	};

	loadFilterOptions = async (name: string) => {
		const { deliveryCatalogGeneralEnv: deliveryCatalog } = getRootEnv().dashboardSdk.sdk;

		const options = await deliveryCatalog.getFiltersDetails({
			// @ts-ignore
			// the method doesn't have this arg, but we passing this to func for better logging
			destination_uuid: '6946e284-65f5-4083-b5da-d32cca820ee7',
			filter_name: name
		});

		return options.options;
	};

	sendEmail = async (params: SendNotificationPayload) => {
		const { deliveryCatalogGeneralEnv: deliveryCatalog } = getRootEnv().dashboardSdk.sdk;

		return deliveryCatalog.sendEmailNotification(params);
	};

	toggleFavorite = async (id: number) => {
		const { deliveryCatalogGeneralEnv: deliveryCatalog } = getRootEnv().dashboardSdk.sdk;

		const { favorite } = this.deliveryProviders.get(id);

		const params: GetFavouritesPayload = {
			add: [],
			remove: []
		};

		if (favorite) {
			params.remove = [id];
		} else {
			params.add = [id];
		}

		const response: { added: Array<number>; removed: Array<number> } = (await deliveryCatalog.toggleFavorites(
			params
		)) as any;

		if (response?.added?.length) {
			response.added.forEach(dpID => {
				const updatedDP = this.deliveryProviders.get(dpID);
				updatedDP.favorite = true;
				this.set(updatedDP);
			});
		}

		if (response?.removed?.length) {
			response.removed.forEach(dpID => {
				const updatedDP = this.deliveryProviders.get(dpID);
				updatedDP.favorite = false;
				this.set(updatedDP);
			});
		}
	};

	private static convertUnitDimensions(length: number): number {
		const { merchantConfigurationsStore } = getRoot<RootStore>().data;

		return merchantConfigurationsStore.merchantCountry === 'us'
			? Math.round(length * KM_TO_MILES_MULTIPLEXER)
			: length;
	}
}

export default DeliveryCatalogStore;
