import { action, computed, makeObservable, observable, override } from 'mobx';
import { DisplayFact, FactType, NestedCondition } from '@bringg/types';
import ArrayConditionStore from './array-condition-store';
import { mapRuleFromServer } from '../utils/mapper';
import { AtomicCondition } from '../utils/types';
import PlaceholderCondition from './placeholder-condition-store';
import { ConditionStore, ConditionBaseStore } from './internal';

class RuleStore extends ConditionBaseStore {
	conditions: (RuleStore | AtomicCondition)[] = [];
	parent: RuleStore;
	_value;
	private _factType: FactType;
	private _displayFact: DisplayFact;

	constructor(
		factType: FactType,
		displayFact?: DisplayFact,
		rule?: NestedCondition,
		guid?: number,
		parentCondition?: RuleStore
	) {
		super();
		makeObservable(this, {
			conditions: observable,
			addAndRule: action,
			removeCondition: action,
			setAndRuleInstance: action,
			isValid: computed,
			usedAttributes: override,
			conditionMetadata: override,
			path: override,
			factType: override,
			displayFact: override
		});

		this._factType = factType;
		this._displayFact = displayFact;

		if (guid) {
			this.guid = guid;
		}

		if (rule) {
			this.conditions = mapRuleFromServer(this, rule);
		}
		if (!this.conditions.length && !guid) {
			this.conditions.push(new PlaceholderCondition(this));
		}
		this.parent = parentCondition;
	}

	addAndRule = (path?: string): void => {
		this.conditions.push(
			path
				? new ConditionStore(this, {
						path,
						operator: null,
						value: []
				  })
				: new PlaceholderCondition(this)
		);
	};

	findCondition = (condition: PlaceholderCondition | RuleStore | ConditionStore | ArrayConditionStore): number => {
		let index = -1;

		this.conditions.forEach((cond, i) => {
			if (cond.guid === condition.guid) {
				index = i;
			} else if (cond instanceof RuleStore) {
				const tempIndex = cond.findCondition(condition);
				if (tempIndex > -1) {
					index = i;
				}
			}
		});
		return index;
	};

	setAndRuleInstance = (condition: PlaceholderCondition | RuleStore | ConditionStore | ArrayConditionStore) => {
		if (this.parent) {
			this.parent.setAndRuleInstance(condition);
			return;
		}
		let newCondition: RuleStore | AtomicCondition;
		const { path } = condition;

		if (condition.isMultiValue) {
			newCondition = new RuleStore(this.factType, this.displayFact, null, condition.guid, this);
			newCondition.conditions.push(new ConditionStore(this, { path }));
		} else if (condition.isArray) {
			newCondition = new ArrayConditionStore(this, { path }, condition.guid);
		} else {
			newCondition = new ConditionStore(this, { path }, condition.guid);
		}

		const index = this.findCondition(condition);
		if (index > -1) {
			this.conditions[index] = newCondition;
		}
	};

	removeCondition = (condition: AtomicCondition) => {
		const index = this.conditions.indexOf(condition);
		if (index > -1) {
			this.conditions.splice(index, 1);
		}
	};

	get conditionMetadata() {
		return this.conditions[0].conditionMetadata;
	}

	get path() {
		return this.conditions[0]?.path;
	}

	get isValid() {
		return this.conditions.every(condition => condition.isValid);
	}

	get usedAttributes(): string[] {
		return this.conditions.map(condition => condition.path).filter(Boolean);
	}

	get canComplete() {
		return this.conditions.every(condition => condition.canComplete);
	}

	get factType(): FactType {
		return this._factType;
	}

	get displayFact(): DisplayFact {
		return this._displayFact;
	}
}

export default RuleStore;
