import React, { useCallback, useEffect, useRef, useState } from 'react';

import { OptGroup, Option, Select } from '@bringg/react-components';
import { useTranslation } from 'react-i18next';
import _isEqual from 'lodash/isEqual';

import { EmployeeStateFilter, EmployeeAvailabilityState } from '../types';
import {
	EmployeeStateFilterOptions,
	EmployeeAvailabilityStateOptions,
	useEmployeeStateFilterItems,
	useEmployeeAvailabilityStateFilterItems
} from './use-employee-filter-items';
import { getPersistentFilterState, saveFilterState } from '../persistent-filter-state';

import styles from './employee-filter.module.scss';

type TagRenderProps = Parameters<React.ComponentProps<typeof Select>['tagRender']>[0];
const TagRender = (props: TagRenderProps) => {
	const { label, value } = props;

	// Only display the employee state
	if (!EmployeeStateFilterOptions.has(value)) {
		return null;
	}

	return <span>{label}</span>;
};

function getNewItem<T>({
	oldItems,
	newItems,
	allValues,
	allowEmpty
}: {
	oldItems: any[];
	newItems: any[];
	allValues: Set<T>;
	allowEmpty: boolean;
}): T {
	const oldOptionsDisplayTypesValues = oldItems.filter(value => allValues.has(value));

	const newValueDisplayType = newItems.find(item => allValues.has(item) && !oldItems.includes(item));

	if (newValueDisplayType) {
		return newValueDisplayType;
	}

	if (allowEmpty) {
		// Find the item that did not change
		return newItems.find(item => allValues.has(item));
	}

	return oldOptionsDisplayTypesValues[0];
}

interface Props {
	employeeStateFilterTypeCounts: Record<EmployeeStateFilter, number>;

	onFilterUpdate(filter: {
		employeeState: EmployeeStateFilter;
		tasksState: EmployeeAvailabilityState | undefined;
	}): any;

	cameFromEmployeeInFocus?: boolean;
}

type SelectValueType = [EmployeeStateFilter, EmployeeAvailabilityState] | [EmployeeStateFilter];

const EmployeesFilter = ({ employeeStateFilterTypeCounts, onFilterUpdate, cameFromEmployeeInFocus }: Props) => {
	const { t } = useTranslation();
	const containerRef = useRef(null);
	const [values, setValues] = useState<SelectValueType>(() => {
		// This means the page opened with employee already selected in the url
		if (cameFromEmployeeInFocus) {
			return [EmployeeStateFilter.ON_SHIFT];
		}

		const preSetFilter = getPersistentFilterState();
		return [preSetFilter != null ? preSetFilter : EmployeeStateFilter.ON_SHIFT];
	});

	// Force the select to be able to select 1 value from employee state group
	// and 1 or nothing from employee tasks state group
	const onChange = useCallback(
		(newValues: SelectValueType) => {
			setValues(oldValues => {
				const newValuesReady: (EmployeeStateFilter | EmployeeAvailabilityState)[] = [
					getNewItem({
						oldItems: oldValues,
						newItems: newValues,
						allValues: EmployeeStateFilterOptions,
						allowEmpty: false
					})
				];

				const taskValueNewItem = getNewItem({
					oldItems: oldValues,
					newItems: newValues,
					allValues: EmployeeAvailabilityStateOptions,
					allowEmpty: true
				});

				if (taskValueNewItem !== undefined) {
					newValuesReady.push(taskValueNewItem);
				}

				const equal = _isEqual([...newValuesReady].sort(), [...oldValues].sort());

				if (equal) {
					return oldValues;
				}

				return newValuesReady as SelectValueType;
			});
		},
		[setValues]
	);

	useEffect(() => {
		const employeeStateFilterTypeValue = values.find(item =>
			EmployeeStateFilterOptions.has(item as any)
		) as EmployeeStateFilter;
		saveFilterState(employeeStateFilterTypeValue);
		onFilterUpdate({
			employeeState: employeeStateFilterTypeValue,
			tasksState: values.find(item =>
				EmployeeAvailabilityStateOptions.has(item as any)
			) as EmployeeAvailabilityState
		});
	}, [values]);

	const { items: employeeStateFilterItems, selectedItem: selectedDisplayDriversFilter } = useEmployeeStateFilterItems(
		{
			employeeStateFilterTypeCounts,
			selectedValue: values.find(item => EmployeeStateFilterOptions.has(item as any)) as EmployeeStateFilter
		}
	);

	const { items: employeeAvailabilityItems } = useEmployeeAvailabilityStateFilterItems();

	return (
		<div className={styles.employeeCategoryFilter}>
			<Select
				getPopupContainer={() => containerRef.current}
				dropdownAlign={{
					// Align the (t)op (c)enter of the dropdown to the (b)ottom (c)enter of the select
					points: ['tc', 'bc']
				}}
				placement="bottomLeft"
				// To not show any scroll
				listHeight={350}
				// Otherwise it will look weird
				showSearch={false}
				// Allow to select multiple values
				mode="multiple"
				// We only want to show the employee state
				tagRender={TagRender}
				data-test-id="employee-category-filter-select"
				// TODO - fix this
				aria-label={selectedDisplayDriversFilter?.translationKey}
				aria-description="employee-category-filter-select"
				optionLabelProp="label"
				value={values}
				bordered={false}
				className={styles.select}
				onChange={onChange}
			>
				<OptGroup label={t('MAP.EMPLOYEES_FILTER.EMPLOYEE_STATE.GROUP_LABEL')}>
					{employeeStateFilterItems.map(item => (
						<Option
							role="option"
							key={item.id}
							value={item.id}
							label={item.name}
							data-test-id={`employee-category-filter-select-${item.id}`}
						>
							{item.title}
						</Option>
					))}
				</OptGroup>
				<OptGroup label={t('MAP.EMPLOYEES_FILTER.EMPLOYEE_TASKS_STATE.GROUP_LABEL')}>
					{employeeAvailabilityItems.map(item => (
						<Option
							role="option"
							key={item.id}
							value={item.id}
							label={item.name}
							data-test-id={`employee-availability-filter-select-${item.id}`}
						>
							{item.name}
						</Option>
					))}
				</OptGroup>
			</Select>

			{/*
				Having this container to avoid bugs when the select label is moving around due to changing parent width
				Causing the popover to show in the wrong place
			 */}
			<div className={styles.dropdownContainer} ref={containerRef}></div>
		</div>
	);
};

export default EmployeesFilter;
