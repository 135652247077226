import { Task, WayPoint } from '@bringg/types';
import { getEnv, getRoot } from 'mobx-easy';
import { GROUP_VALUES, TaskGroupTypes } from '@bringg/dashboard-sdk/dist/Task/Tasks.consts';
import { IReactionDisposer, reaction } from 'mobx';
import { MappedStorableItem } from '@bringg/dashboard-sdk/dist/data-entity';

import { RootStore } from '../root-store-types';
import { RootEnv } from '../root-env';
import { hasFeatureFlag } from '../other/feature-flags';
import { DEFAULT_PLANNED_TASK_FIELDS } from '../other/consts';
import TasksStore from './tasks-store';
import { emitAnyTaskRunUpdated } from './emit-task-run-updated';
import { getSharedRootStore } from '../root-store';

// This is an ugly hack to remove the usage of alerts view store in here
export const TASK_RUN_UPDATED_GLOBAL_EVENT = 'task-run-updated';

export default class TasksStoreAdapter {
	private localStore: TasksStore;
	private dispose: IReactionDisposer;

	constructor() {
		this.localStore = new TasksStore();
	}

	public afterRootInit() {
		this.dispose = reaction(
			() => getSharedRootStore().views.authStore.isLoggedIn,
			isLoggedIn => {
				if (TasksStoreAdapter.shouldUseDashboardSdkApi()) {
					TasksStoreAdapter.getTasksApi().updateStoreOnEvents();

					TasksStoreAdapter.getTasksApi().onUpdate(async ({ current, diff }: MappedStorableItem<Task>) => {
						await getSharedRootStore().data.taskInventoriesStore.getTaskInventoriesRejectDetails(
							current.id
						);

						if (diff?.run_id) {
							emitAnyTaskRunUpdated();
						}
					});
				} else {
					if (isLoggedIn) {
						this.localStore.afterUserLoggedIn();
					} else {
						this.localStore.afterUserLoggedOut();
					}
				}
			}
		);
	}

	public resetAfterRemoveRoot() {
		this.dispose?.();
		this.dispose = undefined;
	}

	public onUpdate(callback: (mappedStorableItem: MappedStorableItem<Task>) => void) {
		if (TasksStoreAdapter.shouldUseDashboardSdkApi()) {
			return TasksStoreAdapter.getTasksApi().onUpdate(callback);
		}

		return getEnv<RootEnv>().dashboardSdk.sdk.tasks.onUpdate(task => callback({ current: task }));
	}

	public onCreate(callback: (mappedStorableItem: MappedStorableItem<Task>) => void) {
		if (TasksStoreAdapter.shouldUseDashboardSdkApi()) {
			return TasksStoreAdapter.getTasksApi().onCreate(callback);
		}

		return getEnv<RootEnv>().dashboardSdk.sdk.tasks.onCreate(task => callback({ current: task }));
	}

	public onDelete(callback: (mappedStorableItem: MappedStorableItem<Task>) => void) {
		if (TasksStoreAdapter.shouldUseDashboardSdkApi()) {
			return TasksStoreAdapter.getTasksApi().onDelete(callback);
		}

		return getEnv<RootEnv>().dashboardSdk.sdk.tasks.onDelete(task => callback({ diff: task }));
	}

	public onWayPointUpdate(callback: (wayPoint: WayPoint) => void) {
		if (TasksStoreAdapter.shouldUseDashboardSdkApi()) {
			return TasksStoreAdapter.getTasksApi().onWayPointUpdate(callback);
		}

		return getEnv<RootEnv>().dashboardSdk.sdk.tasks.onWayPointUpdate(callback);
	}

	public onWayPointDelete(callback) {
		if (TasksStoreAdapter.shouldUseDashboardSdkApi()) {
			return TasksStoreAdapter.getTasksApi().onWayPointDelete(callback);
		}

		return getEnv<RootEnv>().dashboardSdk.sdk.tasks.onWayPointDelete(callback);
	}

	public getMany(ids: number[]) {
		if (TasksStoreAdapter.shouldUseDashboardSdkApi()) {
			return TasksStoreAdapter.getTasksApi().getMany(ids);
		} else {
			return this.localStore.batchGet(ids);
		}
	}

	public get(id: number) {
		if (TasksStoreAdapter.shouldUseDashboardSdkApi()) {
			return TasksStoreAdapter.getTasksApi().get(id);
		} else {
			return this.localStore.get(id);
		}
	}

	public getAll(): Task[] {
		if (TasksStoreAdapter.shouldUseDashboardSdkApi()) {
			return Object.values(TasksStoreAdapter.getTasksApi().getAllById());
		} else {
			return this.localStore.all;
		}
	}

	public setOpen(tasks: Task[]) {
		if (!TasksStoreAdapter.shouldUseDashboardSdkApi()) {
			this.localStore.setOpenTasks(tasks);
		}
	}

	public getOpen(): Task[] {
		if (TasksStoreAdapter.shouldUseDashboardSdkApi()) {
			return TasksStoreAdapter.getTasksApi().getGroup(TaskGroupTypes.Main, GROUP_VALUES.Open);
		} else {
			return this.localStore.allOpenTasks;
		}
	}

	public getPlanning(): Task[] {
		if (TasksStoreAdapter.shouldUseDashboardSdkApi()) {
			return TasksStoreAdapter.getTasksApi().getGroup(TaskGroupTypes.Main, GROUP_VALUES.Planning);
		} else {
			return this.localStore.allPlanningTasks;
		}
	}

	public async loadOpen(): Promise<Task[]> {
		if (TasksStoreAdapter.shouldUseDashboardSdkApi()) {
			const response = await TasksStoreAdapter.getTasksApi().loadOpen(null);
			return response.items;
		}

		return await this.localStore.fetchAllOpenTasks();
	}

	public async loadPlanning(additionalColumns?: string[]): Promise<Task[]> {
		const taskFields = additionalColumns?.length
			? [...DEFAULT_PLANNED_TASK_FIELDS, ...additionalColumns]
			: DEFAULT_PLANNED_TASK_FIELDS;

		if (TasksStoreAdapter.shouldUseDashboardSdkApi()) {
			const response = await TasksStoreAdapter.getTasksApi().loadPlanning(taskFields);
			return response.items;
		}

		return await this.localStore.fetchAllPlanningTasks(taskFields);
	}

	public async load(taskId: number, force?: boolean): Promise<Task> {
		if (TasksStoreAdapter.shouldUseDashboardSdkApi()) {
			return await TasksStoreAdapter.getTasksApi().load(taskId, { useCache: !force });
		}

		return await this.localStore.fetch(taskId, force);
	}

	public async loadMany(taskIds: number[]): Promise<Task[]> {
		if (!taskIds?.length) {
			return [];
		}
		if (TasksStoreAdapter.shouldUseDashboardSdkApi()) {
			return await TasksStoreAdapter.getTasksApi().loadMany(taskIds);
		}

		return await this.localStore.batchFetch(taskIds);
	}

	public async update(taskId: number, diff: Partial<Task>): Promise<void> {
		if (TasksStoreAdapter.shouldUseDashboardSdkApi()) {
			await TasksStoreAdapter.getTasksApi().update({ ...diff, id: taskId });
		} else {
			await this.localStore.saveEdit(taskId, diff);
		}
	}

	public async duplicate(taskId: number): Promise<number> {
		if (TasksStoreAdapter.shouldUseDashboardSdkApi()) {
			const duplicatedTask = await TasksStoreAdapter.getTasksApi().duplicate(taskId);
			return duplicatedTask.id;
		} else {
			return await this.localStore.duplicateTask(taskId);
		}
	}

	public async addTaskNote({
		taskId,
		wayPointId,
		note,
		hiddenFromDriver
	}: {
		taskId: number;
		wayPointId: number;
		note: string;
		hiddenFromDriver?: boolean;
	}) {
		return TasksStoreAdapter.getTasksApi().addTaskNote(
			taskId,
			wayPointId,
			note,
			undefined,
			undefined,
			hiddenFromDriver
		);
	}

	public async moveToPlanning(ids: number[]) {
		const response = { success: false };

		if (TasksStoreAdapter.shouldUseDashboardSdkApi()) {
			const v2response = await TasksStoreAdapter.getTasksApi().moveToPlanning(ids);
			response.success = v2response.success;
		} else {
			const v1response = await getEnv<RootEnv>().dashboardSdk.sdk.tasks.moveToPlanning(ids);
			response.success = v1response;
		}

		return response;
	}

	public async releaseToDrivers(ids: number[]) {
		const response = { success: false };

		if (TasksStoreAdapter.shouldUseDashboardSdkApi()) {
			const v2response = await TasksStoreAdapter.getTasksApi().releaseToDrivers(ids);
			response.success = v2response.success;
		} else {
			const v1response = await getEnv<RootEnv>().dashboardSdk.sdk.tasks.releaseToDrivers(ids);
			response.success = v1response;
		}

		return response;
	}

	private static getTasksApi() {
		return getEnv<RootEnv>().dashboardSdk.sdk.v2.tasks;
	}

	public static shouldUseDashboardSdkApi() {
		return hasFeatureFlag(getRoot<RootStore>().data.usersStore.currentUser, 'tasks_v2_api');
	}
}
