import { getRootEnv } from '@bringg-frontend/bringg-web-infra';
import { DeliveryBlock, RecurringTypes } from '@bringg/types';
import { getEnv } from 'mobx-easy';
import {
	DeliveryBlockOptionsWithResources,
	DeliveryBlockOptionsWithUsers
} from '@bringg/dashboard-sdk/dist/DeliveryBlocks/DeliveryBlocks.consts';
import { RootEnv } from '@bringg-frontend/bringg-web-infra';

export const createDeliveryBlock = async (
	options: DeliveryBlockOptionsWithResources | DeliveryBlockOptionsWithUsers
): Promise<DeliveryBlock> => {
	return getRootEnv().dashboardSdk.sdk.deliveryBlocks.create(options);
};

export const editDeliveryBlock = async (
	options: DeliveryBlockOptionsWithResources | DeliveryBlockOptionsWithUsers
): Promise<DeliveryBlock> => {
	return getRootEnv().dashboardSdk.sdk.deliveryBlocks.update(options);
};

export const deleteDeliveryBlock = async (id: number, recurring?: RecurringTypes): Promise<boolean> => {
	return getRootEnv().dashboardSdk.sdk.deliveryBlocks.delete(id, recurring);
};

export default { createDeliveryBlock, editDeliveryBlock, deleteDeliveryBlock };
