import { getRoot } from 'mobx-easy';
import type { CustomAttributeRes } from '@bringg/types';
import type { RootStore } from '@bringg-frontend/bringg-web-legacy';

class CustomAttributesProvider {
	getAttributesMappedByIds = (ids: number[]) => {
		const { customAttributesStore } = getRoot<RootStore>().data;

		return ids.reduce<Record<number, CustomAttributeRes>>((acc, id) => {
			acc[id] = customAttributesStore.get(id);
			return acc;
		}, {});
	};
}

export const customAttributesProvider = new CustomAttributesProvider();
