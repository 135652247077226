import React from 'react';
import { Moment } from 'moment';
import classNames from 'classnames';
import { BringgFontIcons, BringgIcon } from '@bringg/bringg-icons';
import { CapacityMetric } from '@bringg/react-components';
import { DeliveryBlocksEvent } from '../delivery-blocks-calendar-view';
import TimezoneService from '../../../../services/timezone/timezone-service';
import { DELIVERY_BLOCK_TIME_FORMAT } from '../../modal/form/delivery-block-form';
import { useHasCombinedOptimization } from 'bringg-web/features/delivery-blocks-v2/utils/use-has-combined-optimization';

interface Props {
	event: DeliveryBlocksEvent;
	title: string;
	onMouseEnter?: () => void;
	onMouseLeave?: () => void;
	onFocus?: () => void;
	onClick?: () => void;
}

const createEventTime = (start: Moment, end: Moment) =>
	`${start.format(DELIVERY_BLOCK_TIME_FORMAT)} - ${end.format(DELIVERY_BLOCK_TIME_FORMAT)}`;

const titleClassGetter = isDeliveryBlockFull => {
	return classNames('delivery-block-event-content-title', {
		'white-title': isDeliveryBlockFull,
		'gray-title': !isDeliveryBlockFull
	});
};

const DeliveryBlockEventContent = ({ event, title, onMouseEnter, onMouseLeave, onFocus, onClick }: Props) => {
	const { userIds, originalCapacity, start, end, teamTimezone, deliveryBlocksResources, team } = event;
	const isDeliveryBlockFull = userIds.length >= originalCapacity;
	const momentTz = TimezoneService.getMomentTimezone(teamTimezone);
	const isCombinedOptimization = useHasCombinedOptimization(team);

	return (
		<div
			className="delivery-blocks-event-content"
			onMouseEnter={onMouseEnter}
			onMouseLeave={onMouseLeave}
			onFocus={onFocus}
			onClick={onClick}
		>
			<p className={titleClassGetter(isDeliveryBlockFull)}>{title}</p>
			<div className="delivery-blocks-event-content-capacity">
				<BringgIcon iconName={BringgFontIcons.Users} />
				<CapacityMetric
					className="delivery-block-event-content-capacity-count"
					capacity={isCombinedOptimization ? deliveryBlocksResources.length : userIds.length}
					maxCapacity={originalCapacity}
				/>
			</div>
			<p className="delivery-blocks-event-content-time">{createEventTime(momentTz(start), momentTz(end))}</p>
		</div>
	);
};

export default DeliveryBlockEventContent;
