import React, { useRef } from 'react';
import { useCallback } from 'react';
import { useState } from 'react';

import type { Driver } from '@bringg/types';
import AssignComponent, {
	AssignUserProps
} from '@bringg/react-components/dist/components/assign-component/assign-component';
import { useOutsideClick } from '@bringg-frontend/hooks';

type ControlledAssignComponentProps = Omit<AssignUserProps<Driver>, 'open'>;

// this controlled component is a fix to AssignComponent when in vis-timeline
// antd Select v4 is listening to 'mousedown' to open the dropdown and vis-timeline somehow prevent the event to bubble
const ControlledAssignComponent: React.FC<ControlledAssignComponentProps> = props => {
	const [open, setOpen] = useState<boolean>(false);
	const ref = useRef<HTMLDivElement>();

	useOutsideClick(
		ref,
		useCallback(() => {
			setOpen(false);
		}, [open])
	);

	const clickHandler = useCallback(
		(e: React.MouseEvent<HTMLDivElement>) => {
			const inputEl = ref.current.querySelector('input');

			if (open && e.nativeEvent.target !== inputEl) {
				setOpen(false);
			} else {
				setOpen(true);
			}
		},
		[open]
	);

	return (
		<div data-test-id="drivers-select" className="controlled-assign-component" ref={ref} onClick={clickHandler}>
			<AssignComponent<Driver> {...props} open={open} />
		</div>
	);
};

export default ControlledAssignComponent;
