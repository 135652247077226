import { useTranslation } from 'react-i18next';
import TaskRejectReasonsCard from './components/task-reject-reasons-card/task-reject-reasons-card';
import './task-reject-reasons-config.scss';

const TaskRejectReasonsConfig = () => {
	const { t } = useTranslation();

	return (
		<div className="task-reject-reasons-config">
			<div className="task-reject-reasons-config-content">
				<h3 className="task-reject-reasons-config-title">{t('TASK_REJECT_REASON_CONFIG.TITLE')}</h3>
				<TaskRejectReasonsCard />
			</div>
		</div>
	);
};
export default TaskRejectReasonsConfig;
