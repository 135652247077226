import React, { memo } from 'react';
import { Language as LanguageType } from '@bringg/types';
import { BringgFontIcons, BringgIcon } from '@bringg/bringg-icons';
import { Button, DropdownMenu, DropdownMenuItem } from '@bringg/react-components';

import overlayStyle from './add-language-dropdown.module.scss';

interface AddLanguageDropDownProps {
	languages: LanguageType[];
	['data-test-id']?: string;
	onSelect(lang: LanguageType): void;
}

export const AddLanguageDropDown = memo(
	({ languages, onSelect, 'data-test-id': dataTestId }: AddLanguageDropDownProps) => {
		const items: DropdownMenuItem[] = languages.map(lang => ({
			key: lang.code,
			label: lang.name,
			onClick: () => onSelect(lang)
		}));

		return (
			<DropdownMenu items={items} trigger={['click']} overlayClassName={overlayStyle.overlay}>
				<Button
					shape="circle"
					type="link"
					size="large"
					icon={<BringgIcon iconName={BringgFontIcons.Plus} />}
					data-test-id={dataTestId}
				/>
			</DropdownMenu>
		);
	}
);
