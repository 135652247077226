import React, { useCallback, useMemo, useState } from 'react';

import { Button, Form, useForm } from '@bringg/react-components';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { VehicleType } from '@bringg/types';
import { omit } from 'lodash';

import TitleSection from './vehicle-profile-form/title-section';
import GeneralInfoSection from './vehicle-profile-form/general-info-section';
import RoadRestrictionsSection from './vehicle-profile-form/road-restrictions-section';
import CapacityRestrictionsSection from './vehicle-profile-form/capacity-restrictions-section';
import notification from 'bringg-web/services/notification';

import './vehicle-profile-form.scss';

const VehicleProfileForm = ({ vehicleType, setVehicleType, vehicleTypesStore, vehicleCategories }) => {
	const { t } = useTranslation();
	const [form] = useForm();

	const [initialValues] = useState(vehicleType);

	const isCreateForm = !vehicleType?.id;

	const trimRedundantSpaces = (record: VehicleType): VehicleType => ({
		...record,
		title: record.title.trim(),
		description: record.description?.trim(),
		external_id: record.external_id?.trim()
	});

	const saveVehicleType = async record => {
		if (isCreateForm) {
			try {
				await vehicleTypesStore.create(record);

				notification.success(t('VEHICLE_PROFILES.TOAST.CREATE.SUCCESS', { title: record.title }));
			} catch {
				notification.error(t('VEHICLE_PROFILES.TOAST.CREATE.ERROR', { title: record.title }));
			}
		} else {
			try {
				await vehicleTypesStore.update(record.id, record);

				notification.success(t('VEHICLE_PROFILES.TOAST.UPDATE.SUCCESS', { title: record.title }));
			} catch {
				notification.error(t('VEHICLE_PROFILES.TOAST.UPDATE.ERROR', { title: record.title }));
			}
		}
	};

	const getValidatedValues = useCallback(async () => {
		let values = null;
		try {
			values = await form.validateFields();
		} catch (err) {
			console.error('Validate Failed:', err);
		}

		return values;
	}, [form]);

	const resetValues = useCallback(() => {
		setVehicleType({
			id: isCreateForm ? null : vehicleType.id,
			handling_units: {}
		} as unknown as VehicleType);
		form.setFieldsValue({
			title: '',
			description: '',
			default_for_merchant: false,
			external_id: '',
			vehicle_category: '',
			cost_per_shift: null,
			is_green: false,
			is_trailer: false,
			can_attach_trailer: false,
			max_route_distance_km: null,
			handling_units: undefined,
			max_total_weight: null,
			maximum_tasks_per_route: null,
			height: null,
			width: null,
			length: null
		});
	}, [setVehicleType, form, isCreateForm, vehicleType.id]);

	const reassignVehicleCategory = (value: VehicleType) => {
		if (typeof value?.vehicle_category === 'string') {
			return value;
		}

		return omit({ ...value, vehicle_category_id: value.vehicle_category }, 'vehicle_category');
	};

	const submitCreateAndSave = useCallback(async () => {
		const values = await getValidatedValues();

		if (values) {
			const submitData = reassignVehicleCategory(
				trimRedundantSpaces({ ...values, id: null, external_id: values.external_id || null })
			);

			await saveVehicleType(submitData);
			form.resetFields();
			setVehicleType({});
		}
	}, [saveVehicleType, setVehicleType, getValidatedValues, form]);

	const submit = useCallback(async () => {
		const values = await getValidatedValues();

		if (values) {
			const submitData = reassignVehicleCategory(
				trimRedundantSpaces({ ...values, id: vehicleType.id, external_id: values.external_id || null })
			);

			await saveVehicleType(submitData);
			setVehicleType(null);
		}
	}, [saveVehicleType, setVehicleType, getValidatedValues]);

	const existingVehicleProfilesTitles = useMemo(
		() => vehicleTypesStore.all.filter(({ id }) => id !== vehicleType?.id).map(({ title }) => title?.toLowerCase()),
		[vehicleTypesStore.all, vehicleType?.id]
	);

	return (
		<Form form={form} initialValues={initialValues}>
			<div className="vehicle-profiles-form-wrapper">
				<TitleSection
					form={form}
					resetValues={resetValues}
					existingVehicleProfilesTitles={existingVehicleProfilesTitles}
				/>
				<div className="vehicle-profiles-page--form-wrapper">
					<GeneralInfoSection vehicleType={vehicleType} vehicleCategories={vehicleCategories} form={form} />
					<RoadRestrictionsSection />
					<CapacityRestrictionsSection />
				</div>
				<div className="vehicle-profiles-page--form-controls-wrapper">
					<Button type="link" onClick={() => setVehicleType(null)}>
						{t('VEHICLE_PROFILES.FORM.DISCARD')}
					</Button>
					<div className="vehicle-profiles-page--form-controls-wrapper-right">
						{isCreateForm && (
							<Button className="save-and-create-new-btn" onClick={submitCreateAndSave}>
								{t('VEHICLE_PROFILES.FORM.SAVE_AND_CREATE')}
							</Button>
						)}
						<Button onClick={submit}>
							{isCreateForm ? t('VEHICLE_PROFILES.FORM.CREATE') : t('VEHICLE_PROFILES.FORM.UPDATE')}
						</Button>
					</div>
				</div>
			</div>
		</Form>
	);
};

export default observer(VehicleProfileForm);
