import React from 'react';

import List from 'antd/lib/list';

export interface AttachmentsNamesListProps {
	attachmentsNames: string[];
}

export const AttachmentsNamesList = ({ attachmentsNames }: AttachmentsNamesListProps) => {
	return (
		<List
			dataSource={attachmentsNames}
			renderItem={item => <List.Item style={{ padding: '5px 0' }}>{item}</List.Item>}
			split={false}
			size="small"
		/>
	);
};
