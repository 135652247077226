import { useCallback, useEffect, useMemo, useState } from 'react';

import { Button, Checkbox, Modal } from '@bringg/react-components';
import { Notification } from '@bringg/react-components';
import { BringgIcon, BringgFontIcons } from '@bringg/bringg-icons';
import papaparse from 'papaparse';
import { registerAction } from '@bringg-frontend/global-stores';
import { ResourceUploadResult } from '@bringg/dashboard-sdk/dist/ResourceUpload/ResourceResults';
import ResourceUploadType from '@bringg/dashboard-sdk/dist/ResourceUpload/ResourceUploadType';
import { Failure } from '@bringg-frontend/batch-action-status';
import { downloadFile } from '@bringg-frontend/utils';

import { useCSVImportTranslation } from './translations';
import BringgDragger from 'bringg-web/components/bringg-dragger/bringg-dragger';
import OrdersMappingTable from 'bringg-web/features/orders/csv-import/orders-mapping-table';
import { arrayToCSV, csvToTasks, duplicateFailuresForSameTasks, tasksToJSONL } from './task-csv-to-jsonl';
import { getMissingMandatoryFields, taskColumns } from 'bringg-web/features/orders/csv-import/columns';
import useStores from '../../../recipes/use-stores';
import { ModalAction } from 'bringg-web/services/cross-application/cross-application.actions';
import resourceUploader from 'bringg-web/services/resource-upload/resource-uploader';
import { DONE_EVENT } from 'bringg-web/features/service-area/constants';
import ResourceAsyncOperationStatus from 'bringg-web/features/resources/resource-async-operation-status';

import './orders-csv-import.scss';

const LOCAL_KEY = 'orders-mapping-table';
const PREVIEW_ROWS = 5;
const MODAL_WIDTH = 960;
const flexRow = { display: 'flex', justifyContent: 'end', alignItems: 'center' };

function OrdersCSVImport() {
	const translations = useCSVImportTranslation();
	const [file, setFile] = useState<File>(null);
	const [originalHeaders, setOriginalHeaders] = useState<string[]>([]);
	const [headers, setHeaders] = useState<string[]>([]);
	const [parsedFile, setParsedFile] = useState<string[][]>(null);
	const [keepMapping, setKeepMapping] = useState(true);
	const { authStore, usersStore } = useStores();
	const [uploadOpen, setUploadOpen] = useState(false);
	const [uploadStatus, setUploadStatus] = useState<ResourceUploadResult>(null);
	const [processedTasks, setProcessedTasks] = useState([]);
	const [showPrompt, setShowPrompt] = useState(false);

	useEffect(() => {
		registerAction(ModalAction.NEW_TASK_CSV, () => setUploadOpen(true), authStore.crossAppTransport);
	}, [authStore]);

	const reset = useCallback(() => {
		setFile(null);
		setOriginalHeaders([]);
		setProcessedTasks([]);
		setHeaders([]);
		setParsedFile(null);
		setUploadOpen(false);
	}, []);

	useEffect(() => {
		if (!file) {
			return;
		}

		papaparse.parse(file, {
			worker: true,
			skipEmptyLines: 'greedy',
			dynamicTyping: true,
			error: ex => {
				console.error(ex);
				Notification.error(translations.failedToParseFile);
				reset();
			},
			complete: ({ data }) => {
				if (!data?.length) {
					reset();
					return Notification.error(translations.failedToParseFile);
				}

				const localHeaders = JSON.parse(localStorage.getItem(LOCAL_KEY) || 'null') || {};
				const parsedHeaders = data[0].map(column => {
					if (localHeaders[column] === null) {
						return null;
					}

					return (
						localHeaders[column] ||
						taskColumns.find(({ dataIndex }) => dataIndex === column)?.dataIndex ||
						null
					);
				});

				setHeaders(parsedHeaders);
				setParsedFile(data);
				setOriginalHeaders(data[0]);
			}
		});
	}, [file]);

	const missingMandatoryFields = useMemo(() => getMissingMandatoryFields(headers), [headers]);

	const allMatched = useMemo(
		() => headers.filter(el => el).length === originalHeaders.length,
		[headers, originalHeaders]
	);
	const showWarning = useMemo(
		() => file && Boolean(missingMandatoryFields.length) && allMatched,
		[missingMandatoryFields, allMatched, file]
	);

	const startUpload = useCallback(
		async tasks => {
			try {
				const prev = JSON.parse(localStorage.getItem(LOCAL_KEY) || 'null') || {};
				const next = {
					...prev,
					...originalHeaders.reduce((acc, original, index) => ({ ...acc, [original]: headers[index] }), {})
				};

				if (keepMapping) {
					localStorage.setItem(LOCAL_KEY, JSON.stringify(next));
				}

				setUploadStatus(
					await resourceUploader.prepareAndProcess(
						tasksToJSONL(tasks, file.name),
						ResourceUploadType.Tasks,
						DONE_EVENT,
						{}
					)
				);
			} catch (ex) {
				console.error(ex);
				Notification.error(translations.uploadFailed);
			} finally {
				setUploadOpen(false);
			}
		},
		[translations, keepMapping, file, headers]
	);

	const footer = useMemo(() => {
		if (!file) {
			return null;
		}

		const uploadFile = () => {
			const tasks = csvToTasks(parsedFile, headers, usersStore.currentUser);
			setProcessedTasks(tasks);

			if (!usersStore.currentUser.admin && tasks.some(task => !task.teams && !task.team_external_id)) {
				setShowPrompt(true);
			} else {
				void startUpload(tasks);
			}
		};

		return (
			<div style={flexRow}>
				<Button type="primary" disabled={Boolean(missingMandatoryFields.length)} onClick={uploadFile}>
					{translations.approveAndGenerate}
				</Button>
			</div>
		);
	}, [file, translations, headers, missingMandatoryFields, parsedFile, usersStore.currentUser, startUpload]);

	const getErrorsFile = useCallback(
		(failures: Failure[]) => [parsedFile[0], ...failures.map(failure => parsedFile[Number(failure.id)])],
		[parsedFile]
	);

	const downloadErrors = useCallback(
		(failures: Failure[]) => {
			downloadFile(arrayToCSV(getErrorsFile(failures), `errors_${file.name}`));
		},
		[file, getErrorsFile]
	);

	const renderFooter = useCallback(
		(failures: Failure[]) => {
			const hasFailures = Boolean(failures?.length);
			const close = () => {
				setUploadStatus(null);
				reset();
			};

			return (
				<div style={{ ...flexRow, marginTop: 16 }}>
					{!hasFailures && (
						<Button type="primary" onClick={close}>
							{translations.finish}
						</Button>
					)}
					{hasFailures && (
						<div>
							<Button onClick={close}>{translations.close}</Button>
							<Button
								style={{ marginLeft: 28 }}
								type="primary"
								onClick={() => {
									downloadErrors(failures);
									close();
								}}
							>
								{translations.downloadAndClose}
							</Button>
						</div>
					)}
				</div>
			);
		},
		[translations, reset, downloadErrors]
	);

	return (
		<>
			<Modal
				destroyOnClose
				maskClosable={false}
				footer={footer}
				width={MODAL_WIDTH}
				open={uploadOpen}
				onCancel={reset}
			>
				<div className="orders-upload-modal">
					<h4 className="orders-upload-modal-title">
						<BringgIcon iconName={BringgFontIcons.CsvFile} />
						{translations.uploadTitle}
					</h4>

					{!file && (
						<div>
							<div className="secondary-text">{translations.uploadDescription}</div>
							<BringgDragger
								accept={'.csv'}
								height={388}
								style={{ margin: '16px 0 24px' }}
								onFileUploaded={setFile}
							/>
						</div>
					)}

					{parsedFile && (
						<div>
							{showWarning && (
								<div className="orders-upload-warning">
									<BringgIcon iconName={BringgFontIcons.Warning} />
									<div className="orders-upload-warning-text">
										<h5>{translations.mandatory}</h5>
										<span>{translations.mandatoryDescription}</span>
									</div>
								</div>
							)}

							<div className="orders-upload-matched">
								<span>{translations.mappingDescription}</span>
								<span>
									<span>
										{headers.filter(el => el).length} / {originalHeaders.length}{' '}
										{translations.matched}
									</span>
									{allMatched && <BringgIcon iconName={BringgFontIcons.Selected} />}
								</span>
							</div>

							<OrdersMappingTable
								parsedFile={parsedFile.slice(0, PREVIEW_ROWS)}
								headers={headers}
								onChange={setHeaders}
								expandMandatory={Boolean(missingMandatoryFields.length)}
							/>
							<div style={{ margin: '16px 0' }}>
								<Checkbox checked={keepMapping} onChange={e => setKeepMapping(e.target.checked)} />{' '}
								{translations.keepMapping}
							</div>
						</div>
					)}
				</div>
			</Modal>
			{showPrompt && (
				<Modal
					open
					title={
						<h4 className="orders-upload-modal-title">
							<BringgIcon iconName={BringgFontIcons.Warning} />
							{translations.confirmNonAssignedTasks}
						</h4>
					}
					onOk={() => {
						setShowPrompt(false);
						void startUpload(processedTasks);
					}}
					onCancel={() => {
						setShowPrompt(false);
						reset();
					}}
				>
					{translations.confirmNonAssignedTasksDescription}
				</Modal>
			)}

			{uploadStatus && (
				<ResourceAsyncOperationStatus
					mergedActions={parsedFile.length - 1 - processedTasks.length}
					className="orders-upload-resource-status"
					title={
						<h4 className="orders-upload-modal-title">
							<BringgIcon iconName={BringgFontIcons.CsvFile} />
							{translations.uploadTitle}
						</h4>
					}
					translations={translations}
					resourceId={uploadStatus.resource_id}
					width={MODAL_WIDTH}
					onClose={() => {
						setUploadStatus(null);
						reset();
					}}
					processFailures={failures =>
						duplicateFailuresForSameTasks(failures, parsedFile, headers, processedTasks) as any
					}
					renderFailures={failures => (
						<OrdersMappingTable
							failures={failures}
							readonly
							parsedFile={getErrorsFile(failures)}
							headers={headers}
						/>
					)}
					renderFooter={renderFooter}
				/>
			)}
		</>
	);
}

export default OrdersCSVImport;
