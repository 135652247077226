import React, { Component } from 'react';
import { Col, Row } from '@bringg/react-components';
import { observer } from 'mobx-react';
import { ActionData } from '@bringg/types';
import Translate from '../../../translation/translator';
import { Button, Option, SelectWithStickyPopup as Select } from '../../../components';

import { actionValueToType } from '../action-data.consts';
import { ActionDataType, ActionsConfigurationLevel } from 'bringg-web/types/common.consts';

interface Props {
	onSelectType: (actionData: Partial<ActionData>) => void;
	actionType: string;
	onDelete: () => void;
	level?: ActionsConfigurationLevel;
}

class ActionsDataHeader extends Component<Props, Record<string, unknown>> {
	onSelectType = (value: string) => {
		this.props.onSelectType({ value });
	};

	actionValuesToType = () => {
		const types = Array.from(actionValueToType.entries());

		if (this.props.level === ActionsConfigurationLevel.INVENTORY) {
			return types.filter(([, type]) => type !== ActionDataType.VERIFICATION_PIN_CODE);
		}

		return types;
	};

	render() {
		return (
			<Row className="actions-data-header">
				<Col span={6}>
					<div>
						<Translate text="ACTION_CONFIGURATION.CHOOSE_ACTION_TYPE" />
					</div>
					<Select
						onSelect={this.onSelectType}
						value={this.props.actionType}
						data-test-id="action-data-type"
						className="action-data-input"
					>
						{this.actionValuesToType().map(([key, value]) => (
							<Option key={value} value={key} data-test-id="action-data-type-option">
								{value}
							</Option>
						))}
					</Select>
				</Col>
				<Button onClick={this.props.onDelete} className="delete-button" data-test-id="action-data-delete">
					<Translate text="ACTION_CONFIGURATION.DELETE_ACTION_DATA" />
				</Button>
			</Row>
		);
	}
}

export default observer(ActionsDataHeader);
