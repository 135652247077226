import { generateStoreHelpers } from '@bringg-frontend/bringg-web-infra';

import WebhooksAppStore from './webhooks/webhooks-app-store';
import OAuthAppsStore from './oauth-app/oauth-apps-store';
import OauthAppView from './oauth-app/oauth-app-view';
import WebhookAuthenticationConfigurationsStore from './webhook-authentication-configurations/webhook-authentication-configurations-store';

class WebhooksApiRoot {
	private static _instance?: WebhooksApiRoot;

	oAuthAppsStore: OAuthAppsStore;
	oauthAppView: OauthAppView;
	webhookAuthenticationConfigurationStore: WebhookAuthenticationConfigurationsStore;
	webhooksAppStore: WebhooksAppStore;

	public static get instance(): WebhooksApiRoot {
		if (!WebhooksApiRoot._instance) {
			WebhooksApiRoot._instance = new WebhooksApiRoot();
			WebhooksApiRoot._instance.init();
		}

		return WebhooksApiRoot._instance;
	}

	init() {
		this.oAuthAppsStore = new OAuthAppsStore();
		this.oauthAppView = new OauthAppView();
		this.webhookAuthenticationConfigurationStore = new WebhookAuthenticationConfigurationsStore();
		this.webhooksAppStore = new WebhooksAppStore();

		this.webhookAuthenticationConfigurationStore.afterInit();
	}

	static reset() {
		WebhooksApiRoot._instance = undefined;
	}
}

export type WebhooksApiRootStore = WebhooksApiRoot;

export const webhooksApiRootStore = {
	getStore: () => WebhooksApiRoot.instance,
	clearStore: () => WebhooksApiRoot.reset()
};

export const { useStoreContext: useWebhooksApiStore, withRootStore: withWebhooksApiStore } =
	generateStoreHelpers(webhooksApiRootStore);
