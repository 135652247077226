import { useMemo, useState } from 'react';

import { LatLng } from '@bringg/types';

import {
	Avatar,
	DRIVER_MARKER_SIZE,
	DRIVER_MARKER_ZINDEX,
	generateCustomMarkerData,
	ROUTE_PLAYER_DRIVER_MARKER_ID
} from '../../../utils';
import { ExtendedMarkerData } from '../../../types';

const useRoutePlayer = ({
	markers,
	disablePlayer,
	hasRoutePath
}: {
	markers: ExtendedMarkerData[];
	disablePlayer: boolean;
	hasRoutePath: boolean;
}) => {
	const [routePlayerDriverLocation, setRoutePlayerDriverLocation] = useState<LatLng | null>(null);
	const playerDisabled = disablePlayer || !hasRoutePath;
	const hasRealtimeDriverLocation = useMemo(() => markers?.filter(Boolean).some(m => m.id === 'driver'), [markers]);
	let markersWithRoutePlayerDriverMarker;

	if (!playerDisabled && routePlayerDriverLocation) {
		markersWithRoutePlayerDriverMarker = [
			...markers,
			generateCustomMarkerData({
				id: ROUTE_PLAYER_DRIVER_MARKER_ID,
				location: routePlayerDriverLocation,
				icon: Avatar,
				size: { width: DRIVER_MARKER_SIZE, height: DRIVER_MARKER_SIZE },
				zIndex: DRIVER_MARKER_ZINDEX,
				shouldAutoFocus: true,
				shouldIncludeInBounds: true
			})
		];
	}

	return {
		setRoutePlayerDriverLocation,
		markersWithRoutePlayerDriverMarker,
		hasRealtimeDriverLocation,
		playerDisabled
	};
};

export default useRoutePlayer;
