import { action, computed, makeObservable, observable } from 'mobx';
import { ActionCoreData, ActionType, AlertSeverity, UserRole, CommunicationDataRecipient } from '@bringg/types';

import { ActionFamilyType, ClientAction, ClientAlertData } from '../utils/types';
import { ActionBaseStore, ActionsRepo } from './internal';
import { alertActionsWithTitle, mapAlertActions } from '../utils/mapper';
import { getRandomGuid } from '../utils/helpers';
import { isAlertActionValid } from 'bringg-web/features/automation-workflows/actions/validators/validator';

export default class AlertActionStore extends ActionBaseStore {
	title: string;
	actions: ClientAlertData[];
	severity: AlertSeverity = AlertSeverity.Medium;
	description_rich_text: string;

	type: ActionFamilyType = ActionFamilyType.NOTIFY;

	constructor(alert: ClientAction, actionsRepo: ActionsRepo) {
		super(actionsRepo);

		makeObservable(this, {
			actions: observable,
			title: observable,
			description_rich_text: observable,
			severity: observable,
			setTitle: action,
			removeAction: action,
			editAction: action,
			addAction: action,
			setSeverity: action,
			setDescriptionText: action,
			isValid: computed,
			groupedActions: computed,
			mappedToServer: computed,
			isTitleRequired: computed
		});

		this.update({ ...alert });
	}

	update(alert: ClientAction) {
		Object.assign(this, alert);
	}

	setDescriptionText = (editorStateString: string) => {
		this.description_rich_text = editorStateString;
	};

	setTitle = (title: string) => {
		this.title = title;

		this.actions.forEach(action => {
			if (
				action.action_type === ActionType.ALERT ||
				action.action_type === ActionType.EMAIL ||
				action.action_type === ActionType.INTERNAL_SMS
			) {
				action.data.title = title;
			}
		});
	};

	removeAction = (actionData: ClientAlertData) => {
		this.actions = this.actions.filter(a => a.guid !== actionData.guid);
	};

	editAction = (actionData: ClientAlertData) => {
		this.actions = this.actions.map(a => (a.guid === actionData.guid ? { ...actionData } : a));
	};

	addAction = (actionType: ActionType, roleType?: UserRole | CommunicationDataRecipient) => {
		switch (actionType) {
			case ActionType.ALERT:
				this.actions.push({
					guid: getRandomGuid(),
					action_type: actionType,
					data: {
						title: this.title,
						severity: this.severity,
						role: roleType as UserRole
					}
				});
				break;

			case ActionType.WEBHOOK:
				this.actions.push({
					guid: getRandomGuid(),
					action_type: actionType,
					data: null
				});
				break;

			case ActionType.EMAIL:
				this.actions.push({
					guid: getRandomGuid(),
					action_type: actionType,
					data: {
						title: this.title,
						recipient: roleType as CommunicationDataRecipient
					}
				});
				break;

			case ActionType.INTERNAL_SMS:
				this.actions.push({
					guid: getRandomGuid(),
					action_type: actionType,
					data: {
						title: this.title,
						role: roleType as UserRole,
						userIds: [],
						isRoleBased: false
					}
				});
				break;

			default:
				console.error('Unknown action type', {
					actionType,
					roleType
				});
		}
	};

	setSeverity = (severity: AlertSeverity) => {
		this.severity = severity;

		this.actions.forEach(action => {
			if (action.action_type === ActionType.ALERT) {
				action.data.severity = severity;
			}
		});
	};

	get isValid() {
		return isAlertActionValid(this);
	}

	get groupedActions(): ActionType[] {
		return [...new Set(this.actions.map(({ action_type }) => action_type))];
	}

	get mappedToServer(): ActionCoreData[] {
		const { actions, severity, title, description_rich_text } = this;

		return mapAlertActions({ actions, severity, title, description_rich_text });
	}

	get isTitleRequired(): boolean {
		return this.actions.some(({ action_type }) => alertActionsWithTitle.includes(action_type));
	}
}
