import React from 'react';

import { Portal } from '@bringg-frontend/bringg-web-infra';

import Timeoff from '../../time-off/time-off';
import useStores from '../../../recipes/use-stores';

const TimeOffPortal = () => {
	const { authStore } = useStores();

	return (
		<Portal
			element={<Timeoff timezone={authStore.merchant.time_zone} className="timeoff-page" />}
			nodeId={'timeoff-page'}
		/>
	);
};

export default TimeOffPortal;
