import React from 'react';
import { Button, Collapse, Tooltip } from '@bringg/react-components';
import { BreakType } from '@bringg/types';
import { BringgFontIcons, BringgIcon } from '@bringg/bringg-icons';
import moment from 'moment-timezone';
import { useTranslation } from 'react-i18next';
import { LocationOption } from '../../stores/domain-objects/delivery-block-break';
import { BreakItem } from './break/break-item';
import { BreaksSummary } from './breaks-summary/breaks-summary';
import { DeliveryBlockBreakBase } from './break-types';
import './breaks-panel.scss';
import { useHasFeatureFlag } from '../../../../utils/feature-flags';
import classNames from 'classnames';

const { Panel } = Collapse;

export interface Props {
	header?: string;
	key: number | string;
	props?: any;
	deliveryBlockBreaks: DeliveryBlockBreakBase[];
	timezone: string;
	use12Hours: boolean;
	format: string;
	onAddBreak: (breakItem: DeliveryBlockBreakBase) => void;
	onDeleteBreak: (breakItem: DeliveryBlockBreakBase) => void;
	onUpdateBreak: (breakIndex: number, updatedProperties: Partial<DeliveryBlockBreakBase>) => void;
	deliveryBlockTimes: { startTime: string; endTime: string };
	allowMultiDayOptimization: boolean;
	shouldBeDisabled?: boolean;
}

const DEFAULT_LENGTH = 30;
const MAX_BREAKS_LENGTH = 3;
export const BreaksPanel = ({
	deliveryBlockBreaks,
	timezone,
	format,
	use12Hours,
	onAddBreak,
	onDeleteBreak,
	onUpdateBreak,
	deliveryBlockTimes,
	allowMultiDayOptimization,
	shouldBeDisabled,
	...props
}: Props) => {
	const { t } = useTranslation();
	const relativeBreakEnabled = useHasFeatureFlag('enable_relative_breaks');

	const filterRelativeBreakByFF = (breakItem: DeliveryBlockBreakBase) =>
		relativeBreakEnabled ? breakItem : breakItem.break_type !== BreakType.Relative;

	const deliveryBlockBreaksFiltered = deliveryBlockBreaks.filter(filterRelativeBreakByFF);
	const getHeader = () => {
		return (
			<div className="panel-title">
				<BringgIcon iconName={BringgFontIcons.Cafe} className="panel-title-icon" />
				<span className="panel-title-translate">{t('DELIVERY_BLOCK_MODAL.BREAKS.BREAKS')}</span>
				<BreaksSummary breaks={deliveryBlockBreaksFiltered} format={format} timezone={timezone} />
			</div>
		);
	};

	const isAddBreakDisabled = deliveryBlockBreaksFiltered.length >= MAX_BREAKS_LENGTH;

	const getAddButton = () => {
		return (
			<Button
				type="link"
				className="add-break-button"
				onClick={addBreak}
				disabled={isAddBreakDisabled}
				style={isAddBreakDisabled ? { pointerEvents: 'none' } : {}}
			>
				<BringgIcon iconName={BringgFontIcons.Plus} />
				{t('DELIVERY_BLOCK_MODAL.BREAKS.ADD_BREAK')}
			</Button>
		);
	};

	const getAddButtonWrappedToolTipIfNeeded = () => {
		if (!isAddBreakDisabled) return getAddButton();

		return (
			<Tooltip
				key="add-break-button-tooltip"
				placement="right"
				title={t('DELIVERY_BLOCK_MODAL.BREAKS.ADD_BUTTON_DISABLED_REASON')}
			>
				<span style={{ cursor: isAddBreakDisabled ? 'not-allowed' : 'pointer' }}>{getAddButton()}</span>
			</Tooltip>
		);
	};

	const addBreak = () => {
		if (isAddBreakDisabled) return;

		const { startTime: startTimeBlock } = deliveryBlockTimes;
		let startTime = startTimeBlock;
		let endTime: string = moment(startTimeBlock).add(DEFAULT_LENGTH, 'minutes').toISOString();

		const { length } = deliveryBlockBreaksFiltered;

		if (length > 0) {
			const lastBreakWhichIsNotRelative = [...deliveryBlockBreaksFiltered]
				.reverse()
				.find(blockBreak => blockBreak.break_type !== BreakType.Relative);
			if (lastBreakWhichIsNotRelative) {
				startTime = moment(lastBreakWhichIsNotRelative.end_time).add(1, 'minutes').toISOString();
				endTime = moment(startTime).add(DEFAULT_LENGTH, 'minutes').toISOString();
			}
		}

		const breakItem: DeliveryBlockBreakBase = {
			start_time: startTime,
			end_time: endTime,
			break_type: BreakType.Flex,
			location_option: LocationOption.ANYWHERE,
			length: DEFAULT_LENGTH
		};
		onAddBreak(breakItem);
	};

	return (
		<Panel {...props} header={getHeader()} showArrow className="delivery-block-panel breaks-panel">
			<div className={classNames('breaks-items', { disabled: shouldBeDisabled })} data-test-id="breaks-items">
				{deliveryBlockBreaksFiltered.length > 0 &&
					deliveryBlockBreaksFiltered.map((breakItem, index) => (
						<BreakItem
							key={index.toString()}
							timezone={timezone}
							breakItem={breakItem}
							breakIndex={index}
							use12Hours={use12Hours}
							format={format}
							onDeleteBreak={onDeleteBreak}
							onUpdateBreak={onUpdateBreak}
							deliveryBlockTimes={deliveryBlockTimes}
							deliveryBlockBreaks={deliveryBlockBreaksFiltered}
							allowMultiDayOptimization={allowMultiDayOptimization}
							relativeBreakEnabled={relativeBreakEnabled}
						/>
					))}
			</div>
			{!shouldBeDisabled && <div className="add-break">{getAddButtonWrappedToolTipIfNeeded()}</div>}
		</Panel>
	);
};
