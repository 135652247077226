import { MutableRefObject, useCallback, useMemo } from 'react';

import type { TimelineEventPropertiesResult } from 'vis-timeline/types/entry-standalone';
import {
	find as _find,
	isFunction as _isFunction,
	isUndefined as _isUndefined,
	noop as _noop,
	debounce as _debounce,
	isEqual as _isEqual
} from 'lodash';

import type { TooltipRenderFunction } from '../components/timeline-item-tooltip/timeline-item-tooltip';
import { ClickFunc, GanttOptions, GanttTimeLineItem, TooltipEventOption } from '../gantt-types';
import {
	getTimelineItemElement,
	getTimelineItemsForClusterElement,
	isElementCluster
} from '../components/timeline-item-tooltip/tooltip.utils';

interface Props {
	options?: GanttOptions;
	items?: GanttTimeLineItem[];
	selectedItems: GanttTimeLineItem[];
	toolTipRender?: TooltipRenderFunction;
	preventUnnecessaryClick: MutableRefObject<boolean>;
	onItemClicked?: ClickFunc;
	onItemDoubleClicked?: ClickFunc;
	setSelectedItemElement: (value: HTMLElement | null) => void;
	setSelectedItems: (value: GanttTimeLineItem[] | null) => void;
	containerRef: MutableRefObject<HTMLDivElement | null>;
}

export const useGanttItemEvents = ({
	options,
	items,
	selectedItems,
	toolTipRender,
	preventUnnecessaryClick,
	onItemClicked,
	onItemDoubleClicked,
	setSelectedItemElement,
	setSelectedItems,
	containerRef
}: Props) => {
	const setItemForTooltip = useCallback(
		(
			eventItems: GanttTimeLineItem[] | null,
			relatedHtmlElement: HTMLElement | null,
			eventSource: TooltipEventOption = TooltipEventOption.Click
		) => {
			if (!toolTipRender || eventSource !== options?.tooltipBy) {
				return;
			}

			const isSameItem = _isEqual(
				eventItems?.map(item => item.id),
				selectedItems?.map(item => item.id)
			);

			if (isSameItem) {
				return;
			}

			setSelectedItemElement(relatedHtmlElement);
			setSelectedItems(eventItems);
		},
		[selectedItems, toolTipRender, options?.tooltipBy]
	);

	const onGanttItemHover = useCallback(
		(visHoverEvent: TimelineEventPropertiesResult) => {
			if (options?.tooltipBy !== TooltipEventOption.Hover || !_isFunction(toolTipRender)) {
				return;
			}

			const element = getTimelineItemElement(visHoverEvent.event as MouseEvent);

			const hoverableItems = (items || []).filter((item: GanttTimeLineItem) => {
				if (_isUndefined(item.hoverable)) {
					item.hoverable = true;
				}
				return item.hoverable;
			});

			if (isElementCluster(element)) {
				const clusterItems = getTimelineItemsForClusterElement(element, hoverableItems);
				if (clusterItems.length === 0) {
					// cluster which all items are not hoverable
					setItemForTooltip(null, null, TooltipEventOption.Hover);
					return;
				}

				setItemForTooltip(clusterItems, element, TooltipEventOption.Hover);
				return;
			}

			const singleItem = _find(hoverableItems, item => item.id === visHoverEvent.item);

			if (!singleItem?.hoverable) {
				setItemForTooltip(null, null, TooltipEventOption.Hover);
				return;
			}

			setItemForTooltip([singleItem], element, TooltipEventOption.Hover);
		},
		[options, items, setItemForTooltip, toolTipRender]
	);

	const debouncedTooltipHover = useMemo(
		() => _debounce(onGanttItemHover, options?.tooltipDelay),
		[onGanttItemHover, options?.tooltipDelay]
	);

	const hoverHandlers = useMemo(() => {
		return options?.tooltipBy === TooltipEventOption.Hover
			? {
					itemoverHandler: debouncedTooltipHover,
					itemoutHandler: () => setItemForTooltip(null, null, TooltipEventOption.Hover)
			  }
			: {};
	}, [debouncedTooltipHover, setItemForTooltip, options?.tooltipBy]);

	const triggerMouseDownEvent = () => {
		containerRef.current?.dispatchEvent(
			new MouseEvent('mousedown', {
				view: window,
				bubbles: true,
				cancelable: true
			})
		);
	};

	const onGanttItemClicked = useCallback(
		(visClickEvent: TimelineEventPropertiesResult) => {
			triggerMouseDownEvent();

			const specialKey =
				// @ts-ignore
				visClickEvent.event.ctrlKey || visClickEvent.event.shiftKey || visClickEvent.event.metaKey;

			if (preventUnnecessaryClick.current) {
				preventUnnecessaryClick.current = false;
				return;
			}

			if (!_isFunction(onItemClicked) && !toolTipRender) {
				return;
			}

			const onItemClickedFunc = onItemClicked ?? _noop;

			if (_isUndefined(visClickEvent.what)) {
				return; // pass weird behaviour of vis
			}

			if (visClickEvent.what !== 'item') {
				setItemForTooltip(null, null);
				return;
			}

			const element = getTimelineItemElement(visClickEvent.event as MouseEvent);
			const clickableItems = (items || []).filter((item: GanttTimeLineItem) => {
				if (_isUndefined(item.clickable)) {
					item.clickable = true;
				}
				return item.clickable;
			});

			if (specialKey) {
				return;
			}

			if (isElementCluster(element)) {
				const clusterItems = getTimelineItemsForClusterElement(element, clickableItems);
				if (clusterItems.length === 0) {
					// cluster which all items are not clickable
					setItemForTooltip(null, null);
					return;
				}

				setItemForTooltip(clusterItems, element);
				onItemClickedFunc(clusterItems, true, visClickEvent);
				return;
			}

			const singleItem = _find(clickableItems, item => item.id === visClickEvent.item);
			if (!singleItem?.clickable) {
				setItemForTooltip(null, null);
				return;
			}

			setItemForTooltip([singleItem], element);
			onItemClickedFunc([singleItem], false, visClickEvent);
		},
		[setItemForTooltip, toolTipRender, onItemClicked, items]
	);

	const onGanttItemDoubleClicked = useCallback(
		(visClickEvent: TimelineEventPropertiesResult) => {
			if (!_isFunction(onItemDoubleClicked)) {
				return;
			}

			if (visClickEvent.what !== 'item') {
				return;
			}

			const element = getTimelineItemElement(visClickEvent.event as MouseEvent);
			const clickableItems = (items || []).filter((item: GanttTimeLineItem) => {
				if (_isUndefined(item.clickable)) {
					item.clickable = true;
				}
				return item.clickable;
			});

			if (isElementCluster(element)) {
				const clusterItems = getTimelineItemsForClusterElement(element, clickableItems);
				if (clusterItems.length === 0) {
					// cluster which all items is not clickable
					return;
				}

				onItemDoubleClicked(clusterItems, true, visClickEvent);
				return;
			}

			const singleItem = _find(clickableItems, item => item.id === visClickEvent.item);
			if (!singleItem?.clickable) {
				return;
			}
			onItemDoubleClicked([singleItem], false, visClickEvent);
		},
		[onItemDoubleClicked, items]
	);

	return { onGanttItemClicked, onGanttItemDoubleClicked, hoverHandlers };
};
