import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { getRootEnv } from '@bringg-frontend/bringg-web-infra';
import { Button, Divider, Form, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { Modal } from '@bringg/react-components';
import notification from '@bringg/react-components/dist/components/notification/notification';
import { User } from '@bringg/types';
import { isEmpty, isNil } from 'lodash';

import DriverForm from './forms/driver-form';
import { executeAction } from '../../services/cross-application/cross-application';
import { useMerchantConfiguration, useStores } from '../../recipes';
import { DriverAction } from '../../services/cross-application/cross-application.actions';
import { getErrorDetailsTranslationKey } from '../../utils/get-translation-key';
import DispatcherForm from './forms/dispatcher-form';
import AdminForm from './forms/admin-form';

import './styles.scss';

const MODAL_ANIMATION_TIME = 300;

const userForms = {
	driver: DriverForm,
	dispatcher: DispatcherForm,
	admin: AdminForm
};

interface CreateEmployeeModalProps {
	onClose: () => void;
	modalParams: {
		showUserTypeSelection: boolean;
		defaultUserType: keyof typeof userForms;
	} & React.ComponentProps<typeof Modal>;
}

const filterEmpty = obj =>
	Object.fromEntries(
		Object.entries(obj).filter(([, v]) =>
			typeof v === 'object' || typeof v === 'string' ? !isEmpty(v) : !isNil(v)
		)
	);

const create = async (user: Partial<User>): Promise<User> => getRootEnv().dashboardSdk.sdk.users.create(user);

const CreateEmployeeModal = (props: CreateEmployeeModalProps) => {
	const { t } = useTranslation();
	const { onClose, modalParams } = props;
	const [isLoading, setIsLoading] = useState(false);
	const { authStore } = useStores();
	const { language_code } = useMerchantConfiguration();
	const [form] = Form.useForm();
	const [currentFormName, setCurrentFormName] = useState(modalParams.defaultUserType);
	const [afterSubmit, setAfterSubmit] = useState<() => void>(() => {
		// do nothing
	});

	const containerEl = useRef<HTMLDivElement>();

	const UserForm = useMemo(() => userForms[currentFormName], [currentFormName]);

	const submitForm = useCallback(
		async (user): Promise<void> => {
			setIsLoading(true);

			try {
				const finalUser = filterEmpty({
					...user,
					driver: false, // pass driver false as default, override with [currentFormName]
					[currentFormName]: true,
					// do not save language if merchant default one is the same
					language: user.language === language_code ? null : user.language ?? null,
					user_type_id: user.user_type_id ? Number(user.user_type_id) : user.user_type_id
				});

				const result = await create(finalUser as Partial<User>);

				notification.success(
					'Success',
					t(`CREATE_USER_MODAL.SUCCESSFULLY_CREATED_${currentFormName.toUpperCase()}`)
				);
				afterSubmit();
				executeAction(authStore.crossAppTransport, DriverAction.DRIVER_CREATED, result);
			} catch (error) {
				const additionalErrorKey = getErrorDetailsTranslationKey((error as any).details);

				if (error instanceof Error) {
					notification.error(
						t(`CREATE_USER_MODAL.FAILED_TO_CREATE_${currentFormName.toUpperCase()}`),
						additionalErrorKey ? t(additionalErrorKey) : null
					);
				}
			} finally {
				setIsLoading(false);
			}
		},
		[afterSubmit, t, authStore.crossAppTransport, currentFormName, language_code]
	);

	useEffect(() => {
		if (!containerEl) {
			return;
		}

		const timeout = setTimeout(() => {
			(containerEl.current.querySelector('.ant-input') as HTMLElement).focus();
		}, MODAL_ANIMATION_TIME);

		return () => clearTimeout(timeout);
	}, [containerEl]);

	return (
		<Modal
			className="new-user-modal"
			title={modalParams.title}
			visible
			width={600}
			afterClose={modalParams.afterClose}
			onCancel={event => {
				modalParams.onCancel(event);
				onClose();
			}}
			maskClosable={false}
			footer={[
				<Button
					key="saveAndCreate"
					className="save-and-create"
					type="primary"
					loading={isLoading}
					disabled={isLoading}
					onClick={() => {
						setAfterSubmit(() => () => form.resetFields());
						form.submit();
					}}
				>
					{t('CREATE_USER_MODAL.SAVE_AND_CREATE_NEW')}
				</Button>,
				<Button
					key="save"
					className="save"
					type="primary"
					disabled={isLoading}
					loading={isLoading}
					onClick={() => {
						setAfterSubmit(() => () => onClose());
						form.submit();
					}}
				>
					{t(`CREATE_USER_MODAL.CREATE_${currentFormName.toUpperCase()}`)}
				</Button>
			]}
		>
			<div className="modal-header">
				<h3>{t(currentFormName === 'driver' ? 'DRIVERS.ADD_DRIVER' : 'CREATE_USER_MODAL.ADD_USER')}</h3>
			</div>
			<div className="modal-body">
				<div ref={containerEl} className="container-fluid">
					{modalParams.showUserTypeSelection && (
						<>
							<div className="ant-form-item-label">
								<label
									className="ant-form-item-label ant-form-item-no-colon"
									title={t('CREATE_USER_MODAL.SELECT_TYPE')}
								>
									{t('CREATE_USER_MODAL.SELECT_TYPE')}
								</label>
							</div>
							<Select
								className="full-width"
								options={[
									{ value: 'driver', label: t('CREATE_USER_MODAL.DRIVER') },
									{ value: 'dispatcher', label: t('CREATE_USER_MODAL.DISPATCHER') },
									{ value: 'admin', label: t('CREATE_USER_MODAL.ADMIN') }
								]}
								value={currentFormName}
								onSelect={setCurrentFormName}
							/>
							<Divider />
						</>
					)}
					<UserForm form={form} onSubmit={submitForm} />
				</div>
			</div>
		</Modal>
	);
};

export default CreateEmployeeModal;
