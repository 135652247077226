import { useState, useCallback } from 'react';

export interface UseTableFilters<T> {
	filters: T;
	updateFilters: (newFilters) => void;
	resetFilters: (filterKey?: string) => void;
}

interface Props<T> {
	defaultFilters: T;
}
export const useTableFilters = <T extends Partial<T>>({ defaultFilters }: Props<T>): UseTableFilters<T> => {
	const [filters, setFilters] = useState<T>(defaultFilters);

	const updateFilters = useCallback(newFilters => {
		setFilters({ ...filters, ...newFilters });
	}, []);

	const resetFilters = useCallback(
		(filterKey?: string) => {
			if (filterKey) {
				updateFilters({ ...filters, [filterKey]: defaultFilters[filterKey] });
			} else {
				updateFilters(defaultFilters);
			}
		},
		[filters]
	);

	return { filters, updateFilters, resetFilters };
};
