import React, { memo, useMemo } from 'react';

import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import { TemplateTypeEnum } from '@bringg/types';

import styles from './styles.module.scss';

interface Props {
	type: TemplateTypeEnum;
}

function TemplateTypeCell({ type }: Props) {
	const { t } = useTranslation();

	const typeNames = useMemo(
		() => ({
			[TemplateTypeEnum.customer_notification_email]: t('EMAIL_TEMPLATES.EMAIL_TYPE'),
			[TemplateTypeEnum.package_label]: t('EMAIL_TEMPLATES.LABEL_TYPE')
		}),
		[t]
	);

	return (
		<span>
			<span
				className={classnames(styles.typeIcon, {
					[styles.emailType]: type === TemplateTypeEnum.customer_notification_email
				})}
			></span>
			{typeNames[type]}
		</span>
	);
}

export default memo(TemplateTypeCell);
