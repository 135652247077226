import { ExtendedMarkerData } from '../../types';

// Because Google Maps does not have a property to attach some custom data on the marker we create this solution
// (WeakMap to avoid memory handling when the marker is no longer in used)
const markerToMarkerData = new WeakMap<google.maps.Marker, ExtendedMarkerData>();

export function getDataForMarker(marker: google.maps.Marker): ExtendedMarkerData | undefined {
	return markerToMarkerData.get(marker);
}

export function setDataForMarker(marker: google.maps.Marker, data: ExtendedMarkerData) {
	markerToMarkerData.set(marker, data);
}

export function removeDataForMarker(marker: google.maps.Marker) {
	markerToMarkerData.delete(marker);
}
