import { Frequency } from 'rrule';

export const RECURRING_NO_REPEAT_FREQUENCY = -1;

const DEFAULT_RRULE_OPTIONS = {};

const defaultFrequencyOptions = {
	[Frequency.DAILY]: {
		interval: 1
	},
	[Frequency.WEEKLY]: {
		interval: 1,
		byweekday: []
	},
	[Frequency.MONTHLY]: {
		interval: 1,
		bymonthday: 1
	}
};

const allRecurringFrequencies = [RECURRING_NO_REPEAT_FREQUENCY, Frequency.DAILY, Frequency.WEEKLY, Frequency.MONTHLY];

export { DEFAULT_RRULE_OPTIONS, defaultFrequencyOptions, allRecurringFrequencies };
