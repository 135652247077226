import React from 'react';

import moment from 'moment';
import _times from 'lodash/times';
import _flatMapDeep from 'lodash/flatMapDeep';
import _get from 'lodash/get';
import _compact from 'lodash/compact';
import _isEmpty from 'lodash/isEmpty';
import { ActualBreak, BreakType, DeliveryBlockResource, Vehicle } from '@bringg/types';
import { TFunction } from 'i18next';
import { GanttTimeLineItem, GroupRow } from '@bringg-frontend/gantt-timeline';

import DeliveryBlockBreak, { DeliveryBlockBreakId } from '../stores/domain-objects/delivery-block-break';
import DeliveryBlock from '../stores/domain-objects/delivery-block';
import DeliveryBlockDriverAssign from '../driver-assign/delivery-block-driver-assign';
import Driver from '../../../stores/drivers/domain-object/driver';
import DeliveryBlocksStore from '../stores/delivery-blocks-store';
import DayViewTableRowContent from '../day-view-table-row-content/day-view-table-row-content';

// eslint-disable-next-line etc/no-commented-out-code
export type DeliveryBlockTimeLineIndex = [number, number]; // [deliveryBlockId, timelineStartRowIndex]
export type DeliveryBlockTimeLineCustomData = {
	deliveryBlockId: number;
	breakId?: DeliveryBlockBreakId;
	actualBreakId?: number;
	rechargeStartTime?: string;
};
export const ASSIGNED_SLOT_COLOR = '#439affc4';
export const UNASSIGNED_SLOT_COLOR = '#ffffffcc;';
export const BREAK_ITEM_COLOR = '#426EA0';
export const FLEX_BREAK_ITEM_COLOR = '#426ea066';

const createDeliveryBlockText = (title, capacity, originalCapacity) => `${title} ${capacity}/${originalCapacity}`;

const getRowIdByDeliveryBlockCapacity = (deliveryBlockStartIndex: number, capacityIndex: number): number =>
	deliveryBlockStartIndex + capacityIndex;

export const calculateAllBlocksBreaksLength = (deliveryBlock: DeliveryBlock[]) =>
	deliveryBlock.reduce((total, { delivery_block_breaks: breaks, capacity }) => {
		return total + calculateBreaksLength(breaks, capacity);
	}, 0);

export const calculateBreaksLength = (deliveryBlockBreaks: DeliveryBlockBreak[] = [], capacity = 0) =>
	deliveryBlockBreaks.reduce((total, { break_type, start_time, end_time, length: actualBreakLength }) => {
		const breakLength =
			break_type === BreakType.Flex
				? actualBreakLength
				: moment.duration(moment(end_time).diff(start_time)).asMinutes();
		return total + breakLength * capacity;
	}, 0);

export const createTimeLineRowsForDeliveryBlock = (
	deliveryBlock: DeliveryBlock,
	availableDrivers: Driver[],
	unavailableDrivers: Driver[],
	startIndex: number,
	searchValue = '',
	t: TFunction,
	isNewModalOfDeliveryBlock: boolean,
	enableVehicles: boolean
): GroupRow[] => {
	const {
		capacity,
		original_capacity,
		assignedDrivers,
		assignedDriversByResources,
		assignedVehicles,
		start_time,
		end_time,
		delivery_block_breaks
	} = deliveryBlock;

	const durationInMinutes = moment.duration(moment(end_time).diff(start_time)).asMinutes();
	const durationInHours = (durationInMinutes / 60).toFixed(1).replace(/\.0$/, '');

	const totalBreaksTimeInMinutes = calculateBreaksLength(delivery_block_breaks, 1);
	const durationInHoursWithoutBreaks = ((durationInMinutes - totalBreaksTimeInMinutes) / 60)
		.toFixed(1)
		.replace(/\.0$/, '');

	const resourcesArray = isNewModalOfDeliveryBlock
		? getFilteredResources(assignedDriversByResources, assignedVehicles, deliveryBlock, searchValue, enableVehicles)
		: getFilteredDrivers(assignedDrivers, searchValue);

	const assignedRows: GroupRow[] = resourcesArray
		.slice()
		.reverse()
		.map((resource, capacityIndex) => {
			let driver: Driver;
			let vehicle: Vehicle;
			if (isNewModalOfDeliveryBlock) {
				driver = assignedDriversByResources.get(resource.user_id);
				if (enableVehicles && resource.vehicle_id) {
					vehicle = assignedVehicles.get(resource.vehicle_id);
				}
			} else {
				driver = resource;
			}

			return {
				rowId: getRowIdByDeliveryBlockCapacity(startIndex, capacityIndex),
				rowCells: [
					{
						content:
							enableVehicles && isNewModalOfDeliveryBlock ? (
								<DayViewTableRowContent driver={driver} vehicle={vehicle} />
							) : (
								<DeliveryBlockDriverAssign
									isNewModalOfDeliveryBlock={isNewModalOfDeliveryBlock}
									driver={driver}
									deliveryBlock={deliveryBlock}
									availableDrivers={availableDrivers}
									unavailableDrivers={unavailableDrivers}
								/>
							),
						width: 130
					},
					{
						content: vehicle ? vehicle?.vehicle_type?.title : driver?.userTypeModel?.title || ' ',
						width: 130
					},
					{ content: `${durationInHours} ${t('GLOBAL.HOURS_SHORT')}`, width: 50 },
					{ content: `${durationInHoursWithoutBreaks} ${t('GLOBAL.HOURS_SHORT')}`, width: 50 }
				],
				hideRemoveButton: true
			};
		});

	let unassignedRows: GroupRow[] = [];

	if (_isEmpty(searchValue)) {
		const assignedRowsCount = isNewModalOfDeliveryBlock ? assignedRows.length : capacity;
		unassignedRows = _times(original_capacity - assignedRows.length, capacityIndex => ({
			rowId: getRowIdByDeliveryBlockCapacity(startIndex, assignedRowsCount + capacityIndex),
			rowCells: [
				{
					content:
						enableVehicles && isNewModalOfDeliveryBlock ? (
							<span>{t('GLOBAL.UNASSIGNED')}</span>
						) : (
							<DeliveryBlockDriverAssign
								isNewModalOfDeliveryBlock={isNewModalOfDeliveryBlock}
								deliveryBlock={deliveryBlock}
								availableDrivers={availableDrivers}
								unavailableDrivers={unavailableDrivers}
							/>
						),
					width: 130
				},
				{ content: <></>, width: 130 },
				{ content: `${durationInHours} ${t('GLOBAL.HOURS_SHORT')}`, width: 50 },
				{ content: `${durationInHoursWithoutBreaks} ${t('GLOBAL.HOURS_SHORT')}`, width: 50 }
			],
			hideRemoveButton: true
		}));
	}

	return [...assignedRows, ...unassignedRows];
};

export const createTimeLineItemsForDeliveryBlock = (
	deliveryBlock: DeliveryBlock,
	startIndex: number,
	searchValue: string,
	isNewModalOfDeliveryBlock: boolean,
	enableVehicles: boolean,
	teamTimezone: string
): GanttTimeLineItem[] => {
	const {
		id: deliveryBlockId,
		name,
		capacity,
		original_capacity,
		user_ids,
		start_time,
		end_time,
		delivery_block_resources,
		assignedDriversByResources,
		assignedVehicles,
		assignedDrivers
	} = deliveryBlock;

	const getContent = (rowId, resourcesCapacity) => {
		return rowId === startIndex ? createDeliveryBlockText(name, resourcesCapacity, original_capacity) : ' ';
	};

	const resources = isNewModalOfDeliveryBlock
		? getFilteredResources(assignedDriversByResources, assignedVehicles, deliveryBlock, searchValue, enableVehicles)
		: getFilteredDrivers(assignedDrivers, searchValue);

	const assignedItems: GanttTimeLineItem[] = resources.map((_, capacityIndex) => {
		const rowId = getRowIdByDeliveryBlockCapacity(startIndex, capacityIndex);
		return {
			id: `${name}_${rowId}`,
			rowId,
			start: moment(start_time).tz(teamTimezone).toISOString(),
			end: moment(end_time).tz(teamTimezone).toISOString(),
			className: 'delivery-block-slot-content-assigned',
			content: getContent(rowId, isNewModalOfDeliveryBlock ? delivery_block_resources.length : user_ids.length),
			color: ASSIGNED_SLOT_COLOR,
			customData: { deliveryBlockId },
			clusterable: false,
			editable: {
				draggableToOtherRow: false,
				draggable: deliveryBlock.isEditable
			}
		};
	});

	const assignedRowsCount = assignedItems.length;
	let unassignedItems: GanttTimeLineItem[] = [];

	if (_isEmpty(searchValue)) {
		unassignedItems = _times(original_capacity - assignedRowsCount, capacityIndex => {
			const rowId = getRowIdByDeliveryBlockCapacity(startIndex, assignedRowsCount + capacityIndex);
			return {
				id: `${name}_${rowId}`,
				rowId,
				start: moment(start_time).toISOString(),
				end: moment(end_time).toISOString(),
				className: 'delivery-block-slot-content-unassigned',
				content: getContent(rowId, capacity),
				color: UNASSIGNED_SLOT_COLOR,
				customData: { deliveryBlockId },
				clusterable: false,
				editable: {
					draggableToOtherRow: false,
					draggable: deliveryBlock.isEditable
				}
			};
		});
	}

	return [...assignedItems, ...unassignedItems];
};

export const createTimeLineItemsForDeliveryBlockBreaks = (
	deliveryBlock: DeliveryBlock,
	startIndex: number,
	searchValue: string,
	t: TFunction,
	isNewModalOfDeliveryBlock: boolean,
	enableVehicles: boolean,
	teamTimezone: string
): GanttTimeLineItem[] => {
	const {
		id: deliveryBlockId,
		delivery_block_breaks,
		original_capacity,
		assignedDrivers,
		name,
		assignedDriversByResources,
		assignedVehicles
	} = deliveryBlock;

	const assignedDriversDisplayedOrder = isNewModalOfDeliveryBlock
		? getFilteredResources(assignedDriversByResources, assignedVehicles, deliveryBlock, searchValue, enableVehicles)
		: getFilteredDrivers(assignedDrivers.slice().reverse(), searchValue);

	const rowsCount = _isEmpty(searchValue) ? original_capacity : assignedDriversDisplayedOrder.length;

	return _flatMapDeep(delivery_block_breaks, ({ id, start_time, end_time, break_type, length, actual_breaks }) =>
		_times(rowsCount, capacityIndex => {
			const isFlexBreak = break_type === BreakType.Flex;
			const rowId = getRowIdByDeliveryBlockCapacity(startIndex, capacityIndex);
			const color = isFlexBreak ? FLEX_BREAK_ITEM_COLOR : BREAK_ITEM_COLOR;
			const className = isFlexBreak ? 'delivery-block-flex-break-item' : 'delivery-block-break-item';
			let content = t('GLOBAL.BREAK');

			const currentRowAssignedDriver = _get(assignedDriversDisplayedOrder, `[${capacityIndex}]`);

			let actualBreakItem: GanttTimeLineItem;

			if (currentRowAssignedDriver) {
				const driverActualBreak = actual_breaks.find(({ user_id }) => user_id === currentRowAssignedDriver.id);
				if (driverActualBreak) {
					actualBreakItem = createTimeLineItemsForDeliveryBlockActualBreaks(
						deliveryBlock,
						id,
						capacityIndex,
						rowId,
						driverActualBreak,
						t,
						teamTimezone
					);
				}
			}

			if (isFlexBreak) {
				content = actualBreakItem ? ' ' : `${t('GLOBAL.BREAK')} (${length} ${t('GLOBAL.MIN')})`;
			}

			const breakItem = {
				id: `${name}_${deliveryBlockId}_${id}_${capacityIndex}`,
				rowId,
				content,
				color,
				className,
				start: moment(start_time).tz(teamTimezone).toISOString(),
				end: moment(end_time).tz(teamTimezone).toISOString(),
				customData: { deliveryBlockId, breakId: id },
				clusterable: false,
				editable: {
					draggableToOtherRow: false,
					draggable: deliveryBlock.isEditable
				}
			};

			return _compact([breakItem, actualBreakItem]);
		})
	);
};

export const createTimeLineItemsForDeliveryBlockActualBreaks = (
	deliveryBlock: DeliveryBlock,
	breakId: DeliveryBlockBreakId,
	capacityIndex: number,
	rowId: number,
	{ id, estimated_start_time, estimated_end_time }: ActualBreak,
	t,
	teamTimezone: string
): GanttTimeLineItem => {
	return {
		id: `${deliveryBlock.name}_${deliveryBlock.id}_${breakId}_${id}_${capacityIndex}`,
		rowId,
		content: t('GLOBAL.BREAK'),
		color: BREAK_ITEM_COLOR,
		className: 'delivery-block-break-item',
		start: moment(estimated_start_time).tz(teamTimezone).toISOString(),
		end: moment(estimated_end_time).tz(teamTimezone).toISOString(),
		customData: { deliveryBlockId: deliveryBlock.id, breakId, actualBreakId: id },
		clusterable: false,
		editable: {
			draggableToOtherRow: false,
			draggable: deliveryBlock.isEditable
		}
	};
};

export const createTimelineItemsForDeliveryBlockRecharges = (
	deliveryBlock: DeliveryBlock,
	startIndex: number,
	timeInMinutes: number,
	timezone: string
): GanttTimeLineItem[] => {
	return _flatMapDeep(deliveryBlock.delivery_block_recharges, ({ start_time }, index) =>
		_times(deliveryBlock.original_capacity, capacityIndex => {
			const start = moment(start_time).tz(timezone);
			const end = start.clone().add(timeInMinutes);

			const rowId = getRowIdByDeliveryBlockCapacity(startIndex, capacityIndex);

			return {
				id: `${rowId}-${deliveryBlock.id}-${index}-recharge`,
				rowId: rowId,
				start: start.toISOString(),
				end: end.toISOString(),
				className: 'gantt-recharge-time',
				clusterable: false,
				clickable: false,
				customData: {
					deliveryBlockId: deliveryBlock.id,
					rechargeStartTime: start_time
				}
			} as GanttTimeLineItem;
		})
	);
};

export const createDeliveryBlockSlotIndexes = (
	deliveryBlocks: DeliveryBlock[],
	searchValue: string,
	isNewModalOfDeliveryBlock: boolean,
	enableVehicles: boolean
): DeliveryBlockTimeLineIndex[] => {
	let currentIndex = 1;

	return deliveryBlocks.map(deliveryBlock => {
		const { assignedDrivers, assignedDriversByResources, assignedVehicles, id, original_capacity } = deliveryBlock;
		const deliveryBlockSlotIndex = [id, currentIndex] as DeliveryBlockTimeLineIndex;
		currentIndex += _isEmpty(searchValue)
			? original_capacity
			: isNewModalOfDeliveryBlock
			? getFilteredResources(
					assignedDriversByResources,
					assignedVehicles,
					deliveryBlock,
					searchValue,
					enableVehicles
			  ).length
			: getFilteredDrivers(assignedDrivers, searchValue).length;
		return deliveryBlockSlotIndex;
	});
};

export const getBusyDriversAtTimeWindow = (
	deliveryBlocksStore: DeliveryBlocksStore,
	deliveryBlockId: number,
	startTime: string,
	endTime: string
): Map<Driver, DeliveryBlock> => {
	const deliveryBlock = deliveryBlocksStore.get(deliveryBlockId);
	const busyDriverWithConflictedBlock: [Driver, DeliveryBlock][] = deliveryBlock.assignedDrivers.map(driver => {
		const conflictedDeliveryBlock = driver.findConflictedBlockWithRequestedTime(startTime, endTime, [
			deliveryBlock.id
		]);
		return conflictedDeliveryBlock ? [driver, conflictedDeliveryBlock] : null;
	});

	return new Map(_compact(busyDriverWithConflictedBlock));
};

export const getDriversCountByTime = (deliveryBlocks: DeliveryBlock[]): Map<number, number> => {
	const driversCountByTimeBlock: Map<number, number> = new Map();

	if (!deliveryBlocks) {
		return driversCountByTimeBlock;
	}

	deliveryBlocks.forEach((deliveryBlock: DeliveryBlock) => {
		let startIndex = moment(deliveryBlock.start_time).get('hours');
		const momentEndTime = moment(deliveryBlock.end_time);
		const endTimeHour = momentEndTime.get('hours');
		const endIndex = momentEndTime.get('minutes') > 0 ? endTimeHour : endTimeHour - 1;

		while (startIndex <= endIndex) {
			const currentCount = driversCountByTimeBlock.get(startIndex) || 0;
			driversCountByTimeBlock.set(startIndex, currentCount + deliveryBlock.capacity);
			startIndex += 1;
		}
	});

	return driversCountByTimeBlock;
};

export const getFilteredResources = (
	assignedDrivers: Map<number, Driver>,
	assignedVehicles: Map<number, Vehicle>,
	deliveryBlock: DeliveryBlock,
	searchValue: string,
	enableVehicles: boolean
): DeliveryBlockResource[] => {
	const filterValue = searchValue.toLowerCase();
	return deliveryBlock.delivery_block_resources.filter(resource => {
		let filterVehicle = false;
		let filterDriver = false;

		if (enableVehicles && resource.vehicle_id) {
			const vehicle = assignedVehicles.get(resource.vehicle_id);
			filterVehicle = vehicle?.name.toLowerCase().includes(filterValue);
		}
		if (resource.user_id) {
			const driver = assignedDrivers.get(resource.user_id);
			filterDriver = driver?.name.toLowerCase().includes(filterValue);
		}

		return (resource.user_id || resource.vehicle_id) && (filterVehicle || filterDriver);
	});
};

const getFilteredDrivers = (drivers: Driver[], filter: string): Driver[] => {
	return _isEmpty(filter)
		? drivers
		: drivers.filter(({ name }) => name.toLowerCase().includes(filter?.toLowerCase()));
};
