import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useObserver } from 'mobx-react-lite';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ExclusionWindow, PrivilegeTypes } from '@bringg/types';
import { Button } from '@bringg/react-components';
import { ExclusionWindowRequest } from '@bringg/dashboard-sdk/dist/ExclusionWindow/ExclusionWindow.consts';
import { first as _first, isNumber as _isNumber, find as _find, isEmpty as _isEmpty } from 'lodash';
import classNames from 'classnames';
import { withErrorBoundary } from '@bringg-frontend/bringg-web-infra';

import useStores from 'bringg-web/recipes/use-stores';
import TimeOffModal, { TimeoffForm } from './time-off-modal/time-off-modal';
import bringgNotification from 'bringg-web/services/notification';
import TimeoffTable from './time-off-table/time-off-table';
import TimeoffAttentionModal from './time-off-attention-modal/time-off-attention-modal';
import TimezoneService from 'bringg-web/services/timezone/timezone-service';
import { useHasAccess } from 'bringg-web/utils/privileges';

interface Props {
	teamId?: number;
	timezone?: string;
	className?: string;
}

const DefaultModalValues = {
	name: '',
	dates: null,
	repeatYearly: false
};

interface AttentionModalState {
	isOpen: boolean;
	onSubmit?: (shoulOverride: boolean) => void;
}

const TimeOff: React.FC<Props> = ({ teamId, timezone, className }) => {
	const { t } = useTranslation();
	const { exclusionWindowsStore } = useStores();
	const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
	const [isLoadingData, setIsLoadingData] = useState<boolean>(true);
	const [selectedTimeoffId, setSelectedTimeoffId] = useState<number>();
	const [attentionModalState, setAttentionModalState] = useState<AttentionModalState>({
		isOpen: false
	});
	const form = useForm<TimeoffForm>({ defaultValues: DefaultModalValues });
	const hasAccess = useHasAccess(PrivilegeTypes.EDIT_EXCLUSION_WINDOWS);

	const momentTz = useMemo(() => TimezoneService.getMomentTimezone(timezone), [timezone]);

	useEffect(() => {
		const fetchData = async () => {
			await exclusionWindowsStore.getOrFetch(teamId);
			setIsLoadingData(false);
		};

		fetchData();
	}, [teamId, exclusionWindowsStore]);

	const copyFromMerchant = useCallback(
		async (shouldOverride: boolean, copyId?: number): Promise<ExclusionWindow[]> => {
			try {
				if (shouldOverride && !copyId) {
					return;
				}

				let copiedExclusionWindow;

				if (copyId) {
					copiedExclusionWindow = await exclusionWindowsStore.copyFromMerchant(teamId, copyId);
				} else {
					copiedExclusionWindow = await exclusionWindowsStore.copyFromMerchant(teamId);
				}

				bringgNotification.success(t('TIMEOFF.SUCESSFULLY_COPIED'));

				return copiedExclusionWindow;
			} catch (e) {
				bringgNotification.error(t('TIMEOFF.FAILED_TO_COPY'));
			}
		},
		[exclusionWindowsStore, teamId, t]
	);

	const onAttentionModalSubmit = useCallback(
		async (
			shouldOverride: boolean,
			callback: (shouldOverride?: boolean, copiedExclusionWindows?: ExclusionWindow[]) => void,
			copyId?: number
		) => {
			const copiedExclusionWindows = await copyFromMerchant(shouldOverride, copyId);

			setAttentionModalState({
				isOpen: false
			});

			callback(shouldOverride, copiedExclusionWindows);
		},
		[copyFromMerchant]
	);

	const openAttentionModal = useCallback(
		(callback: (shouldOverride?: boolean, copiedExclusionWindows?: ExclusionWindow[]) => void, copyId?: number) => {
			// If Merchant level
			if (!teamId) {
				return callback();
			}

			const exclusionWindows = exclusionWindowsStore.getAll(teamId);

			// If team already disconnected from merchant
			if (_isEmpty(exclusionWindows) || _isNumber(_first(exclusionWindows).team_id)) {
				return callback();
			}

			setAttentionModalState({
				isOpen: true,
				onSubmit: async (shouldOverride: boolean) => onAttentionModalSubmit(shouldOverride, callback, copyId)
			});
		},
		[teamId, exclusionWindowsStore, onAttentionModalSubmit]
	);

	const editTimeoff = useCallback(
		(timeoff: ExclusionWindow, copiedExclusionWindow: ExclusionWindow[]) => {
			let timeoffToUpdate = timeoff;

			if (copiedExclusionWindow) {
				timeoffToUpdate = _find(copiedExclusionWindow, { src_id: timeoff.id }) as ExclusionWindow;
			}

			setSelectedTimeoffId(timeoffToUpdate.id);

			form.reset({
				name: timeoffToUpdate.name,
				dates: [momentTz(timeoffToUpdate.start_time), momentTz(timeoffToUpdate.end_time)],
				repeatYearly: Boolean(timeoffToUpdate.repeat)
			});

			setIsModalOpen(true);
		},
		[form, momentTz]
	);

	const deleteTimeoff = useCallback(
		async (id: number, shouldOverride: boolean, copiedExclusionWindow: ExclusionWindow[]) => {
			if (shouldOverride) {
				return;
			}

			let deleteId = id;

			if (copiedExclusionWindow) {
				deleteId = (_find(copiedExclusionWindow, { src_id: id }) as ExclusionWindow).id;
			}

			try {
				await exclusionWindowsStore.delete(deleteId, teamId);
				bringgNotification.success(t('TIMEOFF.SUCESSFULLY_DELETED'));
			} catch (e) {
				bringgNotification.error(t('TIMEOFF.FAILED_DELETING'));
			}
		},
		[teamId, exclusionWindowsStore, t]
	);

	const handleEditClick = useCallback(
		(timeoff: ExclusionWindow) => {
			openAttentionModal(
				(_, copiedExclusionWindows: ExclusionWindow[]) => editTimeoff(timeoff, copiedExclusionWindows),
				timeoff.id
			);
		},
		[openAttentionModal, editTimeoff]
	);

	const handleDeleteClick = useCallback(
		async (id: number) => {
			openAttentionModal(async (shouldOverride: boolean, copiedExclusionWindows: ExclusionWindow[]) =>
				deleteTimeoff(id, shouldOverride, copiedExclusionWindows)
			);
		},
		[openAttentionModal, deleteTimeoff]
	);

	const handleAddTimeoffClick = useCallback(() => {
		openAttentionModal(() => setIsModalOpen(true));
	}, [openAttentionModal]);

	const handleModalClose = useCallback(() => {
		setIsModalOpen(false);
		form.reset(DefaultModalValues);
		setSelectedTimeoffId(null);
	}, [form]);

	const handleFormSubmit = useCallback(async () => {
		const timeoff = form.getValues();

		const timeoffRequest = {
			name: timeoff.name,
			start_time: momentTz(timeoff.dates[0]).startOf('day').toISOString(),
			end_time: momentTz(timeoff.dates[1]).endOf('day').toISOString(),
			repeat: timeoff.repeatYearly,
			team_id: teamId
		} as ExclusionWindowRequest;

		try {
			await exclusionWindowsStore.createOrUpdate(timeoffRequest, selectedTimeoffId);
			bringgNotification.success(t('TIMEOFF.SUCESSFULLY_SAVED'));
			handleModalClose();
		} catch (e) {
			bringgNotification.error(t('TIMEOFF.FAILED_SAVING'));
		}
	}, [form, selectedTimeoffId, momentTz, exclusionWindowsStore, handleModalClose, t, teamId]);

	const handleAttentionModalCancel = useCallback(() => {
		setAttentionModalState({
			isOpen: false
		});
	}, []);

	return useObserver(() => (
		<div className={classNames('timeoff', className)}>
			<TimeoffTable
				isLoading={isLoadingData}
				data={exclusionWindowsStore.getAll(teamId)}
				onEdit={handleEditClick}
				onDelete={handleDeleteClick}
				timezone={timezone}
			/>
			{hasAccess && (
				<Button className="add-timeoff-btn" type="primary" onClick={handleAddTimeoffClick}>
					{t('TIMEOFF.ADD_TIME_OFF')}
				</Button>
			)}
			<TimeOffModal visible={isModalOpen} onCancel={handleModalClose} onSubmit={handleFormSubmit} form={form} />
			<TimeoffAttentionModal
				visible={attentionModalState.isOpen}
				onOk={attentionModalState.onSubmit}
				onCancel={handleAttentionModalCancel}
			/>
		</div>
	));
};

export default withErrorBoundary(TimeOff);
