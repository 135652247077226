import { useState } from 'react';

import { ServiceArea } from '@bringg/types';

import TeamsStore from '../stores/teams/teams-store';

function reverseCoordinatesForMapbox(serviceArea: ServiceArea) {
	if (!serviceArea.polygon) return null;

	serviceArea.polygon = serviceArea.polygon.map(([lat, lng]) => [lng, lat]);
	return serviceArea;
}

const useServiceAreas = (teamsStore: TeamsStore, teamId: number, neighbours: boolean, latLngFF) => {
	const [serviceAreaById, setServiceAreaById] = useState(new Map<number, ServiceArea>());

	const getAllServiceAreas = async () => {
		let serviceAreas = await teamsStore.getServiceAreas(teamId, neighbours);
		serviceAreas = serviceAreas?.filter(serviceArea => !!serviceArea.polygon);

		if (latLngFF) {
			serviceAreas = serviceAreas.map(reverseCoordinatesForMapbox);
		}

		setServiceAreaById(new Map(serviceAreas.map(serviceArea => [serviceArea.id, serviceArea])));

		return serviceAreas;
	};

	return { getAllServiceAreas, serviceAreaById };
};

export default useServiceAreas;
