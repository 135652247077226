import { useEffect, useRef } from 'react';

export function usePrevious<T>(prop: T): T | undefined {
	const ref = useRef<T>();
	useEffect(() => {
		ref.current = prop;
	});
	return ref.current;
}

export function usePropOnce(prop, errorMessage = 'prop should update once') {
	const prevValue = usePrevious(prop);

	if (prevValue === undefined) return;

	if (prevValue !== prop) throw new Error(errorMessage);
}
