import { getRootEnv } from '@bringg-frontend/bringg-web-infra';
import { datetime } from '@bringg/types';
import { action, computed, observable, makeObservable } from 'mobx';
import { getEnv } from 'mobx-easy';
import { RootEnv } from '@bringg-frontend/bringg-web-infra';

export default class TagMerchantConfiguration {
	id: number;
	merchant_id: number;
	tag_id: number;
	sharing_mode = 0;
	created_at?: datetime;
	updated_at?: datetime;

	constructor(config: Partial<Bringg.TagCustomerConfiguration>) {
		makeObservable(this, {
			sharing_mode: observable,
			updateSharingMode: action,
			sharingMode: computed
		});

		Object.assign(this, config);
	}

	updateSharingMode = (mode: number) => {
		this.sharing_mode = mode;
	};

	get sharingMode(): number {
		return this.sharing_mode;
	}

	async upsert(): Promise<Bringg.TagMerchantConfiguration> {
		let response;
		if (this.id) {
			response = await getRootEnv().dashboardSdk.sdk.tagMerchantConfigurations.update(this.id, this);
		} else {
			response = await getRootEnv().dashboardSdk.sdk.tagMerchantConfigurations.create(this.tag_id, this);
		}
		return response.tag_merchant_configuration;
	}
}
