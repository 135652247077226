import React from 'react';
import { observer } from 'mobx-react';

import { useStores } from 'bringg-web/recipes';

interface Props {
	userId: number;
}

function CreatorNameCell({ userId }: Props) {
	const {
		driversStore: { get: getUser }
	} = useStores();

	return <span>{userId ? getUser(userId)?.name : '–'}</span>;
}

export default observer(CreatorNameCell);
