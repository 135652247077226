import React from 'react';
import classNames from 'classnames';

import type { Row } from 'react-table';

import { SELECTION_COLUMN_ID, ACTIONS_COLUMN_ID } from '../../../consts';

interface TableRowProps<T extends Record<string, unknown> = Record<string, unknown>> {
	row: Row<T>;
}

const TableRow = <T extends Record<string, unknown> = Record<string, unknown>>({ row }: TableRowProps<T>) => {
	return (
		<>
			{row.cells.map(cell => {
				const { style, ...rest } = cell.getCellProps();
				return (
					<div
						{...rest}
						className={classNames('table-data-cell', {
							'row-selection-cell': cell.column.id === SELECTION_COLUMN_ID,
							'row-actions-cell': cell.column.id === ACTIONS_COLUMN_ID
						})}
						style={{ ...style, display: 'flex', alignItems: 'center' }}
					>
						<div className="cell-data">{cell.render('Cell')}</div>
					</div>
				);
			})}
		</>
	);
};

export default TableRow;
