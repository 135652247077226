import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import { Portal } from '..';
import DeliveryCatalog from './delivery-catalog';

export const DeliveryCatalogPortal = () => {
	const history = useHistory();

	const handleOpenFleetsList = useCallback(
		deliveryProvider => {
			history.push(`delivery-catalog-connect/${deliveryProvider.id}`);
		},
		[history]
	);

	return (
		<Portal
			element={<DeliveryCatalog onOpenFleetsList={handleOpenFleetsList} className="singleton" />}
			nodeId="delivery-catalog-portal"
		/>
	);
};
