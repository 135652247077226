import { action, computed, makeObservable, observable, reaction, runInAction } from 'mobx';
import { getRoot } from 'mobx-easy';
import i18next from 'i18next';
import { ActionType, ALERT_TYPES, AlertSeverity } from '@bringg/types';

import { getRandomGuid } from '../../utils/mapper';
import RootStore from 'bringg-web/stores/root-store';
import { workflowsRootStore } from '../workflows-root-store';

export interface PredefinedAlertConf {
	admin: boolean;
	dispatcher: boolean;
	webhooks: boolean;
}

export interface PredefinedAlert {
	id: number;
	title: string;
	admin: boolean;
	dispatcher: boolean;
	webhook: boolean;
	severity: AlertSeverity;
	alert: PredefinedAlertConf;
	isPredefined?: boolean;
	description?: string;
}

export const PREDEFINED_AVAILABLE_ACTIONS_TYPE: ActionType[] = [ActionType.ALERT, ActionType.WEBHOOK];

class PredefinedAlertStore {
	id: number;
	guid: number = getRandomGuid();
	isPredefined = true;
	title: string;
	description: string;
	admin: boolean;
	dispatcher: boolean;
	webhook: boolean;
	severity: AlertSeverity;

	alert?: PredefinedAlertConf;

	isAdding = false;

	constructor(alert: PredefinedAlert) {
		makeObservable(this, {
			admin: observable,
			dispatcher: observable,
			webhook: observable,
			alert: observable,
			isAdding: observable,
			update: action,
			save: action,
			isDirty: computed,
			discardChanges: action,
			matchingPredefinedAlert: computed,
			remove: action,
			isEnabled: computed
		});

		this.update(alert);

		reaction(
			() => this.matchingPredefinedAlert,
			() => {
				const matchingPredefinedAlert = this.matchingPredefinedAlert || {
					admin: false,
					dispatcher: false,
					webhooks: false
				};

				this.admin = matchingPredefinedAlert.admin;
				this.dispatcher = matchingPredefinedAlert.dispatcher;
				this.webhook = matchingPredefinedAlert.webhooks;
				this.alert = { ...matchingPredefinedAlert };
			}
		);
	}

	get matchingPredefinedAlert() {
		return getRoot<RootStore>().data.merchantConfigurationsStore?.configuration?.alert_configurations?.[this.id];
	}

	async add() {
		this.isAdding = true;
		try {
			await workflowsRootStore.getStore().predefinedAlertRepo.add(this.id);
		} finally {
			runInAction(() => {
				this.isAdding = false;
			});
		}
	}

	update(alert: Partial<PredefinedAlert>) {
		Object.assign(this, alert);
	}

	save = async () => {
		const config = {
			admin: this.admin,
			dispatcher: this.dispatcher,
			webhooks: this.webhook
		};
		const { merchantConfigurationsStore } = getRoot<RootStore>().data;
		merchantConfigurationsStore.configuration.alert_configurations[this.id] = config;
		try {
			await merchantConfigurationsStore.update();
			runInAction(() => Object.assign(this.alert, config));
		} catch (err) {
			console.error('failed to update merchant configuration: ', err);
			throw err;
		}
	};

	remove = async () => {
		Object.assign(this, {
			admin: false,
			dispatcher: false,
			webhook: false
		});
		await this.save();
	};

	get isDirty() {
		return (
			this.admin !== this.alert.admin ||
			this.dispatcher !== this.alert.dispatcher ||
			this.webhook !== this.alert.webhooks
		);
	}

	get isEnabled() {
		return this.alert.admin || this.alert.dispatcher || this.alert.webhooks;
	}

	discardChanges = () => {
		Object.assign(this, {
			admin: this.alert.admin,
			dispatcher: this.alert.dispatcher,
			webhook: this.alert.webhooks
		});
	};

	static create(
		predefined = {
			admin: false,
			dispatcher: false,
			webhooks: false
		},
		alertType
	) {
		return new this({
			id: Number(alertType),
			title: i18next.t(`ALERT_TYPES.${ALERT_TYPES[alertType]}`),
			alert: predefined,
			admin: predefined.admin,
			dispatcher: predefined.dispatcher,
			webhook: predefined.webhooks,
			severity: AlertSeverity.Medium,
			description: i18next.t(
				`ALERT_TYPES.DESCRIPTION.${ALERT_TYPES[Number(alertType)]}`,
				i18next.t('ALERTS_CONFIGURATION.NEW_SET_DEFAULT_DESCRIPTION')
			)
		});
	}
}

export default PredefinedAlertStore;
