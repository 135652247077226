import { useMemo } from 'react';

import classNames from 'classnames';
import { BringgFontIcons, BringgIcon } from '@bringg/bringg-icons';
import { Modal, Button, Progress } from '@bringg/react-components';
import { Failure } from '@bringg-frontend/batch-action-status';

import './resource-results-modal.scss';

export type Props = {
	onClose: () => void;
	translations: Record<any, any>;
	currentNumberOfActionsSucceeded: number;
	totalNumberOfActions: number;
	failures?: Failure[];
	onMinimize: () => void;
	renderFailures?: (failures: Failure[]) => React.ReactNode;
	renderFooter?: (failures: Failure[]) => React.ReactNode;
	width?: number;
	className?: string;
};

export default function ResourceResultsModal({
	onClose,
	translations: t,
	failures = [],
	currentNumberOfActionsSucceeded,
	totalNumberOfActions,
	onMinimize,
	renderFailures,
	renderFooter,
	width,
	className
}: Props) {
	const currentNumber = useMemo(
		() => (failures?.length || 0) + currentNumberOfActionsSucceeded,
		[failures, currentNumberOfActionsSucceeded]
	);
	const completed = useMemo(() => currentNumber === totalNumberOfActions, [currentNumber, totalNumberOfActions]);
	const percent = useMemo(() => (currentNumber / totalNumberOfActions) * 100, [currentNumber, totalNumberOfActions]);
	const hasFailures = useMemo(() => Boolean(failures?.length), [failures]);

	return (
		<Modal
			maskClosable={false}
			destroyOnClose
			className={classNames(className, 'resource-results-modal')}
			open
			closeIcon={<></>}
			onCancel={onClose}
			footer={null}
			width={hasFailures ? width : undefined}
		>
			<div className="resource-results-title">
				{t.title}
				<div className="resource-results-actions">
					<Button
						data-test-id="resource-async-operation-minimize"
						type="ghost"
						icon={<BringgIcon iconName={BringgFontIcons.Minimize} />}
						onClick={onMinimize}
					/>
					<Button
						data-test-id="resource-async-operation-close"
						type="ghost"
						icon={<BringgIcon iconName={BringgFontIcons.Close} />}
						onClick={onClose}
					/>
				</div>
			</div>

			{!completed && (
				<div>
					<Progress
						status="success"
						className="resource-results-progress"
						type="circle"
						size={68}
						percent={percent}
					/>
					<span className="resource-results-text">
						<span className="resource-results-success">
							{currentNumber}/{totalNumberOfActions}
						</span>{' '}
						{t.progress}
					</span>
				</div>
			)}

			{completed && (
				<div>
					{Boolean(currentNumberOfActionsSucceeded) && (
						<div>
							<BringgIcon className="resource-results-success" iconName={BringgFontIcons.Selected} />
							<strong>
								{hasFailures
									? `${currentNumberOfActionsSucceeded} ${t.someSuccess}`
									: `${currentNumberOfActionsSucceeded} ${t.allSuccess}`}
							</strong>
						</div>
					)}

					{hasFailures && (
						<div style={{ marginBottom: 16 }}>
							<BringgIcon className="resource-results-error" iconName={BringgFontIcons.Warning} />
							<strong>{failures.length}</strong> {t.someFailed}
						</div>
					)}

					{hasFailures && renderFailures?.(failures)}
					{renderFooter?.(failures)}
				</div>
			)}
		</Modal>
	);
}
