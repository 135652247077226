import { getRoot } from 'mobx-easy';
import moment from 'moment';
import { DeliveryBlock, DeliveryBlockResource } from '@bringg/types';

import RootStore from 'bringg-web/stores/root-store';

export const buildResourceWithBlockData = (deliveryBlock: DeliveryBlock, resource: DeliveryBlockResource) => {
	const timeZone = getRoot<RootStore>().data.teamsStore.get(deliveryBlock.team_id)?.time_zone || '';
	return {
		...resource,
		startTime: moment.tz(deliveryBlock.start_time, timeZone).toString(),
		endTime: moment.tz(deliveryBlock.end_time, timeZone).toString(),
		teamId: deliveryBlock.team_id
	};
};
