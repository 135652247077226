export enum FieldComponent {
	LABEL,
	DYNAMIC_LABEL,
	MANDATORY,
	TYPE,
	LIST_VALUES,
	DEFAULT_VALUE,
	DISCLAIMER
}

export interface FieldSetting {
	name: string;
	components: FieldComponent[];
	disabled?: boolean;
}

export enum FieldType {
	Field,
	Checkbox,
	Switch,
	Radio,
	Dropdown,
	Signature,
	Photo,
	Label,
	Logo,
	DisplayInventory = 11,
	Address = 14
}

export const fieldTypes: Map<FieldType, FieldSetting> = new Map([
	[
		FieldType.Field,
		{ name: 'Field', components: [FieldComponent.LABEL, FieldComponent.MANDATORY, FieldComponent.TYPE] }
	],
	[
		FieldType.Checkbox,
		{
			name: 'Checkbox',
			components: [FieldComponent.LABEL, FieldComponent.MANDATORY]
		}
	],
	[
		FieldType.Switch,
		{
			name: 'Switch',
			components: [FieldComponent.LABEL, FieldComponent.MANDATORY]
		}
	],
	[FieldType.Radio, { name: 'Radio', components: [], disabled: true }],
	[
		FieldType.Dropdown,
		{
			name: 'Dropdown',
			components: [
				FieldComponent.LABEL,
				FieldComponent.MANDATORY,
				FieldComponent.TYPE,
				FieldComponent.LIST_VALUES
			]
		}
	],
	[
		FieldType.Signature,
		{
			name: 'Signature',
			components: [FieldComponent.LABEL, FieldComponent.MANDATORY, FieldComponent.TYPE, FieldComponent.DISCLAIMER]
		}
	],
	[
		FieldType.Photo,
		{ name: 'Photo', components: [FieldComponent.LABEL, FieldComponent.MANDATORY, FieldComponent.DEFAULT_VALUE] }
	],
	[FieldType.Label, { name: 'Label', components: [FieldComponent.TYPE, FieldComponent.DYNAMIC_LABEL] }],
	[FieldType.Logo, { name: 'Logo', components: [FieldComponent.TYPE] }],
	[FieldType.DisplayInventory, { name: 'Display Inventory Items', components: [FieldComponent.LABEL] }],
	[FieldType.Address, { name: 'Address Input', components: [FieldComponent.LABEL] }]
]);

export const enum ControlFormatType {
	Number = 0,
	Text = 1,
	Boolean = 2,
	List = 4,
	Draw = 5
}

export const BooleanOnlyFieldTypes: FieldType[] = [FieldType.Switch, FieldType.Checkbox];

export const FieldTypesWithDisabledFormat: FieldType[] = [...BooleanOnlyFieldTypes];

export const controlFormatTypes: Map<ControlFormatType, string> = new Map([
	[ControlFormatType.Number, 'Number'],
	[ControlFormatType.Text, 'Text'],
	[ControlFormatType.Boolean, 'Boolean'],
	[ControlFormatType.List, 'List'],
	[ControlFormatType.Draw, 'Draw (Signature)']
]);
