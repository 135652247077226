import { computed, action, observable, makeObservable } from 'mobx';
import _isNil from 'lodash/isNil';

export default class FleetTemplate {
	id: number = null;
	name: string = null;
	fleet_id: number = null;

	constructor(fleetTemplate: Bringg.FleetTemplate) {
		makeObservable(this, {
			id: observable,
			name: observable,
			fleet_id: observable,
			setFleetId: action,
			isRegistered: computed
		});

		Object.assign(this, fleetTemplate);
	}

	setFleetId(fleetId: number) {
		this.fleet_id = fleetId;
	}

	get isRegistered(): boolean {
		return !_isNil(this.fleet_id);
	}
}
