import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { useObserver } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { pick, omitBy, isNil, isEmpty } from 'lodash';
import { Col, Row, Button, Spin, Tag, Drawer, Affix, BackTop } from 'antd';
import classNames from 'classnames';
import { BringgFontIcons, BringgIcon } from '@bringg/bringg-icons';
import { useStores } from '../../recipes';
import DeliveryCatalogFilterBar from './delivery-catalog-filter-bar';
import DeliveryCatalogContactUsModal from './delivery-catalog-contact-us-modal';
import DeliveryCatalogDetails from './delivery-catalog-details';
import { ComingSoonStatus, TurnkeyStatus, EcoFriendlyStatus, FavoriteButton } from './components';
import { FALLBACK_LOGO } from '../../consts';

import './delivery-catalog.scss';
import { Close } from './icons';

interface DeliveryProviderCardProps {
	deliveryProvider: any;
	onClickDetails: (any) => void;
	onToggleFavorite: (any) => void;
}

const DeliveryProviderCard = ({ deliveryProvider, onClickDetails, onToggleFavorite }: DeliveryProviderCardProps) => {
	const { t } = useTranslation();

	const handleToggleFavorite = useCallback(event => {
		event.stopPropagation();
		onToggleFavorite(deliveryProvider);
	}, []);

	const handleClickDetails = useCallback(() => {
		onClickDetails(deliveryProvider);
	}, []);

	return (
		// eslint-disable-next-line
		<div className="delivery-catalog-card" onClick={handleClickDetails}>
			<div className="delivery-catalog-card-header">
				<FavoriteButton isActive={deliveryProvider.favorite} onClick={handleToggleFavorite} />
				{deliveryProvider.connected ? <Tag className="connected-tag">Connected</Tag> : null}
			</div>

			<div>
				<div className="delivery-catalog-card-body">
					<img
						src={deliveryProvider.absolute_logo_path || FALLBACK_LOGO}
						alt={deliveryProvider.name}
						loading="lazy"
					/>
				</div>

				<div className="delivery-catalog-card-name">{deliveryProvider.name}</div>

				<div className="delivery-catalog-card-footer">
					<div className="delivery-catalog-card-footer-icons">
						<TurnkeyStatus
							status={deliveryProvider.bringg_turnkey}
							tooltip={t('DELIVERY_PROVIDER_CATALOG.DETAILS.TURNKEY')}
						/>
						<EcoFriendlyStatus
							status={deliveryProvider.eco_friendly}
							tooltip={t('DELIVERY_PROVIDER_CATALOG.DETAILS.ECO_FRIENDLY')}
						/>
						<ComingSoonStatus status={deliveryProvider.status} />
					</div>
					<Button type="link">{deliveryProvider.connected ? 'Configure' : 'Details'}</Button>
				</div>
			</div>
		</div>
	);
};

interface DeliveryProviderDetailsProps {
	isOpened: boolean;
	deliveryProvider: any;
	onClose: () => void;
	onEmail: () => void;
	onToggleFavorite: (object) => void;
	onOpenFleets: () => void;
}

const DeliveryProviderDetails = ({
	isOpened,
	deliveryProvider,
	onClose,
	onEmail,
	onToggleFavorite,
	onOpenFleets
}: DeliveryProviderDetailsProps) => {
	const { t } = useTranslation();

	const footerButton = useMemo(() => {
		let text;

		const isConfigurable = !isEmpty(deliveryProvider?.setup_configuration);
		const providerCanBeOpened = isConfigurable || deliveryProvider?.connected;

		const onClick = () => (providerCanBeOpened ? onOpenFleets() : onEmail());

		if (deliveryProvider?.connected) {
			text = t('DELIVERY_PROVIDER_CATALOG.DETAILS.CONFIGURE');
		} else if (isConfigurable) {
			text = 'Connect';
		} else {
			text = t('DELIVERY_PROVIDER_CATALOG.DETAILS.CONTACT_US');
		}

		return (
			<Button className="delivery-catalog-details-button" key="submit" type="primary" block onClick={onClick}>
				{text}
			</Button>
		);
	}, [deliveryProvider]);

	return (
		<Drawer
			footer={footerButton}
			maskStyle={{ backgroundColor: 'rgba(26, 32, 44, 0.24)' }}
			className="delivery-catalog-details-drawer"
			closeIcon={<Close />}
			visible={isOpened}
			open={isOpened}
			onClose={onClose}
			placement="right"
			width={422}
		>
			<DeliveryCatalogDetails deliveryProvider={deliveryProvider} onToggleFavorite={onToggleFavorite} />
		</Drawer>
	);
};

interface DeliveryProviderList {
	isFetching: boolean;
	deliveryProviders: any[];
	onClickDetails: (details: { id: string }) => void;
	onToggleFavorite: (object) => void;
}

const DeliveryProviderList = ({
	isFetching,
	deliveryProviders,
	onClickDetails,
	onToggleFavorite
}: DeliveryProviderList) => {
	const { t } = useTranslation();

	return (
		<div className="delivery-catalog-list">
			<div className="delivery-catalog-list-found">
				{!isFetching ? (
					<span>
						{deliveryProviders.length} {t('DELIVERY_PROVIDER_CATALOG.MAIN.DELIVERY_PROVIDERS_WERE_FOUND')}
					</span>
				) : null}
			</div>

			<div className="delivery-catalog-list-content">
				{isFetching ? (
					<Row justify="center">
						<Col>
							<Spin spinning={isFetching} />
						</Col>
					</Row>
				) : (
					<Row gutter={[25, 25]}>
						{deliveryProviders.map(deliveryProvider => (
							<Col key={deliveryProvider.id}>
								<DeliveryProviderCard
									deliveryProvider={deliveryProvider}
									onClickDetails={onClickDetails}
									onToggleFavorite={onToggleFavorite}
								/>
							</Col>
						))}
					</Row>
				)}
			</div>
		</div>
	);
};

interface DeliveryCatalog {
	onOpenFleetsList: (activeDelivery) => void;
	className?: string;
}

const DeliveryCatalog = ({ onOpenFleetsList, className }: DeliveryCatalog) => {
	const { deliveryCatalogStore, merchantConfigurationsStore } = useStores();

	const [isDetailsOpened, setIsDetailsOpened] = useState(false);
	const [activeDeliveryProvider, setActiveDeliveryProvider] = useState(null);
	const [isEmailModalActive, setIsEmailModalActive] = useState(false);

	const container = document.querySelector<HTMLElement>('.right-side');

	const onClickDetails = ({ id }) => {
		setActiveDeliveryProvider(deliveryCatalogStore.deliveryProviders.get(id));
		setIsDetailsOpened(true);
	};

	const onCloseDetails = () => {
		setIsDetailsOpened(false);
	};

	const toggleEmailModal = () => {
		setIsEmailModalActive(!isEmailModalActive);
	};

	const handleToggleFavorite = async ({ id }) => {
		await deliveryCatalogStore.toggleFavorite(id);
		setActiveDeliveryProvider(deliveryCatalogStore.deliveryProviders.get(id));
	};

	const handleSendEmail = async params => {
		await deliveryCatalogStore.sendEmail(params);
	};

	const handleOpenFleets = () => {
		onCloseDetails();
		onOpenFleetsList(activeDeliveryProvider);
	};

	const handleFilter = async filters => {
		const values = omitBy(filters, isNil);

		await Promise.all([
			deliveryCatalogStore.fetch(values),
			merchantConfigurationsStore.updateDPCatalogFilters(pick(values, ['countries', 'delivery_types']))
		]);
	};

	useEffect(() => {
		merchantConfigurationsStore
			.fetch()
			.then(async () => deliveryCatalogStore.fetch(merchantConfigurationsStore.getDPCatalogFilters()));
	}, []);

	return useObserver(() => (
		<div className={classNames('delivery-hub', className)}>
			<BackTop className="delivery-hub-backtop" target={container ? () => container : undefined}>
				<Button shape="circle" icon={<BringgIcon iconName={BringgFontIcons.ArrowUp} />} type="primary" />
			</BackTop>

			<Affix target={container ? () => container : undefined}>
				<DeliveryCatalogFilterBar
					onFilter={handleFilter}
					onFilterOptions={deliveryCatalogStore.loadFilterOptions}
					getSavedFilters={merchantConfigurationsStore.getDPCatalogFilters}
				/>
			</Affix>
			<DeliveryProviderDetails
				isOpened={isDetailsOpened}
				deliveryProvider={activeDeliveryProvider}
				onClose={onCloseDetails}
				onEmail={toggleEmailModal}
				onToggleFavorite={handleToggleFavorite}
				onOpenFleets={handleOpenFleets}
			/>
			<DeliveryProviderList
				deliveryProviders={deliveryCatalogStore.all}
				isFetching={deliveryCatalogStore.isFetching}
				onClickDetails={onClickDetails}
				onToggleFavorite={handleToggleFavorite}
			/>
			{isEmailModalActive && (
				<DeliveryCatalogContactUsModal
					name={activeDeliveryProvider?.name}
					onSend={handleSendEmail}
					onClose={toggleEmailModal}
				/>
			)}
		</div>
	));
};

export default DeliveryCatalog;
