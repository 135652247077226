import { getRootEnv } from '@bringg-frontend/bringg-web-infra';
import moment from 'moment';
import { Task } from '@bringg/types';
import { DateRangeType } from '@bringg/dashboard-sdk/dist/DeliveryBlocks/DeliveryBlocks.consts';

import { getWaypointRange } from 'bringg-web/services/tasks/task-time-run-provider';

export const getTeamDeliveryBlocks = async (tasks: Task[], teamId: number) => {
	const tasksDateRange = getWaypointRange(tasks);

	if (!tasksDateRange.startTime || !tasksDateRange.endTime) {
		return [];
	}

	return getRootEnv().dashboardSdk.sdk.deliveryBlocks.get(
		teamId,
		moment(tasksDateRange.startTime).startOf('day')?.unix(),
		moment(tasksDateRange.endTime).endOf('day')?.unix(),
		DateRangeType.overlapsWithRange
	);
};
