// Need to add translations
export const getTasksCsvOptions = localTimezone => [
	{ value: 'external_id', text: 'External Id' },
	{ value: 'date', text: 'Date' },
	{ value: 'time', text: 'Scheduled Time (today) (UTC)' },
	{ value: 'time_tz', text: `Scheduled Time (today), Timezone: ${localTimezone}` },
	{ value: 'datetime', text: 'Scheduled Date Time (UTC)' },
	{ value: 'datetime_tz', text: `Scheduled Date Time, Timezone: ${localTimezone}` },
	{ value: 'time_window_with_tz', text: `Delivery Window, Timezone ${localTimezone}` },
	{ value: 'customer_name', text: 'Customer Name' },
	{ value: 'customer_phone', text: 'Customer Phone' },
	{ value: 'customer_first_name', text: 'Customer First Name' },
	{ value: 'customer_last_name', text: 'Customer Last Name' },
	{ value: 'customer_address', text: 'Customer Address (all in one cell)' },
	{ value: 'customer_address_part_1', text: 'Customer Address Part 1' },
	{ value: 'customer_address_part_2', text: 'Customer Address Part 2' },
	{ value: 'customer_address_part_3', text: 'Customer Address Part 3' },
	{ value: 'customer_address_part_4', text: 'Customer Address Part 4' },
	{ value: 'customer_address_part_5', text: 'Customer Address Part 5' },
	{ value: 'zipcode', text: 'Zipcode / Postal Code' },
	{ value: 'address_second_line', text: 'Customer Address Second Line' },
	{ value: 'customer_email', text: 'Customer Email' },
	{ value: 'customer_lat', text: 'Customer Latitude' },
	{ value: 'customer_lng', text: 'Customer Longitude' },
	{ value: 'task_note', text: 'Task Note' },
	{ value: 'driver_name', text: "Driver's Name" },
	{ value: 'title', text: 'Task Title' },
	{ value: 'total_price', text: "Task's Total Price" },
	{ value: 'delivery_price', text: "Task's Delivery Price" },
	{ value: 'wp_lat', text: 'Waypoint Latitude' },
	{ value: 'wp_lng', text: 'Waypoint Longitude' },
	{ value: 'wp_position', text: 'Waypoint Position' },
	{ value: 'priority', text: 'Priority' },
	{ value: 'scan_code', text: 'Inventory Scan Code' },
	{ value: 'inventory_title', text: "Inventory's Title" },
	{ value: 'inventory_price', text: "Inventory's Total Price" },
	{ value: 'inventory_quantity', text: "Inventory's Quantity" },
	{ value: 'pickup_dropoff', text: 'Inventory needs to be picked up (0) or dropped off (1)' },
	{ value: 'weight', text: 'Inventory Weight' },
	{ value: 'width', text: 'Inventory Width' },
	{ value: 'length', text: 'Inventory Length' },
	{ value: 'height', text: 'Inventory Height' },
	{ value: 'team_id', text: 'Team ID' },
	{ value: 'tag_id', text: 'Tag ID' },
	{ value: 'tag', text: 'Tag' },
	{
		value: 'delivery_confirmation_scan',
		text: 'Scan Delivery Confirmation Required'
	},
	{
		value: 'delivery_confirmation_note',
		text: 'Note Delivery Confirmation Required'
	},
	{
		value: 'delivery_confirmation_photo',
		text: 'Photo Delivery Confirmation Required'
	},
	{
		value: 'delivery_confirmation_signature',
		text: 'Signature Delivery Confirmation Required'
	},
	{
		value: 'delivery_confirmation_form',
		text: 'Form Delivery Confirmation Required'
	},
	{
		value: 'delivery_confirmation_ocr',
		text: 'OCR Delivery Confirmation Required'
	},
	{ value: 'pickup_customer_name', text: 'Pickup Customer Name' },
	{ value: 'pickup_location_address_1', text: 'Pickup Customer Main Address' },
	{
		value: 'pickup_location_address_second_line',
		text: 'Pickup Customer 2nd Line Address'
	},
	{ value: 'pickup_location_borough', text: 'Pickup Customer Borough' },
	{ value: 'pickup_location_city', text: 'Pickup Customer City' },
	{ value: 'pickup_location_state', text: 'Pickup Customer State' },
	{ value: 'pickup_location_zipcode', text: 'Pickup Customer Zipcode' },
	{ value: 'due_date', text: 'Due Date' },
	{ value: 'no_later_than', text: 'No Later Than (UTC)' },
	{ value: 'no_earlier_than', text: 'No Earlier Than (UTC)' },
	{ value: 'no_later_than_tz', text: `No Later Than, Timezone: ${localTimezone}` },
	{ value: 'no_earlier_than_tz', text: `No Earlier Than, Timezone: ${localTimezone}` },
	{ value: 'sack_id', text: 'Sack ID' },
	{ value: 'task_type_id', text: 'Task Type ID' },
	{ value: 'task_configuration_id', text: 'Task Configuration ID' },
	{ value: 'team_external_id', text: 'Team External ID' },
	{ value: 'etos', text: 'Waypoint ETOS' },
	{ value: 'extras', text: "Task's Extras" },
	{ value: 'required_skills', text: 'Required Skills' },
	{ value: 'scheduled_arrival', text: 'Scheduled Arrival (UTC)' },
	{ value: 'scheduled_arrival_tz', text: `Scheduled Arrival, Timezone: ${localTimezone}` }
];
