import _times from 'lodash/times';
import { BreakType } from '@bringg/types';
import moment from 'moment';
import { isBetween } from '@bringg-frontend/utils';

import { DeliveryBlockBreakBase } from 'bringg-web/features/delivery-blocks-v2/new-modal/breaks/break-types';

const generateBreakSizes = (breakInterval: number, maxBreakSize: number) =>
	_times(Math.floor(maxBreakSize / breakInterval), interval => (interval + 1) * breakInterval);

const createBreakText = (breakTime: number) => {
	const minutes = breakTime % 60;
	const hours = Math.floor(breakTime / 60);
	return `${hours > 0 ? `${hours} ${hours === 1 ? 'Hour ' : 'Hours '}` : ''}${minutes > 0 ? `${minutes} Min.` : ''}`;
};

const calcBreakSize = (breakStart: string, breakEnd: string) =>
	Math.floor((new Date(breakEnd).getTime() - new Date(breakStart).getTime()) / 1000 / 60);

const isRelativeBreak = (breakItem: DeliveryBlockBreakBase) => breakItem.break_type === BreakType.Relative;

const isBreakBetweenBlockTime = (breakItem: DeliveryBlockBreakBase, deliveryBlockTimes) => {
	return isRelativeBreak(breakItem)
		? true
		: isBetween(breakItem.start_time, [deliveryBlockTimes.startTime, deliveryBlockTimes.endTime]) &&
				isBetween(breakItem.end_time, [breakItem.start_time, deliveryBlockTimes.endTime]);
};

const isBreakLengthGreaterThanBreakRangeTimes = (breakItem: DeliveryBlockBreakBase) =>
	breakItem.break_type === BreakType.Flex &&
	moment(breakItem.end_time).diff(breakItem.start_time, 'minutes') < breakItem.length;

const isBreakBetweenOrSameOtherBreaksTimes = (
	breakItem: DeliveryBlockBreakBase,
	otherBreaks: DeliveryBlockBreakBase[]
) => {
	return (
		!isRelativeBreak(breakItem) &&
		otherBreaks.some(blockBreak => {
			if (blockBreak.break_type === BreakType.Relative) {
				return false;
			}

			return (
				breakItem !== blockBreak &&
				(isBetween(breakItem.start_time, [blockBreak.start_time, blockBreak.end_time]) ||
					isBetween(breakItem.end_time, [blockBreak.start_time, blockBreak.end_time]))
			);
		})
	);
};

export const breakService = {
	generateBreakSizes,
	createBreakText,
	calcBreakSize,
	isBreakBetweenBlockTime,
	isBreakLengthGreaterThanBreakRangeTimes,
	isBreakBetweenOrSameOtherBreaksTimes,
	isRelativeBreak
};
