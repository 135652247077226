import { getRootEnv } from '@bringg-frontend/bringg-web-infra';
import { action, makeObservable, observable } from 'mobx';
import _forEach from 'lodash/forEach';
import {
	AllOAuth2EndpointsResponse,
	OAuth2Endpoint,
	OauthApplication
} from '@bringg/dashboard-sdk/dist/OauthApplications/OauthApplicaitions.consts';
import _map from 'lodash/map';
import { BaseDomainStore } from '@bringg-frontend/global-stores';

import OAuthApp from './domain-objects/oauth-app';

export type EndpointWithChildren = {
	title: string;
	key: string;
	children?: EndpointWithChildren[];
};

class OAuthAppsStore extends BaseDomainStore<OAuthApp> {
	private allOAuth2Endpoints: EndpointWithChildren[];
	isFetched = false;
	constructor() {
		super();

		makeObservable(this, {
			saveApp: action,
			deleteApp: action,
			isFetched: observable
		});
	}

	fetchAll = async () => {
		if (this.isFetched) {
			return;
		}

		const { dashboardSdk } = getRootEnv();
		const applications = await dashboardSdk.sdk.oauthApplication.getAll();
		this.setApplication(applications);

		const allEndpoints = await dashboardSdk.sdk.oauthApplication.getAllEndpointsHierarchy();
		this.allOAuth2Endpoints = this.transformEndpoints(allEndpoints);

		this.isFetched = true;
	};

	private setApplication = (applications: OauthApplication[]) => {
		_forEach(applications, app => {
			this.items.set(app.id, new OAuthApp(app));
		});
	};

	public getAllEndpoints(): EndpointWithChildren[] {
		return this.allOAuth2Endpoints;
	}

	private transformEndpoints(allEndpoints: AllOAuth2EndpointsResponse): EndpointWithChildren[] {
		return _map(allEndpoints, this.transformEndpointsRecursively.bind(this));
	}

	private transformEndpointsRecursively(endpoint: OAuth2Endpoint): EndpointWithChildren {
		return {
			title: this.transformTitle(endpoint.title),
			key: endpoint.title,
			children: _map(endpoint.children, this.transformEndpointsRecursively.bind(this))
		};
	}

	private transformTitle(title: string): string {
		return title
			.split('_')
			.map(word => word.charAt(0).toUpperCase() + word.slice(1))
			.join(' ');
	}

	async saveApp(app: OAuthApp): Promise<void> {
		await app.save();
		this.set(app);
	}

	async deleteApp(app: OAuthApp): Promise<void> {
		await app.delete();
		this.remove(app.id);
	}
}

export default OAuthAppsStore;
