import React from 'react';

import { Portal } from '@bringg-frontend/bringg-web-infra';

import ServicePlans from 'bringg-web/features/service-plans/service-plan';

const ServicePlansPortal = () => {
	return <Portal element={<ServicePlans />} nodeId="service-plans-page" />;
};

export default ServicePlansPortal;
