import { computed, makeObservable } from 'mobx';
import { Skill as BringgSkill } from '@bringg/types';
import BaseDomainObject from '../../base-domain-object';
import SkillsStore from '../skills-store';

interface Skill extends BringgSkill {}

class Skill extends BaseDomainObject<BringgSkill> {
	id: number;

	constructor(skillsStore: SkillsStore, skill: BringgSkill) {
		super(skillsStore, 'skills');

		makeObservable(this, {
			asJson: computed
		});

		Object.assign(this, skill);
	}

	get asJson(): Partial<BringgSkill> {
		return {
			id: this.id,
			merchant_id: this.merchant_id,
			name: this.name
		};
	}
}

export default Skill;
