import { Schedule } from '../../../../team-page-logic/team-info-types';
import _isNil from 'lodash/isNil';
import _map from 'lodash/map';
import { GetShiftCollection, ShiftProps, ShiftTableRow } from './types';

export const getShiftPropsFromSchedule = (shiftsSchedule: Schedule, day: string, shiftPart: string): ShiftProps => {
	const shift = shiftsSchedule[day]?.[shiftPart];
	const start = shift?.startHour;
	const end = shift?.endHour;
	const isChecked = !(_isNil(start) && _isNil(end));
	return { start, end, isChecked };
};

export const getShiftCollection: GetShiftCollection = (form, days, schedule, shiftParts, isDisabled) => {
	return _map(days, day => {
		return shiftParts.reduce(
			(acc, part) => {
				const { start, end, isChecked } = getShiftPropsFromSchedule(schedule, day, part);
				acc[part] = { name: `schedule.${day}.${part}`, form, start, end, isChecked, disabled: isDisabled };
				return acc;
			},
			{ day }
		);
	}) as ShiftTableRow[];
};
