import React, { FunctionComponent, useCallback, useState } from 'react';
import { useObserver } from 'mobx-react';
import { BringgFontIcons, BringgIcon } from '@bringg/bringg-icons';
import Input from '@bringg/react-components/dist/components/floating-placeholder-input/input';
import { useTranslation } from 'react-i18next';
import Button from '@bringg/react-components/dist/components/button/button';
import Translate from '../../../../translation/translator';
import CreateSkillModal from '../create-skill-modal/create-skill-modal';

interface Props {
	onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const SkillsPageActions: FunctionComponent<Props> = ({ onChange }) => {
	const [isModalOpen, setModalOpenState] = useState(false);
	const { t } = useTranslation();

	const closeModal = useCallback(() => setModalOpenState(false), []);
	const openModal = useCallback(() => setModalOpenState(true), []);

	return useObserver(() => {
		return (
			<div className="skills-page-actions">
				<Input
					icon={<BringgIcon iconName={BringgFontIcons.Search} />}
					className="skills-search"
					placeholder={t('SKILLS.SEARCH')}
					onChange={onChange}
				/>
				<Button type="primary" onClick={openModal}>
					<Translate text="SKILLS.CREATE" />
				</Button>
				<CreateSkillModal visible={isModalOpen} closeModal={closeModal} />
			</div>
		);
	});
};

export default SkillsPageActions;
