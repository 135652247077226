import { FormItem, BringgTextArea } from '@bringg/react-components';
import { useTranslation } from 'react-i18next';

import {
	coordinatesToPolygon,
	OnlySingleArrayOfCoordinatesError,
	InvalidPolygonError
} from 'bringg-web/features/service-area/utils/coordinates-to-polygon';
import { checkIfPolygon, ShapeIsNotPolygonError } from '../utils/check-if-polygon';

const DEFAULT_ERROR_MESSAGE = 'SERVICE_AREA.CANT_PARSE';
const errorsMessagesMap = new Map([
	[OnlySingleArrayOfCoordinatesError, 'SERVICE_AREA.SINGLE_ARRAY_OF_COORDINATES'],
	[ShapeIsNotPolygonError, 'SERVICE_AREA.THREE_COORDINATES_REQUIRED'],
	[InvalidPolygonError, 'SERVICE_AREA.CANT_PARSE']
]);

export function ServiceAreaCoordinatesInput() {
	const { t } = useTranslation();

	// antd requires async function to validate
	const validatePolygon = (_, value) =>
		Promise.resolve().then(() => {
			try {
				checkIfPolygon(coordinatesToPolygon(value));
			} catch (ex) {
				for (const [error, message] of errorsMessagesMap) {
					if (ex instanceof error) {
						throw new Error(t(message));
					}
				}

				throw new Error(t(DEFAULT_ERROR_MESSAGE));
			}
		});

	return (
		<FormItem
			validateFirst
			rules={[{ required: true, message: t('SERVICE_AREA.CANT_BE_EMPTY') }, { validator: validatePolygon }]}
			name="coordinates"
		>
			<BringgTextArea
				data-test-id="service-area-coordinates"
				className="textarea-input"
				placeholder="[&#10;[ lat, long ],&#10;[ 12.2123313, 12.2323232 ],&#10;]"
			/>
		</FormItem>
	);
}
