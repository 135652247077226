import React from 'react';
import { useTranslation } from 'react-i18next';
import { DeliveryProviderStatus } from '@bringg/types';
import { Tag, TagType, Tooltip } from '@bringg/react-components';

interface Props {
	status: DeliveryProviderStatus;
}

export const ComingSoonStatus = ({ status }: Props) => {
	const shownIcon = status === DeliveryProviderStatus.Pending || status === DeliveryProviderStatus.InProgress;
	const { t } = useTranslation();

	return shownIcon ? (
		<Tooltip placement="top" title="Coming soon">
			<Tag type={TagType.soon}>{t('COMING_SOON_STATUS.COMING_SOON')}</Tag>
		</Tooltip>
	) : null;
};
