import { VehicleType } from '@bringg/types';
import { useStores } from 'bringg-web/recipes';

function useVehicleType() {
	const { vehiclesStore, vehicleTypesStore } = useStores();

	const getVehicleTypeByVehicleIds = (): { [key: string]: VehicleType } => {
		const vehicleTypeByVehicleIds = {};
		vehiclesStore.getAll().forEach(vehicle => {
			vehicleTypeByVehicleIds[vehicle.id] = vehicleTypesStore.get(vehicle.vehicle_type_id);
		});
		return vehicleTypeByVehicleIds;
	};

	return {
		getVehicleTypeByVehicleIds
	};
}

export default useVehicleType;
