import React from 'react';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import { ChatMessage, ChatMessageText } from '@bringg/types';
import ChatStore from '../stores/chat-store';

interface Props {
	message: ChatMessage;
	chatStore?: ChatStore;
}

const ChatMessageItem = ({ message, chatStore }: Props) => {
	const { user_id } = message;
	const user = chatStore?.currentChat.getUser(user_id);
	const isSameUser = chatStore?.isCurrentUser(user_id);

	return user ? (
		<div className={`message-block ${isSameUser ? 'right' : 'left'}`}>
			{isSameUser ? (
				<div className="user-info current">
					<span className="message-time">{moment(message.server_timestamp).format('LT')}</span>
					<span className="user-name">{user.name}</span>
					<span className="user-image">
						{user.profile_image ? <img src={user.profile_image} alt="user profile" /> : null}
					</span>
				</div>
			) : (
				<div className="user-info">
					<span className="user-image">
						{user.profile_image ? <img src={user.profile_image} alt="user profile" /> : null}
					</span>
					<span className="user-name">{user.name}</span>
					<span className="message-time">{moment(message.server_timestamp).format('LT')}</span>
				</div>
			)}

			<div className="message">{(message as ChatMessageText).payload.text}</div>
		</div>
	) : null;
};

export default inject('chatStore')(observer(ChatMessageItem));
