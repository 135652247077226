import React from 'react';
import { observer } from 'mobx-react';
import { Col, Row } from '@bringg/react-components';
import { ActionData } from '@bringg/types/types/action_data';
import ActionsDataHeader from '../actions-data-header/actions-data-header';
import { actionValueToType } from '../action-data.consts';
import { ActionDataType, ActionsConfigurationLevel } from '../../../types/common.consts';
import GeneralData from '../general-data/general-data';
import ActionFormData from '../action-form-data/action-form-data';
import QuestionData from '../question-data/question-data';
import OpeniFrameData from '../open-iframe-data/open-iframe-data';

export enum PageMode {
	EDIT,
	CREATE
}

interface Props {
	onFieldUpdate: (actionData: Partial<ActionData>) => void;
	onDeleted: () => void;
	actionsData: ActionData;
	level?: ActionsConfigurationLevel;
	mode?: PageMode;
}

const ActionsDataContainer = ({ actionsData, onFieldUpdate, onDeleted, level }: Props) => {
	return (
		<div className="actions-data-container">
			<ActionsDataHeader
				onSelectType={onFieldUpdate}
				actionType={actionsData.value}
				onDelete={onDeleted}
				level={level}
			/>
			<Row>
				<Col span={8}>
					<GeneralData actionData={actionsData} onFieldUpdate={onFieldUpdate} />
				</Col>
				<Col span={16}>
					{(actionValueToType.get(actionsData.value) === ActionDataType.QUESTION && (
						<QuestionData actionsData={actionsData} onFieldUpdate={onFieldUpdate} />
					)) ||
						(actionValueToType.get(actionsData.value) === ActionDataType.ACTION_FORM && (
							<ActionFormData actionsData={actionsData} onFieldUpdate={onFieldUpdate} />
						)) ||
						(actionValueToType.get(actionsData.value) === ActionDataType.OPEN_IFRAME && (
							<OpeniFrameData actionsData={actionsData} onFieldUpdate={onFieldUpdate} />
						))}
				</Col>
			</Row>
		</div>
	);
};

export default observer(ActionsDataContainer);
