import React, { useCallback, useMemo, useState } from 'react';

import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { BringgFontIcons, BringgIcon } from '@bringg/bringg-icons';
import { Button, BringgPopover, Modal } from '@bringg/react-components';
import { observer } from 'mobx-react';

import { useStores } from '../../../recipes';
import notification from 'bringg-web/services/notification';

import './styles.scss';

interface Props {
	id: number;
	suggestion: boolean;
	onEdit: (id: number) => void;
}

function ActionsCell({ id, suggestion, onEdit }: Props) {
	const { t } = useTranslation();
	const {
		customAttributesStore: { deleteAttribute, get: getAttribute }
	} = useStores();

	const [deleteModalShown, setDeleteModalShown] = useState(false);
	const [isDeleting, setIsDeleting] = useState(false);

	const editAttribute = useCallback(() => {
		onEdit(id);
	}, [id]);

	const showDeleteModal = useCallback(() => {
		setDeleteModalShown(true);
	}, []);

	const handleDelete = useCallback(async () => {
		if (isDeleting) {
			return;
		}

		setIsDeleting(true);

		try {
			const { name } = getAttribute(id);
			await deleteAttribute(id);

			notification.success(t('CUSTOM_ATTRIBUTES.SUCCESSFULLY_DELETED'), `${t('GLOBAL.NAME')}: ${name}`);
		} catch (error) {
			notification.error((error as any)?.data?.title ?? t('CUSTOM_ATTRIBUTES.FAILED_DELETE'));
		} finally {
			setIsDeleting(false);
		}
		onDeleteModalClose();
	}, [id, t, isDeleting]);

	const onDeleteModalClose = useCallback(() => {
		setDeleteModalShown(false);
	}, []);

	const popoverContent = useMemo(() => {
		return (
			<div className="actions-popover">
				<Button type="link" icon={<BringgIcon iconName={BringgFontIcons.Pencil} />} onClick={editAttribute}>
					{t('CUSTOM_ATTRIBUTES.EDIT')}
				</Button>
				<Button type="link" icon={<BringgIcon iconName={BringgFontIcons.Trash} />} onClick={showDeleteModal}>
					{t('CUSTOM_ATTRIBUTES.REMOVE')}
				</Button>
			</div>
		);
	}, [t, editAttribute]);

	const DeleteModalTitle = useMemo(
		() => (
			<>
				<BringgIcon iconName={BringgFontIcons.InfoFilled} />
				{suggestion
					? t('CUSTOM_ATTRIBUTES.DELETE_SUGGESTED_ATTRIBUTE.TITLE')
					: t('CUSTOM_ATTRIBUTES.DELETE.ATTRIBUTE.TITLE')}
			</>
		),
		[suggestion, t]
	);

	return (
		<div className={classNames('attribute-actions', { 'align-to-right': !suggestion })}>
			{suggestion ? (
				<>
					<Button type="link" onClick={editAttribute} icon={<BringgIcon iconName={BringgFontIcons.Plus} />}>
						{t('CUSTOM_ATTRIBUTES.ADD')}
					</Button>
					<Button
						type="link"
						shape="circle"
						className="remove-button hidden-action-item"
						icon={<BringgIcon iconName={BringgFontIcons.Trash} />}
						onClick={showDeleteModal}
					/>
				</>
			) : (
				<BringgPopover content={popoverContent} destroyOnHide>
					<Button
						className="hidden-action-item"
						shape="circle"
						type="link"
						icon={<BringgIcon iconName={BringgFontIcons.MenuHorizontal} />}
					/>
				</BringgPopover>
			)}
			<Modal
				visible={deleteModalShown}
				title={DeleteModalTitle}
				onOk={handleDelete}
				onCancel={onDeleteModalClose}
				className="delete-attribute-modal"
				cancelButtonProps={{ className: 'cancel-delete-attribute' }}
				okButtonProps={{ className: 'confirm-delete-attribute', disabled: isDeleting }}
				cancelText={t('CUSTOM_ATTRIBUTES.DELETE_ATTRIBUTE.NO')}
				okText={t('CUSTOM_ATTRIBUTES.DELETE_ATTRIBUTE.YES')}
			>
				{suggestion
					? t('CUSTOM_ATTRIBUTES.DELETE_SUGGESTED_ATTRIBUTE.DESCRIPTION')
					: t('CUSTOM_ATTRIBUTES.DELETE_ATTRIBUTE.DESCRIPTION')}
			</Modal>
		</div>
	);
}

export default observer(ActionsCell);
