import React, { useState } from 'react';

import moment from 'moment';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { Button, Table, Modal, Tooltip } from '@bringg/react-components';
import { BringgFontIcons, BringgIcon } from '@bringg/bringg-icons';

import { useStores } from 'bringg-web/recipes';
import notification from '../../services/notification';
import FilterBar from './filter-bar';
import EcoIcon from './eco-icon';
import EmptyTableData from './empty-table-data';
import VehicleProfilesActions from './vehicle-profiles-actions';

import './vehicle-profiles.scss';

export const formatTime = (time: string) => moment(time).format('hh:mm A DD.MM.YY');

const VehicleProfilesTable = ({ setVehicleType, isLoading, vehicleCategories }) => {
	const { t } = useTranslation();
	const { vehicleTypesStore } = useStores();
	const [recordToDelete, setRecordToDelete] = useState(null);
	const [deletionError, setDeletionError] = useState(false);

	const columns = [
		{
			title: t('VEHICLE_PROFILES.TABLE.PROFILE'),
			dataIndex: 'title',
			render: (title: string, record) => {
				return (
					<span className="vehicle-profiles-vehicle-category">
						{title}
						{record.default_for_merchant && (
							<div className="vehicle-profiles-vehicle-category-position">
								<Tooltip title={t('VEHICLE_PROFILES.TOOLTIP.DEFAULT')} placement="top">
									<BringgIcon iconName={BringgFontIcons.Info} />
								</Tooltip>
							</div>
						)}
					</span>
				);
			}
		},
		{
			title: t('VEHICLE_PROFILES.TABLE.EXTERNAL_ID'),
			dataIndex: 'external_id'
		},
		{
			title: t('VEHICLE_PROFILES.TABLE.VEHICLE_CATEGORY'),
			dataIndex: 'vehicle_category',
			render: (category: string) => {
				return ['car', 'truck'].includes(category)
					? t(`VEHICLE_PROFILES.VEHICLE_CATEGORIES.${category.toUpperCase()}`)
					: category;
			}
		},
		{
			title: t('VEHICLE_PROFILES.TABLE.IS_GREEN'),
			dataIndex: 'is_green',
			render: (value: boolean) => <EcoIcon isGreen={value} />
		},
		{
			title: t('VEHICLE_PROFILES.TABLE.UPDATED_AT'),
			dataIndex: 'updated_at',
			render: (timeString: string) => <span>{formatTime(timeString)}</span>
		},
		{
			title: t('VEHICLE_PROFILES.TABLE.ACTIONS'),
			render: (_, record) => VehicleProfilesActions({ record, setVehicleType, setRecordToDelete, t }),
			className: 'table-action-column'
		}
	];

	const handleDelete = async () => {
		try {
			await vehicleTypesStore.delete(recordToDelete.id);

			notification.success(t('VEHICLE_PROFILES.TOAST.DELETE.SUCCESS', { title: recordToDelete.title }));
		} catch (ex) {
			setDeletionError(true);
			console.error(ex);

			notification.error(t('VEHICLE_PROFILES.TOAST.DELETE.ERROR', { title: recordToDelete.title }));
		}

		setRecordToDelete(null);
	};

	const newVehicleType = {
		id: null,
		title: '',
		external_id: null,
		handling_units: {}
	};

	return (
		<div className="vehicle-profiles-page-wrapper">
			<div className="vehicle-profiles-page--create-button-wrapper">
				<Button onClick={() => setVehicleType(newVehicleType)} type="primary" className="create-new-btn">
					{t('VEHICLE_PROFILES.ACTION.CREATE')}
				</Button>
			</div>
			<div className="vehicle-profiles-page--filter-bar-wrapper">
				<FilterBar vehicleCategories={vehicleCategories} />
			</div>
			<Table
				locale={{ emptyText: EmptyTableData(isLoading, t) }}
				className="vehicle-profiles-page--table"
				dataSource={vehicleTypesStore.getAllFiltered}
				columns={columns}
				loading={isLoading}
				rowKey="id"
			/>

			<Modal
				title={
					<div className="vehicle-profiles--delete-title">{t('VEHICLE_PROFILES.ACTION.DELETE.TITLE')}</div>
				}
				visible={!!recordToDelete}
				onOk={handleDelete}
				onCancel={() => setRecordToDelete(null)}
				okText={t('VEHICLE_PROFILES.ACTION.DELETE.BUTTON_TEXT')}
				cancelText={t('VEHICLE_PROFILES.ACTION.CANCEL')}
			>
				<p>{t('VEHICLE_PROFILES.ACTION.DELETE.CONFIRM', { title: recordToDelete?.title })}</p>
				<p>{t('VEHICLE_PROFILES.ACTION.DELETE.CONFIRM_QUESTION')}</p>
			</Modal>

			<Modal
				title="Unable to delete Vehicle Profile"
				visible={!!deletionError}
				onOk={() => setDeletionError(false)}
				cancelButtonProps={{ style: { display: 'none' } }}
				okText={t('VEHICLE_PROFILES.ACTION.OK')}
			>
				<p>{t('VEHICLE_PROFILES.ACTION.DELETE.ERROR')}</p>
			</Modal>
		</div>
	);
};

export default observer(VehicleProfilesTable);
