import {
	FloatingInventoryCustody,
	OWNERSHIP_ORIGIN,
	FloatingInventoryOwnerType,
	FloatingInventoryHistoryEventTypes
} from '@bringg/types';
import { TFunction } from 'i18next';

export const DetailsTranslationKeyPrefix = 'FLOATING_INVENTORY.EVENT_LOG.DETAILS.';

export enum DetailsTranslationKeys {
	CREATED_FOR_DRIVER = 'CREATED_FOR_DRIVER',
	CREATED_FOR_TEAM = 'CREATED_FOR_TEAM',
	REASSIGNED_TO_DRIVER = 'REASSIGNED_TO_DRIVER',
	UPDATED_BY_DRIVER = 'UPDATED_BY_DRIVER',
	RETURNED_BY_DRIVER = 'RETURNED_BY_DRIVER',
	REASSIGNED_TO_TEAM = 'REASSIGNED_TO_TEAM',
	UNKNOWN = 'UNKNOWN'
}

export const buildTranslationKey = (key: string) => DetailsTranslationKeyPrefix + key;

type getEventLogDetailsTranslationKey = {
	eventType: FloatingInventoryHistoryEventTypes;
	custody: FloatingInventoryCustody;
	ownerType: FloatingInventoryOwnerType;
	ownershipOrigin: OWNERSHIP_ORIGIN;
};

const handleCreated = ({
	eventType,
	custody,
	ownerType,
	ownershipOrigin
}: getEventLogDetailsTranslationKey): string => {
	if (custody !== FloatingInventoryCustody.UNKNOWN || ownershipOrigin !== OWNERSHIP_ORIGIN.SERVICES_API)
		return buildTranslationKey(eventType.toUpperCase());

	if (ownerType === FloatingInventoryOwnerType.DRIVER)
		return buildTranslationKey(DetailsTranslationKeys.CREATED_FOR_DRIVER);

	if (ownerType === FloatingInventoryOwnerType.TEAM)
		return buildTranslationKey(DetailsTranslationKeys.CREATED_FOR_TEAM);

	return buildTranslationKey(eventType.toUpperCase());
};

const handleUserAssigned = ({
	eventType,
	custody,
	ownerType,
	ownershipOrigin
}: getEventLogDetailsTranslationKey): string => {
	if (ownerType !== FloatingInventoryOwnerType.DRIVER) return buildTranslationKey(eventType.toUpperCase());

	if (custody === FloatingInventoryCustody.UNKNOWN && ownershipOrigin === OWNERSHIP_ORIGIN.SERVICES_API)
		return buildTranslationKey(DetailsTranslationKeys.REASSIGNED_TO_DRIVER);

	if (custody === FloatingInventoryCustody.DRIVER && ownershipOrigin === OWNERSHIP_ORIGIN.DRIVER_API)
		return buildTranslationKey(DetailsTranslationKeys.UPDATED_BY_DRIVER);

	return buildTranslationKey(eventType.toUpperCase());
};

const handleTeamAssigned = ({
	eventType,
	custody,
	ownerType,
	ownershipOrigin
}: getEventLogDetailsTranslationKey): string => {
	if (ownerType !== FloatingInventoryOwnerType.TEAM || custody !== FloatingInventoryCustody.UNKNOWN)
		return buildTranslationKey(eventType.toUpperCase());

	if (ownershipOrigin === OWNERSHIP_ORIGIN.DRIVER_API)
		return buildTranslationKey(DetailsTranslationKeys.RETURNED_BY_DRIVER);

	if (ownershipOrigin === OWNERSHIP_ORIGIN.SERVICES_API)
		return buildTranslationKey(DetailsTranslationKeys.REASSIGNED_TO_TEAM);

	return buildTranslationKey(eventType.toUpperCase());
};

const getEventLogDetailsTranslation = ({
	t,
	eventType,
	custody,
	ownerType,
	ownershipOrigin
}: { t: TFunction } & getEventLogDetailsTranslationKey): string => {
	let translationKey;
	switch (eventType) {
		case FloatingInventoryHistoryEventTypes.CREATED:
			translationKey = handleCreated({ eventType, custody, ownerType, ownershipOrigin });
			break;
		case FloatingInventoryHistoryEventTypes.USER_ASSIGNED:
			translationKey = handleUserAssigned({ eventType, custody, ownerType, ownershipOrigin });
			break;
		case FloatingInventoryHistoryEventTypes.TEAM_ASSIGNED:
			translationKey = handleTeamAssigned({ eventType, custody, ownerType, ownershipOrigin });
			break;
		case FloatingInventoryHistoryEventTypes.REPORTED_MISSING:
		case FloatingInventoryHistoryEventTypes.ITEM_FOUND:
		case FloatingInventoryHistoryEventTypes.MASS_CUSTODY_CHANGE:
			translationKey = buildTranslationKey(eventType.toUpperCase());
			break;
		default:
			translationKey = buildTranslationKey(DetailsTranslationKeys.UNKNOWN);
			break;
	}
	return t(translationKey);
};
export default getEventLogDetailsTranslation;
