import React, { useEffect, useMemo, useState } from 'react';

import { getRootEnv } from '@bringg-frontend/bringg-web-infra';
import { Column, Row, useExpanded, useFlexLayout, useGroupBy, useTable } from 'react-table';
import { BringgTable, Button, DropdownMenu, Modal } from '@bringg/react-components';
import { BringgFontIcons, BringgIcon } from '@bringg/bringg-icons';
import { useTranslation } from 'react-i18next';
import moment from 'moment/moment';
import { DeliveryBlockSchedule } from '@bringg/types/types/delivery_block_schedule';
import classNames from 'classnames';

import useDateFormat from 'bringg-web/hooks/use-date-format';
import { useStores } from 'bringg-web/recipes';
import { timezoneProvider } from 'bringg-web/services/timezone/timezone-provider';
import { EMPTY_SCHEDULE_ID } from 'bringg-web/features/delivery-blocks-v2/settings/delivery-blocks-settings';
import notification from 'bringg-web/services/notification';

import './delivery-blocks-schedules.scss';

export type Schedule = Partial<DeliveryBlockSchedule> & {
	template_id: number;
	template_name: string;
};

export interface Props {
	deliveryBlockSchedules: Schedule[];
	navigateToEditTemplate: (templateId: number) => void;
	onDeliveryBlockSchedulesChanged: (schedules: Schedule[]) => void;
}

enum CellType {
	TEMPLATE_NAME,
	TEAM_NAME,
	START_TIME,
	END_TIME,
	CAPACITIY
}

const DeliveryBlocksSchedules = ({
	navigateToEditTemplate,
	deliveryBlockSchedules,
	onDeliveryBlockSchedulesChanged
}) => {
	const { t } = useTranslation();
	const dateFormat = useDateFormat();
	const timezone = timezoneProvider.getTimezoneByMerchant();
	const { teamsStore } = useStores();
	const [templateIdToDelete, setTemplateIdToDelete] = useState<number>();
	const [expandedRows, setExpandedRows] = useState<object>();

	const getScheduleGroupCell = (row, cellType) => {
		const isEmptyGroup = row.subRows[0].original.id === EMPTY_SCHEDULE_ID;
		if (isEmptyGroup) {
			row.toggleRowExpanded(false);
		}

		if (cellType === CellType.TEMPLATE_NAME) {
			return (
				<div className="delivery-blocks-schedules-name-container">
					<BringgIcon
						className={classNames('delivery-blocks-schedules-name-chervon', {
							'empty-group': isEmptyGroup
						})}
						iconName={row.isExpanded ? BringgFontIcons.Chevron : BringgFontIcons.ChevronRight}
					/>
					<span data-test-id="schedule_group_template_name">{row.subRows[0].original.template_name}</span>
				</div>
			);
		}

		if (cellType === CellType.TEAM_NAME && row.subRows[0].original.id !== EMPTY_SCHEDULE_ID) {
			return <span data-test-id="teams-count">{row.subRows.length}</span>;
		}
		if (cellType === CellType.CAPACITIY) {
			return <span data-test-id="schedule_group_capacity">-</span>;
		}
		if (cellType === CellType.START_TIME) {
			return <span data-test-id="schedule_group_start_time">-</span>;
		}
		return <span data-test-id="schedule_group_end_time">-</span>;
	};
	const getScheduleCell = (row, value, cellType: CellType): JSX.Element => {
		if (row.canExpand) {
			return getScheduleGroupCell(row, cellType);
		}
		if (value && (cellType === CellType.START_TIME || cellType === CellType.END_TIME)) {
			return (
				<span
					data-test-id={
						cellType === CellType.START_TIME ? 'schedule_start_time_value' : 'schedule_end_time_value'
					}
				>
					{moment(value).tz(timezone).format(dateFormat)}
				</span>
			);
		}
		if (cellType === CellType.TEAM_NAME && value) {
			return <span data-test-id="schedule_team_name_value">{teamsStore.teams.get(value).name}</span>;
		}
		return <span data-test-id="schedule_capacity_value">{value}</span>;
	};

	const getMenuItem = (
		row: Row<Schedule>,
		className: string,
		onClick,
		label: string,
		fontIcon: BringgFontIcons,
		isDisabled: boolean
	): JSX.Element => {
		return (
			<Button
				className={classNames('template-schedule-menu-button', className)}
				type="link"
				onClick={onClick}
				disabled={isDisabled}
				title={isDisabled ? t('DELIVERY_BLOCKS_SCHEDULES.DELETE_TOOLTIP') : null}
			>
				<div className="template-schedule-menu-container" data-test-id="template-schedule-menu-container">
					<span className="schedule-menu-title">{label}</span>
					<BringgIcon className="schedule-menu-icon" iconName={fontIcon} />
				</div>
			</Button>
		);
	};

	const deleteTemplate = async () => {
		try {
			await getRootEnv().dashboardSdk.sdk.deliveryBlocks.deleteTemplate(templateIdToDelete);
			notification.success(t('DELIVERY_BLOCKS_SCHEDULES.DELETED_SUCCESSFULLY'));
			onDeliveryBlockSchedulesChanged(
				deliveryBlockSchedules.filter(schedule => schedule.template_id !== templateIdToDelete)
			);
		} catch (e) {
			notification.error(t('DELIVERY_BLOCKS_SCHEDULES.FAILED_TO_DELETE'));
			console.error('failed to delete template', e);
		} finally {
			setTemplateIdToDelete(null);
		}
	};

	const cancelDeleteTemplate = () => {
		setTemplateIdToDelete(null);
	};

	const columns: Column<Schedule>[] = useMemo(
		() =>
			[
				{
					id: 'template_id',
					accessor: 'template_id',
					translateKey: 'DELIVERY_BLOCKS_SCHEDULES.TITLE_NAME',
					minWidth: 250,
					Cell: ({ row, value }) => getScheduleCell(row, value, CellType.TEMPLATE_NAME)
				},
				{
					id: 'teams',
					accessor: 'team_id',
					translateKey: 'DELIVERY_BLOCKS_SCHEDULES.TITLE_TEAMS',
					Cell: ({ row, value }) => getScheduleCell(row, value, CellType.TEAM_NAME)
				},
				{
					id: 'start_date',
					accessor: 'start_time',
					translateKey: 'DELIVERY_BLOCKS_SCHEDULES.TITLE_START_DATE',
					Cell: ({ row, value }) => getScheduleCell(row, value, CellType.START_TIME)
				},
				{
					id: 'end_date',
					accessor: 'termination_time',
					translateKey: 'DELIVERY_BLOCKS_SCHEDULES.TITLE_END_DATE',
					Group: () => <span data-test-id="schedule_group_end_date_value">-</span>,
					Cell: ({ row, value }) => getScheduleCell(row, value, CellType.END_TIME)
				},
				{
					id: 'capacity',
					accessor: 'original_capacity',
					translateKey: 'DELIVERY_BLOCKS_SCHEDULES.TITLE_CAPACITY',
					Group: () => <span data-test-id="schedule_group_capacity_value">-</span>,
					Cell: ({ row, value }) => getScheduleCell(row, value, CellType.CAPACITIY)
				},
				{
					id: 'menu_schedule',
					width: 60,
					Cell: ({ row }) => {
						if (!row.isGrouped) {
							return null;
						}
						const onEditClick = () => navigateToEditTemplate(Number(row.groupByVal));

						const onDeleteClick = () => {
							setTemplateIdToDelete(row.values.template_id);
						};

						const isDeleteDisabled = deliveryBlockSchedules.some(
							schedule => schedule.id !== -1 && schedule.template_id === row.values.template_id
						);
						const items = [
							{
								key: 'edit_schedule',
								label: getMenuItem(
									row,
									'icon-edit-container',
									onEditClick,
									t('DELIVERY_BLOCKS_SCHEDULES.EDIT'),
									BringgFontIcons.Pencil,
									false
								),
								disabled: false
							},
							{
								key: 'delete_schedule',
								label: getMenuItem(
									row,
									'icon-delete-container',
									onDeleteClick,
									t('DELIVERY_BLOCKS_SCHEDULES.DELETE'),
									BringgFontIcons.Trash,
									isDeleteDisabled
								),
								disabled: isDeleteDisabled
							}
						];
						return (
							<DropdownMenu items={items} data-test-id="template-dropdown-actions">
								<Button type="link" onClick={e => e.stopPropagation()}>
									<BringgIcon className="schedule-menu" iconName={BringgFontIcons.Menu} />
								</Button>
							</DropdownMenu>
						);
					}
				}
			].map(col => ({
				...col,
				Header: <span className="delivery-blocks-schedules-title">{t(col.translateKey)}</span>
			})) as Column<Schedule>[],
		[deliveryBlockSchedules, expandedRows]
	);

	const tableInstance = useTable<Schedule>(
		{
			data: deliveryBlockSchedules,
			columns,
			initialState: { groupBy: ['template_id'] }
		},
		useFlexLayout,
		useGroupBy,
		useExpanded
	);

	useEffect(() => {
		setExpandedRows(tableInstance.state.expanded);
	}, [tableInstance.state.expanded]);

	return (
		<div data-test-id="delivery-blocks-schedules" className="delivery-blocks-schedules-container">
			<BringgTable className="delivery-blocks-schedules-table" tableInstance={tableInstance} />
			{!!templateIdToDelete && (
				<Modal
					className="delevery-block-schedule-delete-modal"
					key="delete-schedule"
					title={t('DELIVERY_BLOCKS_SCHEDULES.DELETE_MODAL.TITLE')}
					okText={t('DELIVERY_BLOCKS_SCHEDULES.DELETE_MODAL.APPLY')}
					cancelText={t('DELIVERY_BLOCKS_SCHEDULES.DELETE_MODAL.CANCEL')}
					onOk={deleteTemplate}
					onCancel={cancelDeleteTemplate}
					visible={!!templateIdToDelete}
				/>
			)}
		</div>
	);
};

export default DeliveryBlocksSchedules;
