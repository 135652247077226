import React, { useCallback, useState } from 'react';
import { BringgException } from '@bringg/dashboard-sdk/dist/Core/BringgException';
import Input from '@bringg/react-components/dist/components/floating-placeholder-input/input';
import Modal from '@bringg/react-components/dist/components/modal/modal';
import { useTranslation } from 'react-i18next';
import notification from '@bringg/react-components/dist/components/notification/notification';
import useStores from '../../../../recipes/use-stores';
import { Translate } from '../../../../translation';

interface Props {
	visible: boolean;
	closeModal: () => void;
}

const CreateSkillModal = ({ visible, closeModal }: Props) => {
	const { t } = useTranslation();
	const { skillsStore } = useStores();
	const [text, setText] = useState('');

	const onClose = useCallback(() => {
		closeModal();
		setText('');
	}, [closeModal]);

	const onCreate = useCallback(async () => {
		try {
			await skillsStore.create(text);
			notification.success(t('SKILLS.CREATED_SUCCESSFULLY'), text);
			onClose();
		} catch (e) {
			console.error(`failed to create skill name: ${text}`, (e as BringgException).message);
			notification.error(`${text} ${t('SKILLS.FAILED_TO_CREATED')}`);
		}
	}, [text, skillsStore, t, onClose]);

	const onTextChange = useCallback(e => setText(e.target.value), []);

	return (
		<div className="create-skill">
			<Modal
				className="create-skill-modal"
				visible={visible}
				title={<Translate text="SKILLS.ADD_SKILL" />}
				okText={<Translate text="SKILLS.SAVE" />}
				cancelText={<Translate text="SKILLS.CANCEL" />}
				onCancel={onClose}
				onOk={onCreate}
				destroyOnClose
				okButtonProps={{
					disabled: text === ''
				}}
			>
				<Input placeholder={t('SKILLS.NAME')} type="text" onChange={onTextChange} value={text} />
			</Modal>
		</div>
	);
};

export default CreateSkillModal;
