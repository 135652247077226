import { getRoot } from 'mobx-easy';
import RootStore from 'bringg-web/stores/root-store';

const generateGuid = () => {
	let counter = 0;
	// eslint-disable-next-line no-plusplus
	return () => counter++;
};
export const getRandomGuid = generateGuid();

export function getFlatList<T extends { items?: T[] }>(items: T[], idKey: string): { [id: string]: T } {
	return items.reduce((acc, attr) => {
		if (!attr.items) {
			acc[attr[idKey]] = attr;
		} else {
			const flatAttr = getFlatList(attr.items, idKey);
			return { ...acc, ...flatAttr };
		}
		return acc;
	}, {});
}

export const getFormatByMerchant = (): string => {
	return getRoot<RootStore>().data.merchantConfigurationsStore.shouldUseTime12HoursFormat ? 'hh:mm A' : 'HH:mm';
};
