import { getRootEnv } from '@bringg-frontend/bringg-web-infra';

export async function getDriverParkingSpots(teamId: number) {
	const PARKING_TYPE_DRIVER = 2;
	try {
		return await getRootEnv().dashboardSdk.sdk.parkingSpotApi.getParkingSpots(teamId, PARKING_TYPE_DRIVER);
	} catch (e) {
		console.error(e);
		throw e;
	}
}
