import { getRootEnv } from '@bringg-frontend/bringg-web-infra';
import { action, computed, makeObservable, observable } from 'mobx';
import { getEnv, getRoot } from 'mobx-easy';
import {
	CustomAttributeRes,
	CustomAttributeCreateRequest,
	CustomAttributeUpdateRequest,
	RuleCategory
} from '@bringg/types';
import { pick } from 'lodash';
import { RootEnv } from '@bringg-frontend/bringg-web-infra';

import BaseDomainStore from '../core/base-domain-store';
import { ATTRIBUTE_FIELDS_FOR_CREATE, ATTRIBUTE_FIELDS_FOR_UPDATE, MANDATORY_NEW_ATTRIBUTE_FIELDS } from './consts';
import { executeAction } from 'bringg-web/services/cross-application/cross-application';
import { CustomAttributesActions } from 'bringg-web/services/cross-application/cross-application.actions';
import { workflowsRootStore } from 'bringg-web/features/automation-workflows/stores/workflows-root-store';
import type RootStore from 'bringg-web/stores/root-store';

class CustomAttributesStore extends BaseDomainStore<CustomAttributeRes> {
	loading = false;
	isFetched = false;
	updateMetadataRepo = false;

	constructor() {
		super();

		makeObservable(this, {
			loading: observable,
			isFetched: observable,
			isLoading: computed,
			setIsLoading: action,
			setIsFetched: action,
			activeCustomAttributes: computed,
			activeCustomAttributesSortedByName: computed
		});
	}

	setIsLoading = (isLoading: boolean) => {
		this.loading = isLoading;
	};

	setIsFetched = (isFetched: boolean) => {
		this.isFetched = isFetched;
	};

	get isLoading(): boolean {
		return this.loading;
	}

	get activeCustomAttributes() {
		return this.all.filter(attr => !attr.suggestion);
	}

	get activeCustomAttributesSortedByName() {
		return this.activeCustomAttributes.sort((a, b) => {
			return String(a.name).toLowerCase().localeCompare(String(b.name).toLowerCase());
		});
	}

	fetchAll = async () => {
		if (this.isFetched) {
			return;
		}

		const { dashboardSdk } = getRootEnv();
		this.setIsLoading(true);
		try {
			const attributes = await dashboardSdk.sdk.customAttributesApi.getAllCustomAttributes({
				returnSuggested: true
			});

			this.setBatch(attributes);
			this.setIsFetched(true);
		} catch (error) {
			console.error('failed to fetch custom attributes', error);
		} finally {
			this.setIsLoading(false);
		}
	};

	crossApplicationSetAttributes = () => {
		const { authStore } = getRoot<RootStore>().views;

		executeAction(
			authStore.crossAppTransport,
			CustomAttributesActions.CUSTOM_ATTRIBUTES_SET,
			this.activeCustomAttributes
		);
	};

	createAttribute = async (attribute: CustomAttributeRes) => {
		const { dashboardSdk } = getRootEnv();
		try {
			const payload = pick(
				Object.assign(attribute, MANDATORY_NEW_ATTRIBUTE_FIELDS),
				ATTRIBUTE_FIELDS_FOR_CREATE
			) as CustomAttributeCreateRequest;
			const createdAttribute = await dashboardSdk.sdk.customAttributesApi.createCustomAttribute(payload);

			this.set(createdAttribute);

			this.crossApplicationSetAttributes();
			this.updateMetadataRepo = true;
		} catch (error) {
			console.error('failed to create custom attributes', error);
			throw error;
		}
	};

	updateAttribute = async (attribute: CustomAttributeRes) => {
		const { dashboardSdk } = getRootEnv();
		try {
			let attributeToUpdate = Object.assign({}, attribute);
			// the logic with `suggestion: false` done in purpose to make suggested attr(which is create by system based on merchant data) to be real attr
			// to make attribute a real one(that will be working in system) this attr should have `suggestion: false`
			// as user should be allowed anyhow to edit suggested attr right now "editing" suggested attr meaning create a real one that will works in a system
			// to avoid a lot of is-else logic inside the component `{ suggestion: false }` placed here as one source of truth
			if (attribute.suggestion) {
				attributeToUpdate = Object.assign({}, attribute, { suggestion: false });
			}

			const updatedAttribute = await dashboardSdk.sdk.customAttributesApi.updateCustomAttribute(
				pick(attributeToUpdate, ATTRIBUTE_FIELDS_FOR_UPDATE) as CustomAttributeUpdateRequest
			);

			this.set(updatedAttribute);

			this.crossApplicationSetAttributes();
			this.updateMetadataRepo = true;
		} catch (error) {
			console.error('failed to update custom attributes', error);
			throw error;
		}
	};

	deleteAttribute = async (id: number) => {
		const { dashboardSdk } = getRootEnv();
		try {
			await dashboardSdk.sdk.customAttributesApi.deleteCustomAttribute(id);

			this.remove(id);

			this.crossApplicationSetAttributes();
			this.updateMetadataRepo = true;
		} catch (error) {
			console.error('failed to delete custom attributes', error);
			throw error;
		}
	};

	updateMetadataInWorkflows = () => {
		const { metadataRepo } = workflowsRootStore.getStore();
		if (!this.updateMetadataRepo || !metadataRepo.metadata) {
			return;
		}

		this.updateMetadataRepo = false;

		metadataRepo.load(RuleCategory.Workflow);
	};
}

export default CustomAttributesStore;
