import { getRoot, wrapRoot } from 'mobx-easy';
import RateCardRootStore from '../store/rate-card-root-store';

export const RATE_CARD_WRAPPER = 'rate-card';

const getStore = () => getRoot<RateCardRootStore>(RATE_CARD_WRAPPER);

const createRoot = () => {
	try {
		return getRoot<RateCardRootStore>(RATE_CARD_WRAPPER);
	} catch (error) {
		return wrapRoot({ wrapperName: RATE_CARD_WRAPPER, env: {}, RootStore: RateCardRootStore });
	}
};

export const RateCardRoot = { createRoot, getStore };
