import React from 'react';

import { Slider } from '@bringg/react-components';
import classNames from 'classnames';
import moment from 'moment-timezone';

import { RouteLocation } from '../../types';

import styles from './bringg-map-route-player-slider.module.scss';

interface Props {
	routeLocations: RouteLocation[];
	routeIndex: number;
	onChangeRouteIndex: (newIndex: number) => void;
	timeFormat: string;
	disabled: boolean;
	timeZone?: string;
}

const BringgMapRoutePlayerSlider: React.FC<Props> = ({
	routeLocations,
	routeIndex,
	onChangeRouteIndex,
	timeFormat,
	timeZone,
	disabled
}) => {
	const sliderValue = (routeIndex / (routeLocations.length - 1)) * 100;

	const onChanged = (value: number) => {
		onChangeRouteIndex(Math.round((value * (routeLocations.length - 1)) / 100));
	};

	const tooltipFormatter = () => {
		const happendAtTime = moment(routeLocations[routeIndex]?.happened_at);
		return timeZone ? happendAtTime.tz(timeZone).format(timeFormat) : happendAtTime.format(timeFormat);
	};

	const getTooltipPopupContainer = (triggerNode: HTMLElement) => {
		return triggerNode;
	};

	return (
		<Slider
			data-test-id={'bringg-map-route-player-slider'}
			className={classNames(styles.bringgMapRoutePlayerSlider)}
			defaultValue={sliderValue}
			value={sliderValue}
			max={100}
			onChange={onChanged}
			tooltip={{ open: !disabled, formatter: tooltipFormatter, getPopupContainer: getTooltipPopupContainer }}
			disabled={disabled}
		/>
	);
};

export default BringgMapRoutePlayerSlider;
