import React, { useEffect } from 'react';

const useClickOutside = (
	ref: React.MutableRefObject<HTMLDivElement | null>,
	isRefVisible: boolean,
	callback: () => void
) => {
	useEffect(() => {
		function handleClickOutside(event) {
			if (ref?.current && !ref.current.contains(event.target) && isRefVisible) {
				callback();
				document.removeEventListener('mousedown', handleClickOutside);
			}
		}

		if (isRefVisible) {
			document.addEventListener('mousedown', handleClickOutside);
		}
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [ref, isRefVisible]);
};

export default useClickOutside;
