import { getRootEnv } from '@bringg-frontend/bringg-web-infra';
import { action, observable, makeObservable } from 'mobx';
import { getEnv } from 'mobx-easy';
import { TaskRelatedFleet } from '@bringg/types';
import { RootEnv } from '@bringg-frontend/bringg-web-infra';

import TaskRelatedFleetStore from './task-related-fleet-store';
import Task from '../tasks/domain-object/task';

class TaskRelatedFleetRepo {
	relatedFleetsByTaskId: Record<Task['id'], TaskRelatedFleetStore[]> = {};

	constructor() {
		makeObservable<TaskRelatedFleetRepo, 'addRelatedFleetToTask'>(this, {
			relatedFleetsByTaskId: observable,
			addRelatedFleetToTask: action,
			updateWithQuoteDataByTaskId: action
		});
	}

	fetchByTaskId = async (taskId: Task['id'], forceFetch?: boolean): Promise<TaskRelatedFleetStore[]> => {
		if (!forceFetch && this.relatedFleetsByTaskId[taskId]) {
			return this.relatedFleetsByTaskId[taskId];
		}

		const { dashboardSdk } = getRootEnv();

		const relatedFleets = await dashboardSdk.sdk.fleets.getByTask(taskId);

		this.addRelatedFleetToTask(taskId, relatedFleets);

		return this.relatedFleetsByTaskId[taskId];
	};

	private addRelatedFleetToTask(taskId: Task['id'], relatedFleets: TaskRelatedFleet[]) {
		this.relatedFleetsByTaskId[taskId] = relatedFleets.map(fleet => new TaskRelatedFleetStore(fleet, taskId));
	}

	async updateWithQuoteDataByTaskId(taskId: Task['id']) {
		if (!this.relatedFleetsByTaskId[taskId]) {
			return;
		}

		await Promise.allSettled(
			this.relatedFleetsByTaskId[taskId].map(async relatedFleet => relatedFleet.fetchQuoteData())
		);
	}

	getByTaskId = (taskId: Task['id']): TaskRelatedFleetStore[] | undefined => {
		return this.relatedFleetsByTaskId[taskId];
	};
}

export default TaskRelatedFleetRepo;
