import React, { FunctionComponent } from 'react';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import { ParkingSpot, Vehicle } from '@bringg/types';
import _get from 'lodash/get';
import TableWrapper from '@bringg/react-components/dist/components/table/table';
import { BringgFontIcons, BringgIcon } from '@bringg/bringg-icons';
import { useStores } from '../../../recipes';
import { Link } from '../../../components';

const getAvailabilityColumn = (t: TFunction) => {
	return {
		title: t('VEHICLES.AVAILABLE'),
		dataIndex: 'available',
		sorter: (a, b) => sorter(a, b, 'available'),
		render: (value: boolean) => {
			const icon = value ? BringgFontIcons.Selected : BringgFontIcons.Close;
			return <BringgIcon iconName={icon} data-test-id="vehicleAvailable" />;
		}
	};
};

const getVehicleValue = (vehicle: Vehicle, path: string): unknown => _get(vehicle, path);

export const sorter = (
	vehicle1: Vehicle,
	vehicle2: Vehicle,
	path: string,
	valueExtractor = getVehicleValue
): number => {
	const firstValue = valueExtractor(vehicle1, path);
	const secondValue = valueExtractor(vehicle2, path);

	if (!secondValue) {
		return 1;
	}

	if (!firstValue) {
		return -1;
	}

	return firstValue < secondValue ? -1 : 1;
};

interface Props {
	isLoading: boolean;
	vehicles: Vehicle[];
	onSelectedChange: (selectedRowsKeys: any) => void;
	selectedKeys: number[];
	teamParkingSpots: ParkingSpot[];
}

const VehiclesTable: FunctionComponent<Props> = ({
	isLoading,
	vehicles,
	onSelectedChange,
	selectedKeys,
	teamParkingSpots
}: Props) => {
	const { t } = useTranslation();
	const { vehicleTypesStore } = useStores();

	const getCanAttachTrailerValue = (vehicle: Vehicle) => {
		return vehicleTypesStore?.get(vehicle?.vehicle_type_id)?.can_attach_trailer;
	};

	const getVehicleTypeTitle = (vehicle?: Vehicle) => {
		return vehicleTypesStore?.get(vehicle?.vehicle_type_id)?.title;
	};

	const getDefaultColumns = (parkingSpots: ParkingSpot[]) => {
		return [
			{
				title: t('VEHICLES.ID'),
				dataIndex: 'id',
				render: (text: string, vehicle: Vehicle) => (
					<Link to={`/vehicle/${vehicle.id}`}>
						<span data-test-id="vehicleId">{vehicle.id}</span>
					</Link>
				),
				sorter: (a, b) => sorter(a, b, 'id'),
				defaultSortOrder: 'descend' as any
			},
			{
				title: t('VEHICLES.NAME'),
				dataIndex: 'name',
				sorter: (a, b) => sorter(a, b, 'name'),
				render: text => {
					return <span data-test-id="vehicleName">{text}</span>;
				}
			},
			{
				title: t('VEHICLES.TYPE'),
				sorter: (a, b) => sorter(a, b, 'vehicle_type_id', getVehicleTypeTitle),
				render: vehicle => {
					return <span data-test-id="vehicleType">{String(getVehicleTypeTitle(vehicle))}</span>;
				}
			},
			{
				title: t('VEHICLES.LICENSE_PLATE'),
				dataIndex: 'license_plate',
				sorter: (a, b) => sorter(a, b, 'license_plate'),
				render: text => {
					return <span data-test-id="vehicleLicensePlate">{text}</span>;
				}
			},
			{
				title: t('VEHICLES.MODEL'),
				dataIndex: 'model',
				sorter: (a, b) => sorter(a, b, 'model'),
				render: text => {
					return <span data-test-id="vehicleModel">{text}</span>;
				}
			},
			{
				title: t('VEHICLES.COLOR'),
				dataIndex: 'color',
				sorter: (a, b) => sorter(a, b, 'color'),
				render: text => {
					return <span data-test-id="vehicleColor">{text}</span>;
				}
			},
			{
				title: t('VEHICLES.IS_TRAILER'),
				dataIndex: 'is_trailer',
				sorter: (a, b) => sorter(a, b, 'is_trailer'),
				render: text => {
					return <span data-test-id="vehicleIsTrailer">{String(text)}</span>;
				}
			},
			{
				title: t('VEHICLES.CAN_ATTACH_TRAILER'),
				sorter: (a, b) => sorter(a, b, 'can_attach_trailer', getCanAttachTrailerValue),
				render: vehicle => {
					return (
						<span data-test-id="vehicleCanAttachTrailer">{String(getCanAttachTrailerValue(vehicle))}</span>
					);
				}
			},
			{
				title: t('VEHICLES.DEFAULT_PARKING_SPOT'),
				dataIndex: 'default_parking_spot_id',
				sorter: (a, b) => sorter(a, b, 'default_parking_spot_id'),
				render: parkingSpotId => {
					const foundParkingSpot = parkingSpots.find(parkingSpot => parkingSpot.id === Number(parkingSpotId));
					return <span data-test-id="vehicleParkingSpot">{foundParkingSpot?.name || parkingSpotId}</span>;
				}
			}
		];
	};

	const getColumns = (vehiclesList: Vehicle[], parkingSpots: ParkingSpot[]) => {
		const columns = getDefaultColumns(parkingSpots);

		if (vehiclesList.some(vehicle => !vehicle.available)) {
			columns.push(getAvailabilityColumn(t));
		}

		return columns;
	};

	return (
		<TableWrapper
			rowSelection={{ onChange: onSelectedChange, selectedRowKeys: selectedKeys }}
			className="override-antd-table"
			dataSource={vehicles}
			columns={getColumns(vehicles, teamParkingSpots)}
			loading={isLoading}
			rowKey="id"
		/>
	);
};

export default VehiclesTable;
