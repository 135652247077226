import React, { useState, useEffect, useCallback } from 'react';

import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import { getRoot } from 'mobx-easy';
import moment from 'moment';
import { omit, cloneDeep, isNil } from 'lodash';
import { Button, ConfirmModal } from '@bringg/react-components';
import { BringgIcon, BringgFontIcons } from '@bringg/bringg-icons';
import { ApplicationUuid, OptimizationItem, Team } from '@bringg/types';
import { HttpStatusCode } from '@bringg/dashboard-sdk/dist/Core/Http/Enums';

import { useStores } from 'bringg-web/recipes';
import notification from 'bringg-web/services/notification';
import RootStore from 'bringg-web/stores/root-store';
import { useTeams } from '../../hooks';
import ConfigurationsList from './components/configurations-list/configurations-list';
import OptimizationSettingsForm from './components/optimization-configuration-form/optimization-configuration-form';
import { GenericParameter, OptimizationConfigurationManifestTypes, OptimizationConfigurationView } from './types';
import { getValuesWithDefaults } from './utils';

import './optimization-configuration.scss';

export interface DashboardSdkResponse {
	data: unknown;
	status: number;
	statusText: string;
	headers?: Record<string, string>;
}

export interface RequestError {
	type: string;
	title: string;
	status: number;
	success: boolean;
}

export function getRelevantConfigurationFieldsWithValue(manifest, configuration, merchantTeamSettings) {
	const merchantROSettings = merchantTeamSettings?.merchant;

	return manifest
		.reduce((acc, attribute) => {
			const value = configuration?.[attribute.id] ?? attribute.value;

			// if the attribute has `sub_fields_if_enabled` and the value TRUE
			// include to sub-fields to general UI fields array
			if (value && attribute.sub_fields_if_enabled) {
				return acc.concat(
					attribute,
					...attribute.sub_fields_if_enabled.map(field => Object.assign(field, { isSubField: true }))
				);
			}

			return acc.concat(attribute);
		}, [])
		.filter(attribute => {
			let valid = true;

			if (!isNil(merchantROSettings.optimizationType)) {
				// optimization_type in manifest ->
				// 0: both
				// 5: 1-5
				// 6:6
				const merchantOptimizationType =
					merchantROSettings.optimizationType > 0 && merchantROSettings.optimizationType < 5
						? 5
						: merchantROSettings.optimizationType;

				valid = attribute.optimization_type === merchantOptimizationType || attribute.optimization_type === 0;
			}

			if (valid && !isNil(merchantROSettings.dmEngine)) {
				valid = attribute.dm_engine === merchantROSettings.dmEngine || attribute.dm_engine === null;
			}

			return valid;
		})
		.map(attribute => {
			const value = configuration?.[attribute.id] ?? attribute.value;
			attribute.value = value ?? attribute.default;
			attribute.isValid = true;

			if (
				attribute.type === OptimizationConfigurationManifestTypes.DROPDOWN ||
				attribute.type === OptimizationConfigurationManifestTypes.CONDITIONAL_TIME_INPUT
			) {
				const newDefaultOption = attribute.options.findIndex(({ id }) => id === configuration?.[attribute.id]);

				if (newDefaultOption !== -1) {
					const oldDefaultOption = attribute.options.findIndex(option => option.default);
					attribute.options[oldDefaultOption] = omit(attribute.options[oldDefaultOption], 'default');

					attribute.options[newDefaultOption] = { ...attribute.options[newDefaultOption], default: true };

					if (isNil(attribute.value)) {
						attribute.value = newDefaultOption;
					}
				}
			}

			if (attribute.type === OptimizationConfigurationManifestTypes.CONDITIONAL_TIME_INPUT) {
				// parse hour string to Moment object
				const selectedOption = attribute.options.find(({ id }) => id === attribute.value);

				if (
					selectedOption &&
					selectedOption.timePicker &&
					selectedOption.timePickerValueKey &&
					configuration?.[selectedOption.timePickerValueKey]
				) {
					attribute[selectedOption.timePickerValueKey] = moment(
						configuration[selectedOption.timePickerValueKey],
						'HH:mm' // should be always serialized to 24h format
					);
				}
			}

			return attribute;
		});
}

const OptimizationConfiguration = () => {
	const { t } = useTranslation();

	const { routeOptimizationConfigurationStore } = useStores();
	const configurationSetsList = routeOptimizationConfigurationStore.all;
	const {
		fetchAllTeams,
		fetchOptimizationConfigurations,
		teams,
		getRouteOptimizationApplicationATCs,
		get: getTeamById
	} = useTeams();

	const [editableRecord, setEditableRecord] = useState<OptimizationConfigurationView>(null);

	useEffect(() => {
		routeOptimizationConfigurationStore.fetchAll();
		fetchAllTeams();
		fetchOptimizationConfigurations();
	}, []); // don't update deps array, hook should fire only once

	const handleCancel = useCallback(() => setEditableRecord(null), []);

	const handleCreate = () => {
		const configurationWithDefaultValues = cloneDeep(
			getValuesWithDefaults(routeOptimizationConfigurationStore.manifest)
		);
		const relevantConfigurationWithValues: GenericParameter[] = getRelevantConfigurationFieldsWithValue(
			routeOptimizationConfigurationStore.manifest,
			configurationWithDefaultValues,
			routeOptimizationConfigurationStore.merchantTeamSettings
		);

		setEditableRecord({
			configurationName: '',
			description: '',
			defaultConfiguration: !configurationSetsList.length,
			configuration: relevantConfigurationWithValues,
			teams: []
		});
	};
	const onDeleteDropdown = async (record: OptimizationItem) => {
		try {
			await routeOptimizationConfigurationStore.deleteRouteOptimizationConfiguration(record.id);

			notification.success(
				t('OPTIMIZATION_CONFIGURATIONS.TOAST.DELETE.SUCCESS', { title: record.configurationName })
			);
		} catch {
			notification.error(
				t('OPTIMIZATION_CONFIGURATIONS.TOAST.DELETE.ERROR', { title: record.configurationName })
			);
		}
	};

	const onBatchDelete = async (ids: number[]) => {
		if (!ids.length) {
			return;
		}

		try {
			await routeOptimizationConfigurationStore.batchDeleteRouteOptimizationConfigurations(ids);

			notification.success(t('OPTIMIZATION_CONFIGURATIONS.TOAST.BATCH_DELETE.SUCCESS'));
		} catch {
			notification.error(t('OPTIMIZATION_CONFIGURATIONS.TOAST.BATCH_DELETE.ERROR'));
		}
	};

	const onEditDropdown = (configObject: OptimizationItem) => {
		const configurationWithDefaultValues = cloneDeep(
			getValuesWithDefaults(routeOptimizationConfigurationStore.manifest)
		);
		const configurationWithValues = Object.assign({}, configurationWithDefaultValues, configObject.configuration);
		const relevantConfigurationWithValues: GenericParameter[] = getRelevantConfigurationFieldsWithValue(
			routeOptimizationConfigurationStore.manifest,
			configurationWithValues,
			routeOptimizationConfigurationStore.merchantTeamSettings
		);

		const nextEditable = {
			...configObject,
			configuration: relevantConfigurationWithValues
		};

		setEditableRecord(nextEditable);
	};

	const onDuplicateDropdown = async (configuration: OptimizationItem) => {
		try {
			await routeOptimizationConfigurationStore.createRouteOptimizationConfiguration({
				...configuration,
				teams: [],
				configurationName: `${configuration.configurationName} copy`
			} as OptimizationItem);

			notification.success(
				t('OPTIMIZATION_CONFIGURATIONS.TOAST.DUPLICATE.SUCCESS', { title: configuration.configurationName })
			);
		} catch {
			notification.error(
				t('OPTIMIZATION_CONFIGURATIONS.TOAST.DUPLICATE.ERROR', { title: configuration.configurationName })
			);
		}
	};

	const isTeamOptimizationSupported = (teamId: number): boolean => {
		const routeOptimizationAMC = getRoot<RootStore>().data.merchantConfigurationsStore.findApplicationConfiguration(
			ApplicationUuid.RouteOptimizer2
		);
		const optimization = getRouteOptimizationApplicationATCs(teamId);

		const isATCOptimizationSupported = optimization?.data?.optimization_solver === 'vroom';
		const isAMCOptimizationSupported =
			!optimization?.data?.optimization_solver && routeOptimizationAMC?.data?.optimization_solver === 'vroom';
		return isATCOptimizationSupported || isAMCOptimizationSupported;
	};

	const handleSubmit = async data => {
		function getTeamNamesByIds(teamIds: Team['id'][]): string {
			return teamIds.map(id => getTeamById(id)?.name).join(', ');
		}

		if (data.id) {
			try {
				const unsupportedEngines: number[] = data.teams.filter(teamId => !isTeamOptimizationSupported(teamId));

				if (unsupportedEngines.length) {
					return notification.error(
						t(`OPTIMIZATION_CONFIGURATIONS.TOAST.UPDATE.PARTIAL_TEAMS_ERROR`, {
							title: data.configurationName,
							teamsNames: getTeamNamesByIds(unsupportedEngines)
						})
					);
				}

				await routeOptimizationConfigurationStore.updateRouteOptimizationConfiguration(data, editableRecord);

				notification.success(
					t('OPTIMIZATION_CONFIGURATIONS.TOAST.UPDATE.SUCCESS', { title: data.configurationName })
				);
			} catch (error) {
				handleError(
					error as DashboardSdkResponse,
					t('OPTIMIZATION_CONFIGURATIONS.TOAST.UPDATE.ERROR', { title: data.configurationName })
				);

				return;
			}
		} else {
			try {
				await routeOptimizationConfigurationStore.createRouteOptimizationConfiguration(data);

				notification.success(
					t('OPTIMIZATION_CONFIGURATIONS.TOAST.CREATE.SUCCESS', { title: data.configurationName })
				);
			} catch (error) {
				handleError(
					error as DashboardSdkResponse,
					t('OPTIMIZATION_CONFIGURATIONS.TOAST.CREATE.ERROR', { title: data.configurationName })
				);

				return;
			}
		}

		handleCancel();
	};

	const handleError = (error: DashboardSdkResponse, defaultErrorMessage: string) => {
		const errorData = error.data as RequestError;

		if (errorData.status === HttpStatusCode.CONFLICT) {
			return notification.error(t('OPTIMIZATION_CONFIGURATIONS.TOAST.CREATE.NAME_CONFLICT'));
		}

		notification.error(defaultErrorMessage);
	};

	const TopBar = ({ onBackToClick }: { onBackToClick: () => void }) => {
		if (!editableRecord) {
			return (
				<div className="route-optimization-nav-header-empty">
					<div className="route-optimization-title">
						{t('OPTIMIZATION_CONFIGURATIONS.LIST.OPTIMIZATION_SETTINGS')}
					</div>
					<div className="route-optimization-description">
						{t('OPTIMIZATION_CONFIGURATIONS.LIST.CREATE_AND_EDIT')}
					</div>
				</div>
			);
		}

		const { defaultConfiguration } = editableRecord;
		const buttonTextKey = defaultConfiguration
			? 'OPTIMIZATION_CONFIGURATIONS.BACK_TO'
			: 'OPTIMIZATION_CONFIGURATIONS.BACK_TO_SETTINGS';
		const titleTextKey = defaultConfiguration
			? 'OPTIMIZATION_CONFIGURATIONS.MERCHANT_OPTIMIZATION'
			: 'OPTIMIZATION_CONFIGURATIONS.TEAM_OPTIMIZATION';

		return (
			<div className="route-optimization-nav-header">
				<Button className="route-optimization-back-button" onClick={onBackToClick}>
					<BringgIcon iconName={BringgFontIcons.ChevronLeft} />
					<span>{t(buttonTextKey)}</span>
				</Button>
				<span>{t(titleTextKey)}</span>
			</div>
		);
	};

	if (!routeOptimizationConfigurationStore.isFetched) {
		return null;
	}

	return (
		<div className="route-optimization-settings-wrapper">
			<TopBar
				onBackToClick={() => {
					const confirmModalProps = {
						title: t('GLOBAL.DISCARD'),
						content: t('OPTIMIZATION_CONFIGURATIONS.LIST.SAVE_PROMPT'),
						okText: t('OPTIMIZATION_CONFIGURATIONS.LIST.DISCARD_ALL_CHANGES'),
						cancelText: t('GLOBAL.CANCEL'),
						onOk: handleCancel
					};

					ConfirmModal(confirmModalProps);
				}}
			/>

			{editableRecord ? (
				<OptimizationSettingsForm
					containerId={'optimization-configuration'}
					values={editableRecord}
					allTeams={teams}
					getRouteOptimizationApplicationATCs={getRouteOptimizationApplicationATCs}
					onSubmit={handleSubmit}
					onCancel={handleCancel}
					onCreate={handleCreate}
					createNewEnabled={configurationSetsList.length > 0}
					isTeamOptimizationSupported={isTeamOptimizationSupported}
				/>
			) : (
				<ConfigurationsList
					configurations={configurationSetsList}
					onDeleteDropdown={onDeleteDropdown}
					onCreate={handleCreate}
					onEditDropdown={onEditDropdown}
					onDuplicateDropdown={onDuplicateDropdown}
					onBatchDelete={onBatchDelete}
				/>
			)}
		</div>
	);
};

export default observer(OptimizationConfiguration);
