import { useCallback, useEffect } from 'react';

import { LatLng } from '@bringg/types';

type useBringgMapRoutePlayerProps = {
	isPlaying: boolean;
	routeLocations: LatLng[];
	routeIndex: number;
	onRoutePlayerGotToEndOfRoute: () => void;
	onRoutePlayerPlay: () => void;
	onRoutePlayerStop: () => void;
};

const useBringgMapRoutePlayer = ({
	isPlaying,
	routeLocations,
	routeIndex,
	onRoutePlayerGotToEndOfRoute,
	onRoutePlayerPlay,
	onRoutePlayerStop
}: useBringgMapRoutePlayerProps) => {
	useEffect(() => {
		if (!isPlaying) {
			onRoutePlayerStop();
			return;
		}
		playRoutePlayer();
	}, [routeIndex, isPlaying]);

	const playRoutePlayer = useCallback(() => {
		const lastPoint = routeLocations.length - 2;
		if (routeIndex > lastPoint) {
			onRoutePlayerGotToEndOfRoute();
			return;
		}
		onRoutePlayerPlay();
	}, [routeLocations, routeIndex]);

	return { playRoutePlayer };
};
export default useBringgMapRoutePlayer;
