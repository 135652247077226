import { getSharedRootStore } from '@bringg-frontend/global-stores';

function log(...args: any[]) {
	const enableLogs =
		localStorage.getItem('ls.logEnabled') === 'true' ||
		getSharedRootStore().data.usersStore.currentUser.enable_logs;

	if (enableLogs) {
		// eslint-disable-next-line no-console
		console.log(...args);
	}
}

export const localLogger = { log };
