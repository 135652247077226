import React, { useEffect, useState } from 'react';

import { getRootEnv } from '@bringg-frontend/bringg-web-infra';
import { useTranslation } from 'react-i18next';
import { DeliveryBlockTemplate as DeliveryBlockTemplateType, DeliveryBlockTemplateSchedule, Team } from '@bringg/types';
import { useHistory, useParams } from 'react-router-dom';
import { Modal } from '@bringg/react-components';
import { DeliveryBlockConsts } from '@bringg/dashboard-sdk';
import { Portal } from '@bringg-frontend/bringg-web-infra';

import DeliveryBlockTemplate, {
	DeliveryBlockTemplateUpdate
} from 'bringg-web/features/delivery-blocks-v2/settings/template/main/delivery-block-template';
import DeliveryBlockTemplateHeader from 'bringg-web/features/delivery-blocks-v2/settings/template/header/delivery-block-template-header';
import { useStores } from 'bringg-web/recipes';
import notification from 'bringg-web/services/notification';
import { DELIVERY_BLOCKS_SETTINGS } from 'bringg-web/features/delivery-blocks-v2/settings/delivery-blocks-settings';
import UnassignedResources from './main/unassigned-resources/unassigned-resources';

import styles from './delivery-block-template-page.module.scss';

export const DeliveryBlockTemplatePage = ({ templateId: templateIdProp }) => {
	const { t } = useTranslation();
	const { teamsStore } = useStores();
	const history = useHistory();
	const [template, setTemplate] = useState<DeliveryBlockTemplateType>();
	const [teamsMap, setTeamsMap] = useState<Map<number, Team>>();
	const [unassignedResources, setUnassignedResources] =
		useState<DeliveryBlockConsts.UnassignedResourcesArrayByTeam>(null);

	const templateId = Number(templateIdProp);
	const isInEditMode = !!templateId;

	useEffect(() => {
		const fetchTemplateById = async () => {
			const result = await getRootEnv().dashboardSdk.sdk.deliveryBlocks.getTemplateById(templateId);
			setTemplate(result);
		};
		if (isInEditMode) {
			fetchTemplateById();
		}
	}, []);

	useEffect(() => {
		const fetchAllTeams = async () => {
			const fetchedTeams = await teamsStore.fetchAll();
			const teams = new Map();
			fetchedTeams.forEach(team => {
				teams.set(team.id, team);
			});
			setTeamsMap(teams);
		};
		fetchAllTeams();
	}, []);

	const goBack = () => {
		if (history.location.state?.from === DELIVERY_BLOCKS_SETTINGS) {
			history.goBack();
		} else {
			history.push({ pathname: '/merchant/delivery-blocks-templates' });
		}
	};

	const getEditPayload = (
		scheduleIdsToRemove: number[],
		schedulesToAdd: Partial<DeliveryBlockTemplateSchedule>[],
		schedulesToUpdate: Partial<DeliveryBlockTemplateSchedule>[],
		templateUpdateChanges?: DeliveryBlockTemplateUpdate
	) => {
		const payload = {
			schedule_ids_to_remove: scheduleIdsToRemove,
			template_schedules: schedulesToAdd,
			schedules_to_update: schedulesToUpdate
		};
		if (templateUpdateChanges) {
			addDeliveryBlockTemplateChangesToPayload(templateUpdateChanges, payload);
		}
		return payload;
	};

	const addDeliveryBlockTemplateChangesToPayload = (
		{ deliveryBlockTemplate, originalDeliveryBlockTemplate }: DeliveryBlockTemplateUpdate,
		payload: object
	) => {
		[
			{ property: 'name' },
			{ property: 'description' },
			{ property: 'ical' },
			{ property: 'start_time' },
			{ property: 'end_time' },
			{ property: 'template_recharges', updateProperty: 'delivery_block_recharges' },
			{ property: 'template_breaks', updateProperty: 'delivery_block_breaks' }
		].forEach(({ property, updateProperty }) => {
			if (
				JSON.stringify(deliveryBlockTemplate[property]) !==
				JSON.stringify(originalDeliveryBlockTemplate[property])
			) {
				Object.assign(payload, { [updateProperty || property]: deliveryBlockTemplate[property] });
			}
		});
	};

	const onEditTemplate = async (
		scheduleIdsToRemove: number[],
		schedulesToAdd: Partial<DeliveryBlockTemplateSchedule>[],
		schedulesToUpdate: Partial<DeliveryBlockTemplateSchedule>[],
		templateUpdateChanges?: DeliveryBlockTemplateUpdate
	): Promise<boolean> => {
		try {
			const response = await getRootEnv().dashboardSdk.sdk.deliveryBlocks.updateTemplate(
				templateId,
				getEditPayload(
					scheduleIdsToRemove,
					schedulesToAdd,
					schedulesToUpdate,
					templateUpdateChanges
				) as DeliveryBlockConsts.UpdateDeliveryBlockTemplateRequest
			);

			if (
				response.unassigned_resources_array_by_team &&
				Object.keys(response.unassigned_resources_array_by_team).length
			) {
				setUnassignedResources(response.unassigned_resources_array_by_team);
				return;
			}
			notification.success(t('DELIVERY_BLOCKS_TEMPLATE.UPDATED_SUCCESSFULLY'));
			return true;
		} catch (e) {
			notification.error(t('DELIVERY_BLOCKS_TEMPLATE.FAILED_TO_UPDATE'));
			console.error('failed to update delivery block template', e);
			return false;
		}
	};

	const onCreateTemplate = async (updatedTemplate: DeliveryBlockTemplateType): Promise<boolean> => {
		try {
			const response = await getRootEnv().dashboardSdk.sdk.deliveryBlocks.createTemplate(updatedTemplate);
			if (
				response.unassigned_resources_array_by_team &&
				Object.keys(response.unassigned_resources_array_by_team).length
			) {
				setUnassignedResources(response.unassigned_resources_array_by_team);
				return;
			}
			notification.success(t('DELIVERY_BLOCKS_TEMPLATE.CREATED_SUCCESSFULLY'));
			return true;
		} catch (e) {
			notification.error(t('DELIVERY_BLOCKS_TEMPLATE.FAILED_TO_CREATE'));
			console.error('failed to create delivery block template', e);
			return false;
		}
	};

	const onCloseModal = () => {
		setUnassignedResources(null);
		notification.success(t('DELIVERY_BLOCKS_TEMPLATE.CREATED_SUCCESSFULLY'));
		goBack();
	};

	return (
		<div className={styles.deliveryBlockTemplateContainer}>
			<DeliveryBlockTemplateHeader goBack={goBack} />
			<DeliveryBlockTemplate
				onCreateTemplate={onCreateTemplate}
				onEditTemplate={onEditTemplate}
				onDiscardChanges={goBack}
				template={template}
				teamsMap={teamsMap}
				backToTemplatesPage={goBack}
			/>
			<Modal
				width="fit-content"
				visible={!!unassignedResources}
				onCancel={onCloseModal}
				cancelButtonProps={{ hidden: true }}
				okButtonProps={{ hidden: true }}
				footer={false}
			>
				<UnassignedResources unassignedResources={unassignedResources} teamsMap={teamsMap} />
			</Modal>
		</div>
	);
};

const DeliveryBlocksTemplatePagePortal = () => {
	const { templateId } = useParams();
	return (
		<Portal
			element={<DeliveryBlockTemplatePage templateId={templateId} />}
			nodeId="delivery-blocks-template-portal"
		/>
	);
};

export default DeliveryBlocksTemplatePagePortal;
