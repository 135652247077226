import React, { createRef } from 'react';

import { observer } from 'mobx-react';
import { AnimateMovingItems } from '@bringg/react-components';
import { WorkflowStore } from '@bringg-frontend/bringg-web-legacy/automation-platform';

import WorkflowCard from '../../../components/workflow-card/workflow-card';

import styles from './workflows-list.module.scss';

interface Props {
	workflows: WorkflowStore[];
	expandAll?: boolean;
	viewMode?: boolean;
}

const WorkflowsList = ({ workflows, expandAll, viewMode }: Props) => {
	return (
		<div className={styles.workflowList}>
			<AnimateMovingItems>
				{workflows.map(wf => (
					<WorkflowCard
						viewMode={viewMode}
						key={wf.id}
						workflow={wf}
						ref={createRef()}
						expanded={expandAll}
					/>
				))}
			</AnimateMovingItems>
		</div>
	);
};

export default observer(WorkflowsList);
