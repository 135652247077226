import { BringgFontIcons, BringgIcon } from '@bringg/bringg-icons';
import { Button } from '@bringg/react-components';
import { Link } from 'react-router-dom';

import { useSpeedFactorTranslation } from '../traffic-matrix/translations';

export const SpeedFactorSection = ({ serviceAreaId }) => {
	const translations = useSpeedFactorTranslation();

	return (
		<div data-test-id="speed-factor-section">
			<h2 className="header-info">{translations.sidebarTitle}</h2>
			<div className="header-description">{translations.sidebarSubtitle}</div>
			<div style={{ textAlign: 'right' }}>
				<Link to={`/merchant/service-areas/traffic/${serviceAreaId}`}>
					<Button icon={<BringgIcon iconName={BringgFontIcons.Plus} />}>
						{translations.sidebarButton}
						<BringgIcon
							iconName={BringgFontIcons.ExternalLink}
							style={{
								fontSize: '24px',
								marginLeft: '4px',
								marginTop: '-4px',
								position: 'relative',
								top: '4px'
							}}
						/>
					</Button>
				</Link>
			</div>
		</div>
	);
};
