import React from 'react';

import { Divider } from '@bringg/react-components';
import i18next from 'i18next';

import DeliveryBlock from '../../stores/domain-objects/delivery-block';
import FlexBreakMessage from '../flex-break-message/flex-break-message';
import TimezoneDate from '../../../../components/timezone-date/timezone-date';
import { DELIVERY_BLOCK_DATE_FORMAT, DELIVERY_BLOCK_TIME_FORMAT } from '../form/delivery-block-form';
import TimezoneService from '../../../../services/timezone/timezone-service';
import { Translate } from '../../../../translation';
import BreaksCard from './breaks/breaks-card';
import { breakService } from 'bringg-web/features/delivery-blocks-v2/services/break-service';
import { timezoneProvider } from 'bringg-web/services/timezone/timezone-provider';

interface Props {
	deliveryBlock?: DeliveryBlock;
	breakBuffer?: number;
	useOldBreaks?: boolean;
}

export const DeliveryBlockInfo = ({ label, info }) => {
	return (
		<div className="delivery-block-info">
			<label>
				<Translate text={label} />:
			</label>
			<div className="info">{info}</div>
		</div>
	);
};

const getTimeRangeText = (startTime, endTime) =>
	`${startTime.format(DELIVERY_BLOCK_TIME_FORMAT)} - ${endTime.format(DELIVERY_BLOCK_TIME_FORMAT)}`;

const DeliveryBlockCard = ({ deliveryBlock, breakBuffer, useOldBreaks }: Props) => {
	const timezone = timezoneProvider.getTimezoneByTeamId(deliveryBlock.team_id);
	const momentTz = TimezoneService.getMomentTimezone(timezone);
	const { calcBreakSize, createBreakText } = breakService;
	const breakSize = calcBreakSize(deliveryBlock.break_start_time, deliveryBlock.break_end_time);
	return (
		<>
			<div className="delivery-block-card">
				<DeliveryBlockInfo label={'DELIVERY_BLOCKS.NAME'} info={deliveryBlock.name} />
				<DeliveryBlockInfo label={'DELIVERY_BLOCKS.DESCRIPTION'} info={deliveryBlock.description} />
				<DeliveryBlockInfo
					label={'DELIVERY_BLOCKS.DATE'}
					info={
						<TimezoneDate
							timezone={timezone}
							date={deliveryBlock.start_time}
							format={DELIVERY_BLOCK_DATE_FORMAT}
						/>
					}
				/>
				<DeliveryBlockInfo
					label={'DELIVERY_BLOCKS.CAPACITY'}
					info={`${deliveryBlock.original_capacity} ${i18next.t('DELIVERY_BLOCKS.DRIVERS')}`}
				/>
				<Divider />
				<DeliveryBlockInfo
					label={'DELIVERY_BLOCKS.TIME'}
					info={getTimeRangeText(momentTz(deliveryBlock.start_time), momentTz(deliveryBlock.end_time))}
				/>
				{useOldBreaks && deliveryBlock.break_start_time && deliveryBlock.break_end_time && (
					<>
						<Divider />
						<DeliveryBlockInfo label={'DELIVERY_BLOCKS.BREAK'} info={createBreakText(breakSize)} />
						<DeliveryBlockInfo
							label={'DELIVERY_BLOCKS.TIME_RANGE'}
							info={getTimeRangeText(
								momentTz(deliveryBlock.break_start_time),
								momentTz(deliveryBlock.break_end_time)
							)}
						/>
						{breakBuffer && (
							<FlexBreakMessage
								breakSize={breakSize}
								breakBuffer={breakBuffer}
								breakStartTime={deliveryBlock.break_start_time}
								breakEndTime={deliveryBlock.break_end_time}
								timezone={timezone}
							/>
						)}
					</>
				)}
			</div>
			{!useOldBreaks && deliveryBlock.delivery_block_breaks?.length ? (
				<BreaksCard teamTimezone={timezone} deliveryBlockBreaks={deliveryBlock.delivery_block_breaks} />
			) : (
				''
			)}
		</>
	);
};

export default DeliveryBlockCard;
