import React from 'react';

import type { ExtendedTimelineItem } from '../../gantt-types';
import { ClusterItem } from './cluster-item/cluster-item';
import { SingleItem } from './single-item/single-item';

import './timeline-item.scss';

interface Props {
	item: ExtendedTimelineItem;
	isCluster: boolean;
}

const TimelineItem = ({ item, isCluster }: Props) => {
	return (
		<div className="bringg-timeline-item" data-test-id="bringg-timeline-item">
			{isCluster ? <ClusterItem content={item.content} /> : <SingleItem item={item} />}
		</div>
	);
};

export default TimelineItem;
