import React from 'react';

import { Portal } from '@bringg-frontend/bringg-web-infra';

import VehicleProfiles from 'bringg-web/features/vehicle-profiles/vehicle-profiles';

const VehicleProfilesPortal = () => {
	return <Portal nodeId="vehicle-profiles-page" element={<VehicleProfiles />} />;
};

export default VehicleProfilesPortal;
