import React, { useEffect, useState } from 'react';
import RelativeDropdown from 'bringg-web/features/planned-delivery-windows/modals/slots-modal/wizard-steps/configuration-step/time-slots/cutoff-radio-group/relative-dropdown/relative-dropdown';
import { Button } from '@bringg/react-components';
import {
	CutoffEnum,
	DailyCutoff,
	RelativeCutoff,
	RelativeOptions
} from 'bringg-web/features/planned-delivery-windows/modals/slots-modal/wizard-steps/configuration-step/time-slots/cutoff-radio-group/cutoff.types';
import DailyDropdown from 'bringg-web/features/planned-delivery-windows/modals/slots-modal/wizard-steps/configuration-step/time-slots/cutoff-radio-group/daily-dropdown/daily-dropdown';
import { Moment } from 'moment';
import {
	initialDailyCutoff,
	initialRelativeCutoff
} from 'bringg-web/features/planned-delivery-windows/modals/slots-modal/planned-delivery-windows-slots.consts';
import styles from '../popover.module.scss';
import { useTranslation } from 'react-i18next';
import { relativeCutoffUpdater } from 'bringg-web/features/planned-delivery-windows/services/relative-cutoff-updater';
import { dailyCutoffUpdater } from 'bringg-web/features/planned-delivery-windows/services/daily-cutoff-updater';

interface Props {
	cutoff: RelativeCutoff | DailyCutoff;
	updateCutoff: (cutoff: RelativeCutoff | DailyCutoff) => void;
	cutoffType: CutoffEnum;
	format: string;
	use12Hours: boolean;
	timeDuration: { hours: number; minutes: number };
	startTimeInMinutes: number;
	timezone: string;
}

const CutoffPopover = ({
	cutoff,
	updateCutoff,
	cutoffType,
	format,
	use12Hours,
	timeDuration,
	startTimeInMinutes,
	timezone
}: Props) => {
	const [relativeCutoff, setRelativeCutoff] = useState<RelativeCutoff>(initialRelativeCutoff);
	const [dailyCutoff, setDailyCutoff] = useState<DailyCutoff>(initialDailyCutoff(timezone));
	const { t } = useTranslation();

	useEffect(() => {
		if (cutoffType === CutoffEnum.RELATIVE) {
			setRelativeCutoff(cutoff as RelativeCutoff);
		} else if (cutoffType === CutoffEnum.DAILY) {
			setDailyCutoff(cutoff as DailyCutoff);
		}
	}, []);

	const updateRelativeCutoff = (updatedData: Partial<RelativeCutoff>) => {
		setRelativeCutoff(prevState => ({ ...prevState, ...updatedData }));
	};

	const updateDailyCutoff = (updatedData: Partial<DailyCutoff>) => {
		setDailyCutoff(prevState => ({ ...prevState, ...updatedData }));
	};

	const onButtonClick = () => {
		updateCutoff(cutoffType === CutoffEnum.RELATIVE ? relativeCutoff : dailyCutoff);
	};

	return (
		<div className={styles.flex}>
			<div className={styles.flex}>
				<span className={styles.title}>{t('PLANNED_DELIVERY_WINDOWS_MODAL_CUTOFF_POPOVER.CUTOFF_TEXT')}</span>
				<span className={styles.description}>
					{cutoffType === CutoffEnum.RELATIVE
						? t('PLANNED_DELIVERY_WINDOWS_MODAL_CUTOFF_POPOVER.CHANGE_RELATIVE_CUTOFF')
						: t('PLANNED_DELIVERY_WINDOWS_MODAL_CUTOFF_POPOVER.CHANGE_DAILY_CUTOFF')}
				</span>
			</div>
			<div className={styles.popoverBody}>
				{cutoffType === CutoffEnum.RELATIVE && (
					<RelativeDropdown
						relativeCutoff={relativeCutoff}
						timeDuration={timeDuration}
						days={{
							onChange: (days: number) =>
								relativeCutoffUpdater.updateRelativeDays(days, relativeCutoff, updateRelativeCutoff),
							disabled: relativeCutoff.relativeOption === RelativeOptions.AFTER
						}}
						hours={{
							onChange: (hours: number) =>
								relativeCutoffUpdater.updateRelativeHours(
									hours,
									relativeCutoff,
									updateRelativeCutoff,
									timeDuration
								)
						}}
						minutes={{
							onChange: (minutes: number) =>
								relativeCutoffUpdater.updateRelativeMinutes(
									minutes,
									relativeCutoff,
									updateRelativeCutoff,
									timeDuration
								)
						}}
						relativeOptions={{
							onChange: (relativeOption: RelativeOptions) =>
								relativeCutoffUpdater.updateRelativeOption(
									relativeOption,
									relativeCutoff,
									updateRelativeCutoff,
									timeDuration
								)
						}}
					/>
				)}
				{cutoffType === CutoffEnum.DAILY && (
					<DailyDropdown
						startRangeTimeInMinutes={startTimeInMinutes}
						format={format}
						use12Hours={use12Hours}
						dailyCutoff={dailyCutoff}
						days={{
							onChange: (days: number) =>
								dailyCutoffUpdater.updateDays(
									days,
									dailyCutoff,
									updateDailyCutoff,
									startTimeInMinutes,
									timezone
								)
						}}
						time={{
							onChange: (time: Moment) => dailyCutoffUpdater.updateTime(time, updateDailyCutoff)
						}}
						timezone={timezone}
					/>
				)}
			</div>
			<Button type="primary" className={styles.okButton} onClick={onButtonClick}>
				{t('PLANNED_DELIVERY_WINDOWS_MODAL_CUTOFF_POPOVER.OK_TEXT')}
			</Button>
		</div>
	);
};

export default CutoffPopover;
