import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import notification from 'bringg-web/services/notification';
import UserTypeStore from 'bringg-web/stores/user-type/user-type-store';
import UserTypesTable from '../user-types-table/user-types-table';
import UserTypesHeader from '../user-types-header/user-types-header';

interface Props {
	userTypeStore: UserTypeStore;
}

class UserTypesContainer extends Component<Props> {
	async componentDidMount(): Promise<void> {
		try {
			await this.props.userTypeStore.fetchAll();
		} catch (e) {
			notification.error((e as TypeError).message, (e as any).details);
		}
	}

	render() {
		const { count } = this.props.userTypeStore;
		const userTypes = this.props.userTypeStore.all;

		return (
			<div className="user-types-container">
				<UserTypesHeader />
				<UserTypesTable userTypes={userTypes} count={count} />
			</div>
		);
	}
}

export default inject('userTypeStore')(observer(UserTypesContainer));
