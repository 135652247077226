import React from 'react';

import { BringgFontIcons, BringgIcon } from '@bringg/bringg-icons';
import { Collapse } from '@bringg/react-components';

import { Failure, Translation } from '../../types';
import FailureRowHeader from './failure-row-header';

import style from './index.module.scss';

type Props = {
	translations: Pick<Translation, 'allFailed' | 'someFailed' | 'whatColumn' | 'failureReasonColumn'>;

	totalNumberOfActions: number;

	isCompleted: boolean;
	failures: Failure[];
	hideFailureReasonColumn?: boolean;
	failureRenderer?: (failure: Failure) => React.ReactNode;
};

const FailureRow = ({
	translations,
	totalNumberOfActions,
	isCompleted,
	failures,
	hideFailureReasonColumn,
	failureRenderer
}: Props) => {
	if (failures.length === 0) {
		return null;
	}

	return (
		<Collapse ghost className={style.row} expandIconPosition="end" removePadding>
			<Collapse.Panel
				key="batch-error-failure"
				header={
					<FailureRowHeader
						key="batch-error-failure-header"
						numberOfFailures={failures.length}
						totalNumberOfActions={totalNumberOfActions}
						translations={translations}
						isCompleted={isCompleted}
					/>
				}
				className={style.collapseHeader}
			>
				<div className={style.tableContainer}>
					<table className={style.failureTable}>
						<thead className={style.failureTableHeader}>
							<tr>
								<th>#</th>
								<th>{translations.whatColumn}</th>
								{hideFailureReasonColumn || <th>{translations.failureReasonColumn}</th>}
							</tr>
						</thead>
						<tbody>
							{failures.map((failure, i) => (
								<tr key={failure.id}>
									<td>{i + 1}</td>
									<td title={failure.title}>
										{failureRenderer ? failureRenderer(failure) : failure.title}
									</td>
									{!hideFailureReasonColumn && (
										<td title={failure.reason}>
											<BringgIcon
												iconName={BringgFontIcons.Info}
												className={style.failureReasonIcon}
											/>
											{failure.reason}
										</td>
									)}
								</tr>
							))}
						</tbody>
					</table>
				</div>
			</Collapse.Panel>
		</Collapse>
	);
};

export default FailureRow;
