import React, { useEffect, useMemo, useState } from 'react';

import { getRootEnv } from '@bringg-frontend/bringg-web-infra';
// eslint-disable-next-line no-restricted-imports
import { Select, Input, Form, Radio } from 'antd';
import { useTranslation } from 'react-i18next';
import { ActivityTypes } from '@bringg/types';
import { Alert, TreeSelect } from '@bringg/react-components';
import { useObserver } from 'mobx-react';
import { isPhoneValid } from '@bringg-frontend/utils';

import { filterOption } from 'bringg-web/services/utils';
import IntTelInput from './intl-tel-input/intl-tel-input';
import { useCompanies, useSkills, useTeamsTreeData, useUserTypes } from '../../hooks';
import { useMerchantConfiguration, useStores } from '../../recipes';
import { formConfig } from '../../consts';
import AddressAutoComplete from '../address-auto-complete/address-auto-complete';
import { useHasFeatureFlag } from 'bringg-web/utils/feature-flags';

const sortByLabel = (a, b) => a.label?.localeCompare(b.label);

const ADD_USER_SELECT_TEAM_WHEN_DISPATCHER_WITH_SINGLE_TEAM = 'add_user_select_team_when_dispatcher_with_single_team';

type Validator = (rule: any, value: any, callback: (error?: string) => void) => Promise<void | any> | void;

type FieldProps = {
	form?: any;
	required?: boolean;
	validator?: Validator;
	className?: string;
};

const customValidatorRules = (validator, rules) => {
	return [validator ? { validator } : null, ...rules].filter(el => el);
};

export function PhoneField({ required, validator }: FieldProps) {
	const { country_code } = useMerchantConfiguration();
	const { t } = useTranslation();

	const debouncedPhoneValidation = useMemo(
		() =>
			isPhoneValid(
				getRootEnv().dashboardSdk.sdk.users.validatePhone.bind(getRootEnv().dashboardSdk.sdk.users),
				formConfig.debouncePhone.intervalInMillis
			),
		[formConfig]
	);

	return (
		<Form.Item
			label={t('DRIVER.PHONE')}
			name="phone"
			dependencies={['email', 'password']}
			rules={customValidatorRules(validator, [
				{
					required,
					validator: async (_, value) => await debouncedPhoneValidation(value),
					message: t('CREATE_USER_MODAL.PHONE_INVALID')
				}
			])}
		>
			<IntTelInput initialCountry={country_code} data-test-id="phone-field" />
		</Form.Item>
	);
}

export function EmailField({ required, validator }: FieldProps) {
	const { t } = useTranslation();
	const { secured_setup, enable_secured_user_passwords } = useMerchantConfiguration();

	const isSecured = secured_setup || enable_secured_user_passwords;

	return (
		<>
			<Form.Item
				label={t('CUSTOMER.EMAIL')}
				name="email"
				dependencies={['phone', 'password']}
				rules={customValidatorRules(validator, [{ required }, { type: 'email' }])}
				style={isSecured ? { display: 'flex', width: 'calc(100% - 16px)' } : null}
			>
				<Input
					autoComplete="new-email"
					type="email"
					style={isSecured ? { width: 'calc(50% - 16px)' } : null}
					placeholder={t('CREATE_USER_MODAL.TYPE_EMAIL_ADDRESS')}
				/>
			</Form.Item>
			<Alert
				type="info"
				message={t('CREATE_USER_MODAL.EMAIL_ADDRESS_SECURED_ALERT')}
				showIcon
				className="email-input-field-alert"
			/>
		</>
	);
}

export function PasswordField({ required, validator }: FieldProps) {
	const { t } = useTranslation();
	const { secured_setup, enable_secured_user_passwords } = useMerchantConfiguration();

	if (secured_setup || enable_secured_user_passwords) {
		return null;
	}

	return (
		<Form.Item
			label={t('DRIVER.PASSWORD')}
			data-test-id="password-field"
			name="password"
			dependencies={['email', 'phone']}
			rules={customValidatorRules(validator, [{ min: 4, max: 20 }, { required }])}
		>
			<Input.Password autoComplete="new-password" placeholder={t('CREATE_USER_MODAL.TYPE_PASSWORD')} />
		</Form.Item>
	);
}

export function AddressField({ form }: FieldProps) {
	const { t } = useTranslation();

	return (
		<Form.Item label={t('CREATE_USER_MODAL.ADDRESS')} name="home_address" rules={[{ min: 1, max: 255 }]}>
			<AddressAutoComplete
				onSelect={home_address => form.setFieldsValue({ home_address })}
				placeholder={t('CREATE_USER_MODAL.TYPE_USERS_ADDRESS')}
			/>
		</Form.Item>
	);
}

export function TeamsField({ required }: FieldProps) {
	const { t } = useTranslation();
	const {
		usersStore: { currentUser },
		teamsStore
	} = useStores();
	const isTeamsFetched = useObserver(() => teamsStore.isFetched);

	useEffect(() => {
		const fetch = async () => {
			await teamsStore.fetchAll();
		};
		fetch();
	}, [teamsStore]);

	const teams = useMemo(() => {
		if (!isTeamsFetched) return [];
		if (currentUser.admin) return teamsStore.all;
		return teamsStore.all.filter(team => currentUser.team_ids?.includes(team.id));
	}, [currentUser, isTeamsFetched, teamsStore.all]);

	const teamOptions = useTeamsTreeData(teams);
	const userWithATeam =
		useHasFeatureFlag(ADD_USER_SELECT_TEAM_WHEN_DISPATCHER_WITH_SINGLE_TEAM) && required && teams.length === 1;
	const defaultValue: number[] = userWithATeam ? [teams[0].id] : undefined;

	if (!isTeamsFetched) {
		return null;
	}

	return (
		<Form.Item label={t('DRIVERS.TEAMS')} initialValue={defaultValue} name="team_ids" rules={[{ required }]}>
			<TreeSelect
				selectAllThreshold={1}
				data-test-id="teams-field"
				allowSelectAll
				selectAllText={t('MULTI_SELECT.SELECT_ALL')}
				placeholder={t('CREATE_USER_MODAL.SELECT_TEAMS_FOR_USER')}
				treeData={teamOptions}
				selectAllTagText={userWithATeam ? teams[0].name : t('CREATE_USER_MODAL.ALL_TEAMS')}
			/>
		</Form.Item>
	);
}

export function ExternalIDField() {
	const { t } = useTranslation();

	return (
		<Form.Item label={t('CREATE_USER_MODAL.EMPLOYEE_ID')} name="external_id" rules={[{ max: 255 }]}>
			<Input data-test-id="employee-field" placeholder={`${t('CREATE_USER_MODAL.EXAMPLE_SHORT')} 7749392`} />
		</Form.Item>
	);
}

export function NameField() {
	const { t } = useTranslation();

	return (
		<Form.Item label={t('DRIVERS.NAME')} name="name" rules={[{ required: true }, { max: 255 }]}>
			<Input placeholder={t('CREATE_USER_MODAL.TYPE_USERS_NAME')} />
		</Form.Item>
	);
}

export function AllowedUserTypesField({ form }: FieldProps) {
	const { t } = useTranslation();
	const userTypes = useUserTypes();
	const [selectedUserRoles, setUserRoles] = useState<number[]>([]);
	const [defaultUserRole, setDefaultUserRole] = useState<number>(null);

	useEffect(() => {
		if (userTypes && 'length' in userTypes) {
			if (!userTypes.length) {
				return;
			}

			const typeIds = userTypes.map(type => type.id);
			const defaultSelectedId = userTypes.find(type => type.default_for_merchant)?.id || null;

			setUserRoles(typeIds);
			setDefaultUserRole(defaultSelectedId);
			form.setFieldsValue({
				allowed_user_types_ids: typeIds,
				user_type_id: defaultSelectedId
			});
		}
	}, [userTypes, form]);

	return (
		<>
			{userTypes && 'length' in userTypes && (
				<>
					<Form.Item
						label={t('DRIVERS.USER_TYPE_OPTIONS')}
						name="allowed_user_types_ids"
						initialValue={userTypes.map(type => type.id)}
					>
						<Select
							mode="multiple"
							placeholder={t('CREATE_USER_MODAL.SELECT_ROLE_OPTIONS_FOR_USER')}
							filterOption={filterOption}
							data-test-id="user-types"
							showArrow
							getPopupContainer={trigger => trigger.parentNode}
							maxTagCount={2}
							options={userTypes
								.map(userType => ({
									value: userType.id,
									label: userType.title
								}))
								.sort(sortByLabel)}
							onChange={v => {
								const ids = v as number[];
								const user_type_id = form.getFieldValue('user_type_id');
								form.setFieldsValue({
									allowed_user_types_ids: ids,
									user_type_id: ids.includes(user_type_id) ? user_type_id : null
								});
								setUserRoles(ids);
							}}
						/>
					</Form.Item>
					<Form.Item
						label={t('CREATE_USER_MODAL.USER_ROLE')}
						name="user_type_id"
						initialValue={defaultUserRole}
					>
						<Select
							placeholder={t('CREATE_USER_MODAL.USER_ROLE')}
							showArrow
							allowClear
							getPopupContainer={trigger => trigger.parentNode}
							options={selectedUserRoles
								.map(id => {
									const userType = userTypes.find(type => id === type.id);

									return { value: userType.id, label: userType.title };
								})
								.sort(sortByLabel)}
							onChange={id => {
								form.setFieldsValue({ user_type_id: id });
							}}
						/>
					</Form.Item>
				</>
			)}
		</>
	);
}

export function SkillsField() {
	const { t } = useTranslation();
	const { enable_skills } = useMerchantConfiguration();
	const skills = useSkills();

	if (!enable_skills) {
		return null;
	}

	return (
		<Form.Item label={t('CREATE_USER_MODAL.SKILLS')} name="skills">
			<Select
				data-test-id="skills-field"
				mode="multiple"
				placeholder={t('CREATE_USER_MODAL.SELECT_USERS_SKILLS')}
				getPopupContainer={trigger => trigger.parentNode}
				filterOption={filterOption}
				maxTagCount={2}
				options={skills
					.map(skill => ({
						value: skill.name,
						label: skill.name
					}))
					.sort(sortByLabel)}
			/>
		</Form.Item>
	);
}

export function DefaultUserActivityField() {
	const { t } = useTranslation();

	const activities = useMemo(
		() => [
			{ value: ActivityTypes.WALKING, className: 'bringg-icon bringg-icon-man' },
			{ value: ActivityTypes.BICYCLE, className: 'bringg-icon bringg-icon-bicycle' },
			{ value: ActivityTypes.DRIVING, className: 'bringg-icon bringg-icon-car' }
		],
		[]
	);

	return (
		<Form.Item label={t('CREATE_USER_MODAL.DEFAULT_TRANSPORTATION_TYPE')} name="default_user_activity">
			<Radio.Group>
				{activities.map(activity => (
					<Radio.Button value={activity.value} key={activity.value}>
						<i className={activity.className} />
					</Radio.Button>
				))}
			</Radio.Group>
		</Form.Item>
	);
}

export function CompanyField() {
	const {
		usersStore: { currentUser }
	} = useStores();
	const allCompanies = useCompanies();
	const { t } = useTranslation();

	// Admin must be able to assign to any company
	// Dispatcher without company_id should be able to assign to any company
	// Dispatcher with company_id must be able to assign only to a company it is assigned itself with
	const companies =
		currentUser.admin || !currentUser.company_id
			? allCompanies
			: allCompanies.filter(({ id }) => id === currentUser.company_id);

	if (!companies.length) {
		return null;
	}

	return (
		<Form.Item label={t('DRIVER.COMPANY')} name="company_id">
			<Select
				data-test-id="companies-field"
				allowClear
				placeholder={t('CREATE_USER_MODAL.SELECT_CONTRACTED_DRIVERS_COMPANY')}
				getPopupContainer={trigger => trigger.parentNode}
				options={companies
					.map(company => ({
						value: company.id,
						label: company.name
					}))
					.sort(sortByLabel)}
			/>
		</Form.Item>
	);
}

export function JobDescriptionField({ className = null }: FieldProps) {
	const { t } = useTranslation();

	return (
		<Form.Item
			className={className}
			label={t('CREATE_USER_MODAL.JOB_DESCRIPTION')}
			name="job_description"
			rules={[
				{
					min: 1,
					max: 255
				}
			]}
		>
			<Input placeholder={`${t('CREATE_USER_MODAL.JOB_DESCRIPTION_PLACEHOLDER')}`} />
		</Form.Item>
	);
}

export function LanguageField() {
	const { t } = useTranslation();

	const [languages, setLanguages] = useState([]);
	const { language_code } = useMerchantConfiguration();

	useEffect(() => {
		async function fetchLanguages() {
			setLanguages(await getRootEnv().dashboardSdk.sdk.languages.getAll());
		}

		fetchLanguages();
	}, []);

	return (
		<Form.Item label={t('CREATE_USER_MODAL.LANGUAGE')} name="language" initialValue={language_code}>
			<Select
				placeholder={t('CREATE_USER_MODAL.LANGUAGE_PLACEHOLDER')}
				getPopupContainer={trigger => trigger.parentNode}
				options={languages
					.map(language => ({
						value: language.code,
						label: language.name
					}))
					.sort(sortByLabel)}
			/>
		</Form.Item>
	);
}

export function UserPrivilegesField({ required }: FieldProps) {
	const { t } = useTranslation();

	const [privileges, setPrivileges] = useState([]);

	useEffect(() => {
		async function fetch() {
			const { dashboardSdk } = getRootEnv();

			setPrivileges(await dashboardSdk.sdk.privileges.getAll());
		}

		fetch();
	}, []);

	if (!privileges.length) {
		return null;
	}

	return (
		<Form.Item
			label={t('CREATE_USER_MODAL.USER_PRIVILEGES')}
			name="privilege_id"
			initialValue={privileges[0]?.id}
			rules={[{ required }]}
		>
			<Select
				placeholder={t('CREATE_USER_MODAL.USER_PRIVILEGES_PLACEHOLDER')}
				getPopupContainer={trigger => trigger.parentNode}
				options={privileges
					.map(privilege => ({
						value: privilege.id,
						label: privilege.name
					}))
					.sort(sortByLabel)}
			/>
		</Form.Item>
	);
}
