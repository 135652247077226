import { createContext } from 'react';

export interface ListInnerElementContextType {
	headerHeight: number;
	isLoading: boolean;
	bodyHeight?: number;
}

const defaultValue: ListInnerElementContextType = {
	headerHeight: 0,
	isLoading: false,
	bodyHeight: 0
};

const ListInnerElementContext = createContext<ListInnerElementContextType>(defaultValue);

export default ListInnerElementContext;
