import React from 'react';
import { Tag, TagType } from '@bringg/react-components';
import styles from './info-tags.module.scss';

interface Props {
	tags: string[];
}

const InfoTags = ({ tags }: Props) => (
	<div className={styles.list}>
		{tags.map(tag => (
			<Tag key={tag} type={TagType.info}>
				{tag}
			</Tag>
		))}
	</div>
);

export default InfoTags;
