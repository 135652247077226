import React from 'react';
import { DELIVERY_BLOCK_TIME_FORMAT } from '../form/delivery-block-form';
import { Moment } from 'moment';
import i18next from 'i18next';
import TimezoneService from '../../../../services/timezone/timezone-service';
import { breakService } from 'bringg-web/features/delivery-blocks-v2/services/break-service';

interface Props {
	breakSize: number;
	breakBuffer: number; // in minutes
	breakStartTime: string | Date | Moment;
	timezone?: string;
	breakEndTime?: string | Date | Moment;
}

const FlexBreakMessage = ({ breakSize, breakBuffer, breakStartTime, breakEndTime, timezone }: Props) => {
	const { createBreakText } = breakService;
	const momentTz = TimezoneService.getMomentTimezone(timezone);

	const flexBreakStartTime = momentTz(breakStartTime || momentTz())
		.subtract(breakBuffer, 'minutes')
		.format(DELIVERY_BLOCK_TIME_FORMAT);

	const flexBreakEndTime = momentTz(breakEndTime || momentTz())
		.add(breakBuffer, 'minutes')
		.format(DELIVERY_BLOCK_TIME_FORMAT);

	return (
		<div className="delivery-block-flex-break-text">
			{`${i18next.t('DELIVERY_BLOCKS.FLEX_BREAK_MESSAGE_PART_1')} ${createBreakText(breakSize)} ${i18next.t(
				'DELIVERY_BLOCKS.FLEX_BREAK_MESSAGE_PART_2'
			)} ${flexBreakStartTime}
            ${i18next.t('DELIVERY_BLOCKS.FLEX_BREAK_MESSAGE_PART_3')} ${flexBreakEndTime}.`}
		</div>
	);
};

export default FlexBreakMessage;
