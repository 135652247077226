import React, { Suspense } from 'react';

import { observer } from 'mobx-react';
import { Spinner } from '@bringg/react-components';

import './App.scss';

interface Props {
	Routes: React.LazyExoticComponent<React.ComponentType<any>>;
}
const App = ({ Routes }: Props) => {
	return (
		<Suspense fallback={<Spinner className="flex-centered" />}>
			<Routes />
		</Suspense>
	);
};

export default observer(App);
