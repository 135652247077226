import { ServiceArea } from '@bringg/types';
import ServiceAreasViewStore from 'bringg-web/stores/service-areas-view/service-areas-view-store';
import { useMemo } from 'react';

export const useDuplicatedZipcodes = (serviceAreaViewStore: ServiceAreasViewStore) => {
	return useMemo<Record<string, Partial<ServiceArea>[]>>(() => {
		if (!serviceAreaViewStore.focusedServiceArea?.zipcodes) {
			return {};
		}

		const zipcodesToCheck = serviceAreaViewStore.focusedServiceArea.zipcodes;
		const indexedByZipcodeNames = serviceAreaViewStore.decorated.reduce((acc, serviceArea) => {
			if (serviceArea.id === serviceAreaViewStore.focusedServiceArea.id) {
				return acc;
			}

			(serviceArea.zipcodes || []).forEach(zipcode => {
				const lowerCased = zipcode.toLowerCase();
				if (!acc[lowerCased]) {
					acc[lowerCased] = [];
				}

				acc[lowerCased].push(serviceArea);
			});

			return acc;
		}, {});

		return zipcodesToCheck.reduce((acc, zipcode) => {
			const lowerCased = zipcode.toLowerCase();
			if (!indexedByZipcodeNames[lowerCased]) {
				return acc;
			}

			return {
				...acc,
				[lowerCased]: indexedByZipcodeNames[lowerCased]
			};
		}, {});
	}, [serviceAreaViewStore.focusedServiceArea, serviceAreaViewStore.decorated]);
};
