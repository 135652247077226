import React, { PureComponent } from 'react';
import { Table, Link } from '../../../components';
import { Translate } from '../../../translation';
import Company from '../../../stores/companies/domain-objects/company';
import _get from 'lodash/get';

interface Props {
	companies: Company[];
}

export const sorter = (company1: Company, company2: Company, path: string): number => {
	const firstValue = _get(company1, path);
	const secondValue = _get(company2, path);

	if (!secondValue) {
		return 1;
	}

	if (!firstValue) {
		return -1;
	}

	return firstValue < secondValue ? -1 : 1;
};

class CompaniesTable extends PureComponent<Props, {}> {
	TABLE_SIZE = 'small' as const;

	getColumns = () => {
		return [
			{
				sortDirections: ['descend', 'ascend'],
				title: <Translate text="COMPANIES.ID" />,
				dataIndex: 'id',
				sorter: (a, b) => a.id - b.id,
				render: (id: number, record: Company) => {
					return (
						<span data-test-id="companyExternalId">
							<Link to={`/companies/${id}`}>{record.external_id || record.id}</Link>
						</span>
					);
				}
			},
			{
				sortDirections: ['descend', 'ascend'],
				title: <Translate text="COMPANIES.NAME" />,
				sorter: (a, b) => sorter(a, b, 'name'),
				dataIndex: 'name',
				render: text => {
					return <span data-test-id="companyName">{text}</span>;
				}
			},
			{
				sortDirections: ['descend', 'ascend'],
				title: <Translate text="COMPANIES.PHONE" />,
				sorter: (a, b) => sorter(a, b, 'phone'),
				dataIndex: 'phone',
				render: text => {
					return <span data-test-id="companyPhone">{text}</span>;
				}
			},
			{
				sortDirections: ['descend', 'ascend'],
				title: <Translate text="COMPANIES.TAX_ID" />,
				sorter: (a, b) => sorter(a, b, 'tax_id'),
				dataIndex: 'tax_id',
				render: text => {
					return <span data-test-id="companyTaxId">{text}</span>;
				}
			},
			{
				sortDirections: ['descend', 'ascend'],
				title: <Translate text="COMPANIES.ADDRESS" />,
				sorter: (a, b) => sorter(a, b, 'address'),
				dataIndex: 'address',
				render: text => {
					return <span data-test-id="companyAddress">{text}</span>;
				}
			}
		];
	};

	public render() {
		return (
			<div className="companies-table">
				<Table
					dataSource={this.props.companies}
					columns={this.getColumns() as any}
					pagination={false}
					rowKey="id"
					size={this.TABLE_SIZE}
				/>
			</div>
		);
	}
}

export default CompaniesTable;
