import { getSharedRootStore } from '@bringg-frontend/global-stores';

export const useAllowPaymentInfo = (): boolean => {
	const {
		data: { merchantConfigurationsStore, usersStore }
	} = getSharedRootStore();

	const allowPaymentInfo =
		merchantConfigurationsStore.enableTaskPrice &&
		(usersStore.isAdmin ||
			(usersStore.isDispatcher && merchantConfigurationsStore.allowDispatcherToAccessTaskPrice));

	return allowPaymentInfo;
};
