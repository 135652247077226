import { useStores } from '../recipes';

const useFleets = () => {
	const { fleetsStore } = useStores();

	return {
		fleets: fleetsStore.all,
		fetchAllFleets: fleetsStore.fetchAll,
		getFleetById: fleetsStore.get
	};
};

export default useFleets;
