export enum CutoffEnum {
	RELATIVE = 'RELATIVE',
	DAILY = 'DAILY'
}

export enum RelativeOptions {
	BEFORE = 'BEFORE',
	AFTER = 'AFTER'
}

export interface RelativeCutoff {
	days: number;
	hours: number;
	minutes: number;
	relativeOption: RelativeOptions;
}

export interface DailyCutoff {
	time: string;
	days: number;
}

export interface Configuration<T> {
	onChange: (value: T) => void;
	disabled?: boolean;
}
