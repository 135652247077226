import { computed, makeObservable } from 'mobx';
import { DeliveryBlockResource } from '@bringg/types';

import DeliveryBlock from '../stores/domain-objects/delivery-block';
import Team from '../../../stores/teams/domain-object/team';
import { deliveryBlocksRootStore } from '../stores/delivery-blocks-root-store';
import { timezoneProvider } from 'bringg-web/services/timezone/timezone-provider';

export type DeliveryBlocksEvent = {
	id: number;
	title: string;
	start: Date;
	end: Date;
	capacity: number;
	originalCapacity: number;
	description: string;
	userIds: number[];
	teamTimezone: string;
	deliveryBlocksResources: DeliveryBlockResource[];
	team: Team;
};

class DeliveryBlocksCalendarView {
	constructor() {
		makeObservable(this, {
			allDeliveryBlockEventsViewModelsNormalized: computed
		});
	}

	get allDeliveryBlockEventsViewModelsNormalized() {
		const viewModels = (
			deliveryBlocksRootStore.getStore().deliveryBlocksView.currentTeam?.deliveryBlocks || []
		).map(deliveryBlock => deliveryBlock.viewModel);
		return this.normalizeEvents(viewModels);
	}

	normalizeEvents = (deliveryBlocks: DeliveryBlock[]): DeliveryBlocksEvent[] => {
		return deliveryBlocks.map(deliveryBlock => ({
			id: deliveryBlock.id,
			title: deliveryBlock.name,
			description: deliveryBlock.description,
			start: new Date(deliveryBlock.start_time),
			end: new Date(deliveryBlock.end_time),
			capacity: deliveryBlock.capacity,
			originalCapacity: deliveryBlock.original_capacity,
			userIds: deliveryBlock.user_ids,
			teamTimezone: timezoneProvider.getTimezoneByTeamId(deliveryBlock.team.id),
			team: deliveryBlock.team,
			deliveryBlocksResources: deliveryBlock.delivery_block_resources
		}));
	};
}

export default DeliveryBlocksCalendarView;
