import { getRootEnv } from '@bringg-frontend/bringg-web-infra';
import { datetime } from '@bringg/types';
import { action, observable, makeObservable } from 'mobx';
import { getEnv } from 'mobx-easy';
import { RootEnv } from '@bringg-frontend/bringg-web-infra';

export default class NotificationTemplate {
	id?: number;
	tag_id?: number;
	language: string;
	notification_type: string;
	created_at?: datetime;
	updated_at?: datetime;

	template = '';

	constructor(template: Partial<Bringg.NotificationTemplate>) {
		makeObservable(this, {
			template: observable,
			setTemplate: action
		});

		Object.assign(this, template);
	}

	setTemplate(template: string) {
		this.template = template;
	}

	upsert = async (): Promise<Bringg.NotificationTemplate> => {
		if (this.id) {
			return getRootEnv().dashboardSdk.sdk.notificationTemplates.update(this.id, this);
		}
		return getRootEnv().dashboardSdk.sdk.notificationTemplates.create(this);
	};

	delete = async () => {
		if (this.id) {
			await getRootEnv().dashboardSdk.sdk.notificationTemplates.delete(this.id);
		}
	};
}
