import React from 'react';

import { Moment } from 'moment';
import { RangePickerProps } from 'antd/es/date-picker';
import { Alert, DateRangePicker, DaysInWeek } from '@bringg/react-components';
import { EndOfWeekDay } from '@bringg/types';
import { useTranslation } from 'react-i18next';
import moment from 'moment-timezone';

import CutoffRadioGroup from 'bringg-web/features/planned-delivery-windows/modals/slots-modal/wizard-steps/configuration-step/time-slots/cutoff-radio-group/cutoff-radio-group';
import {
	CutoffEnum,
	DailyCutoff,
	RelativeCutoff
} from 'bringg-web/features/planned-delivery-windows/modals/slots-modal/wizard-steps/configuration-step/time-slots/cutoff-radio-group/cutoff.types';
import {
	Duration,
	PlannedDeliveryWindowOmitted
} from 'bringg-web/features/planned-delivery-windows/modals/slots-modal/planned-delivery-windows-slots.consts';
import { useStores } from 'bringg-web/recipes';
import { Times } from './times/times';

import styles from './time-slots.module.scss';

interface Props {
	timezone: string;
	use12Hours: boolean;
	format: string;
	updateTimes: (startTime: Moment, endTime: Moment) => void;
	plannedDeliveryWindowEditable: PlannedDeliveryWindowOmitted;
	changeEffectiveDates: (effectiveStartDate: string | null, effectiveEndDate: string | null) => void;
	timeDuration: Duration;
	updateTimeDuration: (duration: Duration) => void;
	daysInWeek: EndOfWeekDay[];
	setDaysInWeek: React.Dispatch<React.SetStateAction<EndOfWeekDay[]>>;
	cutoffType: CutoffEnum;
	setCutoffType: React.Dispatch<React.SetStateAction<CutoffEnum>>;
	relativeCutoff: RelativeCutoff;
	setRelativeCutoff: React.Dispatch<React.SetStateAction<RelativeCutoff>>;
	dailyCutoff: DailyCutoff;
	setDailyCutoff: React.Dispatch<React.SetStateAction<DailyCutoff>>;
	isEditMode: boolean;
}

export const TimeSlots = ({
	use12Hours,
	format,
	updateTimes,
	plannedDeliveryWindowEditable,
	timeDuration,
	updateTimeDuration,
	timezone,
	daysInWeek,
	setDaysInWeek,
	cutoffType,
	setCutoffType,
	relativeCutoff,
	setRelativeCutoff,
	dailyCutoff,
	setDailyCutoff,
	isEditMode,
	changeEffectiveDates
}: Props) => {
	const { t } = useTranslation();
	const { merchantConfigurationsStore } = useStores();
	const effectiveStartDate = plannedDeliveryWindowEditable.series_effective_start_date
		? moment(plannedDeliveryWindowEditable.series_effective_start_date).tz(timezone)
		: null;
	const effectiveEndDate = plannedDeliveryWindowEditable.series_effective_end_date
		? moment(plannedDeliveryWindowEditable.series_effective_end_date).tz(timezone)
		: null;

	function onEffectiveRangeDatesChanged(dates: Moment[] | null) {
		if (!dates) {
			changeEffectiveDates(moment().tz(timezone).startOf('day').toISOString(), null);
			return;
		}
		const [firstDate, secondDate] = dates;
		const startDate = firstDate ? firstDate.tz(timezone).startOf('day').toISOString() : null;
		const endDate = secondDate ? secondDate.tz(timezone).endOf('day').toISOString() : null;
		changeEffectiveDates(startDate, endDate);
	}
	const disabledDate: RangePickerProps['disabledDate'] = current => {
		return current && current < moment().tz(timezone).subtract(1, 'd');
	};

	return (
		<div className={styles.timeSlots}>
			{isEditMode && (
				<Alert
					type="warning"
					message={t('PLANNED_DELIVERY_WINDOWS_MODAL.EDIT_MODE.ALERT_MESSAGE')}
					description={t('PLANNED_DELIVERY_WINDOWS_MODAL.EDIT_MODE.ALERT_DESCRIPTION')}
					showIcon
				/>
			)}
			{!isEditMode && (
				<div className={styles.timeSlotsDescription}>
					<p className={styles.descriptionText}>
						{t('PLANNED_DELIVERY_WINDOWS_MODAL.APPLY_TIME_SLOTS_ON_DESCRIPTIONS')}
					</p>
				</div>
			)}

			<div className={styles.effectiveDateRangePicker}>
				<span className={styles.label}>{t('PLANNED_DELIVERY_WINDOWS_MODAL.EFFECTIVE_DATES_TEXT')}</span>
				<DateRangePicker
					startDate={effectiveStartDate}
					endDate={effectiveEndDate}
					translations={{
						fromDate: t('PLANNED_DELIVERY_WINDOWS_MODAL.EFFECTIVE_START_DATE'),
						toDate: t('PLANNED_DELIVERY_WINDOWS_MODAL.EFFECTIVE_END_DATE')
					}}
					disabledDate={disabledDate}
					onChange={onEffectiveRangeDatesChanged}
					allowEmpty={[true, true]}
				/>
			</div>
			{!isEditMode && (
				<div className={styles.daysInWeek}>
					<span className={styles.title}>
						{`${t('PLANNED_DELIVERY_WINDOWS_MODAL.APPLY_TIME_SLOTS_ON')}:`}
					</span>
					<DaysInWeek
						endOfWeekDay={merchantConfigurationsStore.endOfWeekDay}
						onChange={weekDays => setDaysInWeek(weekDays)}
						value={daysInWeek}
						translates={{
							su: t('WEEK_DAYS.SU'),
							mo: t('WEEK_DAYS.MO'),
							tu: t('WEEK_DAYS.TU'),
							we: t('WEEK_DAYS.WE'),
							th: t('WEEK_DAYS.TH'),
							fr: t('WEEK_DAYS.FR'),
							sa: t('WEEK_DAYS.SA')
						}}
					/>
				</div>
			)}
			<Times
				timezone={timezone}
				use12Hours={use12Hours}
				format={format}
				updateTimes={updateTimes}
				startTimeMinutes={plannedDeliveryWindowEditable.start_time}
				endTimeMinutes={plannedDeliveryWindowEditable.end_time}
				timeDuration={timeDuration}
				updateTimeDuration={updateTimeDuration}
				isEditMode={isEditMode}
			/>
			<CutoffRadioGroup
				startTimeMinutes={plannedDeliveryWindowEditable.start_time}
				use12Hours={use12Hours}
				format={format}
				cutoffType={cutoffType}
				setCutoffType={setCutoffType}
				dailyCutoff={dailyCutoff}
				setDailyCutoff={setDailyCutoff}
				relativeCutoff={relativeCutoff}
				setRelativeCutoff={setRelativeCutoff}
				timeDuration={timeDuration}
				isEditMode={isEditMode}
				timezone={timezone}
			/>
		</div>
	);
};
