import { FilterBar2Value } from '@bringg/react-components';
import { RunQueryOptions } from '@bringg/dashboard-sdk/dist/Run/run-types';
import { omit as _omit } from 'lodash';
import { Driver, PlannedRoute, Run, Task, Team } from '@bringg/types';

import { RunFiltersParams } from './runs-filter.consts';
import { DEAFULT_PAGE_SIZE, INITIAL_PAGE, MAX_PAGE_SIZE } from '../runs.consts';

export const getPageFromRouteParams = (location: Location) => {
	const params = new URLSearchParams(location.search);
	const page = parseInt(params.get('page'), 10);
	if (page && page > 0) {
		return page;
	}
	return INITIAL_PAGE;
};

export const getPageSizeFromRouteParams = (location: Location) => {
	const params = new URLSearchParams(location.search);
	const limit = parseInt(params.get('limit'), 10);
	if (limit && limit > 0 && limit < MAX_PAGE_SIZE) {
		return limit;
	}
	return DEAFULT_PAGE_SIZE;
};

export const getQueryString = (query: RunFiltersParams) => {
	let queryString = '';
	if (!query) return queryString;

	query.run_ids?.length && (queryString += `run_ids=${query.run_ids}&`);
	query.from_date && (queryString += `from_date=${query.from_date}&`);
	query.to_date && (queryString += `to_date=${query.to_date}&`);
	query.run_status && (queryString += `status=${query.run_status}&`);
	query.task_ids?.length && (queryString += `task_ids=${query.task_ids}&`);
	query.team_ids?.length && (queryString += `team_ids=${query.team_ids}&`);
	query.driver_ids?.length && (queryString += `driver_ids=${query.driver_ids}&`);
	query.by_title_ids?.length && (queryString += `by_title_ids=${query.by_title_ids}&`);
	return queryString ? `&${queryString}` : '';
};

export const filtersAreSet = (query: RunFiltersParams) => {
	const filters = _omit(query, ['page', 'items']);
	return Object.values(filters).length && !!Object.values(filters).filter(val => val).length;
};

export const mapTasksToFilter = (tasks: Task[]) =>
	tasks.map(task => ({
		label: task?.external_id,
		value: task?.id
	}));

export const mapRunsToFilter = (runs: Run[]): FilterBar2Value[] =>
	runs.map(run => ({
		label: run?.external_id,
		value: run?.id
	}));

export const mapPlannedRoutesToFilter = (plannedRoutes: PlannedRoute[]): FilterBar2Value[] =>
	plannedRoutes.map(route => ({
		label: route?.title,
		value: route?.id
	}));

export const mapTeamsToFilter = (teams: Team[]): FilterBar2Value[] =>
	teams.map(team => ({
		label: team?.name,
		value: team?.id
	}));

export const mapDriversToFilter = (drivers: Driver[]): FilterBar2Value[] =>
	drivers.map(driver => ({
		label: driver?.name,
		value: driver?.id
	}));

export const getIdsFromQueryParams = (params: URLSearchParams, field: keyof RunFiltersParams) =>
	params.get(field) ? [...params.get(field).split(',').map(Number)] : [];

export const prepareQueryParmas = (query: RunFiltersParams): RunQueryOptions => {
	const cleanQuery: RunQueryOptions = Object.keys(query).reduce((acc, key) => {
		if (!Array.isArray(query[key])) {
			return { ...acc, [key]: query[key] };
		}
		if (key === 'by_title_ids' && query[key].length) {
			return { ...acc, planned_route_ids: query[key] };
		}
		return query[key].length ? { ...acc, [key]: query[key] } : { ...acc };
	}, {});

	return _omit(cleanQuery, ['task_ids', 'run_ids', 'by_title_ids']);
};

export const mapListValues = (data): FilterBar2Value[] => {
	return data.items.map(item => {
		const matchedKey: string = item.matched_keys[0];
		return { value: item.id || item[matchedKey], label: item[matchedKey] };
	});
};
