import React from 'react';

import { Draggable, Droppable } from 'react-beautiful-dnd';

import DraggableListItem from '../draggable-list-item/draggable-list-item';
import { ListsType, UserDraggableItem } from '../../types';

import styles from './dropContent.module.scss';

interface Props {
	droppableId: ListsType;
	isDropDisabled?: boolean;
	list: UserDraggableItem[];
	searchString: string;
	onItemCheckboxChange: (index: number, listType: ListsType, id: string) => void;
	emptyText: string;
}

const DropContent = ({ droppableId, isDropDisabled, list, searchString, onItemCheckboxChange, emptyText }: Props) => {
	const isSelectedList = droppableId === ListsType.SELECTED;
	const isMoveDisabled = isSelectedList && list.length === 1;
	const isDragDisabled = !!searchString || isMoveDisabled;

	return (
		<Droppable droppableId={droppableId} isDropDisabled={isDropDisabled}>
			{({ innerRef, placeholder }) => (
				<div ref={innerRef} data-test-id={droppableId}>
					{list.length > 0 ? (
						list
							.filter(item => item.title.toLowerCase().includes(searchString.toLowerCase()))
							.map((item, index) => (
								<Draggable
									key={item.id}
									draggableId={`${item.id}`}
									index={index}
									isDragDisabled={isDragDisabled}
								>
									{({ draggableProps, dragHandleProps: eventHandlers, innerRef }, { isDragging }) => (
										<DraggableListItem
											ref={innerRef}
											checked={isSelectedList}
											dragDisabled={isDragDisabled}
											id={`${item.id}`}
											title={item.title}
											checkboxDisabled={item.required || isMoveDisabled}
											onSelectCheckbox={event => {
												return onItemCheckboxChange(
													index,
													droppableId,
													event.target.id as string
												);
											}}
											isDragging={isDragging}
											withFadeIn={item.withFadeIn}
											{...eventHandlers}
											{...draggableProps}
										/>
									)}
								</Draggable>
							))
					) : (
						<div className={styles.emptyItem}>{emptyText}</div>
					)}

					{placeholder}
				</div>
			)}
		</Droppable>
	);
};
export default DropContent;
