import React from 'react';
import { Checkbox } from '@bringg/react-components';
import { useTranslation } from 'react-i18next';
import _toUpper from 'lodash/toUpper';
import _startCase from 'lodash/startCase';
import { useStores } from '../../../../../../recipes';
import { useObserver } from 'mobx-react';

const NotificationsConfigurationSection: React.FC = () => {
	const { t } = useTranslation();
	const { storeAppStore } = useStores();

	return useObserver(() => (
		<div className="notification-checkboxes">
			{Object.keys(storeAppStore.notificationsConfig).map(key => {
				const onChange = e => {
					const newConfig = { ...storeAppStore.notificationsConfig, [key]: e.target.checked };
					storeAppStore.setApplicationMerchantConfiguration({
						...storeAppStore.applicationMerchantConfig,
						notifications_configuration: newConfig
					});
				};

				return (
					<Checkbox
						key={key}
						checked={storeAppStore.notificationsConfig[key]}
						onChange={onChange}
						className="store-app-settings-checkbox"
					>
						{t(`STORE_APP_CONFIG.${_toUpper(key)}`, { defaultValue: _startCase(key) })}
					</Checkbox>
				);
			})}
		</div>
	));
};

export default NotificationsConfigurationSection;
