import React, { useEffect, useState } from 'react';

import { Modal, Notification } from '@bringg/react-components';
import { PlannedDeliveryWindow } from '@bringg/dashboard-sdk';
import moment, { Moment } from 'moment-timezone';
import { useTranslation } from 'react-i18next';
import { EndOfWeekDay } from '@bringg/types';
import { isEqual as _isEqual, cloneDeep as _cloneDeep } from 'lodash';
import { dateUtils } from '@bringg-frontend/utils';

import { useStores } from 'bringg-web/recipes/index';
import { durationCalculate } from 'bringg-web/features/planned-delivery-windows/services/duration-calculate';
import useTimeFormat from 'bringg-web/hooks/use-time-format';
import { NO_TEAM } from 'bringg-web/features/planned-delivery-windows/planned-delivery-windows-view';
import ConfigurationStep from 'bringg-web/features/planned-delivery-windows/modals/slots-modal/wizard-steps/configuration-step/configuration-step';
import ModalFooter from 'bringg-web/features/planned-delivery-windows/modals/slots-modal/footer/modal-footer';
import TableStep, {
	PlannedDeliveryWindowsSlots
} from 'bringg-web/features/planned-delivery-windows/modals/slots-modal/wizard-steps/table-step/table-step';
import {
	CutoffEnum,
	DailyCutoff,
	RelativeCutoff,
	RelativeOptions
} from 'bringg-web/features/planned-delivery-windows/modals/slots-modal/wizard-steps/configuration-step/time-slots/cutoff-radio-group/cutoff.types';
import {
	allWeek,
	Duration,
	initialDailyCutoff,
	initialRelativeCutoff,
	PlannedDeliveryWindowOmitted,
	PlannedDeliveryWindowsStepWizard
} from 'bringg-web/features/planned-delivery-windows/modals/slots-modal/planned-delivery-windows-slots.consts';
import { plannedDeliveryWindowsSlotsGenerator } from 'bringg-web/features/planned-delivery-windows/services/planned-delivery-windows-slots-generator';
import { cutoffCalculation } from 'bringg-web/features/planned-delivery-windows/services/cutoff-calculation';
import { ModalHeader } from 'bringg-web/features/planned-delivery-windows/modals/slots-modal/header/modal-header';
import RecurrenceTypeModal, {
	RecurrenceDisabled
} from 'bringg-web/features/planned-delivery-windows/modals/recurrence-type-modal/recurrence-type-modal';
import {
	RecurrenceType,
	RecurrenceTypeModalModes
} from 'bringg-web/features/planned-delivery-windows/modals/recurrence-type-modal/recurrence-type-modal.consts';

import styles from './planned-delivery-windows-slots-modal.module.scss';

interface Props {
	modalVisible: boolean;
	closeModal: () => void;
	plannedDeliveryWindow?: PlannedDeliveryWindow;
	selectedTeam: number;
	timezone: string;
	refetchPlannedDeliveryWindows: () => Promise<void>;
	updatePdw: (
		updatedPlannedDeliveryWindow: Partial<PlannedDeliveryWindow>,
		recurrenceType: RecurrenceType
	) => Promise<void>;
	removePdw: (id: number[], recurrenceType: RecurrenceType) => Promise<void>;
}

function getDefaultDeliveryWindow(
	selectedTeam: number,
	timezone: string,
	plannedDeliveryWindow?: PlannedDeliveryWindow
): PlannedDeliveryWindowOmitted {
	if (plannedDeliveryWindow) {
		return plannedDeliveryWindow;
	}
	const startDate = moment().tz(timezone).startOf('day').add(8, 'h');
	return {
		id: null,
		team_id: selectedTeam === NO_TEAM ? null : selectedTeam,
		start_time: dateUtils.diffMinutesFromLastMonday(startDate),
		end_time: dateUtils.diffMinutesFromLastMonday(startDate.clone().add(8, 'h')),
		name: '',
		service_area_ids: [],
		service_plan_ids: [],
		cutoff: 0,
		effective_start_date: moment().tz(timezone).startOf('day').toISOString(),
		effective_end_date: null,
		series_effective_start_date: moment().tz(timezone).startOf('day').toISOString(),
		series_effective_end_date: null
	};
}

export type ModalLoading = {
	create: boolean;
	update: boolean;
	remove: boolean;
};
const PlannedDeliveryWindowsSlotsModal = ({
	modalVisible,
	closeModal,
	timezone,
	plannedDeliveryWindow,
	selectedTeam,
	refetchPlannedDeliveryWindows,
	removePdw,
	updatePdw
}: Props) => {
	const { t } = useTranslation();
	const [plannedDeliveryWindowEditable, setPlannedDeliveryWindowEditable] = useState<PlannedDeliveryWindowOmitted>(
		getDefaultDeliveryWindow(selectedTeam, timezone, plannedDeliveryWindow)
	);
	const [recurrenceTypeModalVisible, setRecurrenceTypeModalVisible] = useState(false);
	const [recurrenceTypeModalMode, setRecurrenceTypeModalMode] = useState<RecurrenceTypeModalModes>(
		RecurrenceTypeModalModes.EDIT
	);
	const isEditMode = plannedDeliveryWindow !== null;
	const [copyOfPlannedDeliveryWindow, setCopyOfPlannedDeliveryWindow] = useState<PlannedDeliveryWindowOmitted | null>(
		null
	);
	const [stepWizard, setStepWizard] = useState<PlannedDeliveryWindowsStepWizard>(
		PlannedDeliveryWindowsStepWizard.CONFIGURATION
	);
	const [plannedDeliveryWindowsSlots, setPlannedDeliveryWindowsSlots] = useState<PlannedDeliveryWindowsSlots[]>([]);
	const [daysInWeek, setDaysInWeek] = useState<EndOfWeekDay[]>(allWeek);
	const [timeDuration, setTimeDuration] = useState<Duration>(
		durationCalculate.getDurations(
			plannedDeliveryWindowEditable.start_time,
			plannedDeliveryWindowEditable.end_time,
			timezone
		)
	);
	const [cutoffType, setCutoffType] = useState<CutoffEnum>(CutoffEnum.RELATIVE);
	const [relativeCutoff, setRelativeCutoff] = useState<RelativeCutoff>(
		isEditMode ? cutoffCalculation.cutoffToRelative(plannedDeliveryWindow.cutoff) : initialRelativeCutoff
	);
	const [dailyCutoff, setDailyCutoff] = useState<DailyCutoff>(initialDailyCutoff(timezone));
	const [plannedDeliveryWindowsModalLoaders, setPlannedDeliveryWindowsModalLoaders] = useState<ModalLoading>({
		update: false,
		remove: false,
		create: false
	});
	const [recurrenceDisabled, setRecurrenceDisabled] = useState<RecurrenceDisabled>({
		current: false,
		current_and_following: false,
		all: false
	});
	const { merchantConfigurationsStore, plannedDeliveryWindows, servicePlansStore } = useStores();
	const use12Hours = dateUtils.use12Hours(merchantConfigurationsStore.hourFormat);
	const format = useTimeFormat();

	useEffect(() => {
		if (isEditMode) {
			setCopyOfPlannedDeliveryWindow(_cloneDeep(plannedDeliveryWindowEditable));
		}
	}, []);

	useEffect(() => {
		updateDeliveryWindow('team_id', selectedTeam === NO_TEAM ? null : selectedTeam);
	}, [selectedTeam]);

	const updateDeliveryWindow = <T,>(key: keyof Omit<PlannedDeliveryWindow, 'id' | 'merchant_id'>, value: T) => {
		setPlannedDeliveryWindowEditable(prev => ({ ...prev, [key]: value }));
	};

	const onClose = () => {
		closeModal();
	};

	const isPlannedDeliveryWindowChanged =
		isEditMode &&
		(!_isEqual(copyOfPlannedDeliveryWindow, plannedDeliveryWindowEditable) ||
			!(cutoffCalculation.calculateRelative(relativeCutoff) === copyOfPlannedDeliveryWindow.cutoff));

	const createWindows = async () => {
		try {
			setPlannedDeliveryWindowsModalLoaders(prevState => ({ ...prevState, create: true }));
			await plannedDeliveryWindows.create(
				plannedDeliveryWindowsSlotsGenerator.generateWindowsSlotsForCreation(
					plannedDeliveryWindowsSlots,
					cutoffType,
					plannedDeliveryWindowEditable.name,
					servicePlansStore,
					format,
					timezone
				)
			);
			await refetchPlannedDeliveryWindows();
			Notification.success(t('PLANNED_DELIVERY_WINDOWS.CREATE_SUCCESS'));
			onClose();
		} catch (e) {
			console.error('cannot create pdw: ', e);
			Notification.error(t('PLANNED_DELIVERY_WINDOWS.CREATE_FAILED'));
		} finally {
			setPlannedDeliveryWindowsModalLoaders(prevState => ({ ...prevState, create: false }));
		}
	};

	const updateTimeRange = (startTime: Moment, endTime: Moment) => {
		const startTimeInMinutes = dateUtils.diffMinutesFromLastMonday(startTime);
		const endTimeInMinutes = dateUtils.diffMinutesFromLastMonday(endTime);
		updateDeliveryWindow('start_time', startTimeInMinutes);
		updateDeliveryWindow('end_time', endTimeInMinutes);

		const duration = durationCalculate.getDurations(startTimeInMinutes, endTimeInMinutes, timezone);
		updateTimeDuration(duration);
	};

	const updateTimeDuration = (duration: Duration) => {
		const isRelativeCutoffAfterOption =
			cutoffType === CutoffEnum.RELATIVE && relativeCutoff.relativeOption === RelativeOptions.AFTER;
		const sumOfCutoffWithoutDays = dateUtils.sumOfMinutes(relativeCutoff.hours, relativeCutoff.minutes);
		const sumOfDuration = dateUtils.sumOfMinutes(duration.hours, duration.minutes);
		const isRelativeCutoffGTDuration = sumOfCutoffWithoutDays > sumOfDuration;

		if (isRelativeCutoffAfterOption && isRelativeCutoffGTDuration) {
			setRelativeCutoff(prevState => ({ ...prevState, ...duration }));
		}

		setTimeDuration(duration);
	};

	const onChangeName = (name: string) => {
		updateDeliveryWindow('name', name);
	};

	const updateServicePlanIds = (servicePlanIds: number[]) => {
		updateDeliveryWindow('service_plan_ids', servicePlanIds);
	};

	const updateServiceAreasIds = (serviceAreasIds: number[]) => {
		updateDeliveryWindow('service_area_ids', serviceAreasIds);
	};

	const generateWindows = () => {
		const startTimeInMinutes = plannedDeliveryWindowEditable.start_time;
		const endTimeInMinutes = plannedDeliveryWindowEditable.end_time;
		const durationInMinutes = timeDuration.minutes + dateUtils.hoursToMinutes(timeDuration.hours);
		const windowsInDays: PlannedDeliveryWindowsSlots[] = plannedDeliveryWindowsSlotsGenerator.generateWindowSlots({
			startTimeInMinutes,
			endTimeInMinutes,
			durationInMinutes,
			daysInWeek,
			plannedDeliveryWindowEditable,
			cutoff: cutoffType === CutoffEnum.RELATIVE ? relativeCutoff : dailyCutoff,
			timezone
		});

		setPlannedDeliveryWindowsSlots(windowsInDays);
	};

	const updateWindow = (
		windowToUpdate: PlannedDeliveryWindowsSlots,
		updateValues: Partial<PlannedDeliveryWindowsSlots>
	) => {
		setPlannedDeliveryWindowsSlots(prevState => {
			const copy = [...prevState];
			const index = plannedDeliveryWindowsSlots.findIndex(item => item === windowToUpdate);
			copy[index] = {
				...copy[index],
				...updateValues
			};
			return copy;
		});
	};

	const checkAndUpdateEffectiveDate = (
		prevPlannedDeliveryWindow: PlannedDeliveryWindow,
		updatedPlannedDeliveryWindow: PlannedDeliveryWindowOmitted
	) => {
		if (prevPlannedDeliveryWindow.effective_start_date === updatedPlannedDeliveryWindow.effective_start_date) {
			updatedPlannedDeliveryWindow.effective_start_date =
				updatedPlannedDeliveryWindow.series_effective_start_date;
		}

		if (prevPlannedDeliveryWindow.effective_end_date === updatedPlannedDeliveryWindow.effective_end_date) {
			updatedPlannedDeliveryWindow.effective_end_date = updatedPlannedDeliveryWindow.series_effective_end_date;
		}
	};

	const updatePlannedDeliveryWindow = async (recurrenceType: RecurrenceType) => {
		try {
			setPlannedDeliveryWindowsModalLoaders(prevState => ({ ...prevState, update: true }));
			const plannedDeliveryWindowToUpdate: PlannedDeliveryWindowOmitted = plannedDeliveryWindowEditable;
			checkAndUpdateEffectiveDate(plannedDeliveryWindow, plannedDeliveryWindowToUpdate);

			await updatePdw(
				{
					...plannedDeliveryWindowToUpdate,
					cutoff: cutoffCalculation.calculateRelative(relativeCutoff)
				},
				recurrenceType
			);
			closeModal();
		} catch (e) {
			setPlannedDeliveryWindowsModalLoaders(prevState => ({ ...prevState, update: false }));
			console.error('failed to update window slot', e);
		}
	};

	const removePlannedDeliveryWindow = async (recurrenceType: RecurrenceType) => {
		try {
			setPlannedDeliveryWindowsModalLoaders(prevState => ({ ...prevState, remove: true }));
			await removePdw([plannedDeliveryWindowEditable.id], recurrenceType);
			closeModal();
		} catch (e) {
			setPlannedDeliveryWindowsModalLoaders(prevState => ({ ...prevState, remove: false }));
			console.error('failed to remove window slot', e);
		}
	};

	const changeEffectiveDates = (effectiveStartDate: string | null, effectiveEndDate: string | null) => {
		setPlannedDeliveryWindowEditable(prevState => ({
			...prevState,
			effective_start_date: effectiveStartDate || null,
			effective_end_date: effectiveEndDate || null,
			series_effective_start_date: effectiveStartDate || null,
			series_effective_end_date: effectiveEndDate || null
		}));
	};

	const closeRecurrenceTypeModalAndCancel = () => {
		setPlannedDeliveryWindowsModalLoaders(prevState => ({ ...prevState, update: false, remove: false }));
		setRecurrenceTypeModalVisible(false);
	};

	const openRecurrenceTypeModalWithEditMode = () => {
		setRecurrenceTypeModalMode(RecurrenceTypeModalModes.EDIT);
		setPlannedDeliveryWindowsModalLoaders(prevState => ({ ...prevState, update: true }));

		if (plannedDeliveryWindowEditable.single_day) {
			setRecurrenceDisabled(prevState => ({ ...prevState, current_and_following: true, all: true }));
		}
		setRecurrenceTypeModalVisible(true);
	};

	const openRecurrenceTypeModalWithDeleteMode = () => {
		setRecurrenceTypeModalMode(RecurrenceTypeModalModes.REMOVE);
		setPlannedDeliveryWindowsModalLoaders(prevState => ({ ...prevState, remove: true }));
		if (plannedDeliveryWindowEditable.single_day) {
			setRecurrenceDisabled(prevState => ({ ...prevState, current_and_following: true, all: true }));
		}
		setRecurrenceTypeModalVisible(true);
	};

	return (
		<>
			<Modal
				width={960}
				visible={modalVisible}
				destroyOnClose
				title={<ModalHeader onChangeName={onChangeName} name={plannedDeliveryWindowEditable.name} />}
				wrapClassName={styles.deliveryWindowsModalWrapper}
				className={styles.deliveryWindowsModal}
				onCancel={onClose}
				footer={
					<ModalFooter
						isEditMode={isEditMode}
						stepWizard={stepWizard}
						setStepWizard={setStepWizard}
						onCancel={onClose}
						createWindows={createWindows}
						generateWindows={generateWindows}
						daysInWeekLength={daysInWeek.length}
						openRecurrenceTypeModalWithEditMode={openRecurrenceTypeModalWithEditMode}
						openRecurrenceTypeModalWithDeleteMode={openRecurrenceTypeModalWithDeleteMode}
						plannedDeliveryWindowsModalLoaders={plannedDeliveryWindowsModalLoaders}
						isPlannedDeliveryWindowChanged={isPlannedDeliveryWindowChanged}
						windowsLength={plannedDeliveryWindowsSlots.length}
					/>
				}
			>
				{stepWizard === PlannedDeliveryWindowsStepWizard.CONFIGURATION && (
					<ConfigurationStep
						use12Hours={use12Hours}
						format={format}
						timezone={timezone}
						updateTimes={updateTimeRange}
						plannedDeliveryWindowEditable={plannedDeliveryWindowEditable}
						changeEffectiveDates={changeEffectiveDates}
						timeDuration={timeDuration}
						updateTimeDuration={updateTimeDuration}
						cutoffType={cutoffType}
						dailyCutoff={dailyCutoff}
						setDailyCutoff={setDailyCutoff}
						relativeCutoff={relativeCutoff}
						setRelativeCutoff={setRelativeCutoff}
						setCutoffType={setCutoffType}
						daysInWeek={daysInWeek}
						setDaysInWeek={setDaysInWeek}
						teamId={selectedTeam}
						updateServiceAreasIds={updateServiceAreasIds}
						updateServicePlanIds={updateServicePlanIds}
						isEditMode={isEditMode}
					/>
				)}
				{stepWizard === PlannedDeliveryWindowsStepWizard.TABLE && (
					<TableStep
						startTimeInMinutes={plannedDeliveryWindowEditable.start_time}
						endTimeInMinutes={plannedDeliveryWindowEditable.end_time}
						plannedDeliveryWindowsSlots={plannedDeliveryWindowsSlots}
						format={format}
						updateWindow={updateWindow}
						cutoffType={cutoffType}
						use12Hours={use12Hours}
						teamId={selectedTeam}
						timeDuration={timeDuration}
						timezone={timezone}
					/>
				)}
			</Modal>
			{recurrenceTypeModalVisible && (
				<RecurrenceTypeModal
					visible={recurrenceTypeModalVisible}
					closeAndCancel={closeRecurrenceTypeModalAndCancel}
					isEditMode={recurrenceTypeModalMode === RecurrenceTypeModalModes.EDIT}
					onUpdate={updatePlannedDeliveryWindow}
					onDelete={removePlannedDeliveryWindow}
					recurrenceDisabled={recurrenceDisabled}
				/>
			)}
		</>
	);
};

export default PlannedDeliveryWindowsSlotsModal;
