import React from 'react';
import { LabelNumberInput } from '@bringg/react-components';
import { Options } from 'rrule';

interface Props {
	options: Options;
	onOptionsChange: (options: Partial<Options>) => void;
	disabled: boolean;
	everyLabel: string;
	monthsLabel: string;
	onLabel: string;
}

const RecurringMonthly = ({ options, onOptionsChange, disabled, everyLabel, monthsLabel, onLabel }: Props) => {
	const onIntervalChange = interval => onOptionsChange({ interval });
	const onMonthDayChange = bymonthday => onOptionsChange({ bymonthday });

	return (
		<>
			<div className="recurring-frequency">
				<label>{everyLabel}:</label>
				<LabelNumberInput
					label={monthsLabel}
					minValue={1}
					defaultValue={options.interval}
					onChange={onIntervalChange}
					disabled={disabled}
					data-test-id="recurring-monthly-every"
				/>
			</div>
			<div className="recurring-frequency">
				<label>{onLabel}:</label>
				<LabelNumberInput
					label=""
					minValue={1}
					maxValue={31}
					defaultValue={options.bymonthday as number}
					onChange={onMonthDayChange}
					disabled={disabled}
					data-test-id="recurring-monthly-on"
				/>
			</div>
		</>
	);
};

export default RecurringMonthly;
