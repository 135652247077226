import { getRootEnv } from '@bringg-frontend/bringg-web-infra';
import { action, observable, makeObservable } from 'mobx';

import CustomerConfiguration from './domain-objects/customer-configuration';
import { registerAction } from 'bringg-web/services/cross-application/cross-application';
import { CustomerAppConfiguration } from 'bringg-web/services/cross-application/cross-application.actions';

class CustomerConfigurationsStore {
	isFetched = false;
	configuration: CustomerConfiguration = null;

	private cleanupGlobalListener: () => void;

	constructor() {
		makeObservable(this, {
			isFetched: observable,
			configuration: observable,
			setFetched: action,
			storeConfiguration: action
		});

		this.cleanupGlobalListener = registerAction(
			CustomerAppConfiguration.CUSTOMER_APP_CONFIGURATION_UPDATED,
			() => this.forceFetch(),
			getRootEnv().dashboardSdk.sdk.crossAppTransport
		);
	}

	forceFetch = async (): Promise<void> => {
		this.isFetched = false;
		return await this.fetch();
	};

	setFetched = () => {
		this.isFetched = true;
	};

	storeConfiguration = (config: Bringg.CustomerConfiguration) => {
		this.configuration = new CustomerConfiguration(config);
	};

	fetch = async (): Promise<void> => {
		if (this.isFetched) {
			return;
		}
		this.storeConfiguration(await getRootEnv().dashboardSdk.sdk.customerConfiguration.get());
		this.setFetched();
	};

	update = async (): Promise<void> => {
		await this.configuration.update();
	};

	resetAfterRemoveRoot() {
		this.cleanupGlobalListener();
		this.cleanupGlobalListener = () => undefined;
	}
}

export default CustomerConfigurationsStore;
