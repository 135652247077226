import i18next from 'i18next';
import { computed, makeObservable, observable, reaction } from 'mobx';
import { computedFn } from 'mobx-utils';
import {
	AlertAction,
	FactType,
	SharedLocationActionMetadata,
	GenericAction,
	MetadataResponse,
	UpdateAction,
	DisplayFact,
	OptimizationActionMetadata,
	CancelTaskActionMetadata,
	NarrowActionableWpTimeWindowDataMetadata,
	CustomWebhookMetadata
} from '@bringg/types';

import { ActionFamily } from './actions-repo';
import { notify } from '../utils/use-automation-translations';
import { getFlatTriggersById } from '../utils/triggers-utils';
import { ActionFamilyType, MappedAttribute, MappedTriggerData } from '../utils/types';
import { mapActions, mapAttributeFromServer, mapTriggerMetadataFromServer } from '../utils/mapper';
import { getFlatList } from '../utils/helpers';
import { webhookSpecialKey } from '../utils/filter-bar-utils';

class MetadataStore {
	metadata: MetadataResponse;
	actions: ActionFamily[];

	private attributesByFactType: Record<FactType, MappedAttribute[]>;
	facts: { type: FactType; title: string; displayFact: DisplayFact; items: MappedAttribute[] }[];

	constructor(metadata: MetadataResponse, disposeSignal: AbortSignal) {
		makeObservable(this, {
			metadata: observable,
			triggers: computed,
			triggersById: computed,
			flatTriggers: computed,
			actionsByType: computed,
			actionsData: computed,
			cancelTaskReasonsMap: computed,
			hasReadOnlyActions: computed
		});
		this.metadata = metadata;
		this.actions = mapActions(metadata.actions);

		reaction(
			() => this.metadata.facts,
			(facts: MetadataResponse['facts']) => {
				this.facts = facts.map(({ type, title, attributes, display_fact }) => ({
					type,
					title: i18next.t(title),
					displayFact: display_fact,
					items: attributes.map(attr => mapAttributeFromServer(attr, type))
				}));

				this.attributesByFactType = this.facts.reduce((acc, { type, items }) => {
					if (!acc[type]) {
						acc[type] = [];
					}
					acc[type].push(...items);
					return acc;
				}, {} as Record<FactType, MappedAttribute[]>);
			},
			{
				fireImmediately: true,
				signal: disposeSignal
			}
		);
	}

	get triggers(): MappedTriggerData[] {
		return this.metadata.triggers
			.filter(currTrigger => currTrigger.items.length > 0)
			.map(trigger => mapTriggerMetadataFromServer(trigger));
	}

	get triggersById(): { [id: string]: MappedTriggerData } {
		return getFlatTriggersById(this.triggers) || {};
	}

	get flatTriggers(): MappedTriggerData[] {
		return Object.values(this.triggersById);
	}

	factsByFactType(factType: FactType, displayFact: DisplayFact): MappedAttribute[] {
		return (
			this.facts.find(fact => {
				return fact.displayFact === displayFact;
			})?.items || []
		);
	}

	getComparable(factType: FactType): (path: string) => string[] {
		return (path: string) => {
			return this.metadata.fact_comparison[factType]?.[path] || [];
		};
	}

	flatAttributesByPath = computedFn((factType: FactType): { [path: string]: MappedAttribute } => {
		const attributes = this.attributesByFactType[factType];
		return getFlatList(attributes, 'path');
	});

	displayFactType = (factType: FactType): string => {
		const { title } = this.metadata.facts.find(fact => fact.type === factType);
		return i18next.t(title);
	};

	get actionsData(): ActionFamily[] {
		return [
			...(this.actions || []),
			{
				type: ActionFamilyType.NOTIFY,
				title: i18next.t(notify)
			}
		];
	}

	get actionsByType() {
		return this.actions
			.filter(({ type }) => type !== ActionFamilyType.NOTIFY)
			.reduce((acc, { actionType, title, isWebhook }) => {
				if (isWebhook) {
					acc[webhookSpecialKey] = title;
				} else {
					acc[actionType] = title;
				}
				return acc;
			}, {});
	}

	getActionMetaData = (
		actionType: ActionFamilyType
	):
		| AlertAction
		| UpdateAction
		| GenericAction
		| SharedLocationActionMetadata
		| OptimizationActionMetadata
		| CancelTaskActionMetadata
		| NarrowActionableWpTimeWindowDataMetadata
		| CustomWebhookMetadata => {
		return this.metadata.actions[actionType];
	};

	get hasReadOnlyActions() {
		return this.actions.some(action => action.disabled);
	}

	get cancelTaskReasonsMap() {
		return new Map(
			((this.getActionMetaData(ActionFamilyType.CANCEL_TASK) as CancelTaskActionMetadata)?.values || []).map(
				reason => [reason.key, reason]
			)
		);
	}
}

export default MetadataStore;
