import React, { Component, useMemo } from 'react';
import { Provider, inject, observer } from 'mobx-react';
import { Col, Row } from 'antd';
import ChatStore from '../stores/chat-store';
import { chatRootStore } from '../stores/chat-root-store';
import { Spinner } from 'bringg-web/components';
import ChatList from '../chat-list/chat-list';
import ChatWindow from '../chat-window/chat-window';

interface Props {
	chatStore?: ChatStore;
}

class ChatContainer extends Component<Props, {}> {
	componentDidMount() {
		this.props.chatStore.attachListeners();
		this.props.chatStore.fetchConversations();
	}

	render() {
		const { isFetched } = this.props.chatStore;
		return (
			<div className="chat">
				{isFetched ? (
					<Row className="chat-wrapper">
						<Col span={5}>
							<ChatList />
						</Col>
						<Col span={12}>{<ChatWindow />}</Col>
					</Row>
				) : (
					<Spinner className="center" />
				)}
			</div>
		);
	}
}

export const ChatConnected = inject('chatStore')(observer(ChatContainer));

const ChatContainerWrapper = () => {
	const ChatRootStore = useMemo(() => chatRootStore.getStore(), []);

	return (
		<Provider {...ChatRootStore}>
			<ChatConnected />
		</Provider>
	);
};

export default ChatContainerWrapper;
