import { useEffect } from 'react';

import { useStores } from 'bringg-web/recipes';
import { useHasFeatureFlag } from 'bringg-web/utils/feature-flags';

const useCustomAttributesReady = () => {
	const { customAttributesStore } = useStores();
	const isCustomAttributesEnabled = useHasFeatureFlag('enable_custom_attributes');
	const { isFetched, isLoading } = customAttributesStore;

	useEffect(() => {
		if (isCustomAttributesEnabled && !isFetched && !isLoading) {
			customAttributesStore.fetchAll();
		}
	}, []);

	return {
		isCustomAttributesEnabled: isCustomAttributesEnabled,
		isCustomAttributesFetched: isCustomAttributesEnabled ? isFetched : false
	};
};

export default useCustomAttributesReady;
