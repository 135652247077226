import React from 'react';

import { Portal } from '@bringg-frontend/bringg-web-infra';

import { CustomAttributes } from '../../custom-attributes';

const CustomAttributesPortal = () => {
	return <Portal element={<CustomAttributes />} nodeId="custom-attributes-page" />;
};

export default CustomAttributesPortal;
