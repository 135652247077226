import React, { FunctionComponent } from 'react';
import { LanguageSelect } from 'bringg-web/components/language-select';
import { useStores } from '../../../recipes';
import { useObserver } from 'mobx-react';

interface Props {
	selectedLanguageCode: string;
	setLang: (code: string) => void;
}

const DEFAULT_LANG = 'en';

const CustomerRatingHeader: FunctionComponent<Props> = ({ selectedLanguageCode, setLang }) => {
	const { notificationTemplatesStore } = useStores();

	return useObserver(() => {
		const ratingReasonLanguages = notificationTemplatesStore.ratingReasonLanguages;
		let availableLanguages = notificationTemplatesStore.availableLanguages;

		availableLanguages = availableLanguages.map(language => ({
			...language,
			hasTemplates: ratingReasonLanguages.includes(language.code)
		}));

		return (
			<div className="customer-rating-header">
				<LanguageSelect
					selectedLanguageCode={selectedLanguageCode}
					defaultLanguageCode={DEFAULT_LANG}
					availableLanguages={availableLanguages}
					selectedLanguageCodeFunc={setLang}
				/>
			</div>
		);
	});
};

export default CustomerRatingHeader;
