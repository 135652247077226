import { isEqual } from 'lodash';

export function enclosePolygon(polygon: number[][]) {
	const first = polygon[0];
	const last = polygon[polygon.length - 1];

	if (isEqual(first, last)) {
		return polygon;
	}

	return [...polygon, [...first]]; // return a copy with the first point appended to the end
}

export function openPolygonPath(path: google.maps.LatLng[]) {
	const first = path[0];
	const last = path[path.length - 1];

	if (first.equals(last)) {
		return path.slice(0, -1);
	}

	return path;
}
