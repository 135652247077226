import React, { useMemo } from 'react';
import type { Rule } from 'antd/lib/form';
import { TypographyTitle, InputNumber, Tooltip, FormItem } from '@bringg/react-components';
import { BringgFontIcons, BringgIcon } from '@bringg/bringg-icons';
import { useTranslation } from 'react-i18next';

import HandlingUnitsInput from './components/handling-units-input';

import { MIN_NUMBER_VALUE } from './constants';

const CapacityRestrictionsSection = () => {
	const { t } = useTranslation();
	const inputNumberRules = useMemo<Rule[]>(
		() => [{ type: 'number', min: MIN_NUMBER_VALUE, message: t('VEHICLE_PROFILES.ERROR.NUMBER_INPUT') }],
		[t]
	);

	return (
		<div className="vehicle-profiles-form--section-wrapper">
			<div>
				<TypographyTitle level={4} className="vehicle-profiles-form--section-icon">
					<BringgIcon iconName={BringgFontIcons.Weight} />
				</TypographyTitle>
			</div>
			<div className="vehicle-profiles-form--section-content">
				<TypographyTitle level={4} className="vehicle-profiles-form--section-title">
					{t('VEHICLE_PROFILES.FORM.CAPACITY_RESTRICTIONS')}
				</TypographyTitle>
				<div className="vehicle-profiles-form--section-sub-title">
					{t('VEHICLE_PROFILES.FORM.CAPACITY_RESTRICTIONS_DESCRIPTION')}
				</div>
				<FormItem name="handling_units" className="vehicle-profiles-form-item">
					<HandlingUnitsInput />
				</FormItem>
				<div className="vehicle-profiles-form--flex-group">
					<div className="vehicle-profiles-form--input-group">
						<div className="vehicle-profiles-form--label-text">
							{t('VEHICLE_PROFILES.FORM.MAX_TOTAL_WEIGHT')}
						</div>
						<FormItem
							name="max_total_weight"
							rules={inputNumberRules}
							className="vehicle-profiles-form-item"
						>
							<InputNumber
								placeholder={t('VEHICLE_PROFILES.FORM.WEIGHT_CAPACITY_PLACEHOLDER')}
								className="vehicle-profiles-form--input"
								data-test-id="max_total_weight"
							/>
						</FormItem>
					</div>
					<div className="vehicle-profiles-form--input-group">
						<div className="vehicle-profiles-form--label-text">
							{t('VEHICLE_PROFILES.FORM.MAXIMUM_TASKS_PER_ROUTE')}
							&nbsp;
							<Tooltip
								overlayClassName="attribute-input-tooltip"
								placement="right"
								title={t('VEHICLE_PROFILES.FORM.HELP.MAXIMUM_TASKS_PER_ROUTE')}
							>
								<BringgIcon className="info-icon" iconName={BringgFontIcons.Info} />
							</Tooltip>
						</div>
						<FormItem
							name="maximum_tasks_per_route"
							rules={inputNumberRules}
							className="vehicle-profiles-form-item"
						>
							<InputNumber
								placeholder={t('VEHICLE_PROFILES.FORM.MAXIMUM_TASKS_PER_ROUTE_PLACEHOLDER')}
								className="vehicle-profiles-form--input"
								data-test-id="maximum_tasks_per_route"
							/>
						</FormItem>
					</div>
				</div>

				<div className="vehicle-profiles-form--flex-group">
					<div className="vehicle-profiles-form--group-title">
						{t('VEHICLE_PROFILES.FORM.DIMENSIONS_TITLE')}
						<Tooltip
							overlayClassName="attribute-input-tooltip"
							placement="right"
							title={t('VEHICLE_PROFILES.FORM.HELP.DIMENSIONS')}
						>
							<BringgIcon className="info-icon" iconName={BringgFontIcons.Info} />
						</Tooltip>
					</div>
					<div className="vehicle-profiles-form--input-group">
						<div className="vehicle-profiles-form--label-text">{t('VEHICLE_PROFILES.FORM.HEIGHT')}</div>
						<FormItem name="height" rules={inputNumberRules} className="vehicle-profiles-form-item">
							<InputNumber
								placeholder={t('VEHICLE_PROFILES.FORM.HEIGHT_PLACEHOLDER')}
								className="vehicle-profiles-form--input"
								data-test-id="container-height"
							/>
						</FormItem>
					</div>

					<div className="vehicle-profiles-form--input-group">
						<div className="vehicle-profiles-form--label-text">{t('VEHICLE_PROFILES.FORM.WIDTH')}</div>
						<FormItem name="width" rules={inputNumberRules} className="vehicle-profiles-form-item">
							<InputNumber
								placeholder={t('VEHICLE_PROFILES.FORM.WIDTH_PLACEHOLDER')}
								className="vehicle-profiles-form--input"
								data-test-id="container-width"
							/>
						</FormItem>
					</div>
					<div className="vehicle-profiles-form--input-group">
						<div className="vehicle-profiles-form--label-text">{t('VEHICLE_PROFILES.FORM.LENGTH')}</div>
						<FormItem name="length" rules={inputNumberRules} className="vehicle-profiles-form-item">
							<InputNumber
								placeholder={t('VEHICLE_PROFILES.FORM.LENGTH_PLACEHOLDER')}
								className="vehicle-profiles-form--input"
								data-test-id="container-length"
							/>
						</FormItem>
					</div>
				</div>
			</div>
		</div>
	);
};

export default CapacityRestrictionsSection;
