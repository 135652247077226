export enum EmployeeStateFilter {
	ALL = 'all',
	ONLINE = 'online',
	ON_SHIFT = 'on_shift',
	ON_SHIFT_OFFLINE = 'on_shift_offline',
	OFF_SHIFT = 'off_shift'
}

export enum EmployeeAvailabilityState {
	HAS_TASKS = 'has_tasks',
	NO_TASKS = 'no_tasks'
}
