import React, { useState } from 'react';

import { useObserver } from 'mobx-react';
import classNames from 'classnames';
import i18next from 'i18next';
import { Fleet } from '@bringg/react-components/dist/features/fleet-router/rule-form/priority-configuration/priority-configuration';
import SpinnerWrapper from '@bringg/react-components/dist/components/spinner/spinner';
import { Rule, ReadOnlyMessage, AntdDivider } from '@bringg/react-components';
import { Condition } from '@bringg/react-components/dist/features/conditions/rule/rule-utils';
import { PrivilegeTypes } from '@bringg/types';
import { useTranslation } from 'react-i18next';

import { useHasAccess } from 'bringg-web/utils/privileges';
import { useStores } from 'bringg-web/recipes';
import { isInternalFleet } from 'bringg-web/stores/fleets/fleet-checker';
import { Button } from '../../../components';
import useConditionsSetStore from '../use-conditions-set-store';
import { State } from '../types';
import useRuleEngineTranslations from '../use-rule-engine-translations';
import notification from '../../../services/notification';

import './conditions-set.scss';

interface Props {
	fleet: Fleet;
}

const MIN_RULES_TO_COLLAPSE = 2;
const ConditionsSet = ({ fleet }: Props) => {
	const { t } = useTranslation();
	const { conditionsSetStore, state: conditionsSetStoreState } = useConditionsSetStore(fleet.id);
	const { merchantStore } = useStores();
	const { merchant } = merchantStore;

	const translations = useRuleEngineTranslations({
		trueKey: 'CONDITIONS.BOOLEAN.TRUE',
		falseKey: 'CONDITIONS.BOOLEAN.FALSE'
	});
	const [openRules, setOpenRules] = useState<Set<number>>(new Set());
	const readOnly = !useHasAccess(PrivilegeTypes.ENABLE_SETTING_PAGE);

	const removeRule = rule => {
		conditionsSetStore.removeRule(rule);
		setOpenRules(prevState => {
			const updatedOpenRules = new Set(prevState);
			updatedOpenRules.delete(rule.guid);
			return updatedOpenRules;
		});
	};

	const toggleOpenPanel = (isOpen: boolean, ruleGuid: number) => {
		setOpenRules(prevRules => {
			const newOpenRules = new Set(prevRules);
			if (isOpen) {
				newOpenRules.add(ruleGuid);
			} else {
				newOpenRules.delete(ruleGuid);
			}
			return newOpenRules;
		});
	};

	const addRule = () => {
		const { guid } = conditionsSetStore.addRule();
		toggleOpenPanel(true, guid);
	};
	const discardChanges = () => {
		conditionsSetStore.discardChanges();
	};
	const save = async () => {
		try {
			await conditionsSetStore.saveRules();
			setOpenRules(new Set());
			notification.success(i18next.t('CONDITIONS.CONDITIONS_SUCCESSFULLY_SAVED'));
		} catch {
			notification.error(i18next.t('CONDITIONS.FAILED_SAVING_CONDITIONS'));
		}
	};
	const handleChange = (rule, updatedConditions: Condition[]) => {
		conditionsSetStore.updateRule({
			...rule,
			conditions: updatedConditions
		});
	};

	const handleChangeConstraint = (rule, checked) => {
		conditionsSetStore.updateRule({
			...rule,
			hardConstraint: checked
		});
	};

	const changeRuleName = (rule, newName: string) => {
		conditionsSetStore.updateRule({
			...rule,
			description: newName
		});
	};
	const toggleEnabled = (rule, isEnabled) => {
		conditionsSetStore.updateRule({
			...rule,
			enabled: isEnabled
		});
	};

	const toggleCollapseAll = () => {
		if (openRules.size) {
			setOpenRules(new Set());
		} else {
			setOpenRules(new Set(conditionsSetStore.rules.map(rule => rule.guid)));
		}
	};

	return useObserver(() => {
		if (conditionsSetStoreState === State.Idle || conditionsSetStoreState === State.Loading) {
			return <SpinnerWrapper className="spinner-center" />;
		}
		const isSingleRule = conditionsSetStore.rules.length === 1;

		return (
			<>
				<ReadOnlyMessage showMessage={readOnly} message={translations.readonlyMessage} />
				<div className="conditions-set">
					<div className="title">
						{translations.conditionsSets} -{' '}
						<span className="fleet-name">
							{isInternalFleet(Number(fleet.id), fleet?.fleet_type)
								? t('ASSIGN_FLEET.INTERNAL_FLEET_TEXT')
								: fleet.name}
						</span>
						<div className="description">{translations.description}</div>
					</div>
					{conditionsSetStore.rules.length > MIN_RULES_TO_COLLAPSE && (
						<Button type="link" onClick={toggleCollapseAll} className="collapse-all-button">
							{openRules.size ? translations.collapse : translations.expand}
						</Button>
					)}
					<div className="conditions-rules-list">
						{conditionsSetStore.rules.map((rule, index) => (
							<div key={rule.guid} className={classNames({ 'non-collapsable': isSingleRule })}>
								<Rule
									conditions={rule.conditions}
									name={rule.description || translations.conditionsSet}
									onRemove={() => removeRule(rule)}
									setDirty={conditions => handleChange(rule, conditions)}
									hardConstraint={rule.hardConstraint}
									setHardConstraint={
										merchant?.['feature_flags']?.['delivery_terms_hard_constraint']
											? () => handleChangeConstraint(rule, !rule.hardConstraint)
											: null
									}
									translations={translations}
									attributes={conditionsSetStore.attributes}
									onNameChange={newName => {
										changeRuleName(rule, newName);
									}}
									isOpen={openRules.has(rule.guid) || isSingleRule}
									toggleOpen={isOpen => toggleOpenPanel(isOpen, rule.guid)}
									isEnabled={rule.enabled}
									toggleEnabled={checked => {
										toggleEnabled(rule, checked);
									}}
									disabled={readOnly}
								/>
								{index < conditionsSetStore.rules.length - 1 ? (
									<AntdDivider plain>{translations.or}</AntdDivider>
								) : null}
							</div>
						))}
					</div>
					<div className="conditions-footer">
						<Button
							type="link"
							className="add-button"
							onClick={addRule}
							disabled={!conditionsSetStore.rulesAreValid || readOnly}
						>
							{translations.addConditionsSet}
						</Button>
						<Button
							className="discard-button"
							onClick={discardChanges}
							disabled={!conditionsSetStore.isDirty || readOnly}
						>
							{translations.discardChanges}
						</Button>
						<Button
							type="primary"
							className="save-button"
							onClick={save}
							disabled={!conditionsSetStore.isDirty || !conditionsSetStore.rulesAreValid || readOnly}
						>
							{translations.saveAndDeploy}
						</Button>
					</div>
				</div>
			</>
		);
	});
};

export default ConditionsSet;
