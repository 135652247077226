import React from 'react';
import { EditableText } from '@bringg/react-components';
import { useTranslation } from 'react-i18next';
import { SummaryDetails } from './summary-details/summary-details';
import DeliveryBlock from '../../stores/domain-objects/delivery-block';
import { useStores } from '../../../../recipes';
import './modal-header.scss';

export interface Props {
	deliveryBlockEditable: Partial<DeliveryBlock>;
	timezone: string;
	onChangeName: (name: string) => void;
	onChangeDescription: (description: string) => void;
}

export const ModalHeader = ({ deliveryBlockEditable, timezone, onChangeName, onChangeDescription }: Props) => {
	const { merchantConfigurationsStore } = useStores();
	const { t } = useTranslation();
	const { name, description, start_time, end_time } = deliveryBlockEditable || {};

	return (
		<div className="delivery-block-modal-header">
			<SummaryDetails
				resources={{
					connectedResources: deliveryBlockEditable.delivery_block_resources.length,
					maxResources: deliveryBlockEditable.original_capacity
				}}
				name={name}
				blockTime={{ startTime: start_time, endTime: end_time }}
				hourFormat={merchantConfigurationsStore.hourStringFormat}
				timezone={timezone}
			/>

			<EditableText
				onValueChange={onChangeName}
				value={name}
				className="delivery-block-name"
				placeholder={t('DELIVERY_BLOCK_MODAL.NAME')}
			/>
			<EditableText
				onValueChange={onChangeDescription}
				value={description}
				className="delivery-block-description"
				placeholder={t('DELIVERY_BLOCK_MODAL.DESCRIPTION')}
			/>
		</div>
	);
};
