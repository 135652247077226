import React from 'react';
import { getFormattedTimeWindow, LayoutType } from '@bringg/react-components';
import { useObserver } from 'mobx-react';
import './summary-details.scss';
import { useTranslation } from 'react-i18next';

interface Props {
	name: string;
	blockTime: { startTime: string | null; endTime: string | null };
	resources: { connectedResources: number | undefined; maxResources: number | undefined };
	hourFormat: string;
	timezone: string;
}
export const SummaryDetails = ({ name, resources, blockTime, hourFormat, timezone }: Props) => {
	const { t } = useTranslation();
	const { connectedResources, maxResources } = resources || {};
	const { startTime, endTime } = blockTime || {};

	return useObserver(() => (
		<div className="delivery-block-summary-details">
			<span className="summary-details-title">{t('DELIVERY_BLOCK_MODAL.SUMMARY')}</span>
			<span className="summary-details-name">{name}</span>
			{startTime && endTime && (
				<span className="summary-details-block-time">
					{getFormattedTimeWindow(
						blockTime.startTime,
						blockTime.endTime,
						hourFormat,
						timezone,
						LayoutType.INLINE,
						''
					)}
				</span>
			)}
			<span className="summary-details-resources">
				{`${t('DELIVERY_BLOCK_MODAL.RESOURCES')}: ${connectedResources || '0'}/${maxResources || '0'}`}
			</span>
		</div>
	));
};
