import { getRootEnv } from '@bringg-frontend/bringg-web-infra';
import { action, computed, makeObservable, observable } from 'mobx';
import { getEnv } from 'mobx-easy';
import { VehicleType } from '@bringg/types';
import { RootEnv } from '@bringg-frontend/bringg-web-infra';

import BaseDomainStore from '../core/base-domain-store';

class VehicleTypesStore extends BaseDomainStore<VehicleType> {
	filters = {
		fullTextSearch: null,
		vehicleCategory: null
	};

	constructor() {
		super();
		makeObservable(this, {
			fetchAll: action,
			fetch: action,
			create: action,
			update: action,
			delete: action,
			getAllFiltered: computed,
			setFilters: action,
			filters: observable
		});
	}

	setFilters(filters: { fullTextSearch: string; vehicleCategory: string }) {
		this.filters = filters;
	}

	get getAllFiltered(): VehicleType[] {
		let all = this.all;

		if (this.filters.vehicleCategory) {
			all = all.filter(vehicleType => vehicleType.vehicle_category === this.filters.vehicleCategory);
		}

		if (this.filters.fullTextSearch) {
			all = all.filter(vehicleType =>
				['title', 'external_id'].some(k =>
					String(vehicleType[k]).toLowerCase().includes(this.filters.fullTextSearch?.toLowerCase())
				)
			);
		}

		return all;
	}

	async fetchAll() {
		const vehicleTypesResponse = await getRootEnv().dashboardSdk.sdk.vehicleTypes.getAll();
		this.setBatch(vehicleTypesResponse);
	}

	async fetch(id) {
		const vehicleTypeResponse = await getRootEnv().dashboardSdk.sdk.vehicleTypes.get(id);

		return vehicleTypeResponse;
	}

	async create(payload) {
		const vehicleTypeResponse = await getRootEnv().dashboardSdk.sdk.vehicleTypes.create(payload);

		this.updateDefault(vehicleTypeResponse);
		this.set(vehicleTypeResponse);

		return vehicleTypeResponse;
	}

	async update(id, payload) {
		const vehicleTypeResponse = await getRootEnv().dashboardSdk.sdk.vehicleTypes.update(id, payload);

		this.updateDefault(vehicleTypeResponse);
		this.set(vehicleTypeResponse);

		return vehicleTypeResponse;
	}

	async delete(id) {
		const result = await getRootEnv().dashboardSdk.sdk.vehicleTypes.delete(id);

		this.remove(id);

		return result;
	}

	private updateDefault(vehicleTypeResponse: VehicleType) {
		if (vehicleTypeResponse.default_for_merchant) {
			this.items.forEach(item => (item.default_for_merchant = false));
		}
	}
}

export default VehicleTypesStore;
