import React, { useCallback, useMemo, useState, useEffect } from 'react';

import { BringgFontIcons, BringgIcon } from '@bringg/bringg-icons';
import { BringgTable, Select } from '@bringg/react-components';
import { Column, useExpanded, useFlexLayout, useGroupBy, useTable } from 'react-table';
import { PlannedDeliveryWindow } from '@bringg/dashboard-sdk';
import { ServiceAreaFilterGroups } from '@bringg/dashboard-sdk/dist/ServiceArea/v2/service-area.consts';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { dateUtils } from '@bringg-frontend/utils';

import TitleWithIcon from 'bringg-web/features/planned-delivery-windows/modals/slots-modal/title-with-icon/title-with-icon';
import { Cutoff, CutoffMode } from 'bringg-web/features/planned-delivery-windows/cutoff/cutoff';
import CutoffPopover from 'bringg-web/features/planned-delivery-windows/modals/slots-modal/wizard-steps/table-step/cutoff-popover/cutoff-popover';
import {
	CutoffEnum,
	DailyCutoff,
	RelativeCutoff
} from 'bringg-web/features/planned-delivery-windows/modals/slots-modal/wizard-steps/configuration-step/time-slots/cutoff-radio-group/cutoff.types';
import { cutoffCalculation } from 'bringg-web/features/planned-delivery-windows/services/cutoff-calculation';
import TimeWindowPopover from 'bringg-web/features/planned-delivery-windows/modals/slots-modal/wizard-steps/table-step/time-window-popover/time-window-popover';
import { useStores } from 'bringg-web/recipes';
import { filterOption } from 'bringg-web/services/utils';
import { TablePopover } from 'bringg-web/features/planned-delivery-windows/modals/slots-modal/wizard-steps/table-step/table-popover';
import { NO_TEAM } from 'bringg-web/features/planned-delivery-windows/planned-delivery-windows-view';

import styles from './table-step.module.scss';

export interface PlannedDeliveryWindowsSlots extends Omit<PlannedDeliveryWindow, 'merchant_id' | 'id' | 'cutoff'> {
	day: number;
	cutoff: RelativeCutoff | DailyCutoff;
}

interface Props {
	plannedDeliveryWindowsSlots: PlannedDeliveryWindowsSlots[];
	format: string;
	use12Hours: boolean;
	updateWindow: (
		windowToUpdate: PlannedDeliveryWindowsSlots,
		updateValues: Partial<PlannedDeliveryWindowsSlots>
	) => void;
	cutoffType: CutoffEnum;
	teamId: number;
	startTimeInMinutes: number;
	endTimeInMinutes: number;
	timeDuration: { hours: number; minutes: number };
	timezone: string;
}

const TableStep = ({
	plannedDeliveryWindowsSlots,
	format,
	use12Hours,
	updateWindow,
	cutoffType,
	teamId,
	startTimeInMinutes,
	endTimeInMinutes,
	timeDuration,
	timezone
}: Props) => {
	const skipPageResetRef = React.useRef(false);
	const { t } = useTranslation();

	const data = useMemo(() => {
		return plannedDeliveryWindowsSlots;
	}, [plannedDeliveryWindowsSlots]);

	const columns: Column<PlannedDeliveryWindowsSlots>[] = useMemo(
		() => [
			{
				Header: 'Start time',
				accessor: 'start_time',
				Cell: ({ row, value }) => {
					const updateSlotTimeWindow = (timeWindow: { startTime: number; endTime: number }) => {
						skipPageResetRef.current = true;
						updateWindow(row.original, { start_time: timeWindow.startTime, end_time: timeWindow.endTime });
					};

					return (
						<TablePopover
							content={
								<TimeWindowPopover
									format={format}
									use12Hours={use12Hours}
									startTime={value}
									endTime={row.original.end_time}
									updateSlotTimeWindow={updateSlotTimeWindow}
									timeRange={{ startTimeInMinutes, endTimeInMinutes }}
									timezone={timezone}
								/>
							}
						>
							<span data-test-id="start-time">
								{dateUtils.addMinutesToLastMonday(value, timezone).format(format)}
							</span>
						</TablePopover>
					);
				}
			},
			{
				Header: 'End time',
				accessor: 'end_time',
				Cell: ({ row, value }) => {
					const updateSlotTimeWindow = (timeWindow: { startTime; endTime }) => {
						skipPageResetRef.current = true;
						updateWindow(row.original, { start_time: timeWindow.startTime, end_time: timeWindow.endTime });
					};

					return (
						<TablePopover
							content={
								<TimeWindowPopover
									format={format}
									use12Hours={use12Hours}
									startTime={row.original.start_time}
									endTime={value}
									updateSlotTimeWindow={updateSlotTimeWindow}
									timeRange={{ startTimeInMinutes, endTimeInMinutes }}
									timezone={timezone}
								/>
							}
						>
							<span data-test-id="end-time">
								{dateUtils.addMinutesToLastMonday(value, timezone).format(format)}
							</span>
						</TablePopover>
					);
				}
			},
			{
				Header: 'Cutoff',
				accessor: 'cutoff',
				Cell: ({ row, value }) => {
					const updateCutoff = (cutoff: RelativeCutoff | DailyCutoff) => {
						skipPageResetRef.current = true;
						updateWindow(row.original, { cutoff });
					};
					const mode = cutoffType === CutoffEnum.RELATIVE ? CutoffMode.RELATIVE : CutoffMode.DAILY;
					const cutoff =
						cutoffType === CutoffEnum.RELATIVE
							? cutoffCalculation.calculateRelative(value as RelativeCutoff)
							: cutoffCalculation.calculateDaily(value as DailyCutoff, row.original.start_time, timezone);
					const startWindowTime = dateUtils
						.addMinutesToLastMonday(row.original.start_time, timezone)
						.toDate();

					return (
						<TablePopover
							content={
								<CutoffPopover
									startTimeInMinutes={startTimeInMinutes}
									cutoff={value}
									updateCutoff={updateCutoff}
									cutoffType={cutoffType}
									format={format}
									use12Hours={use12Hours}
									timeDuration={timeDuration}
									timezone={timezone}
								/>
							}
						>
							<div className={styles.tableStepCutoff} data-test-id="cutoff">
								<Cutoff
									showText={false}
									mode={mode}
									startWindowTime={startWindowTime}
									cutoff={cutoff}
									timezone={timezone}
								/>
							</div>
						</TablePopover>
					);
				}
			},
			{
				Header: 'Service Area',
				accessor: 'service_area_ids',
				Cell: ({ row, value }) => {
					const { serviceArea } = useStores();
					const [serviceAreasIds, setServiceAreasIds] = useState<number[]>(value);
					const updateLocallyServiceAreaIds = (service_area_ids: number[]) => {
						skipPageResetRef.current = true;
						setServiceAreasIds(service_area_ids);
					};
					const updateServiceAreaIds = (open: boolean) => {
						if (!open) {
							skipPageResetRef.current = true;
							updateWindow(row.original, { service_area_ids: serviceAreasIds });
						}
					};

					return (
						<span data-test-id="service-area-ids">
							<Select
								showSearch
								mode="multiple"
								maxTagCount={1}
								className={styles.selectedItem}
								dropdownMatchSelectWidth={false}
								onDropdownVisibleChange={updateServiceAreaIds}
								maxTagTextLength={5}
								options={serviceArea
									.getGroup(ServiceAreaFilterGroups.Teams, teamId)
									.map(({ id, name }) => ({ id, name }))}
								disabled={teamId === NO_TEAM}
								value={serviceAreasIds}
								onChange={updateLocallyServiceAreaIds}
								filterOption={filterOption}
								placeholder={t('PLANNED_DELIVERY_WINDOWS_MODAL_CONDITIONS.SERVICE_AREAS_PLACEHOLDER')}
							/>
						</span>
					);
				}
			},
			{
				Header: 'Service Plan',
				accessor: 'service_plan_ids',
				Cell: ({ row, value }) => {
					const { servicePlansStore } = useStores();
					const [servicePlanIds, setServicePlanIds] = useState<number[]>(value);

					const updateServicePlanIds = open => {
						if (!open) {
							skipPageResetRef.current = true;
							updateWindow(row.original, { service_plan_ids: servicePlanIds });
						}
					};
					const updateLocallyServicePlanIds = (service_plan_ids: number[]) => {
						skipPageResetRef.current = true;
						setServicePlanIds(service_plan_ids);
					};

					const dropdownOptions = useMemo(() => {
						return servicePlansStore.getAll
							.sort((a, b) => (a.name > b.name ? 1 : -1))
							.map(({ id, name }) => ({
								id,
								name
							}));
					}, []);
					return (
						<span data-test-id="service-plan-id">
							<Select
								showSearch
								maxTagCount={1}
								className={styles.selectedItem}
								mode="multiple"
								dropdownMatchSelectWidth={false}
								maxTagTextLength={5}
								options={dropdownOptions}
								value={servicePlanIds}
								onDropdownVisibleChange={updateServicePlanIds}
								onChange={updateLocallyServicePlanIds}
								filterOption={filterOption}
								placeholder={t('PLANNED_DELIVERY_WINDOWS_MODAL_CONDITIONS.SERVICE_PLAN_PLACEHOLDER')}
							/>
						</span>
					);
				}
			},
			{
				Header: 'Day',
				accessor: 'day'
			}
		],
		[plannedDeliveryWindowsSlots]
	);

	useEffect(() => {
		// After the table has updated, always remove the flag
		skipPageResetRef.current = false;
	});

	const tableInstance = useTable(
		{
			columns,
			data,
			initialState: { groupBy: ['day'], hiddenColumns: ['day'] },
			autoResetExpanded: !skipPageResetRef.current
		},
		useGroupBy,
		useExpanded,
		useFlexLayout
	);

	const getGroupRow = useCallback(row => {
		return (
			<div {...row.getToggleRowExpandedProps()} key={`group-row-${row.groupByVal}`}>
				<BringgIcon
					iconName={row.isExpanded ? BringgFontIcons.Chevron : BringgFontIcons.ChevronRight}
					data-test-id={`expand-row-icon-${row.groupByVal}`}
					className="expand-icon"
				/>
				{moment().weekday(+row.groupByVal).format('dddd')}
			</div>
		);
	}, []);

	return (
		<div className={styles.tableStep}>
			<div className={styles.header}>
				<TitleWithIcon
					title={t('PLANNED_DELIVERY_WINDOWS_MODAL_TABLE_STEP.TIME_SLOT_PREVIEW')}
					icon={BringgFontIcons.Calendar}
				/>
				<span className={styles.description}>
					{t('PLANNED_DELIVERY_WINDOWS_MODAL_TABLE_STEP.TIME_SLOT_PREVIEW_DESCRIPTION')}
				</span>
			</div>
			<div className={styles.tableDetails}>
				<span className={styles.slotsCount}>{`${plannedDeliveryWindowsSlots.length} ${t(
					'PLANNED_DELIVERY_WINDOWS_MODAL_TABLE_STEP.TIME_SLOTS'
				)}`}</span>
			</div>
			<BringgTable
				tableInstance={tableInstance}
				customGroupRow={getGroupRow}
				tableCellTextClass={styles.plannedDeliveryWindowsTableCell}
				tableBodyClass={styles.tableBody}
			/>
		</div>
	);
};

export default TableStep;
