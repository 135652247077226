import { OptimizationAction, ActionType, OptimizationActionMetadata } from '@bringg/types';
import i18next from 'i18next';
import { action, computed, makeObservable, observable } from 'mobx';
import moment from 'moment';
import { Moment } from 'moment';
import { getFormatByMerchant } from '../utils/helpers';
import { ActionFamilyType, ClientOptimizationAction } from '../utils/types';
import { ActionBaseStore, ActionsRepo } from './internal';

export enum SaveExisting {
	FALSE,
	TRUE
}

export enum KeepSequence {
	FALSE,
	TRUE
}

export enum DaysAhead {
	TODAY,
	TOMORROW,
	TWO_DAYS,
	THREE_DAYS,
	FOUR_DAYS,
	FIVE_DAYS,
	SIX_DAYS,
	SEVEN_DAYS
}

class OptimizationActionStore extends ActionBaseStore {
	type = ActionFamilyType.OPTIMIZATION;
	actionType = ActionType.OPTIMIZATION;
	daysAhead: DaysAhead;
	timeRange: [Moment, Moment];
	saveExisting: SaveExisting = SaveExisting.FALSE;
	keepSequence: KeepSequence = KeepSequence.FALSE;

	constructor(initAction: ClientOptimizationAction, actionsRepo: ActionsRepo) {
		super(actionsRepo);

		makeObservable(this, {
			daysAhead: observable,
			setDaysAhead: action,
			timeRange: observable,
			setTimeRange: action,
			title: computed,
			isValid: computed,
			mappedToServer: computed,
			saveExisting: observable,
			setSaveExisting: action,
			keepSequence: observable,
			setKeepSequence: action
		});

		if (initAction?.data) {
			const { data } = initAction;
			const timeFormat = getFormatByMerchant();

			this.timeRange = [moment(data.from_time, timeFormat), moment(data.to_time, timeFormat)];
			this.daysAhead = data.days_ahead;
			this.saveExisting = data.save_existing ? SaveExisting.TRUE : SaveExisting.FALSE;
			this.keepSequence = data.keep_sequence ? KeepSequence.TRUE : KeepSequence.FALSE;
		}
	}

	setDaysAhead(daysAhead: DaysAhead) {
		this.daysAhead = daysAhead;
	}

	setTimeRange(timeRange: [Moment, Moment]) {
		this.timeRange = timeRange;
	}

	setSaveExisting(saveExisting: SaveExisting) {
		this.saveExisting = saveExisting;
	}

	setKeepSequence(keepSequence: KeepSequence) {
		this.keepSequence = keepSequence;
	}

	get mappedToServer(): OptimizationAction {
		return {
			action_type: ActionType.OPTIMIZATION,
			data: {
				days_ahead: this.daysAhead,
				from_time: this.timeRange?.[0].format('HH:mm'),
				to_time: this.timeRange?.[1].format('HH:mm'),
				save_existing: this.saveExisting === SaveExisting.TRUE,
				keep_sequence: this.keepSequence === KeepSequence.TRUE
			}
		};
	}

	get isValid() {
		return this.daysAhead != null && !!this.timeRange;
	}

	get title(): string {
		const titleKey = (this.actionData as OptimizationActionMetadata).translation_string;
		return i18next.t(titleKey);
	}
}

export default OptimizationActionStore;
