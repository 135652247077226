import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { useObserver } from 'mobx-react';
import { ComponentWithForm } from '@bringg/react-pages/dist/components';
import { FleetTemplateSelection } from '../../../../stores/fleets/fleets-store';
import AvailableFleetsOptions from '../available-fleets-options/available-fleets-options';
import AvailableFleetsButtons from '../available-fleets-buttons/available-fleets-buttons';

const AvailableFleetsForm: FunctionComponent<ComponentWithForm<FleetTemplateSelection>> = ({ form, defaultValues }) => {
	const { t } = useTranslation();

	return useObserver(() => {
		return (
			<div className="available-fleet-form">
				<div className="fleet-router-title">{t('FLEET_ROUTER.TITLE')}</div>
				<div className="available-fleets">
					<div className="available-fleets-header">
						<span className="available-fleets-title">{t('FLEET_ROUTER.AVAILABLE_FLEETS_TITLE')}</span>
						<AvailableFleetsButtons form={form} defaultValues={defaultValues} />
					</div>
					<AvailableFleetsOptions form={form} defaultValues={defaultValues} />
				</div>
			</div>
		);
	});
};

export default AvailableFleetsForm;
