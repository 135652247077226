import { computed, makeObservable } from 'mobx';
import { ActionCoreData, GenericActionTypes } from '@bringg/types';

import { ActionsRepo } from './internal';
import ActionBaseStore from './action-base-store';
import { ActionFamilyType } from '../utils/types';

class GenericActionStore extends ActionBaseStore {
	type = ActionFamilyType.GENERIC_ACTION;
	actionType: GenericActionTypes;

	constructor(actionToInit: GenericActionTypes, actionsRepo: ActionsRepo) {
		super(actionsRepo);
		makeObservable(this, {
			title: computed,
			icon: computed
		});

		this.actionType = actionToInit;
	}

	get mappedToServer(): ActionCoreData {
		return {
			action_type: this.actionType,
			data: null
		};
	}

	get isValid() {
		return true;
	}

	get title(): string {
		return this.genericItemsByFact.find(data => data?.action_type === this.actionType)?.display_path || '';
	}

	get icon(): string {
		return this.genericItemsByFact.find(data => data?.action_type === this.actionType)?.icon || '';
	}
}

export default GenericActionStore;
