export enum RecurrenceTypeModalModes {
	EDIT = 'EDIT',
	REMOVE = 'REMOVE'
}

export enum RecurrenceType {
	current = 'current',
	current_and_following = 'current_and_following',
	all = 'all'
}
