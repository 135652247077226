import { getRootEnv } from '@bringg-frontend/bringg-web-infra';
import { ApplicationUuid } from '@bringg/types';
import { action, observable, makeObservable } from 'mobx';
import { getRoot, getEnv } from 'mobx-easy';
import { RootEnv } from '@bringg-frontend/bringg-web-infra';

import RootStore from '../root-store';

class ApplicationTeamConfigurationStore {
	configurationsData: Map<string, object> = new Map<string, object>();

	constructor() {
		makeObservable(this, {
			configurationsData: observable,
			setConfigData: action,
			updateConfiguration: action,
			getConfiguration: action,
			removeConfiguration: action
		});
	}

	setConfigData = (appUuid: string, teamId: number, data: object) => {
		this.configurationsData.set(`${appUuid}-${teamId}`, data);
	};

	getConfigData = (appUuid: string, teamId: number): any => {
		return this.configurationsData.get(`${appUuid}-${teamId}`);
	};

	updateConfiguration = async (appUuid: ApplicationUuid, teamId: number, configData: object): Promise<boolean> => {
		let success: boolean;

		try {
			success = await getRootEnv().dashboardSdk.sdk.applicationTeamConfiguration.updateConfig(
				appUuid,
				teamId,
				configData
			);
			if (success) {
				this.setConfigData(appUuid, teamId, configData);
			}
			return success;
		} catch (e) {
			return false;
		}
	};

	getConfiguration = async (appUuid: ApplicationUuid, teamId: number): Promise<any> => {
		if (!getRoot<RootStore>().data.applicationStore.isApplicationInstalled(appUuid)) {
			return null;
		}
		const atc = this.configurationsData.get(`${appUuid}-${teamId}`);
		if (atc) return atc;

		const { dashboardSdk } = getRootEnv();
		const appConfig = await dashboardSdk.sdk.applicationTeamConfiguration.getConfig(appUuid, teamId);

		this.setConfigData(appUuid, teamId, appConfig && appConfig.data);
		return appConfig && appConfig.data;
	};

	removeConfiguration = async (appUuid: ApplicationUuid, teamId: number): Promise<boolean> => {
		const conf = this.configurationsData.get(`${appUuid}-${teamId}`);
		if (!conf) return true;

		try {
			const { dashboardSdk } = getRootEnv();
			await dashboardSdk.sdk.applicationTeamConfiguration.removeConfig(appUuid, teamId);
			this.setConfigData(appUuid, teamId, null);
			return true;
		} catch (e) {
			return false;
		}
	};
}

export default ApplicationTeamConfigurationStore;
