import React from 'react';
import { Select } from '@bringg/react-components';
import {
	getDaysOptions,
	getHoursOptions,
	getMinutesOptions,
	getRelativeOptions
} from 'bringg-web/features/planned-delivery-windows/modals/slots-modal/wizard-steps/configuration-step/time-slots/cutoff-radio-group/cutoff-options-builder';
import {
	Configuration,
	RelativeCutoff,
	RelativeOptions
} from 'bringg-web/features/planned-delivery-windows/modals/slots-modal/wizard-steps/configuration-step/time-slots/cutoff-radio-group/cutoff.types';
import { useTranslation } from 'react-i18next';
import styles from './relative-dropdown.module.scss';
import { dontLoseFocus, filterOptionByTitle } from 'bringg-web/services/utils';
import { Duration } from 'bringg-web/features/planned-delivery-windows/modals/slots-modal/planned-delivery-windows-slots.consts';

interface Props {
	days: Configuration<number>;
	hours: Configuration<number>;
	minutes: Configuration<number>;
	relativeOptions: Configuration<RelativeOptions>;
	relativeCutoff: RelativeCutoff;
	timeDuration: Duration;
}

const RelativeDropdown = ({ relativeCutoff, days, hours, minutes, relativeOptions, timeDuration }: Props) => {
	const { t } = useTranslation();

	return (
		<div className={styles.selects} onClick={dontLoseFocus}>
			<Select
				className={styles.selectItem}
				data-test-id="relative-dropdown-days"
				showSearch
				options={getDaysOptions(t)}
				value={relativeCutoff.days}
				onChange={days.onChange}
				filterOption={filterOptionByTitle}
				disabled={days.disabled}
			/>
			<Select
				className={styles.selectItem}
				data-test-id="relative-dropdown-hours"
				showSearch
				options={getHoursOptions(t, relativeCutoff, timeDuration)}
				value={relativeCutoff.hours}
				onChange={hours.onChange}
				filterOption={filterOptionByTitle}
				disabled={hours.disabled}
			/>
			<Select
				className={styles.selectItem}
				data-test-id="relative-dropdown-minutes"
				showSearch
				options={getMinutesOptions(t, relativeCutoff, timeDuration)}
				value={relativeCutoff.minutes}
				onChange={minutes.onChange}
				filterOption={filterOptionByTitle}
				disabled={minutes.disabled}
			/>
			<Select
				className={styles.selectItem}
				data-test-id="relative-dropdown-relative-options"
				options={getRelativeOptions(t)}
				value={relativeCutoff.relativeOption}
				onChange={relativeOptions.onChange}
				dropdownMatchSelectWidth={false}
				disabled={relativeOptions.disabled}
			/>
		</div>
	);
};
export default RelativeDropdown;
