import React, { ChangeEvent, Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Input, Row } from '@bringg/react-components';
import { ActionData } from '@bringg/types/types/action_data';
import _get from 'lodash/get';
import Translate from '../../../translation/translator';
import { Option, SelectWithStickyPopup as Select } from '../../../components';
import {
	action_data_rules,
	actionValueToType,
	AllowShareWithCustomerOptions,
	taskTypes,
	user_state_actions_rules,
	wayPointTypes
} from '../action-data.consts';
import { ActionDataType, ActionsConfigurationLevel } from '../../../types/common.consts';
import ActionsConfigurationsStore from '../../../stores/actions-configuration/actions-configuration-store';

interface Props {
	onFieldUpdate: (actionData: Partial<ActionData>) => void;
	actionData: ActionData;
	actionsConfigurationsStore?: ActionsConfigurationsStore;
}

class GeneralData extends Component<Props, Record<string, unknown>> {
	setIsTopLevel = (isTopLevel: string) => {
		this.props.onFieldUpdate({ is_top_level: isTopLevel === 'true' });
	};

	setIsMandatory = (isMandatory: string) => {
		this.props.onFieldUpdate({ is_mandatory: isMandatory === 'true' });
	};

	setTaskTypeId = (taskTypeId: number) => {
		this.props.onFieldUpdate({ task_type_id: taskTypeId });
	};

	setRule = (rule: number) => {
		this.props.onFieldUpdate({ rule });
	};

	setWayPointType = (wayPointType: number) => {
		this.props.onFieldUpdate({ way_point_type: wayPointType });
	};

	setTitle = event => {
		this.props.onFieldUpdate({ title: event.target.value });
	};

	setDisplayMode = (displayMode: number) => {
		this.props.onFieldUpdate({
			data: Object.assign(this.props.actionData.data || {}, { display_mode: displayMode })
		});
	};

	setAllowShareWithCustomer = (option: number) => {
		this.props.onFieldUpdate({
			data: Object.assign(this.props.actionData.data || {}, { allow_share_with_customer: option })
		});
	};

	setDisclaimer = (changeEvent: ChangeEvent<HTMLInputElement>) => {
		const data = Object.assign(this.props.actionData.data || {}, { disclaimer: changeEvent.target.value });
		this.props.onFieldUpdate({ data });
	};

	setIsFulfillmentRequired = (isFulfillmentRequired: string) => {
		this.props.onFieldUpdate({
			data: Object.assign(this.props.actionData.data || {}, {
				iframe_fulfillment_required: isFulfillmentRequired === 'true'
			})
		});
	};

	render() {
		const { actionData } = this.props;
		const isShiftActions = this.props.actionsConfigurationsStore.currentLevel === ActionsConfigurationLevel.SHIFT;
		const availableRules = isShiftActions ? user_state_actions_rules : action_data_rules;
		const shouldShowAllowShareWithCustomer = [ActionDataType.TAKE_SIGNATURE, ActionDataType.TAKE_PICTURE].includes(
			actionValueToType.get(actionData.value)
		);
		const isVerificationPinCode = actionValueToType.get(actionData.value) === ActionDataType.VERIFICATION_PIN_CODE;

		return (
			<div className="action-data">
				<Row className="action-data-field-row">
					<div>
						<Translate text="ACTION_CONFIGURATION.TITLE" />
					</div>
					<Input
						type="text"
						placeholder=""
						onChange={this.setTitle}
						value={actionData.title}
						className="action-data-input"
						data-test-id="action-data-title"
					/>
				</Row>
				{!isShiftActions ? (
					<>
						<Row className="action-data-field-row">
							<div>
								<Translate text="ACTION_CONFIGURATION.WAY_POINT_TYPE" />
							</div>
							<Select
								onSelect={this.setWayPointType}
								value={actionData.way_point_type}
								data-test-id="action-data-way-point"
								className="action-data-input"
							>
								{Array.from(wayPointTypes.entries()).map(([key, selectValue]) => (
									<Option
										key={selectValue.title}
										value={key}
										disabled={selectValue.disabled}
										data-test-id="action-data-way-point-option"
									>
										{selectValue.title}
									</Option>
								))}
							</Select>
						</Row>
						<Row className="action-data-field-row">
							<div>
								<Translate text="ACTION_CONFIGURATION.TASK_TYPE_ID" />
							</div>
							<Select
								onSelect={this.setTaskTypeId}
								value={actionData.task_type_id}
								data-test-id="action-data-task-type"
								className="action-data-input"
							>
								{Array.from(taskTypes.entries()).map(([key, selectValue]) => (
									<Option
										key={selectValue.title}
										value={key}
										disabled={selectValue.disabled}
										data-test-id="action-data-task-type-option"
									>
										{selectValue.title}
									</Option>
								))}
							</Select>
						</Row>
					</>
				) : null}
				{isShiftActions ? (
					<Row className="action-data-field-row">
						<div>
							<Translate text="ACTION_CONFIGURATION.IS_MANDATORY" />
						</div>
						<Select
							onSelect={this.setIsMandatory}
							value={`${_get(actionData, 'is_mandatory', 'false')}`}
							data-test-id="action-data-mandatory"
							className="action-data-input"
						>
							<Option key="true" value="true" data-test-id="action-data-mandatory-option">
								<Translate text="ACTION_CONFIGURATION.TRUE" />
							</Option>
							<Option key="false" value="false" data-test-id="action-data-mandatory-option">
								<Translate text="ACTION_CONFIGURATION.FALSE" />
							</Option>
						</Select>
					</Row>
				) : null}
				{!isVerificationPinCode ? (
					<Row className="action-data-field-row">
						<div>
							<Translate text="ACTION_CONFIGURATION.TOP_LEVEL" />
						</div>
						<Select
							onSelect={this.setIsTopLevel}
							value={`${actionData.is_top_level}`}
							data-test-id="action-data-top-level"
							className="action-data-input"
						>
							<Option key="true" value="true" data-test-id="action-data-top-level-option">
								<Translate text="ACTION_CONFIGURATION.TRUE" />
							</Option>
							<Option key="false" value="false" data-test-id="action-data-top-level-option">
								<Translate text="ACTION_CONFIGURATION.FALSE" />
							</Option>
						</Select>
					</Row>
				) : null}
				{!isVerificationPinCode ? (
					<Row className="action-data-field-row">
						<div>
							<Translate text="ACTION_CONFIGURATION.RULE" />
						</div>
						<Select
							onSelect={this.setRule}
							value={actionData.rule}
							data-test-id="action-data-rule"
							className="action-data-input"
						>
							{Array.from(availableRules.entries()).map(([key, value]) => (
								<Option key={value} value={key} data-test-id="action-data-rule-option">
									{value}
								</Option>
							))}
						</Select>
					</Row>
				) : null}
				{actionValueToType.get(actionData.value) === ActionDataType.TAKE_SIGNATURE ? (
					<Row className="action-data-field-row">
						<div>
							<Translate text="ACTION_CONFIGURATION.DISCLAIMER" />
						</div>
						<Input
							type="text"
							placeholder=""
							onChange={this.setDisclaimer}
							value={_get(actionData, 'data.disclaimer', '')}
							dataTestId="action-data-field-disclaimer"
							className="action-data-input"
						/>
					</Row>
				) : null}
				{shouldShowAllowShareWithCustomer ? (
					<Row className="action-data-field-row">
						<div>
							<Translate text="ACTION_CONFIGURATION.SHOW_ALLOW_TO_SHARE_WITH_CUSTOMER_CHECKBOX" />
						</div>
						<Select
							data-test-id="action-data-display-mode"
							onSelect={this.setAllowShareWithCustomer}
							value={_get(
								actionData,
								'data.allow_share_with_customer',
								AllowShareWithCustomerOptions.get('allow')
							)}
							className="action-data-input"
						>
							{Array.from(AllowShareWithCustomerOptions.entries()).map(([key, value]) => (
								<Option key={key} value={value} data-test-id="allow_share_with_customer-option">
									<Translate text={`ACTION_CONFIGURATION.${key.toUpperCase()}`} />
								</Option>
							))}
						</Select>
					</Row>
				) : null}
				{actionValueToType.get(actionData.value) === ActionDataType.OPEN_IFRAME ? (
					<Row className="action-data-field-row">
						<div>
							<Translate text="ACTION_CONFIGURATION.OPEN_IFRAME.FULFILLMENT_REQUIRED" />
						</div>
						<Select
							onSelect={this.setIsFulfillmentRequired}
							value={`${_get(actionData, 'data.iframe_fulfillment_required', 'false')}`}
							data-test-id="action-data-fulfillment-required"
							className="action-data-input iframe_fulfillment_required-input"
						>
							<Option key="true" value="true" data-test-id="action-data-top-level-option">
								<Translate text="ACTION_CONFIGURATION.TRUE" />
							</Option>
							<Option key="false" value="false" data-test-id="action-data-top-level-option">
								<Translate text="ACTION_CONFIGURATION.FALSE" />
							</Option>
						</Select>
					</Row>
				) : null}
			</div>
		);
	}
}

export default inject('actionsConfigurationsStore')(observer(GeneralData));
