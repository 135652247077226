import { Team as BringgTeam } from '@bringg/types';
import { Option } from '@bringg/react-components/dist/components/filter-bar';

import TeamsStore from 'bringg-web/stores/teams/teams-store';

export const mapItemsToFilter = (storeItems: Option<number>[]) =>
	storeItems.map(item => ({
		label: item.name,
		value: item.id
	}));

export const mapTeamToFilter = (teams: (BringgTeam & { childrenTeamIds: number[] })[], teamsStore: TeamsStore) =>
	teams.map(item => ({
		label: item.name,
		value: item.id,
		children: item.childrenTeamIds.length
			? mapItemsToFilter(item.childrenTeamIds.map(id => teamsStore.get(id)))
			: []
	}));
