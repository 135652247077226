import { getRootEnv } from '@bringg-frontend/bringg-web-infra';
import { getEnv } from 'mobx-easy';
import { RootEnv } from '@bringg-frontend/bringg-web-infra';

export async function fetchUser(userId: number) {
	if (!userId) {
		return null;
	}
	try {
		return await getRootEnv().dashboardSdk.sdk.users.get(userId);
	} catch (err) {
		console.error(`Failed to fetch user ${userId}`, err);
	}

	return null;
}
