import { getRoot } from 'mobx-easy';
import RootStore from 'bringg-web/stores/root-store';
import { ApplicationUuid } from '@bringg/types';

function isEnabled() {
	const { data } = getRoot<RootStore>();

	return (
		data.merchantConfigurationsStore.enablePlannedRoutes ||
		data.applicationStore.getApplication(ApplicationUuid.RouteOptimizer2)
	);
}

export const plannedRunsIndicator = { isEnabled };
