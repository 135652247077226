import { computed, makeObservable } from 'mobx';
import map from 'lodash/map';
import isEmpty from 'lodash/isEmpty';
import some from 'lodash/some';
import orderBy from 'lodash/orderBy';
import isNumber from 'lodash/isNumber';
import isUndefined from 'lodash/isUndefined';
import { getRoot } from 'mobx-easy';
import { EDITABLE_NOTIFICATION_TYPES } from '../../features/customer-notifications/notification-template/notification-template.consts';
import CustomerNotificationsStore from './customer-notifications-store';
import RootStore from '../root-store';

class MerchantCustomerNotificationsStore extends CustomerNotificationsStore {
	constructor() {
		// TODO: [mobx-undecorate] verify the constructor arguments and the arguments of this automatically generated super call
		super();

		makeObservable(this, {
			customerConfiguration: computed,
			merchantConfiguration: computed,
			selectedLanguageTemplates: computed,
			availableLanguages: computed
		});
	}

	get customerConfiguration() {
		return getRoot<RootStore>().data.customerConfigurationsStore.configuration;
	}

	get merchantConfiguration() {
		return getRoot<RootStore>().data.merchantConfigurationsStore.configuration;
	}

	get selectedLanguageTemplates() {
		return getRoot<RootStore>().data.notificationTemplatesStore.getByLanguage(this.selectedLanguageCode);
	}

	get availableLanguages(): any {
		const { notificationTemplatesStore } = getRoot<RootStore>().data;
		const { availableLanguages } = notificationTemplatesStore;
		const languages = availableLanguages.map(language => {
			const templates = notificationTemplatesStore.getByLanguage(language.code, null);
			const hasTemplates = !isEmpty(templates) && some(templates, ({ id }) => isNumber(id));
			return { ...language, hasTemplates };
		});
		return orderBy(languages, ['hasTemplates', 'name'], 'desc');
	}

	selectLanguageCode = (code: string) => {
		this.updateLanguageCode(code);
		this.fillMissingTemplates(code);
	};

	initConfigurations = async () => {
		await Promise.all([
			getRoot<RootStore>().data.notificationTemplatesStore.fetchAll(),
			getRoot<RootStore>().data.customerConfigurationsStore.fetch(),
			getRoot<RootStore>().data.merchantConfigurationsStore.fetch()
		]);

		this.selectLanguageCode(this.defaultLanguageCode);

		this.setFetched();
	};

	updateConfigurations = async () => {
		const { merchantConfigurationsStore, notificationTemplatesStore, customerConfigurationsStore } =
			getRoot<RootStore>().data;
		const promises = [];

		promises.push(merchantConfigurationsStore.update());

		map(this.selectedLanguageTemplates, template => {
			if (template.template.length > 0) {
				promises.push(notificationTemplatesStore.update(template));
			}
		});

		promises.push(customerConfigurationsStore.update());

		promises.push(this.installRequiredApplications(customerConfigurationsStore.configuration));

		return Promise.all(promises);
	};

	deleteLanguageTemplates = async () => {
		const promises = map(this.selectedLanguageTemplates, async template =>
			getRoot<RootStore>().data.notificationTemplatesStore.delete(template)
		);

		await Promise.all(promises);
		this.updateLanguageCode(this.defaultLanguageCode);
	};

	fillMissingTemplates = (language: string) => {
		const { notificationTemplatesStore } = getRoot<RootStore>().data;
		EDITABLE_NOTIFICATION_TYPES.forEach(templateType => {
			const template = {
				language,
				notification_type: templateType,
				tag_id: null
			};
			if (isUndefined(notificationTemplatesStore.get(template))) {
				notificationTemplatesStore.set(template);
			}
		});
	};
}

export default MerchantCustomerNotificationsStore;
