import { UserRollout } from '@bringg/dashboard-sdk/dist/Rollouts/Rollouts.consts';

export interface Rollout {
	id: number;
	name: string;
	route_path: string;
	feature_flag_name: string;
	start_time: string;
	end_time: string;
	created_at: string;
	updated_at: string;
	is_enabled: boolean;
	user_rollout: UserRollout;
	translations: Translations;
	block_switching?: boolean;
	image_url?: string;
}

export interface Translations {
	enabled_bar_text: string;
	disabled_bar_text: string;
	modal_title: string;
	modal_description: string;
}

export const translationKeys = ['enabled_bar_text', 'disabled_bar_text', 'modal_title', 'modal_description'];
