import React, { Dispatch, memo, SetStateAction, useMemo } from 'react';

import { useTranslation } from 'react-i18next';
import { Button } from '@bringg/react-components';
import { BringgFontIcons, BringgIcon } from '@bringg/bringg-icons';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { EnumDefinitionItem } from '@bringg/types';

import DefinitionRow, { KeyValueDefinition } from './definition-row';

import './key-value-table.scss';

interface Props {
	state: {
		definitionList: EnumDefinitionItem[];
		setDefinitionList: Dispatch<SetStateAction<KeyValueDefinition[]>>;
		enumDefinitionError: string;
		orderedDefinitionList: KeyValueDefinition[];
		handleDefinitionChange: (index: number, newDefinition: KeyValueDefinition) => void;
		handleDelete: (deleteIndex: number) => void;
		addNewValue: () => void;
		handleMoveItem: (dragIndex: number, hoverIndex: number) => void;
		handleDroppedItem: () => void;
	};
	headers: {
		keyLabel: string;
		valueLabel: string;
	};
}

function KeyValueTable({ state, headers }: Props) {
	const { t } = useTranslation();

	const {
		enumDefinitionError,
		handleDefinitionChange,
		handleDelete,
		addNewValue,
		orderedDefinitionList,
		handleMoveItem,
		handleDroppedItem
	} = state;

	const containsEmpty = useMemo(() => {
		return Boolean(orderedDefinitionList.find(({ value }) => !value.length));
	}, [orderedDefinitionList]);

	return (
		<div className="enum-definition">
			<div className="enum-definition-header">
				<div className="enum-definition-header-item value-col">{headers.keyLabel}</div>
				<div className="enum-definition-header-item name-col">{headers.valueLabel}</div>
			</div>
			<div className="enum-definition-list">
				<DndProvider backend={HTML5Backend}>
					<div className="reorder-columns-container" role="list">
						{orderedDefinitionList.map((item, index) => (
							<DefinitionRow
								key={item.key}
								definition={item}
								index={index}
								moveItem={handleMoveItem}
								onDropItem={handleDroppedItem}
								onDefinitionChange={handleDefinitionChange}
								onDelete={handleDelete}
							/>
						))}
					</div>
				</DndProvider>
			</div>
			<div className="enum-definition-add-new">
				<Button type="link" onClick={addNewValue} disabled={containsEmpty} tabIndex={0}>
					<BringgIcon iconName={BringgFontIcons.Plus} />
					{t('CUSTOM_ATTRIBUTES.ENUM_DEFINITION.ADD_NEW')}
				</Button>
			</div>
			<div className="enum-definition-errors">{enumDefinitionError}</div>
		</div>
	);
}

export default memo(KeyValueTable);
