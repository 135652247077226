import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Input, Modal } from 'antd';
import { observable, action, makeObservable } from 'mobx';
import MerchantConfigurationsStore from 'bringg-web/stores/merchant-configurations/merchant-configurations-store';
import CompaniesStore from '../../../stores/companies/companies-store';
import { Button } from '../../../components';
import { Translate } from '../../../translation';
import Company from '../../../stores/companies/domain-objects/company';
import AddressAutoComplete from '../../../components/address-auto-complete/address-auto-complete';
import { Status as LoadingStatus } from '../../../components/address-auto-complete/with-loading/with-loading';

interface Props {
	companiesStore?: CompaniesStore;
	merchantConfigurationsStore?: MerchantConfigurationsStore;
}

class CompaniesHeader extends Component<Props, {}> {
	visible = false;
	addressStatus: LoadingStatus = LoadingStatus.Idle;
	company: Company = new Company(
		{ merchant_id: this.props.merchantConfigurationsStore?.configuration?.merchant_id },
		this.props.companiesStore
	);

	constructor(props: Props) {
		super(props);

		makeObservable(this, {
			visible: observable,
			addressStatus: observable,
			company: observable,
			toggleModal: action
		});
	}

	toggleModal = () => {
		this.visible = !this.visible;
		this.company = new Company(
			{ merchant_id: this.props.merchantConfigurationsStore?.configuration?.merchant_id },
			this.props.companiesStore
		);
	};

	save = async () => {
		await this.props.companiesStore.create(this.company.toJson());

		this.toggleModal();
	};

	cancel = () => {
		this.toggleModal();
	};

	handleAddressSelect = async (address, addressDetailsPromise) => {
		const { lat, lng, street, city, zipcode, house_number } = await addressDetailsPromise;
		this.company.setAddress(address, lat, lng, street, city, zipcode, house_number);
	};

	handleAddressStatusChanged = (status: LoadingStatus) => {
		this.addressStatus = status;
	};

	render() {
		const { company, visible, toggleModal, save, cancel } = this;

		return (
			<div className="companies-header">
				<Button onClick={toggleModal} className="add-company">
					<Translate text="COMPANIES.ADD" />
				</Button>
				<Modal
					className="create-company-modal"
					visible={visible}
					title={<Translate text="COMPANIES.CREATE_COMPANY_MODAL_TITLE" />}
					okText={<Translate text="COMPANIES.SUBMIT_COMPANY_MODAL" />}
					cancelText={<Translate text="COMPANIES.CANCEL" />}
					onCancel={cancel}
					onOk={save}
					destroyOnClose
					okButtonProps={{
						disabled: company.name === '' || this.addressStatus === LoadingStatus.Fail
					}}
				>
					<span className="company-name">
						<Translate text="COMPANIES.ID" />:
					</span>
					<Input
						data-test-id="inputCompanyExternalId"
						type="text"
						onChange={e => company.setExternalId(e.target.value)}
						value={company.external_id}
					/>
					<span className="company-name">
						<Translate text="COMPANIES.NAME" />:
					</span>
					<Input
						data-test-id="inputCompanyName"
						type="text"
						onChange={e => company.setName(e.target.value)}
						value={company.name}
					/>
					<span className="company-name">
						<Translate text="COMPANIES.PHONE" />:
					</span>
					<Input
						data-test-id="inputCompanyPhone"
						type="text"
						onChange={e => company.setPhone(e.target.value)}
						value={company.phone}
					/>
					<span className="company-name">
						<Translate text="COMPANIES.TAX_ID" />:
					</span>
					<Input
						data-test-id="inputCompanyTaxId"
						type="text"
						onChange={e => company.setTaxId(e.target.value)}
						value={company.tax_id}
					/>
					<span className="company-name">
						<Translate text="COMPANIES.ADDRESS" />:
					</span>
					<AddressAutoComplete
						className="company-address-input"
						onSelect={this.handleAddressSelect}
						name="company.address"
						onAddressStatusChanged={this.handleAddressStatusChanged}
					/>
				</Modal>
			</div>
		);
	}
}

export default inject('companiesStore', 'merchantConfigurationsStore')(observer(CompaniesHeader));
