import React from 'react';

import classNames from 'classnames';

import { Failure, Translation } from '../types';
import SuccessRow from './success-row';
import FailureRow from './failure-row';

import style from './index.module.scss';

type Props = {
	translations: Omit<Translation, 'title'>;
	className: string;

	totalNumberOfActions: number;
	currentNumberOfActionsSucceeded: number;

	failures: Failure[];
	failureRenderer?: (failure: Failure) => React.ReactNode;
	errorsFileRender?: React.ReactNode;
	hideFailureReasonColumn?: boolean;
};

const Content = ({
	translations,
	className,
	totalNumberOfActions,
	currentNumberOfActionsSucceeded,
	failures,
	hideFailureReasonColumn,
	errorsFileRender,
	failureRenderer
}: Props) => {
	const isCompleted = failures.length + currentNumberOfActionsSucceeded === totalNumberOfActions;

	return (
		<div className={classNames(style.content, className)}>
			<SuccessRow
				translations={translations}
				totalNumberOfActions={totalNumberOfActions}
				currentNumberOfActionsSucceeded={currentNumberOfActionsSucceeded}
				numberOfCompleted={failures.length + currentNumberOfActionsSucceeded}
				isCompleted={isCompleted}
			/>
			<FailureRow
				translations={translations}
				totalNumberOfActions={totalNumberOfActions}
				failures={failures}
				failureRenderer={failureRenderer}
				hideFailureReasonColumn={hideFailureReasonColumn}
				isCompleted={isCompleted}
			/>
			{errorsFileRender}
		</div>
	);
};

export default Content;
