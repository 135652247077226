import { action, computed, observable, makeObservable } from 'mobx';
import _reject from 'lodash/reject';
import i18next from 'i18next';
import { map as _map, first as _first } from 'lodash';
import { BringgException } from '@bringg/dashboard-sdk/dist/Core/BringgException';
import { DeliveryBlockCreateValues, DeliveryBlockModalViewMode } from './delivery-block-modal';
import DeliveryBlock from '../stores/domain-objects/delivery-block';
import notification from '../../../services/notification';
import { RecurringOptions } from './recurring-options-modal/recurring-options-modal';
import DeliveryBlockBreak from '../stores/domain-objects/delivery-block-break';
import Driver from '../../../stores/drivers/domain-object/driver';

export interface DeliveryBlockModalViewOptions {
	deliveryBlock: DeliveryBlock;
	drivers: Driver[];
	breakBuffer: number;
	viewMode: DeliveryBlockModalViewMode;
	refetchWeek: () => Promise<void>;
	useOldBreaks: boolean;
}

class DeliveryBlockModalView {
	drivers: Driver[];
	breakBuffer: number;

	deliveryBlock: DeliveryBlock = null;
	viewMode: DeliveryBlockModalViewMode = DeliveryBlockModalViewMode.VIEW;
	originalCapacity = 1;
	loadingState = false;
	selectedDriversIds: number[] = [];
	recurringOptionsModalVisible = false;
	useOldBreaks = false;
	refetchWeek: () => Promise<void>;

	constructor({
		deliveryBlock,
		drivers,
		breakBuffer,
		viewMode,
		refetchWeek,
		useOldBreaks = true
	}: Partial<DeliveryBlockModalViewOptions>) {
		makeObservable(this, {
			deliveryBlock: observable,
			viewMode: observable,
			originalCapacity: observable,
			loadingState: observable,
			selectedDriversIds: observable.shallow,
			recurringOptionsModalVisible: observable,
			useOldBreaks: observable,
			setSelectedDriversIds: action,
			setOriginalCapacity: action,
			setViewMode: action,
			cancelEditMode: action,
			setEditMode: action,
			setLoadingState: action,
			setRecurringOptionsModalVisible: action,
			minimumCapacity: computed,
			isDeletable: computed,
			isEditable: computed
		});

		this.drivers = drivers;
		this.deliveryBlock = deliveryBlock;
		this.breakBuffer = breakBuffer;
		this.refetchWeek = refetchWeek;
		this.useOldBreaks = useOldBreaks;

		this.initModal(viewMode, deliveryBlock);
	}

	setSelectedDriversIds = selectedDriversIds => {
		this.selectedDriversIds = selectedDriversIds;
	};

	setOriginalCapacity = (originalCapacity: number) => {
		this.originalCapacity = originalCapacity;
	};

	setViewMode = (viewMode: DeliveryBlockModalViewMode) => {
		this.viewMode = viewMode;
	};

	cancelEditMode = () => {
		this.setOriginalCapacity(this.deliveryBlock.original_capacity);
		this.setSelectedDriversIds(this.deliveryBlock.user_ids);
		this.resetViewMode();
	};

	setEditMode = () => {
		this.setViewMode(DeliveryBlockModalViewMode.EDIT);
	};

	resetViewMode = () => {
		this.setViewMode(DeliveryBlockModalViewMode.VIEW);
	};

	setLoadingState = loading => {
		this.loadingState = loading;
	};

	setRecurringOptionsModalVisible = (visible: boolean) => {
		this.recurringOptionsModalVisible = visible;
	};

	get minimumCapacity() {
		return this.selectedDriversIds.length;
	}

	get isDeletable() {
		if (this.deliveryBlock) {
			return this.deliveryBlock.isDeletable;
		}
		return true;
	}

	get isEditable() {
		if (this.deliveryBlock) {
			return this.deliveryBlock.isEditable;
		}
		return true;
	}

	initModal = (viewMode: DeliveryBlockModalViewMode, deliveryBlock?: DeliveryBlock) => {
		if (deliveryBlock) {
			this.setSelectedDriversIds(deliveryBlock.user_ids);
			this.setOriginalCapacity(deliveryBlock.original_capacity);
		}

		this.setViewMode(viewMode);
	};

	createOrUpdate = async (values: DeliveryBlockCreateValues, recurringOption?: RecurringOptions) => {
		try {
			const normalizedValues: Partial<DeliveryBlock> = this.normalizeDeliveryBlockFormValues(values);
			if (this.viewMode === DeliveryBlockModalViewMode.CREATE) {
				this.deliveryBlock.set(normalizedValues);
				await this.deliveryBlock.create(this.deliveryBlock.asJson);
				notification.success(i18next.t('DELIVERY_BLOCKS.CREATED_SUCCESSFULLY'));
			} else {
				Object.assign(this.deliveryBlock.viewModel, normalizedValues);
				await this.deliveryBlock.viewTransactionUpdate(recurringOption ? { recurring: recurringOption } : {});
				if (this.deliveryBlock.isRecurring) await this.refetchWeek();
				notification.success(i18next.t('DELIVERY_BLOCKS.UPDATED_SUCCESSFULLY'));
			}
		} catch (e) {
			notification.error(
				i18next.t('DELIVERY_BLOCKS.FAILED_TO_CREATE_OR_UPDATE'),
				(e as BringgException).details as string
			);
		}
	};

	onDelete = async (recurringOption?: RecurringOptions) => {
		try {
			await this.deliveryBlock.delete(recurringOption);
			notification.success(i18next.t('DELIVERY_BLOCKS.DELETED_SUCCESSFULLY'));
		} catch (e) {
			console.error(e);
			notification.error(i18next.t('DELIVERY_BLOCKS.FAILED_TO_DELETE'), (e as BringgException).details as string);
		}
	};

	onOriginalCapacityChange = (originalCapacity: number) => {
		this.setOriginalCapacity(originalCapacity);
	};

	onDriverSelect = (id: number) => {
		this.setSelectedDriversIds([...this.selectedDriversIds, id]);
	};

	onDriverRemove = (id: number) => {
		this.setSelectedDriversIds(_reject(this.selectedDriversIds, driverId => driverId === id));
	};

	normalizeDeliveryBlockFormValues = ({
		name,
		description,
		originalCapacity,
		date,
		endTime,
		startTime,
		breakEndTime,
		breakStartTime,
		userIds,
		ical,
		deliveryBlocksBreaks
	}: DeliveryBlockCreateValues): Partial<DeliveryBlock> => {
		const start_time = date.set({ hour: startTime.hours(), minutes: startTime.minutes() }).toISOString();
		const end_time = date.set({ hour: endTime.hours(), minutes: endTime.minutes() }).toISOString();

		let break_start_time;
		let break_end_time;
		let deliveryBlockBreaks;

		if (this.useOldBreaks) {
			if (breakStartTime && breakEndTime) {
				break_start_time = date
					.set({ hour: breakStartTime.hours(), minutes: breakStartTime.minutes() })
					.toISOString();
				break_end_time = date
					.set({ hour: breakEndTime.hours(), minutes: breakEndTime.minutes() })
					.toISOString();

				const breakValues = deliveryBlocksBreaks ? _first(Array.from(deliveryBlocksBreaks.values())) : {};
				const deliveryBlockBreak = new DeliveryBlockBreak(
					{
						...breakValues,
						startTime: breakStartTime,
						endTime: breakEndTime
					},
					date
				);
				deliveryBlockBreaks = [deliveryBlockBreak];
			} else {
				break_start_time = null;
				break_end_time = null;
				deliveryBlockBreaks = [];
			}
		} else {
			deliveryBlockBreaks = _map(Array.from(deliveryBlocksBreaks.values()), deliveryBlockBreak => {
				return new DeliveryBlockBreak(deliveryBlockBreak, date);
			});
		}

		return {
			name,
			description,
			original_capacity: originalCapacity,
			start_time,
			end_time,
			break_start_time,
			break_end_time,
			user_ids: userIds,
			ical,
			delivery_block_breaks: deliveryBlockBreaks
		};
	};
}

export default DeliveryBlockModalView;
