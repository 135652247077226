import _ from 'lodash';

export function trimStartWhitespacesRecursively(obj: unknown) {
	if (Array.isArray(obj)) {
		return obj
			.filter(el => {
				if (typeof el === 'string') {
					return el.trimStart().length;
				}

				return true;
			})
			.map(trimStartWhitespacesRecursively);
	} else if (typeof obj === 'object' && obj !== null) {
		return _.mapValues(
			_.mapKeys(obj, (value, key) => key),
			trimStartWhitespacesRecursively
		);
	} else if (typeof obj === 'string' && /^\s+$/.test(obj)) {
		return obj.trimStart();
	} else {
		return obj;
	}
}
