import { getRootEnv } from '@bringg-frontend/bringg-web-infra';
/* eslint-disable etc/no-commented-out-code */
import { action, computed, makeObservable, observable } from 'mobx';
import { OptimizationItem } from '@bringg/types';

import BaseDomainStore from 'bringg-web/stores/core/base-domain-store';
import { GenericParameter, OptimizationConfigurationView } from 'bringg-web/features/optimization-configuration/types';

const DEFAULT_OPTIMIZATION_TYPE = 6;
const DEFAULT_DM_ENGINE = '';
class RouteOptimizationConfigurationStore extends BaseDomainStore<any> {
	loading = false;
	fetched = false;

	manifest: GenericParameter[] = null;
	merchantTeamSettings = null;

	teamToConfigMap: Map<number, number> = new Map();

	constructor() {
		super();

		makeObservable(this, {
			loading: observable,
			isLoading: computed,
			setIsLoading: action,
			fetched: observable,
			isFetched: computed,
			setIsFetched: action,

			manifest: observable.shallow,
			setManifest: action,

			merchantTeamSettings: observable.shallow,
			setMerchantTeamSettings: action,

			teamToConfigMap: observable,

			setBatchOrderedWithNormalizedTeams: action
		});
	}

	setIsLoading = (isLoading: boolean) => {
		this.loading = isLoading;
	};

	setIsFetched = (isFetched: boolean) => {
		this.fetched = isFetched;
	};

	get isLoading(): boolean {
		return this.loading;
	}

	get isFetched(): boolean {
		return this.fetched;
	}

	setManifest = manifest => {
		this.manifest = manifest;
	};

	setMerchantTeamSettings = merchantTeamSettings => {
		const next = {
			...merchantTeamSettings,
			merchant: {
				optimizationType: DEFAULT_OPTIMIZATION_TYPE,
				dmEngine: DEFAULT_DM_ENGINE,
				...merchantTeamSettings.merchant
			}
		};

		// this.merchantTeamSettings = merchantTeamSettings;
		this.merchantTeamSettings = next;
	};

	fetchAll = async () => {
		if (this.isFetched) {
			return this.all;
		}

		const { dashboardSdk } = getRootEnv();

		this.setIsLoading(true);

		try {
			const [configurationSetsList, merchantTeamSettings] = await Promise.all([
				dashboardSdk.sdk.optimizationConfigurationV2.list(),
				dashboardSdk.sdk.optimizationConfigurationV2.getMerchantTeamSettings()
			]);

			const manifest = await dashboardSdk.sdk.optimizationConfiguration.getConfigurationAttributes();
			this.setManifest(manifest);

			// this.setBatch(configurationSetsList);
			this.setBatch(
				configurationSetsList.map(item => {
					// TODO remove the transform after migration of configurations to flat structure
					// leaving for safety
					return Object.assign(item, {
						configuration: item.configuration.generalParameters
							? Object.values(item.configuration).reduce(
									(acc, el) => ({ ...(acc as any), ...(el as any) }),
									{}
							  )
							: item.configuration
					});
				})
			);
			this.setMerchantTeamSettings(merchantTeamSettings);

			this.teamToConfigMap = new Map<number, number>(
				configurationSetsList.map(item => item.teams.map(teamId => [teamId, item.id] as const)).flat()
			);

			this.setIsFetched(true);
		} catch (error) {
			console.error('failed to fetch configuration set', error);
		} finally {
			this.setIsLoading(false);
		}
	};

	// Team <-> Route Optimization Config Map must be normalized up after every action same as on BE side
	setBatchOrderedWithNormalizedTeams = items => {
		this.setBatch(
			moveDefaultToZeroIndex(
				items.map(item => ({
					...item,
					teams: item.teams.filter(t => this.teamToConfigMap.get(t) === item.id)
				}))
			)
		);
	};

	createRouteOptimizationConfiguration = async (newROConfig: OptimizationItem) => {
		const { dashboardSdk } = getRootEnv();

		const record = await dashboardSdk.sdk.optimizationConfigurationV2.create(newROConfig);

		record.teams.forEach(teamId => this.teamToConfigMap.set(teamId, record.id));

		this.setBatchOrderedWithNormalizedTeams(this.all.concat(record));

		return record;
	};

	updateRouteOptimizationConfiguration = async (
		config: OptimizationItem,
		previousVersion: OptimizationConfigurationView
	) => {
		const { dashboardSdk } = getRootEnv();

		previousVersion.teams.forEach(teamId => this.teamToConfigMap.delete(teamId));

		const record = await dashboardSdk.sdk.optimizationConfigurationV2.update(config.id, config);

		this.set(record);

		record.teams.forEach(teamId => this.teamToConfigMap.set(teamId, record.id));

		this.setBatchOrderedWithNormalizedTeams(this.all);

		return record;
	};

	deleteRouteOptimizationConfiguration = async (id: number) => {
		const { dashboardSdk } = getRootEnv();
		try {
			const record = this.get(id);
			await dashboardSdk.sdk.optimizationConfigurationV2.delete(id);

			record.teams.forEach(teamId => this.teamToConfigMap.delete(teamId));

			this.remove(id);

			this.setBatchOrderedWithNormalizedTeams(this.all);
		} catch (error) {
			console.error('failed to delete custom attributes', error);
			throw error;
		}
	};

	batchDeleteRouteOptimizationConfigurations = async (ids: number[]) => {
		const { dashboardSdk } = getRootEnv();

		await dashboardSdk.sdk.optimizationConfigurationV2.batchDelete(ids);

		ids.forEach(configId => {
			const record = this.get(configId);

			record.teams.forEach(teamId => this.teamToConfigMap.delete(teamId));

			this.remove(configId);
		});

		this.setBatchOrderedWithNormalizedTeams(this.all);
	};
}

function moveDefaultToZeroIndex(configurations: OptimizationItem[]): OptimizationItem[] {
	const index = configurations.findIndex(config => config.defaultConfiguration);

	if (index !== -1 && index !== 0) {
		const defaultConfig = configurations.splice(index, 1)[0];
		configurations.unshift(defaultConfig);
	}

	return configurations;
}

export default RouteOptimizationConfigurationStore;
