import { action, computed, makeObservable, observable } from 'mobx';
import {
	ActionType,
	AttributeDatatype,
	ClientTriggerParams,
	DisplayFact,
	EventTriggerTasksManualParams,
	FactType,
	ManualTriggerInputParam,
	NumericOperator,
	StringOperator,
	TimeTriggerUnitOfTime,
	TriggerParamsValueType
} from '@bringg/types';
import i18next from 'i18next';
import { decodeParam, EncodedDataType } from '@bringg-frontend/bringg-web-legacy/automation-platform';

import {
	MappedTriggerData,
	Relative,
	StringTriggerValueOperator,
	StringTriggerValues,
	TriggerContainer,
	TriggerValue
} from '../utils/types';
import { mapTriggerFromServer } from '../utils/mapper';
import {
	isEventHappenedValid,
	isFieldChangedValid,
	isManualValid,
	isTimeBasedValid
} from '../components/triggers/utils/utils';
import { workflowsRootStore } from './internal';
import { baseStringOperators } from '../utils/use-options';

export const DEFAULT_TIME_VALUE = 30;

class TriggerStore {
	id: string | null;
	value: TriggerValue | null;
	units: TimeTriggerUnitOfTime | null;
	operator: NumericOperator | null;
	changedTo?: TriggerParamsValueType | null;
	family: TriggerContainer | null;
	relative: Relative | null;

	constructor(trigger: ClientTriggerParams) {
		makeObservable(this, {
			id: observable,
			value: observable,
			units: observable,
			operator: observable,
			changedTo: observable,
			family: observable,
			relative: observable,
			setForceActionForAllConditions: action,
			setForceActionForMatchingConditions: action,
			setID: action,
			setDynamicVariableValue: action,
			setValue: action,
			setRelative: action,
			setUnits: action,
			setOperator: action,
			setStringOperator: action,
			setStringValue: action,
			addStringValue: action,
			setChangedTo: action,
			setFamily: action,
			reset: action,
			isValid: computed,
			triggersById: computed,
			triggers: computed,
			flatTriggers: computed,
			familyTriggers: computed,
			flatFamilyTriggers: computed,
			linkingKey: computed,
			datatype: computed,
			mappedValue: computed,
			factType: computed,
			displayFact: computed,
			displayFamily: computed,
			actions: computed,
			addManualFieldConfiguration: action,
			removeManualFieldConfiguration: action,
			dynamicVariables: computed
		});

		this.id = trigger.id;

		const triggerMetadata = this.id && this.findTriggerMetadata;

		const { value, units, operator, changedTo, relative } = mapTriggerFromServer(trigger, triggerMetadata);
		this.value = value;
		this.units = units;
		this.operator = operator;
		this.changedTo = changedTo || TriggerParamsValueType.Changed;
		this.relative = relative;
		this.family = triggerMetadata?.family;
	}

	setID = (newID: string) => {
		if (newID === this.id) {
			return;
		}
		this.id = newID;
		this.changedTo = null;
		if (this.family !== TriggerContainer.Date_Arrived) {
			this.value = null;
		} else if (this.value != null) {
			this.value = Math.abs(this.value as number);
		}
	};

	setValue = (newValue: TriggerValue) => {
		this.value = this.family === TriggerContainer.Date_Arrived && newValue == null ? 0 : newValue;
	};

	setManualValue = (newValue: string) => {
		if (!this.value) {
			(this.value as EventTriggerTasksManualParams['value']) = {
				name: '',
				allow_to_force_actions_for_all: false,
				allow_to_force_actions_for_matching_conditions: false
			};
		}
		(this.value as EventTriggerTasksManualParams['value']).name = newValue;
	};

	initManualTriggerValue = () => {
		if (!this.value) {
			(this.value as EventTriggerTasksManualParams['value']) = {
				name: '',
				allow_to_force_actions_for_all: false,
				allow_to_force_actions_for_matching_conditions: false
			};
		}
	};

	setForceActionForAllConditions = (newValue: boolean) => {
		this.initManualTriggerValue();
		(this.value as EventTriggerTasksManualParams['value']).allow_to_force_actions_for_all = newValue;
	};

	setForceActionForMatchingConditions = (newValue: boolean) => {
		this.initManualTriggerValue();
		(this.value as EventTriggerTasksManualParams['value']).allow_to_force_actions_for_matching_conditions =
			newValue;
	};

	addStringValue = () => {
		const newValue: StringTriggerValueOperator = {
			value: '',
			operator: baseStringOperators[0]
		};

		if (Array.isArray(this.value)) {
			(this.value as StringTriggerValues).push(newValue);
		} else {
			this.value = [newValue];
		}
	};

	setStringOperator = (operator: StringOperator, index: number) => {
		(this.value as StringTriggerValues)[index].operator = operator;
	};

	setStringValue = (value: string, index: number) => {
		(this.value as StringTriggerValues)[index].value = value;
	};

	setRelative = (relative: Relative) => {
		this.relative = relative;
	};

	setUnits = (newUnits: TimeTriggerUnitOfTime) => {
		this.units = newUnits;
	};

	setOperator = (newOperator: NumericOperator) => {
		this.operator = newOperator;
	};

	setChangedTo = (newChanged: TriggerParamsValueType) => {
		this.value = null;
		this.units = null;
		this.operator = null;
		this.changedTo = newChanged;
	};

	setFamily = (family: TriggerContainer) => {
		this.family = family;
		if (this.family === TriggerContainer.Date_Arrived) {
			this.value = DEFAULT_TIME_VALUE;
			this.units = TimeTriggerUnitOfTime.MINUTES;
			this.relative = Relative.before;
		} else if (this.familyTriggers.length === 1) {
			this.setID(this.familyTriggers[0].id);
		}
	};

	reset = () => {
		this.id = null;
		this.value = null;
		this.units = null;
		this.operator = null;
		this.changedTo = null;
	};

	get triggersById() {
		const { metadataRepo } = workflowsRootStore.getStore();
		return metadataRepo.metadata.triggersById;
	}

	get triggers() {
		const { metadataRepo } = workflowsRootStore.getStore();
		return metadataRepo.metadata.triggers;
	}

	get flatTriggers() {
		const { metadataRepo } = workflowsRootStore.getStore();
		return metadataRepo.metadata.flatTriggers;
	}

	get familyTriggers(): MappedTriggerData[] {
		const { metadataRepo } = workflowsRootStore.getStore();
		return metadataRepo.metadata.triggers.find(tr => tr.id === this.family)?.items || [];
	}

	get flatFamilyTriggers(): MappedTriggerData[] {
		const { metadataRepo } = workflowsRootStore.getStore();

		return metadataRepo.metadata.flatTriggers.filter(tr => tr.family === this.family);
	}

	get linkingKey(): string | undefined {
		return this.triggersById[this.id]?.linking_key;
	}

	get datatype(): AttributeDatatype | undefined {
		return this.triggersById[this.id]?.datatype;
	}

	get mappedValue(): TriggerValue {
		const triggerData = this.triggersById[this.id];

		if (!triggerData) {
			return this.value;
		}
		if ((this.value as number[])?.length) {
			const values = triggerData?.values || [];
			return (this.value as number[]).map(key => values.find(v => v.key === key)?.value);
		}
		if (triggerData.datatype === AttributeDatatype.Boolean) {
			return (
				this.value ? i18next.t('PROGRAMMABLE_TRIGGERS.TRUE') : i18next.t('PROGRAMMABLE_TRIGGERS.FALSE')
			) as string;
		}
		return this.value;
	}

	get findTriggerMetadata(): MappedTriggerData {
		return this.triggersById[this.id];
	}

	get isValid() {
		if (!this.id) {
			return false;
		}
		switch (this.family) {
			case TriggerContainer.Event_Happened:
				return isEventHappenedValid(this);
			case TriggerContainer.Date_Arrived:
				return isTimeBasedValid(this);
			case TriggerContainer.Field_Changed:
				return isFieldChangedValid(this);
			case TriggerContainer.Recurring:
				return !!this.value;
			case TriggerContainer.Manual:
				return isManualValid(this);

			default:
				return true;
		}
	}

	get canComplete() {
		const triggerMetadata = this.findTriggerMetadata;
		return !triggerMetadata.values || !!triggerMetadata.values.length;
	}

	get factType(): FactType {
		return this.findTriggerMetadata?.factType;
	}

	get displayFamily(): string | undefined {
		return this.triggers.find(trigger => trigger.id === this.family)?.displayFamily;
	}

	get displayFact(): DisplayFact {
		return this.findTriggerMetadata?.display_fact;
	}

	get actions(): ActionType[] {
		return this.findTriggerMetadata?.actions || [];
	}

	setDynamicVariableValue = (index: number, updatedFieldName: string) => {
		const triggerValue = this.value;
		if (!this.isEventTriggerTasksManualParamsValue(triggerValue)) {
			return;
		}
		triggerValue.vars = [...triggerValue.vars];
		triggerValue.vars[index] = { ...triggerValue.vars[index], name: updatedFieldName };
	};

	setDynamicVariableType = (index: number, updatedFieldType: EncodedDataType) => {
		const triggerValue = this.value;
		if (!this.isEventTriggerTasksManualParamsValue(triggerValue)) {
			return;
		}
		const { dataType, refId } = decodeParam(updatedFieldType);
		triggerValue.vars[index] = { data_type: dataType, ref_id: refId, name: triggerValue.vars[index].name };
	};

	isEventTriggerTasksManualParamsValue(value: TriggerValue): value is EventTriggerTasksManualParams['value'] {
		return (
			!!value &&
			typeof value === 'object' &&
			Array.isArray((value as EventTriggerTasksManualParams['value']).vars)
		);
	}

	addManualFieldConfiguration = () => {
		const newValue: ManualTriggerInputParam = {
			name: '',
			data_type: undefined
		};

		const { vars } = (this as EventTriggerTasksManualParams).value;

		if (Array.isArray(vars)) {
			vars.push(newValue);
		} else {
			(this as EventTriggerTasksManualParams).value.vars = [newValue];
		}
	};

	removeManualFieldConfiguration = (index: number) => {
		(this as EventTriggerTasksManualParams).value?.vars.splice(index, 1);
	};

	get dynamicVariables(): ManualTriggerInputParam[] {
		return (this as EventTriggerTasksManualParams).value?.vars || [];
	}
}

export default TriggerStore;
