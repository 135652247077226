import { action, computed, makeObservable, observable } from 'mobx';
import i18next from 'i18next';
import {
	ActionCoreData,
	ActionType,
	NarrowActionableWpTimeWindowData,
	NarrowActionableWpTimeWindowDataActionMetadata
} from '@bringg/types';
import { ActionsRepo } from './internal';
import ActionBaseStore from './action-base-store';
import { ActionFamilyType, ClientNarrowActionableWpTimeWindowAction } from '../utils/types';

export const MAX_BUFFER_IN_MINUTES = 180; // 3 HOURS

class NarrowActionableWpTimeWindowActionStore extends ActionBaseStore {
	type = ActionFamilyType.NARROW_ACTIONABLE_WP_TIME_WINDOW;
	action_type = ActionType.NARROW_ACTIONABLE_WP_TIME_WINDOW;

	bufferInMinutes: NarrowActionableWpTimeWindowData['buffer_in_minutes'];

	constructor(actionToInit: ClientNarrowActionableWpTimeWindowAction, actionsRepo: ActionsRepo) {
		super(actionsRepo);
		makeObservable(this, {
			bufferInMinutes: observable,
			setBufferInMinutes: action,
			title: computed
		});

		this.bufferInMinutes = actionToInit.bufferInMinutes ?? 0;
	}

	get mappedToServer(): ActionCoreData {
		return {
			action_type: ActionType.NARROW_ACTIONABLE_WP_TIME_WINDOW,
			data: {
				buffer_in_minutes: this.bufferInMinutes
			}
		};
	}

	get isValid(): boolean {
		if (this.bufferInMinutes === null) {
			return true;
		}

		if (this.bufferInMinutes >= 0 && this.bufferInMinutes <= MAX_BUFFER_IN_MINUTES) {
			return true;
		}

		return false;
	}

	setBufferInMinutes = (buffer: number | null): void => {
		this.bufferInMinutes = buffer;
	};

	get title(): string {
		const titleKey = (this.actionData as NarrowActionableWpTimeWindowDataActionMetadata).translation_string;
		return i18next.t(titleKey);
	}

	get icon(): string {
		return (this.actionData as NarrowActionableWpTimeWindowDataActionMetadata).icon || '';
	}
}

export default NarrowActionableWpTimeWindowActionStore;
