import { WorkflowTemplateResponse } from '@bringg/types';
import { computed, makeObservable, observable } from 'mobx';
import { getRandomGuid } from '@bringg/react-components/dist/features/conditions/rule/rule-utils';
import { difference } from '@bringg-frontend/utils';

import {
	workflowsRootStore,
	WorkflowStore,
	emptyWorkflow,
	RulesRepo,
	TriggerStore,
	ActionsRepo,
	WorkflowContentGenerator,
	ContentType
} from 'bringg-web/automation-platform-exports';

class TemplateStore {
	id: number;
	title: string;
	trigger: TriggerStore;
	rules: RulesRepo;
	actions: ActionsRepo;
	team_ids: number[];
	predefined_id?: number;

	private templateResponse: WorkflowTemplateResponse;
	private contentHolder: WorkflowContentGenerator;

	constructor(template: WorkflowTemplateResponse) {
		makeObservable(this, {
			title: observable,
			trigger: observable,
			rules: observable.ref,
			actions: observable,
			team_ids: observable.ref,
			isExpandable: computed,
			available: computed
		});

		this.templateResponse = { ...template };
		this.init();

		this.contentHolder = new WorkflowContentGenerator(this);
	}

	init = () => {
		this.update({ ...this.templateResponse });

		this.trigger = new TriggerStore(this.templateResponse.trigger);
		this.rules = new RulesRepo(this.templateResponse.rule, this.trigger.factType, this.trigger.displayFact);
		this.actions = new ActionsRepo(
			undefined,
			this.templateResponse.actions,
			this.trigger.factType,
			this.trigger.displayFact,
			this.trigger
		);
	};

	update(workflow: Partial<WorkflowTemplateResponse>) {
		Object.assign(this, workflow);
	}

	get workflow(): WorkflowStore {
		return new WorkflowStore({
			...emptyWorkflow,
			template_id: this.id,
			guid: getRandomGuid(),
			team_ids: this.templateResponse.team_ids,
			trigger: this.templateResponse.trigger,
			actions: this.templateResponse.actions,
			rule: this.templateResponse.rule,
			title: this.templateResponse.title
		});
	}

	get canComplete() {
		return this.trigger.canComplete && this.rules.canComplete && this.actions.canComplete;
	}

	/**
	 * Available meaning that the matching predefined is not enabled
	 */
	get available() {
		return workflowsRootStore.getStore().predefinedAlertRepo.enabled.every(({ id }) => id !== this.id);
	}

	get content() {
		return this.contentHolder;
	}

	get isExpandable() {
		return this.contentHolder.sections.some(section => {
			const fullContent = this.contentHolder.mappedSection(section as keyof ContentType, true);
			const partialContent = this.contentHolder.mappedSection(section as keyof ContentType, false);
			const diff = difference(fullContent, partialContent);
			return (diff as [])?.length > 0;
		});
	}
}

export default TemplateStore;
