import type { CustomAttributeCreateRequest, CustomAttributeUpdateRequest } from '@bringg/types';

export const ATTRIBUTE_FIELDS_FOR_CREATE: (keyof CustomAttributeCreateRequest)[] = [
	'entity_type',
	'path',
	'data_type',
	'name',
	'required',
	'hidden',
	'resolver',
	'description',
	'suggestion',
	'enum_definition',
	'show_in_mobile_app'
];
export const ATTRIBUTE_FIELDS_FOR_UPDATE: (keyof CustomAttributeUpdateRequest)[] = [
	...ATTRIBUTE_FIELDS_FOR_CREATE,
	'id'
];

export const MANDATORY_NEW_ATTRIBUTE_FIELDS = {
	hidden: false
};

export const ATTRIBUTE_PATH_START_KEY = 'extras';
