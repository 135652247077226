import React, { useEffect } from 'react';

import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { PrivilegeTypes } from '@bringg/types';
import Pages, { RouteDetails } from '@bringg/react-pages/dist/components/pages/pages';

import { ServiceArea } from 'bringg-web/features/service-area/service-area';
import { useHasAccess } from 'bringg-web/utils/privileges';
import useStores from '../../recipes/use-stores';
import TeamInfoContainer from './team-page-logic/team-info-container';
import TeamDrivers from './drivers/team-drivers';
import TeamVehicles from './vehicles/team-vehicles';
import TeamSettings from './settings/team-settings';
import MerchantConfigurationsStore from '../../stores/merchant-configurations/merchant-configurations-store';
import { useHasFeatureFlag } from '../../utils/feature-flags';
import { SERVICE_AREA_LAT_LNG_FF } from 'bringg-web/consts';

import './team-page.scss';

const getTeamRoutes = (
	teamId: number,
	t: TFunction,
	merchantConfigurationsStore: MerchantConfigurationsStore,
	allowUpdateATC: boolean,
	canEditServiceArea: boolean
): RouteDetails[] => {
	const routes: RouteDetails[] = [
		{
			path: 'info',
			title: t('TEAM.INFO'),
			render: () => <TeamInfoContainer teamId={teamId} />,
			default: true
		},
		{
			path: 'drivers',
			title: t('TEAM.DRIVERS'),
			render: () => <TeamDrivers teamId={teamId} />
		},
		{
			path: 'vehicles',
			title: t('TEAM.VEHICLES'),
			render: () => <TeamVehicles teamId={teamId} />
		},
		{
			path: 'service-areas',
			title: t('TEAM.SERVICE_AREAS'),
			render: () => <ServiceArea readonly={!canEditServiceArea} teamId={teamId} />
		}
	].filter(Boolean);

	const autoDispatchApplicationInstalled = merchantConfigurationsStore.autoDispatchStatus;

	if (autoDispatchApplicationInstalled && allowUpdateATC) {
		routes.push({
			path: 'settings',
			title: t('MAIN.SETTINGS'),
			render: () => <TeamSettings teamId={teamId} />
		});
	}

	return routes;
};

export const TeamPage: React.FC = () => {
	const { t } = useTranslation();
	const { id } = useParams();
	const { teamsStore, merchantConfigurationsStore, serviceAreaViewStore, merchantStore, serviceAreasStore } =
		useStores();
	const allowUpdateATC = useHasFeatureFlag('allow_update_atc');
	const latLngFF = useHasFeatureFlag(SERVICE_AREA_LAT_LNG_FF);
	const canEditServiceArea = useHasAccess(PrivilegeTypes.ALLOW_DISPATCHER_EDIT_SERVICE_AREA);

	useEffect(() => {
		void serviceAreaViewStore.init(serviceAreasStore, latLngFF);
		void merchantStore.fetch();
		void teamsStore.fetchAll();
		serviceAreaViewStore.filterBy({ byTeams: [Number(id)] });
	}, [id, serviceAreaViewStore, teamsStore, merchantStore, serviceAreasStore, latLngFF]);

	return (
		<div className="team-page-container">
			<Pages
				routes={getTeamRoutes(Number(id), t, merchantConfigurationsStore, allowUpdateATC, canEditServiceArea)}
			/>
		</div>
	);
};

export default TeamPage;
