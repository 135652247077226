import { observable, makeObservable } from 'mobx';
import { datetime } from '@bringg/types';
import { getRootEnv } from '@bringg-frontend/bringg-web-infra';

import UserTypeStore from '../user-type-store';

export default class UserType {
	id: number = null;
	merchant_id: number = null;
	title: string = null;
	default_for_merchant: boolean = null;
	maximum_tasks_per_shift: number = null;
	minimum_tasks_per_shift: number = null;
	cost_per_shift: number = null;
	cost_per_task: number = null;
	created_at: datetime = null;
	updated_at: datetime = null;
	external_id: string = null;
	is_crowd: boolean = null;
	max_total_weight: number = null;
	max_weight_per_parcel: number = null;
	height: number = null;
	width: number = null;
	length: number = null;
	vehicle_height: number = null;
	vehicle_width: number = null;
	vehicle_length: number = null;
	cost_per_km_driving: number = null;
	cost_per_minute_driving: number = null;
	cost_per_minute_waiting: number = null;
	cost_per_minute_service: number = null;
	speed_factor: number = null;

	store: UserTypeStore = null;

	constructor(userType, userTypeStore) {
		makeObservable(this, {
			title: observable
		});

		Object.assign(this, userType);
		this.store = userTypeStore;
	}

	create = async () => {
		const { id } = await getRootEnv().dashboardSdk.sdk.userTypes.create(this.asJson);
		this.id = id;
		this.store.set(this);
	};

	get asJson(): Partial<UserType> {
		return {
			id: this.id,
			merchant_id: this.merchant_id,
			title: this.title,
			default_for_merchant: this.default_for_merchant,
			maximum_tasks_per_shift: this.maximum_tasks_per_shift,
			minimum_tasks_per_shift: this.minimum_tasks_per_shift,
			cost_per_shift: this.cost_per_shift,
			cost_per_task: this.cost_per_task,
			external_id: this.external_id,
			is_crowd: this.is_crowd,
			max_total_weight: this.max_total_weight,
			max_weight_per_parcel: this.max_weight_per_parcel,
			height: this.height,
			width: this.width,
			length: this.length,
			vehicle_height: this.vehicle_height,
			vehicle_width: this.vehicle_width,
			vehicle_length: this.vehicle_length,
			cost_per_km_driving: this.cost_per_km_driving,
			cost_per_minute_driving: this.cost_per_minute_driving,
			cost_per_minute_waiting: this.cost_per_minute_waiting,
			cost_per_minute_service: this.cost_per_minute_service,
			speed_factor: this.speed_factor
		};
	}
}
