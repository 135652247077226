import React from 'react';
import map from 'lodash/map';
import { observer } from 'mobx-react';
import { Select, Option } from '../../../components';
import { SHARING_MODES } from './sharing-mode.consts';
import { Translate } from '../../../translation';
import TagMerchantConfiguration from '../../../stores/tag-merchant-configurations/tag-merchant-configuration';
import MerchantConfiguration from '../../../stores/merchant-configurations/domain-objects/merchant-configuration';

interface Props {
	configuration: MerchantConfiguration | TagMerchantConfiguration;
}

const SharingMode = (props: Props) => {
	const { sharingMode, updateSharingMode } = props.configuration;

	return (
		<div className="sharing-mode">
			<div className="title">
				<Translate text="CUSTOMER_CONFIGURATION_VIEW.SHARING_WITH_CUSTOMER" />
			</div>
			<Select className="sharing-mode-select" value={sharingMode} onSelect={updateSharingMode}>
				{map(SHARING_MODES, ({ value, label }) => (
					<Option key={value} value={value}>
						{label}
					</Option>
				))}
			</Select>
		</div>
	);
};

export default observer(SharingMode);
