import React, { FunctionComponent } from 'react';
import { Row, Col } from 'antd';
import { Checkbox } from '@bringg/react-components';
import { useObserver } from 'mobx-react';
import useStores from '../../../../recipes/use-stores';
import { ComponentWithForm } from '@bringg/react-pages/dist/components';
import { Controller } from 'react-hook-form';
import { FleetTemplateSelection } from '../../../../stores/fleets/fleets-store';

const AvailableFleetsOptions: FunctionComponent<ComponentWithForm<FleetTemplateSelection>> = ({
	form,
	defaultValues
}) => {
	const { fleetsStore } = useStores();
	const { control } = form;

	return useObserver(() => {
		return (
			<Row>
				{fleetsStore.getFleetTemplateIds.map(fleetId => (
					<Col span={6} key={`${fleetId}`}>
						<Controller
							render={({ value, onChange }) => (
								<Checkbox
									checked={value}
									onChange={e => onChange(e.target.checked)}
									className="fleet-checkbox"
								>
									{fleetsStore.getFleetTemplateById(fleetId).name}
								</Checkbox>
							)}
							name={fleetId.toString()}
							control={control}
							defaultValue={defaultValues[fleetId]}
						/>
					</Col>
				))}
			</Row>
		);
	});
};

export default AvailableFleetsOptions;
