import React, { useEffect, useState } from 'react';
import { InputNumber, Select, Option, Tooltip } from '@bringg/react-components';

import { useMerchantConfiguration } from '../../../../recipes';

import { MIN_NUMBER_VALUE } from '../constants';
import { BringgFontIcons, BringgIcon } from '@bringg/bringg-icons';
import { useTranslation } from 'react-i18next';
import { CustomUnitsDefinition, HandlingUnits } from '@bringg/types';

interface HandlingUnitsInputProps {
	value?: HandlingUnits;
	onChange?: (value: HandlingUnits) => void;
}

const HandlingUnitsInput = ({ value = {}, onChange }: HandlingUnitsInputProps) => {
	const { t } = useTranslation();
	const { custom_units_definition = {} }: { custom_units_definition?: CustomUnitsDefinition } =
		useMerchantConfiguration();

	const [unitKey, setUnitKey] = useState<string | undefined>();
	const [unitValue, setUnitValue] = useState<number | undefined>();

	useEffect(() => {
		setUnitKey(Object.keys(value)[0]);
		setUnitValue(Object.values(value)[0]);
	}, [value]);

	useEffect(() => {
		if (unitKey && unitValue) {
			const handlingUnits: HandlingUnits = { [unitKey]: unitValue };

			onChange(handlingUnits);
		}
	}, [unitKey, unitValue]);

	const handlingUnits = Object.keys(custom_units_definition || {}).map(key => ({
		name: custom_units_definition[key].name,
		key: key
	}));

	return (
		<div className="vehicle-profiles-form--flex-group">
			<div className="vehicle-profiles-form--input-group">
				<div className="vehicle-profiles-form--label-text">{t('VEHICLE_PROFILES.FORM.HANDLING_UNITS')}</div>
				<Select
					placeholder={t('VEHICLE_PROFILES.FORM.HANDLING_UNITS_PLACEHOLDER')}
					onSelect={setUnitKey}
					className="vehicle-profiles-form--select"
					value={unitKey}
					data-test-id={'handling-units'}
				>
					{handlingUnits.map(unit => (
						<Option key={unit.key} value={unit.key} data-test-id={`handling-units-${unit.key}`}>
							{unit.name}
						</Option>
					))}
				</Select>
			</div>
			<div className="vehicle-profiles-form--input-group">
				<div className="vehicle-profiles-form--label-text">
					{t('VEHICLE_PROFILES.FORM.HANDLING_UNITS_VALUE')}
					&nbsp;
					<Tooltip
						overlayClassName="attribute-input-tooltip"
						placement="right"
						title={t('VEHICLE_PROFILES.FORM.HELP.HANDLING_UNITS_VALUE')}
					>
						<BringgIcon className="info-icon" iconName={BringgFontIcons.Info} />
					</Tooltip>
				</div>
				<InputNumber
					placeholder={t('VEHICLE_PROFILES.FORM.HANDLING_UNITS_VALUE_PLACEHOLDER')}
					onChange={value => setUnitValue(value as number)}
					precision={0}
					value={Number(unitValue)}
					className="vehicle-profiles-form--input"
					min={MIN_NUMBER_VALUE}
					data-test-id="handling_units"
				/>
			</div>
		</div>
	);
};

export default HandlingUnitsInput;
