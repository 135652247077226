export const TEMPLATE_HEADERS = [
	'name',
	'external_id',
	'zipcodes',
	'polygon',
	'team_external_ids',
	'start_date',
	'end_date'
];
export const TEMPLATE_ROWS = [
	[
		'Service Area 1 Zipcodes',
		'sa_1',
		'"[141249, 123123,  143123]"',
		'',
		'"team1_external_id, team2_external_id"',
		'',
		''
	],
	[
		'Service Area 2 Polygons',
		'sa_2',
		'',
		'"[(40.8820, -73.9170), (40.7736, -73.9566), (40.7026, -74.0125), (40.6501, -73.9496), (40.7498, -73.7976), (40.8370, -73.8654)]"',
		'',
		'',
		''
	],
	['Service Area 3 Effective Date', 'sa_3', '"[90210]"', '', '"team1_external_id"', '2024-01-01', '2024-12-12']
];

export const DONE_EVENT = 'service areas import done';
