import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@bringg/react-components';
import { BringgFontIcons, BringgIcon } from '@bringg/bringg-icons';
import classNames from 'classnames';

interface Props {
	onBreakAdded: () => void;
	useSmallIcon?: true;
}

const AddBreak: FunctionComponent<Props> = ({ onBreakAdded, useSmallIcon }: Props) => {
	const { t } = useTranslation();

	return (
		<Button
			className="add-break"
			onClick={onBreakAdded}
			type="primary"
			icon={<BringgIcon iconName={BringgFontIcons.Plus} />}
		>
			<span className={classNames('add-break-text', { 'big-icon-text': !useSmallIcon })}>
				{t('DELIVERY_BLOCKS.ADD_BREAK')}
			</span>
		</Button>
	);
};

export default AddBreak;
