import { getRoot } from 'mobx-easy';

import { RootStore } from './root-store-types';

// This is initialized in the bringg-web-react root store, so don't call it from there
// For tests call getRootStoreMock() instead
export function getSharedRootStore() {
	const rootStore = getRoot<RootStore>();

	return rootStore;
}
