import { BringgFontIcons, BringgIcon } from '@bringg/bringg-icons';
import { Button } from '@bringg/react-components';
import { ServiceArea } from '@bringg/types';
import * as ReactDOM from 'react-dom';
import { TFunction } from 'react-i18next';

import './info-window.scss';

export type Props = {
	serviceArea: Partial<ServiceArea>;
	t: TFunction;
	polygon: google.maps.Polygon;
	onEdit: () => void;
	readonly?: boolean;
};

let _infoWindow: google.maps.InfoWindow;

function getInfoWindow() {
	if (!_infoWindow) {
		_infoWindow = new google.maps.InfoWindow();
	}
	return _infoWindow;
}

export function attachInfoWindow({ serviceArea, t, polygon, onEdit, readonly = false }: Props) {
	const content = (
		<div id="info-popup" className="service-area-info-popup">
			<div className="info-header">
				<div className="info-name">{serviceArea.name}</div>
				{!readonly ? (
					<Button
						type="link"
						onClick={() => {
							getInfoWindow().close();
							onEdit();
						}}
					>
						<BringgIcon iconName={BringgFontIcons.Pencil} />
						{t('SERVICE_AREA.EDIT')}
					</Button>
				) : null}
			</div>
		</div>
	);

	const container = document.createElement('div');

	ReactDOM.render(content, container);

	polygon.addListener('mouseover', event => {
		if (polygon.getEditable()) {
			return;
		}

		getInfoWindow().setContent(container);
		getInfoWindow().setPosition(event.latLng);
		getInfoWindow().open(polygon.get('map'));
	});

	polygon.addListener('mouseout', () => {
		if (polygon.getEditable()) {
			return;
		}

		getInfoWindow().close();
	});

	return getInfoWindow();
}
