import React, { useEffect, useState } from 'react';
import { Select, Option } from '../../../components/';
import { Driver, ServiceArea } from '@bringg/types';
import { useStores } from '../../../recipes';
import { getServiceAreaColor } from '@bringg/react-components/dist/components/mapbox/mapbox-with-service-areas/utils/service-areas-supervisor';
import { hexToRGB } from '../../../utils/color-utils';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { useObserver } from 'mobx-react';
import _isEmpty from 'lodash/isEmpty';

const getCircleStyle = (serviceArea: ServiceArea) => {
	const serviceAreaColor = getServiceAreaColor(serviceArea);
	const circleColor = hexToRGB(serviceAreaColor, 0.2);

	return {
		backgroundColor: circleColor,
		borderColor: serviceAreaColor
	};
};

const getItem = (serviceArea: ServiceArea, isSelected?: boolean) => {
	return (
		<div className="service-area-select-item">
			<div
				className={classNames('service-area-select-circle', { 'service-area-item-selected': isSelected })}
				style={getCircleStyle(serviceArea)}
			/>
			<span className="service-area-select-title">{serviceArea.name}</span>
		</div>
	);
};

const UNASSIGN = 'unassign';

interface Props {
	serviceAreas: ServiceArea[];
	driver: Driver;
}

const ServiceAreasSelect: React.FC<Props> = ({ serviceAreas, driver }) => {
	const { teamsStore } = useStores();
	const { t } = useTranslation();
	const unassignLabel = t('DRIVERS.UNASSIGN_SERVICE_AREA');
	const [selectedServiceAreaId, setSelectedServiceArea] = useState(driver.service_area_id ?? undefined);

	const onClick = async value => {
		if (value) {
			setSelectedServiceArea(value);
			await teamsStore.assignServiceArea(value, driver.id);
		} else if (UNASSIGN) {
			setSelectedServiceArea(undefined);
			await teamsStore.unassignServiceArea(driver.id);
		}
	};

	const hasServiceArea = (id: number): boolean => {
		return !_isEmpty(
			serviceAreas.find(serviceArea => {
				return serviceArea.id === id;
			})
		);
	};

	useEffect(() => {
		setSelectedServiceArea(hasServiceArea(driver.service_area_id) ? driver.service_area_id : undefined);
	}, [serviceAreas]);

	return useObserver(() => (
		<Select
			className="service-area-select"
			placeholder={t('DRIVERS.ASSIGN_SERVICE_AREA')}
			value={selectedServiceAreaId}
			onChange={onClick}
			optionLabelProp="label"
		>
			{selectedServiceAreaId && (
				<Option key={UNASSIGN} value={null}>
					<span className="service-area-select-title">{unassignLabel}</span>
				</Option>
			)}

			{serviceAreas.map(serviceArea => (
				<Option key={`${serviceArea.id}`} value={serviceArea.id} label={getItem(serviceArea, true)}>
					{getItem(serviceArea)}
				</Option>
			))}
		</Select>
	));
};

export default ServiceAreasSelect;
