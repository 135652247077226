import { getRootEnv } from '@bringg-frontend/bringg-web-infra';
import { action, makeObservable, observable } from 'mobx';
import type { SupportTicket } from '@bringg/types';
import type { UploadChangeParam } from 'antd/lib/upload';

import { uploadTicketAttachments } from '../upload-ticket-attachments';

export class SupportTicketStore {
	loading = false;

	constructor() {
		makeObservable(this, {
			loading: observable,
			setIsLoading: action
		});
	}

	setIsLoading = (isLoading: boolean) => {
		this.loading = isLoading;
	};

	get isLoading(): boolean {
		return this.loading;
	}

	get dashboardSdk() {
		return getRootEnv().dashboardSdk.sdk;
	}

	createSupportTicket = async (params: SupportTicket) => {
		try {
			return await this.dashboardSdk.supportTicket.createSupportTicket(params);
		} catch (error) {
			console.error(error);
		}
	};

	uploadAttachments = async (filesList: UploadChangeParam['fileList'], caseId: string) => {
		try {
			const {
				user: { authentication_token },
				config: { getAuthenticationEndpoint }
			} = this.dashboardSdk.session;
			const { host, protocol } = getAuthenticationEndpoint();

			return await uploadTicketAttachments({
				filesList,
				caseId,
				token: String(authentication_token),
				apiUrl: `${protocol}//${host}`
			});
		} catch (error) {
			console.error(error);
		}
	};
}
