import React from 'react';

import { Tooltip } from '@bringg/react-components';
import { BringgFontIcons, BringgIcon } from '@bringg/bringg-icons';
import classnames from 'classnames';
import { observer } from 'mobx-react';

import './attribute-list-item.scss';

interface Props {
	className?: string;
	name: string;
	displayValue: any;
	description?: string;
	attributeValueClassName?: string;
	displayTooltip?: React.ReactNode;
}

const AttributeListItem = ({
	className,
	name,
	displayValue,
	description,
	attributeValueClassName,
	displayTooltip
}: Props) => {
	const descriptionTooltip = description?.trim() ? (
		<Tooltip key="attributes-list-item-tooltip" title={description} placement="top">
			&nbsp;
			<BringgIcon iconName={BringgFontIcons.Help} />
		</Tooltip>
	) : null;

	return (
		<p className={classnames(className, 'attribute-list-item')} role="listitem">
			<span className="attribute-list-item-name">
				{name}
				{descriptionTooltip}:
			</span>
			&nbsp;
			<span className={classnames('attribute-list-item-value', attributeValueClassName)}>{displayValue}</span>
			{displayTooltip}
		</p>
	);
};

export default observer(AttributeListItem);
