import { getRootEnv } from '@bringg-frontend/bringg-web-infra';
import { action, computed, makeObservable, observable } from 'mobx';
import { getRoot } from 'mobx-easy';
import { AsyncOperationStatusPayload, AsyncOperationStatusType, Modify } from '@bringg/types';
import i18next from 'i18next';
import { Failure } from '@bringg-frontend/batch-action-status';

import RootStore from 'bringg-web/stores/root-store';
import { workflowsRootStore } from '../stores/internal';
import { workflowsProvider } from '../data-providers/workflows-provider';

const ASYNC_OPERATION_DEFAULT_TIMEOUT = 1000 * 60 * 5; // 5 minutes

type AsyncOperationPayload = { task_ids: number[] };
export type AsyncOperationData = AsyncOperationStatusPayload<AsyncOperationPayload>;

type Options = {
	requestId: string;
	taskIds: number[];
	numberOfActionsPerTask: number;
	triggerName: string;
	onClose: (store: TasksAsyncOperationStore) => void;
	timeout?: number;
};

export class TasksAsyncOperationStore {
	readonly triggerName: string;
	private readonly requestId: string;
	private readonly numberOfActionsPerTask: number;
	private readonly timeout: number;

	private readonly taskIds: number[] = [];

	partialCompleted = new Map<number, number>();
	fullyCompleted = new Map<number, boolean>();
	failuresMap = new Map<number, Failure<true>>();

	private realtimeAsyncOperationAbortController = new AbortController();
	private onClose: Options['onClose'];

	private constructor({
		triggerName,
		taskIds,
		requestId,
		numberOfActionsPerTask,
		timeout = ASYNC_OPERATION_DEFAULT_TIMEOUT,
		onClose
	}: Options) {
		makeObservable(this, {
			successfulItemsCount: computed,
			failuresMap: observable,
			fullyCompleted: observable,
			partialCompleted: observable,
			failures: computed,
			dispose: action,
			startAsyncOperation: action,
			markTasksAsFailed: action,
			totalNumberOfTasks: computed
		});

		this.timeout = timeout;
		this.requestId = requestId;
		this.numberOfActionsPerTask = numberOfActionsPerTask || 1;
		this.triggerName = triggerName;
		this.onClose = onClose;
		this.taskIds = taskIds;
	}

	static async create(
		options: Modify<Options, { workflowId: number; numberOfActionsPerTask?: never }>
	): Promise<TasksAsyncOperationStore> {
		const numberOfActionsPerTask = await TasksAsyncOperationStore.getNumberOfActionsPerTask(
			options.workflowId,
			options.requestId
		);

		return new TasksAsyncOperationStore({
			...options,
			numberOfActionsPerTask
		});
	}

	dispose() {
		this.realtimeAsyncOperationAbortController.abort();
		this.onClose(this);

		// Make sure we don't call onClose twice
		this.onClose = () => undefined;
	}

	startAsyncOperation(): this {
		// We get realtime updates per each action per task

		const timeout = setTimeout(async () => {
			this.realtimeAsyncOperationAbortController.signal.removeEventListener('abort', clearRealTimeTimeout);
			this.realtimeAsyncOperationAbortController.abort();

			const pendingTasks = this.taskIds.filter(id => !this.fullyCompleted.has(id));
			await this.onTaskFailure(pendingTasks);
		}, this.timeout);

		const clearRealTimeTimeout = clearTimeout.bind(undefined, timeout);
		this.realtimeAsyncOperationAbortController.signal.addEventListener('abort', clearRealTimeTimeout, {
			once: true
		});

		getRootEnv().dashboardSdk.sdk.asyncOperationStatus.addListener<AsyncOperationPayload>({
			requestId: this.requestId,
			handler: async payload => {
				const taskIds = payload.payload.task_ids;
				if (payload.status === AsyncOperationStatusType.SUCCESS) {
					this.onTaskSuccess(taskIds);
				}

				if (payload.status === AsyncOperationStatusType.FAILURE) {
					await this.onTaskFailure(taskIds);
				}

				if (this.fullyCompleted.size >= this.taskIds.length) {
					this.realtimeAsyncOperationAbortController.abort();
				}
			},

			signal: this.realtimeAsyncOperationAbortController.signal
		});

		return this;
	}

	private static async getNumberOfActionsPerTask(workflowId: number, requestId: string) {
		let workflow = workflowsRootStore.getStore().workflowRepo.get(workflowId);

		if (!workflow) {
			await workflowsProvider.loadAll();
			workflow = workflowsRootStore.getStore().workflowRepo.get(workflowId);
		}

		if (!workflow) {
			console.error('workflow not found', { workflowId, requestId: requestId });
			return 1;
		}

		return workflow.actions.actions.length;
	}

	private onTaskSuccess(taskIds: number[]) {
		const notFinishedTaskIds = taskIds.filter(taskId => !this.fullyCompleted.has(taskId));

		for (const taskId of notFinishedTaskIds) {
			const currentCount = (this.partialCompleted.get(taskId) || 0) + 1;

			if (currentCount === this.numberOfActionsPerTask) {
				this.fullyCompleted.set(taskId, true);
				this.partialCompleted.delete(taskId);
			} else {
				this.partialCompleted.set(taskId, currentCount);
			}
		}
	}

	private async onTaskFailure(taskIds: number[]) {
		const newTaskIds = taskIds.filter(taskId => !this.failuresMap.has(taskId));
		if (!newTaskIds.length) {
			return;
		}

		let tasks: { id: number; external_id: string }[];

		try {
			tasks = (await getRoot<RootStore>().data.tasksStore.loadMany(newTaskIds)) as {
				id: number;
				external_id: string;
			}[];
		} catch (error) {
			console.error('failed to load task', error);
			tasks = newTaskIds.map(id => ({ id, external_id: 'unknown' }));
		}

		this.markTasksAsFailed(tasks);
	}

	markTasksAsFailed(tasks: { id: number; external_id: string }[]) {
		for (const task of tasks) {
			this.failuresMap.set(task.id, {
				id: task.id,
				title: `${i18next.t('DISPATCH_LIST.ORDER')} ${task?.external_id}`,

				reason: undefined
			});

			// Once failed we don't care about the task anymore
			this.fullyCompleted.set(task.id, false);
		}
	}

	get failures() {
		return Array.from(this.failuresMap.values());
	}

	get successfulItemsCount() {
		return Array.from(this.fullyCompleted.values()).filter(Boolean).length;
	}

	get totalNumberOfTasks() {
		return this.taskIds.length;
	}

	get id() {
		return this.requestId;
	}
}
