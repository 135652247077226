import React from 'react';
import { observer } from 'mobx-react';
import { Checkbox, Tooltip } from '@bringg/react-components';
import { BringgFontIcons, BringgIcon } from '@bringg/bringg-icons';
import Translate from '../../../translation/translator';
import CustomerConfiguration from '../../../stores/customer-configurations/domain-objects/customer-configuration';
import TagCustomerConfiguration from '../../../stores/tag-customer-configurations/tag-customer-configuration';

interface Props {
	customerConfiguration: CustomerConfiguration | TagCustomerConfiguration;
}

export const ATTR_KEEP_TIME_WINDOW_FIXED_ON_SHARE = 'keep_time_window_fixed_on_share';
export const ATTR_ENABLE_SHARE_WITH_CUSTOMER_MMS = 'enable_share_with_customer_mms';

const CustomerNotificationSettings: React.SFC<Props> = observer(props => {
	const { customerConfiguration } = props;

	const onShareWithCustomerChanged = ({ target }) =>
		customerConfiguration.setValue(ATTR_ENABLE_SHARE_WITH_CUSTOMER_MMS, target.checked);
	const onKeepTimeWindowChanged = ({ target }) =>
		customerConfiguration.setValue(ATTR_KEEP_TIME_WINDOW_FIXED_ON_SHARE, target.checked);

	return (
		<div className="customer-notification-settings">
			<div className="customer-notification-setting">
				<Checkbox
					className="setting-checkbox"
					checked={customerConfiguration.enable_share_with_customer_mms}
					onChange={onShareWithCustomerChanged}
					data-test-id="enable-share-with-customer-mms"
				>
					<Translate text="CUSTOMER_CONFIGURATION_VIEW.SHARE_WITH_CUSTOMER_MMS" />
				</Checkbox>
			</div>
			<div className="customer-notification-setting">
				<Checkbox
					className="setting-checkbox"
					checked={customerConfiguration.keep_time_window_fixed_on_share}
					onChange={onKeepTimeWindowChanged}
					data-test-id="keep-time-window-fixed-on-share"
				>
					<Translate text="CUSTOMER_CONFIGURATION_VIEW.KEEP_TIME_WINDOW_FIXED_ON_SHARE" />
				</Checkbox>
				<Tooltip
					key="setting-checkbox"
					title={<Translate text="CUSTOMER_CONFIGURATION_VIEW.KEEP_TIME_WINDOW_FIXED_ON_SHARE_TOOLTIP" />}
				>
					<BringgIcon iconName={BringgFontIcons.Info} className="setting-checkbox-info" />
				</Tooltip>
			</div>
		</div>
	);
});

export default CustomerNotificationSettings;
