import React from 'react';

import type { Row, Cell } from 'react-table';

interface Props {
	row: Row<Record<string, unknown>>;
}

// TODO: review this component
const DefaultGroupHeader = ({ row }: Props) => {
	return (
		<>
			{row.allCells.map((cell: Cell) => {
				let content = null;

				if (cell.isGrouped) {
					content = (
						<>
							{cell.render('Header')}: {cell.render('Cell')} ({row.leafRows.length} items)
						</>
					);
				} else if (cell.isAggregated && cell.value) {
					content = <> --- {cell.render('Aggregated')}</>;
				}
				return (
					<span key={cell.column.id} data-test-id={cell.column.id}>
						{content}
					</span>
				);
			})}
		</>
	);
};

export default DefaultGroupHeader;
