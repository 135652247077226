import React from 'react';

import _isNumber from 'lodash/isNumber';
import classnames from 'classnames';
import classNames from 'classnames';
import { FieldData } from '@bringg/types';
import {
	BringgInput,
	BringgTextArea,
	Button,
	Checkbox,
	DatePicker,
	Form,
	FormChildren,
	FormInstance,
	ImageUploader,
	InputNumber,
	regexRule,
	requiredRule,
	Select,
	Signature,
	Switch,
	TimePicker,
	UserSelectorMode,
	validateType
} from '@bringg/react-components';
import { AsyncUsersSelector } from '@bringg-frontend/users-selector';
import { FormLayout } from 'antd/lib/form/Form';

import './dynamic-form.scss';

interface Props {
	fields: FormFields[];
	title?: string | JSX.Element;
	onSubmit?: (values: any) => void;
	onCancel?: () => void;
	showSubmitButton?: boolean;
	showCancelButton?: boolean;
	cancelButton?: JSX.Element;
	submitButton?: JSX.Element;
	formInstance: FormInstance;
	layout?: FormLayout;
	numberOfColumns?: number;
	resetFields?: boolean;
	initialValues?: Record<string, any>;
}

export type FormFields = FieldData & {
	regex?: RegExp;
	formItemClassName?: string;
	disabled?: boolean;
	initialValue?: any;
	valuePropName?: string;
	tooltip?: string;
	timeFormat?: string;
	use12Hours?: boolean;
	placeholder?: string;
	mode?: UserSelectorMode;
};

export enum FORM_CONTROLS {
	INPUT = 0,
	CHECKBOX = 1,
	SWITCH = 2,
	RADIO = 3,
	DROP_DOWN = 4,
	SIGNATURE = 5,
	PICTURE = 6,
	LABEL = 7,
	LOGO = 8,
	OPEN_APP = 9,
	TEXTBOX = 10,
	TIME = 11,
	DATE = 12,
	USER_SELECTOR = 13,
	DATETIME = 14
}

const NOT_FORM_CHILDREN_CONTROLS = [FORM_CONTROLS.LABEL, FORM_CONTROLS.OPEN_APP];

export enum StringType {
	number = 0,
	string = 1,
	boolean = 2
}

class DynamicForm extends React.PureComponent<Props> {
	static defaultProps = {
		resetFields: true
	};

	getFieldControl = (field: FormFields) => {
		const {
			key,
			control,
			type,
			label,
			list_values,
			disclaimer,
			title,
			disabled,
			mandatory,
			url,
			formItemClassName,
			timeFormat,
			use12Hours,
			placeholder = ''
		} = field;

		switch (control) {
			case FORM_CONTROLS.INPUT:
				return this.getInputComponent(type, { disabled });

			case FORM_CONTROLS.TEXTBOX:
				return <BringgTextArea disabled={disabled} key={key} />;

			case FORM_CONTROLS.CHECKBOX:
				return <Checkbox disabled={disabled} />;

			case FORM_CONTROLS.SWITCH:
				return <Switch key={key} disabled={disabled} />;

			case FORM_CONTROLS.DROP_DOWN:
				return (
					<Select
						showSearch
						getPopupContainer={triggerNode => triggerNode.parentNode}
						key={key}
						options={this.generateSelectOptions(list_values || [], `${key}`)}
						disabled={disabled}
						data-test-id={`select_${key}`}
					/>
				);

			case FORM_CONTROLS.SIGNATURE:
				return (
					<Signature
						disclaimer={disclaimer}
						title={title}
						disabled={disabled}
						mandatory={mandatory}
						id={key}
					/>
				);

			case FORM_CONTROLS.PICTURE:
				return <ImageUploader title={title} mandatory={mandatory} id={key} />;

			case FORM_CONTROLS.LABEL:
				return (
					<p key={key} className={classNames('label-control', formItemClassName)}>
						{label}
					</p>
				);
			case FORM_CONTROLS.OPEN_APP:
				return (
					<Button key={key} className={classNames('open-app-control', formItemClassName)} id={key}>
						<a href={url}>{title}</a>
					</Button>
				);

			case FORM_CONTROLS.TIME:
				return <TimePicker key={key} format={timeFormat} use12Hours={use12Hours} disabled={disabled} />;
			case FORM_CONTROLS.DATE:
				return <DatePicker key={key} disabled={disabled} />;
			case FORM_CONTROLS.USER_SELECTOR:
				return (
					<AsyncUsersSelector
						key={key}
						mode={field.mode || UserSelectorMode.multiple}
						data-test-id={`users-selector-${key}`}
						isDisabled={disabled}
						defaultUsersIds={[]}
						onChange={() => undefined}
						baseSearch={{
							roles: ['driver'],
							includeAllAdmins: false
						}}
					/>
				);
			case FORM_CONTROLS.DATETIME:
				return (
					<DatePicker
						key={key}
						showTime={{ use12Hours }}
						format={timeFormat}
						disabled={disabled}
						data-test-id={'bringg-datetime-picker'}
					/>
				);
			default:
				return <BringgInput placeholder={placeholder} />;
		}
	};

	getTypeString = (type: StringType) => {
		switch (type) {
			case StringType.number:
				return 'number';
			case StringType.string:
				return 'string';
			case StringType.boolean:
				return 'boolean';
			default:
				return 'string';
		}
	};

	generateSelectOptions = (options: string[], dropDownKey: string) =>
		options.map(option => ({ id: option, name: option, dropDownKey }));

	getInputComponent<Type extends number>(
		type: Type,
		props?: React.ComponentProps<Type extends 0 ? typeof InputNumber : typeof BringgInput>
	) {
		return type === 0 ? (
			// type="number" is not documented, https://github.com/ant-design/ant-design/issues/9421
			// @ts-ignore
			<InputNumber type="number" {...props} />
		) : (
			<BringgInput {...props} placeholder={props?.placeholder || ''} />
		);
	}

	getRules = (field: FormFields) => {
		const { type, mandatory, regex } = field;
		let rules: { required?: boolean; message: string; pattern?: RegExp }[] = [];

		if (_isNumber(type)) {
			rules = [...rules, validateType(this.getTypeString(type))];
		}
		if (mandatory) {
			rules = [...rules, requiredRule];
		}
		if (regex) {
			rules = [...rules, regexRule(regex)];
		}

		return rules;
	};

	createFormChildren = (field: FormFields) => {
		const { getFieldControl, getRules } = this;
		const { key, label, formItemClassName, valuePropName, tooltip, mandatory, control } = field;
		const isBoolean = control === FORM_CONTROLS.CHECKBOX;

		const rules = getRules(field);

		return (
			<FormChildren
				formItemsProps={{
					name: key,
					label: label,
					rules,
					initialValue: field.default_value != null ? field.default_value : field.initialValue,
					className: classnames(formItemClassName, { 'boolean-field': isBoolean }),
					valuePropName,
					tooltip,
					required: mandatory
				}}
				key={key}
				id={`${key}`}
			>
				{getFieldControl(field)}
			</FormChildren>
		);
	};

	getField = (field: FormFields) => {
		if (NOT_FORM_CHILDREN_CONTROLS.includes(field.control)) {
			return this.getFieldControl(field);
		}

		return this.createFormChildren(field);
	};

	render() {
		const { getField } = this;
		const {
			fields,
			title,
			submitButton,
			cancelButton,
			numberOfColumns,
			layout,
			resetFields,
			onSubmit,
			initialValues,
			formInstance
		} = this.props;

		return (
			<div className="dynamic-form">
				{title ? (
					<div>
						<h2>{title}</h2>
					</div>
				) : null}

				<Form
					numberOfColumns={numberOfColumns}
					onSubmit={onSubmit}
					submitButton={submitButton}
					cancelButton={cancelButton}
					layout={layout}
					resetOnErr={resetFields}
					initialValues={initialValues}
					form={formInstance}
				>
					{fields.map(getField)}
				</Form>
			</div>
		);
	}
}

export default DynamicForm;
