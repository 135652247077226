import moment, { Moment } from 'moment-timezone';

class TimezoneService {
	static getDate = (timezone: string, date?: Date | Moment | string): Moment => {
		return date ? moment.tz(date, timezone) : moment.tz(timezone);
	};

	static getMomentTimezone =
		(timezone?: string) =>
		(date?: Moment | Date | string): Moment => {
			if (timezone) {
				return TimezoneService.getDate(timezone, date);
			}
			return date ? moment(date) : moment();
		};

	static getDateInTargetTimezone = (date: Date | Moment | string, timezone: string) => {
		const browserOffset = TimezoneService.getBrowserUtcOffset();
		let dateInTimezone = moment(date);
		if (timezone) {
			dateInTimezone = moment(date).tz(timezone);
		}

		// adding moment's offsets takes you back to UTC, so we add the utcOffset
		// of the input and subtract the offset of the browser to render the time
		// for the browser.
		const isDST = moment(date).isDST();
		const nowDST = moment().isDST();

		let offset = dateInTimezone.utcOffset() - browserOffset;

		if (isDST && !nowDST && timezone) {
			offset = offset - TimezoneService.getDST(timezone);
		}

		dateInTimezone.add(offset, 'minutes');
		return dateInTimezone.toDate();
	};

	static getDateAsUtc = (date: Date | Moment | string, timezone: string) => {
		const browserOffset = TimezoneService.getBrowserUtcOffset();
		return moment(date).subtract(moment.tz(timezone).utcOffset() - browserOffset, 'minutes');
	};

	static getBrowserUtcOffset = () => -1 * new Date().getTimezoneOffset(); // the sign of getTimezoneOffset is opposite of moment

	static getDST = timezone => {
		const janOffset = moment.tz({ month: 0, day: 1 }, timezone).utcOffset();
		const junOffset = moment.tz({ month: 5, day: 1 }, timezone).utcOffset();
		return Math.abs(junOffset - janOffset);
	};

	static getTimezoneDateAsUTC = (date: Date | Moment | string, timezone: string): string => {
		return moment.utc(date).tz(timezone).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
	};
}

export default TimezoneService;
