import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import throttle from 'lodash/throttle';
import map from 'lodash/map';
import moment from 'moment';
import { Divider } from 'antd';
import ChatMessage from '../chat-message/chat-message';
import { Spinner } from '../../../components';
import ChatStore from '../stores/chat-store';
import ChatView from '../stores/chat-view';

interface Props {
	chatStore?: ChatStore;
	chatView?: ChatView;
}

class ChatWindowBody extends Component<Props, {}> {
	// can be extracted to scroll component later
	onScroll = () => {
		const currentChat = this.props.chatStore.getCurrentChat;
		const elem = this.props.chatView.chat_ref;
		const bottom = elem.scrollHeight - elem.scrollTop - 800 <= elem.clientHeight;
		const top = elem.scrollTop < 80;

		if (top && currentChat.hasMoreOldMessages) {
			currentChat.fetchOlderMessages().catch(error => {
				console.error('failed to fetch older messages', error);
			});
		}
		if (bottom && currentChat.hasMoreNewMessages) {
			currentChat.fetchNewerMessages().catch(error => {
				console.error('failed to fetch newer messages', error);
			});
		}
	};

	render() {
		const { chatStore, chatView } = this.props;
		const currentChat = chatStore.getCurrentChat;
		const isMessagesReady = currentChat.messagesByDays && currentChat.isFetched;

		return (
			<div
				className="chat-window-body"
				onScroll={throttle(this.onScroll, 200, { trailing: true })}
				ref={chatView.setRef}
			>
				{chatView.isFetching ? <Spinner data-test-id="spinner" /> : null}
				{isMessagesReady ? (
					<div className="messages">
						{map(currentChat.messagesByDays, (messages, date) => {
							const groupDate =
								moment(date).format('DD') === moment().format('DD')
									? 'Today'
									: moment(date).format('DD-MM-YYYY');

							return (
								<div className={`${currentChat.id}-${date}`} key={date}>
									<Divider className="date-divider">{groupDate}</Divider>
									{messages.map(message => (
										<ChatMessage key={message.id} message={message} />
									))}
								</div>
							);
						})}
					</div>
				) : (
					<Spinner data-test-id="spinner" />
				)}
			</div>
		);
	}
}

export default inject('chatStore', 'chatView')(observer(ChatWindowBody));
