import React from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from '@bringg/react-components';

interface Props {
	visible: boolean;
	onApply: () => void;
	onCancel: () => void;
}

export const DoubleBookingModal = ({ visible, onApply, onCancel }: Props) => {
	const { t } = useTranslation();

	return (
		<Modal
			title={t('DELIVERY_BLOCKS.SOME_RESOURCES_ARE_NOT_AVAILABLE_TITLE')}
			visible={visible}
			onCancel={onCancel}
			onOk={onApply}
			okText={t('GLOBAL.PROCEED')}
			style={{ marginTop: 200 }}
		>
			<span>{t('DELIVERY_BLOCKS.DRIVER_OR_VEHICLE_NOT_AVAILABLE_MESSAGE')}</span>
		</Modal>
	);
};
