import React, { useCallback, useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useObserver } from 'mobx-react-lite';
import { Button } from '@bringg/react-components';
import { useForm } from 'react-hook-form';
import { MerchantConfigurationCSVField, ParkingSpot, PrivilegeTypes, Vehicle } from '@bringg/types';
import { VehicleGroup } from '@bringg/dashboard-sdk/dist/Vehicle/Vehicle.consts';
import { withErrorBoundary } from '@bringg-frontend/bringg-web-infra';

import { useHasAccess } from 'bringg-web/utils/privileges';
import VehicleModal, { VehicleForm } from './vehicle-modal/vehicle-modal';
import VehiclesCSVUploader from './vehicle-csv-upload/vehicle-csv-upload';
import VehiclesTable from '../../vehicles/vehicles-table/vehicles-table';
import useStores from '../../../recipes/use-stores';
import bringgNotification from '../../../services/notification';
import * as parkingSpotsProvider from '../../../services/parking-spots-provider';
import * as trailersProvider from '../../../stores/vehicles/trailers-provider';

interface Props {
	teamId: number;
}

const getDeleteText = (t, selectedVehiclesNumber) => {
	if (selectedVehiclesNumber === 0) {
		return t('GLOBAL.DELETE');
	}

	return `${t('GLOBAL.DELETE')} (${selectedVehiclesNumber})`;
};

const VehicleFormDefaultValues = {
	name: '',
	externalId: '',
	licensePlate: '',
	model: '',
	color: '',
	skills: []
};

const getCreateVehicleBaseObject = (teamId: number, formValues) => {
	return {
		name: formValues.name,
		external_id: formValues.externalId,
		license_plate: formValues.licensePlate,
		model: formValues.model,
		vehicle_type_id: formValues.vehicleTypeId,
		default_parking_spot_id: formValues.defaultParkingSpotId,
		color: formValues.color,
		skills: formValues.skills,
		team_id: teamId
	} as Partial<Vehicle>;
};

const TeamVehicles: React.FC<Props> = ({ teamId }) => {
	const { vehiclesStore, vehicleTypesStore, skillsStore, usersStore } = useStores();
	const { t } = useTranslation();
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [teamParkingSpots, setTeamParkingSpots] = useState<ParkingSpot[]>([]);
	const [isDeleting, setIsDeleting] = useState<boolean>(false);
	const [selectedVehiclesIds, setSelectedVehiclesIds] = useState<number[]>([]);
	const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
	const [isImportCsvModalVisible, setIsImportCsvModalVisible] = useState<boolean>(false);
	const [csvStructure, setCsvStructure] = useState<MerchantConfigurationCSVField[]>();
	const form = useForm<VehicleForm>({ mode: 'onChange', defaultValues: VehicleFormDefaultValues });

	const createIsAllowedForDispatcher = useHasAccess(PrivilegeTypes.ALLOW_DISPATCHER_CREATE_VEHICLE);

	useEffect(() => {
		let mount = true;

		const fetchVehicles = async () => {
			await vehiclesStore.loadAllByTeam(teamId, { useCache: false });

			if (mount) {
				setIsLoading(false);
			}
		};

		fetchVehicles();
		parkingSpotsProvider.getDriverParkingSpots(teamId).then(setTeamParkingSpots);
		vehicleTypesStore.fetchAll();
		skillsStore.fetchAll();
		vehiclesStore.getCsvUploadFields().then(setCsvStructure);

		return () => {
			mount = false;
		};
	}, [teamId, vehiclesStore, vehicleTypesStore, skillsStore]);

	const onSelectedChange = useCallback(selectedRowsKeys => {
		setSelectedVehiclesIds(selectedRowsKeys);
	}, []);

	const handleDeleteClick = useCallback(async () => {
		setIsDeleting(true);

		try {
			await vehiclesStore.deleteMany(selectedVehiclesIds);
			bringgNotification.success(t('TEAM_VEHICLES.DELETE_SUCCEED'));
			setSelectedVehiclesIds([]);
		} catch (e) {
			bringgNotification.error(t('TEAM_VEHICLES.DELETE_FAILED'));
		} finally {
			setIsDeleting(false);
		}
	}, [selectedVehiclesIds, vehiclesStore, t]);

	const handleModalClose = useCallback(() => {
		setIsModalVisible(false);
		form.reset(VehicleFormDefaultValues);
	}, [form]);

	const handleModalSubmit = useCallback(async () => {
		const formValues = form.getValues();

		const vehicleRequest = getCreateVehicleBaseObject(teamId, formValues);

		if (formValues.truckId) {
			vehicleRequest.truck_id = Number(formValues.truckId);
		}

		try {
			const newVehicle = await vehiclesStore.create(vehicleRequest);

			if (formValues.trailerId) {
				await vehiclesStore.update(Number(formValues.trailerId), { truck_id: newVehicle.id });
			}

			bringgNotification.success(t('TEAM_VEHICLES.SUCCESSFULLY_SAVED'));
			handleModalClose();
		} catch (e) {
			bringgNotification.error(t('TEAM_VEHICLES.FAILED_SAVING'));
		}
	}, [form, vehiclesStore, t, teamId, handleModalClose]);

	const handleAddClick = useCallback(() => {
		setIsModalVisible(true);
	}, []);

	const toggleImportVehiclesCsvVisibility = useCallback(() => {
		setIsImportCsvModalVisible(prev => !prev);
	}, []);

	return useObserver(() => (
		<div className="table-container team-vehicles" data-test-id="teamVehicleTableContainer">
			{usersStore.currentUser.admin || createIsAllowedForDispatcher ? (
				<>
					<div className="table-actions">
						<Button
							danger
							type="primary"
							className="delete-btn"
							loading={isDeleting}
							onClick={handleDeleteClick}
							disabled={selectedVehiclesIds.length === 0}
						>
							{getDeleteText(t, selectedVehiclesIds.length)}
						</Button>
						<Button data-test-id="add-vehicle-btn" type="primary" onClick={handleAddClick}>
							{t('TEAM_VEHICLES.ADD_VEHICLE')}
						</Button>
						<Button
							data-test-id="import-vehicles-csv-btn"
							type="primary"
							onClick={toggleImportVehiclesCsvVisibility}
						>
							{t('TEAM_VEHICLES.IMPORT_VEHICLES_CSV')}
						</Button>
					</div>
					{isModalVisible && (
						<VehicleModal
							onCancel={handleModalClose}
							onSubmit={handleModalSubmit}
							form={form}
							vehicleTypes={vehicleTypesStore.all}
							parkingSpots={teamParkingSpots}
							skills={skillsStore.all}
							trucks={trailersProvider.allNonTrailersByTeam(teamId)}
							trailers={trailersProvider.allTrailersByTeam(teamId)}
						/>
					)}
					{isImportCsvModalVisible && csvStructure && (
						<VehiclesCSVUploader
							afterModalClose={toggleImportVehiclesCsvVisibility}
							vehiclesCsvFields={csvStructure}
						/>
					)}
				</>
			) : null}
			<VehiclesTable
				isLoading={isLoading}
				vehicles={vehiclesStore.getGroup(VehicleGroup.Team, teamId)}
				onSelectedChange={onSelectedChange}
				selectedKeys={selectedVehiclesIds}
				teamParkingSpots={teamParkingSpots}
			/>
		</div>
	));
};

export default withErrorBoundary(TeamVehicles);
