import { getEnv } from 'mobx-easy';
import { action, computed, observable, makeObservable, toJS } from 'mobx';
import { Currency, DashboardUiConfiguration, DynamicCapacityValuesOptions, HourFormat } from '@bringg/types';
import type {
	Application,
	datetime,
	AlertConfigurations,
	CustomUnitsDefinition,
	ResizableDataField,
	ApplicationMerchantConfigurations,
	AddressFormat,
	GrabMode
} from '@bringg/types';

import { RootEnv } from '../../root-env';

const getLanguageCode = (countryCode: string): string => {
	let languageCode = countryCode || 'en';

	switch (countryCode) {
		case 'ao':
		case 'cv':
		case 'gw':
		case 'mz':
		case 'st':
		case 'tl':
			languageCode = 'pt';
			break;
		case 'ae':
		case 'bh':
		case 'dz':
		case 'eg':
		case 'eh':
		case 'iq':
		case 'jo':
		case 'km':
		case 'kw':
		case 'lb':
		case 'ly':
		case 'ma':
		case 'mr':
		case 'om':
		case 'ps':
		case 'qa':
		case 'sa':
		case 'sd':
		case 'sy':
		case 'tn':
		case 'ye':
			languageCode = 'ar';
			break;
		case 'bf':
		case 'bi':
		case 'bj':
		case 'bl':
		case 'cd':
		case 'cf':
		case 'cg':
		case 'ci':
		case 'cm':
		case 'dj':
		case 'ga':
		case 'gf':
		case 'gn':
		case 'gp':
		case 'ht':
		case 'mc':
		case 'mf':
		case 'ml':
		case 'mq':
		case 'nc':
		case 'ne':
		case 'pf':
		case 'pm':
		case 're':
		case 'sc':
		case 'sn':
		case 'td':
		case 'tf':
		case 'tg':
		case 'wf':
		case 'yt':
			languageCode = 'fr';
			break;
		case 'md':
			languageCode = 'ru';
			break;
		case 'us':
			languageCode = 'en';
			break;
		case 'br':
			languageCode = 'pt-br';
			break;
		case 'il':
			languageCode = 'he';
			break;
		case 'bg':
			languageCode = 'bg';
			break;
		case 'dk':
			languageCode = 'da';
			break;
		case 'vn':
			languageCode = 'vi-vn';
			break;
		case 'id':
			languageCode = 'id';
			break;
		case 'ar':
		case 'cl':
		case 'es':
		case 'co':
		case 'mx':
		case 'pe':
		case 'ec':
		case 'uy':
		case 'cr':
		case 'pa':
		case 'pr':
			languageCode = 'es';
			break;
		case 'fi':
			languageCode = 'fi';
			break;
		case 'nz':
			languageCode = 'en-au';
			break;
		case 'gb':
		case 'au':
		case 'ca':
			languageCode = 'en-' + countryCode;
			break;
		case 'gr':
			languageCode = 'el';
			break;
		case 'ch':
		case 'at':
			languageCode = 'de';
			break;
	}

	return languageCode;
};

export default class MerchantConfiguration {
	id: number;
	merchant_id: number;
	enable_bi_dashboard: boolean;
	language_code = 'en';
	name = '';
	created_at?: datetime;
	updated_at?: datetime;
	end_of_week_day = 2;
	lat?: number;
	lng?: number;
	address_format?: AddressFormat;
	grab_mode: GrabMode = null;
	enable_free_sms_mode?: boolean;
	enable_task_price?: boolean;
	enable_vehicles?: boolean;
	enable_fleets?: boolean;
	enable_chat_v2?: boolean;
	allow_dispatcher_to_access_task_price?: boolean;
	localize_task_timezones_by_teams?: boolean;
	enable_planned_routes?: boolean;
	delivery_pin: string;
	open_tasks_filter: Bringg.MerchantConfiguration['open_tasks_filter'];
	allow_offshift_drivers_to_be_assigned: boolean;
	enable_skills: boolean;
	enable_dispatcher_edit_task_inventory_quantity: boolean;
	enable_dimension_editing_in_ui: boolean;
	allow_start_shift_on_all_teams: boolean;
	allow_multi_day_optimization: boolean;
	allow_moving_tasks_to_planning_phase: boolean;
	planning_phase_exists: boolean;
	country_code: string;
	dashboard_ui_configuration: DashboardUiConfiguration;
	merchant_dashboard_ui_configuration: DashboardUiConfiguration;
	dispatch_filter_limit: number;
	alert_configurations: AlertConfigurations;
	custom_units_definition?: CustomUnitsDefinition;
	task_alert_fields?: ResizableDataField[];
	alerts_types?: { [key: string]: number };
	hour_format: HourFormat = HourFormat.Default;
	application_merchant_configurations: ApplicationMerchantConfigurations[];
	applications: Application[] = [];
	sharing_mode = 0;
	allow_rating: boolean;
	secured_setup: boolean;
	enable_teams: boolean;
	package_inventory_when_grouping_orders: boolean;
	customer_verification_pin_code_flow: boolean;
	price_currency: Currency;
	ignore_inventory_quantity_for_capacity_calculation: boolean;
	max_distance_for_optimization_in_meters: number;
	dynamic_capacity_values_mode: DynamicCapacityValuesOptions;
	initial_loading_time_minutes: number;
	initial_unloading_time_minutes: number;
	enable_tender_notify: boolean;
	enable_secured_user_passwords: boolean;
	unique_inventory_fields: string[];
	allow_dispatcher_to_access_all_teams: boolean;
	cx_calendar_view_for_delivery_options: boolean;
	enable_support_ticket_on_web: boolean;
	enable_user_types: boolean;
	tasks_page_size: number;
	initial_tasks_page_size: number;
	delivery_hub_main_menu_ui: boolean;

	constructor(config: Partial<Bringg.MerchantConfiguration>) {
		makeObservable(this, {
			alert_configurations: observable,
			hour_format: observable,
			applications: observable,
			sharing_mode: observable,
			allow_rating: observable,
			cx_calendar_view_for_delivery_options: observable,
			updateSharingMode: action,
			addApplication: action,
			set: action,
			setCxCalendarViewForDeliveryOptions: action,
			sharingMode: computed
		});

		Object.assign(this, config, {
			language_code: config.language_code || getLanguageCode(config.country_code)
		});
	}

	updateSharingMode = (mode: number) => {
		this.sharing_mode = mode;
	};

	addApplication = (application: Application) => {
		this.applications = [...this.applications, application];
	};

	set = (merchantConfig: Partial<Bringg.MerchantConfiguration>) => {
		Object.assign(this, merchantConfig);
	};

	get sharingMode(): number {
		return this.sharing_mode;
	}

	async update(): Promise<Bringg.MerchantConfiguration> {
		// Using toJS as we don't want to send the mobx object
		// eslint-disable-next-line no-return-await
		return await getEnv<RootEnv>().dashboardSdk.sdk.merchantConfiguration.update(toJS(this));
	}

	setCxCalendarViewForDeliveryOptions(value: boolean) {
		this.cx_calendar_view_for_delivery_options = value;
	}
}
