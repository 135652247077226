import { useEffect } from 'react';

import { ExtendedMarkerData, ExtendedPolylineData } from '../../types';
import { isLatLngValid } from '../../utils';
import { addLocationToBounds, fitMapBound, panMap } from '../utils';

export const useBoundsHandler = (
	map: google.maps.Map | null,
	markers?: ExtendedMarkerData[],
	polylines?: ExtendedPolylineData[],
	shouldAutoFocusMap?: boolean,
	padding?: number
) => {
	useEffect(() => {
		if (!map) return;
		const newMapBounds = new google.maps.LatLngBounds();
		const focusedMarker = markers?.find(m => m?.shouldAutoFocus && isLatLngValid(m?.location));
		if (focusedMarker) {
			newMapBounds.extend(new google.maps.LatLng(focusedMarker.location));
		} else {
			markers?.forEach(
				m =>
					m?.shouldIncludeInBounds &&
					isLatLngValid(m?.location) &&
					addLocationToBounds(m.location, newMapBounds)
			);
			polylines?.forEach(
				polyline =>
					polyline?.shouldIncludeInBounds &&
					polyline.path?.filter(
						location => isLatLngValid(location) && addLocationToBounds(location, newMapBounds)
					)
			);
		}

		if (shouldAutoFocusMap) {
			fitMapBound(map, newMapBounds, padding);
			panMap(map, newMapBounds.getCenter());
		}
	}, [map, markers, polylines, shouldAutoFocusMap]);
};
