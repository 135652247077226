import React from 'react';
import { inject, observer } from 'mobx-react';
import i18next from 'i18next';
import noop from 'lodash/noop';
import { BringgFontIcons, BringgIcon } from '@bringg/bringg-icons';
import { BringgInput, Row, Col, Tooltip } from '@bringg/react-components';
import { useHasFeatureFlag } from 'bringg-web/utils/feature-flags';
import { Button } from '../../../components';
import { Translate } from '../../../translation';
import ActionsConfigurationsStore from '../../../stores/actions-configuration/actions-configuration-store';

interface Props {
	actionsConfigurationsStore?: ActionsConfigurationsStore;
	onAdd: () => void;
}

const ActionsConfigurationsHeader = ({ actionsConfigurationsStore, onAdd }: Props) => {
	const editAllowed = useHasFeatureFlag('enabled_fixed_driver_actions_config');

	const setFilterText = (filterText: string) => {
		actionsConfigurationsStore?.setFilterText(filterText);
	};

	return (
		<Row className="actions-configurations-header" justify="space-between">
			<Col>
				{/* TODO please uncomment code below together with removing Tooltip after BRNGG-3720 is done */}
				<Tooltip
					title={
						editAllowed
							? ''
							: 'Sorry, this functionality is temporary disabled. Please contact your CSM for further support.'
					}
				>
					{/* Somehow Tooltip doesn't appear over a disabled button so I have to wrap it in additional <span> */}
					<span>
						<Button
							disabled={!editAllowed}
							className="add-actions-configuration"
							onClick={editAllowed ? onAdd : noop}
						>
							<Translate text="ACTION_CONFIGURATION.ADD" />
						</Button>
					</span>
				</Tooltip>
			</Col>

			<Col>
				<BringgInput
					className="action-search-input"
					placeholder={i18next.t('GLOBAL.SEARCH')}
					type="text"
					onChange={e => setFilterText(e.target.value)}
					value={actionsConfigurationsStore.filterText}
					prefix={<BringgIcon iconName={BringgFontIcons.Search} />}
				/>
			</Col>
		</Row>
	);
};

export default inject('actionsConfigurationsStore')(observer(ActionsConfigurationsHeader));
