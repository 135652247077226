import _ from 'lodash';
import { TaskDescriptions } from './task-description';

export const tagColor = {
	mandatory: 'danger',
	optional: 'warning',
	avalible: 'success'
};
export function transformToDataFields({ order, validationRules, mappingRules }) {
	const table = {
		mandatory: {
			missingFields: [],
			existingFields: []
		},
		optional: {
			missingFields: [],
			existingFields: []
		},
		orderId: null
	};

	if (!order) {
		return table;
	}

	const requieredFields = {};

	for (const [firstKey, firstValue] of Object.entries(validationRules)) {
		// @ts-ignore
		for (const [secondKey, secondValue] of Object.entries(firstValue.nested_object)) {
			// @ts-ignore
			if (_.has(secondValue, 'nested_object')) {
				// @ts-ignore
				for (const [thirdKey, thirdValue] of Object.entries(secondValue.nested_object)) {
					// @ts-ignore
					if (thirdValue.includes('required')) {
						const bringgFieldPath = getBringgKeyPath([firstKey, secondKey, thirdKey]);
						_.set(requieredFields, bringgFieldPath, true);
					}
				}
				// @ts-ignore
			} else if (secondValue.includes('required')) {
				const bringgFieldPath = getBringgKeyPath([firstKey, secondKey]);
				_.set(requieredFields, bringgFieldPath, true);
			}
		}
	}

	let index = 0;

	mappingRulesForEach(mappingRules);
	function mappingRulesForEach(mappingRules, from = '') {
		mappingRules.forEach(rule => {
			if (_.isNil(rule.from) || !_.isNil(rule.function)) return;

			if (rule.arrayRules) {
				mappingRulesForEach(rule.arrayRules, rule.from);
				return;
			}

			rule.from = _.compact([from, rule.from]).join('.');

			const bringgPath = getBringgKeyPath(rule.from.split('.'));
			const mandatoryOrOptional = _.get(requieredFields, bringgPath) ? 'mandatory' : 'optional';

			const field = {
				key: index,
				bringgField: rule.from,
				bringgFieldDescription: _.get(TaskDescriptions, `${bringgPath}.desc`) || '—',
				bringgFallBacks: bringgFieldFallBack(rule.fallback, from),
				dataAvailability: undefined
			};

			if (_.has(order, bringgPath) || checkFallBack(order, rule.fallback)) {
				field.dataAvailability = tagColor.avalible;
				table[mandatoryOrOptional].existingFields.push(field);
			} else {
				field.dataAvailability = tagColor[mandatoryOrOptional];
				table[mandatoryOrOptional].missingFields.push(field);
			}

			index += 1;
		});
	}

	table.orderId = order.id;
	return table;
}
function checkFallBack(order, fallback) {
	if (_.isNil(fallback)) return false;

	const bringgFallBackPaths = fallback.map(path => getBringgKeyPath(path.split('.')));
	return bringgFallBackPaths.some(path => _.has(order, path));
}
function bringgFieldFallBack(fallback, from) {
	if (_.isNil(fallback)) return '—';

	return fallback
		.map((val, index) => {
			return `${(index + 1).toString()}. ${_.compact([from, val]).join('.')}\n`;
		})
		.join('');
}
function getBringgKeyPath(key) {
	if (key.includes('task')) {
		return key.slice(1).join('.');
	}
	if (key.includes('pickup')) {
		return ['way_points', 0, ...key.slice(1)].join('.');
	}

	if (key.includes('dropoff')) {
		return ['way_points', 1, ...key.slice(1)].join('.');
	}

	if (key.includes('inventories')) {
		return ['task_inventories', 0, ...key.slice(1)].join('.');
	}

	return key.join('.');
}
