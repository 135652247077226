import React, { useRef, useState, useEffect } from 'react';
import TooltipWrapper from '@bringg/react-components/dist/components/tooltip/tooltip';
import { Driver, Vehicle } from '@bringg/types';

interface DayViewTableRowContentProps {
	driver?: Driver;
	vehicle?: Vehicle;
}

const DayViewTableRowContent = ({ driver, vehicle }: DayViewTableRowContentProps): JSX.Element => {
	const ref = useRef(null);
	const [elementWidth, setElementWidth] = useState(0);
	const nameString = `${vehicle?.name || ''} ${driver?.name || ''}`.trim();
	const widthLimit = 130;

	useEffect(() => {
		setElementWidth(ref.current?.offsetWidth || 0);
	}, [ref]);

	return (
		<TooltipWrapper title={elementWidth > widthLimit ? nameString : ''}>
			<span data-test-id="resource-name" ref={ref}>
				{nameString}
			</span>
		</TooltipWrapper>
	);
};

export default DayViewTableRowContent;
