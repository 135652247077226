import { getRootEnv } from '@bringg-frontend/bringg-web-infra';
import { action, computed, IObservableArray, observable, makeObservable, toJS } from 'mobx';
import { OauthApplication } from '@bringg/dashboard-sdk/dist/OauthApplications/OauthApplicaitions.consts';
import _forEach from 'lodash/forEach';

export default class OAuthApp {
	id: number = null;
	name = '';
	enabled: boolean = null;
	scopes: IObservableArray<string> = [] as IObservableArray<string>;
	uid: string = null;
	secret: string = null;

	constructor(oauthApplication?: OauthApplication) {
		makeObservable(this, {
			name: observable,
			enabled: observable,
			scopes: observable.shallow,
			uid: observable,
			secret: observable,
			selectedEndpoints: computed,
			setSelectedEndpoints: action,
			getName: computed,
			setName: action,
			isEnabled: computed,
			clientId: computed,
			clientSecret: computed,
			save: action,
			enableApp: action,
			disableApp: action,
			delete: action,
			generateNewSecret: action
		});

		Object.assign(this, oauthApplication || {});
	}

	get selectedEndpoints(): string[] {
		return toJS(this.scopes);
	}

	setSelectedEndpoints(selectedEndpoints: string[]): void {
		this.scopes.clear();
		_forEach(selectedEndpoints, endpoint => this.scopes.push(endpoint));
	}

	get getName(): string {
		return this.name;
	}

	setName(name: string): void {
		this.name = name;
	}

	get isEnabled(): boolean {
		return this.enabled;
	}

	get clientId(): string {
		return this.uid;
	}

	get clientSecret(): string {
		return this.secret;
	}

	async save(): Promise<void> {
		const { dashboardSdk } = getRootEnv();
		const updatedApp = await dashboardSdk.sdk.oauthApplication.create({ scopes: this.scopes, name: this.name });
		Object.assign(this, updatedApp);
	}

	async enableApp(): Promise<void> {
		const { dashboardSdk } = getRootEnv();
		const updatedApp = await dashboardSdk.sdk.oauthApplication.enable(this.id);
		Object.assign(this, updatedApp);
	}

	async disableApp(): Promise<void> {
		const { dashboardSdk } = getRootEnv();
		const updatedApp = await dashboardSdk.sdk.oauthApplication.disable(this.id);
		Object.assign(this, updatedApp);
	}

	async delete(): Promise<void> {
		const { dashboardSdk } = getRootEnv();
		await dashboardSdk.sdk.oauthApplication.destroy(this.id);
	}

	async generateNewSecret(): Promise<void> {
		const { dashboardSdk } = getRootEnv();
		const updatedApp = await dashboardSdk.sdk.oauthApplication.renew(this.id);
		Object.assign(this, updatedApp);
	}
}
