import React from 'react';

import { useTranslation } from 'react-i18next';

import emptyStateImage from './empty-state.svg';

import styles from './empty-state.module.scss';

interface Props {
	title: string;
	description: string;
}

const EmptyState = ({ title, description }: Props) => {
	const { t } = useTranslation();

	return (
		<div data-test-id="empty-state" className={styles.emptyStateContainer}>
			<img className={styles.emptyStateImage} src={emptyStateImage} />
			<span className={styles.emptyStateTitle}>{t(title)}</span>
			<span className={styles.emptyStateText}>{t(description)}</span>
		</div>
	);
};

export default EmptyState;
