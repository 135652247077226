import { User } from '@bringg/types';

export class LogRocketService {
	static async initLogRocket(appId: string, user: User) {
		try {
			// @ts-ignore
			const LogRocket = window['_LRLogger'];

			if (LogRocket) {
				this.innerLoad(appId, user, LogRocket);
			} else {
				const LogRocketModule = await import('logrocket');
				this.innerLoad(appId, user, LogRocketModule.default);
			}
		} catch (error) {
			console.warn('Failed to init logRocket', error);
		}
	}

	private static innerLoad(appId: string, user: User, LogRocket: any) {
		LogRocket.init(appId);

		const { name, email, id, merchant_id } = user;

		LogRocket.identify(id.toString(), {
			name,
			email,
			merchant_id
		});
	}
}
