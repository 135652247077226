import { DataNode } from 'antd/lib/tree';
import _ from 'lodash';
import { ModelConfig } from '@bringg/types';
import { webhooksApiRootStore } from '@bringg-frontend/webhooks-api-internal-stores';

import styles from './webhook-model-tree.module.scss';

export type DataNodeWithAriaAndData = DataNode & Record<`${'aria' | 'data'}-${string}`, any> & { role?: string };

let cache: {
	modelName: string;
	tree: DataNodeWithAriaAndData[];
	defaultFields: string[];
} = {
	modelName: '',
	tree: [],
	defaultFields: []
};

const buildModelTree = (
	modelName: string,
	hasInitialValue: boolean,
	modelConfig?: ModelConfig
): DataNodeWithAriaAndData[] => {
	return buildModelTreeInner(modelName, hasInitialValue, modelConfig, new Set([modelName]), [], true, modelName);
};

const buildModelTreeInner = (
	modelName: string,
	hasInitialValue: boolean,
	modelConfig: ModelConfig,
	parents: Set<string>,
	defaultFields: string[],
	isDefaultRelation,
	path: string
): DataNodeWithAriaAndData[] => {
	if (modelName === cache.modelName) {
		return cache.tree;
	}

	const tree: DataNodeWithAriaAndData[] = [];

	const { webhooksAppStore } = webhooksApiRootStore.getStore();
	const model = webhooksAppStore.webhookModels.find(({ name }) => name === modelName);

	if (!model) {
		return tree;
	}

	model.allowedFields.forEach(field => {
		const key = _.uniqueId();
		tree.push({
			title: field,
			key,
			role: 'treeitem',
			'aria-label': `${path} > ${field}`
		});

		const isChecked = hasInitialValue
			? (modelConfig?.fields || []).includes(field)
			: isDefaultRelation && model.defaultFields.includes(field);

		if (isChecked) {
			defaultFields.push(key);
		}
	});

	model.allowedRelations.forEach(relation => {
		const relationName = typeof relation === 'string' ? relation : relation.name;
		const relationIdentifier = typeof relation === 'string' ? relation : relation.identifier;
		const hasRelationCycle = parents.has(relationName);
		const isRelationDefault = model.defaultRelations.includes(relationIdentifier);
		const relationPath = `${path} > ${relationIdentifier}`;
		if (!hasRelationCycle) {
			const relationTree = buildModelTreeInner(
				relationName,
				hasInitialValue,
				(modelConfig?.relations || []).find(r => r.name === relationName),
				new Set([...parents, relationName]),
				defaultFields,
				isRelationDefault,
				relationPath
			);

			if (relationTree.length) {
				tree.push({
					title: relationIdentifier,
					key: _.uniqueId(),
					className: styles.disableMultiSelect,
					children: relationTree,
					role: 'treeitem',
					'aria-label': relationPath
				});
			}
		}
	});

	if (parents.size === 1) {
		cache = {
			tree,
			modelName,
			defaultFields
		};
	}

	return tree;
};

const buildModelConfigRecursive = (name, tree: DataNodeWithAriaAndData[], selectedFields: string[]): ModelConfig => {
	const fields: string[] = [];
	const relations: ModelConfig[] = [];
	const keySet: Set<string> = new Set(selectedFields);

	tree?.forEach(treeNode => {
		if (keySet.has(treeNode.key as string) && !treeNode.children) {
			fields.push(`${treeNode.title}`);
		} else if (treeNode.children) {
			const childTree = buildModelConfigRecursive(
				treeNode.title,
				treeNode.children as DataNodeWithAriaAndData[],
				selectedFields
			);
			if (childTree.fields?.length || childTree.relations?.length) {
				relations.push(childTree);
			}
		}
	});

	return {
		name,
		fields,
		relations
	};
};

const buildModelConfigFromTree = (name, selectedFields: string[]): ModelConfig => {
	cache.defaultFields = selectedFields;
	return buildModelConfigRecursive(name, cache.tree, selectedFields);
};

const loadAll = async () => {
	const { webhooksAppStore } = webhooksApiRootStore.getStore();
	await webhooksAppStore.fetchConfiguration();
};

const reset = () => {
	cache = {
		modelName: '',
		tree: [],
		defaultFields: []
	};
};

const getModelConfigByModel = (modelName: string): ModelConfig => {
	const { webhooksAppStore } = webhooksApiRootStore.getStore();
	const model = webhooksAppStore.webhookModels.find(({ name }) => name === modelName);

	if (!model) {
		return null;
	}

	const relations = model.defaultRelations.map(relationName => {
		return getModelConfigByModel(relationName);
	});

	return {
		name: modelName,
		fields: model.defaultFields,
		relations
	};
};

export const webhookModelProvider = {
	buildModelTree,
	getSelectedFields: () => cache.defaultFields,
	buildModelConfigFromTree,
	loadAll,
	reset,
	getModelConfigByModel
};
