import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import CompaniesStore from 'bringg-web/stores/companies/companies-store';
import CompaniesTable from '../companies-table/companies-table';
import CompaniesHeader from '../companies-header/companies-header';

interface Props {
	companiesStore?: CompaniesStore;
}

class CompaniesContainer extends Component<Props, {}> {
	async componentDidMount() {
		await this.props.companiesStore.fetchAll();
	}

	render() {
		const companies = this.props.companiesStore.getAll;

		return (
			<div className="companies-container">
				<CompaniesHeader />
				<CompaniesTable companies={companies} />
			</div>
		);
	}
}

export default inject('companiesStore')(observer(CompaniesContainer));
