import { MomentInput } from 'moment';
import { Date } from '../../../components';
import { NO_INFO } from './floating-inventories.consts';
import { FloatingInventoryOwnerType } from '@bringg/types';

export const dateRendererFormat = 'MMM DD, LT';

export const dateRenderer = (date: MomentInput) => (
	<Date format={dateRendererFormat} invalidDateText={NO_INFO} date={date} />
);

export const getOwnerType = ({ userId, teamId }: { userId: number; teamId: number }): FloatingInventoryOwnerType => {
	if (userId) return FloatingInventoryOwnerType.DRIVER;

	if (teamId) return FloatingInventoryOwnerType.TEAM;

	return FloatingInventoryOwnerType.NONE;
};
