import React from 'react';

import { observer } from 'mobx-react';
import { UseFormMethods } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ServiceAreaMap } from 'bringg-web/features/service-area/map';
import useStores from '../../../recipes/use-stores';
import TeamInfoForm from './team-info-form/team-info-form';
import ShiftTable from './shifts/shift-table/shift-table';
import { TeamInfoFormValues } from '../team-page-logic/team-info-types';
import TimeOff from '../../time-off/time-off';

interface Props {
	form: UseFormMethods;
	defaultValues: TeamInfoFormValues;
	onReload: () => any;
}

const TeamInfo: React.FC<Props> = observer(({ form, defaultValues, onReload }) => {
	const { teamsStore } = useStores();
	const { team, schedule } = defaultValues;
	const { t } = useTranslation();
	const teamLocation = { lat: team.lat, lng: team.lng };

	const timezone = teamsStore.get(team.id)?.localizationTimezone;

	return (
		<div className="team-info">
			<div className="shifts-section">
				<TeamInfoForm team={team} form={form} onReload={onReload} />
			</div>
			<div className="map">
				<ServiceAreaMap readonly teamId={team.id} mapCenter={teamLocation} />
			</div>
			<div className="shifts-section">
				<div className="team-info-section-header">{t('TEAM.SHIFT_TIMES')}</div>
				<ShiftTable form={form} schedule={schedule} />
			</div>
			<div className="shifts-section">
				<div className="team-info-section-header">{t('TEAM.TIME_OFF')}</div>
				<TimeOff teamId={team.id} timezone={timezone} />
			</div>
		</div>
	);
});

export default TeamInfo;
