import { useEffect, useState } from 'react';
import _isNil from 'lodash/isNil';
import notification from '../../../services/notification';
import useStores from '../../../recipes/use-stores';
import { Rollout } from '../../../stores/soft-rollout-store/consts';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

export enum RolloutStateId {
	TRY_NOW = 'try-now',
	SWITCH_BACK = 'switch-back'
}

export const useRolloutBar = rollout => {
	const { t } = useTranslation();
	const { softRolloutStore } = useStores();
	const [isBarOpen, setIsBarOpen] = useState(true);

	const onSwitch = async () => {
		try {
			await softRolloutStore.updateUserRollout(rollout, { is_enabled: !rollout.is_enabled });
			window.location.reload();
		} catch (err) {
			notification.error(t('ROLLOUT.FAILED_TO_UPDATE_ROLLOUT'));
		}
	};

	useEffect(() => {
		if (_isNil(rollout)) {
			return;
		}

		const updateBarOnRolloutChange = () => {
			setIsBarOpen(rollout.user_rollout?.is_bar_open ?? true);
		};

		updateBarOnRolloutChange();
	}, [rollout]);

	const onToggleBar = (isOpen: boolean) => {
		setIsBarOpen(isOpen);
		softRolloutStore.updateUserRollout(rollout, { is_bar_open: isOpen });
	};

	return {
		onToggleBar,
		isBarOpen,
		onSwitch,
		translations: formatBarTranslations(rollout, t),
		elementId: rollout?.is_enabled ? RolloutStateId.SWITCH_BACK : RolloutStateId.TRY_NOW
	};
};

const formatBarTranslations = (rollout: Rollout, t) => {
	if (!rollout) {
		return;
	}

	const TIME_FORMAT = 'LL';

	if (rollout.is_enabled) {
		return {
			clickableText: `${t('ROLLOUT.BAR.SWITCH_BACK')}.`,
			beforeClickableText: `${t(rollout.translations.enabled_bar_text)} ${
				rollout.block_switching ? '' : t('ROLLOUT.TEXT_BEFORE_SWITCH_BACK')
			}`,
			afterClickableText: `${t('ROLLOUT.TEXT_AFTER_SWITCH_BACK')} ${moment(rollout.end_time).format(TIME_FORMAT)}`
		};
	} else {
		return {
			clickableText: t('ROLLOUT.BAR.SWITCH'),
			beforeClickableText: t(rollout.translations.disabled_bar_text),
			afterClickableText: null
		};
	}
};
