import { RRule, Frequency } from 'rrule';
import { RECURRING_NO_REPEAT_FREQUENCY } from './recurring.consts';

export type RecurringRepeatLabels = {
	endRepeatLabel?: string;
	timesLabel?: string;
	afterLabel?: string;
	onLabel: string;
	repeat: string;
};

export type RecurringFrequenciesOptionsLabels = {
	everyLabel?: string;
	onLabel?: string;
	daysLabel?: string;
	weeksLabel?: string;
	monthsLabel?: string;
	weekDaysLabels?: { [key: number]: string };
};

const defaultFrequenciesOptionsLabels: RecurringFrequenciesOptionsLabels = {
	everyLabel: 'Every:',
	onLabel: 'On:',
	daysLabel: 'Day(s)',
	weeksLabel: 'Week(s)',
	monthsLabel: 'Month(s)',
	weekDaysLabels: {
		[RRule.SU.weekday]: 'Su',
		[RRule.MO.weekday]: 'Mo',
		[RRule.TU.weekday]: 'Tu',
		[RRule.WE.weekday]: 'We',
		[RRule.TH.weekday]: 'Th',
		[RRule.FR.weekday]: 'Fr',
		[RRule.SA.weekday]: 'Sa'
	}
};

const defaultRepeatLabels: RecurringRepeatLabels = {
	timesLabel: 'time(s)',
	endRepeatLabel: 'End Repeat',
	afterLabel: 'After',
	onLabel: 'On',
	repeat: 'Repeat'
};

const defaultFrequencyLabels = {
	[RECURRING_NO_REPEAT_FREQUENCY]: 'No Repeat',
	[Frequency.DAILY]: 'Daily',
	[Frequency.WEEKLY]: 'Weekly',
	[Frequency.MONTHLY]: 'Monthly'
};

export { defaultFrequencyLabels, defaultFrequenciesOptionsLabels, defaultRepeatLabels };
