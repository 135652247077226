import { getRootEnv } from '@bringg-frontend/bringg-web-infra';
import { action, observable, makeObservable } from 'mobx';
import { getEnv } from 'mobx-easy';
import { RootEnv } from '@bringg-frontend/bringg-web-infra';

import TagMerchantConfiguration from './tag-merchant-configuration';

class TagMerchantConfigurationsStore {
	configurations: Map<number, TagMerchantConfiguration> = new Map();

	constructor() {
		makeObservable(this, {
			configurations: observable.shallow,
			set: action
		});
	}

	set = (configuration: Partial<Bringg.TagMerchantConfiguration>) => {
		this.configurations.set(configuration.tag_id, new TagMerchantConfiguration(configuration));
	};

	getByTag(tagId: number): TagMerchantConfiguration {
		return this.configurations.get(tagId);
	}

	async fetch(tagId: number): Promise<TagMerchantConfiguration> {
		const result = await getRootEnv().dashboardSdk.sdk.tagMerchantConfigurations.getByTag(tagId);
		if (result.tag_merchant_configuration) {
			this.set(result.tag_merchant_configuration);
			return this.getByTag(tagId);
		}
		return null;
	}
}

export default TagMerchantConfigurationsStore;
