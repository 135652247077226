import React, { useCallback } from 'react';

import classnames from 'classnames';
import { Tooltip } from '@bringg/react-components';
import { BringgFontIcons, BringgIcon } from '@bringg/bringg-icons';

import './input-label-with-tooltip.scss';

type InputLabelWithTooltipProps = {
	id: string;
	title: string;
	className?: string;
	tooltip?: string;
	required?: boolean;
	containerId?: string;
};

const InputLabelWithTooltip = ({
	id,
	title,
	tooltip,
	className,
	required = false,
	containerId
}: InputLabelWithTooltipProps) => {
	const wrapperIdText = containerId ? `${containerId}-${id}` : `${id}`;
	const wrapperId = `optimization-configuration-input-${wrapperIdText}-label`;
	const getPopupContainer = useCallback(() => document.getElementById(wrapperId), [wrapperId]);

	return (
		<div id={wrapperId} className={classnames('optimization-configuration-input-label-with-tooltip', className)}>
			<span className={classnames('input-title', { 'required-field': required })}>{title}</span>
			{tooltip && (
				<Tooltip
					title={tooltip}
					overlayClassName="attribute-input-tooltip"
					placement="right"
					getPopupContainer={getPopupContainer}
				>
					<BringgIcon
						className="optimization-configuration-input-tooltip-icon"
						iconName={BringgFontIcons.Info}
					/>
				</Tooltip>
			)}
		</div>
	);
};

export default InputLabelWithTooltip;
