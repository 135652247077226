import React, { memo, useMemo } from 'react';

import { useTranslation } from 'react-i18next';
import type { TemplateUsage } from '@bringg/types';

import { DATA_TEST_IDS } from '../../consts';

interface Props {
	templateUsage: TemplateUsage[];
}

export const EMPTY_CELL_TEXT = '—';

function UsageCell({ templateUsage }: Props) {
	const { t } = useTranslation();

	const text = useMemo(() => {
		if (!templateUsage?.length) {
			return EMPTY_CELL_TEXT;
		}

		const lastTemplateUsageIndex = templateUsage.length - 1;
		const usageNames = templateUsage.reduce((acc, next, index) => {
			if (next.ids?.length) {
				return acc.concat(
					`${t(next.entity, { count: next.ids.length })}${index === lastTemplateUsageIndex ? '' : ', '}`
				);
			}

			return acc;
		}, '');

		return usageNames || EMPTY_CELL_TEXT;
	}, [t, templateUsage]);

	return (
		<span data-test-id={DATA_TEST_IDS.templateUsageText} title={text}>
			{text}
		</span>
	);
}

export default memo(UsageCell);
