import React from 'react';

import classname from 'classnames';
import { Modal, useForm, FormLayout } from '@bringg/react-components';

import DynamicForm, { FormFields } from '../dynamic-form/dynamic-form';

interface Props {
	visible: boolean;
	title: string | JSX.Element;
	okText: string;
	className?: string;
	cancelText?: string;
	onClose: () => void;
	destroyOnClose?: boolean;
	onSubmit: (values: any[]) => void;
	formFields: FormFields[];
	numberOfColumns?: number;
	layout?: FormLayout;
	cancelButtonProps?: Record<`data-${string}`, any>;
	okButtonProps?: Record<`data-${string}`, any>;
}

const DynamicFormModal: React.FC<Props> = props => {
	const {
		visible,
		title,
		okText,
		onClose,
		onSubmit,
		formFields,
		destroyOnClose,
		className,
		numberOfColumns,
		layout,
		okButtonProps,
		cancelButtonProps
	} = props;
	const [formInstance] = useForm();

	const handleSubmit = () => {
		formInstance
			.validateFields()
			.then(values => {
				onSubmit(values);
				onClose();
				formInstance.resetFields();
			})
			.catch(info => {
				console.error('Validate Failed:', info);

				return;
			});
	};

	return (
		<Modal
			className={classname('dynamic-form-modal', className)}
			visible={visible}
			title={title}
			okText={okText}
			onCancel={onClose}
			destroyOnClose={destroyOnClose}
			onOk={handleSubmit}
			okButtonProps={okButtonProps}
			cancelButtonProps={cancelButtonProps}
		>
			<DynamicForm
				fields={formFields}
				showSubmitButton={false}
				showCancelButton={false}
				formInstance={formInstance}
				numberOfColumns={numberOfColumns}
				layout={layout}
			/>
		</Modal>
	);
};

export default DynamicFormModal;
