import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { isNumber } from 'lodash';
import { Button } from '@bringg/react-components';
import { BringgFontIcons, BringgIcon } from '@bringg/bringg-icons';
import { Check } from './icons';
import { useStores } from '../../recipes';
import { KG_TO_POUNDS_MULTIPLEXER, KM_TO_MILES_MULTIPLEXER } from '../../consts';
import { EcoFriendlyStatus, FavoriteButton, TurnkeyStatus } from './components';

interface DeliveryCatalogDetailsProps {
	deliveryProvider: any; // TODO: Add proper type
	onToggleFavorite: (any) => void;
}

interface CheckProps {
	active: boolean;
	separator?: boolean;
	block?: boolean;
	children: string | JSX.Element | JSX.Element[];
}

export const ListItem = ({ active, separator, block, children }: CheckProps) => (
	<div className={classNames('delivery-catalog-details-item', { 'delivery-catalog-details-item-block': block })}>
		<Check active={active} />
		<span>
			{children}
			{separator ? ' | ' : null}
		</span>
	</div>
);

interface ExpanderProps {
	children: string | JSX.Element;
	header: string;
	showHide?: boolean;
}

export const Expander = ({ children, header, showHide }: ExpanderProps) => {
	const [isOpened, setIsOpened] = useState(false);

	const onToggle = () => {
		setIsOpened(!isOpened);
	};

	return (
		<div className="delivery-catalog-details-expander">
			<Button type="text" className="delivery-catalog-details-expander-header" onClick={onToggle}>
				{header}
				<BringgIcon iconName={BringgFontIcons[isOpened ? 'ChevronUp' : 'Chevron']} />
			</Button>
			{isOpened && <div className="delivery-catalog-details-expander-body">{children}</div>}
			{showHide && isOpened && (
				<Button className="delivery-catalog-details-expander-hide" type="text" onClick={onToggle}>
					Hide
				</Button>
			)}
		</div>
	);
};

const DeliveryCatalogDetails = ({ deliveryProvider, onToggleFavorite }: DeliveryCatalogDetailsProps) => {
	const { t } = useTranslation();
	const { merchantConfigurationsStore } = useStores();

	const SHOW_HIDE_WHEN = 10;

	const isUSA = merchantConfigurationsStore.merchantCountry === 'us';

	const packageWeight = useMemo(() => {
		const unit = isUSA ? t('DELIVERY_PROVIDER_CATALOG.DETAILS.POUNDS') : t('DELIVERY_PROVIDER_CATALOG.DETAILS.KG');
		const { supported_package_weight_from, supported_package_weight_to } = deliveryProvider;

		if (isNumber(supported_package_weight_from) && isNumber(supported_package_weight_to)) {
			const from = isUSA
				? supported_package_weight_from * KG_TO_POUNDS_MULTIPLEXER
				: supported_package_weight_from;
			const to = isUSA ? supported_package_weight_to * KG_TO_POUNDS_MULTIPLEXER : supported_package_weight_to;
			return `Between ${Math.round(from)} - ${Math.round(to)} ${unit}`;
		}

		if (isNumber(supported_package_weight_from)) {
			const from = isUSA
				? supported_package_weight_from * KG_TO_POUNDS_MULTIPLEXER
				: supported_package_weight_from;
			return `From ${Math.round(from)} ${unit}`;
		}

		if (isNumber(supported_package_weight_to)) {
			const to = isUSA ? supported_package_weight_to * KG_TO_POUNDS_MULTIPLEXER : supported_package_weight_to;
			return `Up to ${Math.round(to)} ${unit}`;
		}

		return '';
	}, [deliveryProvider, isUSA, t]);

	const maxDeliveryRadius = useMemo(() => {
		if (!deliveryProvider?.max_delivery_radius) {
			return '';
		}

		const unit = isUSA ? t('DELIVERY_PROVIDER_CATALOG.DETAILS.MILES') : t('DELIVERY_PROVIDER_CATALOG.DETAILS.KM');
		const distance = isUSA
			? Math.round(deliveryProvider?.max_delivery_radius / KM_TO_MILES_MULTIPLEXER)
			: deliveryProvider?.max_delivery_radius;

		return `${distance} ${unit}`;
	}, [deliveryProvider, isUSA, t]);

	const serviceLevelList = useMemo(() => {
		const { carrier_international_services, carrier_domestic_services } = deliveryProvider || {};

		const international = carrier_international_services ? carrier_international_services.split('\n') : [];
		const domestic = carrier_domestic_services ? carrier_domestic_services.split('\n') : [];

		return [...international, ...domestic];
	}, [deliveryProvider]);

	const additionalServicesLimited = useMemo(() => {
		return [
			deliveryProvider.refrigeration_note || null,
			deliveryProvider.fragile_items_note || null,
			deliveryProvider.hazard_materials_note || null
		].filter(Boolean);
	}, [deliveryProvider]);

	return (
		<div className="delivery-catalog-details">
			<div className="delivery-catalog-details-tags">
				<FavoriteButton
					isActive={deliveryProvider.favorite}
					onClick={() => {
						onToggleFavorite(deliveryProvider);
					}}
				/>
				<TurnkeyStatus
					status={deliveryProvider.bringg_turnkey}
					tooltip={t('DELIVERY_PROVIDER_CATALOG.DETAILS.TURNKEY')}
				/>
				<EcoFriendlyStatus
					status={deliveryProvider.eco_friendly}
					tooltip={t('DELIVERY_PROVIDER_CATALOG.DETAILS.ECO_FRIENDLY')}
				/>
			</div>

			<div className="delivery-catalog-details-head">
				<img alt={deliveryProvider.name} src={deliveryProvider.absolute_logo_path} />
				<h3>{deliveryProvider.name}</h3>

				<div className="delivery-catalog-details-head-border" />
			</div>

			<div className="delivery-catalog-details-body">
				{/* COUNTRIES */}
				<div className="delivery-catalog-details-section">
					<h4>
						<div className="delivery-catalog-details-icon">
							<BringgIcon iconName={BringgFontIcons.Location} />
						</div>
						<span>{t('DELIVERY_PROVIDER_CATALOG.DETAILS.COUNTRIES')}</span>
					</h4>
					<p>
						{deliveryProvider.countries.length === 0
							? t('DELIVERY_PROVIDER_CATALOG.DETAILS.ALL_COUNTRIES')
							: deliveryProvider.countries.map(country => country.name).join(' | ')}
					</p>
				</div>

				{/* ITEMS_DELIVERED */}
				<div className="delivery-catalog-details-section">
					<h4>
						<div className="delivery-catalog-details-icon">
							<BringgIcon iconName={BringgFontIcons.Cart} />
						</div>
						<span>{t('DELIVERY_PROVIDER_CATALOG.DETAILS.ITEMS_DELIVERED')}</span>
					</h4>
					<ul>
						{deliveryProvider.delivery_types.map(({ value }) => (
							<li key={value}>{value}</li>
						))}
					</ul>
					{deliveryProvider.delivery_type_note && (
						<Expander header={t('DELIVERY_PROVIDER_CATALOG.DETAILS.LIMITED_TO')}>
							{deliveryProvider.delivery_type_note}
						</Expander>
					)}
				</div>

				{/* SHIP_FROM */}
				{deliveryProvider.shipping_origing?.length > 0 && (
					<div className="delivery-catalog-details-section">
						<h4>
							<div className="delivery-catalog-details-icon">
								<BringgIcon iconName={BringgFontIcons.Home} />
							</div>
							<span>{t('DELIVERY_PROVIDER_CATALOG.DETAILS.SHIP_FROM')}</span>
						</h4>
						<p>{deliveryProvider.shipping_origins.map(({ value }) => value).join('  |  ')}</p>

						{deliveryProvider.shipping_origins_note && (
							<Expander header={t('DELIVERY_PROVIDER_CATALOG.DETAILS.LIMITED_TO')}>
								{deliveryProvider.shipping_origins_note}
							</Expander>
						)}
					</div>
				)}

				{/* SERVICE_LEVEL */}
				<div className="delivery-catalog-details-section">
					<h4>
						<div className="delivery-catalog-details-icon">
							<BringgIcon iconName={BringgFontIcons.Suitcase} />
						</div>
						<span>{t('DELIVERY_PROVIDER_CATALOG.DETAILS.SERVICE_LEVEL')}</span>
					</h4>

					{deliveryProvider.service_levels.map(({ value }, index) => (
						<ListItem key={value} separator={deliveryProvider.service_levels.length - 1 !== index} active>
							{value}
						</ListItem>
					))}

					{deliveryProvider.service_level_note && (
						<Expander header={t('DELIVERY_PROVIDER_CATALOG.DETAILS.LIMITED_TO')}>
							{deliveryProvider.service_level_note}
						</Expander>
					)}

					{serviceLevelList.length > 0 && (
						<Expander
							header={t('DELIVERY_PROVIDER_CATALOG.DETAILS.LIST_OF_SUPPORTED_SERVICES')}
							showHide={serviceLevelList.length > SHOW_HIDE_WHEN}
						>
							<ul>
								{serviceLevelList.map(item => (
									<li key={item}>{item}</li>
								))}
							</ul>
						</Expander>
					)}
				</div>

				{/* MAX_DELIVERY_RAD / SUPPORTING_PACKAGE_WEIGHT */}
				{(maxDeliveryRadius || packageWeight) && (
					<div className="delivery-catalog-details-section">
						{maxDeliveryRadius && (
							<h4>
								<div className="delivery-catalog-details-icon">
									<BringgIcon iconName={BringgFontIcons.Distance} />
								</div>
								<span>
									{t('DELIVERY_PROVIDER_CATALOG.DETAILS.MAX_DELIVERY_RAD')} ={' '}
									<strong> {maxDeliveryRadius}</strong>
								</span>
							</h4>
						)}
						{packageWeight && (
							<h4>
								<div className="delivery-catalog-details-icon">
									<BringgIcon iconName={BringgFontIcons.Weight} />
								</div>
								<span>
									{t('DELIVERY_PROVIDER_CATALOG.DETAILS.SUPPORTING_PACKAGE_WEIGHT')} ={' '}
									<strong>{packageWeight}</strong>
								</span>
							</h4>
						)}
					</div>
				)}

				{/* VEHICLE_TYPE */}
				<div className="delivery-catalog-details-section">
					<h4>{t('DELIVERY_PROVIDER_CATALOG.DETAILS.VEHICLE_TYPE')}</h4>
					<p>{deliveryProvider.vehicle_types.map(({ value }) => value).join('  |  ')}</p>
				</div>

				{/* ADDITIONAL_SERVICES */}
				<div className="delivery-catalog-details-section">
					<h4>
						<div className="delivery-catalog-details-icon">
							<BringgIcon iconName={BringgFontIcons.Plus} />
						</div>
						<span>{t('DELIVERY_PROVIDER_CATALOG.DETAILS.ADDITIONAL_SERVICES')}</span>
					</h4>

					{deliveryProvider.white_gloves.length > 0 && (
						<ListItem active block>
							{t('DELIVERY_PROVIDER_CATALOG.DETAILS.ADDITIONAL_SERVICES_WHITE_GLOVE')}:{' '}
							{deliveryProvider.white_gloves.map(({ value }) => value).join('  |  ')}
						</ListItem>
					)}
					{deliveryProvider.age_restrictions.length > 0 && (
						<ListItem active block>
							{t('DELIVERY_PROVIDER_CATALOG.DETAILS.ADDITIONAL_SERVICES_AGE_RESTRICTION')}:{' '}
							{deliveryProvider.age_restrictions.map(({ value }) => value).join('  |  ')}
						</ListItem>
					)}
					<ListItem active={deliveryProvider.refrigeration} block>
						{t('DELIVERY_PROVIDER_CATALOG.DETAILS.ADDITIONAL_SERVICES_REFRIGERATION')}
					</ListItem>
					<ListItem active={deliveryProvider.fragile_items} block>
						{t('DELIVERY_PROVIDER_CATALOG.DETAILS.ADDITIONAL_SERVICES_FRAGILE')}
					</ListItem>
					<ListItem active={deliveryProvider.hazard_materials} block>
						{t('DELIVERY_PROVIDER_CATALOG.DETAILS.ADDITIONAL_SERVICES_HAZARD')}
					</ListItem>

					{additionalServicesLimited.length > 0 && (
						<Expander header={t('DELIVERY_PROVIDER_CATALOG.DETAILS.LIMITED_TO')}>
							<ul>
								{additionalServicesLimited.map(item => (
									<li key={item}>{item}</li>
								))}
							</ul>
						</Expander>
					)}
				</div>

				{/* SKILLS */}
				<div className="delivery-catalog-details-section">
					<h4>
						<div className="delivery-catalog-details-icon">
							{/* @ts-ignore */}
							<BringgIcon iconName={BringgFontIcons.Monitor} />
						</div>
						<span>{t('DELIVERY_PROVIDER_CATALOG.DETAILS.SKILLS')}</span>
					</h4>
					<ListItem separator active={deliveryProvider.get_delivery_quote}>
						{t('DELIVERY_PROVIDER_CATALOG.DETAILS_SKILLS.QUOTES')}
					</ListItem>
					<ListItem separator active={deliveryProvider.proof_of_delivery}>
						{t('DELIVERY_PROVIDER_CATALOG.DETAILS_SKILLS.PROOF_OF_DELIVERY')}
					</ListItem>
					<ListItem separator active={deliveryProvider.cash_on_delivery}>
						{t('DELIVERY_PROVIDER_CATALOG.DETAILS_SKILLS.CASH_ON_DELIVERY')}
					</ListItem>
					<ListItem separator active={deliveryProvider.multi_package}>
						{t('DELIVERY_PROVIDER_CATALOG.DETAILS_SKILLS.MULTI_PARCEL')}
					</ListItem>
					<ListItem separator active={deliveryProvider.live_tracking}>
						{t('DELIVERY_PROVIDER_CATALOG.DETAILS_SKILLS.LIVE_TRACKING')}
					</ListItem>
					<ListItem separator active={deliveryProvider.cancel_delivery}>
						{t('DELIVERY_PROVIDER_CATALOG.DETAILS_SKILLS.CANCEL_DELIVERY')}
					</ListItem>
					<ListItem separator active={deliveryProvider.status_updates}>
						{t('DELIVERY_PROVIDER_CATALOG.DETAILS_SKILLS.UPDATE_DELIVERY_DRIVER')}
					</ListItem>
					<ListItem active={deliveryProvider.label_printing}>
						{t('DELIVERY_PROVIDER_CATALOG.DETAILS_SKILLS.PRINT_LABEL')}
					</ListItem>
				</div>

				{/* SETUP_WITH_BRINGG */}
				<div className="delivery-catalog-details-section">
					<h4>
						<div className="delivery-catalog-details-icon">
							<BringgIcon iconName={BringgFontIcons.Bringg} />
						</div>
						<span>{t('DELIVERY_PROVIDER_CATALOG.DETAILS.SETUP_WITH_BRINGG')}</span>
					</h4>
					<ListItem active={deliveryProvider.direct_contract} separator>
						{t('DELIVERY_PROVIDER_CATALOG.DETAILS.DIRECT_WITH_CARRIER')}
					</ListItem>
					<ListItem active={deliveryProvider.bringg_turnkey}>
						{t('DELIVERY_PROVIDER_CATALOG.DETAILS.BRINGG_ONE_TOUCH')}
					</ListItem>
				</div>
			</div>
		</div>
	);
};

export default DeliveryCatalogDetails;
