import _map from 'lodash/map';
import _range from 'lodash/range';
import _each from 'lodash/each';
import _sortBy from 'lodash/sortBy';
import _reduce from 'lodash/reduce';
import _pick from 'lodash/pick';
import _times from 'lodash/times';
import moment from 'moment';
import { isNil as _isNil } from 'lodash';

import { DaySchedule, Schedule, Shift, ShiftSchedule, TranslatedConfigurations } from '../team-info-types';

let daysByLanguage;

export const days = languageCode => {
	if (_isNil(daysByLanguage)) {
		daysByLanguage = _times(7, index =>
			moment()
				.locale(languageCode)
				.isoWeekday(index + 1)
				.format('dddd')
		);
	}
	return daysByLanguage;
};

const dayValue = languageCode =>
	days(languageCode).reduce((acc, item, index) => {
		acc[item] = index;
		return acc;
	}, {});

export const translateToDb = (schedule: Schedule, languageCode): TranslatedConfigurations => {
	const shifts = [];
	const lunchBreaks = [];

	_each(Object.entries(schedule), function ([day, daySchedule]: [string, DaySchedule]) {
		const dayInHours = dayValue(languageCode)[day] * 24;

		_each(Object.values(_pick(daySchedule, ['firstShift', 'secondShift'])) as Shift[], function (shift) {
			if (!shift.enabled) {
				return;
			}
			shifts.push({
				start_hour: shift.startHour + dayInHours,
				end_hour: shift.endHour + dayInHours
			});
		});

		if (daySchedule.break && daySchedule.break.enabled) {
			lunchBreaks.push({
				start_hour: daySchedule.break.startHour + dayInHours,
				end_hour: daySchedule.break.endHour + dayInHours
			});
		}
	});

	return {
		shifts: shifts,
		lunchBreaks: lunchBreaks
	};
};

export const translateFromDb = (
	dbShifts: ShiftSchedule[],
	dbScheduledBreaks: ShiftSchedule[],
	languageCode
): Schedule => {
	const shiftsPerDay = _map(_range(7), function (index) {
		return {
			day: days(languageCode)[index],
			shifts: [],
			break: null
		};
	});

	_each(dbShifts, function (dbShift) {
		const shiftDay = (dbShift.start_hour / 24) >> 0;
		shiftsPerDay[shiftDay].shifts.push({
			startHour: dbShift.start_hour % 24,
			endHour: dbShift.end_hour % 24
		});

		// make sure the first shift of each day comes before the second one
		shiftsPerDay[shiftDay].shifts = _sortBy(shiftsPerDay[shiftDay].shifts, 'startHour');
	});

	_each(dbScheduledBreaks, function (dbScheduledBreak) {
		const breakDay = (dbScheduledBreak.start_hour / 24) >> 0;
		shiftsPerDay[breakDay].break = {
			startHour: dbScheduledBreak.start_hour % 24,
			endHour: dbScheduledBreak.end_hour % 24
		};
	});

	const shiftsPerDayObject = _reduce(
		shiftsPerDay,
		(acc, item) => {
			acc[item.day] = {
				day: item.day,
				firstShift: item.shifts[0],
				secondShift: item.shifts[1],
				break: item.break
			};
			return acc;
		},
		{}
	);
	return shiftsPerDayObject;
};
