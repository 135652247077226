import { useTranslation } from 'react-i18next';
import i18next from 'i18next';

export interface BooleanKeys {
	trueKey: string;
	falseKey: string;
}

const useRuleEngineTranslations = (booleanKeys: BooleanKeys) => {
	const { t } = useTranslation();
	const translations = {
		numeric: {
			addRow: t('CONDITIONS.NUMERIC.ADD_A_ROW'),
			equals: t('CONDITIONS.NUMERIC.EQUALS'),
			notEquals: t('CONDITIONS.NUMERIC.NOT_EQUALS'),
			lessThan: t('CONDITIONS.NUMERIC.LESS_THAN'),
			lessThanOrEquals: t('CONDITIONS.NUMERIC.LESS_THAN_OR_EQUALS'),
			greaterThan: t('CONDITIONS.NUMERIC.GREATER_THAN'),
			greaterThanOrEquals: t('CONDITIONS.NUMERIC.GREATER_THAN_OR_EQUALS'),
			inRange: t('CONDITIONS.NUMERIC.IN_RANGE'),
			validations: {
				onlyPositive: t('ALERTS_CONFIGURATION.CONDITIONS.VALIDATION.ONLY_POSITIVE'),
				toGreaterThanFrom: t('ALERTS_CONFIGURATION.CONDITIONS.VALIDATION.TO_GREATER_THAN_FROM')
			}
		},
		freeText: {
			addRow: t('CONDITIONS.STRING.ADD_A_ROW'),
			equals: t('CONDITIONS.STRING.EQUALS'),
			notEquals: t('CONDITIONS.STRING.NOT_EQUALS'),
			include: t('CONDITIONS.STRING.INCLUDE'),
			exclude: t('CONDITIONS.STRING.EXCLUDE')
		},
		array: {
			hasOne: t('CONDITIONS.ARRAY.ARRAY_INTERSECTION_HAS_ONE'),
			hasAll: t('CONDITIONS.ARRAY.ARRAY_INTERSECTION_HAS_ALL'),
			operatorPlaceholder: t('CONDITIONS.ARRAY.OPERATOR_SELECT_PLACEHOLDER'),
			valuePlaceHolder: t('CONDITIONS.ARRAY.VALUE_SELECT_PLACEHOLDER')
		},
		addAttribute: t('CONDITIONS.ADD_ATTRIBUTE'),
		deleteTitle: t('CONDITIONS.MODAL_DELETE_TITLE'),
		deleteBody: t('CONDITIONS.DELETE_BODY'),
		cancel: t('GLOBAL.CANCEL'),
		delete: t('GLOBAL.DELETE'),
		changed: t('CONDITIONS.CHANGED'),
		conditionsSet: t('CONDITIONS.CONDITIONS_SET'),
		conditionsSets: t('CONDITIONS.CONDITIONS_SETS'),
		addConditionsSet: t('CONDITIONS.ADD_CONDITIONS_SET'),
		discardChanges: t('CONDITIONS.DISCARD_CHANGES'),
		saveAndDeploy: t('CONDITIONS.SAVE_AND_DEPLOY'),
		description: t('CONDITIONS.DESCRIPTION'),
		or: t('CONDITIONS.OR'),
		collapse: t('CONDITIONS.COLLAPSE'),
		expand: t('CONDITIONS.EXPAND'),
		readonlyMessage: t('CONDITIONS.READONLY_NOTICE_MESSAGE'),
		getBooleanTranslations: (attribute_name: string) => ({
			trueOption: i18next.t(booleanKeys.trueKey, { attribute_name }),
			falseOption: i18next.t(booleanKeys.falseKey, { attribute_name })
		}),
		inactive: t('GLOBAL.INACTIVE')
	};
	return translations;
};

export default useRuleEngineTranslations;
