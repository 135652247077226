import React, { useEffect } from 'react';

export function useOutsideClick(ref: React.RefObject<Element>, callback: (event) => void) {
	useEffect(() => {
		const handleClickOutside = event => {
			if (!ref.current || ref.current.contains(event.target)) {
				return;
			}
			callback(event);
		};

		document.addEventListener('click', handleClickOutside);

		return () => {
			document.removeEventListener('click', handleClickOutside);
		};
	}, [ref, callback]);
}
