import React, { useState } from 'react';
import { Modal, Radio } from '@bringg/react-components';
import { BringgFontIcons, BringgIcon } from '@bringg/bringg-icons';
import RecurrenceTypeModalFooter from 'bringg-web/features/planned-delivery-windows/modals/recurrence-type-modal/footer/recurrence-type-modal-footer';
import { useTranslation } from 'react-i18next';
import styles from './recurrence-type-modal.module.scss';
import { RecurrenceType } from './recurrence-type-modal.consts';

export type RecurrenceDisabled = {
	current: boolean;
	current_and_following: boolean;
	all: boolean;
};

interface Props {
	visible: boolean;
	isEditMode: boolean;
	closeAndCancel: () => void;
	onUpdate: (recurrenceType: RecurrenceType) => void;
	onDelete: (recurrenceType: RecurrenceType) => void;
	recurrenceDisabled: RecurrenceDisabled;
}

const RecurrenceTypeModal = ({
	isEditMode,
	onUpdate,
	onDelete,
	closeAndCancel,
	visible,
	recurrenceDisabled
}: Props) => {
	const { t } = useTranslation();
	const [recurrenceType, setRecurrenceType] = useState<RecurrenceType>(RecurrenceType.current);
	const [loader, setLoader] = useState(false);
	const getModalHeader = () => {
		return (
			<div className={styles.modalHeader}>
				<span className={isEditMode ? styles.infoIcon : styles.warningIcon}>
					<BringgIcon iconName={isEditMode ? BringgFontIcons.InfoFilled : BringgFontIcons.Warning} />
				</span>
				<span className={styles.title}>
					{isEditMode
						? t('PLANNED_DELIVERY_WINDOWS_RECURRENCE_MODAL.EDIT_TEXT')
						: t('PLANNED_DELIVERY_WINDOWS_RECURRENCE_MODAL.DELETE_TEXT')}
				</span>
			</div>
		);
	};

	const onChange = event => {
		const updatedRecurrenceType: RecurrenceType = event.target.value;
		setRecurrenceType(updatedRecurrenceType);
	};

	const updatePlannedDeliveryWindow = () => {
		setLoader(true);
		onUpdate(recurrenceType);
	};

	const deletePlannedDeliveryWindow = () => {
		setLoader(true);
		onDelete(recurrenceType);
	};

	return (
		<Modal
			width={480}
			destroyOnClose
			visible={visible}
			title={getModalHeader()}
			onCancel={closeAndCancel}
			footer={
				<RecurrenceTypeModalFooter
					isEditMode={isEditMode}
					loader={loader}
					updatePlannedDeliveryWindow={updatePlannedDeliveryWindow}
					deletePlannedDeliveryWindow={deletePlannedDeliveryWindow}
					closeRecurrenceTypeModalAndCancel={closeAndCancel}
				/>
			}
		>
			<div className={styles.modalBody}>
				<span className={styles.title}>
					{isEditMode
						? t('PLANNED_DELIVERY_WINDOWS_RECURRENCE_MODAL.ARE_YOU_SURE_EDIT')
						: t('PLANNED_DELIVERY_WINDOWS_RECURRENCE_MODAL.ARE_YOU_SURE_DELETE')}
				</span>
				<div className={styles.radioOptions}>
					<Radio.Group defaultValue={recurrenceType} onChange={onChange} className={styles.radioGroup}>
						<Radio
							value={RecurrenceType.current}
							key={RecurrenceType.current}
							className={styles.radioOption}
							disabled={recurrenceDisabled.current}
						>
							{t('PLANNED_DELIVERY_WINDOWS_RECURRENCE_MODAL.CURRENT')}
						</Radio>
						<Radio
							value={RecurrenceType.current_and_following}
							key={RecurrenceType.current_and_following}
							className={styles.radioOption}
							disabled={recurrenceDisabled.current_and_following}
						>
							{t('PLANNED_DELIVERY_WINDOWS_RECURRENCE_MODAL.CURRENT_AND_FOLLOWING')}
						</Radio>
						<Radio
							value={RecurrenceType.all}
							key={RecurrenceType.all}
							className={styles.radioOption}
							disabled={recurrenceDisabled.all}
						>
							{t('PLANNED_DELIVERY_WINDOWS_RECURRENCE_MODAL.ALL')}
						</Radio>
					</Radio.Group>
				</div>
			</div>
		</Modal>
	);
};

export default RecurrenceTypeModal;
