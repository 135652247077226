import React from 'react';
import { Button, Modal } from '@bringg/react-components';
import { useTranslation } from 'react-i18next';
import { Rule } from '../../../stores/fleets-configuration/fleets-configurations-store';

import './conflict-rules-modal.scss';

interface Translation {
	title: string;
	description: string;
	rule: string;
	cancel: string;
	disableRules: string;
	removeTeams: string;
}

interface Props {
	rules: Rule[];
	translations: Translation;
	isVisible: boolean;

	onRemoveTeams?(): void;

	onDisableRules(): void;

	onCancel(): void;
}

const RuleRow = (rule, ruleTranslation) => {
	const { t } = useTranslation();
	return (
		<div key={rule.id} className="rule-row" role="listitem">
			{`${t(ruleTranslation)} ${rule.name}`}
		</div>
	);
};

const ConflictRulesModal = ({ rules, onDisableRules, onRemoveTeams, onCancel, translations, isVisible }: Props) => {
	const title = (
		<div className="conflict-rules-modal-title">
			<div className="square" />
			<div className="title">{translations.title}</div>
		</div>
	);

	const footer = [
		<Button key="back" onClick={onCancel} type="link" className="cancel-btn">
			{translations.cancel}
		</Button>,
		<Button
			key="disable-rules"
			className="disable-rules"
			type="primary"
			// when no remove teams button is needed, this button is the primary one
			ghost={!!onRemoveTeams}
			onClick={onDisableRules}
		>
			{translations.disableRules}
		</Button>,
		onRemoveTeams && (
			<Button key="remove-teams" className="remove-teams" type="primary" onClick={onRemoveTeams}>
				{translations.removeTeams}
			</Button>
		)
	].filter(Boolean);

	return (
		<Modal title={title} footer={footer} visible={isVisible} className="conflict-rules-modal" closable={false}>
			<div className="description">{translations.description}</div>
			<div className="rules-list" role="list" aria-label="The conflicting rules">
				{rules.map(rule => RuleRow(rule, translations.rule))}
			</div>
		</Modal>
	);
};

export default ConflictRulesModal;
