import React, { useState } from 'react';

import { PopOver } from '@bringg/react-components';

import { PlannedDeliveryWindowsEventType } from '../../planned-delivery-windows-calendar';
import PlannedDeliveryWindowsPopoverContent from '../popover-content/planned-delivery-windows-popover-content';
import { Cutoff } from '../../../cutoff/cutoff';
import { useHasFeatureFlag } from 'bringg-web/utils/feature-flags';
import { selectionService } from 'bringg-web/services/selection/selection-service';

import styles from './planned-delivery-windows-event.module.scss';

interface Props {
	title: string;
	event: PlannedDeliveryWindowsEventType;
	openModalWithPlannedDeliveryWindow: (plannedDeliveryWindowId: number) => void;
	openRecurrenceTypeModalWithDeleteMode: (id: number) => void;
	selectedEventsIdsLength: number;
	fillRatio: string;
	timezone: string;
}

const PlannedDeliveryWindowsEvent: React.FC<Props> = ({
	event,
	title,
	openModalWithPlannedDeliveryWindow,
	openRecurrenceTypeModalWithDeleteMode,
	fillRatio,
	timezone,
	selectedEventsIdsLength
}: Props) => {
	const [popoverOpen, setPopoverOpen] = useState(false);
	const isPDWMultiSelectEnabled = useHasFeatureFlag('pdw_multi_select');

	const onClickOnEvent = clickEvent => {
		if ((isPDWMultiSelectEnabled && selectionService.isMultiSelect(clickEvent)) || selectedEventsIdsLength > 1) {
			return;
		}

		openModalWithPlannedDeliveryWindow(event.id);
		clickEvent.stopPropagation();
	};

	return (
		<PopOver
			content={
				<PlannedDeliveryWindowsPopoverContent
					title={title}
					event={event}
					setPopoverOpen={setPopoverOpen}
					openModalWithPlannedDeliveryWindowSlot={openModalWithPlannedDeliveryWindow}
					openRecurrenceTypeModalWithDeleteMode={openRecurrenceTypeModalWithDeleteMode}
					fillRatio={fillRatio}
					timezone={timezone}
				/>
			}
			trigger="hover"
			mouseEnterDelay={1}
			showArrow={false}
			placement="right"
			open={popoverOpen}
			onOpenChange={newOpen => setPopoverOpen(newOpen)}
		>
			<div data-test-id={`pdw-id-${event.id}`} className={styles.eventContentItem} onClick={onClickOnEvent}>
				{fillRatio}
				<span className={styles.title}>{title}</span>
				{event.cutoff !== 0 && (
					<Cutoff startWindowTime={event.start} cutoff={event.cutoff} timezone={timezone} />
				)}
			</div>
		</PopOver>
	);
};

export default React.memo(PlannedDeliveryWindowsEvent);
