import { AttributeDatatype, EventTriggerTasksManualParams, ManualTriggerInputParam } from '@bringg/types';

import TriggerStore from '../../../stores/trigger-store';
import { StringTriggerValues, triggerChangeNoValuesOptions } from '../../../utils/types';

export const isFieldChangedValid = (trigger: TriggerStore) => {
	if (
		triggerChangeNoValuesOptions.has(trigger.changedTo) ||
		(trigger.datatype === AttributeDatatype.Boolean && trigger.value != null)
	) {
		return true;
	}
	if (Array.isArray(trigger.value)) {
		return trigger.value.length > 0;
	}
	return trigger.value != null;
};

const areDynamicVariablesValid = (fields: ManualTriggerInputParam[]) => {
	const fieldsNames = new Set(fields.map(field => field.name));
	if (fieldsNames.size !== fields.length) {
		return false;
	}
	return fields.every(field => field.name?.trim() && field.data_type);
};

export const isManualValid = (trigger: TriggerStore): boolean => {
	if (trigger.datatype === AttributeDatatype.String && trigger.value != null) {
		return true;
	}
	if (
		!(trigger as EventTriggerTasksManualParams).value?.name.trim().length ||
		!areDynamicVariablesValid((trigger as EventTriggerTasksManualParams).value?.vars || [])
	) {
		return false;
	}
	return true;
};

export const isTimeBasedValid = (trigger: TriggerStore) => {
	return trigger.value != null && trigger.units;
};

export const isEventHappenedValid = (trigger: TriggerStore) => {
	const isMultiSelect = !!trigger.findTriggerMetadata.values;

	if (trigger.linkingKey) {
		if (trigger.datatype === AttributeDatatype.String) {
			// can accept trigger with no values
			if (!trigger.value) return true;

			// once array of values is set, string value is needed
			if (Array.isArray(trigger.value)) {
				return (trigger.value as StringTriggerValues).every(value => !!value.value);
			}

			return false;
		}

		return isMultiSelect ? (trigger.value as number[])?.length : trigger.value;
	}

	return true;
};
