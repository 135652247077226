import { useMemo } from 'react';

import { EmailTemplateUpdateRequest, TemplateContentType, TemplateTypeEnum } from '@bringg/types';

import { useStores } from 'bringg-web/recipes';
import useTemplatesStore from '../store/helpers/use-templates-store';
import { templateLanguagesFieldName } from '../consts';

export const CONTENT_INITIAL_VALUE =
	'<html>\n<head>\n<style>\n<!-- Put your CSS here -->\n</style>\n</head>\n<body>\n<p>Put your EMAIL content here</p>\n</body>\n</html>';

export interface UseInitialValues {
	initialValues: EmailTemplateUpdateRequest;
}

export const useInitialValues = templateId => {
	const { merchantCustomerNotificationsStore } = useStores();
	const {
		templatesStore: { getMappedToServer }
	} = useTemplatesStore();

	const DEFAULT_VALUES = useMemo<EmailTemplateUpdateRequest>(
		() => ({
			template_type: TemplateTypeEnum.customer_notification_email,
			name: '',
			// content_type hardcoded until business logic won't be resolved
			content_type: TemplateContentType['text/x-handlebars-template'],
			[templateLanguagesFieldName]: [
				{
					subject: '',
					content: CONTENT_INITIAL_VALUE,
					language_code: merchantCustomerNotificationsStore.defaultLanguageCode
				}
			]
		}),
		[merchantCustomerNotificationsStore.defaultLanguageCode]
	);

	const initialValues = useMemo<EmailTemplateUpdateRequest>(() => {
		if (templateId) {
			return getMappedToServer(templateId);
		} else {
			return DEFAULT_VALUES;
		}
	}, [templateId]);

	return initialValues;
};
