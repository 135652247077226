import React from 'react';

import { useQuery } from '@bringg-frontend/hooks';

import { Portal } from '@bringg-frontend/bringg-web-infra';
import ApplicationConfigurationForm from './application-configuration-form';

export const PortalApplicationConfigurationForm = () => {
	const appUuid = useQuery().get('appUuid') as Bringg.ApplicationUuid;
	return (
		<Portal
			element={<ApplicationConfigurationForm appUuid={appUuid} />}
			nodeId="application-configuration-portal"
		/>
	);
};
