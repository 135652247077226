import { EmployeeStateFilter } from './types';

const key = 'displayDriversFilterLabel';

export function saveFilterState(employeeStateFilter: EmployeeStateFilter): void {
	localStorage.setItem(key, employeeStateFilter);
}

export function getPersistentFilterState(): EmployeeStateFilter | null {
	return localStorage.getItem(key) as EmployeeStateFilter;
}

export function resetPersistentFilterState() {
	localStorage.removeItem(key);
}
