import { DeliveryBlockTemplate, DeliveryBlockTemplateBreak } from '@bringg/types';
import { DeliveryBlockBreakBase } from 'bringg-web/features/delivery-blocks-v2/new-modal/breaks/break-types';
import DeliveryBlock from 'bringg-web/features/delivery-blocks-v2/stores/domain-objects/delivery-block';
import moment from 'moment';

const init = (
	deliveryBlock: Partial<DeliveryBlock> | DeliveryBlockTemplate,
	updateDeliveryBlock: <T>(key: string, value: T) => void
) => {
	const isTemplate = (deliveryBlock as DeliveryBlockTemplate).template_breaks !== undefined;

	const deliveryBlockTemplate = deliveryBlock as DeliveryBlockTemplate;

	const deliveryBlockEditable = deliveryBlock as DeliveryBlock;

	const onAddTemplateBreak = (breakItem: DeliveryBlockTemplateBreak) => {
		updateDeliveryBlock('template_breaks', [...deliveryBlockTemplate.template_breaks, breakItem]);
	};

	const onAddDeliveryBlockBreak = (breakItem: DeliveryBlockBreakBase) => {
		updateDeliveryBlock('delivery_block_breaks', [
			...(deliveryBlock as DeliveryBlock).delivery_block_breaks,
			breakItem
		]);
	};

	const onAddBreak = (breakItem: DeliveryBlockBreakBase) => {
		if (isTemplate) {
			onAddTemplateBreak(breakItem as DeliveryBlockTemplateBreak);
		} else {
			onAddDeliveryBlockBreak(breakItem as DeliveryBlockBreakBase);
		}
	};

	const onDeleteBreak = (breakItem: DeliveryBlockBreakBase) => {
		if (isTemplate) {
			onDeleteTemplateBreak(breakItem as DeliveryBlockTemplateBreak);
		} else {
			onDeleteDeliveryBlockBreak(breakItem as DeliveryBlockBreakBase);
		}
	};

	const onDeleteTemplateBreak = (breakItem: DeliveryBlockTemplateBreak) => {
		baseDeleteBreak(breakItem, deliveryBlockTemplate.template_breaks, 'template_breaks');
	};
	const onDeleteDeliveryBlockBreak = (breakItem: DeliveryBlockBreakBase) => {
		baseDeleteBreak(breakItem, deliveryBlockEditable.delivery_block_breaks, 'delivery_block_breaks');
	};
	const baseDeleteBreak = (breakItem: DeliveryBlockBreakBase | DeliveryBlockTemplateBreak, breaks, key) => {
		const filteredBreaks = breaks.filter(brk => brk !== breakItem);
		updateDeliveryBlock(key, filteredBreaks);
	};

	const onUpdateTemplateBreak = (breakIndex: number, updatedProperties: Partial<DeliveryBlockTemplateBreak>) => {
		const breaks = deliveryBlockTemplate.template_breaks;

		breaks[breakIndex] = { ...breaks[breakIndex], ...updatedProperties };
		updateDeliveryBlock('template_breaks', breaks);
	};

	const onUpdateDeliveryBlockBreak = (breakIndex: number, updatedProperties: Partial<DeliveryBlockBreakBase>) => {
		const breaks = deliveryBlockEditable.delivery_block_breaks as Bringg.DeliveryBlockBreak[];

		breaks[breakIndex] = { ...breaks[breakIndex], ...updatedProperties };
		updateDeliveryBlock('delivery_block_breaks', breaks);
	};

	const onUpdateBreak = (breakIndex: number, updatedProperties: Partial<DeliveryBlockBreakBase>) => {
		if (isTemplate) {
			onUpdateTemplateBreak(breakIndex, updatedProperties);
		} else {
			onUpdateDeliveryBlockBreak(breakIndex, updatedProperties);
		}
	};

	const getUpdatedBreaksDates = (date: string, timezone: string) => {
		const deliveryBlockDate = moment(date).tz(timezone);
		const breaks = isTemplate ? deliveryBlockTemplate.template_breaks : deliveryBlockEditable.delivery_block_breaks;

		return breaks.map(item => {
			const startTime = moment(item.start_time)
				.set({
					year: deliveryBlockDate.year(),
					month: deliveryBlockDate.month(),
					date: deliveryBlockDate.date()
				})
				.toISOString();
			const endTime = moment(item.end_time)
				.set({
					year: deliveryBlockDate.year(),
					month: deliveryBlockDate.month(),
					date: deliveryBlockDate.date()
				})
				.toISOString();
			return { ...item, start_time: startTime, end_time: endTime };
		});
	};

	return { onUpdateBreak, onDeleteBreak, onAddBreak, getUpdatedBreaksDates };
};

export const breakEditor = { init };
