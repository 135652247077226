import { EndOfWeekDay } from '@bringg/types';
import moment, { Moment } from 'moment-timezone';
import { dateUtils } from '@bringg-frontend/utils';

import {
	CutoffEnum,
	DailyCutoff,
	RelativeCutoff
} from 'bringg-web/features/planned-delivery-windows/modals/slots-modal/wizard-steps/configuration-step/time-slots/cutoff-radio-group/cutoff.types';
import {
	PlannedDeliveryWindowCreate,
	PlannedDeliveryWindowOmitted
} from 'bringg-web/features/planned-delivery-windows/modals/slots-modal/planned-delivery-windows-slots.consts';
import { PlannedDeliveryWindowsSlots } from 'bringg-web/features/planned-delivery-windows/modals/slots-modal/wizard-steps/table-step/table-step';
import { cutoffCalculation } from 'bringg-web/features/planned-delivery-windows/services/cutoff-calculation';
import { endOfWeekDayService } from 'bringg-web/services/end-of-week-day/end-of-week-day';
import ServicePlansStore from 'bringg-web/stores/service-plans-store/service-plans-store';

interface GenerateProps {
	startTimeInMinutes: number;
	endTimeInMinutes: number;
	durationInMinutes: number;
	daysInWeek: EndOfWeekDay[];
	plannedDeliveryWindowEditable: PlannedDeliveryWindowOmitted;
	cutoff: RelativeCutoff | DailyCutoff;
	timezone: string;
}

const generateWindowSlots = ({
	startTimeInMinutes,
	endTimeInMinutes,
	durationInMinutes,
	daysInWeek,
	plannedDeliveryWindowEditable,
	cutoff,
	timezone
}: GenerateProps): PlannedDeliveryWindowsSlots[] => {
	const windowsInDays: PlannedDeliveryWindowsSlots[] = [];
	const endOfWeekDayToMomentISODay = endOfWeekDayService.getMomentISODayMap();

	daysInWeek.forEach(dayInWeek => {
		const { momentDay } = endOfWeekDayToMomentISODay.get(dayInWeek);

		const startTimeByDayInWeek = dateUtils
			.addMinutesToLastMonday(startTimeInMinutes, timezone)
			.isoWeekday(momentDay);
		const endTimeByDayInWeek = dateUtils.addMinutesToLastMonday(endTimeInMinutes, timezone).isoWeekday(momentDay);

		const startTimeByDayInWeekInMinutes = dateUtils.diffMinutesFromLastMonday(startTimeByDayInWeek);
		const endTimeByDayInWeekInMinutes = dateUtils.diffMinutesFromLastMonday(endTimeByDayInWeek);

		const result = checkAndGetEffectiveStartDate(
			startTimeByDayInWeek,
			plannedDeliveryWindowEditable.effective_start_date,
			plannedDeliveryWindowEditable.effective_end_date,
			timezone
		);

		if (!result.success) {
			return;
		}

		for (
			let startTime = startTimeByDayInWeekInMinutes;
			startTime < endTimeByDayInWeekInMinutes;
			startTime += durationInMinutes
		) {
			windowsInDays.push({
				...plannedDeliveryWindowEditable,
				effective_start_date: result.date.tz(timezone).startOf('day').toISOString(),
				series_effective_start_date: result.date.tz(timezone).startOf('day').toISOString(),
				start_time: startTime,
				end_time:
					startTime + durationInMinutes <= endTimeByDayInWeekInMinutes
						? startTime + durationInMinutes
						: startTime + (endTimeByDayInWeekInMinutes - startTime),
				day: dateUtils.addMinutesToLastMonday(startTime, timezone).weekday(),
				cutoff: cutoff
			});
		}
	});

	return windowsInDays;
};

const checkAndGetEffectiveStartDate = (
	date: Moment,
	start: string,
	end: string,
	timezone: string
): { success: boolean; date?: Moment } => {
	const dateMoment = moment(date).tz(timezone).clone();
	const startMoment = moment(start).tz(timezone);
	const endMoment = moment(end).tz(timezone);

	if (!end) {
		while (!dateMoment.isAfter(startMoment)) {
			dateMoment.add(7, 'd');
		}
		return { success: true, date: dateMoment };
	}

	for (let i = dateMoment; i < endMoment; i.add(7, 'd')) {
		if (dateUtils.isBetween(i, [startMoment, endMoment])) {
			return { success: true, date: i };
		}
	}

	return { success: false };
};
const generateWindowsSlotsForCreation = (
	plannedDeliveryWindowsSlots: PlannedDeliveryWindowsSlots[],
	cutoffType: CutoffEnum,
	name: string,
	servicePlanStore: ServicePlansStore,
	format: string,
	timezone: string
): PlannedDeliveryWindowCreate[] => {
	const getName = (
		name: string,
		windowToView: PlannedDeliveryWindowsSlots,
		servicePlanStore: ServicePlansStore,
		format: string
	): string => {
		if (name.trim()) return name;
		const startTimeWithEndTimeString = `${dateUtils
			.addMinutesToLastMonday(windowToView.start_time, timezone)
			.format(format)} - ${dateUtils.addMinutesToLastMonday(windowToView.end_time, timezone).format(format)}`;
		if (!windowToView.service_plan_id) {
			return startTimeWithEndTimeString;
		}
		const servicePlanName = servicePlanStore.servicePlans.get(windowToView.service_plan_id).name;
		return `${servicePlanName} ${startTimeWithEndTimeString}`;
	};

	return plannedDeliveryWindowsSlots.map(windowToView => {
		const updatedWindowToView = {
			...windowToView,
			name: getName(name, windowToView, servicePlanStore, format),
			cutoff:
				cutoffType === CutoffEnum.RELATIVE
					? cutoffCalculation.calculateRelative(windowToView.cutoff as RelativeCutoff)
					: cutoffCalculation.calculateDaily(
							windowToView.cutoff as DailyCutoff,
							windowToView.start_time,
							timezone
					  )
		};
		delete updatedWindowToView.day;
		return updatedWindowToView;
	});
};
export const plannedDeliveryWindowsSlotsGenerator = { generateWindowSlots, generateWindowsSlotsForCreation };
