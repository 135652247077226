import { useCallback, useMemo, useState } from 'react';

import moment from 'moment';
import { noop } from 'lodash';
import {
	Button,
	DateRangePicker,
	DaysInWeek,
	Form,
	FormItem,
	InputNumber,
	Radio,
	Space,
	TimeRangePicker,
	useForm
} from '@bringg/react-components';
import { Notification } from '@bringg/react-components';
import { EndOfWeekDay } from '@bringg/types';

import { useStores } from 'bringg-web/recipes';
import Dialog from '../../components/dialog/dialog';
import RemoveDialog from './remove-dialog';
import UpdateDialog from './update-dialog';
import { CalendarEvent, isoWeekdayToEndOfWeekday, validateOverlapping } from '../data-utils';
import OverrideDialog from './override-dialog';
import { useSpeedFactorTranslation } from '../translations';

export const allWeek = [
	EndOfWeekDay.Sunday,
	EndOfWeekDay.Monday,
	EndOfWeekDay.Tuesday,
	EndOfWeekDay.Wednesday,
	EndOfWeekDay.Friday,
	EndOfWeekDay.Saturday,
	EndOfWeekDay.Thursday
];

const timeFormat = 'HH:mm';

const getTimeFrame = event => {
	if (!event) return 'specificHours';

	const originalEvent = event.originalEvent;
	// TODO: Should this support TZ?
	if (originalEvent.interval_start === 0 && originalEvent.interval_end === 24) {
		return 'allDay';
	}

	return 'specificHours';
};

const currentDayOfWeek: EndOfWeekDay = isoWeekdayToEndOfWeekday(moment().isoWeekday());

// NOTE: this will be refactored more on the next iteration
const initForm = (event: CalendarEvent, timezone: string) => {
	// TODO: think about setting applicableDays from event if exists
	const applicableDays = [currentDayOfWeek];

	const effectiveDates = event
		? [moment(event.originalStart), moment(event.originalEnd)]
		: [moment().startOf('year'), moment().endOf('year')];

	const intervals = event
		? [
				moment.utc(`${event.originalEvent.interval_start}:00`, timeFormat).tz(timezone),
				moment.utc(`${event.originalEvent.interval_end}:00`, timeFormat).tz(timezone)
		  ]
		: [moment.tz('6:00', timeFormat, timezone), moment.tz('9:00', timeFormat, timezone)];

	const factor = event?.originalEvent ? (event.originalEvent.factor * 100 - 100).toFixed() : 0;

	const timeFrame = getTimeFrame(event);

	return {
		applicableDays,
		effectiveDates,
		intervals,
		factor,
		timeFrame
		// TODO: Probably ServiceAreaId could be a part of a form state (aka EventFormData type)
		// serviceAreaId
	};
};

const EventDialog = ({ open, onSubmit, onCancel, event, speedFactors, serviceAreaId, timezone }) => {
	const { merchantConfigurationsStore, serviceAreasStore } = useStores();

	const [daysInWeek, setDaysInWeek] = useState<EndOfWeekDay[]>([currentDayOfWeek]);

	const [timeFrame, setTimeFrame] = useState(getTimeFrame(event));
	const editable = !!event;

	const [loading, setLoading] = useState(false);
	const [openRemove, setOpenRemove] = useState(false);
	const [openUpdate, setOpenUpdate] = useState(false);
	const [openOverride, setOpenOverride] = useState(false);

	const translations = useSpeedFactorTranslation();

	const [form] = useForm();
	const initialValues = useMemo(() => initForm(event, timezone), [event, timezone]);

	const handleTimeFrameChange = e => {
		setTimeFrame(e.target.value);
		form.setFieldValue('timeFrame', e.target.value);
	};

	const handleCancel = () => {
		form.resetFields();
		onCancel();
	};

	const handleOk = useCallback(async (override = false) => {
		setLoading(true);

		if (!override) {
			const result = validateOverlapping(form.getFieldsValue(), speedFactors);

			if (!result.valid) {
				setOpenOverride(true);
				setLoading(false);

				return;
			}
		}

		try {
			const response = await serviceAreasStore.createSpeedFactors(
				// TODO: Probably ServiceAreaId could be a part of a form state
				{ service_area_id: serviceAreaId, ...form.getFieldsValue() },
				speedFactors,
				override
			);

			Notification.success(response.message);

			form.resetFields();
			onSubmit();
		} catch (error) {
			const message = error instanceof Error ? error.message : String(error);

			Notification.error(message);
		} finally {
			setLoading(false);
		}
	}, []);

	const handleRemove = () => {
		setOpenRemove(false);
		onSubmit();
	};

	const handleUpdate = () => {
		setOpenUpdate(false);
		onSubmit();
	};

	const closeRemove = () => {
		setOpenRemove(false);
	};

	const closeUpdate = () => {
		setOpenUpdate(false);
	};

	const closeOverride = () => {
		setOpenOverride(false);
	};

	if (!open) return null;

	return (
		<Dialog
			title={translations.eventTitle}
			subtitle={translations.eventSubtitle}
			open={open}
			handleOk={() => handleOk(false)}
			handleCancel={handleCancel}
			okText={translations.eventOk}
			okButtonProps={{ disabled: loading }}
			footer={
				editable && (
					<Footer
						onCancel={handleCancel}
						onRemove={() => setOpenRemove(true)}
						onUpdate={() => setOpenUpdate(true)}
					/>
				)
			}
		>
			<Form layout="vertical" form={form} initialValues={initialValues}>
				<FormItem label={translations.eventEffectiveDatesLabel}>
					<LabelHint>{translations.eventEffectiveDatesDescription}</LabelHint>
					<FormItem name="effectiveDates">
						<DateRangePicker
							startDate={initialValues.effectiveDates[0]}
							endDate={initialValues.effectiveDates[1]}
							translations={translations}
							onChange={noop}
							allowClear={false}
							allowEmpty={[false, false]}
							disabled={editable}
						/>
					</FormItem>
				</FormItem>

				<FormItem label={translations.eventFactorLabel}>
					<LabelHint>{translations.eventFactorDescription}</LabelHint>
					<FormItem name="factor">
						<InputNumber
							min={0}
							max={200}
							formatter={value => `${value}%`}
							parser={value => value!.replace('%', '')}
							onChange={noop}
							style={{ width: 120 }}
						/>
					</FormItem>
				</FormItem>

				{!editable && (
					<FormItem label={translations.eventApplicableDaysLabel} name="applicableDays">
						<DaysInWeek
							endOfWeekDay={merchantConfigurationsStore.endOfWeekDay}
							onChange={weekDays => setDaysInWeek(weekDays)}
							value={daysInWeek}
							translates={translations}
						/>
					</FormItem>
				)}

				<FormItem label={translations.eventTimeFrameLabel} name="timeFrame">
					<Radio.Group value={timeFrame} onChange={handleTimeFrameChange}>
						<Space direction="vertical" size="small">
							<Radio value="allDay" style={{ fontWeight: 400 }}>
								{translations.eventTimeFrameFullDay}
							</Radio>
							<Radio value="specificHours" style={{ fontWeight: 400 }}>
								{translations.eventTimeFrameHours}
							</Radio>
						</Space>
					</Radio.Group>
					{timeFrame === 'specificHours' && (
						<FormItem name="intervals" style={{ marginLeft: '24px', marginTop: '12px' }}>
							<TimeRangePicker
								minuteStep={60}
								use12Hours={false}
								format={timeFormat}
								value={[moment.tz(timezone), moment.tz(timezone)]}
								disabledTime={() => ({
									disabledMinutes: () => [0, 20, 40]
								})}
								hideDisabledOptions
								allowClear={false}
								placeholder={[translations.from, translations.to]}
							/>
						</FormItem>
					)}
				</FormItem>
			</Form>

			<RemoveDialog
				open={openRemove}
				onOk={handleRemove}
				onCancel={closeRemove}
				event={event}
				speedFactors={speedFactors}
			/>

			<UpdateDialog
				open={openUpdate}
				onOk={handleUpdate}
				onCancel={closeUpdate}
				event={event}
				values={form.getFieldsValue()}
				speedFactors={speedFactors}
			/>

			<OverrideDialog
				open={openOverride}
				onOk={() => handleOk(true)}
				onCancel={closeOverride}
				loading={loading}
			/>
		</Dialog>
	);
};

const Footer = ({ onRemove, onCancel, onUpdate }) => {
	const translations = useSpeedFactorTranslation();

	return (
		<Space style={{ display: 'flex', justifyContent: 'space-between' }}>
			<Button onClick={onRemove}>{translations.eventRemove}</Button>
			<div>
				<Button onClick={onCancel}>{translations.eventCancel}</Button>
				<Button type="primary" onClick={onUpdate}>
					{translations.eventUpdate}
				</Button>
			</div>
		</Space>
	);
};

const LabelHint = ({ children }) => {
	return <div style={{ marginTop: '-8px', marginBottom: '4px', color: '#718096', fontSize: '12px' }}>{children}</div>;
};

export default EventDialog;
