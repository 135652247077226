import React, { useCallback, useEffect, useMemo, useState } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Controller, UseFormMethods } from 'react-hook-form';
import { InputWithTitle, Select, Option, ConfirmModal } from '@bringg/react-components';
import { useTranslation } from 'react-i18next';
import { Team } from '@bringg/types';
import classNames from 'classnames';

import useStores from '../../../../recipes/use-stores';
import withTitle from './with-title/with-title';
import countryList from './config/country-list';
import timeZoneList from './config/time-zone-list';
import { initTeamsList, TeamOptions } from './team-form-logic/team-form-logic';
import AddressAutoComplete from '../../../../components/address-auto-complete/address-auto-complete';
import { useIsAdmin } from '../../../../utils/privileges';
import { useHasFeatureFlag } from '../../../../utils/feature-flags';
import TeamZipCodesUpload from '../team-zip-codes/team-zip-codes-upload/team-zip-codes-upload';

import './team-info-form.scss';

interface TickedSelectedProps {
	value: string[];
}

const TickedSelect = ({ value, ...otherProps }: TickedSelectedProps) => {
	return (
		<Select {...otherProps} value={value}>
			{value?.map(row => (
				<Option key={row} value={row}>
					<div role="presentation" onClick={event => event.stopPropagation()}>
						{row}
					</div>
				</Option>
			))}
		</Select>
	);
};

const SelectWithTitle = withTitle(Select);
const TickedSelectWithTitle = withTitle(TickedSelect);
const AddressAutoCompleteWithTitle = withTitle(AddressAutoComplete);

interface Props {
	team: Team;
	form: UseFormMethods;
	onReload: () => void;
}

const TeamInfoForm: React.FC<Props> = ({ team, form, onReload }) => {
	const { t } = useTranslation();
	const { teamsStore } = useStores();
	// eslint-disable-next-line @typescript-eslint/unbound-method
	const { control, setValue, setError, clearErrors, register, errors } = form;
	const [parentTeamOptions, setParentTeamOptions] = useState<TeamOptions | null>(null);
	const isDisabled = !useIsAdmin();

	const hasSeparateZipCodes = useHasFeatureFlag('zip_codes_lookup_table');
	const zipCodesReachedLimit = hasSeparateZipCodes && team.service_zipcodes_reached_limit;

	const existingExternalIds = useMemo(() => {
		return new Set(teamsStore.all.map(item => item.external_id));
	}, [teamsStore.all]);

	const onSearchAddress = () => {
		setError('team.address', { type: 'manual', message: 'Address was not validated' });
	};

	const onSelectAddress = async (address, addressDetailsPromise) => {
		try {
			const { lat, lng } = await addressDetailsPromise;
			clearErrors(['team.address']);
			setValue('team.lat', lat, { shouldDirty: true });
			setValue('team.lng', lng, { shouldDirty: true });
			setValue('team.address', address, { shouldDirty: true });
		} catch (err) {
			setError('team.address', { type: 'manual', message: 'Address was not validated' });
			console.error(err);
		}
	};

	const [isZipCodesModalVisible, setIsZipCodesModalVisible] = useState<boolean>(false);

	const openImportModal = () => {
		ConfirmModal({
			title: t('TEAM.ZIP_CODES_UPLOAD.CONFIRM_TITLE'),
			content: t('TEAM.ZIP_CODES_UPLOAD.CONFIRM_DESCRIPTION'),
			okText: t('TEAM.ZIP_CODES_UPLOAD.CONFIRM_OK'),
			okType: 'primary',
			cancelText: t('TEAM.ZIP_CODES_UPLOAD.CONFIRM_CANCEL'),
			onOk() {
				setIsZipCodesModalVisible(true);
			}
		});
	};

	useEffect(() => {
		initTeamsList(teamsStore, setParentTeamOptions);
	}, [teamsStore]);

	useEffect(() => {
		register({ name: 'team.address' });
	}, [register]);

	const handleExternalIdChange = useCallback(
		(
			event: React.ChangeEvent<HTMLInputElement>,
			name: string,
			onChange: React.ChangeEventHandler<HTMLInputElement>
		) => {
			if (event.target.value !== team.external_id && existingExternalIds.has(event.target.value)) {
				setError(name, {
					type: 'manual',
					message: t('TEAM.FORM_ERRORS.EXTERNAL_ID')
				});
			} else {
				clearErrors(name);
			}

			onChange(event);
		},
		[teamsStore.all]
	);

	return (
		team &&
		parentTeamOptions && (
			<div className="team-info-form">
				<Controller
					disabled
					render={({ value, onChange }) => (
						<InputWithTitle
							placeholder={t('GLOBAL.ID')}
							disabled
							containerClassName="form-item item-width"
							value={value}
							onChange={onChange}
							id="teamId"
						/>
					)}
					control={control}
					name="team.id"
					defaultValue={team.id}
				/>
				<Controller
					disabled={isDisabled}
					render={({ value, onChange }) => (
						<InputWithTitle
							containerClassName="form-item item-width"
							placeholder={t('GLOBAL.NAME')}
							value={value}
							onChange={onChange}
							id="teamName"
						/>
					)}
					control={control}
					name="team.name"
					defaultValue={team.name}
				/>
				<Controller
					disabled={isDisabled}
					render={({ value, onChange }) => (
						<InputWithTitle
							placeholder={t('GLOBAL.DESCRIPTION')}
							containerClassName="form-item item-width"
							value={value}
							onChange={onChange}
							id="teamDescription"
						/>
					)}
					control={control}
					name="team.description"
					defaultValue={team.description}
				/>
				<AddressAutoCompleteWithTitle
					disabled={isDisabled}
					className="form-item item-width"
					placeholder={t('TEAM.ADDRESS')}
					onSearch={onSearchAddress}
					onSelect={onSelectAddress}
					defaultValue={team.address}
					name="team.address"
					id="teamAddressInput"
				/>
				<Controller
					disabled={isDisabled}
					render={({ value, onChange }) => (
						<InputWithTitle
							placeholder={t('TEAM.LAT')}
							containerClassName="form-item half-item-width"
							value={value}
							onChange={onChange}
							id="teamLat"
						/>
					)}
					name="team.lat"
					type="number"
					control={control}
					defaultValue={team.lat}
				/>
				<Controller
					disabled={isDisabled}
					render={({ value, onChange }) => (
						<InputWithTitle
							placeholder={t('TEAM.LNG')}
							containerClassName="form-item half-item-width"
							value={value}
							onChange={onChange}
							id="teamLng"
						/>
					)}
					control={control}
					name="team.lng"
					type="number"
					defaultValue={team.lng}
				/>
				<Controller
					disabled={isDisabled}
					render={({ value, onChange, name }) => (
						<InputWithTitle
							placeholder={t('TEAM.EXTERNAL_ID')}
							containerClassName={classNames('form-item item-width', {
								'invalid-field': errors?.[name]
							})}
							value={value}
							onChange={event => handleExternalIdChange(event, name, onChange)}
							id="teamExternalId"
						/>
					)}
					control={control}
					name="team.external_id"
					defaultValue={team.external_id}
				/>
				<Controller
					disabled={isDisabled}
					render={({ value, onChange }) => (
						<InputWithTitle
							placeholder={t('TEAM.CONTACT_PHONE')}
							containerClassName="form-item item-width"
							value={value}
							onChange={onChange}
							id="teamContactPhone"
						/>
					)}
					control={control}
					name="team.contact_phone"
					defaultValue={team.contact_phone}
				/>
				<Controller
					disabled={isDisabled}
					render={({ value, onChange }) => (
						<SelectWithTitle
							placeholder={t('TEAM.PARENT_TEAM')}
							className="form-item item-width"
							id="teamParent"
							value={value}
							onChange={onChange}
						>
							{Object.entries(parentTeamOptions).map(([idString, name]) => {
								const id = parseInt(idString, 10);
								return (
									<Option key={id} value={id}>
										{name}
									</Option>
								);
							})}
						</SelectWithTitle>
					)}
					name="team.parent_team_id"
					control={control}
					defaultValue={team.parent_team_id}
				/>
				<Controller
					disabled={isDisabled}
					render={({ value, onChange }) => (
						<SelectWithTitle
							className="form-item half-item-width"
							id="teamCountry"
							placeholder={t('TEAM.COUNTRY_CODE')}
							value={value}
							onChange={onChange}
						>
							{countryList.map(({ value: countryValue, text }) => {
								return (
									<Option key={countryValue} value={countryValue}>
										{text}
									</Option>
								);
							})}
						</SelectWithTitle>
					)}
					control={control}
					defaultValue={team.country_code}
					name="team.country_code"
				/>
				<Controller
					disabled={isDisabled}
					render={({ value, onChange }) => (
						<SelectWithTitle
							placeholder={t('GLOBAL.TIMEZONE')}
							className="form-item half-item-width"
							id="teamTimeZone"
							value={value}
							onChange={onChange}
						>
							{timeZoneList.map(({ value: tzValue, text }) => {
								return (
									<Option key={tzValue} value={tzValue}>
										{text}
									</Option>
								);
							})}
						</SelectWithTitle>
					)}
					name="team.time_zone"
					control={control}
					defaultValue={team.time_zone}
				/>
				<Controller
					disabled={isDisabled}
					render={({ value, onChange }) => (
						<TickedSelectWithTitle
							mode="tags"
							maxTagCount="responsive"
							placeholder={t('TEAM.SERVICE_CITIES')}
							className="form-item item-width remove-padding"
							id="serviceCities"
							value={value}
							notFoundContent={t('TEAM.NO_CITIES')}
							onChange={onChange}
							autoFocus
							getPopupContainer={() => document.querySelector('.team-info')}
						/>
					)}
					name="team.service_cities"
					control={control}
					defaultValue={team.service_cities}
				/>
				<Controller
					disabled={isDisabled}
					render={({ value, onChange }) => (
						<TickedSelectWithTitle
							mode="tags"
							showSearch
							maxTagCount="responsive"
							placeholder={t('TEAM.SERVICE_ZIPCODES')}
							className={classNames('form-item remove-padding item-width', {
								'zip-codes-section': hasSeparateZipCodes
							})}
							onTitleClick={hasSeparateZipCodes ? openImportModal : undefined}
							clickText={t('TEAM.IMPORT_SERVICE_ZIPCODES')}
							id="serviceZipCodes"
							value={value}
							notFoundContent={t('TEAM.NO_ZIPCODES')}
							onChange={onChange}
							disabled={zipCodesReachedLimit}
							getPopupContainer={() => document.querySelector('.team-info')}
							autoFocus
						/>
					)}
					name="team.service_zipcodes"
					control={control}
					defaultValue={team.service_zipcodes}
				/>
				{isZipCodesModalVisible && (
					<TeamZipCodesUpload
						team={team}
						afterUploadDone={onReload}
						afterModalClose={() => setIsZipCodesModalVisible(false)}
					/>
				)}
				<Controller
					disabled={isDisabled}
					render={({ value, onChange }) => (
						<TickedSelectWithTitle
							mode="tags"
							maxTagCount="responsive"
							placeholder={t('TEAM.SERVICE_BOROUGHS')}
							defaultValue={team.service_boroughs}
							className="form-item remove-padding item-width"
							id="serviceBoroughs"
							value={value}
							notFoundContent={t('TEAM.NO_SERVICE_BOROUGHS')}
							onChange={onChange}
							getPopupContainer={() => document.querySelector('.team-info')}
							autoFocus
						/>
					)}
					name="team.service_boroughs"
					control={control}
					defaultValue={team.service_boroughs}
				/>
				<Controller
					disabled={isDisabled}
					render={({ value, onChange }) => (
						<InputWithTitle
							placeholder={t('TEAM.EMERGENCY_CONTACT_NAME')}
							containerClassName="form-item item-width"
							value={value}
							onChange={onChange}
							id="teamEmergencyContactName"
						/>
					)}
					control={control}
					name="team.emergency_contact_name"
					defaultValue={team.emergency_contact_name}
				/>
				<Controller
					disabled={isDisabled}
					render={({ value, onChange }) => (
						<InputWithTitle
							placeholder={t('TEAM.EMERGENCY_CONTACT_PHONE')}
							containerClassName="form-item item-width"
							value={value}
							onChange={onChange}
							id="teamEmergencyContactPhone"
						/>
					)}
					control={control}
					name="team.emergency_contact_phone"
					defaultValue={team.emergency_contact_phone}
				/>
			</div>
		)
	);
};

export default TeamInfoForm;
