import { ActionType, ExternalCommunicationData, InternalCommunicationData } from '@bringg/types';
import { findMismatchLabels } from 'bringg-web';

import AlertActionStore from '../../stores/alert-action-store';
import { ClientAlertData } from 'bringg-web/features/automation-workflows/utils/types';

export const isAlertActionValid = (alertAction: AlertActionStore) => {
	return alertAction.actions.length > 0 && areActionsValid(alertAction.actions, alertAction.placeholdersSet);
};

export const areActionsValid = (actions: ClientAlertData[], placeholdersSet?: Set<string>) => {
	return actions.every(action => {
		const title = action.data?.title;
		if (action.action_type !== ActionType.WEBHOOK && !title) {
			return false;
		}
		let mismatch;
		switch (action.action_type) {
			case ActionType.ALERT:
				mismatch = findMismatchLabels(title || '', placeholdersSet);
				return !!action.data.role && !mismatch.length;

			case ActionType.EMAIL:
				return (
					(action.data as ExternalCommunicationData).recipient ||
					((action.data as InternalCommunicationData).role &&
						((action.data as InternalCommunicationData).isRoleBased ||
							(action.data as InternalCommunicationData).userIds.length > 0))
				);
			case ActionType.INTERNAL_SMS:
				return action.data.role && (action.data.isRoleBased || action.data.userIds.length > 0);

			default:
				return true;
		}
	});
};
