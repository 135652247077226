import toUpper from 'lodash/toUpper';
import { camelCaseToSnakeCase } from '@bringg-frontend/utils';

export interface WebhookData {
	key?: string;
	condition?: Condition;
	urls?: string[];
	uuid?: string;
	isNew?: boolean;
	model_config?: WebhookAMC;
	enableFlexibleWebhook?: boolean;
	newStructure?: boolean;
	webhook_authentication_configuration_id?: number;
	headers?: Record<string, string>;
}

export interface WebhookFieldsTree {
	tree: Tree;
	rootId: string;
	nodeValues: NodeValues;
	mandatoryNodeIds?: string[];
}

export interface Tree {
	[nodeId: string]: TreeNode;
}

export interface TreeNode {
	option: string;
	children: string[];
	parentId: string;
}

export interface NodeValues {
	[nodeId: string]: boolean;
}

export type WebhookModels = WebhookModel[];

export interface Models {
	[modelName: string]: WebhookModel;
}

export interface WebhookModel {
	name: string;
	allowedFields: string[];
	// from the backend the webhook model can only be an array of strings
	allowedRelations: (string | { identifier: string; name: string })[];
	defaultFields: string[];
	defaultRelations: string[];
}

export interface WebhookAMC {
	name: string;
	fields?: string[];
	relations?: WebhookAMC[];
}

export interface Condition {
	key?: string;
	value?: string;
}

export interface WebhookMerchantConfigurationData {
	webhooks?: WebhookData[];
	headers?: Headers;
	authenticator?: any;
}

export interface Headers {
	[key: string]: string;
}

export interface ApplicationConfiguration {
	webhooks: WebhookConfig[];
}

export interface WebhookConfig {
	key: string;
	title: string;
	fields: Field[];
	testable: boolean;
	baseModel: string;
	mandatoryFields: string[];
	virtualFields: string[];
	onlyFlexible?: boolean;
}

export interface Field {
	key: string;
	title: string;
}

export function webhookTypeTranslationKey(key): string {
	return `WEBHOOK.${toUpper(camelCaseToSnakeCase(key))}`;
}
