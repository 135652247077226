import React from 'react';

import { FilterBar2, FilterBar2ListItem, FilterBarTranslations } from '@bringg/react-components';
import { useStores } from 'bringg-web/recipes';
import { useTranslation } from 'react-i18next';

const EMPTY_FILTERS = {
	fullTextSearch: null,
	vehicleCategory: null
};

const FilterBar = ({ vehicleCategories }) => {
	const { t } = useTranslation();
	const { vehicleTypesStore } = useStores();
	const list: FilterBar2ListItem[] = [
		{
			type: FilterBar2.TYPES.SEARCH,
			name: 'fullTextSearch',
			title: t('VEHICLE_PROFILES.FILTER_BAR.SEARCH'),
			placeholder: t('VEHICLE_PROFILES.FILTER_BAR.SEARCH_PLACEHOLDER')
		},
		{
			type: FilterBar2.TYPES.SELECT,
			name: 'vehicleCategory',
			title: t('VEHICLE_PROFILES.FILTER_BAR.VEHICLE_CATEGORY'),
			placeholder: t('VEHICLE_PROFILES.FILTER_BAR.VEHICLE_CATEGORY_PLACEHOLDER'),
			multiselect: false,
			values: vehicleCategories.map(vehicleCategory =>
				vehicleCategory.type === 'vehicle_category'
					? { value: vehicleCategory.id, label: vehicleCategory.name }
					: {
							value: vehicleCategory.name,
							label: vehicleCategory.name
					  }
			)
		}
	];

	const handleDone = value => {
		vehicleTypesStore.setFilters(value);
	};

	const onReset = field => {
		if (field === 'vehicleCategory') {
			vehicleTypesStore.setFilters({ ...vehicleTypesStore.filters, vehicleCategory: null });
		} else if (field === undefined) {
			vehicleTypesStore.setFilters(EMPTY_FILTERS);
		}
	};

	const translations = {
		reset: t('VEHICLE_PROFILES.FILTER_BAR.RESET'),
		moreFilters: t('VEHICLE_PROFILES.FILTER_BAR.MORE_FILTERS'),
		done: t('VEHICLE_PROFILES.FILTER_BAR.DONE'),
		cancel: t('VEHICLE_PROFILES.FILTER_BAR.CANCEL'),
		search: '',
		all: ''
	} as unknown as FilterBarTranslations;

	return (
		<FilterBar2
			list={list}
			translations={translations}
			resetToInitial
			applyFilterImmediately={true}
			onFilter={handleDone}
			onReset={onReset}
			className="vehicle-profiles-page--filter-bar"
		/>
	);
};

export default FilterBar;
