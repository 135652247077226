import React, { Component } from 'react';
import { FieldData as FieldDataBringgEntity, ActionData, ActionFormData as ActionFormDataEntity } from '@bringg/types';
import { Col, Row, Input, Collapse } from '@bringg/react-components';
import { isNil, includes as _includes, omit as _omit, first as _first, isNull as _isNull } from 'lodash';
import { v4 as uuidv4 } from 'uuid';

import DraggableList from '../../../components/draggable-list/draggable-list';
import FieldData from '../field-data/field-data';
import ValuesList from '../values-list/values-list';
import { BooleanOnlyFieldTypes, ControlFormatType, FieldComponent, fieldTypes } from '../field-data/field-data.consts';
import Translate from '../../../translation/translator';
import ActionFormDataHeader from '../action-form-data-header/action-form-data-header';

type FieldDataEntity = FieldDataBringgEntity & {
	uuid: string;
};

interface Props {
	actionsData: ActionData;
	onFieldUpdate: (updatedFields: Partial<ActionData>) => void;
}

interface State {
	fieldsData: FieldDataEntity[];
	title: string;
	expended: string[];
}

const HEADER_TITLE_MAX_LENGTH = 35;

const { Panel } = Collapse;

export default class ActionFormData extends Component<Props, State> {
	// eslint-disable-next-line react/sort-comp
	getActionsFormData = (): ActionFormDataEntity => {
		const defaultData = { fields: [] };
		return Object.assign(defaultData, this.props.actionsData.data || {});
	};

	state = {
		fieldsData: this.getActionsFormData().fields.map(fieldData => Object.assign({ uuid: uuidv4() }, fieldData)),
		title: this.getActionsFormData().title || '',
		expended: []
	};

	onFieldMove = (fromIndex: number, toIndex: number) => {
		if (!_isNull(fromIndex) && !_isNull(toIndex)) {
			const fieldsData = [...this.state.fieldsData];

			const fieldToMove = _first(fieldsData.splice(fromIndex, 1));
			fieldsData.splice(toIndex, 0, fieldToMove);

			this.setAndNotify(fieldsData);
		}
	};

	getTitle = (fieldData: FieldDataEntity): string => {
		const fieldType = fieldTypes.get(fieldData.control);
		const fieldName = fieldType ? fieldType.name : '';
		let labelText = fieldData.label_title || fieldData.label || '';

		if (labelText.length > HEADER_TITLE_MAX_LENGTH) {
			labelText = `${labelText.substring(0, HEADER_TITLE_MAX_LENGTH)}...`;
		}

		return `${labelText} (${fieldName})`;
	};

	includeListValues = (fieldData: FieldDataEntity): boolean => {
		const fieldType = fieldTypes.get(fieldData.control);
		const components = fieldType ? fieldType.components : [];
		return components.includes(FieldComponent.LIST_VALUES);
	};

	fieldUpdate = (index: number, updatedFields: FieldDataBringgEntity) => {
		// Try to avoid proxy state between component and store
		let updatedFieldsWithSideEffects = updatedFields;
		const fieldsData = [...this.state.fieldsData];
		const fieldToUpdate = fieldsData[index];

		if (fieldToUpdate) {
			if (!isNil(updatedFields.control) && BooleanOnlyFieldTypes.includes(updatedFields.control)) {
				updatedFieldsWithSideEffects = {
					...updatedFieldsWithSideEffects,
					type: ControlFormatType.Boolean
				};
			}

			Object.assign(fieldToUpdate, updatedFieldsWithSideEffects);
			this.setAndNotify(fieldsData);
		}
	};

	deleteField = (index: number) => {
		const { fieldsData } = this.state;
		fieldsData.splice(index, 1);

		this.setAndNotify(fieldsData);
	};

	onCollapseChanged = (expended: string | string[]) => {
		this.setState({ expended: Array.isArray(expended) ? expended : [expended] });
	};

	addField = () => {
		const { fieldsData } = this.state;
		fieldsData.push({ uuid: uuidv4(), control: 0, type: 0, mandatory: false, list_values: [] });
		this.setAndNotify(fieldsData);
	};

	setAndNotify = (fieldsData: FieldDataEntity[]) => {
		const fields = fieldsData.map(field => _omit(field, 'uuid'));

		this.setState({ fieldsData });
		this.props.onFieldUpdate({ data: { fields, title: this.state.title } });
	};

	setTitle = event => {
		const fields = this.state.fieldsData.map(field => _omit(field, 'uuid'));

		this.setState({ title: event.target.value });
		this.props.onFieldUpdate({ data: { title: event.target.value, fields } });
	};

	render() {
		const { expended } = this.state;

		return (
			<div className="action-form-data">
				<Row className="title-row">
					<div>
						<Translate text="ACTION_CONFIGURATION.FORM_TITLE" />
					</div>
					<Input
						placeholder=""
						type="text"
						onChange={this.setTitle}
						value={this.state.title}
						data-test-id="action-data-form-title"
						className="action-data-input"
					/>
				</Row>
				<DraggableList onFieldMove={this.onFieldMove} data-test-id="action-data-fields-list">
					{this.state.fieldsData.map((fieldData: FieldDataEntity, index) => (
						<Collapse
							data-test-id="action-data-field"
							bordered={false}
							key={fieldData.uuid}
							defaultActiveKey={expended}
							className="field-data-collapse"
							onChange={this.onCollapseChanged}
						>
							<Panel
								key={fieldData.uuid}
								showArrow={false}
								header={
									<ActionFormDataHeader
										data-test-id="action-data-field-header"
										onDeleteField={() => this.deleteField(index)}
										title={this.getTitle(fieldData)}
										isExtended={_includes(expended, fieldData.uuid)}
									/>
								}
							>
								<Row justify="end">
									<Col span={10}>
										<FieldData
											fieldData={fieldData}
											onFieldUpdated={updatedFields => {
												this.fieldUpdate(index, updatedFields);
											}}
										/>
									</Col>
									<Col span={12}>
										{this.includeListValues(fieldData) ? (
											<ValuesList
												data-test-id="action-data-values-list"
												valuesList={fieldData.list_values || []}
												onListUpdated={list => {
													this.fieldUpdate(index, list);
												}}
											/>
										) : null}
									</Col>
								</Row>
							</Panel>
						</Collapse>
					))}
				</DraggableList>
				<div className="add-value" onClick={this.addField} data-test-id="action-data-add-field">
					<Translate text="ACTION_CONFIGURATION.ADD_FIELD" />
				</div>
			</div>
		);
	}
}
