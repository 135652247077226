import React, { useEffect, useState } from 'react';

import classNames from 'classnames';
import { Collapse } from '@bringg/react-components';
import { Options } from 'rrule';
import { BringgFontIcons, BringgIcon } from '@bringg/bringg-icons';
import { EndOfWeekDay } from '@bringg/types';
import { useTranslation } from 'react-i18next';

import { Frequencies } from './frequency/frequencies';
import { EndsAt } from './ends-at/ends-at';
import { daysMap, DEFAULT_FREQUENCY_OPTIONS, RECURRING_NO_REPEAT_FREQUENCY } from './recurrence.const';
import * as rruleService from '../../../../services/rrule/rrule-service';

import './rcurrence-panel.scss';

const { Panel } = Collapse;

export interface Props {
	props?: any;
	key: number | string;
	ical: string;
	onIcalChange: (ical: string) => void;
	timezone: string;
	deliveryBlockDate: number;
	endOfWeekDay: EndOfWeekDay;
	hideEndsAt: boolean;
	recurringFrequencies?: number[];
	shouldBeDisabled?: boolean;
}

export const RecurrencePanel = ({
	ical,
	onIcalChange,
	timezone,
	deliveryBlockDate,
	endOfWeekDay,
	hideEndsAt,
	recurringFrequencies,
	shouldBeDisabled = false,
	...props
}: Props) => {
	const { t } = useTranslation();
	const [rruleOptions, setRRuleOptions] = useState<Partial<Options>>(rruleService.parseStringToRRuleOptions(ical));

	useEffect(() => {
		setRRuleOptions(rruleService.parseStringToRRuleOptions(ical));
	}, [ical]);

	const getHeader = () => {
		return (
			<div className="panel-title">
				<BringgIcon iconName={BringgFontIcons.Undo} className="panel-title-icon" />
				<span className="panel-title-translate">{t('DELIVERY_BLOCK_MODAL.RECURRENCES.RECURRENCES')}</span>
				<span className="recurrence-summary">
					{ical ? t('DELIVERY_BLOCK_MODAL.RECURRENCES.ACTIVE') : t('DELIVERY_BLOCK_MODAL.RECURRENCES.NO_SET')}
				</span>
			</div>
		);
	};

	const handleRRuleOptionsChange = options => {
		if (options.interval < 1) {
			options.interval = 1;
		}
		onIcalChange(rruleService.makeRRuleString(options));
	};

	const onRecurrenceTypeSelect = (frequency: number) => {
		if (frequency === RECURRING_NO_REPEAT_FREQUENCY) {
			handleRRuleOptionsChange({});
			return;
		}

		const { count, until } = rruleOptions;

		handleRRuleOptionsChange({ count, until, freq: frequency, ...DEFAULT_FREQUENCY_OPTIONS[frequency] });
	};

	const onIntervalChange = (interval: number) => {
		handleRRuleOptionsChange({ ...rruleOptions, interval });
	};

	const onAtChange = (bymonthday: number) => {
		handleRRuleOptionsChange({ ...rruleOptions, bymonthday });
	};

	const onWeekDaysChange = (weekDays: number[]) => {
		const byweekday = weekDays.map(weekDay => daysMap.get(weekDay));
		handleRRuleOptionsChange({ ...rruleOptions, byweekday });
	};

	const onUntilChanged = (until: Date | null) => {
		handleRRuleOptionsChange({ ...rruleOptions, count: null, until });
	};
	const onCountChanged = (count: number | null) => {
		handleRRuleOptionsChange({ ...rruleOptions, count, until: null });
	};

	return (
		<Panel
			{...props}
			header={getHeader()}
			className="delivery-block-panel recurrence-panel disable-when-select-is-open"
		>
			<div
				className={classNames('recurrence-items', shouldBeDisabled ? 'disabled' : '')}
				data-test-id="recurrence-items"
			>
				<Frequencies
					rruleOptions={rruleOptions}
					onRecurrenceTypeSelect={onRecurrenceTypeSelect}
					onAtChange={onAtChange}
					onIntervalChange={onIntervalChange}
					onWeekDaysChange={onWeekDaysChange}
					deliveryBlockDate={deliveryBlockDate}
					endOfWeekDay={endOfWeekDay}
					recurringFrequencies={recurringFrequencies}
				/>
				{!hideEndsAt && ical !== '' && (
					<EndsAt
						timezone={timezone}
						rruleOptions={rruleOptions}
						onUntilChanged={onUntilChanged}
						onCountChanged={onCountChanged}
					/>
				)}
			</div>
		</Panel>
	);
};
