import React, { ChangeEvent, Component } from 'react';
import { FieldData as FieldDataEntity } from '@bringg/types';
import { BringgTextArea, Col, Input, Row } from '@bringg/react-components';

import { hasRegexValidation } from 'bringg-web/features/actions-data/field-data/field-data-utils';
import { Option, SelectWithStickyPopup as Select } from '../../../components';
import { Translate } from '../../../translation';
import { controlFormatTypes, FieldComponent, fieldTypes, FieldTypesWithDisabledFormat } from './field-data.consts';
import DynamicLabel from './dynamic-label/dynamic-label';

interface Props {
	fieldData: FieldDataEntity;
	onFieldUpdated: (updatedField: Partial<FieldDataEntity>) => void;
}

export default class FieldData extends Component<Props> {
	updateKey = (event: any): void => {
		this.props.onFieldUpdated({ key: event.target.value });
	};

	updateDisclaimer = (updateEvent: ChangeEvent<HTMLInputElement>): void => {
		this.props.onFieldUpdated({ disclaimer: updateEvent.target.value });
	};

	updateLabel = (updateEvent: ChangeEvent<HTMLTextAreaElement>): void => {
		this.props.onFieldUpdated({ label: updateEvent.target.value });
	};

	render() {
		const { fieldData, onFieldUpdated } = this.props;
		const controlType = fieldTypes.get(fieldData.control);
		const components: FieldComponent[] = controlType ? controlType.components : [];
		const hasRegexFields = hasRegexValidation(fieldData);

		return (
			<div className="field-data">
				<div>
					<Translate text="ACTION_CONFIGURATION.CONTROL" />
				</div>
				<Select
					onSelect={control => onFieldUpdated({ control })}
					value={fieldData.control}
					data-test-id="action-data-field-control"
					className="action-data-input"
				>
					{Array.from(fieldTypes.entries()).map(fieldType => (
						<Option
							key={fieldType[1].name}
							value={fieldType[0]}
							disabled={fieldType[1].disabled}
							data-test-id="action-data-field-control-option"
						>
							{fieldType[1].name}
						</Option>
					))}
				</Select>
				<Col span={8}>
					{components.includes(FieldComponent.LABEL) ? (
						<Row>
							<div>
								<Translate text="ACTION_CONFIGURATION.FIELD_LABEL" />:
							</div>
							<BringgTextArea
								onChange={this.updateLabel}
								value={fieldData.label}
								data-test-id="action-data-field-label"
								className="action-data-input"
								autoSize
							/>
						</Row>
					) : null}
					{components.includes(FieldComponent.DYNAMIC_LABEL) ? (
						<DynamicLabel fieldData={fieldData} onFieldUpdated={onFieldUpdated} />
					) : null}
					{components.includes(FieldComponent.MANDATORY) ? (
						<Row>
							<div>
								<Translate text="ACTION_CONFIGURATION.MANDATORY" />:
							</div>
							<Select
								onSelect={mandatory => onFieldUpdated({ mandatory: mandatory === 'true' })}
								value={String(fieldData.mandatory)}
								data-test-id="action-data-field-mandatory"
								className="action-data-input"
							>
								<Option key="true" value="true" data-test-id="action-data-field-mandatory-option">
									True
								</Option>
								<Option key="false" value="false" data-test-id="action-data-field-mandatory-option">
									False
								</Option>
							</Select>
						</Row>
					) : null}
					{components.includes(FieldComponent.DEFAULT_VALUE) ? (
						<Row>
							<div>
								<Translate text="ACTION_CONFIGURATION.DEFAULT_VALUE" />:
							</div>
							<Input
								placeholder=""
								type="text"
								onChange={e => onFieldUpdated({ default_value: e.target.value })}
								value={fieldData.default_value}
								className="action-data-input"
								data-test-id="action-data-default-value"
							/>
						</Row>
					) : null}
					{components.includes(FieldComponent.TYPE) ? (
						<Row>
							<div>
								<Translate text="ACTION_CONFIGURATION.CONTROL_TYPE" />:
							</div>
							<Select
								disabled={FieldTypesWithDisabledFormat.includes(fieldData.control)}
								onSelect={type => onFieldUpdated({ type })}
								value={fieldData.type}
								data-test-id="action-data-control-type"
								className="action-data-input"
							>
								{Array.from(controlFormatTypes.entries()).map(type => (
									<Option
										key={type[1]}
										value={type[0]}
										data-test-id="action-data-control-type-option"
									>
										{type[1]}
									</Option>
								))}
							</Select>
						</Row>
					) : null}
					{hasRegexFields ? (
						<>
							<Row>
								<div>
									<Translate text="ACTION_CONFIGURATION.REGEX" />
								</div>
								<Input
									placeholder=""
									type="text"
									onChange={e => onFieldUpdated({ regex: e.target.value })}
									value={fieldData.regex}
									className="action-data-input"
									data-test-id="action-data-regex"
								/>
							</Row>
							<Row>
								<div>
									<Translate text="ACTION_CONFIGURATION.REGEX_ERROR_MESSAGE" />
								</div>
								<Input
									placeholder=""
									type="text"
									onChange={e => onFieldUpdated({ regex_error_message: e.target.value })}
									value={fieldData.regex_error_message}
									className="action-data-input"
									data-test-id="action-data-regex-error-message"
								/>
							</Row>
						</>
					) : null}
					{components.includes(FieldComponent.DISCLAIMER) ? (
						<Row>
							<div>
								<Translate text="ACTION_CONFIGURATION.DISCLAIMER" />:
							</div>
							<Input
								placeholder=""
								type="text"
								onChange={this.updateDisclaimer}
								value={fieldData.disclaimer}
								data-test-id="action-data-field-disclaimer"
								className="action-data-input"
							/>
						</Row>
					) : null}
					<Row>
						<div>
							<Translate text="ACTION_CONFIGURATION.KEY" />:
						</div>
						<div className="flex">
							{!fieldData.key && <label className="required-field" />}
							<Input
								placeholder=""
								type="text"
								onChange={this.updateKey}
								value={fieldData.key}
								className={`action-data-input ${!fieldData.key && 'red-border'}`}
								data-test-id="action-data-field-key"
							/>
						</div>
					</Row>
				</Col>
			</div>
		);
	}
}
