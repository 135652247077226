import { Task, TaskTypeId } from '@bringg/types';

export const tasksById = (tasks: Task[]) => {
	const tasksToObject = {};
	tasks.forEach(task => {
		tasksToObject[task.id] = task;
	});
	return tasksToObject;
};

export const isMultiTeamTasksSelected = (tasks: Task[]) => {
	if (tasks?.length === 0 || tasks[0]?.team_ids?.length === 0) {
		return false;
	}
	const firstTeamId = tasks[0]?.team_ids?.[0];
	return tasks.some(task => firstTeamId !== task.team_ids?.[0]);
};

export const isTaskClickAndCollect = (task: Task) => task.task_type_id === TaskTypeId.CLICK_AND_COLLECT;

export const openOrderPage = (taskId: number) => {
	window.open(`${window.location.origin}/#/history/${taskId}`, '_blank');
};
