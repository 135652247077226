interface SmsNotification {
	title: string;
	placeholder: string;
	privateModeField?: string;
	enableOptionType: string;
	messageType: string;
	selectOptions?: CheckoutOption[];
}

interface CheckoutOption {
	label: string;
	value: number;
}

export const alertOnCheckoutOptions: CheckoutOption[] = [
	{
		label: 'Never',
		value: 0
	},
	{
		label: 'On every checkout',
		value: 1
	},
	{
		label: 'On every checkout if was not rated',
		value: 2
	}
];

export const DISPLAYED_NOTIFICATION_COLLECTION: { [key: string]: SmsNotification } = {
	alert_on_start: {
		title: 'CUSTOMER_CONFIGURATION_VIEW.ORDER_STARTED_SMS',
		placeholder: 'CUSTOMER_CONFIGURATION_VIEW.ORDER_STARTED_SMS_PLACEHOLDER',
		privateModeField: 'private_mode_on_start',
		enableOptionType: 'alert-on-start',
		messageType: 'start'
	},
	alert_on_eta_delay: {
		title: 'CUSTOMER_CONFIGURATION_VIEW.LATE_SMS',
		placeholder: 'CUSTOMER_CONFIGURATION_VIEW.ALERT_ON_ETA_DELAY_PLACEHOLDER',
		enableOptionType: 'alert-on-eta-delay',
		messageType: 'eta_delay'
	},
	alert_on_task_created: {
		title: 'CUSTOMER_CONFIGURATION_VIEW.ORDER_CREATED_SMS',
		placeholder: 'CUSTOMER_CONFIGURATION_VIEW.ORDER_CREATED_SMS_PLACEHOLDER',
		privateModeField: 'private_mode_on_task_created',
		enableOptionType: 'checkbox',
		messageType: 'created'
	},
	alert_customer_on_schedule_ready: {
		title: 'CUSTOMER_CONFIGURATION_VIEW.SCHEDULE_SMS',
		placeholder: 'CUSTOMER_CONFIGURATION_VIEW.SCHEDULE_SMS_PLACEHOLDER',
		privateModeField: 'private_mode_on_schedule_ready',
		enableOptionType: 'checkbox',
		messageType: 'schedule_ready'
	},
	alert_on_route_started: {
		title: 'CUSTOMER_CONFIGURATION_VIEW.ROUTE_STARTED_SMS',
		placeholder: 'CUSTOMER_CONFIGURATION_VIEW.ROUTE_STARTED_SMS_PLACEHOLDER',
		privateModeField: 'private_mode_on_route_started',
		enableOptionType: 'checkbox',
		messageType: 'route'
	},
	alert_on_checkin: {
		title: 'CUSTOMER_CONFIGURATION_VIEW.ORDER_ARRIVED_SMS',
		placeholder: 'CUSTOMER_CONFIGURATION_VIEW.ORDER_ARRIVED_SMS_PLACEHOLDER',
		privateModeField: 'private_mode_on_checkin',
		enableOptionType: 'checkbox',
		messageType: 'checked_in'
	},
	alert_on_checkout: {
		title: 'CUSTOMER_CONFIGURATION_VIEW.ORDER_LEFT_SMS',
		placeholder: 'CUSTOMER_CONFIGURATION_VIEW.ORDER_LEFT_SMS_PLACEHOLDER',
		privateModeField: 'private_mode_on_checkout',
		selectOptions: alertOnCheckoutOptions,
		enableOptionType: 'select',
		messageType: 'checked_out'
	},
	alert_customer_on_reschedule: {
		title: 'CUSTOMER_CONFIGURATION_VIEW.RESCHEDULE_SMS',
		placeholder: 'CUSTOMER_CONFIGURATION_VIEW.RESCHEDULE_SMS_PLACEHOLDER',
		privateModeField: 'private_mode_on_reschedule',
		enableOptionType: 'checkbox',
		messageType: 'reschedule'
	},
	alert_on_task_schedule: {
		title: 'CUSTOMER_CONFIGURATION_VIEW.SCHEDULING_SMS',
		placeholder: 'CUSTOMER_CONFIGURATION_VIEW.SCHEDULING_SMS_PLACEHOLDER',
		enableOptionType: 'checkbox',
		messageType: 'schedule'
	},
	alert_on_customer_pickup: {
		title: 'CUSTOMER_CONFIGURATION_VIEW.CUSTOMER_PICKUP_SMS',
		placeholder: 'CUSTOMER_CONFIGURATION_VIEW.CUSTOMER_PICKUP_SMS_PLACEHOLDER',
		enableOptionType: 'checkbox',
		messageType: 'customer_pickup'
	}
};

export const EDITABLE_NOTIFICATION_TYPES = Object.keys(DISPLAYED_NOTIFICATION_COLLECTION);
