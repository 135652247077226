import { action, computed, makeObservable, observable } from 'mobx';
import i18next from 'i18next';
import { getRoot } from 'mobx-easy';
import {
	ActionCoreData,
	ActionType,
	ShareLocationData,
	SharedLocationActionMetadata,
	SharingMethod
} from '@bringg/types';

import RootStore from 'bringg-web/stores/root-store';
import { ActionsRepo } from './internal';
import ActionBaseStore from './action-base-store';
import { ActionFamilyType, ClientShareLocationAction } from '../utils/types';
import { findMismatchLabels } from '../../../components/text-template';

class CustomerNotificationActionStore extends ActionBaseStore {
	type = ActionFamilyType.COSTUMER_NOTIFICATION;
	actionType: ActionType;

	translation: ShareLocationData['sms']['translation'];
	sharingMethod: ShareLocationData['sharing_method'];
	privateMode: ShareLocationData['private_mode'];

	constructor(actionToInit: ClientShareLocationAction, actionsRepo: ActionsRepo) {
		super(actionsRepo);
		makeObservable(this, {
			translation: observable,
			sharingMethod: observable,
			privateMode: observable,
			setTranslation: action,
			initTranslation: action,
			togglePrivateMode: action,
			resetPrivateMode: action,
			title: computed
		});

		this.sharingMethod = actionToInit.sharingMethod ?? SharingMethod.Phone;
		this.translation = actionToInit.translation;
		this.privateMode = actionToInit.privateMode;
		this.actionType = ActionType.SHARED_LOCATION;

		this.initTranslation();
	}

	initTranslation = () => {
		const {
			merchantCustomerNotificationsStore: { defaultLanguageCode }
		} = getRoot<RootStore>().data;

		if (!this.translation[defaultLanguageCode]) {
			this.translation[defaultLanguageCode] = {
				body: ''
			};
		}
	};

	get mappedToServer(): ActionCoreData {
		return {
			action_type: ActionType.SHARED_LOCATION,
			data: {
				sms: {
					translation: this.translation
				},
				sharing_method: this.sharingMethod,
				private_mode: this.privateMode
			}
		};
	}

	get isValid(): boolean {
		const {
			merchantCustomerNotificationsStore: { defaultLanguageCode }
		} = getRoot<RootStore>().data;

		if (!this.translation[defaultLanguageCode]) {
			return false;
		}
		const languageCodes = Object.keys(this.translation);
		if (!languageCodes.length) {
			return false;
		}

		return languageCodes.every(code => {
			const mismatch = findMismatchLabels(this.translation[code].body, this.placeholdersSet);
			return !!this.translation[code].body && !mismatch.length;
		});
	}

	setTranslation(code: string, text?: string) {
		if (text == null) {
			delete this.translation[code];
			return;
		}
		this.translation[code] = {
			body: text
		};
	}

	setSharingMethod(sharingMethod: ShareLocationData['sharing_method']) {
		this.sharingMethod = sharingMethod;
	}

	resetPrivateMode = () => {
		this.privateMode = undefined;
	};

	togglePrivateMode = () => {
		this.privateMode = !this.privateMode;
	};

	get title(): string {
		const titleKey = (this.actionData as SharedLocationActionMetadata).translation_string;
		return i18next.t(titleKey);
	}
}

export default CustomerNotificationActionStore;
