import React, { useState, useEffect } from 'react';

import moment from 'moment';
import { BringgFontIcons, BringgIcon } from '@bringg/bringg-icons';

import useAutomationTranslations from '../../../../utils/use-automation-translations';
import { fetchUser } from 'bringg-web/services/users';
import useDateFormat from 'bringg-web/hooks/use-date-format';

import styles from './summary-info.module.scss';

interface Props {
	updatedAt: string;
	updatedByUserId: number;
}

const SummaryInfos = ({ updatedAt, updatedByUserId }: Props) => {
	const dateFormat = useDateFormat();
	const { updatedBy, updated } = useAutomationTranslations();
	const [user, setUser] = useState(null);

	useEffect(() => {
		let userChangedOrUnmounted = false;
		const fetchCreator = async () => {
			const userData = await fetchUser(updatedByUserId);

			if (!userChangedOrUnmounted) {
				setUser(userData);
			}
		};

		fetchCreator();

		return () => {
			userChangedOrUnmounted = true;
		};
	}, [updatedByUserId]);

	return (
		<div className={styles.createSummary}>
			<span className={styles.summaryInfo}>
				{updatedBy}: <span className={styles.infoName}>{user?.name || 'Bringg'}</span>
			</span>
			<BringgIcon iconName={BringgFontIcons.SmallCircle} />
			<span className={styles.summaryInfo}>
				{updated}: <span className={styles.infoDate}>{moment(updatedAt).format(dateFormat)}</span>
			</span>
		</div>
	);
};

export default SummaryInfos;
