import { getRootEnv } from '@bringg-frontend/bringg-web-infra';
import { action, observable, makeObservable } from 'mobx';
import { getEnv } from 'mobx-easy';
import { RootEnv } from '@bringg-frontend/bringg-web-infra';

import TagCustomerConfiguration from './tag-customer-configuration';

class TagCustomerConfigurationsStore {
	configurations: Map<number, TagCustomerConfiguration> = new Map();

	constructor() {
		makeObservable(this, {
			configurations: observable.shallow,
			set: action
		});
	}

	set = (configuration: Partial<Bringg.TagCustomerConfiguration>) => {
		this.configurations.set(configuration.tag_id, new TagCustomerConfiguration(configuration));
	};

	getByTag(tagId: number): TagCustomerConfiguration {
		return this.configurations.get(tagId);
	}

	async fetch(tagId: number): Promise<TagCustomerConfiguration> {
		const result = await getRootEnv().dashboardSdk.sdk.tagCustomerConfigurations.getByTag(tagId);
		if (result.tag_customer_configuration) {
			this.set(result.tag_customer_configuration);
			return this.getByTag(tagId);
		}
		return null;
	}
}

export default TagCustomerConfigurationsStore;
