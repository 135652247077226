import { getRootEnv, generateStoreHelpers } from '@bringg-frontend/bringg-web-infra';
import { WorkflowsApi } from '@bringg/dashboard-sdk';

import { MetadataRepo, WorkflowStore, TemplateBoardViewStore, TemplateRepo } from './internal';
import { workflowsStoreMapper } from '../data-providers/workflows-store-mapper';
import AutomationBoardViewStore from '../automations-board/store/automation-board-view-store';
import PredefinedAlertRepo from './alerts-configuration/predefined-alert-repo';
import WorkflowAlertsStore from './alerts-configuration/workflow-alerts-store';

const initializationMap = new Map<string, any>([
	['templateRepo', TemplateRepo],
	['metadataRepo', MetadataRepo],
	['workflowsQueryViewStore', AutomationBoardViewStore],
	['templatesQueryViewStore', TemplateBoardViewStore],
	['predefinedAlertRepo', PredefinedAlertRepo],
	['workflowAlertsStore', WorkflowAlertsStore]
]);

class WorkflowsRoot {
	private static _instance?: WorkflowsRoot;

	workflowRepo: WorkflowsApi<WorkflowStore>;
	templateRepo: TemplateRepo;
	metadataRepo: MetadataRepo;
	workflowsQueryViewStore: AutomationBoardViewStore;
	templatesQueryViewStore: TemplateBoardViewStore;
	predefinedAlertRepo: PredefinedAlertRepo;
	workflowAlertsStore: WorkflowAlertsStore;

	public static get instance(): WorkflowsRoot {
		if (!WorkflowsRoot._instance) {
			WorkflowsRoot._instance = new WorkflowsRoot();
			WorkflowsRoot._instance.init();
		}

		return WorkflowsRoot._instance;
	}

	public static get isInitialized(): boolean {
		return !!WorkflowsRoot._instance;
	}

	init() {
		this.workflowRepo = getRootEnv().dashboardSdk.sdk.v2.createWorkflows<WorkflowStore>(workflowsStoreMapper.map);

		// eslint-disable-next-line no-restricted-syntax
		for (const [key, Clazz] of initializationMap.entries()) {
			this[key] = new Clazz();
		}
	}

	static reset() {
		// Reset reactions
		WorkflowsRoot._instance?.workflowRepo?.getAll()?.forEach(workflow => {
			workflow?.dispose();
		});
		WorkflowsRoot._instance?.metadataRepo?.dispose();
		WorkflowsRoot._instance = undefined;
	}
}

export type WorkflowsRootStore = WorkflowsRoot;

export const workflowsRootStore = {
	getStore: () => WorkflowsRoot.instance,
	clearStore: () => WorkflowsRoot.reset(),
	isInitialized: () => WorkflowsRoot.isInitialized
};
export const { useStoreContext: useWorkflowsRootStore, withRootStore: withWorkflowsRootStore } =
	generateStoreHelpers(workflowsRootStore);
