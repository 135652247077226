import React, { useCallback, useEffect, useMemo } from 'react';

import { observer, useObserver } from 'mobx-react';
import { Button, Col, Input, Row, Select, Tooltip } from '@bringg/react-components';
import { useTranslation } from 'react-i18next';

import DeliveryBlocksNavigator from './navigator/delivery-blocks-navigator';
import DeliveryBlockToolbarView, { TimeRange } from './delivery-block-toolbar-view';
import { Translate } from '../../../translation';
import { useDeliveryBlocksStore } from '../stores/delivery-blocks-root-store';
import { timezoneProvider } from 'bringg-web/services/timezone/timezone-provider';

interface Props {
	onTeamSelect: (teamId: number, timeRange: TimeRange) => void;
	onAddClick: () => void;
	deliveryBlockToolbarView?: DeliveryBlockToolbarView;
	onViewModeChanged: (viewMode: DeliveryBlocksViewMode) => void;
	viewMode: DeliveryBlocksViewMode;
	onFilterChange?: (searchTerm: string) => void;
	isEditable: boolean;
}

export enum DeliveryBlocksViewMode {
	Week,
	Day
}

const DeliveryBlocksToolbar: React.FC<Props> = ({
	onTeamSelect,
	onAddClick,
	deliveryBlockToolbarView,
	viewMode,
	onViewModeChanged,
	onFilterChange,
	isEditable
}) => {
	const { t } = useTranslation();
	const { deliveryBlocksStore, deliveryBlocksView } = useDeliveryBlocksStore();

	useEffect(() => {
		return () => deliveryBlockToolbarView.timeRangeDisposer();
	}, [deliveryBlockToolbarView]);

	const handleTeamSelect = useCallback(
		teamId => {
			onTeamSelect(teamId, deliveryBlockToolbarView.timeRange);
		},
		[deliveryBlockToolbarView, onTeamSelect]
	);

	const deliveryBlockViewModeOptions = useMemo(
		() => [
			{ id: DeliveryBlocksViewMode.Week, name: t('DELIVERY_BLOCKS.WEEK_VIEW') },
			{ id: DeliveryBlocksViewMode.Day, name: t('DELIVERY_BLOCKS.DAY_VIEW') }
		],
		[t]
	);

	const handleViewModeChange = useCallback(
		(viewMode: DeliveryBlocksViewMode) => {
			if (viewMode === DeliveryBlocksViewMode.Day) {
				deliveryBlockToolbarView.setCurrentDay();
			} else {
				deliveryBlockToolbarView.setCurrentWeek();
			}

			onViewModeChanged(viewMode);
		},
		[deliveryBlockToolbarView, onViewModeChanged]
	);

	return useObserver(() => {
		const { timeRange, setNextWeek, setPrevWeek, setNextDay, setPrevDay } = deliveryBlockToolbarView;
		const teamTimezone = timezoneProvider.getTimezoneByTeamId(deliveryBlocksView.currentTeamId);

		const getAddBlockButton = () => {
			return (
				<Button
					className="delivery-blocks-add-block"
					type="primary"
					onClick={onAddClick}
					disabled={
						!isEditable ||
						deliveryBlocksStore.isFetching ||
						deliveryBlocksView.isFetching ||
						!deliveryBlocksView.hasAnyResource
					}
					loading={deliveryBlocksStore.isFetching || deliveryBlocksView.isFetching}
				>
					<Translate text="DELIVERY_BLOCKS.ADD_BLOCK" />
				</Button>
			);
		};

		return (
			<Row className="delivery-blocks-toolbar" justify="space-between" align="top">
				<Col className="left-section">
					{viewMode === DeliveryBlocksViewMode.Day && (
						<div className="day-view-filter">
							<Input
								placeholder={t('DELIVERY_BLOCKS.TYPE_TO_FILTER')}
								onChange={({ target }) => onFilterChange(target.value)}
							/>
						</div>
					)}
					<div className="selected-team">
						{deliveryBlocksView.allTeams.length > 0 && (
							<Select
								className="delivery-blocks-teams-select"
								defaultValue={
									deliveryBlocksView.currentTeam?.name || deliveryBlocksView.allTeams[0]?.name
								}
								options={deliveryBlocksView.allTeams}
								showSearch
								onSelect={handleTeamSelect}
								filterOption={(input, option) =>
									option.props.children.toLowerCase().includes(input.toLowerCase())
								}
							/>
						)}
						{teamTimezone && <div className="selected-team-timezone">{teamTimezone}</div>}
					</div>
				</Col>
				<Col className="right-section">
					<Select
						data-test-id="view-mode-selection"
						value={viewMode}
						className="view-mode-selection"
						options={deliveryBlockViewModeOptions}
						onSelect={handleViewModeChange}
					/>

					<DeliveryBlocksNavigator
						timeRange={timeRange}
						setNext={viewMode === DeliveryBlocksViewMode.Week ? setNextWeek : setNextDay}
						setPrevious={viewMode === DeliveryBlocksViewMode.Week ? setPrevWeek : setPrevDay}
						setTimeRange={
							viewMode === DeliveryBlocksViewMode.Week
								? deliveryBlockToolbarView.setWeekTimeRange
								: deliveryBlockToolbarView.setTimeRange
						}
						mode={viewMode}
					/>
					{!deliveryBlocksView.hasAnyResource &&
					!deliveryBlocksStore.isFetching &&
					!deliveryBlocksView.isFetching ? (
						<Tooltip title={t('DELIVERY_BLOCKS.TRY_AGAIN_MODAL_BODY_TEXT')}>
							<div className="delivery-block-add-block-button">{getAddBlockButton()}</div>
						</Tooltip>
					) : (
						<>{getAddBlockButton()}</>
					)}
				</Col>
			</Row>
		);
	});
};

export default observer(DeliveryBlocksToolbar);
