import React from 'react';

import { Button, Modal } from '@bringg/react-components';

import useAutomationTranslations from '../../utils/use-automation-translations';

import styles from './manual-trigger-force-action-modal.module.scss';

interface Props {
	onClose: () => void;
	allowToForceAllActions: boolean;
	allowToForceMatchingActions: boolean;
	numberOfSelectedTasks: number;
	failedTasks: { id: number; externalId: string }[];
	forceAction: (applyOnAll: boolean) => void;
}

const ManualTriggerForceActionModal = ({
	onClose,
	allowToForceAllActions,
	allowToForceMatchingActions,
	failedTasks,
	forceAction,
	numberOfSelectedTasks
}: Props) => {
	const {
		forceManualModalAction: { dismiss, description, runOnAll, runOnMatching, title, confirmText }
	} = useAutomationTranslations();

	const acceptManualTriggerMessage = (applyOnAll: boolean) => {
		forceAction(applyOnAll);
		onClose();
	};

	const allOrdersFailed = numberOfSelectedTasks === failedTasks.length;

	const getModalFooter = () => (
		<div className={styles.footerButtons}>
			{(allowToForceMatchingActions ||
				allowToForceAllActions ||
				(!allowToForceMatchingActions && !allowToForceAllActions)) && (
				<Button onClick={onClose} type="link">
					{dismiss}
				</Button>
			)}

			<div className={styles.actionButton}>
				{allowToForceMatchingActions && (
					<Button onClick={() => acceptManualTriggerMessage(false)} type="primary" disabled={allOrdersFailed}>
						{runOnMatching}
					</Button>
				)}

				{allowToForceAllActions && (
					<Button onClick={() => acceptManualTriggerMessage(true)} type="primary">
						{runOnAll}
					</Button>
				)}
			</div>
		</div>
	);

	return (
		<Modal
			visible
			title={<span className={styles.title}>{title}</span>}
			className={styles.manualTriggerModal}
			onCancel={onClose}
			footer={getModalFooter()}
		>
			<div className="modal-body">
				<div>{description}:</div>
				<div className={styles.tasksList}>{failedTasks.map(task => task.externalId).join(', ')}</div>
				<br />
				{(allowToForceMatchingActions || allowToForceAllActions) && <div>{confirmText}</div>}
			</div>
		</Modal>
	);
};

export default ManualTriggerForceActionModal;
