import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { FleetsList } from '@bringg/react-components/dist/features/fleet-router';
import { Fleet } from '@bringg/react-components/dist/features/fleet-router/rule-form/priority-configuration/priority-configuration';

import ConditionsSet from '../conditions-set/conditions-set';

import useFleetConfigurationStore from '../use-fleet-configuration-store';

interface Props {
	fleet?: Fleet;
}

const FleetsListComponent = ({ fleet }: Props) => {
	const { fleetsConfigurationStore } = useFleetConfigurationStore();
	const { fleets } = fleetsConfigurationStore;

	const fleetsList: Fleet[] = Object.values(fleets);
	const [selectedFleet, setSelectedFleet] = useState<Fleet>(null);

	const handleSelectFleet = fleetId => {
		setSelectedFleet(fleets[fleetId]);
	};

	useEffect(() => {
		if (fleet) {
			setSelectedFleet(fleet);
		}
	}, [fleet]);

	return (
		<div className="fleet-list-section">
			<FleetsList fleets={fleetsList} onSelect={handleSelectFleet} />
			{selectedFleet && <ConditionsSet fleet={selectedFleet} />}
		</div>
	);
};

export default observer(FleetsListComponent);
