import React from 'react';

import { Portal } from '@bringg-frontend/bringg-web-infra';

import OptimizationConfiguration from './optimization-configuration';

const OptimizationConfigurationPortal = () => {
	return <Portal element={<OptimizationConfiguration />} nodeId="optimization-configuration-portal" />;
};

export default OptimizationConfigurationPortal;
