import { getRootEnv } from '@bringg-frontend/bringg-web-infra';
import { useEffect, useState } from 'react';

import { BringgDashboardSDK } from '@bringg/dashboard-sdk';
import { getEnv } from 'mobx-easy';
import { RootEnv } from '@bringg-frontend/bringg-web-infra';

function useCompanies(): Bringg.Company[] {
	const dashboardSdk: BringgDashboardSDK = getRootEnv().dashboardSdk.sdk;

	const [companies, setCompanies] = useState<Bringg.Company[]>([]);

	useEffect(() => {
		if (!dashboardSdk) {
			setCompanies([] as Bringg.Company[]);
			return;
		}

		async function fetchCompanies() {
			const { companies } = await dashboardSdk.companies.getAll({});
			setCompanies(companies);
		}

		fetchCompanies();
	}, [dashboardSdk]);

	return companies;
}

export default useCompanies;
