import _ from 'lodash';

import {
	Models,
	NodeValues,
	Tree,
	WebhookAMC,
	WebhookConfig,
	WebhookFieldsTree,
	WebhookModel,
	WebhookModels
} from '../../webhooks.consts';

const _populateTree = (
	tree: Tree,
	models: Models,
	model: WebhookModel,
	identifier: string,
	parentId: string,
	parents: Set<string>
) => {
	const option = identifier || model?.name;
	const id = _.uniqueId();
	const children = [];

	if (option == null || model == null) return; //TODO: throw airbrake

	_.forEach(model.allowedFields, field => {
		const leafId = _.uniqueId();
		children.push(leafId);
		tree[leafId] = { option: field, children: null, parentId: id };
	});

	_.forEach(model.allowedRelations, relation => {
		const relationName = typeof relation === 'string' ? relation : relation.name;
		const relationIdentifier = typeof relation === 'string' ? relation : relation.identifier;
		if (parents.has(relationName)) {
			return;
		}

		const relationId = _populateTree(
			tree,
			models,
			models[relationName],
			relationIdentifier,
			id,
			new Set([...parents, relationName])
		);
		if (!_.isNil(relationId)) children.push(relationId);
	});

	tree[id] = { option, children, parentId };

	return id;
};

const _isRelationSelected = (parentModel: WebhookModel, relationName: string): boolean =>
	parentModel.defaultRelations.includes(relationName);

function* _traverseTreeWithAC(tree: Tree, nodeId: string, models: Models, modelName: string) {
	const node = tree?.[nodeId];
	const model = models?.[modelName];

	if (_.isNil(node) || _.isNil(node.children) || _.isNil(model)) {
		return;
	}

	for (const childId of node.children) {
		yield { id: childId, model };
		const childNode = tree[childId];
		const isRelation = !_.isEmpty(childNode.children);
		if (isRelation && _isRelationSelected(model, childNode.option)) {
			yield* _traverseTreeWithAC(tree, childId, models, childNode.option);
		}
	}
}

const _populateNodeValuesFromApplicationMerchantConfiguration = (
	tree: Tree,
	nodeId: string,
	webhookFieldsConfiguration: WebhookAMC,
	nodeValues: NodeValues
) => {
	const { children } = tree[nodeId];
	const fields = _.keyBy(webhookFieldsConfiguration?.fields, field => field);
	const relations = _.keyBy(webhookFieldsConfiguration?.relations, 'name');
	const [fieldIds, relationsIds] = _.partition(children, id => _.isEmpty(tree[id].children));

	_.forEach(fieldIds, id => {
		const childName = tree[id].option;
		nodeValues[id] = childName in fields;
	});

	_.forEach(relationsIds, id => {
		nodeValues[id] = false;
		const relationName = tree[id].option;
		const childRelations = relations[relationName];
		_populateNodeValuesFromApplicationMerchantConfiguration(tree, id, childRelations, nodeValues);
	});
};

export const _getNodeValuesFromAC = (
	tree: Tree,
	rootId: string,
	webhookModels: WebhookModels,
	modelName: string
): NodeValues => {
	const nodeValues = _.mapValues(tree, () => false);
	const models = _.keyBy(webhookModels, 'name');

	for (const { id, model } of _traverseTreeWithAC(tree, rootId, models, modelName)) {
		const nodeName = tree[id].option;
		nodeValues[id] = model.defaultFields.includes(nodeName);
	}
	return nodeValues;
};

export const _getNodeValuesFromAMC = (
	tree: Tree,
	rootId: string,
	webhookFieldsConfiguration: WebhookAMC
): NodeValues => {
	const nodeValues = _.mapValues(tree, () => false);
	_populateNodeValuesFromApplicationMerchantConfiguration(tree, rootId, webhookFieldsConfiguration, nodeValues);
	return nodeValues;
};

export const _getWebhookTreeWithMandatoryFields = (
	tree,
	rootId,
	nodeValues,
	mandatoryFields: string[]
): { tree: Tree; nodeValues: NodeValues; mandatoryNodeIds: string[] } => {
	const mandatoryFieldsWithIds = mandatoryFields.map(name => ({ name, id: _.uniqueId() }));

	const mandatoryNodeIds = mandatoryFieldsWithIds.map(({ id }) => id);
	const mandatoryNodes = mandatoryFieldsWithIds.reduce((acc, { id, name }) => {
		acc[id] = { option: name, children: null, parentId: rootId };
		return acc;
	}, {});
	const mandatoryNodeValues = _.mapValues(mandatoryNodes, () => true);

	const newTree = {
		...tree,
		[rootId]: {
			...tree[rootId],
			children: [...mandatoryNodeIds, ...tree[rootId].children]
		},
		...mandatoryNodes
	};

	const newNodeValues = {
		...nodeValues,
		...mandatoryNodeValues
	};

	return { tree: newTree, nodeValues: newNodeValues, mandatoryNodeIds };
};

export const _getTreeWithVirtualFields = (rootId: string, tree: Tree, virtualFields: string[]) => {
	const virtualFieldsWithIds = virtualFields.map(name => ({ name, id: _.uniqueId() }));
	const virtualFieldIds = virtualFieldsWithIds.map(({ id }) => id);
	const virtualFieldNodes = virtualFieldsWithIds.reduce((acc, { id, name }) => {
		acc[id] = { option: name, children: null, parentId: rootId };
		return acc;
	}, {});

	const enrichedTree = {
		...tree,
		...virtualFieldNodes,
		[rootId]: {
			...tree[rootId],
			children: [...virtualFieldIds, ...tree[rootId].children]
		}
	};

	return enrichedTree;
};

export const _getTree = (webhookModels: WebhookModels, modelName: string): { tree: Tree; rootId: string } => {
	const tree = {};
	let rootId = null;
	const models = _.keyBy(webhookModels, 'name');
	const rootModel = models?.[modelName];
	if (!_.isEmpty(rootModel)) {
		rootId = _populateTree(tree, models, rootModel, rootModel.name, null, new Set([modelName]));
	}

	return { tree, rootId };
};

export const formatTreeFromServer = (
	webhookType: WebhookConfig,
	webhookModels: WebhookModels,
	webhookAMC: WebhookAMC
): WebhookFieldsTree => {
	const modelName = webhookType.baseModel;
	const { tree, rootId } = _getTree(webhookModels, modelName);

	if (_.isNil(rootId)) {
		return { tree: null, nodeValues: null, rootId: null };
	}

	const virtualFields = webhookType.virtualFields || [];

	const enrichedTree = _getTreeWithVirtualFields(rootId, tree, virtualFields);

	const configNodeValues = webhookAMC
		? _getNodeValuesFromAMC(enrichedTree, rootId, webhookAMC)
		: _getNodeValuesFromAC(enrichedTree, rootId, webhookModels, modelName);
	const {
		tree: treeWithMandatoryFields,
		nodeValues,
		mandatoryNodeIds
	} = _getWebhookTreeWithMandatoryFields(enrichedTree, rootId, configNodeValues, webhookType.mandatoryFields);
	return { tree: treeWithMandatoryFields, rootId, nodeValues, mandatoryNodeIds };
};

export function formatTreeToSelectedFields(tree: Tree, nodeValues: NodeValues): Array<string[]> {
	return _.chain(nodeValues)
		.map((enabled: boolean, nodeId: string): string[] => {
			if (!enabled || !isLeaf(tree[nodeId])) {
				return;
			}

			return getPathToRoot(tree, nodeId);
		})
		.compact()
		.value();
}

function getPathToRoot(tree, nodeId): string[] {
	const fieldChain: string[] = [];

	let node = tree[nodeId];
	while (node) {
		fieldChain.unshift(node.option);
		node = tree[node.parentId];
	}

	return fieldChain;
}

function isLeaf(node): boolean {
	return _.isEmpty(node.children);
}

export const formatTreeToServer = (tree: Tree, nodeId: string, nodeValues: NodeValues): WebhookAMC => {
	if (_.isNil(tree) || _.isNil(nodeId) || _.isNil(nodeValues)) return null;

	const node = tree[nodeId];

	const [fieldIds, relationIds] = _.partition(node.children, childId => {
		return _.isEmpty(tree[childId].children);
	});

	const fields = _.chain(fieldIds)
		.filter(id => nodeValues[id])
		.map(id => tree[id].option)
		.value();

	const relations = _.reduce(
		relationIds,
		(acc, relationId) => {
			const selectedFields = formatTreeToServer(tree, relationId, nodeValues);
			if (!_.isEmpty(selectedFields)) acc.push(selectedFields);
			return acc;
		},
		[]
	);

	if (!_.isEmpty(fields) || !_.isEmpty(relations)) {
		const fieldConfig = { name: node.option } as WebhookAMC;
		if (!_.isEmpty(fields)) fieldConfig.fields = fields;
		if (!_.isEmpty(relations)) fieldConfig.relations = relations;
		return fieldConfig;
	}
};
