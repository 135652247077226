import React, { useState, useEffect, useMemo, useCallback, ReactNode } from 'react';
import { observer } from 'mobx-react';
import { Tabs, Spinner } from '@bringg/react-components';
import { Language as LanguageType } from '@bringg/types';

import { useStores } from 'bringg-web/recipes';
import { AddLanguageDropDown, TabTitle } from 'bringg-web/components/text-template';

import styles from './template-language.module.scss';

interface LanguageTabsProps {
	activeLanguageCode: string;
	languageCodesUsed: string[];
	isLoading?: boolean;
	setActiveLanguageCode: (code: string) => void;
	onAddLang: (code: string) => void;
	onRemoveLang: (code: string) => void;
	getTabContent: (code: string, index?: number) => ReactNode;
}

export const LanguageTabs = observer((props: LanguageTabsProps) => {
	const {
		onAddLang,
		onRemoveLang,
		languageCodesUsed,
		getTabContent,
		activeLanguageCode,
		setActiveLanguageCode,
		isLoading
	} = props;
	const { notificationTemplatesStore, merchantCustomerNotificationsStore } = useStores();

	const [languagesLoading, setLanguagesLoading] = useState(true);
	const allLanguagesMap = useMemo<Map<string, Bringg.Language>>(() => {
		return new Map(notificationTemplatesStore.availableLanguages.map(lang => [lang.code, lang]));
	}, [notificationTemplatesStore.availableLanguages]);

	const selectedLanguages = useMemo<LanguageType[]>(() => {
		if (!allLanguagesMap.size) {
			return [];
		}

		return languageCodesUsed.map(langCode => {
			return allLanguagesMap.get(langCode);
		});
	}, [allLanguagesMap, languageCodesUsed]);

	const availableLanguages = useMemo<LanguageType[]>(() => {
		return Array.from(allLanguagesMap.values()).filter(lang => {
			return !languageCodesUsed.includes(lang.code);
		});
	}, [allLanguagesMap, languageCodesUsed]);

	useEffect(() => {
		const fetchLanguages = async () => {
			try {
				await notificationTemplatesStore.fetchLanguages();
			} finally {
				setLanguagesLoading(false);
			}
		};
		fetchLanguages();
	}, [notificationTemplatesStore]);

	const addLanguage = useCallback(
		(lang: LanguageType) => {
			onAddLang(lang.code);
			setActiveLanguageCode(lang.code);
		},
		[onAddLang, setActiveLanguageCode]
	);

	const onRemove = useCallback(
		(langCode: string) => {
			onRemoveLang(langCode);
		},
		[onRemoveLang]
	);

	const tabItems = useMemo(
		() =>
			selectedLanguages.map((lang, index) => ({
				key: lang.code,
				label: (
					<TabTitle
						language={lang}
						onRemove={() => onRemove(lang.code)}
						isRemovable={lang.code !== merchantCustomerNotificationsStore.defaultLanguageCode}
					/>
				),
				children: getTabContent(lang.code, index)
			})),
		[selectedLanguages, getTabContent, onRemove]
	);

	return (
		<div>
			{isLoading || languagesLoading ? (
				<Spinner className="spinner-center" />
			) : (
				<Tabs
					activeKey={activeLanguageCode}
					defaultActiveKey={merchantCustomerNotificationsStore.defaultLanguageCode}
					className={styles.contentTab}
					tabBarGutter={0}
					tabBarExtraContent={
						availableLanguages.length > 0
							? {
									left: <AddLanguageDropDown languages={availableLanguages} onSelect={addLanguage} />
							  }
							: null
					}
					onChange={setActiveLanguageCode}
					items={tabItems}
				/>
			)}
		</div>
	);
});
