import { set, groupBy } from 'lodash';
import notification from '@bringg/react-components/dist/components/notification/notification';
import { User } from '@bringg/types';

export function arrayToCSV(array: string[][], fileName: string): File {
	return new File([array.map(row => row.join(',')).join('\n')], fileName);
}

function assignInventoryToWaypoints(task: Record<string, any>) {
	if (!task.inventory) {
		return;
	}

	const inventory = task.inventory;
	delete task.inventory;
	set(
		task,
		'way_points[0].inventory',
		inventory.map(item => ({ ...item, pending: true }))
	);
	set(
		task,
		'way_points[1].inventory',
		inventory.map(item => ({ ...item, pending: false }))
	);
}

function assignWaypointOption(task: Record<string, any>) {
	set(task, 'way_points[0].position', 1);
	set(task, 'way_points[1].position', 2);
	set(task, 'way_points[0].pickup_dropoff_option', 0);
	set(task, 'way_points[1].pickup_dropoff_option', 1);
}

function concatName(task: Record<string, any>) {
	if (task.customer?.first_name && !task.customer?.name) {
		set(task, 'customer.name', [task.customer.first_name, task.customer.last_name].filter(Boolean).join(' '));
		delete task.customer.first_name;
		delete task.customer.last_name;
	}
}

function parseExtra(task: Record<string, any>) {
	if (task.extras) {
		try {
			task.extras = JSON.parse(task.extras);
		} catch (ex) {
			console.error('Failed to parse extras JSON', task.extras);
			notification.error('Failed to parse "extras", column ignored');
			delete task.extras;
		}
	}
}

function mergeTaskInventories(tasks: any[]) {
	const tasksByExternalId = groupBy(tasks, 'external_id');

	return tasks.reduce((acc, task) => {
		if (!task.external_id) {
			task.inventory = [task.inventory];
			return [...acc, task];
		}

		const tasksToMerge = tasksByExternalId[task.external_id];

		if (!tasksToMerge) {
			return acc;
		}

		delete tasksByExternalId[task.external_id];

		const inventories = tasksToMerge.map(t => t.inventory).filter(Boolean);
		return [...acc, { ...tasksToMerge[0], inventory: inventories }];
	}, []);
}

export function assignTeams(task: Record<string, any>, user: Partial<User>) {
	if (user.admin) {
		return;
	}

	if (!user.dispatcher) {
		throw new Error('ORDERS_CSV.NOT_DISPATCHER');
	}

	if (!task.teams && !task.team_external_id) {
		if (user.team_ids.length === 1) {
			task.teams = [...user.team_ids];
		}
	}
}

export function csvToTasks(csv: string[][], headers: string[], user: Partial<User>) {
	let tasks = replaceHeaders(csv, headers).map(formatLine);

	tasks.forEach(task => {
		parseExtra(task);
		concatName(task);
		assignWaypointOption(task);
		assignTeams(task, user);
	});

	if (tasks.some(task => task.inventory)) {
		tasks = mergeTaskInventories(tasks);
	}

	tasks.forEach(assignInventoryToWaypoints);

	return tasks;
}

export function tasksToJSONL(tasks: any[], fileName: string): File {
	return new File([tasks.map(task => JSON.stringify({ task })).join('\n')], fileName.replace('.csv', '.jsonl'), {
		type: 'application/octet-stream'
	});
}

export function replaceHeaders(data: string[][], headers: string[]) {
	const rows = data.slice(1);

	return rows.map(row =>
		headers.reduce((acc, value, index) => {
			if (value === null) {
				return acc;
			}

			acc[value] = row[index];
			return acc;
		}, {})
	);
}

export function formatLine(parsedTask: Record<string, any>) {
	const task = Object.entries(parsedTask).reduce((acc, [keyString, value]) => {
		set(acc, keyString, value);
		return acc;
	}, {} as Record<string, any>);

	return task;
}

export function duplicateFailuresForSameTasks(
	failures: Record<string, any>[],
	csv: string[][],
	headers: string[],
	tasks: Record<string, any>[]
) {
	const externalIdIndex = headers.findIndex(header => header === 'external_id');

	if (externalIdIndex === -1) {
		return failures;
	}

	const externalIdFailure = failures.reduce((acc, failure) => {
		const externalId = tasks[Number(failure.id) - 1].external_id;

		acc.set(externalId, failure);

		return acc;
	}, new Map());

	return csv.slice(1).reduce((acc, row, index) => {
		const externalId = row[externalIdIndex];

		if (externalIdFailure.has(externalId)) {
			acc.push({ ...externalIdFailure.get(externalId), id: index + 1 });
		}

		return acc;
	}, []);
}
