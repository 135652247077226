import React, { createRef } from 'react';

import { observer } from 'mobx-react';
import { AnimateMovingItems, AttributePath } from '@bringg/react-components';
import { NONE_GROUP_TYPE } from '@bringg-frontend/utils';

import { WorkflowStore, WorkflowCard } from 'bringg-web/automation-platform-exports';

import styles from './workflows-list.module.scss';

interface Props {
	workflows: Record<string, WorkflowStore[]>;
	expandAll?: boolean;
	viewMode?: boolean;
	scrollToViewWorkflowId?: number;
}

const WorkflowsGroupedList = ({ workflows, expandAll, viewMode, scrollToViewWorkflowId }: Props) => {
	const children = Object.keys(workflows).map(group =>
		[
			group !== NONE_GROUP_TYPE ? (
				<div className={styles.workflowGroupHeader} data-test-id="workflow-group-header" key={group}>
					<AttributePath path={group} />{' '}
					<span data-test-id="workflow-group-header-counter">({workflows[group].length})</span>
				</div>
			) : (
				''
			)
		].concat(
			workflows[group].map(wf => (
				<WorkflowCard
					viewMode={viewMode}
					key={wf.id}
					workflow={wf}
					shouldScrollToView={scrollToViewWorkflowId === wf.id}
					ref={createRef()}
					expanded={expandAll}
				/>
			))
		)
	);

	return (
		<div className={styles.workflowList}>
			{/* In case we need to scroll into view we avoid using animation as it will cause workflow cards to jump */}
			{scrollToViewWorkflowId ? children : <AnimateMovingItems>{children}</AnimateMovingItems>}
		</div>
	);
};

export default observer(WorkflowsGroupedList);
