import moment from 'moment';
import _partition from 'lodash/partition';
import { naturalSortByKey } from '@bringg-frontend/utils';

import DeliveryBlock from './stores/domain-objects/delivery-block';
import Driver from '../../stores/drivers/domain-object/driver';
import { DeliveryTimes } from './modal/delivery-block-modal';

export const checkRequestedTimeConflictsWithBlock = (
	{ start_time, end_time }: DeliveryBlock,
	startTimeToCheck: string,
	endTimeToCheck: string
): boolean => {
	return (
		moment(startTimeToCheck).isBetween(start_time, end_time, null, '[]') ||
		moment(endTimeToCheck).isBetween(start_time, end_time, null, '[]') ||
		(moment(start_time).isBetween(startTimeToCheck, endTimeToCheck, null, '[]') &&
			moment(end_time).isBetween(startTimeToCheck, endTimeToCheck, null, '[]'))
	);
};

export const getSortedDriversByAvailabilityAndName = (
	drivers: Driver[],
	deliveryBlockTimes: DeliveryTimes,
	deliveryBlockId: number
): Driver[] => {
	const dividedDriversByAvailability = _partition(drivers, driver => {
		const { startTime, endTime } = deliveryBlockTimes;
		return driver.isAvailableAtRequestedBlock(startTime, endTime, [deliveryBlockId]);
	});
	const sortedDrivers = dividedDriversByAvailability.map(drivers => {
		return naturalSortByKey(drivers, 'name');
	});

	return sortedDrivers.flat();
};
