import React, { useState, useContext, useMemo } from 'react';

import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { BringgInput } from '@bringg/react-components';
import { BringgFontIcons, BringgIcon } from '@bringg/bringg-icons';

import InputLabelWithTooltip from './../input-label-with-tooltip/input-label-with-tooltip';
import { OptimizationSettingsFormContext } from '../optimization-configuration-form/optimization-configuration-form';
import { HandleChange, OptimizationPercentageAttribute, OptimizationSettingsFormContextType } from '../../types';

import './percentage-input.scss';

const percentsToValue = (percent: number): number => {
	return (100 + percent) / 100;
};

const valueToPercents = (value: number): number => {
	return Number((value * 100 - 100).toFixed(2));
};

const MIN_PERCENT = 0;
const MAX_PERCENT = 100;

const validate =
	(
		setStatus: React.Dispatch<React.SetStateAction<'' | 'error' | undefined>>,
		onChange: HandleChange,
		numberAttribute: OptimizationPercentageAttribute,
		t: (string) => string
	) =>
	(ch: React.ChangeEvent<HTMLInputElement>) => {
		const {
			id,
			constraints: { min, minPercent = MIN_PERCENT, max, maxPercent = MAX_PERCENT }
		} = numberAttribute;
		const val = ch.target?.value;
		const percentNumberValue = Number.parseFloat(val);

		if (!val || Number.isNaN(percentNumberValue)) {
			setStatus('error');
			onChange(id, {
				value: '',
				isValid: false,
				validationErrorMsg: t('OPTIMIZATION_CONFIGURATIONS.REQUIRED_VALUE_ERROR_MESSAGE')
			});
			return;
		}

		const value = percentsToValue(percentNumberValue);

		if (percentNumberValue < minPercent || percentNumberValue > maxPercent) {
			setStatus('error');
			onChange(id, {
				value,
				isValid: false,
				validationErrorMsg: `${t(
					'OPTIMIZATION_CONFIGURATIONS.NUMBER_NOT_IN_RANGE'
				)} ${minPercent}% - ${maxPercent}%`
			});
			return;
		}

		if (value <= max && value >= min) {
			setStatus('');
			onChange(id, { value, isValid: true });
		} else {
			setStatus('error');
			onChange(id, {
				value,
				isValid: false,
				validationErrorMsg: `${t('OPTIMIZATION_CONFIGURATIONS.NUMBER_NOT_IN_RANGE')} ${min} - ${max}`
			});
		}
	};

const PercentageInput = (props: OptimizationPercentageAttribute) => {
	const { t } = useTranslation();
	const {
		title,
		value = '',
		isValid,
		id,
		tooltipTitle,
		prompt,
		constraints: { minPercent = MIN_PERCENT, maxPercent = MAX_PERCENT },
		validationErrorMsg,
		isSubField,
		disabled,
		containerId
	} = props;
	const { handleChange } = useContext<OptimizationSettingsFormContextType>(OptimizationSettingsFormContext);

	const [status, setStatus] = useState<'' | 'error'>(isValid ? '' : 'error');
	const [focused, setFocused] = useState(false);

	const percentageValue = value === '' ? null : valueToPercents(parseFloat(value));
	const inputValue = Number.isFinite(percentageValue) ? percentageValue : '';
	const percentSign = focused ? '' : '%';

	const showInputPrompt = useMemo(
		() => status !== 'error' && Number(value) > minPercent && Number(value) < maxPercent,
		[status, value, minPercent, maxPercent]
	);

	return (
		<div
			className={classnames('route-optimization-percentage-input', 'route-optimization-field-container', {
				'sub-field': isSubField
			})}
		>
			<InputLabelWithTooltip
				id={id}
				title={t(title)}
				tooltip={tooltipTitle ? t(tooltipTitle) : null}
				containerId={containerId}
			/>
			<BringgInput
				className="percentage-input"
				id={id}
				status={status}
				value={`${inputValue}${percentSign}`}
				placeholder="Ex. 75%"
				onChange={validate(setStatus, handleChange, props, t)}
				onFocus={() => setFocused(true)}
				onBlur={() => setFocused(false)}
				disabled={disabled}
			/>
			{showInputPrompt && (
				<span className="percentage-input-prompt">
					{t(prompt)} {percentageValue}%
				</span>
			)}
			{status === 'error' && (
				<div className="route-optimization-percentage-input-error-text">
					<BringgIcon
						className="route-optimization-percentage-input-error-icon"
						iconName={BringgFontIcons.Warning}
					/>
					<span>{validationErrorMsg}</span>
				</div>
			)}
		</div>
	);
};

export default PercentageInput;
