import { action, observable, reaction, makeObservable } from 'mobx';
import moment, { Moment } from 'moment-timezone';
import _noop from 'lodash/noop';
import _isFunction from 'lodash/isFunction';

import { deliveryBlocksRootStore } from '../stores/delivery-blocks-root-store';
import { timezoneProvider } from 'bringg-web/services/timezone/timezone-provider';

export type TimeRange = {
	startDate: Moment;
	endDate: Moment;
};

export interface DeliveryBlockToolbarViewOptions {
	onTimeRangeUpdate?: (onRangeUpdate: TimeRange) => void;
	timezone?: string;
}

class DeliveryBlockToolbarView {
	timeRange: TimeRange = null;
	timeRangeDisposer = _noop;

	constructor({ onTimeRangeUpdate }: DeliveryBlockToolbarViewOptions) {
		makeObservable(this, {
			timeRange: observable.shallow,
			setCurrentWeek: action,
			setNextWeek: action,
			setPrevWeek: action,
			setCurrentDay: action,
			setNextDay: action,
			setPrevDay: action,
			setWeekTimeRange: action,
			setTimeRange: action
		});

		if (_isFunction(onTimeRangeUpdate)) {
			this.timeRangeDisposer = reaction(
				() => [this.timeRange],
				() => onTimeRangeUpdate(this.timeRange)
			);
		}
	}

	setCurrentWeek = () => {
		const teamId = deliveryBlocksRootStore.getStore().deliveryBlocksView.currentTeam.id;
		const teamTimezone = timezoneProvider.getTimezoneByTeamId(teamId);

		this.timeRange = {
			startDate: moment().tz(teamTimezone).startOf('week'),
			endDate: moment().tz(teamTimezone).endOf('week')
		};
	};

	setWeek = (startDate: Moment, endDate: Moment) => {
		const teamId = deliveryBlocksRootStore.getStore().deliveryBlocksView.currentTeam.id;
		const teamTimezone = timezoneProvider.getTimezoneByTeamId(teamId);

		this.timeRange = {
			startDate: startDate.clone().tz(teamTimezone, true),
			endDate: endDate.clone().tz(teamTimezone, true)
		};
	};

	setNextWeek = () => {
		this.timeRange = {
			startDate: this.timeRange.startDate.add(1, 'weeks'),
			endDate: this.timeRange.endDate.add(1, 'weeks')
		};
	};

	setPrevWeek = () => {
		this.timeRange = {
			startDate: this.timeRange.startDate.subtract(1, 'weeks'),
			endDate: this.timeRange.endDate.subtract(1, 'weeks')
		};
	};

	setCurrentDay = () => {
		const teamId = deliveryBlocksRootStore.getStore().deliveryBlocksView.currentTeam.id;
		const teamTimezone = timezoneProvider.getTimezoneByTeamId(teamId);

		this.timeRange = {
			startDate: moment().tz(teamTimezone).startOf('day'),
			endDate: moment().tz(teamTimezone).endOf('day')
		};
	};

	setNextDay = () => {
		this.timeRange = {
			startDate: this.timeRange.startDate.add(1, 'days'),
			endDate: this.timeRange.endDate.add(1, 'days')
		};
	};

	setPrevDay = () => {
		this.timeRange = {
			startDate: this.timeRange.startDate.subtract(1, 'days'),
			endDate: this.timeRange.endDate.subtract(1, 'days')
		};
	};

	setWeekTimeRange = (time: Moment) => {
		this.timeRange = {
			startDate: time.clone().startOf('week'),
			endDate: time.clone().endOf('week')
		};
	};

	setTimeRange = (time: Moment) => {
		this.timeRange = {
			startDate: time.clone().startOf('day'),
			endDate: time.clone().endOf('day')
		};
	};

	dispose = () => {
		this.timeRange = null;
		this.timeRangeDisposer = _noop;
	};
}

export default DeliveryBlockToolbarView;
