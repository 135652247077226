import React from 'react';
import { Portal } from '../../components';
import { ComponentType } from 'react';

interface Props {
	fullScreenComponent: ComponentType;
}
const FullScreenPortal = ({ fullScreenComponent: FullScreenCompoent }: Props) => {
	return <Portal element={<FullScreenCompoent />} nodeId={'react-fullscreen-portal'} />;
};

export default FullScreenPortal;
