import { useEffect, useState } from 'react';
import { State } from './types';
import { useStores } from '../../recipes';
import { useTranslation } from 'react-i18next';
import notification from '../../services/notification';

const useFleetConfigurationStore = () => {
	const { fleetsConfigurationStore } = useStores();
	const [state, setState] = useState<State>(State.Idle);
	const { t } = useTranslation();

	useEffect(() => {
		const init = async () => {
			setState(State.Loading);
			try {
				await fleetsConfigurationStore.load();
				setState(State.Done);
			} catch (err) {
				notification.error(t('FLEETS.FAILED_LOADING_FLEETS_CONFIGURATION'));
				setState(State.Failed);
			}
		};

		init();
	}, []);

	return { fleetsConfigurationStore, state };
};

export default useFleetConfigurationStore;
