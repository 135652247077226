import React from 'react';
import { Row, Col, RowProps } from 'antd';
import { diffWords } from 'diff';
import { BringgFontIcons, BringgIcon } from '@bringg/bringg-icons';
import DiffSpan, { ChangesPart } from './diff-span';
import FieldTitle from './field-title';

const gutter: RowProps['gutter'] = [16, 16];

const ChangesDiff = changes => {
	const [changedField, [was, is]] = changes;
	const added = [];
	const removed = [];

	const diff = diffWords(JSON.stringify(was), JSON.stringify(is));

	diff.forEach((part: ChangesPart, index: number) => {
		const key = changedField + index;
		const span = <DiffSpan part={part} key={key} />;

		if (!part.removed) {
			added.push(span);
		}
		if (!part.added) {
			removed.push(span);
		}
	});

	return (
		<div key={changedField}>
			<FieldTitle fieldName={changedField} />
			<Row justify="center" gutter={gutter}>
				<Col span={11} className="changes-diff-text">
					{removed}
				</Col>
				<Col span={2} className="changes-diff-arrow-wrapper">
					<BringgIcon iconName={BringgFontIcons.ArrowRight} className="changes-diff-arrow-icon" />
				</Col>
				<Col span={11} className="changes-diff-text">
					{added}
				</Col>
			</Row>
		</div>
	);
};

export default ChangesDiff;
