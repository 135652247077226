import { reaction } from 'mobx';
import { DisplayFact, FactType } from '@bringg/types';

import { UpdateEntityActionRepo, WorkflowStore } from './internal';
import { ActionFamilyType } from '../utils/types';

export function workflowResetListener(workflowStore: WorkflowStore, signal: AbortSignal) {
	resetActionsOnTriggerChange(workflowStore, signal);
	resetRulesOnTriggerDisplayFactChange(workflowStore, signal);
	resetActionsOnManualTriggerFieldChange(workflowStore, signal);
}

function resetActionsOnTriggerChange(workflowStore: WorkflowStore, signal: AbortSignal) {
	reaction(
		() => [workflowStore.trigger.id],
		([triggerId], [prevTriggerId]) => {
			if (!workflowStore.isDirty) {
				return;
			}

			if (triggerId === prevTriggerId) {
				return;
			}

			workflowStore.actions.overrideAction.set(workflowStore.trigger.actions);
			workflowStore.actions.setDisplayFact(workflowStore.trigger.displayFact);

			workflowStore.actions.reset();
		},
		{
			signal
		}
	);
}

function resetRulesOnTriggerDisplayFactChange(workflowStore: WorkflowStore, signal: AbortSignal) {
	reaction(
		() => [workflowStore.trigger.displayFact, workflowStore.trigger.factType],
		([displayFactType, factType], [prevDisplayFactType]) => {
			if (!workflowStore.isDirty) {
				return;
			}

			if (displayFactType === prevDisplayFactType) {
				return;
			}

			workflowStore.rules.reset(factType as FactType, displayFactType as DisplayFact);
		},
		{
			signal
		}
	);
}

export function resetActionsOnManualTriggerFieldChange(workflowStore: WorkflowStore, signal: AbortSignal) {
	reaction(
		() => workflowStore.trigger.dynamicVariables,
		(dynamicVariables, prevDynamicVariables) => {
			if (!workflowStore.isDirty) {
				return;
			}

			const prevDynamicVariablesNames = prevDynamicVariables.map(key => key.name);
			const prevDynamicVariableDataTypes = prevDynamicVariables.map(key => key.data_type);

			const dynamicVariablesNames = dynamicVariables.map(key => key.name);
			const dynamicVariableDataTypes = dynamicVariables.map(key => key.data_type);

			const allUpdateEntityActionValues = workflowStore.actions.actions
				.filter(action => action.type === ActionFamilyType.UPDATE_ENTITY)
				.flatMap(action => (action as UpdateEntityActionRepo).actions)
				.map(action => action.value)
				// only strings as dynamic variables are used in the action by name
				.filter(value => typeof value === 'string') as string[];

			allUpdateEntityActionValues.some(actionValue => {
				if (prevDynamicVariablesNames.includes(actionValue) && !dynamicVariablesNames.includes(actionValue)) {
					workflowStore.actions.actions
						.filter(action => action.type === ActionFamilyType.UPDATE_ENTITY)
						.flatMap(action => (action as UpdateEntityActionRepo).actions)
						.map(
							action =>
								(action.value = dynamicVariablesNames[prevDynamicVariablesNames.indexOf(actionValue)])
						);
					return true;
				}
			});

			const isUsedDynamicVariableDataTypeChanged = allUpdateEntityActionValues
				.filter(
					actionValue =>
						prevDynamicVariablesNames.includes(actionValue) && dynamicVariablesNames.includes(actionValue)
				)
				.map(actionValue => prevDynamicVariablesNames.indexOf(actionValue))
				.some(
					dynamicVariableIndex =>
						dynamicVariableDataTypes[dynamicVariableIndex] !==
						prevDynamicVariableDataTypes[dynamicVariableIndex]
				);

			if (isUsedDynamicVariableDataTypeChanged) {
				workflowStore.actions.reset();
			}
		},
		{
			signal
		}
	);
}
