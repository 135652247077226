import React, { useCallback, useEffect, useRef, useState } from 'react';

import { Spinner, UserSelectorMode, UsersSelector } from '@bringg/react-components';
import _debounce from 'lodash/debounce';
import Bringg, { SetOptional } from '@bringg/types';
import { useTranslation } from 'react-i18next';
import { LookupConsts } from '@bringg/dashboard-sdk';
import { UsersSearchResponse, UsersSearchResponseItem } from '@bringg/dashboard-sdk/dist/LookUp/lookup.consts';
import { getRootEnv } from '@bringg-frontend/bringg-web-infra';

type UserSelectorProps = React.ComponentProps<typeof UsersSelector>;

type BaseSearch = Omit<LookupConsts.UsersSearchQuery, 'query'>;

interface Props
	extends SetOptional<
		Pick<UserSelectorProps, 'isDisabled' | 'isOptionDisabled' | 'placeholder' | 'mode'>,
		'placeholder'
	> {
	onChange(userIds: number[]): void;

	defaultUsersIds: number[];

	baseSearch: BaseSearch;

	mode?: UserSelectorMode;

	'data-test-id'?: string;
}

export const MINIMUM_CHARACTERS_REQUIRED_BY_SEARCH_API = 2;

export const AsyncUsersSelector = ({
	onChange,
	isDisabled,
	placeholder,
	baseSearch,
	defaultUsersIds,
	mode = UserSelectorMode.multiple,
	'data-test-id': dataTestId
}: Props) => {
	const { t } = useTranslation();
	const placeholderText = placeholder || t('USERS_SELECTOR_MODAL.PLACEHOLDER') || 'select user';

	const [defaultSelectedUsersFetched, setDefaultSelectedUsersFetched] = useState(false);
	const [isFetching, setIsFetching] = useState(false);
	const ac = useRef(new AbortController());
	const [users, setUsers] = useState<(Bringg.User | UsersSearchResponseItem)[]>([]);

	const searchDebounce = useCallback(
		_debounce(async (value: string, searchMixin: BaseSearch) => {
			ac.current.abort();
			ac.current = new AbortController();

			const currentSignal = ac.current.signal;

			let foundedUsers: UsersSearchResponseItem[] = [];
			try {
				foundedUsers = await searchUsers({
					...searchMixin,
					query: value,
					signal: currentSignal
				});

				setUsers(foundedUsers || []);
			} catch (error) {
				console.error('Failed to search users', error);
			} finally {
				setIsFetching(false);
			}

			if (currentSignal.aborted || !foundedUsers?.length) return;

			try {
				const userIds = foundedUsers.map(user => user.id);

				const usersWithProfilePictures = await getRootEnv().dashboardSdk.sdk.users.getMany(userIds, {
					signal: currentSignal
				});

				if (currentSignal.aborted) return;

				setUsers(usersWithProfilePictures);
			} catch (e) {
				console.error('Failed to fetch users', e);
			}
		}, 500),
		[]
	);

	useEffect(() => {
		async function fetchDefaultSelected(ids: number[]) {
			try {
				setUsers(await getRootEnv().dashboardSdk.sdk.users.getMany(ids));
			} catch (error) {
				console.error('Failed to fetch default users', error);
			} finally {
				setDefaultSelectedUsersFetched(true);
			}
		}

		setDefaultSelectedUsersFetched(false);

		fetchDefaultSelected(defaultUsersIds);
	}, [defaultUsersIds]);

	useEffect(() => {
		return () => {
			ac.current.abort();
		};
	}, []);

	if (defaultUsersIds?.length && !defaultSelectedUsersFetched) {
		return null;
	}

	return (
		<UsersSelector
			data-test-id={dataTestId}
			users={users}
			mode={mode}
			selectedIds={users.map(user => user.id)}
			placeholder={placeholderText}
			isDisabled={isDisabled}
			notFoundContent={isFetching ? <Spinner size="small" /> : null}
			onChange={onChange}
			onSearch={(value: string) => {
				if (value.length < MINIMUM_CHARACTERS_REQUIRED_BY_SEARCH_API) {
					return;
				}

				setIsFetching(true);

				// Can return undefined when the user never typed anything
				searchDebounce(value, baseSearch)?.catch(error => console.error('Failed to search users', error));
			}}
		/>
	);
};

async function searchUsers({
	includeAllAdmins,
	roles,
	signal,
	...rest
}: LookupConsts.UsersSearchQuery & {
	signal: AbortSignal;
}): Promise<UsersSearchResponse['items']> {
	const { dashboardSdk } = getRootEnv();

	const result = await dashboardSdk.sdk.v2.lookup.getUsers(
		{
			roles,
			...rest
		},
		{ signal }
	);

	return result.items;
}
