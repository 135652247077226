import moment from 'moment';
import { dateUtils } from '@bringg-frontend/utils';

import { Duration } from 'bringg-web/features/planned-delivery-windows/modals/slots-modal/planned-delivery-windows-slots.consts';

const getHours = (startTimeMinutes: number, endTimeMinutes: number, timezone: string): number => {
	const startTimeDate = dateUtils.addMinutesToLastMonday(startTimeMinutes, timezone);
	const endTimeDate = dateUtils.addMinutesToLastMonday(endTimeMinutes, timezone);
	return endTimeDate.diff(startTimeDate, 'h');
};
const getMinutes = (startTimeMinutes: number, endTimeMinutes: number, timezone: string) => {
	const startTimeDate = dateUtils.addMinutesToLastMonday(startTimeMinutes, timezone);
	const endTimeDate = dateUtils.addMinutesToLastMonday(endTimeMinutes, timezone);
	const diffInMinutes = endTimeDate.diff(startTimeDate, 'm');
	return moment(0)
		.add(diffInMinutes, 'm')
		.subtract(getHours(startTimeMinutes, endTimeMinutes, timezone), 'h')
		.minutes();
};

const getDurations = (startTimeMinutes: number, endTimeMinutes: number, timezone: string): Duration => {
	return {
		minutes: getMinutes(startTimeMinutes, endTimeMinutes, timezone),
		hours: getHours(startTimeMinutes, endTimeMinutes, timezone)
	};
};
export const durationCalculate = { getHours, getMinutes, getDurations };
