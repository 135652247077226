export class ShapeIsNotPolygonError extends Error {
	constructor() {
		super('Shape is not polygon');
	}
}

export function checkIfPolygon(polygon: number[][]): void {
	if (polygon.length < 3) {
		throw new ShapeIsNotPolygonError();
	}
}
