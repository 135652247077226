import React from 'react';
import { BringgFontIcons, BringgIcon } from '@bringg/bringg-icons';
import './with-loading.scss';

export enum Status {
	Idle,
	Loading,
	Success,
	Fail
}

const withLoading = Component => {
	// eslint-disable-next-line react/prop-types
	return ({ status, ...otherProps }) => {
		return (
			<div className="with-loading">
				<Component {...otherProps} />
				{status === Status.Loading && (
					<BringgIcon className="status-icon loader" iconName={BringgFontIcons.Loader} spin />
				)}
				{status === Status.Success && (
					<BringgIcon className="status-icon" iconName={BringgFontIcons.Selected} />
				)}
				{status === Status.Fail && <BringgIcon className="status-icon" iconName={BringgFontIcons.Close} />}
			</div>
		);
	};
};

export default withLoading;
