import { ALERT_TYPES, AlertDisplayCategory } from '@bringg/types';

export { APP_CONFIG, setAppConfig } from './app-config';
export { DEFAULT_DATE_FORMAT } from '@bringg-frontend/global-stores';

export const EMPTY_TEAM_ID = -1;
export const EMPTY_ROUTE_ID = -1;

export const KM_TO_MILES_MULTIPLEXER = 1.6;
export const KG_TO_POUNDS_MULTIPLEXER = 2.20462;

export const TRANSLATION_DOMAINS = {
	EXCEPTIONS_LIST: 'EXCEPTIONS_LIST'
};

export enum CONFIG_TABLE_IDS {
	EXCEPTIONS = 'exception_table_config',
	DRIVER_ALERTS = 'driver_alerts_table_config',
	OPTIMIZATION_ALERTS = 'optimization_alerts_table_config'
}

export type TableAdminFieldsKey =
	| 'task_fields'
	| 'task_alert_fields'
	| 'driver_alert_fields'
	| 'optimization_alert_fields';
export const CONFIG_FIELDS_KEY_BY_TABLE_ID: Record<CONFIG_TABLE_IDS, TableAdminFieldsKey> = {
	[CONFIG_TABLE_IDS.EXCEPTIONS]: 'task_alert_fields',
	[CONFIG_TABLE_IDS.DRIVER_ALERTS]: 'driver_alert_fields',
	[CONFIG_TABLE_IDS.OPTIMIZATION_ALERTS]: 'optimization_alert_fields'
};

export const TASK_ALERTS: ALERT_TYPES[] = [
	ALERT_TYPES.LATE,
	ALERT_TYPES.FAILED_SMS,
	ALERT_TYPES.STAYS_TOO_LONG_ON_SITE,
	ALERT_TYPES.CHECKIN_FAR_FROM_LOCATION,
	ALERT_TYPES.SCANNING_OUTSIDE_GEOFENCE,
	ALERT_TYPES.COULD_NOT_CREATE_RETURNING_TASK,
	ALERT_TYPES.WAY_POINT_NOT_GEOCODED,
	ALERT_TYPES.UNASSIGNED_TASK_THAT_MUST_LEAVE,
	ALERT_TYPES.NOT_ASSIGNED_BEFORE_SCHEDULE,
	ALERT_TYPES.NOT_ACCEPTED_BEFORE_SCHEDULE,
	ALERT_TYPES.NOT_STARTED_BEFORE_SCHEDULE,
	ALERT_TYPES.VRP_AUTO_DISPATCH_FAILED_TO_ASSIGN_TASK,
	ALERT_TYPES.DRIVER_CANCELLED_ORDER,
	ALERT_TYPES.FAILED_TO_CREATE_FLEET_DELIVERY,
	/**
	 * the following alert list (prefixed TMP) is temporary. it was defined this way to allow delivering https://bringg.atlassian.net/browse/SERVER-37682 on time
	 * in the near future they will be replaced with custom alerts with different subtypes to represent the different alerts
	 */
	ALERT_TYPES.TMP_DAY_AND_ROSS_ORDER_TOTAL_WEIGHT_CHANGED,
	ALERT_TYPES.TMP_DAY_AND_ROSS_INVENTORY_SIZE_CHANGED,
	ALERT_TYPES.TMP_DAY_AND_ROSS_PICKUP_ORDER_TIME_WINDOW_CHANGED,
	ALERT_TYPES.TMP_DAY_AND_ROSS_DELIVERY_ORDER_TIME_WINDOW_CHANGED,
	ALERT_TYPES.TMP_DAY_AND_ROSS_PICKUP_ORDER_SCHEDULED_TIME_CHANGED,
	ALERT_TYPES.TMP_DAY_AND_ROSS_DELIVERY_ORDER_SCHEDULED_TIME_CHANGED,
	ALERT_TYPES.TMP_DAY_AND_ROSS_ORDER_DANGEROUS_GOODS_STATUS_CHANGED,
	ALERT_TYPES.TMP_DAY_AND_ROSS_PICKUP_ORDER_LOCATION_CHANGED,
	ALERT_TYPES.TMP_DAY_AND_ROSS_DELIVERY_ORDER_LOCATION_CHANGED,
	ALERT_TYPES.TMP_DAY_AND_ROSS_ORDER_SERVICE_PLAN_CHANGED,
	ALERT_TYPES.TMP_DAY_AND_ROSS_NUMBER_OF_PALLETS_CHANGED
];

export const DRIVER_ALERTS: ALERT_TYPES[] = [
	ALERT_TYPES.PHONE_STOPPED_REPORTING,
	ALERT_TYPES.LOW_BATTERY,
	ALERT_TYPES.FAILED_PUSHING_TO_DRIVER,
	ALERT_TYPES.DRIVER_LOST_GPS,
	ALERT_TYPES.DRIVER_OFF_ROUTE,
	ALERT_TYPES.DRIVER_ENDS_SHIFT_WITH_OPEN_TASKS,
	ALERT_TYPES.DRIVER_ENDS_SHIFT_BEFORE_SCHEDULE,
	ALERT_TYPES.USER_RATED_BELLOW_THRESHOLD,
	ALERT_TYPES.MOBILE_BLUETOOTH_IS_OFF,
	ALERT_TYPES.ETA_TO_HOME,
	ALERT_TYPES.GPS_IS_OFF,
	ALERT_TYPES.DRIVER_STARTED_UNPRIORITIZED_TASK
];

export const OPTIMIZATION_ALERTS: ALERT_TYPES[] = [
	ALERT_TYPES.REQUIRE_MORE_DRIVERS,
	ALERT_TYPES.TOO_MANY_DRIVERS,
	ALERT_TYPES.ROUTE_OPTIMIZATION_FAILED,
	ALERT_TYPES.BAD_OPTIMIZATION_PARAMETERS,
	ALERT_TYPES.ROUTE_OPTIMIZATION_ORDERS_LEFT_OUTSIDE_OPTIMIZATION,
	ALERT_TYPES.TMP_DAY_AND_ROSS_RUN_BREACHED_BY_WEIGHT,
	ALERT_TYPES.TMP_DAY_AND_ROSS_RUN_BREACHED_BY_PALLETS
];

export const UNSUPPORTED_ALERTS_IDS = new Set([
	ALERT_TYPES.UNACCEPTED,
	ALERT_TYPES.MISSED_CLIENT_MESSAGE,
	ALERT_TYPES.FAILED_CONSUMER_APP,
	ALERT_TYPES.UNKNOWN,
	ALERT_TYPES.DRIVER_LOST_GPS,
	ALERT_TYPES.ROGUE_CUSTOMER_SUSPECTED,
	ALERT_TYPES.ROGUE_ORDER_SUSPECTED,
	ALERT_TYPES.FAILED_EMAIL,
	ALERT_TYPES.DRIVER_FORCE_CLOSED_APP,
	ALERT_TYPES.ADDRESS_CANT_BE_FOUND,
	ALERT_TYPES.DONE_LAST_ORDER,
	ALERT_TYPES.DRIVER_DEPOSIT_CLIENT_DEPOSIT_AMOUNT_DIFFERENT_FROM_SERVER,
	ALERT_TYPES.DRIVER_DEPOSIT_CLIENT_DEPOSIT_AMOUNT_BASED_ON_TASK_IDS_DIFFERENT_FROM_SERVIER,
	ALERT_TYPES.FLEET_FAILED_TO_FIND_DRIVER,
	ALERT_TYPES.FLEET_UNABLE_TO_DELIVER,
	ALERT_TYPES.UNASSIGNED_TASK_THAT_MUST_LEAVE
]);

export const getAlertCategory = (alertType): AlertDisplayCategory => {
	if (TASK_ALERTS.indexOf(alertType) > -1) {
		return AlertDisplayCategory.TASK;
	}
	if (DRIVER_ALERTS.indexOf(alertType) > -1) {
		return AlertDisplayCategory.DRIVER;
	}
	if (OPTIMIZATION_ALERTS.indexOf(alertType) > -1) {
		return AlertDisplayCategory.OPTIMIZATION;
	}
	return null;
};

export const formConfig = {
	debouncePhone: {
		intervalInMillis: 500
	},
	debounceAddress: {
		intervalInMillis: 500
	}
};

export const NEW_FILTER_BAR_FF_ALERTS_SCREEN = 'enable_alert_display_new_filters';

export const USE_EXTERNAL_ID_FF = 'enable_external_id_display';

export const ALERTS_ADDITIONAL_TEXT_FF = 'enable_alerts_additional_text';
export const SERVICE_AREA_LAT_LNG_FF = 'service_area_lat_lng';

export const FALLBACK_LOGO = 'https://app.bringg.com/images/dp_logos/Generic_1.png';

export const SSO_CONFIGURATION_PAGE = {
	learnMorePage: 'https://help.bringg.com/r/Integrations-Guide/Configure-SSO-Access-to-Bringg'
};

export const DEFAULT_ALERT_REALTIME_EVENTS_BATCHING_TIME = 3000;

export const TestIds = {
	GROUPED_ROW_CHECKBOX: 'grouped-row-checkbox',
	TABLE_ROW: 'table-row',
	TABLE_GROUP_ROW: 'table-group-row',
	TABLE_ROW_MEMO: 'table-row-memo',
	TABLE_GROUP_ROW_MEMO: 'table-group-row-memo',
	NO_DATA_MESSAGE: 'no-data-message',
	TABLE_LOADING_SPINNER: 'table-loading-spinner'
};
