import { getRootEnv } from '@bringg-frontend/bringg-web-infra';
import { BringgException } from '@bringg/dashboard-sdk/dist/Core/BringgException';

import BaseDomainStore from '../core/base-domain-store';
import Skill from './domain-object/skill';
import notification from '../../services/notification';

class SkillsStore extends BaseDomainStore<Skill> {
	async fetchAll() {
		try {
			const { dashboardSdk } = getRootEnv();
			const skills = await dashboardSdk.sdk.skills.getAll();
			const skillsArray = skills.map(skill => new Skill(this, skill));

			this.setBatch(skillsArray);
		} catch (e) {
			const { message, details } = e as BringgException;
			notification.error(message, details as string);
		}
	}

	async create(name: string) {
		try {
			const { dashboardSdk } = getRootEnv();

			const newSkill = await dashboardSdk.sdk.skills.create(name);

			this.set(new Skill(this, newSkill));
		} catch (e) {
			const { message } = e as BringgException;
			console.error(`failed to create skill ${name}`, message);
			throw e;
		}
	}
}

export default SkillsStore;
