import { Condition, ConditionType } from '@bringg/react-components/dist/features/conditions/rule/rule-utils';
import { isEmpty as _isEmpty, isNil as _isNil } from 'lodash';
import { FreeText, Numeric } from '@bringg/react-components';
import { OPERATORS } from './mapper';

export const isConditionInvalid = (condition: Condition): boolean => {
	switch (condition.type) {
		case ConditionType.BOOLEAN:
		case ConditionType.LIST:
			return _isEmpty(condition.value);
		case ConditionType.NUMBER:
			return (
				_isEmpty(condition.value) ||
				(condition.value as Numeric[]).some(
					c =>
						!c.operator ||
						_isNil(c.fromValue) ||
						(c.operator === OPERATORS.IN_RANGE && (_isNil(c.toValue) || c.toValue < c.fromValue))
				)
			);
		case ConditionType.STRING:
			return _isEmpty(condition.value) || (condition.value as FreeText[]).some(c => !c.operator || !c.value);
		default:
			return false;
	}
};
export const validate = (conditions: Condition[]) => conditions.length > 0 && !conditions.some(isConditionInvalid);
