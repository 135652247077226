import React from 'react';

import { getRootEnv } from '@bringg-frontend/bringg-web-infra';
import {
	FilterBar2,
	FilterBar2ListItem,
	FilterBar2Value,
	FilterBar2Values,
	getDefaultFilterBarTranslations
} from '@bringg/react-components';
import { LookupConsts } from '@bringg/dashboard-sdk';
import { useTranslation } from 'react-i18next';
import { omitBy as _omitBy, isNil as _isNil, omit as _omit, omit } from 'lodash';
import { TFunction } from 'i18next';
import moment from 'moment';

import useTimeFormat from 'bringg-web/hooks/use-time-format';
import useDateFormat from 'bringg-web/hooks/use-date-format';
import {
	runDateTranslations,
	runIdTranslations,
	runStatusTranslations,
	runOrderIdTranslations,
	runTitleTranslations,
	RunsFilterItems,
	RunFiltersParams,
	runTeamNameTranslations,
	runDriverNameTranslations
} from './runs-filter.consts';
import { mapListValues } from './runs-filter-utils';

interface Props {
	getFilterResults: (currentFilter: RunFiltersParams) => void;
	initialValues: FilterBar2Values;
	onResetFilter: (field?: string) => void;
	filterItems: RunsFilterItems;
	plannedRouteEnabled: boolean;
}

type NamePath = string | number | (string | number)[];

const onSearch = async (value: string, key: NamePath): Promise<FilterBar2Value[]> => {
	const lookUp = getRootEnv().dashboardSdk.sdk.v2.lookup;
	let listValues;

	const params: LookupConsts.BaseSearchQuery = {
		size: 100,
		query: value
	};

	switch (key) {
		case 'task_ids':
			listValues = await lookUp.getTasksByExternalIds(params);
			break;

		case 'run_ids':
			listValues = await lookUp.getRuns(params);
			break;

		case 'by_title_ids':
			listValues = await lookUp.getPlannedRoutes(params);
			break;

		case 'team_ids':
			listValues = await lookUp.getTeams(params);
			break;

		case 'driver_ids':
			listValues = await lookUp.getUsers({ ...params, roles: ['driver'] });
			break;

		default:
			return [];
	}

	return listValues?.items?.length ? mapListValues(listValues) : [];
};

const buildFilterObject = (
	hourFormat: string,
	dateFormat: string,
	t: TFunction,
	filterItems: RunsFilterItems,
	plannedRouteEnabled: boolean
): FilterBar2ListItem[] => {
	const filterList: FilterBar2ListItem[] = [
		{
			type: FilterBar2.TYPES.SELECT,
			name: 'run_ids',
			title: runIdTranslations(t).runId,
			placeholder: runIdTranslations(t).runId,
			tooltip: runIdTranslations(t).runIdTooltip,
			multiselect: true,
			values: filterItems.run_ids || [],
			customProps: {
				onSearch
			},
			applyFilterOnValueChange: true
		}
	];
	if (plannedRouteEnabled) {
		filterList.push({
			type: FilterBar2.TYPES.SELECT,
			name: 'by_title_ids',
			title: runTitleTranslations(t).runTitle,
			placeholder: runTitleTranslations(t).runTitle,
			tooltip: runTitleTranslations(t).runTitleTooltip,
			multiselect: true,
			values: filterItems.by_title_ids || [],
			customProps: {
				onSearch
			},
			applyFilterOnValueChange: true
		});
	}
	filterList.push(
		{
			type: FilterBar2.TYPES.DATE_RANGE,
			name: 'dates',
			title: runDateTranslations(t).dates,
			tooltip: runDateTranslations(t).datesTooltip,
			customProps: {
				showTime: {
					format: hourFormat
				},
				format: `${dateFormat} ${hourFormat}`,
				ranges: {
					[runDateTranslations(t).today]: [moment().startOf('day'), moment().endOf('day')],
					[runDateTranslations(t).yesterday]: [
						moment().subtract(1, 'd').startOf('day'),
						moment().subtract(1, 'd').endOf('day')
					],
					[runDateTranslations(t).last2Days]: [
						moment().subtract(1, 'd').startOf('day'),
						moment().endOf('day')
					],
					[runDateTranslations(t).last7Days]: [
						moment().subtract(6, 'd').startOf('day'),
						moment().endOf('day')
					],
					[runDateTranslations(t).last30Days]: [
						moment().subtract(29, 'd').startOf('day'),
						moment().endOf('day')
					]
				} as { [name: string]: [moment.Moment, moment.Moment] },
				placeholder: [runDateTranslations(t).fromDate, runDateTranslations(t).toDate]
			},
			applyFilterOnValueChange: true
		},
		{
			type: FilterBar2.TYPES.SELECT,
			name: 'run_status',
			title: runStatusTranslations(t).runStatus,
			placeholder: runStatusTranslations(t).runStatus,
			tooltip: runStatusTranslations(t).runStatusTooltip,
			values: [
				{ label: runStatusTranslations(t).runStatusClosed, value: 'closed' },
				{ label: runStatusTranslations(t).runStatusOpen, value: 'open' }
			],
			applyFilterOnValueChange: true
		},
		{
			type: FilterBar2.TYPES.SELECT,
			name: 'task_ids',
			title: runOrderIdTranslations(t).orderId,
			placeholder: runOrderIdTranslations(t).orderId,
			tooltip: runOrderIdTranslations(t).orderIdTooltip,
			multiselect: true,
			values: filterItems.task_ids || [],
			customProps: {
				onSearch
			},
			applyFilterOnValueChange: true
		},
		{
			type: FilterBar2.TYPES.SELECT,
			name: 'team_ids',
			title: runTeamNameTranslations(t).teamName,
			placeholder: runTeamNameTranslations(t).teamName,
			tooltip: runTeamNameTranslations(t).teamNameTooltip,
			multiselect: true,
			values: filterItems.team_ids || [],
			customProps: {
				onSearch
			},
			applyFilterOnValueChange: true
		},
		{
			type: FilterBar2.TYPES.SELECT,
			name: 'driver_ids',
			title: runDriverNameTranslations(t).driverName,
			placeholder: runDriverNameTranslations(t).driverName,
			tooltip: runDriverNameTranslations(t).driverNameTooltip,
			multiselect: true,
			values: filterItems.driver_ids || [],
			customProps: {
				onSearch
			},
			applyFilterOnValueChange: true
		}
	);
	return filterList;
};

const RunsFilter: React.FunctionComponent<Props> = ({
	getFilterResults,
	initialValues,
	filterItems,
	onResetFilter,
	plannedRouteEnabled
}: Props) => {
	const { t } = useTranslation();
	const hourFormat = useTimeFormat();
	const dateFormat = useDateFormat();

	const getCurrentValues = () =>
		omit(
			{
				...initialValues,
				dates:
					initialValues.from_date && initialValues.to_date
						? [moment(initialValues.from_date as string), moment(initialValues.to_date as string)]
						: undefined
			},
			['items', 'page']
		);

	const onChange = (query: RunFiltersParams) => {
		getFilterResults(_omit(query, ['from_date', 'to_date']));
	};

	return (
		<div className="runs-filter">
			<FilterBar2
				className="dispatch-planning-filter-bar"
				list={buildFilterObject(hourFormat, dateFormat, t, filterItems, plannedRouteEnabled)}
				initialValues={_omitBy(getCurrentValues(), _isNil)}
				translations={getDefaultFilterBarTranslations(t)}
				onFilter={onChange}
				dateFormat={dateFormat}
				onReset={onResetFilter}
			>
				{() => <></>}
			</FilterBar2>
		</div>
	);
};

export default RunsFilter;
