import React, { Component } from 'react';

import { Row, Col, Input } from '@bringg/react-components';
import _isNull from 'lodash/isNull';
import _first from 'lodash/first';
import { BringgFontIcons, BringgIcon } from '@bringg/bringg-icons';
import { FieldData as FieldDataEntity } from '@bringg/types';
import { v4 as uuidv4 } from 'uuid';
import _map from 'lodash/map';

import DraggableList from '../../../components/draggable-list/draggable-list';
import Translate from '../../../translation/translator';

type UniqueValue = {
	value: string;
	uuid: string;
};

interface Props {
	valuesList: string[];
	onListUpdated: (updatedField: Partial<FieldDataEntity>) => void;
}

interface State {
	valuesList: UniqueValue[];
}

export default class ValuesList extends Component<Props, State> {
	state = {
		valuesList: this.props.valuesList.map(value => Object.assign({ value, uuid: uuidv4() }))
	};

	setValue = (index: number, value: string) => {
		const { valuesList } = this.state;
		valuesList[index].value = value;

		this.setAndNotify(valuesList);
	};

	addValue = () => {
		const { valuesList } = this.state;
		valuesList.push({ value: '', uuid: uuidv4() });

		this.setAndNotify(valuesList);
	};

	deleteValue = (index: number) => {
		const { valuesList } = this.state;
		valuesList.splice(index, 1);

		this.setAndNotify(valuesList);
	};

	valueMoved = (removedIndex: number, addedIndex: number) => {
		if (!_isNull(removedIndex) && !_isNull(addedIndex)) {
			const valuesList = [...this.state.valuesList];

			const itemToAdd = _first(valuesList.splice(removedIndex, 1));
			valuesList.splice(addedIndex, 0, itemToAdd);

			this.setAndNotify(valuesList);
		}
	};

	setAndNotify = (valuesList: UniqueValue[]) => {
		this.setState({ valuesList }, () => this.props.onListUpdated({ list_values: _map(valuesList, 'value') }));
	};

	render() {
		return (
			<div className="values-list">
				<DraggableList onFieldMove={this.valueMoved}>
					{this.state.valuesList.map((uniqueValue: UniqueValue, index) => (
						<Row className="value-row" key={uniqueValue.uuid}>
							<Col span={2}>
								<div className="draggable-icon">
									<BringgIcon iconName={BringgFontIcons.Drag} />
								</div>
							</Col>
							<Col span={18}>
								<div>
									<Translate text="ACTION_CONFIGURATION.CONTROL_VALUE" /> {index + 1}
								</div>
								<Input
									placeholder=""
									className="action-data-input"
									type="text"
									onChange={e => this.setValue(index, e.target.value)}
									value={uniqueValue.value}
									data-test-id="action-data-value"
								/>
							</Col>
							<Col span={4}>
								<div
									className="delete-icon"
									onClick={() => this.deleteValue(index)}
									data-test-id="action-data-deleve-value"
								>
									<BringgIcon iconName={BringgFontIcons.Close} />
								</div>
							</Col>
						</Row>
					))}
				</DraggableList>
				<div className="add-value" onClick={this.addValue} data-test-id="action-data-add-value">
					<Translate text="ACTION_CONFIGURATION.ADD_VALUE" />
				</div>
			</div>
		);
	}
}
