import DeliveryBlocksView from './delivery-blocks-view';
import DeliveryBlocksStore from './delivery-blocks-store';
import { generateStoreHelpers } from 'bringg-web/components/with-root-store/store-helper-generator';

class DeliveryBlocksRoot {
	private static _instance?: DeliveryBlocksRoot;

	deliveryBlocksStore: DeliveryBlocksStore;
	deliveryBlocksView: DeliveryBlocksView;

	public static get instance(): DeliveryBlocksRoot {
		if (!DeliveryBlocksRoot._instance) {
			DeliveryBlocksRoot._instance = new DeliveryBlocksRoot();
			DeliveryBlocksRoot._instance.init();
		}

		return DeliveryBlocksRoot._instance;
	}

	init() {
		this.deliveryBlocksStore = new DeliveryBlocksStore();
		this.deliveryBlocksView = new DeliveryBlocksView();
	}

	static reset() {
		DeliveryBlocksRoot._instance = undefined;
	}
}

export const DELIVERY_BLOCKS_WRAPPER = 'delivery-blocks-root-store';

export type DeliveryBlocksRootStore = DeliveryBlocksRoot;

export const deliveryBlocksRootStore = {
	getStore: () => DeliveryBlocksRoot.instance,
	clearStore: () => DeliveryBlocksRoot.reset()
};
export const { useStoreContext: useDeliveryBlocksStore, withRootStore: withDeliveryBlocksStore } =
	generateStoreHelpers(deliveryBlocksRootStore);
