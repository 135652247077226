import { DeliveryBlockResource, DeliveryBlockTemplate } from '@bringg/types';
import DeliveryBlock from 'bringg-web/features/delivery-blocks-v2/stores/domain-objects/delivery-block';

const init = (
	deliveryBlock: Partial<DeliveryBlock> | DeliveryBlockTemplate,
	updateDeliveryBlock: <T>(key: string, value: T) => void
) => {
	const onIcalChange = (ical: string) => {
		updateDeliveryBlock('ical', ical);
	};

	const onChangeName = (name: string) => {
		updateDeliveryBlock('name', name);
	};

	const onChangeDescription = (description: string) => {
		updateDeliveryBlock('description', description);
	};

	const onResourcesChange = (resources: DeliveryBlockResource[]) => {
		updateDeliveryBlock('delivery_block_resources', resources);
	};

	const onCapacityChange = (capacity: number) => {
		updateDeliveryBlock('original_capacity', capacity);
	};

	return { onIcalChange, onChangeName, onChangeDescription, onResourcesChange, onCapacityChange };
};

export const deliveryBlockBaseEditor = { init };
