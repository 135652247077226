import React from 'react';
import { Button, Tooltip } from 'antd';

import { Eco, EcoFilled } from '../icons';

import './styles.scss';

export const EcoButton = ({ title, isActive, onClick }: any) => (
	<Tooltip placement="top" title={title}>
		<Button className="icon-button" icon={isActive ? <EcoFilled /> : <Eco />} shape="circle" onClick={onClick} />
	</Tooltip>
);
