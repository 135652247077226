import React from 'react';
import { PureComponent } from 'react';

import ReactDOM from 'react-dom';

interface Props {
	element: JSX.Element;
	nodeId: string;
}

interface State {
	node: null | HTMLElement;
}

const POLL_MAX_RETRIES_COUNT = 40;

const isInsideTests = process.env.NODE_ENV === 'test';

export class Portal extends PureComponent<Props, State> {
	state: State = {
		node: null
	};
	pollForElementRefTimer: number | null = null;

	componentDidMount() {
		this.updateNode();
	}

	componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>) {
		// when the component updates the node saved inside state is disconnected from body
		// therefore need to reset the pointer to the node
		if (prevState.node) {
			this.setState({ node: null }, () => {
				this.updateNode();
			});
		}
	}

	updateNode = () => {
		if (this.state.node && this.state.node.isConnected) {
			return;
		}

		if (isInsideTests) {
			this.setState({ node: document.getElementById(this.props.nodeId) || document.body });
			return;
		}

		this.pollForElementRef(0);
	};

	pollForElementRef = (retries: number) => {
		this.pollForElementRefTimer = window.setTimeout(() => {
			const node = document.getElementById(this.props.nodeId);

			if (node || retries === POLL_MAX_RETRIES_COUNT) {
				if (!node) {
					console.warn(`unable to find node item for ${this.props.nodeId}`);
				}
				this.setState({ node });
				return;
			}

			this.pollForElementRef(retries + 1);
		}, 200);
	};

	componentWillUnmount = () => {
		if (this.pollForElementRefTimer) {
			clearTimeout(this.pollForElementRefTimer);
			this.pollForElementRefTimer = null;
		}
	};

	getComponentInstance(node: HTMLElement | null) {
		const paramsAttribute = this.state.node?.getAttribute('params');

		if (!paramsAttribute) {
			return this.props.element;
		}

		const callerScope = (window as any).angular.element(node).scope();
		const callerParams = callerScope[paramsAttribute];
		const componentIntanceWithParams = React.cloneElement(this.props.element, callerParams);
		return componentIntanceWithParams;
	}

	render() {
		const node = this.state.node;

		if (node && node.isConnected) {
			return ReactDOM.createPortal(this.getComponentInstance(node), this.state.node!);
		}

		return null;
	}
}
