import React, { useMemo } from 'react';

import { useTranslation } from 'react-i18next';
import { Collapse } from '@bringg/react-components';
import { EnumDefinitionItem } from '@bringg/types';

import { KeyValueTable, useKeyValueDefinitionTableState } from './index';

const { Panel } = Collapse;

interface HeaderComponentProps {
	value?: Record<string, string>;
	onChange?: (value: Record<string, string>) => void;
}

export const HeaderComponent = ({ value = {}, onChange }: HeaderComponentProps) => {
	const { t } = useTranslation();
	const headersDefinition = useMemo(
		() =>
			Object.keys(value).reduce((acc, key) => {
				acc.push({ value: key, name: value[key] });

				return acc;
			}, [] as EnumDefinitionItem[]),
		// don't add value to the dependency array it will cause infinite onChange callback invocation
		[]
	);

	const headersDefinitionState = useKeyValueDefinitionTableState({
		allowEmpty: true,
		keyValueDefinition: headersDefinition,
		onEnumDefinitionChange: (headers: EnumDefinitionItem[]) => {
			const keyValueHeaders = headers.reduce((acc: Record<string, string>, cur: EnumDefinitionItem) => {
				acc[cur.value] = cur.name as string;

				return acc;
			}, {});

			onChange?.(keyValueHeaders);
		},
		translations: {
			errors: {
				emptyRowMessage: t('WEBHOOK_AUTHENTICATION_METHOD.MODAL.ERRORS.NO_EMPTY_LIST'),
				noSameValue: t('WEBHOOK_AUTHENTICATION_METHOD.MODAL.ERRORS.NO_SAME_VALUE'),
				valueAndNameNotEmpty: t('WEBHOOK_AUTHENTICATION_METHOD.MODAL.ERRORS.VALUE_AND_NAME_NOT_EMPTY')
			}
		}
	});

	return (
		<Collapse removePadding>
			<Panel header={t('WEBHOOK_AUTHENTICATION_METHOD.MODAL.HEADERS')} key="headers-panel-1">
				<KeyValueTable
					state={headersDefinitionState}
					headers={{
						keyLabel: t('WEBHOOK_AUTHENTICATION_METHOD.MODAL.KEY'),
						valueLabel: t('WEBHOOK_AUTHENTICATION_METHOD.MODAL.VALUE')
					}}
				/>
			</Panel>
		</Collapse>
	);
};
