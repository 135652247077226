import i18next from 'i18next';
import { defaultTo, isEmpty, isNil, get } from 'lodash';
import { getRoot } from 'mobx-easy';
import { getRootEnv } from '@bringg-frontend/bringg-web-infra';
import { LogRocketService } from '@bringg-frontend/bringg-web-infra';

import ServiceAreaViewStore from 'bringg-web/stores/service-areas-view/service-areas-view-store';
import RootStore from './root-store';
import CrossAppDynamicFormModalView from '../cross-application/modals/dynamic-form/cross-app-dynamic-form-modal-view';
import { registerAction } from '../services/cross-application/cross-application';
import { UserSettingsAction } from '../services/cross-application/cross-application.actions';
import { AuthStore } from './auth-store/auth-store';
import { hasFeatureFlag } from '../utils/feature-flags';

class ViewsStore {
	authStore: AuthStore;
	crossAppDynamicFormModalView: CrossAppDynamicFormModalView;
	serviceAreaViewStore: ServiceAreaViewStore;

	constructor() {
		this.authStore = new AuthStore();
		this.crossAppDynamicFormModalView = new CrossAppDynamicFormModalView();
		this.serviceAreaViewStore = new ServiceAreaViewStore();
	}

	syncLanguageWithAngular() {
		this.initLanguage();

		registerAction(
			UserSettingsAction.USER_SETTINGS_UPDATE_LANG,
			this.initLanguage,
			getRootEnv().dashboardSdk.sdk.crossAppTransport
		);
	}

	initLanguage = (languageCode?: string) => {
		const merchantConfiguration = getRoot<RootStore>().data.merchantConfigurationsStore;
		const userLan = getRootEnv().dashboardSdk.sdk.session.user.language;
		// eslint-disable-next-line no-param-reassign
		languageCode = defaultTo(languageCode || userLan, merchantConfiguration.defaultLanguageCode);
		const { firstDayOfWeek } = merchantConfiguration;

		if (!isEmpty(languageCode)) {
			i18next.changeLanguage(languageCode);
			const env = getRootEnv();
			env.localeService.initMomentLocale(languageCode, firstDayOfWeek);
			getRoot<RootStore>().data.usersStore.setCurrentUserLanguage(languageCode);
		}
	};

	initLogRocket() {
		const appId = getRootEnv().envConfig.log_rocket_app_id;
		const user = get(getRootEnv(), 'dashboardSdk.sdk.session.user');

		if (hasFeatureFlag(user, 'enable_log_rocket') && !isNil(appId)) {
			LogRocketService.initLogRocket(appId, user)
				// eslint-disable-next-line no-console
				.then(() => console.log('LogRocket initialized successfully'))
				.catch(e => console.error('LogRocket initialization failed', e));
		}
	}
}

export default ViewsStore;
