import React from 'react';
import { useTranslation } from 'react-i18next';

import {
	Checkbox,
	TypographyTitle,
	InputNumber,
	BringgInput as Input,
	Tooltip,
	FormItem
} from '@bringg/react-components';

import { BringgFontIcons, BringgIcon } from '@bringg/bringg-icons';

import VehicleCategorySelector from './components/vehicle-category-selector';

import { MIN_NUMBER_VALUE } from './constants';

const GeneralInfoSection = ({ form, vehicleType, vehicleCategories }) => {
	const { t } = useTranslation();

	return (
		<div className="vehicle-profiles-form--section-wrapper">
			<div>
				<TypographyTitle level={4} className="vehicle-profiles-form--section-icon">
					<BringgIcon iconName={BringgFontIcons.Settings} />
				</TypographyTitle>
			</div>
			<div className="vehicle-profiles-form--section-content">
				<TypographyTitle level={4} className="vehicle-profiles-form--section-title">
					{t('VEHICLE_PROFILES.FORM.GENERAL_INFO')}
				</TypographyTitle>
				<div className="vehicle-profiles-form--section-sub-title">
					{t('VEHICLE_PROFILES.FORM.GENERAL_INFO_DESCRIPTION')}
				</div>

				<div className="vehicle-profiles-form--input-group">
					<FormItem
						name="default_for_merchant"
						valuePropName="checked"
						className="vehicle-profiles-form-item"
					>
						<Checkbox className="vehicle-profiles-form--label-text">
							{t('VEHICLE_PROFILES.FORM.IS_DEFAULT')}
							&nbsp;
							<Tooltip
								overlayClassName="attribute-input-tooltip"
								placement="right"
								title={t('VEHICLE_PROFILES.FORM.HELP.IS_DEFAULT')}
							>
								<BringgIcon className="info-icon" iconName={BringgFontIcons.Info} />
							</Tooltip>
						</Checkbox>
					</FormItem>
				</div>

				<div className="vehicle-profiles-form--input-group">
					<div className="vehicle-profiles-form--label-text">
						{t('VEHICLE_PROFILES.FORM.EXTERNAL_ID')}
						&nbsp;
						<Tooltip
							overlayClassName="attribute-input-tooltip"
							placement="right"
							title={t('VEHICLE_PROFILES.FORM.HELP.EXTERNAL_ID')}
						>
							<BringgIcon className="info-icon" iconName={BringgFontIcons.Info} />
						</Tooltip>
					</div>
					<FormItem name="external_id" className="vehicle-profiles-form-item">
						<Input
							placeholder={t('VEHICLE_PROFILES.FORM.EXTERNAL_ID_PLACEHOLDER')}
							type="text"
							data-test-id="external_id"
							className="vehicle-profiles-form--input"
						/>
					</FormItem>
				</div>

				<VehicleCategorySelector vehicleType={vehicleType} vehicleCategories={vehicleCategories} form={form} />

				<div className="vehicle-profiles-form--input-group">
					<div className="vehicle-profiles-form--label-text">
						{t('VEHICLE_PROFILES.FORM.COST_PER_SHIFT')}
						&nbsp;
						<Tooltip
							overlayClassName="attribute-input-tooltip"
							placement="right"
							title={t('VEHICLE_PROFILES.FORM.HELP.COST_PER_SHIFT')}
						>
							<BringgIcon className="info-icon" iconName={BringgFontIcons.Info} />
						</Tooltip>
					</div>
					<FormItem
						name="cost_per_shift"
						rules={[
							{ type: 'number', min: MIN_NUMBER_VALUE, message: t('VEHICLE_PROFILES.ERROR.NUMBER_INPUT') }
						]}
						className="vehicle-profiles-form-item"
					>
						<InputNumber
							placeholder={t('VEHICLE_PROFILES.FORM.COST_PER_SHIFT_PLACEHOLDER')}
							className="vehicle-profiles-form--input"
							data-test-id="cost_per_shift"
						/>
					</FormItem>
				</div>

				<div className="vehicle-profiles-form--checkbox-group">
					<p className="vehicle-profiles-form--label-text">{t('VEHICLE_PROFILES.FORM.ADDITIONAL_OPTIONS')}</p>
					<div>
						<FormItem name="is_green" valuePropName="checked" className="vehicle-profiles-form-item">
							<Checkbox className="vehicle-profiles-form--label-text">
								{t('VEHICLE_PROFILES.FORM.IS_GREEN')}
								&nbsp;
								<Tooltip
									overlayClassName="attribute-input-tooltip"
									placement="right"
									title={t('VEHICLE_PROFILES.FORM.HELP.IS_GREEN')}
								>
									<BringgIcon className="info-icon" iconName={BringgFontIcons.Info} />
								</Tooltip>
							</Checkbox>
						</FormItem>
					</div>
					<div>
						<FormItem name="is_trailer" valuePropName="checked" className="vehicle-profiles-form-item">
							<Checkbox className="vehicle-profiles-form--label-text">
								{t('VEHICLE_PROFILES.FORM.IS_TRAILER')}
								&nbsp;
								<Tooltip
									overlayClassName="attribute-input-tooltip"
									placement="right"
									title={t('VEHICLE_PROFILES.FORM.HELP.IS_TRAILER')}
								>
									<BringgIcon className="info-icon" iconName={BringgFontIcons.Info} />
								</Tooltip>
							</Checkbox>
						</FormItem>
					</div>
					<div>
						<FormItem
							name="can_attach_trailer"
							valuePropName="checked"
							className="vehicle-profiles-form-item"
						>
							<Checkbox className="vehicle-profiles-form--label-text">
								{t('VEHICLE_PROFILES.FORM.CAN_ATTACH_TRAILER')}
								&nbsp;
								<Tooltip
									overlayClassName="attribute-input-tooltip"
									placement="right"
									title={t('VEHICLE_PROFILES.FORM.HELP.CAN_ATTACH_TRAILER')}
								>
									<BringgIcon className="info-icon" iconName={BringgFontIcons.Info} />
								</Tooltip>
							</Checkbox>
						</FormItem>
					</div>
				</div>
			</div>
		</div>
	);
};

export default GeneralInfoSection;
