import React, { useContext, useMemo } from 'react';

import classNames from 'classnames';

import TableInstanceContext from '../../contexts/table-instance-context';
import { GroupedRow, TableRow } from './components';
import type { RenderRowProps } from '../../types';
import { TestIds } from 'bringg-web/consts';

interface Props extends RenderRowProps {
	className?: string;
	// in order to achieve auto width of content row shouldn't be with hardcoded width. So autoColumnWidth={true} needs to be present
	// also the <BringgReactTable> component shouldn't be sticky. Look at bringg-react-table/bringg-react-table.tsx:22
	autoColumnWidth?: boolean;
}

const ListChildRow = ({ index, style, className, autoColumnWidth }: Props) => {
	const {
		prepareRow,
		rows,
		totalColumnsWidth,
		state: { selectedRowIds }
	} = useContext(TableInstanceContext);

	const row = rows[index];
	prepareRow(row);

	const { style: rowStyle, ...restRow } = row.getRowProps({ style });
	const rowStyleMemo = useMemo(() => {
		const styles = {
			...rowStyle
		};
		if (!autoColumnWidth) {
			styles.width = totalColumnsWidth;
		}
		return styles;
		//  rowStyle removed from deps intentionally in our case this should not change ever
	}, [totalColumnsWidth, style, autoColumnWidth]);

	if (row.isGrouped) {
		// This is fix for group isSomeSelected logic, if group has a depth > 1, meaning this group also belongs to a group
		// default isSomeSelected logic stops showing isSomeSelected state for parent group if any leafRow is selected
		// maybe this will be fixed in newer version of react-table package
		const isSomeSelected = !row.isSelected && row.leafRows.some(({ id }) => selectedRowIds[id]);
		return (
			<div {...restRow} style={rowStyleMemo} className="table-group-row" data-test-id={TestIds.TABLE_GROUP_ROW}>
				<GroupedRow key={row.id} row={row} isSomeSelected={isSomeSelected} />
			</div>
		);
	}

	return (
		<div
			{...restRow}
			style={rowStyleMemo}
			className={classNames('table-row', className)}
			data-test-id={TestIds.TABLE_ROW}
		>
			<TableRow key={row.id} row={row} />
		</div>
	);
};

export default ListChildRow;
