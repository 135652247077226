import React from 'react';

// eslint-disable-next-line no-restricted-imports
import { Row, Col } from 'antd';
import { RunHistoryEvent } from '@bringg/types';

import { RUN_HISTORY_TYPES } from '../run.consts';
import RunHistoryFilter from './run-history-filter/run-history-filter';
import RunHistoryList from './run-history-list/run-history-list';
import { Translate } from '../../../translation';

interface Props {
	runHistories: RunHistoryEvent[];
}

interface State {
	currentEvents: RunHistoryEvent[];
	selectedType: RUN_HISTORY_TYPES;
}

class RunHistory extends React.Component<Props, State> {
	state: State = {
		currentEvents: this.props.runHistories,
		selectedType: RUN_HISTORY_TYPES.ALL_EVENTS
	};

	onSelect = (value: number): void => {
		const events: RunHistoryEvent[] =
			value === RUN_HISTORY_TYPES.ALL_EVENTS
				? this.props.runHistories
				: this.props.runHistories.filter(history => history.event_type === value);

		this.setState(() => {
			return {
				currentEvents: events,
				selectedType: value
			};
		});
	};

	public render() {
		return (
			<div data-test-id="runs-history">
				{
					<Row>
						<Col span={24}>
							<RunHistoryFilter onSelect={this.onSelect} selectedType={this.state.selectedType} />
						</Col>
						<Col span={24}>
							{this.state.currentEvents && this.state.currentEvents.length ? (
								<RunHistoryList historyEvents={this.state.currentEvents} />
							) : (
								<Translate text={'RUN.NO_DATA'} />
							)}
						</Col>
					</Row>
				}
			</div>
		);
	}
}

export default RunHistory;
