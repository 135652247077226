import React from 'react';
import { DateDisplay } from '@bringg/react-components';
import { Moment } from 'moment-timezone';
import TimezoneService from '../../services/timezone/timezone-service';

interface Props {
	date: Moment | Date | string;
	format?: string;
	timezone?: string;
}

class TimezoneDate extends React.Component<Props> {
	render() {
		const { date, format, timezone } = this.props;
		const momentTz = TimezoneService.getMomentTimezone(timezone);
		return <DateDisplay date={momentTz(date)} format={format} />;
	}
}

export default TimezoneDate;
