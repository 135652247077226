import { getRootEnv } from '@bringg-frontend/bringg-web-infra';
import { action, computed, makeObservable, observable, reaction, toJS } from 'mobx';
import * as Bringg from '@bringg/types';
import { AuthenticationMethod, AuthorizationType, WebhookAuthenticationConfiguration } from '@bringg/types';
import { countBy } from 'lodash';

import { webhooksApiRootStore } from '../webhooks-api-root-store';

class WebhookAuthenticationConfigurationsStore {
	isFetched = false;
	webhookAuthenticationConfigurations = new Map<number, WebhookAuthenticationConfiguration>();
	webhookUsagesCountMap: Record<number, number> = {};

	constructor() {
		makeObservable(this, {
			fetchAll: action,
			getAll: computed,
			getWebhookAuthUsagesCount: computed,
			create: action,
			update: action,
			delete: action,
			getById: action,
			isFetched: observable,
			webhookAuthenticationConfigurations: observable,
			webhookUsagesCountMap: observable
		});
	}

	async create(
		webhookAuthentication: WebhookAuthenticationConfiguration
	): Promise<WebhookAuthenticationConfiguration> {
		const result = await getRootEnv().dashboardSdk.sdk.webhookAuthenticationConfiguration.create(
			webhookAuthentication
		);

		this.webhookAuthenticationConfigurations.set(result.id, result);

		return result;
	}

	async update(
		id: number,
		webhookAuthentication: Partial<WebhookAuthenticationConfiguration>
	): Promise<WebhookAuthenticationConfiguration> {
		const result = await getRootEnv().dashboardSdk.sdk.webhookAuthenticationConfiguration.update(
			id,
			webhookAuthentication
		);

		this.webhookAuthenticationConfigurations.set(result.id, result);

		return result;
	}

	async fetchAll(isWACAPIOAuthEnabled = false) {
		if (this.isFetched) {
			return;
		}

		const result = await getRootEnv().dashboardSdk.sdk.webhookAuthenticationConfiguration.list();

		this.webhookAuthenticationConfigurations = new Map(
			result
				.filter(configuration => configuration.type !== AuthorizationType.STATIC_HEADER)
				.filter(
					configuration =>
						isWACAPIOAuthEnabled ||
						!(
							configuration.method === AuthenticationMethod.API &&
							configuration.type === AuthorizationType.OAUTH2
						)
				)
				.map(configuration => [configuration.id, configuration])
		);
		this.isFetched = true;
	}

	get getAll() {
		const all = Array.from(this.webhookAuthenticationConfigurations.values());

		return toJS(all);
	}

	get getWebhookAuthUsagesCount() {
		return toJS(this.webhookUsagesCountMap);
	}

	async getById(id: number): Promise<Bringg.WebhookAuthenticationConfiguration> {
		return getRootEnv().dashboardSdk.sdk.webhookAuthenticationConfiguration.getById(id);
	}

	async delete(configurationId: number): Promise<void> {
		await getRootEnv().dashboardSdk.sdk.webhookAuthenticationConfiguration.delete(configurationId);

		this.webhookAuthenticationConfigurations.delete(configurationId);
	}

	afterInit() {
		const { webhooksAppStore } = webhooksApiRootStore.getStore();

		// construct object of used WebhookAuths based on Webhooks list
		reaction(
			() => webhooksAppStore.webhooks,
			value => {
				this.webhookUsagesCountMap = countBy(value, 'webhookAuthenticationConfigurationId');

				value.forEach(webhook => {
					const webhookAuthenticationConfiguration = this.webhookAuthenticationConfigurations.get(
						webhook.webhookAuthenticationConfigurationId
					);
					webhook.setWebhookAuthenticationConfiguration(webhookAuthenticationConfiguration);
				});
			}
		);
	}
}

export default WebhookAuthenticationConfigurationsStore;
