import React, { useMemo } from 'react';

export interface RootStoreGetter<T> {
	getStore: () => T;
}

export function withRootStore<S, P>(
	StoreProvider: React.Provider<S>,
	rootStoreGetter: RootStoreGetter<S>,
	Component: React.ComponentType<P>
) {
	const Wrapped: React.ComponentType<P> = props => {
		const store = useMemo(() => rootStoreGetter.getStore(), []);

		return (
			<StoreProvider value={store}>
				<Component {...props} />
			</StoreProvider>
		);
	};

	return Wrapped;
}

export function withStore<S>(StoreProvider: React.Provider<S>, rootStoreGetter: RootStoreGetter<S>) {
	return function <P>(Component: React.ComponentType<P>) {
		const Wrapped: React.ComponentType<P> = props => {
			return (
				<StoreProvider value={rootStoreGetter.getStore()}>
					<Component {...props} />
				</StoreProvider>
			);
		};

		return Wrapped;
	};
}
