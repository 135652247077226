import moment from 'moment';
import { FormInstance } from 'antd';

export const formatTime = (time: string) => moment(time).format('hh:mm A DD.MM.YY');

export const getCount = (name: string, form: FormInstance): number => {
	const value = form.getFieldValue(name);

	if (Array.isArray(value)) {
		return value.length;
	}

	return Number(Boolean(value));
};
