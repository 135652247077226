import React from 'react';

export const VpnKeyFilled = () => {
	return (
		<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<circle cx="12" cy="12" r="12" fill="#3091CE" />
			<rect width="12" height="12" transform="translate(6 6)" fill="#3091CE" />
			<path
				d="M12.325 11C11.915 9.835 10.805 9 9.5 9C7.845 9 6.5 10.345 6.5 12C6.5 13.655 7.845 15 9.5 15C10.805 15 11.915 14.165 12.325 13H14.5V15H16.5V13H17.5V11H12.325ZM9.5 13C8.95 13 8.5 12.55 8.5 12C8.5 11.45 8.95 11 9.5 11C10.05 11 10.5 11.45 10.5 12C10.5 12.55 10.05 13 9.5 13Z"
				fill="white"
			/>
		</svg>
	);
};
