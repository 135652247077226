import { BreakType } from '@bringg/types';
import { TFunction } from 'i18next';
import { LocationOption } from '../../../stores/domain-objects/delivery-block-break';

export const getStartTimeText = (type: BreakType, t: TFunction): string => {
	return type === BreakType.Fixed ? `${t('DELIVERY_BLOCKS.BETWEEN')}:` : `${t('DELIVERY_BLOCKS.NO_EARLIER_THAN')}:`;
};

export const getEndTimeText = (type: BreakType, t: TFunction): string => {
	return type === BreakType.Fixed ? '' : `${t('DELIVERY_BLOCKS.NO_LATER_THAN')}:`;
};

export const getTimesSeparator = (type: BreakType): string => {
	return type === BreakType.Fixed ? '&' : '-';
};

export const BREAK_TYPE_OPTIONS: string[] = Object.keys(BreakType).filter(
	key => key !== 'Relative' && Number.isNaN(Number(key))
);
export const BREAK_TYPE_OPTIONS_WITH_RELATIVE: string[] = Object.keys(BreakType).filter(key =>
	Number.isNaN(Number(key))
);

export const LOCATION_OPTIONS: string[] = Object.keys(LocationOption).filter(key => Number.isNaN(Number(key)));
