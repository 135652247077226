export class OnlySingleArrayOfCoordinatesError extends Error {
	constructor() {
		super('Only single array of coordinates is supported');
	}
}

export class InvalidPolygonError extends Error {}

export function coordinatesToPolygon(coordinatesInput: string) {
	const trimmed = coordinatesInput.trim();
	const noWhiteSpaces = trimmed.replace(/[^\S\r\n]/g, '');
	const noNewLines = noWhiteSpaces.replace(/\s/g, '');

	if (noNewLines.includes('[(')) {
		// Array + WKT, somebody use it?
		return csvToPolygon(wktStringToCsv(noNewLines.replace('[', '(').replace(']', ')')));
	}

	if (noNewLines.includes('[[')) {
		// Array
		return csvToPolygon(arrayStringToCsv(noNewLines));
	}

	if (noNewLines.includes('((')) {
		// WKT
		return csvToPolygon(wktStringToCsv(noNewLines));
	}

	if (/^([+-]?([0-9]*[.])?[0-9]+,[+-]?([0-9]*[.])?[0-9]+$)+/gm.test(noWhiteSpaces)) {
		// CSV
		return csvToPolygon(noWhiteSpaces);
	}

	throw new InvalidPolygonError(`Can't parse coordinates ${noWhiteSpaces}`);
}

function arrayStringToCsv(arrayString: string) {
	const string = arrayString.replace(/]]/, '').replace(/\[\[/, '').replace(/],]/g, '').replace(/],\[/g, '\n');

	if (string.includes(']]') || string.includes('[[')) {
		throw new OnlySingleArrayOfCoordinatesError();
	}

	return string;
}

function wktStringToCsv(wktString: string) {
	const string = wktString.replace(/\)\)/, '').replace(/\(\(/, '').replace(/\),\(/g, '\n');

	if (string.includes('))') || string.includes('((')) {
		throw new OnlySingleArrayOfCoordinatesError();
	}

	return string;
}

function csvToPolygon(csv: string) {
	const polygon = csv.split('\n').map(coords => coords.split(',').map(parseFloat));

	if (polygon.some(coords => coords.some(Number.isNaN))) {
		throw new InvalidPolygonError("Coordinates can't be NaN");
	}

	return polygon;
}
