import React from 'react';

import { Button } from '@bringg/react-components';
import { useTranslation } from 'react-i18next';

interface Props {
	onCancel: () => void;
	isLoading: boolean;
	disabled: boolean;
	onUpdate: () => void;
}
const ModalFooter = ({ onCancel, isLoading, disabled, onUpdate }: Props) => {
	const { t } = useTranslation();

	return (
		<div>
			<Button type="link" onClick={onCancel}>
				{t('PLANNED_DELIVERY_WINDOWS_MODAL_FOOTER.CANCEL')}
			</Button>
			<Button type="primary" onClick={onUpdate} loading={isLoading} disabled={disabled}>
				{t('PLANNED_DELIVERY_WINDOWS_MODAL_FOOTER.UPDATE')}
			</Button>
		</div>
	);
};

export default ModalFooter;
