import React, { Component } from 'react';

import { inject, observer } from 'mobx-react';
import fromPairs from 'lodash/fromPairs';
import map from 'lodash/map';
import { VariablesLegend } from '@bringg/react-components';
import { withErrorBoundary } from '@bringg-frontend/bringg-web-infra';

import MerchantConfigurationsStore from '../../../stores/merchant-configurations/merchant-configurations-store';
import CustomerNotificationsContainer from './customer-notifications-container/customer-notifications-container';
import { buildDictionary } from '../helper';
import MerchantCustomerNotificationsStore from '../../../stores/customer-notifications/merchant-customer-notifications-store';
import { FetchedData } from '../../../components';
import UsersStore from '../../../stores/users/users-store';
import Translate from '../../../translation/translator';

interface Props {
	merchantConfigurationsStore?: MerchantConfigurationsStore;
	merchantCustomerNotificationsStore?: MerchantCustomerNotificationsStore;
	usersStore?: UsersStore;
}

class MerchantCustomerNotificationsContainer extends Component<Props> {
	legendDictionary: any = {};
	previewDictionary: any = {};

	constructor(props: Props) {
		super(props);
		const userName = this.props.usersStore.currentUser.name;
		this.legendDictionary = buildDictionary(userName, this.getMerchantName);
		this.previewDictionary = fromPairs(map(this.legendDictionary, item => [item.key, item.value])) as {
			key: string;
			value: string | Function;
		};
	}

	async componentDidMount(): Promise<void> {
		await this.props.merchantCustomerNotificationsStore.initConfigurations();
	}

	getMerchantName = () => {
		const { configuration } = this.props.merchantConfigurationsStore;
		return configuration ? configuration.name : '{{merchant}}';
	};

	render() {
		const { isFetched } = this.props.merchantCustomerNotificationsStore;
		return (
			<FetchedData isFetched={isFetched}>
				<div className="customer-notifications">
					<div className="body">
						<VariablesLegend
							dictionary={this.legendDictionary}
							title={<Translate text="CUSTOMER_CONFIGURATION_VIEW.VARIABLES" />}
							keyValueSeparator={<Translate text="CUSTOMER_CONFIGURATION_VIEW.WILL_BE_CHANGED_TO" />}
						/>
						<CustomerNotificationsContainer dictionary={this.previewDictionary} />
					</div>
				</div>
			</FetchedData>
		);
	}
}

export default withErrorBoundary(
	inject(
		'merchantCustomerNotificationsStore',
		'merchantConfigurationsStore',
		'usersStore'
	)(observer(MerchantCustomerNotificationsContainer))
);
