import React, { memo } from 'react';
import { CustomAttributeEntityType } from '@bringg/types';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import './styles.scss';

interface Props {
	entityType: CustomAttributeEntityType;
	suggestion?: boolean;
}

function AttributeEntityTypeCell({ entityType, suggestion }: Props) {
	const { t } = useTranslation();

	return (
		<span className={classNames('attribute-type', { 'suggested-attribute': suggestion })}>
			{t(`CUSTOM_ATTRIBUTES.ENTITY_TYPE_${CustomAttributeEntityType[entityType].toUpperCase()}`)}
		</span>
	);
}

export default memo(AttributeEntityTypeCell);
