import ViewsStore from './views-store';
import DataStore from './data-store';

class RootStore {
	data: DataStore;
	views: ViewsStore;

	init() {
		this.data = new DataStore();
		this.views = new ViewsStore();

		this.data.afterRootInit();
	}

	resetAfterRemoveRoot() {
		this.data.resetAfterRemoveRoot();
	}
}

export default RootStore;
