import React from 'react';
import { Button, Tag, Tooltip } from '@bringg/react-components';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { BringgFontIcons, BringgIcon } from '@bringg/bringg-icons';
import styles from './planned-delivery-windows-popover-content.module.scss';
import { PlannedDeliveryWindowsEventType } from '../../planned-delivery-windows-calendar';
import { useStores } from '../../../../../recipes';
import { Cutoff } from '../../../cutoff/cutoff';

interface Props {
	title: string;
	event: PlannedDeliveryWindowsEventType;
	setPopoverOpen: React.Dispatch<React.SetStateAction<boolean>>;
	openModalWithPlannedDeliveryWindowSlot: (plannedDeliveryWindowId: number) => void;
	openRecurrenceTypeModalWithDeleteMode: (id: number) => void;
	fillRatio: string;
	timezone: string;
}

const PlannedDeliveryWindowsPopoverContent = ({
	title,
	event,
	openModalWithPlannedDeliveryWindowSlot,
	openRecurrenceTypeModalWithDeleteMode,
	fillRatio,
	timezone
}: Props) => {
	const { servicePlansStore, serviceArea } = useStores();
	const { t } = useTranslation();

	const getMultiTags = (idsToTags: number[], translate: string, getName: (id: number) => string) => {
		if (!idsToTags?.length) {
			return null;
		}

		if (idsToTags.length > 1) {
			return <Tag className={styles.tag}>{`${idsToTags.length} ${translate}`}</Tag>;
		}
		return <Tag className={styles.tag}>{getName(idsToTags[0])}</Tag>;
	};

	return (
		<div className={styles.popoverContent}>
			<div className={styles.header}>
				<div className={styles.title}>
					<Tooltip title={title}>{title}</Tooltip>
				</div>
				<div className={styles.actions}>
					<Tooltip title={t('GLOBAL.EDIT')}>
						<Button
							icon={<BringgIcon iconName={BringgFontIcons.ShowMore} />}
							className={styles.gray600}
							shape="circle"
							type="text"
							onClick={() => openModalWithPlannedDeliveryWindowSlot(event.id)}
							data-test-id="planned-delivery-window-popover-content-edit"
						/>
					</Tooltip>
					<Tooltip placement="top" title={t('GLOBAL.DELETE')}>
						<Button
							icon={<BringgIcon iconName={BringgFontIcons.Trash} />}
							className={styles.gray600}
							shape="circle"
							type="text"
							onClick={() => openRecurrenceTypeModalWithDeleteMode(event.id)}
							data-test-id="planned-delivery-window-popover-content-delete"
						/>
					</Tooltip>
				</div>
			</div>
			<div className={styles.popoverContentBody}>
				{event.cutoff !== 0 && (
					<Cutoff startWindowTime={event.start} cutoff={event.cutoff} timezone={timezone} />
				)}
				{`${t('PLANNED_DELIVERY_WINDOWS_POPOVER_CONTENT.FILL_RATIO')}: ${fillRatio}`}
				<div className={styles.services}>
					{getMultiTags(
						event.service_plan_ids,
						t('PLANNED_DELIVERY_WINDOWS.POPOVER.SERVICE_PLAN'),
						(id: number) => servicePlansStore.servicePlans.get(id)?.name
					)}
					{getMultiTags(
						event.service_area_ids,
						t('PLANNED_DELIVERY_WINDOWS.POPOVER.SERVICE_AREA'),
						(id: number) => serviceArea.get(id)?.name
					)}
				</div>
			</div>
		</div>
	);
};

export default observer(PlannedDeliveryWindowsPopoverContent);
