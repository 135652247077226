const customerDescription = {
	id: { desc: 'The unique Id of this customer' },
	access_token: { desc: "TThis customer's access token for login" },
	address: { desc: "This customer's address. The maximum length is 255 characters" },
	address_second_line: {
		desc: "This customer's address second line, if needed. The maximum length is 255 characters"
	},
	allow_sending_email: {
		desc:
			'Indicates whether this customer allows email sharing. The values are:\n' +
			'false or 0 - customer does not allow email sharing\n' +
			'true or 1 - customer allows email sharing\n' +
			'The default value is true.'
	},
	allow_sending_sms: {
		desc:
			'Indicates whether this customer allows SMS sharing.The values are:\n' +
			'false or 0 - customer does not allow SMS sharing\n' +
			'true or 1 - customer allows SMS sharing\n' +
			'The default value is true.'
	},
	borough: { desc: "This customer's borough. This can also be used for any neighborhood or zone" },
	business_code: { desc: "This customer's business code" },
	city: { desc: "This customer's city" },
	client_name: { desc: 'The client name' },
	client_version: { desc: 'The client version' },
	confirmation_code: { desc: "The confirmation code sent to this customer's phone for login verification" },
	consecutive_checkins_out_of_geofence: {
		desc: 'The number of times the user (driver) checked in outside of the geofence'
	},
	email: { desc: 'The email address of this customer. The default value is an empty string' },
	external_id: { desc: "The customer's external Id" },
	house_number: { desc: 'The house number of this customer' },
	image: { desc: 'The URL of an image associated with this customer' },
	lat: { desc: "The latitude of this customer's address" },
	lng: { desc: "The longitude of this customer's address" },
	merchant_id: {
		desc: 'The Id of the company. The merchant_id may be null, if the developer works with only one company'
	},
	name: { desc: 'The name of this customer. The maximum length is 255 letters' },
	original_lat: {
		desc: "The original latitude of this customer's address when this customer was created. The default is null"
	},
	original_lng: {
		desc: "The original longitude of this customer's address when this customer was created. The default is null"
	},
	original_phone_number: {
		desc: "This customer's original phone number, if the phone number changed. The default is the same phone number as phone"
	},
	phone: { desc: 'The phone number of this customer' },
	state: { desc: "This customer's state" },
	street: { desc: "This customer's street" },
	zipcode: { desc: "This customer's ZIP Code. The default value is null" }
};

const taskInventoriesDescription = {
	external_id: { desc: "The customer's external Id" },
	inventory_id: { desc: 'The Id of this task inventory item' },
	merchant_id: {
		desc: 'The Id of the company. The merchant_id may be null, if the developer works with only one company'
	},
	name: { desc: 'The name of the inventories in this order' },
	original_quantity: { desc: 'The original quantity of the inventory item' },
	note: { desc: 'A note added to this inventory item' },
	price: { desc: 'The price of the inventory item' },
	quantity: { desc: 'The quantity of this inventory item' },
	width: { desc: 'The width of this inventory item' },
	weight: { desc: 'The weight of this inventory item' },
	scan_string: { desc: 'If a scan code is associated with this task inventory item, this is that scan code' }
};

const teamDescription = {
	id: { desc: 'The Id of this team' },
	address: { desc: 'The of this team' },
	contact_phone: { desc: 'The contact phone number associated with this team' },
	country_code: { desc: 'The country code of this team. The maximum length is 255 characters' },
	description: { desc: 'The description of this team' },
	external_id: { desc: "The team's external Id" },
	lat: { desc: "The geoposition latitude of the team's address" },
	lng: { desc: "The geoposition longitude of the team's address" },
	merchant_id: {
		desc: 'The Id of the company. The merchant_id may be null, if the developer works with only one company'
	},
	name: { desc: 'The name of this team' },
	zipcode: { desc: 'The ZIP Code of this team' }
};

const waypointDescription = {
	id: { desc: 'The unique Id of the waypoint' },
	address: { desc: 'The address of this waypoint' },
	address_second_line: { desc: 'The second line of the address of this waypoint' },
	location_name: { desc: 'name of the location e.g. "Public Library"' },
	allow_scanning_inventory: {
		desc:
			'Indicates whether the user (driver) is allowed to scan the inventory. The values are:\n' +
			'false or 0 - the user (driver) is not allowed to scan the inventory\n' +
			'true or 1 - the user (driver) is allowed to scan the inventory\n' +
			'The default value is false'
	},
	allow_sending_email: {
		desc:
			'Indicates whether emails are allowed to be sent to the customer on this waypoint. The values are:\n' +
			'false or 0 - do not send emails\n' +
			'true or 1 - allow emails to be sent\n' +
			'The default value is true'
	},
	allow_sending_sms: {
		desc:
			'Indicates whether SMSes are allowed to be sent to the customer on this waypoint. The values are:\n' +
			'false or 0 - do not send SMSes\n' +
			'true or 1 - allow SMSes to be sent\n' +
			'The default value is true'
	},
	asap: {
		desc:
			'Indicates whether this is an ASAP waypoint. The values are:\n' +
			'\n' +
			'false or 0 - this not an ASAP waypoint\n' +
			'true or 1 - this is an ASAP waypoint\n' +
			'The default value is false.'
	},
	borough: { desc: "This waypoint's borough. This can also be used for any neighborhood or zone" },
	city: { desc: "This waypoint's city" },
	company_name: { desc: 'The company name associated with this waypoint' },
	contacts: {
		desc: 'An array of objects containing detailed information about all customers waiting for this order, including their contact information and whether the user (driver) is allowed to contact each'
	},
	customer: customerDescription,
	customer_id: { desc: 'The Id of the customer associated with this waypoint' },
	delivery_method: {
		desc:
			'This is for waypoint 2 only. Possible values:\n' +
			'contactless : This tells the driver or external fleet that the delivery needs to be contactless'
	},
	district: { desc: 'The district of the order' },
	email: { desc: 'The email address of this waypoint' },
	house_number: { desc: 'The house number of the order' },
	lat: { desc: 'The geoposition latitude of this waypoint' },
	lng: { desc: 'The geoposition longitude of this waypoint' },
	merchant_id: {
		desc: 'The Id of the company. The merchant_id may be null, if the developer works with only one company'
	},
	no_earlier_than: {
		desc: 'The date and time of the beginning of the delivery time window, if one exists. This is in UTC in the format is " %Y-%m-%dT%H:%M:%S%z"'
	},
	no_later_than: {
		desc: 'The date and time of the end of the delivery time window, if one exists. This is in UTC in the format is " %Y-%m-%dT%H:%M:%S%z"'
	},
	note: { desc: 'A note on this waypoint. The maximum length is 255 characters' },
	phone: { desc: 'The phone number of this waypoint' },
	pickup_dropoff_option: {
		desc:
			'Indicates whether this waypoint is a pickup, dropoff, or both. The values are:\n' +
			'0 - pickup\n' +
			'1 - dropoff\n' +
			'2 - both'
	},
	position: {
		desc: 'Indicates the arrangement of a sequence of waypoints. For example, a task with 2 waypoints would have a waypoint with position 1 (the first waypoint) and a waypoint with position 2 (the second waypoint)'
	},
	scheduled_at: {
		desc: 'The date and time the order was scheduled. This is in UTC and is in the format is " %Y-%m-%dT%H:%M:%S%z"'
	},
	state: { desc: "This waypoint's state" },
	street: { desc: 'The street of the order' },
	task_id: { desc: 'Bringg order Id' },
	zip_code: { desc: 'The ZIP code of this waypoint' }
};
export const TaskDescriptions = {
	id: { desc: 'The unique Id of this order' },
	active_way_point_id: {
		desc: "The Id of the way point in this order that is the user's (driver's) next destination"
	},
	customer: customerDescription,
	customer_id: { desc: 'The Id of the customer associated with this order' },
	external_id: { desc: "The order's external Id" },
	fleet_id: { desc: 'If this order is assigned to an external fleet, this is the Id of that external fleet' },
	merchant_id: {
		desc: 'The Id of the company. The merchant_id may be null, if the developer works with only one company'
	},
	tag_id: { desc: 'If a tag exists, this is the Bringg tag Id' },
	task_inventories: [taskInventoriesDescription],
	task_notes: { desc: 'An array of objects containing notes added to this order' },
	team_ids: { desc: 'An array of Int32 containing the Ids of all teams assigned to this order' },
	title: { desc: "This order's title. The maximum length is 255 characters" },
	user_id: { desc: 'The Id of the user (driver). The default value is null, if no user (driver) is assigned' },
	way_points: [waypointDescription, waypointDescription],
	delivery_price: { desc: 'The delivery fee that the customer pays for this order' },
	delivery_cost: { desc: 'The cost per delivery charged by a 3rd party fleet' },
	discount: { desc: 'The discount amount of this order' },
	external_team_id: { desc: 'Specify your Team ID (instead of the Bringg Team ID)' },
	external_user_id: {
		desc: "The external user's (driver's) Id associated with this order (the user or driver's Id in your system)"
	},
	pre_delivery_tip: { desc: 'The tip (gratuity) paid to the user (driver) before this order delivery' },
	quote_id: { desc: 'The Id of a quote received from Get Quote' },
	price_before_tax: { desc: 'The net price of this order not including taxes' },
	total_price: { desc: 'The total price of this order' },
	tip: { desc: 'The tip (gratuity) paid to the user (driver) by the customer for this order' },
	team: teamDescription
};
