import React, { useState, useEffect } from 'react';

import { Table, TablePaginationConfig, ConfigProvider, Empty } from 'antd';
import moment, { Moment } from 'moment';
import { useTranslation } from 'react-i18next';
import { Portal } from '@bringg-frontend/bringg-web-infra';

import FilterBar from './filter-bar';
import getConfigurationsAudit from './get-configurations-audit';
import ChangedFieldsString from './changed-fields-string';

import './configurations-audit.scss';

import ExportLog from './export-log';

const pagination = (length: number): TablePaginationConfig => {
	const onePageSize = 10;
	const showControls = length > onePageSize;

	return {
		position: ['bottomRight'],
		hideOnSinglePage: !showControls,
		showSizeChanger: showControls
	};
};

const EmptyTableData = (isLoading: boolean, t: typeof useTranslation) => {
	const EmptyDescription = () => {
		if (isLoading) {
			return null;
		}

		return (
			<>
				<div>{t('CONFIGURATION_AUDIT.NO_DATA')}</div>
				<div>{t('CONFIGURATION_AUDIT.TRY_BROADERING_RANGE')}</div>
			</>
		);
	};

	return () => <Empty description={<EmptyDescription />} />;
};

const initialFilterValues = {
	changeTime: [moment().subtract(7, 'days'), moment()] as [Moment, Moment]
};

const ConfigurationsAudit = () => {
	const [isLoading, setIsLoading] = useState(true);
	const [configurationsAudit, setConfigurationsAudit] = useState([]);
	const [exportLogValues, setExportLogValues] = useState(initialFilterValues);
	const [exportLogDisable, setExportLogDisable] = useState(true);
	const { t } = useTranslation();
	const tableColumns = [
		{
			title: t('CONFIGURATION_AUDIT.CHANGE_TIME'),
			dataIndex: 'changeTime',
			width: 170,
			sorter: (a, b) => (a > b ? 1 : -1)
		},
		{
			title: t('CONFIGURATION_AUDIT.CONFIGURATION_MODEL'),
			dataIndex: 'configurationModel',
			width: 250
		},
		{
			title: t('CONFIGURATION_AUDIT.CONFIGURATION_ID'),
			dataIndex: 'configurationId',
			width: 140
		},
		{
			title: t('CONFIGURATION_AUDIT.CHANGED_FIELDS'),
			dataIndex: 'changedFields',
			render: (text: string[]) => <ChangedFieldsString text={text} />
		},
		{
			title: t('CONFIGURATION_AUDIT.CHANGED_BY'),
			dataIndex: 'changedBy',
			width: 150
		},
		{
			title: ' ',
			dataIndex: 'changes',
			width: 120
		}
	];

	const setTableValues = async pendingPromise => {
		setIsLoading(true);

		const audits = await pendingPromise;
		setConfigurationsAudit(audits);
		setExportLogDisable(Boolean(audits.length === 0));

		setIsLoading(false);
	};

	useEffect(() => {
		const getTableData = async () => setTableValues(getConfigurationsAudit(initialFilterValues));

		getTableData();
	}, []);

	return (
		<>
			<h2>{t('CONFIGURATION_AUDIT.TITLE')}</h2>
			<div className="configurations-audit form-horizontal">
				<FilterBar
					setTableValues={setTableValues}
					setExportValues={setExportLogValues}
					initialValues={initialFilterValues}
				/>
				<ExportLog exportValues={exportLogValues} disabled={exportLogDisable} />
				<ConfigProvider renderEmpty={EmptyTableData(isLoading, t)}>
					<Table
						className="configurations-audit-antd-table-overides"
						dataSource={configurationsAudit}
						columns={tableColumns}
						pagination={pagination(configurationsAudit.length)}
						size="middle"
						loading={isLoading}
						tableLayout="fixed"
					/>
				</ConfigProvider>
			</div>
		</>
	);
};

const ConfigurationsAuditPortal = () => (
	<Portal element={<ConfigurationsAudit />} nodeId="configurations-audit-portal" />
);

export default ConfigurationsAuditPortal;
