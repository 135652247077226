import { getRootEnv } from '@bringg-frontend/bringg-web-infra';
import { action, computed, observable, makeObservable } from 'mobx';
import { getEnv } from 'mobx-easy';
import { RootEnv } from '@bringg-frontend/bringg-web-infra';

import CompaniesStore from '../companies-store';

interface Company {
	setName: (name: string) => void;
	setExternalId: (external_id: string) => void;
	setPhone: (external_id: string) => void;
	setTaxId: (external_id: string) => void;
	setAddress: (
		address: string,
		lat: number,
		lng: number,
		street: string,
		city: string,
		zipcode: string,
		house_number: number
	) => void;
}

class Company {
	id: number = null;

	external_id: string = null;

	name = '';

	phone: string = null;

	tax_id: string = null;

	address: string = null;

	lat: number = null;

	lng: number = null;

	street: string = null;

	city: string = null;

	zipcode: string = null;

	house_number: number = null;

	merchant_id: number = null;

	companiesStore: CompaniesStore = null;

	constructor(company: Bringg.Company, companiesStore: CompaniesStore) {
		makeObservable(this, {
			external_id: observable,
			name: observable,
			phone: observable,
			tax_id: observable,
			address: observable,
			lat: observable,
			lng: observable,
			street: observable,
			city: observable,
			zipcode: observable,
			house_number: observable,
			setAddress: action,
			drivers: computed,
			dispatchers: computed,
			setName: action,
			setExternalId: action,
			setPhone: action,
			setTaxId: action
		});

		Object.assign(this, company);

		this.companiesStore = companiesStore;
	}

	setAddress = (
		address: string,
		lat: number,
		lng: number,
		street: string,
		city: string,
		zipcode: string,
		house_number: number
	) => {
		this.address = address;
		this.lat = lat;
		this.lng = lng;
		this.street = street;
		this.city = city;
		this.zipcode = zipcode;
		this.house_number = house_number;
	};

	setExternalId = (externalId: string) => {
		this.external_id = externalId;
	};

	setName = (name: string) => {
		this.name = name;
	};

	setPhone = (phone: string) => {
		this.phone = phone;
	};

	setTaxId = (taxId: string) => {
		this.tax_id = taxId;
	};

	get drivers() {
		const companyUsers = this.companiesStore.companyUsers.get(this.id) || [];

		return companyUsers.filter(user => Boolean(user.driver));
	}

	get dispatchers() {
		const companyUsers = this.companiesStore.companyUsers.get(this.id) || [];

		return companyUsers.filter(user => Boolean(user.dispatcher));
	}

	update = async () => {
		await getRootEnv().dashboardSdk.sdk.companies.update(this.id, this.toJson());
	};

	toJson() {
		return {
			name: this.name,
			external_id: this.external_id,
			phone: this.phone,
			tax_id: this.tax_id,
			address: this.address,
			lat: this.lat,
			lng: this.lng,
			street: this.street,
			city: this.city,
			zipcode: this.zipcode,
			house_number: this.house_number,
			merchant_id: this.merchant_id
		};
	}
}

export default Company;
