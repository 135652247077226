import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { BringgFontIcons, BringgIcon } from '@bringg/bringg-icons';
import {
	BringgInput,
	BringgTextArea,
	Col,
	Row,
	Select,
	Tooltip,
	Checkbox,
	Modal,
	Button,
	Form,
	useForm,
	FormItem,
	useWatch
} from '@bringg/react-components';
import {
	CustomAttributeRes,
	CustomAttributeEntityType,
	CustomAttributeDataType,
	pathValidationRegExp
} from '@bringg/types';
import { KeyValueTable } from '@bringg-frontend/key-value-table';
import { hasFeatureFlag } from '@bringg-frontend/global-stores';

import { ATTRIBUTE_PATH_START_KEY } from 'bringg-web/stores/custom-attributes/consts';
import { useStores } from 'bringg-web/recipes';
import { TEST_IDS } from './test-ids';
import { useKeyValueDefinitionTableState } from '../../hooks';

import './attribute-modal-details.scss';

type Rule = React.ComponentProps<typeof FormItem>['rules'][number];
type FormInstance = React.ComponentProps<typeof Form>['form'];

const DATA_TYPE_EXAMPLES = {
	string: '{extras: {"child_name":"john"}}',
	numeric: '{extras: {"box_height":30}}',
	boolean: '{extras: {"leave_at_door":true}}',
	enum: '{extras: {"number_level":"1"}}'
};

interface ModalFooterProps {
	form: FormInstance;
	attribute: CustomAttributeRes;
	enumTableContainsError: boolean;
	isSubmitted: boolean;
	onOk: (isAttrRequired: boolean, showOnMobile: boolean) => void;
	onCancel: () => void;
	isShowOnMobileEnabled?: boolean;
}

const ModalFooter = memo(
	({
		form,
		attribute,
		enumTableContainsError,
		isSubmitted,
		onCancel,
		onOk,
		isShowOnMobileEnabled = false
	}: ModalFooterProps) => {
		const { t } = useTranslation();

		const [isAttrRequired, setIsAttrRequired] = useState(Boolean(attribute?.required));
		const [showOnMobile, setShowOnMobile] = useState(Boolean(attribute?.show_in_mobile_app));
		const [isSubmitting, setIsSubmitting] = useState(false);

		useEffect(() => {
			setIsSubmitting(isSubmitted);
		}, [isSubmitted]);

		const handleMandatoryChange = useCallback(event => {
			setIsAttrRequired(event.target.checked);
		}, []);

		const handleShowOnMobileChange = useCallback(event => {
			setShowOnMobile(event.target.checked);
		}, []);

		const okButtonText = useMemo(() => {
			if (attribute?.id && !attribute?.suggestion) {
				return t('CUSTOM_ATTRIBUTES.EDIT_ATTRIBUTE');
			}

			return t('CUSTOM_ATTRIBUTES.ADD_ATTRIBUTE');
		}, [t, attribute]);

		const submit = useCallback(async () => {
			await form
				.validateFields()
				.then(() => {
					setIsSubmitting(true);
					onOk(isAttrRequired, showOnMobile);
				})
				.catch(info => {
					console.error('Validate Failed:', info);
				});
		}, [form, isAttrRequired, showOnMobile, onOk]);

		return (
			<>
				{!isShowOnMobileEnabled && (
					<Checkbox
						id="mandatory-attribute"
						checked={isAttrRequired}
						onChange={handleMandatoryChange}
						className="checkbox-mandatory"
					>
						{t('CUSTOM_ATTRIBUTES.MANDATORY_ATTRIBUTE')}
						<Tooltip
							id={TEST_IDS.mandatory_attribute_tooltip}
							overlayClassName="attribute-input-tooltip"
							title={t('CUSTOM_ATTRIBUTES.MANDATORY_ATTRIBUTE_TOOLTIP')}
							placement="top"
						>
							<BringgIcon iconName={BringgFontIcons.Info} />
						</Tooltip>
					</Checkbox>
				)}
				{isShowOnMobileEnabled && (
					<div className="checkbox-element">
						<Checkbox
							id="mobile-checkbox"
							checked={showOnMobile}
							onChange={handleShowOnMobileChange}
							className="show-on-mobile-checkbox"
						>
							{t('CUSTOM_ATTRIBUTES.SHOW_ON_MOBILE_APP')}
							<Tooltip
								id={TEST_IDS.show_on_mobile_tooltip}
								overlayClassName="attribute-input-tooltip"
								title={t('CUSTOM_ATTRIBUTES.SHOW_ON_MOBILE_TOOLTIP')}
								placement="top"
							>
								<BringgIcon iconName={BringgFontIcons.Info} />
							</Tooltip>
						</Checkbox>
						<Checkbox id="mandatory-attribute" checked={isAttrRequired} onChange={handleMandatoryChange}>
							{t('CUSTOM_ATTRIBUTES.MANDATORY_ATTRIBUTE')}
							<Tooltip
								id={TEST_IDS.mandatory_attribute_tooltip}
								overlayClassName="attribute-input-tooltip"
								title={t('CUSTOM_ATTRIBUTES.MANDATORY_ATTRIBUTE_TOOLTIP')}
								placement="top"
							>
								<BringgIcon iconName={BringgFontIcons.Info} />
							</Tooltip>
						</Checkbox>
					</div>
				)}
				<div className="modal-actions">
					<Button type="link" onClick={onCancel}>
						{t('GLOBAL.CANCEL')}
					</Button>
					<Button type="primary" onClick={submit} disabled={enumTableContainsError || isSubmitting}>
						{okButtonText}
					</Button>
				</div>
			</>
		);
	}
);

interface ModalTitleProps {
	attributeName: string;
}

const ModalTitle = memo(({ attributeName }: ModalTitleProps) => {
	const { t } = useTranslation();

	return (
		<div className="attribute-definition-header">
			<span className="attribute-definition-header-title">{t('CUSTOM_ATTRIBUTES.CONFIGURE_ATTRIBUTE')}</span>
			<span className="attribute-definition-header-subtitle">
				{t('CUSTOM_ATTRIBUTES.CONFIGURE_ATTRIBUTE_SUBTITLE')}{' '}
				{Boolean(attributeName) && <span className="attribute-name">{`"${attributeName}"`}</span>}
			</span>
		</div>
	);
});

interface ModalProps {
	attribute?: CustomAttributeRes;
	visible: boolean;
	onSubmit: (attribute: CustomAttributeRes) => Promise<void>;
	onCancel: () => void;
}

const DATA_TYPE_INPUT_KEY = 'data_type';

function AttributeDetailsModal({ attribute, visible, onSubmit, onCancel }: ModalProps) {
	const { t } = useTranslation();
	const [form] = useForm();

	const [attributeEnumDefinition, setAttributeEnumDefinition] = useState<CustomAttributeRes['enum_definition']>([]);
	const [isSubmitted, setIsSubmitted] = useState(false);
	const selectedDataType = useWatch<CustomAttributeDataType>(DATA_TYPE_INPUT_KEY, form);

	const { usersStore } = useStores();

	const isShowOnMobileEnabled = hasFeatureFlag(usersStore?.currentUser, 'enable_support_for_custom_attributes');

	const defaultInputRules = useMemo(() => {
		return [{ required: true, message: t('GLOBAL.FIELD_REQUIRED') }];
	}, [t]);

	const nameInputRules: Rule[] = useMemo(() => {
		return [
			...defaultInputRules,
			{
				validator: async (_, value: string) => {
					if (value && !value?.trim()) {
						return Promise.reject(t('GLOBAL.FIELD_REQUIRED'));
					}

					return Promise.resolve();
				}
			}
		];
	}, [t]);

	const pathInputRules: Rule[] = useMemo(() => {
		return [
			...defaultInputRules,
			{
				validator: async (_, value) => {
					if (value) {
						const pathParts = value.split('.');
						const valid = pathParts.every(part => pathValidationRegExp.test(part));

						return valid
							? Promise.resolve()
							: Promise.reject(t('CUSTOM_ATTRIBUTES.INVALID_PATH_INPUT_VALUE_MESSAGE'));
					}

					return Promise.resolve();
				}
			}
		];
	}, [t, defaultInputRules]);

	const initialValues = useMemo(() => {
		const initialValues = Object.assign({}, attribute);
		if (initialValues?.path?.length) {
			const splittedPath = initialValues.path.split('.');
			initialValues.path = splittedPath.slice(1, splittedPath.length + 1).join('.');
		}

		return initialValues;
	}, [attribute]);

	// TODO: this effect feels like redundant state transition, need to think how we can avoid it
	useEffect(() => {
		if (initialValues.data_type === CustomAttributeDataType.Enum) {
			setAttributeEnumDefinition(initialValues.enum_definition);
		}
	}, [initialValues]);

	const entityTypeOptions = useMemo(() => {
		return [
			{ name: t('CUSTOM_ATTRIBUTES.ENTITY_TYPE_TASK'), id: CustomAttributeEntityType.Task }
			// TODO: more options will be available later
			// { name: t('CUSTOM_ATTRIBUTES.ENTITY_TYPE_WAYPOINT'), id: CustomAttributeEntityType.Waypoint },
			// { name: t('CUSTOM_ATTRIBUTES.ENTITY_TYPE_CUSTOMER'), id: CustomAttributeEntityType.Customer },
			// { name: t('CUSTOM_ATTRIBUTES.ENTITY_TYPE_TEAM'), id: CustomAttributeEntityType.Team },
			// { name: t('CUSTOM_ATTRIBUTES.ENTITY_TYPE_USER'), id: CustomAttributeEntityType.User },
		];
	}, [t]);

	const dataTypeOptions = useMemo(() => {
		return [
			{
				id: CustomAttributeDataType.Number,
				name: t('CUSTOM_ATTRIBUTES.NUMBER_TYPE')
			},
			{
				id: CustomAttributeDataType.String,
				name: t('CUSTOM_ATTRIBUTES.STRING_TYPE')
			},
			{
				id: CustomAttributeDataType.Boolean,
				name: t('CUSTOM_ATTRIBUTES.BOOLEAN_TYPE')
			},
			{
				id: CustomAttributeDataType.Enum,
				name: t('CUSTOM_ATTRIBUTES.ENUM_TYPE')
			}
		];
	}, [t]);

	const submitAttribute = useCallback(
		async (required: boolean, show_in_mobile_app: boolean) => {
			setIsSubmitted(true);

			const formValues = form.getFieldsValue();
			const values = Object.assign(initialValues, formValues, {
				required,
				show_in_mobile_app: isShowOnMobileEnabled ? show_in_mobile_app : false,
				path: `${ATTRIBUTE_PATH_START_KEY}.${formValues.path}`,
				enum_definition: selectedDataType === CustomAttributeDataType.Enum ? attributeEnumDefinition : undefined
			});

			if (typeof values.description === 'string') {
				Object.assign(values, { description: values.description.trim() });
			}

			try {
				await onSubmit(values);
			} finally {
				setIsSubmitted(false);
			}
		},
		[initialValues, form, attribute, attributeEnumDefinition, selectedDataType]
	);

	const keyValueDefinitionState = useKeyValueDefinitionTableState({
		keyValueDefinition: initialValues.enum_definition,
		onEnumDefinitionChange: setAttributeEnumDefinition,
		translations: {
			errors: {
				emptyRowMessage: t('CUSTOM_ATTRIBUTES.ENUM_DEFINITION.ERRORS.NO_EMPTY_LIST'),
				valueAndNameNotEmpty: t('CUSTOM_ATTRIBUTES.ENUM_DEFINITION.ERRORS.VALUE_AND_NAME_NOT_EMPTY'),
				noSameValue: t('CUSTOM_ATTRIBUTES.ENUM_DEFINITION.ERRORS.NO_SAME_VALUE')
			}
		}
	});
	const isEnumTableContainsError: boolean = useMemo(() => {
		if (selectedDataType !== CustomAttributeDataType.Enum) {
			return false;
		}

		return Boolean(keyValueDefinitionState.enumDefinitionError);
	}, [selectedDataType, keyValueDefinitionState.enumDefinitionError]);

	// to avoid additional js logic and deps on form changes
	// the icons hide/show based on css class that are applied by antd
	const InputIcons = () => (
		<>
			<BringgIcon iconName={BringgFontIcons.Suffix} />
			<BringgIcon iconName={BringgFontIcons.CloseCircle} />
		</>
	);

	const DataTypeExamples = useCallback(
		() => (
			<div className="data-type-examples">
				<p>
					<b>{t('CUSTOM_ATTRIBUTES.STRING_TYPE')}</b> {t('GLOBAL.EXAMPLE')}
				</p>
				<pre>{DATA_TYPE_EXAMPLES.string}</pre>
				<p>
					<b>{t('GLOBAL.NUMERIC')}</b> {t('GLOBAL.EXAMPLE')}
				</p>
				<pre>{DATA_TYPE_EXAMPLES.numeric}</pre>
				<p>
					<b>{t('CUSTOM_ATTRIBUTES.BOOLEAN_TYPE')}</b> {t('GLOBAL.EXAMPLE')}
				</p>
				<pre>{DATA_TYPE_EXAMPLES.boolean}</pre>
				<p>
					<b>{t('CUSTOM_ATTRIBUTES.ENUM_TYPE')}</b> {t('GLOBAL.EXAMPLE')}
				</p>
				<pre>{DATA_TYPE_EXAMPLES.enum}</pre>
			</div>
		),
		[t]
	);

	return (
		<Modal
			visible={visible}
			className="attribute-definition"
			title={<ModalTitle attributeName={initialValues.name} />}
			onCancel={onCancel}
			footer={[
				<ModalFooter
					form={form}
					enumTableContainsError={isEnumTableContainsError}
					attribute={attribute}
					isSubmitted={isSubmitted}
					onCancel={onCancel}
					onOk={submitAttribute}
					isShowOnMobileEnabled={isShowOnMobileEnabled}
				/>
			]}
		>
			<Form form={form} layout="vertical" initialValues={initialValues}>
				<Row>
					<Col span={12}>
						<label className="attribute-definition-input-label required">
							{t('CUSTOM_ATTRIBUTES.ENTITY_INPUT')}
						</label>
						<FormItem name="entity_type" rules={defaultInputRules}>
							<Select
								data-test-id={TEST_IDS.entity_type}
								options={entityTypeOptions}
								disabled={attribute?.suggestion}
								placeholder={t('CUSTOM_ATTRIBUTES.ENTITY_INPUT_PLACEHOLDER')}
							/>
						</FormItem>
					</Col>
				</Row>
				<Row>
					<Col span={24}>
						<label className="attribute-definition-input-label required">
							{t('CUSTOM_ATTRIBUTES.PATH_INPUT')}
							<Tooltip
								id={TEST_IDS.path_input_tooltip}
								overlayClassName="attribute-input-tooltip"
								title={t('CUSTOM_ATTRIBUTES.PATH_INPUT_TOOLTIP')}
								placement="right"
							>
								<BringgIcon iconName={BringgFontIcons.Info} />
							</Tooltip>
						</label>
						{/* TODO: addonBefore should be done as part of the path */}
						<FormItem name="path" rules={pathInputRules}>
							<BringgInput
								addonBefore="Extras."
								allowClear={{ clearIcon: <InputIcons /> }}
								disabled={attribute?.suggestion}
								placeholder={t('CUSTOM_ATTRIBUTES.PATH_INPUT_PLACEHOLDER')}
							/>
						</FormItem>
					</Col>
				</Row>
				<Row>
					<Col span={24}>
						<label className="attribute-definition-input-label required">
							{t('CUSTOM_ATTRIBUTES.DISPLAY_NAME_INPUT')}
						</label>
						<FormItem name="name" rules={nameInputRules}>
							<BringgInput
								maxLength={256}
								required
								allowClear={{ clearIcon: <InputIcons /> }}
								placeholder={t('CUSTOM_ATTRIBUTES.DISPLAY_NAME_INPUT_PLACEHOLDER')}
							/>
						</FormItem>
					</Col>
				</Row>
				<Row>
					<Col span={12}>
						<label className="attribute-definition-input-label required">
							{t('CUSTOM_ATTRIBUTES.DATA_TYPE_INPUT')}
							<Tooltip
								id={TEST_IDS.data_type_input_tooltip}
								title={DataTypeExamples}
								placement="right"
								overlayClassName="attribute-input-tooltip data-type-examples"
							>
								<BringgIcon iconName={BringgFontIcons.Info} />
							</Tooltip>
						</label>
						<FormItem name={DATA_TYPE_INPUT_KEY} rules={defaultInputRules}>
							<Select
								data-test-id={TEST_IDS.data_type}
								options={dataTypeOptions}
								placeholder={t('CUSTOM_ATTRIBUTES.DATA_TYPE_INPUT_PLACEHOLDER')}
							/>
						</FormItem>
					</Col>
				</Row>
				{selectedDataType === CustomAttributeDataType.Enum && (
					<Row>
						<Col span={24}>
							<KeyValueTable
								state={keyValueDefinitionState}
								headers={{
									keyLabel: t('CUSTOM_ATTRIBUTES.ENUM_DEFINITION.VALUE'),
									valueLabel: t('CUSTOM_ATTRIBUTES.ENUM_DEFINITION.NAME')
								}}
							/>
						</Col>
					</Row>
				)}
				<Row>
					<Col span={24}>
						<label className="attribute-definition-input-label">
							{t('CUSTOM_ATTRIBUTES.DESCRIPTION_INPUT.LABEL')}
						</label>
						<FormItem name="description">
							<BringgTextArea
								placeholder={t('CUSTOM_ATTRIBUTES.DESCRIPTION_INPUT.PLACEHOLDER')}
								maxLength={1024}
							/>
						</FormItem>
					</Col>
				</Row>
			</Form>
		</Modal>
	);
}

export default memo(AttributeDetailsModal);
