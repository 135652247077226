export function validateZipOrPostalCode(inputString) {
	const usZipCodePattern = /^\d{5}(?:[-\s]\d{4})?$/;
	const ukPostalCodePattern = /^[A-Za-z]{1,2}\d{1,2}[A-Za-z]?\s*\d[A-Za-z]{2}$/i;
	const canadaPostalCodePattern = /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/;
	const australiaPostalCodePattern = /^\d{4}$/;
	const germanyPostalCodePattern = /^\d{5}$/;
	const francePostalCodePattern = /^(F-)?((2[A|B])|[0-9]{2})[0-9]{3}$/;
	const japanPostalCodePattern = /^\d{3}-\d{4}$/;
	const brazilPostalCodePattern = /^\d{5}-\d{3}$/;
	const spainPostalCodePattern = /^(?:0[1-9]|[1-4]\d|5[0-2])\d{3}$/;
	const chinaPostalCodePattern = /^\d{6}$/;
	const polandPostalCodePattern = /^\d{2}-\d{3}$/;
	const argentinaPostalCodePattern = /^[A-Za-z]\d{4}[A-Za-z]{0,3}$/;
	const chilePostalCodePattern = /^\d{7}$/;
	const czechRepublicPostalCodePattern = /^\d{3} \d{2}$/;
	const greecePostalCodePattern = /^\d{3}\s?\d{2}$/;
	const irelandPostalCodePattern = /^[A-Za-z]\d{2}\s?[A-Za-z0-9]{4}$/;
	const latviaPostalCodePattern = /^LV-\d{4}$/;
	const netherlandsPostalCodePattern = /^[1-9][0-9]{3} ?(?!sa|sd|ss)[a-z]{2}$/i;
	const portugalPostalCodePattern = /^\d{4}-\d{3}$/;
	const surinamePostalCodePattern = /^[A-Za-z]{2}\d{4}$/;
	const irelandRegionPostalCodePattern = /^\w\d{2}$/;
	const italyRegionPostalCodePattern = /^\d{3}$/;

	const patterns = [
		usZipCodePattern,
		ukPostalCodePattern,
		canadaPostalCodePattern,
		australiaPostalCodePattern,
		germanyPostalCodePattern,
		francePostalCodePattern,
		japanPostalCodePattern,
		brazilPostalCodePattern,
		spainPostalCodePattern,
		chinaPostalCodePattern,
		polandPostalCodePattern,
		argentinaPostalCodePattern,
		chilePostalCodePattern,
		czechRepublicPostalCodePattern,
		greecePostalCodePattern,
		irelandPostalCodePattern,
		latviaPostalCodePattern,
		netherlandsPostalCodePattern,
		portugalPostalCodePattern,
		surinamePostalCodePattern,
		irelandRegionPostalCodePattern,
		italyRegionPostalCodePattern
	];

	return patterns.some(pattern => pattern.test(inputString));
}
