import React from 'react';

import { Portal } from '@bringg-frontend/bringg-web-infra';

import SoftRollout from './soft-rollout';

const SoftRolloutPortal = () => {
	return <Portal element={<SoftRollout />} nodeId="soft-rollout" />;
};

export default SoftRolloutPortal;
