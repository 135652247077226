export const SHARING_MODES = [
	{
		label: 'All methods',
		value: 0
	},
	{
		label: 'Only via email',
		value: 1
	},
	{
		label: 'Only via SMS',
		value: 2
	},
	{
		label: 'Do not share with customer',
		value: 3
	},
	{
		label: 'Only webhook',
		value: 4
	}
];
