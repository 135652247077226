export interface DraggableItem {
	id: string;
	title: string;
	required?: boolean;
}

export interface UserDraggableItem extends DraggableItem {
	withFadeIn?: boolean;
}

export enum ListsType {
	SELECTED = 'selected',
	NON_SELECTED = 'non-selected'
}

export interface DraggableListSelectorTranslations {
	title: string;
	defaultButton: string;
	searchPlaceholder?: string;
	selected: string;
	nonSelected: string;
	applyButton?: string;
	cancelButton?: string;
	empty: string;
}
