import { phoneNumberService } from '@bringg-frontend/utils';

import { useHasFeatureFlag } from './feature-flags';

const defaultCallback = (phoneNumber: string, _countryCode?: string) => phoneNumber;

export const usePhoneNumberFormatter = () => {
	const formattingEnabled = useHasFeatureFlag('enable_phone_number_formatting');

	return formattingEnabled ? phoneNumberService.formatPhoneNumber : defaultCallback;
};
