import React, { useMemo } from 'react';

import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { capitalize } from 'lodash';
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import { ClientRateCardTerm, Collapse, Modal } from '@bringg/react-components';
import { Currency, Currencies } from '@bringg/types';

import useRateCardStore from '../store/helpers/use-rate-card-store';
import { getOperator, getAmountWithPrecision } from './preview-rate-modal-utils';
import type { Connect } from '../../../../components/delivery-catalog-connect/delivery-catalog-connect';
import { FALLBACK_LOGO } from '../../../../consts';

import './preview-rate-modal.scss';

const modalWidthInPx = 415;

interface SummaryTermItemProps {
	term: ClientRateCardTerm;
	t: TFunction;
}

const SummaryTermItem = ({ term, t }: SummaryTermItemProps) => {
	const title = term.active ? term.term_title : `${term.term_title} (${t('GLOBAL.DISABLED')})`;
	const currencySign =
		Currencies[String(term.rate_currency).toUpperCase()]?.symbol ?? Currencies[Currency.USD].symbol;
	const rate = `${capitalize(term.rate_base)} ${getOperator(term.rate_base)} ${currencySign}${getAmountWithPrecision(
		term.rate_amount
	)}`;

	return (
		<div key={term.guid} className={classNames('rate-preview-item', { inactive: !term.active })}>
			<div className="term-title" title={title}>
				{title}
			</div>
			<div className="term-rate" title={rate}>
				{rate}
			</div>
		</div>
	);
};

interface Props {
	visible: boolean;
	closeModal: () => void;
	connection?: Connect;
}

export const PreviewRateModal = observer<Props>(({ visible, closeModal, connection }: Props) => {
	const {
		rateCardStore: { rateTermsByType }
	} = useRateCardStore();
	const { t } = useTranslation();

	const title = useMemo(
		() => (
			<div className="preview-rate-modal-title">
				<div
					className="delivery-catalog-connect-name-logo"
					style={{
						backgroundImage: `url(${connection?.delivery_provider?.absolute_logo_path || FALLBACK_LOGO})`
					}}
				/>
				{connection?.name} / {t('RATES.RATE_SUMMARY')}
			</div>
		),
		[connection, t]
	);

	const basicHeader = useMemo(
		() => (
			<span className="preview-rate-collapse-header">
				{t('RATES.BASIC_FEE')}&nbsp;
				<sub>({t('RATES.CONDITIONS')})</sub>
			</span>
		),
		[t]
	);

	const surchargeHeader = useMemo(
		() => (
			<span className="preview-rate-collapse-header">
				{t('RATES.SURCHARGE_FEE')}&nbsp;
				<sub>({t('RATES.ADDITION')})</sub>
			</span>
		),
		[t]
	);

	return (
		<Modal
			visible={visible}
			width={modalWidthInPx}
			title={title}
			className="preview-rate-modal"
			onCancel={closeModal}
			// Disable modal transition which looks inappropriate here
			transitionName=""
			maskTransitionName=""
			onOk={closeModal}
			footer={null}
		>
			<div className="preview-items-wrapper">
				<Collapse className="basic-fee-collapse" defaultActiveKey="basic-fee-panel">
					<Collapse.Panel key="basic-fee-panel" header={basicHeader}>
						{rateTermsByType.base?.length ? (
							rateTermsByType.base.map(term => (
								<SummaryTermItem key={`summary-term-${term.guid}`} term={term} t={t} />
							))
						) : (
							<span className="empty-collapse-text">{t('RATES.PREVIEW_EMPTY_BASE')}</span>
						)}
					</Collapse.Panel>
				</Collapse>
				<Collapse className="surcharge-fee-collapse" defaultActiveKey="surcharge-fee-panel">
					<Collapse.Panel header={surchargeHeader} key="surcharge-fee-panel">
						{rateTermsByType.surcharge?.length ? (
							rateTermsByType.surcharge.map(term => <SummaryTermItem term={term} t={t} />)
						) : (
							<span className="empty-collapse-text">{t('RATES.PREVIEW_EMPTY_SURCHARGE')}</span>
						)}
					</Collapse.Panel>
				</Collapse>
			</div>
		</Modal>
	);
});
