import { Notification } from '@bringg/react-components';
import { action, observable, makeObservable } from 'mobx';
import { getEnv } from 'mobx-easy';

import { RootEnv } from '../root-env';
import { getSharedRootStore } from '../root-store';

class ApplicationMerchantConfigurationStore {
	configurationsData: Map<string, object> = new Map<string, object>();

	constructor() {
		makeObservable(this, {
			configurationsData: observable,
			updateConfiguration: action,
			setConfigData: action
		});
	}

	updateConfiguration = async (appUuid: Bringg.ApplicationUuid | string, configData: object): Promise<boolean> => {
		let success: boolean;
		try {
			success = await getEnv<RootEnv>().dashboardSdk.sdk.applicationMerchantConfiguration.updateConfig(
				appUuid,
				configData
			);
		} catch (e) {
			Notification.error('Error when updating config.');
			return false;
		}

		if (success) {
			this.configurationsData.set(appUuid, configData);
			Notification.success('Updated config successfully.');
			return true;
		}
		Notification.error('Failed to update config.');
		return false;
	};

	setConfigData = (appUuid: string, data: object) => {
		this.configurationsData.set(appUuid, data);
	};

	fetchConfiguration = async (appUuid: Bringg.ApplicationUuid): Promise<any> => {
		if (!getSharedRootStore().data.applicationStore.isApplicationInstalled(appUuid)) {
			return null;
		}
		if (this.configurationsData.has(appUuid)) {
			return this.configurationsData.get(appUuid);
		}
		const appConfig = await getEnv<RootEnv>().dashboardSdk.sdk.applicationMerchantConfiguration.getByAppUuid(
			appUuid
		);

		this.setConfigData(appUuid, (appConfig && appConfig.data) || {});
		return appConfig && appConfig.data;
	};

	fetchOrCreate = async (appUuid: Bringg.ApplicationUuid): Promise<any> => {
		try {
			const application = await getEnv<RootEnv>().dashboardSdk.sdk.applicationMerchantConfiguration.getByAppUuid(
				appUuid
			);

			return {
				configuration: application.data
			};
		} catch {
			return getEnv<RootEnv>().dashboardSdk.sdk.applicationMerchantConfiguration.addApplicationByUuid(appUuid);
		}
	};

	getConfiguration = (appUuid: Bringg.ApplicationUuid): any => {
		return this.configurationsData.get(appUuid);
	};
}

export default ApplicationMerchantConfigurationStore;
