import React from 'react';

export const Edit = () => (
	<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M4.192 19.808h1.534L16.74 8.822l-.767-.795-.795-.767L4.192 18.274v1.534zM2 22v-4.657L16.74 2.63c.42-.42.936-.63 1.548-.63s1.128.21 1.548.63l1.534 1.562c.42.42.63.931.63 1.534s-.21 1.114-.63 1.534L6.658 22H2zM16.74 8.822l-.767-.795-.795-.767 1.562 1.562z"
			fill="#718096"
		/>
	</svg>
);
