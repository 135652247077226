import React from 'react';

import { FormPage } from '@bringg/react-pages/dist/components';
import notification from '@bringg/react-components/dist/components/notification/notification';
import { BringgException } from '@bringg/dashboard-sdk/dist/Core/BringgException';
import { withErrorBoundary } from '@bringg-frontend/bringg-web-infra';

import TeamInfo from '../info/team-info';
import TeamsStore from '../../../stores/teams/teams-store';
import { translateFromDb, translateToDb } from './shifts-logic/shifts-logic';
import { Schedule } from './team-info-types';
import { useStores } from '../../../recipes';

export const initValues = async (teamId, teamsStore: TeamsStore, languageCode) => {
	const team = await teamsStore.fetchById(teamId);
	const [{ shift_schedules: shifts }, { scheduled_breaks: breaks }] = await Promise.all([
		teamsStore.getShifts(teamId),
		teamsStore.getBreaks(teamId)
	]);
	const schedule: Schedule = translateFromDb(shifts, breaks, languageCode);

	return {
		team,
		schedule
	};
};

export const reloadValues = async (teamId, teamsStore: TeamsStore) => {
	const team = await teamsStore.loadById(teamId);
	return { team };
};

export const submit = async ({ team, schedule }, teamsStore: TeamsStore, languageCode) => {
	const { shifts, lunchBreaks: breaks } = translateToDb(schedule, languageCode);
	const teamDomainObject = teamsStore.get(team.id);

	if (teamDomainObject.service_zipcodes_reached_limit) {
		delete team.service_zipcodes;
	}
	await teamDomainObject
		.update(team)
		.then(async () => {
			await Promise.all([teamsStore.updateShifts(team.id, shifts), teamsStore.updateBreaks(team.id, breaks)]);
		})
		.catch((error: BringgException) => {
			// this logic done on purpose to handle the real reason(message) why the team wasn't created
			// sometimes message from BE returning as Array<string> and dashboard-sdk casting it to string with index of message in array
			// this is why `.replace(/^0 */, '')` used to remove first symbols that are not related to real message
			const message = error?.details ? String(error.details).replace(/^0 */, '') : error.message;
			notification.error(message);
		});

	return Promise.resolve({ team, schedule }); // TODO: add option to not override form in react pages
};

interface Props {
	teamId: number;
}

const TeamInfoContainer: React.FC<Props> = ({ teamId }) => {
	const { teamsStore, usersStore } = useStores();
	const languageCode = usersStore.currentUserLanguage;

	const onSubmit = async formValues => {
		return submit(formValues, teamsStore, languageCode);
	};

	const onInitValues = async () => {
		return initValues(teamId, teamsStore, languageCode);
	};

	const onReloadValues = async () => {
		return reloadValues(teamId, teamsStore);
	};

	return (
		<FormPage
			showFormErrors
			useSaveGuard={false}
			component={TeamInfo}
			onInitValues={onInitValues}
			onSubmit={onSubmit}
			onReload={onReloadValues}
		/>
	);
};

export default withErrorBoundary(TeamInfoContainer);
