import React, { useState } from 'react';

import moment from 'moment/moment';
import { Button, TimeRangePicker } from '@bringg/react-components';
import { Moment } from 'moment';
import { useTranslation } from 'react-i18next';
import { dateUtils } from '@bringg-frontend/utils';

import styles from '../popover.module.scss';

interface Props {
	use12Hours: boolean;
	format: string;
	startTime: number;
	endTime: number;
	updateSlotTimeWindow: (timeWindow: { startTime: number; endTime: number }) => void;
	timeRange: { startTimeInMinutes: number; endTimeInMinutes: number };
	timezone: string;
}

const TimeWindowPopover = ({
	startTime,
	endTime,
	format,
	use12Hours,
	updateSlotTimeWindow,
	timeRange,
	timezone
}: Props) => {
	const { t } = useTranslation();
	const [timeWindow, setTimeWindow] = useState<{ startTime: number; endTime: number }>({ startTime, endTime });
	const onChange = (values: [Moment, Moment]) => {
		const [startTimeDate, endTimeDate] = values;
		const halfHour = 30;
		dateUtils.resetMinutesIfIsNotEqual(startTimeDate, halfHour);
		dateUtils.resetMinutesIfIsNotEqual(endTimeDate, halfHour);

		let startTimeInMinutes = dateUtils.diffMinutesFromLastMonday(startTimeDate);
		let endTimeInMinutes = dateUtils.diffMinutesFromLastMonday(endTimeDate);
		const day = values[0].isoWeekday();
		const startRangeTimeInMinutes = dateUtils.diffMinutesFromLastMonday(
			dateUtils.addMinutesToLastMonday(timeRange.startTimeInMinutes, timezone).isoWeekday(day)
		);
		const endRangeTimeInMinutes = dateUtils.diffMinutesFromLastMonday(
			dateUtils.addMinutesToLastMonday(timeRange.endTimeInMinutes, timezone).isoWeekday(day)
		);

		if (startTimeInMinutes < startRangeTimeInMinutes) {
			startTimeInMinutes = startRangeTimeInMinutes;
		}
		if (endTimeInMinutes > endRangeTimeInMinutes) {
			endTimeInMinutes = endRangeTimeInMinutes;
		}
		setTimeWindow({
			startTime: startTimeInMinutes,
			endTime: endTimeInMinutes
		});
	};
	const onButtonClick = () => {
		updateSlotTimeWindow(timeWindow);
	};
	const getDisabledTime = () => ({
		disabledHours: () => {
			const { startTimeInMinutes, endTimeInMinutes } = timeRange;
			const startHour = dateUtils.addMinutesToLastMonday(startTimeInMinutes, timezone).hours();
			const endHour = dateUtils.addMinutesToLastMonday(endTimeInMinutes, timezone).hours();

			return [...Array(dateUtils.HOURS_IN_DAY).keys()]
				.filter(hourInDay => hourInDay < startHour || hourInDay > endHour)
				.concat(0);
		},
		disabledMinutes: hour => {
			const { startTimeInMinutes, endTimeInMinutes } = timeRange;
			const endTimeOfTimeWindow = dateUtils.diffMinutesFromLastMonday(
				dateUtils.addMinutesToLastMonday(timeWindow.endTime, timezone).startOf('day').add(hour, 'h')
			);
			const timeRangeDurationInMinutes = endTimeInMinutes - startTimeInMinutes;
			const slotDurationInMinutes = endTimeOfTimeWindow - timeWindow.startTime;
			const halfHour = 30;
			if (timeRangeDurationInMinutes - slotDurationInMinutes === 0) {
				return [halfHour];
			}
			return [];
		}
	});

	return (
		<div className={styles.flex}>
			<div className={styles.flex}>
				<span className={styles.title}>
					{t('PLANNED_DELIVERY_WINDOWS_MODAL_TIME_WINDOW_POPOVER.WINDOW_TIME_RANGE')}
				</span>
				<span className={styles.description}>
					{t('PLANNED_DELIVERY_WINDOWS_MODAL_TIME_WINDOW_POPOVER.CHOOSE_RANGE_TIME')}
				</span>
			</div>
			<div className={styles.popoverBody}>
				<TimeRangePicker
					minuteStep={30}
					onChange={onChange}
					use12Hours={use12Hours}
					format={format}
					value={[
						moment(dateUtils.addMinutesToLastMonday(timeWindow.startTime, timezone)),
						moment(dateUtils.addMinutesToLastMonday(timeWindow.endTime, timezone))
					]}
					disabledTime={getDisabledTime}
					allowClear={false}
					placeholder={[t('PLANNED_DELIVERY_WINDOWS_MODAL.FROM'), t('PLANNED_DELIVERY_WINDOWS_MODAL.TO')]}
				/>
			</div>

			<Button type="primary" onClick={onButtonClick} className={styles.okButton}>
				{t('PLANNED_DELIVERY_WINDOWS_MODAL_TIME_WINDOW_POPOVER.OK_TEXT')}
			</Button>
		</div>
	);
};

export default TimeWindowPopover;
