import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Input } from 'antd';
import { translate } from '../../../translation';
import ChatStore from '../stores/chat-store';
import ChatView from '../stores/chat-view';

interface Props {
	chatStore?: ChatStore;
	chatView?: ChatView;
	t?: Function;
}

class ChatWindowInput extends Component<Props, {}> {
	translate: Function = this.props.t as Function;

	render() {
		const chat = this.props.chatStore.getCurrentChat;
		const { newMessageText, setText } = this.props.chatView;

		return chat ? (
			<div className="chat-window-input">
				<Input
					value={newMessageText}
					onChange={e => setText(e.target.value)}
					onPressEnter={chat.sendMessage}
					placeholder={this.translate('CHAT.TYPE_MESSAGE')}
				/>
			</div>
		) : null;
	}
}

export const ChatWindowInputComponent = ChatWindowInput;
export default translate()(inject('chatStore', 'chatView')(observer(ChatWindowInput)));
