import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { cutoffCalculation } from '../services/cutoff-calculation';
import useTimeFormat from '../../../hooks/use-time-format';
import styles from './cutoff.module.scss';
import {
	DailyCutoff,
	RelativeCutoff,
	RelativeOptions
} from 'bringg-web/features/planned-delivery-windows/modals/slots-modal/wizard-steps/configuration-step/time-slots/cutoff-radio-group/cutoff.types';
import moment from 'moment-timezone';

export enum CutoffMode {
	DAY_AND_TIME = 'DAY_AND_TIME',
	DAILY = 'DAILY',
	RELATIVE = 'RELATIVE'
}

interface Props {
	startWindowTime: Date;
	cutoff: number;
	timezone: string;
	mode?: CutoffMode;
	showText?: boolean;
}

export const Cutoff = ({
	startWindowTime,
	cutoff,
	mode = CutoffMode.DAY_AND_TIME,
	showText = true,
	timezone
}: Props) => {
	const format = useTimeFormat();
	const { t } = useTranslation();
	const [cutoffView, setCutoffView] = useState('');

	useEffect(() => {
		if (mode === CutoffMode.DAY_AND_TIME) {
			const dayAndTime = dayAndTimeText(startWindowTime, cutoff);
			setCutoffView(dayAndTime);
		} else if (mode === CutoffMode.RELATIVE) {
			const relativeCutoff = cutoffCalculation.cutoffToRelative(cutoff);
			const relativeText = relativeCutoffToText(relativeCutoff);
			setCutoffView(relativeText);
		} else if (mode === CutoffMode.DAILY) {
			const dailyCutoff = cutoffCalculation.cutoffToDaily(cutoff, startWindowTime, timezone);
			const dailyText = dailyCutoffToText(dailyCutoff);
			setCutoffView(dailyText);
		}
	}, []);

	const dayAndTimeText = (startTime: Date, cutoff: number): string => {
		const startWindowTimeDate = moment(startTime).tz(timezone).date();
		const cutoffDate = moment(startTime).tz(timezone).subtract(cutoff, 'm').date();

		if (startWindowTimeDate - cutoffDate === 0) {
			const sameDayText = t('PLANNED_DELIVERY_WINDOWS_POPOVER.SAME_DAY');
			const atText = t('PLANNED_DELIVERY_WINDOWS_POPOVER.AT');
			return `${sameDayText}, ${atText} ${cutoffCalculation.getDate(startTime, cutoff, timezone).format(format)}`;
		}

		return cutoffCalculation.getDate(startTime, cutoff, timezone).format(`dddd ${format}`);
	};
	const relativeCutoffToText = (relativeCutoff: RelativeCutoff): string => {
		let text = '';
		if (relativeCutoff.days > 0) {
			text += `${relativeCutoff.days}${t('DELIVERY_WINDOW_CUTOFF_COMPONENT.DAYS_FIRST_LETTER')} `;
		}
		if (relativeCutoff.hours > 0) {
			text += `${relativeCutoff.hours}${t('DELIVERY_WINDOW_CUTOFF_COMPONENT.HOUR_FIRST_LETTER')} `;
		}
		if (relativeCutoff.minutes > 0) {
			text += `${relativeCutoff.minutes}${t('DELIVERY_WINDOW_CUTOFF_COMPONENT.MINUTE_FIRST_LETTER')} `;
		}
		if (text && relativeCutoff.relativeOption === RelativeOptions.AFTER) {
			text += `${t('DELIVERY_WINDOW_CUTOFF_COMPONENT.AFTER')}`;
		}
		if (text && relativeCutoff.relativeOption === RelativeOptions.BEFORE) {
			text += `${t('DELIVERY_WINDOW_CUTOFF_COMPONENT.BEFORE')}`;
		}

		return text;
	};

	const dailyCutoffToText = (dailyCutoff: DailyCutoff): string => {
		if (dailyCutoff.days > 0) {
			const daysText = `${dailyCutoff.days} ${t('PLANNED_DELIVERY_WINDOWS_POPOVER.DAYS_BEFORE')}`;
			return `${daysText}, ${t('PLANNED_DELIVERY_WINDOWS_POPOVER.AT')} ${moment(dailyCutoff.time)
				.tz(timezone)
				.format(format)}`;
		}

		return `${t('PLANNED_DELIVERY_WINDOWS_POPOVER.SAME_DAY')} ${moment(dailyCutoff.time)
			.tz(timezone)
			.format(format)}`;
	};

	return (
		<div className={styles.cutoff}>
			{showText && (
				<span className={styles.cutoffTranslate}>{`${t('PLANNED_DELIVERY_WINDOWS.POPOVER.CUTOFF')}: `}</span>
			)}
			<span className={styles.cutoffValue}>{cutoffView || t('PLANNED_DELIVERY_WINDOWS_POPOVER.NO_CUTOFF')}</span>
		</div>
	);
};
