import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';

import { uniq } from 'lodash';
import { BringgInput, Divider, PopOver, Tooltip, Spacing } from '@bringg/react-components';
// TODO: fix react-components <Tree treeData={treeData}/> is not reactive to treeData changes
// eslint-disable-next-line no-restricted-imports
import { Tree } from 'antd';
import { BringgFontIcons, BringgIcon } from '@bringg/bringg-icons';
import { useTranslation } from 'react-i18next';

import {
	findExpandedByDataIndex,
	taskColumnsByDataIndex,
	tasksColumnsTreeData
} from 'bringg-web/features/orders/csv-import/columns';
import { useCSVImportTranslation } from 'bringg-web/features/orders/csv-import/translations';

import './orders-columns-popover.scss';

export type Props = {
	children: Node | string;
	originalTitle: string;
	onSelect(_: string[], e): void;
	selected: string;
	headers: string[];
	expandMandatory?: boolean;
	readonly?: boolean;
};

export default function OrdersColumnsPopover({
	children,
	originalTitle,
	onSelect,
	selected,
	headers,
	expandMandatory = false,
	readonly = false
}: Props) {
	const translations = useCSVImportTranslation();
	const { t } = useTranslation();
	const [search, setSearch] = useState('');
	const [expandedKeys, setExpandedKeys] = useState(findExpandedByDataIndex(selected));

	useEffect(() => {
		if (expandMandatory) {
			setExpandedKeys(prev => uniq([...prev, 'ORDERS_CSV.MANDATORY_FIELDS']));
		}
	}, [expandMandatory]);

	const handleSelect = useCallback(
		(_, e) => {
			if (e.node.children) {
				setExpandedKeys(prev => {
					if (prev.includes(e.node.key)) {
						return prev.filter(key => key !== e.node.key);
					}
					return [...prev, e.node.key];
				});
			} else {
				onSelect(_, e);
			}
		},
		[onSelect]
	);

	const translatedTreeData = useMemo(() => {
		return tasksColumnsTreeData.map(parent => {
			return {
				...parent,
				title: t(parent.title as string),
				children: parent.children?.map(child => ({
					...child,
					title: t(child.title as string),
					tooltip: t(`${child.title}_TOOLTIP`)
				}))
			};
		});
	}, [t]);

	const treeData = useMemo(() => {
		const disableIfSelected = node => ({
			...node,
			children: node.children?.map(child => ({
				...child,
				disabled: headers.includes(child.key) && child.key !== selected
			}))
		});

		const addStyles = node => {
			const childNode = child => (
				<div className="orders-columns-node">
					<span>{child.title}</span>
					<BringgIcon iconName={BringgFontIcons.Close} />
				</div>
			);

			return {
				...node,
				title: (
					<div className="orders-columns-node" style={{ fontSize: 13, minWidth: 205 }}>
						{node.title}
					</div>
				),
				children: node.children?.map(child => ({
					...child,
					title: !child.disabled ? (
						<Tooltip overlayClassName="orders-columns-tooltip" title={child.tooltip} placement="right">
							{childNode(child)}
						</Tooltip>
					) : (
						childNode(child)
					)
				}))
			};
		};

		if (!search) {
			return translatedTreeData.map(node => addStyles(disableIfSelected(node)));
		}

		function filter(node) {
			return node.title.toLowerCase().includes(search.trim().toLowerCase()) || node.children?.some(filter);
		}

		const result = translatedTreeData
			.map(node => {
				if (!filter(node)) {
					return null;
				}

				return addStyles(
					disableIfSelected({
						...node,
						children: node.children?.filter(filter)
					})
				);
			})
			.filter(Boolean);

		if (result.length > 0 && result.length < 3) {
			setExpandedKeys(result.map(node => node.key));
		}

		return result;
	}, [translatedTreeData, search, headers, selected]);

	const switcherIcon = ({ expanded }) => {
		return expanded ? (
			<BringgIcon iconName={BringgFontIcons.Chevron} />
		) : (
			<BringgIcon iconName={BringgFontIcons.ChevronRight} />
		);
	};

	const showTitle = (node: ReactNode) => {
		if (typeof node !== 'string') {
			return node || originalTitle;
		}

		const title = taskColumnsByDataIndex[node]?.title;

		return title ? t(title) : originalTitle;
	};

	return (
		<PopOver
			destroyTooltipOnHide
			placement="bottom"
			trigger={readonly ? [] : ['click']}
			content={
				<div className="orders-column-popover">
					<div className="original-field">{translations.originalField}</div>
					<div className="original-title">{originalTitle}</div>
					<Divider spacing={9 as Spacing} />
					<BringgInput
						autoFocus
						style={{ margin: '16px 0' }}
						value={search}
						onChange={e => setSearch(e.target.value)}
						allowClear
						placeholder={translations.search}
						type="search"
						prefix={<BringgIcon className="search-icon" iconName={BringgFontIcons.Search} />}
					/>
					<Divider spacing={9 as Spacing} />
					<Tree
						switcherIcon={switcherIcon}
						showLine
						autoExpandParent
						expandedKeys={expandedKeys}
						onExpand={setExpandedKeys}
						onSelect={handleSelect}
						selectedKeys={[selected]}
						height={300}
						treeData={treeData}
					/>
				</div>
			}
		>
			<div style={{ whiteSpace: 'nowrap' }}>
				{showTitle(children)}
				{!readonly && <BringgIcon iconName={BringgFontIcons.Chevron} />}
			</div>
		</PopOver>
	);
}
