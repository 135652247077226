import { DatePicker, TimeRangePicker } from '@bringg/react-components';
import moment, { Moment } from 'moment-timezone';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { getDiffAsHoursAndMinutes, setFullDate, setTimeAndDate } from '@bringg-frontend/utils';

import useDateFormat from 'bringg-web/hooks/use-date-format';
import timeZoneList from 'bringg-web/features/team/info/team-info-form/config/time-zone-list';
import { ONE_DAY, ONE_HOUR } from '../delivery-block-modal';

import './delivery-block-time.scss';

interface Props {
	deliveryBlockStartTime: string;
	deliveryBlockEndTime: string;
	updateDeliveryBlockTimes: (startTime: string, endTime: string) => void;
	timezone: string;
	use12Hours: boolean;
	format: string;
	allowMultiDayOptimization: boolean;
	hideDate?: boolean;
	className?: string;
	shouldBeDisabled?: boolean;
	isTemplate?: boolean;
}

export const DeliveryBlockTime = ({
	deliveryBlockStartTime,
	deliveryBlockEndTime,
	updateDeliveryBlockTimes,
	timezone,
	use12Hours,
	format,
	allowMultiDayOptimization,
	hideDate,
	className,
	shouldBeDisabled = false,
	isTemplate = false
}: Props) => {
	const { t } = useTranslation();
	const dateFormat = useDateFormat();

	const updateDeliveryBlockTime = (timeRanges: Moment[] | null) => {
		if (!timeRanges) return;

		const startTime = timeRanges[0];
		let endTime = timeRanges[1].set({ date: startTime.date() });

		if (allowMultiDayOptimization && moment(startTime).isAfter(moment(endTime))) {
			endTime.add(ONE_DAY, 'day');
		}

		const isEndTimeSmallerThan1Hour = moment.duration(endTime.diff(startTime)).asHours() < ONE_HOUR;
		if (isEndTimeSmallerThan1Hour) {
			endTime = startTime.clone().add(ONE_HOUR, 'hour');
		}

		if (!moment(startTime).isSame(moment(endTime), 'day') && !allowMultiDayOptimization) {
			endTime = startTime.clone().endOf('day');
		}

		updateDeliveryBlockTimes(
			startTime ? setTimeAndDate(startTime, deliveryBlockStartTime, timezone) : null,
			endTime ? setTimeAndDate(endTime, deliveryBlockEndTime, timezone) : null
		);
	};

	const updateDeliveryBlockDate = (date: Moment) => {
		const startDate = date;
		let endDate = date;

		if (!moment(deliveryBlockStartTime).isSame(moment(deliveryBlockEndTime), 'day')) {
			endDate = date.clone().add(ONE_DAY, 'day');
		}

		updateDeliveryBlockTimes(
			setFullDate(startDate, deliveryBlockStartTime, timezone),
			setFullDate(endDate, deliveryBlockEndTime, timezone)
		);
	};

	const isTimezoneDifferentFromMachineTz = timezone !== moment.tz.guess(true);

	const [hoursDuration, minutesDuration] = getDiffAsHoursAndMinutes(
		deliveryBlockEndTime,
		deliveryBlockStartTime
	).split(':');

	const timezoneText = timeZoneList.find(item => item.value === timezone)?.text;

	return (
		<div className={classNames('delivery-block-time-container', className, { disabled: shouldBeDisabled })}>
			<div className={classNames('delivery-block-time', className, { disabled: shouldBeDisabled })}>
				{!hideDate && (
					<div className="delivery-block-date">
						<span className="delivery-block-date-label">{t('DELIVERY_BLOCK_MODAL.DATE')}</span>
						<DatePicker
							className="delivery-block-date-input"
							allowClear={false}
							onChange={updateDeliveryBlockDate}
							value={moment(deliveryBlockStartTime).tz(timezone)}
							placeholder={t('DELIVERY_BLOCK_MODAL.SELECT_DATE')}
							format={dateFormat}
						/>
					</div>
				)}
				<div className="delivery-block-hour-time">
					<span className="delivery-block-hour-time-label">{t('DELIVERY_BLOCK_MODAL.TIME_RANGE')}</span>
					<TimeRangePicker
						order={!allowMultiDayOptimization}
						allowClear={false}
						allowEmpty={[true, true]}
						className="delivery-block-hour-time-input"
						use12Hours={use12Hours}
						format={format}
						onChange={updateDeliveryBlockTime}
						value={[moment(deliveryBlockStartTime).tz(timezone), moment(deliveryBlockEndTime).tz(timezone)]}
						placeholder={[t('DELIVERY_BLOCK_MODAL.FROM'), t('DELIVERY_BLOCK_MODAL.TO')]}
					/>
				</div>
				<div className="delivery-block-details">
					<span className="time-value hours">{hoursDuration}</span>
					<span className="time-text hours">{t('GLOBAL.HOURS_SHORT')}</span>
					<span className="time-value minutes">{minutesDuration}</span>
					<span className="time-text minutes">{t('GLOBAL.MIN')}</span>
					{!isTemplate &&
						moment(deliveryBlockStartTime).isValid() &&
						isTimezoneDifferentFromMachineTz &&
						timezoneText && (
							<span className="timezone-guess">{`${t(
								'DELIVERY_BLOCK_MODAL.TIMEZONE_IN'
							)}: ${timezoneText}`}</span>
						)}
				</div>
			</div>
		</div>
	);
};
