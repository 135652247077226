import React, { useEffect, useRef, useState } from 'react';

import intlTelInput from 'intl-tel-input';

import './intl-tel-input.scss';

export type Props = {
	value?: string;
	initialCountry?: string;
	onInit?: (instance: intlTelInput.Plugin) => void;
	onChange?: (value: string) => void;
} & React.HTMLProps<HTMLInputElement>;

const IntlTelInput = ({ initialCountry, onChange, value, onInit, ...props }: Props) => {
	const inputEl = useRef();
	const [instance, setInstance] = useState<intlTelInput.Plugin>();

	useEffect(() => {
		if (!inputEl.current) {
			return null;
		}

		// This seems to remove input itself somehow, which causes test error as it can't find element to unmount
		// wrapping input in span tag fixes test crash
		const iti = intlTelInput(inputEl.current, {
			initialCountry: initialCountry ?? null,
			separateDialCode: true,
			utilsScript: 'https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.17/js/utils.min.js'
		});

		setInstance(iti);
		if (onInit) {
			onInit(iti);
		}

		return () => {
			iti.destroy();
		};
	}, [inputEl, initialCountry, onInit]);

	useEffect(() => {
		if (!instance || !value) {
			return;
		}

		instance.setNumber(value);
	}, [value, instance]);

	return (
		<span>
			<input
				{...props}
				className="ant-input"
				type="text"
				ref={inputEl}
				// onChange triggered onInput for some reason
				onChange={() => {
					if (!instance) {
						return;
					}

					onChange(instance.getNumber());
				}}
			/>
		</span>
	);
};

export default IntlTelInput;
