import Bringg from '@bringg/types';
import { makeObservable, observable } from 'mobx';
import { getEnv } from 'mobx-easy';

import Merchant from './domain-objects/merchant';
import { RootEnv } from '../root-env';

export default class MerchantStore {
	isFetched = false;
	merchant: Merchant = null;

	constructor() {
		makeObservable(this, {
			isFetched: observable,
			merchant: observable
		});
	}

	setFetched() {
		this.isFetched = true;
	}

	storeMerchant(merchant: Bringg.Merchant) {
		this.merchant = new Merchant(merchant);
	}

	async fetch() {
		if (this.isFetched) {
			return;
		}

		const merchant = await getEnv<RootEnv>().dashboardSdk.sdk.merchant.get();
		this.storeMerchant(merchant);
		this.setFetched();
	}
}
