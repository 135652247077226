// This is an ugly hack to remove the usage of alerts view store in here
const ANY_TASK_RUN_UPDATED_GLOBAL_EVENT = 'any-task-run-updated';

/**
 * @deprecated please don't use this method, and just use reaction
 */
export function emitAnyTaskRunUpdated() {
	const event = new CustomEvent(ANY_TASK_RUN_UPDATED_GLOBAL_EVENT);
	document.dispatchEvent(event);
}

/**
 * @deprecated please don't use this method, and just use reaction
 */
export function listenToAnyTaskRunUpdated(cb: () => void) {
	document.addEventListener(ANY_TASK_RUN_UPDATED_GLOBAL_EVENT, cb);
}

/**
 * @deprecated please don't use this method, and just use reaction
 */
export function removeAnyTaskRunUpdatedListener(cb: () => void) {
	document.removeEventListener(ANY_TASK_RUN_UPDATED_GLOBAL_EVENT, cb);
}
